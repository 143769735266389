import React, { InputHTMLAttributes } from 'react';
// @ts-ignore
import CurrencyInput from 'react-currency-input';
import { Icon } from './Icon';
import './style.scss';

export type NumericInput = {
    skin?: 'gray' | 'blue' | 'red';
    disabled?: boolean;
    prefix?: string;
    suffix?: string;
    allowNegative?: boolean;
    decimalSeparator?: string;
    thousandSeparator?: string;
    precision?: string;
    fullWidth?: boolean;
    value?: number;
    onChangeEvent?: (e: React.ChangeEvent<HTMLInputElement>) => void;
} & Omit<InputHTMLAttributes<HTMLInputElement>, 'value'>;

export const NumericInput = ({
    className = '',
    prefix,
    suffix,
    skin = 'gray',
    name,
    fullWidth,
    ...props
}: NumericInput) => (
    <label
        htmlFor={name}
        className={[
            'currency-input',
            `currency-input-${skin}`,
            fullWidth ? 'currency-input-full-width' : '',
            className,
        ].join(' ')}
    >
        {prefix && <Icon>{prefix}</Icon>}
        <CurrencyInput
            name={name}
            className={[
                'currency-input__input',
                'font-size-100-regular',
                suffix && !prefix ? 'currency-input__input-padded' : '',
                className,
            ].join(' ')}
            {...props}
        />
        {suffix && <Icon>{suffix}</Icon>}
    </label>
);
