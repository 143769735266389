import React from 'react';
import { Cell } from '.';
import { CompetitivenesIcon } from '../../../../RevisaoPrecos/Components/CompetitivenesIcon';
import { Flex } from '../../atoms';
import { DATA_TABLE_PROPS } from './types';

const columns: DATA_TABLE_PROPS = {
    table: {
        headerHeight: 46,
        rowHeight: 64,
        autoHeight: true,
        rowKey: 'gtin',
    },
    content: [
        {
            column: {
                dataKey: 'gtin',
                name: 'Produto',
                minWidth: 200,
                flexGrow: 2,
            },
            cell: {
                children: Cell.Product,
            },
        },
        {
            column: {
                dataKey: 'retailPrice',
                name: 'Preço vigente',
                width: 148,
                sortable: true
            },
            cell: {
                children: (rowData, index) => (
                    <Cell.Numeric
                        dataKey="retailPrice"
                        name="retailPrice"
                        index={index}
                        {...rowData}
                    >
                        <p className="font-size-50-regular subtitle">
                            {rowData.datePrice}
                        </p>
                    </Cell.Numeric>
                ),
            },
        },
        {
            column: {
                dataKey: 'competitorPrice',
                name: 'Preço concorrência',
                width: 148
            },
            cell: {
                children: (rowData, index) => (
                    <Flex align="center" gap="2px">
                        <Cell.Numeric
                            dataKey="competitorPrice"
                            name="competitorPrice"
                            index={index}
                            disabled
                            {...rowData}
                        />
                        <CompetitivenesIcon
                            type={rowData.competitorIndicator}
                        />
                    </Flex>
                ),
            },
        },
        {
            column: {
                dataKey: 'price',
                name: 'Novo preço',
                width: 148,
            },
            cell: {
                children: (rowData, index) => (
                    <Cell.Numeric
                        dataKey="price"
                        name="price"
                        index={index}
                        prefix="R$"
                        skin="blue"
                        editable
                        {...rowData}
                    />
                ),
            },
        },
        {
            column: {
                dataKey: 'competitiveness',
                name: 'Competitividade',
                width: 148,
            },
            cell: {
                children: (rowData, index) => (
                    <Cell.Competitiveness
                        name="competitiveness"
                        index={index}
                        {...rowData}
                    />
                ),
            },
        },
        {
            column: {
                dataKey: 'pmz',
                name: 'Previsão de demanda',
                width: 164,
            },
            cell: {
                children: Cell.ProFeature,
            },
        },
    ],
};
export default columns;
