import React, { Component } from "react";
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';

import { LoadingMenu } from "./LoadingSpinner";
import { Button, SelectPicker } from "rsuite";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class MobileSingleSelectComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            open: false
        }

        this.openModalSelect = this.openModalSelect.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleSelectItem = this.handleSelectItem.bind(this);
        this.apply = this.apply.bind(this);
        this.clearSelection = this.clearSelection.bind(this);
    }

    openModalSelect() {
        this.setState({
            open: true
        })
    }

    handleClose() {
        this.setState({
            open: false
        })
    }

    handleSelectItem(item) {
        this.setState({
            selected: item
        })
    }

    apply() {
        if (this.state.selected) {
            this.props.onSelect(this.state.selected);

            setTimeout(() => {
                this.props.onChange(this.state.selected.value)
            }, 100)
        } else {
            this.props.onChange(null)
        }

        this.handleClose();
    }

    clearSelection() {
        this.handleSelectItem(null);
    }

    render() {
        const { data, value, id, placeholder } = this.props;
        const { open, selected } = this.state;


        return (
            <>
                <SelectPicker
                    id={id}
                    className="inf-picker"
                    placeholder={placeholder}
                    data={data}
                    onClick={this.openModalSelect}
                    value={value}
                    block
                    cleanable={false}
                />
                <Dialog className="dialog-select" fullScreen open={open} onClose={this.handleClose} TransitionComponent={Transition}>
                    <AppBar className="dialog-select__header">
                        <Toolbar className="header">
                            <IconButton edge="start" color="inherit" onClick={this.handleClose} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                        </Toolbar>
                    </AppBar>

                    <List className="dialog-select__list">
                        <LoadingMenu area="panel-filter" />
                        {data?.map((item) => {
                            const labelId = `checkbox-list-label-${item.label}`;

                            return (
                                <ListItem key={item.label} onClick={() => this.handleSelectItem(item)}>
                                    <ListItemIcon>
                                        <Checkbox
                                            edge="start"
                                            checked={selected?.value === item.value}
                                            tabIndex={-1}
                                            disableRipple
                                            inputProps={{ 'aria-labelledby': labelId }}
                                        />
                                    </ListItemIcon>
                                    <ListItemText id={labelId} primary={item.label} />
                                </ListItem>
                            );
                        })}
                    </List>

                    <AppBar position="fixed" className="dialog-select__footer">
                        <Button appearance="subtle" disabled={!selected} onClick={this.clearSelection}>
                            Limpar
                        </Button>
                        <Button appearance="primary" onClick={this.apply}>
                            Aplicar
                        </Button>

                    </AppBar>
                </Dialog>
            </>
        )
    }
}


export default MobileSingleSelectComponent
