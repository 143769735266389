import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Link, useHistory, withRouter } from 'react-router-dom';
import { Dropdown, Icon, Nav, Navbar } from 'rsuite';

import logoInfoprice from '../assets/logo/logo-infoprice-branco.svg';

const NavBarAdmin = () => {
    const history = useHistory();
    const { activeKey, permissoes } = useSelector(
        (state) => ({
            activeKey: state.navbarAdminDataReducer.activeKey,
            permissoes: state.usuarioDataReducer.permissoes,
        }),
        shallowEqual,
    );

    const handleSelect = (eventKey) => {
        if (eventKey === '1') {
            history.push('/administrador/pessoas');
        }
        if (eventKey === '2') {
            history.push('/administrador/produtos');
        }
        if (eventKey === '3') {
            history.push('/administrador/upload');
        }
    };

    const handleSairAdmin = () => {
        const serviceOrder = ['PAN', 'IPA', 'IRA', 'COL'];

        const serviceRoutes = {
            PAN: '/isa/painel-geral',
            IPA: '/ipa/gerenciador-de-precos',
            IRA: '/painel-retail-analytics',
            COL: '/isa/historico-preco',
        };

        const service = serviceOrder.find((serviceName) => {
            return permissoes.some((service) => {
                return service?.name === serviceName;
            });
        });

        if (service) {
            history.push(serviceRoutes[service]);
        } else {
            history.push('/isa/painel-geral');
        }
    };

    const handleLogout = () => {
        localStorage.removeItem('autenticacao.token');
        localStorage.removeItem('cliente');
        localStorage.removeItem('usuario');
        history.push('/login');
    };

    return (
        <Navbar appearance="inverse" className="navbar-admin">
            <Navbar.Header style={{ marginRight: '25px' }}>
                <div className="navbar-brand logo">
                    <img alt="Logo Infoprice" src={logoInfoprice} />
                </div>
            </Navbar.Header>
            <Navbar.Body>
                <Nav activeKey={activeKey} onSelect={handleSelect}>
                    <Nav.Item eventKey="1" componentClass="div">
                        <Link className="link" to="/administrador/pessoas">
                            Pessoas
                        </Link>
                    </Nav.Item>
                    <Nav.Item eventKey="2" componentClass="div">
                        <Link className="link" to="/administrador/produtos">
                            Produtos
                        </Link>
                    </Nav.Item>
                </Nav>
                <Nav style={{ marginRight: '20px', float: 'right' }}>
                    <Dropdown placement="bottomEnd" icon={<Icon icon="cog" />}>
                        <Dropdown.Item onClick={handleSairAdmin}>
                            Sair do Administrador
                        </Dropdown.Item>
                        <Dropdown.Item onClick={handleLogout}>
                            Sair
                        </Dropdown.Item>
                    </Dropdown>
                </Nav>
            </Navbar.Body>
        </Navbar>
    );
};

export default withRouter(NavBarAdmin);
