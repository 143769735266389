import React from 'react';
import { IconBaseProps } from 'react-icons';
import { MdInfo } from 'react-icons/md';
import { Tooltip, Whisper } from 'rsuite';

type TooltipsProps = {
    tooltip: string;
} & Partial<IconBaseProps>;

const Info = ({ tooltip, ...props }: TooltipsProps) => (
    <Whisper
        placement="top"
        trigger="hover"
        speaker={<Tooltip>{tooltip}</Tooltip>}
    >
        <MdInfo {...props} />
    </Whisper>
);

export const Tooltips = {
    Info,
} as const;
