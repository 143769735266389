import React from 'react'
import { shallowEqual, useSelector } from 'react-redux';
import { Content, Button } from 'rsuite';
import CircleProgress from '../../../../../../components/CircleProgress/CircleProgress';
import { percentage } from '../../../../../../utils/Helpers';
import style from './AnalisysCounter.module.scss'

type AnalisysCounterProps = {
    onClick?: () => void,
}

const setColorFromPercentage = (percentData: number) => {

    if (percentData === 0) {
        return '#D7373F'
    }

    if (percentData <= 25) {
        return '#ffc107'
    }

    if (percentData >= 25) {
        return '#0D66D0'
    }
}
const AnalisysCounter = ({ onClick }: AnalisysCounterProps) => {

    const { userContratoLimiteData } = useSelector(
        (state) => ({
            userContratoLimiteData: state.painelGeralDataReducer.userContratoLimiteData,
        }),
        shallowEqual,
    );

    const circleProgressPercentValue = userContratoLimiteData?.limiteDisponivel === 0 ? 100 : percentage(userContratoLimiteData?.limiteDisponivel, userContratoLimiteData?.limiteTotal)

    return (
        <Content className={style['analisys-counter']}>
            <div className={style['chart-container']}>
                <CircleProgress
                    percent={circleProgressPercentValue}
                    showInfo={false}
                    strokeColor={setColorFromPercentage(userContratoLimiteData?.limiteDisponivel)}
                    strokeWidth={8}
                >
                    <div className={style['analysis-quantity-wrapper']}>
                        <span className={style['big-quantity']}>{userContratoLimiteData?.limiteDisponivel}</span>
                        {' / '}
                        <span className={style['small-quantity']}>{userContratoLimiteData?.limiteTotal}</span>
                    </div>
                </CircleProgress>
            </div>
            <div>
                <div className={style['title-wrapper']}>
                    <p className={style.title}>Você possui {userContratoLimiteData?.limiteDisponivel} análises mensais disponíveis!</p>
                    <p className={style.subtitle}>Contrate o plano Pro ou Flex para análises ilimitadas.</p>
                </div>
                <Button
                    className={style['btn-redirect']}
                    appearance="ghost"
                    onClick={onClick}
                >
                    Entre em contato agora
                </Button>
            </div>
        </Content>
    )
}

export default AnalisysCounter
