import EditIcon from '@material-ui/icons/Edit';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
    Button,
    Checkbox,
    Table,
    Tooltip,
    Whisper,
} from 'rsuite';
import { selectorCalculadoraPrevisaoDemanda } from '../../../../reducers/calculadoraPrevisaoDemanda';
import {
    setFiltersFromEditScenario,
} from '../../../../reducers/calculadoraPrevisaoDemanda/filtros';
import {
    setInputFromEditScenario,
} from '../../../../reducers/calculadoraPrevisaoDemanda/inputCalculos';
import {
    setResultFromEditScenario,
    setResultScenarioData,
} from '../../../../reducers/calculadoraPrevisaoDemanda/resultScenario';
import { LoadingSpinerArea } from '../../../../components/LoadingSpinner';
import { Indicator } from '../Indicator';
import { useTableActions, useGetScenarioResults } from '../../hooks';
import * as utils from '../../utils';
import * as service from '../../services';

import styles from './SavedScenarioTable.module.scss';

const {
 Column, HeaderCell, Cell, Pagination,
} = Table;

const lengthMenu = [
    {
        value: 10,
        label: 10,
    },
    {
        value: 20,
        label: 20,
    },
];

export const SavedScenarioTable = () => {
    const { savedScenario } = useSelector(selectorCalculadoraPrevisaoDemanda);
    const selectedScenario = savedScenario.selectedScenarioItemList;
    const tableData = savedScenario.savedScenarioData;
    const indeterminate = selectedScenario.length > 0 && selectedScenario.length < tableData.totalElements;

    const {
        tableHandlers,
        tableState,
    } = useTableActions();

    const { resultHandlers } = useGetScenarioResults();

    const dispatch = useDispatch();

    const history = useHistory();

    const handleEditItem = async (id: string) => {
        dispatch(setResultScenarioData({ name: 'isEditing', data: true }));
        dispatch(setResultScenarioData({ name: 'editId', data: id }));
        const response = await service.requestScenariosByID('GET', id, null);
        if (response) {
            dispatch(setFiltersFromEditScenario({ data: response }));
            dispatch(setResultFromEditScenario({ data: response }));
            resultHandlers.handleGetFirstChartsData(response);
            resultHandlers.handleGetSecondChartsData(response);
            resultHandlers.handleGetCPIChartsData(response);
            resultHandlers.handleChartBigNumbers(response);
            setTimeout(() => {
                dispatch(setInputFromEditScenario({ data: response }));
            }, 1500);
        }
        history.push('/ipa/calculadora-demanda');
    };

    return (
        <div className={styles['table-scenario-container']}>
            <LoadingSpinerArea area="table-scenario" />
            <Table
                data={tableData?.content}
                autoHeight
                id="table-negociacoes-fornecedor"
                className={styles['table-scenario']}
                rowClassName={styles['table-scenario-row']}
                rowHeight={64}
                headerHeight={46}
                sortColumn={tableState.sortColumn}
                sortType={tableState.sortType}
                onSortColumn={tableHandlers.handleSortColumns}
            >
                <Column align="center" width={60}>
                    <HeaderCell style={{ padding: '2px' }}>
                        {/* <Checkbox
                            indeterminate={indeterminate}
                            checked={selectedScenario.length === tableData.totalElements}
                            onChange={tableHandlers.handleChangeAllCheckbox}
                        /> */}
                    </HeaderCell>
                    <Cell>
                        {(rowData) => (
                            <Checkbox
                                checked={selectedScenario.includes(rowData.scenario.scenario_id)}
                                value={rowData.scenario.scenario_id}
                                onChange={tableHandlers.handleChangeCheckbox}
                            />
                        )}
                    </Cell>
                </Column>
                <Column sortable flexGrow={3}>
                    <HeaderCell>
                        Cenário
                    </HeaderCell>
                    <Cell dataKey="cenario" height={60}>
                        {(rowData) => (
                            <Whisper
                                delayShow={0.2}
                                trigger="hover"
                                placement="bottom"
                                speaker={<Tooltip>{rowData.scenario.scenario_name}</Tooltip>}
                            >
                                <div>
                                    <div>
                                        <span className={styles['main-bold-text']}>{rowData.scenario.scenario_name}</span>
                                    </div>
                                    <div className={styles['product-subtext-flex']}>
                                        <span className={styles.subtext}>
                                            {rowData.inputs.preferences.period_in_days}
                                            {' '}
                                            dias
                                        </span>
                                        <span className={styles['chevron-right']}>{' > '}</span>

                                        <span className={styles.subtext}>
                                            {rowData.inputs.competitor_price_follow_up || '100'}
                                            {' '}
                                            %
                                        </span>
                                    </div>
                                </div>
                            </Whisper>
                        )}
                    </Cell>
                </Column>
                <Column sortable width={120}>
                    <HeaderCell>
                        Produto
                    </HeaderCell>
                    <Cell dataKey="produto" height={60}>
                        {(rowData) => (
                            <div>
                                <p className={styles['main-bold-text']}>{rowData.query.product_id}</p>
                                <p className={styles.subtext}>{rowData.query.product_description}</p>
                            </div>
                            )}
                    </Cell>
                </Column>
                <Column sortable width={120}>
                    <HeaderCell>Local</HeaderCell>
                    <Cell dataKey="local">
                        {(rowData) => (
                            <div>
                                <p className={styles['main-bold-text']}>
                                    {rowData.query.location_type === 'UF'
                                        ? utils.ufToName[rowData.query.location]
                                        : rowData.query.location}
                                </p>
                                <p className={styles.subtext}>{rowData.query.location_type}</p>
                            </div>
                            )}
                    </Cell>
                </Column>
                <Column sortable width={150}>
                    <HeaderCell>Canal</HeaderCell>
                    <Cell dataKey="canal">
                        {(rowData) => (
                            <div className={styles['margem-objetiva-cell-wrapper']}>
                                <p className={styles['main-bold-text']}>{rowData.query.channel}</p>
                                <p className={styles.subtext}>
                                    {`${rowData.query.sub_channel === null
                                        ? 'Todo o canal'
                                        : rowData.query.sub_channel}
                                        > ${rowData.inputs.preferences.period_in_days}
                                    dias`}
                                </p>
                            </div>
                            )}
                    </Cell>
                </Column>
                <Column sortable minWidth={88}>
                    <HeaderCell>Preço</HeaderCell>
                    <Cell dataKey="preco">
                        {(rowData) => (
                            <div>
                                <p className={styles['main-bold-text']}>
                                    {utils.formatNumberToCurrency(rowData.outputs.new_price_scenario.price, 2)}
                                </p>
                                <p className={styles.subtext}>
                                    {utils.formatNumberToCurrency(rowData.outputs.base_price_scenario.price, 2)}
                                </p>
                            </div>
                            )}
                    </Cell>
                </Column>
                <Column sortable width={90}>
                    <HeaderCell>Margem</HeaderCell>
                    <Cell dataKey="margem">
                        {(rowData) => (
                            <div>
                                <p className={styles['main-bold-text']}>
                                    {utils.formatPercent(rowData.outputs.new_price_scenario.margin)}
                                    {' '}
                                    %
                                </p>
                                <p className={styles.subtext}>
                                    {utils.formatPercent(rowData.outputs.base_price_scenario.margin)}
                                    {' '}
                                    %
                                </p>
                            </div>
                            )}
                    </Cell>
                </Column>
                <Column sortable width={130}>
                    <HeaderCell>Demanda (un)</HeaderCell>
                    <Cell dataKey="demanda">
                        {(rowData) => (
                            <div>
                                <p className={styles['main-bold-text']}>
                                    {utils.formatLocaleNumber(rowData.outputs.new_price_scenario?.demand_forecast, 0)}
                                </p>
                                <div className={styles['sub-flex']}>
                                    <p className={styles.subtext}>
                                        {utils.formatLocaleNumber(rowData.outputs.base_price_scenario?.demand_forecast, 0)}
                                    </p>
                                    <Indicator
                                        className="indicator"
                                        fontSize="10px"
                                        value={rowData.outputs.base_to_new_variations?.demand_forecast * 100}
                                    />
                                </div>
                            </div>
                )}
                    </Cell>
                </Column>
                <Column sortable width={160}>
                    <HeaderCell>Receita (R$)</HeaderCell>
                    <Cell dataKey="receita">
                        {(rowData) => (
                            <div>
                                <p className={styles['main-bold-text']}>
                                    {utils.formatNumberToCurrency(rowData.outputs.new_price_scenario?.revenue_forecast, 0)}
                                </p>
                                <div className={styles['sub-flex']}>
                                    <p className={styles.subtext}>
                                        {utils.formatNumberToCurrency(rowData.outputs.base_price_scenario?.revenue_forecast, 0)}
                                    </p>
                                    <Indicator
                                        className="indicator"
                                        fontSize="10px"
                                        value={rowData.outputs.base_to_new_variations?.revenue_forecast * 100}
                                    />
                                </div>
                            </div>
                )}
                    </Cell>
                </Column>
                <Column sortable width={150}>
                    <HeaderCell>Lucro</HeaderCell>
                    <Cell dataKey="lucro">
                        {(rowData) => (
                            <div>
                                <p className={styles['main-bold-text']}>
                                    {utils.formatNumberToCurrency(rowData.outputs.new_price_scenario?.profit_forecast, 0)}
                                </p>
                                <div className={styles['sub-flex']}>
                                    <p className={styles.subtext}>
                                        {utils.formatNumberToCurrency(rowData.outputs.base_price_scenario?.profit_forecast, 0)}
                                    </p>
                                    <Indicator
                                        className="indicator"
                                        fontSize="10px"
                                        value={rowData.outputs.base_to_new_variations?.profit_forecast * 100}
                                    />
                                </div>
                            </div>
                            )}
                    </Cell>
                </Column>
                <Column sortable width={116}>
                    <HeaderCell>Responsável</HeaderCell>
                    <Cell dataKey="responsavel">
                        {(rowData) => (
                            <div>
                                <p className={styles['regular-text']}>
                                    {rowData.scenario.responsible}
                                </p>
                                <p className={styles.subtext}>
                                    {rowData.scenario.simulation_date
                                        ? utils.formatDate(rowData.scenario.simulation_date)
                                        : '-'}
                                </p>
                            </div>
                            )}
                    </Cell>
                </Column>
                <Column width={80}>
                    <HeaderCell />
                    <Cell>
                        {(rowData) => (
                            <Button
                                id="edit-scenario"
                                className={styles['btn-edit']}
                                onClick={() => handleEditItem(rowData.scenario.scenario_id)}
                            >
                                <EditIcon className={styles['icon-edit']} />
                            </Button>
                            )}
                    </Cell>
                </Column>
            </Table>
            <Pagination
                activePage={tableState.page + 1 || null}
                displayLength={tableData.size}
                total={tableData.totalElements || null}
                onChangePage={tableHandlers.handleChangePage}
                onChangeLength={tableHandlers.handleChangeSize}
                lengthMenu={lengthMenu}
            />
        </div>

    );
};
