import React, { HtmlHTMLAttributes } from 'react';
import { Button, Checkbox } from 'rsuite';
import { ButtonProps } from 'rsuite/lib/Button';
import { CheckboxProps } from 'rsuite/lib/Checkbox';
import {
    SelectPill,
    SelectPillProps,
} from '../../../../../IRA/Components/SelectPill';
import './style.scss';

const FilterGroup = ({
    className = '',
    ...props
}: HtmlHTMLAttributes<HTMLDivElement>) => (
    <div
        className={['filter-box-container', 'filter-group', className].join(
            ' ',
        )}
        {...props}
    />
);

export type FilterItemProps = SelectPillProps;

const FilterItem = ({ className = '', ...props }: FilterItemProps) => (
    <SelectPill
        className={['filter-group__item', className].join(' ')}
        {...props}
    />
);

const FilterCleanBtn = ({ className = '', ...props }: ButtonProps) => (
    <Button
        className={[
            'filter-group__item',
            'filter-group__clean-btn',
            'clear-filtros-btn',
            className,
        ].join(' ')}
        {...props}
    />
);

export type FilterFooterProps = CheckboxProps;

const FilterFooter = ({ className = '', ...props }: FilterFooterProps) => (
    <div className="picker-pill-footer">
        <Checkbox
            className={['filter-group__footer', className].join(' ')}
            inline
            {...props}
        />
    </div>
);

FilterGroup.Item = FilterItem;
FilterGroup.CleanFilters = FilterCleanBtn;
FilterGroup.Footer = FilterFooter;

export { FilterGroup };
