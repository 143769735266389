import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
    Modal, Button, Whisper, Input, Tooltip,
} from 'rsuite';
import { getUsersEmailData } from '../../../../../actions/actionsPainelGeral';
import SelectTag from '../../../../../components/SelectTag';
import iconLink from '../../../../../assets/icons/icon_link.svg';
import { validEmail } from '../../Freemium/utils';
import { trackEvent } from '../../../../../utils/MatomoConfig';

const ModalShareAnalyzedViews = ({
    show,
    close,
    onClickCopyLink,
    deadlineDate,
    setShareEmailData,
}) => {
    const dispatch = useDispatch();
    const { data } = useSelector(
        (state) => ({
            data: state.painelGeralDataReducer?.userEmails?.data,
        }),
        shallowEqual,
    );
    const [textarea, setTextarea] = useState({ length: 0, message: '' });
    const [emails, setEmails] = useState([]);
    const [error, setError] = useState('');
    const [cachedEmails, setCachedEmails] = useState([]);

    const emailData = data?.map((item) => ({
        label: item.nome,
        value: item.email,
    }));

    const tooltip = (
        <Tooltip className="copy-link-tooltip">
            Link copiado para a área de transferência
        </Tooltip>
    );

    const renderMenuItem = (label, item) => (
        <>
            <p className="item-name">{label}</p>
            <p className="item-email">{item.value}</p>
        </>
    );

    const renderValue = (value) => <span>{value}</span>;

    const handleTextareaChange = (value) => {
        setTextarea({
            length: value.length,
            message: value,
        });
    };

    const handleEmailSearch = (value) => {
        if (value.length) {
            dispatch(getUsersEmailData(value));
        }
    };

    const handleSelectEmail = (value, item) => {
        const hasInvalidEmail = value
            .map((email) => validEmail(email))
            .some((valid) => valid === false);

        setCachedEmails([...cachedEmails, item]);

        if (hasInvalidEmail) {
            setError('invalid-email');
        } else {
            setError('');
        }
    };

    const handleCancel = () => {
        close();
        setCachedEmails([]);
        setEmails([]);
    };

    const trackEventMatomo = () => {
        trackEvent('InfoPanel', 'click-compartilhar-relatorio');
    };

    const handleShareAnalysis = () => {
        if (emails.length === 0) {
            setError('invalid-email');
        } else {
            setShareEmailData({ emails, message: textarea.message });
            trackEventMatomo();
        }
    };

    useEffect(() => {
        dispatch(getUsersEmailData());
    }, []);

    return (
        <Modal
            className="modal-share-analyzed-views"
            size="sm"
            show={show}
            onHide={handleCancel}
        >
            <Modal.Header>
                <Modal.Title>Compartilhar análise</Modal.Title>
                <p className="subtitle">
                    Dê maior visibilidade à sua análise compartilhando os
                    achados com a sua equipe.
                </p>
            </Modal.Header>
            {deadlineDate && (
                <div className="date-alert">
                    Essa análise estará disponível para visualização até a data:
                    <span className="date">{deadlineDate}</span>
                </div>
            )}
            <Modal.Body>
                <div>
                    <label htmlFor="email-select">Enviar por e-mail para</label>
                    <SelectTag
                        block
                        creatable
                        value={emails}
                        cacheData={cachedEmails}
                        className={error}
                        name="email-select"
                        placeholder="Insira nome ou email"
                        menuClassName="email-select-menu"
                        data={emailData}
                        renderMenuItem={renderMenuItem}
                        renderValue={renderValue}
                        searchable
                        onChange={setEmails}
                        onSearch={handleEmailSearch}
                        onSelect={handleSelectEmail}
                        locale={{
                            createOption: '',
                        }}
                    />
                </div>
                <div className="message-wrapper">
                    <label htmlFor="message">Mensagem (opcional)</label>
                    <Input
                        name="message"
                        rows={4}
                        placeholder="Adicione uma mensagem"
                        componentClass="textarea"
                        onChange={handleTextareaChange}
                        maxLength="450"
                    />
                    <p>
                        {450 - textarea?.length}
                        {' '}
                        caracteres restantes
                    </p>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className="link-copy-wrapper">
                    <Whisper
                        placement="top"
                        controlId="control-id-click"
                        trigger="click"
                        speaker={tooltip}
                    >
                        <Button appearance="link" onClick={onClickCopyLink}>
                            <img src={iconLink} alt="" />
                            Copiar link da análise
                        </Button>
                    </Whisper>
                </div>
                <div>
                    <Button onClick={handleCancel} appearance="ghost">
                        CANCELAR
                    </Button>
                    <Button onClick={handleShareAnalysis} appearance="primary">
                        COMPARTILHAR
                    </Button>
                </div>
            </Modal.Footer>
        </Modal>
    );
};

export default ModalShareAnalyzedViews;
