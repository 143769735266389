import Immutable from 'seamless-immutable';
import {
    SET_CLIENTE,
    SET_SERVICOS_CLIENTE,
    SET_NAME_CLIENTE,
    SET_CLIENTE_DATA,
} from '../actions/actionsCliente';

const clienteInitialState = Immutable({
    codigoCliente: null,
    servicosCliente: null,
    nameCliente: null,
    clienteData: null,
});

function clienteDataReducer(state = clienteInitialState, action) {
    switch (action.type) {
        case SET_CLIENTE:
            return state.merge({
                codigoCliente: action.codigoCliente,
            });
        case SET_SERVICOS_CLIENTE:
            return state.merge({
                servicosCliente: action.servicosCliente,
            });
        case SET_NAME_CLIENTE:
            return state.merge({
                nameCliente: action.nameCliente,
            });
        case SET_CLIENTE_DATA:
            return state.merge({
                clienteData: action.data,
            });
        default:
            return state;
    }
}

export default clienteDataReducer;
