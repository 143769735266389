import { useState } from 'react';

export const useReferenciaCompetidor = () => {
    const [referenciaCompetidor, setReferenciaCompetidor] = useState({
        referencia: [],
        competidor: [],
        concatList: [],
    });

    const setReferencia = (v) => {
        setReferenciaCompetidor((prevState) => ({
            ...prevState,
            referencia: [v],
            concatList: [...prevState.competidor, v],
        }));
    };

    const setCompetidor = (v) => {
        setReferenciaCompetidor((prevState) => ({
            ...prevState,
            competidor: v,
            concatList: [...prevState.referencia.concat(v)],
        }));
    };

    const setCleanReferenciaCompetidor = (name) => {
        const removedType = referenciaCompetidor.concatList.filter(
            (item) => !referenciaCompetidor[name].includes(item),
        );
        setReferenciaCompetidor((prevState) => ({
            ...prevState,
            [name]: [],
            concatList: removedType,
        }));
    };

    return {
        referenciaCompetidor,
        setReferencia,
        setCompetidor,
        setCleanReferenciaCompetidor,
    };
};
