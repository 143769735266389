import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Table,
} from 'rsuite';
import ModalConfirm from '../../../../../../components/ModalConfirm';
import { selectorExtracaoDados } from '../../../../../../reducers/extracaoDados';
import {
    setEcomValues,
} from '../../../../../../reducers/extracaoDados/ecom';
import { modalTabelaDadosExtracao } from '../../utils';
import styles from './ModalDadosTabelaExtracao.module.scss';

const {
    Column, HeaderCell, Cell,
} = Table;

export const ModalDadosTabelaExtracao = () => {
    const dispatch = useDispatch();

    const { ecom } = useSelector(selectorExtracaoDados);

    const handleCloseModal = () => {
        const obj = { name: 'openModalDadosTabelaExtracao', value: false };
        dispatch(setEcomValues(obj));
    };

    return (
        <ModalConfirm
            show={ecom.openModalDadosTabelaExtracao}
            modalWidth="925px"
            titleFontSize="16px"
            footerAlign="end"
            title="Dados da tabela de extração"
            message="Esses são os campos que aparecerão no arquivo de download da extração"
            cancelButton="Fechar"
            onHideHeaderButton={handleCloseModal}
            onCancel={handleCloseModal}
            onConfirm={handleCloseModal}
        >
            <Table
                className={styles.table}
                bordered
                height={300}
                data={modalTabelaDadosExtracao}
            >
                <Column flexGrow={1}>
                    <HeaderCell>
                        Nome da coluna na extração
                    </HeaderCell>
                    <Cell dataKey="name" />
                </Column>
                <Column flexGrow={2}>
                    <HeaderCell>
                        Descrição do campo
                    </HeaderCell>
                    <Cell dataKey="description" />
                </Column>
                <Column flexGrow={1}>
                    <HeaderCell>
                        Origem da informação
                    </HeaderCell>
                    <Cell dataKey="origin" />
                </Column>
            </Table>
        </ModalConfirm>
    );
};
