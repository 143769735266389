import React from 'react';
import { useSelector } from 'react-redux';
import { selectorCalculadoraPrevisaoDemanda } from '../../../../reducers/calculadoraPrevisaoDemanda';
import * as utils from '../../utils';
import styles from './InfopanelCompetition.module.scss';

export const InfopanelCompetition = () => {
    const { inputCalculos } = useSelector(selectorCalculadoraPrevisaoDemanda);
    const {
        client_product_price,
        competitor_product_price,
        cpi,
    } = inputCalculos.concorrenciaInfopanel;

    return (
        <div className={styles['competition-container']}>
            <div className={styles['title-wrapper']}>
                <p className={styles.title}>Concorrência Infopanel</p>
                <p className={styles.subtitle}>Preço mais frequente 30d no Varejo</p>
            </div>
            <p className={styles['product-title']}>{utils.limitString(inputCalculos.query.competitor_product_description, 34)}</p>
            <div className={styles['price-area']}>
                <div className={styles['price-row']}>
                    <div className={styles['price-label-wrapper']}>
                        <p className={styles.label}>Preço competidor</p>
                    </div>
                    <div className={styles['price-wrapper']}>
                        <p className={styles.price}>
                            {utils.formatNumberToCurrency(competitor_product_price, 2)}
                        </p>
                    </div>
                </div>
                <div className={styles['price-row']}>
                    <div className={styles['price-label-wrapper']}>
                        <p className={styles.label}>Preço do seu produto</p>
                    </div>
                    <div className={styles['price-wrapper']}>
                        <p className={styles.price}>
                            {utils.formatNumberToCurrency(client_product_price, 2)}
                        </p>
                    </div>
                </div>
                <div className={styles['price-row']}>
                    <div className={styles['price-label-wrapper']}>
                        <p className={styles.label}>CPI (Index de preço)</p>
                    </div>
                    <div className={styles['price-wrapper']}>
                        <p className={styles.price}>
                            {cpi ? utils.formatLocaleNumber((cpi * 100), 2) : '-'}
                        </p>
                    </div>
                </div>
            </div>
        </div>
  );
};
