import qs from 'qs';
import { trackPromise } from 'react-promise-tracker';
import api from '../../../../utils/API';
import { getValidData } from '../../RevisaoPrecos/utils';
import { DATAPOINTS_RESPONSE_PROPS, EDIT_OBJECT } from '../@types';

export type OnboardingProps = {
    id: string;
    clientId: number;
    client: string;
    createdBy: string;
    editedBy: string;
    indirectCompetitivenessDTO: {
        storeType: 'SAME_TYPE' | 'INDEPENDENT_TYPE';
        location: 'CITY' | 'UF';
        competitiveness: number;
    };
};

export async function getOnboardingSetup() {
    return trackPromise<OnboardingProps>(
        // @ts-ignore
        api({
            method: 'GET',
            url: `${process.env.REACT_APP_IPA_URL}/setup/lite`,
            expectToken: true,
        }).then((res) => res.data),
        'get-onboarding-setup',
    );
}

type SaveOnboardingProps = {
    createdBy: string;
    editedBy: string;
    indirectCompetitivenessDTO: {
        storeType: 'SAME_TYPE' | 'INDEPENDENT_TYPE';
        location: 'CITY' | 'UF' | null;
        competitiveness: number;
    };
};

export async function saveOnboarding(data: SaveOnboardingProps) {
    return trackPromise(
        // @ts-ignore
        api({
            url: `${process.env.REACT_APP_IPA_URL}/setup/lite`,
            method: 'POST',
            expectToken: true,
            data: JSON.stringify(data),
        }).then((res) => res),
        'onboard-config',
    );
}

export type FilterModelProps = {
    field: string;
    operator: 'in';
    values: string[];
};

export type GetDatapointProps = {
    page?: number;
    size?: number;
    sort?: string;
    filters?: FilterModelProps[];
} | null;

export async function getDatapoints(data?: GetDatapointProps) {
    return trackPromise<DATAPOINTS_RESPONSE_PROPS>(
        // @ts-ignore
        api({
            url: `${process.env.REACT_APP_IPA_URL}/lite/all-products`,
            method: 'POST',
            expectToken: true,
            params: {
                page: data?.page,
                size: data?.size,
                sort: data?.sort
            },
            data: {
                filters: data?.filters
            },
        }).then((resp) => resp.data),
        'datapoints-table',
    );
}

export async function editDatapoint(data: EDIT_OBJECT) {
    return trackPromise<{
        id: string;
        clientId: number;
        client: string;
        productsToBePricedId: string | null;
        productId: string;
        storeId: string;
        retailPrice: number;
        author: string | null;
        price: number | null;
        competitorPrice: number;
    }>(
        // @ts-ignore
        api({
            method: 'POST',
            url: `${process.env.REACT_APP_IPA_URL}/lite/price`,
            data: JSON.stringify(data),
            expectToken: true,
        }).then((resp) => resp.data),
        `numeric-input-${data.productsToBePricedId}`,
    );
}

export async function downloadDatapoints() {
    return trackPromise(
        // @ts-ignore
        api({
            method: 'GET',
            url: `${process.env.REACT_APP_IPA_URL}/lite/download`,
            expectToken: true,
            responseType: 'arraybuffer',
        })
            .then((res) => {
                if (res.status === 200) {
                    return res.data;
                }
                return res;
            })
            .then((resp) => {
                let download = resp;
                if (!download.status) {
                    const blob = new Blob([download]);
                    const hiddenElement = document.createElement('a');
                    hiddenElement.href = window.URL.createObjectURL(blob);
                    hiddenElement.target = '_blank';
                    hiddenElement.download = 'gerenciador_de_precos.csv';
                    hiddenElement.click();
                    download = null;
                }
                return download;
            }),
        'download-datapoints',
    );
}

export type USERFILTERS_RESPONSE = {
    data:
        | {
              client: string;
              store: string;
              gtin: string;
              description: string;
              store_id: string;
              store_brand: string;
              product_id: string;
          }[];
    total: number;
};

export async function getFiltersGtin(
    params: Partial<{
        query?: string;
    }>,
): Promise<USERFILTERS_RESPONSE> {
    return trackPromise(
        // @ts-ignore
        api({
            url: `${process.env.REACT_APP_IPA_URL}/lite/products/search`,
            method: 'GET',
            expectToken: true,
            params: getValidData(params),
            paramsSerializer: (params) =>
                qs.stringify(params, { arrayFormat: 'repeat' }),
        }).then((resp) => resp.data),
    );
}
