import React, { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { setSingleReducer } from '../../../../../../../actions/actionsAnaliseCompetitividade';
import { LoadingMenu } from '../../../../../../../components/LoadingSpinner';
import SelectPill from '../../../../../../../components/SelectPill';
import { useChangePlaceholder } from '../../../hooks/useChangePlaceholder';

import arrowRightIcon from '../../../../../../../assets/icons/icon_arrow-right-gray.svg';
import {
    filtrosDataType,
    getModel,
    reverseLocalDataType,
} from '../../../utils';
import styles from './styles.module.scss';

const LocalTypeSelectComponent = ({
    data,
    value,
    placeholder,
    onSearch,
    onOpen,
    onSelect,
    filterIsEmpty,
}) => (
    <SelectPill
        type="check"
        id={filterIsEmpty ? 'select-pill-is-empty' : 'check-picker-pill'}
        placeholder={placeholder}
        cleanable={false}
        value={value}
        data={data}
        onSearch={onSearch}
        onSelect={onSelect}
        onOpen={onOpen}
        locale={{
            noResultsText: 'Nenhum item encontrado',
            searchPlaceholder: 'Buscar item',
        }}
        renderMenu={(menu) => (
            <span>
                <LoadingMenu area="panel-filter" />
                {menu}
            </span>
        )}
    />
);

export const LocalSelect = ({
    placeholder,
    firstSelectData,
    onSelectLocationType,
    onSelectLocations,
    savedFilters,
    locationState,
    locationHandlers,
}) => {
    const { filtrosDataCompetitividade, filterIsEmpty } = useSelector(
        (state) => ({
            filtrosDataCompetitividade:
                state.analiseCompetitividadeDataReducer
                    .filtrosDataCompetitividade,
            filterIsEmpty:
                state.analiseCompetitividadeDataReducer.filterIsEmpty,
        }),
        shallowEqual,
    );

    const dispatch = useDispatch();

    const {
        selectPlaceholder,
        handlePlaceholderChange,
        handleFirstPlaceholderChange,
    } = useChangePlaceholder(placeholder, true);

    const handleLocationTypeOnSelect = (value) => {
        handleFirstPlaceholderChange(value);
        onSelectLocationType(value);
    };

    const handleLocationsOnSelect = (value, item, name) => {
        const hasValue = locationState.location[name].value?.includes(
            item.value,
        );

        handlePlaceholderChange(value);
        onSelectLocations(value, item);

        if (hasValue) {
            locationHandlers.handleRemoveLocation(name, item);
        } else {
            locationHandlers.handleAddLocation(name, item);
        }
    };

    const handleLocationsOnOpen = (name) => {
        locationHandlers.getLocalSelectList(
            filtrosDataType[name],
            getModel(filtrosDataCompetitividade, '', filtrosDataType[name]),
        );
        dispatch(
            setSingleReducer('filterIsEmpty', {
                ...filterIsEmpty,
                listaLocalizacao: false,
            }),
        );
    };

    const handleLocationsTypeOnOpen = () => {
        dispatch(
            setSingleReducer('filterIsEmpty', {
                ...filterIsEmpty,
                tipoLocalizacao: false,
            }),
        );
    };

    useEffect(() => {
        const localList = savedFilters?.listaLocalizacao?.map((item) => ({
            label: item,
            value: item,
        }));

        const nameLowerCase = savedFilters?.tipoLocalizacao?.toLowerCase();
        if (savedFilters) {
            handleFirstPlaceholderChange(
                reverseLocalDataType[savedFilters?.tipoLocalizacao],
            );
            locationHandlers.setLocation((prevState) => ({
                ...prevState,
                [nameLowerCase]: {
                    ...prevState[nameLowerCase],
                    list: localList,
                    cache: localList,
                    value: savedFilters?.listaLocalizacao,
                },
            }));
        } else {
            handleFirstPlaceholderChange('');
        }
    }, [savedFilters]);

    const selectCidade = (
        <LocalTypeSelectComponent
            filterIsEmpty={filterIsEmpty?.listaLocalizacao}
            placeholder={selectPlaceholder?.placeholder}
            cleanable={false}
            data={locationState.location?.cidade?.list}
            value={locationState.location?.cidade?.value}
            onSearch={(search) =>
                locationHandlers.handleSearchLocation(search, 'cidade')
            }
            onSelect={(value, item) =>
                handleLocationsOnSelect(value, item, 'cidade')
            }
            onOpen={() => handleLocationsOnOpen(selectPlaceholder?.label)}
        />
    );

    const selectUF = (
        <LocalTypeSelectComponent
            filterIsEmpty={filterIsEmpty?.listaLocalizacao}
            placeholder={selectPlaceholder?.placeholder}
            cleanable={false}
            data={locationState.location?.uf?.list}
            value={locationState.location?.uf?.value}
            onSearch={(search) =>
                locationHandlers.handleSearchLocation(search, 'uf')
            }
            onSelect={(value, item) =>
                handleLocationsOnSelect(value, item, 'uf')
            }
            onOpen={() => handleLocationsOnOpen(selectPlaceholder?.label)}
        />
    );

    return (
        <div className={styles['select-pill-wrapper']}>
            <SelectPill
                id={
                    filterIsEmpty?.tipoLocalizacao
                        ? 'select-pill-is-empty'
                        : 'select-picker-pill'
                }
                placeholder="Escolha uma opção"
                name="first"
                cleanable={false}
                value={selectPlaceholder?.label}
                data={firstSelectData}
                onSelect={handleLocationTypeOnSelect}
                onOpen={handleLocationsTypeOnOpen}
                locale={{
                    noResultsText: 'Nenhum item encontrado',
                    searchPlaceholder: 'Buscar item',
                }}
            />
            {selectPlaceholder?.label !== '' ? (
                <>
                    <img
                        src={arrowRightIcon}
                        alt=""
                        className={styles['arrow-right-icon']}
                    />
                    {selectPlaceholder?.label === 'Cidade'
                        ? selectCidade
                        : null}
                    {selectPlaceholder?.label === 'UF' ? selectUF : null}
                    {selectPlaceholder?.label === 'Todo o território' ? (
                        <div className={styles['div-pill']}>
                            {selectPlaceholder?.label}
                        </div>
                    ) : null}
                </>
            ) : null}
        </div>
    );
};
