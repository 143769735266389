import React from 'react';
import {
    Modal, Button, FlexboxGrid, List, Col,
} from 'rsuite';

const ModalProdutosSemDetalhes = ({ show, close, produtos }) => (

    <Modal size="sm" show={show} onHide={close}>
        <Modal.Header>
            <Modal.Title>Produtos sem detalhes</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <FlexboxGrid>
                <FlexboxGrid.Item componentClass={Col} lg={24} md={24} sm={24} xs={24}>
                    <p>Os seguintes produtos estão fora da lista de produtos com detalhes disponíveis:</p>
                </FlexboxGrid.Item>
            </FlexboxGrid>
            <FlexboxGrid>
                <FlexboxGrid.Item componentClass={Col} lg={24} md={24} sm={24} xs={24}>
                    <List autoScroll>
                        {produtos?.map((item, index) => (
                            <List.Item key={item.label} index={index}>
                                {item.label}
                            </List.Item>
                        ))}
                    </List>
                </FlexboxGrid.Item>
            </FlexboxGrid>
        </Modal.Body>
        <Modal.Footer>
            <Button onClick={close} appearance="primary">
                Ok
            </Button>
        </Modal.Footer>
    </Modal>

);

export default ModalProdutosSemDetalhes;
