import { trackPromise } from 'react-promise-tracker';

import api from '../../../../utils/API';

export const getDashboardLockedStores = () =>
    trackPromise(
        api({
            url: `${process.env.REACT_APP_CLIENTE_URL}/lojas-bloqueadas/dashboard`,
            method: 'GET',
            expectToken: true,
        }).then((resp) => resp.data)
    );
