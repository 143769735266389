import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Button,
    Content,
} from 'rsuite';
import { LoadingMenu } from '../../../../components/LoadingSpinner';
import SelectPill from '../../../../components/SelectPill';
import { selectorCalculadoraPrevisaoDemanda } from '../../../../reducers/calculadoraPrevisaoDemanda';
import {
    clearFilters,
} from '../../../../reducers/calculadoraPrevisaoDemanda/filtros';
import {
    resetInputCalculos,
} from '../../../../reducers/calculadoraPrevisaoDemanda/inputCalculos';
import { useSelectChannel, useSelectLocal, useSelectProduct } from '../../hooks';
import { LocalFilter, ProductFilter, SalesChannel } from '../index';

import { filterTitle } from '../../utils';
import styles from './FiltersArea.module.scss';

export const FiltersArea = () => {
    const { filtros } = useSelector(selectorCalculadoraPrevisaoDemanda);
    const dispatch = useDispatch();

    const { productHandlers } = useSelectProduct();
    const { localHandlers } = useSelectLocal();
    const { canalHandlers } = useSelectChannel();

    const handleClearAllFilters = () => {
        dispatch(clearFilters());
        dispatch(resetInputCalculos());
    };

    return (
        <Content className={styles['filters-area-content']}>
            <div className={styles['inner-div']}>
                <div className={styles['title-container']}>
                    {filterTitle.map((title) => (
                        <div className={styles.title}>
                            <p>{title}</p>
                        </div>
                    ))}
                </div>
                <div>
                    <div className={styles['filter-selection-wrapper']}>
                        <ProductFilter
                            valueSecond={filtros.produtos.value[0]}
                            dataSecond={filtros.produtos.list}
                            productHandlers={productHandlers}
                        />

                    </div>
                    <div className={styles['filter-selection-wrapper']}>
                        <LocalFilter
                            localHandlers={localHandlers}
                            filters={filtros}
                        />
                    </div>
                    <div className={styles['filter-selection-wrapper']}>
                        <SalesChannel
                            filterData={filtros}
                            channelHandlers={canalHandlers}
                        />
                    </div>
                    <div className={styles['filter-selection-wrapper']}>
                        <SelectPill
                            type="select"
                            id="select-picker-pill"
                            placeholder="Selecione um concorrente"
                            name="produto-concorrente"
                            value={filtros.produtoConcorrente.value[0] || null}
                            data={filtros.produtoConcorrente.list}
                            onOpen={() => productHandlers.onSearchProducts('produtoConcorrente', '')}
                            onSearch={(value) => productHandlers.onSearchProducts('produtoConcorrente', value)}
                            onSelect={productHandlers.handleSelectCompetitorProduct}
                            onClean={productHandlers.handleClearCompetitorProducts}
                            locale={{
                                noResultsText: 'Nenhum item encontrado',
                                searchPlaceholder: 'Buscar item',
                            }}
                            renderMenu={(menu) => (
                                <span>
                                    <LoadingMenu area="scenario-filter" />
                                    {menu}
                                </span>
                            )}
                        />
                    </div>
                </div>
                <Button
                    id="clean-saved-scenario-filters"
                    className={styles['btn-clear-filters']}
                    onClick={handleClearAllFilters}
                >
                    Limpar filtros
                </Button>

            </div>
        </Content>
  );
};
