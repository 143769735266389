import { trackPromise } from 'react-promise-tracker';

import api from '../../../../utils/API';

export const getDashboardSLA = () => trackPromise(
    api({
        url: `${process.env.REACT_APP_CLIENTE_URL}/sla/dashboard`,
        method: 'GET',
        expectToken: true,
    }).then((resp) => resp.data),
);

export const getDetalhesSLA = (data) => trackPromise(
    api({
        url: `${process.env.REACT_APP_CLIENTE_URL}/sla/detalhe/dashboard`,
        method: 'POST',
        expectToken: true,
        data: JSON.stringify(data),
    }).then((resp) => resp.data),
);
