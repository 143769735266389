import React from 'react';
import { Nav, Icon } from 'rsuite';

export const CustomNav = ({ title, icon, itens, onSelect, activeKey }) => (
    <Nav
        activeKey={activeKey}
        vertical
        onSelect={onSelect}
        className="nav-filtro-dinamico"
    >
        <div className="nav-filtro-dinamico__title-div">
            <Icon icon={icon} />
            <p className="nav-filtro-dinamico__title">{title}</p>
        </div>
        {itens.map((item) => (
            <Nav.Item key={item.name} name={item.name} eventKey={item.name}>
                {item.title}
            </Nav.Item>
        ))}
    </Nav>
);
