import React from 'react';
import { Table } from 'rsuite';
import { TableProps } from 'rsuite/lib/Table';
import { BaseCell, GhostCheckPicker } from '../../../../../../../../components';
import { useEditRuleCompetitors } from '../../../hooks/useEditRuleCompetitors';
import { EditRuleCompetitorsApi } from '../EditCompetitors.types';
import styles from './EditCompetitorsModalTable.module.scss';

const { Column, HeaderCell, Cell, Pagination } = Table;

type RowData = EditRuleCompetitorsApi['rowData'];

export type EditCompetitorsModalTableProps = TableProps & {
    filters: ReturnType<typeof useEditRuleCompetitors>['table']['filters'];
    pagination: ReturnType<
        typeof useEditRuleCompetitors
    >['table']['pagination'];
};

export const EditCompetitorsModalTable = ({
    filters,
    pagination,
    ...table
}: EditCompetitorsModalTableProps) => {
    return (
        <div className={styles.wrapper}>
            <Table
                rowKey="storeId"
                headerHeight={46}
                rowHeight={48}
                className={styles.table}
                height={540}
                {...table}
            >
                {/* @ts-expect-error Property children does not exist on type 'IntrinsicAttributes & TableColumnProps' */}
                <Column verticalAlign="middle" sortable width={258}>
                    <HeaderCell>Loja</HeaderCell>
                    <Cell dataKey="storeId">
                        {({ storeId, storeName }: RowData) => (
                            <>
                                <BaseCell.MainContainer>
                                    {storeId}
                                </BaseCell.MainContainer>
                                <BaseCell.SubContainer>
                                    {storeName}
                                </BaseCell.SubContainer>
                            </>
                        )}
                    </Cell>
                </Column>
                {/* @ts-expect-error Property children does not exist on type 'IntrinsicAttributes & TableColumnProps' */}
                <Column verticalAlign="middle" flexGrow={1}>
                    <HeaderCell>Concorrentes</HeaderCell>
                    <Cell dataKey="competitors">
                        {({ storeId, competitors }: RowData) => (
                            <GhostCheckPicker
                                data={competitors}
                                value={filters.values[storeId]}
                                searchable={false}
                                cleanable={false}
                                onSelect={(_v, item) => {
                                    filters.select(storeId, item.value);
                                }}
                                invertedSelection
                            />
                        )}
                    </Cell>
                </Column>
            </Table>
            <Pagination className={styles.pagination} {...pagination} />
        </div>
    );
};
