import React from 'react';
import { Col, Content, FlexboxGrid } from 'rsuite';

const Header = ({ headerData, expired }) => {
    const formatDate = (data) => {
        if (data) {
            const [startYear, startMonth, startDay]
                = data?.data_inicio?.split('-');
            const [endYear, endMonth, endDay] = data?.data_fim?.split('-');

            return `${startDay}/${startMonth}/${startYear} ~ ${endDay}/${endMonth}/${endYear}`;
        }
    };

    return (
        <Content className="share-analyze-header share-content">
            <h4 className="content-title">InfoPanel</h4>
            <p className="subtitle">
                Um ótimo resumo para entender o contexto dos preços!
            </p>
            <div className="filter-wrapper">
                <h5 className="filter-title">Filtros</h5>
                <p className="filter-subtitle">
                    Os filtros utilizados para o compartilhamento estão
                    indicados abaixo e se aplicaram a todos os gráficos
                    exibidos.
                </p>
                <FlexboxGrid className="filter-items">
                    <FlexboxGrid.Item
                        componentClass={Col}
                        lg={4}
                        md={4}
                        sm={4}
                        xs={4}
                        className="data-content"
                    >
                        <div>Data</div>
                        <div
                            className={
                                expired ? 'expired-date' : 'filter-values'
                            }
                        >
                            {formatDate(headerData)}
                        </div>
                    </FlexboxGrid.Item>
                    {headerData?.rede !== null ? (
                        <FlexboxGrid.Item
                            componentClass={Col}
                            lg={4}
                            md={4}
                            sm={4}
                            xs={4}
                            className="data-content"
                        >
                            <div>Rede</div>
                            <div className="data-content-items-wrapper">
                                {headerData?.rede.map((item) => (
                                    <div className="filter-values">
                                        {item}
                                    </div>
                                ))}
                            </div>
                        </FlexboxGrid.Item>
                    ) : null}
                    {headerData?.tipo_loja !== null ? (
                        <FlexboxGrid.Item
                            componentClass={Col}
                            lg={4}
                            md={4}
                            sm={4}
                            xs={4}
                            className="data-content"
                        >
                            <div>Tipo de loja</div>
                            <div className="data-content-items-wrapper">
                                {headerData?.tipo_loja.map((item) => (
                                    <div className="filter-values">
                                        {item}
                                    </div>
                                ))}
                            </div>
                        </FlexboxGrid.Item>
                    ) : null}
                    {headerData?.uf !== null ? (
                        <FlexboxGrid.Item
                            componentClass={Col}
                            lg={4}
                            md={4}
                            sm={4}
                            xs={4}
                            className="data-content"
                        >
                            <div>Região</div>
                            <div className="data-content-items-wrapper">
                                {headerData?.uf.map((item) => (
                                    <div className="filter-values">
                                        {item}
                                    </div>
                                ))}
                            </div>
                        </FlexboxGrid.Item>
                    ) : null}
                    {headerData?.cidade !== null ? (
                        <FlexboxGrid.Item
                            componentClass={Col}
                            lg={4}
                            md={4}
                            sm={4}
                            xs={4}
                            className="data-content"
                        >
                            <div>Cidade</div>
                            <div className="data-content-items-wrapper">
                                {headerData?.cidade.map((item) => (
                                    <div className="filter-values">
                                        {item}
                                    </div>
                                ))}
                            </div>
                        </FlexboxGrid.Item>
                    ) : null}
                    {headerData?.fabricante !== null ? (
                        <FlexboxGrid.Item
                            componentClass={Col}
                            lg={4}
                            md={4}
                            sm={4}
                            xs={4}
                            className="data-content"
                        >
                            <div>Fabricante</div>
                            <div className="data-content-items-wrapper">
                                {headerData?.fabricante.map((item) => (
                                    <div className="filter-values">
                                        {item}
                                    </div>
                                ))}
                            </div>
                        </FlexboxGrid.Item>
                    ) : null}
                    {headerData?.tipo_produto !== null ? (
                        <FlexboxGrid.Item
                            componentClass={Col}
                            lg={4}
                            md={4}
                            sm={4}
                            xs={4}
                            className="data-content"
                        >
                            <div>Tipo de produto</div>
                            <div className="data-content-items-wrapper">
                                {headerData?.tipo_produto.map((item) => (
                                    <div className="filter-values">
                                        {item}
                                    </div>
                                ))}
                            </div>
                        </FlexboxGrid.Item>
                    ) : null}
                    {headerData?.secao !== null ? (
                        <FlexboxGrid.Item
                            componentClass={Col}
                            lg={4}
                            md={4}
                            sm={4}
                            xs={4}
                            className="data-content"
                        >
                            <div>Seção</div>
                            <div className="data-content-items-wrapper">
                                {headerData?.secao.map((item) => (
                                    <div className="filter-values">
                                        {item}
                                    </div>
                                ))}
                            </div>
                        </FlexboxGrid.Item>
                    ) : null}
                    {headerData?.categoria !== null ? (
                        <FlexboxGrid.Item
                            componentClass={Col}
                            lg={4}
                            md={4}
                            sm={4}
                            xs={4}
                            className="data-content"
                        >
                            <div>Categoria</div>
                            <div className="data-content-items-wrapper">
                                {headerData?.categoria.map((item) => (
                                    <div className="filter-values">
                                        {item}
                                    </div>
                                ))}
                            </div>
                        </FlexboxGrid.Item>
                    ) : null}
                    {headerData?.tipo_preco !== null ? (
                        <FlexboxGrid.Item
                            componentClass={Col}
                            lg={4}
                            md={4}
                            sm={4}
                            xs={4}
                            className="data-content"
                        >
                            <div>Tipo de preço</div>
                            <div className="data-content-items-wrapper">
                                {headerData?.tipo_preco.map((item) => (
                                    <div className="filter-values">
                                        {item}
                                    </div>
                                ))}
                            </div>
                        </FlexboxGrid.Item>
                    ) : null}
                    {headerData?.origem_preco !== null ? (
                        <FlexboxGrid.Item
                            componentClass={Col}
                            lg={4}
                            md={4}
                            sm={4}
                            xs={4}
                            className="data-content"
                        >
                            <div>Origem de preço</div>
                            <div className="data-content-items-wrapper">
                                {headerData?.origem_preco.map((item) => (
                                    <div className="filter-values">
                                        {item}
                                    </div>
                                ))}
                            </div>
                        </FlexboxGrid.Item>
                    ) : null}
                </FlexboxGrid>
            </div>
        </Content>
    );
};

export default Header;
