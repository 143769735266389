import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { DataItem } from '../../../@types/DataItem';
import { GerenciadorPrecos } from '../../../@types/GerenciadorPrecos';
import { RootState } from '../../../@types/RootState';
import { SegmentData } from '../../../components/FilterSection/services';

type Keys = GerenciadorPrecos.FilterKeys;

export type FiltersDataState = Partial<
    Record<Keys, DataItem[]> & {
        segmentos: SegmentData[];
    }
>;

const initialState: FiltersDataState = {};

const slice = createSlice({
    name: 'ipa.filtros.data',
    initialState,
    reducers: {
        SET_FILTER_DATA: (_, action: PayloadAction<FiltersDataState>) => {
            return action.payload;
        },
        SET_FILTER_DATA_BY_KEY: (
            state,
            action: PayloadAction<{
                key: Keys;
                data: Array<SegmentData | DataItem>;
            }>,
        ) => {
            return {
                ...state,
                [action.payload.key]: action.payload.data,
            };
        },
        RESET_FILTERS_DATA: () => initialState,
    },
});

const { SET_FILTER_DATA, RESET_FILTERS_DATA, SET_FILTER_DATA_BY_KEY } =
    slice.actions;

const selectorFiltersData = (state: RootState) => {
    return state.gerenciadorPrecosReducer.filters.data;
};

export {
    RESET_FILTERS_DATA,
    SET_FILTER_DATA,
    SET_FILTER_DATA_BY_KEY,
    initialState,
    selectorFiltersData,
};

export default slice.reducer;
