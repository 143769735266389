import React, { useEffect, useState } from 'react';
import {
    Container, Content, FlexboxGrid, Button, Form, FormGroup, ControlLabel, FormControl, ButtonToolbar, Alert, Col, HelpBlock,
} from 'rsuite';
import { withRouter, Link } from 'react-router-dom';

import { getLinkResetSenha } from '../../services/UsuarioService';
import { HomeCarouselComponent } from '../../components/HomeCarouselComponent';

const RecuperarSenha = () => {
    const [email, setEmail] = useState('');
    const [errors, setErrors] = useState({});

    const validInput = () => {
        const errors = {};
        let formIsValid = true;

        if (!email.length) {
            formIsValid = false;
            errors.email = 'O e-mail é obrigatório';
            const email = document.getElementsByName('email')[0];
            if (email) {
                email.classList.add('invalid-input');
            }
        } else {
            const email = document.getElementsByName('email')[0];
            if (email) {
                email.classList.remove('invalid-input');
            }
        }
        setErrors(errors);
        return formIsValid;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validInput()) {
            getLinkResetSenha(email).then(() => {
                Alert.success('Um e-mail foi enviado. Por favor clique no link presente no corpo do e-mail', 5000);
            });
        }
    };

    useEffect(() => {
        if (email) { validInput(); }
    }, [email]);

    return (
        <div className="recuperar-senha-page">
            <Container>
                <FlexboxGrid>
                    <Col md={10} lg={10} xsHidden smHidden>
                        <Content>
                            <HomeCarouselComponent />
                        </Content>
                    </Col>
                    <Col md={14} lg={14} xs={24} className="form-area">
                        <Content className="recovery-form">
                            <h4>Recuperar minha senha</h4>
                            <div style={{ marginBottom: '35px', fontWeight: '600' }}>Não se preocupe, acontece com todos :)</div>
                            <Form fluid onSubmit={(e) => handleSubmit(e)}>
                                <FormGroup>
                                    <ControlLabel>E-mail da sua conta InfoPrice</ControlLabel>
                                    <FormControl
                                        id="email"
                                        placeholder="Digite seu e-mail"
                                        name="email"
                                        value={email}
                                        onChange={(value) => setEmail(value)}
                                        type="email"
                                    />
                                    <HelpBlock className="invalid-field" name="email">{errors.email}</HelpBlock>
                                </FormGroup>

                                <FormGroup>
                                    <ButtonToolbar>
                                        <Button block type="submit" appearance="primary">recuperar minha senha</Button>
                                    </ButtonToolbar>
                                </FormGroup>
                                <div className="link-login">
                                    <Link to={{ pathname: '/login' }}>Fazer login </Link>
                                </div>
                            </Form>

                        </Content>
                    </Col>
                </FlexboxGrid>
            </Container>
        </div>
    );
};

export default withRouter(RecuperarSenha);
