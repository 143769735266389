import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    Col, Button, AutoComplete, Alert, FlexboxGrid, Icon, FormGroup, ControlLabel,
} from 'rsuite';

import countryIconWhite from '../../../../../assets/icons/icon_country-white.svg';
import countryIconBlue from '../../../../../assets/icons/icon_country-blue.svg';
import stateIconWhite from '../../../../../assets/icons/icon_state-white.svg';
import stateIconBlue from '../../../../../assets/icons/icon_state-blue.svg';
import cityIconWhite from '../../../../../assets/icons/icon_city-white.svg';
import cityIconBlue from '../../../../../assets/icons/icon_city-blue.svg';

import {
    setAccountData, getRegionAutocompleteOptions, getEnoughData, getCity, setEnoughDataNull,
} from '../../../../../actions/actionsCreateAccountFreemium';
import { addUserToDataUpdate } from '../services';
import { setUserAccoundModel } from '../utils';

class DataGroupForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            regionValue: '',
        };

        this.setDataAlarm = this.setDataAlarm.bind(this);
        this.clearRegionValueData = this.clearRegionValueData.bind(this);
    }

    setDataAlarm() {
        const { account } = this.props;

        addUserToDataUpdate(setUserAccoundModel(account)).then((response) => {
            if (response) {
                Alert.success('Sua solicitação foi cadastrada com sucesso.', 5000);
            }
        });
    }

    setRegion(value, type) {
        const { setAccountData, getRegionAutocompleteOptions, setEnoughDataNull } = this.props;

        this.clearRegionValueData();
        setEnoughDataNull();

        if (value === 'state' || value === 'city') {
            getRegionAutocompleteOptions(value);
        }

        setAccountData(value, type);
    }

    getDataQuantity() {
        const { account, getEnoughData } = this.props;

        if (account.channel && account.region && (account.state || account.city)) {
            const model = {
                canal: account.channel,
                agrupamentoPais: account.region === 'country',
                estado: account.region === 'state' ? { value: account.state } : null,
                cidadeId: account.region === 'city' ? account.city : null,
            };

            getEnoughData(model);
        }
    }

    clearRegionValueData() {
        this.setState({
            regionValue: '',
        });
    }

    async changeAutocompleteValue(item, key) {
        const { setAccountData } = this.props;
        await setAccountData(key === 'state' ? item.name : item.codigo, key);

        this.getDataQuantity();
    }

    searchCity(query) {
        const { account, getCity } = this.props;

        if (account.region === 'city') {
            getCity(query);
        }

        this.setState({
            regionValue: query,
        });
    }

    render() {
        const {
            account, states, cities, hasEnoughData,
        } = this.props;

        const { regionValue } = this.state;

        return (
            <>
                <h4 id="stage2" className="title">Falta pouco!</h4>
                <div className="subtitle">
                    <p>O agrupamento de dados é a forma como vamos sintetizar e exibir os dados da sua pesquisa.</p>
                </div>

                <FlexboxGrid align="middle">
                    <Col xs={24} md={16}>
                        <div className="region-selection">
                            <p className="region-selection__label">Escolha sua forma de agrupamento de dados</p>
                            <div>
                                <Button
                                    appearance={account.region === 'country' ? 'primary' : 'ghost'}
                                    className="region-selection__btn-option"
                                    onClick={() => this.setRegion('country', 'region')}
                                >
                                    <img alt="País" src={account.region === 'country' ? countryIconWhite : countryIconBlue} />
                                    <p>País</p>
                                </Button>
                                <Button
                                    appearance={account.region === 'state' ? 'primary' : 'ghost'}
                                    className="region-selection__btn-option"
                                    onClick={() => this.setRegion('state', 'region')}
                                >
                                    <img alt="Estado" src={account.region === 'state' ? stateIconWhite : stateIconBlue} />
                                    <p>Estado</p>
                                </Button>
                                <Button
                                    appearance={account.region === 'city' ? 'primary' : 'ghost'}
                                    className="region-selection__btn-option"
                                    onClick={() => this.setRegion('city', 'region')}
                                >
                                    <img alt="Cidade" src={account.region === 'city' ? cityIconWhite : cityIconBlue} />
                                    <p>Cidade</p>
                                </Button>
                            </div>
                        </div>
                    </Col>
                    <Col md={8} xsHidden>
                        <div className="update-warning-box">
                            <span className="update-warning-box__icon-area">
                                <Icon icon="exclamation-triangle" />
                            </span>
                            <span className="update-warning-box__description-area">
                                <p><b>Atenção!</b></p>
                                <p>
                                    Você poderá realizar
                                    {' '}
                                    <b>UMA</b>
                                    {' '}
                                    alteração de agrupamento de dados
                                    {' '}
                                    <b>POR MÊS</b>
                                    .
                                </p>
                            </span>
                        </div>
                    </Col>
                    {account.region.length && account.region !== 'country'
                        ? (
                            <Col xs={24} md={14} className="subregion-area">
                                <FormGroup>
                                    {account.region === 'state'
                                        ? (
                                            <ControlLabel className="subregion-area__label">Digite um estado*</ControlLabel>
                                        )
                                        : (
                                            <ControlLabel className="subregion-area__label">Digite uma cidade*</ControlLabel>

                                        )}
                                    <AutoComplete
                                        menuClassName="subregion-area__menu"
                                        className={`subregion-area__input ${!regionValue ? 'empty-value' : ''}`}
                                        data={account.region === 'state' ? states : cities}
                                        name={account.region === 'state' ? 'state' : 'city'}
                                        id="autocomplete"
                                        autoComplete="off"
                                        value={regionValue}
                                        onSelect={(item) => this.changeAutocompleteValue(item, account.region === 'state' ? 'state' : 'city')}
                                        onChange={(value) => this.searchCity(value)}
                                    />
                                </FormGroup>
                            </Col>
                        ) : null}
                    {account.region.length
                        ? (
                            <Col xs={24} md={14} className="description-area">
                                {account.region === 'country'
                                    ? (
                                        <p className="description-area__message">
                                            Ao selecionar o agrupamento de dados por
                                            {' '}
                                            <b>País</b>
                                            {' '}
                                            você vai visualizar uma
                                            {' '}
                                            <b>única média</b>
                                            {' '}
                                            de preços do Brasil.
                                        </p>
                                    )
                                    : account.region === 'state'
                                        ? (
                                            <p className="description-area__message">
                                                Ao selecionar o agrupamento de dados por
                                                {' '}
                                                <b>Estado</b>
                                                {' '}
                                                você vai visualizar uma
                                                {' '}
                                                <b>única média</b>
                                                {' '}
                                                de preços do estado selecionado.
                                            </p>
                                        )
                                        : (
                                            <p className="description-area__message">
                                                Ao selecionar o agrupamento de dados por
                                                {' '}
                                                <b>Cidade</b>
                                                {' '}
                                                você vai visualizar uma
                                                {' '}
                                                <b>única média</b>
                                                {' '}
                                                de preços da cidade selecionada.
                                            </p>
                                        )}
                            </Col>
                        )
                        : null}
                    {hasEnoughData === false ? (
                        <Col xs={24} md={14} className="no-data-warning">
                            <p
                                className="no-data-warning__message"
                            >
                                {account.region === 'state'
                                    ? 'O estado selecionada possui poucos dados. Por favor selecione outra estado ou outra forma de agrupamento.'
                                    : 'A cidade selecionada possui poucos dados. Por favor selecione outra cidade ou outra forma de agrupamento.'}
                            </p>
                            <button
                                onClick={this.setDataAlarm}
                                className="no-data-warning__link"
                                type="button"
                            >
                                Clique aqui para ser informado quando houver mais dados disponíveis

                            </button>
                        </Col>
                    ) : null}
                </FlexboxGrid>
            </>
        );
    }
}

const mapStateToProps = (store) => ({
    account: store.accountFreemiumDataReducer.account,
    stage: store.accountFreemiumDataReducer.stage,
    states: store.accountFreemiumDataReducer.states,
    cities: store.accountFreemiumDataReducer.cities,
    hasEnoughData: store.accountFreemiumDataReducer.hasEnoughData,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    setAccountData,
    getRegionAutocompleteOptions,
    getEnoughData,
    getCity,
    setEnoughDataNull,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DataGroupForm);
