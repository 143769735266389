import React, { forwardRef } from 'react';

export type CardFooterProps = React.HtmlHTMLAttributes<HTMLDivElement>;

export const CardFooter = forwardRef(
    (
        { children, ...props }: CardFooterProps,
        ref: React.LegacyRef<HTMLDivElement>,
    ) => (
        <div ref={ref} {...props}>
            {children}
        </div>
    ),
);
