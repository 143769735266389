import logoInfopanel from '../assets/logo/logo-ISA-infopanel-branco.svg';
import logoMPDV from '../assets/logo/logo-ISA-MPDV-branco.svg';

export const productsNav = [
    {
        label: 'ISA | Infopanel',
        value: 'infopanel',
        link: '/isa/painel-geral',
        key: '4',
        logo: logoInfopanel,
    },
    {
        label: 'ISA | Monitoramento em PDV',
        value: 'mpdv',
        link: '/isa/gestao-retorno',
        key: '1',
        logo: logoMPDV,
    },
    {
        label: 'IPA | Software de Precificação',
        value: 'ipa',
        link: null,
        key: '0',
        logo: null,
    },
];

export const sidebarNav = {
    infopanel: [
        {
            label: 'InfoPanel',
            value: 'infopanel',
            disabled: false,
            link: '/isa/painel-geral',
            icon: 'logo-analytics',
            key: '1',
        },
        {
            label: 'On Demand',
            value: 'ondemand',
            disabled: true,
            link: '',
            icon: 'retention',
            key: '2',
        },
    ],
    mpdv: [
        {
            label: 'Histórico de preço',
            value: 'historico',
            disabled: true,
            link: '',
            icon: 'line-chart',
            key: '1',
        },
        {
            label: 'Download de relatório',
            value: 'download',
            disabled: true,
            link: '',
            icon: 'file-download',
            key: '2',
        },
        {
            label: 'Extração de dados',
            value: 'extracao',
            disabled: true,
            link: '',
            icon: 'cloud-download',
            key: '3',
        },
        {
            label: 'Gestão de retorno',
            value: 'gestao',
            disabled: false,
            link: '/isa/gestao-retorno',
            icon: 'attribution',
            key: '4',
        },
        {
            label: 'Acompanhamento de coletas',
            value: 'coletas',
            disabled: false,
            link: '/isa/acompanhamento-coletas',
            icon: 'clock-o',
            key: '5',
        },
        {
            label: 'Revisão de lista',
            value: 'revisao',
            disabled: true,
            link: '',
            icon: 'views-authorize',
            key: '6',
        },
        {
            label: 'Lojas bloqueadas',
            value: 'lojasBloqueadas',
            disabled: false,
            link: '/isa/lojas-bloqueadas',
            icon: 'views-unauthorize',
            key: '7',
        },
    ],
};
