import React from 'react';

type Count = number;

type CountActions =
    | {
          type: 'reset';
      }
    | {
          type: 'increment';
      }
    | {
          type: 'decrement';
      }
    | {
          type: 'set';
          payload: number;
      };

const initialData: Count = 0;

const reducer = (state: Count, action: CountActions): Count => {
    switch (action.type) {
        case 'reset':
            return initialData;
        case 'increment':
            return state + 1;
        case 'decrement':
            return state - 1;
        case 'set':
            return action.payload;
        default:
            return state;
    }
};

type UseCountProps = {
    initialData: Count;
};

export const useCount = ({ initialData = 0 }: UseCountProps) => {
    const [count, dispatch] = React.useReducer(reducer, initialData);

    const increment = () => dispatch({ type: 'increment' });

    const reset = () => dispatch({ type: 'reset' });

    const decrement = () => dispatch({ type: 'decrement' });

    const set = (number: number) => dispatch({ type: 'set', payload: number });

    return [
        count,
        {
            increment,
            decrement,
            reset,
            set,
        },
    ] as const;
};
