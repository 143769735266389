import { BoxBoldText } from './BoxBoldText';
import { BoxIndicator } from './BoxIndicator';
import { BoxMainValue } from './BoxMainValue';
import { BoxRegularText } from './BoxRegularText';
import { BoxRoot } from './BoxRoot';

export const BigNumbersBox = {
    Root: BoxRoot,
    MainValue: BoxMainValue,
    RegularText: BoxRegularText,
    BoldText: BoxBoldText,
    Indicator: BoxIndicator,
};
