import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectorCalculadoraPrevisaoDemanda } from '../../../../reducers/calculadoraPrevisaoDemanda';
import styles from './ProductElasticity.module.scss';

export interface IProductElasticity{
    className?: string;
}

export const ProductElasticity = ({ className }: IProductElasticity) => {
    const { resultScenario } = useSelector(selectorCalculadoraPrevisaoDemanda);
    const { outputs } = resultScenario.resultScenarioData;

    const formatElasticityValue = outputs.elasticity * -1;
    const elasticityValue = (formatElasticityValue < 0 || formatElasticityValue === null) ? 0 : formatElasticityValue;

    const graficoData = {
        exporting: {
            enabled: false,
        },
        chart: {
            type: 'gauge',
            backgroundColor: 'transparent',
            height: 210,
        },
        title: {
            text: '',
        },
        pane: {
            startAngle: -90,
            endAngle: 89.9,
            background: null,
            center: ['50%', '75%'],
            size: '100%',
        },
        plotOptions: {
            gauge: {
                wrap: false,
            },
        },
        yAxis: {
            min: 0,
            max: 4,
            tickInterval: 5,
            tickLength: 125,
            tickWidth: 0,
            minorTickInterval: null,
            labels: {
                distance: 16,
                style: {
                    display: 'none',
                },
                formatter() {
                    const label = this.axis.defaultLabelFormatter.call(this);
                    if (label % 2 === 1) {
                        return `${label}%`;
                    }
                },
            },
            plotBands: [
                {
                    from: 0,
                    to: 1,
                    color: '#fff',
                    thickness: 20,
                },
                {
                    from: 1,
                    to: 2,
                    color: '#CCE9FA',
                    thickness: 20,
                },
                {
                    from: 2,
                    to: 3,
                    color: '#559FF2',
                    thickness: 20,
                },
                {
                    from: 3,
                    to: 4,
                    color: '#0D66D0',
                    thickness: 20,
                },
            ],
        },
        tooltip: {
            enabled: false,
        },
        series: [
            {
                data: [elasticityValue],
                dataLabels: {
                    y: 100,
                    borderWidth: 0,
                    color: '#323232',
                    style: {
                        fontSize: '24px',
                        fontWeight: '600',
                        fontFamily: 'Open Sans',
                        fontStyle: 'normal',
                    },
                },
                dial: {
                    radius: '65%',
                    backgroundColor: '#505050',
                    baseWidth: 12,
                    baseLength: '0%',
                    rearLength: '0%',
                },
                pivot: {
                    backgroundColor: '#505050',
                    radius: 6,
                },
            },
        ],
    };

    const isElastic = (data: number) => {
        switch (true) {
            case data > 0 && data <= 1:
                return 'Esse produto é Inelástico';
            case data > 1 && data <= 2:
                return 'Esse produto é Pouco elástico';
            case data > 2 && data <= 3:
                return 'Esse produto é Elástico';
            case data > 3:
                return 'Esse produto é Muito elástico';
            default:
                break;
        }
    };

    return (
        <div className={`${styles['product-elasticity-box']} ${className}`}>
            <p className={styles.title}>
                Elasticidade do produto
            </p>
            <div className={styles['gauge-chart-container']}>
                <HighchartsReact
                    highcharts={Highcharts}
                    options={graficoData}
                />
            </div>
            <div className={styles['bottom-text-wrapper']}>
                <p className={styles['main-text']}>{isElastic(elasticityValue)}</p>
                <p className={styles['regular-text']}>
                    Pequenas variações de preços impactam nas vendas do produto
                </p>
            </div>
        </div>
    );
};
