import React, { memo } from 'react';
import { withRouter } from 'react-router-dom';
import { Layout } from '../../../../../components';
import {
    PrecoAtacadoFilters,
    PrecoAtacadoHeader,
    PrecoAtacadoTable,
    PrecoAtacadoTableHeading,
} from '../../components';
import PrecoAtacadoModalsWrapper from '../../components/Modals/Modals';

const Header = memo(PrecoAtacadoHeader);
const TableHeading = memo(PrecoAtacadoTableHeading);
const Filters = memo(PrecoAtacadoFilters);
const Table = memo(PrecoAtacadoTable);
const Modals = memo(PrecoAtacadoModalsWrapper);

const PrecoAtacado = () => {
    return (
        <>
            <Layout>
                <Header />
                <Layout.Body>
                    <Filters />
                    <TableHeading />
                    <Table />
                </Layout.Body>
            </Layout>
            <Modals />
        </>
    );
};

export default withRouter(PrecoAtacado);
