import { trackPromise } from 'react-promise-tracker';
import qs from 'qs';
import api from '../../../utils/API';

export const getProdutos = (params) => trackPromise(
        api({
            url: `${process.env.REACT_APP_IPA_URL}/products`,
            expectToken: true,
            method: 'GET',
            params: {
                // clientId: localStorage.getItem('autenticacao.clientId'),
                name: JSON.parse(localStorage.getItem('cliente'))?.nome,
                page: params.page ? params.page : 0,
                size: params.size ? params.size : 20,
                categoryLevel1: params.categoryLevel1
                    ? params.categoryLevel1
                    : null,
                categoryLevel2: params.categoryLevel2
                    ? params.categoryLevel2
                    : null,
                categoryLevel3: params.categoryLevel3
                    ? params.categoryLevel3
                    : null,
                categoryLevel4: params.categoryLevel4
                    ? params.categoryLevel4
                    : null,
                categoryLevel5: params.categoryLevel5
                    ? params.categoryLevel5
                    : null,
                categoryLevel6: params.categoryLevel6
                    ? params.categoryLevel6
                    : null,
                categoryLevel7: params.categoryLevel7
                    ? params.categoryLevel7
                    : null,
                description: params.description ? params.description : null,
                productId: params.productId ? params.productId : null,
                gtin: params.gtin ? params.gtin : null,
                abcClass: params.abcClass ? params.abcClass : null,
                productFamilyIds: params.productFamilyIds ? params.productFamilyIds : null,
            },
            paramsSerializer(params) {
                return qs.stringify(params, { arrayFormat: 'repeat' });
            },
        }).then((resp) => resp.data),
    );

export const searchFiltroProdutos = (params) => api({
        url: `${process.env.REACT_APP_IPA_URL}/products/search`,
        method: 'GET',
        expectToken: true,
        params: {
            clientId: JSON.parse(localStorage.getItem('cliente'))?.codigo,
            name: JSON.parse(localStorage.getItem('cliente'))?.nome,
            size: params.size ? params.size : 20,
            query: params.query ? params.query : '',
        },
    }).then((resp) => resp.data);
