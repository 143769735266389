import classNames from 'classnames';
import React from 'react';
import { Modal } from 'rsuite';
import { ModalProps } from 'rsuite/lib/Modal';
import { User } from '../../@types';
import gif from '../../assets/image/mpdv-banner-lead.gif';
import { ReactComponent as Logo } from '../../assets/logo/logo-ISA-MPDV-branco.svg';
import { sendLead } from '../../pages/ISA/InfoPanel/InfoPanel/utils';
import { trackEvent } from '../../utils/MatomoConfig';
import styles from './ModalMPDVLead.module.scss';

type ModalMPDVLeadProps = ModalProps & {
    user?: User;
};

const ModalMPDVLead = ({ user, className, ...props }: ModalMPDVLeadProps) => {
    const handleClick = async (e: React.MouseEvent<Element, MouseEvent>) => {
        // eslint-disable-next-line no-alert
        const confirm = window.confirm(
            'Deseja entrar em contato com a nossa equipe?',
        );
        if (!confirm) return;
        await sendLead(user, 'Monitoramento de concorrentes');
        trackEvent('Upsell/Crossell', 'crossell', 'Monitoramento de concorrentes');
        window.open(
            'https://wa.me/551137779670?text=Ol%C3%A1%21+Quero+saber+mais+sobre+o+ISA+-+Monitoramento+PDV',
        );
        props.onHide?.(e);
    };

    return (
        <Modal className={classNames(styles.modal, className)} {...props}>
            <Modal.Body className={styles.modal__content}>
                <Logo className={styles.logo} />
                <h2 className={styles.title}>
                    Potencialize seu processo de
                    <strong> monitoramento de concorrentes </strong>
                    com o Monitoramento PDV
                </h2>
                <div className={styles.img__container}>
                    <img
                        src={gif}
                        alt="Imagem animada demonstrando o funcionamento da aplicação"
                    />
                    <span>
                        Garanta agilidade na coleta e nas decisões do seu negócio com tecnologia e treinamento para o seu time
                    </span>
                </div>
                <ul>
                    <li>
                        Hardware SmartPrice que coleta dados de preço no PDV
                    </li>
                    <li>Tratamento dos dados coletados que garantem confiabilidade</li>
                    <li>
                        Treinamento e padronização de processos da coleta de preços
                    </li>
                    <li>
                        Acompanhamento das coletas, revisão de listas e avaliação de retorno no painel
                    </li>
                </ul>
                <button onClick={handleClick}>CONHEÇA O MONITORAMENTO PDV</button>
            </Modal.Body>
        </Modal>
    );
};

export default ModalMPDVLead;
