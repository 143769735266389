import { format, parseISO } from 'date-fns';
import React, { useCallback, useMemo, useState } from 'react';
import CurrencyFormat from 'react-currency-format';
import { useDispatch, useSelector } from 'react-redux';
import { CompetitorPriceDTO } from '../../../../../@types';
import { GerenciadorPrecos } from '../../../../../@types/GerenciadorPrecos';
import { Competitors, PhotoModal } from '../../../../../components';
import { IPAMaths } from '../../../../../lib';
import {
    ADD_DISABLED_COMPETITOR,
    REMOVE_DISABLED_COMPETITOR,
    selectorDisabledCompetitors,
} from '../../../../../reducers/gerenciadorPrecos/disabledCompetitors';
import { findDifference } from '../../../../../utils/findArrayDifference';
import { CompetitorPriceDTOProps } from '../../../Negociacoes';

const ORIGIN_PRICE = {
    'INFOPRICE - INFOPANEL': 'INFOPANEL',
    'INFOPRICE - MPDV': 'MPDV',
} as const;

type RowData = GerenciadorPrecos.RowData;

type ExpandedRowDtoListProps = {
    rowData: RowData;
};

const ExpandedRowDtoList = ({ rowData }: ExpandedRowDtoListProps) => {
    const dispatch = useDispatch();

    const disabledCompetitors = useSelector(selectorDisabledCompetitors);

    const [photoModal, setPhotoModal] =
        useState<CompetitorPriceDTO['photo']>(null);

    const showModal = useMemo(() => {
        return !!photoModal;
    }, [photoModal]);

    const competitorPriceDTOList = useMemo(
        () => rowData.competitorsInfo?.competitorPriceDTO,
        [rowData?.competitorsInfo],
    );

    const handleToggleOn = useCallback(
        async (rowData: RowData, competitor: CompetitorPriceDTO) => {
            const { productsToBePricedId } = rowData;
            const { competitorStoreId } = competitor;
            const payload = { productsToBePricedId, competitorStoreId };
            dispatch(REMOVE_DISABLED_COMPETITOR(payload));
        },
        [dispatch],
    );

    const handleToggleOff = useCallback(
        async (rowData: RowData, competitor: CompetitorPriceDTO) => {
            const { competitorStoreId } = competitor;
            const { productsToBePricedId } = rowData;
            const payload = { productsToBePricedId, competitorStoreId };
            dispatch(ADD_DISABLED_COMPETITOR(payload));
        },
        [dispatch, rowData.productsToBePricedId],
    );

    const handleToggleCompetitor = useCallback(
        (
            checked: boolean,
            rowData: RowData,
            competitor: CompetitorPriceDTO,
        ) => {
            if (checked) handleToggleOn(rowData, competitor);
            else handleToggleOff(rowData, competitor);
        },
        [handleToggleOn, handleToggleOff],
    );

    const handleHidePhotoModal = useCallback(() => {
        setPhotoModal(null);
    }, []);

    const handleClickPhotoModal = useCallback(
        (photo?: CompetitorPriceDTO['photo']) => {
            if (!photo) return;
            if (showModal) handleHidePhotoModal();
            else setPhotoModal(photo);
        },
        [showModal, handleHidePhotoModal],
    );

    const isChecked = useCallback(
        (competitorStoreId: string) => {
            return !disabledCompetitors?.[rowData.productsToBePricedId]?.some(
                (item) => item === competitorStoreId,
            );
        },
        [disabledCompetitors, rowData.productsToBePricedId],
    );

    const isDisabled = useCallback(
        (
            competitorsList?: typeof competitorPriceDTOList,
            competitorStoreId?: CompetitorPriceDTOProps['competitorStoreId'],
        ) => {
            let isDisabled = false;

            const allIds =
                competitorsList?.map((item) => item.competitorStoreId) ?? [];

            const disabledList =
                disabledCompetitors[rowData.productsToBePricedId] ?? [];

            const activeList = findDifference(allIds, disabledList);

            if (
                activeList.length === 1 &&
                activeList[0] === competitorStoreId
            ) {
                isDisabled = true;
            }

            return isDisabled;
        },
        [rowData.productsToBePricedId, disabledCompetitors],
    );

    return (
        <>
            {competitorPriceDTOList?.map((competitor) => {
                return (
                    <Competitors.Card key={competitor.competitorStoreId}>
                        <Competitors.CardHeader>
                            <Competitors.Title>
                                {competitor.competitorStore}
                            </Competitors.Title>
                            <Competitors.Toggle
                                checked={isChecked(
                                    competitor.competitorStoreId,
                                )}
                                onChange={(checked) => {
                                    handleToggleCompetitor(
                                        checked,
                                        rowData,
                                        competitor,
                                    );
                                }}
                                disabled={isDisabled(
                                    competitorPriceDTOList,
                                    competitor.competitorStoreId,
                                )}
                            />
                            <Competitors.CardHeaderAction>
                                <Competitors.Tag>
                                    {ORIGIN_PRICE[competitor.origemPreco]}
                                </Competitors.Tag>
                                <Competitors.Label>
                                    {competitor.competitorStoreType}
                                </Competitors.Label>
                                {competitor.photo && (
                                    <Competitors.PhotoIcon
                                        onClick={() => {
                                            handleClickPhotoModal(
                                                competitor.photo,
                                            );
                                        }}
                                    />
                                )}
                            </Competitors.CardHeaderAction>
                        </Competitors.CardHeader>

                        <Competitors.CardContent>
                            <Competitors.InfoBox>
                                <Competitors.Label>Preço</Competitors.Label>
                                <Competitors.Value>
                                    <CurrencyFormat
                                        fixedDecimalScale
                                        decimalScale={2}
                                        value={competitor?.retailPrice ?? 0}
                                        displayType="text"
                                        prefix="R$"
                                        decimalSeparator=","
                                        thousandSeparator="."
                                    />
                                </Competitors.Value>
                            </Competitors.InfoBox>

                            <Competitors.InfoBox>
                                <Competitors.Label>
                                    Competitividade
                                </Competitors.Label>
                                <Competitors.Value>
                                    <CurrencyFormat
                                        fixedDecimalScale
                                        decimalScale={2}
                                        value={IPAMaths.novaCompetitividade(
                                            rowData.retailPrice ?? 0,
                                            competitor.retailPrice ?? 0,
                                        )}
                                        displayType="text"
                                        suffix="%"
                                        decimalSeparator=","
                                        thousandSeparator="."
                                    />
                                </Competitors.Value>
                            </Competitors.InfoBox>
                            <Competitors.InfoBox>
                                <Competitors.Label>Data</Competitors.Label>
                                <Competitors.Value>
                                    {competitor.date
                                        ? format(
                                              parseISO(competitor.date),
                                              'dd/MM/yyyy',
                                          )
                                        : '--'}
                                </Competitors.Value>
                            </Competitors.InfoBox>
                        </Competitors.CardContent>
                    </Competitors.Card>
                );
            })}
            <PhotoModal
                show={showModal}
                onHide={handleHidePhotoModal}
                photo={photoModal}
            />
        </>
    );
};

export default ExpandedRowDtoList;
