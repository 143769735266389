import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useState } from 'react';
import { checkIfIsLoading } from '../../../../utils';
import { usePagination, useSort } from '../../Negociacoes';
import {
    IMargemObjetivaTable,
    IMargemObjetivaTableRowKey,
} from '../components';
import {
    IGetMargemObjetivaResponseItem,
    getMargemObjetivaTableData,
    patchMargemObjetivaEditDatapoint,
} from '../pages/MargemObjetiva/services';

type IUseMargemObjetivaTable = {
    filters?: Partial<Record<string, string[]>>;
    segment: {
        name: string;
        level: number;
        field: string;
    };
};

const rowKey: IMargemObjetivaTableRowKey = 'id';

export const useMargemObjetivaTable = ({
    filters = {},
    segment,
}: IUseMargemObjetivaTable): IMargemObjetivaTable & {
    total: number;
} => {
    const [content, setContent] = useState<IGetMargemObjetivaResponseItem[]>(
        [],
    );
    const [total, setTotal] = useState<number>(0);

    const [sort, { onSort }, sortModel] = useSort<IMargemObjetivaTableRowKey>({
        type: 'categoryName',
        orderBy: 'asc',
    });

    const pagination = usePagination({ total });

    const { fetchStatus } = useQuery({
        queryKey: [
            'ipa/gerenciador/margem-objetiva/table-data',
            filters,
            sort,
            pagination.activePage,
            pagination.displayLength,
        ],
        queryFn: () => {
            return getMargemObjetivaTableData({
                filters,
                sort: sortModel,
                page: pagination.activePage,
                size: pagination.displayLength,
            });
        },
        onSuccess(data) {
            if (!data) return;
            setTotal(data.totalElements);
            setContent(data.content);
        },
        retry: false,
    });

    const onChangeObjectiveMargin = (value: number, index: number) => {
        if (!content[index]) return;

        setContent((oldValue) => {
            const updatedList = [...oldValue];
            updatedList[index].objectiveMargin = value;
            return updatedList;
        });
    };

    const onBlurObjectiveMargin = async (_value: number, index: number) => {
        if (!content[index]) return;
        try {
            const res = await patchMargemObjetivaEditDatapoint({
                ...content[index],
                categoryLevel: segment.level,
            });
            if (res) {
                setContent((oldValue) => {
                    const updatedList = [...oldValue];
                    updatedList[index].updatedAt = res.updatedAt;
                    updatedList[index].editedBy = res.editedBy;
                    return updatedList;
                });
            }
        } catch (err) {
            if (axios.isAxiosError(err)) {
                throw new Error(err.message);
            }
        }
    };

    return {
        rowKey,
        data: content,
        loading: checkIfIsLoading(fetchStatus),
        sortType: sort.orderBy,
        sortColumn: sort.type,
        pagination,
        total,
        onSortColumn: (dataKey, sortType) => {
            onSort(dataKey as IMargemObjetivaTableRowKey, sortType);
        },
        onChangeObjectiveMargin,
        onBlurObjectiveMargin,
    };
};
