import React from 'react';
import {
 Modal, Button, FlexboxGrid, Table, Content, Container,
} from 'rsuite';
import CurrencyFormat from 'react-currency-format';

const { Column, HeaderCell, Cell, Pagination } = Table;

const ModalConfirmacaoFamilia = ({
    isOpen,
    onClose,
    saveProduto,
    saveFamilia,
    tabela,
    loja,
    onChangePage,
}) => (
    <Modal
        style={{ width: '992px', height: '646px' }}
        show={isOpen}
        onHide={onClose}
        className="modal-family-loja-confirmacao"
    >
        <Container className="modal-family-loja-confirmacao">
            <Modal.Header>
                <Modal.Title>Aplicar preço na família no cluster</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Content>
                    <FlexboxGrid.Item>
                        <span>
                            Você alterou o preço do produto
                            {' '}
                            <strong>
                                {loja.productId}
                                {' '}
                                -
                                {' '}
                                {loja.description}
                            </strong>
                            {' '}
                            na loja
                            {' '}
                            {loja.storeName}
                            . O novo
                            preço foi aplicado nos demais itens da família
                            {' '}
                            <strong>{loja.productFamilyId}</strong>
                            {' '}
                            na loja selecionada
                        </span>
                    </FlexboxGrid.Item>
                </Content>
                <Content>
                    <Table data={tabela.content} className="tabela" autoHeight>
                        <Column flexGrow={4}>
                            <HeaderCell>Produto</HeaderCell>
                            <Cell>
                                {(rowData) => {
                                    const codigoproduto = [
                                        ...(rowData.product_id?.length
                                            ? [rowData.product_id]
                                            : []),
                                    ];

                                    const descricaoproduto = [
                                        ...(rowData.description?.length
                                            ? [rowData.description]
                                            : []),
                                    ];
                                    return (
                                        <span>
                                            {codigoproduto?.length ? (
                                                <strong>{codigoproduto}</strong>
                                            ) : null}
                                            {' '}
                                            -
                                            {' '}
                                            {descricaoproduto}
                                        </span>
                                    );
                                }}
                            </Cell>
                        </Column>
                        <Column flexGrow={1}>
                            <HeaderCell>Loja</HeaderCell>
                            <Cell className="grid__tabel-modal">{loja.storeId}</Cell>
                        </Column>
                        <Column flexGrow={1}>
                            <HeaderCell>PMZ</HeaderCell>
                            <Cell className="grid__tabel-modal">
                                {(rowData) => (rowData.pmz ? (
                                    <CurrencyFormat
                                        fixedDecimalScale
                                        decimalScale={2}
                                        value={rowData.pmz}
                                        displayType="text"
                                        prefix="R$"
                                        decimalSeparator=","
                                        thousandSeparator="."
                                    />
                                    ) : (
                                        '--'
                                    ))}
                            </Cell>
                        </Column>
                        <Column flexGrow={1}>
                            <HeaderCell>Preço vigente</HeaderCell>
                            <Cell className="grid__tabel-modal">
                                {(rowData) => (rowData.retail_price ? (
                                    <CurrencyFormat
                                        fixedDecimalScale
                                        decimalScale={2}
                                        value={rowData.retail_price}
                                        displayType="text"
                                        prefix="R$"
                                        decimalSeparator=","
                                        thousandSeparator="."
                                    />
                                    ) : (
                                        '--'
                                    ))}
                            </Cell>
                        </Column>
                        <Column width={150}>
                            <HeaderCell>Média concorrência</HeaderCell>
                            <Cell className="grid__tabel-modal">
                                {(rowData) => (rowData.competitor_price ? (
                                    <CurrencyFormat
                                        fixedDecimalScale
                                        decimalScale={2}
                                        value={rowData.competitor_price}
                                        displayType="text"
                                        prefix="R$"
                                        decimalSeparator=","
                                        thousandSeparator="."
                                    />
                                    ) : (
                                        '--'
                                    ))}
                            </Cell>
                        </Column>
                        <Column flexGrow={1}>
                            <HeaderCell>Competitividade</HeaderCell>
                            <Cell className="grid__tabel-modal">
                                {(rowData) => (rowData.competitiveness ? (
                                    <CurrencyFormat
                                        fixedDecimalScale
                                        decimalScale={2}
                                        value={rowData.competitiveness}
                                        displayType="text"
                                        suffix="%"
                                        decimalSeparator=","
                                        thousandSeparator="."
                                    />
                                    ) : (
                                        '--'
                                    ))}
                            </Cell>
                        </Column>
                        <Column flexGrow={1}>
                            <HeaderCell>Margem</HeaderCell>
                            <Cell className="grid__tabel-modal">
                                {(rowData) => (rowData.margin ? (
                                    <CurrencyFormat
                                        fixedDecimalScale
                                        value={rowData.margin}
                                        decimalScale={2}
                                        displayType="text"
                                        suffix="%"
                                        decimalSeparator=","
                                        thousandSeparator="."
                                    />
                                    ) : null)}
                            </Cell>
                        </Column>
                    </Table>
                    <Pagination
                        showLengthMenu={false}
                        activePage={tabela.number + 1}
                        displayLength={tabela.size}
                        total={tabela.totalElements}
                        onChangePage={onChangePage}
                    />
                </Content>
            </Modal.Body>
        </Container>
        <Modal.Footer>
            <Button
            className= 'botao' appearance="ghost" onClick={onClose}>
                CANCELAR
            </Button>
            <Button
            className= 'botao' appearance="ghost" onClick={saveProduto}>
                APLICAR SOMENTE NO ITEM
            </Button>
            <Button
            className= 'botao' appearance="primary" onClick={saveFamilia}>
                APLICAR NA FAMÍLIA
            </Button>
        </Modal.Footer>
    </Modal>
);

export default ModalConfirmacaoFamilia;
