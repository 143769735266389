import React from 'react';
import { DATAPOINT_PROPS } from '../../../../@types';
import { Competitiveness } from './Competitiveness';
import { Numeric } from './Numeric';
import { ProFeature } from './ProFeature';
import { ProductCell } from './Product';
import './style.scss';

export type CellProps = React.HTMLAttributes<HTMLDivElement> & DATAPOINT_PROPS;

const Cell = ({ children }: CellProps) => <div>{children}</div>;

Cell.Numeric = Numeric;
Cell.Product = ProductCell;
Cell.ProFeature = ProFeature;
Cell.Competitiveness = Competitiveness;

export { Cell };
