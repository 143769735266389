import classNames from 'classnames';
import React from 'react';
import { SelectPicker } from 'rsuite';
import { SelectPickerProps } from 'rsuite/lib/SelectPicker';
import { FilterSelectAll, FilterSelectAllProps } from '../../FilterSection';
import { ChipIcon } from '../ChipIcon';
import { Loading } from '../Loading';
import styles from './SelectPill.module.scss';

export type SelectPillProps = SelectPickerProps & {
    startIcon?: React.ReactNode;
    endIcon?: React.ReactNode;
    error?: boolean;
    selectedValueText?: string;
    selectAll?: FilterSelectAllProps;
    isLoading?: boolean;
    textLoading?: string;
};

export const SelectPill = ({
    data = [],
    value = [],
    className,
    startIcon,
    endIcon,
    error,
    selectAll,
    isLoading,
    textLoading,
    textSearch,
    locale,
    disabled,
    placeholder = 'Selecione uma opção',
    renderMenu,
    renderExtraFooter,
    onChange,
    ...props
}: SelectPillProps) => {
    return (
        <div
            className={classNames(
                styles['select-pill'],
                'info-filter',
                error && 'info-filter-error',
                className,
            )}
        >
            {startIcon && (
                <ChipIcon className="filter-start-icon">{startIcon}</ChipIcon>
            )}
            <SelectPicker
                data={data}
                value={disabled ? undefined : value}
                placeholder={placeholder}
                onChange={(value, event) => {
                    onChange?.(value ?? undefined, event);
                }}
                renderExtraFooter={
                    renderExtraFooter ||
                    (selectAll
                        ? () => {
                              return (
                                  <FilterSelectAll
                                      checked={
                                          data?.length === value?.length &&
                                          !!value?.length
                                      }
                                      {...selectAll}
                                  />
                              );
                          }
                        : undefined)
                }
                locale={
                    locale || {
                        noResultsText: isLoading
                            ? textLoading
                            : 'Nenhum item encontrado',
                        searchPlaceholder: textSearch,
                    }
                }
                renderMenu={
                    renderMenu ||
                    ((menu) => {
                        if (isLoading) return <Loading>{textLoading}</Loading>;
                        return menu;
                    })
                }
                {...props}
            />
            {endIcon && (
                <ChipIcon className="filter-end-icon">{endIcon}</ChipIcon>
            )}
        </div>
    );
};
