import { AxiosResponse } from 'axios';
import { format } from 'date-fns';
import qs from 'qs';
import { trackPromise } from 'react-promise-tracker';
import { api } from '../../../../../lib';
import { getValidData } from '../../../RevisaoPrecos/utils';
import * as T from './NegociacaoFornecedor.types';

export async function getDatapointsNegotiation(
    params: Partial<Record<string, any>>,
): Promise<T.SuppliersResponseProps> {
    return trackPromise(
        api({
            url: '/suppliers',
            method: 'GET',
            expectToken: true,
            params: getValidData({
                ...params,
            }),
            paramsSerializer(params) {
                return qs.stringify(params, { arrayFormat: 'repeat' });
            },
        }).then((resp) => resp.data),
        'get-datapoints-negociacao',
    );
}

export async function downloadDatapointsNegotiation(data: {
    filters: Record<string, string[]>;
    products: Record<'selectedIds' | 'excludedIds', string[]>;
    selectedAll: boolean;
    sort: string;
}) {
    const currentDate = new Date();
    const formattedDate = format(currentDate, 'dd-MM-yyyy');
    const downloadTableName = `${formattedDate}_ipa_negociacao_fornecedor.csv`;
    return trackPromise(
        api({
            url: '/suppliers/download',
            method: 'POST',
            expectToken: true,
            data,
            responseType: 'arraybuffer',
        })
            .then((res) => {
                if (res.status === 200) {
                    return res.data;
                }
                return res;
            })
            .then((resp) => {
                let download = resp;
                if (!download.status) {
                    const blob = new Blob([download]);
                    const hiddenElement = document.createElement('a');
                    hiddenElement.href = window.URL.createObjectURL(blob);
                    hiddenElement.target = '_blank';
                    hiddenElement.download = downloadTableName;
                    hiddenElement.click();
                    download = null;
                }
                return download;
            }),
        'download-datapoints-negociacao',
    );
}

export async function patchNegotiationSaveEditedDatapoint(
    datapoint: T.NegotitationEditDatapointProps,
): Promise<
    Pick<
        T.NegotiationDataItemProps,
        | 'isEdited'
        | 'newCost'
        | 'newPrice'
        | 'objectiveMargin'
        | 'productsToBePricedId'
    >
> {
    return trackPromise(
        api({
            url: '/suppliers',
            method: 'PATCH',
            expectToken: true,
            data: getValidData(datapoint),
        }).then((resp) => resp.data),
        `negotiation-edit-datapoint-${datapoint.id}`,
    );
}

export type SalvarNegociacaoData = {
    name: string;
    supplier: string;
    startDate: Date;
    endDate?: Date | null;
    filters: Partial<Record<string, string[]>>;
    datapoints: Record<'excludedIds' | 'selectedIds', string[]>;
    selectedAll: boolean;
};

export async function salvarNegociacao(
    data: SalvarNegociacaoData,
): Promise<AxiosResponse<{ id: string }>> {
    return trackPromise(
        api({
            url: '/negotiations',
            method: 'POST',
            expectToken: true,
            data: JSON.stringify(getValidData(data)),
        }),
        'negotiation-save-negotiation',
    );
}

type ResponseDataItem = Record<'id' | 'name', string>;

export async function getNegociacoesSalvas(
    search?: string,
): Promise<Record<'label' | 'value', string>[]> {
    return trackPromise<AxiosResponse<ResponseDataItem[]>>(
        api({
            url: '/negotiations/find',
            expectToken: true,
            params: { search },
            paramsSerializer: (params) => {
                return qs.stringify(params, { arrayFormat: 'repeat' });
            },
        }),
    ).then((res) => {
        return res.data.map(({ id, name }) => ({
            label: name,
            value: id,
        }));
    });
}

export type AddProductToNegotiationProps = {
    negotiationId: string;
    filters: Partial<Record<string, string[]>>;
    datapoints: Record<'selectedIds' | 'excludedIds', string[]>;
    selectedAll: boolean;
};

export async function addProductToNegotiation(
    data: AddProductToNegotiationProps,
): Promise<AxiosResponse<unknown>> {
    return trackPromise(
        api({
            url: '/negotiations/datapoints/add',
            method: 'PUT',
            expectToken: true,
            data: JSON.stringify(getValidData(data)),
        }),
        'negotiation/add-product-to-negotiation',
    );
}

export async function desfazerAlteracaoDePrecos(
    data: Partial<T.NegotiationDataItemProps>,
) {
    const res = await api.put<T.NegotiationDataItemProps>(
        'suppliers/undo-datapoint-changes',
        data,
        {
            expectToken: true,
        },
    );
    return res.data;
}
