import React, { Component } from "react";
import { bindActionCreators } from 'redux';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Table, Container, Content, FlexboxGrid, Col, Button, HelpBlock } from 'rsuite';
import { LoadingSpiner } from '../components/LoadingSpinner';
import CurrencyFormat from 'react-currency-format';
import moment from 'moment';

import { getProdutos, getLojas, getDatapoints } from '../services/DatapointAuditService';
import Autocomplete2 from '../components/AutoComplete2';
import DataRangePicker from '../components/DataRangePicker';
import { FormGroup } from "@material-ui/core";

const { Pagination } = Table;


class HistoricoPrecoDatapointPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            clientId: JSON.parse(localStorage.getItem('cliente'))?.codigo,
            errors: {}
        }

        this.handleFilterProduto = this.handleFilterProduto.bind(this);
        this.handleFilterLoja = this.handleFilterLoja.bind(this);
        this.handleSelectDate = this.handleSelectDate.bind(this);
        this.searchDatapoints = this.searchDatapoints.bind(this);
    }

    componentWillMount() {
        this.handleFilterLoja();
        this.handleFilterProduto();

    }

    handleChangePage = (e) => {
        this.setState({
            page: e
        }, this.searchDatapoints)
    }

    handleChangeLength = (e) => {
        this.setState({
            pageSize: e
        }, this.searchDatapoints)
    }


    handleSelectValue(value, name) {
        this.setState({
            [name]: value
        })

    }

    handleSelectDate(value) {
        this.setState({
            dataInicio: value[0],
            dataFim: value[1]
        })
    }

    handleFilterLoja(searchValue) {
        getLojas(this.state.clientId, searchValue).then(response => {
            if (response) {
                this.setState({
                    lojas: response.content.map(item => {
                        return {
                            value: item.store,
                            id: item.storeId
                        }
                    })
                })
            }
        })
    }


    handleFilterProduto(searchValue) {
        getProdutos(this.state.clientId, searchValue).then(response => {
            if (response) {
                this.setState({
                    produtos: response.content.map(item => {
                        return {
                            value: item.productId + ' - ' + item.description,
                            id: item.productId
                        }
                    })
                })
            }
        })
    }

    getModel() {
        return {
            dataInicio: this.state.dataInicio ? moment(this.state.dataInicio).format("YYYY-MM-DD") : null,
            dataFim: this.state.dataFim ? moment(this.state.dataFim).format("YYYY-MM-DD") : null,
            loja: this.state.loja.id,
            gtin: this.state.produto.id,
            clientId: this.state.clientId,
            page: this.state.page,
            size: this.state.pageSize
        }
    }

    validInput() {
        const data = document.getElementsByName('data')[0];
        var error = this.state.errors;
        let formIsValid = true;


        if (!this.state.dataInicio) {
            formIsValid = false;
            error["data"] = "Campo obrigatório";
            if (data) {
                data.classList.add('filtros__input_invalid')
            }
        } else {
            formIsValid = true;
            error["data"] = null;
            if (data) {
                data.classList.remove('filtros__input_invalid')
            }
        }

        const loja = document.getElementsByName('loja')[0];
        if (!this.state.loja || !this.state.loja.id) {
            formIsValid = false;
            error["loja"] = "Campo obrigatório";
            if (loja) {
                loja.classList.add('filtros__input_invalid')
            }
        } else {
            formIsValid = true;
            error["loja"] = null;
            if (loja) {
                loja.classList.remove('filtros__input_invalid')
            }
        }

        const produto = document.getElementsByName('produto')[0];
        if (!this.state.produto || !this.state.produto.id) {
            formIsValid = false;
            error["produto"] = "Campo obrigatório";
            if (produto) {
                produto.classList.add('filtros__input_invalid')
            }
        } else {
            formIsValid = true;
            error["produto"] = null;
            if (produto) {
                produto.classList.remove('filtros__input_invalid')
            }
        }

        this.setState({ errors: error });
        return formIsValid;
    }

    searchDatapoints() {
        if (this.validInput()) {
            getDatapoints(this.state.clientId, this.getModel()).then(response => {
                if (response) {
                    this.setState({
                        data: response
                    })
                }
            })
        }
    }


    render() {

        const { data } = this.state;

        return (
            <Container className="main-container historico-preco-datapoint-page">
                <Content>
                    <FlexboxGrid>
                        <FlexboxGrid.Item colspan={24}>
                            <FlexboxGrid className="filtros">

                                <FlexboxGrid.Item componentClass={Col} lg={4} md={4} sm={24} xs={24}>
                                    <FormGroup>
                                        <Autocomplete2 items={this.state.produtos} onSelect={(value) => this.handleSelectValue(value, "produto")} name={'produto'} placeholder={'Produto*'} onInputValueChange={this.handleFilterProduto} itemToString={item => (item ? item.value : '')} gridSize={24} />
                                        <HelpBlock className="filtros__message filtros__message_invalid" name="erroData">{this.state.errors["produto"]}</HelpBlock>
                                    </FormGroup>
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item componentClass={Col} colspan={4} lg={4} md={4} sm={22} xs={22}>
                                    <FormGroup>
                                        <Autocomplete2 items={this.state.lojas} onSelect={(value) => this.handleSelectValue(value, "loja")} name={'loja'} placeholder={'Loja*'} onInputValueChange={this.handleFilterLoja} itemToString={item => (item ? item.value : '')} gridSize={24} />
                                        <HelpBlock className="filtros__message filtros__message_invalid" name="erroData">{this.state.errors["loja"]}</HelpBlock>
                                    </FormGroup>
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item componentClass={Col} colspan={4} lg={4} md={4} sm={22} xs={22}>
                                    <FormGroup>
                                        <DataRangePicker className="filtros__input" name={'data'} placeholder={'Data*'} onChangeValue={this.handleSelectDate} ranges={[
                                            {
                                                label: 'Últimos 30 dias',
                                                value: [moment().subtract(31, 'days').toDate(), moment().subtract(1, 'days').toDate()]
                                            },
                                            {
                                                label: 'Últimos 60 dias',
                                                value: [moment().subtract(61, 'days').toDate(), moment().subtract(1, 'days').toDate()]
                                            },
                                            {
                                                label: 'Últimos 90 dias',
                                                value: [moment().subtract(91, 'days').toDate(), moment().subtract(1, 'days').toDate()]
                                            }
                                        ]} />
                                        <HelpBlock className="filtros__message filtros__message_invalid" name="erroData">{this.state.errors["data"]}</HelpBlock>
                                    </FormGroup>
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item componentClass={Col} colspan={2} lg={2} mg={2} sm={24} xs={24}>
                                    <Button appearance="primary" block type="submit" onClick={this.searchDatapoints}>Buscar histórico</Button>
                                </FlexboxGrid.Item>

                            </FlexboxGrid>

                            <LoadingSpiner size="md" />


                            {/* <Table
                                height={400}
                                data={data.content}
                                autoHeight
                            >
                                <Column flexGrow={2}>
                                    <HeaderCell>Descricao do Produto</HeaderCell>
                                    <Cell dataKey="description" />
                                </Column>
                                <Column flexGrow={2} >
                                    <HeaderCell>Loja</HeaderCell>
                                    <Cell dataKey="store" />
                                </Column>
                                <Column flexGrow={2} >
                                    <HeaderCell>Preço Cadastrado</HeaderCell>
                                    <Cell>
                                        {rowData => (
                                            <CurrencyFormat fixedDecimalScale={true} decimalScale={2} value={rowData.priceRetail} displayType={'text'} prefix={'R$'} decimalSeparator={','} thousandSeparator={'.'} />
                                        )}
                                    </Cell>
                                </Column>
                                <Column flexGrow={2} >
                                    <HeaderCell>Tipo de preço</HeaderCell>
                                    <Cell>
                                        {rowData => {
                                            let priceType = rowData.priceType === "AUTO_PRICE" ? "AUTOMÁTICO" : '';
                                            return (
                                                <span>{priceType}</span>
                                            )
                                        }}
                                    </Cell>
                                </Column>

                                <Column flexGrow={2} >
                                    <HeaderCell>Data de criação</HeaderCell>
                                    <Cell>
                                        {rowData => {
                                            let data = moment(rowData.createdAt).format("DD/MM/YYYY");
                                            return (
                                                <span>{data}</span>
                                            )
                                        }}
                                    </Cell>
                                </Column>
                            </Table> */}
                            <table>
                                <col />
                                <col className="wide" />
                                <col />
                                <col />
                                <col />
                                <col />
                                <col />
                                <col />
                                <col />

                                <tr className="table-row-principal-header">
                                    <th scope="col">Identificador</th>
                                    <th scope="col">Descrição</th>
                                    <th scope="col">PMZ</th>
                                    <th scope="col">Loja</th>
                                    <th scope="col" span="2">Preço Varejo</th>
                                    <th scope="col"></th>
                                    <th scope="col" span="2">Preço atacado</th>
                                    <th scope="col" className="text-center">Gatilho</th>
                                    <th scope="col" className="text-center">Criação</th>
                                </tr>
                                <>
                                    {this.state.data.content ? this.state.data.content.map((item, index) => (
                                        <tr key={index} className="table-row">
                                            <td title={item.productId}>{item.productId}</td>
                                            <td title={item.description}>{item.description}</td>
                                            <td>{item.pmz ? <CurrencyFormat fixedDecimalScale={true} decimalScale={2} value={item.pmz} displayType={'text'} prefix={'R$'} decimalSeparator={','} thousandSeparator={'.'} /> : null}</td>
                                            <td>{item.store}</td>
                                            <td>{item.price ? <CurrencyFormat fixedDecimalScale={true} decimalScale={2} value={item.price} displayType={'text'} prefix={'R$'} decimalSeparator={','} thousandSeparator={'.'} /> : null}</td>
                                            <td><span>{item.priceType === "AUTO_PRICE" ? "AUTOMÁTICO" : ''}</span></td>
                                            <td className="text-center"></td>
                                            <td>{item.priceAtacado ? <CurrencyFormat fixedDecimalScale={true} decimalScale={2} value={item.priceAtacado} displayType={'text'} prefix={'R$'} decimalSeparator={','} thousandSeparator={'.'} /> : null}</td>
                                            <td><span>{item.priceTypeAtacado === "AUTO_PRICE" ? "AUTOMÁTICO" : ''}</span></td>
                                            <td>{item.gatilho}</td>
                                            <td> {item => {
                                                let data = moment(item.createdAt).format("DD/MM/YYYY");
                                                return (
                                                    <span>{data}</span>
                                                )
                                            }}</td>

                                        </tr>
                                    )) : null}
                                </>

                            </table>
                            <Pagination
                                lengthMenu={[
                                    {
                                        value: 20,
                                        label: 20
                                    },
                                    {
                                        value: 40,
                                        label: 40
                                    }
                                ]}
                                activePage={data.page + 1}
                                displayLength={data.numberOfElements}
                                total={data.totalElements}
                                onChangePage={this.handleChangePage}
                                onChangeLength={this.handleChangeLength}
                            />

                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </Content>


            </Container >
        )
    }
}



const mapStateToProps = store => ({

})

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {

        }, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HistoricoPrecoDatapointPage));
