import React from 'react';
import { Icon } from 'rsuite';
import CurrencyFormat from 'react-currency-format';
// @ts-ignore
import { Card } from '../../Components/index.tsx';
// @ts-ignore
import { useGanhoEmMargemTotal } from '../../../hooks/useGanhoEmMargemTotal.tsx';

const Description = (
    diff: number | undefined,
    lastMonth: string | undefined,
    marginGain: string | undefined
) => (
    <span>
        <span
            className={`display-porcentagem ${
                lastMonth && marginGain && lastMonth > marginGain
                    ? 'negative'
                    : 'positive'
            }`}
        >
            {lastMonth && marginGain && lastMonth > marginGain ? (
                <Icon icon="arrow-down2" />
            ) : (
                <Icon icon="arrow-up2" />
            )}{' '}
            <CurrencyFormat
                className="currency"
                fixedDecimalScale
                decimalScale={2}
                value={diff}
                displayType="text"
                suffix="%"
                decimalSeparator=","
                thousandSeparator="."
            />
        </span>
        <span>era {lastMonth} no último mês</span>
    </span>
);

export const GanhoEmMargemTotal: React.FC = () => {
    const { data, isLoading } = useGanhoEmMargemTotal();

    return (
        <Card
            data={data?.marginGain}
            isLoading={isLoading}
            title="Ganho em margem total em reais"
            description={
                data?.diff && data?.lastMonth
                    ? Description(data?.diff, data?.lastMonth, data?.marginGain)
                    : null
            }
        />
    );
};
