import React from 'react';
import { Tooltip, Whisper } from 'rsuite';

export const HoverNomeColuna = ({ placement, text, tooltip }) => (
    <Whisper
        trigger="hover"
        placement={placement}
        speaker={<Tooltip>{tooltip}</Tooltip>}
    >
        <span>{text}</span>
    </Whisper>
);