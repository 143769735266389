import { combineReducers } from '@reduxjs/toolkit';
import filtrosSlice, { CalculadoraPrevisaoDemandaFiltrosStateProps } from './filtros';
import savedFiltrosSlice, { ScenarioSavedFiltrosStateProps } from './savedFiltros';
import inputCalculosSlice, { InputCalculosStateProps } from './inputCalculos';
import resultScenarioSlice, { ResultScenarioStateProps } from './resultScenario';
import savedScenarioSlice, { SavedScenarioStateProps } from './savedScenario';

export type CalculadoraPrevisaoDemandaStateProps = {
    filtros: CalculadoraPrevisaoDemandaFiltrosStateProps;
    inputCalculos: InputCalculosStateProps;
    resultScenario: ResultScenarioStateProps;
    savedScenario: SavedScenarioStateProps;
    savedFiltros: ScenarioSavedFiltrosStateProps;
};

const rootReducer = combineReducers({
    filtros: filtrosSlice,
    inputCalculos: inputCalculosSlice,
    resultScenario: resultScenarioSlice,
    savedScenario: savedScenarioSlice,
    savedFiltros: savedFiltrosSlice,
});

export const selectorCalculadoraPrevisaoDemanda = (
    state: Record<'calculadoraPrevisaoDemandaReducer', CalculadoraPrevisaoDemandaStateProps>,
) => state.calculadoraPrevisaoDemandaReducer;

export default rootReducer;
