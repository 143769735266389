export const CompetitorPriceSources = [
    'MPDV',
    'INFOPANEL',
    'INDIRECT',
    'PROPRIA',
] as const;

export type ICompetitorPriceSources = (typeof CompetitorPriceSources)[number];

export const CompetitorPriceSourcesDataList: {
    label: string;
    value: ICompetitorPriceSources;
}[] = [
    { label: 'Monitoramento PDV', value: 'MPDV' },
    { label: 'Concorrentes diretos', value: 'INFOPANEL' },
    { label: 'Preço de mercado', value: 'INDIRECT' },
    { label: 'Pesquisa própria', value: 'PROPRIA' },
];
