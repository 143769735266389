import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../@types/RootState';

type Keys = 'CHANGE_MASSIVE_PRICE';

type WholesaleModalsState = Record<Keys, boolean>;

const initialState: WholesaleModalsState = {
    CHANGE_MASSIVE_PRICE: false,
};

const slice = createSlice({
    name: 'ipa/precoAtacado/modals',
    initialState,
    reducers: {
        OPEN_WHOLESALE_MODAL: (state, { payload }: PayloadAction<Keys>) => {
            state[payload] = true;
        },
        CLOSE_WHOLESALE_MODAL: (state, { payload }: PayloadAction<Keys>) => {
            state[payload] = false;
        },
    },
});

export const { OPEN_WHOLESALE_MODAL, CLOSE_WHOLESALE_MODAL } = slice.actions;

export default slice.reducer;

export const selectorWholesaleModals = (state: RootState) => {
    return state.precoAtacadoReducer.modals;
};
