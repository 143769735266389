import { trackPromise } from 'react-promise-tracker';
import api from '../../../../utils/API';

export const getDatapointProdutoFamilia = (productFamilyId) => trackPromise(
    api({
        url: `${process.env.REACT_APP_IPA_URL}/products/families/${productFamilyId}/summary/aggregate`,
        method: 'GET',
        expectToken: true,
    }).then((resp) => resp.data),
);

export const getGridModalFamilia = (productFamilyId, page) => trackPromise(
    api({
        url: `${process.env.REACT_APP_IPA_URL}/products/aggregate`,
        method: 'GET',
        expectToken: true,
       params: {
         productFamilyId,
         size: 8,
         page,
                },
    }).then((resp) => resp.data),
);

export const getInformacoesProduto = (id) => trackPromise(
        api({
            url: `${process.env.REACT_APP_IPA_URL}/products/${id}`,
            method: 'GET',
            expectToken: true,
        }).then((resp) => resp.data),
    );

export const getLojasProduto = () => trackPromise(
        api({
            url: `${process.env.REACT_APP_IPA_URL}/datapoints/storeType`,
            method: 'GET',
            expectToken: true,
        }).then((resp) => resp.data),
    );
