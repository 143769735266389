import React from 'react';
// @ts-ignore
import { Tabela } from '../../Components/index.tsx';
// @ts-ignore
import { useTotalVendasPorCategoria1 } from '../../../hooks/useTotalVendasPorCategoria1.tsx';

export const TotalVendasPorCategoria1: React.FC = () => {
    const { data, isLoading } = useTotalVendasPorCategoria1();

    const columns = [
        {
            align: 'left',
            headerCell: 'Categoria Nível 1',
            dataKey: 'categoryLevel1',
            flexGrow: 2,
        },
        {
            align: 'left',
            headerCell: 'Unidades vendidas',
            dataKey: 'sumSalesAmount',
            flexGrow: 1,
        },
        {
            align: 'left',
            headerCell: 'Venda bruta',
            dataKey: 'sumSalesGross',
            flexGrow: 1,
        },
    ];

    return (
        <Tabela
            data={data}
            isLoading={isLoading}
            columns={columns}
            title="Total de vendas por categoria nível 1"
        />
    );
};
