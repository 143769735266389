import classNames from 'classnames';
import React from 'react';
import CurrencyInput from 'react-currency-input';
import {
    Input,
    InputGroup,
} from 'rsuite';

import styles from './InputAddon.module.scss';

interface IInputAddon {
    addon?: string;
    position?: string;
    type?: string;
    inputSize?: string;
    disabled?: boolean;
    defaultValue?: string;
    value?: string | number | null;
    groupSize?: string;
    marginBottom?: string;
    width?: string;
    currency?: boolean;
    className?: string;
    maxLength?: number;
    allowNegative?: boolean;
    suffix?: string;
    prefix?: string;
    precision?: string;
    onChange?: (arg: string) => void;
}

export const InputAddon = ({
    addon,
    position,
    type,
    inputSize,
    disabled,
    defaultValue,
    value,
    groupSize,
    marginBottom,
    width,
    currency,
    className,
    maxLength,
    allowNegative,
    suffix,
    prefix,
    precision = '2',
    onChange,
}: IInputAddon) => (
    <InputGroup className={styles['input-addon']} size={groupSize} style={{ marginBottom, width }}>
        {position === 'left' ? <InputGroup.Addon>{addon}</InputGroup.Addon> : null}
        {currency ? (
            <CurrencyInput
                className={classNames('rs-input', className)}
                decimalSeparator=","
                thousandSeparator="."
                precision={precision}
                allowNegative={allowNegative}
                maxLength={maxLength}
                value={value}
                prefix={prefix}
                suffix={suffix}
                onChange={onChange}
            />
        ) : (
            <Input
                type={type}
                size={inputSize}
                disabled={disabled}
                defaultValue={defaultValue}
                value={value}
                onChange={onChange}
            />
        )}
        {position === 'right' ? <InputGroup.Addon>{addon}</InputGroup.Addon> : null}
    </InputGroup>
);
