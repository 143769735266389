import { format } from 'date-fns';

class Exception extends Error {
    constructor(message: string) {
        const timeStamp = format(new Date(), 'HH:mm:ss');
        super(`${timeStamp} - ${message}`);
        this.name = this.constructor.name;
    }
}

export default Exception;
