import React from 'react';
import { CheckPicker, SelectPicker } from 'rsuite';
import { CheckPickerProps } from 'rsuite/lib/CheckPicker';
import { Icon } from '../../IPA/RevisaoPrecos/Components/QuickActionFilter';

// id = select-picker-pill (forma de pílula, para select normal sem checkbox)
// id = check-picker-pill (forma de pílula, para select com checkbox na lista e placeholder visível no check do item)
// id = check-picker-pill-default (forma de pílula, para select com checkbox na lista e placeholder escondido)
// type = check (para select com checkbox na lista, se essa props estiver vazia o select será uma lista normal)

// interface SelectPillProps extends CheckPickerProps {
//     id: string;
//     type?: string;
//     className?: string;
//     style?: React.CSSProperties;
//     searchable?: boolean;
// }

export type SelectPillProps = CheckPickerProps & {
    id: string;
    type?: string;
    className?: string;
    style?: React.CSSProperties;
    searchable?: boolean;
};

export const SelectPill: React.FC<SelectPillProps> = (props) => {
    const {
        id,
        searchable,
        type,
        className,
        style,
        placeholder,
        renderValue,
        name,
        locked = false,
        disabled = false,
        ...restProps
    } = props;

    const renderPlaceholder =
        id === 'check-picker-pill'
            ? (value: string | any[]) => (
                  <>
                      <span>{value && placeholder}</span>
                      <span className="counter">{value && value?.length}</span>
                  </>
              )
            : renderValue;

    return type === 'check' ? (
        <div className="picker-pill-wrapper">
            <CheckPicker
                block
                id={id}
                className={className}
                name={name}
                // @ts-expect-error na doc informa que o placeholder é um ReactNode
                placeholder={
                    locked ? (
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '4px',
                            }}
                        >
                            <Icon icon="premium" />
                            <span>{placeholder}</span>
                        </div>
                    ) : (
                        placeholder
                    )
                }
                searchable={searchable}
                renderValue={renderPlaceholder}
                style={style}
                disabled={disabled || locked}
                {...restProps}
            />
        </div>
    ) : (
        <SelectPicker
            block
            id={id}
            className={className}
            name={name}
            // @ts-expect-error na doc informa que o placeholder é um ReactNode
            placeholder={
                locked ? (
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '4px',
                        }}
                    >
                        <Icon icon="premium" />
                        <span>{placeholder}</span>
                    </div>
                ) : (
                    placeholder
                )
            }
            searchable={searchable}
            style={style}
            disabled={disabled || locked}
            {...restProps}
        />
    );
};
