import {
    differenceInDays,
    differenceInMonths,
    differenceInYears,
    format,
} from 'date-fns';
import React from 'react';
import { Content } from 'rsuite';

type FiltroProps = {
    filtros: {
        periodoSelecionado: Date[];
    };
    title: string;
};

export const Periodo: React.FC<FiltroProps> = (props) => {
    const { filtros, title } = props;
    const { periodoSelecionado } = filtros;
    const checkPeriodo = () => {
        if (differenceInDays(periodoSelecionado[1], new Date()) === 0) {
            if (
                differenceInMonths(
                    periodoSelecionado[1],
                    periodoSelecionado[0]
                ) < 1
            ) {
                return `${
                    differenceInDays(
                        periodoSelecionado[1],
                        periodoSelecionado[0]
                    ) > 0
                        ? `Últimos ${
                              differenceInDays(
                                  periodoSelecionado[1],
                                  periodoSelecionado[0]
                              ) + 1
                          } dias`
                        : 'Último dia'
                }`;
            }

            return `${
                differenceInMonths(
                    periodoSelecionado[1],
                    periodoSelecionado[0]
                ) > 0
                    ? `Últimos ${
                          differenceInMonths(
                              periodoSelecionado[1],
                              periodoSelecionado[0]
                          ) + 1
                      } meses`
                    : 'Último mês'
            }`;
        }

        if (
            differenceInMonths(periodoSelecionado[1], periodoSelecionado[0]) < 1
        ) {
            return `${format(periodoSelecionado[0], 'dd MMM.')} - ${format(
                periodoSelecionado[1],
                'dd MMM. yyyy'
            )}`;
        }

        if (
            differenceInMonths(periodoSelecionado[1], periodoSelecionado[0]) >=
            1
        ) {
            return `${format(periodoSelecionado[0], 'dd')} - ${format(
                periodoSelecionado[1],
                'dd MMM. yyyy'
            )}`;
        }

        if (
            differenceInYears(periodoSelecionado[1], periodoSelecionado[0]) > 1
        ) {
            return `${format(periodoSelecionado[0], 'dd MMM. yyyy')} - ${format(
                periodoSelecionado[1],
                'dd MMM. yyyy'
            )}`;
        }
    };

    if (periodoSelecionado?.length > 1)
        return (
            <Content className="periodo-header">
                <h3>{title}</h3>
                <p>
                    Período analisado: <strong>{checkPeriodo()}</strong>
                </p>
            </Content>
        );

    return (
        <Content className="periodo-header">
            <h3>{title}</h3>
            <p>
                Período analisado: <strong>Últimos 3 meses</strong>
            </p>
        </Content>
    );
};
