import iconEmailClock from '../../../../assets/icons/icon_email_clock.svg';
import iconEmailHourglass from '../../../../assets/icons/icon_email_hourglass.svg';
import iconNofileDownloaded from '../../../../assets/icons/icon_no-file-downloaded.svg';

export const fileDownloadStatus = {
    willReceiveByEmail: {
        title: 'Você receberá o arquivo por e-mail',
        message:
            'Precisamos de um tempo para processar as informações solicitadas. Te enviaremos um e-mail quando o arquivo estiver disponível para download.',
        confirmButton: null,
        cancelButton: null,
        icon: iconEmailClock,
        cancelBtnWidth: null,
        confirmBtnWidth: null,
        closeButton: true,
    },
    willDelay: {
        title: 'O envio do arquivo pode demorar mais de 20 minutos',
        message:
            'Sua solicitação possui uma grande quantidade dados. Te enviaremos um e-mail quando o arquivo estiver disponível para download.',
        confirmButton: null,
        cancelButton: null,
        icon: iconEmailHourglass,
        cancelBtnWidth: '200px',
        confirmBtnWidth: '200px',
        closeButton: true,
    },
    willNotDownload: {
        title: 'A solicitação é muito grande para ser processada',
        message:
            'Não é possível fazer download de arquivos com mais de 1 milhão de linhas. Revise os filtros e tente novamente.',
        confirmButton: 'REVISAR FILTROS',
        cancelButton: 'FECHAR',
        icon: iconNofileDownloaded,
        cancelBtnWidth: '200px',
        confirmBtnWidth: '200px',
        closeButton: false,
    },
    noDataForDownload: {
        title: 'Nenhum resultado encontrado',
        message:
            'Não foram encontrados dados para download. Revise os filtros e tente novamente.',
        confirmButton: null,
        cancelButton: null,
        icon: iconNofileDownloaded,
        cancelBtnWidth: '200px',
        confirmBtnWidth: '200px',
        closeButton: true,
    },
};
