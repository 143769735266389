import _ from 'lodash';
import React, { useEffect, useReducer } from 'react';

export type IFilterItem = {
    label: string;
    value: string;
} & Record<string, unknown>;

type Key = string;

export type FiltersDataState<T extends Key = Key> = Partial<
    Record<T, IFilterItem[]>
>;

type Action<T extends Key> =
    | {
          type: 'ADD';
          key: T;
          payload: IFilterItem;
      }
    | {
          type: 'SET';
          key: T;
          payload: IFilterItem[];
      }
    | {
          type: 'SET_STATE';
          payload: FiltersDataState<T>;
      }
    | {
          type: 'RESET';
      }
    | {
          type: 'RESET_BY_KEY';
          key: T;
      };

const initialState: FiltersDataState<never> = {};

const reducer = <T extends Key>(
    state: FiltersDataState<T>,
    action: Action<T>,
): FiltersDataState<T> => {
    switch (action.type) {
        case 'ADD':
            return {
                ...state,
                [action.key]: _.unionBy(
                    [...(state[action.key] ?? []), action.payload],
                    'value',
                ),
            };
        case 'SET':
            return {
                ...state,
                [action.key]: action.payload,
            };
        case 'RESET':
            return initialState;
        case 'RESET_BY_KEY':
            return {
                ...state,
                [action.key]: [],
            };
        default:
            return state;
    }
};

const ADD_DATA = <T extends Key>(key: T, payload: IFilterItem) => {
    return {
        type: 'ADD',
        key,
        payload,
    } as const;
};

const SET_DATA = <T extends Key>(key: T, payload: IFilterItem[]) => {
    return {
        type: 'SET',
        key,
        payload,
    } as const;
};

const SET_STATE_DATA = <T extends Key>(payload: FiltersDataState<T>) => {
    return {
        type: 'SET_STATE',
        payload,
    } as const;
};

const RESET_DATA = () => {
    return {
        type: 'RESET',
    } as const;
};

const RESET_DATA_BY_KEY = <T extends Key>(key: T) => {
    return {
        type: 'RESET_BY_KEY',
        key,
    } as const;
};

export const useFilterData = <T extends Key>(initialData = initialState) => {
    const [state, dispatch] = useReducer(
        reducer as (
            state: FiltersDataState<T>,
            action: Action<T>,
        ) => FiltersDataState<T>,
        initialState as FiltersDataState<T>,
    );

    const onAddData = (key: T, payload: IFilterItem) => {
        dispatch(ADD_DATA(key, payload));
    };

    const onSetData = (key: T, payload: IFilterItem[]) => {
        dispatch(SET_DATA(key, payload));
    };

    const onSetStateData = (payload: FiltersDataState<T>) => {
        dispatch(SET_STATE_DATA(payload));
    };

    const findByValue = (key: T, value: string) => {
        return state[key]?.find((item) => item.value === value);
    };

    const onRemoveData = (key: T, value: string) => {
        const filteredData =
            state[key]?.filter((item) => item.value !== value) ?? [];
        dispatch(SET_DATA(key, filteredData));
    };

    const onResetData = () => {
        dispatch(RESET_DATA());
    };

    const onResetByKey = (key: T) => {
        dispatch(RESET_DATA_BY_KEY(key));
    };

    const findManyByValue = (key: T, value: string[]) => {
        return state[key]?.filter((item) => value.includes(item.value));
    };

    useEffect(() => {
        if (Object.keys(initialData).length) {
            dispatch(SET_STATE_DATA(initialData));
        }
    }, [initialData]);

    return [
        React.useMemo(() => state, [state]),
        {
            add: onAddData,
            remove: onRemoveData,
            set: onSetData,
            reset: onResetData,
            setState: onSetStateData,
            resetByKey: onResetByKey,
            findByValue,
            findManyByValue,
        },
    ] as const;
};
