import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
    Container,
} from 'rsuite';
import {
    SavedScenarioCompare,
    SavedScenarioFilter,
    SavedScenarioHeader,
    SavedScenarioTable,
} from '../../Components';
import {
    resetFilters,
} from '../../../../reducers/calculadoraPrevisaoDemanda/filtros';
import {
    resetInputCalculos,
} from '../../../../reducers/calculadoraPrevisaoDemanda/inputCalculos';
import {
    resetResultScenario,
    setResultScenarioData,
} from '../../../../reducers/calculadoraPrevisaoDemanda/resultScenario';
import { useTableActions } from '../../hooks/useTableActions';

const params = {
    page: 0,
    size: 10,
};

export const CalculadoraCenariosSalvos = () => {
    const dispatch = useDispatch();
    const { tableHandlers } = useTableActions();

    useEffect(() => {
        tableHandlers.getTableList(params, 'initial');
        dispatch(resetFilters());
        dispatch(resetInputCalculos());
        dispatch(resetResultScenario());
        dispatch(setResultScenarioData({ name: 'isEditing', data: false }));
        dispatch(setResultScenarioData({ name: 'editId', data: '' }));
    }, []);

    return (
        <Container className="main-container">
            <SavedScenarioHeader />
            <SavedScenarioFilter />
            <SavedScenarioCompare />
            <SavedScenarioTable />
        </Container>
    );
};
