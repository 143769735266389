import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
    Button,
    Content,
    Icon,
    Input,
    InputGroup,
} from 'rsuite';
import { selectorCalculadoraPrevisaoDemanda } from '../../../../reducers/calculadoraPrevisaoDemanda';
import {
    setSavedFilterRequestBody,
} from '../../../../reducers/calculadoraPrevisaoDemanda/savedFiltros';
import { useTableActions } from '../../hooks/useTableActions';
import styles from './SavedScenarioHeader.module.scss';

export const SavedScenarioHeader = () => {
    const { savedFiltros } = useSelector(selectorCalculadoraPrevisaoDemanda);

    const dispatch = useDispatch();

    const history = useHistory();

    const { tableHandlers } = useTableActions();

    const handleChangeSearch = (value: string) => {
        dispatch(setSavedFilterRequestBody({
            name: 'query',
            data: {
                ...savedFiltros.savedFilterRequestBody?.query,
                query: value,
            },
        }));
    };

    const handleSubmitSearch = (e) => {
        e.preventDefault();
        const params = { page: 0, sort: [] };
        tableHandlers.getTableList(params, savedFiltros.savedFilterRequestBody);
    };

    return (
        <Content className={styles['saved-header-content']}>
            <p className={styles.title}>Cenários salvos</p>
            <form
                className={styles.form}
                onSubmit={(e) => handleSubmitSearch(e)}
            >
                <InputGroup inside className={styles['search-input-group']}>
                    <InputGroup.Button>
                        <Icon icon="search" style={{ color: '#1473E6' }} />
                    </InputGroup.Button>
                    <Input
                        placeholder="Buscar por um cenário"
                        value={savedFiltros.savedFilterRequestBody?.query?.query}
                        onChange={handleChangeSearch}
                    />
                </InputGroup>
            </form>
            <Button
                id="new-simulation-scenario"
                onClick={() => history.push('/ipa/calculadora-demanda')}
            >
                Nova Simulação
            </Button>
        </Content>
  );
};
