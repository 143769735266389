import React from 'react';
// @ts-ignore
import { Tabela } from '../../Components/index.tsx';
// @ts-ignore
import { useTotalVendasPorCidade } from '../../../hooks/useTotalVendasPorCidade.tsx';

export const TotalVendasPorCidade: React.FC = () => {
    const { data, isLoading } = useTotalVendasPorCidade();

    const columns = [
        {
            align: 'left',
            headerCell: 'Cidade',
            dataKey: 'fullCity',
            flexGrow: 2,
        },
        {
            align: 'left',
            headerCell: 'Venda bruta',
            dataKey: 'sumSalesGross',
            flexGrow: 1,
        },
    ];

    return (
        <Tabela
            data={data}
            isLoading={isLoading}
            columns={columns}
            title="Total de vendas por cidade"
        />
    );
};
