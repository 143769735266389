import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { Content, Loader } from 'rsuite';

type GraficoProps = {
    grafico: any;
    isLoading: boolean;
    title: string;
    data?: any;
};

export const Grafico: React.FC<GraficoProps> = (props) => {
    const { isLoading, grafico, title, data } = props;

    if (isLoading)
        return (
            <Content className="grafico-loading">
                <Loader center />
            </Content>
        );

    if (!data)
        return (
            <Content className="grafico">
                <h2>{title}</h2>
                <div className="sem-dados">
                    <h3>Sem dados para essa análise</h3>
                    <p>Revise os filtros e tente novamente</p>
                </div>
            </Content>
        );

    return (
        <Content>
            <div className="grafico">
                <h2>{title}</h2>
                <HighchartsReact highcharts={Highcharts} options={grafico} />
            </div>
        </Content>
    );
};
