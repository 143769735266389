import classNames from 'classnames';
import _ from 'lodash';
import plural from 'pluralize-ptbr';
import React, { useMemo } from 'react';
import { CheckPicker } from 'rsuite';
import { CheckPickerProps } from 'rsuite/lib/CheckPicker';
import styles from './GhostCheckPicker.module.scss';

export type GhostCheckPickerProps = CheckPickerProps & {
    invertedSelection?: boolean;
};

export const GhostCheckPicker = ({
    className,
    data,
    value = [],
    invertedSelection,
    ...props
}: GhostCheckPickerProps) => {
    const invertedValues = useMemo(() => {
        const inverted: string[] = [];
        for (const { value: itemValue } of data) {
            if (!value.includes(itemValue)) {
                inverted.push(itemValue);
            }
        }
        return _.uniq(inverted);
    }, [data, value]);

    const renderValue = useMemo(() => {
        return invertedSelection ? invertedValues : value;
    }, [invertedSelection, invertedValues, value]);

    return (
        <CheckPicker
            {...props}
            className={classNames(styles.wrapper, className)}
            renderValue={({ length = 0 }) => (
                <span className={styles['render-value']}>
                    {`${length} ${length > 1 ? plural('ativo') : 'ativo'}`}
                </span>
            )}
            data={data}
            value={renderValue}
        />
    );
};
