import React from 'react';
import { format, parseISO } from 'date-fns';
import {
    Content,
    FlexboxGrid,
    IconButton,
    Icon,
    ControlLabel,
    Button,
} from 'rsuite';
import { useParams } from 'react-router-dom';
import { downloadPrevisao } from '../../../services';

const Header = ({
    header: { id, userEmail, date, store, product },
    history,
}) => {
    const { id: previsaoId } = useParams();

    const baixarPrevisao = () => {
        downloadPrevisao({ ids: [previsaoId] }).then(() => {
            console.log('ok');
        });
    };

    const back = () => {
        history.push('/ipa/simulacoes-analises/previsao-demanda/lista');
    };

    const novaSimulacao = () => {
        history.push('/ipa/simulacoes-analises/previsao-demanda');
    };

    return (
        <>
            <FlexboxGrid
                align="middle"
                justify="space-between"
                className="buttons-container"
            >
                <FlexboxGrid.Item>
                    <IconButton
                        icon={<Icon icon="back-arrow" />}
                        placement="left"
                        appearance="subtle"
                        onClick={back}
                    >
                        Voltar para Simulações salvas de Previsão de demanda
                    </IconButton>
                </FlexboxGrid.Item>
                <FlexboxGrid.Item>
                    <Button appearance="primary" onClick={novaSimulacao}>
                        Fazer nova simulação
                    </Button>
                </FlexboxGrid.Item>
            </FlexboxGrid>
            <Content className="page-header">
                <FlexboxGrid.Item colspan={24}>
                    <FlexboxGrid justify="space-between" align="middle">
                        <FlexboxGrid.Item>
                            <h2>Previsão de Demanda</h2>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item>
                            <IconButton
                                icon={<Icon icon="download" />}
                                onClick={baixarPrevisao}
                            />
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </FlexboxGrid.Item>
                <FlexboxGrid.Item colspan={24}>
                    <div className="previsao-salva-info">
                        <div>
                            <ControlLabel>Identificador</ControlLabel>
                            <p>{id}</p>
                        </div>
                        <div>
                            <ControlLabel>Descrição</ControlLabel>
                            <p>{product}</p>
                        </div>
                        <div>
                            <ControlLabel>Loja</ControlLabel>
                            <p>{store}</p>
                        </div>
                        <div>
                            <ControlLabel>Data de simulação</ControlLabel>
                            <p>
                                {date
                                    ? format(parseISO(date), 'dd/MM/yyyy')
                                    : '--'}
                            </p>
                        </div>
                        <div>
                            <ControlLabel>Usuário</ControlLabel>
                            <p>{userEmail}</p>
                        </div>
                    </div>
                </FlexboxGrid.Item>
            </Content>
        </>
    );
};

export default Header;
