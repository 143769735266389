import React, { createContext, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Container, Content, FlexboxGrid } from 'rsuite';
import {
    CompetitividadeMediaGeral,
    ItensPesquisados,
    VendaBruta,
    CompetitividadeCidade,
    CompetitividadeMediaLoja,
    CompetitividadeMediaItensPesquisados,
    CompetitividadeCategoria1,
    CompetitividadeCategoria2,
    ListaRenegociacaoProdutosMaisCaros,
    ListaOportunidades,
    ListaProdutosCompetitividadeAcima,
    ListaProdutosCompetitividadeAbaixo,
    // @ts-ignore
} from './Components/index.tsx';
// @ts-ignore
import { Filtros, Periodo } from '../Components/index.tsx';

type FiltrosContextProps = {
    lojasSelecionadas: string[];
    produtosSelecionados: string[];
    periodoSelecionado: Date[];
    tipoLojasSelecionadas: string[];
    categoriaNivel1Selecionadas: string[];
    categoriaNivel2Selecionadas: string[];
    competitividadeSelecionada: string[];
};

export const FiltrosContext = createContext<FiltrosContextProps>({
    lojasSelecionadas: [],
    produtosSelecionados: [],
    periodoSelecionado: [],
    tipoLojasSelecionadas: [],
    categoriaNivel1Selecionadas: [],
    categoriaNivel2Selecionadas: [],
    competitividadeSelecionada: [],
});

type CompetitividadeGeralProps = {
    match?: {
        isExact: boolean;
        params: {};
        path: string;
        url: string;
    }
};

const CompetitividadeGeral: React.FC<CompetitividadeGeralProps> = ({match}) => {
    const [filtros, setFiltros] = useState({
        lojasSelecionadas: [],
        produtosSelecionados: [],
        periodoSelecionado: [],
        tipoLojasSelecionadas: [],
        categoriaNivel1Selecionadas: [],
        categoriaNivel2Selecionadas: [],
        competitividadeSelecionada: [],
    });

    const changeFiltros = (value?: any, filtro?: string) => {
        if (value && filtro)
            setFiltros((prevState) => ({
                ...prevState,
                [filtro]: value,
            }));
        else
            setFiltros({
                lojasSelecionadas: [],
                produtosSelecionados: [],
                periodoSelecionado: [],
                tipoLojasSelecionadas: [],
                categoriaNivel1Selecionadas: [],
                categoriaNivel2Selecionadas: [],
                competitividadeSelecionada: [],
            });
    };

    return (
        <Container className="main-container competitividade-geral-page">
            <Content className="no-margin-bottom page-header">
                <h2>Competitividade Geral</h2>
            </Content>
            <Filtros filtros={filtros} match={match} changeFiltros={changeFiltros} />
            <Periodo filtros={filtros} title="Resultado da competitividade" />
            <FiltrosContext.Provider value={filtros}>
                <Container className="graficos-container">
                    <FlexboxGrid>
                        <FlexboxGrid.Item colspan={12}>
                            <FlexboxGrid>
                                <FlexboxGrid.Item colspan={24}>
                                    <CompetitividadeMediaGeral />
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item colspan={7}>
                                    <ItensPesquisados />
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item colspan={17}>
                                    <VendaBruta />
                                </FlexboxGrid.Item>
                            </FlexboxGrid>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={6}>
                            <CompetitividadeCidade />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={6}>
                            <CompetitividadeMediaLoja />
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                    <FlexboxGrid>
                        <FlexboxGrid.Item colspan={12}>
                            <CompetitividadeMediaItensPesquisados />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={6}>
                            <CompetitividadeCategoria1 />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={6}>
                            <CompetitividadeCategoria2 />
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                    <FlexboxGrid>
                        <FlexboxGrid.Item colspan={12}>
                            <ListaRenegociacaoProdutosMaisCaros />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={12}>
                            <ListaOportunidades />
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                    <FlexboxGrid>
                        <FlexboxGrid.Item colspan={12}>
                            <ListaProdutosCompetitividadeAcima />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={12}>
                            <ListaProdutosCompetitividadeAbaixo />
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </Container>
            </FiltrosContext.Provider>
        </Container>
    );
};

export default withRouter(CompetitividadeGeral);
