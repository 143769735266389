import Immutable from 'seamless-immutable';
import {
    RESET_ALL_FILTERS,
    SELECT_FILTER_VALUE,
    SET_ADDRESS_LIST,
    SET_FILTER_OBJECT,
    SET_FILTER_VALUE_LOJAS_CONCORRENTES,
    SET_FILTROS_DATA_LOJAS_CONCORRENTES,
    SET_SINGLE_REDUCER,
} from '../actions/actionsLojaConcorrentes';

const lojasConcorrentesInitialState = Immutable({
    filtrosValue: {
        tipoLoja: null,
        rede: null,
        endereco: null,
        cidade: null,
        uf: null,
    },
    filtrosData: {
        tipoLoja: {
            list: [],
            cache: [],
            value: [],
        },
        rede: {
            list: [],
            cache: [],
            value: [],
        },
        endereco: {
            list: [],
            cache: [],
            value: [],
            token: null,
        },
        cidade: {
            list: [],
            cache: [],
            value: [],
        },
        uf: {
            list: [],
            cache: [],
            value: [],
        },
    },
    stores: [],
    recenter: false,
});

function lojasConcorrentesDataReducer(
    state = lojasConcorrentesInitialState,
    action,
) {
    switch (action.type) {
        case SET_FILTER_VALUE_LOJAS_CONCORRENTES:
            return state.merge({
                ...state,
                filtrosValue: {
                    ...state.filtrosValue,
                    [action.name]: action.value,
                },
            });
        case SET_FILTROS_DATA_LOJAS_CONCORRENTES:
            return state.merge({
                ...state,
                filtrosData: {
                    ...state.filtrosData,
                    [action.name]: {
                        ...state.filtrosData[action.name],
                        list: state.filtrosData[action.name]?.value.length
                            ? state.filtrosData[action.name]?.cache.concat(
                                action.list.filter(
                                    (i) => !state.filtrosData[
                                        action.name
                                    ]?.value.includes(i.value),
                                ),
                            )
                            : action.list,
                    },
                },
            });
        case SELECT_FILTER_VALUE:
            return state.merge({
                ...state,
                filtrosData: {
                    ...state.filtrosData,
                    [action.name]: {
                        ...state.filtrosData[action.name],
                        cache: action.value.cache,
                        value: action.value.value,
                    },
                },
            });
        case SET_ADDRESS_LIST:
            return state.merge({
                ...state,
                filtrosData: {
                    ...state.filtrosData,
                    endereco: {
                        ...state.filtrosData.endereco,
                        list: state.filtrosData.endereco.value?.length
                            ? state.filtrosData.endereco.cache.concat(
                                action.list.filter(
                                    (i) => state.filtrosData.endereco?.value
                                        !== i.value,
                                ),
                            )
                            : action.list,
                        token: action.list.session_token,
                    },
                },
            });
        case SET_FILTER_OBJECT:
            return state.merge({
                ...state,
                filtrosData: {
                    ...state.filtrosData,
                    [action.name]: {
                        ...state.filtrosData[action.name],
                        list: action.value.list,
                        cache: action.value.cache,
                        value: action.value.value,
                    },
                },
            });
        case RESET_ALL_FILTERS:
            return state.merge({
                ...state,
                filtrosData: lojasConcorrentesInitialState.filtrosData,
                filtrosValue: lojasConcorrentesInitialState.filtrosValue,
            });
        case SET_SINGLE_REDUCER:
            return state.merge({
                ...state,
                [action.name]: action.value,
            });

        default:
            return state;
    }
}

export default lojasConcorrentesDataReducer;
