import React from 'react';
import { useSelector } from 'react-redux';
import { selectorCalculadoraPrevisaoDemanda } from '../../../../reducers/calculadoraPrevisaoDemanda/calculadoraPrevisaoDemandaReducer';
import * as utils from '../../utils';
import { ForecastNumbers } from '../ForecastNumbers';
import { Indicator } from '../Indicator';
import { ProductElasticity } from '../ProductElasticity';

import styles from './BigNumbersArea.module.scss';

export const BigNumbersArea = () => {
    const { resultScenario, filtros } = useSelector(
        selectorCalculadoraPrevisaoDemanda,
    );

    const { outputs } = resultScenario.resultScenarioData;

    const formatIndicatorValue = (value: number) => {
        if (value) {
            return value * 100;
        }
        return 0;
    };

    return (
        <div className={styles['first-chart-container']}>
            <ProductElasticity />
            <div className={styles['forecast-container']}>
                <div className={styles.div1}>
                    <ForecastNumbers.Root
                        title="preço tabela"
                        sideTitle={utils.paymentTypeText(filtros.filterRequestBody.payment_method)}
                    >
                        <ForecastNumbers.MainValue margin="6px 0 3px 0">
                            {utils.formatNumberToCurrency(outputs.new_price_scenario?.price, 2)}
                        </ForecastNumbers.MainValue>
                        <Indicator
                            value={formatIndicatorValue(outputs.base_to_new_variations?.price)}
                        />
                        <ForecastNumbers.SubValue margin="3px 0 0 0">
                            {utils.formatNumberToCurrency(outputs.base_price_scenario?.price, 2)}
                        </ForecastNumbers.SubValue>
                    </ForecastNumbers.Root>
                </div>
                <div className={styles.div2}>
                    <ForecastNumbers.Root title="margem de contribuição">
                        <ForecastNumbers.MainValue margin="6px 0 3px 0">
                            {outputs.new_price_scenario?.margin
                                ? utils.formatLocaleNumber(formatIndicatorValue(outputs.new_price_scenario?.margin), 1)
                                : 0}
                            %
                        </ForecastNumbers.MainValue>
                        <Indicator
                            type="pp"
                            value={formatIndicatorValue(outputs.base_to_new_variations?.margin)}
                        />
                        <ForecastNumbers.SubValue margin="3px 0 0 0">
                            {outputs.base_price_scenario?.margin
                                ? utils.formatLocaleNumber(formatIndicatorValue(outputs.base_price_scenario?.margin), 1)
                                : 0}
                            %
                        </ForecastNumbers.SubValue>
                    </ForecastNumbers.Root>
                </div>
                <div className={styles.div3}>
                    <ForecastNumbers.Root title="PREVISÃO DE DEMANDA (UN)">
                        <ForecastNumbers.MainValue margin="6px 0 3px 0">
                            {outputs.new_price_scenario?.demand_forecast
                                ? utils.formatLocaleNumber(outputs.new_price_scenario?.demand_forecast, 0)
                                : 0}
                        </ForecastNumbers.MainValue>
                        <Indicator value={formatIndicatorValue(outputs.base_to_new_variations?.demand_forecast)} />
                        <ForecastNumbers.SubValue margin="3px 0 0 0">
                            {outputs.base_price_scenario?.demand_forecast
                                ? utils.formatLocaleNumber(outputs.base_price_scenario?.demand_forecast, 0)
                                : 0}
                        </ForecastNumbers.SubValue>
                    </ForecastNumbers.Root>
                </div>
                <div className={styles.div4}>
                    <ForecastNumbers.Root title="PREVISÃO DE RECEITA (R$)">
                        <ForecastNumbers.MainValue margin="6px 0 3px 0">
                            {utils.formatNumberToCurrency(outputs.new_price_scenario?.revenue_forecast, 0)}
                        </ForecastNumbers.MainValue>
                        <Indicator value={formatIndicatorValue(outputs.base_to_new_variations?.revenue_forecast)} />
                        <ForecastNumbers.SubValue margin="3px 0 0 0">
                            {utils.formatNumberToCurrency(outputs.base_price_scenario?.revenue_forecast, 0)}
                        </ForecastNumbers.SubValue>
                    </ForecastNumbers.Root>
                </div>
                <div className={styles.div5}>
                    <ForecastNumbers.Root title="PREVISÃO LUCRO (R$)">
                        <ForecastNumbers.MainValue margin="6px 0 3px 0">
                            {utils.formatNumberToCurrency(outputs.new_price_scenario?.profit_forecast, 0)}
                        </ForecastNumbers.MainValue>
                        <Indicator value={formatIndicatorValue(outputs.base_to_new_variations?.profit_forecast)} />
                        <ForecastNumbers.SubValue margin="3px 0 0 0">
                            {utils.formatNumberToCurrency(outputs.base_price_scenario?.profit_forecast, 0)}
                        </ForecastNumbers.SubValue>
                    </ForecastNumbers.Root>
                </div>
            </div>
        </div>
  );
};
