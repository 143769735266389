import React from 'react';
import { Modal, Button } from 'rsuite';

const ModalConfirmacao = ({
    show,
    title,
    subtitle,
    message,
    onCancel,
    cancelButton,
    onConfirm,
    confirmButton,
    width,
    height,
}) => (
    <Modal
        backdrop
        show={show}
        className="new-modal-confirmacao"
        style={{ width, height }}
        onHide={onCancel}
    >
        <Modal.Header>
            <Modal.Title className="title">{title}</Modal.Title>
            {subtitle ? (
                <Modal.Title className="subtitle">{subtitle}</Modal.Title>
            ) : null}
        </Modal.Header>
        <Modal.Body>{message}</Modal.Body>
        <hr />
        <Modal.Footer>
            {cancelButton ? (
                <Button
                    onClick={onCancel}
                    appearance={confirmButton ? 'ghost' : 'primary'}
                    size="lg"
                >
                    {cancelButton}
                </Button>
            ) : null}
            {confirmButton ? (
                <Button onClick={onConfirm} appearance="primary" size="lg">
                    {confirmButton}
                </Button>
            ) : null}
        </Modal.Footer>
    </Modal>
);

export default ModalConfirmacao;
