import api from '../utils/API';

export const getHistorico = (data) =>

    api({
        url: process.env.REACT_APP_CLIENTE_URL + '/historico',
        method: 'POST',
        data: JSON.stringify(data),
        expectToken: true
    }).then((resp) => {
        return resp.data;
    })

