import * as ModuloRelatorioService from '../pages/ISA/InfoPanel/ModuloRelatorio/services';

export const SET_GENERATE_REPORT_DATA = 'SET_GENERATE_REPORT_DATA';
export const SET_CONSOLIDADO_DATA = 'SET_CONSOLIDADO_DATA';
export const SET_GENERATE_REPORT_RESPONSE = 'SET_GENERATE_REPORT_RESPONSE';
export const SET_GENERATE_REPORT_HISTORY_DATA =
    'SET_GENERATE_REPORT_HISTORY_DATA';
export const SET_TIPO_PROMOCAO_DATA = 'SET_TIPO_PROMOCAO_DATA';
export const SET_IS_SPECIFIC_DATES = 'SET_IS_SPECIFIC_DATES';
export const SET_SAVED_REPORT_LIST = 'SET_SAVED_REPORT_LIST';
export const SET_STATE_VALUE = 'SET_STATE_VALUE';
export const SET_SAVED_REPORT_ITEM_IN_MODULE_DATA =
    'SET_SAVED_REPORT_ITEM_IN_MODULE_DATA';
export const SET_MODULO_RELATORIO_DATA_TO_INITIAL_DATA =
    'SET_MODULO_RELATORIO_DATA_TO_INITIAL_DATA';
export const SET_SAVED_REPORT_ITEM_UPDATE = 'SET_SAVED_REPORT_ITEM_UPDATE';
export const SET_ENDERECO_LIST = 'SET_ENDERECO_LIST';
export const SET_ENDERECO_LAT_LON = 'SET_ENDERECO_LAT_LON';
export const SET_ENDERECO_RAIO = 'SET_ENDERECO_RAIO';

export function setGenerateReportData(name, data) {
    return {
        type: SET_GENERATE_REPORT_DATA,
        data,
        name,
    };
}

export function setConsolidadoData(name, data) {
    return {
        type: SET_CONSOLIDADO_DATA,
        name,
        data,
    };
}

export function setGenerateReportResponse(response, saveReport, line) {
    return {
        type: SET_GENERATE_REPORT_RESPONSE,
        data: response,
        line,
        saveReport,
    };
}

export function setGenerateReportHistoryData(response) {
    return {
        type: SET_GENERATE_REPORT_HISTORY_DATA,
        data: response,
    };
}

export function setTipoPromocaoData(name, response) {
    return {
        type: SET_TIPO_PROMOCAO_DATA,
        data: response,
        name,
    };
}

export function setIsSpecificDates(response) {
    return {
        type: SET_IS_SPECIFIC_DATES,
        data: response,
    };
}

export function setSavedReportList(response, loading) {
    return {
        type: SET_SAVED_REPORT_LIST,
        data: response,
        loading,
    };
}

export function setStateValue(name, response) {
    return {
        type: SET_STATE_VALUE,
        data: response,
        name,
    };
}

export function setSavedReportItemInModuleData(name, response) {
    return {
        type: SET_SAVED_REPORT_ITEM_IN_MODULE_DATA,
        data: response,
        name,
    };
}

export function setModuloRelatorioDataToInitialData() {
    return {
        type: SET_MODULO_RELATORIO_DATA_TO_INITIAL_DATA,
    };
}

export function setSavedReportItemUpdate(status) {
    return {
        type: SET_SAVED_REPORT_ITEM_UPDATE,
        status,
    };
}

export function setEnderecoList(list) {
    return {
        type: SET_ENDERECO_LIST,
        list,
    };
}

export function setEnderecoLatLon(data) {
    return {
        type: SET_ENDERECO_LAT_LON,
        data,
    };
}

export function setEnderecoRaio(data) {
    return {
        type: SET_ENDERECO_RAIO,
        data,
    };
}

export function generateReport(data) {
    return (dispatch) => {
        ModuloRelatorioService.generateReportService(data)
            .then((response) => {
                dispatch(
                    setGenerateReportResponse(
                        response.status,
                        data.relatorioSalvo,
                    ),
                );
                dispatch(setGenerateReportData('relatorioSalvo', false));
            })
            .catch((err) => {
                dispatch(
                    setGenerateReportResponse(
                        err.response.status,
                        data.relatorioSalvo,
                        err.response.status === 413
                            ? err.response.data.match(/\d+/g)[0]
                            : '0',
                    ),
                );
            });
    };
}

export function getHistoryList(params) {
    return (dispatch) => {
        ModuloRelatorioService.getGenerateReportHistoryListService(params).then(
            (response) => {
                if (response.status === 200) {
                    dispatch(setGenerateReportHistoryData(response.data));
                }
            },
        );
    };
}

export function getTipoPromocaoList() {
    return (dispatch) => {
        ModuloRelatorioService.getTipoPromocaoService().then((response) => {
            if (response.status === 200) {
                dispatch(setTipoPromocaoData('service', response.data));
            }
        });
    };
}

export function getSavedReportList(query) {
    return (dispatch) => {
        dispatch(setSavedReportList([], true));
        ModuloRelatorioService.getSavedReportListService(query).then(
            (response) => {
                if (response.status === 200) {
                    dispatch(setSavedReportList(response.data, false));
                }
            },
        );
    };
}

export function deleteSavedReportItem(id) {
    return (dispatch) => {
        dispatch(setStateValue('savedTableLoading', true));
        ModuloRelatorioService.deleteSavedReportItemService(id).then(
            (response) => {
                if (response.status === 200) {
                    dispatch(setStateValue('savedTableLoading', false));
                    dispatch(setStateValue('deleteSavedReportSuccess', true));

                    dispatch(
                        getSavedReportList({ page: 0, size: 10, query: '' }),
                    );
                }
            },
        );
    };
}

export function getSavedReportItem(id) {
    return (dispatch) => {
        ModuloRelatorioService.getSavedReportItemService(id).then(
            (response) => {
                if (response.status === 200) {
                    dispatch(
                        setStateValue('saveReportItemData', response.data),
                    );
                    dispatch(
                        setSavedReportItemInModuleData('insert', response.data),
                    );
                }
            },
        );
    };
}

export function saveEditedReport(id, data) {
    return (dispatch) => {
        ModuloRelatorioService.saveEditedReportService(id, data)
            .then((response) => {
                dispatch(setSavedReportItemUpdate(response.status));
            })
            .catch((err) => {
                dispatch(setSavedReportItemUpdate(err.response.status));
            });
    };
}

export function searchEndereco(data) {
    return (dispatch) => {
        if (data.query) {
            ModuloRelatorioService.getEndereco(data).then((data) =>
                dispatch(setEnderecoList(data)),
            );
        }
    };
}
