import React, { memo } from 'react';
import { Table } from 'rsuite';
import useGerenciadorPagination from './useGerenciadorPagination';

const { Pagination } = Table;

const TablePagination = () => {
    const pagination = useGerenciadorPagination();
    return <Pagination {...pagination} />;
};

export default memo(TablePagination);
