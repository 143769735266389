export const SET_CLIENTE = 'SET_CLIENTE';
export const SET_SERVICOS_CLIENTE = 'SET_SERVICOS_CLIENTE';
export const SET_NAME_CLIENTE = 'SET_NAME_CLIENTE';
export const SET_CLIENTE_DATA = 'SET_CLIENTE_DATA';

export function setCliente(codigoCliente) {
    return {
        type: SET_CLIENTE,
        codigoCliente,
    };
}

export function setServicosCliente(servicosCliente) {
    return {
        type: SET_SERVICOS_CLIENTE,
        servicosCliente,
    };
}

export function setNameCliente(nameCliente) {
    return {
        type: SET_NAME_CLIENTE,
        nameCliente,
    };
}

export function setClienteData(data) {
    return {
        type: SET_CLIENTE_DATA,
        data,
    };
}
