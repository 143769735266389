import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Button,
    Col,
    Content,
    Dropdown,
    Grid,
    Icon,
    Row,
    SelectPicker,
} from 'rsuite';
import IconCircleThreeDots from '../../../../../../assets/icons/icon_circle_three_dots.svg';
import IconButton from '../../../../../../components/IconButton';
import {
    reducerOnDemandSelector,
    setOnDemandState,
} from '../../../../../../reducers/reducerOnDemand';
import styles from './RequestDetailContainer.module.scss';

const downloadTypeData = [
    {
        label: 'Lista de solicitações',
        value: 'list',
    },
    {
        label: 'Preços retornados',
        value: 'price',
    },
];

const RequestDetailContainer = ({
    requests,
    download,
    checkedItems,
    onClickSelectWholeList,
    onClickUpdateStatus,
    tableArea,
    filterArea,
}) => {
    const [totalElements, setTotalElements] = useState(checkedItems.length);
    const { downloadType, tableRequestBody } = useSelector(
        reducerOnDemandSelector,
    );

    const dispatch = useDispatch();

    const handleSelectDownloadType = (value) => {
        dispatch(
            setOnDemandState({
                name: 'downloadType',
                data: value,
            }),
        );
    };

    const handleSelectTotalElements = () => {
        onClickSelectWholeList();
        setTotalElements(requests?.totalElements);
    };

    useEffect(() => {
        if (tableRequestBody.requestdAll) {
            setTotalElements(requests?.totalElements);
        }

        if (!tableRequestBody.requestdAll && checkedItems.length) {
            setTotalElements(checkedItems.length);
        }
    }, [tableRequestBody.requestdAll, checkedItems.length]);

    return (
        <Content className={styles['data-table']}>
            <Grid>
                <Row>
                    <Col xs={24} md={8}>
                        <h3 className={styles.title}>
                            Detalhamento das Solicitações
                        </h3>
                    </Col>
                    <Col xs={24} md={3} mdPush={10}>
                        <SelectPicker
                            className={styles['download-type']}
                            block
                            cleanable={false}
                            searchable={false}
                            data={downloadTypeData}
                            value={downloadType}
                            onSelect={(value) =>
                                handleSelectDownloadType(value)
                            }
                        />
                    </Col>
                    <Col xs={24} md={3} mdPush={10}>
                        <Dropdown
                            id="download-btn"
                            placement="bottomEnd"
                            title="Download"
                            renderTitle={() => (
                                <Button block appearance="primary">
                                    <Icon icon="cloud-download" /> Download{' '}
                                </Button>
                            )}
                        >
                            <Dropdown.Item
                                id="download-csv-file"
                                eventKey="csv"
                                onSelect={download}
                                icon={<Icon icon="file-text-o" />}
                            >
                                .csv
                            </Dropdown.Item>
                            <Dropdown.Item
                                id="download-xlsx-file"
                                eventKey="xlsx"
                                onSelect={download}
                                icon={<Icon icon="file-excel-o" />}
                            >
                                .xlsx
                            </Dropdown.Item>
                        </Dropdown>
                    </Col>
                </Row>
                <Row>{filterArea}</Row>
            </Grid>
            <div className={styles['data-table-container']}>
                {checkedItems?.length ? (
                    <div className={styles['bulk-action-buttons']}>
                        <div className={styles['all-selection-wrapper']}>
                            {`${totalElements} solicitações selecionadas`}
                            <Button
                                appearance="link"
                                onClick={handleSelectTotalElements}
                            >
                                {`Selecionar todas as ${requests?.totalElements} solicitações da tabela`}
                            </Button>
                        </div>
                        <IconButton
                            icon={IconCircleThreeDots}
                            label="Alterar status"
                            size="sm"
                            onClick={onClickUpdateStatus}
                        />
                        {/* <IconButton
                            icon={IconCalendar}
                            label="Alterar vigência"
                            size="sm"
                            disabled
                        /> */}
                    </div>
                ) : null}
                {requests?.content?.length ? (
                    tableArea
                ) : (
                    <div className={styles['empty-results-container']}>
                        <p className={styles['empty-results-title']}>
                            Nenhuma solicitação encontrada
                        </p>
                        <p>Revise os filtros utilizados e tente novamente.</p>
                    </div>
                )}
            </div>
        </Content>
    );
};
export default RequestDetailContainer;
