import React from 'react';
import { InputAddon } from '../../InputAddon';
import { InputCurrency } from '../../InputCurrency';
import { InputGroup } from '../../InputGroup';
import { BaseCell } from '../BaseCell';
import { BaseCellProps } from '../BaseCell/BaseCell';

const { MainContainer } = BaseCell;

type DiscountCellProps = BaseCellProps & {
    rowData: { discountPercentage: number | null };
    index: number;
    handleChangeValue: (value: number, index: number) => void;
    handleBlur: (index: number) => void;
};

const DiscountCell = ({
    rowData,
    index,
    onBlur,
    handleChangeValue,
    handleBlur,
    ...props
}: DiscountCellProps) => {
    return (
        <BaseCell {...props}>
            <MainContainer>
                <InputGroup>
                    <InputCurrency
                        value={rowData.discountPercentage as number}
                        allowNegative={false}
                        precision="1"
                        onChangeEvent={(_e, _m, value) => {
                            handleChangeValue(value, index);
                        }}
                        decimalSeparator=","
                        className="discountPercentage"
                        onBlur={(e, _v, details) => {
                            onBlur?.(e);
                            if (details.hasChanges) {
                                handleBlur?.(index);
                            }
                        }}
                    />
                    <InputAddon>%</InputAddon>
                </InputGroup>
            </MainContainer>
        </BaseCell>
    );
};

export default DiscountCell;
