
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import {
    Container,
    Content,
    FlexboxGrid,
    Col,
    Form,
    FormGroup,
    HelpBlock,
    FormControl,
    ControlLabel,
    Button,
    InputGroup,
    Icon,
    Divider,
    Whisper,
    Row,
    Nav,
    IconButton,
    Tag,
    Popover,
    ButtonToolbar,
    CheckPicker,
} from 'rsuite';
import CurrencyInput from 'react-currency-input';
import CurrencyFormat from 'react-currency-format';
import { LoadingSpiner } from '../components/LoadingSpinner';
import {
    addSegmento,
    deleteSegmento,
    changeValueSegmento,
    changeCacheSegmento,
    selectValueSegmento,
    searchSegmentos,
    searchItensSegmento,
    clearSegmentos,
    setSegmentosUpdateRegra
} from '../actions/actionsRegraPrecoAtacadoCadastro';



class RegraPrecoAtacadoCadastroPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            errors: {},
            fator: null
        }
    }

    componentDidMount() {
        this.props.searchSegmentos();
    }


    handleChange = (value, e) => {
        this.setState({
            [e.target.name]: value
        });
    }


    handleChangeFator = (value, e) => {
        this.setState({
            fator: parseFloat(e.replace(',', '.'))
        });
    }

    hasNoSegmento() {
        var segmentoRegra = {
            nivel_1: this.props.segmentos.findIndex((item) => { return item.nivel === 1 }) !== -1 ? this.props.segmentosData[this.props.segmentos[this.props.segmentos.findIndex((item) => { return item.nivel === 1 })].name].value : null,
            nivel_2: this.props.segmentos.findIndex((item) => { return item.nivel === 2 }) !== -1 ? this.props.segmentosData[this.props.segmentos[this.props.segmentos.findIndex((item) => { return item.nivel === 2 })].name].value : null,
            nivel_3: this.props.segmentos.findIndex((item) => { return item.nivel === 3 }) !== -1 ? this.props.segmentosData[this.props.segmentos[this.props.segmentos.findIndex((item) => { return item.nivel === 3 })].name].value : null,
            nivel_4: this.props.segmentos.findIndex((item) => { return item.nivel === 4 }) !== -1 ? this.props.segmentosData[this.props.segmentos[this.props.segmentos.findIndex((item) => { return item.nivel === 4 })].name].value : null,
            nivel_5: this.props.segmentos.findIndex((item) => { return item.nivel === 5 }) !== -1 ? this.props.segmentosData[this.props.segmentos[this.props.segmentos.findIndex((item) => { return item.nivel === 5 })].name].value : null,
            nivel_6: this.props.segmentos.findIndex((item) => { return item.nivel === 6 }) !== -1 ? this.props.segmentosData[this.props.segmentos[this.props.segmentos.findIndex((item) => { return item.nivel === 6 })].name].value : null,
            nivel_7: this.props.segmentos.findIndex((item) => { return item.nivel === 7 }) !== -1 ? this.props.segmentosData[this.props.segmentos[this.props.segmentos.findIndex((item) => { return item.nivel === 7 })].name].value : null
        };

        if (
            (segmentoRegra.nivel_1 === null || !segmentoRegra.nivel_1.length) &&
            (segmentoRegra.nivel_2 === null || !segmentoRegra.nivel_2.length) &&
            (segmentoRegra.nivel_3 === null || !segmentoRegra.nivel_3.length) &&
            (segmentoRegra.nivel_4 === null || !segmentoRegra.nivel_4.length) &&
            (segmentoRegra.nivel_5 === null || !segmentoRegra.nivel_5.length) &&
            (segmentoRegra.nivel_6 === null || !segmentoRegra.nivel_6.length) &&
            (segmentoRegra.nivel_7 === null || !segmentoRegra.nivel_7.length)
        ) {
            return true;
        } else {
            return false;
        }
    }

    validForm() {
        let errors = {};
        let formIsValid = true;

        const nome = document.getElementsByName('nome')[0];
        if (!this.state.nome || !this.state.nome.length) {
            formIsValid = false;
            errors["nome"] = "Campo obrigatório";

            if (nome) {
                nome.classList.add('form__input_invalid')
            }
        } else {
            if (nome) {
                nome.classList.remove('form__input_invalid')
            }
        }

        const fator = document.getElementsByName('fator')[0];
        if (!this.state.fator || this.state.fator < 0) {
            formIsValid = false;
            errors["fator"] = "Campo obrigatório";

            if (fator) {
                fator.classList.add('form__input_invalid')
            }
        } else {
            if (fator) {
                fator.classList.remove('form__input_invalid')
            }
        }

        if (this.hasNoSegmento()) {
            formIsValid = false;
            errors["segmentos"] = "Selecione pelo menos um segmento";
        }

        this.setState({ errors: errors });
        return formIsValid;
    }

    render() {
        const { nome } = this.state;
        const { segmentosAdicionados } = this.props;


        return (
            <Container className="main-container regra-atacado-cadastro-page">
                <Content>
                    <LoadingSpiner size="md" />
                    <FlexboxGrid>
                        <FlexboxGrid.Item componentClass={Col} lg={24} md={24} sm={24} xs={24}>
                            <h4>Preço para atacado</h4>
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                    <FlexboxGrid>
                        <FlexboxGrid.Item componentClass={Col} lg={24} md={24} sm={24} xs={24} className="info-section">
                            <p><b>Atenção!</b></p>
                            <p>o fator para <b>Preço atacado</b> somente será aplicado na <b>Definição automática</b> de preços quando o produto possuir um <b>gatilho atacado</b> cadastrado</p>
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                    <Form fluid>
                        <FlexboxGrid>
                            <FlexboxGrid.Item componentClass={Col} lg={24} md={24} sm={24} xs={24}>
                                <FormGroup>
                                    <ControlLabel>Nome </ControlLabel>
                                    <FormControl name="nome" value={nome} onChange={this.handleChange} />
                                    <HelpBlock className="form__message_invalid" name="erroNome">{this.state.errors["nome"]}</HelpBlock>
                                </FormGroup>
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                        <Divider />

                        <FlexboxGrid>
                            <FlexboxGrid.Item>
                                <AddFiltroComponent />
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                        <FlexboxGrid>
                            {segmentosAdicionados.map((filtro, index) => (
                                <FiltroCustomizado key={filtro.name} filtro={filtro} deleteFiltro={this.handleDeleteSegmento} index={index} />
                            ))}
                            <FlexboxGrid.Item>
                                <HelpBlock className="form__message_invalid" name="erroSegmentos">{this.state.errors["segmentos"]}</HelpBlock>
                            </FlexboxGrid.Item>
                        </FlexboxGrid>

                        <Divider />
                        <FlexboxGrid align="middle">
                            <FlexboxGrid.Item componentClass={Col} lg={3} md={3} sm={24} xs={24}>
                                <FormGroup>
                                    <ControlLabel>Fator para atacado </ControlLabel>
                                    <InputGroup className="info-preco__input-group">
                                        <CurrencyInput name="fator" className="rs-input" decimalSeparator="," thousandSeparator="." precision="2" allowEmpty={true} allowNegative={false}
                                            onChangeEvent={this.handleChangeFator} value={this.state.fator} />
                                        <InputGroup.Addon>%</InputGroup.Addon>
                                    </InputGroup>

                                    <HelpBlock className="info-preco__message_invalid" name="erroCompetitividade">{this.state.errors["fator"]}</HelpBlock>
                                </FormGroup>
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item componentClass={Col} lg={4} md={5} sm={24} xs={24}>
                                <span>mais barato que preço varejo</span>
                            </FlexboxGrid.Item>
                            {this.state.fator ?
                                <FlexboxGrid.Item componentClass={Col} lg={5} md={5} sm={24} xs={24}>
                                    <FormGroup>
                                        <ControlLabel>Exemplo</ControlLabel>
                                        <span>Preço varejo: <CurrencyFormat fixedDecimalScale={true} decimalScale={2} value={10 || 0} displayType={'text'} prefix={'R$'} decimalSeparator={','} thousandSeparator={'.'} /></span> <Icon icon="caret-right" className="margin-10" /> <span>Preço atacado: <CurrencyFormat fixedDecimalScale={true} decimalScale={2} value={10 - (10 * (this.state.fator / 100))} displayType={'text'} prefix={'R$'} decimalSeparator={','} thousandSeparator={'.'} /></span>
                                    </FormGroup>
                                </FlexboxGrid.Item> : null
                            }
                        </FlexboxGrid>
                        <FlexboxGrid>
                            <FlexboxGrid.Item componentClass={Col} lg={2} md={4} sm={24} xs={24}>
                                <Button onClick={this.createRegra} appearance="primary">
                                    {this.props.match.params.id ? "Editar regra" : "Criar regra"}
                                </Button>
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item componentClass={Col} lg={2} md={4} sm={24} xs={24}>
                                <Button onClick={this.back}>
                                    Cancelar
                                </Button>
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                    </Form>
                </Content>
            </Container>
        )
    }
}

const AddFiltroComponent = () => {
    return (
        <Whisper
            trigger="click"
            placement="bottomStart"
            speaker={<Speaker className="popover-filtro-dinamico" />}
        >
            <ButtonToolbar>
                <IconButton icon={<Icon icon="plus" />}>Adicionar segmento</IconButton>
            </ButtonToolbar>
        </Whisper>
    )
}

class Speaker extends Component {
    constructor(props) {
        super(props);

        this.handleClick = this.handleClick.bind(this);
    }
    handleClick = (eventKey, b) => {
        if (this.props.segmentosAdicionados.findIndex(item => { return item.name === b.target.name }) === -1) {
            var searchFunction = this.props.segmentos[this.props.segmentos.findIndex((item) => { return item.name === b.target.name })].onSearch;
            this.props[searchFunction](eventKey, '', b.target.name);
            this.props.addSegmento(this.props.segmentos[this.props.segmentos.findIndex((item) => { return item.name === b.target.name })]);

        }
    }

    render() {
        return (
            <Popover {...this.props} >
                <Row className="popover-filtro-dinamico__row">
                    {this.props.tiposSegmento.map(item => (

                        <Col md={12} key={item.title} className="popover-filtro-dinamico__col">
                            <CustomNav title={item.title} icon={item.icon} itens={item.itens} onSelect={this.handleClick} />
                        </Col>
                    ))}
                </Row>
            </Popover>
        )
    }
};

const CustomNav = ({ title, icon, itens, onSelect, ...props }) => {
    return (
        <Nav {...props} vertical onSelect={onSelect} className="nav-filtro-dinamico">
            <div className="nav-filtro-dinamico__title-div">
                <Icon icon={icon}></Icon>
                <p className="nav-filtro-dinamico__title">{title}</p>
            </div>
            {itens.map((item) => {
                return (
                    <Nav.Item key={item.name} name={item.name} eventKey={item.name}>{item.title}</Nav.Item>
                )
            })}
        </Nav>
    );
};

class FiltroCustomizado extends Component {

    handleChange(value, event, item) {
        this.props.changeValueSegmento(value, item.name);

        let cache;
        if (value === null) {
            cache = [];
            this.props.searchItensSegmento(item.nivel, { query: '' }, item.name);
            this.props.changeCacheSegmento(item.name, cache)
        }
        if (value !== null && value.length !== this.props.segmentosData[`${item.name}`].cache.length) {
            cache = this.props.segmentosData[`${item.name}`].cache.filter(function (item) {
                return value.indexOf(item.label) !== -1
            });

            this.props.changeCacheSegmento(item.name, cache);
        }
    }

    handleItensAfetados = (item) => {

        this.props[item.onSearch](item.name, { query: '' }, item.name);
    }


    handleSelect = (value, item, name) => {
        this.props.selectValueSegmento(item, name);
    }

    render() {
        const { filtro, index, deleteFiltro } = this.props;

        return (
            <FlexboxGrid.Item className="filtro-dinamico" componentClass={Col} lg={12} md={12}>
                <FlexboxGrid.Item componentClass={Col} lg={4} md={6}>
                    <Tag className="filtro-dinamico__tag">{filtro.title}</Tag>
                </FlexboxGrid.Item>
                <FlexboxGrid.Item className="filtro-dinamico__input-section" componentClass={Col} lg={15} md={14} sm={24} xs={24}>
                    <CheckPicker
                        cleanable={filtro.cleanable}
                        searchable={filtro.searchable}
                        filter={filtro.filter}
                        data={this.props.segmentosData[filtro.name] ? this.props.segmentosData[filtro.name].lista : []}
                        value={this.props.segmentosData[filtro.name] ? this.props.segmentosData[filtro.name].value : []}
                        name={filtro.name}
                        placeholder={filtro.placeholder}
                        locale={filtro.locale}
                        onSelect={filtro.onSelect ? (value, i) => this.handleSelect(value, i, filtro.name) : null}
                        onClean={filtro.onClean ? () => this[filtro.onClean]('') : null}
                        onChange={filtro.onChange ? (value, e) => setTimeout(function () { this[filtro.onChange](value, e, filtro) }.bind(this), 100) : null}
                        onSearch={filtro.onSearch ? (search) => this.props[filtro.onSearch](filtro.name, { query: search }, filtro.name) : null}
                        onClose={() => this.handleItensAfetados(filtro)}
                    />
                </FlexboxGrid.Item>
                <FlexboxGrid.Item componentClass={Col} lg={2} md={2}>
                    <IconButton className="filtro-dinamico__delete-button" icon={<Icon icon="trash2" />} onClick={() => deleteFiltro(index, filtro.name)} />
                </FlexboxGrid.Item>
            </FlexboxGrid.Item>
        )
    }
};



const mapStateToProps = store => ({
    tiposSegmento: store.regraAtacadoCadastroDataReducer.tiposSegmento,
    segmentos: store.regraAtacadoCadastroDataReducer.segmentos,
    segmentosAdicionados: store.regraAtacadoCadastroDataReducer.segmentosAdicionados,
    segmentosData: store.regraAtacadoCadastroDataReducer.segmentosData
})

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            addSegmento,
            deleteSegmento,
            selectValueSegmento,
            searchSegmentos,
            searchItensSegmento,
            changeValueSegmento,
            changeCacheSegmento,
            clearSegmentos,
            setSegmentosUpdateRegra,
        }, dispatch);


Speaker = connect(mapStateToProps, mapDispatchToProps)(Speaker)
FiltroCustomizado = connect(mapStateToProps, mapDispatchToProps)(FiltroCustomizado)
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RegraPrecoAtacadoCadastroPage));
