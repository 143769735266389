import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Modal,
    Input,
    Button,
} from 'rsuite';
import {
    setResultScenarioData,
} from '../../../../reducers/calculadoraPrevisaoDemanda/resultScenario';
import { selectorCalculadoraPrevisaoDemanda } from '../../../../reducers/calculadoraPrevisaoDemanda';
import styles from './ModalSaveScenario.module.scss';

const {
 Header, Title, Body, Footer,
} = Modal;

type ModalSaveScenarioProps = {
    show?: boolean,
    title?: string,
    onHide?: () => void,
    onClickSave?: () => void,
}

export const ModalSaveScenario = ({
 show, title, onHide, onClickSave,
}: ModalSaveScenarioProps) => {
    const { resultScenario } = useSelector(selectorCalculadoraPrevisaoDemanda);
    const { scenario } = resultScenario.resultScenarioData;
    const dispatch = useDispatch();

    const handleChangeName = (value: string) => {
        dispatch(setResultScenarioData({
            name: 'resultScenarioData',
            data: {
                ...resultScenario.resultScenarioData,
                scenario: {
                    ...scenario,
                    scenario_name: value,
                },
            },
        }));
    };

    return (
        <Modal
            className={styles.modal}
            show={show}
            onHide={onHide}
        >
            <Header onHide={onHide}>
                <Title>
                    {title}
                    {' '}
                    cenário
                </Title>
            </Header>
            <Body>
                <label
                    htmlFor="scenario-name"
                    className={styles['input-label']}
                >
                    Nome do cenário
                </label>
                <Input
                    name="scenario-name"
                    className={styles['input-name']}
                    value={scenario.scenario_name}
                    onChange={handleChangeName}
                />
            </Body>
            <Footer>
                <Button
                    className={styles['btn-cancel']}
                    appearance="ghost"
                    onClick={onHide}
                >
                    Fechar
                </Button>
                <Button
                    id="btn-save-scenario"
                    className={styles['btn-save']}
                    appearance="primary"
                    onClick={onClickSave}
                >
                    Salvar
                </Button>
            </Footer>
        </Modal>
    );
};
