import { trackPromise } from 'react-promise-tracker';

import api from '../../../utils/API';

export const getRanges = () => api({
        url: `${process.env.REACT_APP_IPA_URL}/pricing/ranges`,
        method: 'GET',
        expectToken: true,
    }).then((resp) => resp.data);

export const saveRanges = (data) => trackPromise(
        api({
            url: `${process.env.REACT_APP_IPA_URL}/pricing/ranges`,
            method: 'POST',
            params: {
                clientId: JSON.parse(localStorage.getItem('cliente'))?.codigo,
            },
            expectToken: true,
            data: JSON.stringify(data),
        }).then((resp) => resp.data || resp),
    );
