import React from 'react';
import { Tooltip, Whisper } from 'rsuite';

export const HoverTooltip = ({ placement, text }) => (
    <Whisper
        trigger="hover"
        placement={placement}
        speaker={<Tooltip>{text}</Tooltip>}
    >
        <span>{text}</span>
    </Whisper>
);
