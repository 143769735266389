import cn from 'classnames';
import React, { ComponentProps } from 'react';
import styles from './BaseCell.module.scss';

export type BaseCellProps = ComponentProps<'div'>;

const BaseCell = ({ className, ...props }: BaseCellProps) => {
    return <div className={cn(styles.container, className)} {...props} />;
};

const MainContainer = ({
    className,
    ...props
}: React.HtmlHTMLAttributes<HTMLDivElement>) => {
    return <div className={cn(styles.container__main, className)} {...props} />;
};

const SubContainer = ({
    className,
    ...props
}: React.HtmlHTMLAttributes<HTMLDivElement>) => {
    return <div className={cn(styles.container__sub, className)} {...props} />;
};

BaseCell.MainContainer = MainContainer;
BaseCell.SubContainer = SubContainer;

export default BaseCell;
