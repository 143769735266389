import React from 'react';
import {
    Modal, Button, FlexboxGrid, Table, Col,
} from 'rsuite';

const { Column, HeaderCell, Cell } = Table;

const ModalPreviewDownload = ({
    show, close, itensPreview, columnsPreview, confirm,
}) => (

    <Modal full size="lg" show={show} onHide={close}>
        <Modal.Header>
            <Modal.Title>Exemplo de download</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <FlexboxGrid>
                <FlexboxGrid.Item componentClass={Col} lg={24} md={24} sm={24} xs={24}>
                    <p>O arquivo de download terá apenas colunas selecionadas. Abaixo você consegue visualizar um exemplo:</p>
                </FlexboxGrid.Item>
                {itensPreview?.length
                    ? (
                        <FlexboxGrid.Item componentClass={Col} lg={24} md={24} sm={24} xs={24}>
                            <Table
                                virtualized
                                height={200}
                                data={itensPreview}
                            >

                                {columnsPreview?.length ? columnsPreview.map((coluna) => (
                                    <Column
                                        key={coluna.column}
                                        width={coluna.descricao.length <= 12
                                            ? 150 : coluna.descricao.length > 12 && coluna.descricao.length < 20
                                                ? 170 : coluna.descricao.length > 20 ? 230 : 250}
                                    >
                                        <HeaderCell>
                                            {coluna.descricao}
                                        </HeaderCell>
                                        <Cell dataKey={coluna.column}>
                                            {(rowData) => (
                                                <span title={`${rowData[coluna.column]}`}>{rowData[coluna.column]}</span>
                                            )}
                                        </Cell>
                                    </Column>
                                )) : null}
                            </Table>
                        </FlexboxGrid.Item>
                    )
                    : null}
            </FlexboxGrid>
        </Modal.Body>
        <Modal.Footer>
            <Button onClick={close} appearance="default">
                Cancelar
            </Button>
            <Button onClick={confirm} appearance="primary">
                Confirmar download
            </Button>
        </Modal.Footer>
    </Modal>
);

export default ModalPreviewDownload;
