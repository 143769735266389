import React from 'react';
import Slider from 'react-slick';
import { Button } from 'rsuite';
import PriceTypeBox from '../PriceTypeBox';
import ProductInfoBox from '../ProductInfoBox';
import styles from './styles.module.scss';

import chevroDownIcon from '../../../../../../assets/icons/icon_chevron_down-blue.svg';
import { LoadingSpinerLocal } from '../../../../../../components/LoadingSpinner';
import { handleDateFormat } from '../../../../../../utils/Helpers';

import { formatProductDashboardPrice } from '../../utils';

import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
};

const ProductSlider = ({ products, onClickSeeAll, loading }) => (
    <div className={products?.length === 1 ? styles.content2 : styles.content}>
        <LoadingSpinerLocal loading={loading} size="md" />
        <Slider
            {...settings}
            className={`${styles.carousel} ${products?.length === 1
                ? styles['carousel-padding2']
                : styles['carousel-padding']
                }`}
        >
            {products?.length
                ? products.map((item) => (
                    <div
                        key={item.identificador}
                        className={styles['wrapper-slick']}
                    >
                        <ProductInfoBox
                            image={item.gtin_link_imagem}
                            productTitle={item.descricao}
                            gtinValue={item.gtin}
                            secaoValue={item.secao}
                            categoryValue={item.categoria}
                        />
                        <div>
                            <PriceTypeBox
                                headerText="Último preço"
                                priceValue={formatProductDashboardPrice(
                                    item.ultimo_preco,
                                )}
                                bottomValue={handleDateFormat(item.data_preco)}
                                className={styles['price-type']}
                            />
                        </div>
                        <PriceTypeBox
                            headerText="Preço médio"
                            priceValue={formatProductDashboardPrice(
                                item.preco_medio,
                            )}
                            bottomValue={item.total_datapoints}
                            className={styles['price-type']}
                        />
                        <PriceTypeBox
                            headerText="Preço mais frequente"
                            priceValue={formatProductDashboardPrice(
                                item.preco_mais_frequente,
                            )}
                            bottomValue={`${item.percent_topk
                                .toFixed(1)
                                .toString()
                                .replace('.', ',')}%`}
                        />
                    </div>
                ))
                : null}
        </Slider>
        {products?.length && products?.length !== 1 ? (
            <div className={styles['see-all']}>
                <div>
                    <span className={styles['bottom-bold-text']}>
                        {products?.length}
                        {' '}
                        produtos
                    </span>
                    <span className={styles['bottom-regular-text']}>
                        estão inclusos na seleção.
                    </span>
                </div>
                <Button
                    appearance="link"
                    className={styles['see-all-button']}
                    onClick={onClickSeeAll}
                >
                    Ver todos os produtos selecionados
                    <img src={chevroDownIcon} alt="" />
                </Button>
            </div>
        ) : null}
    </div>
);

export default ProductSlider;
