import React, { CSSProperties, HTMLAttributes } from 'react';
import styles from './styles.module.scss';

export interface IBoxBoldText{
    children: string | number;
    style?: CSSProperties;
    className?: HTMLAttributes<HTMLParagraphElement>;
}

export const BoxBoldText = ({ children, style, className }: IBoxBoldText) => (
    <p className={`${styles['bold-text']} ${className}`} style={style}>{children}</p>
);
