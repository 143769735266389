import React from 'react';

export const MdStore = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        {...props}
    >
        <path
            d="M4.16722 4.99967H15.8339C16.2922 4.99967 16.6672 4.62467 16.6672 4.16634C16.6672 3.70801 16.2922 3.33301 15.8339 3.33301H4.16722C3.70888 3.33301 3.33388 3.70801 3.33388 4.16634C3.33388 4.62467 3.70888 4.99967 4.16722 4.99967ZM16.8005 6.49967C16.7255 6.11634 16.3839 5.83301 15.9839 5.83301H4.01722C3.61722 5.83301 3.27555 6.11634 3.20055 6.49967L2.36722 10.6663C2.26722 11.183 2.65888 11.6663 3.18388 11.6663H3.33388V15.833C3.33388 16.2913 3.70888 16.6663 4.16722 16.6663H10.8339C11.2922 16.6663 11.6672 16.2913 11.6672 15.833V11.6663H15.0005V15.833C15.0005 16.2913 15.3756 16.6663 15.8339 16.6663C16.2922 16.6663 16.6672 16.2913 16.6672 15.833V11.6663H16.8172C17.3422 11.6663 17.7339 11.183 17.6339 10.6663L16.8005 6.49967ZM10.0005 14.9997H5.00055V11.6663H10.0005V14.9997Z"
            fill="currentColor"
        />
    </svg>
);
