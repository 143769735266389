import React from 'react';

export const Premium = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="12"
        viewBox="0 0 16 12"
        fill="none"
        {...props}
    >
        <path
            d="M8.51042 2.35428C8.7875 2.18414 8.97222 1.87546 8.97222 1.52789C8.97222 0.990734 8.53715 0.555664 8 0.555664C7.46285 0.555664 7.02778 0.990734 7.02778 1.52789C7.02778 1.87789 7.2125 2.18414 7.48958 2.35428L6.09688 5.13969C5.87569 5.58205 5.30208 5.70844 4.91563 5.39976L2.75 3.66678C2.87153 3.50393 2.94444 3.30219 2.94444 3.08344C2.94444 2.54629 2.50937 2.11122 1.97222 2.11122C1.43507 2.11122 1 2.54629 1 3.08344C1 3.62059 1.43507 4.05566 1.97222 4.05566C1.97708 4.05566 1.98438 4.05566 1.98924 4.05566L3.1 10.1661C3.23368 10.905 3.87778 11.4446 4.63125 11.4446H11.3688C12.1198 11.4446 12.7639 10.9074 12.9 10.1661L14.0108 4.05566C14.0156 4.05566 14.0229 4.05566 14.0278 4.05566C14.5649 4.05566 15 3.62059 15 3.08344C15 2.54629 14.5649 2.11122 14.0278 2.11122C13.4906 2.11122 13.0556 2.54629 13.0556 3.08344C13.0556 3.30219 13.1285 3.50393 13.25 3.66678L11.0844 5.39976C10.6979 5.70844 10.1243 5.58205 9.90312 5.13969L8.51042 2.35428Z"
            fill="url(#paint0_linear_375_22784)"
            stroke="#CB6F10"
            strokeWidth="0.5"
        />
        <defs>
            <linearGradient
                id="paint0_linear_375_22784"
                x1="5.8125"
                y1="5.90308"
                x2="8.4375"
                y2="10.7156"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#E09233" />
                <stop offset="1" stopColor="#CB6F10" />
            </linearGradient>
        </defs>
    </svg>
);
