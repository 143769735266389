import { useContext } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getVendaBrutaDosItensPesquisados } from '../services';
// @ts-ignore
import { FiltrosContext } from '../Pages/CompetitividadeGeral/index.tsx';
import { format } from 'date-fns';

export const useVendaBruta = () => {
    const filtros = useContext(FiltrosContext);

    const listVendaBrutaDosItensPesquisados = async () => {
        const params = {
            filters: [
                {
                    filter: 'STOREID',
                    values: filtros?.lojasSelecionadas || null,
                },
                {
                    filter: 'PRODUCTID',
                    values: filtros?.produtosSelecionados || null,
                },
                {
                    filter: 'STARTDATE',
                    values: filtros?.periodoSelecionado?.length
                        ? [format(filtros.periodoSelecionado[0], 'yyyy-MM-dd')]
                        : null,
                },
                {
                    filter: 'ENDDATE',
                    values: filtros?.periodoSelecionado?.length
                        ? [format(filtros.periodoSelecionado[1], 'yyyy-MM-dd')]
                        : null,
                },
                {
                    filter: 'STORETYPE',
                    values: filtros?.tipoLojasSelecionadas || null,
                },
                {
                    filter: 'CATEGORYLEVEL1',
                    values: filtros?.categoriaNivel1Selecionadas || null,
                },
                {
                    filter: 'CATEGORYLEVEL2',
                    values: filtros?.categoriaNivel2Selecionadas || null,
                },
                {
                    filter: 'COMPETITORSTOREID',
                    values: filtros?.competitividadeSelecionada || null,
                },
            ],
        };
        const resp = await getVendaBrutaDosItensPesquisados(params);
        const key = Object.keys(resp)[0]?.split('.');
        return resp[Object.keys(resp)[0]]
            ? {
                  [key[1]]: parseFloat(
                      resp[Object.keys(resp)[0]]
                  )?.toLocaleString('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                  }),
              }
            : null;
    };

    const { isLoading, isError, data, error } = useQuery(
        ['vendaBrutaDosItensPesquisados', filtros],
        listVendaBrutaDosItensPesquisados,
        {
            retry: false,
            cacheTime: 6 * 180 * 1000,
            staleTime: 6 * 180 * 1000,
        }
    );

    return {
        isLoading,
        isError,
        data,
        error,
    };
};
