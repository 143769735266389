import { useQueries } from '@tanstack/react-query';
import _ from 'lodash';
import {
    useFilterData,
    useFilterSearch,
    useFilterValue,
} from '../../../../components';
import { checkIfIsLoading } from '../../../../utils';

type IFilterItem<K extends string = string> = {
    key: K;
    queryFn?: (query: string) => Promise<Record<'label' | 'value', string>[]>;
    selectAll?: boolean;
    type?: 'check' | 'select';
    placeholder?: string;
    textSearch?: string;
    textLoading?: string;
} & Record<string, unknown>;

export type IMargemObjetivaFilters<K extends string = string> =
    IFilterItem<K>[];

export const useMargemObjetivaFilters = <K extends string = string>(
    filters: IMargemObjetivaFilters<K> = [],
) => {
    const [data, { set: setData }] = useFilterData<K>();

    const [cache, { set: setCache }] = useFilterData<K>();

    const [search, { onSearch }] = useFilterSearch<K>();

    const [values, { set: setValues, reset: onClearAll }] = useFilterValue<K>();

    const queries = useQueries({
        queries: filters.map(({ key, queryFn }) => ({
            queryKey: [key, search[key]],
            queryFn: () => queryFn?.(search[key] ?? ''),
            onSuccess: (data: Record<'label' | 'value', string>[]) => {
                setData(
                    key,
                    _.unionBy(
                        [...(cache[key] ?? []), ...(data ?? [])],
                        'value',
                    ),
                );
            },
            retry: false,
        })),
    });

    const onSelect = (key: K, value: string[]) => {
        setValues(key, value);
        const updatedList =
            data[key]?.filter((item) => value.includes(item.value)) ?? [];
        setCache(key, updatedList);
    };

    return [
        values,
        filters.map(({ key, ...filter }, index) => ({
            ...filter,
            key,
            data: data[key] ?? [],
            value: values[key] ?? [],
            isLoading: checkIfIsLoading(queries[index].fetchStatus),
            onSelect: (value: string[]) => onSelect(key, value),
            onSearch: (query: string) => onSearch(key, query),
            onClose: () => onSearch(key, ''),
            onClean: () => {
                onSearch(key, '');
                onSelect(key, []);
            },
            ...(filter.selectAll && {
                selectAll: {
                    onSelectAll: (isChecked: boolean) => {
                        const allData =
                            data[key]?.map((item) => item.value) ?? [];
                        setValues(key, isChecked ? allData : []);
                    },
                    indeterminate: ((values[key]?.length as number) ?? 0) > 0,
                },
            }),
        })) || [],
        { onClearAll },
    ] as const;
};
