import React, {
    HTMLAttributes,
    createContext,
    useContext,
    useMemo,
    useState,
} from 'react';
import { STORE_PROPS } from '../components';
import { PAGE_PROPS } from '../components/template/GerenciadorTable/types';

type DATA_PROPS = {
    stores?: STORE_PROPS[];
};

export type DataFormProps = {
    store: string | null;
    isIndirectCompetition: boolean;
    storeType: 'SAME_TYPE' | 'INDEPENDENT_TYPE';
    location: 'CITY' | 'UF';
    competitiveness: number;
};

export type OnboardingContextProps = {
    data: DATA_PROPS;
    setData: React.Dispatch<React.SetStateAction<DATA_PROPS>>;
    step: number;
    increment: () => void;
    decrement: () => void;
    reset: () => void;
    dataForm: DataFormProps;
    setDataForm: React.Dispatch<React.SetStateAction<DataFormProps>>;
};

export const OnboardingContext = createContext({} as OnboardingContextProps);

export const OnboardingProvider = ({
    children,
}: HTMLAttributes<HTMLElement> & Pick<PAGE_PROPS, 'usuario' | 'history'>) => {
    const [data, setData] = useState<DATA_PROPS>({} as DATA_PROPS);
    const [step, setStep] = useState(0);
    const [dataForm, setDataForm] = useState<DataFormProps>({
        store: data.stores?.[0]?.alias ?? null,
        isIndirectCompetition: true,
        storeType: 'SAME_TYPE',
        location: 'CITY',
        competitiveness: 100,
    });

    function increment() {
        setStep((oldValue) => oldValue + 1);
    }

    function decrement() {
        setStep((oldValue) => oldValue - 1);
    }

    function reset() {
        setStep(0);
    }

    const value = useMemo(
        () => ({
            data,
            setData,
            step,
            increment,
            decrement,
            reset,
            dataForm,
            setDataForm,
        }),
        [data, setData, step, setStep, dataForm, setDataForm],
    );

    return (
        <OnboardingContext.Provider value={value}>
            {children}
        </OnboardingContext.Provider>
    );
};

export const useOnboarding = () => useContext(OnboardingContext);
