import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { Button, Checkbox, Col, FlexboxGrid } from 'rsuite';
import { useFiltros } from '../../hooks/useFiltros';
import { Icon } from '../../pages/IPA/RevisaoPrecos/Components/QuickActionFilter';
import {
    FILTERS_OPTIONS_PROPS,
    FILTER_STATE_PROPS,
} from '../../pages/IPA/RevisaoPrecos/hooks/useRevisaoDePrecosFiltros';
import { SAVED_FILTER_ITEM } from '../../pages/IPA/RevisaoPrecos/utils';
import { SelectPill } from './Components/SelectPill';

type FilterBoxProps = {
    filtros: FILTER_STATE_PROPS;
    setFiltrosValue: (
        value: string | string[] | Record<'type' | 'value', string>[],
        filtro: FILTERS_OPTIONS_PROPS,
    ) => void;
    addFiltrosValue: (
        value: string | string[] | Record<'type' | 'value', string>[],
        filtro: FILTERS_OPTIONS_PROPS,
    ) => void;
    limparFiltros: () => void;
    handleToggleOnChangedPrices: () => void;
    isFilterEmpty: boolean;
    handleOpenModal: (modal: 'salvarFiltro' | 'excluirFiltro') => void;
    selectedSavedFilter: Record<'label' | 'value', string> | null;
    setSelectedSavedFilter: (
        savedFilter: Record<'label' | 'value', string> | null,
    ) => void;
    handleSelectFilterLimits: (value: unknown) => void;
};

export const FilterBox = ({
    filtros,
    selectFiltro,
    changeFiltros,
    changeFiltroCheckAll,
    limparFiltros,
    setFiltrosValue,
    addFiltrosValue,
    handleToggleOnChangedPrices,
    isFilterEmpty = true,
    handleOpenModal,
    selectedSavedFilter,
    setSelectedSavedFilter,
    handleSelectFilterLimits,
}: FilterBoxProps) => {
    const {
        data,
        loadingLojas,
        loadingProdutos,
        loadingFamilias,
        loadingTiposPreco,
        segmentos,
        changeQuery,
        loadingFiltrosSalvos,
        handleAddFiltroData,
    } = useFiltros();

    const segmentoPlaceHolder = (level: number, placeholder: string) => (
        <p>
            <span className="placeholder-level">
                {level < 10 ? `0${level}` : level}
            </span>{' '}
            {placeholder}
        </p>
    );

    const handleSelectSavedFilter = ({
        filters,
        id,
        name,
    }: SAVED_FILTER_ITEM) => {
        limparFiltros();
        setSelectedSavedFilter({
            label: name,
            value: id,
        });
        Object.keys(filters)?.forEach((key) => {
            if (filters[key as keyof typeof filters] === null) return;
            if (key === 'productIds') {
                const cache = filters.products?.map(
                    ({ id, description, ...filtro }) => ({
                        label: `${id} - ${description}`,
                        value: id,
                        ...filtro,
                    }),
                );
                const value = filters.productIds;
                handleAddFiltroData(cache, 'produtosSelecionados');
                setFiltrosValue(value, 'produtosSelecionados');
            }
            if (key === 'storeIds') {
                const cache = filters.stores?.map(({ id, description }) => ({
                    label: `${id} - ${description}`,
                    value: id,
                }));
                const value = filters.storeIds;
                handleAddFiltroData(cache, 'lojasSelecionadas');
                setFiltrosValue(value, 'lojasSelecionadas');
            }
            if (key === 'productFamilyIds') {
                const cache = filters[key]?.map((item) => ({
                    label: item,
                    value: item,
                }));
                const value = filters[key];
                handleAddFiltroData(cache, 'familiaSelecionada');
                setFiltrosValue(value, 'familiaSelecionada');
            }
            if (key === 'priceType') {
                const cache = filters[key]?.map((item) => ({
                    label: item,
                    value: item,
                }));
                const value = filters[key];
                handleAddFiltroData(cache, 'tipoPrecosSelecionados');
                setFiltrosValue(value, 'tipoPrecosSelecionados');
            }
            if (key === 'sensibilities') {
                const newValue = filters.sensibilities?.map((value) => ({
                    type: 'infoPriceSensibility',
                    value,
                }));
                addFiltrosValue(newValue, 'segmentacaoSelecionada');
            }
            if (key === 'exclusive') {
                const newValue = filters.exclusive?.map((value) => ({
                    type: 'sensibilityType',
                    value,
                }));
                addFiltrosValue(newValue, 'segmentacaoSelecionada');
            }
            if (key === 'curveAbcLocal') {
                const newValue = filters.curveAbcLocal?.map((value) => ({
                    type: 'infoPriceAbcClass',
                    value,
                }));

                addFiltrosValue(newValue, 'segmentacaoSelecionada');
            }
            if (key === 'curveAbcGlobal') {
                const newValue = filters.curveAbcGlobal?.map((value) => ({
                    type: 'infoPriceGlobalAbcClass',
                    value,
                }));
                addFiltrosValue(newValue, 'segmentacaoSelecionada');
            }
            if (
                key === 'categoryLevel1' ||
                key === 'categoryLevel2' ||
                key === 'categoryLevel3' ||
                key === 'categoryLevel4' ||
                key === 'categoryLevel5' ||
                key === 'categoryLevel6' ||
                key === 'categoryLevel7'
            ) {
                const cache = filters[key]?.map((item) => ({
                    label: item,
                    value: item,
                }));
                const value = filters[key];
                handleAddFiltroData(cache, key);
                setFiltrosValue(value, key);
            }
            if (key === 'changedPrices' && filters.changedPrices === true) {
                handleToggleOnChangedPrices();
            }
            if (key === 'brokenLimit') {
                handleSelectFilterLimits(filters.brokenLimit);
            }
        });
    };

    const isGerenciadorPrecosRoute = useRouteMatch(
        '/ipa/gerenciador-de-precos',
    )?.isExact;

    return (
        <FlexboxGrid justify="space-between" className="filter-box-container">
            <FlexboxGrid.Item colspan={16}>
                <FlexboxGrid className="filtros-container">
                    {filtros?.produtosSelecionados && (
                        <FlexboxGrid.Item componentClass={Col}>
                            <SelectPill
                                id="check-picker-pill"
                                type="check"
                                placeholder="Produto"
                                name="produto"
                                sticky
                                data={data.produtosSelecionados}
                                cache={filtros.produtosSelecionados.cache}
                                value={filtros.produtosSelecionados.value}
                                isLoading={loadingProdutos}
                                onSelect={(value) => {
                                    selectFiltro(value, 'produtosSelecionados');
                                }}
                                onChange={(value) => {
                                    changeFiltros(
                                        value,
                                        data.produtosSelecionados,
                                        'produtosSelecionados',
                                    );
                                }}
                                onSearch={(value) => {
                                    changeQuery(value, 'produtosSelecionados');
                                }}
                                onClean={() => {
                                    selectFiltro([], 'produtosSelecionados');
                                }}
                                onClose={() => {
                                    changeQuery('', 'produtosSelecionados');
                                }}
                                locale={{
                                    noResultsText: loadingProdutos
                                        ? 'Carregando produtos...'
                                        : 'Nenhum item encontrado',
                                    searchPlaceholder: 'Pesquisar',
                                }}
                            />
                        </FlexboxGrid.Item>
                    )}
                    {filtros?.familiaSelecionada && (
                        <FlexboxGrid.Item componentClass={Col}>
                            <SelectPill
                                id="check-picker-pill"
                                type="check"
                                placeholder="Família"
                                name="familia"
                                data={data.familiaSelecionada}
                                cache={filtros.familiaSelecionada.cache}
                                value={filtros.familiaSelecionada.value}
                                isLoading={loadingFamilias}
                                onSelect={(value) => {
                                    selectFiltro(value, 'familiaSelecionada');
                                }}
                                onChange={(value) => {
                                    changeFiltros(
                                        value,
                                        data.familiaSelecionada,
                                        'familiaSelecionada',
                                    );
                                }}
                                onSearch={(value) => {
                                    changeQuery(value, 'familiaSelecionada');
                                }}
                                onClean={() => {
                                    selectFiltro([], 'familiaSelecionada');
                                }}
                                onClose={() => {
                                    changeQuery('', 'familiaSelecionada');
                                }}
                                locale={{
                                    noResultsText: loadingFamilias
                                        ? 'Carregando famílias...'
                                        : 'Nenhum item encontrado',
                                    searchPlaceholder: 'Pesquisar',
                                }}
                            />
                        </FlexboxGrid.Item>
                    )}
                    {filtros?.lojasSelecionadas && (
                        <FlexboxGrid.Item componentClass={Col}>
                            <SelectPill
                                id="check-picker-pill"
                                type="check"
                                placeholder="Lojas"
                                name="lojas"
                                data={data.lojasSelecionadas}
                                cache={filtros.lojasSelecionadas.cache}
                                value={filtros.lojasSelecionadas.value}
                                isLoading={loadingLojas}
                                onChange={(value) => {
                                    changeFiltros(
                                        value,
                                        data.lojasSelecionadas,
                                        'lojasSelecionadas',
                                    );
                                }}
                                onSelect={(value) => {
                                    changeFiltroCheckAll(
                                        {
                                            cache: value,
                                            value: value,
                                            indeterminate:
                                                value.length > 0 &&
                                                value.length <
                                                    data.lojasSelecionadas
                                                        .length,
                                            checkAll:
                                                value.length ===
                                                data.lojasSelecionadas.length,
                                        },
                                        'lojasSelecionadas',
                                    );
                                }}
                                onSearch={(value) => {
                                    changeQuery(value, 'lojasSelecionadas');
                                }}
                                onClose={() => {
                                    changeQuery('', 'lojasSelecionadas');
                                }}
                                onClean={() => {
                                    changeFiltroCheckAll(
                                        {
                                            cache: [],
                                            value: [],
                                            indeterminate: false,
                                            checkAll: false,
                                        },
                                        'lojasSelecionadas',
                                    );
                                }}
                                locale={{
                                    noResultsText: loadingLojas
                                        ? 'Carregando lojas...'
                                        : 'Nenhum item encontrado',
                                    searchPlaceholder: 'Pesquisar',
                                }}
                                renderExtraFooter={() => (
                                    <div className="picker-pill-footer">
                                        <Checkbox
                                            inline
                                            indeterminate={
                                                filtros?.lojasSelecionadas
                                                    ?.indeterminate
                                            }
                                            checked={
                                                filtros.lojasSelecionadas
                                                    .checkAll
                                            }
                                            onChange={(
                                                value: any,
                                                checked: boolean,
                                            ) => {
                                                const nextValue = checked
                                                    ? data.lojasSelecionadas.map(
                                                          (item: {
                                                              value: string;
                                                          }) => item.value,
                                                      )
                                                    : [];
                                                changeFiltroCheckAll(
                                                    {
                                                        cache: nextValue,
                                                        value: nextValue,
                                                        indeterminate: false,
                                                        checkAll: checked,
                                                    },
                                                    'lojasSelecionadas',
                                                );
                                            }}
                                        >
                                            Selecionar todas as lojas
                                        </Checkbox>
                                    </div>
                                )}
                            />
                        </FlexboxGrid.Item>
                    )}
                    {filtros?.tipoPrecosSelecionados && (
                        <FlexboxGrid.Item componentClass={Col}>
                            <SelectPill
                                id="check-picker-pill"
                                type="check"
                                placeholder="Tipo de Preço"
                                name="tipoPreco"
                                searchable={false}
                                // @ts-ignore
                                data={data.tipoPrecosSelecionados}
                                value={filtros.tipoPrecosSelecionados.value}
                                isLoading={loadingTiposPreco}
                                onSelect={(value) => {
                                    selectFiltro(
                                        value,
                                        'tipoPrecosSelecionados',
                                    );
                                }}
                                onChange={(value) => {
                                    changeFiltros(
                                        value,
                                        data.tipoPrecosSelecionados,
                                        'tipoPrecosSelecionados',
                                    );
                                }}
                                onClean={() => {
                                    selectFiltro([], 'tipoPrecosSelecionados');
                                }}
                                locale={{
                                    noResultsText: loadingTiposPreco
                                        ? 'Carregando tipos de preço...'
                                        : 'Nenhum item encontrado',
                                    searchPlaceholder: 'Pesquisar',
                                }}
                            />
                        </FlexboxGrid.Item>
                    )}
                    {filtros?.segmentacaoSelecionada && (
                        <FlexboxGrid.Item componentClass={Col}>
                            <SelectPill
                                id="check-picker-pill"
                                type="check"
                                placeholder="Segmentação"
                                name="segmentacao"
                                searchable={false}
                                // @ts-ignore
                                data={data.segmentacaoSelecionada}
                                groupBy="role"
                                value={filtros.segmentacaoSelecionada.value}
                                onSelect={(value) => {
                                    selectFiltro(
                                        value,
                                        'segmentacaoSelecionada',
                                    );
                                }}
                                onChange={(value) => {
                                    changeFiltros(
                                        value,
                                        data.segmentacaoSelecionada,
                                        'segmentacaoSelecionada',
                                    );
                                }}
                                onClean={() => {
                                    selectFiltro([], 'segmentacaoSelecionada');
                                }}
                                locale={{
                                    searchPlaceholder: 'Pesquisar',
                                }}
                            />
                        </FlexboxGrid.Item>
                    )}
                </FlexboxGrid>
            </FlexboxGrid.Item>
            <FlexboxGrid.Item>
                <Button className="clear-filtros-btn" onClick={limparFiltros}>
                    Limpar filtros
                </Button>
            </FlexboxGrid.Item>
            <FlexboxGrid.Item componentClass={Col} colspan={24}>
                <FlexboxGrid>
                    <FlexboxGrid.Item colspan={24}>
                        <FlexboxGrid className="sub-menu">
                            <FlexboxGrid className="filtros-container">
                                {segmentos?.map(
                                    (
                                        segmento: {
                                            name: string;
                                            field: string;
                                            level: number;
                                        },
                                        index: number,
                                    ) => (
                                        <FlexboxGrid.Item
                                            componentClass={Col}
                                            key={`${segmento.name}-${index}`}
                                        >
                                            <SelectPill
                                                key={segmento.level}
                                                id="check-picker-pill"
                                                type="check"
                                                // @ts-ignore
                                                placeholder={segmentoPlaceHolder(
                                                    segmento.level,
                                                    segmento.name,
                                                )}
                                                name={segmento.field}
                                                // @ts-ignore
                                                data={data[segmento.field]}
                                                cache={
                                                    filtros[segmento.field]
                                                        .cache
                                                }
                                                value={
                                                    filtros[segmento.field]
                                                        .value
                                                }
                                                onSelect={(value) => {
                                                    selectFiltro(
                                                        value,
                                                        segmento.field,
                                                    );
                                                }}
                                                onChange={(value) => {
                                                    changeFiltros(
                                                        value,
                                                        data[segmento.field],
                                                        segmento.field,
                                                    );
                                                }}
                                                onSearch={(value) => {
                                                    changeQuery(
                                                        value,
                                                        segmento.field,
                                                    );
                                                }}
                                                onClean={() => {
                                                    selectFiltro(
                                                        [],
                                                        segmento.field,
                                                    );
                                                }}
                                                onClose={() => {
                                                    changeQuery(
                                                        '',
                                                        segmento.field,
                                                    );
                                                }}
                                                locale={{
                                                    noResultsText:
                                                        loadingProdutos
                                                            ? 'Carregando produtos...'
                                                            : 'Nenhum item encontrado',
                                                    searchPlaceholder: `Buscar por ${segmento.name}`,
                                                }}
                                            />
                                        </FlexboxGrid.Item>
                                    ),
                                )}
                            </FlexboxGrid>
                            {isGerenciadorPrecosRoute && (
                                <FlexboxGrid
                                    align="middle"
                                    className="salvar-filtros sub-menu filtros-container"
                                >
                                    <SelectPill
                                        id="check-picker-pill"
                                        data={data.filtrosSalvos}
                                        value={
                                            selectedSavedFilter?.value ?? null
                                        }
                                        placeholder="Filtros salvos"
                                        placement="bottomEnd"
                                        locale={{
                                            noResultsText: loadingFiltrosSalvos
                                                ? 'Carregando filtros...'
                                                : 'Nenhum filtro encontrado',
                                            searchPlaceholder: 'Pesquisar',
                                        }}
                                        onSearch={(value) => {
                                            changeQuery(
                                                value,
                                                'filtrosSalvosSelecionados',
                                            );
                                        }}
                                        onSelect={(value, item) => {
                                            handleSelectSavedFilter(item);
                                        }}
                                        onClean={limparFiltros}
                                    />
                                    {!isFilterEmpty && (
                                        <Button
                                            className="clear-filtros-btn salvar-filtros-btn"
                                            onClick={() => {
                                                handleOpenModal('salvarFiltro');
                                            }}
                                        >
                                            <Icon icon="MdSave" />
                                        </Button>
                                    )}

                                    {selectedSavedFilter?.value && (
                                        <Button
                                            className="clear-filtros-btn salvar-filtros-btn"
                                            onClick={() => {
                                                handleOpenModal(
                                                    'excluirFiltro',
                                                );
                                            }}
                                        >
                                            <Icon icon="MdDelete" />
                                        </Button>
                                    )}
                                </FlexboxGrid>
                            )}
                        </FlexboxGrid>
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </FlexboxGrid.Item>
        </FlexboxGrid>
    );
};
