import React, { memo } from 'react';
import { ButtonPrimary, TableHeading } from '../../../../../components';
import AppyPricesButton from '../AppyPricesButton/AppyPricesButton';
import { GerenciadorQuickActionFilters } from '../GerenciadorQuickActionFilters';
import SelectAllButton from '../SelectAllButton/SelectAllButton';
import useGerenciadorTableHeading from './useGerenciadorTableHeading';

const GerenciadorTableHeading = () => {
    const {
        total,
        isFiltersEmpty,
        isEmptySelectedDatapoints,
        handleOpenModal,
        handleSelectAllFromAllPages,
        handleUnselectAllFromAllPages,
    } = useGerenciadorTableHeading();

    return (
        <TableHeading isSelected={!isEmptySelectedDatapoints}>
            <TableHeading.Container>
                <TableHeading.TotalProducts total={total} />
                <SelectAllButton
                    isEmpty={isFiltersEmpty}
                    handleSelectAllFromAllPages={handleSelectAllFromAllPages}
                    handleUnselectAll={handleUnselectAllFromAllPages}
                />
            </TableHeading.Container>
            <TableHeading.Container>
                {isEmptySelectedDatapoints && <GerenciadorQuickActionFilters />}
                {!isEmptySelectedDatapoints && (
                    <ButtonPrimary
                        id="alterar-precos"
                        theme="ghost"
                        skin="blue"
                        onClick={() => handleOpenModal('alterar-precos')}
                    >
                        ALTERAR PREÇO
                    </ButtonPrimary>
                )}
                <AppyPricesButton />
            </TableHeading.Container>
        </TableHeading>
    );
};

export default memo(GerenciadorTableHeading);
