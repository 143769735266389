import React from 'react';
import { Button, Content, Dropdown, Icon } from 'rsuite';
import downloadIcon from '../../../../../../assets/icons/icon_download_blue.svg';
import styles from './styles.module.scss';

export const ResultsButton = ({
    onClick,
    onClickDownload,
    downloadDisabled,
    hasDataRendered,
}) => (
    <Content className={styles['result-button-content']}>
        <Button
            id="competitivite-results-button"
            appearance="primary"
            className={styles['result-button']}
            onClick={onClick}
        >
            {hasDataRendered ? 'ATUALIZAR RESULTADOS' : 'VISUALIZAR RESULTADOS'}
        </Button>
        <Dropdown
            id="download-btn"
            placement="bottomStart"
            title="Download"
            renderTitle={() => (
                <Button
                    appearance="ghost"
                    className={styles['result-button']}
                    disabled={downloadDisabled}
                >
                    <img
                        src={downloadIcon}
                        alt=""
                        className={styles['download-button-icon']}
                    />
                    DOWNLOAD
                </Button>
            )}
        >
            <Dropdown.Item
                id="download-csv-file"
                eventKey="csv"
                onSelect={onClickDownload}
                icon={<Icon icon="file-text-o" />}
            >
                .csv
            </Dropdown.Item>
            <Dropdown.Item
                id="download-xlsx-file"
                eventKey="xlsx"
                onSelect={onClickDownload}
                icon={<Icon icon="file-excel-o" />}
            >
                .xlsx
            </Dropdown.Item>
        </Dropdown>
    </Content>
);
