/* eslint-disable react/jsx-wrap-multilines */
import classNames from 'classnames';
import React from 'react';
import { Button } from 'rsuite';
import { FilterKeysProps } from '../../../../../@types/FiltersTypes';
import {
    CheckPill,
    SegmentsPlaceholder,
    SelectPill,
} from '../../../../../components/NewFilterBox/Components';
import { Icon } from '../../../RevisaoPrecos/Components';
import styles from './FilterBox.module.scss';
import * as T from './FilterBox.types';

const SETTINGS: T.PartialSettings = {
    productIds: {
        placeholder: 'Produto',
        type: 'check',
    },
    storeIds: {
        placeholder: 'Loja',
        type: 'check',
    },
    productFamilyIds: {
        placeholder: 'Família',
        type: 'check',
    },
    sensibilities: {
        placeholder: 'Segmentação',
        type: 'check',
    },
    priceType: {
        placeholder: 'Tipo de Preço',
        type: 'check',
    },
    productBrand: {
        placeholder: 'Marca',
        type: 'check',
    },
    supplier: {
        placeholder: 'Fornecedor',
        type: 'check',
    },
    savedFilters: {
        placeholder: 'Filtros Salvos',
        type: 'check',
    },
};

function getLoadingText(key: FilterKeysProps) {
    return `Carregando ${SETTINGS[key]?.placeholder.toLocaleLowerCase()}s...`;
}

export const FilterBox = ({
    data,
    state,
    loading,
    className,
    handlers,
    segments,
    isEmpty,
    savedFilterState,
    ...props
}: T.FilterBoxProps) => (
    <div className={classNames(styles['info-filterbox'], className)} {...props}>
        <div
            className={classNames(styles.row, styles['info-filterbox__row-1'])}
        >
            <div>
                {Object.keys(data ?? {}).map((key) => {
                    if (key.match(/category/) || key.match(/savedFilters/)) {
                        return null;
                    }
                    const _key = key as T.KeysProps;
                    return (
                        <CheckPill
                            key={_key}
                            data={data[_key] ?? []}
                            placeholder={SETTINGS[_key]?.placeholder || _key}
                            id="check-picker-pill"
                            type={SETTINGS[_key]?.type ?? 'check'}
                            cache={state[_key]?.cache ?? []}
                            value={state[_key]?.value ?? []}
                            onChange={(value: string[]) => {
                                handlers.onChange(value, _key);
                            }}
                            onSearch={(value: string) => {
                                handlers.onSearch(value, _key);
                            }}
                            onClose={() => {
                                handlers.onSearch('', _key);
                            }}
                            onClean={() => {
                                handlers.onChange([], _key);
                            }}
                            isLoading={loading[_key]}
                            locale={{
                                searchPlaceholder: 'Pesquisar',
                                noResultsText: loading[_key]
                                    ? getLoadingText(_key)
                                    : 'Nenhum item encontrado',
                            }}
                            sticky
                        />
                    );
                })}
            </div>
            <div>
                <Button
                    className={classNames(styles['clear-filtros-btn'])}
                    onClick={handlers.onClear}
                >
                    Limpar filtros
                </Button>
            </div>
        </div>
        <div
            className={classNames(styles.row, styles['info-filterbox__row-2'])}
        >
            <div>
                {segments.map((segment) => (
                    <CheckPill
                        key={segment.level}
                        id="check-picker-pill"
                        data={data[segment.field]}
                        cache={state[segment.field]?.cache ?? []}
                        value={state[segment.field]?.value ?? []}
                        name={segment.field}
                        placeholder={
                            <SegmentsPlaceholder
                                level={segment.level}
                                placeholder={segment.name}
                            />
                        }
                        onChange={(value: string[]) => {
                            handlers.onChange(value, segment.field);
                        }}
                        onSearch={(value: string) => {
                            handlers.onSearch(value, segment.field);
                        }}
                        onClose={() => {
                            handlers.onSearch('', segment.field);
                        }}
                        onClean={() => {
                            handlers.onChange([], segment.field);
                        }}
                        isLoading={loading?.segments}
                        locale={{
                            noResultsText: loading?.segments
                                ? 'Carregando produtos...'
                                : 'Nenhum item encontrado',
                            searchPlaceholder: `Buscar por ${segment.name}`,
                        }}
                    />
                ))}
            </div>
            <div className={classNames(styles.row)}>
                {data.savedFilters && (
                    <SelectPill
                        id="check-picker-pill"
                        placeholder="Filtros salvos"
                        placement="bottomEnd"
                        data={data.savedFilters ?? []}
                        value={savedFilterState?.data.value || ''}
                        isLoading={loading.savedFilters}
                        locale={{
                            noResultsText: loading.savedFilters
                                ? 'Carregando filtros...'
                                : 'Nenhum filtro encontrado',
                            searchPlaceholder: 'Pesquisar',
                        }}
                        onClose={() => {
                            handlers.onChange([], 'savedFilters');
                        }}
                        onSearch={(value) => {
                            handlers.onSearch(value, 'savedFilters');
                        }}
                        // @ts-ignore
                        onSelect={(value, item) => {
                            handlers.onSelectSavedFilter(item);
                        }}
                        onClean={handlers.onClear}
                    />
                )}
                {!isEmpty && (
                    <Button
                        className={classNames(
                            styles['clear-filtros-btn'],
                            styles['salvar-filtros-btn'],
                        )}
                        onClick={() => {
                            handlers.onClickSaveModal('salvarFiltros');
                        }}
                    >
                        <Icon icon="MdSave" />
                    </Button>
                )}
                {savedFilterState.data.value.length > 0 && (
                    <Button
                        className={classNames(
                            styles['clear-filtros-btn'],
                            styles['salvar-filtros-btn'],
                        )}
                        onClick={() => {
                            handlers.onClickSaveModal('excluirFiltro');
                        }}
                    >
                        <Icon icon="MdDelete" />
                    </Button>
                )}
            </div>
        </div>
    </div>
);
