import React from 'react';
import CurrencyFormat from 'react-currency-format';
import { Col, FlexboxGrid, Popover, Row } from 'rsuite';

export const MarginLimitInfo = ({
    content,
    margin,
    cpi,
    onMouseEnter,
    onMouseLeave,
    className,
    style,
}) => (
    <Popover
        title={
            <div className="popover-limite__title">
                Informações de limite
                {!content?.minMargin && !content?.maxMargin ? (
                    <p>
                        Não há limites de margem cadastrados para este produto
                    </p>
                ) : null}
            </div>
        }
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        className={className}
        style={style}
    >
        <Row className="popover-limite__row">
            {content?.minMargin || content?.maxMargin ? (
                // eslint-disable-next-line react/jsx-indent
                <FlexboxGrid justify="end">
                    <FlexboxGrid.Item>
                        <p className="popover-limite__description">LIMITE</p>
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            ) : null}
            {(content?.minMargin || content?.maxMargin) &&
            (content?.minCpi || content?.maxCpi) ? (
                <FlexboxGrid>
                    <FlexboxGrid.Item
                        componentClass={Col}
                        lg={10}
                        md={10}
                        sm={10}
                        xs={10}
                    >
                        <p
                            className={
                                cpi > content.maxCpi || cpi < content.minCpi
                                    ? 'popover-limite__description popover-limite__description_invalid'
                                    : 'popover-limite__description'
                            }
                        >
                            COMPETITIVIDADE
                        </p>
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item
                        componentClass={Col}
                        lg={14}
                        md={14}
                        sm={14}
                        xs={14}
                        className="popover-limite__section-value"
                    >
                        <span className="popover-limite__text">
                            {content.minCpi && !content.maxCpi
                                ? 'ACIMA DE '
                                : ''}
                        </span>
                        <span className="popover-limite__text">
                            {content.minCpi && content.maxCpi ? 'ENTRE ' : ''}
                        </span>
                        <span>
                            {content.minCpi ? (
                                <CurrencyFormat
                                    fixedDecimalScale
                                    decimalScale={2}
                                    value={content.minCpi * 100}
                                    displayType="text"
                                    suffix="%"
                                    decimalSeparator=","
                                    thousandSeparator="."
                                />
                            ) : (
                                ''
                            )}
                        </span>
                        <span className="popover-limite__text">
                            {content.minCpi && content.maxCpi ? ' E ' : ''}
                        </span>
                        <span className="popover-limite__text">
                            {!content.minCpi && content.maxCpi ? 'ATÉ ' : ''}
                        </span>
                        <span>
                            {content.maxCpi ? (
                                <CurrencyFormat
                                    fixedDecimalScale
                                    decimalScale={2}
                                    value={content.maxCpi * 100}
                                    displayType="text"
                                    suffix="%"
                                    decimalSeparator=","
                                    thousandSeparator="."
                                />
                            ) : (
                                ''
                            )}
                        </span>
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            ) : null}
            {content?.minMargin || content?.maxMargin ? (
                <FlexboxGrid>
                    <FlexboxGrid.Item
                        componentClass={Col}
                        lg={10}
                        md={10}
                        sm={10}
                        xs={10}
                    >
                        <p
                            className={
                                margin > content.maxMargin ||
                                margin < content.minMargin
                                    ? 'popover-limite__description popover-limite__description_invalid'
                                    : 'popover-limite__description'
                            }
                        >
                            MARGEM
                        </p>
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item
                        componentClass={Col}
                        lg={14}
                        md={14}
                        sm={14}
                        xs={14}
                        className="popover-limite__section-value"
                    >
                        <span className="popover-limite__text">
                            {content.minMargin && !content.maxMargin
                                ? 'ACIMA DE '
                                : ''}
                        </span>
                        <span className="popover-limite__text">
                            {content.minMargin && content.maxMargin
                                ? 'ENTRE '
                                : ''}
                        </span>
                        <span>
                            {content.minMargin ? (
                                <CurrencyFormat
                                    fixedDecimalScale
                                    decimalScale={2}
                                    value={content.minMargin * 100}
                                    displayType="text"
                                    suffix="%"
                                    decimalSeparator=","
                                    thousandSeparator="."
                                />
                            ) : (
                                ''
                            )}
                        </span>
                        <span className="popover-limite__text">
                            {content.minMargin && content.maxMargin
                                ? ' E '
                                : ''}
                        </span>
                        <span className="popover-limite__text">
                            {!content.minMargin && content.maxMargin
                                ? 'ATÉ '
                                : ''}
                        </span>
                        <span>
                            {content.maxMargin ? (
                                <CurrencyFormat
                                    fixedDecimalScale
                                    decimalScale={2}
                                    value={content.maxMargin * 100}
                                    displayType="text"
                                    suffix="%"
                                    decimalSeparator=","
                                    thousandSeparator="."
                                />
                            ) : (
                                ''
                            )}
                        </span>
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            ) : null}
        </Row>
    </Popover>
);
