import React, { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Alert } from 'rsuite';
import { setSingleReducer } from '../../../../../../../actions/actionsAnaliseCompetitividade';
import { LoadingMenu } from '../../../../../../../components/LoadingSpinner';
import SelectPill from '../../../../../../../components/SelectPill';

import arrowRightIcon from '../../../../../../../assets/icons/icon_arrow-right-gray.svg';
import { useChangePlaceholder } from '../../../hooks/useChangePlaceholder';
import { filtrosDataType } from '../../../utils';
import styles from './styles.module.scss';

export const BetweenVersusSelect = ({
    placeholder,
    addSelect,
    onSelectCompetitors,
    onCleanCompetitors,
    savedRedeSelectedObject,
    redeState,
    redeHandlers,
}) => {
    const { filterIsEmpty } = useSelector(
        (state) => ({
            filterIsEmpty:
                state.analiseCompetitividadeDataReducer.filterIsEmpty,
        }),
        shallowEqual,
    );

    const dispatch = useDispatch();
    const {
        selectPlaceholder,
        handleThirdPlaceholderChange,
        handleCleanCheckPlaceholder,
    } = useChangePlaceholder(placeholder, addSelect);

    const handleCompetitorsOnSelect = (value, item) => {
        const hasValue = redeState.doubleListData.competidoras.value.includes(
            item.value,
        );
        const valueArray = value.length > 10 ? value.slice(0, -1) : value;
        handleThirdPlaceholderChange(value, item);
        onSelectCompetitors(valueArray, item, hasValue);

        if (value.length > 10) {
            Alert.warning('Já existem 10 redes selecionadas.', 5000);
        } else if (hasValue) {
            redeHandlers.handleRemoveDoubleItem(
                filtrosDataType[selectPlaceholder?.label],
                item,
            );
        } else {
            redeHandlers.handleSelectDoubleItem(
                filtrosDataType[selectPlaceholder?.label],
                item,
                'competidoras',
            );
        }
    };

    const handleCompetitorsOnClean = () => {
        redeHandlers.handleCleanDoubleItem('competidoras');
        handleCleanCheckPlaceholder('third');
        onCleanCompetitors();
    };

    const handleCompetitorsOnOpen = () => {
        redeHandlers.handleGetDoubleList(
            selectPlaceholder?.label,
            'competidoras',
        );
        dispatch(
            setSingleReducer('filterIsEmpty', {
                ...filterIsEmpty,
                redes: false,
            }),
        );
    };

    useEffect(() => {
        if (savedRedeSelectedObject) {
            redeHandlers.handleSetSavedDoubleFilters(savedRedeSelectedObject);
        }
    }, [savedRedeSelectedObject]);

    return (
        <div className={styles['select-pill-wrapper']}>
            <div className={styles['div-pill']}>Rede</div>
            <img
                src={arrowRightIcon}
                alt=""
                className={styles['arrow-right-icon']}
            />

            <SelectPill
                type="check"
                id={
                    filterIsEmpty?.redes
                        ? 'select-pill-is-empty'
                        : 'check-picker-pill'
                }
                placeholder={
                    redeState.doubleListData?.competidoras?.value?.length
                        ? 'Redes selecionadas'
                        : 'Escolha até 10 redes'
                }
                name="rede-industria"
                value={redeState.doubleListData?.competidoras?.value}
                data={redeState.doubleListData?.competidoras?.lista}
                onClean={handleCompetitorsOnClean}
                onSearch={(search) =>
                    redeHandlers.handleSearchDoubleList(
                        search,
                        selectPlaceholder?.label,
                        'competidoras',
                    )
                }
                onSelect={handleCompetitorsOnSelect}
                onOpen={handleCompetitorsOnOpen}
                locale={{
                    noResultsText: 'Nenhum item encontrado',
                    searchPlaceholder: 'Buscar item',
                }}
                renderMenu={(menu) => (
                    <span>
                        <LoadingMenu area="panel-filter" />
                        {menu}
                    </span>
                )}
            />
        </div>
    );
};
