import { combineReducers } from '@reduxjs/toolkit';
import ecomSlice, { ExtracaoDadosEcomProps } from './ecom';

export type ExtracaoDadosStateProps = {
    ecom: ExtracaoDadosEcomProps;
};

const rootReducer = combineReducers({
    ecom: ecomSlice,
});

export const selectorExtracaoDados = (
    state: Record<'extracaoDadosReducer', ExtracaoDadosStateProps>,
) => state.extracaoDadosReducer;

export default rootReducer;
