import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { format } from 'date-fns';
import {
    Content,
    Button,
} from 'rsuite';
import {
    setResultScenarioData,
} from '../../../../reducers/calculadoraPrevisaoDemanda/resultScenario';
import { selectorCalculadoraPrevisaoDemanda } from '../../../../reducers/calculadoraPrevisaoDemanda';

import styles from './SimulationArea.module.scss';

export const SimulationArea = () => {
    const { resultScenario } = useSelector(selectorCalculadoraPrevisaoDemanda);
    const { scenario, inputs, query } = resultScenario?.resultScenarioData;

    const dispatch = useDispatch();

    const handleRenameScenario = () => {
        dispatch(setResultScenarioData({ name: 'showModalSave', data: true }));
        dispatch(setResultScenarioData({ name: 'modalSaveTitle', data: 'Renomear' }));
    };

    return (
        <Content className={styles['simulation-area-content']}>
            {!resultScenario.isEditing ? (
                <p className={styles.title}>Cenário simulado</p>
            ) : (
                <div className={styles['flex-center']}>
                    <p className={styles['title-scenario-name']}>{scenario.scenario_name}</p>
                    <Button appearance="link" onClick={handleRenameScenario}>
                        Renomear
                    </Button>
                </div>
            )}
            <div className={styles['simulation-numbers-wrapper']}>
                <div className={styles['simulation-numbers']}>
                    <p className={styles.label}>Data do cálculo: </p>
                    <p className={styles.value}>
                        {scenario?.simulation_date
                            ? format(new Date(scenario?.simulation_date), 'dd/MM/yyyy')
                            : format(new Date(), 'dd/MM/yyyy')}
                    </p>
                </div>
                <div className={styles['simulation-numbers']}>
                    <p className={styles.label}>Período calculado: </p>
                    <p className={styles.value}>
                        {inputs.preferences?.period_in_days || '-'}
                        {' '}
                        dias
                    </p>
                </div>
                <div className={styles['simulation-numbers']}>
                    <p className={styles.label}>Concorrente: </p>
                    <p className={styles.value}>{query.competitor_product_description}</p>
                </div>
                <div className={styles['simulation-numbers']}>
                    <p className={styles.label}>Repasse concorrente: </p>
                    <p className={styles.value}>
                        {inputs.competitor_price_follow_up}
                        {' '}
                        %
                    </p>
                </div>
            </div>
        </Content>
  );
};
