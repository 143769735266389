import classNames from 'classnames';
import React from 'react';
import { Skeleton } from '../Skeleton';
import styles from './TableSkeleton.module.scss';

const WIDTH = 128;

type TableSkeletonProps = {
    cols?: number;
    rows?: number;
} & Omit<React.HtmlHTMLAttributes<HTMLTableElement>, 'children'>;

export const TableSkeleton = ({
    cols = 5,
    rows = 5,
    className,
    ...props
}: TableSkeletonProps) => {
    return (
        <table
            className={classNames(styles.table_skeleton, className)}
            {...props}
        >
            <thead>
                <tr className={styles.table_skeleton__header}>
                    {Array.from({ length: cols }, (_v, k) => (
                        <th key={k}>
                            <Skeleton width={WIDTH} rounded="pill" />
                        </th>
                    ))}
                </tr>
            </thead>

            <tbody>
                {Array.from({ length: rows }, (_v, k) => (
                    <tr className={styles.table_skeleton__body} key={k}>
                        {Array.from({ length: cols }, (_v, k) => (
                            <td key={k}>
                                <Skeleton
                                    width={k === 0 ? WIDTH * 2 : WIDTH}
                                    rounded="pill"
                                />
                            </td>
                        ))}
                    </tr>
                ))}
            </tbody>

            <tfoot className={styles.table_skeleton__footer}>
                <tr>
                    {Array.from({ length: cols }, (_v, k) => (
                        <td key={k} />
                    ))}
                </tr>
            </tfoot>
        </table>
    );
};
