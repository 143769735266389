import React from 'react';
import { ButtonGroup, Dropdown } from 'rsuite';
import { FilterSelectProps } from '../QuickActionFilter.types';
import { IconButton } from './IconButton';

export const FilterSelect = ({
    icon = 'MdSelectAll',
    options,
    placeholder,
    skin = 'neutral',
    onSelect,
    onClick,
}: FilterSelectProps) => (
    <ButtonGroup
        className="quick-action-filter__button__container quick-action-filter__toggle"
        data-testid="filter-select"
    >
        <IconButton
            icon={icon}
            placeholder={placeholder}
            skin={skin}
            onClick={onClick}
            data-testid="placeholder-icon"
        />
        <Dropdown
            activeKey="1"
            eventKey="1"
            onSelect={onSelect}
            placement="bottomEnd"
            renderTitle={() => (
                <IconButton
                    icon="MdKeyboardArrowDown"
                    className="quick-action-filter__toggle__arrow"
                    skin={skin}
                />
            )}
        >
            {options.map(({ label = '', value = '', id = '' }) => (
                <Dropdown.Item key={id} eventKey={value}>
                    {label}
                </Dropdown.Item>
            ))}
        </Dropdown>
    </ButtonGroup>
);
