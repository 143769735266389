import { trackPromise } from 'react-promise-tracker';
import qs from 'qs';
import api from '../../../../../utils/API';

export const getLojas = (params) => trackPromise(
        api({
            url: `${process.env.REACT_APP_IPA_URL}/campaign/campaigns/stores/all`,
            method: 'GET',
            expectToken: true,
            params: {
                clientId: JSON.parse(localStorage.getItem('cliente'))?.codigo,
                size: params?.size || 7,
                page: params?.page || 0,
                ...(params?.storeType && { storeType: params.storeType }),
                ...(params?.storeCluser && { storeCluser: params.storeCluser }),
                ...(params?.storeName && { storeName: params.storeName }),
                ...(params?.campaignId && { campaignId: params.campaignId }),
            },
            paramsSerializer(params) {
                return qs.stringify(params, { arrayFormat: 'repeat' });
            },
        }).then((resp) => resp.data),
    );

export const getFiltroLojas = (params) => trackPromise(
        api({
            url: `${process.env.REACT_APP_IPA_URL}/campaign/campaigns/stores/all`,
            method: 'GET',
            expectToken: true,
            params: {
                clientId: JSON.parse(localStorage.getItem('cliente'))?.codigo,
                size: 200,
                page: 0,
                ...(params?.storeName && { storeName: params.storeName }),
            },
        }).then((resp) => resp.data),
    );

export const getTiposLojas = (id) => trackPromise(
        api({
            url: `${process.env.REACT_APP_IPA_URL}/campaign/campaigns/${id}/storeType`,
            method: 'GET',
            expectToken: true,
            params: {
                clientId: JSON.parse(localStorage.getItem('cliente'))?.codigo,
            },
        }).then((resp) => resp.data),
    );

export const getLojasParticipantes = (params) => trackPromise(
        api({
            url: `${process.env.REACT_APP_IPA_URL}/campaign/campaigns/${params.id}/stores`,
            method: 'GET',
            expectToken: true,
            params: {
                clientId: JSON.parse(localStorage.getItem('cliente'))?.codigo,
                size: params?.size || 20,
                page: params?.page || 0,
                ...(params?.storeType && { storeType: params.storeType }),
                ...(params?.storeCluser && { storeCluser: params.storeCluser }),
                ...(params?.storeName && { storeName: params.storeName }),
            },
        }).then((resp) => resp.data),
    );

export const saveLojasParticipantes = ({ data, id }) => trackPromise(
        api({
            url: `${process.env.REACT_APP_IPA_URL}/campaign/campaigns/${id}/stores`,
            method: 'POST',
            expectToken: true,
            data: JSON.stringify(data),
        }).then((resp) => resp),
    );
export const deleteLojaParticipante = (id, storeId) => trackPromise(
        api({
            url: `${process.env.REACT_APP_IPA_URL}/campaign/campaigns/${id}/stores/${storeId}`,
            method: 'DELETE',
            expectToken: true,
            params: {
                clientId: JSON.parse(localStorage.getItem('cliente'))?.codigo,
            },
        }).then((resp) => resp),
    );
