import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { selectorExtracaoDados } from '../../../../../reducers/extracaoDados';
import { setEcomValues } from '../../../../../reducers/extracaoDados/ecom';
import { formatCurrencyToPtBr } from '../../../../../utils/currency';
import * as ExtracaoService from '../services';

export const useTableList = () => {
    const dispatch = useDispatch();

    const { ecom } = useSelector(selectorExtracaoDados);

    const getEcomTableList = (page) => {
        ExtracaoService.getEcomPreview(ecom.ecomPayload, {
            size: 20,
            page,
        }).then((data) => {
            dispatch(
                setEcomValues({ name: 'ecomTableListEmpty', value: false }),
            );

            const newData = [...data.content];
            const contentData = newData.map((item) => ({
                ...item,
                dt_coleta: moment.utc(item.dt_coleta).format('DD/MM/YYYY'),
                preco_de: formatCurrencyToPtBr(item.preco_de),
                preco_por: formatCurrencyToPtBr(item.preco_por),
                desconto: formatCurrencyToPtBr(item.desconto),
            }));
            const obj = {
                name: 'ecomTableData',
                value: { ...data, content: contentData },
            };
            dispatch(setEcomValues(obj));
        });
    };

    return {
        getEcomTableList,
    };
};
