import { trackPromise } from 'react-promise-tracker';

import api from '../../../../../../utils/API';

export const getPrevisaoSalva = (id) => trackPromise(
        api({
            url: `${process.env.REACT_APP_DEMAND_FORECAST}/demand-forecasts/${id}`,
            method: 'GET',
            expectToken: true,
        }).then((resp) => resp.data),
    );
