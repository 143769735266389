import Immutable from 'seamless-immutable';
import { actionsTypeHistoricoExtracaoPreco } from '../actions/actionsHistoricoExtracaoPreco';

const inicialState = Immutable({
    tiposFiltro: [
        {
            title: 'PRODUTO',
            itens: [
                {
                    name: 'productId',
                    title: 'Produto',
                    eventKey: 'productId',
                    nivel: 'productId',
                },
            ],
        },
        {
            title: 'LOJAS',
            itens: [
                {
                    name: 'storeName',
                    title: 'Lojas',
                    eventKey: 'storeName',
                    nivel: 9,
                },
            ],
        },
    ],
    filtros: [
        {
            name: 'productId',
            nivel: 'productId',
            cleanable: true,
            filter: false,
            title: 'Produto',
            placeholder: 'Selecione um ou mais itens',
            locale: {
                noResultsText: 'Nenhum item encontrado',
                searchPlaceholder: 'Digite ou busque o item desejado',
            },
            onSelect: 'handleSelect',
            onSearch: 'searchProduto',
        },
        {
            name: 'storeName',
            nivel: 'storeName',
            cleanable: true,
            filter: true,
            title: 'Lojas',
            placeholder: 'Selecione uma loja',
            locale: { noResultsText: 'Nenhum item encontrado' },
            onSelect: 'handleSelect',
            onSearch: 'searchStore',
            onChange: 'handleChange',
        },
    ],
    filtrosAdicionados: [],
    filtrosData: {
        storeName: {
            lista: [],
            value: [],
            cache: [],
        },
        productId: {
            lista: [],
            value: [],
            cache: [],
        },
    },
});

function historicoExtracaoPrecoDataReducer(state = inicialState, action) {
    switch (action.type) {
        case actionsTypeHistoricoExtracaoPreco.ADD_FILTRO_EXTRACAO_PRECO:
            return state.merge({
                filtrosAdicionados: [
                    ...state.filtrosAdicionados,
                    action.filtro,
                ],
            });
        case actionsTypeHistoricoExtracaoPreco.DELETE_FILTRO_EXTRACAO_PRECO:
            return state.merge({
                filtrosAdicionados: state.filtrosAdicionados.filter(
                    (item, index) => index !== action.index,
                ),
                filtrosData: {
                    ...state.filtrosData,
                    [`${action.name}`]: {
                        ...state.filtrosData[`${action.name}`],
                        value: [],
                    },
                },
            });
        case actionsTypeHistoricoExtracaoPreco.CHANGE_VALUE_FILTRO_EXTRACAO_PRECO:
            return state.merge({
                ...state,
                filtrosData: {
                    ...state.filtrosData,
                    [`${action.name}`]: {
                        ...state.filtrosData[`${action.name}`],
                        value: action.value,
                    },
                },
            });
        case actionsTypeHistoricoExtracaoPreco.CHANGE_VALUE_FILTRO_LOJA_REVISAO:
            return state.merge({
                ...state,
                filtrosData: {
                    ...state.filtrosData,
                    [`${action.name}`]: {
                        ...state.filtrosData[`${action.name}`],
                        value: action.value,
                    },
                },
            });
        case actionsTypeHistoricoExtracaoPreco.CHANGE_CACHE_FILTRO_EXTRACAO_PRECO:
            return state.merge({
                ...state,
                filtrosData: {
                    ...state.filtrosData,
                    [`${action.name}`]: {
                        ...state.filtrosData[`${action.name}`],
                        cache: action.cache,
                    },
                },
            });
        case actionsTypeHistoricoExtracaoPreco.CHANGE_CACHE_FILTRO_LOJA:
            return state.merge({
                ...state,
                filtrosData: {
                    ...state.filtrosData,
                    [`${action.name}`]: {
                        ...state.filtrosData[`${action.name}`],
                        cache: action.cache,
                    },
                },
            });
        case actionsTypeHistoricoExtracaoPreco.SELECT_VALUE_FILTRO_EXTRACAO_PRECO:
            return state.merge({
                ...state,
                filtrosData: {
                    ...state.filtrosData,
                    [`${action.name}`]: {
                        ...state.filtrosData[`${action.name}`],
                        value: action.item,
                    },
                },
            });
        case actionsTypeHistoricoExtracaoPreco.SELECT_VALUE_FILTRO_LOJAS_REVISAO:
            return state.merge({
                ...state,
                filtrosData: {
                    ...state.filtrosData,
                    [`${action.name}`]: {
                        ...state.filtrosData[`${action.name}`],
                        cache: [
                            ...state.filtrosData[`${action.name}`].cache,
                            action.item,
                        ],
                    },
                },
            });
        case actionsTypeHistoricoExtracaoPreco.CLEAR_ALL_FILTRO_EXTRACAO_PRECO:
            return state.merge({
                ...state,
                tiposFiltro: inicialState.tiposFiltro,
                filtrosData: inicialState.filtrosData,
                filtrosAdicionados: inicialState.filtrosAdicionados,
            });
        case actionsTypeHistoricoExtracaoPreco.LIST_ITENS_FILTRO_EXTRACAO_PRECO:
            return state.merge({
                ...state,
                filtrosData: {
                    ...state.filtrosData,
                    [`${action.name}`]: {
                        ...state.filtrosData[`${action.name}`],
                        lista: action.itens?.map((item) => ({
                            label: item,
                            value: item,
                        })),
                    },
                },
            });

        case actionsTypeHistoricoExtracaoPreco.GET_FILTROS_EXTRACAO_PRECO:
            return state.merge({
                ...state,
                tiposFiltro: state.tiposFiltro.some(
                    (item) => item.title === 'CATEGORIZAÇÃO',
                )
                    ? state.tiposFiltro
                    : [
                          ...state.tiposFiltro,
                          {
                              title: 'CATEGORIZAÇÃO',
                              itens: action.itens.map((item) => ({
                                  name: item.field,
                                  title: item.name,
                                  eventKey: item.field,
                                  nivel: item.level,
                              })),
                          },
                      ],
                filtros: state.tiposFiltro.some(
                    (item) => item.title === 'CATEGORIZAÇÃO',
                )
                    ? state.filtros
                    : state.filtros.concat(
                          action.itens.map((item) => ({
                              name: item.field,
                              nivel: item.level,
                              cleanable: true,
                              filter: false,
                              title: item.name,
                              placeholder: 'Selecione um ou mais itens',
                              locale: {
                                  noResultsText: 'Nenhum item encontrado',
                                  searchPlaceholder:
                                      'Digite ou busque o item desejado',
                              },
                              onSelect: 'handleSelect',
                              onSearch: 'search',
                              onChange: 'handleChange',
                          })),
                      ),
                filtrosData: { ...action.filtros, ...state.filtrosData },
            });
        case actionsTypeHistoricoExtracaoPreco.LIST_ITENS_PRODUTO_EXTRACAO:
            return state.merge({
                ...state,
                filtrosData: {
                    ...state.filtrosData,
                    [`${action.name}`]: {
                        ...state.filtrosData[`${action.name}`],
                        lista: action.itens.data
                            .map((item) => ({
                                label: `${item.product_id} - ${item.description}`,
                                value: item.product_id,
                                gtin: item.gtin,
                                product_id: item.product_id,
                            }))
                            .concat(
                                state.filtrosData[
                                    `${action.name}`
                                ].cache.filter(
                                    (item) => action.itens.data.findIndex(
                                            (itemLista) => itemLista.product_id
                                                === item.product_id,
                                        ) === -1,
                                ),
                            ),
                    },
                },
            });
        case actionsTypeHistoricoExtracaoPreco.LIST_ITENS_LOJA_EXTRACAO:
            return state.merge({
                ...state,
                filtrosData: {
                    ...state.filtrosData,
                    [`${action.name}`]: {
                        ...state.filtrosData[`${action.name}`],
                        lista: action.itens
                            .map((item) => ({
                                label: `${item.storeId} - ${item.store}`,
                                value: item.store,
                            }))
                            .concat(
                                state.filtrosData[
                                    `${action.name}`
                                ].cache.filter(
                                    (item) => action.itens.findIndex(
                                            (itemLista) => itemLista === item,
                                        ) === -1,
                                ),
                            ),
                    },
                },
            });
        default:
            return state;
    }
}

export default historicoExtracaoPrecoDataReducer;
