import React, { HTMLAttributes } from 'react';
import styles from './styles.module.scss';

export interface IBoxMainValue {
    children: string | number;
    className?: HTMLAttributes<HTMLParagraphElement>;
}

export const BoxMainValue = ({ children, className }: IBoxMainValue) => (
    <p className={`${styles['main-value']} ${className}`}>{children}</p>
);
