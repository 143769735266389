import classNames from 'classnames';
import React from 'react';
import { MdAccountTree, MdStore } from 'react-icons/md';
import {
    Callout,
    Chip,
    FilterButton,
    FilterPlaceholder,
    InnerFilter,
    Layout,
    TableHeading,
} from '../../../../../components';
import { SegmentsPlaceholder } from '../../../../../components/NewFilterBox/Components';
import {
    MargemObjetivaFallback,
    MargemObjetivaSkeleton,
} from '../../components';
import { MargemObjetivaTable } from '../../components/MargemObjetivaTable';
import { useMargemObjetiva } from '../../hooks';
import styles from './MargemObjetiva.module.scss';

export const MargemObjetiva = ({
    className,
    ...props
}: React.HtmlHTMLAttributes<HTMLElement>) => {
    const {
        lastUpdatedDate,
        filterList,
        onClearAll,
        segment,
        isLoadingSegment,
        table,
    } = useMargemObjetiva();

    if (isLoadingSegment) return <MargemObjetivaSkeleton />;

    return (
        <Layout
            className={classNames(styles['margem-objetiva'], className)}
            {...props}
        >
            <Layout.Header className={styles['margem-objetiva__header']}>
                <div
                    className={
                        styles['margem-objetiva__header__left-container']
                    }
                >
                    <Layout.Title>Margem objetiva</Layout.Title>
                    <Callout className={styles.callout}>
                        As alterações são salvas automaticamente e serão
                        aplicadas na próxima integração de dados
                    </Callout>
                    <p>
                        Defina a margem objetiva de cada categoria para alcançar
                        seus objetivos de negócio
                    </p>
                    {segment && (
                        <Layout.Subtitle>
                            Última atualização:
                            {` ${lastUpdatedDate}`}
                        </Layout.Subtitle>
                    )}
                </div>
                <div
                    className={
                        styles['margem-objetiva__header__right-container']
                    }
                >
                    <Chip leftIcon={<MdStore />}>Loja</Chip>
                    {segment && (
                        <Chip leftIcon={<MdAccountTree />}>
                            <SegmentsPlaceholder
                                level={segment.level}
                                placeholder={segment.name}
                            />
                        </Chip>
                    )}
                </div>
            </Layout.Header>
            <Layout.Body>
                {segment ? (
                    <>
                        <Layout.Section padded>
                            <div className={styles['margem-objetiva__filters']}>
                                {filterList.map(
                                    ({ key, placeholder, ...filter }) => (
                                        <InnerFilter
                                            // @ts-expect-error
                                            placeholder={
                                                <FilterPlaceholder
                                                    key={key}
                                                    dataKey={key}
                                                    placeholder={placeholder}
                                                />
                                            }
                                            {...filter}
                                        />
                                    ),
                                )}

                                <FilterButton
                                    onClick={onClearAll}
                                    className={
                                        styles[
                                            'margem-objetiva__filters-clear-btn'
                                        ]
                                    }
                                >
                                    Limpar filtros
                                </FilterButton>
                            </div>
                        </Layout.Section>
                        <Layout.Section>
                            <TableHeading>
                                <TableHeading.TotalProducts
                                    total={table.total}
                                />
                            </TableHeading>
                        </Layout.Section>
                        <Layout.Section>
                            <MargemObjetivaTable {...table} />
                        </Layout.Section>
                    </>
                ) : (
                    <Layout.Section padded>
                        <MargemObjetivaFallback />
                    </Layout.Section>
                )}
            </Layout.Body>
        </Layout>
    );
};
