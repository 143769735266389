import classNames from 'classnames';
import React from 'react';
import { CheckPicker } from 'rsuite';
import { CheckPickerProps } from 'rsuite/lib/CheckPicker';
import { parseSelectedText } from '../../../pages/IPA/Estrategia/pages/RegrasDePreco/lib';
import { FilterSelectAll, FilterSelectAllProps } from '../../FilterSection';
import { ChipIcon } from '../ChipIcon';
import { Loading } from '../Loading';
import styles from './CheckPill.module.scss';

type RenderValueProps = React.HtmlHTMLAttributes<HTMLDivElement> & {
    value: any[];
};

const RenderValue = ({
    className,
    children,
    value,
    ...props
}: RenderValueProps) => {
    return (
        <div
            className={classNames(styles['render-value'], className)}
            {...props}
        >
            <span>{value && children}</span>
            <span className="counter">{value && value?.length}</span>
        </div>
    );
};

export type CheckPillProps = CheckPickerProps & {
    startIcon?: React.ReactNode;
    endIcon?: React.ReactNode;
    error?: boolean;
    selectedValueText?: string;
    selectAll?: FilterSelectAllProps;
    isLoading?: boolean;
    textLoading?: string;
};

export const CheckPill = ({
    data = [],
    value = [],
    className,
    placeholder = 'Selecione uma opção',
    startIcon,
    endIcon,
    error,
    selectedValueText = parseSelectedText(value.length),
    selectAll,
    isLoading,
    textLoading = 'Carregando...',
    textSearch = 'Pesquisar',
    locale,
    disabled,
    renderMenu,
    renderExtraFooter,
    onChange,
    ...props
}: CheckPillProps) => {
    return (
        <div
            className={classNames(
                styles['select-pill'],
                'info-filter',
                error && 'info-filter-error',
                className,
            )}
        >
            {startIcon && (
                <ChipIcon className="filter-start-icon">{startIcon}</ChipIcon>
            )}
            <CheckPicker
                data={data}
                placeholder={placeholder}
                value={disabled ? undefined : value}
                onChange={(value, event) => {
                    onChange?.(value ?? undefined, event);
                }}
                renderValue={(value) => (
                    <RenderValue value={value}>
                        {selectedValueText || placeholder}
                    </RenderValue>
                )}
                renderMenu={
                    renderMenu ||
                    ((menu) => {
                        if (isLoading) return <Loading>{textLoading}</Loading>;
                        return menu;
                    })
                }
                locale={
                    locale || {
                        noResultsText: isLoading
                            ? textLoading
                            : 'Nenhum item encontrado',
                        searchPlaceholder: textSearch,
                    }
                }
                renderExtraFooter={
                    renderExtraFooter ||
                    (selectAll
                        ? () => {
                              return (
                                  <FilterSelectAll
                                      checked={
                                          data?.length === value?.length &&
                                          !!value?.length
                                      }
                                      {...selectAll}
                                  />
                              );
                          }
                        : undefined)
                }
                {...props}
            />

            {endIcon && (
                <ChipIcon className="filter-end-icon">{endIcon}</ChipIcon>
            )}
        </div>
    );
};
