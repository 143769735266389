import Immutable from 'seamless-immutable';

import {
    ADD_FILTRO_REVISAO_LISTA,
    DELETE_FILTRO_REVISAO_LISTA,
    LIST_ITENS_FILTROS_REVISAO_LISTA,
    CHANGE_RECOMMENDATION,
    SELECT_FILTER_REVISAO_LISTA,
    CLEAR_FILTER_REVISAO_LISTA,
} from '../actions/actionsRevisaoLista';
import { mapFilterList } from '../pages/ISA/MPDV/RevisaoLista/utils';

const revisaoListaInitialState = Immutable({
    categorias: [
        {

            title: 'COLETA',
            itens: [
                {
                    name: 'escopo',
                    title: 'Escopo',
                },
                {
                    name: 'tipoSolicitacao',
                    title: 'Tipo de solicitação',
                },
            ],
        },
        {
            title: 'PRODUTO',
            itens: [
                {
                    name: 'secao',
                    title: 'Seção',
                },
                {
                    name: 'categoria',
                    title: 'Categoria',
                },
            ],
        },
        {
            title: 'LOCALIZAÇÃO',
            itens: [
                {
                    name: 'uf',
                    title: 'UF',
                },
                {
                    name: 'cidade',
                    title: 'Cidade',
                },
            ],
        },
        {
            title: 'CONCORRENTE',
            itens: [
                {
                    name: 'rede',
                    title: 'Rede',
                },
            ],
        },
    ],
    filtros: [
        {
            id: 'escopo-filter',
            value: null,
            cleanable: true,
            searchable: true,
            data: [],
            name: 'escopo',
            placeholder: 'Escopo',
            locale: { noResultsText: 'Nenhum item encontrado' },
        },
        {
            id: 'tipo-solicitacao-filter',
            value: null,
            cleanable: true,
            searchable: true,
            data: [],
            name: 'tipoSolicitacao',
            placeholder: 'Tipo de solicitação',
            locale: { noResultsText: 'Nenhum item encontrado' },
        },
        {
            id: 'secao-filter',
            value: null,
            cleanable: true,
            searchable: true,
            data: [],
            name: 'secao',
            placeholder: 'Seção',
            locale: { noResultsText: 'Nenhum item encontrado' },
        },
        {
            id: 'categoria-filter',
            value: null,
            cleanable: true,
            searchable: true,
            data: [],
            name: 'categoria',
            placeholder: 'Categoria',
            locale: { noResultsText: 'Nenhum item encontrado' },
        },
        {
            id: 'uf-filter',
            value: null,
            cleanable: true,
            searchable: true,
            data: [],
            name: 'uf',
            placeholder: 'UF',
            locale: { noResultsText: 'Nenhum item encontrado' },
        },
        {
            id: 'cidade-filter',
            value: null,
            cleanable: true,
            searchable: true,
            data: [],
            name: 'cidade',
            placeholder: 'Cidade',
            locale: { noResultsText: 'Nenhum item encontrado' },
        },
        {
            id: 'rede-filter',
            value: null,
            cleanable: true,
            searchable: true,
            data: [],
            name: 'rede',
            placeholder: 'Rede',
            locale: { noResultsText: 'Nenhum item encontrado' },
        },
    ],
    filtrosAdicionados: [],
    filtrosData: {
        produto: {
            value: [],
            data: [],
            cache: [],
        },
        loja: {
            value: [],
            data: [],
            cache: [],
        },
        escopo: {
            value: [],
            data: [],
            cache: [],
        },
        tipoSolicitacao: {
            value: [],
            data: [],
            cache: [],
        },
        secao: {
            value: [],
            data: [],
            cache: [],
        },
        categoria: {
            value: [],
            data: [],
            cache: [],
        },
        uf: {
            value: [],
            data: [],
            cache: [],
        },
        cidade: {
            value: [],
            data: [],
            cache: [],
        },
        rede: {
            value: [],
            data: [],
            cache: [],
        },
        recommendation: true,
    },
});

function revisaoListaDataReducer(state = revisaoListaInitialState, action) {
    switch (action.type) {
        case ADD_FILTRO_REVISAO_LISTA:
            return state.merge({
                filtrosAdicionados: [...state.filtrosAdicionados, action.filtro],
            });
        case DELETE_FILTRO_REVISAO_LISTA:
            return state.merge({
                filtrosAdicionados: state.filtrosAdicionados.filter((item, index) => index !== action.index),
                filtrosData: {
                    ...state.filtrosData,
                    [action.name]: {
                        ...state.filtrosData[action.name],
                        value: [],
                        disabled: [],
                        cache: [],
                    },
                },
            });
        case CHANGE_RECOMMENDATION:
            return state.merge({
                ...state,
                filtrosData: {
                    ...state.filtrosData,
                    recommendation: action.data,
                },
            });

        case CLEAR_FILTER_REVISAO_LISTA:
            return state.merge({
                ...state,
                filtrosData: {
                    ...state.filtrosData,
                    [action.name]: {
                        ...state.filtrosData[action.name],
                        cache: [],
                        value: [],
                    },
                },
            });
        case SELECT_FILTER_REVISAO_LISTA:
            return state.merge({
                ...state,
                filtrosData: {
                    ...state.filtrosData,
                    [action.name]: {
                        ...state.filtrosData[action.name],
                        cache: action.cache,
                        value: action.value,
                    },
                },
            });
        case LIST_ITENS_FILTROS_REVISAO_LISTA:
            return state.merge({
                ...state,
                filtrosData: {
                    ...state.filtrosData,
                    [`${action.name}`]: {
                        ...state.filtrosData[`${action.name}`],
                        data: action.itens.map((item) => mapFilterList(action.name, item)).concat(
                            state.filtrosData[`${action.name}`].cache.filter(
                                (item) => action.itens.findIndex(
                                    (itemLista) => (itemLista.value === item.value),
                                ) === -1,
                            ),
                        ),
                    },
                },
            });
        default:
            return state;
    }
}

export default revisaoListaDataReducer;
