import React, { memo, useMemo } from 'react';
import { Whisper } from 'rsuite';
import {
    InputAddon,
    InputCurrency,
    InputGroup,
} from '../../../../../../components';
import { InputCurrencyProps } from '../../../../../../components/InputCurrency/InputCurrency';
import { IPAMaths } from '../../../../../../lib';
import { VariationBoxIndicator } from '../../../../Negociacoes/pages/NegociacaoFornecedor/components';
import { CompetitivenessLimitInfo } from '../../CompetitivenessLimitInfo';
import { BaseCell } from '../BaseCell';
import { BaseCellWithUpdateCampoProps } from '../BaseCell/BaseCell';

const { MainContainer, SubContainer } = BaseCell;

const CpiCell = ({
    rowData,
    index,
    dataKey,
    handleUpdateCampo,
    handleBlur,
    ...props
}: BaseCellWithUpdateCampoProps) => {
    const {
        novaCompetitividade,
        competitorsPrice,
        maxCpi,
        minCpi,
        novaMargem,
        price,
        retailPrice,
        retailCpi,
    } = rowData;

    const cpiVariation = useMemo(() => {
        return IPAMaths.variation.cpi(novaCompetitividade, retailCpi);
    }, [novaCompetitividade, retailCpi]);

    const skin = useMemo(() => {
        let skin: InputCurrencyProps['skin'] = 'gray';

        if (!competitorsPrice || !novaCompetitividade || !maxCpi || !minCpi) {
            return skin;
        }

        if (
            competitorsPrice > 0 &&
            (novaCompetitividade > maxCpi * 100 ||
                novaCompetitividade < minCpi * 100)
        ) {
            skin = 'red';
        }

        return skin;
    }, [novaCompetitividade, competitorsPrice, maxCpi, minCpi]);

    return (
        <BaseCell rowData={rowData} {...props}>
            <MainContainer>
                <InputGroup>
                    <InputCurrency
                        name={dataKey}
                        value={novaCompetitividade || 0}
                        skin={skin}
                        className="nova-competitividade"
                        disabled={!competitorsPrice}
                        decimalSeparator=","
                        thousandSeparator="."
                        precision="1"
                        allowEmpty
                        allowNegative={false}
                        onBlur={() => handleBlur(rowData, index)}
                        onChangeEvent={(_e, _, v) => {
                            handleUpdateCampo(dataKey, v, rowData, index);
                        }}
                    />
                    <Whisper
                        trigger="click"
                        placement="bottomEnd"
                        speaker={
                            // @ts-ignore
                            <CompetitivenessLimitInfo
                                content={rowData}
                                margin={novaMargem}
                                cpi={novaCompetitividade}
                            />
                        }
                    >
                        <InputAddon>%</InputAddon>
                    </Whisper>
                </InputGroup>
            </MainContainer>
            <SubContainer>
                {!!price && !!competitorsPrice && !!retailPrice ? (
                    <VariationBoxIndicator
                        className="box-indicator"
                        value={cpiVariation}
                    />
                ) : (
                    <>--</>
                )}
            </SubContainer>
        </BaseCell>
    );
};

export default memo(CpiCell);
