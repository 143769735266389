import React from 'react';
import { CellProps } from './Cell';

export const ProductCell = ({
    gtin,
    description,
    className = '',
    ...props
}: CellProps) => (
    <div
        className={['gerenciador-table__cell__product', className].join(' ')}
        {...props}
    >
        <div className="flex-align-center font-size-100-semibold">
            <p>{gtin}</p>
            {/* {image && <Icon icon="MdImage" />} */}
        </div>
        <p className="font-size-75-semibold">{description}</p>
    </div>
);
