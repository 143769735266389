import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Alert } from 'rsuite';
import {
    saveFiltersCompetitivePreference,
    updateSavedFilters,
    getfiltrosSalvos,
} from '../services';

export type AnaliseCompetitividadeStateProps = {
    analiseCompetitividadeData: {
        tipoLocalizacao: string;
        gtins: number[];
        periodoSemanas: null | number;
        redeReferencia: string;
        redesCompetidoras: string[];
        listaLocalizacao: string[];
    };
    analiseIndustriaCompetitividadeData: {
        tipoLocalizacao: string;
        produtoReferencia: string;
        produtosCompetidores: string[];
        periodoSemanas: null;
        redes: string[];
        listaLocalizacao: string[];
    };
};

export const useSaveFilter = (
    competitionType: string,
    filtroSalvoSelecionado: any,
) => {
    const [nameFilter, setNameFilter] = useState('');
    const [openModalSaveFilters, setOpenModalSaveFilters] = useState(false);
    const [saveFiltroResponse, setSaveFiltroResponse] = useState([]);
    const [dataSaveFiltro, setDataSaveFiltro] = useState([]);
    const [radioList, setRadioList] = useState('CRI');

    const { analiseCompetitividadeData, analiseIndustriaCompetitividadeData }
        = useSelector(
            (state: {
                analiseCompetitividadeDataReducer: AnaliseCompetitividadeStateProps;
            }) => ({
                analiseCompetitividadeData: state.analiseCompetitividadeDataReducer.analiseCompetitividadeData,
                analiseIndustriaCompetitividadeData: state.analiseCompetitividadeDataReducer.analiseIndustriaCompetitividadeData,
            }),
        );

    const searchFiltersSave = async () => {
        const competitionTypeUpperCase
            = competitionType === 'varejo' ? 'VAREJO' : 'PRODUTO';

        const response = await getfiltrosSalvos(competitionTypeUpperCase, '');

        if (response) {
            setSaveFiltroResponse(response);
            const mapSave = response.map((item) => ({
                label: item.nomeFiltro,
                value: item.id,
            }));
            setDataSaveFiltro(mapSave);
        }
    };

    const handleHideModal = () => {
        setOpenModalSaveFilters(false);
        setNameFilter('');
    };

    const handleChangeRadio = (value: string, name: string) => {
        setRadioList(value);
        setNameFilter(name);
    };

    const salvarFiltro = async (type: string, id: string) => {
        const competition = competitionType === 'varejo' ? 'VAREJO' : 'PRODUTO';
        const preenchimentoEvolucao = 'PREENCHER_E_REPLICAR';

        const filters = {
            tipoFiltro: competition,
            nomeFiltro: nameFilter,
            request:
            competitionType === 'varejo'
                ? {
                preenchimentoEvolucao,
                agrupamentoEvolucao: 'REDE',
                tipoLocalizacao: analiseCompetitividadeData.tipoLocalizacao,
                gtins: analiseCompetitividadeData.gtins,
                periodoSemanas: analiseCompetitividadeData.periodoSemanas,
                redeReferencia: analiseCompetitividadeData.redeReferencia,
                redesCompetidoras: analiseCompetitividadeData.redesCompetidoras,
                listaLocalizacao: analiseCompetitividadeData.listaLocalizacao,
            }
            : {
                preenchimentoEvolucao,
                agrupamentoEvolucao: 'PRODUTO',
                tipoLocalizacao: analiseIndustriaCompetitividadeData.tipoLocalizacao,
                gtins: analiseIndustriaCompetitividadeData.produtosCompetidores,
                periodoSemanas: analiseIndustriaCompetitividadeData.periodoSemanas,
                redeReferencia: analiseIndustriaCompetitividadeData.produtoReferencia,
                redesCompetidoras: analiseIndustriaCompetitividadeData.redes,
                listaLocalizacao: analiseIndustriaCompetitividadeData.listaLocalizacao,
            },
        };

        const hasAtLeastOneFilterSelected
            = filters.request.gtins.length !== 0
            || filters.request.tipoLocalizacao !== null
            || filters.request.periodoSemanas !== null
            || filters.request.redeReferencia !== null
            || filters.request.redesCompetidoras.length !== 0
            || filters.request.listaLocalizacao.length !== 0;

        if (nameFilter === '') {
            Alert.warning('Preencha o campo do nome do filtro', 5000);
            return;
        }

        if (!hasAtLeastOneFilterSelected) {
            Alert.warning('Selecione ao menos um filtro para salvar', 5000);
            return;
        }

        const response = type === 'CRI'
            ? await saveFiltersCompetitivePreference(filters)
            : await updateSavedFilters(filters, id);

        if (response) {
            setOpenModalSaveFilters(false);
            Alert.success(type === 'CRI' ? 'Filtro salvo com sucesso' : 'Filtro editado com sucesso');
            searchFiltersSave();
        }
    };

    useEffect(() => {
        if (filtroSalvoSelecionado?.nomeFiltro) {
            setNameFilter(filtroSalvoSelecionado?.nomeFiltro);
        } else {
            setNameFilter('');
        }
    }, [filtroSalvoSelecionado]);

    return {
        nameFilter,
        openModalSaveFilters,
        saveFiltroResponse,
        dataSaveFiltro,
        radioList,
        setRadioList,
        setDataSaveFiltro,
        setSaveFiltroResponse,
        setOpenModalSaveFilters,
        setNameFilter,
        salvarFiltro,
        searchFiltersSave,
        handleHideModal,
        handleChangeRadio,
    };
};
