import React, { ReactNode } from 'react';

import styles from './ForecastNumbers.module.scss';

export interface IForecastNumbersRoot {
    children: ReactNode;
    title?: string;
    className?: string;
    sideTitle?: string;
}

export const ForecastNumbersRoot = ({
 children, title, className, sideTitle,
}: IForecastNumbersRoot) => (
    <div
        className={`${styles['forecast-container']} ${className}`}
    >
        <div className={styles['forecast-title-wrapper']}>
            {title ? <p className={styles['forecast-title']}>{title}</p> : null}
            {sideTitle ? <p className={styles['forecast-side-title']}>{sideTitle}</p> : null}
        </div>

        {children}
    </div>
);
