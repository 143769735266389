import React from 'react'
import { Progress } from 'rsuite';
import style from './CircleProgress.module.scss'
const { Circle } = Progress;

type CircleProgressProps = {
    children?: React.ReactNode,
    strokeColor?: string,
    percent?: number,
    showInfo?: boolean,
    strokeWidth?: number
}

const CircleProgress = ({children, percent, showInfo, strokeColor, strokeWidth }: CircleProgressProps) => {
    return (
        <div className={style['circle-progress-container']}>
            <Circle
                percent={percent}
                showInfo={showInfo}
                strokeColor={strokeColor}
                strokeWidth={strokeWidth}
            />
            {children}
        </div>
    )
}

export default CircleProgress
