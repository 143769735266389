import React from 'react';
import {
 Popover, Row, Col, Nav, Icon,
} from 'rsuite';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
    addFiltro,
    deleteFiltro,
    selectValue,
    selectValueLojas,
    changeValueFiltro,
    changeValueFiltroLoja,
    changeCacheFiltro,
    changeCacheFiltroLoja,
    search,
    searchSegmentos,
    searchProduto,
    searchFamiliaProduto,
    searchStore,
    searchRuleType,
    clearFiltros,
    searchSegmentosClasses,
    searchSegmentosSensibilities,
} from '../../../../actions/actionsRevisao';

const CustomNav = ({
 title, icon, itens, onSelect, activeKey,
}) => (
    <Nav
        activeKey={activeKey}
        vertical
        onSelect={onSelect}
        className="nav-filtro-dinamico"
    >
        <div className="nav-filtro-dinamico__title-div">
            <Icon icon={icon} />
            <p className="nav-filtro-dinamico__title">{title}</p>
        </div>
        {itens.map((item) => (
            <Nav.Item key={item.name} name={item.name} eventKey={item.name}>
                {item.title}
            </Nav.Item>
        ))}
    </Nav>
);

const MenuFiltros = ({
    onMouseEnter,
    onMouseLeave,
    className,
    style,
    filtrosAdicionados,
    filtros,
    addFiltro,
    tiposFiltro,
    ...props
}) => {
    const handleClick = (eventKey, b) => {
        const isNewFiltro
            = filtrosAdicionados.findIndex(
                (item) => item.name === b.target.name,
            ) === -1;
        if (isNewFiltro) {
            const filtroList = filtros;
            const newFiltro
                = filtroList[
                    filtroList.findIndex((item) => item.name === b.target.name)
                ];
            if (newFiltro.onSearch) {
                props[newFiltro.onSearch](newFiltro.nivel, '', b.target.name);
            }
            addFiltro(newFiltro);
        }
    };

    return (
        <Popover
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            className={className}
            style={style}
        >
            <Row className="popover-filtro-dinamico__row">
                {tiposFiltro?.map((item) => (
                    <Col
                        md={12}
                        key={item.title}
                        className="popover-filtro-dinamico__col"
                    >
                        <CustomNav
                            title={item.title}
                            icon={item.icon}
                            itens={item.itens}
                            onSelect={handleClick}
                        />
                    </Col>
                ))}
            </Row>
        </Popover>
    );
};

const mapStateToProps = (store) => ({
    tiposFiltro: store.revisaoDataReducer.tiposFiltro,
    filtros: store.revisaoDataReducer.filtros,
    filtrosAdicionados: store.revisaoDataReducer.filtrosAdicionados,
    filtrosData: store.revisaoDataReducer.filtrosData,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
        {
            addFiltro,
            deleteFiltro,
            selectValue,
            selectValueLojas,
            changeValueFiltro,
            changeValueFiltroLoja,
            changeCacheFiltro,
            changeCacheFiltroLoja,
            search,
            searchSegmentos,
            searchProduto,
            searchFamiliaProduto,
            searchStore,
            searchRuleType,
            searchSegmentosClasses,
            searchSegmentosSensibilities,
            clearFiltros,
        },
        dispatch,
    );

export default connect(mapStateToProps, mapDispatchToProps)(MenuFiltros);
