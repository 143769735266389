import DoneIcon from '@material-ui/icons/Done';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import React from 'react';
import { Tooltip, Whisper } from 'rsuite';
import styles from './styles.module.scss';

const alertTooltipMessage = (message: string) => (
    <Whisper
        trigger="hover"
        placement="topEnd"
        speaker={
            <Tooltip>{message}</Tooltip>
        }
    >
        <ReportProblemIcon className={styles?.['gtin-alert-icon']} />
    </Whisper>
);

export const useRenderInputEditStatus = () => {
    const renderIconGtinStatus = (inactive: boolean, status: boolean, valueLength: number) => {
        if (inactive) {
            return null;
        }

        if (valueLength && status) {
            return <DoneIcon className={styles?.['done-icon']} />;
        }
        return alertTooltipMessage('Código de barras inválido');
    };

    const renderIconKeywordStatus = (inactive: boolean, valueLength: number) => {
        if (inactive) {
            return null;
        }

        if (valueLength >= 2) {
            return <DoneIcon className={styles?.['done-icon']} />;
        }
        return alertTooltipMessage('Palavra chave inválida');
    };

    const renderInputGtinStyle = (inactive: boolean, status: boolean, valueLength: number) => {
        if (inactive) {
            return 'regular-input';
        }

        if (valueLength && status) {
            return 'regular-input';
        }
        return 'alert-input';
    };

    const renderInputKeywordStyle = (inactive: boolean, valueLength: number) => {
        if (inactive) {
            return 'regular-input';
        }

        if (valueLength >= 2) {
            return 'regular-input';
        }
            return 'alert-input';
    };

    return {
        renderIconGtinStatus,
        renderIconKeywordStatus,
        renderInputKeywordStyle,
        renderInputGtinStyle,
    };
};
