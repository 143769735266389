export const ADD_FILTRO_EXTRACAO = 'ADD_FILTRO_EXTRACAO'
export const DELETE_FILTRO_EXTRACAO = 'DELETE_FILTRO_EXTRACAO'
export const UPDATE_FILTER_DATA_EXTRACAO = 'UPDATE_FILTER_DATA_EXTRACAO'
export const UPDATE_FILTER_VALUE_EXTRACAO = 'UPDATE_FILTER_VALUE_EXTRACAO'
export const DELETE_FILTRO_REGIAO_EXTRACAO = 'DELETE_FILTRO_REGIAO_EXTRACAO'

export function addFiltroExtracao(filtro) {
    return {
        type: ADD_FILTRO_EXTRACAO,
        filtro
    }
}

export function deleteFiltroExtracao(index, name) {
    return {
        type: name === 'uf' ? DELETE_FILTRO_REGIAO_EXTRACAO : DELETE_FILTRO_EXTRACAO,
        index,
        name
    }
}

export function updateFilterDataExtracao(name, data) {
    return {
        type: UPDATE_FILTER_DATA_EXTRACAO,
        name,
        data
    }
}

export function updateFilterValue(name, value) {
    return {
        type: UPDATE_FILTER_VALUE_EXTRACAO,
        name,
        value
    }
}