import React from 'react';

export const MdArrowForward = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        {...props}
    >
        <path
            d="M9.99967 3.3335L8.82467 4.5085L13.4747 9.16683H3.33301V10.8335H13.4747L8.82467 15.4918L9.99967 16.6668L16.6663 10.0002L9.99967 3.3335Z"
            fill="currentColor"
        />
    </svg>
);
