import React from 'react';
import { Content } from 'rsuite';
import Badge from '../../../../../components/Badge';

const Header = ({ title, subtitle, badgeLabel, badgeClassName }) => (
    <Content className="infopanel-header">
        <div className="infopanel-header-title-container">
            <p className="infopanel-header-title">{title}</p>
            {badgeLabel && (
                <Badge label={badgeLabel} className={badgeClassName} />
            )}
        </div>
        <div className="infopanel-header-subtitle-container">
            <p className="infopanel-header-subtitle">{subtitle}</p>
        </div>
    </Content>
);

export default Header;
