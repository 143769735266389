import Immutable from 'seamless-immutable';
import {
    ADD_SEGMENTO,
    DELETE_SEGMENTO,
    CHANGE_VALUE_SEGMENTO,
    SELECT_VALUE_SEGMENTO,
    CHANGE_CACHE_SEGMENTO,
    LIST_ITENS_SEGMENTOS,
    LIST_ITENS_SEGMENTOS_CLASSES_REGRA_PRECO_CADASTRO,
    GET_SEGMENTOS_REGRA_PRECO,
    CLEAR_ALL_SEGMENTOS,
    SET_QTD_PRODUTO_AFETADOS_REGRA_PRECO,
    SET_SEGMENTOS_UPDATE_REGRA,
    SET_SEGMENTOS_PRODUTOS_UPDATE_REGRA,
    LIST_ITENS_PRODUTO_CADASTRO_REGRA,
    SET_SEGMENTOS_CLASSES_UPDATE_REGRA,
    SET_SEGMENTOS_SENSIBILITY_UPDATE_REGRA,
    LIST_ITEMS_LOJA_CADASTRO_REGRA,
    LIST_ITEMS_CLUSTER_CADASTRO_REGRA,
    SET_LOJAS_UPDATE_REGRA,
    SET_CLUSTERS_UPDATE_REGRA,
} from '../actions/actionsRegraPrecoCadastro';

const regraPrecoInitialState = Immutable({
    tiposSegmento: [
        {
            title: 'LOJA',
            itens: [
                {
                    name: 'searchStoreIds',
                    title: 'Lojas',
                    eventKey: 'searchStoreIds',
                    nivel: 'searchStoreIds',
                },
                {
                    name: 'searchCluster',
                    title: 'Clusters',
                    eventKey: 'searchCluster',
                    nivel: 'searchCluster',
                },
            ],
        },
        {
            title: 'PRODUTO',
            itens: [
                {
                    name: 'productId',
                    title: 'Produto',
                    eventKey: 'productId',
                    nivel: 'productId',
                },
            ],
        },
        {
            title: 'CLASSIFICAÇÃO',
            itens: [
                {
                    name: 'sensibilityType',
                    title: 'Sensibilidade',
                    eventKey: 'sensibilityType',
                    nivel: 8,
                },
            ],
        },
    ],
    segmentosAdicionados: [],
    segmentos: [
        {
            name: 'productId',
            nivel: 'productId',
            cleanable: true,
            filter: false,
            title: 'Produto',
            placeholder: 'Selecione um ou mais itens',
            locale: {
                noResultsText: 'Nenhum item encontrado',
                searchPlaceholder: 'Digite ou busque o item desejado',
            },
            onSelect: 'handleSelect',
            onSearch: 'searchProduto',
            onChange: 'handleChange',
        },
        {
            name: 'searchStoreIds',
            nivel: 'searchStoreIds',
            cleanable: true,
            filter: true,
            title: 'Lojas',
            placeholder: 'Selecione uma loja',
            locale: {
                noResultsText: 'Nenhum item encontrado',
                searchPlaceholder: 'Digite ou busque o item desejado',
            },
            onSelect: 'handleSelect',
            onSearch: 'searchStore',
            onChange: 'handleChange',
        },
        {
            name: 'searchCluster',
            nivel: 'searchCluster',
            cleanable: true,
            filter: true,
            title: 'Clusters',
            placeholder: 'Selecione um cluster',
            locale: {
                noResultsText: 'Nenhum item encontrado',
                searchPlaceholder: 'Digite ou busque o item desejado',
            },
            onSelect: 'handleSelect',
            onSearch: 'searchCluster',
            onChange: 'handleChange',
        },
        {
            name: 'sensibilityType',
            nivel: 8,
            cleanable: true,
            filter: false,
            title: 'Sensibilidade',
            placeholder: 'Selecione um ou mais itens',
            locale: {
                noResultsText: 'Nenhum item encontrado',
                searchPlaceholder: 'Digite ou busque o item desejado',
            },
            onSelect: 'handleSelect',
            onSearch: 'searchSegmentosSensibilities',
            onChange: 'handleChange',
        },
    ],
    segmentosData: {
        productId: {
            lista: [],
            value: [],
            cache: [],
        },
        searchStoreIds: {
            lista: [],
            value: [],
            cache: [],
        },
        searchCluster: {
            lista: [],
            value: [],
            cache: [],
        },
        sensibilityType: {
            lista: [],
            value: [],
            cache: [],
        },
    },
    qtdProdutosAfetados: '',
});

// eslint-disable-next-line default-param-last
function regraPrecoCadastroDataReducer(state = regraPrecoInitialState, action) {
    switch (action.type) {
        case ADD_SEGMENTO:
            return state.merge({
                segmentosAdicionados: [
                    ...state.segmentosAdicionados,
                    action.filtro,
                ],
            });
        case DELETE_SEGMENTO:
            return state.merge({
                segmentosAdicionados: state.segmentosAdicionados.filter(
                    (item, index) => index !== action.index,
                ),
                segmentosData: {
                    ...state.segmentosData,
                    [`${action.name}`]: {
                        ...state.segmentosData[`${action.name}`],
                        value: [],
                    },
                },
            });
        case CHANGE_VALUE_SEGMENTO:
            return state.merge({
                ...state,
                segmentosData: {
                    ...state.segmentosData,
                    [`${action.name}`]: {
                        ...state.segmentosData[`${action.name}`],
                        value: action.value,
                    },
                },
            });
        case CHANGE_CACHE_SEGMENTO:
            return state.merge({
                ...state,
                segmentosData: {
                    ...state.segmentosData,
                    [`${action.name}`]: {
                        ...state.segmentosData[`${action.name}`],
                        cache: action.cache,
                    },
                },
            });
        case SELECT_VALUE_SEGMENTO:
            return state.merge({
                ...state,
                segmentosData: {
                    ...state.segmentosData,
                    [`${action.name}`]: {
                        ...state.segmentosData[`${action.name}`],
                        cache: [
                            ...state.segmentosData[`${action.name}`].cache,
                            action.item,
                        ],
                    },
                },
            });
        case CLEAR_ALL_SEGMENTOS:
            return state.merge({
                ...state,
                tiposSegmento: regraPrecoInitialState.tiposSegmento,
                segmentosData: regraPrecoInitialState.segmentosData,
                segmentosAdicionados:
                    regraPrecoInitialState.segmentosAdicionados,
                qtdProdutosAfetados: '',
            });
        case LIST_ITENS_SEGMENTOS:
            return state.merge({
                ...state,
                segmentosData: {
                    ...state.segmentosData,
                    [`${action.name}`]: {
                        ...state.segmentosData[`${action.name}`],

                        lista: action.itens
                            ?.map((item) => ({
                                label: item,
                                value: item,
                            }))
                            .concat(
                                state.segmentosData[
                                    `${action.name}`
                                ].cache.filter(
                                    (item) => action.itens?.findIndex(
                                            (itemLista) => itemLista === item.label,
                                        ) === -1,
                                ),
                            ),
                    },
                },
            });
        case LIST_ITENS_PRODUTO_CADASTRO_REGRA:
            return state.merge({
                ...state,
                segmentosData: {
                    ...state.segmentosData,
                    [`${action.name}`]: {
                        ...state.segmentosData[`${action.name}`],

                        lista: action.itens?.data
                            .map((item) => ({
                                label: `${item.product_id} - ${item.description}`,
                                value: `${item.product_id}-${item.description}`,
                                gtin: item.gtin,
                                product_id: item.product_id,
                            }))
                            .concat(
                                state.segmentosData[
                                    `${action.name}`
                                ].cache.filter(
                                    (item) => action.itens?.data.findIndex(
                                            (itemLista) => itemLista.product_id
                                                === item.product_id,
                                        ) === -1,
                                ),
                            ),
                    },
                },
            });
        case LIST_ITEMS_LOJA_CADASTRO_REGRA: {
            return state.merge({
                ...state,
                segmentosData: {
                    ...state.segmentosData,
                    [`${action.name}`]: {
                        ...state.segmentosData[`${action.name}`],
                        lista: action.itens
                            .map((item) => ({
                                label: item.store,
                                value: item.store_id,
                                gtin: item.gtin,
                                store_id: item.store_id,
                            }))
                            .concat(
                                state.segmentosData[
                                    `${action.name}`
                                ].cache.filter(
                                    (item) => action.itens?.findIndex(
                                            (itemLista) => itemLista.store_id
                                                === item.store_id,
                                        ) === -1,
                                ),
                            ),
                    },
                },
            });
        }
        case LIST_ITEMS_CLUSTER_CADASTRO_REGRA:
            return state.merge({
                ...state,
                segmentosData: {
                    ...state.segmentosData,
                    [`${action.name}`]: {
                        ...state.segmentosData[`${action.name}`],
                        lista: action.itens
                            .map((item) => ({
                                label: item.storeCluster,
                                value: item.storeCluster,
                            }))
                            .concat(
                                state.segmentosData[
                                    `${action.name}`
                                ].cache.filter(
                                    (item) => action.itens.findIndex(
                                            (itemLista) => itemLista.value === item.value,
                                        ) === -1,
                                ),
                            ),
                    },
                },
            });
        case LIST_ITENS_SEGMENTOS_CLASSES_REGRA_PRECO_CADASTRO:
            return state.merge({
                ...state,
                segmentosData: {
                    ...state.segmentosData,
                    [`${action.name}`]: {
                        ...state.segmentosData[`${action.name}`],
                        lista: action.itens?.map((item) => ({
                            label: item.abc_class || item.sensibility_type,
                            value: item.abc_class || item.sensibility_type,
                        })),
                    },
                },
            });
        case GET_SEGMENTOS_REGRA_PRECO:
            return state.merge({
                ...state,
                tiposSegmento: [
                    ...state.tiposSegmento,
                    {
                        title: 'SEGMENTAÇÃO',
                        itens: action.itens.map((item) => ({
                            name: item.field,
                            title: item.name,
                            eventKey: item.field,
                            nivel: item.level,
                        })),
                    },
                ],
                segmentos: state.segmentos.concat(
                    action.itens.map((item) => ({
                        name: item.field,
                        nivel: item.level,
                        cleanable: true,
                        filter: false,
                        title: item.name,
                        placeholder: 'Selecione um ou mais itens',
                        locale: {
                            noResultsText: 'Nenhum item encontrado',
                            searchPlaceholder:
                                'Digite ou busque o item desejado',
                        },
                        onSelect: 'handleSelect',
                        onSearch: 'searchItensSegmento',
                        onChange: 'handleChange',
                    })),
                ),
                segmentosData: {
                    ...action.segmentos,
                    ...state.segmentosData,
                },
            });
        case SET_QTD_PRODUTO_AFETADOS_REGRA_PRECO:
            return state.merge({
                ...state,
                qtdProdutosAfetados: action.qtdProdutosAfetados,
            });
        case SET_SEGMENTOS_UPDATE_REGRA:
            return state.merge({
                ...state,
                segmentosData: {
                    ...state.segmentosData,
                    [`${action.name}`]: {
                        ...state.segmentosData[`${action.name}`],
                        lista:
                            state.segmentosData[
                                `${action.name}`
                            ].lista.findIndex(
                                (item) => item.value === action.value,
                            ) === -1
                                ? [
                                      ...state.segmentosData[`${action.name}`]
                                          .lista,
                                      {
                                          label: action.value,
                                          value: action.value,
                                      },
                                  ]
                                : state.segmentosData[`${action.name}`].lista,
                    },
                },
            });
        case SET_LOJAS_UPDATE_REGRA:
            return state.merge({
                ...state,
                segmentosData: {
                    ...state.segmentosData,
                    [`${action.name}`]: {
                        ...state.segmentosData[`${action.name}`],
                        lista:
                            state.segmentosData[
                                `${action.name}`
                            ].lista.findIndex(
                                (item) => item.value === action.value,
                            ) === -1
                                ? [
                                      ...state.segmentosData.searchStoreIds
                                          .lista,
                                      {
                                          value: action.item,
                                          store_id: action.item,
                                      },
                                  ]
                                : state.segmentosData.searchStoreIds.lista,
                    },
                },
            });
        case SET_CLUSTERS_UPDATE_REGRA:
            return state.merge({
                ...state,
                segmentosData: {
                    ...state.segmentosData,
                    [`${action.name}`]: {
                        ...state.segmentosData[`${action.name}`],
                        lista:
                            state.segmentosData[
                                `${action.name}`
                            ].lista.findIndex(
                                (item) => item.value === action.value,
                            ) === -1
                                ? [
                                      ...state.segmentosData.searchCluster
                                          .lista,
                                      {
                                          value: action.item,
                                      },
                                  ]
                                : state.segmentosData.searchCluster.lista,
                    },
                },
            });
        case SET_SEGMENTOS_PRODUTOS_UPDATE_REGRA: {
            return state.merge({
                ...state,
                segmentosData: {
                    ...state.segmentosData,
                    productId: {
                        ...state.segmentosData.productId,
                        lista:
                            state.segmentosData.productId.lista.findIndex(
                                (item) => item.value === action.item.value,
                            ) === -1
                                ? [
                                      ...state.segmentosData.productId.lista,
                                      {
                                          label: `${action.item.value} - ${action.item.description}`,
                                          value: `${action.item.value}-${action.item.description}`,
                                          gtin: action.item.gtin,
                                          product_id: action.item.value,
                                      },
                                  ]
                                : state.segmentosData.productId.lista,
                        cache:
                            state.segmentosData.productId.cache.findIndex(
                                (item) => item.value === action.item.value,
                            ) === -1
                                ? [
                                      ...state.segmentosData.productId.cache,
                                      {
                                          label: `${action.item.value} - ${action.item.description}`,
                                          value: `${action.item.value}-${action.item.description}`,
                                          gtin: action.item.gtin,
                                          product_id: action.item.value,
                                      },
                                  ].filter(
                                      (v, i, a) => a.findIndex(
                                              (t) => t.product_id === v.product_id,
                                          ) === i,
                                  )
                                : state.segmentosData.productId.cache,
                    },
                },
            });
        }
        case SET_SEGMENTOS_CLASSES_UPDATE_REGRA:
            return state.merge({
                ...state,
                segmentosData: {
                    ...state.segmentosData,
                    abcClass: {
                        ...state.segmentosData.abcClass,
                        lista:
                            state.segmentosData.abcClass.lista.findIndex(
                                (item) => item.value === action.item,
                            ) === -1
                                ? [
                                      ...state.segmentosData.abcClass.lista,
                                      {
                                          label: action.item,
                                          value: action.item,
                                      },
                                  ]
                                : state.segmentosData.abcClass.lista,
                    },
                },
            });
        case SET_SEGMENTOS_SENSIBILITY_UPDATE_REGRA:
            return state.merge({
                ...state,
                segmentosData: {
                    ...state.segmentosData,
                    sensibilityType: {
                        ...state.segmentosData.sensibilityType,
                        lista:
                            state.segmentosData.sensibilityType.lista.findIndex(
                                (item) => item.value === action.item,
                            ) === -1
                                ? [
                                      ...state.segmentosData.sensibilityType
                                          .lista,
                                      {
                                          label: action.item,
                                          value: action.item,
                                      },
                                  ]
                                : state.segmentosData.sensibilityType.lista,
                    },
                },
            });
        default:
            return state;
    }
}

export default regraPrecoCadastroDataReducer;
