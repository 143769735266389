import classNames from 'classnames';
import React, { HtmlHTMLAttributes, forwardRef } from 'react';
import { NavLink } from 'react-router-dom';
import { Icon } from 'rsuite';
import styles from './SetupMenu.module.scss';

export type SetupMenuProps = HtmlHTMLAttributes<HTMLUListElement>;

export const SetupMenu = forwardRef<HTMLUListElement, SetupMenuProps>(
    ({ children, className, ...props }, ref) => (
        <nav
            className={[styles['setup-menu'], className].join(' ')}
            ref={ref}
            {...props}
        >
            <ul>{children}</ul>
        </nav>
    ),
);

export type SetupMenuItemProps = {
    to: string;
} & HtmlHTMLAttributes<HTMLLIElement>;

export const SetupMenuItem = forwardRef<HTMLLIElement, SetupMenuItemProps>(
    ({ to, className, children, ...props }, ref) => (
        <li
            className={classNames(styles['setup-menu__item'], className)}
            ref={ref}
            {...props}
        >
            <NavLink to={to}>
                {children}
                <Icon icon="arrow-right-line" size="lg" />
            </NavLink>
        </li>
    ),
);
