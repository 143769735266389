import React, { useEffect, useState } from 'react';
import { FlexboxGrid } from 'rsuite';

import DropdownDownloadOptions from '../../../../../components/DropdownDownloadOptions';
import ModalConfirm from '../../../../../components/ModalConfirm';
import { getChainsCompetitors, getDownloadCTopTenRedes } from '../services';
import CompetitorsTable from './CompetitorsTable';
import TopTenHeader from './TopTenHeader';

import { topTenChainCompetitorsTableColumn } from '../utils';

const TopTenChainCompetitors = ({ referenceStore }) => {
    const [showModal, setShowModal] = useState(false);
    const [topTenRedesData, setTopTenRedesData] = useState([]);

    const getTopTenRedesTable = async (referenceStore) => {
        const response = await getChainsCompetitors(referenceStore);
        if (response) {
            const sortChains = response.sort((a, b) => b.competitor_stores - a.competitor_stores);
            setTopTenRedesData(sortChains);
        }
    };

    const handleExport = async (type) => {
        getDownloadCTopTenRedes(referenceStore, type);
    };

    const handleViewFullScreen = () => {
        const element = document.getElementById('top-ten-chain-view');
        element.requestFullscreen();
    };

    useEffect(() => {
        if (referenceStore?.cnpj) {
            getTopTenRedesTable(referenceStore);
        }
    }, [referenceStore]);

    return (
        <div className="top-ten-competitors-content top-ten-chain" id="top-ten-chain-view">
            <FlexboxGrid justify="space-between">
                <TopTenHeader
                    subHeader
                    title="TOP 10 - REDES CONCORRENTES NA UF"
                    subtitle="Redes com o maior número de lojas concorrentes da Rede Referência."
                    rede={referenceStore?.rede || null}
                    uf={referenceStore?.uf || null}
                    onClick={() => setShowModal(true)}
                />
                <FlexboxGrid.Item>
                    <DropdownDownloadOptions
                        showExportOptions
                        idName="top-ten-chain-competitors-dropdown"
                        hidePrintOption
                        onClickFullScreen={handleViewFullScreen}
                        onClickExport={handleExport}
                        disableExport={!topTenRedesData?.length}
                    />
                </FlexboxGrid.Item>
            </FlexboxGrid>
            <CompetitorsTable
                dataColumn={topTenChainCompetitorsTableColumn}
                data={topTenRedesData}
            />
            <ModalConfirm
                title="Entendendo como calculamos o Top 10 - Redes Concorrentes"
                confirmButton="FECHAR"
                confirmButtonWidth="140px"
                footerAlign="end"
                show={showModal}
                closeButton={false}
                onConfirm={() => setShowModal(false)}
            >
                <p>
                    Para gerar essa análise, por padrão, são consideradas os concorrentes de todas as lojas da rede,
                    seguindo o modelo da &quot;análise de lojas concorrentes&quot;,
                    que leva em conta distância, compatibilidade entre os tipos de loja, e correspondência no sortimento.
                </p>
                <p>
                    As redes que mais apareceram são listadas como as principais concorrentes.
                </p>

            </ModalConfirm>
        </div>
    );
};

export default TopTenChainCompetitors;
