import { useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import * as PainelGeralService from '../../InfoPanel/services';
import * as utils from '../utils';

type filterRequestObj = {
    key: string,
    datapoints: number,
}

type selectObj = {
    label: string,
    value: string,
    datapoints?: number,
}

export const useLocalSelectRequestList = () => {
    const [timer, setTimer] = useState(null);

    const [location, setLocation] = useState({
        cidade: {
            list: [],
            cache: [],
            value: [],
        },
        uf: {
            list: [],
            cache: [],
            value: [],
        },
    });

    const { filtrosDataCompetitividade } = useSelector(
        (state) => ({
            filtrosDataCompetitividade:
                state.analiseCompetitividadeDataReducer
                    .filtrosDataCompetitividade,
        }),
        shallowEqual,
    );

    const setList = (name: string, list: filterRequestObj[]) => {
        const newList = list.map((item) => ({
            label: item.key,
            value: item.key,
            datapoints: item.datapoints,
        }));

        const cacheList = location[name].cache;
        const removeDuplicate = newList.filter(
            (i) => !location[name].value.includes(i.value),
        );
        const concatList = location[name].value.length
            ? cacheList.concat(removeDuplicate)
            : newList;

        setLocation((prevState) => ({
            ...prevState,
            [name]: {
                ...prevState[name],
                list: concatList,
            },
        }));
    };

    const getLocalSelectList = (name: string, params) => {
        PainelGeralService.getItensFiltros(params).then((data) => {
            if (data) {
                setList(name, data);
            }
        });
    };

    const handleSearchLocation = (inputValue, name) => {
        clearTimeout(timer);

        const newTimer = setTimeout(() => {
            getLocalSelectList(
                name,
                utils.getModel(
                    filtrosDataCompetitividade,
                    inputValue,
                    name,
                ),
            );
        }, 500);

        setTimer(newTimer);
    };

    const handleAddLocation = (name: string, item: selectObj) => {
        const valueList = location[name].value;
        const newValue = valueList.concat(item.value);

        setLocation((prevState) => ({
            ...prevState,
            [name]: {
                ...prevState[name],
                value: newValue,
                cache: [...prevState[name].cache, item],
            },
        }));
    };

    const handleRemoveLocation = (name: string, item: selectObj) => {
        const removedCache = location[name].cache.filter(
            (i) => i.value !== item.value,
        );
        const newValue = location[name].value.filter(
            (i) => i !== item.value,
        );

        setLocation((prevState) => ({
            ...prevState,
            [name]: {
                ...prevState[name],
                value: newValue,
                cache: removedCache,
            },
        }));
    };

    const handleCleanLocation = () => {
        setLocation({
            cidade: {
                cache: [],
                value: [],
            },
            uf: {
                cache: [],
                value: [],
            },
        });
    };

    return {
        stateLocation: {
            location,
        },
        handlersLocation: {
            setLocation,
            getLocalSelectList,
            handleAddLocation,
            handleRemoveLocation,
            handleCleanLocation,
            handleSearchLocation,
        },
    };
};
