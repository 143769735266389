import React, { forwardRef } from 'react';

type LabelProps = React.LabelHTMLAttributes<HTMLLabelElement>;

export const Label = forwardRef<HTMLLabelElement, LabelProps>(
    ({ className = '', children, ...props }, ref) => (
        <label
            ref={ref}
            className={['info-label', 'font-size-100-regular', className].join(
                ' ',
            )}
            {...props}
        >
            {children}
        </label>
    ),
);
