import LogRocket from 'logrocket';
import packageJson from '../../package.json';

export const startLogRocket = () => {
    const isProduction = window.location.origin === 'https://app.infoprice.co';
    const isInfoPricer = JSON.parse(localStorage.getItem('usuario'))?.email?.includes('infoprice');

    if (isProduction && !isInfoPricer) {
        LogRocket.init('yrcc0j/portal-cliente-infoprice', {
            release: `${packageJson.version}`,
        });
        LogRocket.identify(JSON.parse(localStorage.getItem('usuario'))?.email, {
            email: JSON.parse(localStorage.getItem('usuario'))?.email,
        });

        if (window?.heap) {
            LogRocket.getSessionURL((sessionURL) => {
                window.heap.track('LogRocket', { sessionURL });
            });
        }
    }
};
