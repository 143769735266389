import iconAlertCircle from '../../../../assets/icons/icon_alert_circle.svg';
import iconProcessCircle from '../../../../assets/icons/icon_down-arrow-doted-circle.svg';
import mailClockIcon from '../../../../assets/icons/icon_email_clock.svg';
import iconErrorCircle from '../../../../assets/icons/icon_error_circle.svg';

export const uploadRequestStatus = {
    processing: {
        className: 'upload-result success',
        icon: iconProcessCircle,
        message: 'Precisamos de mais um tempo para processar a solicitação',
        subMessage:
            'Aguarde o e-mail que enviaremos quando as solicitações estiverem disponíveis para consulta na tabela de On Demand.',
    },
    error: {
        className: 'upload-result fail',
        icon: iconErrorCircle,
        message: 'Oops! Ocorreu um erro!',
        subMessage: 'Tente novamente mais tarde',
    },
    manyItems: {
        className: 'upload-result to-many-itens',
        icon: iconAlertCircle,
        message: 'A quantidade de solicitações enviadas excedeu a disponível.',
        subMessage:
            'Realize mudanças no arquivo ou peça solicitações adicionais ou inative algumas solicitações em Detalhamento das Solicitações e re-envie a solicitação.',
    },
    invalidFile: {
        className: 'upload-result fail',
        icon: iconErrorCircle,
        message: 'Oops! Formato do arquivo inválido',
    },
    uploading: {
        className: 'upload-result process',
        icon: '',
        message: '',
        subMessage:
            'O arquivo está sendo validado e o tempo de espera dependerá da quantidade de solicitações enviadas. ',
    },
    invalidHeader: {
        className: 'upload-result fail',
        icon: iconErrorCircle,
        message: 'Oops! Header inválido',
    },
    emptyFile: {
        className: 'upload-result fail',
        icon: iconErrorCircle,
        message: 'Oops! Necessário cabeçalho em ao menos uma linha',
    },
    invalidExtension: {
        className: 'upload-result fail',
        icon: iconErrorCircle,
        message:
            'Oops! Extensao do arquivo invalida. O arquivo deve ser enviado no formato xlsx.',
    },
    invalidCharacter: {
        className: 'upload-result fail',
        icon: iconErrorCircle,
        message: 'Oops! Há uma vírgula incorreta entre as informações',
    },
};

export const changeRequestStatus = {
    statusTrue: {
        title: 'Ativar essa solicitação?',
        message:
            'Alterando o status dessa solicitação para ativa, a captura de dados acontecerá até que a mesma seja inativada novamente.',
        confirmButton: 'ATIVAR',
        cancelButton: 'CANCELAR',
        icon: null,
        cancelBtnWidth: '120px',
        confirmBtnWidth: '120px',
        modalWidth: '500px',
    },
    statusFalse: {
        title: 'Inativar essa solicitação?',
        message:
            'Alterando o status dessa solicitação para inativa, a captura de dados não acontecerá até que a mesma seja ativada novamente.',
        confirmButton: 'INATIVAR',
        cancelButton: 'CANCELAR',
        icon: null,
        cancelBtnWidth: '120px',
        confirmBtnWidth: '120px',
        modalWidth: '500px',
    },
    notAvailable: {
        title: 'Você não possui solicitações disponíveis',
        message:
            'Peça solicitações adicionais ou inative algumas solicitações em Detalhamento das Solicitações.',
        confirmButton: 'ENTRAR EM CONTATO',
        cancelButton: 'FECHAR',
        icon: iconAlertCircle,
        cancelBtnWidth: '120px',
        confirmBtnWidth: null,
        modalWidth: '500px',
    },
    noEdit: {
        title: 'Você está desativando solicitações antes de 7 dias',
        message:
            'Tem certeza que deseja desativar essas solicitações? Edições de solicitações só podem ser feitas semanalmente. Caso inativadas agora, só serão liberadas novamente após o período de 7 dias.',
        confirmButton: 'DESATIVAR SOLICITAÇÕES',
        cancelButton: 'CANCELAR',
        icon: iconAlertCircle,
        cancelBtnWidth: '120px',
        confirmBtnWidth: null,
        modalWidth: '570px',
    },
};

export const activeFilter = [
    {
        label: 'Ativo',
        value: true,
    },
    {
        label: 'Inativo',
        value: false,
    },
];

export const validityFilter = [
    {
        label: 'Determinado',
        value: 'Determinado',
    },
    {
        label: 'Indeterminado',
        value: 'Indeterminado',
    },
];

export const filtersObj = {
    barCode: {
        value: [],
        data: [],
        cache: [],
    },
    creator: {
        value: [],
        data: [],
        cache: [],
    },
    uf: {
        value: [],
        data: [],
        cache: [],
    },
    cities: {
        value: [],
        data: [],
        cache: [],
    },
    radius: {
        value: [],
        data: [],
        cache: [],
    },
    date: {
        value: [],
        data: [],
        cache: [],
    },
    active: {
        value: null,
        cache: [],
    },
    keyword: {
        value: [],
        data: [],
        cache: [],
    },
    requestId: {
        value: [],
        data: [],
        cache: [],
    },
};

export const filtersNameConversion = {
    barCode: 'GTIN',
    creator: 'USUARIO',
    uf: 'UF',
    cities: 'CIDADE',
    radius: 'RAIO',
    date: 'DATA',
    active: 'STATUS',
    keyword: 'PALAVRA_CHAVE',
    requestId: 'ID',
};

export const requestNameConversion = {
    barCode: 'gtin',
    creator: 'criador',
    uf: 'uf',
    cities: 'cidade',
    radius: 'raio',
    date: 'dataCriacao',
    status: 'status',
    keyword: 'palavraChave',
    requestId: 'id',
};

export const sortNameConversion = {
    raio: 'RAIO',
    dataCriacao: 'DATA',
    totalPrecosRetornados: 'TOTAL_PRECOS_RETORNADOS',
    precosUltimaSemana: 'PRECOS_ULTIMA_SEMANA',
};

export const formObj = {
    id: 1,
    uf: '',
    cidade: '',
    cep: '',
    endereco: '',
    numero: '',
    raio: '',
    codeOrText: [],
};

export const sendRequestBody = {
    idSolicitacao: '',
    dataCriacao: '',
    uf: '',
    cidade: '',
    logradouro: '',
    criador: '',
    numeroLogradouro: 0,
    cep: '',
    raio: 0,
    palavraChave: '',
    gtin: '',
    codeOrText: [],
    latitude: 0,
    longitude: 0,
    status: false,
    totalPrecosRetornados: 0,
    precosUltimaSemana: 0,
    fullAddress: '',
};

export const changeSystemRequestStatus = {
    sendRequest: {
        title: 'Enviar solicitações?',
        message:
            'Você pode revisar as informações de solicitação, se não for necessário, basta salvar e finalizar o envio',
        confirmButton: 'SALVAR',
        cancelButton: 'REVISAR',
        icon: mailClockIcon,
        cancelBtnWidth: '82px',
        confirmBtnWidth: '82px',
        onConfirm: 'sendRequest',
    },
    removeAddress: {
        title: 'Remover endereço?',
        message:
            'Tem certeza que deseja remover este endereço da solicitação? Você não poderá reverter esta ação.',
        confirmButton: 'REMOVER',
        cancelButton: 'CANCELAR',
        icon: iconAlertCircle,
        cancelBtnWidth: '98px',
        confirmBtnWidth: '98px',
        onConfirm: 'removeAddress',
    },
    limitExceeded: {
        title: 'Seu pedido excedeu o limite disponível.',
        message:
            'Você pode remover um ou mais produtos da seleção, para solicitação, ou você pode contratar mais solicitações On Demand',
        confirmButton: 'REVISAR SOLICITAÇÕES',
        cancelButton: null,
        icon: iconAlertCircle,
        cancelBtnWidth: null,
        confirmBtnWidth: '184px',
        onConfirm: 'limitExceeded',
    },
};

export const UFDataMock = [
    {
        label: 'Grande SP',
        value: 'Grande SP',
    },
    {
        label: 'SP Interior',
        value: 'SP Interior',
    },
    {
        label: 'MG',
        value: 'MG',
    },
    {
        label: 'RJ',
        value: 'RJ',
    },
    {
        label: 'RS',
        value: 'RS',
    },
    {
        label: 'PR',
        value: 'PR',
    },
    {
        label: 'BA',
        value: 'BA',
    },
    {
        label: 'SC',
        value: 'SC',
    },
    {
        label: 'PE',
        value: 'PE',
    },
    {
        label: 'AL',
        value: 'AL',
    },
    {
        label: 'CE',
        value: 'CE',
    },
    {
        label: 'DF',
        value: 'DF',
    },
    {
        label: 'ES',
        value: 'ES',
    },
    {
        label: 'PA',
        value: 'PA',
    },
    {
        label: 'GO',
        value: 'GO',
    },
    {
        label: 'MA',
        value: 'MA',
    },
    {
        label: 'AM',
        value: 'AM',
    },
    {
        label: 'PB',
        value: 'PB',
    },
    {
        label: 'RN',
        value: 'RN',
    },
    {
        label: 'MT',
        value: 'MT',
    },
    {
        label: 'MS',
        value: 'MS',
    },
    {
        label: 'SE',
        value: 'SE',
    },
    {
        label: 'PI',
        value: 'PI',
    },
    {
        label: 'RO',
        value: 'RO',
    },
    {
        label: 'TO',
        value: 'TO',
    },
    {
        label: 'AP',
        value: 'AP',
    },
    {
        label: 'AC',
        value: 'AC',
    },
    {
        label: 'RR',
        value: 'RR',
    },
];
