import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Alert, Button, Icon, Modal, Progress, Uploader } from 'rsuite';
import { setOnDemandState } from '../../../../../reducers/reducerOnDemand';
import useRequestPending from '../Hooks/useRequestPending';
import { uploadRequestStatus } from '../data';
import {
    getBulkDuplicateFile,
    getBulkErrorFile,
    getModelo,
    setData,
} from '../services';

import { downloadFile } from '../utils';

const { Circle } = Progress;

const errorUpload = (file, name) => {
    downloadFile(file, `erro_${name}`);
};

const displayStatus = {
    ACCEPTED: uploadRequestStatus.processing,
    NOT_FOUND: uploadRequestStatus.error,
    BAD_REQUEST: uploadRequestStatus.error,
    QUANTIDADE_EXCEDIDA: uploadRequestStatus.manyItems,
    UPLOADING: uploadRequestStatus.uploading,
    HEADER_INVALIDO: uploadRequestStatus.invalidHeader,
    ARQUIVO_VAZIO: uploadRequestStatus.emptyFile,
    EXTENSAO_INVALIDA: uploadRequestStatus.invalidExtension,
    CARACTER_INCORRETO: uploadRequestStatus.invalidCharacter,
};

const ModalUpload = ({ client, onCancel, onSuccess, show }) => {
    const [fileList, setFileList] = useState([]);
    const [file, setFile] = useState(null);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [uploadStatus, setUploadStatus] = useState('');
    const [duplicateFile, setDuplicateFile] = useState(null);
    const controller = new AbortController();

    const { pendingData, refetch } = useRequestPending();

    const reduceDispatch = useDispatch();

    const uploadFile = (file) => {
        setFile(file[0]);
        setUploadStatus('UPLOADING');
        const formData = new FormData();
        formData.append('file', file[0].blobFile);

        setData(client, formData, setUploadProgress, controller)
            .then((response) => {
                setUploadStatus(response?.statusCode);
                onSuccess();
                if (!response?.success && response?.error_file) {
                    errorUpload(response?.error_file);
                }
                if (response.success && response?.duplicate_file) {
                    setDuplicateFile(response.duplicate_file);
                }
                setFileList([]);
                refetch();
                reduceDispatch(
                    setOnDemandState({
                        name: 'hasPendingRequest',
                        data: true,
                    }),
                );
            })
            .catch((error) => {
                onSuccess();
                setUploadStatus(error.response.data[0].mensagem);
                if (
                    !error.response.data.success &&
                    !error.response.data.hasDuplicates
                ) {
                    getBulkErrorFile(client, formData, controller).then(
                        (response) => {
                            errorUpload(response, file[0].name);
                        },
                    );
                }
                if (error.response.data.hasDuplicates) {
                    getBulkDuplicateFile(client, formData, controller).then(
                        (response) => {
                            errorUpload(response, file[0].name);
                            setDuplicateFile(response);
                        },
                    );
                }
            });

        setUploadProgress(0);
    };

    const downloadModel = () => {
        getModelo().then((response) => {
            if (response.status === 200) {
                const content = response.data;
                const fileName = response.headers.filename;

                downloadFile(content, fileName);

                Alert.success('Modelo baixado com sucesso');
            }
        });
    };

    const cancelUpload = () => {
        controller.abort();
    };

    const downloadDuplicadas = () => {
        const fileName = 'duplicadas.xlsx';
        downloadFile(duplicateFile, fileName);
    };

    const handleOnCancel = () => {
        setFile(null);
        setUploadStatus('');
        onCancel();
    };

    const renderStatusContent = (status) =>
        displayStatus[status] ? (
            <div className={displayStatus[status].className}>
                {status === 'UPLOADING' ? (
                    <Circle
                        percent={uploadProgress}
                        strokeColor="#3385ff"
                        status={null}
                    />
                ) : (
                    <img src={displayStatus[status].icon} alt="" />
                )}
                <div>
                    {displayStatus[status].message && (
                        <p className="message">
                            {displayStatus[status].message}
                        </p>
                    )}
                    <p className="sub-message">
                        {displayStatus[status].subMessage}
                    </p>
                    {status === 'UPLOADING' && (
                        <>
                            <b>Por favor, aguarde...</b>
                            <button
                                id="btn-cancel"
                                className="btn-cancel"
                                type="button"
                                onClick={() => cancelUpload()}
                            >
                                Cancelar envio
                            </button>
                        </>
                    )}
                </div>
            </div>
        ) : null;

    return (
        <Modal
            overflow={false}
            backdrop="static"
            show={show}
            className="on-demand-modal-upload"
            onHide={onCancel}
        >
            <Modal.Header>
                <Modal.Title>Dados da Solicitação</Modal.Title>
                <p>
                    Realize o upload do arquivo com as informações necessárias
                    da solicitação.
                </p>
            </Modal.Header>
            <Modal.Body>
                <div className="content">
                    {!file ? (
                        <>
                            <Uploader
                                multiple={false}
                                autoUpload={false}
                                fileList={fileList}
                                onChange={(file) => uploadFile(file)}
                                fileListVisible={false}
                            >
                                <Button
                                    id="btn-upload"
                                    appearance="primary"
                                    className="btn-upload"
                                >
                                    Enviar arquivo de solicitação
                                </Button>
                            </Uploader>
                            <div className="modelo-area">
                                <p>
                                    A planilha deve obrigatoriamente ter os
                                    campos de UF, Cidade, Raio (1, 3 ou 10) e,
                                    palavra chave <b>OU</b> código de barras
                                    preenchidos. Não use vírgulas no
                                    preenchimento.
                                </p>
                                <button
                                    type="button"
                                    id="btn-modelo"
                                    onClick={() => downloadModel()}
                                >
                                    Baixar modelo
                                    <Icon icon="external-link" />
                                </button>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="file-name-area">
                                <div className="icon">
                                    <Icon icon="paperclip" />
                                </div>
                                <div className="file-name">
                                    <p>{file.name}</p>
                                </div>
                                <div className="file-size">
                                    <p>
                                        {(file.blobFile.size * 0.001).toFixed(
                                            2,
                                        )}
                                        KB
                                    </p>
                                </div>
                            </div>
                            {renderStatusContent(uploadStatus)}
                        </>
                    )}
                </div>
                {uploadStatus === 'ACCEPTED' && duplicateFile && (
                    <div className="warning-duplicate-item">
                        <span className="warning-duplicate-item__icon-area">
                            <Icon icon="exclamation-triangle" />
                        </span>
                        <span className="warning-duplicate-item__description-area">
                            <p>
                                Uma ou mais solicitações enviadas já estavam
                                ativas e não foram consideradas
                            </p>
                            <button
                                type="button"
                                id="btn-duplicate"
                                onClick={() => downloadDuplicadas()}
                            >
                                Baixar arquivo com duplicadas
                                <Icon icon="external-link" />
                            </button>
                        </span>
                    </div>
                )}
            </Modal.Body>
            <Modal.Footer>
                {uploadStatus === 'BAD_REQUEST' ||
                uploadStatus === 'FORBIDDEN' ? (
                    <Uploader
                        multiple={false}
                        autoUpload={false}
                        fileList={fileList}
                        onChange={(file) => uploadFile(file)}
                        fileListVisible={false}
                    >
                        <Button id="btn-retry" appearance="ghost">
                            REENVIAR SOLICITAÇÃO
                        </Button>
                    </Uploader>
                ) : (
                    <Button onClick={handleOnCancel} appearance="ghost">
                        Fechar
                    </Button>
                )}
            </Modal.Footer>
        </Modal>
    );
};
export default ModalUpload;
