import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Alert } from 'rsuite';
import {
    getSetupIPAConfiguracoesBasicas,
    postSetupIPAConfiguracoesBasicas,
    upadateSetupIPAConfiguracoesBasicas,
} from '../services';

export const useSetupIPAConfig = () => {
    const queryClient = useQueryClient();

    const { data, error, isLoading } = useQuery({
        queryKey: ['ipa/setup-form/competitor-days'],
        queryFn: getSetupIPAConfiguracoesBasicas,
    });

    const { mutateAsync: createConfig } = useMutation({
        mutationFn: postSetupIPAConfiguracoesBasicas,
        onSuccess: () => {
            Alert.success('Configurações salvas com sucesso');
            queryClient.invalidateQueries(['ipa/setup-form/competitor-days']);
        },
        onError: () => {
            Alert.error('Erro ao salvar as configurações');
        },
    });

    const { mutateAsync: updateConfig } = useMutation({
        mutationFn: upadateSetupIPAConfiguracoesBasicas,
        onSuccess: () => {
            Alert.success('Configurações salvas com sucesso');
            queryClient.invalidateQueries(['ipa/setup-form/competitor-days']);
        },
        onError: () => {
            Alert.error('Erro ao salvar as configurações');
        },
    });

    return {
        data,
        error,
        isLoading,
        createConfig,
        updateConfig,
    };
};
