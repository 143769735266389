import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TableProps } from 'rsuite/lib/Table';
import { RootState } from '../../@types/RootState';

export type PrecoAtacadoSortState<K extends TableProps['sortColumn'] = string> =
    {
        type: K;
        orderBy: TableProps['sortType'];
    };

const initialState: PrecoAtacadoSortState = {
    type: 'productIds',
    orderBy: 'asc',
};

const slice = createSlice({
    name: 'ipa/precoAtacado/sort',
    initialState,
    reducers: {
        SET_PRECO_ATACADO_SORT: (
            _state,
            { payload }: PayloadAction<PrecoAtacadoSortState>,
        ) => payload,
    },
});

export const { SET_PRECO_ATACADO_SORT } = slice.actions;

export const selectorPrecoAtacadoSort = (state: RootState) => {
    return state.precoAtacadoReducer.sort;
};

export default slice.reducer;
