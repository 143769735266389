import React from 'react';
import { format, parseISO } from 'date-fns';
import ModalConfirm from '../../../../../../components/ModalConfirm';
import infoIcon from '../../../../../../assets/icons/icon_info.svg';

const ModalProgramarCampanha = ({ campanha, onCancel, onConfirm, show }) => (
    <ModalConfirm
        show={show}
        className="modal-offer-campaign"
        title="Programar Campanha"
        confirmButton="CONFIRMAR"
        cancelButton="CANCELAR"
        footerAlign="end"
        textAlign="start"
        titleFontSize="20px"
        modalWidth="432px"
        onCancel={onCancel}
        onConfirm={onConfirm}
        onHide={onCancel}
    >
        {campanha.name && (
            <div className="campaign-message">
                A campanha <span>{campanha?.name}</span> entrará em circulação
                no dia {format(parseISO(campanha?.starting_date), 'dd/MM/yyyy')}{' '}
                e ficará vigente até o dia{' '}
                {format(parseISO(campanha?.expiration_date), 'dd/MM/yyyy')}.
            </div>
        )}
        <div className="campaign-confirm-question">
            Você quer confirmar a programação?
        </div>
        <div className="campaign-price-info">
            <div>
                <img src={infoIcon} alt="" />
            </div>
            <div>
                Durante a <span>vigência da campanha</span>, não haverá geração
                de preços regulares para os produtos e lojas participantes, seja
                através de regras ou precificação manual.
            </div>
        </div>
    </ModalConfirm>
);

export default ModalProgramarCampanha;
