import React from 'react';
import {
    Whisper, ButtonToolbar, IconButton, Icon,
} from 'rsuite';
import DropDownFiltros from './DropDownFiltros';

const ButtonAddFiltro = ({ usuario }) => (
    <Whisper
        trigger="click"
        placement="top"
        speaker={<DropDownFiltros usuario={usuario} className="popover-filtro-dinamico" />}
    >
        <ButtonToolbar>
            <IconButton id="add-filter" icon={<Icon icon="plus" />}>Adicionar filtros</IconButton>
        </ButtonToolbar>
    </Whisper>
);

export default ButtonAddFiltro;
