import React from 'react';
import { usePromiseTracker } from 'react-promise-tracker';
import { Loader } from 'rsuite';
import { LoaderProps } from 'rsuite/lib/Loader';

type LOADER_SIZE = 'xs' | 'sm' | 'md' | 'lg';

type LoadingSpinerProps = { size?: LOADER_SIZE };

export const LoadingSpiner = ({ size }: LoadingSpinerProps) => {
    const { promiseInProgress } = usePromiseTracker();

    return (
        <div>
            {promiseInProgress === true ? (
                <Loader className="global-loader" size={size} />
            ) : null}
        </div>
    );
};

type LoadingSpinerWithMessageProps = {
    area?: string;
    message?: string;
    size?: LOADER_SIZE;
};

export const LoadingSpinerWithMessage = ({
    area,
    message,
    size,
}: LoadingSpinerWithMessageProps) => {
    const { promiseInProgress } = usePromiseTracker({ area });

    return (
        <div>
            {promiseInProgress === true ? (
                <>
                    <div className="wrapper-message-loader">
                        <p className="message-loader">{message}</p>
                    </div>
                    <Loader className="global-loader" size={size} />
                </>
            ) : null}
        </div>
    );
};

type LoadingSpinerAreaProps = React.HtmlHTMLAttributes<HTMLElement> &
    LoaderProps & {
        area?: string;
        height?: string;
        isLoading?: boolean;
    };

export const LoadingSpinerArea = ({
    area,
    height = '100%',
    style,
    isLoading = false,
    ...props
}: LoadingSpinerAreaProps) => {
    const { promiseInProgress } = usePromiseTracker({ area });

    return (
        [isLoading, promiseInProgress].includes(true) && (
            <div>
                <Loader
                    className="global-loader"
                    style={{ height, ...style }}
                    {...props}
                />
            </div>
        )
    );
};

type LoadingSpinerComponentProps = {
    area?: string;
    size?: LOADER_SIZE;
    styleClass?: string;
};

export const LoadingSpinerComponent = ({
    area,
    size,
    styleClass,
}: LoadingSpinerComponentProps) => {
    const { promiseInProgress } = usePromiseTracker({ area });

    return (
        <div>
            {promiseInProgress === true ? (
                <Loader className={styleClass} size={size} />
            ) : null}
        </div>
    );
};

export const InProgress = () => {
    const { promiseInProgress } = usePromiseTracker();

    if (promiseInProgress === true) {
        return true;
    }
    return false;
};

type LoadingMenuProps = {
    area?: string;
};

export const LoadingMenu = ({ area }: LoadingMenuProps) => {
    const { promiseInProgress } = usePromiseTracker({ area });

    return (
        <div>
            {promiseInProgress === true ? (
                <div style={{ padding: 4, color: '#999', textAlign: 'center' }}>
                    <Loader size="xs" />
                </div>
            ) : null}
        </div>
    );
};

type LoadingSpinerLocalProps = {
    loading?: boolean;
    size?: LOADER_SIZE;
    height?: React.CSSProperties['height'];
    position?: React.CSSProperties['position'];
};

export const LoadingSpinerLocal = ({
    loading = false,
    size = undefined,
    height = '100%',
    position,
}: LoadingSpinerLocalProps) => (
    <div>
        {loading === true ? (
            <Loader
                className="area-loader"
                style={{ height, position }}
                size={size}
            />
        ) : null}
    </div>
);
