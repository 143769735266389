import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
    setItemPreferenceFilter,
    setPreferenceFilter,
    savePreferences,
} from '../../../../../actions/actionsAnaliseCompetitividade';
import { getCompetitivePreferences } from '../services';

export const usePreferenceFilter = () => {
    const { preferences } = useSelector(
        (state) => ({
            preferences:
                    state.analiseCompetitividadeDataReducer.preferences,
        }),
        shallowEqual,
    );

    const dispatch = useDispatch();

    const radio = (value, type) => {
        dispatch(setItemPreferenceFilter(type, value));
    };

    const app = (value) => {
        if (!value && !preferences.canalFisico) {
            dispatch(setItemPreferenceFilter('canalFisico', true));
            dispatch(setItemPreferenceFilter('canalDigital', value));
        } else {
            dispatch(setItemPreferenceFilter('canalDigital', value));
        }
    };

    const pontoVenda = (value) => {
        if (!value && !preferences.canalDigital) {
            dispatch(setItemPreferenceFilter('canalFisico', value));
            dispatch(setItemPreferenceFilter('canalDigital', true));
        } else {
            dispatch(setItemPreferenceFilter('canalFisico', value));
        }
    };

    const onCancel = () => {
        dispatch(setPreferenceFilter(preferences));
    };

    const save = () => {
        dispatch(savePreferences(preferences));
    };

    const getPreferences = async () => {
        const response = await getCompetitivePreferences();
        const preference = {
            canalDigital: response.canalDigital,
            id: response.id,
            login: response.login,
            canalFisico: response.canalFisico,
            tipoPreco: response.tipoPreco,
        };

        dispatch(setPreferenceFilter(preference));
    };

    return {
        handlersPreference: {
            radio,
            app,
            pontoVenda,
            onCancel,
            save,
            getPreferences,
        },
    };
};
