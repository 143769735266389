import classNames from 'classnames';
import React from 'react';
import CurrencyFormat from 'react-currency-format';
import styles from './VariationBoxIndicator.module.scss';

export interface IVariationBoxIndicator {
    value: number | null;
    className?: string;
    suffix?: string;
    prefix?: string;
}

export const VariationBoxIndicator = ({
    value,
    className,
    prefix,
    suffix = '',
}: IVariationBoxIndicator) => {
    if (Number.isNaN(value) || value === null) return <>--</>;

    if (value === 0) {
        return (
            <div className={classNames(styles.box, className)}>SEM VAR.</div>
        );
    }

    const isValuePositive = value > 0;

    return (
        <CurrencyFormat
            className={classNames(
                styles.box,
                isValuePositive
                    ? styles['box-positive']
                    : styles['box-negative'],
                className,
            )}
            value={value}
            displayType="text"
            decimalScale={1}
            prefix={prefix || isValuePositive ? '+ ' : '- '}
            suffix={suffix}
            fixedDecimalScale
            allowNegative={false}
        />
    );
};
