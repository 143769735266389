import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { TableProps } from 'rsuite/lib/Table';
import { TablePaginationProps } from 'rsuite/lib/Table/TablePagination';
import { checkIfIsLoading, getFiltrosModel } from '../../../../../utils';
import History from '../../../../../utils/History';
import { usePagination, useSort } from '../../hooks';
import { GETNegociacoesSalvas } from './NegociacoesSalvas.services';
import { TableNegociacoesSalvasProps } from './components/TableNegociacoesSalvas';

export type UseNegociacoesSalvasProps = {
    table: TableNegociacoesSalvasProps & {
        pagination: TablePaginationProps;
    };
    onNewNegotiation: () => Promise<void>;
};

const DEFAULT_SORT = {
    type: 'name',
    orderBy: 'asc',
} as const;

export const useNegociacoesSalvas = (): UseNegociacoesSalvasProps => {
    const [sort, { onSort: setSort }] = useSort(DEFAULT_SORT);

    const [totalElements, setTotalElements] = useState(0);

    const pagination = usePagination({
        total: totalElements,
    });

    const model = getFiltrosModel({
        sort,
        pageSize: pagination.pageSize,
        pageNumber: pagination.requestPage,
    });

    const { data, fetchStatus } = useQuery({
        initialData: {
            content: [],
            totalElements: 0,
        },
        queryKey: ['negociacoes/salvas/get-negociacoes', sort, pagination],
        queryFn: () => GETNegociacoesSalvas(model),
        onSuccess: (data) => setTotalElements(data.totalElements),
        retry: false,
    });

    const handleClickNewNegotiation = async () => {
        History.push('/ipa/negociacoes/fornecedor');
    };

    const handleSortColumn = (
        sortColumn: TableProps['sortColumn'],
        sortType: TableProps['sortType'],
    ) => {
        setSort(sortColumn, sortType);
    };

    const handleEditNegotiation = (id: string) => {
        History.push(`/ipa/negociacoes/salvas/${id}`);
    };

    return {
        table: {
            data: data.content,
            rowKey: 'name',
            loading: checkIfIsLoading(fetchStatus),
            sortColumn: sort.type,
            sortType: sort.orderBy,
            onSortColumn: handleSortColumn,
            onEditNegotiation: handleEditNegotiation,
            pagination,
        },
        onNewNegotiation: handleClickNewNegotiation,
    };
};
