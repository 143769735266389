export const SET_PERMISSOES = 'SET_PERMISSOES'

export function setPermissoesUsuario(permissoes, servicoBi, servicoPainel, servicoIra, servicoColeta){
    return {
        type: SET_PERMISSOES,
        permissoes,
        servicoBi,
        servicoPainel,
        servicoIra,
        servicoColeta
    }
}
