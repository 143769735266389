import { useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import * as PainelGeralService from '../../InfoPanel/services';
import { getModel } from '../utils';

export const useProductDoubleRequestList = () => {
    const [productsReference, setProductsReference] = useState({
        list: [],
        cache: [],
        value: [],
    });
    const [productsCompetitor, setProductsCompetitor] = useState({
        list: [],
        cache: [],
        value: [],
    });
    const [timer, setTimer] = useState(null);
    const [indeterminateProductCompetitor, setIndeterminateProductCompetitor] =
        useState(false);
    const [allProductsCompetitorChecked, setAllProductsCompetitorChecked] =
        useState(false);
    const [productCompetitorQuery, setProductCompetitorQuery] = useState('');
    const [productReferenceQuery, setProductReferenceQuery] = useState('');
    const [savedFilterLoading, setSavedFiterLoading] = useState(false);

    const { filtrosDataCompetitividade } = useSelector(
        (state) => ({
            filtrosDataCompetitividade:
                state.analiseCompetitividadeDataReducer
                    .filtrosDataCompetitividade,
        }),
        shallowEqual,
    );

    const setList = (list, type) => {
        const newList = list.map((item) => ({
            id: item.id,
            value: item.identificador,
            label: `${item.identificador} - ${item.descricao}`,
            identificador: item.identificador,
            descricao: item.descricao,
            secao: item.secao,
            categoria: item.categoria,
            datapoints: item.datapoints,
            limited: item.limited,
            foto: item.foto,
        }));

        if (type !== 'referencia' && type !== 'competitor') {
            setProductsReference((prevState) => ({
                ...prevState,
                list: newList,
            }));
            setProductsCompetitor((prevState) => ({
                ...prevState,
                list: newList,
            }));
        }

        if (type === 'referencia') {
            const referenceValue = productsReference.cache;
            const removeDuplicateReference = newList.filter(
                (i) => !productsReference.value.includes(i.value),
            );
            const concatListReference = productsReference.value.length
                ? referenceValue.concat(removeDuplicateReference)
                : newList;

            setProductsReference((prevState) => ({
                ...prevState,
                list: concatListReference,
            }));
        }

        if (type === 'competitor') {
            const cacheListCompetitor = productsCompetitor.cache;
            const removeDuplicateCompetitor = newList.filter(
                (i) => !productsCompetitor.value.includes(i.value),
            );

            const concatListCompetitor = productsCompetitor.value.length
                ? cacheListCompetitor.concat(removeDuplicateCompetitor)
                : newList;

            setProductsCompetitor((prevState) => ({
                ...prevState,
                list: concatListCompetitor,
            }));

            if (
                concatListCompetitor.length !== productsCompetitor.value.length
            ) {
                setAllProductsCompetitorChecked(false);
            }
        }
    };

    const changeProductValue = (cache, value) => {
        setProductsCompetitor((prevState) => ({
            ...prevState,
            cache,
            value,
        }));
    };

    const handleAddProduct = (item, type) => {
        const newListCompetitor = productsCompetitor.value;
        const newValueCompetitor = newListCompetitor.concat(item.identificador);

        if (type === 'referencia') {
            setProductsReference((prevState) => ({
                ...prevState,
                value: [item.identificador],
                cache: [item],
            }));
        } else {
            setProductsCompetitor((prevState) => ({
                ...prevState,
                value: newValueCompetitor,
                cache: item,
            }));
        }
    };

    const handleRemoveProduct = (item, type) => {
        const removedCache = productsCompetitor.cache.filter(
            (i) => i.identificador !== item.identificador,
        );
        const newValue = productsCompetitor.value.filter(
            (i) => i !== item.identificador,
        );

        if (type === 'referencia') {
            setProductsReference((prevState) => ({
                ...prevState,
                value: newValue,
                cache: removedCache,
            }));
        } else {
            setProductsCompetitor((prevState) => ({
                ...prevState,
                value: newValue,
                cache: removedCache,
            }));
        }
    };

    const handleCleanProduct = (type) => {
        if (type === 'referencia') {
            setProductsReference((prevState) => ({
                ...prevState,
                value: [],
                cache: [],
            }));
        } else {
            setProductsCompetitor({
                list: [],
                value: [],
                cache: [],
            });
        }
    };

    const handleCleanAllSelectProduct = () => {
        setProductsReference({
            value: [],
            cache: [],
        });
        setProductsCompetitor({
            value: [],
            cache: [],
        });
    };

    const searchProdutos = (data, type, size) => {
        PainelGeralService.getProduto(data, size).then((response) => {
            if (response) {
                setList(response, type);
            }
        });
    };

    const onSearchProductsReference = (inputValue) => {
        clearTimeout(timer);

        const newTimer = setTimeout(() => {
            searchProdutos(
                getModel(filtrosDataCompetitividade, inputValue, 'produto'),
                'referencia',
            );
        }, 500);

        setTimer(newTimer);
        setProductReferenceQuery(inputValue);
    };

    const handleSearchProductsCompetitor = (inputValue, type) => {
        let filtros;

        if (type === 'referencia') {
            setProductReferenceQuery(inputValue);
        } else {
            setProductCompetitorQuery(inputValue);
        }

        if (
            inputValue &&
            typeof inputValue === 'string' &&
            inputValue.match(/^\d+ \d+[\d+ ]*$/)
        ) {
            const concatValue = productsCompetitor.value.concat(
                inputValue.split(' '),
            );
            const newValue = [...new Set(concatValue)];
            filtros = { query: '', identificadores: newValue };

            setProductsCompetitor((prevState) => ({
                ...prevState,
                value: newValue,
            }));
        } else {
            filtros = {
                query: typeof inputValue === 'string' ? inputValue : '',
            };
        }

        searchProdutos(
            getModel(
                filtrosDataCompetitividade,
                filtros.query,
                'produto',
                filtros.identificadores ? filtros.identificadores : null,
            ),
            type,
        );
    };

    const handleSearchSavedProductsCompetitor = (competitors) => {
        // Usado somente quando os filtros salvos forem acionados (varejo)

        setSavedFiterLoading(true);

        const bodyCompetitor = getModel(
            filtrosDataCompetitividade,
            '',
            'produto',
            competitors,
        );

        setProductsCompetitor((prevState) => ({
            ...prevState,
            value: competitors,
        }));

        PainelGeralService.getProduto(bodyCompetitor).then((response) => {
            if (response) {
                setList(response, 'competitor');
                setSavedFiterLoading(false);
            } else {
                setSavedFiterLoading(false);
            }
        });
    };

    const savedFiltersDoubleRequest = (reference, competitors) => {
        // Usado somente quando os filtros salvos forem acionados (industria)
        setSavedFiterLoading(true);

        const bodyReference = getModel(
            filtrosDataCompetitividade,
            '',
            'produto',
            reference,
        );

        const bodyCompetitor = getModel(
            filtrosDataCompetitividade,
            '',
            'produto',
            competitors,
        );
        setProductsReference((prevState) => ({
            ...prevState,
            value: [reference],
        }));
        setProductsCompetitor((prevState) => ({
            ...prevState,
            value: competitors,
        }));

        PainelGeralService.getProduto(bodyReference).then((response) => {
            if (response) {
                setList(response, 'referencia');

                PainelGeralService.getProduto(bodyCompetitor).then(
                    (response) => {
                        if (response) {
                            setList(response, 'competitor');
                            setSavedFiterLoading(false);
                        } else {
                            setSavedFiterLoading(false);
                        }
                    },
                );
            } else {
                setSavedFiterLoading(false);
            }
        });
    };

    const handleOnClickLoadMore = (type) => {
        const productListLength =
            type === 'referencia'
                ? productsReference?.list?.length
                : productsCompetitor?.list?.length;
        const productQuery =
            type === 'referencia'
                ? productReferenceQuery
                : productCompetitorQuery;

        if (productListLength < 1000) {
            searchProdutos(
                getModel(filtrosDataCompetitividade, productQuery, 'produto'),
                type,
                productListLength + 100,
            );
        }
    };

    const handleLoadMore = (type) => {
        if (type !== 'referencia') {
            setIndeterminateProductCompetitor(false);
            setAllProductsCompetitorChecked(false);
        }

        handleOnClickLoadMore(type);
    };

    const setProductsForEdition = (inputValue) => {
        const concatValue = productsCompetitor.value.concat(
            inputValue.split(' '),
        );
        const newValue = [...new Set(concatValue)];
        const filtros = { query: '', identificadores: newValue };

        setProductsCompetitor((prevState) => ({
            ...prevState,
            value: newValue,
        }));

        searchProdutos(
            getModel(
                filtrosDataCompetitividade,
                filtros.query,
                'produto',
                filtros.identificadores || null,
            ),
        );
    };

    useEffect(() => {
        if (productsCompetitor.value?.length) {
            const filterList = productsCompetitor.list?.filter((cache) =>
                productsCompetitor.value?.some(
                    (i) => cache.identificador === i,
                ),
            );

            if (filterList?.length) {
                setProductsCompetitor((prevState) => ({
                    ...prevState,
                    cache: filterList,
                }));
            }
        }
    }, [productsCompetitor.value.length, productsCompetitor.list]);

    useEffect(() => {
        if (productsReference.value.length) {
            const referenceFilterList = productsReference.list?.filter(
                (cache) =>
                    productsReference.value?.some(
                        (i) => cache.identificador === i,
                    ),
            );

            if (referenceFilterList?.length) {
                setProductsReference((prevState) => ({
                    ...prevState,
                    cache: referenceFilterList,
                }));
            }
        }
    }, [productsReference.value.length, productsReference.list]);

    return {
        onSearchProductsReference,
        handleSearchProductsCompetitor,
        productsReference,
        productsCompetitor,
        handleAddProduct,
        handleRemoveProduct,
        handleCleanProduct,
        handleLoadMore,
        setProductsForEdition,
        indeterminateProductCompetitor,
        allProductsCompetitorChecked,
        setIndeterminateProductCompetitor,
        setAllProductsCompetitorChecked,
        changeProductValue,
        setProductsCompetitor,
        setProductsReference,
        handleSearchSavedProductsCompetitor,
        savedFiltersDoubleRequest,
        savedFilterLoading,
        handleCleanAllSelectProduct,
    };
};
