import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import { RootState } from '../../@types/RootState';

type State = Partial<Record<string, string[]>>;

const initialState: State = {};

const slice = createSlice({
    name: 'ipa/gerenciador/disabledCompetitors',
    initialState,
    reducers: {
        ADD_DISABLED_COMPETITOR(
            state,
            action: PayloadAction<{
                productsToBePricedId: string;
                competitorStoreId: string;
            }>,
        ) {
            const { productsToBePricedId, competitorStoreId } = action.payload;
            if (state[productsToBePricedId]) {
                state[productsToBePricedId] = _.uniq([
                    ...state[productsToBePricedId],
                    competitorStoreId,
                ]);
            } else state[productsToBePricedId] = [competitorStoreId];
        },
        REMOVE_DISABLED_COMPETITOR(
            state,
            action: PayloadAction<{
                productsToBePricedId: string;
                competitorStoreId: string;
            }>,
        ) {
            const { productsToBePricedId, competitorStoreId } = action.payload;
            if (state[productsToBePricedId]) {
                state[productsToBePricedId] = state[
                    productsToBePricedId
                ].filter((item) => item !== competitorStoreId);
            }
        },
        RESET_DISABLED_COMPETITOR: () => initialState,
    },
});

export const selectorDisabledCompetitors = (state: RootState) => {
    return state.gerenciadorPrecosReducer.disabledCompetitors;
};

export const {
    ADD_DISABLED_COMPETITOR,
    REMOVE_DISABLED_COMPETITOR,
    RESET_DISABLED_COMPETITOR,
} = slice.actions;

export default slice.reducer;
