import {
 getSegmentos,
 getSegmentosClasses,
 getSegmentosSensibilities,
 getItensSegmentoProduto,
 getQtdProdutosAfetados,
 searchFiltroProdutos,
} from '../services/ProdutoService';

import { getLojas, getClusters } from '../pages/IPA/RegrasPreco/services';

export const ADD_SEGMENTO = 'ADD_SEGMENTO';
export const DELETE_SEGMENTO = 'DELETE_SEGMENTO';
export const CHANGE_VALUE_SEGMENTO = 'CHANGE_VALUE_SEGMENTO';
export const CHANGE_CACHE_SEGMENTO = 'CHANGE_CACHE_SEGMENTO';
export const SELECT_VALUE_SEGMENTO = 'SELECT_VALUE_SEGMENTO';
export const LIST_ITENS_SEGMENTOS = 'LIST_ITENS_SEGMENTOS';
export const LIST_ITENS_SEGMENTOS_CLASSES_REGRA_PRECO_CADASTRO = 'LIST_ITENS_SEGMENTOS_CLASSES_REGRA_PRECO_CADASTRO';
export const GET_SEGMENTOS_REGRA_PRECO = 'GET_SEGMENTOS_REGRA_PRECO';
export const CLEAR_ALL_SEGMENTOS = 'CLEAR_ALL_SEGMENTOS';
export const SET_QTD_PRODUTO_AFETADOS_REGRA_PRECO = 'SET_QTD_PRODUTO_AFETADOS_REGRA_PRECO';
export const SET_SEGMENTOS_UPDATE_REGRA = 'SET_SEGMENTOS_UPDATE_REGRA';
export const SET_SEGMENTOS_PRODUTOS_UPDATE_REGRA = 'SET_SEGMENTOS_PRODUTOS_UPDATE_REGRA';
export const SET_SEGMENTOS_CLASSES_UPDATE_REGRA = 'SET_SEGMENTOS_CLASSES_UPDATE_REGRA';
export const SET_SEGMENTOS_SENSIBILITY_UPDATE_REGRA = 'SET_SEGMENTOS_SENSIBILITY_UPDATE_REGRA';
export const LIST_ITENS_PRODUTO_CADASTRO_REGRA = 'LIST_ITENS_PRODUTO_CADASTRO_REGRA';
export const LIST_ITEMS_LOJA_CADASTRO_REGRA = 'LIST_ITEMS_LOJA_CADASTRO_REGRA';
export const LIST_ITEMS_CLUSTER_CADASTRO_REGRA = 'LIST_ITEMS_CLUSTER_CADASTRO_REGRA';
export const SET_LOJAS_UPDATE_REGRA = 'SET_LOJAS_UPDATE_REGRA';
export const SET_CLUSTERS_UPDATE_REGRA = 'SET_CLUSTERS_UPDATE_REGRA';

export function addSegmento(filtro) {
    return {
        type: ADD_SEGMENTO,
        filtro,
    };
}

export function deleteSegmento(index, name) {
    return {
        type: DELETE_SEGMENTO,
        index,
        name,
    };
}

export function changeValueSegmento(value, name) {
    return {
        type: CHANGE_VALUE_SEGMENTO,
        value,
        name,
    };
}

export function changeCacheSegmento(name, cache) {
    return {
        type: CHANGE_CACHE_SEGMENTO,
        name,
        cache,
    };
}

export function selectValueSegmento(item, name) {
    return {
        type: SELECT_VALUE_SEGMENTO,
        item,
        name,
    };
}

export function listItensSegmentos(itens, name) {
    return {
        type: LIST_ITENS_SEGMENTOS,
        itens,
        name,
    };
}

export function listItensProduto(itens, name) {
    return {
        type: LIST_ITENS_PRODUTO_CADASTRO_REGRA,
        itens,
        name,
    };
}

export function listItensLoja(itens, name) {
    return {
        type: LIST_ITEMS_LOJA_CADASTRO_REGRA,
        itens,
        name,
    };
}

export function listItensCluster(itens, name) {
    return {
        type: LIST_ITEMS_CLUSTER_CADASTRO_REGRA,
        itens,
        name,
    };
}

export function listSegmentosClassesSensibility(itens, name) {
    return {
        type: LIST_ITENS_SEGMENTOS_CLASSES_REGRA_PRECO_CADASTRO,
        itens,
        name,
    };
}

export function clearSegmentos() {
    return {
        type: CLEAR_ALL_SEGMENTOS,
    };
}

export function setQtdProdutosAfetados(qtdProdutosAfetados) {
    return {
        type: SET_QTD_PRODUTO_AFETADOS_REGRA_PRECO,
        qtdProdutosAfetados,
    };
}

export function getAllSegmentos(itens) {
    const segmentos = {};
    itens.forEach((item) => {
        segmentos[item.field] = { lista: [], value: [], cache: [] };
    });
    return {
        type: GET_SEGMENTOS_REGRA_PRECO,
        itens,
        segmentos,
    };
}

export function setSegmentosUpdateRegra(name, value) {
    return {
        type: SET_SEGMENTOS_UPDATE_REGRA,
        name,
        value,
    };
}

export function setSegmentosProdutosUpdateRegra(name, item) {
    return {
        type: SET_SEGMENTOS_PRODUTOS_UPDATE_REGRA,
        name,
        item,
    };
}

export function setLojasUpdateRegra(name, item) {
    return {
        type: SET_LOJAS_UPDATE_REGRA,
        name,
        item,
    };
}

export function setClustersUpdateRegra(name, item) {
    return {
        type: SET_CLUSTERS_UPDATE_REGRA,
        name,
        item,
    };
}

export function setSegmentosClassesUpdateRegra(name, item) {
    return {
        type: SET_SEGMENTOS_CLASSES_UPDATE_REGRA,
        name,
        item,
    };
}

export function setSegmentosSensibilitiesUpdateRegra(name, item) {
    return {
        type: SET_SEGMENTOS_SENSIBILITY_UPDATE_REGRA,
        name,
        item,
    };
}

export function getProdutoAfetados(query) {
    return (dispatch) => {
        getQtdProdutosAfetados(query)
            .then((data) => dispatch(setQtdProdutosAfetados(data)));
    };
}

export function searchSegmentos() {
    return (dispatch) => {
        getSegmentos()
            .then((data) => dispatch(getAllSegmentos(data)));
    };
}

export function searchSegmentosClasses() {
    return (dispatch) => {
        getSegmentosClasses()
            .then((data) => dispatch(listSegmentosClassesSensibility(data, 'abcClass')));
        };
    }

export function searchSegmentosSensibilities() {
    return (dispatch) => {
        getSegmentosSensibilities()
            .then((data) => dispatch(listSegmentosClassesSensibility(data, 'sensibilityType')));
    };
}

export function searchItensSegmento(nivel, query, name) {
    return (dispatch) => {
        getItensSegmentoProduto(nivel, query).then((data) => dispatch(listItensSegmentos(data, name)));
    };
}

export function searchProduto(nivel, query, name) {
    return (dispatch) => {
        searchFiltroProdutos(query).then((data) => dispatch(listItensProduto(data, name)));
    };
}

export function searchStore(nivel, query, name) {
    return async (dispatch) => {
        const resp = await getLojas(query);
        if (resp) dispatch(listItensLoja(resp, name));
    };
}

export function searchCluster(nivel, query, name) {
    return async (dispatch) => {
        const resp = await getClusters(query);
        if (resp) dispatch(listItensCluster(resp, name));
    };
}
