import React from "react";
import { Modal, Button } from 'rsuite';


const ModalNotification = (props) => (

    <Modal backdrop={true} show={props.show} onHide={props.onHide}>
        <Modal.Header>
            <Modal.Title>{props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <p>{props.message}</p>
        </Modal.Body>
        <Modal.Footer>
            <Button onClick={props.close} appearance="primary">
                Ok
            </Button>
        </Modal.Footer>
    </Modal>
)

export default ModalNotification;