import React from 'react';
import { Button, InputNumber } from 'rsuite';
import SelectPill from '../../../../../../components/SelectPill';
import { periodRange } from '../../utils';
import styles from './styles.module.scss';

export const SaveReportSelectIntervalContentForm = ({
    active,
    InputNumberValue,
    InputNumberOnChange,
    onClick,
}) => (
    <>
        <p>
            Não é possível salvar relatório com datas específicas. Para
            prosseguir, defina outro Período que será considerado para
            determinar os dados presentes no relatório:
        </p>
        <div className={styles['select-latest-wrapper']}>
            {periodRange.map((item) => (
                <Button
                    className={`${styles['inf-pill-button']} ${
                        active === item ? 'active' : ''
                    }`}
                    key={item}
                    onClick={() => onClick(item)}
                >
                    Últimos {item} dias
                </Button>
            ))}
            <SelectPill
                id="select-picker-pill"
                className={`${styles['select-interval']} ${
                    active === 1 ? 'active' : ''
                }`}
                menuClassName={styles['select-interval-menu']}
                placeholder="Intervalo personalizado"
                searchable={false}
                renderExtraFooter={() => (
                    <div className={styles['select-interval-input-wrapper']}>
                        <p>Últimos</p>
                        <InputNumber
                            min={0}
                            value={InputNumberValue}
                            onChange={InputNumberOnChange}
                        />
                        <p>dias</p>
                    </div>
                )}
            />
        </div>
    </>
);
