import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../@types/RootState';
import { Wholesale } from '../../pages/IPA/Estrategia/pages/PrecoAtacado/types';

export type PrecoAtacadoDatapointsState = Wholesale.RowData[];

const initialState: PrecoAtacadoDatapointsState = [];

const slice = createSlice({
    name: 'ipa/precoAtacado/datapoints',
    initialState,
    reducers: {
        SET_PRECO_ATACADO_DATAPOINTS: (
            _state,
            { payload }: PayloadAction<PrecoAtacadoDatapointsState>,
        ) => payload,
        UPDATE_PRECO_ATACADO_DATAPOINT_BY_INDEX: (
            state,
            {
                payload,
            }: PayloadAction<{
                index: number;
                data: Partial<PrecoAtacadoDatapointsState[number]>;
            }>,
        ) => {
            const { index, data } = payload;
            state[index] = { ...state[index], ...data };
        },
    },
});

const {
    SET_PRECO_ATACADO_DATAPOINTS,
    UPDATE_PRECO_ATACADO_DATAPOINT_BY_INDEX,
} = slice.actions;

const selectorPrecoAtacadoDatapoints = (state: RootState) => {
    return state.precoAtacadoReducer.datapoints;
};

export {
    initialState,
    selectorPrecoAtacadoDatapoints,
    SET_PRECO_ATACADO_DATAPOINTS,
    UPDATE_PRECO_ATACADO_DATAPOINT_BY_INDEX,
};

export default slice.reducer;
