import QueryString from 'qs';
import { DataItem } from '../../@types/DataItem';
import { PageOptions } from '../../@types/FiltersTypes';
import { api } from '../../lib';
import {
    getFamiliaProdutos,
    getLojasRevisao,
} from '../../pages/IPA/RevisaoPrecos/services';
import { getSavedFilters } from '../../pages/IPA/RevisaoPrecos/services_ts';
import {
    getItensSegmentoProduto,
    getSegmentos,
    getSegmentosClasses,
    getSegmentosSensibilities,
    searchFiltroProdutos,
} from '../../services/ProdutoService';
import {
    getProductBrandsData,
    getProductSuppliersData,
} from '../../services/ProdutoService_ts';

type Response<K extends string = string, V = any> = Promise<DataItem<K, V>[]>;

export const listProductBrands = async (query?: string): Response => {
    const data = await getProductBrandsData({ search: query });
    return (
        data.map((item) => ({
            value: item.productBrand,
            label: item.productBrand,
        })) ?? []
    );
};

export const listSuppliers = async (query?: string): Response => {
    const data = await getProductSuppliersData({ search: query });
    return (
        data.map((item) => ({
            value: item.supplier,
            label: item.supplier,
        })) ?? []
    );
};

export const listProducts = async (
    query?: string,
): Response<'description', string> => {
    const response = await searchFiltroProdutos({ query });
    return (
        response.data.map((item: Record<string, unknown>) => ({
            label: `${item.product_id} - ${item.description}`,
            value: item.product_id,
            description: item.description,
        })) ?? []
    );
};

export const listStores = async (query?: string): Response => {
    const response = await getLojasRevisao(query);
    return (
        response.map((item: Record<string, unknown>) => {
            return {
                label: `${item.store_id} - ${item.store}`,
                value: item.store_id,
            };
        }) ?? []
    );
};

export const listPriceType = async (): Response => {
    return new Promise((resolve) => {
        resolve([
            { value: 'AUTOMATIC', label: 'Auto' },
            { value: 'MANUAL', label: 'Manual' },
            { value: 'ERP', label: 'ERP' },
            { value: 'EDITED', label: 'Editado' },
        ]);
    });
};

export const listFamilies = async (query?: string) => {
    const response = await getFamiliaProdutos(query);
    return (
        response.product_family_ids.map((item: Record<string, unknown>) => ({
            label: item,
            value: item,
        })) ?? []
    );
};

export const listaSegmentos = async () => {
    const resp = (await getSegmentos()) as {
        level: number;
        name: string;
        field: string;
    }[];
    const sortedData = resp.sort((a, b) => a.level - b.level);
    return sortedData ?? [];
};

export const listSavedFilters = async (
    query?: string,
    options?: { screen: PageOptions },
) => {
    const data = await getSavedFilters({
        search: query,
        screen: options?.screen ?? 'NEGOTIATION_SUPPLIER',
    });
    return data.map((filter) => ({
        label: filter.name,
        value: filter.id,
        ...filter,
    }));
};

export const listSegmentsData = async (
    level: string,
    data?: Record<string, any>,
) => {
    const resp: string[] = await getItensSegmentoProduto(level, data);
    return resp.map((item) => ({
        label: item,
        value: item,
    }));
};

export const listOwners = async (query?: string): Response => {
    const res = await api.get<string[]>(
        'setup/objective-margin/filters/editedBy',
        {
            expectToken: true,
            params: { search: query },
            paramsSerializer(params) {
                return QueryString.stringify(params, { arrayFormat: 'repeat' });
            },
        },
    );

    return (
        res.data.map((item) => ({
            label: item,
            value: item,
        })) || []
    );
};

export const listSegment = async (query?: string, level?: number): Response => {
    const res = (await getItensSegmentoProduto(`categoryLevel${level}`, {
        query,
    })) as string[];
    return res.map((segment) => ({ label: segment, value: segment }));
};

export const listInfoPriceSensibility = async (): Response => {
    const data = [
        { label: 'Sensível', value: 'SENSITIVE' },
        { label: 'Não Sensível', value: 'NOT_SENSITIVE' },
        { label: 'Super Sensível', value: 'SUPER_SENSITIVE' },
    ];

    return new Promise<typeof data>((resolve) => {
        resolve(data);
    });
};

export const listInfoPriceAbcClass = async (): Response => {
    const data = [
        { label: 'Local A', value: 'A' },
        { label: 'Local B', value: 'B' },
        { label: 'Local C', value: 'C' },
        { label: 'Local D', value: 'D' },
    ];

    return new Promise<typeof data>((resolve) => {
        resolve(data);
    });
};

export const listInfoPriceGlobalAbcClass = async (): Response => {
    const data = [
        { label: 'Global A', value: 'A' },
        { label: 'Global B', value: 'B' },
        { label: 'Global C', value: 'C' },
        { label: 'Global D', value: 'D' },
    ];

    return new Promise<typeof data>((resolve) => {
        resolve(data);
    });
};

export const listSensibilityType = async (): Response => {
    const res: { sensibility_type: string }[] =
        await getSegmentosSensibilities();

    return res.map((item) => ({
        label: item.sensibility_type,
        value: item.sensibility_type,
    }));
};

export const listAbcClass = async (): Response => {
    const data = [
        { label: 'A', value: 'A' },
        { label: 'B', value: 'B' },
        { label: 'C', value: 'C' },
        { label: 'D', value: 'D' },
    ];

    return new Promise<typeof data>((resolve) => {
        resolve(data);
    });
};

export type SegmentData = {
    label: string;
    value: {
        type: string;
        value: string;
    };
    role: string;
};
export const listaSegmentacao = async () => {
    const resp = await getSegmentosSensibilities();

    const respAbcClass = await getSegmentosClasses();

    const data: SegmentData[] = [
        {
            label: 'Super Sensível',
            value: {
                type: 'infoPriceSensibility',
                value: 'SUPER_SENSITIVE',
            },
            role: 'Sensibilidade InfoPrice',
        },
        {
            label: 'Sensível',
            value: {
                type: 'infoPriceSensibility',
                value: 'SENSITIVE',
            },
            role: 'Sensibilidade InfoPrice',
        },
        {
            label: 'Não Sensível',
            value: {
                type: 'infoPriceSensibility',
                value: 'NOT_SENSITIVE',
            },
            role: 'Sensibilidade InfoPrice',
        },
        {
            label: 'Local A',
            value: {
                type: 'infoPriceAbcClass',
                value: 'A',
            },
            role: 'Curva ABC Local',
        },
        {
            label: 'Local B',
            value: {
                type: 'infoPriceAbcClass',
                value: 'B',
            },
            role: 'Curva ABC Local',
        },
        {
            label: 'Local C',
            value: {
                type: 'infoPriceAbcClass',
                value: 'C',
            },
            role: 'Curva ABC Local',
        },
        {
            label: 'Local D',
            value: {
                type: 'infoPriceAbcClass',
                value: 'D',
            },
            role: 'Curva ABC Local',
        },
        {
            label: 'Global A',
            value: {
                type: 'infoPriceGlobalAbcClass',
                value: 'A',
            },
            role: 'Curva ABC Global',
        },
        {
            label: 'Global B',
            value: {
                type: 'infoPriceGlobalAbcClass',
                value: 'B',
            },
            role: 'Curva ABC Global',
        },
        {
            label: 'Global C',
            value: {
                type: 'infoPriceGlobalAbcClass',
                value: 'C',
            },
            role: 'Curva ABC Global',
        },
        {
            label: 'Global D',
            value: {
                type: 'infoPriceGlobalAbcClass',
                value: 'D',
            },
            role: 'Curva ABC Global',
        },
        ...(resp.length
            ? resp.map((item: { sensibility_type: string }) => ({
                  label: item.sensibility_type,
                  value: {
                      type: 'sensibilityType',
                      value: item.sensibility_type,
                  },
                  role: 'Exclusiva',
              }))
            : []),
        ...(respAbcClass.length
            ? respAbcClass.map((item: { abc_class: string }) => ({
                  label: item.abc_class,
                  value: { type: 'abcClass', value: item.abc_class },
                  role: 'Curva',
              }))
            : []),
    ];

    return new Promise<SegmentData[]>((resolve) => {
        resolve(data);
    });
};

export const listFiltrosSalvos = async (
    query = '',
    screen: PageOptions = 'PRICE_MANAGER',
) => {
    const data = await getSavedFilters({ search: query, screen });

    const dataItem: DataItem[] = data.map((filter) => ({
        label: filter.name,
        value: filter.id,
        ...filter,
    }));

    return dataItem;
};
