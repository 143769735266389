import classNames from 'classnames';
import React from 'react';
import {
    Callout,
    Layout,
    Skeleton,
    TableSkeleton,
} from '../../../../../components';
import margemObjetivaStyles from '../../pages/MargemObjetiva/MargemObjetiva.module.scss';
import styles from './MargemObjetivaSkeleton.module.scss';

export const MargemObjetivaSkeleton = ({
    className,
    ...props
}: React.HtmlHTMLAttributes<HTMLElement>) => {
    return (
        <Layout
            className={classNames(
                margemObjetivaStyles['margem-objetiva'],
                styles['margem-objetiva-skeleton'],
                className,
            )}
            {...props}
        >
            <Layout.Header
                className={margemObjetivaStyles['margem-objetiva__header']}
            >
                <div
                    className={
                        margemObjetivaStyles[
                            'margem-objetiva__header__left-container'
                        ]
                    }
                >
                    <Layout.Title>Margem objetiva</Layout.Title>
                    <Callout className={margemObjetivaStyles.callout}>
                        As alterações são salvas automaticamente e serão
                        aplicadas na próxima integração de dados
                    </Callout>
                    <p>
                        Defina a margem objetiva de cada categoria para alcançar
                        seus objetivos de negócio
                    </p>
                </div>
                <div
                    className={
                        margemObjetivaStyles[
                            'margem-objetiva__header__right-container'
                        ]
                    }
                >
                    <Skeleton width={80} height={26} rounded="sm" />
                    <Skeleton width={80} height={26} rounded="sm" />
                </div>
            </Layout.Header>
            <Layout.Body>
                <Layout.Section padded>
                    <div className={styles.filter_section}>
                        <Skeleton width={80} height={26} rounded="pill" />
                        <Skeleton width={80} height={26} rounded="pill" />
                        <Skeleton width={80} height={26} rounded="pill" />
                        <Skeleton width={120} height={26} rounded="pill" />
                    </div>
                </Layout.Section>
                <Layout.Section className={styles.table_heading} padded>
                    <Skeleton width={300} height={14} />
                </Layout.Section>
                <Layout.Section>
                    <TableSkeleton cols={6} rows={4} />
                </Layout.Section>
            </Layout.Body>
        </Layout>
    );
};
