import {
    getCargos, getSegmentos, getCasosDeUso, getCidades, getUfs, validateDataQuantity, getSectors,
} from '../services/FreemiumService';

export const SELECT_FORM_STAGE = 2;
export const SELECT_CHANNEL_STAGE = 3;
const SELECT_REGION_STAGE = 4;
const SELECT_MOTIVATION_STAGE = 5;

export const SET_EMAIL = 'SET_EMAIL';
export const UPDATE_STAGE = 'UPDATE_STAGE';
export const SET_ACCOUNT_DATA = 'SET_ACCOUNT_DATA';
export const SET_ACCOUNT_MOTIVES = 'SET_ACCOUNT_MOTIVES';
export const SET_ACCOUNT_REGION = 'SET_ACCOUNT_REGION';
export const SET_ROLES = 'SET_ROLES';
export const SET_COMPANY_SEGMENTS = 'SET_COMPANY_SEGMENTS';
export const SET_SECTORS = 'SET_SECTORS';
export const SET_MOTIVES = 'SET_MOTIVES';
export const SET_CITIES = 'SET_CITIES';
export const SET_STATES = 'SET_STATES';
export const SET_ENOUGH_DATA = 'SET_ENOUGH_DATA';
export const SET_ENOUGH_DATA_NULL = 'SET_ENOUGH_DATA_NULL';

export function setEmail(email) {
    return {
        type: SET_EMAIL,
        email,
    };
}

export function updateStage(stage) {
    return {
        type: UPDATE_STAGE,
        stage,
    };
}

export function setAccountData(value, attributeName) {
    return {
        type: attributeName === 'region' ? SET_ACCOUNT_REGION : SET_ACCOUNT_DATA,
        value,
        attributeName,
    };
}

export function setAccountMotives(motives) {
    return {
        type: SET_ACCOUNT_MOTIVES,
        motives,
    };
}

export function setRoles(roles) {
    return {
        type: SET_ROLES,
        roles,
    };
}

export function setCompanySegments(companySegments) {
    return {
        type: SET_COMPANY_SEGMENTS,
        companySegments,
    };
}

export function setSectors(sectors) {
    return {
        type: SET_SECTORS,
        sectors,
    };
}

export function setMotives(motives) {
    return {
        type: SET_MOTIVES,
        motives,
    };
}

export function setCities(cities) {
    return {
        type: SET_CITIES,
        cities,
    };
}

export function setStates(states) {
    return {
        type: SET_STATES,
        states,
    };
}

export function setEnoughData(data) {
    return {
        type: SET_ENOUGH_DATA,
        data,
    };
}

export function setEnoughDataNull() {
    return {
        type: SET_ENOUGH_DATA_NULL,
    };
}

export function getRoleList() {
    return (dispatch) => {
        getCargos().then((response) => {
            dispatch(setRoles(response));
        });
    };
}

export function getCompanySegmentsList() {
    return (dispatch) => {
        getSegmentos().then((response) => {
            dispatch(setCompanySegments(response));
        });
    };
}

export function getSectorsList() {
    return (dispatch) => {
        getSectors().then((response) => {
            dispatch(setSectors(response));
        });
    };
}

export function getMotivesList() {
    return (dispatch) => {
        getCasosDeUso().then((response) => {
            dispatch(setMotives(response));
        });
    };
}

export function getCity(query) {
    return (dispatch) => {
        getCidades(query).then((response) => {
            dispatch(setCities(response));
        });
    };
}

export function getState() {
    return (dispatch) => {
        getUfs().then((response) => {
            dispatch(setStates(response));
        });
    };
}

export function getRegionAutocompleteOptions(type) {
    return (dispatch) => {
        if (type === 'city') {
            dispatch(getCity(''));
        }
        if (type === 'state') {
            dispatch(getState());
        }
    };
}

export function getEnoughData(model) {
    return (dispatch) => {
        validateDataQuantity(model).then((response) => {
            dispatch(setEnoughData(response));
        });
    };
}

export function nextStage(stage) {
    return (dispatch) => {
        switch (stage) {
            case 2:
                dispatch(updateStage(SELECT_CHANNEL_STAGE));
                break;
            case 3:
                dispatch(updateStage(SELECT_REGION_STAGE));
                break;
            case 4:
                dispatch(updateStage(SELECT_MOTIVATION_STAGE));
                break;
            default:
                break;
        }
    };
}
