import React, { useEffect, useRef, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
    Button,
    CheckPicker, Checkbox,
    Col,
    Grid,
    Icon,
    Row,
} from 'rsuite';

import {
    changeRecommendation, clearFilter, deleteFiltro, getItensFiltro, selectFilter,
} from '../../../../../actions/actionsRevisaoLista';
import { LoadingMenu, LoadingSpiner } from '../../../../../components/LoadingSpinner';
import { getPreviewListasCadastradas } from '../services';
import { setFiltersModelListaCadastrada, setPreviewListaCadastradaDTO } from '../utils';
import ButtonAddFiltro from './ButtonAddFiltro';
import FiltrosAdicionados from './FiltrosAdicionados';
import ResultadoValidacao from './ResultadoValidacao';

const ListasCadastradas = () => {
    const dispatch = useDispatch();
    const resultRef = useRef(null);
    const size = 20;
    const [result, setResult] = useState([]);
    const [page, setPage] = useState(0);
    const [totalItens, setTotalItens] = useState(0);
    const { filtrosAdicionados, filtrosData, produto } = useSelector(
        (state) => ({
            filtrosAdicionados: state.revisaoListaDataReducer.filtrosAdicionados,
            filtrosData: state.revisaoListaDataReducer.filtrosData,
        }),
        shallowEqual,
    );

    const executeScroll = () => {
        if (resultRef?.current) {
            resultRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const getResult = () => {
        getPreviewListasCadastradas(setPreviewListaCadastradaDTO(filtrosData, { size, page })).then((response) => {
            setResult(response.content);
            setTotalItens(response.totalElements);
            setPage(response.number);
            executeScroll();
        });
    };

    const selectItem = (value, item, name) => {
        const cache = [...filtrosData[name].cache, item];
        let updatedCache = cache;

        if (!value.length) {
            dispatch(getItensFiltro(setFiltersModelListaCadastrada(name, '', filtrosData), name));
            updatedCache = [];
        }

        if (value !== null && value.length !== cache.length) {
            updatedCache = cache.filter(
                (item) => value.indexOf(item.label) !== -1,
            );
        }

        dispatch(selectFilter(name, updatedCache, value));
    };

    const changePage = (page) => {
        setPage(page - 1);
    };

    useEffect(() => {
        if (page !== 0) { getResult(); }
    }, [page]);

    return (
        <>
            <div className="listas-cadastradas-component">
                <LoadingSpiner size="md" />
                <Grid fluid>
                    <Row className="row-btn">
                        <Col xs={24} md={6} lg={6}>
                            <Button
                                id="btn-list-review"
                                className="btn-list-review"
                                appearance="primary"
                                onClick={() => getResult()}
                            >
                                Revisar minhas listas

                            </Button>
                        </Col>
                        <Col xs={24} md={18}>
                            <div className="info-area">
                                <span><Icon className="tooltip-info" icon="info" /></span>
                                <span><p>Todas suas listas cadastradas serão revisadas. Para obter resultados mais direcionados, utilize os filtros.</p></span>
                            </div>
                        </Col>
                    </Row>
                    <div className="filter-area">
                        <Row>
                            <Col xs={24}>
                                <div className="filter-area__label">
                                    <p>Filtros </p>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={8} xs={24}>
                                <CheckPicker
                                    className="inf-picker"
                                    block
                                    id="columns-select-picker"
                                    filter={false}
                                    cleanable
                                    data={filtrosData?.loja?.data || []}
                                    value={filtrosData?.loja?.value || []}
                                    placeholder="Seleciona Loja"
                                    locale={{
                                        searchPlaceholder: 'Pesquisar',
                                        noResultsText: 'Nenhum item encontrado',
                                    }}
                                    renderMenu={(menu) => (
                                        <span>
                                            <LoadingMenu area="revisao-filter" />
                                            {menu}
                                        </span>
                                    )}
                                    onSearch={(query) => dispatch(getItensFiltro(setFiltersModelListaCadastrada('loja', query, filtrosData), 'loja'))}
                                    onOpen={() => dispatch(getItensFiltro(setFiltersModelListaCadastrada('loja', '', filtrosData), 'loja'))}
                                    onSelect={(value, item) => selectItem(value, item, 'loja')}
                                    onClean={() => dispatch(clearFilter('loja'))}
                                />
                            </Col>
                            <Col md={8} xs={24}>
                                <CheckPicker
                                    className="inf-picker"
                                    block
                                    id="columns-select-picker"
                                    filter={false}
                                    cleanable
                                    data={filtrosData?.produto?.data || []}
                                    value={filtrosData?.produto?.value || []}
                                    placeholder="Seleciona Produto"
                                    locale={{
                                        searchPlaceholder: 'Pesquisar',
                                        noResultsText: 'Nenhum item encontrado',
                                    }}
                                    renderMenu={(menu) => (
                                        <span>
                                            <LoadingMenu area="revisao-filter" />
                                            {menu}
                                        </span>
                                    )}
                                    onSearch={(query) => dispatch(getItensFiltro(setFiltersModelListaCadastrada('produto', query, filtrosData), 'produto'))}
                                    onOpen={() => dispatch(getItensFiltro(setFiltersModelListaCadastrada('produto', '', filtrosData), 'produto'))}
                                    onSelect={(value, item) => selectItem(value, item, 'produto')}
                                    onClean={() => dispatch(clearFilter('produto'))}
                                />
                            </Col>
                            <Col xs={24} md={4}>
                                <ButtonAddFiltro />
                            </Col>
                        </Row>
                        <Row>
                            {filtrosAdicionados.length ? filtrosAdicionados.map((filtro, index) => (
                                <FiltrosAdicionados
                                    key={filtro.name}
                                    filtro={filtro}
                                    handleDeleteFiltro={() => dispatch(deleteFiltro(index, filtro.name))}
                                    index={index}
                                />
                            )) : ''}
                        </Row>
                        <Row>
                            <Col xs={24} md={12}>
                                <Checkbox
                                    defaultChecked
                                    onChange={(value, checked) => dispatch(changeRecommendation(checked))}
                                >
                                    Exibir somente produtos com recomendação de substituição
                                </Checkbox>
                            </Col>
                        </Row>
                    </div>
                </Grid>

            </div>
            {result?.length
                ? (
                    <ResultadoValidacao
                        ref={resultRef}
                        resultado={result}
                        page={page}
                        size={size}
                        totalItens={totalItens}
                        onChangePage={changePage}
                        tipo="LISTA_CADASTRADA"
                        filtros={filtrosData}
                    />
                )
                : null}
        </>
    );
};

export default ListasCadastradas;
