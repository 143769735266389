import classNames from 'classnames';
import React from 'react';
import Modal, { ModalProps } from 'rsuite/lib/Modal';
import { ButtonPrimary } from '../../../../../../components';
import { LoadingSpinerArea } from '../../../../../../components/LoadingSpinner';
import styles from './ReabrirNegociacaoModal.module.scss';

export type ReabrirNegociacaoModalDataProps = {
    name: string;
    id: string;
};

export type ReabrirNegociacaoModalProps = ModalProps & {
    data: ReabrirNegociacaoModalDataProps;
    onSubmit?: (formData: ReabrirNegociacaoModalDataProps) => void;
    onHide?: () => void;
};

export const ReabrirNegociacaoModalModal = ({
    className = '',
    onHide,
    onSubmit,
    data,
    ...props
}: ReabrirNegociacaoModalProps) => (
    <>
        <Modal
            className={classNames(
                'info-modal',
                styles['reabrir-negociacao-modal'],
                className,
            )}
            onHide={onHide}
            {...props}
        >
            <LoadingSpinerArea
                height="100%"
                size="md"
                area="negotiation/reopen-negotiation"
            />
            <form onSubmit={(e) => e.preventDefault()}>
                <Modal.Header>
                    <Modal.Title
                        className={styles['excluir-negociacao-modal__title']}
                    >
                        Fechar negócio
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body
                    className={styles['excluir-negociacao-modal__body']}
                >
                    <p>Tem certeza que deseja reabrir a negociação </p>
                    <strong>{data.name}</strong>
                </Modal.Body>
                <Modal.Footer
                    className={styles['excluir-negociacao-modal__footer']}
                >
                    <ButtonPrimary
                        skin="blue"
                        theme="ghost"
                        type="button"
                        onClick={onHide}
                    >
                        CANCELAR
                    </ButtonPrimary>
                    <ButtonPrimary
                        skin="blue"
                        theme="filled"
                        type="submit"
                        onClick={() => onSubmit?.(data)}
                    >
                        reabrir NEGOCIAÇÃO
                    </ButtonPrimary>
                </Modal.Footer>
            </form>
        </Modal>
        <LoadingSpinerArea size="sm" area="saved-filter" height={null} />
    </>
);
