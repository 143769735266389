import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { DataItem } from '../../../@types/DataItem';
import { GerenciadorPrecos } from '../../../@types/GerenciadorPrecos';
import { RootState } from '../../../@types/RootState';
import { SegmentData } from '../../../components/FilterSection/services';

type Keys = GerenciadorPrecos.FilterKeys;

export type FiltersCacheState = Partial<
    Record<Keys, DataItem[]> & {
        segmentos: SegmentData[];
    }
>;

const initialState: FiltersCacheState = {};

const slice = createSlice({
    name: 'ipa.filtros.cache',
    initialState,
    reducers: {
        SET_FILTER_CACHE: (_, action: PayloadAction<FiltersCacheState>) => {
            return action.payload;
        },
        SET_FILTER_CACHE_BY_KEY: (
            state,
            action: PayloadAction<{ key: Keys; cache: DataItem[] }>,
        ) => {
            return {
                ...state,
                [action.payload.key]: action.payload.cache,
            };
        },
        UPDATE_FILTER_CACHE_BY_KEY: (
            state,
            { payload }: PayloadAction<{ key: Keys; cache: DataItem }>,
        ) => {
            return {
                ...state,
                [payload.key]: [...(state[payload.key] ?? []), payload.cache],
            };
        },
        RESET_FILTER_CACHE: () => initialState,
        RESET_FILTERS_CACHE_BY_KEY: (
            state,
            action: PayloadAction<{ key: Keys }>,
        ) => {
            return {
                ...state,
                [action.payload.key]: [],
            };
        },
    },
});

const {
    SET_FILTER_CACHE,
    RESET_FILTER_CACHE,
    SET_FILTER_CACHE_BY_KEY,
    UPDATE_FILTER_CACHE_BY_KEY,
    RESET_FILTERS_CACHE_BY_KEY,
} = slice.actions;

const selectorFiltersCache = (state: RootState) => {
    return state.gerenciadorPrecosReducer.filters.cache;
};

export {
    RESET_FILTERS_CACHE_BY_KEY,
    RESET_FILTER_CACHE,
    SET_FILTER_CACHE,
    SET_FILTER_CACHE_BY_KEY,
    UPDATE_FILTER_CACHE_BY_KEY,
    initialState,
    selectorFiltersCache,
};

export default slice.reducer;
