import { useContext } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getCompetitividadePorLoja } from '../services';
// @ts-ignore
import { FiltrosContext } from '../Pages/CompetitividadeGeral/index.tsx';
import { format } from 'date-fns';

export const useCompetitividadeMediaLoja = () => {
    const filtros = useContext(FiltrosContext);

    const listaCompetitividadeMediaLoja = async () => {
        const params = {
            filters: [
                {
                    filter: 'STOREID',
                    values: filtros?.lojasSelecionadas || null,
                },
                {
                    filter: 'PRODUCTID',
                    values: filtros?.produtosSelecionados || null,
                },
                {
                    filter: 'STARTDATE',
                    values: filtros?.periodoSelecionado?.length
                        ? [format(filtros.periodoSelecionado[0], 'yyyy-MM-dd')]
                        : null,
                },
                {
                    filter: 'ENDDATE',
                    values: filtros?.periodoSelecionado?.length
                        ? [format(filtros.periodoSelecionado[1], 'yyyy-MM-dd')]
                        : null,
                },
                {
                    filter: 'STORETYPE',
                    values: filtros?.tipoLojasSelecionadas || null,
                },
                {
                    filter: 'CATEGORYLEVEL1',
                    values: filtros?.categoriaNivel1Selecionadas || null,
                },
                {
                    filter: 'CATEGORYLEVEL2',
                    values: filtros?.categoriaNivel2Selecionadas || null,
                },
                {
                    filter: 'COMPETITORSTOREID',
                    values: filtros?.competitividadeSelecionada || null,
                },
            ],
        };
        const resp = await getCompetitividadePorLoja(params);
        if (resp.length) {
            const storeName = Object.keys(resp[0])[0]?.split('.');
            const avg_average_competitivity_retail = Object.keys(
                resp[0]
            )[1]?.split('.');
            const count = Object.keys(resp[0])[2]?.split('.');
            return resp.map((item: { [x: string]: any }) => {
                const words = item[Object.keys(item)[0]]
                    .toLowerCase()
                    .split(' ');
                for (let i = 0; i < words.length; i++) {
                    words[i] =
                        words[i].charAt(0).toUpperCase() + words[i].slice(1);
                }
                return {
                    [storeName[1]]: words.join(' '),
                    [avg_average_competitivity_retail[1]]: parseFloat(
                        // @ts-ignore
                        parseFloat(item[Object.keys(item)[1]]) * 100
                    )
                        .toFixed(2)
                        .replace('.', ','),
                    [count[1]]: item[Object.keys(item)[2]]?.replace(
                        /\B(?=(\d{3})+(?!\d))/g,
                        '.'
                    ),
                    isAboveAverage:
                        parseFloat(
                            // @ts-ignore
                            parseFloat(item[Object.keys(item)[1]]) * 100
                        ) > 105,
                };
            });
        }
        return [];
    };

    const { isLoading, isError, data, error } = useQuery(
        ['competitividadeLoja', filtros],
        listaCompetitividadeMediaLoja,
        {
            retry: false,
            cacheTime: 6 * 180 * 1000,
            staleTime: 6 * 180 * 1000,
        }
    );

    return {
        isLoading,
        isError,
        data,
        error,
    };
};
