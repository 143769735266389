import React from 'react';
import {
    Icon,
    IconProps,
} from '../../../../RevisaoPrecos/Components/QuickActionFilter';

export type CardIconProps = IconProps;

export const CardIcon = ({ className = '', ...props }: CardIconProps) => (
    <Icon className={['info-card__icon', className].join(' ')} {...props} />
);
