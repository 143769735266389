import { trackPromise } from 'react-promise-tracker';
import api from '../../../../utils/API';

export const getCampaign = (id) => trackPromise(
        api({
            url: `${process.env.REACT_APP_IPA_URL}/campaign/campaigns/${id}`,
            method: 'GET',
            expectToken: true,
            params: {
                clientId: JSON.parse(localStorage.getItem('cliente'))?.codigo,
            },
        }).then((resp) => resp),
    );
