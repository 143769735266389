import React from 'react';
import CurrencyFormat from 'react-currency-format'
import ArrowUpwardRoundedIcon from '@material-ui/icons/ArrowUpwardRounded';
import ArrowDownwardRoundedIcon from '@material-ui/icons/ArrowDownwardRounded';

const ValueCard = ({ title, value, currentValue, color, type }) => (
    <div className={`simulacao-value-card ${color}`}>
        <span className="simulacao-value-card-title">{title}</span>
        <h5 className="simulacao-value-card-value">
            {value ? (
                <CurrencyFormat
                    fixedDecimalScale={!!type}
                    decimalScale={type ? 2 : 0}
                    value={value}
                    displayType="text"
                    prefix={type === 'value' ? 'R$' : ''}
                    suffix={type && type !== 'value' ? '%' : ''}
                    decimalSeparator=","
                    thousandSeparator="."
                />
            ) : (
                '--'
            )}
            {color === 'positive' ? (
                <ArrowUpwardRoundedIcon className={color} style={{ fontSize: 32 }} />
            ) : null}
            {color === 'negative' ? (
                <ArrowDownwardRoundedIcon className={color} style={{ fontSize: 32 }} />
            ) : null}
        </h5>
        {type !== 'variation' ? (
            <>
                <hr />
                <p>
                    {currentValue ? (
                        <CurrencyFormat
                            className="simulacao-value-card-new-value"
                            fixedDecimalScale={!!type}
                            decimalScale={type ? 2 : 0}
                            value={currentValue}
                            displayType="text"
                            prefix={type === 'value' ? 'R$' : ''}
                            suffix={type === 'percent' ? '%' : ''}
                            decimalSeparator=","
                            thousandSeparator="."
                        />
                    ) : (
                        '--'
                    )}
                </p>
            </>
        ) : null}
    </div>
);

export default ValueCard;
