import { useQuery } from '@tanstack/react-query';
import _ from 'lodash';
import React from 'react';
import { DataItem } from '../../../@types/DataItem';
import { checkIfIsLoading } from '../../../utils';
import { InnerFilter } from '../../FilterSection';
import { SegmentsPlaceholder } from '../../NewFilterBox/Components';
import { CheckPillProps } from '../CheckPill';

export type AsyncCheckPillProps = {
    data?: DataItem[];
    value?: string[];
    placeholder?: string;
    dataKey: string;
    queryKey?: string[];
    queryFn?: (query: string) => Promise<DataItem[]>;
    onSearch?: (query: string) => void;
    onSelect?: (value: string[], item: DataItem) => void;
    onClean?: () => void;
    level?: number;
    selectAll?: boolean;
};

export const AsyncCheckPill = ({
    value = [],
    dataKey = '',
    queryKey = [],
    level,
    placeholder,
    selectAll,
    queryFn,
    onSearch,
    onSelect,
    onClean,
    ...props
}: AsyncCheckPillProps) => {
    const [search, setSearch] = React.useState('');

    const [data, setData] = React.useState<DataItem[]>([]);

    const [cache, setCache] = React.useState<DataItem[]>([]);

    const timer = React.useRef<NodeJS.Timeout>();

    const handleSearch = (query: string) => {
        clearTimeout(timer.current);
        const newTimer = setTimeout(() => {
            setSearch(query);
            onSearch?.(query);
        }, 500);
        timer.current = newTimer;
    };

    const handleClose = () => {
        setSearch('');
        onSearch?.('');
    };

    const handleSuccess = (data: DataItem[]) => {
        setData(_.uniqBy([...cache, ...data], 'value'));
    };

    const handleSelect = (value: string[], item: DataItem) => {
        onSelect?.(value, item);

        const updatedList = data.filter((dataItem) => {
            return value.includes(dataItem.value);
        });

        setCache(updatedList);
    };

    const handleClean = () => {
        setCache([]);
        handleSelect([], {} as DataItem);
        onClean?.();
    };

    const handleSelectAll = () => {
        if (data.length === value.length) handleClean();
        else {
            handleSelect(
                data.map((item) => item.value),
                {} as DataItem,
            );
        }
    };

    const { fetchStatus } = useQuery({
        queryKey: [search, dataKey, ...queryKey],
        initialData: [],
        retry: false,
        keepPreviousData: true,
        enabled: !!queryFn,
        queryFn: () => queryFn?.(search),
        onSuccess: handleSuccess,
    });

    return (
        <InnerFilter
            data={data as CheckPillProps['data']}
            value={value}
            isLoading={checkIfIsLoading(fetchStatus)}
            onSearch={handleSearch}
            onSelect={(value, item) => {
                handleSelect?.(value, item as DataItem);
            }}
            onClose={handleClose}
            onClean={handleClean}
            placeholder={
                (level ? (
                    <SegmentsPlaceholder
                        level={level as number}
                        placeholder={placeholder as string}
                    />
                ) : (
                    placeholder
                )) as string
            }
            selectAll={
                selectAll
                    ? {
                          onSelectAll: handleSelectAll,
                      }
                    : undefined
            }
            {...props}
        />
    );
};
