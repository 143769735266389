import React from 'react';
import { Modal } from 'rsuite';

const HowToUseModuleReportModal = ({ show, onHide, dataVideo}) => (
    <Modal
        size="lg"
        className="how-to-use-module-report-modal"
        show={show}
        onHide={onHide}
    >
        <Modal.Header />
        {dataVideo.map((item) => (
            item.link
        ))}
    </Modal>
);

export default HowToUseModuleReportModal;
