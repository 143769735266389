import history from '../../../../utils/History';

export function roundValue(value: number): number {
    return Math.round(value);
}

export function parseValue(value: string): number {
    return parseFloat(value.replace('.', '').replace(',', '.'));
}

export function getFromLocalStorage<T = unknown>(key: string) {
    const data = localStorage.getItem(key);
    if (!data) return null;
    return JSON.parse(data) as T;
}

export const handleLogout = () => {
    localStorage.removeItem('autenticacao.token');
    localStorage.removeItem('cliente');
    localStorage.removeItem('usuario');
    history.push('/login');
};

export function maskCnpj(cnpj: string): string {
    const cleanedCnpj = cnpj.replace(/\D/g, '');
    if (cleanedCnpj.length !== 14) {
        throw new Error('Invalid CNPJ. CNPJ must contain 14 digits.');
    }
    const maskedCnpj = `${cleanedCnpj.substr(0, 2)}.${cleanedCnpj.substr(
        2,
        3,
    )}.${cleanedCnpj.substr(5, 3)}/${cleanedCnpj.substr(
        8,
        4,
    )}-${cleanedCnpj.substr(12, 2)}`;
    return maskedCnpj;
}
