import axios from 'axios';
import { trackPromise } from 'react-promise-tracker';
import qs from 'qs';
import { QueryCalculos } from './types';

import api from '../../utils/API';

let cancelTokenProduto;
export const getProduto = (query: string) => {
    if (typeof cancelTokenProduto !== typeof undefined) {
        cancelTokenProduto.cancel('Operation canceled due to new request.');
    }

    cancelTokenProduto = axios.CancelToken.source();

    return trackPromise(
        // @ts-ignore
        api({
            url: `${process.env.REACT_APP_IIPA_SCENARIOS_ENGINE_URL}/filters/products`,
            method: 'GET',
            // data: JSON.stringify(data),
            params: {
                query,
                size: 50,
            },
            cancelToken: cancelTokenProduto.token,
            expectToken: true,
        }).then((resp) => resp.data),
        'scenario-filter',
    );
};

export const getProdutoConcorrente = (data: any) => {
    if (typeof cancelTokenProduto !== typeof undefined) {
        cancelTokenProduto.cancel('Operation canceled due to new request.');
    }

    cancelTokenProduto = axios.CancelToken.source();

    return trackPromise(
        // @ts-ignore
        api({
            url: `${process.env.REACT_APP_IIPA_SCENARIOS_ENGINE_URL}/filters/competitor-products`,
            method: 'GET',
            params: {
                product: data.product,
                query: data.query,
                size: 50,
            },
            cancelToken: cancelTokenProduto.token,
            expectToken: true,
        }).then((resp) => resp.data),
        'scenario-filter',
    );
};

export const getLocationType = (query: string) => {
    if (typeof cancelTokenProduto !== typeof undefined) {
        cancelTokenProduto.cancel('Operation canceled due to new request.');
    }

    cancelTokenProduto = axios.CancelToken.source();

    return trackPromise(
        // @ts-ignore
        api({
            url: `${process.env.REACT_APP_IIPA_SCENARIOS_ENGINE_URL}/filters/location-types`,
            method: 'GET',
            params: {
                query,
                size: 50,
            },
            cancelToken: cancelTokenProduto.token,
            expectToken: true,
        }).then((resp) => resp.data),
        'scenario-filter',
    );
};

export const getLocationUF = (query: string) => {
    if (typeof cancelTokenProduto !== typeof undefined) {
        cancelTokenProduto.cancel('Operation canceled due to new request.');
    }

    cancelTokenProduto = axios.CancelToken.source();

    return trackPromise(
        // @ts-ignore
        api({
            url: `${process.env.REACT_APP_IIPA_SCENARIOS_ENGINE_URL}/filters/states`,
            method: 'GET',
            params: {
                query,
                size: 50,
            },
            cancelToken: cancelTokenProduto.token,
            expectToken: true,
        }).then((resp) => resp.data),
        'scenario-filter',
    );
};

export const getChannel = (query: string) => trackPromise(
        // @ts-ignore
        api({
            url: `${process.env.REACT_APP_IIPA_SCENARIOS_ENGINE_URL}/filters/channels`,
            method: 'GET',
            params: {
                query,
                size: 50,
            },
            expectToken: true,
        }).then((resp) => resp.data),
        'scenario-filter',
    );

export const getSubChannel = (data: any) => trackPromise(
    // @ts-ignore
    api({
        url: `${process.env.REACT_APP_IIPA_SCENARIOS_ENGINE_URL}/filters/sub-channels`,
        method: 'GET',
        params: {
            channel: data.channel,
            query: data.query,
            size: 50,
        },
        expectToken: true,
    }).then((resp) => resp.data),
    'scenario-filter',
);

export const getResponsibles = (query: any) => trackPromise(
    // @ts-ignore
    api({
        url: `${process.env.REACT_APP_IIPA_SCENARIOS_ENGINE_URL}/filters/responsibles`,
        method: 'GET',
        params: {
            query,
            size: 50,
        },
        expectToken: true,
    }).then((resp) => resp.data),
    'scenario-filter',
);

export const getPaymentMethod = (query: string) => trackPromise(
    // @ts-ignore
    api({
        url: `${process.env.REACT_APP_IIPA_SCENARIOS_ENGINE_URL}/filters/payment-methods`,
        method: 'GET',
        params: {
            query,
            size: 50,
        },
        expectToken: true,
    }).then((resp) => resp.data),
    'scenario-filter',
);

export const getRegional = (query: string) => trackPromise(
    // @ts-ignore
    api({
        url: `${process.env.REACT_APP_IIPA_SCENARIOS_ENGINE_URL}/filters/regionals`,
        method: 'GET',
        params: {
            query,
            size: 50,
        },
        expectToken: true,
    }).then((resp) => resp.data),
    'scenario-filter',
);

export const getSaveScenarios = (data: string) => trackPromise(
    // @ts-ignore
    api({
        url: `${process.env.REACT_APP_IIPA_SCENARIOS_ENGINE_URL}/scenarios`,
        method: 'POST',
        data,
        expectToken: true,
    }).then((resp) => resp.data),
    'inputs-settings',
);

export const requestScenariosByID = (method: string, id: string, data: any) => trackPromise(
    // @ts-ignore
    api({
        url: `${process.env.REACT_APP_IIPA_SCENARIOS_ENGINE_URL}/scenarios/${id}`,
        method,
        data,
        expectToken: true,
    }).then((resp) => resp.data),
    'inputs-settings',
);

export const getInputsPriceSettings = (data: QueryCalculos) => trackPromise(
    // @ts-ignore
    api({
        url: `${process.env.REACT_APP_IIPA_SCENARIOS_ENGINE_URL}/scenarios/inputs`,
        method: 'POST',
        data,
        expectToken: true,
    }).then((resp) => resp.data),
    'inputs-settings',
);

export const getChartBigNumbers = (data: string) => trackPromise(
    // @ts-ignore
    api({
        url: `${process.env.REACT_APP_IIPA_SCENARIOS_ENGINE_URL}/scenarios/outputs`,
        method: 'POST',
        data,
        expectToken: true,
    }).then((resp) => resp.data),
    'inputs-settings',
);

export const getTableScenarios = (params: any, data: any) => trackPromise(
    // @ts-ignore
    api({
        url: `${process.env.REACT_APP_IIPA_SCENARIOS_ENGINE_URL}/scenarios/search`,
        method: 'POST',
        params: {
            page: params.page || 0,
            size: params.size || 10,
            sort: params.sort || [],
        },
        data,
        expectToken: true,
    }).then((resp) => resp.data),
    'table-scenario',
);

export const scenariosDownload = (method: string, params: any, data: any) => trackPromise(
    // @ts-ignore
    api({
        url: `${process.env.REACT_APP_IIPA_SCENARIOS_ENGINE_URL}/scenarios/download`,
        method,
        params: {
            scenarioId: params || '',
        },
        paramsSerializer(params) {
            return qs.stringify(params, { arrayFormat: 'repeat' });
        },
        data: data ? JSON.stringify(data) : '',
        expectToken: true,
        responseType: 'arraybuffer',
    }).then((res) => {
        if (res.status === 200) {
            return res.data;
        }
        return res;
    }).then((download) => {
        if (!download.status) {
            const blob = new Blob([download]);
            const hiddenElement = document.createElement('a');
            hiddenElement.href = window.URL.createObjectURL(blob);
            hiddenElement.target = '_blank';
            hiddenElement.download = 'cenario_dados.csv';
            hiddenElement.click();
        } else {
            return download;
        }
    }),
    'table-scenario',
);

export const getPlotPointsRevenueDemand = (data: any) => trackPromise(
    // @ts-ignore
    api({
        url: `${process.env.REACT_APP_IIPA_SCENARIOS_ENGINE_URL}/plots/revenue-demand`,
        method: 'POST',
        // params,
        data,
        expectToken: true,
    }).then((resp) => resp.data),
    'inputs-settings',
);

export const getPlotPointsProfitRevenue = (data: any) => trackPromise(
    // @ts-ignore
    api({
        url: `${process.env.REACT_APP_IIPA_SCENARIOS_ENGINE_URL}/plots/profit-revenue`,
        method: 'POST',
        // params,
        data,
        expectToken: true,
    }).then((resp) => resp.data),
    'inputs-settings',
);

export const getPlotPointsCPI = (data: any) => trackPromise(
    // @ts-ignore
    api({
        url: `${process.env.REACT_APP_IIPA_SCENARIOS_ENGINE_URL}/plots/cpi`,
        method: 'POST',
        // params,
        data,
        expectToken: true,
    }).then((resp) => resp.data),
    'inputs-settings',
);

export const getProductCompetitorResource = (data: any) => trackPromise(
    // @ts-ignore
    api({
        url: `${process.env.REACT_APP_IIPA_SCENARIOS_ENGINE_URL}/reference/infopanel/product-competitor`,
        method: 'POST',
        data,
        expectToken: true,
    }).then((resp) => resp.data),
    'inputs-settings',
);

export const getCompareScenarios = (data: any) => trackPromise(
        // @ts-ignore
        api({
            url: `${process.env.REACT_APP_IIPA_SCENARIOS_ENGINE_URL}/scenarios`,
            method: 'GET',
            params: {
                scenarioId: data,
            },
            expectToken: true,
            paramsSerializer(params) {
                return qs.stringify(params, { arrayFormat: 'repeat' });
            },
        }).then((resp) => resp.data),
        'compare-scenario',
    );
