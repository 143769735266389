import React from 'react';
import { EngineWarning as OriginalEngineWarning } from '../../../../../components';
import { useDataUltimaIntegracao } from '../../hooks';

const EngineWarning = () => {
    const { data } = useDataUltimaIntegracao();
    return <OriginalEngineWarning variant={data?.engineStatus} />;
};

export default React.memo(EngineWarning);
