import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    addSavedFilterState,
    setSavedFilterList,
    setSavedFilterRequestBody,
} from '../../../reducers/calculadoraPrevisaoDemanda/savedFiltros';
import {
    selectorCalculadoraPrevisaoDemanda,
} from '../../../reducers/calculadoraPrevisaoDemanda/calculadoraPrevisaoDemandaReducer';
import { useTableActions } from './useTableActions';
import * as service from '../services';

export const useSavedSelectProduct = () => {
    const [timer, setTimer] = useState(null);

    const { savedFiltros } = useSelector(
        selectorCalculadoraPrevisaoDemanda,
    );
    const { value, cache } = savedFiltros.produtos;

    const dispatch = useDispatch();

    const { tableHandlers } = useTableActions();

    const setList = (list) => {
        const newList = list.map((item) => ({
            value: item.key,
            label: `${item.key} - ${item.value}`,
        }));

        const cacheList = cache;
        const removeDuplicate = newList.filter(
            (i) => !value.includes(i.value),
        );
        const concatList = value.length
            ? cacheList.concat(removeDuplicate)
            : newList;

        dispatch(setSavedFilterList({ name: 'produtos', list: concatList }));
    };

    const handleRemoveProduct = (item) => {
        const removedCache = cache.filter(
            (i) => i.value !== item.value,
        );
        const newValue = value.filter(
            (i) => i !== item.value,
        );
        dispatch(addSavedFilterState({
            name: 'produtos',
            data: {
                cache: removedCache,
                value: newValue,
            },
        }));
    };

    const handleAddProduct = (value: string[], item: any) => {
        dispatch(addSavedFilterState({
            name: 'produtos',
            data: {
                cache: [...cache, item],
                value,
            },
        }));
    };

    const handleSelectProduct = (value: string[], item: any) => {
        const hasValue = savedFiltros.produtos.value.includes(item.value);

        dispatch(setSavedFilterRequestBody({
            name: 'query',
            data: {
                ...savedFiltros.savedFilterRequestBody?.query,
                product_id: value,
            },
        }));

        if (hasValue) {
            handleRemoveProduct(item);
        } else {
            handleAddProduct(value, item);
        }
    };

    const searchProdutos = async (query: string) => {
        const response = await service.getProduto(query);

        if (response) {
            setList(response);
        }
    };

    const onSearchProducts = (inputValue: string) => {
        clearTimeout(timer);

        const newTimer = setTimeout(() => {
            searchProdutos(inputValue);
        }, 500);

        setTimer(newTimer);
    };

    const handleClearProducts = () => {
        const query = {
            ...savedFiltros.savedFilterRequestBody?.query,
            product_id: [],
        };
        dispatch(setSavedFilterRequestBody({
            name: 'query',
            data: query,
        }));
        dispatch(addSavedFilterState({
            name: 'produtos',
            data: {
                cache: [],
                value: [],
            },
        }));

        const body = {
            ...savedFiltros.savedFilterRequestBody,
            query,
        };

        tableHandlers.getTableList({ page: 0 }, body);
    };

    return {
        savedProductHandlers: {
            onSearchProducts,
            handleSelectProduct,
            handleClearProducts,
        },
    };
};
