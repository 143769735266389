import classNames from 'classnames';
import React from 'react';
import styles from './FilterLabel.module.scss';

type FilterLabelProps = React.LabelHTMLAttributes<HTMLLabelElement>;
export const FilterLabel = ({
    children,
    className,
    ...props
}: FilterLabelProps) => {
    return (
        <label className={classNames(styles.label, className)} {...props}>
            {children}
        </label>
    );
};
