import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectorExtracaoDados } from '../../../../../reducers/extracaoDados';
import { setEcomValues } from '../../../../../reducers/extracaoDados/ecom';
import { getFontesEcom } from '../services';

export const useFontes = () => {
    const [fontes, setFontes] = useState({
        list: [],
        cache: [],
        value: [],
    });
    const [checkAllFontes, setCheckAllFontes] = useState(false);
    const [timer, setTimer] = useState(null);

    const dispatch = useDispatch();

    const { ecom } = useSelector(selectorExtracaoDados);

    const setList = (list) => {
        const newList = list.map((item) => ({
            value: item,
            label: item,
        }));

        const cacheList = fontes.cache;
        const removeDuplicate = newList.filter(
            (i) => !fontes.value.includes(i.value),
        );
        const concatList = fontes.value.length
            ? cacheList.concat(removeDuplicate)
            : newList;

        setFontes((prevState) => ({
            ...prevState,
            list: concatList,
        }));
    };

    const handleAddFontes = (item) => {
        const hasValue = fontes.value.includes(item.value);
        if (!hasValue) {
            const newList = fontes.value;
            const { cache } = fontes;
            const newValue = newList.concat(item.value);
            const newCache = cache.concat(item);
            setFontes((prevState) => ({
                ...prevState,
                value: newValue,
                cache: newCache,
            }));
        }
    };

    const addAllFontes = () => {
        const newList = fontes.list;
        const newValue = newList.map((item) => item.value);
        setFontes((prevState) => ({
            ...prevState,
            value: newValue,
            cache: fontes.list,
        }));
    };

    const handleRemoveFontes = (item) => {
        const hasValue = fontes.value.includes(item.value);
        if (hasValue) {
            const removedCache = fontes.cache.filter(
                (i) => i.value !== item.value,
            );
            const newValue = fontes.value.filter((i) => i !== item.value);
            setFontes((prevState) => ({
                ...prevState,
                value: newValue,
                cache: removedCache,
            }));
        }
    };

    const removeAllFontes = () => {
        setFontes((prevState) => ({
            ...prevState,
            value: [],
            cache: [],
        }));
    };

    const handleCleanFontes = () => {
        setFontes((prevState) => ({ ...prevState, value: [], cache: [] }));
    };

    const searchFontes = (data) => {
        getFontesEcom(data).then((response) => {
            if (response) {
                setList(response);
            }
        });
    };

    const onSearchFontes = (inputValue) => {
        let filtros;

        if (
            inputValue &&
            typeof inputValue === 'string' &&
            inputValue.match(/^\d+ \d+[\d+ ]*$/)
        ) {
            const concatValue = fontes.value.concat(inputValue.split(' '));
            const newValue = [...new Set(concatValue)];
            filtros = { query: '', identificadores: newValue };

            setFontes((prevState) => ({
                ...prevState,
                value: newValue,
            }));
        } else {
            filtros = {
                query: typeof inputValue === 'string' ? inputValue : '',
            };
        }

        clearTimeout(timer);

        const newTimer = setTimeout(() => {
            searchFontes({
                fonte: filtros.query,
                codigoCliente: JSON.parse(localStorage.getItem('cliente'))
                    ?.codigo,
            });
        }, 500);

        setTimer(newTimer);
    };

    const handleSelectFontes = (item) => {
        const hasValue = fontes?.value?.includes(item.value);
        if (hasValue) {
            handleRemoveFontes(item, 'competidor');
        } else {
            handleAddFontes(item, 'competidor');
        }
    };

    const handleCheckAllFontes = (item, value) => {
        setCheckAllFontes(value);

        if (value) {
            addAllFontes();
        } else {
            removeAllFontes();
        }
    };

    const handleSelectEcomFonte = (value, item) => {
        const hasValue = fontes?.value?.includes(item.value);

        const obj = {
            name: 'ecomPayload',
            value: {
                ...ecom.ecomPayload,
                fonte: value,
            },
        };

        if (hasValue) {
            handleRemoveFontes(item);
            dispatch(setEcomValues(obj));
        } else {
            handleAddFontes(item);
            dispatch(setEcomValues(obj));
        }
    };

    return {
        fontes,
        checkAllFontes,
        onSearchFontes,
        handleAddFontes,
        handleRemoveFontes,
        handleCleanFontes,
        handleSelectFontes,
        handleCheckAllFontes,
        handleSelectEcomFonte,
    };
};
