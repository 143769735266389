import React from 'react';
import { Icon } from '../../../../../RevisaoPrecos/Components/QuickActionFilter';
import { DATAPOINT_PROPS } from '../../../../@types';

export type ProFeatureProps = React.HtmlHTMLAttributes<HTMLDivElement> &
    Pick<DATAPOINT_PROPS, 'gtin' | 'description'>;

export const ProFeature = ({ className = '', ...props }: ProFeatureProps) => (
    <div
        className={[
            'gerenciador-table__cell__pro',
            'font-size-50-semibold',
            'flex-align-center',
            className,
        ].join(' ')}
        {...props}
    >
        <Icon icon="premium" />
        <p>Calcule no Pro</p>
    </div>
);
