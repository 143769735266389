/* eslint-disable no-restricted-syntax */
import { useQuery } from '@tanstack/react-query';
import { useContext, useEffect, useReducer, useState } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { useLocation } from 'react-router-dom';
import {
    getFamiliaProdutos,
    getLojasRevisao,
} from '../pages/IPA/RevisaoPrecos/services';
import { getSavedFilters } from '../pages/IPA/RevisaoPrecos/services_ts';
import {
    getItensSegmentoProduto,
    getSegmentos,
    getSegmentosClasses,
    getSegmentosSensibilities,
    searchFiltroProdutos,
} from '../services/ProdutoService';
import { GlobalContext } from '../utils/Helpers';

type DATA_PROPS = Record<'label' | 'value', string> & Record<string, unknown>;

type DATA_KEYS =
    | 'produtosSelecionados'
    | 'familiaSelecionada'
    | 'lojasSelecionadas'
    | 'tipoPrecosSelecionados'
    | 'segmentacaoSelecionada'
    | 'categoryLevel1'
    | 'categoryLevel2'
    | 'categoryLevel3'
    | 'categoryLevel4'
    | 'categoryLevel5'
    | 'categoryLevel6'
    | 'categoryLevel7'
    | 'filtrosSalvos';

type State = Record<DATA_KEYS, DATA_PROPS[]>;

type Action = {
    type: 'ADD' | 'SET';
    dataKey: DATA_KEYS;
    data?: DATA_PROPS | DATA_PROPS[];
};

const reducer = (state: State, { type, dataKey, data }: Action): State => {
    if (!data) return state;

    const currentData = state[dataKey];
    const dataArray = Array.isArray(data) ? data : [data];

    const uniqueItemsMap = new Map<string, DATA_PROPS>();
    currentData.forEach((item) => {
        uniqueItemsMap.set(item.value, item);
    });

    dataArray.forEach((item) => {
        uniqueItemsMap.set(item.value, item);
    });

    const uniqueItems = Array.from(uniqueItemsMap.values());

    switch (type) {
        case 'ADD':
            return {
                ...state,
                [dataKey]: uniqueItems,
            };
        case 'SET':
            return { ...state, [dataKey]: dataArray };
        default:
            return state;
    }
};

export const useFiltros = () => {
    const location = useLocation();
    const filtros = useContext(GlobalContext);
    const [searchProduto, setSearchProduto] = useState('');
    const [searchFamilia, setSearchFamilia] = useState('');
    const [searchStores, setSearchStores] = useState('');
    const [searchSavedFilters, setSearchSavedFilters] = useState('');
    const [data, dispatch] = useReducer(reducer, {
        produtosSelecionados: [],
        familiaSelecionada: [],
        lojasSelecionadas: [],
        tipoPrecosSelecionados: [],
        segmentacaoSelecionada: [],
        categoryLevel1: [],
        categoryLevel2: [],
        categoryLevel3: [],
        categoryLevel4: [],
        categoryLevel5: [],
        categoryLevel6: [],
        categoryLevel7: [],
        filtrosSalvos: [],
    });

    async function listaTiposPreco() {
        return (
            [
                { value: 'AUTOMATIC', label: 'Auto' },
                { value: 'MANUAL', label: 'Manual' },
                { value: 'ERP', label: 'ERP' },
                { value: 'EDITED', label: 'Editado' },
            ] || []
        );
    }

    const listaSegmentacao = async () => {
        const resp = await getSegmentosSensibilities();
        const respAbcClass = await getSegmentosClasses();
        return [
            {
                label: 'Super Sensível',
                value: {
                    type: 'infoPriceSensibility',
                    value: 'SUPER_SENSITIVE',
                },
                role: 'Sensibilidade InfoPrice',
            },
            {
                label: 'Sensível',
                value: {
                    type: 'infoPriceSensibility',
                    value: 'SENSITIVE',
                },
                role: 'Sensibilidade InfoPrice',
            },
            {
                label: 'Não Sensível',
                value: {
                    type: 'infoPriceSensibility',
                    value: 'NOT_SENSITIVE',
                },
                role: 'Sensibilidade InfoPrice',
            },
            {
                label: 'Local A',
                value: {
                    type: 'infoPriceAbcClass',
                    value: 'A',
                },
                role: 'Curva ABC Local',
            },
            {
                label: 'Local B',
                value: {
                    type: 'infoPriceAbcClass',
                    value: 'B',
                },
                role: 'Curva ABC Local',
            },
            {
                label: 'Local C',
                value: {
                    type: 'infoPriceAbcClass',
                    value: 'C',
                },
                role: 'Curva ABC Local',
            },
            {
                label: 'Local D',
                value: {
                    type: 'infoPriceAbcClass',
                    value: 'D',
                },
                role: 'Curva ABC Local',
            },
            {
                label: 'Global A',
                value: {
                    type: 'infoPriceGlobalAbcClass',
                    value: 'A',
                },
                role: 'Curva ABC Global',
            },
            {
                label: 'Global B',
                value: {
                    type: 'infoPriceGlobalAbcClass',
                    value: 'B',
                },
                role: 'Curva ABC Global',
            },
            {
                label: 'Global C',
                value: {
                    type: 'infoPriceGlobalAbcClass',
                    value: 'C',
                },
                role: 'Curva ABC Global',
            },
            {
                label: 'Global D',
                value: {
                    type: 'infoPriceGlobalAbcClass',
                    value: 'D',
                },
                role: 'Curva ABC Global',
            },
            ...(resp.length
                ? resp.map((item: { sensibility_type: string }) => ({
                      label: item.sensibility_type,
                      value: {
                          type: 'sensibilityType',
                          value: item.sensibility_type,
                      },
                      role: 'Exclusiva',
                  }))
                : []),
            ...(respAbcClass.length
                ? respAbcClass.map((item: { abc_class: string }) => ({
                      label: item.abc_class,
                      value: { type: 'abcClass', value: item.abc_class },
                      role: 'Curva',
                  }))
                : []),
        ];
    };

    const setProdutosList = (list: any[]) => {
        const newList = list.map((item) => ({
            label: `${item.product_id} - ${item.description}`,
            value: item.product_id,
            gtin: item.gtin,
            product_id: item.product_id,
            description: item.description,
        }));

        const cacheList = filtros?.produtosSelecionados?.cache;
        const removeDuplicate = newList.filter(
            (i) => !filtros?.produtosSelecionados?.value.includes(i.value),
        );

        const concatList = filtros?.produtosSelecionados?.value.length
            ? cacheList.concat(removeDuplicate)
            : newList;

        return concatList;
    };

    const setLojasList = (list: any[]) => {
        const newList = list.map(({ store_id, store }) => ({
            label: `${store_id} - ${store}`,
            value: store_id,
            store_id,
        }));

        const cacheList = filtros?.lojasSelecionadas?.cache;
        const removeDuplicate = newList.filter(
            (i) => !filtros?.lojasSelecionadas?.value.includes(i.value),
        );

        const concatList = filtros?.lojasSelecionadas?.value.length
            ? cacheList.concat(removeDuplicate)
            : newList;

        return concatList;
    };

    const setFamiliasList = (list: any[]) => {
        const newList = list.map((item) => ({
            label: item,
            value: item,
        }));

        const cacheList = filtros?.familiaSelecionada?.cache;
        const removeDuplicate = newList.filter(
            (i) => !filtros?.familiaSelecionada?.value.includes(i.value),
        );
        const concatList = filtros?.familiaSelecionada?.value.length
            ? cacheList.concat(removeDuplicate)
            : newList;

        return concatList;
    };

    const setSegmentosList = (list: any, item: any) => {
        const newList = list.map((item: any) => ({
            label: item,
            value: item,
        }));

        const cacheList = filtros[item]?.cache;
        const removeDuplicate = newList.filter(
            (i: { value: any }) => !filtros[item]?.value.includes(i.value),
        );
        const concatList = filtros[item]?.value.length
            ? cacheList.concat(removeDuplicate)
            : newList;

        dispatch({ type: 'SET', dataKey: item, data: concatList });
    };

    const searchProdutos = async (query: string): Promise<DATA_PROPS[]> => {
        const response = await searchFiltroProdutos({ query });
        if (response?.data) {
            return setProdutosList(response.data);
        }
        return [];
    };

    const searchLojas = async (query: string) => {
        const response = await getLojasRevisao(query);
        if (response) {
            return setLojasList(response);
        }
        return [];
    };

    const searchFamilias = async (query: string) => {
        const response = await getFamiliaProdutos(query);
        if (response?.product_family_ids) {
            return setFamiliasList(response.product_family_ids);
        }

        return [];
    };

    const listaSegmentos = async () => {
        const resp = await getSegmentos();
        return resp.sort((a, b) => a.level - b.level);
    };

    const searchItensSegmento = async (nivel: string, query: string) => {
        const response = await getItensSegmentoProduto(nivel, { query });
        if (response) setSegmentosList(response, nivel);
    };

    const listItensSegmento = async (segmentos: any[]) => {
        const niveis = segmentos?.map((item: { field: any }) => item.field);
        if (niveis?.length) {
            niveis.forEach(async (nivel: DATA_KEYS) => {
                const resp = await getItensSegmentoProduto(nivel, {
                    query: '',
                });

                const data = resp?.map((item: string) => ({
                    label: item,
                    value: item,
                }));

                dispatch({
                    type: 'SET',
                    dataKey: nivel,
                    data,
                });
            });
        }
    };

    const changeQuery = (value: string, item: string) => {
        switch (item) {
            case 'produtosSelecionados':
                setSearchProduto(value);
                break;
            case 'familiaSelecionada':
                setSearchFamilia(value);
                break;
            case 'lojasSelecionadas':
                setSearchStores(value);
                break;
            case 'filtrosSalvosSelecionados':
                setSearchSavedFilters(value);
                break;
            default:
                searchItensSegmento(item, value);
                break;
        }
    };

    async function listFiltrosSalvos() {
        const data = await getSavedFilters({
            search: searchSavedFilters,
        });
        return data.map((filter) => ({
            label: filter.name,
            value: filter.id,
            ...filter,
        }));
    }

    const { isLoading: loadingFiltrosSalvos, refetch: refetchSavedFilters } =
        useQuery({
            queryKey: ['get-filtros', searchSavedFilters],
            queryFn: () => trackPromise(listFiltrosSalvos(), 'saved-filter'),
            retry: false,
            staleTime: 1000 * 60 * 15, // 15 min
            onSuccess(data) {
                dispatch({
                    type: 'SET',
                    dataKey: 'filtrosSalvos',
                    data,
                });
            },
        });

    const { isLoading: loadingProdutos } = useQuery({
        queryKey: [
            'filtroProdutosRevisao',
            searchProduto,
            ...((location.pathname.includes('ipa/regras-preco/') &&
                filtros?.produtosSelecionados?.value) ||
                []),
        ],
        retry: false,
        queryFn: () => searchProdutos(searchProduto),
        onSuccess(data) {
            dispatch({
                type: 'SET',
                dataKey: 'produtosSelecionados',
                data,
            });
        },
    });

    const { isLoading: loadingFamilias } = useQuery({
        queryKey: ['filtroFamiliaRevisao', searchFamilia],
        queryFn: () => searchFamilias(searchFamilia),
        retry: false,
        onSuccess(data) {
            dispatch({
                type: 'SET',
                dataKey: 'familiaSelecionada',
                data,
            });
        },
    });

    const {
        isLoading: loadingLojas,
        isError,
        error,
    } = useQuery({
        queryKey: ['filtroLojasRevisao', searchStores],
        queryFn: () => searchLojas(searchStores),
        retry: false,
        onSuccess(data) {
            dispatch({
                type: 'SET',
                dataKey: 'lojasSelecionadas',
                data,
            });
        },
    });

    const { isLoading: loadingTiposPreco } = useQuery({
        queryKey: ['filtroTiposPrecoRevisao'],
        queryFn: listaTiposPreco,
        retry: false,
        onSuccess(data) {
            dispatch({
                type: 'SET',
                dataKey: 'tipoPrecosSelecionados',
                data,
            });
        },
    });

    useQuery({
        queryKey: ['filtroSegmentacaoRevisao'],
        queryFn: listaSegmentacao,
        retry: false,
        onSuccess(data) {
            dispatch({
                type: 'SET',
                dataKey: 'segmentacaoSelecionada',
                data,
            });
        },
    });

    const { data: segmentos } = useQuery({
        queryKey: ['filtroSegmentosRevisao'],
        queryFn: listaSegmentos,
        retry: false,
    });

    useEffect(() => {
        if (segmentos?.length) {
            listItensSegmento(segmentos);
        }
    }, [segmentos]);

    // ======================== HANDLERS ========================
    const handleAddFiltroData = (
        filtro: DATA_PROPS | DATA_PROPS[],
        filterOption: keyof State,
    ) => {
        dispatch({ type: 'ADD', dataKey: filterOption, data: filtro });
    };

    return {
        data,
        loadingLojas,
        loadingProdutos,
        loadingFamilias,
        loadingTiposPreco,
        loadingFiltrosSalvos,
        error,
        isError,
        segmentos,
        changeQuery,
        handleAddFiltroData,
        refetchSavedFilters,
    };
};
