import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../@types/RootState';
import {
    SET_ACTIVE_PAGE,
    SET_DISPLAY_LENGTH,
} from '../../../../../reducers/gerenciadorPrecos/pagination';
import { selectorTotalElements } from '../../../../../reducers/gerenciadorPrecos/totalElements';

const LENGTH_MENU = [
    {
        value: 25,
        label: 25,
    },
    {
        value: 50,
        label: 50,
    },
    {
        value: 100,
        label: 100,
    },
];

const COMPENSATION_VALUE = 1;

const useGerenciadorPagination = () => {
    const dispatch = useDispatch();

    const total = useSelector(selectorTotalElements);

    const activePage = useSelector((state: RootState) => {
        return state.gerenciadorPrecosReducer.pagination.activePage;
    });

    const displayLength = useSelector((state: RootState) => {
        return state.gerenciadorPrecosReducer.pagination.displayLength;
    });

    const onChangePage = useCallback(
        (page: number) => {
            dispatch(SET_ACTIVE_PAGE(page - COMPENSATION_VALUE));
        },
        [dispatch],
    );

    const onChangeLength = useCallback(
        (size: number) => {
            dispatch(SET_DISPLAY_LENGTH(size));
        },
        [dispatch],
    );

    const memoActivePage = useMemo(
        () => activePage + COMPENSATION_VALUE,
        [activePage],
    );

    const lastValidIndex = useMemo(
        () => Math.ceil(total / displayLength),
        [total, displayLength],
    );

    const isOnInvalidLastIndex = useMemo(() => {
        return activePage + COMPENSATION_VALUE > lastValidIndex;
    }, [activePage, lastValidIndex]);

    useEffect(() => {
        if (total && isOnInvalidLastIndex) {
            onChangePage(lastValidIndex);
        }
    }, [total, isOnInvalidLastIndex, onChangePage, lastValidIndex]);

    return {
        lengthMenu: LENGTH_MENU,
        total,
        activePage: memoActivePage,
        displayLength,
        onChangePage,
        onChangeLength,
    };
};

export default useGerenciadorPagination;
