import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../@types/RootState';

export type PrecoAtacadoSelectedDatapointsState = {
    selectedAll: boolean;
    selectedIds: string[];
    excludedIds: string[];
};

export const initialState: PrecoAtacadoSelectedDatapointsState = {
    selectedAll: false,
    selectedIds: [],
    excludedIds: [],
};

type PayloadUpdateByList = PayloadAction<{
    updateList?: 'selectedIds' | 'excludedIds';
    data: string[];
}>;

const slice = createSlice({
    name: 'ipa/precoAtacado/selectedDatapoints',
    initialState,
    reducers: {
        SET_PRECO_ATACADO_SELECTED_DATAPOINTS: (
            _state,
            { payload }: PayloadAction<PrecoAtacadoSelectedDatapointsState>,
        ) => payload,
        SET_PRECO_ATACADO_SELECTED_DATAPOINTS_BY_LIST: (
            state,
            { payload }: PayloadUpdateByList,
        ) => {
            const { updateList = 'selectedIds', data } = payload;
            state[updateList] = data;
        },
        SET_PRECO_ATACADO_SELECT_ALL: (
            state,
            { payload }: PayloadAction<boolean>,
        ) => {
            state.selectedAll = payload;
        },
        UPDATE_PRECO_ATACADO_SELECTED_DATAPOINTS: (
            state,
            {
                payload,
            }: PayloadAction<Partial<PrecoAtacadoSelectedDatapointsState>>,
        ) => {
            return {
                ...state,
                ...payload,
            };
        },
        RESET_PRECO_ATACADO_SELECTED_DATAPOINTS: () => initialState,
        ADD_PRECO_ATACADO_SELECTED_DATAPOINT_BY_LIST: (
            state,
            {
                payload,
            }: {
                payload: {
                    updateList?: 'selectedIds' | 'excludedIds';
                    data: string;
                };
            },
        ) => {
            const { updateList = 'selectedIds', data } = payload;
            state[updateList] = Array.from(
                new Set([...state[updateList], data]),
            );
        },
        REMOVE_PRECO_ATACADO_SELECTED_DATAPOINT_BY_LIST: (
            state,
            {
                payload,
            }: {
                payload: {
                    updateList?: 'selectedIds' | 'excludedIds';
                    data: string;
                };
            },
        ) => {
            const { updateList = 'selectedIds', data } = payload;
            state[updateList] = state[updateList].filter((id) => id !== data);
        },
    },
});

export const {
    RESET_PRECO_ATACADO_SELECTED_DATAPOINTS,
    SET_PRECO_ATACADO_SELECTED_DATAPOINTS,
    UPDATE_PRECO_ATACADO_SELECTED_DATAPOINTS,
    SET_PRECO_ATACADO_SELECTED_DATAPOINTS_BY_LIST,
    ADD_PRECO_ATACADO_SELECTED_DATAPOINT_BY_LIST,
    REMOVE_PRECO_ATACADO_SELECTED_DATAPOINT_BY_LIST,
    SET_PRECO_ATACADO_SELECT_ALL,
} = slice.actions;

export default slice.reducer;

export const selectorPrecoAtacadoSelectedDatapoints = (state: RootState) => {
    return state.precoAtacadoReducer.selectedDatapoints;
};
