import Immutable from 'seamless-immutable';

import {
    ADD_FILTRO_HISTORICO,
    DELETE_FILTRO_HISTORICO,
    DELETE_FILTRO_REGIAO,
    UPDATE_FILTER_DATA_HISTORICO,
    UPDATE_FILTER_VALUE_HISTORICO
} from '../actions/actionsHistoricoPreco';

const historicoPrecoInitialState = Immutable({
    filtersType: [
        {
            icon: 'shopping-cart',
            title: 'LOJA',
            itens: [
                {
                    name: 'rede',
                    title: 'Rede'
                }
            ]
        },
        {
            icon: 'map-marker',
            title: 'LOCALIZAÇÃO',
            itens: [
                {
                    name: 'uf',
                    title: 'Região'
                }
            ]
        }
    ],
    filters: [
        {
            id: 'chain-filter',
            value: null,
            cleanable: true,
            searchable: true,
            data: [],
            name: 'rede',
            placeholder: 'Selecione uma Rede',
            locale: { noResultsText: 'Nenhum item encontrado' },
            onSelect: 'handleSelect',
            onClean: 'handleSelect'
        },
        {
            id: 'uf-filter',
            value: null,
            cleanable: true,
            searchable: true,
            data: [],
            name: 'uf',
            placeholder: 'Selecione uma UF',
            locale: { noResultsText: 'Nenhum item encontrado' },
            onSelect: 'handleSelect',
            onClean: 'handleSelect',
            child: {
                id: 'city-filter',
                value: null,
                cleanable: true,
                searchable: true,
                data: 'cidades',
                name: 'cidade',
                placeholder: 'Selecione uma Cidade',
                locale: { noResultsText: 'Nenhum item encontrado' },
                onSelect: 'handleSelect',
                onClean: 'handleSelect',
            }
        }
    ],
    selectedFilters: [],
    filtersData: {
        rede: {
            value: null,
            data: []
        },
        uf: {
            value: null,
            data: []
        },
        cidade: {
            value: null,
            data: []
        }
    }
})

function historicoPrecoDataReducer(state = historicoPrecoInitialState, action) {
    switch (action.type) {
        case ADD_FILTRO_HISTORICO:
            return state.merge({
                selectedFilters: [...state.selectedFilters, action.filtro]
            })
        case DELETE_FILTRO_HISTORICO:
            return state.merge({
                selectedFilters: state.selectedFilters.filter((item, index) => index !== action.index),
                filtersData: {
                    ...state.filtersData,
                    [action.name]: {
                        ...state.filtersData[action.name],
                        value: null
                    }
                }
            })
        case DELETE_FILTRO_REGIAO:
            return state.merge({
                selectedFilters: state.selectedFilters.filter((item, index) => index !== action.index),
                filtersData: {
                    ...state.filtersData,
                    cidade: {
                        ...state.filtersData.cidade,
                        value: null
                    },
                    uf: {
                        ...state.filtersData.uf,
                        value: null
                    }
                }
            })
        case UPDATE_FILTER_DATA_HISTORICO:
            return state.merge({
                ...state,
                filtersData: {
                    ...state.filtersData,
                    [action.name]: {
                        ...state.filtersData[action.name],
                        data: action.data
                    }
                }
            })
        case UPDATE_FILTER_VALUE_HISTORICO:
            return state.merge({
                ...state,
                filtersData: {
                    ...state.filtersData,
                    [action.name]: {
                        ...state.filtersData[action.name],
                        value: action.value
                    }
                }
            })

        default:
            return state
    }
}

export default historicoPrecoDataReducer;