import React, { ReactNode } from 'react';
import styles from './FormBox.module.scss';

export interface IFormBoxRoot {
    children: ReactNode;
    title?: string;
    className?: string;
}

export const FormBoxRoot = ({ children, title, className }: IFormBoxRoot) => (
    <div
        className={`${styles['box-container']} ${className}`}
    >
        {title ? <p className={styles['box-title']}>{title}</p> : null}
        <div className={styles['box-content']}>
            {children}
        </div>
    </div>
);
