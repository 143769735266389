import React from 'react';
import { Modal, Button } from 'rsuite';

const ModalConfirmation = ({
    show, onCancel, title, message, onConfirm, btnConfirmClass, buttonConfirm, buttonCancel,
}) => (

    <Modal backdrop="static" show={show} size="sm" onHide={onCancel}>
        <Modal.Header>
            <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {message}
        </Modal.Body>
        <Modal.Footer>
            <Button onClick={onConfirm} className={btnConfirmClass} appearance="primary">
                {buttonConfirm}
            </Button>
            <Button onClick={onCancel} appearance="subtle">
                {buttonCancel}
            </Button>
        </Modal.Footer>
    </Modal>
);

export default ModalConfirmation;
