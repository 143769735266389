import React from 'react';
import {
    Radio,
    RadioGroup,
} from 'rsuite';

export const RadioOptionColumns = ({ sort, sortColumn }) => (
    <RadioGroup
        defaultValue={sortColumn}
        className="radio-ordenation-table-revisao"
    >
        <Radio
            onChange={(value) => sort(value, 'asc')}
            value="productId"
            className={sortColumn === 'productId' ? 'selected' : ''}
        >
            Código do produto
        </Radio>
        <Radio
            onChange={(value) => sort(value, 'asc')}
            value="product_description"
            className={sortColumn === 'product_description' ? 'selected' : 'not-selected'}
        >
            Descrição do produto
        </Radio>
    </RadioGroup>
);
