export const initialState = {
    client: null,
    summaryData: null,
    requestsData: [],
    pagination: {
        page: 0,
        size: 50,
        totalElements: 0,
    },
    showModalPlan: false,
    itemData: {
        ids: [],
        status: false,
        type: false,
    },
    openStatusChangeModal: false,
    openBulkSelectionModal: false,
    statusChangeModalType: null,
    toggleIsActive: false,
    cepError: false,
    cepLoading: false,
};

export const onDemandReducer = (
    state,
    action,
) => {
    switch (action.type) {
        case 'SET_CLIENT':
            return {
                ...state,
                client: action.client,
            };
        case 'SET_SUMMARY_DATA':
            return {
                ...state,
                summaryData: action.summaryData,
            };
        case 'SET_REQUESTS_DATA':
            return {
                ...state,
                requestsData: action.requestsData,
            };
        case 'SET_PAGINATION':
            return {
                ...state,
                pagination: action.pagination,
            };
        case 'SET_SHOW_MODAL_PLAN':
            return {
                ...state,
                showModalPlan: action.showModalPlan,
            };
        case 'SET_CHECKED_ITEMS':
            return {
                ...state,
                checkedItems: action.checkedItems,
            };
        case 'SET_OPEN_STATUS_CHANGE_MODAL':
            return {
                ...state,
                openStatusChangeModal: action.openStatusChangeModal,
            };
        case 'SET_OPEN_BULK_SELECTION_MODAL':
            return {
                ...state,
                openBulkSelectionModal: action.openBulkSelectionModal,
            };
        case 'SET_STATUS_CHANGE_MODAL_TYPE':
            return {
                ...state,
                statusChangeModalType: action.statusChangeModalType,
            };
        case 'SET_ITEM_DATA':
            return {
                ...state,
                itemData: action.itemData,
            };
        case 'SET_TOGGLE_IS_ACTIVE':
            return {
                ...state,
                toggleIsActive: action.toggleIsActive,
            };
        case 'SET_CEP_ERROR':
            return {
                ...state,
                cepError: action.cepError,
            };
        case 'SET_CEP_LOADING':
            return {
                ...state,
                cepLoading: action.cepLoading,
            };

        default:
            return state;
    }
};
