import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Alert } from 'rsuite';
import {
    FiltersNameProps,
    addFilterState,
    clearFilters,
    setFilterList,
    selectFilterValue,
} from '../../../reducers/calculadoraPrevisaoDemanda/filtros';
import { selectorCalculadoraPrevisaoDemanda } from '../../../reducers/calculadoraPrevisaoDemanda/calculadoraPrevisaoDemandaReducer';
import * as service from '../services';
import { SelectResponseList, SelectProducts } from '../types';

export const useSelectProduct = () => {
    const [timer, setTimer] = useState(null);

    const dispatch = useDispatch();

    const { filtros } = useSelector(
        selectorCalculadoraPrevisaoDemanda,
    );

    const setList = (name: FiltersNameProps, list: SelectResponseList[]) => {
        const newList = list.map((item: SelectResponseList) => ({
            value: item.key,
            label: `${item.key} - ${item.value}`,
            description: item.value,
        }));

        const cacheList = filtros[name].cache;
        const removeDuplicate = newList.filter(
            (i) => !filtros[name].value.includes(i.value),
        );
        const concatList = filtros[name].value.length
            ? cacheList.concat(removeDuplicate)
            : newList;

        dispatch(setFilterList({ name, list: concatList }));
    };

    const handleSelectProduct = (value: string, item: SelectProducts) => {
        dispatch(selectFilterValue({ name: 'product_id', data: Number(value) }));
        dispatch(selectFilterValue({ name: 'product_description', data: item.description }));
        dispatch(addFilterState({
            name: 'produtos',
            data: {
                cache: [item],
                value: [value],
            },
        }));
    };

    const handleSelectCompetitorProduct = (value: string, item: SelectProducts) => {
        dispatch(selectFilterValue({ name: 'competitor_product_id', data: Number(value) }));
        dispatch(selectFilterValue({ name: 'competitor_product_description', data: item.description }));
        dispatch(addFilterState({
            name: 'produtoConcorrente',
            data: {
                cache: [item],
                value: [value],
            },
        }));
    };

    const searchProdutos = async (name: string, query: string) => {
        const productData = { product: filtros.produtos.value[0], query };
        const params = name === 'produtoConcorrente' ? productData : query;
        const requestType = {
            produtos: service.getProduto,
            produtoConcorrente: service.getProdutoConcorrente,
        };
        const response = await requestType[name](params);

        if (response) {
            setList(name, response);
        }
    };

    const onSearchProducts = (name: string, inputValue: string) => {
        if (name === 'produtoConcorrente' && !filtros.produtos.value.length) {
            Alert.warning('Selecione primeiro um produto referência', 5000);
            return;
        }
        clearTimeout(timer);

        const newTimer = setTimeout(() => {
            searchProdutos(name, inputValue);
        }, 500);

        setTimer(newTimer);
    };

    const handleClearProducts = () => {
        dispatch(selectFilterValue({ name: 'product_id', data: null }));
        dispatch(selectFilterValue({ name: 'product_description', data: '' }));
        dispatch(addFilterState({
            name: 'produtos',
            data: {
                cache: [],
                value: [],
            },
        }));
    };

    const handleClearCompetitorProducts = () => {
        dispatch(selectFilterValue({ name: 'competitor_product_id', data: null }));
        dispatch(selectFilterValue({ name: 'competitor_product_description', data: '' }));
        dispatch(addFilterState({
            name: 'produtoConcorrente',
            data: {
                cache: [],
                value: [],
            },
        }));
    };

    return {
        productHandlers: {
            onSearchProducts,
            handleSelectProduct,
            handleSelectCompetitorProduct,
            handleClearProducts,
            handleClearCompetitorProducts,
        },
    };
};
