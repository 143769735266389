import { format, parseISO } from 'date-fns';

export const downloadFile = (data, fileName) => {
    const blob = new Blob([data]);
    const hiddenElement = document.createElement('a');
    hiddenElement.href = window.URL.createObjectURL(blob);
    hiddenElement.download = fileName;
    hiddenElement.click();
};

export const setFiltersModelOnDemand = (filters) => ({
    barCodes: filters.barCode?.value?.length ? filters.barCode?.value : null,
    userLogins: filters.creator?.value?.length ? filters.creator?.value : null,
    states: filters.state?.value?.length ? filters.state?.value : null,
    cities: filters.cities?.value?.length ? filters.cities?.value : null,
    radii: filters.radius?.value?.length ? filters.radius?.value : null,
    startDate: filters.date?.value?.length
        ? format(filters.date?.value[0], 'yyyy-MM-dd')
        : null,
    endDate: filters.date?.value?.length
        ? format(filters.date?.value[1], 'yyyy-MM-dd')
        : null,
    active: filters.active.value,
});

export const getDownloadBody = (extension, filters) => ({
    extension,
    filters,
});

export const dateLocale = {
    sunday: 'Dom',
    monday: 'Seg',
    tuesday: 'Ter',
    wednesday: 'Qua',
    thursday: 'Qui',
    friday: 'Sex',
    saturday: 'Sáb',
    ok: 'OK',
    today: 'Hoje',
};

export const formatDate = (value) => {
    const index = value.indexOf('+');

    if (index !== -1) {
        const newDate = value.substring(0, index);
        return format(new Date(parseISO(newDate)), 'dd/MM/yyyy');
    }
    return format(new Date(parseISO(value)), 'dd/MM/yyyy');
};
