import React from 'react';
import {
    IconButton, Icon, Modal, Button, Notification,
} from 'rsuite';

const ModalGtinIvalido = ({
    show, gtins, totalGtins, onConfirm, onCancel,
}) => {
    const copyGtins = () => {
        navigator.clipboard.writeText(gtins.join('\n'));
        Notification.open({
            placement: 'topEnd',
            description: 'Código(s) copiado(s) com sucesso!',
            duration: 2000,
            className: 'nova-lista-copy-notification',
        });
    };

    return (

        <Modal backdrop size="md" show={show} className="modal-gtin-invalido">
            <Modal.Body>
                <div className="header">
                    <h1>Códigos identificadores (GTIN) inválidos não serão considerados</h1>
                    <p>
                        Encontramos códigos de identificação de produtos que não correspondem a um GTIN válido e
                        não vamos considerá-los para a revisão da lista.
                    </p>
                </div>
                <div className="content">
                    <p className="content__title">Lista de Códigos Inválidos</p>
                    <div>
                        {gtins.map((gtin) => (
                            <>
                                <span key={{ gtin }}>
                                    {gtin}

                                </span>
                                <br />
                            </>
                        ))}
                    </div>
                    <p className="content__subtitle">
                        {gtins.length}
                        {' '}
                        de
                        {' '}
                        {totalGtins}
                        {' '}
                        códigos de produto informados são inválidos
                    </p>

                    <IconButton
                        icon={<Icon icon="copy" />}
                        onClick={() => copyGtins()}
                        appearance="link"
                    >
                        Copiar a lista de códigos inválidos

                    </IconButton>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button appearance="ghost" onClick={() => onCancel()}>iniciar nova lista</Button>
                <Button appearance="primary" onClick={() => onConfirm()}>prosseguir com revisão</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ModalGtinIvalido;
