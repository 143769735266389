import { GetFamilyModalPaginationState } from '../../../../../reducers/getFamilyModal/pagination';
import { GetFamilyModalPaginationStateSortState } from '../../../../../reducers/getFamilyModal/sort';
import { GET_ITEMS_FAMILY_MODAL_PARAMS } from './types';

namespace GetFamilyModalDatapoints {
    export type Params = {
        sort: GetFamilyModalPaginationStateSortState;
        pagination: GetFamilyModalPaginationState;
        productFamilyIds: string;
        storeIds: string;
    };
    export type Return = GET_ITEMS_FAMILY_MODAL_PARAMS;
}

export const GET_FAMILY_DATAPOINTS_MODEL = (
    params: GetFamilyModalDatapoints.Params,
): GetFamilyModalDatapoints.Return => {
    const { sort, pagination, productFamilyIds, storeIds } = params;

    return {
        sort: `${sort.type},${sort.orderBy}`,
        page: pagination.activePage,
        size: pagination.displayLength,
        productFamilyIds,
        storeIds,
    };
};
