export const startHeap = () => {
    window.heap = window.heap || [], heap.load = function (e, t) {
        window.heap.appid = e, window.heap.config = t = t || {}; const r = document.createElement('script');
        r.type = 'text/javascript', r.async = !0, r.src = `https://cdn.heapanalytics.com/js/heap-${e}.js`;
        const a = document.getElementsByTagName('script')[0]; a.parentNode.insertBefore(r, a);
        for (let n = function (e) {
            return function () { heap.push([e].concat(Array.prototype.slice.call(arguments, 0))); };
        }, p = ['addEventProperties', 'addUserProperties', 'clearEventProperties', 'identify', 'resetIdentity', 'removeEventProperty', 'setEventProperties', 'track', 'unsetEventProperty'], o = 0; o < p.length; o++)heap[p[o]] = n(p[o]);
    };
    heap.load('424056333');
};

export function setPropertiesHeap(usuario) {
    // const isInfoPricer = JSON.parse(localStorage.getItem('usuario'))?.email.includes('infoprice');

    if ((!(usuario?.servicoPanelCliente?.pacotePainel?.description === 'Free'
        && !usuario?.servicos?.some((item) => item.name !== 'PAN')))) {
        if (!window?.heap) {
            startHeap();
        }

        setTimeout(() => {
            if (usuario) {
                window?.heap?.identify(JSON.parse(localStorage.getItem('usuario'))?.email);
                window?.heap?.addUserProperties({
                    client: JSON.parse(localStorage.getItem('cliente'))?.nome || null,
                    organization: JSON.parse(localStorage.getItem('cliente'))?.organizacao,
                    contractManager: usuario?.responsavel,
                    contractStartDate: usuario?.dataInicio,
                    contractEndDate: usuario?.dataFim,
                    userHasMonitoramento: usuario?.servicos.some((item) => item.name === 'COL'),
                    userHasInfoPanel: usuario?.servicos.some((item) => item.name === 'PAN'),
                    packageInfoPanel: usuario?.servicoPanelCliente?.pacotePainel?.description,
                    trialInfoPanel: usuario?.servicoPanelCliente?.trial,
                    userHasPainelCustomizado: usuario?.servicos.some((item) => item.name === 'BI'),
                    userHasIpa: usuario?.servicos.some((item) => item.name === 'IPA'),
                    clientHasMonitoramento: usuario?.servicosCliente.some((item) => item.name === 'COL'),
                    clientHasInfoPanel: usuario?.servicosCliente.some((item) => item.name === 'PAN'),
                    clientHasPainelCustomizado: usuario?.servicosCliente.some((item) => item.name === 'BI'),
                    clientHasIpa: usuario?.servicosCliente.some((item) => item.name === 'IPA'),
                    freeUser: ((usuario?.servicoPanelCliente?.pacotePainel?.description === 'Free'
                        && !usuario?.servicos?.some((item) => item.name !== 'PAN'))),
                });
            }
        }, 500);
    }
}

export function resetIdentity() {
    window?.heap?.resetIdentity();
}
