import { combineReducers } from '@reduxjs/toolkit';
import { default as datapoints } from './datapoints';
import editedDatapoints from './editedDatapoints';
import pagination from './pagination';
import sort from './sort';
import totalElements from './totalElements';


const rootReducer = combineReducers({
    datapoints,
    pagination,
    totalElements,
    sort,
    editedDatapoints,
});

export type GetFamilyModalStateProps = ReturnType<typeof rootReducer>;

export default rootReducer;
