import React from 'react';
import { useSelector } from 'react-redux';
import { Callout, Layout } from '../../../../../components';
import { selectorLastIntegrationDate } from '../../../../../reducers/reducerIPA/lastIntegrationDate';
import styles from './PrecoAtacadoHeader.module.scss';

const PrecoAtacadoHeader = () => {
    const { parsedDate } = useSelector(selectorLastIntegrationDate);

    return (
        <Layout.Header className={styles.header}>
            <span>
                <Layout.Title>Preço Atacado</Layout.Title>
                <Callout size="xxs">
                    As alterações são salvas automaticamente e serão aplicadas
                    na próxima integração de dados
                </Callout>
            </span>

            <p>
                Defina a relação entre o preço de atacado com o preço regular
                para que o IPA calcule automaticamente
            </p>

            <span>
                {parsedDate && (
                    <Layout.Subtitle>
                        Última atualização:
                        {` ${parsedDate}`}
                    </Layout.Subtitle>
                )}
            </span>
        </Layout.Header>
    );
};

export default PrecoAtacadoHeader;
