import { useQuery } from '@tanstack/react-query';
import classNames from 'classnames';
import { addDays, isBefore } from 'date-fns';
import React, {
    forwardRef,
    useDeferredValue,
    useEffect,
    useImperativeHandle,
    useState,
} from 'react';
import { Toggle } from 'rsuite';
import Modal, { ModalProps } from 'rsuite/lib/Modal';
import { InputBox } from '../../../../../../../../components';
import { LoadingSpinerArea } from '../../../../../../../../components/LoadingSpinner';
import { getProductSuppliersData } from '../../../../../../../../services/ProdutoService_ts';
import styles from './SalvarNegociacao.module.scss';

export type SalvarNegociacaoDataProps = {
    name: string;
    supplier?: string;
    startDate: Date;
    endDate: Date | null;
    enableFinalDate: boolean;
};

export type SalvarNegociacaoFormDataProps = {
    name: string;
    supplier: string;
    startDate: Date;
    endDate: Date | null;
    enableFinalDate: boolean;
};

export type SalvarNegociacaoProps = ModalProps & {
    dataValue?: SalvarNegociacaoDataProps;
    onSubmit?: (formData: SalvarNegociacaoFormDataProps) => void;
    onHide?: () => void;
};

const currentDate = new Date();

const nextDay = addDays(currentDate, 1);

const NAME_MAX_LENGTH = 100;

const datePickerLocale = {
    sunday: 'Dom',
    monday: 'Seg',
    tuesday: 'Ter',
    wednesday: 'Qua',
    thursday: 'Qui',
    friday: 'Sex',
    saturday: 'Sáb',
    today: 'Hoje',
};

const initialState: SalvarNegociacaoDataProps = {
    name: '',
    startDate: nextDay,
    endDate: null,
    enableFinalDate: false,
};

export type SalvarNegociacaoRefProps = {
    handleReset: () => void;
    handleHide: () => void;
};

export const SalvarNegociacaoModal = forwardRef<
    SalvarNegociacaoRefProps,
    SalvarNegociacaoProps
>(
    (
        {
            className = '',
            onHide,
            onSubmit,
            dataValue = initialState,
            ...props
        },
        ref,
    ) => {
        const [formData, setFormData] =
            useState<SalvarNegociacaoDataProps>(dataValue);

        const [query, setQuery] = useState('');

        const deferredQuery = useDeferredValue(query);

        const { data, isLoading } = useQuery({
            queryKey: ['negotiation/save-negotiation-suppliers', deferredQuery],
            initialData: [],
            queryFn: async () => {
                const response = await getProductSuppliersData({
                    search: deferredQuery,
                });
                return response.map((item) => ({
                    value: item.supplier,
                    label: item.supplier,
                }));
            },
        });

        const isSubmitDisabled = !formData.name?.length || !formData?.supplier;

        const shouldUpdateName = formData.name?.length < NAME_MAX_LENGTH;

        const handleReset = () => setFormData(initialState);

        const handleHide = () => onHide?.();

        useImperativeHandle(
            ref,
            () => ({
                handleReset,
                handleHide,
            }),
            [],
        );

        useEffect(() => {
            setFormData(dataValue);
        }, [dataValue]);

        useEffect(() => {
            if (!formData.enableFinalDate) {
                setFormData((oldValue) => ({ ...oldValue, endDate: null }));
            }
        }, [formData.enableFinalDate]);

        return (
            <>
                <Modal
                    className={classNames(
                        'info-modal',
                        styles['salvar-negociacao-modal'],
                        className,
                    )}
                    onHide={handleHide}
                    {...props}
                >
                    <LoadingSpinerArea
                        height="100%"
                        size="md"
                        area="negotiation-save-negotiation"
                    />
                    <form onSubmit={(e) => e.preventDefault()}>
                        <Modal.Header>
                            <Modal.Title className="font-size-200-semibold">
                                Salvar negociação
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body
                            className={styles['salvar-negociacao-modal__body']}
                        >
                            <InputBox className={styles['inputbox-name']}>
                                <InputBox.Label htmlFor="name">
                                    Nome da negociação
                                </InputBox.Label>
                                <InputBox.Input
                                    name="name"
                                    value={formData.name}
                                    onChange={(e) => {
                                        if (shouldUpdateName) {
                                            setFormData((oldValue) => ({
                                                ...oldValue,
                                                name: e.target.value,
                                            }));
                                        }
                                    }}
                                />
                            </InputBox>
                            <InputBox className={styles['inputbox-supplier']}>
                                <InputBox.Label>Fornecedor</InputBox.Label>
                                <InputBox.Select
                                    value={formData.supplier}
                                    placeholder="Selecione um fornecedor"
                                    data={isLoading ? [] : data}
                                    onSearch={setQuery}
                                    onChange={(value: string) => {
                                        setFormData((oldValue) => ({
                                            ...oldValue,
                                            supplier: value,
                                        }));
                                    }}
                                    locale={{
                                        searchPlaceholder:
                                            'Busque um fornecedor',
                                        noResultsText: isLoading
                                            ? 'Carregando fornecedores...'
                                            : 'Nenhum fornecedor encontrado',
                                    }}
                                />
                            </InputBox>
                            <InputBox className={styles['inputbox-start']}>
                                <InputBox.Label htmlFor="start-date">
                                    Início de vigência
                                </InputBox.Label>
                                <InputBox.DatePicker
                                    block
                                    oneTap
                                    name="start-date"
                                    format="DD/MM/YYYY"
                                    ranges={[]}
                                    locale={datePickerLocale}
                                    value={formData.startDate}
                                    onChange={(value) => {
                                        setFormData((oldValue) => ({
                                            ...oldValue,
                                            startDate: value,
                                        }));
                                    }}
                                    disabledDate={(date) => {
                                        return isBefore(
                                            date as Date,
                                            new Date(),
                                        );
                                    }}
                                    onClean={() => {
                                        setFormData((oldValue) => ({
                                            ...oldValue,
                                            startDate: nextDay,
                                        }));
                                    }}
                                />
                            </InputBox>
                            <InputBox className={styles['inputbox-end']}>
                                <Toggle
                                    size="sm"
                                    checked={formData.enableFinalDate}
                                    onChange={(checked) => {
                                        setFormData((oldValue) => ({
                                            ...oldValue,
                                            enableFinalDate: checked,
                                        }));
                                    }}
                                />
                                <InputBox.Label htmlFor="end-date">
                                    Final de vigência
                                </InputBox.Label>
                                <InputBox.DatePicker
                                    block
                                    oneTap
                                    name="end-date"
                                    format="DD/MM/YYYY"
                                    disabled={!formData.enableFinalDate}
                                    ranges={[]}
                                    locale={datePickerLocale}
                                    value={formData.endDate ?? undefined}
                                    onChange={(value) => {
                                        setFormData((oldValue) => ({
                                            ...oldValue,
                                            endDate: value,
                                        }));
                                    }}
                                    disabledDate={(date) => {
                                        return isBefore(
                                            date as Date,
                                            formData.startDate,
                                        );
                                    }}
                                    onClean={() => {
                                        setFormData((oldValue) => ({
                                            ...oldValue,
                                            endDate: null,
                                        }));
                                    }}
                                />
                            </InputBox>
                        </Modal.Body>
                        <Modal.Footer>
                            <ButtonPrimary
                                skin="blue"
                                theme="ghost"
                                type="button"
                                onClick={handleHide}
                            >
                                FECHAR
                            </ButtonPrimary>
                            <ButtonPrimary
                                skin="blue"
                                theme="filled"
                                type="submit"
                                onClick={() => {
                                    if (!formData.supplier) return;
                                    onSubmit?.({
                                        name: formData.name,
                                        supplier: formData.supplier,
                                        startDate: formData.startDate,
                                        endDate: formData.endDate,
                                        enableFinalDate:
                                            formData.enableFinalDate,
                                    });
                                }}
                                disabled={isSubmitDisabled}
                            >
                                SALVAR
                            </ButtonPrimary>
                        </Modal.Footer>
                    </form>
                </Modal>
                <LoadingSpinerArea size="sm" area="saved-filter" />
            </>
        );
    },
);
