import {
    getSegmentos,
    getItensSegmentoProduto,
    getSegmentosClasses,
    searchFiltroProdutos,
} from '../services/ProdutoService';
import { getDatapointsStores } from '../services/DatapointService';

export const actionsTypeHistoricoExtracaoPreco = {
    ADD_FILTRO_EXTRACAO_PRECO: 'ADD_FILTRO_EXTRACAO_PRECO',
    DELETE_FILTRO_EXTRACAO_PRECO: 'DELETE_FILTRO_EXTRACAO_PRECO',
    CHANGE_VALUE_FILTRO_EXTRACAO_PRECO: 'CHANGE_VALUE_FILTRO_EXTRACAO_PRECO',
    CHANGE_CACHE_FILTRO_EXTRACAO_PRECO: 'CHANGE_CACHE_FILTRO_EXTRACAO_PRECO',
    SELECT_VALUE_FILTRO_EXTRACAO_PRECO: 'SELECT_VALUE_FILTRO_EXTRACAO_PRECO',
    LIST_ITENS_FILTRO_EXTRACAO_PRECO: 'LIST_ITENS_FILTRO_EXTRACAO_PRECO',
    LIST_ITENS_SEGMENTOS_CLASSES_LIMITE_PRECO_CADASTRO:
        'LIST_ITENS_SEGMENTOS_CLASSES_LIMITE_PRECO_CADASTRO',
    LIST_ITENS_PRODUTO_EXTRACAO: 'LIST_ITENS_PRODUTO_EXTRACAO',
    LIST_ITENS_LOJA_EXTRACAO: 'LIST_ITENS_LOJA_EXTRACAO',
    CLEAR_ALL_FILTRO_EXTRACAO_PRECO: 'CLEAR_ALL_FILTRO_EXTRACAO_PRECO',
    GET_FILTROS_EXTRACAO_PRECO: 'GET_FILTROS_EXTRACAO_PRECO',
    CHANGE_VALUE_FILTRO_LOJA_REVISAO: 'CHANGE_VALUE_FILTRO_LOJA_REVISAO',
    CHANGE_CACHE_FILTRO_LOJA: 'CHANGE_CACHE_FILTRO_LOJA',
    SELECT_VALUE_FILTRO_LOJAS_REVISAO: 'SELECT_VALUE_FILTRO_LOJAS_REVISAO',
};

export function addFiltro(filtro) {
    return {
        type: actionsTypeHistoricoExtracaoPreco.ADD_FILTRO_EXTRACAO_PRECO,
        filtro,
    };
}

export function deleteFiltro(index, name) {
    return {
        type: actionsTypeHistoricoExtracaoPreco.DELETE_FILTRO_EXTRACAO_PRECO,
        index,
        name,
    };
}

export function changeValueFiltro(value, name) {
    return {
        type: actionsTypeHistoricoExtracaoPreco.CHANGE_VALUE_FILTRO_EXTRACAO_PRECO,
        value,
        name,
    };
}

export function changeCacheFiltro(name, cache) {
    return {
        type: actionsTypeHistoricoExtracaoPreco.CHANGE_CACHE_FILTRO_EXTRACAO_PRECO,
        name,
        cache,
    };
}

export function selectValueFiltro(item, name) {
    return {
        type: actionsTypeHistoricoExtracaoPreco.SELECT_VALUE_FILTRO_EXTRACAO_PRECO,
        item,
        name,
    };
}

export function changeValueFiltroLoja(value, name) {
    return {
        type: actionsTypeHistoricoExtracaoPreco.CHANGE_VALUE_FILTRO_LOJA_REVISAO,
        value,
        name,
    };
}

export function changeCacheFiltroLoja(name, cache) {
    return {
        type: actionsTypeHistoricoExtracaoPreco.CHANGE_CACHE_FILTRO_LOJA,
        name,
        cache,
    };
}

export function selectValueLojas(item, name) {
    return {
        type: actionsTypeHistoricoExtracaoPreco.SELECT_VALUE_FILTRO_LOJAS_REVISAO,
        item,
        name,
    };
}

export function listItensSegmentos(itens, name) {
    return {
        type: actionsTypeHistoricoExtracaoPreco.LIST_ITENS_FILTRO_EXTRACAO_PRECO,
        itens,
        name,
    };
}

export function listSegmentosClassesSensibility(itens, name) {
    return {
        type: actionsTypeHistoricoExtracaoPreco.LIST_ITENS_SEGMENTOS_CLASSES_LIMITE_PRECO_CADASTRO,
        itens,
        name,
    };
}

export function getAllSegmentos(itens) {
    const filtros = {};
    itens.forEach((item) => {
        filtros[item.field] = { lista: [], value: [], cache: [] };
    });
    return {
        type: actionsTypeHistoricoExtracaoPreco.GET_FILTROS_EXTRACAO_PRECO,
        itens,
        filtros,
    };
}

export function clearFiltros() {
    return {
        type: actionsTypeHistoricoExtracaoPreco.CLEAR_ALL_FILTRO_EXTRACAO_PRECO,
    };
}

export function listItensProduto(itens, name) {
    return {
        type: actionsTypeHistoricoExtracaoPreco.LIST_ITENS_PRODUTO_EXTRACAO,
        itens,
        name,
    };
}

export function listItensLoja(itens, name) {
    return {
        type: actionsTypeHistoricoExtracaoPreco.LIST_ITENS_LOJA_EXTRACAO,
        itens,
        name,
    };
}

export function searchSegmentos() {
    return (dispatch) => getSegmentos().then((data) => {
            dispatch(getAllSegmentos(data));
        });
}

export function search(nivel, query, name) {
    const nivelCategoria
        = nivel === 'productId'
        || nivel === 'storeName'
        || nivel === 'ruleType'
        || nivel === 'infoPriceSensibility'
            ? nivel
            : `categoryLevel${nivel}` || '';

    return (dispatch) => {
        getItensSegmentoProduto(nivelCategoria, { query }).then((data) => {
            dispatch(listItensSegmentos(data, name));
        });
    };
}

export function searchSegmentosClasses() {
    return (dispatch) => {
        getSegmentosClasses().then((data) => dispatch(listSegmentosClassesSensibility(data, 'abcClass')));
    };
}

export function searchProduto(nivel, query, name) {
    return (dispatch) => {
        searchFiltroProdutos({ query }).then((data) => dispatch(listItensProduto(data, name)));
    };
}

export function searchStore(nivel, query, name) {
    return (dispatch) => {
        getDatapointsStores(query).then((resp) => {
            dispatch(listItensLoja(resp, name));
        });
    };
}
