import React, { useState } from 'react';
import { Table, Tooltip, Whisper } from 'rsuite';
import chevronRightIcon from '../../../../../../assets/icons/icon_chevron-right-12.svg';
import noImage from '../../../../../../assets/icons/no-image.svg';
import { handleDateFormat } from '../../../../../../utils/Helpers';
import { useIsOverflow } from '../../Hooks/useIsOverflow';
import { formatProductDashboardPrice } from '../../utils';

import styles from './styles.module.scss';

const { Column, HeaderCell, Cell } = Table;
const { Pagination } = Table;

const AllProductsSelectedTable = ({
    products,
    loading,
    onClickTablePage,
}) => {
    const [page, setPage] = useState(1);

    const { isOverflow } = useIsOverflow(products?.datapoints, 'description');

    const handleChangePage = (e) => {
        setPage(e);
        onClickTablePage(e - 1);
    };

    return (
        <>
            <Table
                className={styles['all-products-selected-table']}
                autoHeight
                data={products?.datapoints}
                loading={loading}
                rowHeight={68}
                headerHeight={32}
            >
                <Column>
                    <HeaderCell />
                    <Cell
                        dataKey="productImage"
                        className={styles['image-cell']}
                    >
                        {(rowData) => (
                            <img
                                src={rowData.gtin_link_imagem || noImage}
                                alt=""
                                onError={({ currentTarget }) => {
                                    currentTarget.onerror = null; // prevents looping
                                    currentTarget.src = noImage;
                                }}
                            />
                        )}
                    </Cell>
                </Column>
                <Column flexGrow={4}>
                    <HeaderCell className={styles['regular-header']}>
                        Produto
                    </HeaderCell>
                    <Cell dataKey="description">
                        {(rowData, i) => (
                            <div className={styles['product-info-cell']}>
                                <Whisper
                                    placement="top"
                                    trigger="hover"
                                    speaker={
                                        <Tooltip
                                            style={{
                                                display: isOverflow[
                                                    `description-${i}`
                                                ]
                                                    ? 'block'
                                                    : 'none',
                                            }}
                                        >
                                            {rowData.descricao}
                                        </Tooltip>
                                    }
                                >
                                    <p
                                        id={`description-${i}`}
                                        className={styles['product-title']}
                                    >
                                        {rowData.descricao}
                                    </p>
                                </Whisper>
                                <div className={styles['product-info-wrapper']}>
                                    <span
                                        className={styles['product-info-text']}
                                        style={{ marginRight: '16px' }}
                                    >
                                        {rowData.gtin}
                                    </span>
                                    <span
                                        className={styles['product-info-text']}
                                    >
                                        {rowData.categoria}
                                    </span>
                                    <img src={chevronRightIcon} alt="" />
                                    <span
                                        className={styles['product-info-text']}
                                    >
                                        {rowData.secao}
                                    </span>
                                </div>
                            </div>
                        )}
                    </Cell>
                </Column>
                <Column flexGrow={2}>
                    <HeaderCell className={styles['dashed-header']}>
                        <div className={styles['dashed-divider-header']} />
                        Último preço
                    </HeaderCell>
                    <Cell
                        dataKey="description"
                        className={styles['price-cell']}
                    >
                        {(rowData) => (
                            <div className={styles['price-container']}>
                                <div
                                    className={styles['dashed-divider-cell']}
                                />
                                <div className={styles['price-wrapper']}>
                                    <span className={styles['price-symbol']}>
                                        R$
                                    </span>
                                    <span className={styles['price-value']}>
                                        {formatProductDashboardPrice(
                                            rowData.ultimo_preco,
                                        )}
                                    </span>
                                </div>
                                <div className={styles['last-price-wrapper']}>
                                    <p className={styles['price-regular-text']}>
                                        Coletado dia
                                    </p>
                                    <p className={styles['price-bold-text']}>
                                        {handleDateFormat(rowData.data_preco)}
                                    </p>
                                </div>
                            </div>
                        )}
                    </Cell>
                </Column>
                <Column flexGrow={2}>
                    <HeaderCell className={styles['dashed-header']}>
                        <div className={styles['dashed-divider-header']} />
                        Preço médio
                    </HeaderCell>
                    <Cell>
                        {(rowData) => (
                            <div className={styles['price-container']}>
                                <div
                                    className={styles['dashed-divider-cell']}
                                />
                                <div className={styles['price-wrapper']}>
                                    <span className={styles['price-symbol']}>
                                        R$
                                    </span>
                                    <span className={styles['price-value']}>
                                        {formatProductDashboardPrice(
                                            rowData.preco_medio,
                                        )}
                                    </span>
                                </div>
                                <div className={styles['price-info-wrapper']}>
                                    <p className={styles['price-bold-text']}>
                                        {rowData.total_datapoints} dados
                                    </p>
                                    <p className={styles['price-regular-text']}>
                                        Calculados
                                    </p>
                                </div>
                            </div>
                        )}
                    </Cell>
                </Column>
                <Column flexGrow={2}>
                    <HeaderCell className={styles['dashed-header']}>
                        <div className={styles['dashed-divider-header']} />
                        Preço mais frequente
                    </HeaderCell>
                    <Cell dataKey="competitividade">
                        {(rowData) => (
                            <div className={styles['price-container']}>
                                <div
                                    className={styles['dashed-divider-cell']}
                                />
                                <div className={styles['price-wrapper']}>
                                    <span className={styles['price-symbol']}>
                                        R$
                                    </span>
                                    <span className={styles['price-value']}>
                                        {formatProductDashboardPrice(
                                            rowData.preco_mais_frequente,
                                        )}
                                    </span>
                                </div>
                                <div className={styles['price-info-wrapper']}>
                                    <p className={styles['price-regular-text']}>
                                        Praticado em
                                    </p>
                                    <div
                                        className={
                                            styles[
                                                'practiced-price-text-wrapper'
                                            ]
                                        }
                                    >
                                        <span
                                            className={
                                                styles['price-bold-text']
                                            }
                                        >
                                            {rowData.percent_topk
                                                .toFixed(1)
                                                .toString()
                                                .replace('.', ',')}
                                            %{' '}
                                        </span>
                                        <span
                                            className={
                                                styles['price-regular-text']
                                            }
                                        >
                                            das vezes
                                        </span>
                                    </div>
                                </div>
                            </div>
                        )}
                    </Cell>
                </Column>
            </Table>
            {products?.datapoints ? (
                <Pagination
                    className={styles['products-pagination']}
                    activePage={page}
                    displayLength={products?.datapoints?.length}
                    showLengthMenu={false}
                    total={products?.totalElements}
                    onChangePage={handleChangePage}
                    renderTotal={() =>
                        `Exibindo ${products?.datapoints?.length} de ${products?.totalElements} solicitações.`
                    }
                />
            ) : null}
        </>
    );
};

export default AllProductsSelectedTable;
