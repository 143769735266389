import _ from 'lodash';
import { useEffect, useReducer } from 'react';

type Item = string;

export type FilterValueState<T extends string = string> = Partial<{
    [key in T]: Item[];
}>;

type Action<T extends string> =
    | {
          type: 'ADD';
          key: T;
          payload: Item;
      }
    | {
          type: 'SET';
          key: T;
          payload: Item[];
      }
    | { type: 'SET_STATE'; payload: FilterValueState<T> }
    | {
          type: 'RESET';
      }
    | {
          type: 'RESET_BY_KEY';
          key: T;
      }
    | {
          type: 'REMOVE';
          key: T;
          payload: Item;
      };

const initialState: FilterValueState<never> = {};

const reducer = <T extends string>(
    state: FilterValueState<T>,
    action: Action<T>,
): FilterValueState<T> => {
    switch (action.type) {
        case 'ADD':
            return {
                ...state,
                [action.key]: _.union([
                    ...(state[action.key] ?? []),
                    action.payload,
                ]),
            };
        case 'SET':
            return {
                ...state,
                [action.key]: action.payload,
            };
        case 'SET_STATE':
            return action.payload;
        case 'REMOVE':
            return {
                ...state,
                [action.key]: _.without(state[action.key], action.payload),
            };

        case 'RESET':
            return initialState;
        case 'RESET_BY_KEY':
            return _.omit(state, action.key) as FilterValueState<T>;
        default:
            return state;
    }
};

const ADD_DATA = <T extends string>(key: T, payload: Item) => {
    return {
        type: 'ADD',
        key,
        payload,
    } as const;
};

const SET_DATA = <T extends string>(key: T, payload: Item[]) => {
    return {
        type: 'SET',
        key,
        payload,
    } as const;
};

const SET_STATE_DATA = <T extends string>(payload: FilterValueState<T>) => {
    return {
        type: 'SET_STATE',
        payload,
    } as const;
};

const RESET_DATA = () => {
    return {
        type: 'RESET',
    } as const;
};

const RESET_DATA_BY_KEY = <T extends string>(key: T) => {
    return {
        type: 'RESET_BY_KEY',
        key,
    } as const;
};

const REMOVE_DATA = <T extends string>(key: T, payload: Item) => {
    return {
        type: 'REMOVE',
        key,
        payload,
    } as const;
};

export const useFilterValue = <T extends string>(
    initialValues: FilterValueState<T> = {},
) => {
    const [data, dispatch] = useReducer(reducer, initialState);

    const onAddData = (key: T, payload: Item) => {
        dispatch(ADD_DATA(key, payload));
    };

    const onSetData = (key: T, payload: Item[]) => {
        dispatch(SET_DATA(key, payload));
    };

    const onSetDataState = (payload: FilterValueState) => {
        dispatch(SET_STATE_DATA(payload));
    };

    const onResetData = () => {
        dispatch(RESET_DATA());
    };

    const onResetByKey = (key: T) => {
        dispatch(RESET_DATA_BY_KEY(key));
    };

    const onRemoveData = (key: T, payload: Item) => {
        dispatch(REMOVE_DATA(key, payload));
    };

    const onSelect = (key: T, value: Item) => {
        const isCheckeds = data[key]?.includes(value);
        if (isCheckeds) onRemoveData(key, value);
        else onAddData(key, value);
    };

    useEffect(() => {
        if (Object.keys(initialValues).length) {
            dispatch(SET_STATE_DATA(initialValues));
        }
    }, [initialValues]);

    return [
        data,
        {
            add: onAddData,
            set: onSetData,
            setState: onSetDataState,
            reset: onResetData,
            resetByKey: onResetByKey,
            remove: onRemoveData,
            select: onSelect,
        },
    ] as const;
};
