import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsExport from 'highcharts/modules/export-data';
import HighchartsExporting from 'highcharts/modules/exporting';
import React, { useEffect, useRef, useState } from 'react';
import {
    Button,
    Col,
    Content,
    Dropdown,
    FlexboxGrid,
    IconButton,
    SelectPicker,
} from 'rsuite';

import iconEllipsis from '../../../../../assets/icons/icon_ellipsis-v.svg';
import iconMaximize from '../../../../../assets/icons/icon_maximize.svg';
import iconMinimize from '../../../../../assets/icons/icon_minimize.svg';
import noDataImage from '../../../../../assets/image/empty-state-2.svg';

import { LoadingSpinerLocal } from '../../../../../components/LoadingSpinner';
import MobileSelectOptionComponent from '../../../../../components/MobileSelectOptionComponent';
import { HighchartsOptions } from '../../../../../utils/HighchartsConfig';
import { formatPrice, seriesColors, topPricesChart } from '../utils';

import EmptyState from './EmptyState';

HighchartsExporting(Highcharts);
HighchartsExport(Highcharts);
Highcharts.setOptions(HighchartsOptions);

const STopPricesChart = ({
    rede,
    usuario,
    data,
    shared,
    loading,
    produto,
    onSelectTopFive,
    onCleanTopFive,
}) => {
    const topPricesChartRef = useRef(null);
    const [expand, setExpand] = useState(true);
    const [openDialog, setOpenDialog] = useState(false);
    const [chartOptions, setChartOptions] = useState(topPricesChart);
    const [filtroSelecionadoProduto, setFiltroSelecionadoProduto] = useState(null);
    const [filtroSelecionadoRede, setFiltroSelecionadoRede] = useState(null);

    const exportCSV = () => {
        if (topPricesChartRef?.current?.chart) {
            topPricesChartRef.current.chart.downloadCSV();
        }
    };

    const exportXLS = () => {
        if (topPricesChartRef?.current?.chart) {
            topPricesChartRef.current.chart.downloadXLS();
        }
    };

    const exportPNG = () => {
        if (topPricesChartRef?.current?.chart) {
            topPricesChartRef.current.chart.exportChart({ type: 'image/png' });
        }
    };

    const print = () => {
        if (topPricesChartRef?.current?.chart) {
            topPricesChartRef.current.chart.print();
        }
    };

    const viewFullScreen = () => {
        if (topPricesChartRef?.current?.chart) {
            topPricesChartRef.current.chart.fullscreen.toggle();
        }
    };

    const mapCategories = (data) => {
        const prices = [...data];
        return prices.map((item) => formatPrice(item.preco_medio));
    };

    const handleFiltrosSelecionados = (value, item, type) => {
        if (type === 'produto') {
            setFiltroSelecionadoProduto(item.identificador);
        }
        if (type === 'rede') {
            setFiltroSelecionadoRede(value);
        }
    };

    const handleCleanFilters = (type) => {
        if (type === 'produto') {
            setFiltroSelecionadoProduto(null);
        }
        if (type === 'rede') {
            setFiltroSelecionadoRede(null);
        }
    };

    const mapDataSeries = (data) => {
        const prices = [...data.topPrecos];
        const series = [];
        const dataSeries = {
            data: prices.map((item, index) => ({
                name: formatPrice(item.preco_medio),
                y: Number(
                    ((item.datapoints / data?.totalSkus) * 100).toFixed(2),
                ),
                color: seriesColors[index],
                datapoints: item.datapoints,
            })),
        };

        series.push(dataSeries);
        return series;
    };

    const renderChart = () => setChartOptions((oldState) => ({
        ...oldState,
        xAxis: {
            ...oldState.xAxis,
            categories: data?.topPrecos
                ? mapCategories(data.topPrecos)
                : [],
        },
        series: data?.topPrecos ? mapDataSeries(data) : [],
    }));

    useEffect(() => {
        if (data?.topPrecos) {
            renderChart();
        }
    }, [data]);

    useEffect(() => {
        if (!shared) {
            if (filtroSelecionadoRede === null || filtroSelecionadoProduto === null) {
                onCleanTopFive(filtroSelecionadoRede, filtroSelecionadoProduto);
            } else {
                onSelectTopFive(filtroSelecionadoRede, filtroSelecionadoProduto);
            }
        }
    }, [filtroSelecionadoProduto, filtroSelecionadoRede]);

    return (
        <Content className="top-prices-chart">
            <LoadingSpinerLocal loading={loading} size="md" />
            <FlexboxGrid className="header-area">
                <FlexboxGrid.Item
                    componentClass={Col}
                    lg={20}
                    md={20}
                    sm={24}
                    xs={24}
                    xsHidden
                    smHidden
                    className={expand === false ? 'minimized' : null}
                >
                    <h4>top 5 - preços mais frequentes</h4>
                </FlexboxGrid.Item>
                <FlexboxGrid.Item
                    componentClass={Col}
                    lg={20}
                    md={20}
                    sm={17}
                    xs={17}
                    lgHidden
                    mdHidden
                    className={expand === false ? 'minimized' : null}
                >
                    <h4>top 5</h4>
                </FlexboxGrid.Item>
                {shared ? null : (
                    <>
                        <FlexboxGrid.Item
                            componentClass={Col}
                            lg={2}
                            md={2}
                            sm={3}
                            xs={3}
                            className={
                                !expand
                                    ? 'expand-section minimized'
                                    : 'expand-section'
                            }
                        >
                            <Button
                                id="top-prices-expand-btn"
                                appearance="subtle"
                                onClick={() => setExpand(!expand)}
                            >
                                {expand ? (
                                    <img alt="Minimizar" src={iconMinimize} />
                                ) : (
                                    <img alt="Maximizar" src={iconMaximize} />
                                )}
                            </Button>
                        </FlexboxGrid.Item>

                        <FlexboxGrid.Item
                            componentClass={Col}
                            lg={2}
                            md={2}
                            sm={3}
                            xs={3}
                            className={
                                expand === false
                                    ? 'more-options-section minimized'
                                    : 'more-options-section'
                            }
                        >
                            <Dropdown
                                id="top-prices-more-options"
                                placement="bottomEnd"
                                renderTitle={() => (
                                    <IconButton
                                        className="more-options-section__btn"
                                        appearance="subtle"
                                        icon={(
                                            <img
                                                alt="Mais opções"
                                                src={iconEllipsis}
                                            />
                                        )}
                                    />
                                )}
                            >
                                <Dropdown.Item
                                    onClick={viewFullScreen}
                                    id="btn-top-prices-full-screen"
                                >
                                    Ver em tela cheia
                                </Dropdown.Item>
                                <Dropdown.Item
                                    disabled={
                                        usuario?.servicoPanel?.pacotePainel
                                            ?.name === 'FREE'
                                    }
                                    onClick={print}
                                    id="btn-top-prices-print"
                                >
                                    Imprimir
                                </Dropdown.Item>
                                <Dropdown.Item divider />
                                <Dropdown.Item
                                    disabled={
                                        usuario?.servicoPanel?.pacotePainel
                                            ?.name === 'FREE'
                                    }
                                    onClick={exportPNG}
                                    id="btn-top-prices-download-png"
                                >
                                    Download em PNG
                                </Dropdown.Item>
                                <Dropdown.Item
                                    disabled={
                                        usuario?.servicoPanel?.pacotePainel
                                            ?.name === 'FREE'
                                    }
                                    onClick={exportCSV}
                                    id="btn-top-prices-download-csv"
                                >
                                    Download em CSV
                                </Dropdown.Item>
                                <Dropdown.Item
                                    disabled={
                                        usuario?.servicoPanel?.pacotePainel
                                            ?.name === 'FREE'
                                    }
                                    onClick={exportXLS}
                                    id="btn-time-evolution-download-xls"
                                >
                                    Download em XLS
                                </Dropdown.Item>
                            </Dropdown>
                        </FlexboxGrid.Item>
                    </>
                )}
            </FlexboxGrid>
            {expand ? (
                <>
                    <FlexboxGrid>
                        <FlexboxGrid.Item
                            componentClass={Col}
                            lg={24}
                            md={24}
                            xs={24}
                            className="select-product-section"
                        >
                            <span className="title-select-product-input">Produto: </span>
                            <SelectPicker
                                placeholder="Todos os produtos filtrados"
                                className="select-product-input"
                                maxHeight={240}
                                appearance="subtle"
                                onSelect={(value, item) => handleFiltrosSelecionados(value, item, 'produto')}
                                onClean={() => handleCleanFilters('produto')}
                                searchable={false}
                                data={
                                    produto?.value?.length ? produto?.cache : []
                                }
                                cleanable
                                disabled={shared}
                            />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item
                            componentClass={Col}
                            lg={24}
                            md={24}
                            xs={24}
                            className="select-product-section"
                        >
                            <span className="title-select-product-input">Rede: </span>
                            <SelectPicker
                                id="filter-rede-topfive"
                                placeholder="Todos as redes filtradas"
                                className="select-product-input"
                                maxHeight={240}
                                appearance="subtle"
                                onSelect={(value, item) => handleFiltrosSelecionados(value, item, 'rede')}
                                onClean={() => handleCleanFilters('rede')}
                                searchable={false}
                                data={
                                    rede?.value?.length ? rede?.cache : []
                                }
                                cleanable
                                disabled={shared}
                            />
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                    <FlexboxGrid>
                        {data?.topPrecos?.length ? (
                            <>
                                <FlexboxGrid.Item
                                    componentClass={Col}
                                    lg={24}
                                    md={24}
                                    sm={24}
                                    xs={24}
                                    id="chart-area"
                                    className="chart-area"
                                >
                                    <HighchartsReact
                                        ref={topPricesChartRef}
                                        highcharts={Highcharts}
                                        options={chartOptions}
                                    />
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item>
                                    <p className="subtitle">
                                        Total de ocorrência de preços:
                                        {' '}
                                        {data?.totalSkus}
                                    </p>
                                </FlexboxGrid.Item>
                            </>
                        ) : (
                            <FlexboxGrid.Item
                                componentClass={Col}
                                lg={24}
                                md={24}
                                sm={24}
                                xs={24}
                                id="chart-area"
                                className="chart-area"
                            >
                                <EmptyState
                                    title="Ops! Nenhum resultado encontrado"
                                    message="Sua busca não retornou nenhuma informação. Altere os filtros e tente novamente."
                                    image={noDataImage}
                                />
                            </FlexboxGrid.Item>
                        )}
                    </FlexboxGrid>
                </>
            ) : null}
            <MobileSelectOptionComponent
                open={openDialog}
                handleClose={() => setOpenDialog(false)}
                options={[]}
                optionSelected={[]}
                handleSelect={onSelectTopFive}
            />
        </Content>
    );
};

export default STopPricesChart;
