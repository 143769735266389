import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import { FilterKeysProps } from '../@types/FiltersTypes';

export type FilterItemDataProps = Array<
    Record<'label' | 'value', string> & Record<string, unknown>
>;

export type FiltrosProps = Record<FilterKeysProps, FilterItemDataProps>;

export type SearchFilterKeysPropsProps =
    | 'productIds'
    | 'productFamilyIds'
    | 'storeIds'
    | 'savedFilters'
    | 'productBrand'
    | 'supplier';

export type SearchFiltersDataProps = Record<SearchFilterKeysPropsProps, string>;

export type FiltrosDataProps = {
    data: FiltrosProps;
    search: SearchFiltersDataProps;
};

const initialState: FiltrosDataProps = {
    data: {
        productIds: [],
        productFamilyIds: [],
        storeIds: [],
        sensibilities: [],
        priceType: [],
        productBrand: [],
        supplier: [],
        categoryLevel1: [],
        categoryLevel2: [],
        categoryLevel3: [],
        categoryLevel4: [],
        categoryLevel5: [],
        categoryLevel6: [],
        categoryLevel7: [],
        savedFilters: [],
    },
    search: {
        productIds: '',
        productFamilyIds: '',
        storeIds: '',
        savedFilters: '',
        productBrand: '',
        supplier: '',
    },
};

const slice = createSlice({
    name: 'filtros/data',
    initialState,
    reducers: {
        setFiltersData: (
            state,
            action: PayloadAction<{
                name: FilterKeysProps;
                data: FilterItemDataProps;
            }>,
        ) => {
            state.data[action.payload.name] = action.payload.data;
        },
        addFiltersData: (
            state,
            action: PayloadAction<{
                name: FilterKeysProps;
                data: FilterItemDataProps;
            }>,
        ) => {
            state.data[action.payload.name] = _.uniqBy(
                [...state.data[action.payload.name], ...action.payload.data],
                'value',
            );
        },
        resetFiltersData: () => initialState,
        setFiltersSearch(
            state,
            action: PayloadAction<{
                name: SearchFilterKeysPropsProps;
                value: string;
            }>,
        ) {
            state.search[action.payload.name] = action.payload.value;
        },
    },
});

export const reducerDataFiltrosSelector = (
    state: Record<'dataFiltrosReducer', FiltrosDataProps>,
) => state.dataFiltrosReducer;

const { setFiltersData, addFiltersData, resetFiltersData, setFiltersSearch } =
    slice.actions;

export { addFiltersData, resetFiltersData, setFiltersData, setFiltersSearch };

export default slice.reducer;
