import React from 'react';
import { Dropdown, IconButton } from 'rsuite';
import iconEllipsis from '../assets/icons/icon_ellipsis-v.svg';

const id = '6545a527f74f2743a0a67b7e';
const DropdownDownloadOptions = ({
    disableExport,
    showExportOptions,
    idName,
    hasPNG,
    onClickFullScreen,
    onClickPrint,
    onClickExportPNG,
    hidePrintOption,
    // onClickExportCSV,
    // onClickExportXLS,
    onClickExport,
}) => (
    <Dropdown
        id={idName}
        placement="bottomEnd"
        renderTitle={() => (
            <IconButton
                className="more-options-section__btn"
                appearance="subtle"
                style={{ padding: '4px 8px', height: 'unset' }}
                icon={<img alt="Mais opções" src={iconEllipsis} />}
            />
        )}
    >
        <Dropdown.Item
            onClick={onClickFullScreen}
            id={`btn-${idName}-full-screen`}
        >
            Ver em tela cheia
        </Dropdown.Item>
        {showExportOptions ? (
            <>
                {hidePrintOption ? null : (
                    <Dropdown.Item
                        disabled={disableExport}
                        onClick={onClickPrint}
                        id={`btn-${idName}-print`}
                    >
                        Imprimir
                    </Dropdown.Item>
                )}
                <Dropdown.Item divider />
                {hasPNG ? (
                    <Dropdown.Item
                        disabled={disableExport}
                        onClick={onClickExportPNG()}
                        id={`btn-${idName}-download-png`}
                    >
                        Download em PNG
                    </Dropdown.Item>
                ) : null}
                <Dropdown.Item
                    disabled={disableExport}
                    onClick={() => onClickExport('csv')}
                    id={`btn-${idName}-download-csv`}
                >
                    Download em CSV
                </Dropdown.Item>
                <Dropdown.Item
                    disabled={disableExport}
                    onClick={() => onClickExport('xlsx')}
                    id={`btn-${idName}-download-xls`}
                >
                    Download em XLSX
                </Dropdown.Item>
            </>
        ) : null}
    </Dropdown>
);

export default DropdownDownloadOptions;
