import React from 'react';
import { InputAddon } from '../../InputAddon';
import { InputCurrency } from '../../InputCurrency';
import { InputGroup } from '../../InputGroup';
import { BaseCell } from '../BaseCell';
import { BaseCellProps } from '../BaseCell/BaseCell';
import styles from './TriggerCell.module.scss';

const { MainContainer } = BaseCell;

type TriggerCellProps = BaseCellProps & {
    rowData: { triggerCount: number | null };
    index: number;
    handleChangeValue: (value: number, index: number) => void;
    handleBlur: (index: number) => void;
};

const TriggerCell = ({
    rowData,
    index,
    handleChangeValue,
    handleBlur,
    ...props
}: TriggerCellProps) => {
    const value = rowData.triggerCount;

    return (
        <BaseCell {...props}>
            <MainContainer className={styles['main-container']}>
                <InputGroup>
                    <InputAddon>≥</InputAddon>
                    <InputCurrency
                        value={value}
                        allowNegative={false}
                        precision="0"
                        onChangeEvent={(_e, _m, value) => {
                            handleChangeValue(value, index);
                        }}
                        className="triggerCount"
                        onBlur={(e, _v, details) => {
                            if (details.hasChanges) {
                                handleBlur?.(index);
                            }
                        }}
                    />
                </InputGroup>
                <span>UN</span>
            </MainContainer>
        </BaseCell>
    );
};

export default TriggerCell;
