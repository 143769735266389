import React from 'react';
import CurrencyFormat from 'react-currency-format';
import BaseCell, { BaseCellProps } from '../BaseCell/BaseCell';

const PMZCell = (props: BaseCellProps) => {
    const { rowData } = props;
    return (
        <BaseCell {...props}>
            {rowData.pmz ? (
                <CurrencyFormat
                    fixedDecimalScale
                    decimalScale={2}
                    value={rowData?.pmz}
                    displayType="text"
                    prefix="R$"
                    decimalSeparator=","
                    thousandSeparator="."
                    style={{
                        fontWeight: 600,
                    }}
                />
            ) : (
                '--'
            )}
        </BaseCell>
    );
};

export default PMZCell;
