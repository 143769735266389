import { AxiosResponse } from 'axios';
import { format } from 'date-fns';
import qs from 'qs';
import { trackPromise } from 'react-promise-tracker';
import { api } from '../../../../../lib';
import History from '../../../../../utils/History';
import { getValidData } from '../../../RevisaoPrecos/utils';
import {
    NegotiationDataItemProps,
    NegotitationEditDatapointProps,
    SuppliersResponseProps,
} from '../NegociacaoFornecedor';
import { GetNegociacoesItemProps } from '../NegociacoesSalvas/NegociacoesSalvas.services';

export async function GETNegotiationDetails(
    ipaNegotiationId: string,
): Promise<GetNegociacoesItemProps> {
    return trackPromise(
        api({
            url: '/negotiations/info',
            method: 'GET',
            expectToken: true,
            params: { ipaNegotiationId },
            paramsSerializer: (params) => {
                return qs.stringify(params, { arrayFormat: 'repeat' });
            },
        })
            .then((resp) => resp.data)
            .catch((err) => {
                const errorMessage =
                    err.response.data._embedded.errors[0].message;
                if (errorMessage === 'Negociação não encontrada.') {
                    History.push('/ipa/negociacoes/salvas');
                }
            }),
        'negotiation/get-details',
    );
}

export async function DELETENegotiation(id: string) {
    return trackPromise(
        api({
            url: '/negotiations',
            method: 'DELETE',
            expectToken: true,
            data: { negotiationId: id, selectedAll: true },
        }),
        'negotiation/delete-negotiation',
    );
}

export async function PATCHCloseNegotiation(id: string) {
    return trackPromise(
        api({
            url: '/negotiations/closed',
            method: 'PATCH',
            expectToken: true,
            data: { id },
        }),
        'negotiation/close-negotiation',
    );
}

export async function PATCHReopenNegotiation(id: string) {
    return trackPromise(
        api({
            url: '/negotiations/reopen',
            method: 'PATCH',
            expectToken: true,
            data: { id },
        }),
        'negotiation/reopen-negotiation',
    );
}

export async function GETDatapointsDetalhesDaNegociacao(
    params: Record<string, unknown>,
): Promise<SuppliersResponseProps> {
    return trackPromise<AxiosResponse<SuppliersResponseProps>>(
        api({
            url: '/negotiations/details',
            method: 'GET',
            expectToken: true,
            params,
            paramsSerializer: (params) => {
                return qs.stringify(params, { arrayFormat: 'repeat' });
            },
        }),
        'negotiations/details/get-datapoints',
    ).then((res) => res.data);
}

export type EditarNegociacaoProps = {
    negotiationId: string;
    name: string;
    supplier: string;
    startDate: Date;
    endDate?: Date;
};

export async function editarNegociacao(
    data: EditarNegociacaoProps,
): Promise<AxiosResponse<unknown>> {
    return trackPromise(
        api({
            url: '/negotiations/edit',
            method: 'PUT',
            expectToken: true,
            data,
        }),
    );
}

export async function PATCHNegotiationDetailsSaveEditedDatapoint(
    datapoint: NegotitationEditDatapointProps,
): Promise<NegotiationDataItemProps> {
    return trackPromise(
        api({
            url: '/negotiations/datapoints',
            method: 'PUT',
            expectToken: true,
            data: getValidData(datapoint),
        }).then((resp) => resp.data),
        `negotiation-edit-datapoint-${datapoint.id}`,
    );
}

export async function DELETENegotiationDatapoint(data: {
    negotiationId: string;
    datapoints: Record<'excludedIds' | 'selectedIds', string[]>;
}) {
    return trackPromise(
        api({
            url: '/negotiations',
            method: 'DELETE',
            expectToken: true,
            data: {
                ...data,
                selectedAll: false,
            },
        }),
        'negotiation/delete-negotiation',
    );
}

export async function POSTDownloadNegotiation(negotiationId: string) {
    const currentDate = new Date();
    const formattedDate = format(currentDate, 'dd-MM-yyyy');
    const downloadTableName = `${formattedDate}_ipa_negociacao_fornecedor.csv`;
    return trackPromise(
        api({
            url: '/negotiations/download',
            method: 'POST',
            expectToken: true,
            params: { negotiationId },
            paramsSerializer: (params) => {
                return qs.stringify(params, { arrayFormat: 'repeat' });
            },
            responseType: 'arraybuffer',
        })
            .then((res) => {
                if (res.status === 200) {
                    return res.data;
                }
                return res;
            })
            .then((resp) => {
                let download = resp;
                if (!download.status) {
                    const blob = new Blob([download]);
                    const hiddenElement = document.createElement('a');
                    hiddenElement.href = window.URL.createObjectURL(blob);
                    hiddenElement.target = '_blank';
                    hiddenElement.download = downloadTableName;
                    hiddenElement.click();
                    download = null;
                }
                return download;
            }),
        'download-datapoints-negociacao',
    );
}

export async function desfazerAlteracaoDePrecos(
    data: Partial<NegotiationDataItemProps>,
) {
    const res = await api.put<NegotiationDataItemProps>(
        'negotiations/undo-datapoint-changes',
        data,
        {
            expectToken: true,
        },
    );
    return res.data;
}
