import React from 'react';
// @ts-ignore
import { Grafico } from '../../Components/index.tsx';
// @ts-ignore
import { useEvolucaoMargemPraticadaPorSemana } from '../../../hooks/useEvolucaoMargemPraticadaPorSemana.tsx';

export const EvolucaoMargemPraticadaPorSemana: React.FC = () => {
    const { grafico, data, isLoading } = useEvolucaoMargemPraticadaPorSemana();

    return (
        <Grafico
            title="Evolução de margem praticada por semana"
            grafico={grafico}
            isLoading={isLoading}
            data={data}
        />
    );
};
