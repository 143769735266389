export function setPropertiesTagManager(usuario) {
    if (usuario) {
        window?.dataLayer?.push({
            userId: JSON.parse(localStorage.getItem('usuario'))?.email || null,
            userName: JSON.parse(localStorage.getItem('usuario'))?.nome || null,
            client: JSON.parse(localStorage.getItem('cliente'))?.nome || null,
            organization: JSON.parse(localStorage.getItem('cliente'))?.organização,
            contractManager: usuario?.responsavel,
            contractStartDate: usuario?.dataInicio,
            contractEndDate: usuario?.dataFim,
            userHasMonitoramento: usuario?.servicos?.some((item) => item.name === 'COL'),
            userHasInfoPanel: usuario?.servicos?.some((item) => item.name === 'PAN'),
            packageInfoPanel: usuario?.servicoPanelCliente?.pacotePainel?.description,
            trialInfoPanel: usuario?.servicoPanelCliente?.trial,
            userHasPainelCustomizado: usuario?.servicos?.some((item) => item.name === 'BI'),
            userHasIpa: usuario?.servicos?.some((item) => item.name === 'IPA'),
            clientHasMonitoramento: usuario?.servicosCliente?.some((item) => item.name === 'COL'),
            clientHasInfoPanel: usuario?.servicosCliente?.some((item) => item.name === 'PAN'),
            clientHasPainelCustomizado: usuario?.servicosCliente?.some((item) => item.name === 'BI'),
            clientHasIpa: usuario?.servicosCliente?.some((item) => item.name === 'IPA'),
        });
    }
}
