import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import { Content, Loader } from 'rsuite';
// @ts-ignore
import { useCompetitividadeMediaItensPesquisados } from '../../../hooks/useCompetitividadeMediaItensPesquisados.tsx';

export const CompetitividadeMediaItensPesquisados: React.FC = () => {
    const { graficoCompetitividadeMediaGeral, data, isLoading } =
        useCompetitividadeMediaItensPesquisados();

    if (isLoading)
        return (
            <Content className="grafico-competitividade-media-itens-loading">
                <Loader center />
            </Content>
        );

    if (!data)
        return (
            <Content className="grafico-competitividade-media-itens">
                <h2>Competitividade média e Itens pesquisados por semana</h2>
                <div className="sem-dados">
                    <h3>Sem dados para essa análise</h3>
                    <p>Revise os filtros e tente novamente</p>
                </div>
            </Content>
        );

    return (
        <Content>
            <div className="grafico-competitividade-media-itens">
                <h2>Competitividade média e Itens pesquisados por semana</h2>
                <HighchartsReact
                    highcharts={Highcharts}
                    options={graficoCompetitividadeMediaGeral}
                />
            </div>
        </Content>
    );
};
