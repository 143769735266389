import { useQuery } from '@tanstack/react-query';
import * as ExtracaoService from '../services';

export const useTableColumn = () => {
    const getColumnList = async () => {
        const res = await ExtracaoService.getDimensoesEcom();

        return res;
    };

    const { data, isLoading, isError, isSuccess, refetch } = useQuery(
        ['request-pending'],
        getColumnList,
        {
            retry: false,
        },
    );

    const columnData = {
        colunas: data?.map((item) => ({
            id: item.name,
            value: item.name,
            label: item.descricao,
            objeto: item.objeto,
            name: item.name,
            descricao: item.descricao,
            dica: item.dica,
            obrigatorio: item.obrigatorio,
        })),
    };

    const columnDataSelected = columnData.colunas?.map((item) => item.name);

    return {
        getColumnList,
        columnData,
        columnDataSelected,
        isLoading,
        isError,
        isSuccess,
        refetch,
    };
};
