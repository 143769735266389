import { round } from 'lodash';
import { IGerenciadorPrecosDatapoint } from '../@types';

const calcCpi = (
    price: IGerenciadorPrecosDatapoint['price'],
    competitorsPrice: IGerenciadorPrecosDatapoint['competitorsPrice'],
) => {
    if (price === null || competitorsPrice === null) return 0;
    return round((price / competitorsPrice) * 100, 2);
};

const calcNovaMargem = (
    price: IGerenciadorPrecosDatapoint['price'],
    salesTaxPercentage: IGerenciadorPrecosDatapoint['salesTaxPercentage'],
    expensesPercent: IGerenciadorPrecosDatapoint['expensesPercent'],
    cost: IGerenciadorPrecosDatapoint['cost'],
) => {
    const validPrice = price ?? 0;
    const validSalesTaxPercentage = salesTaxPercentage ?? 0;
    const validExpensesPercent = expensesPercent ?? 0;
    const validCost = cost ?? 0;

    return round(
        ((validPrice -
            validPrice *
                ((validSalesTaxPercentage + validExpensesPercent) / 100) -
            validCost) /
            validPrice) *
            100,
        2,
    );
};

const calcPrecoWithCpi = (
    cpi: number | null,
    competitorsPrice: IGerenciadorPrecosDatapoint['competitorsPrice'],
) => {
    if (cpi === null || competitorsPrice === null) return null;
    return (cpi / 100) * competitorsPrice;
};

const calcNovaMargemWithPmz = (
    price: IGerenciadorPrecosDatapoint['price'],
    pmz: IGerenciadorPrecosDatapoint['pmz'],
) => {
    if (price === null || pmz === null) return null;
    return ((price - pmz) / price) * 100;
};

const calcPrecoWithMargem = (
    novaMargem: IGerenciadorPrecosDatapoint['novaMargem'],
    cost: IGerenciadorPrecosDatapoint['cost'],
    expensesPercent: IGerenciadorPrecosDatapoint['expensesPercent'],
    salesTaxPercentage: IGerenciadorPrecosDatapoint['salesTaxPercentage'],
) => {
    if (
        novaMargem === null ||
        novaMargem === undefined ||
        cost === null ||
        expensesPercent === null ||
        salesTaxPercentage === null
    ) {
        return null;
    }
    return round(
        (cost / (100 - novaMargem - (expensesPercent + salesTaxPercentage))) *
            100,
        2,
    );
};

const calcCpiWithMargem = (
    novaMargem: IGerenciadorPrecosDatapoint['novaMargem'],
    price: IGerenciadorPrecosDatapoint['price'],
    competitorsPrice: IGerenciadorPrecosDatapoint['competitorsPrice'],
) => {
    if (novaMargem === null || competitorsPrice === null || price === null) {
        return null;
    }
    return round((price / competitorsPrice) * 100, 2);
};

export const calcPriceVariation = (
    price: IGerenciadorPrecosDatapoint['price'],
    retailPrice: IGerenciadorPrecosDatapoint['retailPrice'],
) => {
    if (price === null || retailPrice === null) return null;
    return round((price / retailPrice - 1) * 100, 1);
};

const calcCpiPriceVariation = (
    novaCompetitividade: IGerenciadorPrecosDatapoint['novaCompetitividade'],
    retailCpi: IGerenciadorPrecosDatapoint['retailCpi'],
) => {
    if (
        novaCompetitividade === null ||
        novaCompetitividade === undefined ||
        retailCpi === null ||
        retailCpi === undefined
    ) {
        return null;
    }
    return round(novaCompetitividade - retailCpi, 1);
};

const calcMargemVariation = (
    novaMargem: IGerenciadorPrecosDatapoint['novaMargem'],
    newMargin: IGerenciadorPrecosDatapoint['newMargin'],
) => {
    if (
        novaMargem === null ||
        novaMargem === undefined ||
        newMargin === null ||
        newMargin === undefined
    ) {
        return null;
    }
    return round(novaMargem - newMargin, 1);
};

export default {
    novaCompetitividade: calcCpi,
    novaMargem: calcNovaMargem,
    precoWithCpi: calcPrecoWithCpi,
    novaMargemWithPmz: calcNovaMargemWithPmz,
    precoWithMargem: calcPrecoWithMargem,
    cpiWithMargem: calcCpiWithMargem,
    variation: {
        price: calcPriceVariation,
        cpi: calcCpiPriceVariation,
        margem: calcMargemVariation,
    },
} as const;
