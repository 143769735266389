import CloseIcon from '@material-ui/icons/Close';
import React, { CSSProperties } from 'react';
import TagsInput from 'react-tagsinput';
import 'react-tagsinput/react-tagsinput.css';
import {
    Button,
    FlexboxGrid,
    Input,
    Panel,
    SelectPicker,
} from 'rsuite';
import { LoadingMenu, LoadingSpinerLocal } from '../../../../../../components/LoadingSpinner';
import ModalGtinIvalido from '../../../../MPDV/RevisaoLista/Components/ModalGtinInvalido';
import { FormValues } from '../../types';
import styles from './styles.module.scss';

export interface IRequestAddressForm {
    style?: CSSProperties;
    addressIndex?: number | undefined;
    onClickRemoveAddress?: (arg0: number) => void;
    onOpenSelect?: (arg0: number, arg1: string) => void;
    onSelect?: (arg0: number, arg1: string, arg2: string) => void;
    onChangeTextInput?: (arg0: number, arg1: string, arg2: string) => void;
    onChangeTag?: (arg0: number, arg1: string[]) => void;
    onClickClearProductInput?: (arg0: number) => void;
    onClickRemoveInvalidGtins?: (arg0: number) => void;
    onSearchCidade?: (arg0: number, arg1: string,) => void;
    cepData?: FormValues[];
    data?: any;
    cepLoading?: boolean;
    invalidGtins: [];
    cepError: boolean;
}

const raioData = [
    {
        label: '1km',
        value: 1,
    },
    {
        label: '3km',
        value: 3,
    },
    {
        label: '10km',
        value: 10,
    },
];

export const RequestAddressForm = ({
    style,
    addressIndex = 1,
    data,
    cepData,
    cepLoading,
    invalidGtins,
    cepError,
    onClickRemoveAddress,
    onOpenSelect,
    onSelect,
    onChangeTextInput,
    onChangeTag,
    onClickClearProductInput,
    onClickRemoveInvalidGtins,
    onSearchCidade,
}: IRequestAddressForm) => {
    const handleTextAreaChange = (index: number, value: []) => {
        const newArray = value
            .map((item: string) => item
                .replace(/(^,)|(,$)/g, '')
                .replace(/(^;)|(;$)/g, '')
                .replace(' ', '')
                .replaceAll('\n', ';')
                .replaceAll('\r', ';')
                .replaceAll('\t', ';')
                .replaceAll(';', ',')
                .split(',')).flat()
            .filter((i) => i);
        onChangeTag?.(index, newArray);
    };

    const setData = (index: number, name: string) => data.filter((item) => item.id === index)[0]?.[name];
    const setInputData = (index: number, name: string) => cepData?.filter((item) => item.id === index)[0]?.[name];

    const renderMenu = (menu) => (
        <span>
            <LoadingMenu area="request-filter" />
            {menu}
        </span>
    );

    return (
        <Panel
            id={`info-${addressIndex?.toString()}`}
            key={`info-${addressIndex?.toString()}`}
            header={(
                <div className={styles.header}>
                    <p className={styles.title}>
                        {addressIndex}
                        º endereço
                    </p>
                    <p className={styles.text}>Escolha o endereço que será feita a solicitação e o(s) produto(s) da solicitação</p>
                </div>
                    )}
            collapsible
            bordered
            className={styles['request-form']}
            defaultExpanded
            style={style}
        >

            <form className={styles['form-body']}>
                <div>
                    <p className={styles['form-bold-title']}>endereço</p>
                    <p className={styles.text}>
                        Os dados dos produtos são coletados no raio de um endereço específico.
                        Caso um endereço específico não seja inserido, coletaremos dados no cep padrão da cidade selecionada
                    </p>

                    <FlexboxGrid justify="space-between" className={styles['address-wrapper']}>
                        <FlexboxGrid.Item colspan={2}>
                            <div className={styles['input-wrapper']}>
                                <label htmlFor="uf">
                                    <span className={styles['required-star']}>*</span>
                                    UF
                                </label>
                                <SelectPicker
                                    key="uf"
                                    searchable={false}
                                    data={setData(addressIndex, 'uf')}
                                    value={setInputData(addressIndex, 'uf')}
                                    renderMenu={renderMenu}
                                    onSelect={(value) => onSelect?.(addressIndex, 'uf', value)}
                                    cleanable={false}
                                    onOpen={() => onOpenSelect?.(addressIndex, 'uf')}
                                />
                            </div>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={4}>

                            <div className={styles['input-wrapper']}>
                                <label htmlFor="cidade">
                                    <span className={styles['required-star']}>*</span>
                                    Cidade
                                </label>
                                <SelectPicker
                                    key="cidade"
                                    searchable
                                    data={setData(addressIndex, 'cidade')}
                                    value={setInputData(addressIndex, 'cidade')}
                                    renderMenu={renderMenu}
                                    onSelect={(value) => onSelect?.(addressIndex, 'cidade', value)}
                                    cleanable={false}
                                    onOpen={() => onOpenSelect?.(addressIndex, 'cidade')}
                                    onSearch={(value) => onSearchCidade?.(addressIndex, value)}
                                />
                            </div>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={3}>
                            <div className={styles['input-wrapper']}>
                                <label htmlFor="cep">CEP</label>
                                <Input
                                    key="cep"
                                    className={styles['text-input']}
                                    value={setInputData(addressIndex, 'cep')}
                                    onChange={(value) => onChangeTextInput?.(addressIndex, 'cep', value)}
                                />
                                {cepError
                                    ? <p className={styles['error-text']}>Este CEP não corresponde com a cidade selecionada</p>
                                : null}
                                <LoadingSpinerLocal loading={cepLoading} size="sm" height="18px" position={undefined} />
                            </div>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={10}>
                            <div className={styles['input-wrapper']}>
                                <label htmlFor="endereco">Endereço</label>
                                <Input
                                    key="endereco"
                                    className={styles['text-input']}
                                    value={setInputData(addressIndex, 'endereco')}
                                    onChange={(value) => onChangeTextInput?.(addressIndex, 'endereco', value)}
                                />
                            </div>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={2}>
                            <div className={styles['input-wrapper']}>
                                <label htmlFor="numero">Número</label>
                                <Input
                                    key="numero"
                                    className={styles['text-input']}
                                    value={setInputData(addressIndex, 'numero')}
                                    onChange={(value) => onChangeTextInput?.(addressIndex, 'numero', value)}
                                />
                            </div>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={2}>

                            <div className={styles['input-wrapper']}>
                                <label htmlFor="raio">
                                    <span className={styles['required-star']}>*</span>
                                    Raio
                                </label>
                                <SelectPicker
                                    key="raio"
                                    searchable={false}
                                    data={raioData}
                                    value={setInputData(addressIndex, 'raio')}
                                // defaultValue={dailyFrequency[0].value}
                                    onSelect={(value) => onSelect?.(addressIndex, 'raio', value)}
                                    cleanable={false}
                                />
                            </div>
                        </FlexboxGrid.Item>
                    </FlexboxGrid>

                </div>
                <div className={styles['product-code']}>
                    <p className={styles['form-bold-title']}>Produtos</p>
                    <p className={styles.text}>
                        Os códigos de barras e/ou palavras-chaves podem ser copiados de uma tabela ou escritos,
                        devendo estar separados por um dos seguintes caracteres especiais: , (vírgula) ; (ponto e vírgula)
                    </p>
                    <TagsInput
                        key="code-input"
                        className={styles['code-input']}
                        addOnPaste
                        value={setInputData(addressIndex, 'codeOrText')}
                        onChange={(tags) => handleTextAreaChange(addressIndex, tags)}
                        onlyUnique
                        inputProps={{
                                placeholder: setInputData(addressIndex, 'codeOrText').length ? '' : 'Cole ou digite a lista de palavras-chaves e/ou códigos de barras dos produtos para solicitações neste endereço.',
                            }}
                    />
                    <div className={styles['code-bottom-wrapper']}>
                        <p className={styles.text}>
                            {setInputData(addressIndex, 'codeOrText').length}
                            {' '}
                            códigos de barras e/ou palavras-chaves de serão enviadas para solicitação.
                        </p>
                        <Button
                            appearance="link"
                            className={styles['link-text']}
                            onClick={() => onClickClearProductInput?.(addressIndex)}
                        >
                            <CloseIcon style={{ fontSize: '10px' }} color="#378EF0" />
                            Limpar campo de produtos
                        </Button>
                    </div>
                    <div className={styles['remove-button-wrapper']}>
                        {addressIndex !== 1 ? (
                            <Button
                                appearance="link"
                                className={styles['remove-link-text']}
                                onClick={() => onClickRemoveAddress?.(addressIndex)}
                            >
                                <CloseIcon style={{ fontSize: '12px' }} color="#378EF0" />
                                Remover endereço
                            </Button>
                        ) : null}
                    </div>

                </div>
            </form>
            <ModalGtinIvalido
                gtins={invalidGtins}
                show={invalidGtins?.length}
                totalGtins={setInputData(addressIndex, 'codeOrText').length}
                onConfirm={() => onClickRemoveInvalidGtins?.(addressIndex)}
                onCancel={() => onClickClearProductInput?.(addressIndex)}
            />
        </Panel>
    );
};
