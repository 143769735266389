import { trackPromise } from 'react-promise-tracker';
import api from '../utils/API';

const mapExtraContract = (data) => {
    const indexExtraContract = data.findIndex((item) => item.codigoCliente === 4916);
    let contractData;
    if (indexExtraContract > -1) {
        contractData = indexExtraContract === 0 ? data[1] : data[0];
        contractData = { ...contractData, painelAbras: data[indexExtraContract]?.servicoBi };
    }

    return contractData;
};

export const getUsuarioInformation = (token) => trackPromise(
    api({
        url: `${process.env.REACT_APP_CADASTROS_API_URL}/autenticacao`,
        method: 'GET',
        expectToken: true,
        token,
    }).then((resp) => resp.data),
);
export const getCliente = (login) => api({
    url: `${process.env.REACT_APP_CADASTROS_API_URL}/contratos/servicos/cliente`,
    method: 'GET',
    params: {
        username: login,
    },
    expectToken: true,
}).then((resp) => (resp.data ? resp.data[0] : 0));

export const getClienteCompleto = (login) => trackPromise(
    api({
        url: `${process.env.REACT_APP_CLIENTE_URL}/painel_geral/cliente/completo`,
        method: 'GET',
        params: {
            username: login,
        },
        expectToken: true,
    }).then((resp) => {
        let resposta = null;

        if (resp?.data.length > 1 && resp?.data?.some((item) => (item.codigoCliente === 4916))) {
            resposta = mapExtraContract(resp.data);
        } else {
            resposta = resp.data ? resp.data[0] : null;
        }
        return resposta;
    }),
);

export const getClienteCompletoByToken = (login, token) => trackPromise(
    api({
        url: `${process.env.REACT_APP_CLIENTE_URL}/painel_geral/cliente/completo`,
        method: 'GET',
        params: {
            username: login,
        },
        token,
    }).then((resp) => {
        let resposta = null;

        if (resp?.data.length > 1 && resp?.data?.some((item) => (item.codigoCliente === 4916))) {
            resposta = mapExtraContract(resp.data);
        } else {
            resposta = resp.data ? resp.data[0] : null;
        }
        return resposta;
    }),
);

export const getNameClienteById = (id, token) => trackPromise(
    api({
        url: `https://api.infopriceti.com.br/cadastros-api/clientes/${id}`,
        method: 'GET',
        token,
    }).then((resp) => resp.data || null),
);

export const getUsuarioByToken = (token) => api({
    url: `${process.env.REACT_APP_CADASTROS_API_URL}/usuarios/token/cadastra-senha`,
    method: 'GET',
    params: {
        token,
    },
}).then((resp) => {
    if (resp.response && resp.response.status === 412) {
        return resp;
    }
    return resp.data;
});

export const createSenha = (token, senha) => api({
    url: `${process.env.REACT_APP_CADASTROS_API_URL}/usuarios/token/cadastra-senha`,
    method: 'POST',
    params: {
        token,
        password: senha,
    },
}).then((resp) => resp.data);

export const resetSenha = (token, senha) => api({
    url: `${process.env.REACT_APP_CADASTROS_API_URL}/usuarios/token/esqueci-senha`,
    method: 'POST',
    params: {
        token,
        senha,
    },
}).then((resp) => resp.data);

export const getLinkResetSenha = (email) => api({
    url: `${process.env.REACT_APP_CADASTROS_API_URL}/usuarios/token/esqueci-senha`,
    method: 'POST',
    params: {
        email,
    },
}).then((resp) => resp.data);

export const getUsuarioByTokenEsqueciSenha = (token) => api({
    url: `${process.env.REACT_APP_CADASTROS_API_URL}/usuarios/token/esqueci-senha`,
    method: 'GET',
    params: {
        token,
    },
}).then((resp) => resp.data);
