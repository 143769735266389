import React from 'react';
import {
    Content,
} from 'rsuite';
import emptyStateImg from '../../../../assets/image/no-results.svg';

import styles from './EmptyStateSecond.module.scss';

export const EmptyStateSecond = () => (
    <Content className={styles['empty-state-content']}>
        <div className={styles['state-container']}>
            <div className={styles['text-wrapper']}>
                <p className={styles.title}>Sem dados para essa seleção</p>
                <p className={styles.text}>Revise os filtros e tente novamente</p>
            </div>
            <img src={emptyStateImg} alt="" />
        </div>
    </Content>
  );
