import React from 'react';
import { FoundResults, FoundResultsProps } from '../../atoms';
import './style.scss';

export type ToolsAndActionsProps = React.HtmlHTMLAttributes<HTMLDivElement> &
    Pick<FoundResultsProps, 'total'>;

const ToolsAndActions = ({
    children,
    className = '',
    total,
    ...props
}: ToolsAndActionsProps) => (
    <div
        className={['flex-align-center', 'tool-and-actions', className].join(
            ' ',
        )}
        {...props}
    >
        <FoundResults total={total} />
        {children}
    </div>
);

ToolsAndActions.Total = FoundResults;

export { ToolsAndActions };
