/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import {
    Container,
    Content,
    FlexboxGrid,
    Button,
    Icon,
    Whisper,
    Tooltip,
    Table,
    IconButton,
    DatePicker,
    Input,
    Notification,
    InputGroup,
} from 'rsuite';
import {
 format, parseISO, isBefore, isAfter,
} from 'date-fns';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { withRouter, useParams } from 'react-router-dom';
import { LoadingSpiner } from '../../../../../components/LoadingSpinner';
import { colunasTabelaBlocoOfertas } from './Components/Columns';
import Modal from '../../../../../components/NewModalConfirmacao';
import ModalConfirmacao from '../../../../../components/ModalConfirmacao';
import Steps from '../Components/Steps';
import { getCampaign } from '../services';
import {
    saveBloco,
    listBlocos,
    deleteBloco,
    listBlocoSelecionado,
    updateBloco,
} from './services';
import Header from '../Components/Header';
import { getOfertas } from '../Ofertas/services';
import { getLojasParticipantes } from '../LojasParticipantes/services';

const {
 Column, HeaderCell, Cell, Pagination,
} = Table;

const BlocoOfertas = ({ modalBloco, setModalBloco, campanha }) => (
    <FlexboxGrid className="bloco-ofertas-modal" justify="space-between">
        <FlexboxGrid.Item colspan={24}>
            <span className="label">Nome do Bloco de Ofertas</span>
            <InputGroup inside className="input-outline-no-icon">
                <Input
                    value={modalBloco.name}
                    onChange={(value) => setModalBloco((prevState) => ({
                            ...prevState,
                            name: value,
                        }))}
                />
            </InputGroup>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={11}>
            <span className="label">Início de circulação</span>
            <DatePicker
                oneTap
                format="DD/MM/YYYY"
                value={modalBloco.start_date}
                disabledDate={(date) => isBefore(date, campanha.start_date)}
                onChange={(value) => setModalBloco((prevState) => ({
                        ...prevState,
                        start_date: value,
                    }))}
                style={{ width: '100%' }}
            />
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={11}>
            <span className="label">Fim de circulação</span>
            <DatePicker
                oneTap
                format="DD/MM/YYYY"
                value={modalBloco.end_date}
                disabledDate={(date) => isAfter(date, campanha.end_date)}
                onChange={(value) => setModalBloco((prevState) => ({
                        ...prevState,
                        end_date: value,
                    }))}
                style={{ width: '100%' }}
            />
        </FlexboxGrid.Item>
    </FlexboxGrid>
);

const BlocosOfertas = ({ history }) => {
    const { id } = useParams();
    const [blocosOfertas, setBlocosOfertas] = useState({ content: [] });
    const [deleteMessage, setDeleteMessage] = useState('');
    const [blocoToDelete, setBlocoToDelete] = useState(null);
    const [showModalConfirmacaoExclusao, setShowModalConfirmacaoExclusao]
        = useState(false);
    const [page, setPage] = useState(0);
    const [size, setSize] = useState(20);
    const [showModalBlocoOfertas, setShowModalBlocoOfertas] = useState(false);
    const [showEditBloco, setShowEditBloco] = useState(false);
    const [modalBloco, setModalBloco] = useState({
        name: '',
        start_date: null,
        end_date: null,
    });
    const [campanha, setCampanha] = useState({});
    const [ofertas, setOfertas] = useState({ content: [] });
    const [lojasParticipantes, setLojasParticipantes] = useState({
        content: [],
    });

    const listBlocosOfertas = async () => {
        const response = await listBlocos(id);
        if (response) setBlocosOfertas(response);
    };

    const confirmBloco = async () => {
        if (isBefore(modalBloco.end_date, campanha.start_date)) {
            Notification.error({
                title: 'Erro',
                duration: 6000,
                description: (
                    <div>
                        <p>
                            Data final do bloco de oferta menor do que a
                            vigência inicial da campanha
                        </p>
                    </div>
                ),
            });
        } else {
            const resp = await saveBloco({ data: modalBloco, id });
            if (resp) setShowModalBlocoOfertas(false);
            setModalBloco((prevState) => ({
                ...prevState,
                name: '',
                start_date: campanha.start_date,
                end_date: campanha.end_date,
            }));
            await listBlocosOfertas();
        }
    };

    const closeBloco = () => {
        setShowEditBloco(false);
        setShowModalBlocoOfertas(false);
    };

    const openEditBloco = async (bloco) => {
        setShowEditBloco(true);
        const response = await listBlocoSelecionado(id, bloco.id);
        response.start_date = parseISO(response.start_date);
        response.end_date = parseISO(response.end_date);
        setModalBloco(response);
        setShowModalBlocoOfertas(true);
    };

    const editBloco = async () => {
        const response = await updateBloco({ data: modalBloco, id });
        if (response) {
            setShowEditBloco(false);
            setShowModalBlocoOfertas(false);
            listBlocosOfertas();
        }
    };

    const removeBloco = (id) => {
        setDeleteMessage(
            'Você está tentando deletar um bloco de oferta. Você confirma essa ação?',
        );
        setShowModalConfirmacaoExclusao(true);
        setBlocoToDelete(id);
    };

    const closeExclusao = () => {
        setDeleteMessage('');
        setShowModalConfirmacaoExclusao(false);
        setBlocoToDelete(null);
    };

    const confirmExclusao = () => {
        deleteBloco(id, blocoToDelete).then(() => {
            Notification.success({
                title: 'Notificação',
                duration: 6000,
                description: (
                    <div>
                        <p>Bloco de Oferta excluído com sucesso.</p>
                    </div>
                ),
            });
            listBlocosOfertas();
            closeExclusao();
        });
    };

    const back = () => {
        history.push(`/ipa/campanhas/cadastro/dados-campanha/${id}`);
    };

    const next = () => {
        history.push(`/ipa/campanhas/cadastro/lojas-participantes/${id}`);
    };

    const listCampaign = async () => {
        const response = await getCampaign(id);
        if (response) {
            setCampanha({
                ...response.data,
                start_date: parseISO(response.data.starting_date),
                end_date: parseISO(response.data.expiration_date),
            });
            setModalBloco((oldState) => ({
                ...oldState,
                start_date: parseISO(response.data.starting_date),
                end_date: parseISO(response.data.expiration_date),
            }));
        }
    };

    const listOfertas = async () => {
        const response = await getOfertas({
            id,
            page: 0,
            size: 20,
        });
        if (response) {
            setOfertas(response);
        }
    };

    const listLojasParticipantes = async () => {
        const response = await getLojasParticipantes({
            id,
            page: 0,
            size: 20,
        });
        if (response) setLojasParticipantes(response);
    };

    useEffect(() => {
        listCampaign();
        listOfertas();
        listLojasParticipantes();
    }, []);

    useEffect(() => {
        listBlocosOfertas();
    }, [page, size]);

    return (
        <Container className="main-container cadastro-blocos-ofertas-page">
            <Steps
                rota={history.location.pathname}
                campanhaId={id}
                status={campanha?.status}
                lojas={lojasParticipantes?.content?.length}
            />
            <LoadingSpiner size="md" />
            <Header
                history={history}
                id={id}
                name={campanha?.name}
                status={campanha?.status}
                backToCampaign={back}
                startingDate={campanha?.starting_date}
                expirationDate={campanha?.expiration_date}
                blocosOfertas={blocosOfertas?.totalElements}
                lojasParticipantes={lojasParticipantes?.totalElements}
                ofertas={ofertas?.totalElements}
            />
            <Content className="bloco-de-ofertas">
                <FlexboxGrid justify="space-between">
                    <FlexboxGrid.Item>
                        <h3>
                            Bloco de Ofertas
                            <Whisper
                                speaker={(
                                    <Tooltip>
                                        Você pode criar blocos de ofertas dentro
                                        da campanha com vigência e metas
                                        específicas.
                                    </Tooltip>
                                  )}
                            >
                                <span>
                                    <Icon icon="info" />
                                </span>
                            </Whisper>
                        </h3>
                    </FlexboxGrid.Item>
                    {!blocosOfertas.content.length ? null : (
                        <FlexboxGrid.Item>
                            <Button
                                title="Nova regra"
                                block
                                appearance="primary"
                                onClick={() => setShowModalBlocoOfertas(true)}
                                disabled={
                                    campanha.status === 'CURRENT'
                                    || campanha.status === 'CLOSED'
                                }
                            >
                                NOVO BLOCO
                            </Button>
                        </FlexboxGrid.Item>
                    )}

                    <FlexboxGrid.Item
                        className="blocos-de-oferta-container"
                        colspan={24}
                    >
                        {!blocosOfertas.content.length ? (
                            <FlexboxGrid
                                className="no-data"
                                justify="center"
                                align="middle"
                            >
                                <FlexboxGrid.Item>
                                    <p>
                                        Você ainda não criou nenhum bloco de
                                        oferta.
                                    </p>
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item>
                                    <Button
                                        title="Criar bloco de oferta"
                                        block
                                        appearance="primary"
                                        onClick={() => setShowModalBlocoOfertas(true)}
                                        disabled={
                                            campanha.status === 'CURRENT'
                                            || campanha.status === 'CLOSED'
                                        }
                                    >
                                        CRIAR BLOCO DE OFERTA
                                    </Button>
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item>
                                    <Button
                                        className="skip-button"
                                        appearance="link"
                                        onClick={next}
                                    >
                                        Pular esta etapa
                                    </Button>
                                </FlexboxGrid.Item>
                            </FlexboxGrid>
                        ) : (
                            <FlexboxGrid.Item colspan={24}>
                                <div className="campanhas-table-container">
                                    <Table
                                        className="table"
                                        data={blocosOfertas.content}
                                        autoHeight
                                    >
                                        {colunasTabelaBlocoOfertas.map(
                                            (coluna) => (!coluna.dataKey ? (
                                                <Column
                                                    width={120}
                                                    fixed="right"
                                                    key={Math.random()}
                                                >
                                                    <HeaderCell />

                                                    <Cell className="table__edit-button">
                                                        {(rowData) => (
                                                            <span className="mg-5">
                                                                <IconButton
                                                                    title="Editar"
                                                                    icon={
                                                                        <Icon icon="edit2" />
                                                                        }
                                                                    size="md"
                                                                    onClick={() => openEditBloco(
                                                                                rowData,
                                                                            )}
                                                                    disabled={
                                                                            campanha.status
                                                                                === 'CURRENT'
                                                                            || campanha.status
                                                                                === 'CLOSED'
                                                                        }
                                                                />
                                                                <IconButton
                                                                    title="Deletar"
                                                                    size="md"
                                                                    icon={
                                                                        <Icon icon="trash2" />
                                                                        }
                                                                    onClick={() => removeBloco(
                                                                                rowData.id,
                                                                            )}
                                                                    disabled={
                                                                            campanha.status
                                                                                === 'CURRENT'
                                                                            || campanha.status
                                                                                === 'CLOSED'
                                                                        }
                                                                />
                                                            </span>
                                                            )}
                                                    </Cell>
                                                </Column>
                                                ) : (
                                                    <Column
                                                        flexGrow={
                                                            coluna.flexGrow
                                                        }
                                                        sortable={
                                                            coluna.sortable
                                                        }
                                                        align={coluna.align}
                                                        fixed={coluna.fixed}
                                                        width={coluna.width}
                                                        key={Math.random()}
                                                    >
                                                        <HeaderCell>
                                                            {coluna.headerCell}
                                                        </HeaderCell>
                                                        {coluna.dataKey
                                                        === 'start_date' ? (
                                                            <Cell dataKey="recurrence.expirationDate">
                                                                {(rowData) => (rowData.start_date
                                                                        ? format(
                                                                              parseISO(
                                                                                  rowData.start_date,
                                                                              ),
                                                                              'dd/MM/yyyy',
                                                                          )
                                                                        : '--')}
                                                            </Cell>
                                                        ) : coluna.dataKey
                                                          === 'end_date' ? (
                                                              <Cell dataKey="recurrence.expirationDate">
                                                                  {(rowData) => (rowData.end_date
                                                                        ? format(
                                                                              parseISO(
                                                                                  rowData.end_date,
                                                                              ),
                                                                              'dd/MM/yyyy',
                                                                          )
                                                                        : '--')}
                                                              </Cell>
                                                        ) : (
                                                            <Cell
                                                                dataKey={
                                                                    coluna.dataKey
                                                                }
                                                            />
                                                        )}
                                                    </Column>
                                                )),
                                        )}
                                    </Table>
                                    <Pagination
                                        lengthMenu={[
                                            {
                                                value: 20,
                                                label: 20,
                                            },
                                            {
                                                value: 40,
                                                label: 40,
                                            },
                                        ]}
                                        activePage={blocosOfertas.number + 1}
                                        displayLength={blocosOfertas.size}
                                        total={blocosOfertas.totalElements}
                                        onChangePage={(value) => setPage(value - 1)}
                                        onChangeLength={(value) => setSize(value)}
                                    />
                                </div>
                            </FlexboxGrid.Item>
                        )}
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </Content>
            <Content className="navegacao-blocos-ofertas">
                <FlexboxGrid justify="space-between">
                    <FlexboxGrid.Item>
                        <Button appearance="link" onClick={back}>
                            <ArrowBackIcon />
                            {' '}
                            Voltar para dados da campanha
                        </Button>
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item>
                        <Button appearance="primary" onClick={next}>
                            <ArrowForwardIcon />
                            {' '}
                            {campanha.status === 'CURRENT'
                            || campanha.status === 'CLOSED'
                                ? 'LOJAS PARTICIPANTES'
                                : 'ESCOLHER LOJAS'}
                        </Button>
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </Content>
            <Modal
                show={showModalBlocoOfertas}
                onConfirm={showEditBloco ? editBloco : confirmBloco}
                onCancel={closeBloco}
                title={
                    showEditBloco ? 'Editar Bloco' : 'Criar Bloco de Ofertas'
                }
                message={BlocoOfertas({ modalBloco, setModalBloco, campanha })}
                confirmButton={
                    showEditBloco ? 'Salvar alterações' : 'Criar Bloco'
                }
                cancelButton="Cancelar"
                width="432px"
            />
            <ModalConfirmacao
                show={showModalConfirmacaoExclusao}
                onConfirm={confirmExclusao}
                onCancel={closeExclusao}
                title="Deletar Bloco de oferta"
                message={deleteMessage}
                confirmButton="Confirmar"
                cancelButton="Cancelar"
            />
        </Container>
    );
};

export default withRouter(BlocosOfertas);
