import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../@types/RootState';

type State = number;

const initialState: State = 0;

const slice = createSlice({
    name: 'ipa/gerenciador/totalElements',
    initialState,
    reducers: {
        SET_GERENCIADOR_TOTAL_ELEMENTS: (
            _state,
            { payload }: PayloadAction<State>,
        ) => payload,
        RESET_GERENCIADOR_TOTAL_ELEMENTS: () => initialState,
    },
});

const selectorTotalElements = (state: RootState) => {
    return state.gerenciadorPrecosReducer.totalElements;
};

const { RESET_GERENCIADOR_TOTAL_ELEMENTS, SET_GERENCIADOR_TOTAL_ELEMENTS } =
    slice.actions;

export {
    RESET_GERENCIADOR_TOTAL_ELEMENTS,
    SET_GERENCIADOR_TOTAL_ELEMENTS,
    initialState,
    selectorTotalElements,
};

export default slice.reducer;
