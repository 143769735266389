import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
    Input,
    InputGroup,
    Modal,
    Radio,
    RadioGroup,
    SelectPicker,
    Tooltip,
    Whisper,
} from 'rsuite';
import { isGtinValid } from '../../../../MPDV/RevisaoLista/utils';

import {
    LoadingMenu,
    LoadingSpinerLocal,
} from '../../../../../../components/LoadingSpinner';
import {
    setCanEditSave,
} from '../../../../../../reducers/reducerOnDemand';
import useRenderInputEditStatus from '../../Hooks/useRenderInputEditStatus';

import styles from './styles.module.scss';

interface IRequestEditContent {
    onOpenUF?: (arg0: string) => void;
    onOpenCidade?: (arg0: string) => void;
    onSearchCidade?: (arg0: string, arg1: string) => void;
    onChangeRadioProduct?: (arg0: string) => void;
    onChangeRaio?: (arg0: string, arg1: number) => void;
    onSelectRegion?: (arg0: string, arg1: string) => void;
    onBlurCep?: (arg0: string) => void;
    onChangeCep?: (arg0: string) => void;
    onChangeGTIN?: (arg0: string, arg1: string) => void;
    onChangeKeyword?: (arg0: string, arg1: string) => void;
    onChangeLogradouro?: (arg0: string, arg1: string) => void;
    onChangeNumeroLogradouro?: (arg0: string, arg1: string) => void;
    dataUF?: any;
    dataCidade?: any;
    dataValue?: any;
    errorCEP?: boolean;
    cepLoading?: boolean;
}

export const RequestEditContent = ({
    onSelectRegion,
    onChangeRadioProduct,
    onChangeRaio,
    onOpenUF,
    onOpenCidade,
    onSearchCidade,
    onBlurCep,
    onChangeCep,
    onChangeGTIN,
    onChangeKeyword,
    onChangeLogradouro,
    onChangeNumeroLogradouro,
    dataUF,
    dataCidade,
    dataValue,
    errorCEP,
    cepLoading,
}: IRequestEditContent) => {
    const productType = dataValue?.gtin !== '' && dataValue?.gtin !== null ? 'gtin' : 'palavraChave';

    const [gtinStatus, setGtinStatus] = useState(true);
    const [radioProduct, setRadioProduct] = useState(productType);

    const reduceDispatch = useDispatch();

    const {
        renderIconGtinStatus,
        renderIconKeywordStatus,
        renderInputKeywordStyle,
        renderInputGtinStyle,
    } = useRenderInputEditStatus();

    const filterInvalidGtin = (value: string) => {
        const hasOnlyGtinNumbers = /^\d+$/.test(value);
        onChangeGTIN?.('gtin', value);

        if (hasOnlyGtinNumbers) {
            const validateGtins = isGtinValid(value);
            setGtinStatus(validateGtins);
        } else {
            setGtinStatus(false);
        }
    };

    const handleOnChangeRadioProduct = (value: string) => {
        setRadioProduct(value);
        onChangeRadioProduct?.(value);
    };

    useEffect(() => {
        const radioProductType = radioProduct === 'gtin' ? gtinStatus : dataValue?.palavraChave?.length > 1;

        if (radioProductType) {
            reduceDispatch(
                setCanEditSave({
                    name: 'canEditSave',
                    data: true,
                }),
            );
        } else {
            reduceDispatch(
                setCanEditSave({
                    name: 'canEditSave',
                    data: false,
                }),
            );
        }
    }, [gtinStatus, dataValue?.palavraChave, dataValue?.cep, errorCEP, radioProduct]);

    const renderMenu = (menu) => (
        <span>
            <LoadingMenu area="request-filter" />
            {menu}
        </span>
    );

    return (
        <Modal.Body className={styles.body}>
            <h4 className={styles['content-title']}>
                Editar solicitação
            </h4>
            <p className={styles['content-subtitle']}>
                Alterações no código de barras, palavra chave, UF, cidade ou raio irão gerar um novo ID para a solicitação.
            </p>
            <form className={styles['edit-form']}>
                <RadioGroup
                    inline
                    name="radioList"
                    className={styles['edit-radio-group']}
                    value={radioProduct}
                    onChange={(value) => handleOnChangeRadioProduct(value)}
                >
                    <p className={styles['item-title']}>Produto</p>
                    <Radio value="gtin">
                        Código de barras
                        <InputGroup inside className={styles['regular-input-group']}>
                            <Input
                                className={
                                    styles[renderInputGtinStyle(radioProduct !== 'gtin', gtinStatus, dataValue?.gtin?.length)]
                                }
                                disabled={radioProduct !== 'gtin'}
                                value={dataValue?.gtin}
                                onChange={filterInvalidGtin}
                            />
                            <InputGroup.Button className={styles['input-button']}>
                                {renderIconGtinStatus(radioProduct !== 'gtin', gtinStatus, dataValue?.gtin?.length)}
                            </InputGroup.Button>
                        </InputGroup>
                    </Radio>
                    <Radio value="palavraChave">
                        Palavras-chave
                        <InputGroup inside className={styles['regular-input-group']}>
                            <Input
                                className={styles[
                                    renderInputKeywordStyle(radioProduct !== 'palavraChave', dataValue?.palavraChave?.length)
                                ]}
                                disabled={radioProduct !== 'palavraChave'}
                                placeholder="e.g. Arroz"
                                value={dataValue?.palavraChave}
                                onChange={(value) => onChangeKeyword?.('palavraChave', value)}
                            />
                            <InputGroup.Button className={styles['input-button']}>
                                {renderIconKeywordStatus(
                                    radioProduct !== 'palavraChave',
                                    dataValue?.palavraChave?.length,
                                )}
                            </InputGroup.Button>
                        </InputGroup>
                    </Radio>
                </RadioGroup>
                <div className={styles['address-wrapper']}>
                    <p className={styles['item-title']}>Endereço</p>
                    <div className={styles['address-first-section']}>
                        <div className={styles['input-uf-wrapper']}>
                            <label htmlFor="">UF</label>
                            <SelectPicker
                                searchable={false}
                                data={dataUF}
                                value={dataValue?.uf}
                                renderMenu={renderMenu}
                                onOpen={() => onOpenUF?.(dataValue?.cidade.toUpperCase())}
                                onSelect={(value) => onSelectRegion?.('uf', value)}
                            />
                        </div>
                        <div className={styles['input-city-wrapper']}>
                            <label htmlFor="">Cidade</label>
                            <SelectPicker
                                data={dataCidade}
                                value={dataValue?.cidade.toUpperCase()}
                                renderMenu={renderMenu}
                                onOpen={() => onOpenCidade?.(dataValue?.uf)}
                                onSelect={(value) => onSelectRegion?.('cidade', value)}
                                onSearch={(value) => onSearchCidade?.(dataValue?.uf, value)}
                            />
                        </div>
                        <div className={styles['radius-wrapper']}>
                            <label>Raio</label>
                            <RadioGroup
                                inline
                                name="radioList"
                                value={dataValue?.raio.toString()}
                                onChange={(value) => onChangeRaio?.('raio', Number(value))}
                            >
                                <Radio value="1">
                                    1km
                                </Radio>
                                <Radio value="3">
                                    3km
                                </Radio>
                                <Radio value="10">
                                    10km
                                </Radio>
                            </RadioGroup>
                        </div>
                    </div>
                    <div className={styles['blue-address-wrapper']}>
                        <p className={styles['item-title']}>Editar endereço específico (opcional)</p>
                        <div className={styles['address-first-section']}>

                            <div className={styles['input-cep-wrapper']}>
                                <label htmlFor="cep">CEP</label>
                                <InputGroup inside>
                                    <Input
                                        name="cep"
                                        className={styles[errorCEP ? 'alert-input' : 'regular-input']}
                                        value={dataValue?.cep}
                                        onBlur={(v: string) => onBlurCep?.(v.target.value)}
                                        onChange={onChangeCep}
                                    />
                                    {errorCEP && !cepLoading ? (
                                        <Whisper
                                            trigger="hover"
                                            placement="topEnd"
                                            speaker={
                                                <Tooltip>Código postal inválido ou não pertence a cidade selecionda</Tooltip>
                                            }
                                        >
                                            <ReportProblemIcon className={styles['alert-icon']} />
                                        </Whisper>
                                    ) : null}
                                    <LoadingSpinerLocal loading={cepLoading} size="sm" height="18px" position={undefined} />
                                </InputGroup>
                            </div>
                            <div className={styles['input-address-wrapper']}>
                                <label htmlFor="logradouro">Logradouro</label>
                                <Input
                                    name="logradouro"
                                    className={styles['regular-input']}
                                    value={dataValue?.logradouro}
                                    onChange={(value) => onChangeLogradouro?.('logradouro', value)}
                                />
                            </div>
                            <div className={styles['input-number-wrapper']}>
                                <label htmlFor="number">Número</label>
                                <Input
                                    name="number"
                                    className={styles['regular-input']}
                                    value={dataValue?.numeroLogradouro}
                                    onChange={(value) => onChangeNumeroLogradouro?.('numeroLogradouro', value)}
                                />
                            </div>
                        </div>

                    </div>
                </div>
            </form>
        </Modal.Body>
  );
};
