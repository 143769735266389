import classNames from 'classnames';
import React, { forwardRef } from 'react';
import { BUTONS_SKINS } from '../../../../../../components/ButtonPrimary';
import './style.scss';

export interface TagProps
    extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    skin?: BUTONS_SKINS;
    size?: 'large' | 'medium' | 'small' | 'xsmall';
    outlined?: boolean;
}

export const Tag = forwardRef(
    (
        {
            children,
            className = '',
            skin = 'gray',
            size = 'xsmall',
            outlined = false,
            ...props
        }: TagProps,
        ref: React.LegacyRef<HTMLButtonElement>,
    ) => (
        <span
            ref={ref}
            className={classNames(
                'tag',
                `tag-${size}`,
                `info-skin-${skin}`,
                outlined && `info-skin-ghost-${skin}`,
                className,
            )}
            {...props}
        >
            {children}
        </span>
    ),
);
