import React from 'react';
import BaseCell, { BaseCellProps } from '../BaseCell/BaseCell';

const { MainContainer, SubContainer } = BaseCell;

type QtdeCellProps = BaseCellProps;

const QtdeCell = (props: QtdeCellProps) => {
    return (
        <BaseCell {...props}>
            <MainContainer>1</MainContainer>
            <SubContainer>UN</SubContainer>
        </BaseCell>
    );
};

export default QtdeCell;
