import React from 'react';
import infoIcon from '../../../../../../assets/icons/icon_info.svg';
import styles from './styles.module.scss';

export const SendNewRequestInfo = () => (
    <div className={styles['send-new-request']}>
        <h6 className={styles.title}>Enviar novas solicitações</h6>
        <div className={styles['content-wrapper']}>
            <img src={infoIcon} alt="" />
            <p className={styles['content-text']}>
                Preencha os campos e adicione o endereço,
                em seguida coloque as palavras-chaves e/ou os códigos de barras que serão solicitadas para aquele endereço.
                Tenha sempre em mente, que cada palavra-chave ou código de barras será uma solicitação diferente.
            </p>
        </div>
    </div>
    );
