import classNames from 'classnames';
import React from 'react';
import { Modal } from 'rsuite';
import { ModalProps } from 'rsuite/lib/Modal';
import { User } from '../../@types';
import gif from '../../assets/image/ipa-banner-lead.gif';
import { ReactComponent as Logo } from '../../assets/logo/logo-ipa-branco.svg';
import { sendLead } from '../../pages/ISA/InfoPanel/InfoPanel/utils';
import { trackEvent } from '../../utils/MatomoConfig';
import styles from './ModalIPALead.module.scss';

type ModalIPALeadProps = ModalProps & {
    user?: User;
};

const ModalIPALead = ({ user, className, ...props }: ModalIPALeadProps) => {
    const handleClick = async (e: React.MouseEvent<Element, MouseEvent>) => {
        // eslint-disable-next-line no-alert
        const confirm = window.confirm(
            'Deseja entrar em contato com a nossa equipe?',
        );
        if (!confirm) return;
        await sendLead(user, 'IPA');
        trackEvent('Upsell/Crossell', 'IPA');
        window.open(
            'https://wa.me/551137779670?text=Ol%C3%A1%21+Quero+saber+mais+sobre+o+IPA+Software+de+Precifica%C3%A7%C3%A3o%21',
        );
        props.onHide?.(e);
    };

    return (
        <Modal className={classNames(styles.modal, className)} {...props}>
            <Modal.Body className={styles.modal__content}>
                <Logo className={styles.logo} />
                <h2 className={styles.title}>
                    <strong>Automatize seus preços </strong>
                    com governança no IPA - Software de Precificação
                </h2>
                <div className={styles.img__container}>
                    <img
                        src={gif}
                        alt="Imagem animada demonstrando o funcionamento da aplicação"
                    />
                    <span>
                        Preço certo com base em competitividade ou margem nos
                        seus produtos.
                    </span>
                </div>
                <ul>
                    <li>
                        Especialistas dedicados para definição de estratégias de
                        pricing
                    </li>
                    <li>
                        Painel de indicadores de vendas, competitividade e
                        performance da loja
                    </li>
                    <li>Previsão de demanda com base na alteração de preço</li>
                    <li>
                        Regras de preço por margem ou competitividade e
                        campanhas promocionais
                    </li>
                </ul>
                <button onClick={handleClick}>OTIMIZE MEU PRICING</button>
            </Modal.Body>
        </Modal>
    );
};

export default ModalIPALead;
