import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../@types/RootState';

export type GerenciadorModalsBooleanState = Partial<Record<string, boolean>>;

const initialState: GerenciadorModalsBooleanState = {};

const slice = createSlice({
    name: 'ipa.gerenciador.modals',
    initialState,
    reducers: {
        OPEN_MODAL(state, action: PayloadAction<string>) {
            state[action.payload] = true;
        },
        CLOSE_MODAL(state, action: PayloadAction<string>) {
            state[action.payload] = false;
        },
    },
});

const selectorModalsBoolean = (state: RootState) => {
    return state.gerenciadorPrecosReducer.modalsBoolean;
};

const { OPEN_MODAL, CLOSE_MODAL } = slice.actions;

export { CLOSE_MODAL, OPEN_MODAL, selectorModalsBoolean };

export default slice.reducer;
