import React, { ReactNode } from 'react';

import styles from './ForecastNumbers.module.scss';

export interface IForecastNumbersMainValue {
    children: ReactNode;
    margin?: string;
    fontSize?: string;
}

export const ForecastNumbersMainValue = ({
    children,
    margin = '0',
    fontSize = '24px',
}: IForecastNumbersMainValue) => (

    <p className={styles['main-value']} style={{ margin, fontSize }}>
        {children}
    </p>
);
