import { useQuery } from '@tanstack/react-query';
import { useCallback, useMemo, useState } from 'react';
import { usePagination } from '.';
import { FilterValueState, useFilterValue } from '../../../../../../components';
import { useSingleModal, useSort } from '../../../../../../hooks';
import { checkIfIsLoading } from '../../../../../../utils';
import {
    EditRuleCompetitorsApi,
    GET_DATA_MODEL,
    GET_EDIT_COMPETITORS_MODAL_DATA,
    GET_SAVE_RULE_EDIT_COMPETITORS_MODEL,
} from '../components';

type UseEditRuleCompetitorsProps = {
    storeId: string[];
    initialValues?: FilterValueState;
};

const initialSortColumn = 'storeId';

export const useEditRuleCompetitors = ({
    storeId = [],
    initialValues = {},
}: UseEditRuleCompetitorsProps) => {
    const [total, setTotal] = useState(0);

    const [modal, { close: closeModal, open: openModal }] = useSingleModal();

    const pagination = usePagination({ total });

    const [
        values,
        { setState: setFilters, reset: resetFilters, select: selectFilter },
    ] = useFilterValue(initialValues);

    const sort = useSort<EditRuleCompetitorsApi['rowKey']>({
        initialColumn: initialSortColumn,
    });

    const params = useMemo<EditRuleCompetitorsApi['params']>(
        () => GET_DATA_MODEL({ sort, pagination }),
        [sort, pagination],
    );

    const handleSuccess = useCallback(
        ({ totalElements }: EditRuleCompetitorsApi['response']) => {
            setTotal(totalElements);
        },
        [],
    );

    const { data, fetchStatus } = useQuery({
        queryKey: ['ipa.rule.edit.competitors', params],
        queryFn: async () => {
            return await GET_EDIT_COMPETITORS_MODAL_DATA(params, { storeId });
        },
        onSuccess: handleSuccess,
        enabled: modal,
    });

    const handleResetModal = useCallback(() => {
        closeModal();
        setTimeout(() => {
            if (Object.keys(values).length) {
                setFilters(initialValues);
            } else resetFilters();
        }, 200);
    }, [values, initialValues, setFilters, resetFilters, closeModal]);

    const memoModal = useMemo(
        () => ({
            show: modal,
            onShow: openModal,
            onHide: handleResetModal,
            onSubmit: closeModal,
        }),
        [modal, openModal, closeModal, handleResetModal],
    );

    const memoFilters = useMemo(
        () => ({
            values: values,
            select: selectFilter,
            reset: resetFilters,
        }),
        [selectFilter, values, resetFilters],
    );

    const memoTable = useMemo(
        () => ({
            pagination,
            filters: memoFilters,
            data: data?.content || [],
            loading: checkIfIsLoading(fetchStatus),
            ...sort,
        }),
        [pagination, data, memoFilters, fetchStatus, sort],
    );

    const model = useMemo(() => {
        return GET_SAVE_RULE_EDIT_COMPETITORS_MODEL(memoTable.filters.values);
    }, [memoTable.filters.values]);

    return useMemo(
        () => ({
            modal: memoModal,
            table: memoTable,
            model,
        }),
        [memoTable, memoModal, model],
    );
};
