import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { GerenciadorPrecos } from '../../../@types/GerenciadorPrecos';
import { RootState } from '../../../@types/RootState';

type Keys = GerenciadorPrecos.FilterKeys;

export type FiltersSearchState = Partial<Record<Keys, string>>;

const initialState: FiltersSearchState = {};

const slice = createSlice({
    name: 'ipa.filters.search',
    initialState,
    reducers: {
        RESET_FILTERS_SEARCH: () => initialState,
        SET_FILTERS_SEARCH_BY_KEY: (
            state,
            { payload }: PayloadAction<{ key: Keys; query: string }>,
        ) => ({
            ...state,
            [payload.key]: payload.query,
        }),
        RESET_FILTERS_SEARCH_BY_KEY: (
            state,
            { payload }: PayloadAction<{ key: Keys }>,
        ) => ({
            ...state,
            [payload.key]: '',
        }),
    },
});

const selectorFiltersSearch = (state: RootState) => {
    return state.gerenciadorPrecosReducer.filters.search;
};

const {
    RESET_FILTERS_SEARCH,
    SET_FILTERS_SEARCH_BY_KEY,
    RESET_FILTERS_SEARCH_BY_KEY,
} = slice.actions;

export {
    RESET_FILTERS_SEARCH,
    RESET_FILTERS_SEARCH_BY_KEY,
    SET_FILTERS_SEARCH_BY_KEY,
    initialState,
    selectorFiltersSearch,
};

export default slice.reducer;
