import Immutable from 'seamless-immutable';
import {
    SET_DATA_C1,
    SET_DATA_C2,
    SET_DATA_C3,
    SET_DATA_C4,
    SET_SHARED_STATE_DATA,
    SET_DATA_MAP_PINPOINTS,
} from '../actions/actionsShareAnalysis';

const shareAnalysisInitialState = Immutable({
    filtrosAdicionados: [],
    filtrosData: {
        preco: {
            name: '',
        },
        tipoPreco: {
            lista: [],
            value: [],
            cache: [],
        },
        origemPreco: {
            lista: [],
            value: [],
            cache: [],
        },
        fabricante: {
            lista: [],
            value: [],
            cache: [],
        },
        tipoProduto: {
            lista: [],
            value: [],
            cache: [],
        },
        categoria: {
            lista: [],
            value: [],
            cache: [],
        },
        secao: {
            lista: [],
            value: [],
            cache: [],
        },
        rede: {
            lista: [],
            value: [],
            cache: [],
            erro: null,
        },
        tipoLoja: {
            lista: [],
            value: [],
            cache: [],
        },
        uf: {
            lista: [],
            value: [],
            cache: [],
        },
        cidade: {
            lista: [],
            value: [],
            cache: [],
        },
        produto: {
            lista: [],
            value: [],
            cache: [],
            limitProducts: null,
        },
        dataInicio: null,
        dataFim: null,
        endereco: {
            lista: [],
            value: '',
            token: null,
            latitude: null,
            longitude: null,
            erro: null,
        },
        raio: {
            lista: [
                {
                    label: '2 KM',
                    value: 2,
                },
                {
                    label: '3 KM',
                    value: 3,
                },
                {
                    label: '4 KM',
                    value: 4,
                },
                {
                    label: '5 KM',
                    value: 5,
                },
                {
                    label: '6 KM',
                    value: 6,
                },
                {
                    label: '7 KM',
                    value: 7,
                },
                {
                    label: '8 KM',
                    value: 8,
                },
                {
                    label: '9 KM',
                    value: 9,
                },
                {
                    label: '10 KM',
                    value: 10,
                },
            ],
            value: null,
        },
    },
    boxplotPublic: {
        data: null,
        type: 'produto',
        sortColumn: null,
    },
    timeEvolutionPublic: {
        data: null,
    },
    mapPublic: {
        data: null,
        type: 'estadual',
    },
    topFivePublic: {
        data: {},
    },
    sharedStatePublic: {
        data: {},
    },
    pinpointsPublic: {
        markers: {},
    },
});

function shareAnalysisDataReducer(state = shareAnalysisInitialState, action) {
    switch (action.type) {
        case SET_DATA_C1:
            return state.merge({
                ...state,
                timeEvolutionPublic: {
                    data: action.data,
                },
            });
        case SET_DATA_C2:
            return state.merge({
                ...state,
                boxplotPublic: {
                    data: action.data,
                },
            });
        case SET_DATA_C3:
            return state.merge({
                ...state,
                mapPublic: {
                    data: action.data,
                },
            });
        case SET_DATA_C4:
            return state.merge({
                ...state,
                topFivePublic: {
                    data: action.data,
                },
            });
        case SET_SHARED_STATE_DATA:
            return state.merge({
                ...state,
                sharedStatePublic: {
                    data: action.data,
                },
            });
        case SET_DATA_MAP_PINPOINTS:
            return state.merge({
                ...state,
                pinpointsPublic: {
                    ...state.pinpointsPublic,
                    markers: action.data.content.map((item) => ({
                        lat: item.latitude,
                        lng: item.longitude,
                        rede: item.rede,
                        price: item.preco_medio,
                    })),
                },
            });
        default:
            return state;
    }
}

export default shareAnalysisDataReducer;
