import classNames from 'classnames';
import React from 'react';
import styles from './Skeleton.module.scss';

enum SIZES {
    'sm' = '4px',
    'pill' = '99999px',
    'circle' = '50%',
}

type SkeletonProps = React.HtmlHTMLAttributes<HTMLDivElement> & {
    width?: number | string;
    height?: number | string;
    rounded?: keyof typeof SIZES;
};

export const Skeleton = ({
    className,
    width,
    height,
    style,
    rounded = 'sm',
    ...props
}: SkeletonProps) => (
    <div
        className={classNames(styles.skeleton, className)}
        style={{
            width: typeof width === 'string' ? width : `${width}px`,
            height: typeof height === 'string' ? height : `${height}px`,
            borderRadius: SIZES[rounded],
            ...style,
        }}
        {...props}
    />
);
