import {
    getSegmentos,
    getItensSegmentoProduto,
    getCompetitorsPrices,
    getFamiliaPrecificacao,
} from '../services/ProdutoService';

export const ADD_FILTRO_PRECIFICACAO_MANUAL = 'ADD_FILTRO_PRECIFICACAO_MANUAL';
export const DELETE_FILTRO_PRECIFICACAO_MANUAL
    = 'DELETE_FILTRO_PRECIFICACAO_MANUAL';
export const SELECT_VALUE_FILTRO_PRECIFICACAO_MANUAL
    = 'SELECT_VALUE_FILTRO_PRECIFICACAO_MANUAL';
export const LIST_ITENS_FILTROS_PRECIFICACAO_MANUAL
    = 'LIST_ITENS_FILTROS_PRECIFICACAO_MANUAL';
export const LIST_SEGMENTOS = 'LIST_SEGMENTOS';
export const LIST_ITENS_FAMILIA_PRODUTO_PRECIFICACAO
    = 'LIST_ITENS_FAMILIA_PRODUTO_PRECIFICACAO';
export const SIMULAR_PRECO_LOJA = 'SIMULAR_PRECO_LOJA';
export const SIMULAR_PRECO_MULTIPLAS_LOJAS = 'SIMULAR_PRECO_MULTIPLAS_LOJAS';
export const CLEAR_FILTROS_PRODUTOS = 'CLEAR_FILTROS_PRODUTOS';

export function addFiltro(filtro) {
    return {
        type: ADD_FILTRO_PRECIFICACAO_MANUAL,
        filtro,
    };
}

export function deleteFiltro(index, name) {
    return {
        type: DELETE_FILTRO_PRECIFICACAO_MANUAL,
        index,
        name,
    };
}

export function selectValue(item, name) {
    return {
        type: SELECT_VALUE_FILTRO_PRECIFICACAO_MANUAL,
        item,
        name,
    };
}

export function listFamiliaFiltro(item, name) {
    return {
        type: LIST_ITENS_FAMILIA_PRODUTO_PRECIFICACAO,
        item,
        name,
    };
}

export function listSegmentos(itens) {
    const segmentos = {};
    if (itens) {
        itens.forEach((item) => {
            segmentos[item.field] = { lista: [], value: [] };
        });
    }
    return {
        type: LIST_SEGMENTOS,
        itens: itens || [],
        segmentos,
    };
}

export function listItens(itens, name) {
    return {
        type: LIST_ITENS_FILTROS_PRECIFICACAO_MANUAL,
        itens,
        name,
    };
}

export function simularPrecoLoja(loja) {
    return {
        type: SIMULAR_PRECO_LOJA,
        loja,
    };
}

export function simularPrecoMultiplasLojas(lojas) {
    return {
        type: SIMULAR_PRECO_MULTIPLAS_LOJAS,
        lojas,
    };
}

export function clearFiltros() {
    return {
        type: CLEAR_FILTROS_PRODUTOS,
    };
}

export function searchSegmentos() {
    return (dispatch) => getSegmentos().then((data) => {
            dispatch(listSegmentos(data));
        });
}

export function searchFamiliaProduto(query, name) {
    return (dispatch) => {
        getFamiliaPrecificacao(query).then((data) => dispatch(listFamiliaFiltro(data, name)));
    };
}

export function search(nivel, query, name) {
    return (dispatch) => {
        if (name === 'productFamilyId') {
            getFamiliaPrecificacao(query?.query).then((data) => dispatch(listFamiliaFiltro(data, name)));
        } else {
            getItensSegmentoProduto(nivel, query).then((data) => dispatch(listItens(data, name)));
        }
    };
}

export function searchCompetitors(nivel, query, name) {
    return (dispatch) => {
        getCompetitorsPrices(nivel, query).then((data) => dispatch(listItens(data, name)));
    };
}
