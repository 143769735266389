import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Icon,
    Table,
    Tooltip,
    Whisper,
} from 'rsuite';

import { selectorExtracaoDados } from '../../../../../../reducers/extracaoDados';

import {
    setEcomValues,
} from '../../../../../../reducers/extracaoDados/ecom';
import { useTableList } from '../../hooks/useTableList';
import * as utils from '../../utils';

const {
    Column, HeaderCell, Cell, Pagination,
} = Table;

export const TableEcom = () => {
    const [sortColumn, setSortColumn] = useState('');
    const [sortType, setSortType] = useState('asc');

    const dispatch = useDispatch();

    const { ecom } = useSelector(selectorExtracaoDados);

    const { getEcomTableList } = useTableList();

    const handleSortColumn = (sortColumn, sortType) => {
        if (sortColumn && sortType) {
            const newList = [...ecom.ecomTableData.content];
            const lista = newList.sort((a, b) => {
                if (sortType === 'asc') {
                    if (a[sortColumn] > b[sortColumn]) {
                        return 1;
                    }
                    if (a[sortColumn] < b[sortColumn]) {
                        return -1;
                    }
                    return 0;
                }
                if (a[sortColumn] < b[sortColumn]) {
                    return 1;
                }
                if (a[sortColumn] > b[sortColumn]) {
                    return -1;
                }
                return 0;
            });

            const obj = {
                name: 'ecomTableData',
                value: { ...ecom.ecomTableData, content: lista },
            };

            setSortColumn(sortColumn);
            setSortType(sortType);
            dispatch(setEcomValues(obj));
        }
    };

    const handleChangePage = (value: number) => {
        getEcomTableList(value - 1);
    };

    return (
        <>
            <Table
                className="table"
                height={500}
                data={ecom.ecomTableData.content}
                sortColumn={sortColumn}
                sortType={sortType}
                onSortColumn={handleSortColumn}
            >
                {(ecom.colunasHeaderList && ecom.colunasHeaderList.length) ? ecom.colunasHeaderList.map((coluna) => (
                    <Column
                        key={coluna.objeto}
                        width={utils.setWidth(coluna)}
                        resizable
                        sortable
                    >
                        <HeaderCell>
                            {coluna.descricao}
                            <Whisper
                                placement="top"
                                trigger="hover"
                                speaker={(
                                    <Tooltip>
                                        {coluna.dica}
                                    </Tooltip>
                                )}
                            >
                                <Icon icon="info" />
                            </Whisper>
                        </HeaderCell>
                        <Cell dataKey={coluna.objeto}>
                            {(rowData) => (
                                <span title={`${rowData[coluna.objeto]}`}>
                                    {rowData[coluna.objeto]}
                                </span>
                            )}
                        </Cell>
                    </Column>
                )) : null}
            </Table>
            <Pagination
                showInfo={false}
                showLengthMenu={false}
                activePage={ecom.ecomTableData.number + 1}
                displayLength={20}
                total={ecom.ecomTableData.totalElements}
                onChangePage={handleChangePage}
            />
        </>
    );
};
