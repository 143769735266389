import React, { forwardRef } from 'react';
import './style.scss';

export type FoundResultsProps = Exclude<
    React.HtmlHTMLAttributes<HTMLParagraphElement>,
    'children'
> & {
    total: number;
};

export const FoundResults = forwardRef(
    (
        { total, className = '', ...props }: FoundResultsProps,
        ref: React.LegacyRef<HTMLParagraphElement>,
    ) => (
        <p
            ref={ref}
            className={['found-results', className].join(' ')}
            {...props}
        >
            {total > 0 ? (
                <>
                    Foram encontrados{' '}
                    <strong>
                        {`${total ?? 0} `}
                        resultados
                    </strong>{' '}
                    no total
                </>
            ) : (
                'Nenhum resultado encontrado'
            )}
        </p>
    ),
);
