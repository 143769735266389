import { combineReducers } from 'redux';
import values from './values';

const filters = combineReducers({
    values,
});

export type PrecoAtacadoFilters = ReturnType<typeof filters>;

export default filters;
