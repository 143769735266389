import classNames from 'classnames';
import React, { HtmlHTMLAttributes } from 'react';
import { Icon } from 'rsuite';
import { SecondaryButton } from '../../../../../../../components';
import { formatDateToPtBr } from '../../../../../../../utils/date';
import { Layout, LinkButton } from '../../../../../Lite';
import { GetNegociacoesItemProps } from '../../NegociacoesSalvas.services';
import { StatusTag } from '../StatusTag';
import styles from './DetalhesDaNegociacaoHeading.module.scss';

type DetalhesDaNegociacaoHeadingProps = Pick<
    GetNegociacoesItemProps,
    'status' | 'supplierId' | 'supplierName' | 'startDate' | 'endDate' | 'name'
> &
    HtmlHTMLAttributes<HTMLDivElement> & {
        openModal: (modal: string) => void;
    };

export const DetalhesDaNegociacaoHeading = ({
    status,
    supplierId,
    supplierName,
    startDate,
    endDate,
    className,
    name,
    openModal,
    ...props
}: DetalhesDaNegociacaoHeadingProps) => {
    return (
        <Layout.Section
            className={classNames(
                styles['detalhes-da-negociacao__heading'],
                className,
            )}
            {...props}
        >
            <span className={styles['name-container']}>
                <h6 className={styles['negociacao-name']}>{name}</h6>
                <LinkButton
                    className={styles['negociacao-link-btn']}
                    onClick={() => openModal('editarNegociacao')}
                    disabled={status === 'CLOSED'}
                >
                    <Icon icon="pencil" />
                    Editar
                </LinkButton>
                <StatusTag
                    className={styles['negociacao-tag']}
                    status={status}
                />
            </span>
            <span>
                <span className={styles['text-description']}>Fornecedor:</span>
                <span>
                    {supplierName ? (
                        <>
                            {/* <span className="font-size-100-semibold">{`${supplierId} `}</span> */}
                            <span className="font-size-100-regular">
                                {supplierName}
                            </span>
                        </>
                    ) : (
                        <>--</>
                    )}
                </span>
            </span>
            <span>
                <span className={styles['text-description']}>
                    Início vigência:
                </span>
                <span className="font-size-100-semibold">
                    {startDate ? formatDateToPtBr(startDate) : '--'}
                </span>
            </span>
            <span>
                <span className={styles['text-description']}>
                    Final vigência:
                </span>
                <span className="font-size-100-semibold">
                    {endDate ? formatDateToPtBr(endDate) : '--'}
                </span>
            </span>
            <SecondaryButton onClick={() => openModal('excluirNegociacao')}>
                Excluir negociação
            </SecondaryButton>
        </Layout.Section>
    );
};
