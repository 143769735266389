import { useQuery } from '@tanstack/react-query';
import { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../@types/RootState';
import { selectorDataPointsExhibitionType } from '../../../../reducers/gerenciadorPrecos';
import { selectorContextMenu } from '../../../../reducers/gerenciadorPrecos/contextMenu';
import {
    RESET_GERENCIADOR_DATAPOINTS,
    SET_GERENCIADOR_DATAPOINTS,
} from '../../../../reducers/gerenciadorPrecos/datapoints';
import { selectorFiltersValues } from '../../../../reducers/gerenciadorPrecos/filters/filtersValues';
import {
    CLOSE_MODAL,
    OPEN_MODAL,
    selectorModalsBoolean,
} from '../../../../reducers/gerenciadorPrecos/modalsBoolean';
import { selectorPagination } from '../../../../reducers/gerenciadorPrecos/pagination';
import {
    SET_GERENCIADOR_TOTAL_ELEMENTS,
    selectorTotalElements,
} from '../../../../reducers/gerenciadorPrecos/totalElements';
import { checkIfIsLoading } from '../../../../utils';
import { GetDatapointsRevisao, getDatapointsRevisao } from '../services';
import useGerenciadorTable from '../useGerenciadorTable';
import {
    GET_MODEL_GERENCIADOR_FILTROS,
    GET_MODEL_GERENCIADOR_PAGINATION,
    GET_MODEL_GERENCIADOR_QUICK_ACTION_FILTERS,
    GET_MODEL_GERENCIADOR_SORT,
} from '../utils';
import { useTotalPrice } from './useTotalPrice';
import { useVariationPrice } from './useVariationPrice';

const sortColumnsTypes = {
    name: 'description',
};

export const useGerenciadorPrecos = () => {
    const dispatch = useDispatch();

    const totalElements = useSelector(selectorTotalElements);

    const contextMenuDatapoint = useSelector(selectorContextMenu);

    const values = useSelector(selectorFiltersValues);

    const booleanModals = useSelector(selectorModalsBoolean);

    const pagination = useSelector(selectorPagination);

    const datapointExhibitionType = useSelector(
        selectorDataPointsExhibitionType,
    );

    const onOpenModal = useCallback(
        (name: string) => dispatch(OPEN_MODAL(name)),
        [dispatch],
    );

    const onCloseModal = useCallback(
        (name: string) => dispatch(CLOSE_MODAL(name)),
        [dispatch],
    );

    const quickActionsFilter = useSelector((state: RootState) => {
        return state.gerenciadorPrecosReducer.quickActionsFilters;
    });

    const [updateBigNumbers, setUpdateBigNumbers] = useState(false);

    const table = useGerenciadorTable({ setUpdateBigNumbers });

    const { sort } = table;

    const MODEL_GET_DATAPOINTS = useMemo(() => {
        return {
            ...GET_MODEL_GERENCIADOR_FILTROS(values),
            ...GET_MODEL_GERENCIADOR_SORT(sort, sortColumnsTypes),
            ...GET_MODEL_GERENCIADOR_PAGINATION(pagination),
            ...GET_MODEL_GERENCIADOR_QUICK_ACTION_FILTERS(quickActionsFilter),
            datapointExhibitionType,
        };
    }, [values, sort, pagination, quickActionsFilter, datapointExhibitionType]);

    const { variationPriceData, variationPriceLoading, variationPriceRefetch } =
        useVariationPrice(MODEL_GET_DATAPOINTS);

    const { totalPriceData, totalPriceLoading, totalPriceRefetch } =
        useTotalPrice(MODEL_GET_DATAPOINTS);

    const handleUpdateBigNumbers = useCallback(() => {
        setUpdateBigNumbers(false);
        variationPriceRefetch();
        totalPriceRefetch();
    }, [setUpdateBigNumbers, variationPriceRefetch, totalPriceRefetch]);

    const getItensRevisao = useCallback(async () => {
        dispatch(RESET_GERENCIADOR_DATAPOINTS());
        const response = getDatapointsRevisao(MODEL_GET_DATAPOINTS);
        return response;
    }, [MODEL_GET_DATAPOINTS, dispatch]);

    const getItensRevisaoSuccess = useCallback(
        (data: GetDatapointsRevisao.Response) => {
            dispatch(SET_GERENCIADOR_DATAPOINTS(data.content));
            dispatch(SET_GERENCIADOR_TOTAL_ELEMENTS(data.totalElements));
            variationPriceRefetch();
            totalPriceRefetch();
        },
        [dispatch],
    );

    const { refetch, fetchStatus } = useQuery({
        queryKey: ['ipa.gerenciador.datapoints', MODEL_GET_DATAPOINTS],
        queryFn: getItensRevisao,
        onSuccess: getItensRevisaoSuccess,
        keepPreviousData: true,
    });

    const modals = useMemo(() => {
        return [
            booleanModals,
            {
                onOpen: onOpenModal,
                onHide: onCloseModal,
            },
        ] as const;
    }, [booleanModals, onOpenModal, onCloseModal]);

    return {
        modals,
        refetch,
        sort,
        table: {
            ...table,
            loading: checkIfIsLoading(fetchStatus),
        },
        totalElements,
        contextMenuDatapoint,
        bigNumbers: {
            variationPriceData,
            variationPriceLoading,
            totalPriceData,
            totalPriceLoading,
            updateBigNumbers,
            handleUpdateBigNumbers,
        },
        models: MODEL_GET_DATAPOINTS,
    } as const;
};
