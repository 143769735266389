import React, { useState, useCallback } from 'react';
import { ArrowDownLine } from '@rsuite/icons';
import { DateRangePicker, Icon } from 'rsuite';
import { DateRangePickerProps } from 'rsuite/lib/DateRangePicker';

interface DateRangePickerPillProps extends DateRangePickerProps {
    disabledDate?: (date: Date) => boolean;
}

export const DateRangePickerPill = (props: DateRangePickerPillProps) => {
    const { onChange, onClean, open, leftIcon, ...restProps } = props;
    const [hasValue, setHasValue] = useState('');
    const arrowDownIconStyle = hasValue
        ? { fontSize: '14px', color: '#0D66D0' }
        : { fontSize: '14px' };

    const handleRenderValue = useCallback(
        (value: string | any[]) => {
            if (value.length) {
                setHasValue('date-range-has-value');
            }
            // @ts-ignore
            onChange && onChange(value);
        },
        [onChange]
    );

    const handleOnClean = useCallback(
        (e: React.SyntheticEvent<HTMLElement, Event>) => {
            setHasValue('');
            onClean && onClean(e);
        },
        [onClean]
    );

    return (
        <div className={`date-range-picker-pill-container ${hasValue}`}>
            <ArrowDownLine
                style={arrowDownIconStyle}
                className="date-range-picker-pill-container__arrow-down-icon"
            />
            {leftIcon ? <span className="left-icon">{leftIcon}</span> : null}
            <DateRangePicker
                id="date-range-picker"
                onChange={handleRenderValue}
                onClean={handleOnClean}
                open={open}
                {...restProps}
            />
        </div>
    );
};
