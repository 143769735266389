import qs from 'qs';
import { trackPromise } from 'react-promise-tracker';
import api from '../../../../../utils/API';
import * as T from './SetupIPA.types';

export async function getSetupIPAOptions(): Promise<T.GetSetupIPAOptionsProps> {
    return trackPromise(
        // @ts-ignore
        api({
            url: `${process.env.REACT_APP_IPA_URL}/setup/info-painel/data`,
            method: 'GET',
            expectToken: true,
        }).then((resp) => resp.data),
        'setup-ipa',
    );
}

export async function putSetupIPAOptions(
    data: T.GetSetupIPAOptionsProps,
): Promise<T.GetSetupIPAOptionsProps> {
    return trackPromise(
        // @ts-ignore
        api({
            url: `${process.env.REACT_APP_IPA_URL}/setup/info-painel/data`,
            method: 'PUT',
            expectToken: true,
            data,
        }).then((resp) => resp.data),
        'setup-ipa',
    );
}

export async function postSetupIPAOptions(
    data: T.GetSetupIPAOptionsProps,
): Promise<T.GetSetupIPAOptionsProps> {
    return trackPromise(
        // @ts-ignore
        api({
            url: `${process.env.REACT_APP_IPA_URL}/setup/info-painel/data`,
            method: 'POST',
            expectToken: true,
            data,
        }).then((resp) => resp.data),
        'setup-ipa',
    );
}

export async function patchSetupIPAOptions(params: {
    info_panel_data_able: boolean;
}): Promise<T.GetSetupIPAOptionsProps> {
    return trackPromise(
        // @ts-ignore
        api({
            url: `${process.env.REACT_APP_IPA_URL}/setup/info-painel/data/status`,
            method: 'PATCH',
            expectToken: true,
            params,
            paramsSerializer(params) {
                return qs.stringify(params, { arrayFormat: 'repeat' });
            },
        }).then((resp) => resp.data),
        'setup-ipa',
    );
}
