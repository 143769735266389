import { trackPromise } from 'react-promise-tracker';
import axios from 'axios';

import api from '../../../../utils/API';

let cancelTokenFiltrosNovaLista;
export const getFiltroItens = (data) => {
    if (typeof cancelTokenFiltrosNovaLista !== typeof undefined) {
        cancelTokenFiltrosNovaLista.cancel('Operation canceled due to new request.');
    }

    cancelTokenFiltrosNovaLista = axios.CancelToken.source();
    return trackPromise(
        api({
            url: `${process.env.REACT_APP_CLIENTE_URL}/lojacliente/filtros`,
            method: 'POST',
            data: JSON.stringify(data),
            cancelToken: cancelTokenFiltrosNovaLista.token,
            expectToken: true,
        }).then((resp) => resp.data),
        'revisao-filter',
    );
};

let cancelTokenFiltros;
export const getFiltroItensListaCadatrada = (data) => {
    if (typeof cancelTokenFiltros !== typeof undefined) {
        cancelTokenFiltros.cancel('Operation canceled due to new request.');
    }

    cancelTokenFiltros = axios.CancelToken.source();
    return trackPromise(
        api({
            url: `${process.env.REACT_APP_CLIENTE_URL}/revisao-lista/cadastrada/filter`,
            method: 'POST',
            data: JSON.stringify(data),
            cancelToken: cancelTokenFiltros.token,
            expectToken: true,
        }).then((resp) => resp.data),
        'revisao-filter',
    );
};

let cancelTokenFiltroLoja;
export const getLojas = (data) => {
    if (typeof cancelTokenFiltroLoja !== typeof undefined) {
        cancelTokenFiltroLoja.cancel('Operation canceled due to new request.');
    }

    cancelTokenFiltroLoja = axios.CancelToken.source();
    return trackPromise(
        api({
            url: `${process.env.REACT_APP_CLIENTE_URL}/lojacliente/search`,
            method: 'POST',
            expectToken: true,
            data: JSON.stringify(data),
            cancelToken: cancelTokenFiltroLoja.token,
        }).then((resp) => resp.data),
        'revisao-filter',
    );
};

export const getPreviewNovaLista = (data) => {
    const options = data.codigoValidacaoLista ? {
        url: `${process.env.REACT_APP_CLIENTE_URL}/revisao-lista/nova/find-next`,
        method: 'POST',
        params: {
            codigoValidacaoLista: data.codigoValidacaoLista,
            page: data.page,
            size: data.size,
        },
        expectToken: true,
    } : {
        url: `${process.env.REACT_APP_CLIENTE_URL}/revisao-lista/nova/process`,
        method: 'POST',
        data: JSON.stringify(data),
        expectToken: true,
    };

    return trackPromise(
    api(options).then((res) => res),
);
};

export const getPreviewListasCadastradas = (data) => trackPromise(
    api({
        url: `${process.env.REACT_APP_CLIENTE_URL}/revisao-lista/cadastrada/find`,
        method: 'POST',
        data: JSON.stringify(data),
        expectToken: true,
    }).then((resp) => resp.data),
);

export const downloadResultNovaLista = (codigo, data) => trackPromise(
    api({
        url: `${process.env.REACT_APP_CLIENTE_URL}/revisao-lista/nova/download`,
        method: 'POST',
        data: JSON.stringify(data),
        params: {
            clienteValidacaoListaCodigo: codigo,
        },
        expectToken: true,
        responseType: 'arraybuffer',
    }).then((res) => res),
);

export const downloadResultListaCadastrada = (data) => trackPromise(
    api({
        url: `${process.env.REACT_APP_CLIENTE_URL}/revisao-lista/cadastrada/download`,
        method: 'POST',
        data: JSON.stringify(data),
        expectToken: true,
        responseType: 'arraybuffer',
    }).then((res) => res),
);

export const getDimensoesNovaLista = () => trackPromise(
    api({
        url: `${process.env.REACT_APP_CLIENTE_URL}/revisao-lista/nova/dimensoes`,
        method: 'GET',
        expectToken: true,
    }).then((resp) => resp.data),
);

export const getDimensoesListaCadastrada = () => trackPromise(
    api({
        url: `${process.env.REACT_APP_CLIENTE_URL}/revisao-lista/cadastrada/dimensoes`,
        method: 'GET',
        expectToken: true,
    }).then((resp) => resp.data),
);
