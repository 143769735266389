import { useCallback, useState } from 'react';

export type UsePaginationProps = {
    initialState?: {
        pageSize?: number;
        activePage?: number;
    };
    totalElements?: number;
};

const COMPENSATION_VALUE = 1;

export const usePagination = ({
    initialState = { activePage: 0, pageSize: 20 },
    totalElements = 0,
}: UsePaginationProps) => {
    const [activeIndex, setActivePage] = useState(
        () => initialState.activePage ?? 0,
    );
    const [pageSize, setPageSize] = useState(() => initialState.pageSize ?? 20);

    const lastValidIndex = Math.ceil(totalElements / pageSize);

    const isOnInvalidLastIndex =
        activeIndex + COMPENSATION_VALUE > lastValidIndex;

    const handleChangePage = useCallback((page: number) => {
        setActivePage(page - COMPENSATION_VALUE);
    }, []);

    const handleChangeLength = useCallback((size: number) => {
        setPageSize(size);
    }, []);

    if (totalElements && isOnInvalidLastIndex) {
        handleChangePage(lastValidIndex);
    }

    return {
        activePage: activeIndex + COMPENSATION_VALUE,
        pageSize,
        handleChangePage,
        handleChangeLength,
        lastValidPage: lastValidIndex,
        isOnInvalidLastIndex,
    };
};
