import Immutable from 'seamless-immutable';
import { CHANGE_MENU, CHANGE_ACTIVE_KEY_MENU } from '../actions/actionsMenu'

const menuInitialState = Immutable({
    sideMenu: null,
    activeKeyMenu: null
})


function menuDataReducer(state = menuInitialState, action) {
    switch (action.type) {
        case CHANGE_MENU:
            return state.merge({
                sideMenu: action.sideMenu
            })
        case CHANGE_ACTIVE_KEY_MENU:
            return state.merge({
                activeKeyMenu: action.activeKeyMenu
            })
        default:
            return state
    }
}

export default menuDataReducer;