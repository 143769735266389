import { trackPromise } from 'react-promise-tracker';
import api from '../../../../utils/API';
import {
    RuleFiltersModel,
    RuleModel,
} from '../../Estrategia/pages/RegrasDePreco/lib';

export const saveRegra = (data) =>
    trackPromise(
        api({
            url: `${process.env.REACT_APP_IPA_URL}/rules`,
            method: 'POST',
            expectToken: true,
            data: JSON.stringify(data),
        }).then((resp) => resp),
    );

export const updateRegra = (id: string, data: Record<string, unknown>) => {
    return trackPromise(
        api({
            url: `${process.env.REACT_APP_IPA_URL}/rules/${id}`,
            method: 'PUT',
            expectToken: true,
            data: JSON.stringify(data),
        }).then((resp) => resp),
        'ipa/rule/edit',
    );
};

export type GetRegra = RuleModel &
    RuleFiltersModel & {
        ruleStatus: 'ACTIVE' | 'EXPIRED';
    };

export const getRegra = (id: string): Promise<GetRegra> => {
    return trackPromise(
        api({
            url: `${process.env.REACT_APP_IPA_URL}/rules/${id}`,
            method: 'GET',
            expectToken: true,
        }).then((resp) => resp.data),
    );
};

export const getCompositionRule = (id, productToBePricedId) =>
    trackPromise(
        api({
            url: `${process.env.REACT_APP_IPA_URL}/rules/composition/${id}?productsToBePricedId=${productToBePricedId}`,
            method: 'GET',
            expectToken: true,
        }).then((resp) => resp.data),
    );
