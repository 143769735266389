import React from 'react';
import {
    Content,
} from 'rsuite';
import emptyStateImg from '../../../../assets/image/simulacao-data.svg';

import styles from './EmptyState.module.scss';

export const EmptyState = () => (
    <Content className={styles['empty-state-content']}>
        <div className={styles['state-container']}>
            <img src={emptyStateImg} alt="" />
            <div className={styles['text-wrapper']}>
                <p className={styles.text}>Para ver os resultados da simulação, preencha os dados acima</p>
            </div>
        </div>
    </Content>
  );
