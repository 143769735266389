import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
    Container, Content, FlexboxGrid, Col, Button, Row, Loader,
} from 'rsuite';

import stateRegionIcon from '../../../../assets/icons/icon_state_region.svg';
import alimentarChannel from '../../../../assets/icons/icon_channel_alimentar.svg';

import { changeSideMenu } from '../../../../actions/actionsMenu';
import ModalEditRegionChannel from './Components/ModalEditRegionChannel';
import { getProductData, setEnoughDataEditNull } from '../../../../actions/actionsProductConfiguration';

class InfoPanelConfig extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showEditModal: false,
            editType: null,
        };

        this.backPage = this.backPage.bind(this);
        this.handleEditModal = this.handleEditModal.bind(this);
        this.getProductConfig = this.getProductConfig.bind(this);
        this.changeType = this.changeType.bind(this);
    }

    componentDidMount() {
        this.getProductConfig();
    }

    handleEditModal(type) {
        this.setState({
            showEditModal: true,
            editType: type,
        });
    }

    getProductConfig() {
        const { getProductData } = this.props;
        getProductData();
    }

    changeType(type) {
        this.setState({
            editType: type,
        });
    }

    backPage() {
        const { history } = this.props;

        history.push('/isa/painel-geral');
    }

    render() {
        const { showEditModal, editType } = this.state;
        const { infopanel, setEnoughDataEditNull } = this.props;

        const allowUpdate = infopanel.daysDiff > 30;
        const allowFirstUpdate = !!(infopanel.firstUpdateDiff <= 15 && infopanel.updateDate === infopanel.registerDate);

        return (
            <Container className="configuration-products-page">
                <Content>

                    <FlexboxGrid>
                        <FlexboxGrid.Item colspan={24}>
                            <Col xs={8} className="channel-card">
                                <div>
                                    <Row>
                                        <h3>Canal</h3>
                                    </Row>
                                    <Row className="info-area">
                                        <Col xs={7}>
                                            <div>
                                                <div className="image-area">
                                                    <img alt="Canal" src={alimentarChannel} />
                                                </div>
                                            </div>
                                        </Col>
                                        <Col>
                                            <h4>{infopanel.channel}</h4>
                                            {allowUpdate || allowFirstUpdate
                                                ? <span className="description-allow">Alteração liberada</span>
                                                : (
                                                    <>
                                                        <span className="description">Alteração liberada em </span>
                                                        {infopanel.daysDiff || infopanel.daysDiff === 0
                                                            ? (
                                                                <span className="period">
                                                                    {30 - infopanel.daysDiff}
                                                                    {' '}
                                                                    dias

                                                                </span>
                                                            )
                                                            : <Loader size="xs" />}
                                                    </>
                                                )}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Button
                                            block
                                            id="btn-edit-channel"
                                            appearance="primary"
                                            onClick={() => this.handleEditModal('channel')}
                                            disabled={!allowUpdate && !allowFirstUpdate}
                                        >
                                            Alterar

                                        </Button>
                                    </Row>
                                </div>
                            </Col>
                            <Col xs={8} className="region-card">
                                <div>
                                    <Row>
                                        <h3>Agrupamento de dados</h3>
                                    </Row>
                                    <Row className="info-area">
                                        <Col xs={7}>
                                            <div>
                                                <div className="image-area">
                                                    <img alt="Região" src={stateRegionIcon} />
                                                </div>
                                            </div>
                                        </Col>
                                        <Col>
                                            {infopanel.region === 'pais' ? (<h4> País </h4>) : null}
                                            {infopanel.region === 'cidade' ? (
                                                <h4>
                                                    {' '}
                                                    Cidade -
                                                    {' '}
                                                    {infopanel.city}
                                                </h4>
                                            ) : null}
                                            {infopanel.region === 'estado' ? (
                                                <h4>
                                                    {' '}
                                                    Estado -
                                                    {' '}
                                                    {infopanel.state}
                                                </h4>
                                            ) : null}
                                            {allowUpdate || allowFirstUpdate
                                                ? <span className="description-allow">Alteração liberada</span>
                                                : (
                                                    <>
                                                        <span className="description">Alteração liberada em </span>
                                                        {infopanel.daysDiff || infopanel.daysDiff === 0
                                                            ? (
                                                                <span className="period">
                                                                    {30 - infopanel.daysDiff}
                                                                    {' '}
                                                                    dias
                                                                </span>
                                                            )
                                                            : <Loader size="xs" />}
                                                    </>
                                                )}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Button
                                            block
                                            id="btn-edit-region"
                                            appearance="primary"
                                            onClick={() => this.handleEditModal('region')}
                                            disabled={!allowUpdate && !allowFirstUpdate}
                                        >
                                            Alterar

                                        </Button>
                                    </Row>
                                </div>
                            </Col>
                        </FlexboxGrid.Item>

                    </FlexboxGrid>
                </Content>
                {showEditModal ? (
                    <ModalEditRegionChannel
                        show={showEditModal}
                        type={editType}
                        onClose={() => { this.setState({ showEditModal: false }); setEnoughDataEditNull(); }}
                        data={infopanel}
                        changeType={this.changeType}
                        confirm={this.getProductConfig}
                    />
                ) : null}
            </Container>
        );
    }
}
const mapStateToProps = (store) => ({
    infopanel: store.productConfigurationDataReducer.infopanel,
});

export default withRouter(connect(mapStateToProps, { changeSideMenu, getProductData, setEnoughDataEditNull })(InfoPanelConfig));
