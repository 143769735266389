import React, { ReactNode } from 'react';
import styles from './FormBox.module.scss';

export interface IFormBoxRow {
    children: ReactNode;
}

export const FormBoxRow = ({ children }: IFormBoxRow) => (
    <div
        className={styles['box-row']}
    >
        {children}
    </div>
);
