import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../@types/RootState';

export type GetFamilyModalPaginationState = {
    activePage: number;
    displayLength: number;
};

const initialState: GetFamilyModalPaginationState = {
    activePage: 0,
    displayLength: 25,
};

const slice = createSlice({
    name: 'getFamilyModal/pagination',
    initialState,
    reducers: {
        SET_GET_FAMILY_MODAL_PAGINATION: (
            _state,
            { payload }: PayloadAction<GetFamilyModalPaginationState>,
        ) => payload,
        UPDATE_GET_FAMILY_MODAL_PAGINATION: (
            state,
            { payload }: PayloadAction<Partial<GetFamilyModalPaginationState>>,
        ) => {
            return {
                ...state,
                ...payload,
            };
        },
        SET_GET_FAMILY_MODAL_DISPLAY_LENGTH: (
            state,
            { payload }: PayloadAction<number>,
        ) => {
            return {
                ...state,
                displayLength: payload,
            };
        },
        SET_GET_FAMILY_MODAL_ACTIVE_PAGE: (
            state,
            { payload }: PayloadAction<number>,
        ) => {
            return {
                ...state,
                activePage: payload,
            };
        },
    },
});

export const {
    SET_GET_FAMILY_MODAL_PAGINATION,
    UPDATE_GET_FAMILY_MODAL_PAGINATION,
    SET_GET_FAMILY_MODAL_DISPLAY_LENGTH,
    SET_GET_FAMILY_MODAL_ACTIVE_PAGE,
} = slice.actions;

export const selectorGetFamilyModalPagination = (state: RootState) => {
    return state.getFamilyModalReducer.pagination;
};

export default slice.reducer;
