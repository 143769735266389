import React, { forwardRef } from 'react';

export const PageSubtitle = forwardRef(
    (
        {
            children,
            className = '',
            ...props
        }: React.HTMLAttributes<HTMLHeadingElement>,
        ref: React.LegacyRef<HTMLHeadingElement>,
    ) => (
        <h2 className={`page__subtitle ${className}`} ref={ref} {...props}>
            {children}
        </h2>
    ),
);
