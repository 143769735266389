import SaveIcon from '@material-ui/icons/Save';
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    Button,
    Content,
} from 'rsuite';
import {
    setResultScenarioData,
} from '../../../../reducers/calculadoraPrevisaoDemanda/resultScenario';
import { selectorCalculadoraPrevisaoDemanda } from '../../../../reducers/calculadoraPrevisaoDemanda';
import { ModalSaveScenario } from '../ModalSaveScenario';
import { ModalUpdateScenario } from '../ModalUpdateScenario';
import { useGetScenarioResults, useSaveScenarios } from '../../hooks';
import DownloadIcon from '../../../../assets/icons/icon_download_gray600.svg';

import * as utils from '../../utils';
import * as service from '../../services';
import styles from './ViewResultsArea.module.scss';

export interface IViewResultsArea{
    onClick?: (arg: string) => void;
    onSelectSave?: () => void;
    onSelectDownload?: () => void;
    data?: any;
}

export const ViewResultsArea = () => {
    const { filtros, inputCalculos, resultScenario } = useSelector(selectorCalculadoraPrevisaoDemanda);
    const { saveHandlers } = useSaveScenarios();
    const { query } = resultScenario?.resultScenarioData;

    const dispatch = useDispatch();
    const { resultHandlers } = useGetScenarioResults();

    const dataRequest = {
        ...filtros.mainRequestBody,
        query: filtros.filterRequestBody,
        inputs: inputCalculos.inputs,
    };

    const savingData = {
        query: filtros.filterRequestBody,
        inputs: inputCalculos.inputs,
        scenario: resultScenario.resultScenarioData.scenario,
        outputs: resultScenario.resultScenarioData.outputs,
    };

    const filterRequestBodyIsFilled
        = filtros.filterRequestBody.product_description !== ''
        && filtros.filterRequestBody.location !== ''
        && filtros.filterRequestBody.channel !== ''
        && filtros.filterRequestBody.sub_channel !== ''
        && filtros.filterRequestBody.competitor_product_description !== ''
        && filtros.filterRequestBody.payment_method !== '';

    const requestChartsAndBigNumbers = () => {
        resultHandlers.handleChartBigNumbers(dataRequest);
        resultHandlers.handleGetFirstChartsData(dataRequest);
        resultHandlers.handleGetSecondChartsData(dataRequest);
        resultHandlers.handleGetCPIChartsData(dataRequest);
    };

    const handleDownload = () => {
        service.scenariosDownload('POST', '', resultScenario?.resultScenarioData);
    };

    return (
        <Content className={styles['view-results-area-content']}>
            {query?.product_description ? (
                <div className={styles['product-title-wrapper']}>
                    <p className={styles['product-title']}>{query?.product_description}</p>
                    <div className={styles['product-origin']}>
                        <p className={styles['product-subtitle']}>{utils.ufToName[query?.location]}</p>
                        <span className={styles['side-arrow']}>{' > '}</span>
                        <p className={styles['product-subtitle']}>{query?.channel}</p>
                        <span className={styles['side-arrow']}>{' > '}</span>
                        <p className={styles['product-subtitle']}>
                            {query?.sub_channel === null ? 'Todo o canal' : query?.sub_channel}
                        </p>
                        <span className={styles['side-arrow']}>{' > '}</span>
                        <p className={styles['product-subtitle']}>{query?.payment_method}</p>
                    </div>
                </div>
            ) : null}
            <div className={styles['buttons-wrapper']}>
                {query?.product_description ? (
                    <>
                        <Button
                            id="scenario-download"
                            className={styles['gray-dropdown-button']}
                            onClick={handleDownload}
                        >
                            <img src={DownloadIcon} alt="" />
                        </Button>
                        <Button
                            id="scenario-save"
                            className={styles['gray-dropdown-button']}
                            onClick={saveHandlers.handleShowModal}
                        >
                            <SaveIcon
                                className={styles['save-icon']}
                            />
                        </Button>
                    </>
                ) : null}
                <Button
                    id="simulate-scenario-results"
                    appearance="primary"
                    className={styles['result-button']}
                    disabled={!filterRequestBodyIsFilled}
                    onClick={requestChartsAndBigNumbers}
                >
                    {query?.product_description ? 'ATUALIZAR RESULTADOS' : 'VISUALIZAR RESULTADOS'}
                </Button>
            </div>
            <ModalSaveScenario
                show={resultScenario.showModalSave}
                title={resultScenario.modalSaveTitle}
                onClickSave={() => saveHandlers.handleSavingTypeScenario(savingData)}
                onHide={() => dispatch(setResultScenarioData({ name: 'showModalSave', data: false }))}
            />
            <ModalUpdateScenario
                show={resultScenario.showModalUpdate}
                onClickSave={() => saveHandlers.handleUpdateScenario(savingData)}
                onClickSaveNew={saveHandlers.handleShowSaveModal}
                onHide={() => dispatch(setResultScenarioData({ name: 'showModalUpdate', data: false }))}
            />
        </Content>
  );
};
