import React from 'react';

export type SegmentsPlaceholderProps = {
    level?: number;
    placeholder: string;
} & React.HtmlHTMLAttributes<HTMLParagraphElement>;

export const SegmentsPlaceholder = ({
    level,
    placeholder,
    className = '',
    ...props
}: SegmentsPlaceholderProps) => (
    <p {...props}>
        <span className={['placeholder-level', className].join(' ')}>
            {level && (level < 10 ? `0${level}` : level)}
        </span>
        {' '}
        {placeholder}
    </p>
);
