import React from 'react';
import {
 Content, Tooltip, Whisper, Col, Grid, Row, Icon,
} from 'rsuite';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import ValueCard from '../../../Components/ValueCard';
import IconQuery from '../../../../../../../assets/icons/icon_query.svg';

const Simulacao = ({
    forecast,
    currentPrice: {
        value: currentPriceValue,
        forecast: {
            sales: currentSales,
            revenue: currentRevenue,
            profit: currentProfit,
        },
        margin: currentMargin,
        competitiveness: currentCompetitiveness,
    },
    newPrice: {
        value: newPriceValue,
        forecast: { sales: newSales, revenue: newRevenue, profit: newProfit },
        margin: newMargin,
        competitiveness: newCompetitiveness,
    },
    variation: {
        sales: variationSales,
        revenue: variationRevenue,
        profit: variationProfit,
    },
    elasticity,
    graficoSemanal,
    graficoMensal,
    graficoElasticidade,
}) => (
    <Content>
        <div className="previsao-volume-info">
            <img alt="ícone de previsão" src={IconQuery} />
            <h3>
                Previsão de demanda de volume e receita
                {' '}
                <span className="previsao-beta-badge">BETA</span>
            </h3>
            <p>Previsão realizada para os próximos 7 dias</p>
            <div className="confiabilidade-info">
                <p>
                    Essa previsão tem margem de erro de aproximadamente
                    {' '}
                    <strong>40% </strong>
                    <Whisper
                        trigger="hover"
                        placement="top"
                        speaker={(
                            <Tooltip>
                                Estimativas de demanda estão sujeitas a erros,
                                visto que o preço não é o único fator de
                                influência do consumo
                            </Tooltip>
                          )}
                    >
                        <Icon icon="info" />
                    </Whisper>
                </p>
            </div>
            {forecast && Object.keys(forecast).length === 0 ? (
                <div className="previsao-volume-sem-dados">
                    <WarningRoundedIcon style={{ fontSize: '24px' }} />
                    <p>
                        Não temos dados suficientes para calcular a previsão de
                        demanda desse produto.
                    </p>
                    <span>Busque outro produto ou loja.</span>
                </div>
            ) : (
                <Grid fluid className="cards-grid">
                    <Row gutter={16}>
                        <Col xs={8} lg={3}>
                            <ValueCard
                                title="Preço"
                                currentValue={currentPriceValue}
                                value={newPriceValue}
                                type="value"
                            />
                        </Col>
                        <Col xs={8} lg={3}>
                            <ValueCard
                                title="Competitividade"
                                currentValue={currentCompetitiveness}
                                value={newCompetitiveness}
                                type="percent"
                            />
                        </Col>
                        <Col xs={8} lg={3}>
                            <ValueCard
                                title="Margem"
                                currentValue={currentMargin}
                                value={newMargin}
                                type="percent"
                            />
                        </Col>
                        <Col xs={8} lg={4}>
                            <ValueCard
                                title="Previsão de vendas 7 dias"
                                currentValue={currentSales}
                                value={newSales}
                            />
                        </Col>
                        <Col xs={8} lg={6}>
                            <ValueCard
                                title="Previsão de receita 7 dias"
                                currentValue={currentRevenue}
                                value={newRevenue}
                                type="value"
                            />
                        </Col>
                        <Col xs={8} lg={5}>
                            <ValueCard
                                title="Previsão de lucro 7 dias"
                                currentValue={currentProfit}
                                value={newProfit}
                                type="value"
                            />
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col xs={8}>
                            <ValueCard
                                title="Variação venda"
                                value={variationSales}
                                type="variation"
                                color={
                                    variationSales > 0
                                        ? 'positive'
                                        : variationSales < 0
                                        ? 'negative'
                                        : ''
                                }
                            />
                        </Col>
                        <Col xs={8}>
                            <ValueCard
                                title="Variação receita"
                                value={
                                    variationRevenue
                                }
                                type="variation"
                                color={
                                    variationRevenue > 0
                                        ? 'positive'
                                        : variationRevenue < 0
                                        ? 'negative'
                                        : ''
                                }
                            />
                        </Col>
                        <Col xs={8}>
                            <ValueCard
                                title="Variação lucro"
                                value={
                                    variationProfit
                                }
                                type="variation"
                                color={
                                    variationProfit > 0
                                        ? 'positive'
                                        : variationProfit < 0
                                        ? 'negative'
                                        : ''
                                }
                            />
                        </Col>
                    </Row>
                    <Row className="container-graficos" gutter={16}>
                        <Col xs={24} md={12} lg={8}>
                            <div className="grafico-card">
                                <h2>Previsão de venda semanal</h2>
                                <p>
                                    Gráfico comparativo do preço vigente e
                                    simulado na venda semanal
                                </p>
                                {graficoSemanal ? (
                                    <HighchartsReact
                                        highcharts={Highcharts}
                                        options={graficoSemanal}
                                    />
                                ) : (
                                    <div className="grafico-indisponivel">
                                        <WarningRoundedIcon
                                            style={{
                                                fontSize: '24px',
                                            }}
                                        />
                                        <h3>Gráfico indisponível</h3>
                                        <p>
                                            Não temos dados suficientes para
                                            gerar o gráfico de sazonalidade
                                            mensal deste produto
                                        </p>
                                    </div>
                                )}
                            </div>
                        </Col>
                        <Col xs={24} md={12} lg={16}>
                            <div className="grafico-card">
                                <h2>Gráfico de sazonalidade mensal</h2>
                                <p>
                                    Comportamento de venda mensal baseado em
                                    histórico
                                </p>
                                {graficoMensal ? (
                                    <HighchartsReact
                                        highcharts={Highcharts}
                                        options={graficoMensal}
                                    />
                                ) : (
                                    <div className="grafico-indisponivel">
                                        <WarningRoundedIcon
                                            style={{
                                                fontSize: '24px',
                                            }}
                                        />
                                        <h3>Gráfico indisponível</h3>
                                        <p>
                                            Não temos dados suficientes para
                                            gerar o gráfico de sazonalidade
                                            mensal deste produto
                                        </p>
                                    </div>
                                )}
                            </div>
                        </Col>
                        <Col xs={24} md={24} lg={24}>
                            {graficoElasticidade ? (
                                <div className="grafico-card-elasticidade">
                                    <h2>Elasticidade do produto</h2>
                                    <HighchartsReact
                                        highcharts={Highcharts}
                                        options={graficoElasticidade}
                                    />
                                    <h3>
                                        Esse produto é
                                        {' '}
                                        {elasticity >= 0
                                        && elasticity <= 1
                                            ? 'Inelástico'
                                            : elasticity >= 1
                                              && elasticity <= 2
                                            ? 'Pouco elástico'
                                            : elasticity >= 2
                                              && elasticity <= 3
                                            ? 'Elástico'
                                            : elasticity >= 3
                                            ? 'Muito elástico'
                                            : ''}
                                    </h3>
                                    <p>
                                        {elasticity >= 0
                                        && elasticity <= 1
                                            ? 'Variações de preços não impactam nas vendas do produto'
                                            : elasticity >= 1
                                              && elasticity <= 2
                                            ? 'Variações de preços podem impactar nas vendas do produto'
                                            : elasticity >= 2
                                              && elasticity <= 3
                                            ? 'Pequenas variações de preços impactam nas vendas do produto'
                                            : elasticity >= 3
                                            ? 'Pequenas variações de preços impactam fortemente nas vendas do produto'
                                            : ''}
                                    </p>
                                </div>
                            ) : (
                                <div className="grafico-indisponivel-elasticidade">
                                    <h2>Elasticidade do produto</h2>
                                    <WarningRoundedIcon
                                        style={{
                                            fontSize: '24px',
                                        }}
                                    />
                                    <h3>Gráfico indisponível</h3>
                                    <p>
                                        Não temos dados suficientes para gerar o
                                        gráfico de elasticidade deste produto
                                    </p>
                                </div>
                            )}
                        </Col>
                    </Row>
                </Grid>
            )}
        </div>
    </Content>
);

export default Simulacao;
