import React from 'react';
import { Modal, Button } from 'rsuite';
import { useSelector } from 'react-redux';
import { selectorCalculadoraPrevisaoDemanda } from '../../../../reducers/calculadoraPrevisaoDemanda';
import styles from './ModalUpdateScenario.module.scss';

const {
    Header, Title, Body, Footer,
   } = Modal;

type ModalUpdateScenarioProps = {
    show?: boolean,
    onHide?: () => void,
    onClickSave?: () => void,
    onClickSaveNew?: () => void,
}

export const ModalUpdateScenario = ({
    show,
    onHide,
    onClickSave,
    onClickSaveNew,
}: ModalUpdateScenarioProps) => {
    const { resultScenario } = useSelector(selectorCalculadoraPrevisaoDemanda);
    const { scenario } = resultScenario.resultScenarioData;

    return (
        <Modal
            show={show}
            className={styles['modal-update-scenario']}
            onHide={onHide}
        >
            <Header className={styles.header} closeButton={false}>
                <Title>Salvar alterações</Title>
            </Header>
            <Body>
                <p className={styles.description}>Salvar as alterações do cenário</p>
                <p className={styles.description}>{scenario.scenario_name}</p>
            </Body>
            <Footer>
                <Button
                    className={styles['btn-cancel']}
                    appearance="ghost"
                    onClick={onHide}
                >
                    Cancelar
                </Button>
                <Button
                    id="btn-save-new-scenario"
                    className={styles['btn-save-new']}
                    appearance="ghost"
                    onClick={onClickSaveNew}
                >
                    Salvar Novo
                </Button>
                <Button
                    id="btn-save-scenario"
                    className={styles['btn-save']}
                    appearance="primary"
                    onClick={onClickSave}
                >
                    Salvar
                </Button>
            </Footer>
        </Modal>
);
};
