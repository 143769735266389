import React from 'react';
import styles from './ModalHowItWorks.module.scss';

const HowItWorksDescription = () => {
  return (
      <div>
          <h6 className={styles['bold-title']}>O que é o On Demand?</h6>
          <p className={styles.answer}>
              É uma modalidade de priorização da captura de dados de nota fiscal.
              A busca desses dados é feita através das Secretarias da Fazenda de cada estado,
              portanto dependemos de que essa UF esteja disponível para capturarmos.
              A lista de UFs possíveis pode ser vista na tabela abaixo
          </p>

          <h6 className={styles['bold-title']}>O que é uma solicitação de On Demand?</h6>
          <p className={styles.answer}>
              Cada solicitação é a combinação de um local+produto.
              O
              {' '}
              <span className={styles.bold}>local</span>
              {' '}
              solicitado pode ser uma
              {' '}
              <span className={styles.bold}>cidade</span>
              {' '}
              ou
              {' '}
              <span className={styles.bold}>um endereço, atrelado a um raio de distância</span>
              .
              Os produtos podem ser buscados por GTIN ou token
              (parte do descritivo do produto, que chamamos de palavra-chave).
              {' '}
              <span className={styles.bold}>Não é possível buscar por lojas específicas, ou redes ou tipos de loja.</span>
          </p>
          <h6 className={styles['bold-title']}>Retorno do On Demand</h6>
          <p className={styles.answer}>
              Cada solicitação tem potencial de retorno máximo entre
              {' '}
              <span className={styles.bold}>20 e 100 datapoints</span>
              ,
              mas isso não quer dizer que o máximo sempre será atingido.
              O retorno depende do que essa mesma busca traz de dados na nossa fonte (SeFaz).
              O SLA de retorno entre a solicitação e a primeira entrada de dados é entre 2 e 3 dias.
          </p>
      </div>
  );
};

export default HowItWorksDescription;
