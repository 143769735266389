import Immutable from 'seamless-immutable';
import { ENTER_ADMIN_AREA, SET_ADMINISTRADOR, SET_ADMINISTRADORES } from '../actions/actionsAdmin'

const adminInitialState = Immutable({
    enterAdmin: false,
    administrador: false,
    administradores: null
})


function adminDataReducer(state = adminInitialState, action) {
    switch (action.type) {
        case ENTER_ADMIN_AREA:
            return state.merge({
                enterAdmin: action.enterAdmin
            })
        case SET_ADMINISTRADOR:
            return state.merge({
                administrador: action.administrador
            })
        case SET_ADMINISTRADORES:
            return state.merge({
                administradores: action.administradores
            })
        default:
            return state
    }
}

export default adminDataReducer;