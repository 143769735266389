import { useState } from 'react';
import { SAVED_FILTER_ITEM } from '../../../pages/IPA/RevisaoPrecos/utils';

export type FilterSavedItem = SAVED_FILTER_ITEM;

const initialData = {} as FilterSavedItem;

export type UseFilterSavedProps = FilterSavedItem & {
    onSelect: (item: FilterSavedItem) => void;
    onClean: () => void;
}

export const useFilterSaved = () => {
    const [filter, setFilter] = useState<FilterSavedItem>(initialData);

    const setUseFilterSavedData = (payload: FilterSavedItem) => {
        setFilter(payload);
    };

    const onSelect = (item: FilterSavedItem) => {
        setUseFilterSavedData(item);
    };

    const onClean = () => {
        setFilter(initialData);
    };

    const onSet = (data: Record<'label' | 'value', string>) => {
        setFilter(data as FilterSavedItem);
    };

    return [filter, { onSelect, onClean, onSet }] as const;
};
