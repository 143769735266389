import React from 'react';
import { Control, Controller } from 'react-hook-form';
import { InputBox } from '../../../../../components';
import { SchemaNewRuleProps, data } from '../../pages/RegrasDePreco/lib';

type BaseProps = React.HtmlHTMLAttributes<HTMLElement> & {
    control: Control<SchemaNewRuleProps>;
};

type RecurrenceType = 'DAILY' | 'WEEKLY' | 'MONTHLY';

type RecurrenceComponent = {
    DAILY: () => null;
    WEEKLY: (props: BaseProps) => React.JSX.Element;
    MONTHLY: (props: BaseProps) => React.JSX.Element;
};

const RECURRENCE_COMPONENTS: RecurrenceComponent = {
    DAILY: () => null,
    WEEKLY: ({ control, ...props }) => (
        <Controller
            name="daysOfWeek"
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
                <InputBox {...props}>
                    <InputBox.Label htmlFor="daysOfWeek">
                        Dia da semana
                    </InputBox.Label>
                    <InputBox.CheckGroup
                        inline
                        value={value ?? []}
                        onChange={onChange}
                    >
                        <InputBox.Check value="SUNDAY">Dom</InputBox.Check>
                        <InputBox.Check value="MONDAY">Seg</InputBox.Check>
                        <InputBox.Check value="TUESDAY">Ter</InputBox.Check>
                        <InputBox.Check value="WEDNESDAY">Qua</InputBox.Check>
                        <InputBox.Check value="THURSDAY">Qui</InputBox.Check>
                        <InputBox.Check value="FRIDAY">Sex</InputBox.Check>
                        <InputBox.Check value="SATURDAY">Sáb</InputBox.Check>
                    </InputBox.CheckGroup>
                    <InputBox.Error message={error?.message} />
                </InputBox>
            )}
        />
    ),
    MONTHLY: ({ control, ...props }) => (
        <>
            <Controller
                name="monthlyRecurrenceType"
                control={control}
                render={({ field, fieldState: { error } }) => (
                    <InputBox {...props}>
                        <InputBox.Label>Semana do mês</InputBox.Label>
                        <InputBox.Select
                            data={data.monthlyRecurrenceType}
                            placeholder="Selecione uma opção"
                            searchable={false}
                            cleanable={false}
                            {...field}
                        />
                        <InputBox.Error message={error?.message} />
                    </InputBox>
                )}
            />
            <Controller
                name="monthlyRecurrenceDayOfWeek"
                control={control}
                render={({ field, fieldState: { error } }) => (
                    <InputBox {...props}>
                        <InputBox.Label>Dia da semana</InputBox.Label>
                        <InputBox.Select
                            data={data.monthlyRecurrenceDayOfWeek}
                            placeholder="Selecione uma opção"
                            searchable={false}
                            cleanable={false}
                            {...field}
                        />
                        <InputBox.Error message={error?.message} />
                    </InputBox>
                )}
            />
        </>
    ),
} as const;

type RecurrenceComponentProps = React.HtmlHTMLAttributes<HTMLElement> & {
    recurrenceType?: RecurrenceType;
    control: Control<SchemaNewRuleProps>;
};

export const RecurrenceComponent = ({
    recurrenceType,
    ...props
}: RecurrenceComponentProps) => {
    if (!recurrenceType) return null;
    const Component = RECURRENCE_COMPONENTS[recurrenceType];
    return <Component {...props} />;
};
