export const calculateExpandedRowHeight = (options: string[]) => {
    const conditions = [
        { condition: 'COMPETITORS', height: 180 },
        { condition: 'WHOLESALE', height: 60 },
    ];

    return conditions.reduce((totalHeight, condition) => {
        if (options.includes(condition.condition)) {
            return totalHeight + condition.height;
        }
        return totalHeight;
    }, 0);
};
