import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    setInputCalculosValue,
} from '../../../../reducers/calculadoraPrevisaoDemanda/inputCalculos';
import { selectorCalculadoraPrevisaoDemanda } from '../../../../reducers/calculadoraPrevisaoDemanda/calculadoraPrevisaoDemandaReducer';
import { FormBox } from '../FormBox';
import { InputAddon } from '../index';

export interface IPricePassOn{
    onChange?: (arg: string) => void;
}

export const PricePassOnForm = () => {
    const { inputCalculos } = useSelector(
        selectorCalculadoraPrevisaoDemanda,
    );

    const dispatch = useDispatch();

    const handleChangePricePassOn = (value: string) => {
        const obj = { name: 'competitor_price_follow_up', value: Number(value) };
        dispatch(setInputCalculosValue(obj));
    };

    return (
        <FormBox.Root>
            <FormBox.Row>
                <FormBox.Label width="160px">
                    Repasse de preço pelo concorrente
                </FormBox.Label>
                <InputAddon
                    position="right"
                    addon="%"
                    width="96px"
                    value={inputCalculos.inputs.competitor_price_follow_up}
                    onChange={handleChangePricePassOn}
                />
            </FormBox.Row>
        </FormBox.Root>
    );
};
