import React from 'react';
import { Modal, Button } from 'rsuite';

const ModalConfiguracaoRegras = ({
    show,
    title,
    subtitle,
    message,
    onCancel,
    cancelButton,
    onConfirm,
    confirmButton,
}) => (
    <Modal backdrop show={show} className="modal-configuracao-regras">
        <Modal.Header closeButton={false}>
            <Modal.Title className="title">
                {title}
            </Modal.Title>
            <Modal.Title className="subtitle">
                {subtitle}
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>{message}</Modal.Body>
        <hr />
        <Modal.Footer>
            <Button onClick={onCancel} appearance="ghost" size="lg">
                {cancelButton}
            </Button>
            <Button onClick={onConfirm} appearance="primary" size="lg">
                {confirmButton}
            </Button>
        </Modal.Footer>
    </Modal>
);

export default ModalConfiguracaoRegras;
