import React from 'react';
import {
    Button,
    Whisper,
    Tooltip,
} from 'rsuite';
import { format } from 'date-fns';
import { ForecastNumbers } from '../ForecastNumbers';
import { Indicator } from '../Indicator';
import * as utils from '../../utils';

import styles from './ComparisonScenarioColumn.module.scss';

type ComparisonScenarioColumnProps = {
    onClickOpenScenario?: (arg: string) => void;
    data?: any;
    dataLength?: number;
}

export const ComparisonScenarioColumn = ({
    data, dataLength = 0, onClickOpenScenario,
}: ComparisonScenarioColumnProps) => {
    return (
        <div className={styles.column}>
            <div className={styles.header}>
                <div className={styles.title}>
                    <Whisper
                        trigger="hover"
                        placement="auto"
                        speaker={<Tooltip>{data?.scenario?.scenario_name}</Tooltip>}
                    >
                        <span>{utils.limitTitleText(data?.scenario?.scenario_name, dataLength)}</span>
                    </Whisper>
                </div>
                <div className={styles['middle-subtitle']}>
                    <span>
                        {data?.scenario?.simulation_date
                            ? format(new Date(data?.scenario?.simulation_date), 'dd/MM/yyyy')
                            : format(new Date(), 'dd/MM/yyyy')}
                    </span>
                    <span className="days">
                        {data?.inputs.preferences?.period_in_days || '-'}
                        {' '}
                        dias
                    </span>
                    <span>
                        Repasse
                        {' '}
                        {data?.inputs.competitor_price_follow_up}
                        %
                    </span>
                </div>
                <div className={styles['bottom-subtitle']}>
                    <span className="competitor">Concorrente:</span>
                    <Whisper
                        trigger="hover"
                        placement="auto"
                        speaker={<Tooltip>{data?.query.competitor_product_description}</Tooltip>}
                    >
                        <span className={styles['competitor-name']}>
                            {utils.limitCompetitorText(data?.query.competitor_product_description, dataLength)}
                        </span>
                    </Whisper>
                </div>
            </div>
            <div className={styles.subheader}>
                <div className={styles['subheader-title']}>
                    <span>{data?.query?.product_id}</span>
                    <span className="dash">-</span>
                    <span>{data?.query?.product_description}</span>
                </div>
                <div className={styles['subheader-subtitle']}>
                    <span>{data?.query?.location}</span>
                    <span className="chevron">{'>'}</span>
                    <span>{data?.query?.channel}</span>
                    <span className="chevron">{'>'}</span>
                    <span>{data?.query?.sub_channel === null ? 'Todo o canal' : data?.query?.sub_channel}</span>
                    <span className="chevron">{'>'}</span>
                    <span>{data?.query?.payment_method}</span>
                </div>
            </div>
            <div>
                <ForecastNumbers.Root
                    className={styles['forecast-box']}
                    title="preço tabela"
                >
                    <ForecastNumbers.MainValue margin="6px 0 3px 0">
                        {utils.formatNumberToCurrency(data?.outputs.new_price_scenario?.price, 2)}
                    </ForecastNumbers.MainValue>
                    <Indicator
                        value={utils.formatIndicatorValue(data?.outputs.base_to_new_variations?.price)}
                    />
                    <ForecastNumbers.SubValue margin="3px 0 0 0">
                        {utils.formatNumberToCurrency(data?.outputs.base_price_scenario?.price, 2)}
                    </ForecastNumbers.SubValue>
                </ForecastNumbers.Root>
                <ForecastNumbers.Root
                    className={styles[data?.highlight.margem ? 'highlight-box' : 'forecast-box']}
                    title="margem de contribuição"
                >
                    <ForecastNumbers.MainValue margin="6px 0 3px 0">
                        {data?.outputs.new_price_scenario?.margin
                        ? utils.formatLocaleNumber(utils.formatIndicatorValue(data?.outputs.new_price_scenario?.margin), 1)
                        : 0}
                        %
                    </ForecastNumbers.MainValue>
                    <Indicator
                        type="pp"
                        value={utils.formatIndicatorValue(data?.outputs.base_to_new_variations?.margin)}
                    />
                    <ForecastNumbers.SubValue margin="3px 0 0 0">
                        {data?.outputs.base_price_scenario?.margin
                        ? utils.formatLocaleNumber(utils.formatIndicatorValue(data?.outputs.base_price_scenario?.margin), 1)
                        : 0}
                        %
                    </ForecastNumbers.SubValue>
                </ForecastNumbers.Root>
                <ForecastNumbers.Root
                    className={styles[data?.highlight.demanda ? 'highlight-box' : 'forecast-box']}
                    title="previsão de demanda (un)"
                >
                    <ForecastNumbers.MainValue margin="6px 0 3px 0">
                        {data?.outputs.new_price_scenario?.demand_forecast
                        ? utils.formatLocaleNumber(data?.outputs.new_price_scenario?.demand_forecast, 0)
                        : 0}
                    </ForecastNumbers.MainValue>
                    <Indicator value={utils.formatIndicatorValue(data?.outputs.base_to_new_variations?.demand_forecast)} />
                    <ForecastNumbers.SubValue margin="3px 0 0 0">
                        {data?.outputs.base_price_scenario?.demand_forecast
                        ? utils.formatLocaleNumber(data?.outputs.base_price_scenario?.demand_forecast, 0)
                        : 0}
                    </ForecastNumbers.SubValue>
                </ForecastNumbers.Root>
                <ForecastNumbers.Root
                    className={styles[data?.highlight.receita ? 'highlight-box' : 'forecast-box']}
                    title="previsão de receita (R$)"
                >
                    <ForecastNumbers.MainValue margin="6px 0 3px 0">
                        {utils.formatNumberToCurrency(data?.outputs.new_price_scenario?.revenue_forecast, 0)}
                    </ForecastNumbers.MainValue>
                    <Indicator value={utils.formatIndicatorValue(data?.outputs.base_to_new_variations?.revenue_forecast)} />
                    <ForecastNumbers.SubValue margin="3px 0 0 0">
                        {utils.formatNumberToCurrency(data?.outputs.base_price_scenario?.revenue_forecast, 0)}
                    </ForecastNumbers.SubValue>
                </ForecastNumbers.Root>
                <ForecastNumbers.Root
                    className={styles[data?.highlight.lucro ? 'highlight-box' : 'forecast-box']}
                    title="previsão lucro (R$)"
                >
                    <ForecastNumbers.MainValue margin="6px 0 3px 0">
                        {utils.formatNumberToCurrency(data?.outputs.new_price_scenario?.profit_forecast, 0)}
                    </ForecastNumbers.MainValue>
                    <Indicator value={utils.formatIndicatorValue(data?.outputs.base_to_new_variations?.profit_forecast)} />
                    <ForecastNumbers.SubValue margin="3px 0 0 0">
                        {utils.formatNumberToCurrency(data?.outputs.base_price_scenario?.profit_forecast, 0)}
                    </ForecastNumbers.SubValue>
                </ForecastNumbers.Root>
            </div>
            <div className={styles['btn-wrapper']}>
                <Button
                    id="abrir-cenario"
                    appearance="primary"
                    onClick={() => onClickOpenScenario(data?.scenario.scenario_id)}
                >
                    ABRIR CENÁRIO
                </Button>
            </div>
        </div>
  );
};
