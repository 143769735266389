import React, { forwardRef } from 'react';

export type CardContentProps = React.HtmlHTMLAttributes<HTMLDivElement>;

export const CardContent = forwardRef(
    (
        { children, className = '', ...props }: CardContentProps,
        ref: React.LegacyRef<HTMLDivElement>,
    ) => (
        <div
            ref={ref}
            className={['info-card__content', className].join(' ')}
            {...props}
        >
            {children}
        </div>
    ),
);
