import React from 'react';
import { Loader, Content } from 'rsuite';
// @ts-ignore
import { useItensPesquisados } from '../../../hooks/useItensPesquisados.tsx';

export const ItensPesquisados: React.FC = () => {
    const { data, isLoading } = useItensPesquisados();

    if (isLoading)
        return (
            <Content className="itens-pesquisados-loading">
                <Loader center />
            </Content>
        );

    if (!data?.count)
        return (
            <Content className="itens-pesquisados-sem-dados">
                <h3>Sem dados para essa análise</h3>
                <p>Revise os filtros e tente novamente</p>
            </Content>
        );

    return (
        <Content className="itens-pesquisados">
            <div>
                <p>{data?.count}</p>
                <span>Número de itens pesquisados</span>
            </div>
        </Content>
    );
};
