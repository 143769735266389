import React from 'react';
import { DateRangePicker } from 'rsuite';

const DataRangePicker = (props) => {
    const {
        id, size, placeholder, maxDate, minDate, onChangeValue, name, ranges, hoverRange, oneTap, preventOverflow, value, onEnter,
    } = props;

    const { combine, after, before } = DateRangePicker;
    return (
        <DateRangePicker
            block
            onEnter={onEnter}
            id={id}
            oneTap={oneTap}
            size={size}
            name={name}
            value={value}
            onChange={onChangeValue}
            preventOverflow={preventOverflow}
            disabledDate={(maxDate || minDate) ? (combine(before(minDate), after(maxDate))) : null}
            appearance="default"
            placeholder={placeholder}
            format="DD/MM/YYYY"
            hoverRange={hoverRange}
            locale={{
                sunday: 'Dom',
                monday: 'Seg',
                tuesday: 'Ter',
                wednesday: 'Qua',
                thursday: 'Qui',
                friday: 'Sex',
                saturday: 'Sáb',
                ok: 'OK',
                today: 'Hoje',
            }}
            ranges={ranges}
        />

    );
};

export default DataRangePicker;
