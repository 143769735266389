import { useQuery } from '@tanstack/react-query';
import { format, parseISO } from 'date-fns';
import { getLastUpdate } from '../../../../services/ProdutoService_ts';

export const useDataUltimaIntegracao = () => {
    const { data, isLoading } = useQuery({
        queryKey: ['last-update'],
        queryFn: getLastUpdate,
        retry: false,
    });

    return {
        data,
        isLoading,
        parsedDate: data?.lastExecutionDate
            ? format(
                  parseISO(data?.lastExecutionDate ?? ''),
                  'dd/MM/yyy, HH:mm',
              )
            : null,
    };
};
