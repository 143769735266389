import classNames from 'classnames';
import React from 'react';
import CurrencyFormat from 'react-currency-format';
import { MdFlag } from 'react-icons/md';
import styles from '../../../gerenciadorPrecos.module.scss';
import BaseCell, { BaseCellProps } from '../BaseCell/BaseCell';

type ObjectiveMarginCellProps = BaseCellProps;

const ObjectiveMarginCell = (props: ObjectiveMarginCellProps) => {
    const {
        rowData: { novaMargem, objectiveMargin },
    } = props;

    const isDisruptive =
        !!novaMargem && objectiveMargin && novaMargem < objectiveMargin;

    return (
        <BaseCell {...props}>
            {objectiveMargin ? (
                <div className={styles['table__cell__margem-objetiva']}>
                    <MdFlag
                        width={11}
                        className={classNames(
                            isDisruptive && 'color-disruptive',
                        )}
                    />
                    <CurrencyFormat
                        fixedDecimalScale
                        decimalScale={1}
                        value={objectiveMargin}
                        displayType="text"
                        suffix="%"
                        decimalSeparator=","
                        className={classNames(
                            'font-size-100-semibold',
                            isDisruptive && 'color-disruptive',
                        )}
                    />
                </div>
            ) : (
                '--'
            )}
        </BaseCell>
    );
};

export default ObjectiveMarginCell;
