import AddIcon from '@material-ui/icons/Add';
import React from 'react';
import {
    Button,
} from 'rsuite';
import styles from './styles.module.scss';

export type RequestAddressContainerTypes = {
    addressFormArea?: React.ReactNode;
    progressBarArea?: React.ReactNode;
    onClickAddAddress?: () => void;
    disabled?: boolean;
}

export const RequestAddressContainer = ({
 addressFormArea, progressBarArea, onClickAddAddress, disabled,
}: RequestAddressContainerTypes) => {
    return (
        <div className={styles['request-address-container']}>
            {addressFormArea}
            <Button
                className={styles['add-address-button']}
                onClick={onClickAddAddress}
                disabled={!disabled}
            >
                <AddIcon style={{ fontSize: '18px' }} color="#378EF0" />
                Adicionar novo endereço
            </Button>
            <div className={styles['progress-bar']}>
                {progressBarArea}
            </div>
        </div>
);
};
