import React, { ReactNode } from 'react';

import styles from './ForecastNumbers.module.scss';

export interface IForecastNumbersSubValue {
    children: ReactNode;
    margin?: string;
    fontSize?: string;
}

export const ForecastNumbersSubValue = ({
    children,
    margin = '0',
    fontSize = '14px',
}: IForecastNumbersSubValue) => (

    <p className={styles['sub-value']} style={{ margin, fontSize }}>
        {children}
    </p>
);
