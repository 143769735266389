import { useMutation, useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import classNames from 'classnames';
import React, { HtmlHTMLAttributes, useReducer } from 'react';
import { Alert, Divider, RadioGroup, Toggle } from 'rsuite';
import { SetupIPAHeader, SetupIPAOption } from '.';
import { queryClient } from '../../../../../../App';
import {
    ButtonPrimary,
    Callout,
    InfoRadio,
} from '../../../../../../components';
import { LoadingSpinerArea } from '../../../../../../components/LoadingSpinner';
import styles from '../SetupIPA.module.scss';
import {
    getSetupIPAOptions,
    patchSetupIPAOptions,
    postSetupIPAOptions,
    putSetupIPAOptions,
} from '../SetupIPA.services';
import * as T from '../SetupIPA.types';

const reducer = (
    data: T.SetupIPAFormStateProps,
    action: T.SetupIPAFormStateActionProps,
): T.SetupIPAFormStateProps => ({ ...data, [action.key]: action.payload });

const ADD_DATA_BY_KEY = (
    key: T.SetupIPAFormStateActionProps['key'],
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any,
) => ({
    key,
    payload: value,
});

const INITIAL_STATE: T.SetupIPAFormStateProps = {
    info_panel_data_able: false,
    store_type: 'SAME_STORE_TYPE',
    same_location: true,
    radius: 2,
    period: 7,
};

let USER_INITIAL_DATA: null | T.SetupIPAFormStateProps = null;

const OPTIONS = {
    store_type: [
        {
            value: 'SAME_STORE_TYPE',
            children: 'Usar somente o mesmo tipo de loja',
        },
        {
            value: 'SAME_NETWORK_DIRECT_COMPETITOR',
            children: 'Usar somente as mesmas redes dos concorrentes diretos',
        },
        {
            value: 'ALL_STORE_TYPE',
            children: 'Usar todos os tipos de loja',
        },
    ],
    same_location: [
        {
            value: true,
            children: 'Usar somente concorrentes da mesma cidade da loja',
        },
        {
            value: false,
            children:
                'Usar concorrentes definidos dentro de um raio de alcance a partir da loja',
        },
    ],
    radius: [
        {
            value: 2,
            children: '2 km',
        },
        {
            value: 5,
            children: '5 km',
        },
        {
            value: 10,
            children: '10 km',
        },
    ],
    period: [
        {
            value: 7,
            children: 'Últimos 7 dias',
        },
        {
            value: 14,
            children: 'Últimos 14 dias',
        },
    ],
};

export const SetupIPAForm = ({
    className,
    ...props
}: HtmlHTMLAttributes<HTMLDivElement>) => {
    const [data, dispatch] = useReducer(reducer, INITIAL_STATE);

    const updateStateWithData = (data: T.SetupIPAFormStateProps) => {
        Object.entries(data).forEach(([key, value]) => {
            dispatch(
                ADD_DATA_BY_KEY(
                    key as T.SetupIPAFormStateActionProps['key'],
                    value,
                ),
            );
        });
        USER_INITIAL_DATA = data;
    };

    useQuery({
        queryKey: ['get-setup-ipa'],
        queryFn: getSetupIPAOptions,
        onSuccess: updateStateWithData,
        retry: false,
    });

    const handleChange = (
        key: T.SetupIPAFormStateActionProps['key'],
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        value: any,
    ) => dispatch({ key, payload: value });

    const handleToggleChange = (checked: boolean) => {
        if (checked) handleChange('info_panel_data_able', checked);
        else {
            patchSetupIPAOptions({ info_panel_data_able: checked }).then(
                (resp) => {
                    handleChange('info_panel_data_able', checked);
                    return resp;
                },
            );
        }
    };

    const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        let response = null;

        try {
            if (USER_INITIAL_DATA) response = await putSetupIPAOptions(data);
            else response = await postSetupIPAOptions(data);
            Alert.success('Configurações de competitividade editada');
        } catch (error) {
            return error as AxiosError;
        }

        return response;
    };

    const { mutateAsync } = useMutation({
        mutationFn: onSubmit,
        onSuccess: () => {
            queryClient.invalidateQueries(['dados-infopanel']);
        },
    });

    return (
        <>
            <div
                className={classNames(
                    styles['setup-ipa__container'],
                    className,
                )}
                {...props}
            >
                <SetupIPAHeader
                    title="Configurações de competitividade"
                    subtitle="Habilite os dados de concorrência InfoPanel quando não
                houver retorno de competidor direto"
                    className="card"
                >
                    <span
                        className={
                            styles['setup-ipa__header__toggle-container']
                        }
                    >
                        <Toggle
                            checked={data.info_panel_data_able}
                            onChange={handleToggleChange}
                            size="sm"
                        />
                        <p>Utilizar dados do InfoPanel</p>
                    </span>
                </SetupIPAHeader>

                {data.info_panel_data_able && (
                    <form
                        onSubmit={mutateAsync}
                        className={classNames(styles['setup-ipa__form'])}
                    >
                        <div>
                            <SetupIPAHeader
                                title="Configurações de competitividade"
                                subtitle="Defina as características de retorno dos dados
                        de concorrência via InfoPanel."
                            />
                            <Divider />
                            <SetupIPAOption
                                title="TIPO DE LOJA"
                                subtitle="O tipo de loja é uma decisão importante para análise
                        comparativa de preços! Escolha o mesmo tipo de loja que
                        a sua ou todos os tipos de loja, de acordo com sua
                        estratégia."
                            >
                                <RadioGroup
                                    value={data.store_type}
                                    onChange={(value) => {
                                        handleChange('store_type', value);
                                    }}
                                >
                                    {OPTIONS.store_type.map((storeType) => (
                                        <InfoRadio {...storeType} />
                                    ))}
                                </RadioGroup>
                            </SetupIPAOption>
                            <Divider />
                            <div className={styles['setup-ipa__location']}>
                                <SetupIPAOption
                                    title="localização"
                                    subtitle="Escolha qual o parâmetro de localização dos concorrentes indiretos a partir da loja que será precificada"
                                >
                                    <RadioGroup
                                        value={data.same_location}
                                        onChange={(value) => {
                                            handleChange(
                                                'same_location',
                                                value,
                                            );
                                            if (value) {
                                                handleChange('radius', null);
                                            } else {
                                                handleChange(
                                                    'radius',
                                                    INITIAL_STATE.radius,
                                                );
                                            }
                                        }}
                                    >
                                        {OPTIONS.same_location.map(
                                            (location) => (
                                                <InfoRadio {...location} />
                                            ),
                                        )}
                                    </RadioGroup>
                                </SetupIPAOption>
                                {!data.same_location && (
                                    <SetupIPAOption
                                        title="Raio de alcance"
                                        subtitle="Esta configuração irá considerar os concorrentes dentro do raio definido no momento de retorno de preços!"
                                    >
                                        <RadioGroup
                                            value={data.radius}
                                            onChange={(value) => {
                                                handleChange('radius', value);
                                            }}
                                        >
                                            {OPTIONS.radius.map((option) => (
                                                <InfoRadio inline {...option} />
                                            ))}
                                        </RadioGroup>
                                    </SetupIPAOption>
                                )}
                            </div>
                            <Divider />
                            <SetupIPAOption
                                title="PERÍODO de tempo"
                                subtitle="Defina o período máximo de
                média de preços via InfoPanel, cada opção indica que exibiremos preços até o limite de 7 ou 14 dias, conforme definido."
                            >
                                <RadioGroup
                                    value={data.period}
                                    onChange={(value) => {
                                        handleChange('period', value);
                                    }}
                                >
                                    {OPTIONS.period.map((period) => (
                                        <InfoRadio {...period} />
                                    ))}
                                </RadioGroup>
                            </SetupIPAOption>
                        </div>

                        <div>
                            <Callout variant="warning" size="sm">
                                As modificações serão aplicadas na próxima
                                integração de dados.
                            </Callout>
                        </div>

                        <div className={styles['setup-ipa__form__footer']}>
                            <ButtonPrimary type="submit" skin="blue">
                                SALVAR
                            </ButtonPrimary>
                        </div>
                    </form>
                )}
            </div>
            <LoadingSpinerArea area="setup-ipa" height="100%" size="sm" />
        </>
    );
};
