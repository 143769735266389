import { trackPromise } from 'react-promise-tracker';
import api from '../../utils/API';

export const getToken = (data) => trackPromise(
    api({
        url: `${process.env.REACT_APP_PORTAL_ACESSO_URL}/oauth/token`,
        method: 'POST',
        params: {
            grant_type: data.grant_type,
            password: data.password,
            username: data.username,
        },
        expectToken: false,
    }).then((resp) => resp.data),
);

export const verifyToken = (token) => api({
    url: `${process.env.REACT_APP_PORTAL_ACESSO_URL}/oauth/check_token`,
    method: 'POST',
    params: {
        token,
    },
}).then((resp) => resp.data);
