import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { InputCalculos, ConcorrenciaInfopanel, QueryCalculos } from '../../pages/CalculadoraPrevisaoDemanda/types';

export type InputCalculosNameProps =
    | 'base_price'
    | 'new_price'
    | 'cost'
    | 'average_monthly_sales'
    | 'taxes'
    | 'competitor_price_follow_up'
    | 'preferences'
    | 'totalTaxes'
    | 'ipi'
    | 'icms'
    | 'pis'
    | 'cofins'
    | 'others'
    | 'period_in_days'
    | 'enable_seasonality'
    | 'variacaoPrecoTabela'
    | 'concorrenciaInfopanel'
    | 'query'

export type InputCalculosStateProps = {
    precoAtualTabela: number | null,
    novoPrecoTabela: number | null,
    variacaoPrecoTabela: number | null,
    custoProduto: number | null,
    demandaAtual: number | null,
    ipi: number | null,
    icms: number | null,
    pis: number | null,
    cofins: number | null,
    outros: number | null,
    repassePreco: number | null,
    periodoCalculo: number | null,
    inputs: InputCalculos,
    concorrenciaInfopanel: ConcorrenciaInfopanel;
    query: QueryCalculos;
};

const initialState: InputCalculosStateProps = {
    variacaoPrecoTabela: 0,
    concorrenciaInfopanel: {
        client_product_price: 0,
        competitor_product_price: 0,
        cpi: 0,
    },
    inputs: {
        base_price: 0,
        new_price: 0,
        cost: 0,
        average_monthly_sales: 0,
        taxes: {
            totalTaxes: 0,
            ipi: 0,
            icms: 0,
            pis: 0,
            cofins: 0,
            others: 0,
        },
        competitor_price_follow_up: 100,
        preferences: {
            period_in_days: 360,
            enable_seasonality: true,
        },
    },
    query: {
        channel: '',
        competitor_product_description: '',
        competitor_product_id: '',
        location: '',
        location_type: '',
        payment_method: '',
        product_description: '',
        product_id: '',
        sub_channel: '',
    },
};

const slice = createSlice({
    name: 'input-calculos-state',
    initialState,
    reducers: {
        setInputCalculosValue(state, { payload }: PayloadAction<{
                name: InputCalculosNameProps;
                value: number;
            }>) {
            state.inputs[payload.name] = payload.value;
        },
        setInputCalculosState(state, { payload }: PayloadAction<{
            name: InputCalculosNameProps;
            value: any;
        }>) {
            state[payload.name] = payload.value;
        },
        setInputTaxesValue(state, { payload }: PayloadAction<{
            name: InputCalculosNameProps;
            value: number;
        }>) {
            state.inputs.taxes = {
                ...state.inputs.taxes,
                [payload.name]: payload.value,
            };
        },
        setInputValuesFromRequest(state, { payload }: PayloadAction<{
            data: any;
        }>) {
            const { inputs, query } = payload.data;
            state.inputs = {
                base_price: inputs.base_price === null ? 0 : inputs.base_price,
                new_price: inputs.new_price === null ? 0 : inputs.new_price,
                cost: inputs.cost === null ? 0 : inputs.cost,
                average_monthly_sales: inputs.average_monthly_sales === null ? 0 : inputs.average_monthly_sales,
                taxes: {
                    totalTaxes: inputs.taxes.totalTaxes === null ? 0 : (inputs.taxes.totalTaxes * 100),
                    ipi: inputs.taxes.ipi === null ? 0 : (inputs.taxes.ipi * 100),
                    icms: inputs.taxes.icms === null ? 0 : (inputs.taxes.icms * 100),
                    pis: inputs.taxes.pis === null ? 0 : (inputs.taxes.pis * 100),
                    cofins: inputs.taxes.cofins === null ? 0 : (inputs.taxes.cofins * 100),
                    others: inputs.taxes.others === null ? 0 : (inputs.taxes.others * 100),
                },
                competitor_price_follow_up: inputs.competitor_price_follow_up === null ? 100 : inputs.competitor_price_follow_up,
                preferences: {
                    period_in_days: inputs.preferences.period_in_days === null ? 360 : inputs.preferences.period_in_days,
                    enable_seasonality: true,
                },
            };
            state.query = query;
        },
        clearInputCalculos() {
            return initialState;
        },
        resetInputCalculos() {
            return initialState;
        },
        setInputFromEditScenario(state, { payload }: PayloadAction<{
            data: any;
        }>) {
            const { inputs } = payload.data;
            state.inputs = inputs;
            state.variacaoPrecoTabela = ((inputs.new_price / inputs.base_price) * 100) - 100;
        },
    },
});

const {
    setInputCalculosValue,
    clearInputCalculos,
    resetInputCalculos,
    setInputValuesFromRequest,
    setInputTaxesValue,
    setInputCalculosState,
    setInputFromEditScenario,
} = slice.actions;

export {
    clearInputCalculos, setInputFromEditScenario,
    resetInputCalculos, setInputCalculosState, setInputCalculosValue, setInputTaxesValue, setInputValuesFromRequest,
};

export default slice.reducer;
