/* eslint-disable react/jsx-indent */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'rsuite';
import { SET_GET_FAMILY_MODAL_IS_OPEN } from '../../../../../reducers/gerenciadorPrecos';
import { RowData } from '../../../../../reducers/gerenciadorPrecos/datapoints';
import { CLEAR_EDITED_DATAPOINTS } from '../../../../../reducers/getFamilyModal/editedDatapoints';
import { selectorGetFamilyModalTotalElements } from '../../../../../reducers/getFamilyModal/totalElements';
import styles from './GetFamilyListModal.module.scss';
import { GetFamilyTable } from './components/GetFamilyTable';

type GetFamilyListModalProps = {
    rowData: RowData;
    show: boolean;
    onHide: () => void;
};

export const GetFamilyListModal = ({
    rowData,
    show,
    onHide,
}: GetFamilyListModalProps) => {
    const { productFamilyId, storeName, objectiveMargin, storeId } = rowData;

    const dispatch = useDispatch();

    const closeGetFamilyModal = () => {
        onHide();
        dispatch(SET_GET_FAMILY_MODAL_IS_OPEN(false));
        dispatch(CLEAR_EDITED_DATAPOINTS());
    };

    const totalElements = useSelector(selectorGetFamilyModalTotalElements);

    return (
        <Modal show={show} onHide={closeGetFamilyModal} className={styles['family-modal']}>
            <Modal.Header>
                <Modal.Title className={styles['modal-title']}>
                    {`Família ${productFamilyId} na ${storeName}`}
                </Modal.Title>
                <div className={styles['header-container']}>
                    <div className={styles['header-container__content']}>
                        <p>FAMILIA DE PRODUTO</p>
                        <span>{productFamilyId}</span>
                    </div>
                    <div className={styles['header-container__content']}>
                        <p>TOTAL DE PRODUTOS</p>
                        <span>{totalElements}</span>
                    </div>

                    <div className={styles['header-container__content']}>
                        <p>MARGEM OBJETIVA</p>
                        {objectiveMargin ? <span>{objectiveMargin}%</span> : <span>--</span>}
                    </div>
                    <div
                        className={`${styles['header-container__content']} ${styles['header-container__content--large']}`}
                    >
                        <p>LOJA</p>
                        <span>{`${storeId} - ${storeName}`}</span>
                    </div>
                </div>
            </Modal.Header>
            <Modal.Body>
                <GetFamilyTable onHide={onHide} productFamilyId={productFamilyId as string} storeId={storeId as string} />
            </Modal.Body>
        </Modal>
    );
};
