import { useContext, useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { format, parseISO } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import { getEvolucaoMargemPraticadaPorSemana } from '../services';
// @ts-ignore
import { FiltrosContextMargem } from '../Pages/MargemPraticadaGeral/index.tsx';

export const useEvolucaoMargemPraticadaPorSemana = () => {
    const filtros = useContext(FiltrosContextMargem);

    const [
        graficoEvolucaoMargemPraticadaPorSemana,
        setGraficoEvolucaoMargemPraticadaPorSemana,
    ] = useState({});

    const listEvolucaoMargemPraticadaPorSemana = async () => {
        const params = {
            filters: [
                {
                    filter: 'STOREID',
                    values: filtros?.lojasSelecionadas || null,
                },
                {
                    filter: 'PRODUCTID',
                    values: filtros?.produtosSelecionados || null,
                },
                {
                    filter: 'STARTDATE',
                    values: filtros?.periodoSelecionado?.length
                        ? [format(filtros.periodoSelecionado[0], 'yyyy-MM-dd')]
                        : null,
                },
                {
                    filter: 'ENDDATE',
                    values: filtros?.periodoSelecionado?.length
                        ? [format(filtros.periodoSelecionado[1], 'yyyy-MM-dd')]
                        : null,
                },
                {
                    filter: 'STORETYPE',
                    values: filtros?.tipoLojasSelecionadas || null,
                },
                {
                    filter: 'CATEGORYLEVEL1',
                    values: filtros?.categoriaNivel1Selecionadas || null,
                },
                {
                    filter: 'CATEGORYLEVEL2',
                    values: filtros?.categoriaNivel2Selecionadas || null,
                },
            ],
        };
        const resp = await getEvolucaoMargemPraticadaPorSemana(params);
        if (resp.length) {
            const dateWeek = Object.keys(resp[0])[0]?.split('.');
            const realizedMargin = Object.keys(resp[0])[1]?.split('.');
            return {
                [dateWeek[1]]: resp.map((item: { [x: string]: any }) =>
                    format(parseISO(item[Object.keys(item)[0]]), 'dd MMM. yyyy', {
                        locale: ptBR,
                    })
                ),
                [realizedMargin[1]]: resp.map((item: { [x: string]: any }) =>
                    parseFloat(
                        // @ts-ignore
                        Number(
                            parseFloat(item[Object.keys(item)[1]]) * 100
                        ).toFixed(2)
                    )
                ),
            };
        }
    };

    const { isLoading, isError, data, error } = useQuery(
        ['listaEvolucaoMargemPraticadaPorSemana', filtros],
        listEvolucaoMargemPraticadaPorSemana,
        {
            retry: false,
            cacheTime: 6 * 180 * 1000,
            staleTime: 6 * 180 * 1000,
        }
    );

    useEffect(() => {
        const realizedMargin = data?.realizedMargin;

        const dateWeek = data?.dateWeek;

        setGraficoEvolucaoMargemPraticadaPorSemana({
            exporting: {
                enabled: false,
            },
            chart: {
                backgroundColor: 'transparent',
                height: 369,
            },
            title: {
                text: '',
            },
            xAxis: [
                {
                    categories: dateWeek,
                    crosshair: true,
                },
            ],
            yAxis: [
                {
                    // Primary yAxis
                    labels: {
                        format: '{value}%',
                    },
                    title: {
                        text: 'Margem',
                    },
                },
            ],
            tooltip: {
                shared: true,
            },
            series: [
                {
                    name: 'Margem',
                    data: realizedMargin,
                    tooltip: {
                        valueSuffix: '%',
                    },
                    color: '#0D66D0',
                },
            ],
        });
    }, [data]);

    return {
        isLoading,
        isError,
        data,
        grafico: graficoEvolucaoMargemPraticadaPorSemana,
        error,
    };
};
