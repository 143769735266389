import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { getRequestPending } from '../../services';
import { setOnDemandState } from '../../../../../../reducers/reducerOnDemand';

export const useRequestPending = () => {
    const reduceDispatch = useDispatch();

    const handleRequest = async () => {
        const res = await getRequestPending();
        if (res?.length === 0) {
            reduceDispatch(
                setOnDemandState({
                    name: 'hasPendingRequest',
                    data: false,
                }),
            );
        }

        return res;
    };

    const {
        data, isLoading, isError, isSuccess, refetch,
    } = useQuery(
        ['request-pending'],
        handleRequest,
        {
            retry: false,
        },
    );

    const pendingData = useMemo(() => data, [data]);

    return {
        pendingData, isLoading, isSuccess, isError, refetch,
    };
};
