import { useState } from 'react';
import { TableProps } from 'rsuite/lib/Table';

type State<K extends TableProps['sortColumn'] = string> = {
    type: K;
    orderBy: TableProps['sortType'];
};

export const useSort = <K extends string = string>(initialState: State<K>) => {
    const [state, setState] = useState<State>(() => initialState);

    const handleSortColumn = (
        sortColumn: K,
        sortType: TableProps['sortType'],
    ) => {
        setState({
            orderBy: sortType,
            type: sortColumn,
        });
    };

    const model = `${state.type},${state.orderBy}`;

    return [state, { onSort: handleSortColumn }, model] as const;
};
