import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GerenciadorPrecos } from '../../../../@types/GerenciadorPrecos';
import { Sort } from '../../../../@types/sort';
import {
    initialState,
    selectorSort,
    SET_GERENCIADOR_SORT,
} from '../../../../reducers/gerenciadorPrecos/sort';

const useGerenciadorSort = () => {
    const sort = useSelector(selectorSort);

    const dispatch = useDispatch();

    const onSortColumn = useCallback(
        (dataKey: GerenciadorPrecos.DataKey, sortType: Sort['orderBy']) => {
            dispatch(
                SET_GERENCIADOR_SORT({
                    type: dataKey === 'retailPrice' ? 'priceRetail' : dataKey,
                    orderBy: sortType,
                }),
            );
        },
        [dispatch],
    );

    return {
        sort,
        onSortColumn,
        initialState,
    } as const;
};

export default useGerenciadorSort;
