import { useContext, useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { format, parseISO } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import { getUnidadesVendidasFaturamento } from '../services';
// @ts-ignore
import { FiltrosContextVendas } from '../Pages/VendasGerais/index.tsx';

export const useUnidadesVendidasFaturamento = () => {
    const filtros = useContext(FiltrosContextVendas);

    const [grafico, setGrafico] = useState({});

    const listUnidadesVendidasFaturamento = async () => {
        const params = {
            filters: [
                {
                    filter: 'STOREID',
                    values: filtros?.lojasSelecionadas || null,
                },
                {
                    filter: 'PRODUCTID',
                    values: filtros?.produtosSelecionados || null,
                },
                {
                    filter: 'STARTDATE',
                    values: filtros?.periodoSelecionado?.length
                        ? [format(filtros.periodoSelecionado[0], 'yyyy-MM-dd')]
                        : null,
                },
                {
                    filter: 'ENDDATE',
                    values: filtros?.periodoSelecionado?.length
                        ? [format(filtros.periodoSelecionado[1], 'yyyy-MM-dd')]
                        : null,
                },
                {
                    filter: 'STORETYPE',
                    values: filtros?.tipoLojasSelecionadas || null,
                },
                {
                    filter: 'CATEGORYLEVEL1',
                    values: filtros?.categoriaNivel1Selecionadas || null,
                },
                {
                    filter: 'CATEGORYLEVEL2',
                    values: filtros?.categoriaNivel2Selecionadas || null,
                },
            ],
        };
        const resp = await getUnidadesVendidasFaturamento(params);
        if (resp.length) {
            const dateWeek = Object.keys(resp[0])[0]?.split('.');
            const sumSalesGross = Object.keys(resp[0])[2]?.split('.');
            const sumSalesAmount = Object.keys(resp[0])[3]?.split('.');
            return {
                [dateWeek[2]]: resp.map((item: { [x: string]: any }) =>
                    format(parseISO(item[Object.keys(item)[0]]), 'dd MMM. yyyy', {
                        locale: ptBR,
                    })
                ),
                [sumSalesGross[1]]: resp.map((item: { [x: string]: any }) =>
                    parseFloat(
                        // @ts-ignore
                        Number(parseFloat(item[Object.keys(item)[2]])).toFixed(
                            2
                        )
                    )
                ),
                [sumSalesAmount[1]]: resp.map((item: { [x: string]: any }) =>
                    parseFloat(
                        // @ts-ignore
                        Number(parseFloat(item[Object.keys(item)[3]])).toFixed(
                            2
                        )
                    )
                ),
            };
        }
    };

    const { isLoading, isError, data, error } = useQuery(
        ['listUnidadesVendidasFaturamento', filtros],
        listUnidadesVendidasFaturamento,
        {
            retry: false,
            cacheTime: 6 * 180 * 1000,
            staleTime: 6 * 180 * 1000,
        }
    );

    useEffect(() => {
        const sumSalesGross = data?.sumSalesGross;

        const sumSalesAmount = data?.sumSalesAmount;

        const dateWeek = data?.week;

        setGrafico({
            exporting: {
                enabled: false,
            },
            chart: {
                backgroundColor: 'transparent',
                height: 369,
            },
            title: {
                text: '',
            },
            xAxis: [
                {
                    categories: dateWeek,
                    crosshair: true,
                },
            ],
            yAxis: [
                {
                    // Primary yAxis
                    labels: {
                        formatter: function () {
                            return this.value.toLocaleString('pt-BR');
                        },
                    },
                    title: {
                        text: 'Unidades vendidas',
                    },
                },
                {
                    // Secondary yAxis
                    title: {
                        text: 'Faturamento',
                    },
                    labels: {
                        formatter: function () {
                            return this.value.toLocaleString('pt-BR', {
                                style: 'currency',
                                currency: 'BRL',
                            });
                        },
                    },
                    opposite: true,
                },
            ],
            tooltip: {
                shared: true,
            },
            series: [
                {
                    name: 'Faturamento',
                    type: 'column',
                    yAxis: 1,
                    data: sumSalesAmount,
                    tooltip: {
                        valuePrefix: 'R$ ',
                    },
                    color: '#959595',
                },
                {
                    name: 'Unidades vendidas',
                    type: 'spline',
                    data: sumSalesGross,
                    tooltip: {
                        formatter: function () {
                            return this.value.toLocaleString('pt-BR');
                        },
                    },
                    color: '#0D66D0',
                },
            ],
        });
    }, [data]);

    return {
        isLoading,
        isError,
        data,
        grafico,
        error,
    };
};
