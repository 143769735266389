import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    Container, Content, Col, Grid, Row,
} from 'rsuite';
import { withRouter } from 'react-router-dom';

import CreateAccountEmailForm from '../Components/CreateAccountEmailForm';
import CreateAccountUserDataForm from '../Components/CreateAccountUserDataForm';
import { HomeCarouselComponent } from '../../../../../components/HomeCarouselComponent';
import { LoadingSpiner } from '../../../../../components/LoadingSpinner';

const FreemiumCadastro = ({ history }) => (
    <div className="create-account-freemium">
        <LoadingSpiner size="md" />
        <Container>
            <Content>
                <Grid>
                    <Row>
                        <Col md={10} lg={10} xsHidden smHidden className="banner-area">
                            <HomeCarouselComponent />
                        </Col>
                        <Col md={14} lg={14} xs={24} className="form-area">
                            <div className="account-form">
                                <h4>Cadastro</h4>
                                {history.location.pathname === '/free/email'
                                    ? <CreateAccountEmailForm /> : history.location.pathname === '/free/usuario'
                                        ? <CreateAccountUserDataForm /> : <div />}
                            </div>
                        </Col>
                    </Row>
                </Grid>

            </Content>
        </Container>
    </div>
);

const mapStateToProps = (store) => ({
    stage: store.accountFreemiumDataReducer.stage,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FreemiumCadastro));
