import React from 'react';
import { Button, Table, Icon } from 'rsuite';
import { handleDateFormat, downloadFile } from '../utils';
import threeDotsCircleIcon from '../../../../../assets/icons/icon_circle_three_dots.svg';
import checkCircleIcon from '../../../../../assets/icons/icon_check_circle_blue.svg';

const { Column, HeaderCell, Cell } = Table;
const { Pagination } = Table;

const StatusElement = ({ status }) => (
    <div className="status-element-wrapper">
        <img src={status ? threeDotsCircleIcon : checkCircleIcon} alt="" />
        <p>{status ? 'Processando' : 'Disponível'}</p>
    </div>
);

const HistoryReportTable = ({ data, setPage, loading }) => {
    const handleChangePage = (e) => {
        setPage(e - 1);
    };

    return (
        <>
            <Table
                className="history-report-table"
                autoHeight
                data={data?.content}
                bordered
                loading={loading}
            >
                <Column>
                    <HeaderCell>Gerado em</HeaderCell>
                    <Cell>
                        {(rowData) => (
                            <span>
                                {rowData.dataFimGeracao
                                    ? handleDateFormat(rowData.dataFimGeracao)
                                    : '-'}
                            </span>
                        )}
                    </Cell>
                </Column>
                <Column flexGrow={2}>
                    <HeaderCell>Nome</HeaderCell>
                    <Cell dataKey="fileName" />
                </Column>
                <Column width={130}>
                    <HeaderCell>Status</HeaderCell>
                    <Cell>
                        {(rowData) => (
                            <StatusElement
                                status={rowData.dataFimGeracao === null}
                            />
                        )}
                    </Cell>
                </Column>
                <Column align="right">
                    <HeaderCell />
                    <Cell className="download-cell">
                        {(rowData) => (
                            <Button
                                disabled={rowData.dataFimGeracao === null}
                                onClick={() =>
                                    downloadFile(
                                        rowData.linkBucket,
                                        rowData.fileName
                                    )
                                }
                            >
                                <Icon icon="download" />
                            </Button>
                        )}
                    </Cell>
                </Column>
            </Table>
            {data ? (
                <Pagination
                    className="history-report-pagination"
                    activePage={data.number + 1}
                    displayLength={data?.size}
                    showLengthMenu={false}
                    total={data?.totalElements}
                    onChangePage={handleChangePage}
                    renderTotal={() =>
                        `Exibindo ${data?.content?.length} de ${data?.totalElements} solicitações.`
                    }
                />
            ) : null}
        </>
    );
};

export default React.memo(HistoryReportTable);
