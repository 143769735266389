import { zodResolver } from '@hookform/resolvers/zod';
import classNames from 'classnames';
import React, { useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import Modal, { ModalProps } from 'rsuite/lib/Modal';
import { z } from 'zod';
import { ButtonPrimary, Callout, InputBox } from '../../../../../components';
import { LoadingSpinerArea } from '../../../../../components/LoadingSpinner';
import { selectorSelectedDatapoints } from '../../../../../reducers/gerenciadorPrecos/selectedDatapoints';
import { selectorTotalElements } from '../../../../../reducers/gerenciadorPrecos/totalElements';
import { getTotalProducts } from '../../utils';
import { Icon } from '../QuickActionFilter';
import styles from './ModalAlterarPrecos.module.scss';

const alterarPrecosSchema = z.object({
    editType: z.enum(['PRICE', 'MARGIN', 'COMPETITIVENESS'], {
        invalid_type_error: 'Selecione uma opção válida.',
        required_error: 'Por favor, selecione uma opção.',
    }),
    comportamentTypeEdit: z.enum(['VALUE', 'INCREASE', 'LOWER'], {
        invalid_type_error: 'Selecione uma opção válida.',
        required_error: 'Por favor, selecione uma opção.',
    }),
    editedValue: z.number({
        required_error: 'Por favor, preencha este campo.',
    }),
    roundPrice: z.boolean(),
});

const SUFFIX = {
    PRICE: {
        VALUE: undefined,
        INCREASE: '%',
        LOWER: '%',
    },
    MARGIN: {
        VALUE: '%',
        INCREASE: 'pp',
        LOWER: 'pp',
    },
    COMPETITIVENESS: {
        VALUE: '%',
        INCREASE: 'pp',
        LOWER: 'pp',
    },
} as const;

export type AlterarPrecosSchema = z.infer<typeof alterarPrecosSchema>;

export type ModalAlterarPrecosProps = {
    onSubmit: (formData: AlterarPrecosSchema) => void;
} & ModalProps;

export const ModalAlterarPrecos = ({
    className,
    onSubmit,
    ...props
}: ModalAlterarPrecosProps) => {
    const {
        control,
        handleSubmit,
        watch,
        formState: { isDirty, isValid },
    } = useForm<AlterarPrecosSchema>({
        defaultValues: {
            editType: 'PRICE',
            comportamentTypeEdit: 'VALUE',
            roundPrice: false,
        },
        resolver: zodResolver(alterarPrecosSchema),
    });

    const shouldHavePrefix =
        watch('editType') === 'PRICE' &&
        watch('comportamentTypeEdit') === 'VALUE';

    const totalElements = useSelector(selectorTotalElements);

    const selectedDatapoints = useSelector(selectorSelectedDatapoints);

    const total = useMemo(
        () => getTotalProducts(totalElements, selectedDatapoints),
        [selectedDatapoints, totalElements],
    );
    return (
        <Modal
            className={classNames(
                'info-modal',
                styles['modal-alterar-precos'],
                className,
            )}
            {...props}
        >
            <form onSubmit={handleSubmit(onSubmit)}>
                <Modal.Header>
                    <Modal.Title>Alterar o preço</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <InputBox.Label>
                        Escolha como alterar os
                        <strong>
                            {` ${total} `}
                            preços selecionados.
                        </strong>
                    </InputBox.Label>
                    <div className={styles['input-wrapper']}>
                        <Controller
                            name="editType"
                            control={control}
                            render={({
                                field: { value, onChange },
                                fieldState: { error },
                            }) => (
                                <InputBox
                                    className={
                                        styles['input-wrapper__edit-type']
                                    }
                                >
                                    <InputBox.Select
                                        data={[
                                            {
                                                value: 'PRICE',
                                                label: 'Preço',
                                            },
                                            {
                                                value: 'MARGIN',
                                                label: 'Margem',
                                            },
                                            {
                                                value: 'COMPETITIVENESS',
                                                label: 'Competitividade',
                                            },
                                        ]}
                                        value={value}
                                        onChange={onChange}
                                        searchable={false}
                                        cleanable={false}
                                    />
                                    <InputBox.Error message={error?.message} />
                                </InputBox>
                            )}
                        />

                        <Icon
                            icon="MdArrowForward"
                            className={styles['arrow-svg']}
                        />

                        <Controller
                            name="comportamentTypeEdit"
                            control={control}
                            render={({
                                field: { value, onChange },
                                fieldState: { error },
                            }) => (
                                <InputBox
                                    className={
                                        styles[
                                            'input-wrapper__comportament-type'
                                        ]
                                    }
                                >
                                    <InputBox.Select
                                        data={[
                                            {
                                                value: 'VALUE',
                                                label: 'Valor',
                                            },
                                            {
                                                value: 'INCREASE',
                                                label: 'Aumentar',
                                            },
                                            {
                                                value: 'LOWER',
                                                label: 'Baixar',
                                            },
                                        ]}
                                        value={value}
                                        onChange={onChange}
                                        searchable={false}
                                        cleanable={false}
                                    />
                                    <InputBox.Error message={error?.message} />
                                </InputBox>
                            )}
                        />

                        <Controller
                            name="editedValue"
                            control={control}
                            render={({
                                field: { value, onChange },
                                fieldState: { error },
                            }) => (
                                <InputBox
                                    className={
                                        styles['input-wrapper__edited-value']
                                    }
                                >
                                    <InputBox.Numeric
                                        value={value}
                                        allowNegative={false}
                                        allowEmpty={false}
                                        precision="2"
                                        decimalSeparator=","
                                        onChangeEvent={onChange}
                                        prefix={
                                            shouldHavePrefix ? 'R$' : undefined
                                        }
                                        suffix={
                                            SUFFIX[watch('editType')][
                                                watch('comportamentTypeEdit')
                                            ]
                                        }
                                    />
                                    <InputBox.Error message={error?.message} />
                                </InputBox>
                            )}
                        />
                    </div>

                    <Controller
                        name="roundPrice"
                        control={control}
                        render={({ field: { value, onChange } }) => (
                            <InputBox>
                                <InputBox.Check
                                    value={value}
                                    onChange={onChange}
                                >
                                    Arredondar segundo preço psicológico
                                </InputBox.Check>
                            </InputBox>
                        )}
                    />

                    <Callout className={styles.callout}>
                        <Callout.Title>Atenção!</Callout.Title>
                        <Callout.Description>
                            Todos os preços selecionados serão alterados.
                        </Callout.Description>
                    </Callout>
                </Modal.Body>
                <Modal.Footer>
                    <ButtonPrimary
                        skin="blue"
                        theme="ghost"
                        type="reset"
                        onClick={props.onHide}
                    >
                        Fechar
                    </ButtonPrimary>
                    <ButtonPrimary
                        skin="blue"
                        theme="filled"
                        type="submit"
                        disabled={!isDirty || !isValid}
                    >
                        alterar preços
                    </ButtonPrimary>
                </Modal.Footer>
            </form>
            <LoadingSpinerArea
                height="100%"
                size="md"
                area="ipa/gerenciador/alterar-precos"
            />
        </Modal>
    );
};
