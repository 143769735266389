import classNames from 'classnames';
import React from 'react';
import { GerenciadorPrecos } from '../../../../../../@types/GerenciadorPrecos';
import styles from './BaseCell.module.scss';

type RowData = GerenciadorPrecos.RowData;
type Inputs = GerenciadorPrecos.Inputs;

export type BaseCellProps = React.HtmlHTMLAttributes<HTMLDivElement> & {
    handleContextMenu?: (rowData: RowData) => void;
    rowData: RowData;
};

export type BaseCellWithUpdateCampoProps = BaseCellProps & {
    dataKey: Inputs;
    index: number;
    handleUpdateCampo: (
        dataKey: Inputs,
        value: number,
        rowData: RowData,
        index: number,
    ) => void;
    handleBlur: (rowData: RowData, index: number) => void;
};

const BaseCell = ({
    className,
    rowData,
    index,
    handleContextMenu,
    ...props
}: BaseCellProps) => {
    return (
        <div
            className={classNames(styles.container, className)}
            onContextMenu={(e) => {
                props.onContextMenu?.(e);
                handleContextMenu?.(rowData);
            }}
            aria-hidden
            {...props}
        />
    );
};

const MainContainer = ({
    className,
    ...props
}: React.HtmlHTMLAttributes<HTMLDivElement>) => {
    return (
        <div
            className={classNames(styles.container__main, className)}
            {...props}
        />
    );
};

const SubContainer = ({
    className,
    ...props
}: React.HtmlHTMLAttributes<HTMLDivElement>) => {
    return (
        <div
            className={classNames(styles.container__sub, className)}
            {...props}
        />
    );
};

BaseCell.MainContainer = MainContainer;
BaseCell.SubContainer = SubContainer;

export default BaseCell;
