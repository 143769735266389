import React from 'react';
// @ts-ignore
import { Card } from '../../Components/index.tsx';
// @ts-ignore
import { useTotalVendasEmReais } from '../../../hooks/useTotalVendasEmReais.tsx';

export const TotalVendaEmReais: React.FC = () => {
    const { data, isLoading } = useTotalVendasEmReais();

    return (
        <Card
            data={data?.sumSalesGross}
            isLoading={isLoading}
            title="Total de vendas em reais"
            fullHeight
        />
    );
};
