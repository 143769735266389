import { trackPromise } from 'react-promise-tracker';
import api from '../../../utils/API';

export const saveProductConfig = (type, data) =>
    trackPromise(
        api({
            url: `${process.env.REACT_APP_CLIENTE_URL}/painel_geral/config_complemento_retorno`,
            method: type,
            expectToken: true,
            token: localStorage.getItem('autenticacao.token'),
            data: JSON.stringify(data),
        }).then((resp) => resp)
    );

export const getProductConfig = (cliente) =>
    trackPromise(
        api({
            url: `${process.env.REACT_APP_CLIENTE_URL}/painel_geral/config_complemento_retorno/${cliente}`,
            method: 'GET',
            expectToken: true,
            token: localStorage.getItem('autenticacao.token'),
        }).then((resp) => resp)
    );
