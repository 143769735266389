import React from 'react';
import { formatCurrencyToPtBr } from '../../../../../../../utils/currency';
import { round } from '../../../../../../../utils/math';
import { SuppliersResponseContentProps } from '../../NegociacaoFornecedor.types';
import { bestIdealPriceCalc, bestIdealPricePMZCalc } from '../../utils';
import styles from './BestBuyPriceIndicator.module.scss';

export interface IBestBuyPriceIndicator {
    data?: SuppliersResponseContentProps;
}

export const BestBuyPriceIndicator = ({ data }: IBestBuyPriceIndicator) => {
    const couldCalculateBestPrice = (data?.competitorsPrice !== null || data?.retailPrice !== null) && data?.newObjectiveMargin !== null;

    const bestPrice = couldCalculateBestPrice ? round(bestIdealPriceCalc(data, data?.newObjectiveMargin), 2) : null;
    const bestPricePMZ = bestPrice ? round(bestIdealPricePMZCalc(data, bestPrice), 2) : null;

    const costReference = data?.newCost !== null ? round(data?.newCost, 2) : round(data?.cost, 2);
    const statusStyle = bestPrice && costReference ? (
        costReference <= bestPrice
        ? 'positive'
        : 'negative'
    ) : 'default';

    return (
        <div className={styles['preco-ideal-cell-wrapper']}>
            <p className={styles[`text-status-${statusStyle}`]}>{bestPrice ? formatCurrencyToPtBr(bestPrice) : 'R$ --'}</p>
            <p className={styles[`subtext-status-${statusStyle}`]}>
                PMZ
                {' '}
                {bestPricePMZ ? formatCurrencyToPtBr(bestPricePMZ) : 'R$ --'}
            </p>
        </div>
    );
};
