import classNames from 'classnames';
import React, { useState } from 'react';
import CurrencyInput from 'react-currency-input';

type ValueProps = number | null;

export type InfoCurrencyInputProps = {
    className?: string;
    name?: string;
    value: ValueProps;
    decimalSeparator?: string;
    thousandSeparator?: string;
    precision?: string;
    allowEmpty?: boolean;
    allowNegative?: boolean;
    maxLength?: number;
    onChangeEvent?: (
        event: React.ChangeEvent<HTMLInputElement>,
        value: string,
        parsedValue: number,
    ) => void;
    onFocus?: (event: React.FocusEvent<HTMLInputElement, Element>) => void;
    onBlur?: (
        event: React.FocusEvent<HTMLInputElement, Element>,
        value: ValueProps,
        details: {
            hasChanges: boolean;
            onFocusValue: ValueProps;
            onBlurValue: ValueProps;
        },
    ) => void;
};

export const InfoCurrencyInput = ({
    className,
    value,
    onFocus,
    onBlur,
    ...props
}: InfoCurrencyInputProps) => {
    const [prevState, setPrevState] = useState<ValueProps>(0);

    const handleFocus = (
        event: React.FocusEvent<HTMLInputElement, Element>,
    ) => {
        setPrevState(value);
        onFocus?.(event);
    };

    const handleBlur = (event: React.FocusEvent<HTMLInputElement, Element>) => {
        onBlur?.(event, value, {
            onBlurValue: value,
            onFocusValue: prevState,
            hasChanges: !(value === prevState),
        });
    };

    return (
        <CurrencyInput
            className={classNames('rs-input', className)}
            value={value as number}
            onFocus={(e) => {
                e.target.select();
                handleFocus?.(e);
            }}
            onBlur={handleBlur}
            {...props}
        />
    );
};
