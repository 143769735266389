import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { getAvailableUFs } from '../../services';

export const useAvailableUFs = () => {
    const handleRequest = async () => {
        const res = await getAvailableUFs();
        return res;
    };

    const {
        data, isLoading, isError, isSuccess, refetch,
    } = useQuery(
        ['available-ufs'],
        handleRequest,
        {
            retry: false,
        },
    );

    const ufData = useMemo(() => data, [data]);

    return {
        ufData, isLoading, isSuccess, isError, refetch,
    };
};
