import React from 'react';
import { Button } from 'rsuite';

const IconButton = ({
    icon,
    label,
    background,
    onClick,
    size,
    appearance,
    color,
    disabled,
}) => (
    <Button
        id="icon-button"
        className={`icon-button btn-${size}`}
        size={size}
        style={{ background }}
        appearance={appearance}
        color={color}
        onClick={onClick}
        disabled={disabled}
    >
        <img className={`icon-main icon-${size}`} src={icon} alt="" />
        <div className={`icon-background bg-${size}`} />
        {label}
    </Button>
);

export default IconButton;
