import React, { forwardRef } from 'react';
import { Icon } from '../../../../RevisaoPrecos/Components/QuickActionFilter';
import './style.scss';

export type PremiumChipProps = React.ButtonHTMLAttributes<HTMLButtonElement>;

export const PremiumChip = forwardRef(
    (
        { className = '', ...props }: PremiumChipProps,
        ref: React.LegacyRef<HTMLButtonElement>,
    ) => (
        <button
            ref={ref}
            className={['premium-chip', className].join(' ')}
            {...props}
        >
            <Icon icon="premium" />
            <p>Conheça a versão PRO</p>
        </button>
    ),
);
