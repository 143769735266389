import { useContext } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getMargemPraticadaPorLoja } from '../services';
// @ts-ignore
import { FiltrosContextMargem } from '../Pages/MargemPraticadaGeral/index.tsx';
import { format } from 'date-fns';

export const useMargemPraticadaPorLoja = () => {
    const filtros = useContext(FiltrosContextMargem);

    const listarMargemPraticadaPorLoja = async () => {
        const params = {
            filters: [
                {
                    filter: 'STOREID',
                    values: filtros?.lojasSelecionadas || null,
                },
                {
                    filter: 'PRODUCTID',
                    values: filtros?.produtosSelecionados || null,
                },
                {
                    filter: 'STARTDATE',
                    values: filtros?.periodoSelecionado?.length
                        ? [format(filtros.periodoSelecionado[0], 'yyyy-MM-dd')]
                        : null,
                },
                {
                    filter: 'ENDDATE',
                    values: filtros?.periodoSelecionado?.length
                        ? [format(filtros.periodoSelecionado[1], 'yyyy-MM-dd')]
                        : null,
                },
                {
                    filter: 'STORETYPE',
                    values: filtros?.tipoLojasSelecionadas || null,
                },
                {
                    filter: 'CATEGORYLEVEL1',
                    values: filtros?.categoriaNivel1Selecionadas || null,
                },
                {
                    filter: 'CATEGORYLEVEL2',
                    values: filtros?.categoriaNivel2Selecionadas || null,
                },
            ],
        };

        const resp = await getMargemPraticadaPorLoja(params);
        if (resp.length) {
            const storeName = Object.keys(resp[0])[0]?.split('.');
            const realizedMargin = Object.keys(resp[0])[1]?.split('.');
            const marginGain = Object.keys(resp[0])[2]?.split('.');
            return resp.map((item: { [x: string]: any }) => ({
                [storeName[1]]: item[Object.keys(item)[0]],
                [realizedMargin[1]]: parseFloat(
                    // @ts-ignore
                    parseFloat(item[Object.keys(item)[1]]) * 100
                )
                    .toFixed(2)
                    .replace('.', ','),
                [marginGain[1]]: parseFloat(item[Object.keys(item)[2]])?.toLocaleString(
                    'pt-BR',
                    {
                        style: 'currency',
                        currency: 'BRL',
                    }
                ),
            }));
        }
        return [];
    };

    const { isLoading, isError, data, error } = useQuery(
        ['listaMargemPraticadaPorLoja', filtros],
        listarMargemPraticadaPorLoja,
        {
            retry: false,
            cacheTime: 6 * 180 * 1000,
            staleTime: 6 * 180 * 1000,
        }
    );

    return {
        isLoading,
        isError,
        data,
        error,
    };
};
