import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RESET_DATAPOINTS_CACHE } from '../../../../../reducers/gerenciadorPrecos/datapointsCache';
import { selectorFiltersValues } from '../../../../../reducers/gerenciadorPrecos/filters/filtersValues';
import { OPEN_MODAL } from '../../../../../reducers/gerenciadorPrecos/modalsBoolean';
import { selectorQuickActionFilters } from '../../../../../reducers/gerenciadorPrecos/quickActionsFilter';
import {
    RESET_SELECTED_DATAPOINTS,
    selectorSelectedDatapoints,
    SET_SELECTED_ALL,
} from '../../../../../reducers/gerenciadorPrecos/selectedDatapoints';
import { selectorTotalElements } from '../../../../../reducers/gerenciadorPrecos/totalElements';
import {
    isSelectedDatapointsEmpty as fnIsEmptySelectedDatapoints,
    GET_MODEL_GERENCIADOR_FILTROS,
    GET_MODEL_GERENCIADOR_QUICK_ACTION_FILTERS,
    getTotalProducts,
    isEmptyFilters,
} from '../../utils';

const useGerenciadorTableHeading = () => {
    const dispatch = useDispatch();

    const totalElements = useSelector(selectorTotalElements);

    const filters = useSelector(selectorFiltersValues);

    const quickActionsFilter = useSelector(selectorQuickActionFilters);

    const selectedDatapoints = useSelector(selectorSelectedDatapoints);

    const { selectedAll } = selectedDatapoints;

    const handleOpenModal = useCallback(
        (name: string) => dispatch(OPEN_MODAL(name)),
        [dispatch],
    );

    const MODEL = useMemo(
        () => ({
            ...GET_MODEL_GERENCIADOR_FILTROS(filters),
            ...GET_MODEL_GERENCIADOR_QUICK_ACTION_FILTERS(quickActionsFilter),
        }),
        [filters, quickActionsFilter],
    );

    const IS_EMPTY_FILTERS = useMemo(() => isEmptyFilters(MODEL), [MODEL]);

    const handleSelectAllFromAllPages = useCallback(() => {
        dispatch(SET_SELECTED_ALL(true));
    }, [dispatch]);

    const handleUnselectAllFromAllPages = useCallback(() => {
        dispatch(RESET_SELECTED_DATAPOINTS());
        dispatch(RESET_DATAPOINTS_CACHE());
    }, [dispatch]);

    const isEmptySelectedDatapoints = useMemo(
        () => fnIsEmptySelectedDatapoints(selectedDatapoints),
        [selectedDatapoints],
    );

    const total = useMemo(
        () => getTotalProducts(totalElements, selectedDatapoints),
        [totalElements, selectedDatapoints],
    );

    return {
        total,
        isFiltersEmpty: IS_EMPTY_FILTERS,
        selectedAll,
        isEmptySelectedDatapoints,
        handleSelectAllFromAllPages,
        handleUnselectAllFromAllPages,
        handleOpenModal,
    };
};

export default useGerenciadorTableHeading;
