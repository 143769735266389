import React from 'react';

export const MdOpenInNew = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="12"
        height="12"
        viewBox="0 0 12 12"
        fill="none"
        {...props}
    >
        <path
            d="M9 9.5H3C2.725 9.5 2.5 9.275 2.5 9V3C2.5 2.725 2.725 2.5 3 2.5H5.5C5.775 2.5 6 2.275 6 2C6 1.725 5.775 1.5 5.5 1.5H2.5C1.945 1.5 1.5 1.95 1.5 2.5V9.5C1.5 10.05 1.95 10.5 2.5 10.5H9.5C10.05 10.5 10.5 10.05 10.5 9.5V6.5C10.5 6.225 10.275 6 10 6C9.725 6 9.5 6.225 9.5 6.5V9C9.5 9.275 9.275 9.5 9 9.5ZM7 2C7 2.275 7.225 2.5 7.5 2.5H8.795L4.23 7.065C4.035 7.26 4.035 7.575 4.23 7.77C4.425 7.965 4.74 7.965 4.935 7.77L9.5 3.205V4.5C9.5 4.775 9.725 5 10 5C10.275 5 10.5 4.775 10.5 4.5V2C10.5 1.725 10.275 1.5 10 1.5H7.5C7.225 1.5 7 1.725 7 2Z"
            fill="currentColor"
        />
    </svg>
);
