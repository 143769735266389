import classNames from 'classnames';
import React, { HtmlHTMLAttributes, useRef } from 'react';
import { Icon as RIcon, Toggle as RToggle } from 'rsuite';
import { ToggleProps } from 'rsuite/lib/Toggle';
import { Tag as TagComponent, TagProps } from '../../pages/IPA/Lite';
import {
    Icon as IconComponent,
    IconProps,
} from '../../pages/IPA/RevisaoPrecos/Components';
import styles from './CompetitorsSection.module.scss';

const SCROLL_DISTANCE = 400;

const Competitors = ({
    className,
    children,
    ...props
}: HtmlHTMLAttributes<HTMLDivElement>) => {
    const elementRef = useRef<HTMLDivElement | null>(null);

    const handleClick = (navigation: 'left' | 'right') => {
        if (!elementRef.current) return;
        const offsetWidth =
            navigation === 'left'
                ? elementRef.current.scrollLeft - SCROLL_DISTANCE
                : elementRef.current.scrollLeft + SCROLL_DISTANCE;
        elementRef.current.scrollTo(offsetWidth, 0);
    };

    return (
        <div
            className={classNames(styles['competitors-section'], className)}
            {...props}
        >
            <div
                className={styles['competitors-section__scrollable-container']}
                ref={elementRef}
            >
                {children}
            </div>
            <RIcon
                className={classNames(
                    styles['competitors-section__nav-icon'],
                    styles['competitors-section__nav-icon-left'],
                )}
                icon="angle-left"
                onClick={() => handleClick('left')}
            />
            <RIcon
                className={classNames(
                    styles['competitors-section__nav-icon'],
                    styles['competitors-section__nav-icon-right'],
                )}
                icon="angle-right"
                onClick={() => handleClick('right')}
            />
        </div>
    );
};

const Container = ({
    className,
    ...props
}: HtmlHTMLAttributes<HTMLDivElement>) => (
    <div
        className={classNames(
            styles['competitors-section__container'],
            className,
        )}
        {...props}
    />
);

const Section = ({
    className,
    ...props
}: HtmlHTMLAttributes<HTMLDivElement>) => (
    <div
        className={classNames(
            styles['competitors-section__section'],
            className,
        )}
        {...props}
    />
);

const Content = ({
    className,
    ...props
}: HtmlHTMLAttributes<HTMLDivElement>) => (
    <div
        className={classNames(
            styles['competitors-section__content'],
            className,
        )}
        {...props}
    />
);

const Header = ({
    className,
    ...props
}: HtmlHTMLAttributes<HTMLDivElement>) => (
    <div
        className={classNames(styles['competitors-section__header'], className)}
        {...props}
    />
);

const Card = ({ className, ...props }: HtmlHTMLAttributes<HTMLDivElement>) => (
    <div
        className={classNames(styles['competitors-section__card'], className)}
        {...props}
    />
);

const CardHeader = ({
    className,
    ...props
}: HtmlHTMLAttributes<HTMLDivElement>) => (
    <div
        className={classNames(
            styles['competitors-section__card__header'],
            className,
        )}
        {...props}
    />
);

const CardHeaderAction = ({
    className,
    ...props
}: HtmlHTMLAttributes<HTMLDivElement>) => (
    <div
        className={classNames(
            styles['competitors-section__card__header-action'],
            className,
        )}
        {...props}
    />
);

const Toggle = ({ className, ...props }: ToggleProps) => (
    <RToggle
        size="sm"
        className={classNames(
            styles['competitors-section__card__header-toggle'],
            'toggle-competitiviness',
            className,
        )}
        {...props}
    />
);

const PhotoIcon = (props: HtmlHTMLAttributes<HTMLDivElement>) => {
    return (
        <div
            className={classNames(
                styles['competitors-section__card__header__photo-icon'],
            )}
            {...props}
        >
            <IconComponent icon="MdImage" />
        </div>
    );
};

const CardContent = ({
    className,
    ...props
}: HtmlHTMLAttributes<HTMLElement>) => (
    <menu
        className={classNames(
            styles['competitors-section__card__content'],
            className,
        )}
        {...props}
    />
);

const InfoBox = ({
    children,
    className,
    isActive,
    ...props
}: HtmlHTMLAttributes<HTMLElement> & { isActive?: boolean }) => (
    <li
        className={classNames(
            styles['competitors-section__infobox'],
            isActive && styles['competitors-section__infobox-active'],
            className,
        )}
        {...props}
    >
        <button>{children}</button>
    </li>
);

const Separator = ({
    className,
    ...props
}: HtmlHTMLAttributes<HTMLDivElement>) => (
    <div
        className={classNames(
            styles['competitors-section__separator'],
            className,
        )}
        {...props}
    />
);

const Title = ({
    className,
    children,
    ...props
}: HtmlHTMLAttributes<HTMLHeadingElement>) => (
    <h5
        className={classNames(styles['competitors-section__title'], className)}
        {...props}
    >
        {children}
    </h5>
);

const Subtitle = ({
    className,
    children,
    ...props
}: HtmlHTMLAttributes<HTMLParagraphElement>) => (
    <p
        className={classNames(
            styles['competitors-section__subtitle'],
            className,
        )}
        {...props}
    >
        {children}
    </p>
);

const InfoboxLabel = ({
    className,
    children,
    ...props
}: HtmlHTMLAttributes<HTMLHeadingElement>) => (
    <h5
        className={classNames(
            styles['competitors-section__info-label'],
            className,
        )}
        {...props}
    >
        {children}
    </h5>
);

const InfoboxValue = ({
    className,
    children,
    ...props
}: HtmlHTMLAttributes<HTMLParagraphElement>) => (
    <p
        className={classNames(
            styles['competitors-section__info-value'],
            className,
        )}
        {...props}
    >
        {children}
    </p>
);

const Icon = ({ className, ...props }: IconProps) => (
    <IconComponent
        className={classNames(styles['competitors-section__icon'], className)}
        {...props}
    />
);

const Tag = ({ className, ...props }: TagProps) => (
    <TagComponent
        className={classNames(styles['competitors-section__tag'], className)}
        {...props}
    />
);

Competitors.Section = Section;
Competitors.Container = Container;
Competitors.Content = Content;
Competitors.Header = Header;
Competitors.InfoBox = InfoBox;
Competitors.Value = InfoboxValue;
Competitors.Label = InfoboxLabel;
Competitors.Card = Card;
Competitors.CardHeader = CardHeader;
Competitors.CardHeaderAction = CardHeaderAction;
Competitors.CardContent = CardContent;
Competitors.Separator = Separator;
Competitors.Title = Title;
Competitors.Subtitle = Subtitle;
Competitors.Icon = Icon;
Competitors.Tag = Tag;
Competitors.Toggle = Toggle;
Competitors.PhotoIcon = PhotoIcon;

export { Competitors };
