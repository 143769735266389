import React from 'react';
import { Button, Modal } from 'rsuite';

const ModalUnderstandBetter = ({ show, onCancel }) => (
    <Modal
        overflow={false}
        backdrop="static"
        show={show}
        className="modulo-relatorio-modal-understand-better"
        onHide={onCancel}
        size="md"
    >
        <Modal.Body>
            <div className="content">
                <h1>
                    Entenda melhor o comportamento das dimensões e suas colunas
                </h1>
                <ul>
                    <li>
                        No total são 4 dimensões e cada uma tem seus próprios
                        elementos;
                    </li>
                    <li>
                        Elementos são a representação das colunas que irão
                        compor o relatório, ou seja, cada elemento selecionando
                        será uma coluna;
                    </li>
                    <li>
                        Após selecionados os elementos, será possível filtrar
                        informações específicas de alguns.
                    </li>
                    <li>
                        É obrigatório a seleção de pelo menos 1 (um) elemento de
                        cada dimensão para liberar o botão “Gerar relatório”;
                    </li>
                </ul>
            </div>
        </Modal.Body>
        <Modal.Footer>
            <Button onClick={onCancel} appearance="primary">
                Fechar
            </Button>
        </Modal.Footer>
    </Modal>
);

export default ModalUnderstandBetter;
