import React, { useEffect } from 'react';
import { Container, Content } from 'rsuite';
import * as pbi from 'powerbi-client';
import {
    withRouter, useParams,
} from 'react-router-dom';

import * as AnaliseService from '../../../../services/AnaliseService';

const powerbi = new pbi.service.Service(pbi.factories.hpmFactory, pbi.factories.wpmpFactory, pbi.factories.routerFactory);

const Analise = () => {
    const { id } = useParams();

    const loadIFrame = (analise, token) => {
        const models = require('powerbi-models');

        const embedConfiguration = {
            type: 'report',
            id: analise.relatorio,
            embedUrl: `https://app.powerbi.com/reportEmbed?reportId=${analise.relatorio}&groupId=${analise.workspace}`,
            tokenType: models.TokenType.Embed,
            accessToken: token.token,
            settings: {
                filterPaneEnabled: false,
                navContentPaneEnabled: true,
            },
        };

        const reportContainer = document.getElementById('reportContainer');
        const report = powerbi.embed(reportContainer, embedConfiguration);
        console.log(report);
    };

    const getAnalise = (id) => {
        const idToNumber = Number(id);

        AnaliseService.getAnalise(idToNumber).then((data) => {
            if (data) {
                AnaliseService.getAnaliseToken(data.workspace, data.relatorio).then((token) => {
                    loadIFrame(data, token);
                });
            }
        });
    };

    useEffect(() => {
        getAnalise(id);
    }, []);

    return (
        <Container className="main-container" style={{ width: '100vh' }}>
            <Content>
                <div
                    id="reportContainer"
                    className="power-bi-frame well well-white width-100-percent"
                    style={{ width: '100%', height: '100vh', display: 'block' }}
                />
            </Content>
        </Container>
    );
};

export default withRouter(Analise);
