import React from 'react';
import {
    Icon,
} from 'rsuite';

export const TitleDropDownOptions = () => (
    <div className="title-dropdown-options-table-revisao">
        <div className="title-dropdown-options-table-revisao__icon">
            <Icon icon="sequence" />
        </div>
        {' '}
        <div className="title-dropdown-options-table-revisao__label">
            ORDENAR POR
        </div>
    </div>
);
