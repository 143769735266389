import {
    getFree, validateDataQuantity, getCidades, getUfs,
} from '../services/FreemiumService';

export const SET_FREE_PRODUCT = 'SET_FREE_PRODUCT';
export const UPDATE_FREE_DATA = 'UPDATE_FREE_DATA';
export const SET_ENOUGH_DATA_EDIT = 'SET_ENOUGH_DATA_EDIT';
export const SET_CITIES_UPDATE_FREE = 'SET_CITIES_UPDATE_FREE';
export const SET_STATES_UPDATE_FREE = 'SET_STATES_UPDATE_FREE';
export const SET_ENOUGH_DATA_EDIT_NULL = 'SET_ENOUGH_DATA_EDIT_NULL';

export function setFreeProduct(data) {
    return {
        type: SET_FREE_PRODUCT,
        data,
    };
}

export function updateFreeData(value, key) {
    return {
        type: UPDATE_FREE_DATA,
        value,
        key,
    };
}

export function setEnoughDataEdit(data) {
    return {
        type: SET_ENOUGH_DATA_EDIT,
        data,
    };
}

export function setEnoughDataEditNull() {
    return {
        type: SET_ENOUGH_DATA_EDIT_NULL,
    };
}

export function setCitiesUpdateFree(cities) {
    return {
        type: SET_CITIES_UPDATE_FREE,
        cities,
    };
}

export function setStatesUpdateFree(states) {
    return {
        type: SET_STATES_UPDATE_FREE,
        states,
    };
}

export function getProductData() {
    return (dispatch) => {
        getFree().then((response) => {
            dispatch(setFreeProduct(response));
        });
    };
}

export function getCityList(query) {
    return (dispatch) => {
        getCidades(query).then((response) => {
            dispatch(setCitiesUpdateFree(response));
        });
    };
}

export function getStatesList() {
    return (dispatch) => {
        getUfs().then((response) => {
            dispatch(setStatesUpdateFree(response));
        });
    };
}

export function getRegionAutocompleteOptions(type) {
    return (dispatch) => {
        if (type === 'cidade') {
            dispatch(getCityList(''));
        }
        if (type === 'estado') {
            dispatch(getStatesList());
        }
    };
}

export function getUpdateEnoughData(model) {
    return (dispatch) => {
        validateDataQuantity(model).then((response) => {
            dispatch(setEnoughDataEdit(response));
        });
    };
}
