import React from 'react';

export const MdPriceChange = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        {...props}
    >
        <path
            d="M20 4H4C2.89 4 2.01 4.89 2.01 6L2 18C2 19.11 2.89 20 4 20H20C21.11 20 22 19.11 22 18V6C22 4.89 21.11 4 20 4ZM11 10H8V11H11C11.55 11 12 11.45 12 12V15C12 15.55 11.55 16 11 16H10C10 16.55 9.55 17 9 17C8.45 17 8 16.55 8 16H7C6.45 16 6 15.55 6 15C6 14.45 6.45 14 7 14H10V13H7C6.45 13 6 12.55 6 12V9C6 8.45 6.45 8 7 8H8C8 7.45 8.45 7 9 7C9.55 7 10 7.45 10 8H11C11.55 8 12 8.45 12 9C12 9.55 11.55 10 11 10ZM15.65 15.9L14 14.25H18L16.35 15.9C16.16 16.09 15.84 16.09 15.65 15.9ZM14 10L15.65 8.35C15.85 8.15 16.16 8.15 16.36 8.35L18 10H14Z"
            fill="currentColor"
        />
    </svg>
);
