import { useState } from 'react';

export const useToggle = (initialValue = false) => {
    const [state, setState] = useState<boolean>(initialValue);

    return [
        state,
        {
            toggleOn: () => setState(true),
            toggleOff: () => setState(true),
            toggle: () => setState((oldValue) => !oldValue),
            set: (state: boolean) => setState(state),
        },
    ] as const;
};
