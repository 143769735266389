import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import {
    Alert,
    Button,
    ButtonToolbar,
    Col,
    Container, Content,
    ControlLabel,
    FlexboxGrid,
    Form,
    FormControl,
    FormGroup,
    HelpBlock,
    Icon,
    Input, InputGroup,
} from 'rsuite';

import { HomeCarouselComponent } from '../../components/HomeCarouselComponent';
import { lead } from '../../services/EventLeadService';
import { createSenha, getUsuarioByToken } from '../../services/UsuarioService';
import { trackEvent } from '../../utils/MatomoConfig';

class CriarContaPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: '',
            senha: '',
            errors: {},
            usuario: null,
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.showPassword = this.showPassword.bind(this);
    }

    componentDidMount() {
        const { location } = this.props;
        const query = new URLSearchParams(location.search);
        const token = query.get('token');
        this.setState({
            token,
        });
        this.getUsuario(token);
    }

    handleSubmit(e) {
        e.preventDefault();
        const { token, senha } = this.state;
        const { history } = this.props;

        if (this.validDateInput()) {
            createSenha(token, senha).then((data) => {
                if (data) {
                    history.push('/login');
                    Alert.success('Conta criada com sucesso', 5000);
                    this.sendEventLead();
                }
            });
        }
    }

    handleChange(value, e) {
        this.setState(
            {
                [e.target.name]: value,
            },
            this.validDateInput,
        );
    }

    getUsuario(token) {
        const { history } = this.props;
        if (token) {
            getUsuarioByToken(token).then((data) => {
                if (data && data.response && data.response.status === 412) {
                    history.push('/login');
                    Alert.error('Solicite ao administrador um novo link para criação de acesso', 5000);
                } else {
                    this.setState({
                        usuario: data,
                        email: data.login,
                    });
                }
            });
        }
    }

    sendEventLead() {
        const { email } = this.state;
        const identifier = 'senha-criada';
        localStorage.setItem('usuario', JSON.stringify({ email }));

        lead(identifier);
        trackEvent('Conta', identifier);
    }

    validDateInput() {
        const { email, usuario, senha } = this.state;
        const errors = {};
        let formIsValid = true;

        if (!email.length) {
            formIsValid = false;
            errors.email = 'Campo obrigatório';
            const email = document.getElementsByName('email')[0];
            if (email) {
                email.classList.add('invalid-input');
            }
        } else if (email !== usuario.login) {
            formIsValid = false;
            errors.email = 'E-mail inválido';
            const email = document.getElementsByName('email')[0];
            if (email) {
                email.classList.add('invalid-input');
            }
        } else {
            const email = document.getElementsByName('email')[0];
            if (email) {
                email.classList.remove('invalid-input');
            }
        }

        if (!senha.length) {
            formIsValid = false;
            errors.senha = 'Campo obrigatório';
            const senha = document.getElementsByName('senha')[0];
            if (senha) {
                senha.classList.add('invalid-input');
            }
        } else if (senha.length < 6) {
            formIsValid = false;
            errors.senha = 'A senha deve possuir pelo menos 6 caracteres';
            const senha = document.getElementsByName('senha')[0];
            if (senha) {
                senha.classList.add('invalid-input');
            }
        } else if (email.length && senha.indexOf(email) !== -1) {
            formIsValid = false;
            errors.senha = 'A senha não pode conter seu e-mail na integra';
            const senha = document.getElementsByName('senha')[0];
            if (senha) {
                senha.classList.add('invalid-input');
            }
        } else {
            const senha = document.getElementsByName('senha')[0];
            if (senha) {
                senha.classList.remove('invalid-input');
            }
        }

        this.setState({ errors });
        return formIsValid;
    }

    showPassword() {
        const passwordInput = document.getElementById('senha');

        if (passwordInput.type === 'password') {
            passwordInput.type = 'text';
        } else {
            passwordInput.type = 'password';
        }
    }

    render() {
        const { email, senha, errors } = this.state;

        return (
            <div className="criar-senha-page">
                <Container>
                    <FlexboxGrid>
                        <Col md={10} lg={10} xsHidden smHidden>
                            <Content>
                                <HomeCarouselComponent />
                            </Content>
                        </Col>
                        <Col md={14} lg={14} xs={24} className="form-area">
                            <Content className="new-account-form ">
                                <h4>Criar conta</h4>
                                <Form fluid onSubmit={this.handleSubmit}>
                                    <FormGroup>
                                        <ControlLabel>E-mail</ControlLabel>
                                        <FormControl name="email" value={email} onChange={this.handleChange} type="email" disabled />
                                        <HelpBlock className="invalid-field" name="errorEmail">{errors.email}</HelpBlock>
                                    </FormGroup>
                                    <FormGroup>
                                        <ControlLabel>Senha</ControlLabel>
                                        <InputGroup>
                                            <Input
                                                placeholder="Digite sua senha"
                                                id="senha"
                                                name="senha"
                                                value={senha}
                                                onChange={this.handleChange}
                                                type="password"
                                            />
                                            <InputGroup.Button onClick={this.showPassword}>
                                                <Icon icon="eye" />
                                            </InputGroup.Button>
                                        </InputGroup>
                                        <HelpBlock className="invalid-field" name="erroSenha">{errors.senha}</HelpBlock>
                                    </FormGroup>
                                    <div className="instructions">
                                        <p>Para proteger sua conta, certifique-se que sua senha:</p>
                                        <ul>
                                            <li>Possui mais que 6 caracteres</li>
                                            <li>Não contenha o e-mail da conta na íntegra</li>
                                        </ul>
                                    </div>
                                    <FormGroup>
                                        <ButtonToolbar>
                                            <Button block type="submit" appearance="primary">Criar conta</Button>
                                        </ButtonToolbar>
                                    </FormGroup>
                                </Form>

                            </Content>
                        </Col>
                    </FlexboxGrid>
                </Container>
            </div>
        );
    }
}

export default withRouter(CriarContaPage);
