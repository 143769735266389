import React from 'react';
import classNames from 'classnames';
import { Tag, TagProps } from '../../../../../Lite';
import styles from './StatusTag.module.scss';
import { Icon } from '../../../../../RevisaoPrecos/Components';

export type StatusTagProps = Omit<TagProps, 'children'> & {
    status?: 'ONGOING' | 'CLOSED'
}

const OPTIONS = {
    ONGOING: {
        skin: 'gray',
        icon: 'MdOutlineEditNote',
        text: 'em negociação',
    },
    CLOSED: {
        skin: 'green',
        icon: 'MdHandshake',
        text: 'negócio fechado',
    },
} as const;

export const StatusTag = ({ className, status = 'ONGOING', ...props }: StatusTagProps) => {
    const SelectedTag = OPTIONS?.[status];
    return (
        <Tag skin={SelectedTag?.skin} className={classNames(styles['status-tag'], className)} {...props}>
            <Icon icon={SelectedTag?.icon} />
            {SelectedTag?.text}
        </Tag>
    );
};
