import React, { forwardRef } from 'react';
import './style.scss';

export type FlexProps = {
    direction?: 'row' | 'column' | 'row-reverse' | 'column-reverse';
    wrap?: 'wrap' | 'wrap-reverse' | 'nowrap';
    align?:
        | 'baseline'
        | 'center'
        | 'end'
        | 'flex-end'
        | 'flex-start'
        | 'first baseline'
        | 'last baseline';
    justify?:
        | 'center'
        | 'flex-start'
        | 'flex-end'
        | 'space-between'
        | 'space-around'
        | 'evenly';
    gap?: string;
} & React.HtmlHTMLAttributes<HTMLDivElement>;

export const Flex = forwardRef(
    (
        {
            children,
            direction,
            align,
            justify,
            gap,
            wrap,
            className = '',
            ...props
        }: FlexProps,
        ref: React.LegacyRef<HTMLDivElement>,
    ) => (
        <div
            className={['flex-component', className].join(' ')}
            style={{
                ['--flex-direction' as string]: direction,
                ['--flex-align' as string]: align,
                ['--flex-justify' as string]: justify,
                ['--flex-gap' as string]: gap,
                ['--flex-wrap' as string]: wrap,
            }}
            ref={ref}
            {...props}
        >
            {children}
        </div>
    ),
);
