import cn from 'classnames';
import React, { ChangeEvent, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GerenciadorPrecos } from '../../../../../../@types/GerenciadorPrecos';
import { RootState } from '../../../../../../@types/RootState';
import {
    InputAddon,
    InputCurrency,
    InputGroup,
} from '../../../../../../components';
import BaseCell, {
    BaseCellProps,
} from '../../../../../../components/TableCells/BaseCell/BaseCell';
import { UPDATE_GERENCIADOR_DATAPOINT_BY_INDEX } from '../../../../../../reducers/gerenciadorPrecos/datapoints';
import styles from './WholesaleQtdeCell.module.scss';

const { MainContainer, SubContainer } = BaseCell;

type RowData = GerenciadorPrecos.RowData;

type WholesaleQtdeCellProps = BaseCellProps & {
    rowData: RowData;
    index: number;
    handleBlur: (rowData: RowData) => void;
};

const WholesaleQtdeCell = ({
    className,
    rowData,
    index,
    handleBlur,
    ...props
}: WholesaleQtdeCellProps) => {
    const { wholesale } = rowData;

    if (!wholesale) return null;

    const dispatch = useDispatch();

    const { triggerCount } = wholesale;

    const isDisabled = useSelector((state: RootState) => {
        return !state.reducerIPA.wholesale.allowTriggerEdit;
    });

    const handleUpdateQtde = useCallback(
        (
            _e: ChangeEvent<HTMLInputElement>,
            _m: string,
            triggerCount: number,
        ) => {
            if (!rowData.wholesale) return;

            const data = {
                wholesale: {
                    ...rowData.wholesale,
                    triggerCount,
                },
            };

            const payload = { index, data };

            dispatch(UPDATE_GERENCIADOR_DATAPOINT_BY_INDEX(payload));
        },
        [dispatch, index, rowData],
    );

    return (
        <BaseCell {...props} className={cn(styles.wrapper, className)}>
            <MainContainer>
                <InputGroup className={styles.input_wrapper}>
                    <InputAddon className={styles.input_addon}>≥</InputAddon>
                    <InputCurrency
                        precision="0"
                        className={styles.input}
                        value={triggerCount}
                        min={0}
                        disabled={isDisabled}
                        onChangeEvent={handleUpdateQtde}
                        onBlur={(_e, _v, { hasChanges }) => {
                            if (hasChanges) handleBlur(rowData);
                        }}
                    />
                </InputGroup>
            </MainContainer>
            <SubContainer>
                <strong>UN</strong>
            </SubContainer>
        </BaseCell>
    );
};

export default WholesaleQtdeCell;
