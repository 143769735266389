import React from 'react';
import { Content, Loader, Table } from 'rsuite';
// @ts-ignore
import { useListaOportunidades } from '../../../hooks/useListaOportunidades.tsx';
import CurrencyFormat from 'react-currency-format';
import { HoverTooltip } from '../../../../../components/HoverTooltip';
const { Column, HeaderCell, Cell } = Table;

type RowData = {
    productId: string;
    description: string;
    avg_average_competitivity_retail: string;
    count: number;
}

const columns = [
    {
        align: 'left',
        headerCell: 'Produto',
        dataKey: 'productId',
    },
    {
        align: 'left',
        headerCell: 'Descrição',
        dataKey: 'description',
        flexGrow: 2,
    },
    {
        align: 'left',
        headerCell: 'Competitividade',
        dataKey: 'avg_average_competitivity_retail',
        width: 80,
    },
    {
        align: 'right',
        headerCell: 'Itens',
        dataKey: 'count',
    },
    // @ts-ignore
] as Column<RowData>[];

export const ListaOportunidades: React.FC = () => {
    const { data, isLoading } = useListaOportunidades();

    const competitividadeColor = (value: number) => {
        switch (true) {
            case value < 54.99:
                return '#33AB8480';

            case value > 55 && value <= 64.99:
                return '#33AB8466';

            case value > 65 && value <= 74.99:
                return '#33AB844D';

            case value > 75 && value <= 84.99:
                return '#33AB8433';

            case value > 85 && value <= 94.99:
                return '#33AB841A';

            default:
                break;
        }
    };

    if (isLoading)
        return (
            <Content className="componente-tabela-loading">
                <Loader center />
            </Content>
        );

    if (!data || !data.length)
        return (
            <Content className="componente-tabela">
                <h2>Lista de oportunidades: aumentar o preço/margem</h2>
                <p>Produtos mais baratos comparados aos seus concorrentes</p>
                <div className="sem-dados">
                    <h3>Sem dados para essa análise</h3>
                    <p>Revise os filtros e tente novamente</p>
                </div>
            </Content>
        );

    return (
        <Content className="componente-tabela">
            <h2>Lista de oportunidades: aumentar o preço/margem</h2>
            <p>Produtos mais baratos comparados aos seus concorrentes</p>
            <div className="table-container">
                <Table data={data} height={368} rowHeight={32}>
                    {columns.map((column, index) => (
                        <Column key={index} {...column}>
                            <HeaderCell className="header-tabela">
                                {column.dataKey ===
                                'avg_average_competitivity_retail' ? (
                                    <HoverTooltip
                                        placement="bottom"
                                        text={column.headerCell}
                                    />
                                ) : (
                                    <span>{column.headerCell}</span>
                                )}
                            </HeaderCell>
                            {column.dataKey ===
                            'avg_average_competitivity_retail' ? (
                                <Cell dataKey={column.dataKey}>
                                    {(rowData: RowData) =>
                                        rowData.avg_average_competitivity_retail ? (
                                            <div
                                                className="linha-tabela"
                                                style={{
                                                    background:
                                                        competitividadeColor(
                                                            Number(
                                                                rowData.avg_average_competitivity_retail.replace(
                                                                    ',',
                                                                    '.'
                                                                )
                                                            )
                                                        ),
                                                }}
                                            >
                                                <CurrencyFormat
                                                    className="currency"
                                                    fixedDecimalScale
                                                    decimalScale={2}
                                                    value={
                                                        rowData.avg_average_competitivity_retail
                                                    }
                                                    displayType="text"
                                                    suffix="%"
                                                    decimalSeparator=","
                                                    thousandSeparator="."
                                                />
                                            </div>
                                        ) : (
                                            '--'
                                        )
                                    }
                                </Cell>
                            ) : (
                                <Cell dataKey={column.dataKey}>
                                    {(rowData: RowData) => (
                                        <div className="linha-tabela">
                                            {rowData[column.dataKey] || '--'}
                                        </div>
                                    )}
                                </Cell>
                            )}
                        </Column>
                    ))}
                </Table>
            </div>
        </Content>
    );
};
