import Immutable from 'seamless-immutable';

import {
    ADD_FILTRO_EXTRACAO,
    DELETE_FILTRO_EXTRACAO,
    DELETE_FILTRO_REGIAO_EXTRACAO,
    UPDATE_FILTER_DATA_EXTRACAO,
    UPDATE_FILTER_VALUE_EXTRACAO
} from '../actions/actionsExtracaoDados';

const extracaoDadosInitialState = Immutable({
    filtersType: [
        {
            icon: 'shopping-cart',
            title: 'LOJA',
            itens: [
                {
                    name: 'rede',
                    title: 'Rede'
                }
            ]
        },
        {
            icon: 'map-marker',
            title: 'LOCALIZAÇÃO',
            itens: [
                {
                    name: 'uf',
                    title: 'Região'
                }
            ]
        },
        {
            icon: 'barcode',
            title: 'COLETA',
            itens: [
                {
                    name: 'tipoSolicitacao',
                    title: 'Tipo de Solicitação'
                },
                {
                    name: 'escopo',
                    title: 'Escopo',
                },
            ]
        }
    ],
    filters: [
        {
            id: 'chain-filter',
            value: null,
            cleanable: true,
            searchable: true,
            data: [],
            name: 'rede',
            placeholder: 'Selecione uma Rede',
            locale: { noResultsText: 'Nenhum item encontrado' },
            onSelect: 'handleSelect',
            onClean: 'handleSelect'
        },
        {
            id: 'uf-filter',
            value: null,
            cleanable: true,
            searchable: true,
            data: [],
            name: 'uf',
            placeholder: 'Selecione uma UF',
            locale: { noResultsText: 'Nenhum item encontrado' },
            onSelect: 'handleSelect',
            onClean: 'handleSelect',
            child: {
                id: 'city-filter',
                value: null,
                cleanable: true,
                searchable: true,
                data: 'cidades',
                name: 'cidade',
                placeholder: 'Selecione uma Cidade',
                locale: { noResultsText: 'Nenhum item encontrado' },
                onSelect: 'handleSelect',
                onClean: 'handleSelect',
            }
        },
        {
            id: 'request-type-filter',
            value: null,
            cleanable: true,
            searchable: false,
            data: [],
            name: 'tipoSolicitacao',
            placeholder: 'Selecione um Tipo de Solicitação',
            locale: { noResultsText: 'Nenhum item encontrado' },
            onSelect: 'handleSelect',
            onClean: 'handleSelect',
            multiple: true
        },
        {
            id: 'escopo-filter',
            value: null,
            cleanable: true,
            searchable: true,
            data: [],
            name: 'escopo',
            placeholder: 'Selecione um Escopo',
            locale: { noResultsText: 'Nenhum item encontrado' },
            onSelect: 'handleSelect',
            onClean: 'handleSelect',
            multiple: true,
        },
    ],
    selectedFilters: [],
    filtersData: {
        rede: {
            value: null,
            data: []
        },
        uf: {
            value: null,
            data: []
        },
        cidade: {
            value: null,
            data: []
        },
        tipoSolicitacao: {
            value: [],
            data: []
        },
        escopo: {
            value: [],
            data: []
        }
    }
})

function extracaoDadosDataReducer(state = extracaoDadosInitialState, action) {
    switch (action.type) {
        case ADD_FILTRO_EXTRACAO:
            return state.merge({
                selectedFilters: [...state.selectedFilters, action.filtro]
            })
        case DELETE_FILTRO_EXTRACAO:
            return state.merge({
                selectedFilters: state.selectedFilters.filter((item, index) => index !== action.index),
                filtersData: {
                    ...state.filtersData,
                    [action.name]: {
                        ...state.filtersData[action.name],
                        value: null
                    }
                }
            })
        case DELETE_FILTRO_REGIAO_EXTRACAO:
            return state.merge({
                selectedFilters: state.selectedFilters.filter((item, index) => index !== action.index),
                filtersData: {
                    ...state.filtersData,
                    cidade: {
                        ...state.filtersData.cidade,
                        value: null
                    },
                    uf: {
                        ...state.filtersData.uf,
                        value: null
                    }
                }
            })
        case UPDATE_FILTER_DATA_EXTRACAO:
            return state.merge({
                ...state,
                filtersData: {
                    ...state.filtersData,
                    [action.name]: {
                        ...state.filtersData[action.name],
                        data: action.data
                    }
                }
            })
        case UPDATE_FILTER_VALUE_EXTRACAO:
            return state.merge({
                ...state,
                filtersData: {
                    ...state.filtersData,
                    [action.name]: {
                        ...state.filtersData[action.name],
                        value: action.value
                    }
                }
            })

        default:
            return state
    }
}

export default extracaoDadosDataReducer;