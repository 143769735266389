import { trackPromise } from 'react-promise-tracker';
import api from '../../../../../utils/API';

export const saveCampanha = (data) => trackPromise(
        api({
            url: `${process.env.REACT_APP_IPA_URL}/campaign/campaigns`,
            method: 'POST',
            expectToken: true,
            data: JSON.stringify(data),
        }).then((resp) => resp?.data?.body),
    );

export const updateCampanha = (data) => trackPromise(
        api({
            url: `${process.env.REACT_APP_IPA_URL}/campaign/campaigns/${data.campaign_id}`,
            method: 'PUT',
            expectToken: true,
            data: JSON.stringify(data),
        }).then((resp) => resp?.data),
    );
