import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';

import { Button, CheckPicker } from 'rsuite';
import dataQuantityIcon1 from '../../../../../assets/icons/data_quantity_icon-1.svg';
import dataQuantityIcon2 from '../../../../../assets/icons/data_quantity_icon-2.svg';
import dataQuantityIcon3 from '../../../../../assets/icons/data_quantity_icon-3.svg';

import {
    searchProdutos,
    changeValue,
    changeCache,
    selectValue,
    changeAddProduct,
    changeRemoveProduct,
    changeCheckAllProduct,
} from '../../../../../actions/actionsPainelGeral';
import { getModelPanel } from '../utils';
import { LoadingMenu } from '../../../../../components/LoadingSpinner';

const Transition = React.forwardRef((props, ref) => (
    <Slide direction="up" ref={ref} {...props} />
));

class MobileProductSelect extends Component {
    constructor(props) {
        super(props);

        this.state = {
            open: false,
        };

        this.openModalSelect = this.openModalSelect.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleCheckProduct = this.handleCheckProduct.bind(this);
        this.handleCleanProduto = this.handleCleanProduto.bind(this);
        this.handleCheckAllProduto = this.handleCheckAllProduto.bind(this);
        this.handleFilterProduto = this.handleFilterProduto.bind(this);
    }

    shouldComponentUpdate(nextProps, nextState) {
        const { open } = this.state;

        return nextProps !== this.props || nextState.open !== open;
    }

    handleClose() {
        this.setState({
            open: false,
        });
    }

    handleCheckProduct(item) {
        const { produto, changeRemoveProduct, changeAddProduct } = this.props;
        const hasValue = produto?.value?.includes(item?.value);
        if (hasValue) {
            changeRemoveProduct(item?.value);
        } else {
            changeAddProduct(item);
        }
    }

    handleCleanProduto() {
        const { changeCheckAllProduct } = this.props;

        changeCheckAllProduct([], []);
    }

    handleFilterProduto(inputValue) {
        const { searchProdutos } = this.props;
        let filtros;

        if (
            inputValue &&
            typeof inputValue === 'string' &&
            inputValue.match(/^\d+ \d+[\d+ ]*$/)
        ) {
            filtros = { query: '', identificadores: inputValue.split(' ') };
        } else {
            filtros = {
                query: typeof inputValue === 'string' ? inputValue : '',
            };
        }

        searchProdutos(
            getModelPanel(
                this.props,
                filtros.query,
                'produto',
                filtros.identificadores ? filtros.identificadores : null
            )
        );
    }

    handleCheckAllProduto() {
        const { changeCheckAllProduct, produto } = this.props;

        const newCacheProduto = produto?.cache?.concat(
            produto?.lista?.filter(
                (cache) =>
                    produto?.cache?.findIndex(
                        (item) => item.value === cache.value
                    ) === -1
            )
        );

        const newValueProduto = newCacheProduto.map((item) => item.value);

        changeCheckAllProduct(newCacheProduto, newValueProduto);
    }

    openModalSelect() {
        const { searchProdutos } = this.props;
        searchProdutos(getModelPanel(this.props, '', 'produto'));

        this.setState({
            open: true,
        });
    }

    render() {
        const { produto } = this.props;
        const { open } = this.state;

        return (
            <>
                <CheckPicker
                    className="inf-picker"
                    block
                    id="product-filter"
                    value={produto.value}
                    data={produto.lista}
                    onClick={this.openModalSelect}
                    name="produtos"
                    placeholder="Produto"
                    cleanable={false}
                />
                <Dialog
                    className="dialog-select"
                    fullScreen
                    open={open}
                    onClose={this.handleClose}
                    TransitionComponent={Transition}
                >
                    <AppBar className="dialog-select__header">
                        <Toolbar className="header">
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={this.handleClose}
                                aria-label="close"
                            >
                                <CloseIcon />
                            </IconButton>

                            <div className="header__search-input">
                                <div className="search-input__icon">
                                    <SearchIcon />
                                </div>
                                <InputBase
                                    inputProps={{ 'aria-label': 'search' }}
                                    onChange={(event) =>
                                        this.handleFilterProduto(
                                            event.target.value
                                        )
                                    }
                                />
                            </div>
                        </Toolbar>
                    </AppBar>

                    <List className="dialog-select__list">
                        <LoadingMenu area="panel-filter" />
                        {produto.lista.map((item) => {
                            const labelId = `checkbox-list-label-${item.label}`;

                            return (
                                <ListItem
                                    key={item.label}
                                    onClick={() =>
                                        this.handleCheckProduct(item)
                                    }
                                >
                                    <ListItemIcon>
                                        <Checkbox
                                            edge="start"
                                            checked={
                                                produto.value.indexOf(
                                                    item.value
                                                ) !== -1
                                            }
                                            tabIndex={-1}
                                            disableRipple
                                            inputProps={{
                                                'aria-labelledby': labelId,
                                            }}
                                        />
                                    </ListItemIcon>
                                    <ListItemText
                                        id={labelId}
                                        primary={item.identificador}
                                        secondary={item.descricao}
                                    />
                                    <ListItemSecondaryAction>
                                        <img
                                            alt="Quantidade de dados"
                                            src={
                                                item.datapoints < 30
                                                    ? dataQuantityIcon1
                                                    : item.datapoints >= 30 &&
                                                      item.datapoints < 100
                                                    ? dataQuantityIcon2
                                                    : item.datapoints >= 100
                                                    ? dataQuantityIcon3
                                                    : null
                                            }
                                        />
                                    </ListItemSecondaryAction>
                                </ListItem>
                            );
                        })}
                    </List>

                    <AppBar position="fixed" className="dialog-select__footer">
                        <Button
                            appearance="subtle"
                            disabled={!produto?.value?.length}
                            onClick={this.handleCleanProduto}
                        >
                            Limpar
                        </Button>
                        {!produto?.value?.length ? (
                            <Button
                                appearance="primary"
                                onClick={this.handleCheckAllProduto}
                            >
                                Selecionar tudo{' '}
                                {produto?.lista?.length
                                    ? `(${produto.lista.length})`
                                    : null}
                            </Button>
                        ) : (
                            <Button
                                appearance="primary"
                                onClick={this.handleClose}
                            >
                                Aplicar
                            </Button>
                        )}
                    </AppBar>
                </Dialog>
            </>
        );
    }
}

const mapStateToProps = (store) => ({
    produto: store.painelGeralDataReducer.produto,
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            searchProdutos,
            changeValue,
            changeCache,
            selectValue,
            changeAddProduct,
            changeRemoveProduct,
            changeCheckAllProduct,
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MobileProductSelect);
