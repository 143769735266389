import classNames from 'classnames';
import React from 'react';
import { Table } from 'rsuite';
import { TableProps } from 'rsuite/lib/Table';
import { TablePaginationProps } from 'rsuite/lib/Table/TablePagination';
import { LoadingSpinerLocal } from '../LoadingSpinner';
import styles from './InfoTable.module.scss';

export type InfoTableProps<K = string, D = unknown> = TableProps<K, D> & {
    pagination?: TablePaginationProps;
    id?: string;
};

const InfoTable = ({
    children,
    className,
    pagination,
    headerHeight = 46,
    autoHeight = true,
    minHeight = 600,
    rowHeight = 62,
    rowClassName = styles.table__row,
    rowExpandedHeight = 200,
    renderLoading,
    id,
    ...props
}: InfoTableProps) => {
    return (
        <div>
            <Table
                id={id}
                className={classNames(styles.table, className)}
                autoHeight={autoHeight}
                minHeight={minHeight}
                headerHeight={headerHeight}
                rowHeight={rowHeight}
                rowClassName={rowClassName}
                rowExpandedHeight={rowExpandedHeight}
                renderLoading={
                    renderLoading ||
                    (() => (
                        // @ts-ignore
                        <LoadingSpinerLocal loading={props.loading} />
                    ))
                }
                {...props}
            >
                {children}
            </Table>
            {pagination && <Table.Pagination {...pagination} />}
        </div>
    );
};

InfoTable.Column = Table.Column;
InfoTable.Cell = Table.Cell;
InfoTable.HeaderCell = Table.HeaderCell;

export default InfoTable;
