import React from 'react';
import { Tooltip, Whisper } from 'rsuite';
import { ContentFamilyModal } from '../../../pages/IPA/RevisaoPrecos/Components/GetFamilyListModal/types';
import { BaseCell } from '../BaseCell';
import { BaseCellProps } from '../BaseCell/BaseCell';
import styles from './FatorCell.module.scss';

type FatorCellProps = BaseCellProps & {
    rowData: ContentFamilyModal
};

const FatorCell = ({ rowData, ...props }: FatorCellProps) => {
    const { factor } = rowData;

    const factorBase = factor === 1
        ? "IGUAL"
        : factor > 1
            ? `+${((factor - 1) * 100).toFixed(1).replace('.', ',')}%`
            : `${((factor - 1) * 100).toFixed(1).replace('.', ',')}%`;

    return (
        <BaseCell {...props}>
            {factor ? (
                <Whisper
                    delayShow={0.2}
                    trigger="hover"
                    placement="right"
                    speaker={<Tooltip>{factor.toString().replace('.', ',')}</Tooltip>}
                >
                    <>
                        <span className="font-size-100-semibold">{factor.toString().replace('.', ',')}</span>
                        <span className={styles['factor-base']}>{factorBase}</span>
                    </>
                </Whisper>
            ) : null}
        </BaseCell>
    );
};

export default FatorCell;
