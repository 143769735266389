import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { Alert } from 'rsuite';
import { TablePaginationProps } from 'rsuite/lib/Table/TablePagination';
import {
    FiltrosModelProps,
    checkIfIsLoading,
    getFiltrosModel,
} from '../../../../utils';
import { getCompetitorsInfo } from '../../RevisaoPrecos/services_ts';
import {
    NegotiationDataItemProps,
    NegotiationDataProps,
    NegotitationRowDataProps,
    SuppliersResponseContentProps,
    SuppliersResponseProps,
} from '../pages';
import {
    PATCHNegotiationDetailsSaveEditedDatapoint,
    desfazerAlteracaoDePrecos,
} from '../pages/DetalhesDaNegociacao/DetalhesDaNegociacao.services';
import { ITableFornecedor } from '../pages/NegociacaoFornecedor/components';
import {
    PMZNewCostCalc,
    bestIdealPriceCalc,
    bestIdealPricePMZCalc,
    getEditedDatapointModel,
    newCostCalc,
    newPriceCalc,
    newPriceCompetitorsCalc,
    priceMarginCalc,
} from '../pages/NegociacaoFornecedor/utils';
import { GetNegociacoesItemProps } from '../pages/NegociacoesSalvas/NegociacoesSalvas.services';
import { usePagination } from './usePagination';
import { useSelectedDatapoints } from './useSelectedDatapoints';
import { useSort } from './useSort';

type UseNegotiationTableProps = {
    queryFn?: (data: FiltrosModelProps) => Promise<SuppliersResponseProps>;
    rowKey: keyof NegotitationRowDataProps;
    filters?: Partial<Record<string, string[]>>;
    negotiationStatus?: GetNegociacoesItemProps['status'];
};

type UseNegotiationTable = ITableFornecedor & {
    isLoading: boolean;
    pagination: TablePaginationProps;
};

const INITIAL_DATA: NegotiationDataProps = {
    content: [],
    totalElements: 0,
    totalPages: 0,
};

export const useNegotiationTable = ({
    rowKey,
    queryFn,
    filters,
    negotiationStatus,
}: UseNegotiationTableProps): UseNegotiationTable => {
    const [data, setData] = useState<NegotiationDataProps>(INITIAL_DATA);

    const [totalElements, setTotalElements] = useState<number>(0);

    const [sort, { onSort }] = useSort({
        type: 'productId',
        orderBy: 'asc',
    });

    const pagination = usePagination({ total: totalElements });

    const selectedDatapoints = useSelectedDatapoints();

    const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);

    const model = getFiltrosModel(
        {
            sort,
            pageSize: pagination.displayLength,
            pageNumber: pagination.activePage,
        },
        'camel',
    );

    const { fetchStatus } = useQuery({
        queryKey: [
            'get-negotiation-datapoints',
            filters,
            sort,
            pagination.activePage,
            pagination.displayLength,
        ],
        queryFn: () => queryFn?.(model),
        onSuccess: (data) => {
            if (!data) return;
            setTotalElements(data.totalElements);
            setData((oldValue) => ({
                ...oldValue,
                content: data.content,
            }));
        },
        retry: false,
    });

    const updateCompetitorData = (
        productsToBePricedId: string,
        response: NegotiationDataItemProps['competitorsInfo'],
    ) => {
        setData((oldState) => ({
            ...oldState,
            content: data.content.map((item) => {
                if (item.productsToBePricedId === productsToBePricedId) {
                    return {
                        ...item,
                        competitorsInfo: response,
                    };
                }
                return item;
            }),
        }));
    };

    const getInfoCompetitors = ({
        storeId,
        productId,
        productFamilyId,
        competitorsPrice,
        productsToBePricedId,
        productFamily,
    }: NegotitationRowDataProps) => {
        if (competitorsPrice !== 0 || productFamilyId) {
            const hasPreviousData = data.content.find(
                (item) => item.productsToBePricedId === productsToBePricedId,
            )?.competitorsInfo;

            if (hasPreviousData) return;

            getCompetitorsInfo(
                {
                    productId,
                    storeId,
                    productFamilyId: productFamily ? productFamilyId : null,
                },
                `competitor-row-${storeId}-${productId}`,
            ).then((data) => {
                if (data) updateCompetitorData(productsToBePricedId, data);
            });
        }
    };

    const handleExpandedRow = (rowData: NegotitationRowDataProps) => {
        let isOpen = false;

        const nextExpandedRowKeys = [];

        expandedRowKeys?.forEach((key) => {
            if (key === rowData[rowKey]) {
                isOpen = true;
            } else {
                nextExpandedRowKeys.push(key);
            }
        });

        if (!isOpen) {
            nextExpandedRowKeys.push(rowData[rowKey] as string);
            getInfoCompetitors(rowData);
        }

        setExpandedRowKeys(nextExpandedRowKeys);
    };

    const handleChangeNewCost = (
        index: number,
        value: string,
        rowData: SuppliersResponseContentProps,
    ) => {
        const cost = Number(value.replace(/\./g, '').replace(',', '.'));

        const PMZNewCost = PMZNewCostCalc(rowData, cost);
        const newPriceDecimals = Number(newPriceCalc(rowData, cost).toFixed(2));
        const newPrice = newPriceCalc(rowData, cost);
        const newPriceMargin = priceMarginCalc(rowData, newPriceDecimals, cost);
        const newPriceCompetitors = newPriceCompetitorsCalc(
            rowData,
            newPriceDecimals,
        );
        const hasRetailPrice =
            rowData.retailPrice !== null && rowData.retailPrice > 0;
        const newRetailPriceMargin = hasRetailPrice
            ? priceMarginCalc(
                  rowData,
                  rowData.retailPrice,
                  cost === 0 || cost == null ? rowData.cost : cost,
              )
            : null;
        const hasCompetitorsPrice =
            rowData.competitorsPrice !== null && rowData.competitorsPrice > 0;
        const dataContent = data?.content;
        const newCompetitorPriceMargin = hasCompetitorsPrice
            ? priceMarginCalc(
                  rowData,
                  rowData.competitorsPrice,
                  cost === 0 || cost == null ? rowData.cost : cost,
              )
            : null;

        if (value === '0,00') {
            dataContent[index] = {
                ...dataContent[index],
                newCost: null,
                newPrice: null,
                newPriceMargin: null,
                newPriceCompetitors: null,
                marginRetailPrice: newRetailPriceMargin,
                marginCompetitorsPrice: newCompetitorPriceMargin,
            };
        } else {
            dataContent[index] = {
                ...dataContent[index],
                newCost: cost,
                newPrice,
                PMZNewCost,
                newPriceMargin,
                marginRetailPrice: newRetailPriceMargin,
                newPriceCompetitors: hasCompetitorsPrice
                    ? newPriceCompetitors
                    : null,
                marginCompetitorsPrice: newCompetitorPriceMargin,
            };
        }
        setData((oldState) => ({ ...oldState, content: dataContent }));
    };

    const handleChangeNewPrice = (
        index: number,
        value: string,
        rowData: SuppliersResponseContentProps,
    ) => {
        const newPrice = Number(value.replace(/\./g, '').replace(',', '.'));
        const newCost = newCostCalc(
            rowData,
            rowData?.newObjectiveMargin,
            newPrice,
        );
        const PMZNewCost = PMZNewCostCalc(rowData, newCost);
        const newPriceMargin = priceMarginCalc(rowData, newPrice, newCost);
        const newPriceCompetitors = newPriceCompetitorsCalc(rowData, newPrice);
        const hasRetailPrice =
            rowData.retailPrice !== null && rowData.retailPrice > 0;
        const newRetailPriceMargin = hasRetailPrice
            ? priceMarginCalc(
                  rowData,
                  rowData.retailPrice,
                  newCost === 0 || newCost == null ? rowData.cost : newCost,
              )
            : null;
        const hasCompetitorsPrice =
            rowData.competitorsPrice !== null && rowData.competitorsPrice > 0;

        const newCompetitorPriceMargin = hasCompetitorsPrice
            ? priceMarginCalc(
                  rowData,
                  rowData.competitorsPrice,
                  newCost === 0 || newCost == null ? rowData.cost : newCost,
              )
            : null;

        const dataContent = data?.content;

        if (value === '0,00') {
            dataContent[index] = {
                ...dataContent[index],
                newCost: null,
                newPrice: null,
                newPriceMargin: null,
                marginRetailPrice: newRetailPriceMargin,
                newPriceCompetitors: null,
                marginCompetitorsPrice: newCompetitorPriceMargin,
            };
            setData((oldState) => ({ ...oldState, content: dataContent }));
        } else {
            dataContent[index] = {
                ...dataContent[index],
                newPrice,
                newCost,
                PMZNewCost,
                newPriceMargin,
                marginRetailPrice: newRetailPriceMargin,
                newPriceCompetitors: hasCompetitorsPrice
                    ? newPriceCompetitors
                    : null,
                marginCompetitorsPrice: newCompetitorPriceMargin,
            };
            setData((oldState) => ({ ...oldState, content: dataContent }));
        }
    };
    const handleChangeObjectiveMargin = (
        index: number,
        value: string,
        rowData: SuppliersResponseContentProps,
    ) => {
        const marginValue = Number(value.replace(/\./g, '').replace(',', '.'));
        const newCost = newCostCalc(rowData, marginValue, rowData.newPrice);
        const PMZNewCost = PMZNewCostCalc(rowData, newCost);
        const bestIdealPrice = Number(
            bestIdealPriceCalc(rowData, marginValue)
                .toFixed(2)
                .replace(',', '.'),
        );
        const bestIdealPricePMZ = Number(
            bestIdealPricePMZCalc(rowData, bestIdealPrice)
                .toFixed(2)
                .replace(',', '.'),
        );
        const newPriceMargin = priceMarginCalc(
            rowData,
            rowData?.newPrice,
            rowData.newCost,
        );
        const hasRetailPrice =
            rowData.retailPrice !== null && rowData.retailPrice > 0;
        const newRetailPriceMargin = hasRetailPrice
            ? priceMarginCalc(
                  rowData,
                  rowData.retailPrice,
                  newCost === 0 || newCost == null ? rowData.cost : newCost,
              )
            : null;
        const isValueNull = value === '0,0' ? null : marginValue;
        const dataContent = data?.content;
        const hasCompetitorsPrice =
            rowData.competitorsPrice !== null && rowData.competitorsPrice > 0;
        const newCompetitorPriceMargin = hasCompetitorsPrice
            ? priceMarginCalc(
                  rowData,
                  rowData.competitorsPrice,
                  newCost === 0 || newCost == null ? rowData.cost : newCost,
              )
            : null;

        if (rowData?.newCost !== null || rowData?.newPrice !== null) {
            dataContent[index] = {
                ...dataContent[index],
                newObjectiveMargin: isValueNull,
                newCost,
                PMZNewCost,
                newPriceMargin,
                bestIdealPrice,
                bestIdealPricePMZ,
                marginRetailPrice: newRetailPriceMargin,
                marginCompetitorsPrice: newCompetitorPriceMargin,
            };
        } else {
            dataContent[index] = {
                ...dataContent[index],
                newObjectiveMargin: isValueNull,
                bestIdealPrice,
                marginRetailPrice: newRetailPriceMargin,
                bestIdealPricePMZ,
                marginCompetitorsPrice: newCompetitorPriceMargin,
            };
        }
        setData((oldState) => ({ ...oldState, content: dataContent }));
    };

    const findDataAndUpdate = async (
        id: string,
        payload: Partial<NegotiationDataItemProps>,
    ) => {
        return new Promise<NegotiationDataItemProps>((resolve, reject) => {
            const index = data.content.findIndex(
                ({ productsToBePricedId }) => productsToBePricedId === id,
            );

            if (index === -1) {
                reject(new Error('Index not found'));
                return;
            }

            const currentData = data.content;

            currentData[index] = {
                ...currentData[index],
                ...payload,
            };

            setData((oldState) => ({ ...oldState, content: currentData }));

            resolve(data.content[index]);
        });
    };

    const handleInputBlur = async (
        rowData: NegotitationRowDataProps,
        _index: number,
        details: {
            onBlurValue: number | null;
            onFocusValue: number | null;
            hasChanges: boolean;
        },
    ) => {
        if (!details?.hasChanges) return;

        const model = getEditedDatapointModel(rowData);

        const res = await PATCHNegotiationDetailsSaveEditedDatapoint(model);

        await findDataAndUpdate(rowData.productsToBePricedId, {
            isEdited: res.isEdited,
        });
    };

    const handleDesfazerAlteracaoDePrecos = async (
        rowData: NegotitationRowDataProps,
    ) => {
        try {
            const res = await desfazerAlteracaoDePrecos(rowData);
            if (!res) return;
            await findDataAndUpdate(rowData.productsToBePricedId, res);
            Alert.success('Alteração de preço desfeita com sucesso');
        } catch {
            Alert.error('Erro ao desfazer alteração de preço');
        }
    };

    return {
        data: data.content,
        isLoading: checkIfIsLoading(fetchStatus),
        sortColumn: sort.type,
        onSortColumn: onSort,
        pagination,
        rowKey,
        selectedDatapoints,
        expandedRowKeys,
        onExpandRow: handleExpandedRow,
        onChangeNewCost: handleChangeNewCost,
        onChangeNewPrice: handleChangeNewPrice,
        onChangeObjectiveMargin: handleChangeObjectiveMargin,
        onBlur: handleInputBlur,
        totalElements,
        ...(negotiationStatus === 'ONGOING' && {
            contextMenu: {
                onUndoChanges: handleDesfazerAlteracaoDePrecos,
            },
        }),
    };
};
