import { PriceCompositionProps } from './usePriceCompositionModal';

type ResponseProps = {
    ipaPriceComposition: Record<
        'retailPrice' | 'newPrice' | 'pmz',
        {
            priceType?: string;
            price: number;
            liquidCost: {
                value: number;
                percentage: number;
            };
            profit: {
                value: number;
                percentage: number;
            };
            expenses: {
                value: number;
                percentage: number;
            };
            tax: {
                value: number;
                percentage: number;
            };
        }
    >;
};

export const getDataModalComposition = (
    response: ResponseProps,
): PriceCompositionProps[] => [
    {
        priceType: 'Preço vigente',
        price: response.ipaPriceComposition.retailPrice.price,
        liquid_cost: {
            value: response.ipaPriceComposition.retailPrice.liquidCost.value,
            percentage:
                response.ipaPriceComposition.retailPrice.liquidCost.percentage,
        },
        profit: {
            value: response.ipaPriceComposition.retailPrice.profit.value,
            percentage:
                response.ipaPriceComposition.retailPrice.profit.percentage,
        },
        expenses: {
            value: response.ipaPriceComposition.retailPrice.expenses.value,
            percentage:
                response.ipaPriceComposition.retailPrice.expenses.percentage,
        },
        tax: {
            value: response.ipaPriceComposition.retailPrice.tax.value,
            percentage: response.ipaPriceComposition.retailPrice.tax.percentage,
        },
    },
    {
        priceType: 'Novo preço',
        price: response.ipaPriceComposition.newPrice.price,
        liquid_cost: {
            value: response.ipaPriceComposition.newPrice.liquidCost.value,
            percentage:
                response.ipaPriceComposition.newPrice.liquidCost.percentage,
        },
        profit: {
            value: response.ipaPriceComposition.newPrice.profit.value,
            percentage: response.ipaPriceComposition.newPrice.profit.percentage,
        },
        expenses: {
            value: response.ipaPriceComposition.newPrice.expenses.value,
            percentage:
                response.ipaPriceComposition.newPrice.expenses.percentage,
        },
        tax: {
            value: response.ipaPriceComposition.newPrice.tax.value,
            percentage: response.ipaPriceComposition.newPrice.tax.percentage,
        },
    },
    {
        priceType: 'PMZ',
        price: response.ipaPriceComposition.pmz.price,
        liquid_cost: {
            value: response.ipaPriceComposition.pmz.liquidCost.value,
            percentage: response.ipaPriceComposition.pmz.liquidCost.percentage,
        },
        profit: {
            value: response.ipaPriceComposition.pmz.profit.value,
            percentage: response.ipaPriceComposition.pmz.profit.percentage,
        },
        expenses: {
            value: response.ipaPriceComposition.pmz.expenses.value,
            percentage: response.ipaPriceComposition.pmz.expenses.percentage,
        },
        tax: {
            value: response.ipaPriceComposition.pmz.tax.value,
            percentage: response.ipaPriceComposition.pmz.tax.percentage,
        },
    },
];
