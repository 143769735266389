import api from '../utils/API';

export const getTypes = () =>
    api({
        url: process.env.REACT_APP_CLIENTE_URL + '/tipo_solicitacao',
        method: 'GET',
        expectToken: true
    }).then((resp) => {
        return resp.data;
    })
