import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { reducerOnDemandSelector } from '../../../../../../reducers/reducerOnDemand';
import { getTableDetailRequest } from '../../services';

type RequestBody = {
    id: string,
    gtin: string,
    palavraChave: string,
    dataCriacao: string,
    criador: string,
    uf: string,
    cidade: string,
    raio: number,
    status: string,
};

export const useOnDemandTableRequest = () => {
    const reducer = useSelector(reducerOnDemandSelector);

    const handleRequest = async () => {
        const res = await getTableDetailRequest(reducer.tableRequestBody);
        return res.data;
    };

    const {
        data, isLoading, isError, isSuccess, refetch,
    } = useQuery(
        ['ondemand-table'],
        handleRequest,
        {
            retry: false,
        },
    );

    const tableData = useMemo(() => data, [data, reducer.tableRequestBody]);

    return {
        tableData, isLoading, isSuccess, isError, refetch,
    };
};
