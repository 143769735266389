import React, { FormEvent, HtmlHTMLAttributes, useEffect } from 'react';
import { usePromiseTracker } from 'react-promise-tracker';
import { Alert } from 'rsuite';
import { LoadingSpinerLocal } from '../../../../../components/LoadingSpinner';
import { Layout } from '../../components';
import { PAGE_PROPS } from '../../components/template/GerenciadorTable/types';
import { useGerenciadorPrecos, useOnboarding } from '../../context';
import { getOnboardingSetup, saveOnboarding } from '../../lib/services';
import { Concorrente, Estrategia, Loja } from './components';
import { Layout as OnboardingLayout } from './components/template';
import './style.scss';

export type OnboardingProps = PAGE_PROPS & HtmlHTMLAttributes<HTMLElement>;

const COMPONENTS = [Loja, Concorrente, Estrategia];

export const Onboarding = ({ history, usuario }: OnboardingProps) => {
    const {
        data: { competitiveness },
        setData: setGerenciadorData,
    } = useGerenciadorPrecos();

    if (competitiveness) {
        return history.push('/ipa/lite/gerenciador-de-precos');
    }

    const { promiseInProgress } = usePromiseTracker({
        area: 'get-onboarding-setup',
    });

    const { step, setData, dataForm } = useOnboarding();
    const Component = COMPONENTS[step];

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        saveOnboarding({
            createdBy: usuario?.login ?? '',
            editedBy: usuario?.login ?? '',
            indirectCompetitivenessDTO: {
                storeType: dataForm.storeType,
                location: dataForm.location,
                competitiveness: dataForm.competitiveness,
            },
        })
            .then((response) => {
                if (response.status === 200) {
                    history.push('/ipa/lite/gerenciador-de-precos');
                }
                return response;
            })
            .catch((err) => {
                Alert.error(err.response.data._embedded.errors[0].message);
            });
    };

    const token = window.localStorage.getItem('autenticacao.token');

    useEffect(() => {
        if (!token) return;
        if (competitiveness) {
            history.push('/ipa/lite/gerenciador-de-precos');
            return;
        }

        getOnboardingSetup()
            .then(({ indirectCompetitivenessDTO: { competitiveness } }) => {
                if (competitiveness) {
                    setGerenciadorData((oldValue) => ({
                        ...oldValue,
                        competitiveness,
                    }));
                    history.push('/ipa/lite/gerenciador-de-precos');
                }
            })
            .catch(() => {
                history.push('/ipa/lite/onboarding');
            });
    }, [token]);

    useEffect(() => {
        setData((oldValue) => ({
            ...oldValue,
            stores: usuario?.servicoIpa.lojasIpa,
        }));
    }, [usuario]);

    return promiseInProgress ? (
        <LoadingSpinerLocal
            size="md"
            height="100%"
            loading={promiseInProgress}
            position={null}
        />
    ) : (
        <Layout className="ipa-lite-onboarding" fullScreen>
            <OnboardingLayout>
                <OnboardingLayout.Container>
                    <form onSubmit={handleSubmit}>
                        <Component />
                    </form>
                </OnboardingLayout.Container>
            </OnboardingLayout>
        </Layout>
    );
};
