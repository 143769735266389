import classNames from 'classnames';
import React from 'react';
import styles from './Chip.module.scss';

type ChipProps = {
    theme?: 'compact';
    filled?: boolean;
    active?: boolean;
    disabled?: boolean;
    leftIcon?: React.ReactNode;
    rightIcon?: React.ReactNode;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

const Chip = ({
    children,
    className,
    leftIcon,
    rightIcon,
    ...props
}: ChipProps) => (
    <button className={classNames(styles['info-chip'], className)} {...props}>
        {leftIcon && (
            <span className={styles['info-chip__icon']}>{leftIcon}</span>
        )}
        <span className={styles['info-chip__content']}>{children}</span>
        {rightIcon && (
            <span className={styles['info-chip__icon']}>{rightIcon}</span>
        )}
    </button>
);

export { Chip };
