import React from 'react';
import { Link, LinkProps } from 'react-router-dom';
import History from '../../../../../../utils/History';
import {
    Icon,
    IconTypes,
} from '../../../../RevisaoPrecos/Components/QuickActionFilter';
import './style.scss';

type DEFAULT_SIZES = 'large' | 'medium' | 'small' | 'xsmall';

export type LinkButtonProps = Omit<LinkProps, 'to'> & {
    icon?: IconTypes;
    size?: DEFAULT_SIZES;
    disabled?: boolean;
    to?: string;
};

export const LinkButton = ({
    children,
    icon,
    size = 'small',
    className = '',
    disabled,
    to = History.location.pathname,
    onClick,

    ...props
}: LinkButtonProps) => {
    return (
        <Link
            to={to}
            className={[
                'link-button',
                `link-button-${size}`,
                disabled && 'link-button-disabled',
                className,
            ].join(' ')}
            onClick={(e) => {
                onClick?.(e);
            }}
            {...props}
        >
            {icon && <Icon icon={icon} />}
            {children}
        </Link>
    );
};
