import { useContext } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getRaioXProdutosMaisVendidos } from '../services';
// @ts-ignore
import { FiltrosContextMargem } from '../Pages/MargemPraticadaGeral/index.tsx';
import { format } from 'date-fns';

export const useRaioXProdutosMaisVendidos = () => {
    const filtros = useContext(FiltrosContextMargem);

    const listarRaioXProdutosMaisVendidos = async () => {
        const params = {
            filters: [
                {
                    filter: 'STOREID',
                    values: filtros?.lojasSelecionadas || null,
                },
                {
                    filter: 'PRODUCTID',
                    values: filtros?.produtosSelecionados || null,
                },
                {
                    filter: 'STARTDATE',
                    values: filtros?.periodoSelecionado?.length
                        ? [format(filtros.periodoSelecionado[0], 'yyyy-MM-dd')]
                        : null,
                },
                {
                    filter: 'ENDDATE',
                    values: filtros?.periodoSelecionado?.length
                        ? [format(filtros.periodoSelecionado[1], 'yyyy-MM-dd')]
                        : null,
                },
                {
                    filter: 'STORETYPE',
                    values: filtros?.tipoLojasSelecionadas || null,
                },
                {
                    filter: 'CATEGORYLEVEL1',
                    values: filtros?.categoriaNivel1Selecionadas || null,
                },
                {
                    filter: 'CATEGORYLEVEL2',
                    values: filtros?.categoriaNivel2Selecionadas || null,
                },
            ],
        };

        const resp = await getRaioXProdutosMaisVendidos(params);
        if (resp.length) {
            const productId = Object.keys(resp[0])[0]?.split('.');
            const description = Object.keys(resp[0])[1]?.split('.');
            const avg_average_competitivity_retail = Object.keys(
                resp[0]
            )[2]?.split('.');
            const avg_average_competitivity_wholesale = Object.keys(
                resp[0]
            )[3]?.split('.');
            const realizedMargin = Object.keys(resp[0])[4]?.split('.');
            const sumSalesGross = Object.keys(resp[0])[5]?.split('.');
            return resp.map((item: { [x: string]: any }) => ({
                [productId[1]]: item[Object.keys(item)[0]],
                [description[1]]: item[Object.keys(item)[1]],
                [avg_average_competitivity_retail[1]]: item[
                    Object.keys(item)[2]
                ]
                    ? parseFloat(
                          // @ts-ignore
                          parseFloat(item[Object.keys(item)[2]]) * 100
                      )
                          .toFixed(2)
                          .replace('.', ',')
                    : null,
                [avg_average_competitivity_wholesale[1]]: item[
                    Object.keys(item)[3]
                ]
                    ? parseFloat(
                          // @ts-ignore
                          parseFloat(item[Object.keys(item)[3]]) * 100
                      )
                          .toFixed(2)
                          .replace('.', ',')
                    : null,
                [realizedMargin[1]]: item[Object.keys(item)[4]]
                    ? parseFloat(
                          // @ts-ignore
                          parseFloat(item[Object.keys(item)[4]]) * 100
                      )
                          .toFixed(2)
                          .replace('.', ',')
                    : null,
                [sumSalesGross[1]]: item[Object.keys(item)[5]]
                    ? parseFloat(item[Object.keys(item)[5]])?.toLocaleString(
                          'pt-BR',
                          {
                              style: 'currency',
                              currency: 'BRL',
                          }
                      )
                    : null,
            }));
        }
        return [];
    };

    const { isLoading, isError, data, error } = useQuery(
        ['listaRaioXProdutosMaisVendidos', filtros],
        listarRaioXProdutosMaisVendidos,
        {
            retry: false,
            cacheTime: 6 * 180 * 1000,
            staleTime: 6 * 180 * 1000,
        }
    );

    return {
        isLoading,
        isError,
        data,
        error,
    };
};
