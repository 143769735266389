import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { GerenciadorPrecos } from '../../@types/GerenciadorPrecos';
import { RootState } from '../../@types/RootState';

export type RowData = GerenciadorPrecos.RowData;

const initialState: RowData[] = [];

const slice = createSlice({
    name: 'ipa/gerenciador/datapoints',
    initialState,
    reducers: {
        ADD_GERENCIADOR_DATAPOINT: (
            state,
            { payload }: PayloadAction<RowData>,
        ) => {
            return [...state, payload];
        },
        SET_GERENCIADOR_DATAPOINTS: (
            _state,
            { payload }: PayloadAction<RowData[]>,
        ) => payload,
        UPDATE_GERENCIADOR_DATAPOINT_BY_INDEX: (
            state,
            {
                payload,
            }: {
                payload: {
                    index: number;
                    data: Partial<RowData>;
                };
            },
        ) => {
            state[payload.index] = {
                ...state[payload.index],
                ...payload.data,
            };
        },
        UPDATE_GERENCIADOR_DATAPOINT_BY_ID: (
            state,
            {
                payload,
            }: PayloadAction<{
                id: string;
                datapointExhibitionType?: string;
                data: Partial<RowData>;
            }>,
        ) => {
            const index = state.findIndex(
                (item) => item.productsToBePricedId === payload.id,
            );
            if (index >= 0) {
                state[index] = {
                    ...state[index],
                    ...payload.data
                };
            }
        },
        RESET_GERENCIADOR_DATAPOINTS: () => initialState,
        SET_GERENCIADOR_DATAPOINT_BY_INDEX: (
            state,
            { payload }: PayloadAction<{ index: number; rowData: RowData }>,
        ) => {
            state[payload.index] = payload.rowData;
        },
    },
});

export const selectorDatapoints = (state: RootState) => {
    return state.gerenciadorPrecosReducer.datapoints;
};

export const {
    RESET_GERENCIADOR_DATAPOINTS,
    ADD_GERENCIADOR_DATAPOINT,
    UPDATE_GERENCIADOR_DATAPOINT_BY_INDEX,
    SET_GERENCIADOR_DATAPOINTS,
    UPDATE_GERENCIADOR_DATAPOINT_BY_ID,
    SET_GERENCIADOR_DATAPOINT_BY_INDEX,
} = slice.actions;

export default slice.reducer;
