import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../@types/RootState';

export type GerenciadorPrecosSavedFilterProps = {
    filterName: string;
    data: {
        label: string;
        value: string;
        name: string;
    };
};

const initialState: GerenciadorPrecosSavedFilterProps = {
    filterName: '',
    data: {
        name: '',
        value: '',
        label: '',
    },
};

const slice = createSlice({
    name: '/ipa/gerenciador-de-precos/savedFilter',
    initialState,
    reducers: {
        SET_SAVED_FILTER(
            state,
            action: PayloadAction<{
                data: GerenciadorPrecosSavedFilterProps;
            }>,
        ) {
            return action.payload.data;
        },
        changeSavedFilter(
            state,
            action: PayloadAction<{
                key: keyof typeof initialState;
                data: GerenciadorPrecosSavedFilterProps['data'];
            }>,
        ) {
            state.data = action.payload.data;
        },
        setSavedFilterInputName(
            state,
            action: PayloadAction<{
                value: string;
            }>,
        ) {
            state.filterName = action.payload.value;
        },
        RESET_SAVED_FILTERS() {
            return initialState;
        },
    },
});

const selectorSavedFilters = (state: RootState) => {
    return state.gerenciadorPrecosReducer.savedFilter;
};

const {
    SET_SAVED_FILTER,
    changeSavedFilter,
    setSavedFilterInputName,
    RESET_SAVED_FILTERS,
} = slice.actions;

export {
    RESET_SAVED_FILTERS,
    SET_SAVED_FILTER,
    changeSavedFilter,
    selectorSavedFilters,
    setSavedFilterInputName,
};

export default slice.reducer;
