import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

import React from 'react';
import styles from './styles.module.scss';

export interface IShowStatus {
    status?: boolean,
}

export const ShowStatus = ({ status }:IShowStatus) => (
    <div className={styles['status-container']}>
        {status ? (
            <>
                <CheckCircleOutlineIcon className={styles['active-icon']} />
                <p className={styles['status-active-text']}>Ativo</p>
            </>
        ) : (
            <>
                <HighlightOffIcon className={styles['inactive-icon']} />
                <p className={styles['status-inactive-text']}>Inativo</p>
            </>
        )}
    </div>
  );
