import qs from 'qs';
import { trackPromise } from 'react-promise-tracker';
import { api as newApi } from '../lib';
import { getValidData } from '../pages/IPA/RevisaoPrecos/utils';
import { Segment } from '../reducers/gerenciadorPrecos/segmentos';
import api from '../utils/API';
import {
    ProdctsBrandResponseProps,
    SuppliersResponseProps,
} from './ProdutoServices.mock';

export async function getLastUpdate(): Promise<{
    lastExecutionDate: string;
    engineStatus: 'NOT_UPDATED' | 'UPDATED' | 'UPDATING';
}> {
    // @ts-ignore
    return api({
        url: `${process.env.REACT_APP_IPA_URL}/engine-history/last-execution`,
        method: 'GET',
        expectToken: true,
        validateStatus: false,
    }).then((resp) => resp.data);
}

export async function getProductBrandsData({
    search,
}: {
    search?: string;
}): Promise<ProdctsBrandResponseProps[]> {
    return trackPromise(
        // @ts-ignore
        api({
            url: `${process.env.REACT_APP_IPA_URL}/products/productBrands`,
            method: 'GET',
            expectToken: true,
            validateStatus: false,
            params: getValidData({
                search,
            }),
            paramsSerializer(params) {
                return qs.stringify(params, { arrayFormat: 'repeat' });
            },
        }).then((resp) => resp.data),
    );
}

export async function getProductSuppliersData({
    search,
}: {
    search?: string;
}): Promise<SuppliersResponseProps[]> {
    return trackPromise(
        // @ts-ignore
        api({
            url: `${process.env.REACT_APP_IPA_URL}/products/suppliers`,
            method: 'GET',
            expectToken: true,
            validateStatus: false,
            params: getValidData({
                search,
            }),
            paramsSerializer(params) {
                return qs.stringify(params, { arrayFormat: 'repeat' });
            },
        }).then((resp) => resp.data),
    );
}

export const getCattegories = async (): Promise<Segment[]> => {
    const res = await trackPromise(
        newApi.get<Segment[]>('/setup/categories', {
            params: {
                clientId: JSON.parse(localStorage.getItem('cliente') as string)
                    ?.codigo,
            },
            expectToken: true,
        }),
    );

    const sortedSegments = res.data.sort((a, b) => a.level - b.level);

    return sortedSegments.map((item) => ({
        ...item,
        label: item.name,
        value: item.field,
    }));
};
