import api from '../utils/API';
import { getUsuariosContrato } from './ContratoService';

function getNotAdminUsers() {
    let qtdUsers;

    getUsuariosContrato({ cliente: JSON.parse(localStorage.getItem('cliente'))?.codigo, query: '' }).then((data) => {
        qtdUsers = data.filter((item) => !item.administrador).length;
    });

    return qtdUsers;
}

function getAdminUsers() {
    let qtdUsers;

    getUsuariosContrato({ cliente: JSON.parse(localStorage.getItem('cliente'))?.codigo, query: '' }).then((data) => {
        qtdUsers = data.filter((item) => item.administrador).length;
    });

    return qtdUsers;
}

function mapParams(identifier, user) {
    return {
        firstname: JSON.parse(localStorage.getItem('usuario'))?.nome?.substring(0, JSON.parse(localStorage.getItem('usuario'))?.nome.indexOf(' ')),
        lastname: JSON.parse(localStorage.getItem('usuario'))?.nome?.substring(
            JSON.parse(localStorage.getItem('usuario'))?.nome.indexOf(' ') + 1,
            JSON.parse(localStorage.getItem('usuario'))?.nome.length,
        ),
        email: JSON.parse(localStorage.getItem('usuario'))?.email,
        rotulo_evento_do_produto: identifier,
        cliente_mpdv: user?.servicos?.some((item) => item.name === 'COL').toString(),
        cliente_ipa: user?.servicos?.some((item) => item.name === 'IPA').toString(),
        cliente_infopanel: user?.servicos?.some((item) => item.name === 'PAN').toString(),
        infopanel_plano: user?.servicoPanelCliente?.pacotePainel?.description,
        user_admin: user?.usuarioAdministrador.toString(),
        usuarios_admin: user?.usuarioAdministrador ? getAdminUsers()?.toString() : null,
        qtde_users_nao_admin_cadastrados: user?.usuarioAdministrador ? getNotAdminUsers()?.toString() : null,
        organizacao_produto: JSON.parse(localStorage.getItem('cliente'))?.organizacao,
    };
}

export const lead = (identificador, usuario) => api({
    url: `${process.env.REACT_APP_CLIENTE_URL}/lead`,
    method: 'POST',
    data: JSON.stringify(mapParams(identificador, usuario)),
}).then((resp) => resp.data);

export const leadFree = (data) => api({
    url: `${process.env.REACT_APP_CLIENTE_URL}/lead`,
    method: 'POST',
    data: JSON.stringify(data),
}).then((resp) => resp.data);
