import React from 'react';
import ReactDOM from 'react-dom';
import { MatomoProvider, createInstance } from '@datapunt/matomo-tracker-react';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import './style/index.scss';
import { Provider } from 'react-redux';
import { IntlProvider } from 'rsuite';
import ptBR from 'rsuite/lib/IntlProvider/locales/pt_BR';
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import App from './App';
import * as serviceWorker from './serviceWorker';
import store from './store/index';
import packageJson from '../package.json';

import 'rsuite/dist/styles/rsuite-default.css';

const instance = createInstance(
    {
        urlBase: 'https://matomo.infoprice.co/',
        trackerUrl: 'https://matomo.infoprice.co/matomo.php',
        srcUrl: 'https://matomo.infoprice.co/matomo.js',
        siteId: 2,
        linkTracking: false,
    },
);

const theme = createTheme({
    palette: {
      primary: {
        main: '#378EF0',
      },
    },
    typography: {
        fontFamily: 'Open Sans',
      },
  });

console.log(`------ Versão do Front-End: v${packageJson.version} ------`);

ReactDOM.render(
    <ThemeProvider theme={theme}>
        <Provider store={store}>
            <MatomoProvider value={instance}>
                <IntlProvider locale={ptBR}>
                    <App />
                </IntlProvider>
            </MatomoProvider>
        </Provider>
    </ThemeProvider>,
    document.getElementById('root'),
);

serviceWorker.unregister();
