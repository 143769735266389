/* eslint-disable no-underscore-dangle */
export function getUserDimensions(user) {
    return [
        {
            instructionType: 'setCustomDimension',
            dimensionId: 1,
            dimensionValue: JSON.parse(localStorage.getItem('cliente'))?.nome || null,
        },
        {
            instructionType: 'setCustomDimension',
            dimensionId: 2,
            dimensionValue: user?.contrato?.cliente?.organizacao?.nome,
        },
        {
            instructionType: 'setCustomDimension',
            dimensionId: 3,
            dimensionValue: user?.servicosCliente.some((item) => item.name === 'PAN'),
        },
        {
            instructionType: 'setCustomDimension',
            dimensionId: 4,
            dimensionValue: user?.servicoPanelCliente?.pacotePainel?.description,
        },
        {
            instructionType: 'setCustomDimension',
            dimensionId: 5,
            dimensionValue: user?.servicoPanelCliente?.trial,
        },
    ];
}

export function trackEvent(category, action, name) {
    window._paq.push(['trackEvent', category, action, name]);
}
