/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-underscore-dangle */
import React, { useRef, useEffect, useState } from 'react';
import { DateRangePicker } from 'rsuite';
import { useHistory } from 'react-router-dom';
import {
    Container,
    Typography,
    Grid,
    Card,
    Button,
    CardContent,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    InputAdornment,
    IconButton,
    Chip,
    TableContainer,
    Checkbox,
    ListItemText,
    TablePagination,
    Divider,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { withStyles } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import InfoIcon from '@material-ui/icons/Info';
import SearchIcon from '@material-ui/icons/Search';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import ErrorIcon from '@material-ui/icons/Error';
import SettingsIcon from '@material-ui/icons/Settings';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import CloseIcon from '@material-ui/icons/Close';
import { format, toDate } from 'date-fns';
import localeBr from 'date-fns/locale/pt';
import { LoadingSpiner } from '../../../components/LoadingSpinner';
import {
    getTokenByEmbedType,
    saveUpload,
    listUploads,
    downloadItemOriginal,
    downloadItemFinal,
} from './services';

const { flatfileImporter } = window;

const CustomChip = withStyles({
    icon: {
        color: '#fff',
    },
})(Chip);

const UploadArquivosPage = () => {
    const importerRef = useRef();
    const datePickerRef = useRef();
    const history = useHistory();

    const [uploadToken, setUploadToken] = useState('');
    const [uploadStatus, setUploadStatus] = useState(null);
    const [dataTable, setDataTable] = useState({
        content: [],
        totalElements: 0,
        size: 20,
    });
    const [searchParams, setSearchParams] = useState({
        userName: '',
        embedType: [],
        startDate: '',
        endingDate: '',
    });
    const [pagination, setPagination] = useState({
        size: 10,
        page: 0,
    });
    const [selectedDate, setSelectedDate] = useState([]);
    const [formattedDates, setFormattedDates] = useState('');
    const embedTypes = [
        {
            label: 'Histórico de vendas e custos',
            value: 'VENDAS_AGREGADAS',
        },
        { label: 'Preços', value: 'PRECO_CLIENTE' },
        { label: 'Concorrência', value: 'CONCORRENCIA' },
        { label: 'Loja Concorrente', value: 'LOJA_CONCORRENTE' },
        { label: 'Produto Cliente', value: 'PRODUTO_CLIENTE' },
        { label: 'Loja Cliente', value: 'LOJA_CLIENTE' },
    ];
    const [embedType, setEmbedType] = useState('');

    const handleChangeParams = (prop) => (event) => {
        setSearchParams({ ...searchParams, [prop]: event.target.value });
    };

    useEffect(() => {
        if (selectedDate.length > 0) {
            setFormattedDates(
                `${format(toDate(selectedDate[0]), 'dd/MM/yyyy')} - ${format(
                    toDate(selectedDate[selectedDate.length - 1]),
                    'dd/MM/yyyy',
                )}`,
            );
            setSearchParams({
                ...searchParams,
                startDate: selectedDate[0],
                endingDate: selectedDate[selectedDate.length - 1],
            });
        }
    }, [selectedDate]);

    async function getUploadsList(params) {
        const resp = await listUploads(params, pagination);
        if (resp?.content?.length > 0) {
            const tableData = resp.content.map((item) => ({
                ...item,
                creationDate: format(new Date(item.createdAt), 'dd/MM/yyyy', {
                    locale: localeBr,
                }),
                creationTime: format(new Date(item.createdAt), 'HH:mm', {
                    locale: localeBr,
                }),
                embedType: embedTypes.find(
                    (embed) => embed.value === item.embedType,
                ).label,
            }));
            setDataTable({ ...resp, content: tableData });
        }
    }

    async function searchItems() {
        const params = Object.keys(searchParams)
            .filter((k) => searchParams[k])
            .reduce((a, k) => ({ ...a, [k]: searchParams[k] }), {});
        if (params.startDate) {
            params.startDate = format(new Date(params.startDate), 'yyyy-MM-dd');
        }
        if (params.endingDate) {
            params.endingDate = format(
                new Date(params.endingDate),
                'yyyy-MM-dd',
            );
        }
        await getUploadsList(params);
    }

    useEffect(() => {
        getUploadsList();
    }, []);

    useEffect(() => {
        async function getUploadToken() {
            const resp = await getTokenByEmbedType(embedType);
            setUploadToken(resp?.jwt);
        }
        if (embedType) getUploadToken();
    }, [embedType]);

    useEffect(() => {
        if (uploadStatus >= 200 && uploadStatus < 300) getUploadsList();
    }, [uploadStatus]);

    useEffect(() => {
        searchItems();
    }, [searchParams, pagination]);

    const handleUpload = async () => {
        const importer = flatfileImporter(uploadToken);

        importer.on('error', (error) => {
            console.error(error);
        });
        importer.on('complete', async (payload) => {
            const resp = await saveUpload({
                batchId: payload.batchId,
                clientId: JSON.parse(localStorage.getItem('cliente'))?.codigo,
            });
            console.log('FINALIZADO!', resp);
            setUploadStatus(resp.status);
        });

        const { batchId } = await importer.launch();

        console.log(`${batchId} has been launched.`);

        importerRef.current = importer;
    };

    const menuProps = {
        getContentAnchorEl: null,
        anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
        },
    };

    const openDatePicker = () => {
        datePickerRef?.current?.open();
    };

    const backPage = () => {
        history.goBack();
    };

    return (
        <Container
            className="main-container upload-arquivos-page"
            maxWidth="xl"
        >
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Button onClick={backPage} startIcon={<ArrowBackIcon />} className="back-btn">
                        Voltar
                    </Button>
                </Grid>
                <Grid item xl={3} lg={4} md={12} sm={12} xs={12}>
                    <Card className="upload-arquivos-modelo-card" elevation={0}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Typography variant="subtitle1">
                                    Modelo de arquivo
                                </Typography>
                                <Typography variant="body1">
                                    Escolha que modelo de arquivo você quer
                                    enviar
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl
                                    variant="outlined"
                                    fullWidth
                                    size="small"
                                >
                                    <InputLabel id="select-modelo-upload-label">
                                        Selecione um modelo
                                    </InputLabel>
                                    <Select
                                        className="upload-arquivos-modelo-select"
                                        labelId="select-modelo-upload-label"
                                        id="select-modelo-upload"
                                        value={embedType}
                                        onChange={(event) => setEmbedType(event.target.value)}
                                        label="Selecione um modelo"
                                        placeholder="Selecione um modelo"
                                        MenuProps={menuProps}
                                    >
                                        {embedTypes.map((type) => (
                                            <MenuItem
                                                key={type.value}
                                                value={type.value}
                                            >
                                                {type.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            {embedType ? (
                                <Grid
                                    className="upload-exemplo-download"
                                    item
                                    xs={12}
                                >
                                    <div style={{ height: '48px' }} />
                                    {/* <Typography variant="body1">
                                        Para ter a melhor experiência de envio
                                        de arquivo,
                                        <br />
                                        {' '}
                                        baixe aqui o exemplo de planilha
                                        de
                                        {' '}
                                        <strong>
                                            Histórico de
                                            <br />
                                            {' '}
                                            Vendas e Custos.
                                        </strong>
                                    </Typography> */}
                                </Grid>
                            ) : null}
                        </Grid>
                    </Card>
                </Grid>
                <Grid item xl={9} lg={8} md={12} sm={12} xs={12}>
                    <Card
                        className="upload-arquivos-modelo-upload"
                        elevation={0}
                    >
                        {!embedType ? (
                            <Paper
                                className="upload-sem-modelo-info"
                                elevation={0}
                            >
                                <Grid
                                    container
                                    direction="column"
                                    justifyContent="center"
                                    alignItems="center"
                                >
                                    <Grid item sz={12}>
                                        <InfoIcon
                                            style={{
                                                fontSize: 24,
                                                color: '#2680EB',
                                            }}
                                        />
                                    </Grid>
                                    <Grid item sz={12}>
                                        <Typography variant="body2">
                                            Para enviar o arquivo, primeiro
                                            <br />
                                            <span fontWeight="fontWeightBold">
                                                selecione o modelo de arquivo
                                            </span>
                                            {' '}
                                            que você quer enviar
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Paper>
                        ) : (
                            <Grid className="upload-info" container spacing={3}>
                                <Grid item xs={12}>
                                    <Typography variant="subtitle1">
                                        {
                                            embedTypes.find(
                                                (embed) => embed.value === embedType,
                                            ).label
                                        }
                                    </Typography>
                                    <Typography variant="body1">
                                        Você pode enviar qualquer arquivo
                                        {' '}
                                        <strong>
                                            .csv, .tsv, .xls, .xlsx e .xml
                                        </strong>
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Button
                                        className="upload-arquivo-botao"
                                        variant="contained"
                                        color="primary"
                                        size="large"
                                        disableElevation
                                        onClick={handleUpload}
                                    >
                                        Enviar arquivo
                                    </Button>
                                </Grid>
                                <Grid item xs={12}>
                                    <Alert
                                        className="upload-arquivos-modelo-info"
                                        icon={<InfoIcon fontSize="inherit" />}
                                        severity="info"
                                    >
                                        O arquivo enviado pode possuir qualquer
                                        conjunto de colunas desde que haja 1
                                        registro por linha. A próxima etapa
                                        permitirá que você faça a
                                        correspondência das colunas da planilha
                                        com os dados corretos. Você também
                                        poderá limpar ou remover dados
                                        corrompidos antes de finalizar o
                                        processo.
                                    </Alert>
                                </Grid>
                            </Grid>
                        )}
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <Card elevation={0} className="upload-arquivos-tabela">
                        <Typography variant="subtitle1">
                            Arquivos anteriores
                        </Typography>
                        <Grid container spacing={3}>
                            <Grid item container xs={12} spacing={2}>
                                <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
                                    <TextField
                                        label="Responsável"
                                        id="input-responsavel"
                                        fullWidth
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <SearchIcon
                                                        style={{
                                                            fontSize: 16,
                                                            color: '#959595',
                                                        }}
                                                    />
                                                </InputAdornment>
                                            ),
                                        }}
                                        value={searchParams.userName}
                                        onChange={handleChangeParams(
                                            'userName',
                                        )}
                                    />
                                </Grid>
                                <Grid item xl={2} lg={2} md={3} sm={6} xs={12}>
                                    <FormControl fullWidth>
                                        <InputLabel id="input-modelo-do-arquivo-label">
                                            Modelo do Arquivo
                                        </InputLabel>
                                        <Select
                                            label="Modelo do Arquivo"
                                            labelId="input-modelo-do-arquivo-label"
                                            id="input-modelo-do-arquivo"
                                            multiple
                                            value={searchParams.embedType}
                                            onChange={handleChangeParams(
                                                'embedType',
                                            )}
                                            IconComponent={() => (
                                                <KeyboardArrowDownIcon
                                                    style={{
                                                        fontSize: 16,
                                                        color: '#959595',
                                                    }}
                                                />
                                            )}
                                            renderValue={(selected) => (
                                                <div>
                                                    {selected.map((value) => (
                                                        <Chip
                                                            key={value}
                                                            label={
                                                                embedTypes.find(
                                                                    (embed) => embed.value
                                                                        === value,
                                                                ).label
                                                            }
                                                            size="small"
                                                        />
                                                    ))}
                                                </div>
                                            )}
                                            MenuProps={menuProps}
                                        >
                                            {embedTypes.map((type) => (
                                                <MenuItem
                                                    key={type.value}
                                                    value={type.value}
                                                >
                                                    <Checkbox
                                                        color="primary"
                                                        checked={
                                                            searchParams.embedType.indexOf(
                                                                type.value,
                                                            ) > -1
                                                        }
                                                    />
                                                    <ListItemText
                                                        primary={type.label}
                                                    />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xl={2} lg={3} md={3} sm={6} xs={12}>
                                    <DateRangePicker
                                        id="seletor-range-data"
                                        ref={datePickerRef}
                                        value={selectedDate}
                                        onChange={(value) => setSelectedDate(value)}
                                        format="YYYY-MM-DD"
                                        appearance="subtle"
                                        style={{
                                            width: 0,
                                            height: 0,
                                            background: '#fff',
                                            color: '#fff',
                                        }}
                                        locale={{
                                            sunday: 'Dom',
                                            monday: 'Seg',
                                            tuesday: 'Ter',
                                            wednesday: 'Qua',
                                            thursday: 'Qui',
                                            friday: 'Sex',
                                            saturday: 'Sáb',
                                            ok: 'OK',
                                            today: 'Hoje',
                                            yesterday: 'Ontem',
                                            last7Days: 'Últimos 7 dias',
                                        }}
                                    />
                                    <TextField
                                        label="Data de envio"
                                        id="input-data-de-envio"
                                        fullWidth
                                        value={formattedDates}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    {formattedDates
                                                        && selectedDate ? (
                                                        <>
                                                            <IconButton
                                                                aria-label="deleção de datas selecionadas"
                                                                size="small"
                                                                onClick={() => {
                                                                    setSelectedDate(
                                                                        [],
                                                                    );
                                                                    setFormattedDates(
                                                                        '',
                                                                    );
                                                                }}
                                                            >
                                                                <CloseIcon
                                                                    style={{
                                                                        fontSize: 16,
                                                                        color: '#959595',
                                                                    }}
                                                                />
                                                            </IconButton>
                                                            <Divider
                                                                orientation="vertical"
                                                                style={{
                                                                    height: 18,
                                                                    margin: 4,
                                                                }}
                                                            />
                                                        </>
                                                    ) : null}

                                                    <IconButton
                                                        aria-label="selecao de range de data"
                                                        onClick={openDatePicker}
                                                        size="small"
                                                    >
                                                        <CalendarTodayIcon
                                                            style={{
                                                                fontSize: 16,
                                                                color: '#959595',
                                                            }}
                                                        />
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                            readOnly: true,
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <LoadingSpiner size="md" />
                            <Grid item xs={12}>
                                <Card variant="outlined">
                                    <CardContent className="upload-arquivos-tabela-content">
                                        <TableContainer>
                                            <Table aria-label="tabela arquivos flatfile">
                                                <TableHead className="upload-arquivos-tabela-header">
                                                    <TableRow>
                                                        <TableCell>
                                                            Título do arquivo
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            Data
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            Horário
                                                        </TableCell>
                                                        <TableCell>
                                                            Responsável
                                                        </TableCell>
                                                        <TableCell>
                                                            Modelo
                                                        </TableCell>
                                                        <TableCell>
                                                            Estado
                                                        </TableCell>
                                                        <TableCell />
                                                        <TableCell />
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {dataTable?.content?.map(
                                                        (row) => (
                                                            <TableRow
                                                                key={
                                                                    row.creationTime
                                                                }
                                                            >
                                                                <TableCell
                                                                    component="th"
                                                                    scope="row"
                                                                >
                                                                    {
                                                                        row.fileTitle
                                                                    }
                                                                </TableCell>
                                                                <TableCell align="right">
                                                                    {
                                                                        row.creationDate
                                                                    }
                                                                </TableCell>
                                                                <TableCell align="right">
                                                                    {
                                                                        row.creationTime
                                                                    }
                                                                </TableCell>
                                                                <TableCell>
                                                                    {
                                                                        row.userName
                                                                    }
                                                                </TableCell>
                                                                <TableCell>
                                                                    {
                                                                        row.embedType
                                                                    }
                                                                </TableCell>
                                                                <TableCell>
                                                                    <CustomChip
                                                                        className={
                                                                            row.uploadStatus
                                                                        }
                                                                        size="small"
                                                                        label={
                                                                            row.uploadStatus
                                                                                === 'SENT'
                                                                                ? 'Enviado'
                                                                                : row.uploadStatus
                                                                                    === 'SENDING'
                                                                                    ? 'Processando'
                                                                                    : 'Erro'
                                                                        }
                                                                        icon={
                                                                            row.uploadStatus
                                                                                === 'SENT' ? (
                                                                                <CheckCircleIcon />
                                                                            ) : row.uploadStatus
                                                                                === 'SENDING' ? (
                                                                                <SettingsIcon />
                                                                            ) : row.uploadStatus
                                                                                === 'ERROR' ? (
                                                                                <ErrorIcon />
                                                                            ) : (
                                                                                ''
                                                                            )
                                                                        }
                                                                    />
                                                                </TableCell>
                                                                <TableCell>
                                                                    <Button
                                                                        className="botao-download-arquivo"
                                                                        disabled={
                                                                            row.uploadStatus
                                                                            === 'ERROR'
                                                                        }
                                                                        onClick={() => downloadItemOriginal(
                                                                            row.batchId,
                                                                            row.fileTitle,
                                                                        )}
                                                                        startIcon={
                                                                            <CloudDownloadIcon fontSize="small" />
                                                                        }
                                                                    >
                                                                        Download
                                                                        original
                                                                    </Button>
                                                                </TableCell>
                                                                <TableCell>
                                                                    <Button
                                                                        className="botao-download-arquivo"
                                                                        disabled={
                                                                            row.uploadStatus
                                                                            !== 'SENT'
                                                                        }
                                                                        onClick={() => downloadItemFinal(
                                                                            row.batchId,
                                                                            row.fileTitle,
                                                                        )}
                                                                        startIcon={
                                                                            <CloudDownloadIcon fontSize="small" />
                                                                        }
                                                                    >
                                                                        Download
                                                                        validado
                                                                    </Button>
                                                                </TableCell>
                                                            </TableRow>
                                                        ),
                                                    )}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                        <TablePagination
                                            rowsPerPageOptions={[
                                                10, 15, 20, 25,
                                            ]}
                                            labelRowsPerPage="Items por página"
                                            labelDisplayedRows={({
                                                from,
                                                to,
                                                count,
                                            }) => `${from}-${to} de ${count !== -1
                                                ? count
                                                : `mais de ${to}`
                                            }`}
                                            component="div"
                                            count={dataTable.totalElements}
                                            rowsPerPage={pagination.size}
                                            page={pagination.page}
                                            onPageChange={(value) => setPagination({
                                                ...pagination,
                                                page: value - 1,
                                            })}
                                            onRowsPerPageChange={(event) => setPagination({
                                                ...pagination,
                                                size: parseInt(
                                                    event.target.value,
                                                    10,
                                                ),
                                                page: 0,
                                            })}
                                        />
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
            </Grid>
        </Container>
    );
};

export default UploadArquivosPage;
