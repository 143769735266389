import React from 'react';
import {
    CalculadoraPrevisaoDemandaFiltrosStateProps,
} from '../../../../reducers/calculadoraPrevisaoDemanda/filtros';
import { LoadingMenu } from '../../../../components/LoadingSpinner';
import SelectPill from '../../../../components/SelectPill';
import arrowRightIcon from '../../../../assets/icons/icon_arrow-right-gray.svg';
import styles from './LocalFilter.module.scss';

type LocalFilterProps = {
    localHandlers?: any;
    filters?: CalculadoraPrevisaoDemandaFiltrosStateProps;
}

const localData = [
    {
        label: 'UF',
        value: 'UF',
    },
    {
        label: 'Regional',
        value: 'REGIONAL',
    },
];

export const LocalFilter = ({
    localHandlers,
    filters,
}: LocalFilterProps) => {
    return (
        <div className={styles['select-pill-wrapper']}>
            <SelectPill
                type="select"
                id="select-picker-pill"
                filter={false}
                searchable={false}
                placeholder="Local"
                name="second"
                value={filters?.filterRequestBody.location_type}
                data={localData}
                onSelect={localHandlers.handleSelectLocatioType}
                onClean={localHandlers.handleClearLocatioType}
            />
            {/* <div className={styles['div-pill']}>UF</div> */}

            { filters?.filterRequestBody.location_type === 'UF' || filters?.filterRequestBody.location_type === 'REGIONAL' ? (
                <img
                    src={arrowRightIcon}
                    alt=""
                    className={styles['arrow-right-icon']}
                />
            ) : null}
            {filters?.filterRequestBody.location_type === 'UF' ? (
                <SelectPill
                    type="select"
                    id="select-picker-pill"
                    placeholder="Selecione uma UF"
                    name="second"
                    value={filters?.uf.value[0] || null}
                    data={filters?.uf.list}
                    onSearch={(value) => localHandlers.onSearchLocal('UF', value)}
                    onSelect={(value, item) => localHandlers.handleSelectLocal('uf', value, item)}
                    onOpen={() => localHandlers.onSearchLocal('UF', '')}
                    onClean={() => localHandlers.handleClearLocal('uf')}
                    locale={{
                        noResultsText: 'Nenhum item encontrado',
                        searchPlaceholder: 'Buscar item',
                    }}
                    renderMenu={(menu) => (
                        <span>
                            <LoadingMenu area="scenario-filter" />
                            {menu}
                        </span>
                    )}
                />

            ) : null}
            {filters?.filterRequestBody.location_type === 'REGIONAL' ? (
                <SelectPill
                    type="select"
                    id="select-picker-pill"
                    placeholder="Selecione uma Regional"
                    name="second"
                    value={filters?.regional.value[0] || null}
                    data={filters?.regional.list}
                    onSearch={(value) => localHandlers.onSearchLocal('REGIONAL', value)}
                    onSelect={(value, item) => localHandlers.handleSelectLocal('regional', value, item)}
                    onOpen={() => localHandlers.onSearchLocal('REGIONAL', '')}
                    onClean={() => localHandlers.handleClearLocal('regional')}
                    locale={{
                    noResultsText: 'Nenhum item encontrado',
                    searchPlaceholder: 'Buscar item',
                }}
                    renderMenu={(menu) => (
                        <span>
                            <LoadingMenu area="scenario-filter" />
                            {menu}
                        </span>
                )}
                />
            ) : null}
        </div>
    );
};
