import React, { useState, useEffect } from 'react';
import { Content, Container, Header } from 'rsuite';
import { getDashboardLockedStores } from './services';
import { LoadingSpiner } from '../../../../components/LoadingSpinner';

const LockedStores = () => {
    const [dashboardUrl, setDashboardUrl] = useState(null);

    async function getDashboard() {
        const response = await getDashboardLockedStores();
        if (response?.url) {
            setDashboardUrl(response.url);
        }
    }

    useEffect(() => {
        getDashboard();
    }, []);

    return (
        <Container className="main-container locked-stores-container">
            <LoadingSpiner size="md" />
            <Header className="locked-stores-title-container">
                <h4 className="content-title">Lojas Bloqueadas</h4>
                <p className="content-subtitle">
                    Consulte as lojas no seu escopo de pesquisa que
                    apresentam algum bloqueio no momento.
                </p>
            </Header>
            <Content className="locked-stores-content">
                <div className="locked-stores-iframe-container">
                    <iframe
                        title="Lojas bloqueadas"
                        src={dashboardUrl}
                        frameBorder="0"
                    />
                </div>
            </Content>
        </Container>
    );
};

export default LockedStores;
