import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { GerenciadorPrecos } from '../../@types/GerenciadorPrecos';
import { RootState } from '../../@types/RootState';

export type ContextMenuState = GerenciadorPrecos.RowData | null;

const initialState: ContextMenuState = null as ContextMenuState;

const slice = createSlice({
    name: 'ipa/gerenciador/contextMenu',
    initialState,
    reducers: {
        SET_GERENCIADOR_CONTEXT_MENU: (
            state,
            { payload }: PayloadAction<ContextMenuState>,
        ) => {
            return payload as typeof state;
        },
    },
});

const { SET_GERENCIADOR_CONTEXT_MENU } = slice.actions;

const selectorContextMenu = (state: RootState) => {
    return state.gerenciadorPrecosReducer.contextMenu;
};

export { SET_GERENCIADOR_CONTEXT_MENU, initialState, selectorContextMenu };

export default slice.reducer;
