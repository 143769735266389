import axios from 'axios';
import { trackPromise } from 'react-promise-tracker';

import api from '../../../../utils/API';

let cancelTokenFiltros;

export const getStoreByCnpj = (cnpj) => trackPromise(
    api({
        url: `${process.env.REACT_APP_CADASTROS_API_URL}/lojas/dto/cnpj`,
        method: 'GET',
        params: {
            cnpj,
            organizacao: 1,
        },
        expectToken: true,
    }).then((res) => res.data),
    'lojas-by-bounds',
);

export const getStoresByFilter = (data) => trackPromise(
    api({
        url: `${process.env.REACT_APP_CLIENTE_URL}/analise_lojas/filters`,
        method: 'POST',
        data,
        expectToken: true,
    }).then((res) => res.data),
    'lojas-by-bounds',
);

export const getStoresByBounds = (data) => trackPromise(
    api({
        url: `${process.env.REACT_APP_CLIENTE_URL}/analise_lojas/latlong/box`,
        method: 'POST',
        data,
        expectToken: true,
    }).then((res) => res.data),
    'lojas-by-bounds',
);

export const getCompetitors = (cnpj) => trackPromise(
    api({
        url: `${process.env.REACT_APP_SCHRUBLES_PROXY_URL}/lojas-concorrentes`,
        method: 'GET',
        params: {
            cnpj,
            avoidSameStore: true,
        },
        expectToken: true,
    }).then((res) => res.data),
    'lojas-by-bounds',
);

export const getInputFilter = (query) => trackPromise(
    api({
        url: `${process.env.REACT_APP_CADASTROS_API_URL}/lojas/findLojaAndEndereco`,
        method: 'GET',
        params: {
            query,
            size: 30,
        },
        expectToken: true,
    }).then((res) => res.data),
    'search-store-input',
);

export const getPlace = (data) => api({
    url: `${process.env.REACT_APP_CLIENTE_URL}/location/place`,
    method: 'GET',
    params: {
        place_id: data.place_id ? data.place_id : null,
        session_token: data.token ? data.token : null,
    },
}).then((resp) => resp.data);

export const getItensFiltros = (data) => {
    if (typeof cancelTokenFiltros !== typeof undefined) {
        cancelTokenFiltros.cancel('Operation canceled due to new request.');
    }

    cancelTokenFiltros = axios.CancelToken.source();

    return trackPromise(
        api({
            url: `${process.env.REACT_APP_CLIENTE_URL}/painel_geral/filtros`,
            method: 'POST',
            data: JSON.stringify(data),
            cancelToken: cancelTokenFiltros.token,
            expectToken: true,
        }).then((resp) => resp.data),
        'panel-filter',
    );
};

export const getEndereco = (data) => trackPromise(
    api({
        url: `${process.env.REACT_APP_CLIENTE_URL}/location/autocomplete/restricted`,
        method: 'GET',
        expectToken: true,
        params: {
            query: data.query ? data.query : null,
            session_token: data.token ? data.token : null,
        },
    }).then((resp) => resp.data),
);

export const getChainsCompetitors = (store) => trackPromise(
    api({
        url: `${process.env.REACT_APP_CLIENTE_URL}/analise_lojas/rede`,
        method: 'GET',
        expectToken: true,
        params: {
            uf: store.uf,
            rede: store.rede,
        },
    }).then((resp) => resp?.data),
);

export const getDownloadCTopTenRedes = (store, extension) => trackPromise(
    api({
        url: `${process.env.REACT_APP_CLIENTE_URL}/analise_lojas/rede/download`,
        method: 'POST',
        expectToken: true,
        params: {
            uf: store.uf,
            rede: store.rede,
            extension,
        },
        responseType: 'arraybuffer',
    })
        .then((resp) => resp)
        .then((download) => {
            if (download.status === 200) {
                const blob = new Blob([download.data]);
                const hiddenElement = document.createElement('a');
                hiddenElement.href = window.URL.createObjectURL(blob);
                hiddenElement.target = '_blank';
                hiddenElement.download = `top_10_redes_concorrentes.${extension}`;
                hiddenElement.click();
            } else {
                return download;
            }
        }),
    'lojas-by-bounds',
);

export const getDownloadCompetitorsStorage = (cnpj, extension) => trackPromise(
    api({
        url: `${process.env.REACT_APP_CLIENTE_URL}/analise_lojas/download`,
        method: 'POST',
        expectToken: true,
        params: {
            cnpj,
            extension,
            avoidSameStore: true,
        },
        responseType: 'arraybuffer',
    }).then((resp) => resp)
        .then((download) => {
            if (download.status === 200) {
                const blob = new Blob([download.data]);
                const hiddenElement = document.createElement('a');
                hiddenElement.href = window.URL.createObjectURL(blob);
                hiddenElement.target = '_blank';
                hiddenElement.download = `top_10_lojas_concorrentes.${extension}`;
                hiddenElement.click();
            } else {
                return download;
            }
        }),
    'lojas-by-bounds',
);
