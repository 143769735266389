import React from 'react';
import {
    Button,
    Dropdown,
    Popover,
    Table, Whisper,
} from 'rsuite';
import threeDotsVerticalIcon from '../../../../../assets/icons/icon_three_dots_vertical_gray.svg';
import { dailyFrequencyEnumsType, handleDateFormat } from '../utils';

const { Column, HeaderCell, Cell } = Table;
const { Pagination } = Table;

const MenuPopover = ({ onSelect, ...rest }) => (
    <Popover {...rest} full>
        <Dropdown.Menu onSelect={onSelect} style={{ width: '196px' }}>
            <Dropdown.Item eventKey={1}>Editar</Dropdown.Item>
            <Dropdown.Item eventKey={2}>Excluir</Dropdown.Item>
            <Dropdown.Item eventKey={3}>Gerar relatório agora</Dropdown.Item>
        </Dropdown.Menu>
    </Popover>
);

const SavedReportTable = ({
    data,
    setPage,
    loading,
    onSelectThreeDotButtonOption,
}) => {
    const handleChangePage = (value) => {
        setPage(value - 1);
    };

    return (
        <>
            <Table
                className="saved-report-table"
                autoHeight
                data={data?.content}
                bordered
                loading={loading}
            >
                <Column flexGrow={2}>
                    <HeaderCell>Nome do relatório</HeaderCell>
                    <Cell dataKey="fileName" />
                </Column>
                <Column flexGrow={2}>
                    <HeaderCell>Recorrência</HeaderCell>
                    <Cell>
                        {(rowData) => (
                            <span>
                                {rowData.reportEngineDTO.recorrencia
                                    ? `${dailyFrequencyEnumsType[
                                    rowData.reportEngineDTO
                                        .reportEngineRecorrenciaDTO
                                        .recorrencia
                                    ]
                                    }, ${rowData.reportEngineDTO.reportEngineRecorrenciaDTO.diasRecorrencia
                                        .map(
                                            (item) => dailyFrequencyEnumsType[item],
                                        )
                                        .join(', ')}`
                                    : 'Não se repete'}
                            </span>
                        )}
                    </Cell>
                </Column>
                <Column flexGrow={1}>
                    <HeaderCell>Data início</HeaderCell>
                    <Cell>
                        {(rowData) => (
                            <span>
                                {rowData.reportEngineDTO?.reportEngineRecorrenciaDTO?.dataInicioEnvio ? handleDateFormat(
                                    rowData.reportEngineDTO
                                        .reportEngineRecorrenciaDTO
                                        .dataInicioEnvio,
                                ) : null}
                            </span>
                        )}
                    </Cell>
                </Column>
                <Column flexGrow={1}>
                    <HeaderCell>Última alteração</HeaderCell>
                    <Cell>
                        {(rowData) => (
                            <span>
                                {handleDateFormat(rowData.dataInicioGeracao)}
                            </span>
                        )}
                    </Cell>
                </Column>
                <Column flexGrow={1}>
                    <HeaderCell>Último relatório</HeaderCell>
                    <Cell>
                        {(rowData) => (
                            <span>
                                {typeof rowData.dataFimGeracao === 'string'
                                    ? handleDateFormat(rowData.dataFimGeracao)
                                    : '-'}
                            </span>
                        )}
                    </Cell>
                </Column>
                <Column>
                    <HeaderCell />
                    <Cell className="popup-cell">
                        {(rowData) => (
                            <Whisper
                                placement="bottomEnd"
                                trigger="click"
                                speaker={(
                                    <MenuPopover
                                        onSelect={(key) => onSelectThreeDotButtonOption(
                                            key,
                                            rowData,
                                        )}
                                    />
                                )}
                            >
                                <Button>
                                    <img src={threeDotsVerticalIcon} alt="" />
                                </Button>
                            </Whisper>
                        )}
                    </Cell>
                </Column>
            </Table>
            {data ? (
                <Pagination
                    className="saved-report-pagination"
                    activePage={data.number + 1}
                    displayLength={data?.size}
                    showLengthMenu={false}
                    total={data?.totalElements}
                    onChangePage={handleChangePage}
                    renderTotal={() => `Exibindo ${data?.content?.length} de ${data?.totalElements} solicitações.`}
                />
            ) : null}
        </>
    );
};

export default SavedReportTable;
