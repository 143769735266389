import classNames from 'classnames';
import React from 'react';
import styles from './InputGroup.module.scss';

export type InputGroupProps = React.HtmlHTMLAttributes<HTMLDivElement>;

const InputGroup = ({ className, ...props }: InputGroupProps) => {
    return (
        <div
            className={classNames(styles['input-group'], className)}
            {...props}
        />
    );
};

export default InputGroup;
