import { trackPromise } from 'react-promise-tracker';
import qs from 'qs';
import api from '../../../../../utils/API';

export const extracaoPrecosCampanha = (id) => trackPromise(
        api({
            url: `${process.env.REACT_APP_IPA_URL}/campaign/campaigns/${id}/download`,
            method: 'GET',
            expectToken: true,
            params: {
                client: JSON.parse(localStorage.getItem('cliente'))?.nome,
            },
            paramsSerializer(params) {
                return qs.stringify(params, { arrayFormat: 'repeat' });
            },
            responseType: 'arraybuffer',
        })
            .then((res) => {
                if (res.status === 200) {
                    return res.data;
                }
                return res;
            })
            .then((resp) => {
                let download = resp;
                if (!download.status) {
                    const blob = new Blob([download]);
                    const hiddenElement = document.createElement('a');
                    hiddenElement.href = window.URL.createObjectURL(blob);
                    hiddenElement.target = '_blank';
                    hiddenElement.download = `extracao_precos_oferta-${id}.csv`;
                    hiddenElement.click();
                    download = null;
                }
                return download;
            }),
    );
