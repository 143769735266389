import React from 'react';

export const MdImage = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="10"
        height="10"
        viewBox="0 0 10 10"
        fill="none"
        {...props}
    >
        <path
            d="M8.75 7.91667V2.08333C8.75 1.625 8.375 1.25 7.91667 1.25H2.08333C1.625 1.25 1.25 1.625 1.25 2.08333V7.91667C1.25 8.375 1.625 8.75 2.08333 8.75H7.91667C8.375 8.75 8.75 8.375 8.75 7.91667ZM3.70833 5.825L4.58333 6.87917L5.875 5.21667C5.95833 5.10833 6.125 5.10833 6.20833 5.22083L7.67083 7.17083C7.775 7.30833 7.675 7.50417 7.50417 7.50417H2.50833C2.33333 7.50417 2.2375 7.30417 2.34583 7.16667L3.38333 5.83333C3.4625 5.725 3.62083 5.72083 3.70833 5.825Z"
            fill="currentColor"
        />
    </svg>
);
