import * as React from 'react';

export const useIsOverflow = (list, id) => {
    const [isOverflow, setIsOverflow] = React.useState({});

    React.useLayoutEffect(() => {
        if (list?.length) {
            setTimeout(() => {
                list.map((d, i) => {
                    const wrapperWidth = document.querySelector(
                        `#${id}-${i}`,
                    ).clientWidth;
                    const textWidth = document.querySelector(
                        `#${id}-${i}`,
                    ).scrollWidth;
                    setIsOverflow((prevState) => ({
                        ...prevState,
                        [`${id}-${i}`]: textWidth > wrapperWidth,
                    }));
                });
            }, 500);
        }
    }, [list]);

    return { isOverflow };
};
