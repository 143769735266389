import React, { useEffect, useState } from 'react';
import {
    Container, Content,
} from 'rsuite';
import { withRouter } from 'react-router-dom';

import { LoadingSpiner } from '../../../../../components/LoadingSpinner';
import { getDetalhesSLA } from '../services';
import { useQuery } from '../utils';

const DetalheColeta = () => {
    const query = useQuery();
    const dataColeta = query.get('data_coleta');
    const loja = query.get('loja');
    const lojaId = query.get('loja_id');
    const [dashboardUrl, setDashboardUrl] = useState(null);

    async function getDashboard() {
        const response = await getDetalhesSLA({ data_coleta: dataColeta, loja, loja_id: lojaId });
        if (response?.url) {
            setDashboardUrl(response.url);
        }
    }

    useEffect(() => {
        getDashboard();
    }, []);

    return (
        <Container className="main-container detalhe-coleta">
            <LoadingSpiner size="md" />
            <Content>
                <iframe
                    title="Acompanhamento de coletas"
                    src={dashboardUrl}
                    frameBorder="0"
                />
            </Content>
        </Container>
    );
};

export default withRouter(DetalheColeta);
