import React from 'react';
// @ts-ignore
import { Grafico } from '../../Components/index.tsx';
// @ts-ignore
import { useUnidadesVendidasFaturamento } from '../../../hooks/useUnidadesVendidasFaturamento.tsx';

export const UnidadesVendidasFaturamento: React.FC = () => {
    const { grafico, data, isLoading } = useUnidadesVendidasFaturamento();

    return (
        <Grafico
            title="Quantidade de unidade vendidas e faturamento"
            grafico={grafico}
            isLoading={isLoading}
            data={data}
        />
    );
};
