import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import { GerenciadorPrecos } from '../../@types/GerenciadorPrecos';
import { RootState } from '../../@types/RootState';

type Key = GerenciadorPrecos.RowData['productsToBePricedId'];

type Option = GerenciadorPrecos.Table.ExpandedRowMenu;

export type GerenciadorExpandedRowKeyState = {
    key: Key;
    options: Option[];
};

export const initialState: GerenciadorExpandedRowKeyState = {
    key: '',
    options: [],
};

const slice = createSlice({
    name: 'ipa/gerenciador/expandedRowKeys',
    initialState,
    reducers: {
        SET_GERENCIADOR_EXPANDED_ROW: (
            _state,
            action: PayloadAction<GerenciadorExpandedRowKeyState>,
        ) => {
            return action.payload;
        },
        SET_GERENCIADOR_EXPANDED_ROW_OPTION: (
            state,
            { payload }: PayloadAction<Option>,
        ) => {
            return { ...state, option: payload };
        },
        SET_GERENCIADOR_EXPANDED_ROW_KEY: (
            state,
            { payload }: PayloadAction<Key>,
        ) => {
            return { ...state, key: payload };
        },
        ADD_GERENCIADOR_EXPANDED_ROW_OPTION: (
            state,
            { payload }: PayloadAction<Option>,
        ) => {
            const uniqueOptions = _.uniq([...state.options, payload]);
            state.options = uniqueOptions;
        },
        REMOVE_GERENCIADOR_EXPANDED_ROW_OPTION: (
            state,
            { payload }: PayloadAction<Option>,
        ) => {
            const { options } = state;

            const filteredOptions = options.filter((option) => {
                return option !== payload;
            });

            state.options = filteredOptions;
        },
        RESET_GERENCIADOR_EXPANDED_ROW: () => initialState,
    },
});

export const {
    SET_GERENCIADOR_EXPANDED_ROW_KEY,
    SET_GERENCIADOR_EXPANDED_ROW,
    RESET_GERENCIADOR_EXPANDED_ROW,
    ADD_GERENCIADOR_EXPANDED_ROW_OPTION,
    REMOVE_GERENCIADOR_EXPANDED_ROW_OPTION,
} = slice.actions;

export const selectorExpandedRowKey = (state: RootState) => {
    return state.gerenciadorPrecosReducer.expandedRowKey;
};

export default slice.reducer;
