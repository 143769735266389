import React from 'react';
import { Content, Loader, Table } from 'rsuite';
import CurrencyFormat from 'react-currency-format';
// @ts-ignore
import { HoverTooltip } from '../../../../components/HoverTooltip';
const { Column, HeaderCell, Cell } = Table;

type RowData = {
    [key: string]: any;
};

type TabelaProps = {
    data: RowData[] | undefined;
    isLoading: boolean;
    // @ts-ignore
    columns: Column<RowData>[];
    title: string;
    description?: string;
};

export const Tabela: React.FC<TabelaProps> = (props) => {
    const { data, isLoading, columns, title, description } = props;

    if (isLoading)
        return (
            <Content className="componente-tabela-loading">
                <Loader center />
            </Content>
        );

    if (!data || !data.length)
        return (
            <Content className="componente-tabela">
                <h2>{title}</h2>
                <p>{description}</p>
                <div className="sem-dados">
                    <h3>Sem dados para essa análise</h3>
                    <p>Revise os filtros e tente novamente</p>
                </div>
            </Content>
        );

    return (
        <Content className="componente-tabela">
            <h2>{title}</h2>
            <p>{description}</p>
            <div className="table-container">
                <Table data={data} height={382} rowHeight={32}>
                    {columns.map((column, index) => (
                        <Column key={index} {...column}>
                            <HeaderCell className="header-tabela">
                                {column.isTooltip ? (
                                    <HoverTooltip
                                        placement="bottom"
                                        text={column.headerCell}
                                    />
                                ) : (
                                    <span>{column.headerCell}</span>
                                )}
                            </HeaderCell>
                            {column?.isPercent ? (
                                <Cell dataKey={column.dataKey}>
                                    {(rowData: RowData) =>
                                        rowData[column.dataKey] ? (
                                            <div className="linha-tabela">
                                                <CurrencyFormat
                                                    className="currency"
                                                    fixedDecimalScale
                                                    decimalScale={2}
                                                    value={
                                                        rowData[column.dataKey]
                                                    }
                                                    displayType="text"
                                                    suffix="%"
                                                    decimalSeparator=","
                                                    thousandSeparator="."
                                                />
                                            </div>
                                        ) : (
                                            '--'
                                        )
                                    }
                                </Cell>
                            ) : (
                                <Cell dataKey={column.dataKey}>
                                    {(rowData: RowData) => (
                                        <div className="linha-tabela">
                                            {rowData[column.dataKey] || '--'}
                                        </div>
                                    )}
                                </Cell>
                            )}
                        </Column>
                    ))}
                </Table>
            </div>
        </Content>
    );
};
