import React from 'react';
import styles from './FormBox.module.scss';

export interface IFormBoxLabel{
    children: string;
    width?: string;
}

export const FormBoxLabel = ({ children, width }: IFormBoxLabel) => (
    <div className={styles['label-wrapper']} style={{ width }}>
        <p className={styles.label}>{children}</p>
    </div>
);
