export const ADD_FILTRO_HISTORICO = 'ADD_FILTRO_HISTORICO'
export const DELETE_FILTRO_HISTORICO = 'DELETE_FILTRO_HISTORICO'
export const UPDATE_FILTER_DATA_HISTORICO = 'UPDATE_FILTER_DATA_HISTORICO'
export const UPDATE_FILTER_VALUE_HISTORICO = 'UPDATE_FILTER_VALUE_HISTORICO'
export const DELETE_FILTRO_REGIAO = 'DELETE_FILTRO_REGIAO'

export function addFiltroHistorico(filtro) {
    return {
        type: ADD_FILTRO_HISTORICO,
        filtro
    }
}

export function deleteFiltroHistorico(index, name) {
    return {
        type: name === 'uf' ? DELETE_FILTRO_REGIAO : DELETE_FILTRO_HISTORICO,
        index,
        name
    }
}

export function updateFilterDataHistorico(name, data) {
    return {
        type: UPDATE_FILTER_DATA_HISTORICO,
        name,
        data
    }
}

export function updateFilterValue(name, value) {
    return {
        type: UPDATE_FILTER_VALUE_HISTORICO,
        name,
        value
    }
}