import { Slot } from '@radix-ui/react-slot';
import classNames from 'classnames';
import React, { forwardRef } from 'react';
import { BaseComponentProps } from '../../../@types/BaseComponent';
import styles from './FilterGroup.module.scss';

export type FilterGroupProps = BaseComponentProps<
    React.HtmlHTMLAttributes<HTMLDivElement>
>;

export const FilterGroup = forwardRef<HTMLDivElement, FilterGroupProps>(
    ({ className, asChild, ...props }, ref) => {
        const Comp = asChild ? Slot : 'div';

        return (
            <Comp
                className={classNames(styles['filter-group'], className)}
                ref={ref}
                {...props}
            />
        );
    },
);
