import React, { useState, useEffect, createRef } from 'react';
import { withRouter } from 'react-router-dom';
import {
    Container,
    Content,
    FlexboxGrid,
    Button,
    InputGroup,
    Icon,
    Input,
    Col,
    Table,
    IconButton,
    Dropdown,
    Popover,
    Whisper,
    Checkbox,
    CheckboxGroup,
    Badge,
    Notification,
} from 'rsuite';
import { Chip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import SubjectIcon from '@material-ui/icons/Subject';
import { format, parseISO } from 'date-fns';
import ModalConfirmacao from '../../../components/ModalConfirmacao';
import {
    getLojas,
    getClusters,
    getAuthors,
    getCampanhas,
    getStatus,
    deleteCampanha,
} from './services';
import { searchFiltroProdutos } from '../../../services/ProdutoService';
import { LoadingSpiner } from '../../../components/LoadingSpinner';
import { colunasTabelaCampanhas } from './Components/Columns';
// import Select from '../../../components/SelectPill';

const {
 Column, HeaderCell, Cell, Pagination,
} = Table;

const CustomChip = withStyles({
    icon: {
        color: '#fff',
    },
})(Chip);

const sortColumnsTypes = {
    name: 'name',
    update_date: 'updateDate',
    starting_date: 'startingDate',
    expiration_date: 'expirationDate',
    author: 'author',
    status: 'status',
};

const MenuPopover = ({
    onSelect,
    data,
    select,
    type,
    handleChange,
    handleSelect,
    searchItem,
    onMouseEnter,
    onMouseLeave,
    className,
    placement,
    positionLeft,
    positionTop,
    style,
    shouldUpdatePosition,
}) => (
    <Popover
        className={`listagem-produtos ${className}`}
        placement={placement}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        positionLeft={positionLeft}
        positionTop={positionTop}
        full
        style={style}
        shouldUpdatePosition={shouldUpdatePosition}
    >
        <Dropdown.Menu>
            {searchItem ? (
                <Dropdown.Item>
                    <Input
                        placeholder={
                            type === 'produto'
                                ? 'Busque por um prouto'
                                : 'Busque por uma loja'
                        }
                        onChange={(search) => searchItem(search)}
                    />
                </Dropdown.Item>
            ) : null}

            {type === 'produto' || type === 'loja' ? (
                <div className="listagem-produtos">
                    <Dropdown.Item>
                        <CheckboxGroup
                            name="checkboxList"
                            value={select.value}
                            onChange={(value) => handleChange(value)}
                        >
                            {data.map((item) => (
                                <Checkbox
                                    value={
                                        type === 'loja'
                                            ? item.store_id
                                            : item.product_id
                                    }
                                    key={
                                        type === 'loja'
                                            ? item.store_id
                                            : item.product_id
                                    }
                                    onChange={() => handleSelect(item)}
                                >
                                    {item.label}
                                </Checkbox>
                            ))}
                        </CheckboxGroup>
                    </Dropdown.Item>
                </div>
            ) : (
                <>
                    {data.map((item) => (
                        <Dropdown.Item
                            active={item.value === select}
                            key={item.value}
                            onSelect={() => onSelect(item, type)}
                        >
                            {item.label}
                        </Dropdown.Item>
                    ))}
                </>
            )}
        </Dropdown.Menu>
    </Popover>
);

const Campanhas = ({ history }) => {
    const [nomeCampanha, setNomeCampanha] = useState('');
    const [campanhas, setCampanhas] = useState({ content: [] });
    const [page, setPage] = useState(0);
    const [size, setSize] = useState(20);
    const [produtos, setProdutos] = useState([]);
    const [lojas, setLojas] = useState([]);
    const [status, setStatus] = useState([]);
    const [clusters, setClusters] = useState([]);
    const [criadores, setCriadores] = useState([]);
    const [produtosSelecionados, setProdutosSelecionados] = useState({
        cache: [],
        value: [],
    });
    const [lojasSelecionadas, setLojasSelecionadas] = useState({
        cache: [],
        value: [],
    });
    const [clusterSelecionado, setClusterSelecionado] = useState('');
    const [statusSelecionado, setStatusSelecionado] = useState({});
    const [criadorSelecionado, setCriadorSelecionado] = useState('');
    const [showModalConfirmacaoExclusao, setShowModalConfirmacaoExclusao]
        = useState(false);
    const [deleteMessage, setDeleteMessage] = useState('');
    const [campanhaToDelete, setCampanhaToDelete] = useState(null);
    const [sort, setSort] = useState({});
    const criadorRef = createRef();
    const lojaRef = createRef();
    const produtosRef = createRef();
    const statusRef = createRef();

    const handleSelectMenu = (item, type) => {
        switch (type) {
            case 'criador':
                setCriadorSelecionado(item.value);
                criadorRef.current.hide();
                break;
            case 'loja':
                setLojasSelecionadas(item);
                lojaRef.current.hide();
                break;
            case 'status':
                setStatusSelecionado(item);
                statusRef.current.hide();
                break;
            default:
                break;
        }
    };

    const limpaFiltros = () => {
        setProdutosSelecionados({
            cache: [],
            value: [],
        });
        setLojasSelecionadas({
            cache: [],
            value: [],
        });
        setClusterSelecionado('');
        setStatusSelecionado({});
        setCriadorSelecionado('');
    };

    const getClustersCliente = async () => {
        const resp = await getClusters();
        setClusters(
            resp.map((item) => ({
                value: item.store_cluster,
                label: item.store_cluster,
            })),
        );
    };

    const searchCriador = async (query) => {
        const params = {
            author: query || null,
            type: null,
        };

        const response = await getAuthors(params);
        if (response) {
            setCriadores(
                response.map((item) => ({
                    value: item,
                    label: item,
                })),
            );
        }
    };

    const searchProdutos = async (query) => {
        const response = await searchFiltroProdutos({ query });
        if (response) {
            setProdutos(
                response.data
                    .map((item) => ({
                        label: `${item.product_id} - ${item.description}`,
                        value: `${item.product_id}-${item.description}`,
                        gtin: item.gtin,
                        product_id: item.product_id,
                    }))
                    .concat(
                        produtosSelecionados.cache.filter(
                            (itemCache) => response.data.findIndex(
                                    (itemLista) => itemLista.product_id
                                        === itemCache.product_id,
                                ) === -1,
                        ),
                    ),
            );
        }
    };

    const searchLojas = async (query) => {
        const response = await getLojas({ query });
        if (response) {
            setLojas(
                response.content
                    ?.map((item) => ({
                        value: item.store_id,
                        label: item.store,
                        store_id: item.store_id,
                    }))
                    .concat(
                        lojasSelecionadas.cache.filter(
                            (itemCache) => response.data.findIndex(
                                    (itemLista) => itemLista.store_id
                                        === itemCache.store_id,
                                ) === -1,
                        ),
                    ),
            );
        }
    };

    const handleSelectProduto = (item) => {
        const cacheProduto = produtosSelecionados.cache;
        cacheProduto.push(item);
        setProdutosSelecionados((prevState) => ({
            ...prevState,
            cache: cacheProduto,
        }));
    };

    const handleChangeFiltroProduto = (value) => {
        setProdutosSelecionados((prevState) => ({ ...prevState, value }));

        if (value === null) {
            setProdutosSelecionados((prevState) => ({
                ...prevState,
                cache: [],
            }));
            searchFiltroProdutos('');
        }
        if (
            value !== null
            && value.length !== produtosSelecionados.cache.length
        ) {
            const newCacheProduto = produtosSelecionados.cache.filter(
                (item) => value.indexOf(item) !== -1,
            );

            setProdutosSelecionados((prevState) => ({
                ...prevState,
                cache: newCacheProduto,
            }));
        }
    };

    const handleSelectLoja = (value, item) => {
        const cacheLoja = lojasSelecionadas.cache;
        if (item) cacheLoja.push(item);
        setLojasSelecionadas((prevState) => ({
            ...prevState,
            cache: cacheLoja,
        }));
    };

    const handleChangeFiltroLoja = (value) => {
        setLojasSelecionadas((prevState) => ({ ...prevState, value }));

        if (value === null) {
            setLojasSelecionadas((prevState) => ({
                ...prevState,
                cache: [],
            }));
            searchLojas('');
        }
        if (value !== null && value.length !== lojasSelecionadas.cache.length) {
            const newCacheLoja = lojasSelecionadas.cache.filter(
                (item) => value.indexOf(item.value) !== -1,
            );

            setLojasSelecionadas((prevState) => ({
                ...prevState,
                cache: newCacheLoja,
            }));
        }
    };

    const getModelFiltros = () => ({
        name: nomeCampanha,
        page: page || null,
        size: size || null,
        authors: criadorSelecionado || null,
        storeIds: lojasSelecionadas.value || null,
        clusters: clusterSelecionado || null,
        status: statusSelecionado.value,
        productIds: produtosSelecionados.value || null,
        sort:
            sort?.type && sort?.orderBy ? `${sortColumnsTypes[sort.type]},${sort.orderBy}` : null,
    });

    const listCampanhas = async () => {
        const query = getModelFiltros();
        const resp = await getCampanhas(query);
        if (resp) setCampanhas(resp);
    };

    const goNovaCampanha = () => {
        history.push('/ipa/campanhas/cadastro/dados-campanha');
    };

    const listStatus = async () => {
        const resp = await getStatus();
        if (resp) {
            setStatus(
                resp.map((item) => ({
                    value: item,
                    label:
                        item === 'CURRENT'
                            ? 'Vigente'
                            : item === 'SCHEDULED'
                            ? 'Programada'
                            : item === 'DRAFT'
                            ? 'Rascunho'
                            : 'Encerrada',
                })),
            );
        }
    };

    const closeExclusao = () => {
        setDeleteMessage('');
        setShowModalConfirmacaoExclusao(false);
        setCampanhaToDelete(null);
    };

    const confirmExclusao = () => {
        deleteCampanha(campanhaToDelete).then(() => {
            Notification.success({
                title: 'Notificação',
                duration: 6000,
                description: (
                    <div>
                        <p>Campanha excluída com sucesso.</p>
                    </div>
                ),
            });
            listCampanhas();
            closeExclusao();
        });
    };

    const removeCampanha = (id) => {
        setDeleteMessage(
            'Você está tentando deletar uma campanha. Você confirma essa ação?',
        );
        setShowModalConfirmacaoExclusao(true);
        setCampanhaToDelete(id);
    };

    const editCampanha = (id) => {
        history.push(`/ipa/campanhas/cadastro/dados-campanha/${id}`);
    };

    useEffect(() => {
        searchLojas();
        getClustersCliente();
        searchCriador();
        searchProdutos();
        listStatus();
    }, []);

    useEffect(() => {
        listCampanhas();
    }, [
        page,
        size,
        statusSelecionado,
        lojasSelecionadas,
        criadorSelecionado,
        produtosSelecionados,
        nomeCampanha,
        sort,
    ]);

    return (
        <Container className="main-container campanhas-page">
            <Content className="secao-busca">
                <FlexboxGrid align="middle">
                    <FlexboxGrid.Item colspan={24}>
                        <FlexboxGrid justify="space-between">
                            <FlexboxGrid.Item>
                                <h4 className="campanhas-title">Campanhas</h4>
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item
                                componentClass={Col}
                                lg={16}
                                md={15}
                                sm={14}
                                xs={4}
                            >
                                <InputGroup inside className="input-outline">
                                    <InputGroup.Addon>
                                        <Icon
                                            className="icon-busca"
                                            icon="search"
                                        />
                                    </InputGroup.Addon>
                                    <Input
                                        placeholder="Pesquise por uma campanha"
                                        value={nomeCampanha}
                                        onChange={(value) => setNomeCampanha(value)}
                                    />
                                    {nomeCampanha ? (
                                        <InputGroup.Addon>
                                            <Icon
                                                icon="close"
                                                onClick={() => setNomeCampanha('')}
                                            />
                                        </InputGroup.Addon>
                                    ) : null}
                                </InputGroup>
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item>
                                <Button
                                    id="nova-campanha"
                                    title="Nova regra"
                                    block
                                    appearance="primary"
                                    onClick={goNovaCampanha}
                                >
                                    NOVA CAMPANHA
                                </Button>
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </Content>
            <Content className="secao-tabela">
                <FlexboxGrid>
                    <FlexboxGrid.Item colspan={24}>
                        <div className="campanhas-filtros">
                            <FlexboxGrid justify="space-between">
                                <FlexboxGrid.Item componentClass={Col} lg={16}>
                                    <FlexboxGrid className="filtros-container">
                                        <FlexboxGrid.Item componentClass={Col}>
                                            <Whisper
                                                placement="bottomStart"
                                                trigger="click"
                                                triggerRef={produtosRef}
                                                onClose={() => searchProdutos('')}
                                                speaker={(
                                                    <MenuPopover
                                                        onSelect={
                                                            handleSelectMenu
                                                        }
                                                        data={produtos}
                                                        select={
                                                            produtosSelecionados
                                                        }
                                                        type="produto"
                                                        handleChange={
                                                            handleChangeFiltroProduto
                                                        }
                                                        handleSelect={
                                                            handleSelectProduto
                                                        }
                                                        searchItem={
                                                            searchProdutos
                                                        }
                                                    />
                                                  )}
                                            >
                                                <Button
                                                    className={`filtro-botao ${
                                                        produtosSelecionados
                                                            .value.length
                                                            ? 'active'
                                                            : ''
                                                    }`}
                                                >
                                                    {produtosSelecionados.value
                                                        .length
                                                        ? produtosSelecionados
                                                              .value.length > 1
                                                            ? 'Produtos'
                                                            : 'Produto'
                                                        : 'Produto'}
                                                    {produtosSelecionados.value
                                                        .length ? (
                                                            <Badge
                                                                style={{
                                                                background:
                                                                    '#0D66D0',
                                                            }}
                                                                content={
                                                                produtosSelecionados
                                                                    .value
                                                                    .length
                                                            }
                                                            />
                                                    ) : null}
                                                    <Icon icon="angle-down" />
                                                    {produtosSelecionados.value
                                                        .length ? (
                                                            <Icon
                                                                icon="close"
                                                                onClick={() => setProdutosSelecionados(
                                                                    {
                                                                        value: [],
                                                                        cache: [],
                                                                    },
                                                                )}
                                                            />
                                                    ) : null}
                                                </Button>
                                            </Whisper>
                                        </FlexboxGrid.Item>
                                        <FlexboxGrid.Item componentClass={Col}>
                                            <Whisper
                                                placement="bottomStart"
                                                trigger="click"
                                                triggerRef={lojaRef}
                                                onClose={() => searchLojas('')}
                                                speaker={(
                                                    <MenuPopover
                                                        onSelect={
                                                            handleSelectMenu
                                                        }
                                                        data={lojas}
                                                        select={
                                                            lojasSelecionadas
                                                        }
                                                        type="loja"
                                                        handleChange={
                                                            handleChangeFiltroLoja
                                                        }
                                                        handleSelect={
                                                            handleSelectLoja
                                                        }
                                                        searchItem={searchLojas}
                                                    />
                                                  )}
                                            >
                                                <Button
                                                    className={`filtro-botao ${
                                                        lojasSelecionadas.value
                                                            .length
                                                            ? 'active'
                                                            : ''
                                                    }`}
                                                >
                                                    {lojasSelecionadas.value
                                                        .length
                                                        ? lojasSelecionadas
                                                              .value.length > 1
                                                            ? 'Lojas'
                                                            : 'Loja'
                                                        : 'Loja'}
                                                    {lojasSelecionadas.value
                                                        .length ? (
                                                            <Badge
                                                                style={{
                                                                background:
                                                                    '#0D66D0',
                                                            }}
                                                                content={
                                                                lojasSelecionadas
                                                                    .value
                                                                    .length
                                                            }
                                                            />
                                                    ) : null}
                                                    <Icon icon="angle-down" />
                                                    {lojasSelecionadas.value
                                                        .length ? (
                                                            <Icon
                                                                icon="close"
                                                                onClick={() => setLojasSelecionadas(
                                                                    {
                                                                        value: [],
                                                                        cache: [],
                                                                    },
                                                                )}
                                                            />
                                                    ) : null}
                                                </Button>
                                            </Whisper>
                                        </FlexboxGrid.Item>
                                        {/* <FlexboxGrid.Item componentClass={Col}>
                                            <Select
                                                id="select-picker-pill"
                                                data={clusters}
                                                placeholder="Cluster"
                                            />
                                        </FlexboxGrid.Item> */}
                                        <FlexboxGrid.Item componentClass={Col}>
                                            <Whisper
                                                placement="bottomStart"
                                                trigger="click"
                                                triggerRef={statusRef}
                                                onClose={() => listStatus()}
                                                speaker={(
                                                    <MenuPopover
                                                        onSelect={
                                                            handleSelectMenu
                                                        }
                                                        data={status}
                                                        select={
                                                            statusSelecionado.value
                                                        }
                                                        type="status"
                                                    />
                                                  )}
                                                type="status"
                                            >
                                                <Button
                                                    className={`filtro-botao ${
                                                        statusSelecionado.value
                                                            ? 'active'
                                                            : null
                                                    }`}
                                                >
                                                    {statusSelecionado.label
                                                        || 'Status'}
                                                    {!statusSelecionado.value ? (
                                                        <Icon icon="angle-down" />
                                                    ) : (
                                                        <Icon
                                                            icon="close"
                                                            onClick={() => setStatusSelecionado(
                                                                    {},
                                                                )}
                                                        />
                                                    )}
                                                </Button>
                                            </Whisper>
                                        </FlexboxGrid.Item>
                                        <FlexboxGrid.Item componentClass={Col}>
                                            <Whisper
                                                placement="bottomStart"
                                                trigger="click"
                                                triggerRef={criadorRef}
                                                onClose={() => searchCriador('')}
                                                speaker={(
                                                    <MenuPopover
                                                        onSelect={
                                                            handleSelectMenu
                                                        }
                                                        data={criadores}
                                                        select={
                                                            criadorSelecionado
                                                        }
                                                        type="criador"
                                                    />
                                                  )}
                                            >
                                                <Button
                                                    className={`filtro-botao ${
                                                        criadorSelecionado
                                                            ? 'active'
                                                            : null
                                                    }`}
                                                >
                                                    {criadorSelecionado
                                                        || 'Criador'}
                                                    {!criadorSelecionado ? (
                                                        <Icon icon="angle-down" />
                                                    ) : (
                                                        <Icon
                                                            icon="close"
                                                            onClick={() => setCriadorSelecionado(
                                                                    '',
                                                                )}
                                                        />
                                                    )}
                                                </Button>
                                            </Whisper>
                                        </FlexboxGrid.Item>
                                    </FlexboxGrid>
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item>
                                    <Button
                                        className="filtro-botao"
                                        appearance="primary"
                                        onClick={limpaFiltros}
                                    >
                                        Limpar filtros
                                    </Button>
                                </FlexboxGrid.Item>
                            </FlexboxGrid>
                        </div>
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item colspan={24}>
                        <LoadingSpiner size="md" />
                        <div className="campanhas-table-container">
                            <Table
                                className="table"
                                data={campanhas.content}
                                autoHeight
                                sortColumn={sort.type}
                                sortType={sort.orderBy}
                                onSortColumn={(sortColumn, sortType) => setSort({
                                    type: sortColumn,
                                    orderBy: sortType,
                                })}
                            >
                                {colunasTabelaCampanhas.map((coluna) => (!coluna.dataKey ? (
                                    <Column
                                        width={120}
                                        fixed="right"
                                        key={Math.random()}
                                    >
                                        <HeaderCell />

                                        <Cell className="table__edit-button">
                                            {(rowData) => (
                                                <span className="mg-5">
                                                    <IconButton
                                                        title={
                                                                rowData.status
                                                                    === 'CURRENT'
                                                                || rowData.status
                                                                    === 'CLOSED'
                                                                    ? 'Visualizar'
                                                                    : 'Editar'
                                                            }
                                                        icon={(
                                                            <Icon
                                                                icon={
                                                                        rowData.status
                                                                            === 'CURRENT'
                                                                        || rowData.status
                                                                            === 'CLOSED'
                                                                            ? 'eye'
                                                                            : 'edit2'
                                                                    }
                                                            />
                                                              )}
                                                        size="md"
                                                        onClick={() => editCampanha(
                                                                    rowData.campaign_id,
                                                                )}
                                                    />
                                                    <IconButton
                                                        title="Deletar"
                                                        size="md"
                                                        icon={
                                                            <Icon icon="trash2" />
                                                            }
                                                        onClick={() => removeCampanha(
                                                                    rowData.campaign_id,
                                                                )}
                                                        disabled={
                                                                rowData.status
                                                                    === 'CURRENT'
                                                                || rowData.status
                                                                    === 'CLOSED'
                                                            }
                                                    />
                                                </span>
                                                )}
                                        </Cell>
                                    </Column>
                                    ) : (
                                        <Column
                                            flexGrow={coluna.flexGrow}
                                            sortable={coluna.sortable}
                                            align={coluna.align}
                                            fixed={coluna.fixed}
                                            width={coluna.width}
                                            key={Math.random()}
                                        >
                                            <HeaderCell>
                                                {coluna.headerCell}
                                            </HeaderCell>
                                            {coluna.dataKey === 'status' ? (
                                                <Cell dataKey={coluna.dataKey}>
                                                    {(rowData) => (rowData.status ? (
                                                        <CustomChip
                                                            className={
                                                                    rowData.status
                                                                }
                                                            size="small"
                                                            label={
                                                                    rowData.status
                                                                    === 'CURRENT'
                                                                        ? 'Vigente'
                                                                        : rowData.status
                                                                          === 'SCHEDULED'
                                                                        ? 'Programada'
                                                                        : rowData.status
                                                                          === 'DRAFT'
                                                                        ? 'Rascunho'
                                                                        : 'Encerrada'
                                                                }
                                                            icon={
                                                                    rowData.status
                                                                    === 'CURRENT' ? (
                                                                        <PlayArrowIcon />
                                                                    ) : rowData.status
                                                                      === 'SCHEDULED' ? (
                                                                          <AccessTimeIcon />
                                                                    ) : rowData.status
                                                                      === 'DRAFT' ? (
                                                                          <SubjectIcon />
                                                                    ) : rowData.status
                                                                      === 'CLOSED' ? (
                                                                          <CheckCircleIcon />
                                                                    ) : (
                                                                        ''
                                                                    )
                                                                }
                                                        />
                                                        ) : (
                                                            '-'
                                                        ))}
                                                </Cell>
                                            ) : coluna.dataKey
                                              === 'update_date' ? (
                                                  <Cell dataKey={coluna.dataKey}>
                                                      {(rowData) => (rowData.update_date
                                                            ? format(
                                                                  parseISO(
                                                                      rowData.update_date,
                                                                  ),
                                                                  'dd/MM/yyyy',
                                                              )
                                                            : '--')}
                                                  </Cell>
                                            ) : coluna.dataKey
                                              === 'starting_date' ? (
                                                  <Cell dataKey={coluna.dataKey}>
                                                      {(rowData) => format(
                                                            parseISO(
                                                                rowData.starting_date,
                                                            ),
                                                            'dd/MM/yyyy',
                                                        )}
                                                  </Cell>
                                            ) : coluna.dataKey
                                              === 'expiration_date' ? (
                                                  <Cell dataKey={coluna.dataKey}>
                                                      {(rowData) => format(
                                                            parseISO(
                                                                rowData.expiration_date,
                                                            ),
                                                            'dd/MM/yyyy',
                                                        )}
                                                  </Cell>
                                            ) : (
                                                <Cell
                                                    dataKey={coluna.dataKey}
                                                />
                                            )}
                                        </Column>
                                    )))}
                            </Table>
                            <Pagination
                                lengthMenu={[
                                    {
                                        value: 20,
                                        label: 20,
                                    },
                                    {
                                        value: 40,
                                        label: 40,
                                    },
                                ]}
                                activePage={campanhas.number + 1}
                                displayLength={campanhas.size}
                                total={campanhas.totalElements}
                                onChangePage={(value) => setPage(value - 1)}
                                onChangeLength={(value) => setSize(value)}
                            />
                        </div>
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </Content>
            <ModalConfirmacao
                show={showModalConfirmacaoExclusao}
                onConfirm={confirmExclusao}
                onCancel={closeExclusao}
                title="Deletar campanha"
                message={deleteMessage}
                confirmButton="Confirmar"
                cancelButton="Cancelar"
            />
        </Container>
    );
};

export default withRouter(Campanhas);
