import classNames from 'classnames';
import React, { forwardRef } from 'react';
import styles from './LinkButton.module.scss';

export type LinkButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement>;

export const LinkButton = forwardRef(
    (
        { children, className, ...props }: LinkButtonProps,
        ref: React.LegacyRef<HTMLButtonElement>,
    ) => {
        return (
            <button
                className={classNames(styles['link-button'], className)}
                ref={ref}
                {...props}
            >
                {children}
            </button>
        );
    },
);
