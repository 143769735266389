import classNames from 'classnames';
import React, { forwardRef } from 'react';
import styles from './TableBadge.module.scss';

export type TableBadgeProps = {
    skin?: 'neutral' | 'light-blue' | 'blue';
} & React.HtmlHTMLAttributes<HTMLDivElement>;

export const TableBadge = forwardRef<HTMLDivElement, TableBadgeProps>(
    ({ skin = 'neutral', className, ...props }, ref) => {
        return (
            <div
                ref={ref}
                className={classNames(styles.badge, styles[skin], className)}
                {...props}
            />
        );
    },
);
