import React, { useState } from 'react';
import {
    Button,
    Col,
    FlexboxGrid,
    Tooltip,
    Whisper,
} from 'rsuite';
import iconChevronDown from '../../../../../assets/icons/icon_chevron_down.svg';
import iconChevronUp from '../../../../../assets/icons/icon_chevron_up.svg';
import iconCopy from '../../../../../assets/icons/icon_copy.svg';
import iconStore from '../../../../../assets/icons/icon_store_2.svg';

const SelectedStore = ({ store }) => {
    const [accordionActive, setAccordionActive] = useState(false);

    const copyToClipboard = (info) => {
        navigator.clipboard.writeText(info);
        // Notification.open({
        //     placement: 'topEnd',
        //     description: 'Informação copiada com sucesso!',
        //     duration: 2000,
        //     className: 'nova-lista-copy-notification',
        // });
    };

    return (
        <div className="selected-store">
            <div className="header-wrapper">
                <div className="title-wrapper">
                    <img src={iconStore} alt="" />
                    <p className="selected-store-title">Loja selecionada</p>
                    <p className="selected-store-subtitle">
                        As análises geradas serão baseadas nas informações dessa
                        loja
                    </p>
                </div>
                <div className="name-wrapper">
                    <div className="name-badge" id="name-selected">
                        <span className="badge-label">Nome</span>
                        <span className="store-name">{store.alias || null}</span>
                    </div>
                    <div className="name-badge">
                        <span className="badge-label">Rede</span>
                        <span className="store-name">{store.rede || null}</span>
                    </div>
                    <Button
                        appearance="link"
                        className="detail-button"
                        onClick={() => setAccordionActive(!accordionActive)}
                    >
                        <img
                            src={
                                !accordionActive
                                    ? iconChevronDown
                                    : iconChevronUp
                            }
                            alt=""
                        />
                        {!accordionActive
                            ? 'Ver mais detalhes'
                            : 'Ocultar mais detalhes'}
                    </Button>
                </div>
                <FlexboxGrid
                    className={`detail-panel ${accordionActive ? 'active' : ''
                        }`}
                >
                    <Col md={3} lg={3}>
                        <p className="col-title">Tipo de loja</p>
                        <p className="col-value">{store.tipoLoja || null}</p>
                    </Col>
                    <Col md={4} lg={4}>
                        <p className="col-title">CNPJ</p>
                        <p className="col-value">
                            {store.cnpj || null}
                            <Whisper
                                placement="bottom"
                                speaker={(
                                    <Tooltip>
                                        Copiar para área de transferência
                                    </Tooltip>
                                )}
                            >
                                <Button
                                    appearance="link"
                                    className="copy-button"
                                    onClick={() => copyToClipboard(store.cnpj)}
                                >
                                    <img src={iconCopy} alt="" />
                                </Button>
                            </Whisper>
                        </p>
                    </Col>
                    <Col md={17} lg={17}>
                        <p className="col-title">Endereço</p>
                        <p className="col-value">
                            {store.endereco || null}
                            <Whisper
                                placement="bottom"
                                speaker={(
                                    <Tooltip>
                                        Copiar para área de transferência
                                    </Tooltip>
                                )}
                            >
                                <Button
                                    appearance="link"
                                    className="copy-button"
                                    onClick={() => copyToClipboard(store.endereco)}
                                >
                                    <img src={iconCopy} alt="" />
                                </Button>
                            </Whisper>
                        </p>
                    </Col>
                </FlexboxGrid>
            </div>
        </div>
    );
};

export default SelectedStore;
