import React, { forwardRef, useEffect, useState } from 'react';
import {
    CheckPicker, Dropdown, Icon, Button, Table, Checkbox,
    Grid, Row, Col,
} from 'rsuite';

import ModalNotification from '../../../../../components/ModalNotification';
import {
    downloadResultNovaLista, downloadResultListaCadastrada, getDimensoesNovaLista, getDimensoesListaCadastrada,
} from '../services';
import { setDownloadDTONovaLista, setDownloadDTOListaCadastrada } from '../utils';

const {
    Column, HeaderCell, Cell, Pagination,
} = Table;

const ResultadoValidacao = forwardRef(({
    resultado, page, size, onChangePage, totalItens, tipo, filtros,
}, ref) => {
    const [indeterminate, setInterminate] = useState(true);
    const [checkAll, setCheckAlll] = useState();
    const [dimensoes, setDimensoes] = useState([]);
    const [colunas, setColunas] = useState({ value: [], selectedItens: [] });
    const [showModalDownload, setModalDownload] = useState(false);

    const getColunasNovaLista = () => {
        getDimensoesNovaLista().then((response) => {
            if (response) {
                setDimensoes(response.map((item) => ({
                    id: item.name,
                    value: item.name,
                    label: item.description,
                    column: item.column,
                    name: item.name,
                    description: item.description,
                    required: item.required,
                })));
                setColunas({
                    selectedItens: response.filter(
                        (item) => item.name !== 'CEP' && item.name !== 'LOGRADOURO' && item.name !== 'NUMERO_LOGRADOURO',
                    ),
                    value: response.map((item) => item.name).filter(
                        (item) => item !== 'CEP' && item !== 'LOGRADOURO' && item !== 'NUMERO_LOGRADOURO',
                    ),
                });
            }
        });
    };

    const getColunasListaCadastrada = () => {
        getDimensoesListaCadastrada().then((response) => {
            if (response) {
                setDimensoes(response.map((item) => ({
                    id: item.name,
                    value: item.name,
                    label: item.description,
                    column: item.column,
                    name: item.name,
                    description: item.description,
                    required: item.required,
                })));
                setColunas({
                    selectedItens: response.filter(
                        (item) => item.name !== 'CEP'
                            && item.name !== 'LOGRADOURO'
                            && item.name !== 'NUMERO_LOGRADOURO'
                            && item.name !== 'CATEGORIA'
                            && item.name !== 'SECAO'
                            && item.name !== 'LISTA'
                            && item.name !== 'RECORRENCIA'
                            && item.name !== 'TIPO_SOLICITACAO',
                    ),
                    value: response.map((item) => item.name).filter(
                        (item) => item !== 'CEP'
                            && item !== 'LOGRADOURO'
                            && item !== 'NUMERO_LOGRADOURO'
                            && item !== 'CATEGORIA'
                            && item !== 'SECAO'
                            && item !== 'LISTA'
                            && item !== 'RECORRENCIA'
                            && item !== 'TIPO_SOLICITACAO',
                    ),
                });
            }
        });
    };

    const getDimensoesDownload = () => {
        if (tipo === 'NOVA_LISTA') {
            getColunasNovaLista();
        } else {
            getColunasListaCadastrada();
        }
    };

    useEffect(() => {
        getDimensoesDownload();
    }, []);

    const getDadosNovaLista = (extensao) => {
        const codigoConsulta = resultado[0].clienteValidacaoLista.id;
        downloadResultNovaLista(codigoConsulta, setDownloadDTONovaLista(extensao, colunas.value)).then((response) => {
            if (response?.status === 202) {
                setModalDownload(true);
            }
            if (response.status === 200) {
                const blob = new Blob([response.data]);
                const hiddenElement = document.createElement('a');
                hiddenElement.href = window.URL.createObjectURL(blob);
                hiddenElement.target = '_blank';
                hiddenElement.download = `revisao_nova_lista.${extensao}`;
                hiddenElement.click();
            }
        });
    };

    const getDadosListaCadastrada = (extensao) => {
        downloadResultListaCadastrada(setDownloadDTOListaCadastrada(extensao, filtros, colunas.value)).then((response) => {
            if (response?.status === 202) {
                setModalDownload(true);
            }
            if (response?.status === 200) {
                const blob = new Blob([response.data]);
                const hiddenElement = document.createElement('a');
                hiddenElement.href = window.URL.createObjectURL(blob);
                hiddenElement.target = '_blank';
                hiddenElement.download = `revisao_lista_cadastrada.${extensao}`;
                hiddenElement.click();
            }
        });
    };

    const getDados = (extensao) => {
        if (tipo === 'NOVA_LISTA') {
            getDadosNovaLista(extensao);
        } else {
            getDadosListaCadastrada(extensao);
        }
    };

    const changeColunasDownload = (value) => {
        const indeterminate = value.length > 0 && value.length < dimensoes.length;

        setColunas((oldState) => ({ ...oldState, value }));
        setInterminate(indeterminate);
        setCheckAlll(value.length === dimensoes.length);
    };

    const setAllColunasDownload = (value, checked) => {
        const nextValue = checked ? dimensoes.map((item) => item.name) : [];
        setColunas((oldState) => ({ ...oldState, value: nextValue }));
        setInterminate(false);
        setCheckAlll(checked);
    };

    const confirmColunas = () => {
        const preview = [];
        dimensoes.forEach((column) => {
            if (colunas.value?.findIndex((item) => column.name === item) !== -1) {
                preview.push(column);
            }
        });

        setColunas((oldState) => (
            {
                ...oldState,
                selectedItens: preview,
            }
        ));
    };

    return (
        <div className="resultado-validacao-component" ref={ref}>
            <Grid fluid>
                <Row>
                    <Col md={12} xs={24}>
                        <h3>Resultado</h3>
                        <p className="itens-count">
                            Exibindo
                            {' '}
                            {resultado.length}
                            {' '}
                            de
                            {' '}
                            {totalItens}
                            {' '}
                            itens. Para visualizar todos os itens faça o download.
                        </p>
                    </Col>
                    <Col md={4} xs={24} mdPush={5}>
                        <CheckPicker
                            filter
                            cleanable={false}
                            data={dimensoes}
                            placeholder="Colunas"
                            style={{ width: 155 }}
                            className="checkpicker-colunas"
                            menuClassName="resultado-column-picker"
                            appearance="subtle"
                            placement="bottomEnd"
                            value={colunas.value}
                            onChange={changeColunasDownload}
                            locale={{ searchPlaceholder: 'Pesquisar', noResultsText: 'Nenhum item encontrado' }}
                            renderExtraFooter={() => (
                                <div className="resultado-column-picker__footer">
                                    <Checkbox
                                        inline
                                        indeterminate={indeterminate}
                                        checked={checkAll}
                                        onChange={(value, checked) => setAllColunasDownload(value, checked)}
                                    >
                                        Selecionar tudo
                                    </Checkbox>
                                    <Button
                                        className="resultado-column-picker__footer-btn"
                                        appearance="primary"
                                        size="sm"
                                        onClick={() => confirmColunas()}
                                    >
                                        Confirmar
                                    </Button>
                                </div>
                            )}
                            renderValue={(value, items, selectedElement) => (
                                <span>
                                    <span style={{ color: '#8e8e93' }}>
                                        Colunas:
                                    </span>
                                    {' '}
                                    <span style={{
                                        margin: '0 4px',
                                        background: '#3498ff',
                                        color: '#fff',
                                        borderRadius: '10px',
                                        padding: '0 10px',
                                        lineHeight: '20px',
                                    }}
                                    >
                                        {selectedElement.props.selectedItems.length}
                                    </span>
                                </span>
                            )}
                        />
                    </Col>
                    <Col xs={24} md={3} mdPush={5}>
                        <Dropdown
                            id="download"
                            placement="bottomEnd"
                            title="Download"
                            icon={<Icon icon="cloud-download" />}
                            renderTitle={() => (
                                <Button block appearance="primary">
                                    <Icon icon="cloud-download" />
                                    {' '}
                                    Download
                                    {' '}
                                </Button>
                            )}
                        >
                            <Dropdown.Item
                                eventKey="csv"
                                id="download-csv"
                                onSelect={(value) => getDados(value)}
                                icon={<Icon icon="file-text-o" />}
                            >
                                .csv
                            </Dropdown.Item>
                            <Dropdown.Item
                                eventKey="xlsx"
                                id="download-xlsx"
                                onSelect={(value) => getDados(value)}
                                icon={<Icon icon="file-excel-o" />}
                            >
                                .xlsx
                            </Dropdown.Item>
                        </Dropdown>
                    </Col>

                </Row>
            </Grid>
            <Grid fluid>
                <Row>
                    <Col xs={24}>
                        <Table
                            id="result-table"
                            className="table"
                            height={300}
                            data={resultado}
                            rowKey="column"
                        >
                            {colunas?.selectedItens?.map((coluna) => (
                                <Column
                                    key={coluna.column}
                                    width={coluna.column === 'motivo' || coluna.column === 'descricaoLoja' || coluna.column === 'descricaoProduto' ? 250 : 130}
                                    resizable
                                >
                                    <HeaderCell>
                                        {coluna.description}
                                    </HeaderCell>
                                    <Cell dataKey={coluna.column}>
                                        {(rowData) => (

                                            <span title={`${rowData[coluna.column]}`}>{rowData[coluna.column]}</span>
                                        )}
                                    </Cell>
                                </Column>
                            ))}
                        </Table>
                        <Pagination
                            showInfo={false}
                            showLengthMenu={false}
                            activePage={page + 1 || null}
                            displayLength={size}
                            total={totalItens || null}
                            onChangePage={onChangePage}
                        />
                    </Col>
                </Row>
            </Grid>
            {showModalDownload ? (
                <ModalNotification
                    show
                    onHide={() => setModalDownload(false)}
                    title="Download"
                    message={'O arquivo que você deseja baixar contém mais de cinquenta mil linhas e pode demorar '
                        + 'um pouco para ficar pronto. Te enviaremos por e-mail assim que estiver tudo certo!'}
                    close={() => setModalDownload(false)}
                />
            ) : null}
        </div>

    );
});

export default ResultadoValidacao;
