import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    Col, Popover, Row, Nav, Icon,
} from 'rsuite';

import { addFiltro } from '../../../../../actions/actionsRevisaoLista';

const CustomNav = ({
    title, icon, itens, onSelect, pacote, ...props
}) => (
    <Nav {...props} vertical onSelect={onSelect} style={{ width: '100' }} className="nav-filtro-dinamico">
        <div className="nav-filtro-dinamico__title-div">
            <p className="nav-filtro-dinamico__title">{title}</p>
        </div>
        {itens?.map((item) => (
            <Nav.Item
                className="nav-item"
                key={item.name}
                name={item.name}
                eventKey={item.name}
            >
                <span>{item.title}</span>
            </Nav.Item>
        ))}
    </Nav>
);

const DropDownFiltros = ({
    categorias, filtrosAdicionados, addFiltro, filtros, ...props
}) => {
    const handleClick = async (eventKey) => {
        if (!filtrosAdicionados.length
            || (filtrosAdicionados.length
                && filtrosAdicionados.findIndex((item) => item.name === eventKey) === -1)
        ) {


            addFiltro(filtros[filtros.findIndex((item) => item.name === eventKey)]);
        }
    };

    return (
        <Popover {...props}>
            <Row style={{ width: '300px' }}>
                {categorias?.map((item) => (
                    <Col md={12} key={item.title} style={{ marginBottom: '15px' }}>
                        <CustomNav
                            title={item.title}
                            icon={item.icon}
                            itens={item.itens}
                            onSelect={handleClick}
                        />
                    </Col>
                ))}
            </Row>
        </Popover>
    );
};

const mapStateToProps = (store) => ({
    categorias: store.revisaoListaDataReducer.categorias,
    filtros: store.revisaoListaDataReducer.filtros,
    filtrosAdicionados: store.revisaoListaDataReducer.filtrosAdicionados,
    filtrosData: store.revisaoListaDataReducer.filtrosData,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    addFiltro,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DropDownFiltros);
