import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { GerenciadorPrecos } from '../../@types/GerenciadorPrecos';
import { RootState } from '../../@types/RootState';
import { Sort as DefaultSort } from '../../@types/sort';

type Sort = DefaultSort<GerenciadorPrecos.DataKey>;

const initialState: DefaultSort<GerenciadorPrecos.DataKey> = {
    type: 'competitorsPrice',
    orderBy: 'asc',
};

const slice = createSlice({
    name: 'ipa/gerenciador/sort',
    initialState,
    reducers: {
        SET_GERENCIADOR_SORT(state, { payload }: PayloadAction<Sort>) {
            state.type = payload.type;
            state.orderBy = payload.orderBy;
        },
    },
});

const selectorSort = (state: RootState) => {
    return state.gerenciadorPrecosReducer.sort;
};

const { SET_GERENCIADOR_SORT } = slice.actions;

export { SET_GERENCIADOR_SORT, initialState, selectorSort };

export default slice.reducer;
