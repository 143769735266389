import { useDispatch } from 'react-redux';
import {
    setResultScenarioData,
} from '../../../reducers/calculadoraPrevisaoDemanda/resultScenario';

import * as service from '../services';

export const useGetScenarioResults = () => {
    const dispatch = useDispatch();

    const handleGetFirstChartsData = async (data: any) => {
        const response = await service.getPlotPointsRevenueDemand(data);
        if (response) {
            dispatch(setResultScenarioData({ name: 'revenueDemandChartData', data: response }));
        }
    };

    const handleGetSecondChartsData = async (data: any) => {
        const response = await service.getPlotPointsProfitRevenue(data);
        if (response) {
            dispatch(setResultScenarioData({ name: 'profitRevenueChartData', data: response }));
        }
    };

    const handleGetCPIChartsData = async (data: any) => {
        const response = await service.getPlotPointsCPI(data);
        if (response) {
            dispatch(setResultScenarioData({ name: 'cpiChartData', data: response }));
        }
    };

    const handleChartBigNumbers = async (data: any) => {
        const response = await service.getChartBigNumbers(data);
        if (response) {
            dispatch(setResultScenarioData({ name: 'resultScenarioData', data: response }));
        }
    };

    return {
        resultHandlers: {
            handleGetFirstChartsData,
            handleGetSecondChartsData,
            handleGetCPIChartsData,
            handleChartBigNumbers,
        },
    };
};
