import classNames from 'classnames';
import React from 'react';
import styles from './FilterPlaceholder.module.scss';

type IFilterPlaceholder = {
    placeholder?: string;
    dataKey?: string;
} & Omit<React.HtmlHTMLAttributes<HTMLSpanElement>, 'children'>;

export const FilterPlaceholder = ({
    placeholder,
    className,
    dataKey,
    ...props
}: IFilterPlaceholder) => {
    if (!placeholder || !dataKey) return null;

    const level = dataKey.replace(/[^0-9]/g, '');

    if (level) {
        return (
            <span
                className={classNames(styles['filter-placeholder'], className)}
                {...props}
            >
                <span className={styles['placeholder-level']}>
                    {`0${level}`}
                </span>
                <span className={styles.placeholder}>{placeholder}</span>
            </span>
        );
    }

    return (
        <span
            className={classNames(styles['filter-placeholder'], className)}
            {...props}
        >
            {placeholder}
        </span>
    );
};
