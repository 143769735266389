import { useMutation } from '@tanstack/react-query';
import { useCallback, useMemo } from 'react';
import { usePromiseTracker } from 'react-promise-tracker';
import { useDispatch, useSelector } from 'react-redux';
import { Alert } from 'rsuite';
import { RootState } from '../../../../../@types/RootState';
import { queryClient } from '../../../../../App';
import {
    RESET_DATAPOINTS_CACHE,
    selectorDatapointsCache,
} from '../../../../../reducers/gerenciadorPrecos/datapointsCache';
import { selectorFiltersValues } from '../../../../../reducers/gerenciadorPrecos/filters/filtersValues';
import { selectorQuickActionFilters } from '../../../../../reducers/gerenciadorPrecos/quickActionsFilter';
import {
    RESET_SELECTED_DATAPOINTS,
    selectorSelectedDatapoints,
} from '../../../../../reducers/gerenciadorPrecos/selectedDatapoints';
import { applyPrices } from '../../services';
import { CREATE_MODEL_APPLY_PRICES } from '../../utils';

const useAppyPricesButton = () => {
    const dispatch = useDispatch();

    const filters = useSelector(selectorFiltersValues);

    const { selectedAll, selectedIds } = useSelector(
        selectorSelectedDatapoints,
    );

    const cachedDatapoints = useSelector(selectorDatapointsCache);

    const quickActionsFilter = useSelector(selectorQuickActionFilters);

    const { promiseInProgress: isLoading } = usePromiseTracker({
        area: 'get-datapoints-revisao',
        delay: 1500,
    });

    const isDisabled = useMemo(() => {
        return (!selectedAll && !selectedIds.length) || isLoading;
    }, [selectedAll, selectedIds, isLoading]);

    const isDisabledOption = useMemo(() => {
        return (
            selectedAll ||
            selectedIds.length !== 1 ||
            cachedDatapoints.selectedIds.find((item) => {
                return item.productsToBePricedId === selectedIds[0];
            })?.productFamilyId === null ||
            isLoading
        );
    }, [selectedAll, selectedIds, cachedDatapoints, isLoading]);

    const placeholder = useMemo(() => {
        return selectedAll || selectedIds.length !== 1
            ? 'Selecione apenas um produto para aplicar preços a família'
            : cachedDatapoints.selectedIds.find((item) => {
                return item.productsToBePricedId === selectedIds[0];
            })?.productFamilyId === null
                ? 'O produto selecionado não possui código de família.'
                : 'Não é possível aplicar o preço a família.';
    }, [selectedAll, selectedIds, cachedDatapoints]);

    const datapointExhibitionType = useSelector(
        (state: RootState) =>
            state.datapointExhibitionType.datapointExhibitionType,
    );

    const mutation = useMutation({
        mutationFn: applyPrices,
        onSuccess: () => {
            queryClient.invalidateQueries(['ipa.gerenciador.datapoints']);
            Alert.success('Precificação salva com sucesso!');
            dispatch(RESET_SELECTED_DATAPOINTS());
            dispatch(RESET_DATAPOINTS_CACHE());
        },
        onError: () => {
            Alert.error('Erro ao salvar precificação!');
        },
    });

    const handleApplyPrices = useCallback(
        async (applyToFamily = false) => {
            const model = CREATE_MODEL_APPLY_PRICES({
                applyToFamily,
                datapointExhibitionType,
                selectedAll,
                cachedDatapoints,
                filters,
                quickActionsFilter,
            });

            mutation.mutate(model);
        },
        [datapointExhibitionType, selectedAll, cachedDatapoints, filters, quickActionsFilter, mutation],
    );

    const memoValues = useMemo(
        () => ({
            isDisabled,
            isDisabledOption,
            placeholder,
            handleApplyPrices,
            isLoading: mutation.isLoading,
        }),
        [isDisabled, isDisabledOption, placeholder, handleApplyPrices, mutation.isLoading],
    );

    return memoValues;
};

export default useAppyPricesButton;
