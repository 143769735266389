import { useQuery } from '@tanstack/react-query';
import {
    getListaProdutos,
    getListaLojas,
    getListaTipoLojas,
    getListaCategoria1,
    getListaCategoria2,
    getListaCompetitividade,
} from '../services';
import { useContext, useState } from 'react';
// @ts-ignore
import { FiltrosContext } from '../Pages/CompetitividadeGeral/index.tsx';

export const useFiltros = () => {
    const filtros = useContext(FiltrosContext);
    const [searchProduto, setSearchProduto] = useState('');
    const listaLojas = async () => {
        const resp = await getListaLojas();
        if (resp)
            return resp.map((item: { store: string; store_id: number }) => ({
                label: item[Object.keys(item)[1]],
                value: item[Object.keys(item)[0]],
            }));
        return [];
    };

    const {
        isLoading: loadingLojas,
        isError,
        data: lojas,
        error,
    } = useQuery(['filtrolojas'], listaLojas, {
        retry: false,
    });

    const changeQuery = (value: string) => {
        setSearchProduto(value);
    };

    const searchProdutos = async (query: string) => {
        const response = await getListaProdutos(query);
        if (response) {
            return response
                .map(
                    (item: {
                        product_id: any;
                        description: string;
                        gtin: any;
                    }) => ({
                        label: `${item.product_id} - ${item.description}`,
                        value: `${item.product_id}`,
                        gtin: item.gtin,
                        product_id: item.product_id,
                    })
                )
                .concat(
                    filtros?.produtosSelecionados?.cache?.filter(
                        (itemCache: { product_id: any }) =>
                            response.data.findIndex(
                                (itemLista: { product_id: any }) =>
                                    itemLista.product_id ===
                                    itemCache.product_id
                            ) === -1
                    ) || []
                );
        }
        return [];
    };

    const {
        isLoading: loadingProdutos,
        // isError,
        data: produtos,
        // error,
    } = useQuery(
        ['filtroProdutos', searchProduto],
        () => searchProdutos(searchProduto),
        {
            retry: false,
        }
    );

    const listaTipoLojas = async () => {
        const resp = await getListaTipoLojas();
        if (resp)
            return resp
                .map((item: { [x: string]: string }) => ({
                    label: item[Object.keys(item)[0]],
                    value: item[Object.keys(item)[0]],
                }))
                .filter((item: { value: string }) => item.value);

        return [];
    };

    const {
        isLoading: loadingTipoLojas,
        // isError,
        data: tipoLojas,
        // error,
    } = useQuery(['filtroTipoLojas'], listaTipoLojas, {
        retry: false,
    });


    const listaCompetitividade = async () => {
        const resp = await getListaCompetitividade();
        if (resp)
            return resp
                .map((item: { [x: string]: string }) => ({
                    label: item[Object.keys(item)[0]],
                    value: item[Object.keys(item)[0]],
                }))
                .filter((item: { value: string }) => item.value);

        return [];
    };

    const {
        isLoading: loadingCompetitividade,
        // isError,
        data: competitividade,
        // error,
    } = useQuery(['filtroCompetitividade'], listaCompetitividade,
    {
        retry: false,
    });

    const listaCategoria1 = async () => {
        const resp = await getListaCategoria1();
        if (resp)
            return resp
                .map((item: { [x: string]: string }) => ({
                    label: item[Object.keys(item)[0]],
                    value: item[Object.keys(item)[0]],
                }))
                .filter((item: { value: string }) => item.value);

        return [];
    };

    const {
        isLoading: loadingCategoria1,
        // isError,
        data: categoria1,
        // error,
    } = useQuery(['filtroCategoria1'], listaCategoria1, {
        retry: false,
    });

    const listaCategoria2 = async () => {
        const resp = await getListaCategoria2();
        if (resp)
            return resp
                .map((item: { [x: string]: string }) => ({
                    label: item[Object.keys(item)[0]],
                    value: item[Object.keys(item)[0]],
                }))
                .filter((item: { value: string }) => item.value);

        return [];
    };

    const {
        isLoading: loadingCategoria2,
        // isError,
        data: categoria2,
        // error,
    } = useQuery(['filtroCategoria2'], listaCategoria2, {
        retry: false,
    });

    return {
        loadingLojas,
        isError,
        lojas,
        error,
        produtos,
        loadingProdutos,
        tipoLojas,
        loadingTipoLojas,
        loadingCategoria1,
        categoria1,
        loadingCategoria2,
        categoria2,
        competitividade,
        loadingCompetitividade,
        changeQuery,
    };
};
