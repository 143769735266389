import React from 'react';
import { usePromiseTracker } from 'react-promise-tracker';
import { LoadingMenu } from '../../../../../components/LoadingSpinner';
import { numberWithCommas } from '../utils';

const Totalizer = ({ datapoints }) => {
    const { promiseInProgress } = usePromiseTracker({ area: 'totalizer' });
    return (
        <div className={datapoints === 0 ? 'totalizer-container no-data' : 'totalizer-container'}>
            <p className="totalizer-text">Total de dados</p>
            <p className="totalizer-value">
                {datapoints || datapoints === 0 ? (
                    <>
                        {promiseInProgress ? null : <div>{numberWithCommas(datapoints)}</div>}
                        <LoadingMenu area="totalizer" size="sm" />
                    </>
                )
                    : (
                        <>
                            {promiseInProgress ? null : <span>-</span>}
                            <LoadingMenu area="totalizer" size="sm" />
                        </>
                    )}
            </p>
        </div>
    );
};

export default Totalizer;
