import Exception from './Exception';

class ExceptionSaveDatapoint extends Exception {
    constructor({
        productId,
        storeId,
        storeName,
    }: {
        productId: string;
        storeId: string;
        storeName: string;
    }) {
        const message = `Erro ao salvar alteração no produto
        ${productId} - ${storeId} - ${storeName}`;

        super(message);
    }
}

export default ExceptionSaveDatapoint;
