import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Modal, Button, Alert } from 'rsuite';

import { LoadingSpiner, LoadingSpinerWithMessage } from '../../../../../components/LoadingSpinner';
import {
    updateFreeData, getUpdateEnoughData, getCityList, getRegionAutocompleteOptions, setEnoughDataEditNull,
} from '../../../../../actions/actionsProductConfiguration';
import { addUserToDataUpdate, updateFreeUser } from '../../../../../services/FreemiumService';
import ChannelForm from './ChannelForm';
import RegionForm from './RegionForm';

class ModalEditRegionChannel extends Component {
    constructor(props) {
        super(props);

        this.state = {
            channel: '',
            region: '',
            city: '',
            state: '',
            autocompleteValue: '',
        };

        this.setChannel = this.setChannel.bind(this);
        this.setRegion = this.setRegion.bind(this);
        this.searchCity = this.searchCity.bind(this);
        this.changeAutocompleteValue = this.changeAutocompleteValue.bind(this);
        this.getDataQuantity = this.getDataQuantity.bind(this);
        this.setDataAlarm = this.setDataAlarm.bind(this);
        this.save = this.save.bind(this);
        this.setPayload = this.setPayload.bind(this);
        this.changeValue = this.changeValue.bind(this);
    }

    componentDidMount() {
        const { data } = this.props;
        this.setState({
            channel: data.channel,
            region: data.region,
            city: data.city,
            state: data.state,
            autocompleteValue: data.region === 'estado' ? data.state : data.city,
        });
    }

    setDataAlarm() {
        const payload = this.setPayload();

        addUserToDataUpdate(payload).then((response) => {
            if (response) {
                Alert.success('Sua solicitação foi cadastrada com sucesso.', 5000);
            }
        });
    }

    setPayload() {
        const {
            channel, region, state, city,
        } = this.state;

        return {
            email: JSON.parse(localStorage.getItem('usuario'))?.email,
            canal: channel || null,
            agrupamentoPais: region === 'pais',
            estado: region === 'estado' && state ? { value: state } : null,
            cidadeId: region === 'cidade' && city ? city : null,
        };
    }

    getDataQuantity() {
        const { getUpdateEnoughData } = this.props;
        const {
            channel, region, state, city,
        } = this.state;

        if (channel && region && (state || city)) {
            const model = this.setPayload();

            getUpdateEnoughData(model);
        }
    }

    setChannel(channel) {
        this.setState({
            channel,
        });
    }

    setRegion(region) {
        const { getRegionAutocompleteOptions, setEnoughDataEditNull } = this.props;
        if (region === 'estado' || region === 'cidade') {
            getRegionAutocompleteOptions(region);
        }

        this.setState({
            region,
            autocompleteValue: '',
        });

        setEnoughDataEditNull();
    }

    save() {
        const {
            confirm, onClose, type, changeType,
        } = this.props;
        const payload = this.setPayload();

        if (type === 'channel') {
            changeType('region');
        } else {
            updateFreeUser(payload).then((response) => {
                if (response) {
                    confirm();
                    onClose();
                }
            });
        }
    }

    searchCity(query, event) {
        if (event.type === 'change') {
            const { getCityList } = this.props;
            const { region } = this.state;

            if (region === 'cidade') {
                getCityList(query);
            }

            this.changeValue(query);
        }
    }

    changeValue(query) {
        this.setState({
            autocompleteValue: query,
        });
    }

    changeAutocompleteValue(item, key) {
        this.setState({
            [key]: key === 'state' ? item.name : item.codigo,
            autocompleteValue: key === 'state' ? item.descricao : item.name,
        }, this.getDataQuantity);
    }

    disabledSave() {
        const { hasEnoughData, type } = this.props;
        const { region, city, state } = this.state;

        if (type === 'region' && region === 'cidade' && !city) {
            return true;
        }
        if (type === 'region' && region === 'estado' && !state) {
            return true;
        }
        if (type === 'region' && region && hasEnoughData === false) {
            return true;
        }

        return false;
    }

    render() {
        const {
            show, onClose, type, states, cities, hasEnoughData,
        } = this.props;
        const {
            channel, region, state, city, autocompleteValue,
        } = this.state;

        return (
            <Modal className="modal-edit-region-channel" backdrop="static" show={show} onHide={onClose}>
                <LoadingSpinerWithMessage size="md" area="hasData" message="Aguarde... Verificando dados" />
                <LoadingSpiner size="md" />

                {type === 'channel'
                    ? (
                        <ChannelForm
                            channel={channel}
                            setChannel={this.setChannel}
                        />
                    )
                    : (
                        <RegionForm
                            region={region}
                            state={state}
                            city={city}
                            setRegion={this.setRegion}
                            setDataAlarm={this.setDataAlarm}
                            autocompleteValue={autocompleteValue}
                            changeAutocompleteValue={this.changeAutocompleteValue}
                            searchCity={this.searchCity}
                            states={states}
                            cities={cities}
                            hasEnoughData={hasEnoughData}
                        />
                    )}

                <Modal.Footer className="footer">
                    <Button
                        onClick={onClose}
                        id="btn-cancel"
                        className="footer__cancel-btn"
                        appearance="default"
                    >
                        Cancelar
                    </Button>
                    <Button
                        onClick={this.save}
                        id="btn-confirm"
                        className="footer__confirm-btn"
                        appearance="primary"
                        disabled={!!this.disabledSave()}
                    >
                        Confirmar
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

const mapStateToProps = (store) => ({
    states: store.productConfigurationDataReducer.states,
    cities: store.productConfigurationDataReducer.cities,
    hasEnoughData: store.productConfigurationDataReducer.hasEnoughData,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    updateFreeData,
    getUpdateEnoughData,
    getCityList,
    getRegionAutocompleteOptions,
    setEnoughDataEditNull,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ModalEditRegionChannel);
