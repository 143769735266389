import React from 'react';
import { Content, FlexboxGrid, Col } from 'rsuite';
import Slider from 'react-slick';

import logoInfoprice from '../assets/logo/logo-infoprice-preto-azul.svg';
import carouselImage1 from '../assets/image/home_carousel-1.svg';
import carouselImage2 from '../assets/image/home_carousel-2.svg';
import carouselImage3 from '../assets/image/home_carousel-3.svg';

const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    arrows: false,
    autoplay: true,
};

export const HomeCarouselComponent = (props) => (
    <Content className="home-carousel">
        <header>
            <img src={logoInfoprice} alt="Logo InfoPrice" />
        </header>
        <div className="carousel-content">
            <FlexboxGrid align="middle">
                <FlexboxGrid.Item componentClass={Col} xs={24}>
                    <Slider {...settings} className="carousel">
                        <div className="slide-home">
                            <div className="slide-home__img">
                                <img alt="Otimize sua competitividade" src={carouselImage1} />
                            </div>
                            <div className="slide-home__description">
                                <h5>Otimize sua competitividade</h5>
                                <p>Monitore os preços da concorrência e se torne mais competitivo</p>
                            </div>
                        </div>
                        <div className="slide-home">
                            <div className="slide-home__img">
                                <img alt="Aumente sua margem" src={carouselImage2} />
                            </div>
                            <div className="slide-home__description">
                                <h5>Aumente sua margem</h5>
                                <p>Negocie melhor e tenha uma precificação inteligente</p>
                            </div>
                        </div>
                        <div>
                            <div className="slide-home">
                                <div className="slide-home__img">
                                    <img alt="Dados de +42mil lojas" src={carouselImage3} />
                                </div>
                                <div className="slide-home__description">
                                    <h5>Acompanhe preços de + 42 mil lojas</h5>
                                    <p>Tenha dados de mercado para embasar sua estratégia de preços</p>
                                </div>
                            </div>
                        </div>
                    </Slider>
                </FlexboxGrid.Item>
            </FlexboxGrid>
        </div>
    </Content>
);
