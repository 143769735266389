import React from 'react';
import { useSelector } from 'react-redux';
import { Tooltip, Whisper } from 'rsuite';
import { FormBox } from '../FormBox';
import { InputAddon } from '../index';
import { selectorCalculadoraPrevisaoDemanda } from '../../../../reducers/calculadoraPrevisaoDemanda/calculadoraPrevisaoDemandaReducer';
import { useInputPrice } from '../../hooks';
import infoIcon from '../../../../assets/icons/icon_info_gray.svg';
import * as utils from '../../utils';
import styles from './PriceForm.module.scss';

export const PriceForm = () => {
    const { inputCalculos, filtros } = useSelector(
        selectorCalculadoraPrevisaoDemanda,
    );

    const { priceHandlers } = useInputPrice();

    return (
        <FormBox.Root title="Preços">
            <FormBox.Row>
                <FormBox.Label width="160px">
                    Preço atual tabela
                </FormBox.Label>
                <InputAddon
                    position="left"
                    addon="R$"
                    width="160px"
                    currency
                    maxLength={6}
                    value={inputCalculos?.inputs.base_price}
                    onChange={priceHandlers.handleOnChangePrecoAtual}
                />
            </FormBox.Row>
            <FormBox.Row>
                <div className={styles['label-wrapper']}>
                    <p className={styles.label}>
                        Novo preço tabela
                    </p>
                    <div className={styles['label-description-wrapper']}>
                        <p className={styles['label-description']}>
                            {utils.paymentTypeText(filtros.filterRequestBody.payment_method)}
                        </p>
                        <Whisper
                            trigger="hover"
                            placement="bottom"
                            speaker={(
                                <Tooltip>
                                    O percentual de forma de pagamento será acrescido
                                    ao preço de tabela no cálculo de demanda
                                </Tooltip>
                              )}
                        >
                            <img src={infoIcon} alt="" />
                        </Whisper>
                    </div>
                </div>
                <InputAddon
                    position="left"
                    addon="R$"
                    width="160px"
                    currency
                    maxLength={6}
                    value={inputCalculos?.inputs.new_price}
                    onChange={priceHandlers.handleOnChangeNovoPreco}
                />
            </FormBox.Row>
            <FormBox.Row>
                <FormBox.Label width="160px">
                    Variação preço tabela
                </FormBox.Label>
                <InputAddon
                    position="right"
                    addon="%"
                    width="160px"
                    currency
                    maxLength={6}
                    allowNegative
                    value={inputCalculos?.variacaoPrecoTabela}
                    onChange={priceHandlers.handleOnChangeVariacao}
                />
            </FormBox.Row>
            <FormBox.Row>
                <FormBox.Label width="160px">
                    Custo do produto
                </FormBox.Label>
                <InputAddon
                    position="left"
                    addon="R$"
                    width="160px"
                    currency
                    value={inputCalculos?.inputs.cost}
                    onChange={priceHandlers.handleOnChangeCusto}
                />
            </FormBox.Row>
            <FormBox.Row>
                <FormBox.Label width="160px">
                    Demanda média mensal
                </FormBox.Label>
                <InputAddon
                    position="right"
                    addon="un."
                    width="160px"
                    value={inputCalculos?.inputs.average_monthly_sales
                        ? inputCalculos?.inputs.average_monthly_sales.toFixed(0)
                        : ''}
                    onChange={priceHandlers.handleOnChangeDemandaPeriodo}
                />
            </FormBox.Row>
        </FormBox.Root>
  );
};
