import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    Button,
    CheckPicker,
    Checkbox,
    Col,
    FlexboxGrid,
    FormGroup,
    HelpBlock,
    Icon,
    Tooltip,
    Whisper,
} from 'rsuite';

import {
    changeAddProduct,
    changeCache,
    changeCheckAllProduct,
    changeRemoveProduct,
    changeValue,
    searchProdutos,
    selectValue,
} from '../../../../../actions/actionsPainelGeral';

import volumeBarBlueIcon from '../../../../../assets/icons/icon_volume_bar_blue.svg';
import volumeBarGreenIcon from '../../../../../assets/icons/icon_volume_bar_green.svg';
import volumeBarOrangeIcon from '../../../../../assets/icons/icon_volume_bar_orange.svg';
import { LoadingMenu } from '../../../../../components/LoadingSpinner';
import { getModelPanel } from '../utils';
import MobileProductFilter from './MobileProductFilter';

class ProductFilter extends Component {
    constructor(props) {
        super(props);

        this.state = {
            checkAllProduto: false,
            indeterminateProduto: false,
        };

        this.handleAddProduct = this.handleAddProduct.bind(this);
        this.handleFilterProduto = this.handleFilterProduto.bind(this);
        this.handleCheckAllProduto = this.handleCheckAllProduto.bind(this);
        this.renderTooltip = this.renderTooltip.bind(this);
        this.handleCleanProduct = this.handleCleanProduct.bind(this);
        this.handleOnOpen = this.handleOnOpen.bind(this);
        this.handleLoadMore = this.handleLoadMore.bind(this);
    }

    componentDidMount() {
        const { searchProdutos } = this.props;
        searchProdutos(getModelPanel(this.props, '', 'produto'));
    }

    shouldComponentUpdate(nextProps, nextState) {
        const { produto } = this.props;
        const { checkAllProduto, indeterminateProduto } = this.state;

        return (
            nextState.checkAllProduto !== checkAllProduto
            || nextState.indeterminateProduto !== indeterminateProduto
            || nextState.produto !== produto
        );
    }

    handleAddProduct(item) {
        const { changeAddProduct } = this.props;
        changeAddProduct(item);
        this.setState({ checkAllProduto: false });
    }

    handleSelect(value, item) {
        const { produto, changeRemoveProduct } = this.props;
        const hasValue = produto.value.includes(item.value);
        if (hasValue) {
            changeRemoveProduct(item.value);
        } else {
            this.handleAddProduct(item);
        }
    }

    handleFilterProduto(inputValue) {
        const { onSearchProduto } = this.props;
        onSearchProduto(inputValue);

        this.setState({
            checkAllProduto: false,
        });
    }

    handleCleanProduct() {
        const { changeCheckAllProduct } = this.props;
        changeCheckAllProduct([], []);
        this.handleFilterProduto();
    }

    handleCheckAllProduto(value, checked) {
        const { produto, changeCheckAllProduct } = this.props;
        let newCacheProduto;
        let newValueProduto;

        if (checked) {
            newCacheProduto = produto?.cache?.concat(
                produto?.lista?.filter(
                    (cache) => produto.cache.findIndex(
                        (item) => item.value === cache.value,
                    ) === -1,
                ),
            );
            newValueProduto = newCacheProduto.map((item) => item.value);
        } else {
            newCacheProduto = produto?.cache?.filter(
                (cacheItem) => produto?.lista?.findIndex(
                    (item) => cacheItem.label === item.label,
                ) === -1,
            );
            newValueProduto = [];
        }

        changeCheckAllProduct(newCacheProduto, newValueProduto);

        this.setState({
            indeterminateProduto: false,
            checkAllProduto: checked,
        });
    }

    handleOnOpen() {
        const { onOpenProduto } = this.props;
        onOpenProduto();
        const input = document.getElementsByClassName(
            'rs-picker-search-bar-input',
        )[0];
        input.focus();
    }

    handleLoadMore() {
        const { onClickLoadMore } = this.props;
        this.setState({
            checkAllProduto: false,
        });

        onClickLoadMore();
    }

    renderTooltip(message, icon) {
        return (
            <Whisper
                placement="top"
                trigger="hover"
                speaker={(
                    <Tooltip>
                        <p>{message}</p>
                    </Tooltip>
                )}
            >
                {icon}
            </Whisper>
        );
    }

    render() {
        const { usuario, produto } = this.props;
        const { indeterminateProduto, checkAllProduto } = this.state;

        return (
            <>
                <FlexboxGrid.Item
                    componentClass={Col}
                    xs={22}
                    lgHidden
                    mdHidden
                >
                    <MobileProductFilter />
                </FlexboxGrid.Item>
                <FlexboxGrid.Item
                    componentClass={Col}
                    lg={22}
                    md={20}
                    xsHidden
                    smHidden
                >
                    <FormGroup>
                        <CheckPicker
                            className="inf-picker"
                            block
                            id="product-filter-desktop"
                            disabledItemValues={produto.disabled}
                            value={produto.value}
                            data={produto.lista}
                            filter={false}
                            locale={{
                                noResultsText: 'Nenhum item encontrado',
                                searchPlaceholder:
                                    'Busque o identificador ou descrição do produto',
                            }}
                            name="produtos"
                            placeholder="Produto"
                            onSelect={(value, item) => this.handleSelect(value, item, 'Produto')}
                            onSearch={(search) => this.handleFilterProduto(search)}
                            onClean={this.handleCleanProduct}
                            maxHeight={200}
                            menuClassName="product-filter-menu"
                            onOpen={this.handleOnOpen}
                            renderMenuItem={(label, item) => (
                                <div>
                                    {item.datapoints < 30
                                        ? this.renderTooltip(
                                            'Alguns dados',
                                            <img
                                                src={volumeBarOrangeIcon}
                                                alt="volumeBarOrangeIcon"
                                                className="product-volume-icon"
                                            />,
                                        )
                                        : null}

                                    {item.datapoints >= 30
                                        && item.datapoints < 100
                                        ? this.renderTooltip(
                                            'Diversos dados',
                                            <img
                                                src={volumeBarBlueIcon}
                                                alt="volumeBarBlueIcon"
                                                className="product-volume-icon"
                                            />,
                                        )
                                        : null}

                                    {item.datapoints >= 100
                                        ? this.renderTooltip(
                                            'Muitos dados',
                                            <img
                                                src={volumeBarGreenIcon}
                                                alt="volumeBarGreenIcon"
                                                className="product-volume-icon"
                                            />,
                                        )
                                        : null}

                                    <span>{label}</span>

                                    {usuario?.servicoPanel?.pacotePainel
                                        ?.name === 'LIMIT'
                                        && item.limited === false ? (
                                        <div
                                            title="Acesso total"
                                            style={{
                                                display: 'inline-block',
                                                float: 'right',
                                                color: '#1e88e5',
                                            }}
                                        >
                                            <Icon
                                                icon="twinkle-star"
                                                style={{
                                                    fontSize: '16px',
                                                    fontWeight: '600',
                                                }}
                                            />
                                        </div>
                                    ) : null}
                                </div>
                            )}
                            renderExtraFooter={() => (
                                <div className="product-filter-menu__footer">
                                    <Checkbox
                                        inline
                                        indeterminate={indeterminateProduto}
                                        checked={checkAllProduto}
                                        onChange={this.handleCheckAllProduto}
                                    >
                                        Selecionar tudo
                                        {' '}
                                        <span>
                                            {produto?.lista?.length
                                                ? `(${produto.lista.length})`
                                                : null}
                                        </span>
                                    </Checkbox>
                                    <Button
                                        appearance="ghost"
                                        onClick={this.handleLoadMore}
                                        disabled={produto?.lista?.length >= 1000}
                                    >
                                        Carregar mais
                                    </Button>
                                </div>
                            )}
                            renderMenu={(menu) => (
                                <span>
                                    <LoadingMenu area="panel-filter" />
                                    {menu}
                                </span>
                            )}
                        />
                        {produto?.disabled?.length ? (
                            <HelpBlock
                                className="warning-no-data"
                                name="noDataFilterWarning"
                            >
                                Existem opções removidas neste filtro por não
                                possuirem dados com os filtros alterados
                            </HelpBlock>
                        ) : null}
                    </FormGroup>
                </FlexboxGrid.Item>
                <FlexboxGrid.Item componentClass={Col} lg={1} md={2} xs={2}>
                    <Whisper
                        placement={window.innerWidth < 995 ? 'left' : 'right'}
                        trigger="hover"
                        speaker={(
                            <Tooltip>
                                <p>
                                    Você pode copiar os códigos identificadores
                                    do Excel e colar no campo de busca de uma
                                    vez para selecionar diversos produtos ao
                                    mesmo tempo. Produtos não encontrados no
                                    painel serão desconsiderados e produtos
                                    duplicados serão considerados apenas uma
                                    vez.
                                </p>
                            </Tooltip>
                        )}
                    >
                        <Icon className="tooltip-info" icon="info" />
                    </Whisper>
                </FlexboxGrid.Item>
            </>
        );
    }
}

const mapStateToProps = (store) => ({
    produto: store.painelGeralDataReducer.produto,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
    {
        changeCache,
        changeValue,
        searchProdutos,
        selectValue,
        changeAddProduct,
        changeRemoveProduct,
        changeCheckAllProduct,
    },
    dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(ProductFilter);
