import React from 'react';
import {
    shallowEqual, useDispatch, useSelector,
} from 'react-redux';
import {
    FormGroup,
    HelpBlock,
    Icon,
    Col,
    IconButton,
    CheckPicker,
} from 'rsuite';

import { LoadingMenu } from '../../../../../components/LoadingSpinner';
import {
    getItensFiltro,
    clearFilter,
    selectFilter,
} from '../../../../../actions/actionsRevisaoLista';
import { setFiltersModelListaCadastrada } from '../utils';

const FiltrosAdicionados = ({ filtro, index, handleDeleteFiltro }) => {
    const dispatch = useDispatch();
    const { filtrosData } = useSelector(
        (state) => ({
            filtrosData: state.revisaoListaDataReducer.filtrosData,
        }),
        shallowEqual,
    );

    const selectItem = (value, item, name) => {
        const cache = [...filtrosData[name].cache, item];
        let updatedCache = cache;

        if (!value.length) {
            dispatch(getItensFiltro(setFiltersModelListaCadastrada(name, '', filtrosData), name));
            updatedCache = [];
        }

        if (value !== null && value.length !== cache.length) {
            updatedCache = cache.filter(
                (item) => value.indexOf(item.label) !== -1,
            );
        }

        dispatch(selectFilter(name, updatedCache, value));
    };

    return (
        <Col lg={8} md={9}>
            <Col xs={20}>
                <FormGroup>
                    <CheckPicker
                        className="inf-picker"
                        block
                        cleanable={filtro.cleanable}
                        searchable={filtro.searchable}
                        filter={filtro.filter}
                        data={filtrosData[filtro.name].data}
                        value={filtrosData[filtro.name].value}
                        name={filtro.name}
                        placeholder={filtro.placeholder}
                        locale={filtro.locale}
                        onSearch={(query) => dispatch(getItensFiltro(setFiltersModelListaCadastrada(filtro.name, query, filtrosData), filtro.name))}
                        onOpen={() => dispatch(getItensFiltro(setFiltersModelListaCadastrada(filtro.name, '', filtrosData), filtro.name))}
                        onSelect={(value, item) => selectItem(value, item, filtro.name)}
                        onClean={() => dispatch(clearFilter(filtro.name))}
                        renderMenu={(menu) => (
                            <span>
                                <LoadingMenu area="revisao-filter" />
                                {menu}
                            </span>
                        )}
                    />
                    <HelpBlock className="error" name="noDataFilterWarning">
                        {filtrosData[filtro.name].erro}
                    </HelpBlock>
                </FormGroup>
            </Col>
            <Col>
                <IconButton style={{ marginLeft: '10px' }} icon={<Icon icon="trash2" />} onClick={() => handleDeleteFiltro(index, filtro.name)} />
            </Col>
        </Col>
    );
};

export default FiltrosAdicionados;
