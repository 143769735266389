import React from 'react';
import { Table } from 'rsuite';
import { TableProps } from 'rsuite/lib/Table';
import { HeaderCell } from '.';
import { LoadingSpinerLocal } from '../../../../../../components/LoadingSpinner';
import { useGerenciadorPrecos } from '../../../context';
import DATA from './columns';
import './style.scss';
import { COLUMN_PROPS } from './types';

export type DATA_TABLE_PROPS = {
    table?: TableProps;
    content: COLUMN_PROPS[];
};

export const GerenciadorTable = ({
    className = '',
    ...props
}: Omit<TableProps, 'data'>) => {
    const {
        data,
        pagination,
        handleChangePage,
        handleChangeLength,
        handleSortColumn,
        sort,
    } = useGerenciadorPrecos();

    return (
        <>
            <Table
                onSortColumn={handleSortColumn}
                className={['gerenciador-table', className].join(' ')}
                sortColumn={sort.type}
                data={data.content}
                renderLoading={() => (
                    // @ts-ignore
                    <LoadingSpinerLocal loading={props.loading} />
                )}
                {...DATA.table}
                {...props}
            >
                {DATA.content.map(
                    ({
                        header,
                        cell,
                        column: { dataKey, name, ...column },
                    }) => (
                        // @ts-ignore
                        <Table.Column
                            key={dataKey}
                            verticalAlign="middle"
                            {...column}
                        >
                            <HeaderCell
                                className="gerenciador-table__header-cell"
                                {...header}
                            >
                                {name}
                            </HeaderCell>
                            <Table.Cell dataKey={dataKey} {...cell} />
                        </Table.Column>
                    ),
                )}
            </Table>
            <Table.Pagination
                lengthMenu={[
                    {
                        value: 20,
                        label: 20,
                    },
                    {
                        value: 40,
                        label: 40,
                    },
                ]}
                activePage={pagination.activePage + 1}
                displayLength={pagination.displayLength}
                total={data.totalElements}
                onChangePage={handleChangePage}
                onChangeLength={handleChangeLength}
            />
        </>
    );
};
