import React from 'react';
import { PageHeader, PageSection, PageSubtitle, PageTitle } from './components';
import './style.scss';

export type PageLayoutProps = React.HtmlHTMLAttributes<HTMLElement> & {
    fullScreen?: boolean;
};

export const Layout = ({
    children,
    className = '',
    fullScreen = false,
    ...props
}: PageLayoutProps) => (
    <main
        className={[
            'page',
            fullScreen ? 'page-full-screen' : '',
            className,
        ].join(' ')}
        {...props}
    >
        {children}
    </main>
);

Layout.Header = PageHeader;
Layout.Title = PageTitle;
Layout.Subtitle = PageSubtitle;
Layout.Section = PageSection;
