import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Content } from 'rsuite';
import { LoadingSpiner } from '../../../../components/LoadingSpinner';

import { getGrafico } from '../../../../services/AnaliseService';

const Acompanhamento = () => {
    const { id } = useParams();

    const getEmbed = (id) => {
        getGrafico(id).then((data) => {
            if (data) {
                const iframe = document.getElementById('reportContainer');
                iframe.setAttribute('src', data.url);
            }
        });
    };

    useEffect(() => {
        getEmbed(id);
    });

    return (
        <Container className="main-container acompanhamento-page">
            <LoadingSpiner size="md" />
            <Content className="content">
                <iframe title="acompanhamento" id="reportContainer" className="content__iframe" />
            </Content>
        </Container>
    );
};
export default Acompanhamento;
