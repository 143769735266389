import { useState } from 'react';
import { TablePaginationProps } from 'rsuite/lib/Table/TablePagination';

const DEFAULT_PAGINATION_OPTIONS = [
    {
        value: 20,
        label: 20,
    },
    {
        value: 40,
        label: 40,
    },
];

const COMPENSATION_VALUE = 1;

type IUsePagination = Omit<TablePaginationProps, 'activePage'> & {
    activePage: number;
};

export const usePagination = ({
    lengthMenu = DEFAULT_PAGINATION_OPTIONS,
    total = 0,
}): IUsePagination => {
    const [activeIndex, setActivePage] = useState(0);
    const [pageSize, setPageSize] = useState(20);

    const lastValidIndex = Math.ceil(total / pageSize);

    const isOnInvalidLastIndex =
        activeIndex + COMPENSATION_VALUE > lastValidIndex;

    const handleChangePage = (page: number) => {
        setActivePage(page - COMPENSATION_VALUE);
    };

    const handleChangeLength = (size: number) => {
        setPageSize(size);
    };

    if (total && isOnInvalidLastIndex) {
        handleChangePage(lastValidIndex);
    }

    const activePage = activeIndex + COMPENSATION_VALUE;

    return {
        lengthMenu,
        activePage,
        displayLength: pageSize,
        total: total ?? 0,
        onChangePage: handleChangePage,
        onChangeLength: handleChangeLength,
        lastValidPage: lastValidIndex,
        isOnInvalidLastIndex,
        requestPage: isOnInvalidLastIndex ? lastValidIndex : activePage,
    };
};
