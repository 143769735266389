import classNames from 'classnames';
import React from 'react';
import CurrencyFormat from 'react-currency-format';

const CompetitorsSection = ({ className = '', ...props }) => (
    <section className={classNames(['competitors-section', className])} {...props} />
);

const Title = ({ className = '', ...props }) => (
    <span
        className={classNames(['competitor-title-concorrencia', className])}
        {...props}
    />
);

type SubtitleProps = Omit<
    React.HtmlHTMLAttributes<HTMLSpanElement>,
    'children'
> & {
    total?: number;
};

const Subtitle = ({ className = '', total, ...props }: SubtitleProps) => (
    <span
        className={classNames([
            'competitor-produto-encontrado-concorrencia',
            className,
        ])}
        {...props}
    >
        {total && (
            <>
                Produto encontrado em
                <span className="competitor-title-concorrencia-amount-store">
                    {' '}
                    {total}
                    {' '}
                    loja
                    {total > 1 ? 's' : ''}
                </span>
            </>
        )}
    </span>
);

const Header = ({ className = '', ...props }) => (
    <span
        className={classNames(['competitor-title-concorrencia-card', className])}
        {...props}
    />
);

Header.Title = Title;
Header.Subtitle = Subtitle;

export type CompetitorProps = Record<
    | 'competitorAverage'
    | 'competitorHigher'
    | 'competitorLower'
    | 'countCompetitorStore',
    number
>;

export type CardResumoConcorrenteProps = React.HtmlHTMLAttributes<HTMLDivElement> & {
    competitorAverage?: number
    competitorLower?: number
    competitorHigher?: number
    totalStores?: number
    title?: string
};

const CardResumoConcorrente = ({
    className = '',
    totalStores,
    competitorAverage,
    competitorLower,
    competitorHigher,
    title,
    ...props
}: CardResumoConcorrenteProps) => (
    <div
        className={classNames(['competitor-card-concorrencia', className])}
        {...props}
    >
        <Header>
            <Header.Title>
                {title}
            </Header.Title>
            <Header.Subtitle total={totalStores} />
        </Header>
        <div className="competitor-info-concorrencia">

            <div>
                <span>
                    <CurrencyFormat
                        fixedDecimalScale
                        decimalScale={2}
                        value={competitorAverage}
                        displayType="text"
                        prefix=" R$"
                        decimalSeparator=","
                        thousandSeparator="."
                    />
                </span>
                <h6>
                    x̄ Preço
                    {' '}
                    <br />
                    Médio
                </h6>
            </div>

            <div>
                <span>
                    <CurrencyFormat
                        fixedDecimalScale
                        decimalScale={2}
                        value={competitorLower}
                        displayType="text"
                        prefix=" R$"
                        decimalSeparator=","
                        thousandSeparator="."
                    />
                </span>
                <h6>
                    {'<'}
                    {' '}
                    Preço
                    {' '}
                    <br />
                    {' '}
                    Mais Baixo
                </h6>
            </div>

            <div>
                <span>
                    <CurrencyFormat
                        fixedDecimalScale
                        decimalScale={2}
                        value={competitorHigher}
                        displayType="text"
                        prefix=" R$"
                        decimalSeparator=","
                        thousandSeparator="."
                    />
                </span>
                <h6>
                    {'>'}
                    {' '}
                    Preço
                    {' '}
                    <br />
                    {' '}
                    Mais Alto
                </h6>
            </div>
        </div>
    </div>
);
export { CardResumoConcorrente, CompetitorsSection };
