import React, { HtmlHTMLAttributes, forwardRef } from 'react';
import {
    ButtonPrimary,
    ButtonPrimaryProps,
} from '../../../../../../../components';
import { Chip, Logo } from '../../../../components';
import { handleLogout } from '../../../../lib';
import './style.scss';

const Layout = ({
    className = '',
    children,
    ...props
}: React.HTMLAttributes<HTMLElement>) => (
    <section
        className={['onboarding-component', className].join(' ')}
        {...props}
    >
        <Logo>
            <Logo.Container>
                <Logo.Label>IPA | Software de Precificação</Logo.Label>
                <Chip>LITE</Chip>
            </Logo.Container>
        </Logo>
        {children}
        <button
            onClick={handleLogout}
            id="logout-btn"
            className="onboarding-component__logout-btn"
        >
            Sair
        </button>
    </section>
);

const Container = forwardRef<
    HTMLDivElement,
    React.HTMLAttributes<HTMLDivElement>
>(({ className = '', ...props }, ref) => (
    <div
        className={['onboarding-component__container', className].join(' ')}
        ref={ref}
        {...props}
    />
));

const Content = forwardRef<HTMLDivElement, HtmlHTMLAttributes<HTMLDivElement>>(
    ({ className = '', ...props }, ref) => (
        <div
            className={['onboarding-component__content', className].join(' ')}
            {...props}
            ref={ref}
        />
    ),
);

const Section = forwardRef<HTMLDivElement, HtmlHTMLAttributes<HTMLDivElement>>(
    ({ className = '', ...props }, ref) => (
        <div
            className={['onboarding-component__section', className].join(' ')}
            {...props}
            ref={ref}
        />
    ),
);

const Title = forwardRef(
    (
        {
            children,
            className = '',
            ...props
        }: React.HTMLAttributes<HTMLHeadingElement>,
        ref: React.LegacyRef<HTMLHeadingElement>,
    ) => (
        <h2
            className={`onboarding-component__title font-size-400-semibold ${className}`}
            ref={ref}
            {...props}
        >
            {children}
        </h2>
    ),
);

const ButtonGroup = forwardRef<
    HTMLDivElement,
    React.HTMLAttributes<HTMLDivElement>
>(({ className = '', ...props }, ref) => (
    <div
        className={['onboarding-component__button__group', className].join(' ')}
        ref={ref}
        {...props}
    />
));

const Button = ({ className = '', ...props }: ButtonPrimaryProps) => (
    <ButtonPrimary
        skin="blue"
        fullWidth
        size="medium"
        className={['onboarding-component__button', className].join(' ')}
        {...props}
    />
);

Layout.Title = Title;
Layout.Container = Container;
Layout.Content = Content;
Layout.Section = Section;
Layout.ButtonGroup = ButtonGroup;
Layout.Button = Button;

export { Layout };
