import classNames from 'classnames';
import React, { useState } from 'react';
import { MdGroupWork, MdStars } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip, Whisper } from 'rsuite';
import { GerenciadorPrecos } from '../../../../../../@types/GerenciadorPrecos';
import { TableBadge } from '../../../../../../components';
import { SET_GET_FAMILY_MODAL_IS_OPEN, selectorDataPointsExhibitionType } from '../../../../../../reducers/gerenciadorPrecos';
import { GetFamilyListModal } from '../../GetFamilyListModal';
import styles from './ProductCell.module.scss';

type RowData = GerenciadorPrecos.RowData;

export type ProductCellProps = {
    rowData: RowData;
    handleContextMenu: (rowData: RowData) => void;
} & React.HtmlHTMLAttributes<HTMLDivElement>;

export const ProductCell = ({
    className,
    rowData,
    handleContextMenu,
    ...props
}: ProductCellProps) => {
    const [modalOpen, setModalOpen] = useState(false);
    const datapointsExibitionType = useSelector(
        selectorDataPointsExhibitionType,
    );

    const dispatch = useDispatch();

    const handleOpenModalFamilyGroup = () => {
        if (datapointsExibitionType === 'FAMILY') {
            setModalOpen(true);
            dispatch(SET_GET_FAMILY_MODAL_IS_OPEN(true));
        }
    };

    const {
        productId = '',
        description = '--',
        productFamilyId = undefined,
        isProductMaster = false,
    } = rowData;

    return (
        <>
            <div
                className={classNames(styles.container, className)}
                onContextMenu={(e) => {
                    props.onContextMenu?.(e);
                    handleContextMenu?.(rowData);
                }}
                {...props}
            >
                <div className={styles.header}>
                    <span className={styles.product_id}>{productId}</span>
                    {productFamilyId && (
                        <Whisper
                            delayShow={0.2}
                            trigger="hover"
                            placement="right"
                            speaker={<Tooltip>{productFamilyId}</Tooltip>}
                        >
                            {rowData.itemsCountFamily !== null && rowData.itemsCountFamily > 0 ? (
                                <>
                                    <TableBadge
                                        skin="light-blue"
                                        onClick={() => handleOpenModalFamilyGroup()}
                                    >
                                        <MdGroupWork />
                                        {`${rowData.itemsCountFamily}` + (rowData.itemsCountFamily > 1 ? ' ITEMS' : ' ITEM')}
                                    </TableBadge>
                                    <TableBadge
                                        skin="blue"
                                        onClick={() => handleOpenModalFamilyGroup()}
                                    >
                                        <MdStars />
                                        {productFamilyId}
                                    </TableBadge>
                                </>
                            ) : (
                                <TableBadge
                                    skin="light-blue"
                                    onClick={() => {
                                        handleOpenModalFamilyGroup();
                                    }}
                                >
                                    <MdGroupWork />
                                    {productFamilyId}
                                </TableBadge>
                            )}
                        </Whisper>
                    )}
                    {isProductMaster && (
                        <TableBadge skin="blue">
                            <MdStars />
                            PAI
                        </TableBadge>
                    )}
                </div>
                <Whisper
                    delayShow={0.2}
                    trigger="hover"
                    placement="right"
                    speaker={<Tooltip>{description}</Tooltip>}
                >
                    <p className={styles.description}>{description}</p>
                </Whisper>
            </div>
            <GetFamilyListModal
                rowData={rowData}
                show={modalOpen}
                onHide={() => setModalOpen(false)}
            />
        </>
    );
};
