import React from 'react';
import {
    RadioGroup,
    Radio,
    Whisper,
    Tooltip,
    Input,
    Toggle,
    SelectPicker,
    CheckPicker,
    DatePicker,
} from 'rsuite';
import { useForm, Controller } from 'react-hook-form';
import { isBefore } from 'date-fns';

import infoIcon from '../../../../../../assets/icons/icon_info-gray.svg';
import { dailyFrequency, weeklyFrequency } from '../../utils';
import styles from './styles.module.scss';

export const SaveReportNameConfigContentForm = ({ submit, formRef }) => {
    const {
        handleSubmit, control, watch, setValue,
    } = useForm({
        defaultValues: {
            fileName: '',
            recorrencia: true,
            repetition: 'DIARIA',
            dailyFrequency: 'WEEKLY',
        },
    });

    const isRepetition = watch('repetition');
    const isRecorrencia = watch('recorrencia');

    const handleRadioGroupChange = (value, onChange) => {
        if (value === 'DIARIA') {
            setValue('dailyFrequency', 'WEEKLY');
        } else {
            setValue('weeklyFrequency', ['SEG']);
        }
        onChange(value);
    };

    return (
        <form ref={formRef} onSubmit={handleSubmit(submit)}>
            <div>
                <label htmlFor="input-name" className={styles.label}>
                    Nome do relatório
                </label>
                <Controller
                    name="fileName"
                    control={control}
                    rules={{ required: true }}
                    render={({
                        field: { value, onChange },
                        fieldState: { error },
                    }) => (
                        <>
                            <Input
                                name="fileName"
                                value={value}
                                className={`${styles[
                                    error
                                        ? 'input-name-error'
                                        : 'input-name'
                                    ]
                                    }`}
                                onChange={onChange}
                            />
                            {error ? (
                                <p className={styles['error-text']}>
                                    Para salvar, informe um nome para o
                                    relatório
                                </p>
                            ) : null}
                        </>
                    )}
                />
            </div>
            <div className={styles['toggle-wrapper']}>
                <Controller
                    name="recorrencia"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                        <Toggle
                            size="sm"
                            defaultChecked
                            value={value}
                            onChange={onChange}
                        />
                    )}
                />
                <p className={styles['bold-text']}>RECORRÊNCIA</p>
                <p className={styles['sub-text']}>
                    Defina uma recorrência para geração automática do relatório
                </p>
            </div>
            {isRecorrencia ? (
                <div className={styles['time-frequency-container']}>
                    <div className={styles['radio-wrapper']}>
                        <p className={styles['bold-text']}>REPETIÇÃO</p>
                        <Controller
                            name="repetition"
                            control={control}
                            rules={{ required: isRecorrencia }}
                            render={({ field: { value, onChange } }) => (
                                <RadioGroup
                                    inline
                                    value={value}
                                    onChange={(v) => handleRadioGroupChange(v, onChange)}
                                >
                                    <Radio value="DIARIA">Diária</Radio>
                                    <Radio value="SEMANAL">Semanal</Radio>
                                </RadioGroup>
                            )}
                        />
                    </div>
                    <div className={styles['select-wrapper']}>
                        {isRepetition === 'DIARIA' ? (
                            <Controller
                                name="dailyFrequency"
                                control={control}
                                rules={{ required: isRecorrencia }}
                                render={({ field: { onChange } }) => (
                                    <SelectPicker
                                        searchable={false}
                                        data={dailyFrequency}
                                        defaultValue={dailyFrequency[0].value}
                                        onChange={onChange}
                                        cleanable={false}
                                    />
                                )}
                            />
                        ) : (
                            <Controller
                                name="weeklyFrequency"
                                control={control}
                                rules={{ required: isRecorrencia }}
                                render={({
                                    field: { onChange },
                                    fieldState: { error },
                                }) => (
                                    <>
                                        <CheckPicker
                                            searchable={false}
                                            data={weeklyFrequency}
                                            defaultValue={[
                                                weeklyFrequency[0].value,
                                            ]}
                                            onChange={onChange}
                                            cleanable={false}
                                            className={
                                                error
                                                    ? styles[
                                                    'check-picker-error'
                                                    ]
                                                    : ''
                                            }
                                        />
                                        {error ? (
                                            <p className={styles['error-text']}>
                                                Para salvar, selecione um dia da
                                                semana
                                            </p>
                                        ) : null}
                                    </>
                                )}
                            />
                        )}
                    </div>
                    <div className={styles['date-wrapper']}>
                        <p className={styles['bold-text']}>INICIA EM</p>
                        <div className={styles['date-container']}>
                            <Controller
                                name="initialDate"
                                control={control}
                                rules={{ required: isRecorrencia }}
                                render={({
                                    field: { onChange },
                                    fieldState: { error },
                                }) => (
                                    <>
                                        <DatePicker
                                            placeholder="Selecione a data"
                                            format="DD/MM/YYYY"
                                            ranges={[]}
                                            onChange={onChange}
                                            placement="autoVerticalStart"
                                            className={
                                                error
                                                    ? styles[
                                                    'check-picker-error'
                                                    ]
                                                    : ''
                                            }
                                            disabledDate={(date) => {
                                                const t = date;
                                                t.setSeconds(
                                                    t.getSeconds() + 10,
                                                );

                                                return isBefore(t, new Date());
                                            }}
                                        />
                                        {error ? (
                                            <p className={styles['error-text']}>
                                                Para salvar, selecione uma data
                                            </p>
                                        ) : null}
                                    </>
                                )}
                            />
                        </div>
                        <Whisper
                            placement="right"
                            speaker={(
                                <Tooltip>
                                    No dia, o relatório será gerado assim que os
                                    dados do InfoPanel estiverem atualizados
                                </Tooltip>
                            )}
                        >
                            <img src={infoIcon} alt="" />
                        </Whisper>
                    </div>
                </div>
            ) : null}
        </form>
    );
};
