import React from 'react';
import styles from './styles.module.scss';

export const QuantityBox = ({
    quantity, quantitySelected, title, className,
}) => (
    <div className={`${styles['quantity-box']} ${className}`}>
        <div className={styles.quantity}>{quantity}</div>
        <div className={styles['quantity-title']}>{title}</div>
        <div
            className={styles['quantity-subtitle']}
        >
            {`de ${quantitySelected} selecionados`}

        </div>
    </div>
);
