import { zodResolver } from '@hookform/resolvers/zod';
import { useQuery } from '@tanstack/react-query';
import classNames from 'classnames';
import { isBefore } from 'date-fns';
import React, { HtmlHTMLAttributes, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Modal, Toggle } from 'rsuite';
import { ModalProps } from 'rsuite/lib/Modal';
import { z } from 'zod';
import { ButtonPrimary, InputBox } from '../../../../../../components';
import { LoadingSpinerArea } from '../../../../../../components/LoadingSpinner';
import { getProductSuppliersData } from '../../../../../../services/ProdutoService_ts';
import styles from './SalvarNegociacaoModal.module.scss';

const SalvarNegociacaoSchema = z.object({
    name: z
        .string({ required_error: 'O campo é obrigatório' })
        .min(1, 'O campo precisa ter no mínimo 1 caracter'),
    supplier: z
        .string({ required_error: 'O campo é obrigatório' })
        .min(1, 'O campo é obrigatório'),
    startDate: z.date(),
    endDate: z.date().optional(),
    enableFinalDate: z.boolean().optional(),
});

export type SalvarNegociacaoSchemaProps = z.infer<
    typeof SalvarNegociacaoSchema
>;

export type SalvarNegociacaoModalProps = ModalProps &
    Omit<HtmlHTMLAttributes<HTMLElement>, 'onSubmit'> & {
        values?: SalvarNegociacaoSchemaProps & { enableFinalDate: boolean };
        defaultValues?: Partial<SalvarNegociacaoSchemaProps>;
        queryKey?: string[];
        onSubmit?: (data: SalvarNegociacaoSchemaProps) => void;
    };

export const SalvarNegociacaoModal = ({
    className,
    defaultValues,
    values,
    queryKey = ['negotiation/save-negotiation-suppliers'],
    onSubmit,
    onHide,
    ...props
}: SalvarNegociacaoModalProps) => {
    const {
        control,
        formState: { isDirty, isValid },
        handleSubmit,
        register,
        resetField,
        reset,
        watch,
    } = useForm<SalvarNegociacaoSchemaProps>({
        defaultValues,
        values,
        mode: 'onChange',
        resolver: zodResolver(SalvarNegociacaoSchema),
    });

    const [query, setQuery] = useState('');

    const { data, isLoading } = useQuery({
        queryKey: [...queryKey, query],
        initialData: [],
        queryFn: async () => {
            const response = await getProductSuppliersData({
                search: query,
            });
            return response.map((item) => ({
                value: item.supplier,
                label: item.supplier,
            }));
        },
    });

    const handleHide = (e: React.MouseEvent<Element, MouseEvent>) => {
        reset();
        onHide?.(e);
    };

    return (
        <>
            <Modal
                className={classNames(
                    'info-modal',
                    styles['salvar-negociacao-modal'],
                    className,
                )}
                onHide={handleHide}
                {...props}
            >
                <form
                    onSubmit={handleSubmit((data) => {
                        const validSchema =
                            SalvarNegociacaoSchema.safeParse(data);
                        if (!validSchema.success) return;
                        onSubmit?.({
                            ...validSchema.data,
                            endDate: watch('enableFinalDate')
                                ? validSchema.data.endDate
                                : undefined,
                        });
                    })}
                >
                    <Modal.Header>
                        <Modal.Title className="font-size-200-semibold">
                            Salvar Negociação
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body
                        className={styles['salvar-negociacao-modal__body']}
                    >
                        <InputBox className={styles['inputbox-name']}>
                            <InputBox.Label htmlFor="name">
                                Nome da negociação
                            </InputBox.Label>
                            <InputBox.Input {...register('name')} />
                        </InputBox>

                        <InputBox className={styles['inputbox-supplier']}>
                            <InputBox.Label>Fornecedor</InputBox.Label>
                            <Controller
                                name="supplier"
                                control={control}
                                render={({ field: { value, onChange } }) => {
                                    return (
                                        <InputBox.Select
                                            data={data}
                                            value={value}
                                            onChange={onChange}
                                            onSearch={setQuery}
                                            isLoading={isLoading}
                                            placeholder="Selecione um fornecedor"
                                            locale={{
                                                searchPlaceholder:
                                                    'Busque um fornecedor',
                                                noResultsText: isLoading
                                                    ? 'Carregando fornecedores...'
                                                    : 'Nenhum fornecedor encontrado',
                                            }}
                                        />
                                    );
                                }}
                            />
                        </InputBox>
                        <InputBox className={styles['inputbox-start']}>
                            <InputBox.Label>Início de vigência</InputBox.Label>
                            <Controller
                                name="startDate"
                                control={control}
                                render={({ field: { value, onChange } }) => {
                                    return (
                                        <InputBox.DatePicker
                                            value={value}
                                            onChange={onChange}
                                            name="startDate"
                                            format="DD/MM/YYYY"
                                            ranges={[]}
                                            locale={{
                                                sunday: 'Dom',
                                                monday: 'Seg',
                                                tuesday: 'Ter',
                                                wednesday: 'Qua',
                                                thursday: 'Qui',
                                                friday: 'Sex',
                                                saturday: 'Sáb',
                                                today: 'Hoje',
                                            }}
                                            disabledDate={(date) => {
                                                if (!date) return false;
                                                return isBefore(date, value);
                                            }}
                                            onClean={() => {
                                                resetField('startDate');
                                            }}
                                            block
                                            oneTap
                                        />
                                    );
                                }}
                            />
                        </InputBox>
                        <InputBox className={styles['inputbox-end']}>
                            <Controller
                                name="enableFinalDate"
                                control={control}
                                render={({ field: { value, onChange } }) => {
                                    return (
                                        <Toggle
                                            size="sm"
                                            checked={value}
                                            onChange={(checked, e) => {
                                                onChange({
                                                    ...e,
                                                    target: { value: checked },
                                                });
                                            }}
                                        />
                                    );
                                }}
                            />
                            <InputBox.Label>Final de vigência</InputBox.Label>
                            <Controller
                                name="endDate"
                                control={control}
                                render={({ field: { value, onChange } }) => {
                                    return (
                                        <InputBox.DatePicker
                                            value={value}
                                            onChange={onChange}
                                            name="endDate"
                                            format="DD/MM/YYYY"
                                            ranges={[]}
                                            locale={{
                                                sunday: 'Dom',
                                                monday: 'Seg',
                                                tuesday: 'Ter',
                                                wednesday: 'Qua',
                                                thursday: 'Qui',
                                                friday: 'Sex',
                                                saturday: 'Sáb',
                                                today: 'Hoje',
                                            }}
                                            disabledDate={(date) => {
                                                if (!date) return true;
                                                return isBefore(
                                                    date,
                                                    watch('startDate'),
                                                );
                                            }}
                                            onClean={() => {
                                                resetField('startDate');
                                            }}
                                            disabled={!watch('enableFinalDate')}
                                            block
                                            oneTap
                                        />
                                    );
                                }}
                            />
                        </InputBox>
                    </Modal.Body>
                    <Modal.Footer>
                        <ButtonPrimary
                            type="reset"
                            theme="ghost"
                            onClick={handleHide}
                        >
                            fechar
                        </ButtonPrimary>
                        <ButtonPrimary disabled={!isDirty || !isValid}>
                            salvar
                        </ButtonPrimary>
                    </Modal.Footer>
                </form>
            </Modal>
            <LoadingSpinerArea
                height="100%"
                size="md"
                area="negotiation-save-negotiation"
            />
        </>
    );
};
