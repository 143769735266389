import React from 'react';

export const MdReport = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        {...props}
    >
        <path
            d="M13.1083 2.5H6.89167L2.5 6.89167V13.1083L6.89167 17.5H13.1083L17.5 13.1083V6.89167L13.1083 2.5ZM10 14.4167C9.4 14.4167 8.91667 13.9333 8.91667 13.3333C8.91667 12.7333 9.4 12.25 10 12.25C10.6 12.25 11.0833 12.7333 11.0833 13.3333C11.0833 13.9333 10.6 14.4167 10 14.4167ZM10.8333 10.8333H9.16667V5.83333H10.8333V10.8333Z"
            fill="currentColor"
        />
    </svg>
);
