import classNames from 'classnames';
import { format, parseISO } from 'date-fns';
import React, { useEffect } from 'react';
import CurrencyFormat from 'react-currency-format';
import { InputGroup } from 'rsuite';
import Table, { TableProps } from 'rsuite/lib/Table';
import { TablePaginationProps } from 'rsuite/lib/Table/TablePagination';
import { LoadingSpinerArea } from '../../../../../components/LoadingSpinner';
import { InfoCurrencyInput } from '../../../Negociacoes';
import { IGetMargemObjetivaResponseItem } from '../../pages/MargemObjetiva/services';
import styles from './MargemObjetivaTable.module.scss';

export type IMargemObjetivaTableRowKey = keyof IGetMargemObjetivaResponseItem;

type RowData = IGetMargemObjetivaResponseItem;

export type IMargemObjetivaTable = TableProps<
    IMargemObjetivaTableRowKey,
    RowData
> & {
    pagination: TablePaginationProps;
    onChangeObjectiveMargin: (value: number, index: number) => void;
    onBlurObjectiveMargin: (value: number, index: number) => void;
};

const { Column, HeaderCell, Cell, Pagination } = Table;

const inputClassName = 'rs-input__margem-objetiva';

const containerId = 'margem-objetiva-table-container';

export const MargemObjetivaTable = ({
    className,
    pagination,
    onChangeObjectiveMargin,
    onBlurObjectiveMargin,
    ...props
}: IMargemObjetivaTable) => {
    const handleMoveFocusToNextColumnInput = (e: KeyboardEvent) => {
        const target = e.target as HTMLElement;
        const targetClass = target?.className.replace(' ', '.');

        if (e.key !== 'Enter' || !targetClass?.match(inputClassName)) return;

        const currentNode = e.currentTarget as HTMLElement;
        const nodeList = currentNode?.querySelectorAll(`.${targetClass}`);
        const currentIndex = Array.from(nodeList).indexOf(target);
        const nextIndex = currentIndex + 1;
        if (nextIndex < nodeList.length) {
            const nextElement = nodeList[nextIndex] as HTMLInputElement;
            nextElement.focus();
            nextElement.select();
        }
    };

    useEffect(() => {
        const container = document.getElementById(containerId);

        container?.addEventListener(
            'keydown',
            handleMoveFocusToNextColumnInput,
        );

        return () => {
            container?.removeEventListener(
                'keydown',
                handleMoveFocusToNextColumnInput,
            );
        };
    }, []);

    return (
        <div id={containerId}>
            <Table
                autoHeight
                minHeight={600}
                headerHeight={36}
                rowHeight={62}
                className={classNames(
                    styles['margem-objetiva-table'],
                    className,
                )}
                rowClassName={styles['margem-objetiva-table__row']}
                {...props}
            >
                <Column flexGrow={1} sortable verticalAlign="middle">
                    <HeaderCell>Categoria ou produto</HeaderCell>
                    <Cell
                        dataKey="categoryName"
                        className="font-size-100-semibold"
                    />
                </Column>
                <Column width={220} sortable verticalAlign="middle">
                    <HeaderCell>Loja</HeaderCell>
                    <Cell
                        dataKey="storeId"
                        className="font-size-100-semibold"
                    />
                </Column>
                <Column width={128} verticalAlign="middle">
                    <HeaderCell>Margem objetiva</HeaderCell>
                    <Cell dataKey="objectiveMargin">
                        {(rowData: RowData, index: number) => (
                            <InputGroup>
                                <InfoCurrencyInput
                                    name="margem-objetiva"
                                    className={inputClassName}
                                    decimalSeparator=","
                                    thousandSeparator="."
                                    precision="1"
                                    allowEmpty
                                    allowNegative={false}
                                    value={rowData.objectiveMargin || 0}
                                    onChangeEvent={(
                                        _e,
                                        _value,
                                        parsedValue,
                                    ) => {
                                        onChangeObjectiveMargin(
                                            parsedValue,
                                            index,
                                        );
                                    }}
                                    onBlur={(_event, value, details) => {
                                        if (details.hasChanges) {
                                            onBlurObjectiveMargin(value, index);
                                        }
                                    }}
                                />
                                <InputGroup.Addon>%</InputGroup.Addon>
                            </InputGroup>
                        )}
                    </Cell>
                </Column>
                <Column width={128} sortable verticalAlign="middle">
                    <HeaderCell>Margem atual</HeaderCell>
                    <Cell dataKey="currentMargin">
                        {(rowData: RowData) => {
                            return rowData.currentMargin ? (
                                <CurrencyFormat
                                    fixedDecimalScale
                                    decimalScale={1}
                                    value={rowData.currentMargin}
                                    displayType="text"
                                    suffix="%"
                                    decimalSeparator=","
                                    thousandSeparator="."
                                    className={classNames(
                                        'font-size-100-semibold',
                                        rowData.currentMargin <
                                            (rowData.objectiveMargin || 0) &&
                                            styles['text-disruptive'],
                                    )}
                                />
                            ) : (
                                '--'
                            );
                        }}
                    </Cell>
                </Column>
                <Column
                    width={240}
                    sortable
                    verticalAlign="middle"
                    className="font-size-100-regular"
                >
                    <HeaderCell>Responsável</HeaderCell>
                    <Cell dataKey="editedBy">
                        {(rowData: RowData) => rowData.editedBy || '--'}
                    </Cell>
                </Column>
                <Column
                    width={160}
                    sortable
                    verticalAlign="middle"
                    className="font-size-100-regular"
                >
                    <HeaderCell>Última atualização</HeaderCell>
                    <Cell dataKey="updatedAt">
                        {(rowData: RowData) => (
                            <>
                                {rowData.updatedAt ? (
                                    <span>
                                        {format(
                                            parseISO(rowData.updatedAt),
                                            'dd/MM/yyyy',
                                        )}
                                    </span>
                                ) : (
                                    '--'
                                )}
                                <LoadingSpinerArea
                                    size="sm"
                                    height="62px"
                                    area={`ipa/gerenciador/margem-objetiva/table-data-${rowData.id}`}
                                />
                            </>
                        )}
                    </Cell>
                </Column>
            </Table>
            <Pagination {...pagination} />
        </div>
    );
};
