import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Col, FlexboxGrid } from 'rsuite';
import BoxPlotChart from '../BoxPlotChart';
import MapChart from '../MapCharts';
import STopPricesChart from '../STopPricesChart';
import TimeEvolutionChart from '../TimeEvolutionChart';

import { PageBlocker } from '../PageBlocker';
import styles from './styles.module.scss';

const ChartsArea = ({
    productsSelected,
    usuario,
    filtros,
    isFree,
    onChangeVisualizationMap,
    onChangeMapProduct,
    onCleanMapProduct,
    onSelectTopFive,
    onCleanTopFive,
    onChangeVisualizationBoxplot,
    onChangeVisualizationTimeEvolution,
    onClickDownload,
    onSelectTimeEvolution,
    onCleanTimeEvolution,
    onClickPageBlocker,
}) => {
    const {
        dataTopFive,
        loadingTopFive,
        produto,
        rede,
        userContratoLimiteData,
    } = useSelector(
        (state) => ({
            dataTopFive: state.painelGeralDataReducer.topFive.data,
            produto: state.painelGeralDataReducer.produto,
            loadingTopFive: state.painelGeralDataReducer.loadingTopFive,
            rede: state.painelGeralDataReducer.rede,
            userContratoLimiteData:
                state.painelGeralDataReducer.userContratoLimiteData,
        }),
        shallowEqual,
    );
    return (
        <FlexboxGrid className={styles['charts-container']}>
            <FlexboxGrid.Item colspan={24} className="">
                <TimeEvolutionChart
                    onSelectTimeEvolution={onSelectTimeEvolution}
                    onCleanTimeEvolution={onCleanTimeEvolution}
                    usuario={usuario}
                    onChangeVisualizationTimeEvolution={
                        onChangeVisualizationTimeEvolution
                    }
                />
            </FlexboxGrid.Item>
            <FlexboxGrid.Item colspan={24} className="">
                <BoxPlotChart
                    usuario={usuario}
                    onChangeVisualizationBoxplot={onChangeVisualizationBoxplot}
                    onClickDownload={onClickDownload}
                />
            </FlexboxGrid.Item>
            <FlexboxGrid.Item
                componentClass={Col}
                lg={12}
                md={12}
                sm={24}
                xs={24}
                className=""
            >
                <MapChart
                    usuario={usuario}
                    filtros={filtros}
                    onChangeVisualizationMap={onChangeVisualizationMap}
                    onChangeMapProduct={onChangeMapProduct}
                    onCleanMapProduct={onCleanMapProduct}
                />
            </FlexboxGrid.Item>
            <FlexboxGrid.Item
                componentClass={Col}
                lg={12}
                md={12}
                sm={24}
                xs={24}
                className=""
            >
                <STopPricesChart
                    rede={rede}
                    usuario={usuario}
                    data={dataTopFive}
                    loading={loadingTopFive}
                    produto={produto}
                    onSelectTopFive={onSelectTopFive}
                    onCleanTopFive={onCleanTopFive}
                />
            </FlexboxGrid.Item>
            {isFree && userContratoLimiteData.limiteDisponivel === 0 ? (
                <PageBlocker
                    title="Você atingiu o número máximo de análises mensais."
                    subtitle="Suas análises são renovadas mensalmente. Para ter acesso
                            ilimitado, escolha um dos nossos planos Flex ou Pro."
                    buttonText="Contratar plano"
                    onClick={onClickPageBlocker}
                />
            ) : null}
        </FlexboxGrid>
    );
};

export default React.memo(ChartsArea);
