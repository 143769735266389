import React from 'react';
import { Modal, Button } from 'rsuite';

const ModalConfirm = ({
    size,
    show,
    title,
    message,
    onCancel,
    onConfirm,
    confirmButton,
    cancelButton,
    children,
    titleFontSize,
    messageFontSize,
    modalWidth,
    textAlign,
    footerAlign,
    cancelButtonWidth,
    confirmButtonWidth,
    icon,
    className,
    id,
    closeButton,
    onHideHeaderButton,
    onHide,
    confirmDisabled,
    cancelDisabled,
    confirmButtonId,
    upMessage,
    upMessageFontSize,
}) => (
    <Modal
        id={id}
        backdrop
        show={show}
        size={size}
        style={{ width: modalWidth }}
        className={`modal-confirm ${className}`}
        onHide={onHide}
    >
        {icon && (
            <div className="modal-confirm__icon">
                <img src={icon} alt="" />
            </div>
        )}
        <Modal.Header
            closeButton={closeButton}
            className="modal-confirm__header"
            onHide={onHideHeaderButton}
        >
            <Modal.Title
                className={`modal-confirm__header--title title-${textAlign}`}
                style={{ fontSize: titleFontSize }}
            >
                {title}
            </Modal.Title>
            {upMessage && (
                <p
                    className={`modal-confirm__header--message message-${textAlign}`}
                    style={{ fontSize: upMessageFontSize }}
                >
                    {upMessage}
                </p>
            )}
            {message && (
                <p
                    className={`modal-confirm__header--message message-${textAlign}`}
                    style={{ fontSize: messageFontSize }}
                >
                    {message}
                </p>
            )}
        </Modal.Header>
        {children && (
            <Modal.Body className="modal-confirm__body">{children}</Modal.Body>
        )}
        <Modal.Footer className={`modal-confirm__footer footer-${footerAlign}`}>
            {cancelButton && (
                <Button
                    className="modal-confirm__footer__btn--cancel"
                    onClick={onCancel}
                    appearance="ghost"
                    color="blue"
                    disabled={cancelDisabled}
                    style={{ width: cancelButtonWidth }}
                >
                    {cancelButton}
                </Button>
            )}
            {confirmButton && (
                <Button
                    className="modal-confirm__footer__btn--confirm"
                    onClick={onConfirm}
                    appearance="primary"
                    disabled={confirmDisabled}
                    style={{ width: confirmButtonWidth }}
                    id={confirmButtonId}
                >
                    {confirmButton}
                </Button>
            )}
        </Modal.Footer>
    </Modal>
);

export default ModalConfirm;
