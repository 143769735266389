import React from 'react';
import { TagPicker } from 'rsuite';

const SelectTag = ({
    block,
    cacheData,
    className,
    classPrefix,
    cleanable,
    container,
    creatable,
    data,
    defaultValue,
    disabled,
    disabledItemValues,
    groupBy,
    labelKey,
    locale,
    menuClassName,
    menuStyle,
    onChange,
    onClean,
    onClose,
    onEnter,
    onEntered,
    onEntering,
    onExit,
    onExited,
    onExiting,
    onGroupTitleClick,
    onOpen,
    onSearch,
    onSelect,
    open,
    placeholder,
    placement,
    preventOverflow,
    renderExtraFooter,
    renderMenuGroup,
    renderMenuItem,
    renderValue,
    searchable,
    size,
    sort,
    trigger,
    value,
    valueKey,
    virtualized,
}) => (
    <TagPicker
        block={block}
        cacheData={cacheData}
        className={`inf-select-tag ${className}`}
        classPrefix={classPrefix}
        cleanable={cleanable}
        container={container}
        creatable={creatable}
        data={data}
        defaultValue={defaultValue}
        disabled={disabled}
        disabledItemValues={disabledItemValues}
        groupBy={groupBy}
        labelKey={labelKey}
        locale={locale}
        menuClassName={`inf-select-tag-menu ${menuClassName}`}
        menuStyle={menuStyle}
        onChange={onChange}
        onClean={onClean}
        onClose={onClose}
        onEnter={onEnter}
        onEntered={onEntered}
        onEntering={onEntering}
        onExit={onExit}
        onExited={onExited}
        onExiting={onExiting}
        onGroupTitleClick={onGroupTitleClick}
        onOpen={onOpen}
        onSearch={onSearch}
        onSelect={onSelect}
        open={open}
        placeholder={placeholder}
        placement={placement}
        preventOverflow={preventOverflow}
        renderExtraFooter={renderExtraFooter}
        renderMenuGroup={renderMenuGroup}
        renderMenuItem={renderMenuItem}
        renderValue={renderValue}
        searchable={searchable}
        size={size}
        sort={sort}
        trigger={trigger}
        value={value}
        valueKey={valueKey}
        virtualized={virtualized}
    />
);
export default SelectTag;
