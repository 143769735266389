import classNames from 'classnames';
import React from 'react';
import { LoadingSpinerLocal } from '../../LoadingSpinner';
import styles from './Loading.module.scss';

export const Loading = ({
    className,
    children,
    ...props
}: React.HtmlHTMLAttributes<HTMLSpanElement>) => {
    return (
        <span className={classNames(styles.container, className)} {...props}>
            <p>{children}</p>
            <div className={styles.loadingContainer}>
                <LoadingSpinerLocal
                    size="sm"
                    height="100%"
                    position="absolute"
                    loading
                />
            </div>
        </span>
    );
};
