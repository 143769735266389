import React from 'react';
import { withRouter } from 'react-router-dom';
import { Container, Content, FlexboxGrid } from 'rsuite';
// @ts-ignore
import { LinkCard } from './Components/LinkCard.tsx';

const Home = ({ history }) => {
    return (
        <Container className="main-container ira-home-page">
            <FlexboxGrid className="pages-container">
                <FlexboxGrid.Item colspan={24}>
                    <Content className="no-margin-bottom page-header">
                        <h2>IRA Analytics</h2>
                    </Content>
                </FlexboxGrid.Item>
                <FlexboxGrid.Item colspan={12}>
                    <LinkCard
                        title="Competitividade geral"
                        description="Painel referente a competitividade da rede"
                        icon="trophy"
                        onRoute={() =>
                            history.push('/ira/competitividade-geral')
                        }
                    />
                </FlexboxGrid.Item>
                <FlexboxGrid.Item colspan={12}>
                    <LinkCard
                        title="Margem praticada geral"
                        description="Painel referente a margem praticada da rede"
                        icon="usd"
                        onRoute={() => history.push('/ira/margem-praticada-geral')}
                    />
                </FlexboxGrid.Item>
                <FlexboxGrid.Item colspan={12}>
                    <LinkCard
                        title="Vendas gerais"
                        description="Painel com os resultados de vendas"
                        icon="charts-line"
                        onRoute={() => history.push('/ira/vendas-gerais')}
                    />
                </FlexboxGrid.Item>
            </FlexboxGrid>
        </Container>
    );
};

export default withRouter(Home);
