import React from 'react';
import {
    Button,
    Icon,
    Whisper,
    Input,
    Popover,
    Dropdown,
    Checkbox,
    CheckboxGroup,
    Badge,
} from 'rsuite';

const MenuPopover = ({
    onSelect,
    data,
    select,
    type,
    onMouseEnter,
    onMouseLeave,
    className,
    placement,
    positionLeft,
    positionTop,
    style,
    shouldUpdatePosition,
    handleChange,
    handleSelect,
    searchItem,
}) => (
    <Popover
        className={`listagem-produtos ${className}`}
        placement={placement}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        positionLeft={positionLeft}
        positionTop={positionTop}
        full
        style={style}
        shouldUpdatePosition={shouldUpdatePosition}
    >
        <Dropdown.Menu>
            {searchItem ? (
                <Dropdown.Item>
                    <Input
                        placeholder={
                            type === 'produto'
                                ? 'Busque por um prouto'
                                : 'Busque por uma loja'
                        }
                        onChange={(search) => searchItem(search)}
                    />
                </Dropdown.Item>
            ) : null}

            {type === 'produto' || type === 'loja' ? (
                <div className="listagem-produtos">
                    <Dropdown.Item>
                        <CheckboxGroup
                            name="checkboxList"
                            value={select.value}
                            onChange={(value) => handleChange(value)}
                        >
                            {data.map((item) => (
                                <Checkbox
                                    value={item.store_id}
                                    key={item.store_id}
                                    onChange={() => handleSelect(item)}
                                >
                                    {item.label}
                                </Checkbox>
                            ))}
                        </CheckboxGroup>
                    </Dropdown.Item>
                </div>
            ) : (
                <>
                    {data.map((item) => (
                        <Dropdown.Item
                            active={item.value === select}
                            key={item.value}
                            onSelect={() => onSelect(item, type)}
                        >
                            {item.label}
                        </Dropdown.Item>
                    ))}
                </>
            )}
        </Dropdown.Menu>
    </Popover>
);

const BotaoFiltro = ({
    filtroRef,
    label,
    data,
    type,
    handleChange,
    handleSelect,
    searchItem,
    itemsSelected,
    onSelect,
    onClear,
    showBadge,
    showValue,
}) => (
    <Whisper
        placement="bottomStart"
        trigger="click"
        triggerRef={filtroRef}
        speaker={(
            <MenuPopover
                onSelect={onSelect}
                data={data}
                select={itemsSelected}
                type={type}
                handleChange={handleChange}
                handleSelect={handleSelect}
                searchItem={searchItem}
            />
          )}
    >
        <Button
            className={`filtro-botao ${itemsSelected?.length ? 'active' : ''}`}
        >
            {itemsSelected?.length
                ? !showValue && itemsSelected?.length > 1
                    ? `${label}s`
                    : showValue
                    ? itemsSelected
                    : label
                : label}
            {itemsSelected?.length && showBadge ? (
                <Badge
                    style={{
                        background: '#0D66D0',
                    }}
                    content={itemsSelected.length}
                />
            ) : null}
            <Icon icon="angle-down" />
            {itemsSelected?.length ? (
                <Icon icon="close" onClick={onClear} />
            ) : null}
        </Button>
    </Whisper>
);

export default BotaoFiltro;
