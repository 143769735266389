import React from 'react';
import { InputGroup, Input, FlexboxGrid, Icon, Button } from 'rsuite';
import styles from './styles.module.scss';
import iconChevronDown from '../../../../../../assets/icons/icon_chevron_down-blue.svg';
import iconChevronUp from '../../../../../../assets/icons/icon_chevron_up-blue.svg';
import iconArrowUp from '../../../../../../assets/icons/icon_arrow_up-gray.svg';
import iconArrowDown from '../../../../../../assets/icons/icon_arrow_down-gray.svg';

const setDiffPercentage = (percentage) => {
    if (+percentage < 100) {
        return (100 - +percentage).toFixed(1).replace('.', ',');
    }
    return (+percentage - 100).toFixed(1).replace('.', ',');
};

export const DetailingPanelHeader = ({
    onClick,
    hasOpen,
    subtitleProduct,
    expensiveQuantity,
    cheaperQuantity,
    data,
    onSearch,
    title,
    comparedItemQuantity,
    comparedItemText,
    expensiveText,
    cheaperText,
    referenceText,
    referenceCheapText,
    referenceExpensiveText,
}) => (
    <FlexboxGrid align="middle" className={styles['panel-header-content']}>
        <FlexboxGrid.Item colspan={12} className={styles['table-item']}>
            <FlexboxGrid align="middle">
                <FlexboxGrid.Item
                    colspan={hasOpen ? 12 : 20}
                    className={styles['table-item']}
                >
                    <p className={styles['detailing-panel-title']}>{title}</p>
                    {subtitleProduct ? (
                        <p className={styles['detailing-panel-subtitle']}>
                            {subtitleProduct}
                        </p>
                    ) : null}
                </FlexboxGrid.Item>
                {hasOpen ? (
                    <FlexboxGrid.Item
                        colspan={11}
                        className={styles['table-item']}
                    >
                        <InputGroup inside>
                            <InputGroup.Addon>
                                <Icon
                                    icon="search"
                                    style={{ color: '#1473E6' }}
                                />
                            </InputGroup.Addon>
                            <Input
                                className={styles['input-search']}
                                onChange={onSearch}
                            />{' '}
                            {/* Falta lógica de procura */}
                        </InputGroup>
                    </FlexboxGrid.Item>
                ) : null}
            </FlexboxGrid>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={6} className={styles['table-item']}>
            <FlexboxGrid align="middle">
                <FlexboxGrid.Item colspan={12}>
                    <p className={styles['detailing-panel-title']}>
                        {comparedItemQuantity} {comparedItemText}
                    </p>
                    <FlexboxGrid align="middle">
                        <Button
                            className={styles['expand-button']}
                            onClick={onClick}
                        >
                            <img
                                src={hasOpen ? iconChevronUp : iconChevronDown}
                                alt=""
                                className={styles['icon-chevron-down']}
                            />
                            {hasOpen ? 'Fechar' : 'Expandir'}
                        </Button>
                    </FlexboxGrid>
                </FlexboxGrid.Item>
                <FlexboxGrid.Item colspan={12}>
                    <div className={styles['cheap-expensive-indicator']}>
                        <FlexboxGrid align="middle">
                            <FlexboxGrid.Item>
                                <img
                                    src={iconArrowUp}
                                    alt=""
                                    className={styles['icon-arrow']}
                                />
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item>
                                <p className={styles['cheap-expensive-number']}>
                                    {expensiveQuantity}
                                </p>
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item>
                                <p className={styles['cheap-expensive-text']}>
                                    {expensiveText}
                                </p>
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                        <FlexboxGrid align="middle">
                            <FlexboxGrid.Item>
                                <img
                                    src={iconArrowDown}
                                    alt=""
                                    className={styles['icon-arrow']}
                                />
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item>
                                <p className={styles['cheap-expensive-number']}>
                                    {cheaperQuantity}
                                </p>
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item>
                                <p className={styles['cheap-expensive-text']}>
                                    {cheaperText}
                                </p>
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                    </div>
                </FlexboxGrid.Item>
            </FlexboxGrid>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={6} className={styles['table-item']}>
            <FlexboxGrid align="middle">
                <FlexboxGrid.Item colspan={6} className={styles['table-item']}>
                    <p className={styles['big-number-percentage']}>
                        {(data.competitividadeMedia * 100)
                            .toFixed(1)
                            .toString()
                            .replace('.', ',')}
                        %
                    </p>
                </FlexboxGrid.Item>
                <FlexboxGrid.Item colspan={18} className={styles['table-item']}>
                    <p className={styles['reference-light-text']}>
                        {referenceText}
                    </p>

                    <FlexboxGrid align="bottom">
                        <p className={styles['small-number-percentage']}>
                            {setDiffPercentage(
                                (data.competitividadeMedia * 100).toFixed(1)
                            )}
                            %
                        </p>
                        <p className={styles['reference-dark-text']}>
                            {(data.competitividadeMedia * 100).toFixed(1) > 100
                                ? referenceCheapText
                                : referenceExpensiveText}
                        </p>
                    </FlexboxGrid>
                </FlexboxGrid.Item>
            </FlexboxGrid>
        </FlexboxGrid.Item>
    </FlexboxGrid>
);
