import React from 'react';
import { Content, FlexboxGrid, Icon } from 'rsuite';
import { SVGIcon } from 'rsuite/lib/@types/common';
import { IconNames } from 'rsuite/lib/Icon';

type LinkCardProps = {
    title: string;
    description: string;
    icon: IconNames | SVGIcon;
    onRoute: () => void;
};

export const LinkCard: React.FC<LinkCardProps> = (props) => {
    const { title, description, icon, onRoute } = props;
    return (
        <Content className="no-margin-bottom item-card" onClick={onRoute}>
            <FlexboxGrid>
                <FlexboxGrid.Item>
                    <Icon icon={icon} />
                </FlexboxGrid.Item>
                <FlexboxGrid.Item colspan={20}>
                    <FlexboxGrid>
                        <FlexboxGrid.Item colspan={24}>
                            <h3>{title}</h3>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={24}>
                            <p>{description}</p>
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </FlexboxGrid.Item>
            </FlexboxGrid>
        </Content>
    );
};
