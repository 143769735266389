import classNames from 'classnames';
import React from 'react';
import styles from './ButtonSecondary.module.scss';

type ButtonSecondaryThemes = 'blue' | 'red' | 'gray';

export type ButtonSecondaryProps =
    React.ButtonHTMLAttributes<HTMLButtonElement> & {
        theme?: ButtonSecondaryThemes;
        size?: 'lg' | 'md' | 'sm' | 'xs';
    };

const ButtonSecondary = ({
    theme = 'gray',
    size = 'md',
    className,
    ...props
}: ButtonSecondaryProps) => {
    return (
        <button
            className={classNames(styles.button, className)}
            data-theme={theme}
            data-size={size}
            data-button
            {...props}
        />
    );
};

export default ButtonSecondary;
