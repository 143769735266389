import React from 'react';
import { Checkbox } from 'rsuite';
import { BaseCell } from '../../../../../../components/TableCells';

type CheckBoxCellProps = {
    value: string | null;
    handleCheck: (value: string, checked: boolean) => void;
    isChecked: boolean;
};

const CheckBoxCell = ({ value, isChecked, handleCheck }: CheckBoxCellProps) => {
    return (
        <BaseCell>
            <Checkbox
                value={value}
                onChange={(value, checked) => {
                    handleCheck(value, checked);
                }}
                checked={isChecked}
            />
        </BaseCell>
    );
};

export default CheckBoxCell;
