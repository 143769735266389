import React, { HTMLAttributes } from 'react';
import { CardContent } from './CardContent';
import { CardFooter } from './CardFooter';
import { CardHeader } from './CardHeader';
import { CardIcon } from './CardIcon';
import { CardTitle } from './CardTitle';
import './style.scss';

export type CardProps = HTMLAttributes<HTMLDivElement> & {
    pointer?: boolean;
    hasBorder?: boolean;
    variant?: 'default' | 'filled';
    disableHover?: boolean;
    isPrimary?: boolean;
};

const Card = ({
    children,
    className = '',
    pointer = false,
    variant = 'default',
    disableHover = false,
    hasBorder = true,
    isPrimary = false,
    ...props
}: CardProps) => {
    const baseClass = 'info-card';

    return (
        <div
            className={[
                baseClass,
                pointer ? `${baseClass}-pointer` : '',
                hasBorder ? `${baseClass}-bordered` : '',
                variant ? `${baseClass}-${variant}` : '',
                disableHover ? `${baseClass}-hover-disabled` : '',
                isPrimary ? `${baseClass}-is-primary` : '',
                className,
            ].join(' ')}
            {...props}
        >
            {children}
        </div>
    );
};

Card.Header = CardHeader;
Card.Title = CardTitle;
Card.Icon = CardIcon;
Card.Content = CardContent;
Card.Footer = CardFooter;

export { Card };
