import { useReducer } from 'react';

type Item = string;

type State = Item[];

const initialState: State = [];

type Action =
    | { type: 'RESET' }
    | { type: 'SET'; payload: State }
    | { type: 'ADD'; payload: Item }
    | { type: 'REMOVE'; payload: Item };

const reducer = (state: State, action: Action): State => {
    switch (action.type) {
        case 'RESET':
            return initialState;
        case 'SET':
            return action.payload;
        case 'ADD':
            return [...state, action.payload];
        case 'REMOVE':
            return state.filter((state) => state !== action.payload);
        default:
            return state;
    }
};

const RESET = (): Action => ({
    type: 'RESET',
});

const SET = (payload: State): Action => ({
    type: 'SET',
    payload,
});

const ADD = (payload: Item): Action => ({
    type: 'ADD',
    payload,
});

const REMOVE = (payload: Item): Action => ({
    type: 'REMOVE',
    payload,
});

type UseSelectedDatapoints = [
    State,
    {
        RESET: () => void;
        SET: (payload: State) => void;
        ADD: (payload: Item) => void;
        REMOVE: (payload: Item) => void;
    },
];

export const useStringArrayReducer = (): UseSelectedDatapoints => {
    const [state, dispatch] = useReducer(reducer, initialState);

    return [
        state,
        {
            RESET: () => dispatch(RESET()),
            SET: (payload) => dispatch(SET(payload)),
            ADD: (payload) => dispatch(ADD(payload)),
            REMOVE: (payload) => dispatch(REMOVE(payload)),
        },
    ] as const;
};
