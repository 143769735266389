import React from 'react';

export const MdUpload = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M5 20H19V18H5V20ZM5 10H9V16H15V10H19L12 3L5 10Z"
            fill="currentColor"
        />
    </svg>
);
