import { useState } from 'react';

export type PaginationProps = Record<
    'totalElements' | 'activePage' | 'displayLength',
    number
>;

export const usePagination = (
    data: Record<'totalElements' | 'number' | 'size', number>,
) => {
    const [pagination, setPagination] = useState<PaginationProps>({
        totalElements: data.totalElements ?? 0,
        activePage: data.number ?? 0,
        displayLength: data.size ?? 20,
    });

    const handleChangePage = (page: number) => {
        setPagination((oldValue) => ({
            ...oldValue,
            activePage: page - 1,
        }));
    };

    const handleChangeLength = (size: number) => {
        setPagination((oldValue) => ({
            ...oldValue,
            displayLength: size,
        }));
    };

    return {
        pagination,
        setPagination,
        handleChangePage,
        handleChangeLength,
    };
};
