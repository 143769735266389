import {
    getItensSegmentoProduto,
    getSegmentos,
    getSegmentosClasses,
    getSegmentosSensibilities,
    searchFiltroProdutos,
} from '../services/ProdutoService';

import { getLojas } from '../pages/IPA/RegrasPreco/services';

export const actionsTypeLimitePrecoCadastro = {
    ADD_SEGMENTO_LIMITE_PRECO_CADASTRO: 'ADD_SEGMENTO_LIMITE_PRECO_CADASTRO',
    DELETE_SEGMENTO_LIMITE_PRECO_CADASTRO:
        'DELETE_SEGMENTO_LIMITE_PRECO_CADASTRO',
    CHANGE_VALUE_SEGMENTO_LIMITE_PRECO_CADASTRO:
        'CHANGE_VALUE_SEGMENTO_LIMITE_PRECO_CADASTRO',
    CHANGE_CACHE_SEGMENTO_LIMITE_PRECO_CADASTRO:
        'CHANGE_CACHE_SEGMENTO_LIMITE_PRECO_CADASTRO',
    SELECT_VALUE_SEGMENTO_LIMITE_PRECO_CADASTRO:
        'SELECT_VALUE_SEGMENTO_LIMITE_PRECO_CADASTRO',
    LIST_ITENS_SEGMENTOS_LIMITE_PRECO_CADASTRO:
        'LIST_ITENS_SEGMENTOS_LIMITE_PRECO_CADASTRO',
    LIST_ITENS_PRODUTO_CADASTRO_LIMITE: 'LIST_ITENS_PRODUTO_CADASTRO_LIMITE',
    LIST_ITENS_SEGMENTOS_CLASSES_LIMITE_PRECO_CADASTRO:
        'LIST_ITENS_SEGMENTOS_CLASSES_LIMITE_PRECO_CADASTRO',
    GET_SEGMENTOS_LIMITE_PRECO_CADASTRO: 'GET_SEGMENTOS_LIMITE_PRECO_CADASTRO',
    CLEAR_ALL_SEGMENTOS_LIMITE_PRECO_CADASTRO:
        'CLEAR_ALL_SEGMENTOS_LIMITE_PRECO_CADASTRO',
    SET_SEGMENTOS_UPDATE: 'SET_SEGMENTOS_UPDATE',
    SET_SEGMENTOS_PRODUTOS_UPDATE_LIMITE:
        'SET_SEGMENTOS_PRODUTOS_UPDATE_LIMITE',
    SET_SEGMENTOS_LOJAS_UPDATE_LIMITE: 'SET_SEGMENTOS_LOJAS_UPDATE_LIMITE',
    SET_SEGMENTOS_CLASSES_UPDATE_LIMITE: 'SET_SEGMENTOS_CLASSES_UPDATE_LIMITE',
    SET_SEGMENTOS_SENSIBILITY_UPDATE_LIMITE:
        'SET_SEGMENTOS_SENSIBILITY_UPDATE_LIMITE',
    LIST_ITEMS_LOJA_CADASTRO_LIMITE: 'LIST_ITEMS_LOJA_CADASTRO_LIMITE',
};

export function addSegmento(filtro) {
    return {
        type: actionsTypeLimitePrecoCadastro.ADD_SEGMENTO_LIMITE_PRECO_CADASTRO,
        filtro,
    };
}

export function deleteSegmento(index, name) {
    return {
        type: actionsTypeLimitePrecoCadastro.DELETE_SEGMENTO_LIMITE_PRECO_CADASTRO,
        index,
        name,
    };
}

export function changeValueSegmento(value, name) {
    return {
        type: actionsTypeLimitePrecoCadastro.CHANGE_VALUE_SEGMENTO_LIMITE_PRECO_CADASTRO,
        value,
        name,
    };
}

export function changeCacheSegmento(name, cache) {
    return {
        type: actionsTypeLimitePrecoCadastro.CHANGE_CACHE_SEGMENTO_LIMITE_PRECO_CADASTRO,
        name,
        cache,
    };
}

export function selectValueSegmento(item, name) {
    return {
        type: actionsTypeLimitePrecoCadastro.SELECT_VALUE_SEGMENTO_LIMITE_PRECO_CADASTRO,
        item,
        name,
    };
}

export function listItensSegmentos(itens, name) {
    return {
        type: actionsTypeLimitePrecoCadastro.LIST_ITENS_SEGMENTOS_LIMITE_PRECO_CADASTRO,
        itens,
        name,
    };
}

export function listItensProduto(itens, name) {
    return {
        type: actionsTypeLimitePrecoCadastro.LIST_ITENS_PRODUTO_CADASTRO_LIMITE,
        itens,
        name,
    };
}

export function listItensLoja(itens, name) {
    return {
        type: actionsTypeLimitePrecoCadastro.LIST_ITEMS_LOJA_CADASTRO_LIMITE,
        itens,
        name,
    };
}

export function listSegmentosClassesSensibility(itens, name) {
    return {
        type: actionsTypeLimitePrecoCadastro.LIST_ITENS_SEGMENTOS_CLASSES_LIMITE_PRECO_CADASTRO,
        itens,
        name,
    };
}

export function getAllSegmentos(itens) {
    const segmentos = {};
    itens.forEach((item) => {
        segmentos[item.field] = { lista: [], value: [], cache: [] };
    });
    return {
        type: actionsTypeLimitePrecoCadastro.GET_SEGMENTOS_LIMITE_PRECO_CADASTRO,
        itens,
        segmentos,
    };
}

export function clearSegmentos() {
    return {
        type: actionsTypeLimitePrecoCadastro.CLEAR_ALL_SEGMENTOS_LIMITE_PRECO_CADASTRO,
    };
}

export function setSegmentosUpdateLimite(name, value) {
    return {
        type: actionsTypeLimitePrecoCadastro.SET_SEGMENTOS_UPDATE,
        name,
        value,
    };
}

export function setSegmentosProdutosUpdateLimite(name, item) {
    return {
        type: actionsTypeLimitePrecoCadastro.SET_SEGMENTOS_PRODUTOS_UPDATE_LIMITE,
        name,
        item,
    };
}

export function setSegmentosLojasUpdateLimite(name, item) {
    return {
        type: actionsTypeLimitePrecoCadastro.SET_SEGMENTOS_LOJAS_UPDATE_LIMITE,
        name,
        item,
    };
}

export function setSegmentosClassesUpdateLimite(name, item) {
    return {
        type: actionsTypeLimitePrecoCadastro.SET_SEGMENTOS_CLASSES_UPDATE_LIMITE,
        name,
        item,
    };
}

export function setSegmentosSensibilitiesUpdateLimite(name, item) {
    return {
        type: actionsTypeLimitePrecoCadastro.SET_SEGMENTOS_SENSIBILITY_UPDATE_LIMITE,
        name,
        item,
    };
}

export function searchSegmentos() {
    return (dispatch) => {
        getSegmentos().then((data) => dispatch(getAllSegmentos(data)));
    };
}

export function searchSegmentosClasses() {
    return (dispatch) => {
        getSegmentosClasses().then((data) => {
            if (data && data.length > 0) {
                dispatch(listSegmentosClassesSensibility(data, 'abcClass'));
            }
        });
    };
}

export function searchSegmentosSensibilities() {
    return (dispatch) => {
        getSegmentosSensibilities().then((data) => dispatch(listSegmentosClassesSensibility(data, 'sensibilityType')));
    };
}

export function searchItensSegmento(nivel, query, name) {
    return (dispatch) => {
        getItensSegmentoProduto(nivel, query).then((data) => dispatch(listItensSegmentos(data, name)));
    };
}

export function searchProduto(nivel, query, name) {
    return (dispatch) => {
        searchFiltroProdutos(query).then((data) => dispatch(listItensProduto(data, name)));
    };
}

export function searchStore(nivel, query, name) {
    return async (dispatch) => {
        const resp = await getLojas(query);
        if (resp) dispatch(listItensLoja(resp, name));
    };
}
