import React, { HtmlHTMLAttributes } from 'react';
import { maskCnpj } from '../../../lib';
import { Card } from '../Card/Card';
import './style.scss';

export type STORE_PROPS = {
    alias: string;
    cnpj: string;
    aliasRede: string;
    endereco: Partial<
        Record<
            | 'logradouro'
            | 'numero'
            | 'complemento'
            | 'bairro'
            | 'cep'
            | 'cidade'
            | 'uf',
            string
        >
    >;
    tipoLoja: Record<'name' | 'descricao', string>;
    isSelected?: boolean;
} & HtmlHTMLAttributes<HTMLDivElement>;

export const CardLojas = ({
    alias,
    cnpj,
    endereco,
    tipoLoja,
    className = '',
    isSelected,
    aliasRede,
    ...props
}: STORE_PROPS) => (
    <div
        className={[
            'store-card',
            isSelected ? 'store-card-is-selected' : '',
            className,
        ].join(' ')}
        {...props}
    >
        <Card className="store-card__card" disableHover>
            <Card.Header className="store-card__header">
                <Card.Title>
                    <span className="font-size-200-semibold">{aliasRede}</span>
                    <span className="font-size-75-regular">
                        {maskCnpj(cnpj)}
                    </span>
                </Card.Title>
            </Card.Header>
            <Card.Content className="store-card__content font-size-75-regular">
                {endereco?.cep &&
                    endereco?.logradouro &&
                    endereco?.numero &&
                    tipoLoja?.descricao && (
                        <>
                            <span>
                                {endereco.cep} - {endereco.logradouro},{' '}
                                {endereco.numero}
                            </span>
                            <div>
                                <b className="font-size-75-semibold">
                                    Tipo de loja:
                                </b>
                                <span> {tipoLoja.descricao}</span>
                            </div>
                        </>
                    )}
            </Card.Content>
        </Card>
    </div>
);
