import { trackPromise } from 'react-promise-tracker';
import api from '../../../utils/API';

export const savePrecificacao = (data) => trackPromise(
        api({
            url: `${process.env.REACT_APP_IPA_URL}/rules`,
            method: 'POST',
            expectToken: true,
            data: JSON.stringify(data),
        }).then((resp) => resp),
    );

export const getDatapointProdutoLoja = (productId, storeId) => trackPromise(
        api({
            url: `${process.env.REACT_APP_IPA_URL}/datapoints/product/${productId}/store/${storeId}`,
            method: 'GET',
            expectToken: true,
            params: {
                clientId: JSON.parse(localStorage.getItem('cliente'))?.codigo,
            },
        }).then((resp) => resp.data),
    );

export const getModalConfimacao = (productFamilyId, storeId, price, page) => trackPromise(
    api({
        url: `${process.env.REACT_APP_IPA_URL}/products/aggregate`,
        method: 'GET',
        expectToken: true,
        params: {
            productFamilyId,
            storeId,
            price,
            size: 8,
            page,
                    },
    }).then((resp) => resp.data),
);

export const getDatapointProdutoFamilia = (productFamilyId, storeId) => trackPromise(
    api({
        url: `${process.env.REACT_APP_IPA_URL}/products/families/${productFamilyId}/store/${storeId}/summary/aggregate`,
        method: 'GET',
        expectToken: true,
    }).then((resp) => resp.data),
);

export const getGridModalFamilia = (productFamilyId, storeId, page) => trackPromise(
    api({
        url: `${process.env.REACT_APP_IPA_URL}/products/aggregate`,
        method: 'GET',
        expectToken: true,
        params: {
            productFamilyId,
            storeId,
            size: 8,
            page,
                   },
    }).then((resp) => resp.data),
);
