import React from 'react';
// @ts-ignore
import { Tabela } from '../../Components/Tabela.tsx';
// @ts-ignore
import { useRaioXProdutosMaisVendidos } from '../../../hooks/useRaioXProdutosMaisVendidos.tsx';

export const RaioXProdutosMaisVendidos: React.FC = () => {
    const { data, isLoading } = useRaioXProdutosMaisVendidos();

    const columns = [
        {
            align: 'left',
            headerCell: 'Produto',
            dataKey: 'productId',
        },
        {
            align: 'left',
            headerCell: 'Descrição',
            dataKey: 'description',
            flexGrow: 2,
        },
        {
            align: 'left',
            headerCell: 'Competitividade média varejo',
            dataKey: 'avg_average_competitivity_retail',
            isPercent: true,
            flexGrow: 1,
        },
        {
            align: 'left',
            headerCell: 'Competitividade média atacado',
            dataKey: 'avg_average_competitivity_wholesale',
            isPercent: true,
            flexGrow: 1,
        },
        {
            align: 'left',
            headerCell: 'Margem praticada',
            dataKey: 'realizedMargin',
            isPercent: true,
            flexGrow: 1,
        },
        {
            align: 'left',
            headerCell: 'Soma de venda bruta',
            dataKey: 'sumSalesGross',
            flexGrow: 1,
        },
    ];

    return (
        <Tabela
            data={data}
            isLoading={isLoading}
            columns={columns}
            title="Raio-X dos produtos (mais vendidos)"
        />
    );
};
