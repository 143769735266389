import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, withRouter } from 'react-router-dom';
import {
    Alert,
    Button,
    Container,
    Icon,
    Panel,
    Tooltip,
    Whisper
} from 'rsuite';
import {
    getCompetitiviteData,
    getDetailsData,
    getDownloadIndustry,
    getDownloadRetail,
    getEvolutionData,
    getIndustryCompetitiviteData,
    getIndustryDetailsData,
    getIndustryEvolutionData,
    resetToInitialState,
    setSingleReducer,
} from '../../../../actions/actionsAnaliseCompetitividade';
import lockIcon from '../../../../assets/icons/icon_lock.svg';
import Header from '../../../../components/Header';
import { LoadingSpinerArea } from '../../../../components/LoadingSpinner';
import ModalConfirm from '../../../../components/ModalConfirm';
import ModalConfirmation from '../../../../components/ModalConfirmation';
import { ModalVideos } from '../../../../components/ModalVideos/ModalVideos';
import { trackEvent } from '../../../../utils/MatomoConfig';
import { sendLead } from '../InfoPanel/utils';
import HowToUseModuleReportBanner from '../ModuloRelatorio/Components/HowToUseModuleReportBanner';
import {
    Detailing,
    EmptyState,
    FiltersArea,
    GeneralAverageBox,
    ModalCalculationInfoContent,
    ModalProductCalculationInfoContent,
    QuantityBox,
    ReferenceBox,
    ResultsButton,
    TimeEvolutionChart,
} from './Components';


import { ButtonModalVideos } from '../../../../components/ModalVideos/ButtonModalVideos';
import { dataVideoModuleReportModal, stringLimited, tooltipCalculationText, videosData } from './utils';

import styles from './styles.module.scss';

const AnaliseCompetitividade = ({ usuario }) => {
    const [showModalUnderstandCalculation, setShowModalUnderstandCalculation]
        = useState(false);
    const [showCTA, setShowCTA] = useState(false);
    const [showModalVideoss, setShowModalVideoss] = useState(false);

    const history = useHistory();
    const location = useLocation();
    const competitionType = location.pathname.split('/').pop();
    const isRetail = competitionType === 'varejo';

    const hasNoPermission
        = !usuario?.tipoAdicionalContratos?.PAN?.includes('MODULO_COMPETITIVIDADE');

    const {
        analiseCompetitividadeData,
        competitividadeResponse,
        evolutionResponse,
        loadingPage,
        loadingChart,
        isEmptyResponse,
        detailsResponse,
        productsResponse,
        servicoPainel,
        productLabel,
        analiseIndustriaCompetitividadeData,
        clienteData,
        filterIsEmpty,
    } = useSelector(
        (state) => ({
            clienteData: state.clienteDataReducer?.clienteData,
            analiseCompetitividadeData:
                state.analiseCompetitividadeDataReducer
                    .analiseCompetitividadeData,
            analiseIndustriaCompetitividadeData:
                state.analiseCompetitividadeDataReducer
                    .analiseIndustriaCompetitividadeData,
            competitividadeResponse:
                state.analiseCompetitividadeDataReducer.competitividadeResponse,
            evolutionResponse:
                state.analiseCompetitividadeDataReducer.evolutionResponse,
            loadingPage: state.analiseCompetitividadeDataReducer.loadingPage,
            loadingChart: state.analiseCompetitividadeDataReducer.loadingChart,
            isEmptyResponse:
                state.analiseCompetitividadeDataReducer.isEmptyResponse,
            detailsResponse:
                state.analiseCompetitividadeDataReducer.detailsResponse,
            productsResponse:
                state.analiseCompetitividadeDataReducer.productsResponse,
            productLabel: state.analiseCompetitividadeDataReducer.productLabel,
            servicoPainel: state.usuarioDataReducer.servicoPainel,
            filterIsEmpty:
                state.analiseCompetitividadeDataReducer.filterIsEmpty,
        }),
        shallowEqual,
    );

    const dispatch = useDispatch();

    const hasFilterfilled =
        analiseCompetitividadeData?.gtins?.length !== 0 &&
        analiseCompetitividadeData?.periodoSemanas !== 0 &&
        analiseCompetitividadeData?.redeReferencia !== null &&
        analiseCompetitividadeData?.redesCompetidoras?.length !== 0 &&
        analiseCompetitividadeData?.tipoLocalizacao !== null;

    const hasIndustryFilterfilled
        = analiseIndustriaCompetitividadeData?.produtoReferencia !== null
        && analiseIndustriaCompetitividadeData?.produtosCompetidores?.length
        !== 0
        && analiseIndustriaCompetitividadeData?.periodoSemanas !== 0
        && analiseIndustriaCompetitividadeData?.redes?.length !== 0
        && analiseIndustriaCompetitividadeData?.tipoLocalizacao !== null;

    const evolutionModel = {
        agrupamentoEvolucao: isRetail ? 'REDE' : 'PRODUTO',
        preenchimentoEvolucao: 'PREENCHER_E_REPLICAR',
        request: isRetail
            ? analiseCompetitividadeData
            : analiseIndustriaCompetitividadeData,
    };

    const sendCTA = () => {
        sendLead(usuario, 'upsell-competitividade');
        trackEvent('Upsell/Crossell', 'upsell-block-competitividade');
        setShowCTA(false);
        window.open('https://wa.me/551137779670?text=Ol%C3%A1%21+Quero+saber+mais+sobre+os+planos+Flex+e+Pro+do+ISA+-+InfoPanel');
    };

    const handleViewResults = () => {
        const locationType = analiseCompetitividadeData?.tipoLocalizacao;
        const locationList = analiseCompetitividadeData?.listaLocalizacao;

        if (hasFilterfilled) {
            dispatch(setSingleReducer('evolutionResponse', []));
            if (
                locationType !== 'TODO_TERRITORIO'
                && locationList?.length !== 0
            ) {
                dispatch(getCompetitiviteData(analiseCompetitividadeData));
                dispatch(getEvolutionData(evolutionModel, 'page'));
                dispatch(getDetailsData(analiseCompetitividadeData));
            } else if (
                locationType === 'TODO_TERRITORIO'
                && locationList?.length === 0
            ) {
                dispatch(getCompetitiviteData(analiseCompetitividadeData));
                dispatch(getEvolutionData(evolutionModel, 'page'));
                dispatch(getDetailsData(analiseCompetitividadeData));
            } else {
                Alert.warning(
                    'Para visualizar os resultados, defina todos os campos de busca',
                    5000,
                );
            }
        } else {
            Alert.warning(
                'Para visualizar os resultados, defina todos os campos de busca',
                5000,
            );

            dispatch(
                setSingleReducer('filterIsEmpty', {
                    ...filterIsEmpty,
                    tipoLocalizacao:
                        analiseCompetitividadeData?.tipoLocalizacao === null,
                    gtins: analiseCompetitividadeData?.gtins?.length === 0,
                    periodoSemanas:
                        analiseCompetitividadeData?.periodoSemanas === 0,
                    redeReferencia:
                        analiseCompetitividadeData?.redeReferencia === null,
                    redesCompetidoras:
                        analiseCompetitividadeData?.redesCompetidoras
                            ?.length === 0,
                    listaLocalizacao:
                        analiseCompetitividadeData?.listaLocalizacao?.length
                        === 0,
                }),
            );
        }
    };

    const handleViewIndustryResults = () => {
        const locationType
            = analiseIndustriaCompetitividadeData?.tipoLocalizacao;
        const locationList
            = analiseIndustriaCompetitividadeData?.listaLocalizacao;

        if (hasIndustryFilterfilled) {
            dispatch(setSingleReducer('evolutionResponse', []));
            if (
                locationType !== 'TODO_TERRITORIO'
                && locationList?.length !== 0
            ) {
                dispatch(
                    getIndustryCompetitiviteData(
                        analiseIndustriaCompetitividadeData,
                    ),
                );
                dispatch(getIndustryEvolutionData(evolutionModel, 'page'));
                dispatch(
                    getIndustryDetailsData(analiseIndustriaCompetitividadeData),
                );
            } else if (
                locationType === 'TODO_TERRITORIO'
                && locationList?.length === 0
            ) {
                dispatch(
                    getIndustryCompetitiviteData(
                        analiseIndustriaCompetitividadeData,
                    ),
                );
                dispatch(getIndustryEvolutionData(evolutionModel, 'page'));
                dispatch(
                    getIndustryDetailsData(analiseIndustriaCompetitividadeData),
                );
            } else {
                Alert.warning(
                    'Para visualizar os resultados, defina todos os campos de busca',
                    5000,
                );
            }
        } else {
            Alert.warning(
                'Para visualizar os resultados, defina todos os campos de busca',
                5000,
            );

            dispatch(
                setSingleReducer('filterIsEmpty', {
                    ...filterIsEmpty,
                    tipoLocalizacao:
                        analiseIndustriaCompetitividadeData?.tipoLocalizacao
                        === null,
                    periodoSemanas:
                        analiseIndustriaCompetitividadeData?.periodoSemanas
                        === 0,
                    listaLocalizacao:
                        analiseIndustriaCompetitividadeData?.listaLocalizacao
                            ?.length === 0,
                    produtoReferencia:
                        analiseIndustriaCompetitividadeData?.produtoReferencia
                        === null,
                    produtosCompetidores:
                        analiseIndustriaCompetitividadeData
                            ?.produtosCompetidores?.length === 0,
                    redes:
                        analiseIndustriaCompetitividadeData?.redes?.length
                        === 0,
                }),
            );
        }
    };

    const handleOnClickChartViewChange = (type) => {
        if (type === 'general') {
            const generalData = {
                ...evolutionModel,
                agrupamentoEvolucao: 'GERAL',
            };
            if (isRetail) {
                dispatch(getEvolutionData(generalData, 'chart'));
            } else {
                dispatch(getIndustryEvolutionData(generalData, 'chart'));
            }
        } else if (isRetail) {
            dispatch(getEvolutionData(evolutionModel, 'chart'));
        } else {
            dispatch(getIndustryEvolutionData(evolutionModel, 'chart'));
        }
    };

    const handleDownload = (extension) => {
        if (hasFilterfilled && isRetail) {
            dispatch(getDownloadRetail(extension, analiseCompetitividadeData));
        }

        if (hasIndustryFilterfilled && !isRetail) {
            dispatch(
                getDownloadIndustry(
                    extension,
                    analiseIndustriaCompetitividadeData,
                ),
            );
        }
    };

    const industryLabelWithTooltip = (label) => (
        <Whisper
            trigger="hover"
            placement="top"
            speaker={<Tooltip>{label}</Tooltip>}
        >
            <p className={styles['item-name']}>{stringLimited(label)}</p>
        </Whisper>
    );

    useEffect(() => {
        dispatch(resetToInitialState());
    }, []);

    const handleShowModalVideos = () => {
        setShowModalVideoss(!showModalVideoss);
    };

    return (
        <Container className="main-container analise-competitividade">
            <div id="container">
                <Header
                    title="Análise de competitividade"
                    subtitle="Compare preços e analise a competitividade entre redes."
                    textButton="Entenda como calculamos"
                    icon={<Icon icon="trophy" />}
                    onClickTextButton={() => setShowModalUnderstandCalculation(true)}
                    bottonModalVideos={ hasNoPermission ? null : <ButtonModalVideos onClickModal={handleShowModalVideos} />}
                />
                {hasNoPermission ? (
                    <HowToUseModuleReportBanner
                        onClickModal={handleShowModalVideos}
                        dataVideo={dataVideoModuleReportModal}
                        title={
                            <>
                                <p>Conheça o Análise</p>
                                <p>de competitividade</p>
                            </>
                        }
                    />
                ) : null}
                <div className={styles['content-area']}>
                    <FiltersArea />
                    <ResultsButton
                        onClick={
                            isRetail
                                ? handleViewResults
                                : handleViewIndustryResults
                        }
                        onClickDownload={handleDownload}
                        downloadDisabled={
                            isRetail
                                ? !hasFilterfilled
                                : !hasIndustryFilterfilled
                        }
                        hasDataRendered={evolutionResponse?.length > 0}
                    />
                    {hasNoPermission ? (
                        <div className={styles['block-plan']}>
                            <div className={styles['block-plan-icon-circle']}>
                                <img src={lockIcon} alt="" />
                            </div>
                            <p>Você não tem acesso a essa funcionalidade.</p>
                            <p>
                                Entre em contato com o nosso time para ativá-la.
                            </p>
                            <Button
                                appearance="primary"
                                onClick={() => setShowCTA(true)}
                            >
                                Quero saber mais
                            </Button>
                        </div>
                    ) : null}
                </div>
            </div>
            {loadingPage ? <LoadingSpinerArea /> : null}
            {evolutionResponse?.length ? (
                <>
                    <div className={styles['graphic-container']}>
                        <ReferenceBox
                            name={
                                isRetail
                                    ? analiseCompetitividadeData.redeReferencia
                                    : industryLabelWithTooltip(productLabel)
                            }
                            product={
                                isRetail
                                    ? null
                                    : analiseIndustriaCompetitividadeData.produtoReferencia
                            }
                            badgeText="referência"
                            className={styles['grid-reference']}
                        />
                        <GeneralAverageBox
                            className={styles['grid-average']}
                            data={
                                (competitividadeResponse?.competitividadeMedia
                                    || 0) * 100
                            }
                            isRetail={isRetail}
                        />
                        <Whisper
                            trigger="hover"
                            placement="right"
                            speaker={
                                <Tooltip>{tooltipCalculationText}</Tooltip>
                            }
                        >
                            <span>
                                <QuantityBox
                                    quantity={
                                        isRetail
                                            ? competitividadeResponse.produtosComparados
                                            : competitividadeResponse.redesComparadas
                                    }
                                    quantitySelected={
                                        isRetail
                                            ? analiseCompetitividadeData?.gtins
                                                ?.length
                                            : analiseIndustriaCompetitividadeData
                                                ?.redes?.length
                                    }
                                    title={
                                        isRetail
                                            ? 'Produtos comparados'
                                            : 'Redes encontradas'
                                    }
                                    className={styles['grid-compare']}
                                />
                            </span>
                        </Whisper>
                        <Whisper
                            trigger="hover"
                            placement="right"
                            speaker={
                                <Tooltip>{tooltipCalculationText}</Tooltip>
                            }
                        >
                            <span>
                                <QuantityBox
                                    quantity={
                                        isRetail
                                            ? competitividadeResponse.redesEncontradas
                                            : competitividadeResponse.produtosEncontrados
                                    }
                                    quantitySelected={
                                        isRetail
                                            ? analiseCompetitividadeData
                                                ?.redesCompetidoras?.length
                                            : analiseIndustriaCompetitividadeData
                                                ?.produtosCompetidores?.length
                                    }
                                    title={
                                        isRetail
                                            ? 'Redes encontradas'
                                            : 'Produtos encontrados'
                                    }
                                    className={styles['grid-found']}
                                />
                            </span>
                        </Whisper>

                        <div className={styles['grid-time-evolution']}>
                            <TimeEvolutionChart
                                usuario={usuario}
                                chartData={evolutionResponse}
                                onClickViewChange={handleOnClickChartViewChange}
                                loadingChart={loadingChart}
                                competitionType={competitionType}
                                redes={
                                    analiseIndustriaCompetitividadeData?.redes
                                        ?.length
                                }
                            />
                        </div>
                    </div>
                    <Detailing
                        dataPanelHeader={detailsResponse}
                        dataTable={productsResponse}
                        competitionType={competitionType}
                    />
                </>
            ) : null}
            {isEmptyResponse ? <EmptyState /> : null}
            <ModalConfirmation
                title="Informações para alteração do plano"
                message="Caso deseje saber mais informações para evoluir o seu plano, clique em “Confirmar”"
                show={showCTA}
                btnConfirmClass="upsell"
                buttonConfirm="Confirmar"
                buttonCancel="Cancelar"
                onConfirm={sendCTA}
                onCancel={() => {
                    setShowCTA(false);
                }}
            />
            <ModalConfirm
                show={showModalUnderstandCalculation}
                modalWidth="700px"
                titleFontSize="16px"
                footerAlign="end"
                closeButton={false}
                title="Entenda como calculamos a competitividade"
                confirmButton="Fechar"
                onConfirm={() => setShowModalUnderstandCalculation(false)}
                className={styles['modal-understand-how']}
            >
                <Panel
                    header="Competitividade entre varejos"
                    collapsible
                    bordered
                    className={styles['modal-understand-how-content-panel']}
                >
                    <ModalCalculationInfoContent />
                </Panel>
                <Panel
                    header="Competitividade entre produtos"
                    collapsible
                    bordered
                    style={{ marginTop: '16px' }}
                    className={styles['modal-understand-how-content-panel']}
                >
                    <ModalProductCalculationInfoContent />
                </Panel>
            </ModalConfirm>
            <ModalVideos show={showModalVideoss} onHide={() => setShowModalVideoss(false)} videosData={videosData} />
        </Container>
    );
};

export default withRouter(AnaliseCompetitividade);
