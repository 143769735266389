import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Content,
} from 'rsuite';
import { LoadingSpinerArea } from '../../../../components/LoadingSpinner';
import { selectorCalculadoraPrevisaoDemanda } from '../../../../reducers/calculadoraPrevisaoDemanda';
import {
    setInputCalculosState,
    setInputValuesFromRequest,
} from '../../../../reducers/calculadoraPrevisaoDemanda/inputCalculos';
import {
    InfopanelCompetition,
    PreferenceForm,
    PriceForm,
    PricePassOnForm,
    TaxForm,
} from '../index';

import * as service from '../../services';
import { QueryCalculos } from '../../types';
import styles from './PriceSettingsArea.module.scss';

export const PriceSettingsArea = () => {
    const { filtros } = useSelector(selectorCalculadoraPrevisaoDemanda);
    const dispatch = useDispatch();

    const requestInfopanelCompetitors = async (data: QueryCalculos) => {
        const response = await service.getProductCompetitorResource(data);
        if (response) {
            dispatch(setInputCalculosState({ name: 'concorrenciaInfopanel', value: response }));
        }
    };

    const requestInputsPriceSettings = async (data: QueryCalculos) => {
        const response = await service.getInputsPriceSettings(data);
        if (response) {
            dispatch(setInputValuesFromRequest({ data: response }));
        }
    };

    const requestInputsData = (data: QueryCalculos) => {
        requestInfopanelCompetitors(data);
        requestInputsPriceSettings(data);
    };

    useEffect(() => {
        const filterRequestBodyIsFilled
            = filtros.filterRequestBody.product_description !== ''
            && filtros.filterRequestBody.location !== ''
            && filtros.filterRequestBody.channel !== ''
            && filtros.filterRequestBody.sub_channel !== ''
            && filtros.filterRequestBody.competitor_product_description !== ''
            && filtros.filterRequestBody.payment_method !== '';

        if (filterRequestBodyIsFilled) {
            const dataRequest = { ...filtros.mainRequestBody, query: filtros.filterRequestBody };
            requestInputsData(dataRequest);
            dispatch(setInputCalculosState({ name: 'variacaoPrecoTabela', value: 0 }));
        }
    }, [filtros.filterRequestBody]);

    return (
        <Content className={styles['price-settings-area-content']}>
            <LoadingSpinerArea area="inputs-settings" />
            <div className={styles.div1}>
                <PriceForm />
            </div>
            <div className={styles.div2}>
                <TaxForm />
            </div>
            <div className={styles.div3}>
                <InfopanelCompetition />
            </div>
            <div className={styles.div4}>
                <PricePassOnForm />
            </div>
            <div className={styles.div5}>
                <PreferenceForm />
            </div>
        </Content>
    );
};
