import classNames from 'classnames';
import React from 'react';
import { Icon as RIcon } from 'rsuite';
import Table, { TableProps } from 'rsuite/lib/Table';
import { TablePaginationProps } from 'rsuite/lib/Table/TablePagination';
import { SecondaryButton } from '../../../../../../../components';
import { parseStringToPlural } from '../../../../../../../utils';
import { formatDateToPtBr } from '../../../../../../../utils/date';
import { GetNegociacoesItemProps } from '../../NegociacoesSalvas.services';
import { StatusTag } from '../StatusTag';
import styles from './TableNegociacoesSalvas.module.scss';

const {
    Column, HeaderCell, Cell, Pagination,
} = Table;

type RowDataProps = GetNegociacoesItemProps & {
    pagination?: TablePaginationProps;
    onEditNegotiation?: (id: string) => void;
};

export type TableNegociacoesSalvasProps = TableProps<string, RowDataProps>;

export const TableNegociacoesSalvas = ({
    pagination,
    onEditNegotiation,
    ...props
}: TableNegociacoesSalvasProps) => (
    <div className={styles['table-negociacoes-salvas']}>
        <Table rowHeight={64} headerHeight={46} autoHeight {...props}>
            <Column sortable minWidth={220} flexGrow={1}>
                <HeaderCell>Negociação</HeaderCell>
                <Cell dataKey="name" height={60}>
                    {(rowData: RowDataProps) => (
                        <div
                            className={
                                styles['table-negociacoes-salvas__cell-content']
                            }
                        >
                            <div className={styles['cell-main-text']}>
                                {rowData.name}
                            </div>
                            <div className={styles['cell-description-text']}>
                                {`${rowData.totalProducts
                                    } ${parseStringToPlural(
                                        'produto',
                                        rowData.totalProducts,
                                    )}`}
                            </div>
                        </div>
                    )}
                </Cell>
            </Column>
            <Column sortable width={180}>
                <HeaderCell>Fornecedor</HeaderCell>
                <Cell dataKey="supplier" height={60}>
                    {(rowData: RowDataProps) => (
                        <div
                            className={
                                styles['table-negociacoes-salvas__cell-content']
                            }
                        >
                            <div className={styles['cell-description-text']}>
                                {rowData.supplierName}
                            </div>
                        </div>
                    )}
                </Cell>
            </Column>
            <Column sortable width={140}>
                <HeaderCell>Início vigência</HeaderCell>
                <Cell dataKey="startDate" height={60}>
                    {(rowData: RowDataProps) => (
                        <div
                            className={
                                styles['table-negociacoes-salvas__cell-content']
                            }
                        >
                            {rowData.startDate ? formatDateToPtBr(rowData.startDate) : '--'}
                        </div>
                    )}
                </Cell>
            </Column>
            <Column sortable width={140}>
                <HeaderCell>Final vigência</HeaderCell>
                <Cell dataKey="endDate" height={60}>
                    {(rowData: RowDataProps) => (
                        <div
                            className={
                                styles['table-negociacoes-salvas__cell-content']
                            }
                        >
                            {rowData.endDate ? formatDateToPtBr(rowData.endDate) : '--'}
                        </div>
                    )}
                </Cell>
            </Column>
            <Column sortable width={140}>
                <HeaderCell>Última edição</HeaderCell>
                <Cell dataKey="lastEditedDate" height={60}>
                    {(rowData: RowDataProps) => (
                        <div
                            className={
                                styles['table-negociacoes-salvas__cell-content']
                            }
                        >
                            {rowData.lastEditedDate ? formatDateToPtBr(rowData.lastEditedDate) : '--'}
                        </div>
                    )}
                </Cell>
            </Column>
            <Column sortable width={160}>
                <HeaderCell>Responsável</HeaderCell>
                <Cell dataKey="owner" height={60}>
                    {(rowData: RowDataProps) => (
                        <div
                            className={
                                styles['table-negociacoes-salvas__cell-content']
                            }
                        >
                            {rowData.owner}
                        </div>
                    )}
                </Cell>
            </Column>
            <Column sortable width={160}>
                <HeaderCell>Status</HeaderCell>
                <Cell dataKey="status" height={60}>
                    {(rowData: RowDataProps) => (
                        <div
                            className={classNames(
                                styles[
                                'table-negociacoes-salvas__cell-content'
                                ],
                                styles['table-negociacoes-salvas__status'],
                            )}
                        >
                            <StatusTag
                                className={
                                    styles[
                                    'table-negociacoes-salvas__status__tag'
                                    ]
                                }
                                status={rowData.status}
                            />
                        </div>
                    )}
                </Cell>
            </Column>
            <Column width={120}>
                <HeaderCell> </HeaderCell>
                <Cell dataKey="edit" height={60}>
                    {(rowData: RowDataProps) => (
                        <div
                            className={classNames(
                                styles[
                                'table-negociacoes-salvas__cell-content'
                                ],
                                styles['table-negociacoes-salvas__status'],
                            )}
                        >
                            <SecondaryButton
                                className={
                                    styles[
                                    'table-negociacoes-salvas__button'
                                    ]
                                }
                                icon={<RIcon icon="pencil" />}
                                onClick={() => onEditNegotiation(rowData.id)}
                            />
                        </div>
                    )}
                </Cell>
            </Column>
        </Table>
        <Pagination {...pagination} />
    </div>
);
