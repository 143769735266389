import { useContext } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getProdutosMaisVendidos } from '../services';
// @ts-ignore
import { FiltrosContextVendas } from '../Pages/VendasGerais/index.tsx';
import { format } from 'date-fns';

export const useProdutosMaisVendidos = () => {
    const filtros = useContext(FiltrosContextVendas);

    const listarProdutosMaisVendidos = async () => {
        const params = {
            filters: [
                {
                    filter: 'STOREID',
                    values: filtros?.lojasSelecionadas || null,
                },
                {
                    filter: 'PRODUCTID',
                    values: filtros?.produtosSelecionados || null,
                },
                {
                    filter: 'STARTDATE',
                    values: filtros?.periodoSelecionado?.length
                        ? [format(filtros.periodoSelecionado[0], 'yyyy-MM-dd')]
                        : null,
                },
                {
                    filter: 'ENDDATE',
                    values: filtros?.periodoSelecionado?.length
                        ? [format(filtros.periodoSelecionado[1], 'yyyy-MM-dd')]
                        : null,
                },
                {
                    filter: 'STORETYPE',
                    values: filtros?.tipoLojasSelecionadas || null,
                },
                {
                    filter: 'CATEGORYLEVEL1',
                    values: filtros?.categoriaNivel1Selecionadas || null,
                },
                {
                    filter: 'CATEGORYLEVEL2',
                    values: filtros?.categoriaNivel2Selecionadas || null,
                },
            ],
        };
        const resp = await getProdutosMaisVendidos(params);
        if (resp.length) {
            const productId = Object.keys(resp[0])[0]?.split('.');
            const description = Object.keys(resp[0])[1]?.split('.');
            const realizedMargin = Object.keys(resp[0])[3]?.split('.');
            const sumSalesAmount = Object.keys(resp[0])[2]?.split('.');
            return resp.map((item: { [x: string]: any }) => ({
                [productId[1]]: item[Object.keys(item)[0]],
                [description[1]]: item[Object.keys(item)[1]],
                [realizedMargin[1]]: parseFloat(
                    // @ts-ignore
                    +item[Object.keys(item)[3]]
                ).toLocaleString('pt-BR', {
                    style: 'percent',
                    minimumFractionDigits: 2,
                }),
                [sumSalesAmount[1]]: parseFloat(
                    // @ts-ignore
                    +item[Object.keys(item)[2]]
                ).toLocaleString('pt-BR', {
                    minimumFractionDigits: 2,
                }),
            }));
        }
        return [];
    };

    const { isLoading, isError, data, error } = useQuery(
        ['listaProdutosMaisVendidos', filtros],
        listarProdutosMaisVendidos,
        {
            retry: false,
            cacheTime: 6 * 180 * 1000,
            staleTime: 6 * 180 * 1000,
        }
    );

    return {
        isLoading,
        isError,
        data,
        error,
    };
};
