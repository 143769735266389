import Immutable from 'seamless-immutable';
import { CHANGE_ACTIVE_KEY } from '../actions/actionsNavbar';

const navbarInitialState = Immutable({
    activeKey: null,
});

function navbarDataReducer(state = navbarInitialState, action) {
    switch (action.type) {
        case CHANGE_ACTIVE_KEY:
            return state.merge({
                activeKey: action.activeKey,
            });
        default:
            return state;
    }
}

export default navbarDataReducer;
