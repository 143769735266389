import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Whisper, Tooltip, Content } from 'rsuite';
import HistoryReportTable from '../Components/HistoryReportTable';
import { getHistoryList } from '../../../../../actions/actionsModuloRelatorio';
import infoIcon from '../../../../../assets/icons/icon_info-gray.svg';

const HistoryReportContent = () => {
    const [page, setPage] = useState(0);
    const dispatch = useDispatch();

    const { generateReportHistoryData, tableLoading } = useSelector(
        (state) => ({
            generateReportHistoryData:
                state.moduloRelatorioDataReducer?.generateReportHistoryData,
            tableLoading: state.moduloRelatorioDataReducer?.tableLoading,
        }),
        shallowEqual,
    );

    const handleGetHistoryList = () => {
        dispatch(
            getHistoryList({
                size: 10,
                page,
                usuarioEmail: JSON.parse(localStorage.getItem('usuario'))
                    ?.email,
                clientCode: JSON.parse(localStorage.getItem('cliente'))?.codigo,
            }),
        );
    };

    setTimeout(() => {
        if (generateReportHistoryData?.content?.some((item) => item.dataFimGeracao === null)) {
            handleGetHistoryList();
        }
    }, 30000);

    useEffect(() => {
        handleGetHistoryList();
    }, [page]);

    return (
        <Content className="history-report">
            <div className="content-title-wrapper">
                <p className="content-title">Últimos relatórios</p>
                <Whisper
                    placement="right"
                    speaker={(
                        <Tooltip>
                            Histórico de relatórios gerados nos últimos 30 dias
                        </Tooltip>
                    )}
                >
                    <img src={infoIcon} alt="" />
                </Whisper>
            </div>
            <HistoryReportTable
                data={generateReportHistoryData}
                setPage={setPage}
                loading={tableLoading}
            />
        </Content>
    );
};

export default HistoryReportContent;
