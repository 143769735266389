import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectorCalculadoraPrevisaoDemanda } from '../../../reducers/calculadoraPrevisaoDemanda/calculadoraPrevisaoDemandaReducer';
import {
    FiltersNameProps,
    addFilterState,
    selectFilterValue,
    setFilterList,
} from '../../../reducers/calculadoraPrevisaoDemanda/filtros';
import * as service from '../services';

export const useSelectLocal = () => {
    const [timer, setTimer] = useState(null);

    const dispatch = useDispatch();

    const { filtros } = useSelector(
        selectorCalculadoraPrevisaoDemanda,
    );

    const setList = (name: FiltersNameProps, list) => {
        const newList = list.map((item) => ({
            value: item.key,
            label: item.value,
        }));

        const cacheList = filtros[name].cache;
        const removeDuplicate = newList.filter(
            (i) => !filtros[name].value.includes(i.value),
        );
        const concatList = filtros[name].value.length
            ? cacheList.concat(removeDuplicate)
            : newList;

        dispatch(setFilterList({ name, list: concatList }));
    };

    const handleSelectLocal = (name: FiltersNameProps, value: string, item: any) => {
        dispatch(selectFilterValue({ name: 'location', data: value }));
        dispatch(addFilterState({
            name,
            data: {
                cache: [item],
                value: [value],
            },
        }));
    };

    const requestLocal = (query: string) => {
        service.getLocationUF(query).then((response) => {
            if (response) {
                setList('uf', response);
            }
        });
    };

    const requestRegional = (query: string) => {
        service.getRegional(query).then((response) => {
            if (response) {
                setList('regional', response);
            }
        });
    };

    const onSearchLocal = (name: string, inputValue: string) => {
        clearTimeout(timer);

        const newTimer = setTimeout(() => {
            name === 'UF' ? requestLocal(inputValue) : requestRegional(inputValue);
        }, 500);

        setTimer(newTimer);
    };

    const handleClearLocal = (name: FiltersNameProps) => {
        dispatch(selectFilterValue({ name: 'location', data: '' }));
        dispatch(addFilterState({
            name,
            data: {
                cache: [],
                value: [],
            },
        }));
    };

    const handleSelectLocatioType = (value: string) => {
        dispatch(selectFilterValue({ name: 'location_type', data: value }));
    };

    const handleClearLocatioType = () => {
        dispatch(selectFilterValue({ name: 'location_type', data: null }));
    };

    return {
        localHandlers: {
            onSearchLocal,
            handleSelectLocal,
            handleClearLocal,
            handleSelectLocatioType,
            handleClearLocatioType,
        },

    };
};
