// @ts-nocheck
import classNames from 'classnames';
import React, { HtmlHTMLAttributes } from 'react';
import { Button, Content } from 'rsuite';
import { BigNumbersBox } from '../../../../../components/BigNumbersBox';
import { LoadingSpinerLocal } from '../../../../../components/LoadingSpinner';
import {
    TotalPriceDataType,
    VariationPriceDataType,
} from './BigNumbersArea.types';
import styles from './styles.module.scss';

interface IBigNumbersArea extends HtmlHTMLAttributes<HTMLElement> {
    variationPriceData?: VariationPriceDataType;
    totalPriceData?: TotalPriceDataType;
    variationLoading?: boolean;
    totalLoading?: boolean;
    update?: boolean;
    onClickUpdate?: () => void;
}

const initialVariation = {
    marginCurrent: 0,
    marginNew: 0,
    cpiCurrent: 0,
    cpiNew: 0,
    profitCurrent: 0,
    profitNew: 0,
};

const initialTotal = {
    totalPrices: 0,
    totalSugestedPrices: 0,
    totalAppliedPrices: 0,
    totalPricesFiltered: 0,
    totalSugestedFiltered: 0,
    totalAppliedFiltered: 0,
};

export const BigNumbersArea = ({
    variationPriceData = initialVariation,
    totalPriceData = initialTotal,
    variationLoading,
    totalLoading,
    update,
    onClickUpdate,
    className,
    ...props
}: IBigNumbersArea) => {
    const formatNumberValue = (number: number) => {
        const timesHundred = number * 100;
        return timesHundred?.toFixed(2).toString().replace('.', ',');
    };

    const formatCurrency = (number: number) =>
        number?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });

    const diffPercent = (numberNew: number, numberCurrent: number) => {
        const newNumb = Number((numberNew * 100).toFixed(2));
        const currNumb = Number((numberCurrent * 100).toFixed(2));
        const diff = newNumb - currNumb;

        return diff;
    };

    const diffEstimate = (numberNew: number, numberCurrent: number) => {
        const diffMinus = (1 - numberCurrent / numberNew) * 100;

        if (Number.isNaN(diffMinus)) {
            return 0;
        }

        return diffMinus;
    };

    const diffCurrency = (numberNew: number, numberCurrent: number) => {
        const minus = numberNew - numberCurrent;
        return formatCurrency(minus);
    };

    const diffBoxStatus = (numberNew: number, numberCurrent: number) => {
        const newNumb = Number((numberNew * 100).toFixed(2));
        const currNumb = Number((numberCurrent * 100).toFixed(2));
        const diffFormat = newNumb - currNumb;

        if (diffFormat === 0) return 'container';
        if (diffFormat > 0) return 'up';
        if (diffFormat < 0) return 'down';
    };

    return (
        <Content
            className={classNames(styles['big-numbers-container'], className)}
            {...props}
        >
            <BigNumbersBox.Root height="73px">
                <LoadingSpinerLocal
                    size="sm"
                    position="absolute"
                    loading={totalLoading}
                />
                <div className={styles['flex-center']}>
                    <BigNumbersBox.MainValue>
                        {Number(totalPriceData?.totalPricesFiltered)}
                    </BigNumbersBox.MainValue>
                    {/* <Button
                        appearance="link"
                        className={styles['link-text']}
                    >
                        Selecionar
                    </Button> */}
                </div>
                <div className={styles['flex-base']}>
                    <BigNumbersBox.RegularText>de</BigNumbersBox.RegularText>
                    <BigNumbersBox.BoldText
                        className={styles['bold-text-value']}
                    >
                        {Number(totalPriceData?.totalPrices)}
                    </BigNumbersBox.BoldText>
                    <BigNumbersBox.RegularText>do</BigNumbersBox.RegularText>
                </div>
                <BigNumbersBox.RegularText>
                    total de preços
                </BigNumbersBox.RegularText>
            </BigNumbersBox.Root>
            <BigNumbersBox.Root height="73px">
                <LoadingSpinerLocal
                    size="sm"
                    position="absolute"
                    loading={totalLoading}
                />
                <BigNumbersBox.MainValue>
                    {Number(totalPriceData?.totalSugestedFiltered)}
                </BigNumbersBox.MainValue>
                <div className={styles['flex-base']}>
                    <BigNumbersBox.RegularText>de</BigNumbersBox.RegularText>
                    <BigNumbersBox.BoldText
                        className={styles['bold-text-value']}
                    >
                        {Number(totalPriceData?.totalSugestedPrices)}
                    </BigNumbersBox.BoldText>
                    <BigNumbersBox.RegularText>novos</BigNumbersBox.RegularText>
                </div>
                <BigNumbersBox.RegularText>preços</BigNumbersBox.RegularText>
            </BigNumbersBox.Root>
            <BigNumbersBox.Root height="73px">
                <LoadingSpinerLocal
                    size="sm"
                    position="absolute"
                    loading={totalLoading}
                />
                <BigNumbersBox.MainValue>
                    {Number(totalPriceData?.totalAppliedFiltered)}
                </BigNumbersBox.MainValue>
                <div className={styles['flex-base']}>
                    <BigNumbersBox.RegularText>de</BigNumbersBox.RegularText>
                    <BigNumbersBox.BoldText
                        className={styles['bold-text-value']}
                    >
                        {Number(totalPriceData?.totalAppliedPrices)}
                    </BigNumbersBox.BoldText>
                    <BigNumbersBox.RegularText>
                        preços
                    </BigNumbersBox.RegularText>
                </div>
                <BigNumbersBox.RegularText>aplicados</BigNumbersBox.RegularText>
            </BigNumbersBox.Root>
            <BigNumbersBox.Root height="73px">
                <LoadingSpinerLocal
                    size="sm"
                    position="absolute"
                    loading={variationLoading}
                />
                <div className={styles['flex-center']}>
                    <BigNumbersBox.MainValue>
                        {formatNumberValue(variationPriceData?.cpiNew)}%
                    </BigNumbersBox.MainValue>
                    <BigNumbersBox.Indicator
                        percentPoint
                        indicator={diffPercent(
                            variationPriceData?.cpiNew,
                            variationPriceData?.cpiCurrent,
                        )}
                    />
                </div>
                <BigNumbersBox.RegularText>
                    Competitividade projetada,
                </BigNumbersBox.RegularText>
                <div className={styles['flex-base']}>
                    <BigNumbersBox.RegularText>era</BigNumbersBox.RegularText>
                    <BigNumbersBox.BoldText
                        className={styles['bold-text-value']}
                    >
                        {formatNumberValue(variationPriceData?.cpiCurrent)}%
                    </BigNumbersBox.BoldText>
                </div>
            </BigNumbersBox.Root>
            <BigNumbersBox.Root height="73px">
                <LoadingSpinerLocal
                    size="sm"
                    position="absolute"
                    loading={variationLoading}
                />
                <div className={styles['flex-center']}>
                    <BigNumbersBox.MainValue>
                        {formatNumberValue(variationPriceData?.marginNew)}%
                    </BigNumbersBox.MainValue>
                    <BigNumbersBox.Indicator
                        percentPoint
                        indicator={diffPercent(
                            variationPriceData?.marginNew,
                            variationPriceData?.marginCurrent,
                        )}
                    />
                </div>
                <BigNumbersBox.RegularText>
                    Margem projetada,
                </BigNumbersBox.RegularText>
                <div className={styles['flex-base']}>
                    <BigNumbersBox.RegularText>era</BigNumbersBox.RegularText>
                    <BigNumbersBox.BoldText
                        className={styles['bold-text-value']}
                    >
                        {formatNumberValue(variationPriceData?.marginCurrent)}%
                    </BigNumbersBox.BoldText>
                </div>
            </BigNumbersBox.Root>
            <BigNumbersBox.Root
                height="73px"
                type={diffBoxStatus(
                    variationPriceData?.profitNew,
                    variationPriceData?.profitCurrent,
                )}
            >
                <LoadingSpinerLocal
                    size="sm"
                    position="absolute"
                    loading={variationLoading}
                />
                <div className={styles['flex-center']}>
                    <BigNumbersBox.MainValue>
                        {diffCurrency(
                            variationPriceData?.profitNew,
                            variationPriceData?.profitCurrent,
                        )}
                    </BigNumbersBox.MainValue>
                    <BigNumbersBox.Indicator
                        indicator={diffEstimate(
                            variationPriceData?.profitNew,
                            variationPriceData?.profitCurrent,
                        )}
                    />
                </div>
                <BigNumbersBox.RegularText>
                    Estimativa de
                </BigNumbersBox.RegularText>
                <BigNumbersBox.RegularText>
                    diferença no lucro
                </BigNumbersBox.RegularText>
            </BigNumbersBox.Root>
            {update ? (
                <Button
                    appearance="default"
                    className={styles['update-button']}
                    onClick={onClickUpdate}
                >
                    <BigNumbersBox.RegularText>
                        Cálculos
                    </BigNumbersBox.RegularText>
                    <BigNumbersBox.RegularText>
                        desatualizados
                    </BigNumbersBox.RegularText>
                    <p className={styles['blue-text']}>Atualizar</p>
                </Button>
            ) : null}
        </Content>
    );
};
