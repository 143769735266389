import { useReducer } from 'react';

type Item = boolean;

export type ModalStateProps = Partial<Record<string, Item>>;

type Action = {
    type: 'SET';
    key: string;
    payload: Item;
};

const reducer = (state: ModalStateProps, action: Action) => {
    switch (action.type) {
        case 'SET':
            return {
                ...state,
                [action.key]: action.payload,
            };
        default:
            return state;
    }
};

const SET_DATA = (key: keyof ModalStateProps, payload: Item) => ({
    type: 'SET',
    key,
    payload,
} as const);

export const useModal = () => {
    const [data, dispatch] = useReducer(reducer, {});

    const onSetData = (key: keyof ModalStateProps, payload: Item) => {
        dispatch(SET_DATA(key, payload));
    };

    return [
        data,
        {
            onOpen: (key: string) => onSetData(key, true),
            onHide: (key: string) => onSetData(key, false),
        },
    ] as const;
};
