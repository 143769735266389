import React, { useEffect, useState } from 'react';
import { SummaryRequestResponseType } from '../../types';
import styles from './styles.module.scss';

export interface IProgressBar {
    summaryData?: SummaryRequestResponseType,
    requestsQuantity?: number,
}

export const ProgressBar = ({ summaryData, requestsQuantity = 0 }: IProgressBar) => {
    const [percentage, setPercentage] = useState({
        first: 0,
        second: 0,
    });

    const using = summaryData?.totalRequests - summaryData?.available;
    const isUsageBiggerThanAvailable = requestsQuantity > using;
    const isHigherThan70 = Math.round((requestsQuantity / summaryData?.available) * 100);

    const calculatePercentage = (inUse: number, total: number, requests: number) => {
        const usingPercent = Math.round((inUse / total) * 100);
        const availablePercent
            = Math.round(((total - inUse) / total) * 100); // available percentage of total
        const requestsPercent
            = Math.round((requests / (total - inUse)) * 100); // user request percentage of available quantity
        const requestsPercentOfAvailablePercent
            = Math.ceil(((requestsPercent / 100) * (availablePercent / 100)) * 100); // user request percentage of available percentage

        if (requestsQuantity === 0) {
            setPercentage({
                first: usingPercent === 0 ? 0.3 : usingPercent,
                second: 0,
            });
        } else {
            setPercentage({
                first: usingPercent === 0 ? 0.3 : usingPercent,
                second: requestsPercentOfAvailablePercent > 0 ? requestsPercentOfAvailablePercent : 0.3,
            });
        }
    };

    useEffect(() => {
        calculatePercentage(using, summaryData?.totalRequests, requestsQuantity);
    }, [requestsQuantity, summaryData?.totalRequests]);

    return (
        <div className={styles['progress-bar-container']}>
            <div className={styles['progress-bar-wrapper']}>
                <div
                    className={styles.first}
                    style={{
                        background: '#378EF0',
                        borderTopLeftRadius: '5px',
                        borderBottomLeftRadius: '5px',
                        width: `${percentage.first}%`,
                    }}
                />
                {isHigherThan70 > 70
                    ? <div
                            className={styles.third}
                            style={{
                            background: '#EC5B62',
                            borderTopRightRadius: isUsageBiggerThanAvailable ? '5px' : '',
                            borderBottomRightRadius: isUsageBiggerThanAvailable ? '5px' : '',
                            width: `${percentage.second}%`,
                        }}
                    />
                    : <div
                            className={styles.second}
                            style={{
                            background: '#33AB84',
                            width: `${percentage.second}%`,
                        }}
                    />}
            </div>
            <div className={styles['progress-bar-footer']}>
                <div className={styles['text-wrapper']}>
                    <p className={styles['regular-text']}>Solicitações em uso:</p>
                    <p className={styles['bold-text']}>
                        {summaryData ? using : 0}
                        {' '}
                        /
                        {' '}
                        {summaryData?.totalRequests}
                    </p>
                </div>
                <div className={styles['text-wrapper']}>
                    <p className={styles['regular-text']}>Solicitações que serão enviadas:</p>
                    <p className={styles['bold-text']}>
                        {requestsQuantity}
                        {' '}
                        /
                        {' '}
                        {summaryData?.available}
                    </p>
                </div>
            </div>
        </div>
);
};
