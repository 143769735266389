import * as CompetitividadeService from '../pages/ISA/InfoPanel/AnaliseCompetitividade/services';
import { downloadFile } from '../utils/Helpers';

export const SET_COMPETITIVIDADE_FILTER = 'SET_COMPETITIVIDADE_FILTER';
export const SET_PREFERENCE_FILTER = 'SET_PREFERENCE_FILTER';
export const SET_ITEM_PREFERENCE_FILTER = 'SET_ITEM_PREFERENCE_FILTER';
export const SET_COMPETITIVITE_DATA = 'SET_COMPETITIVITE_DATA';
export const SET_SINGLE_REDUCER = 'SET_SINGLE_REDUCER';
export const RESET_TO_INITIAL_STATE = 'RESET_TO_INITIAL_STATE';
export const SET_FILTROS_DATA_COMPETITIVIDADE =
    'SET_FILTROS_DATA_COMPETITIVIDADE';
export const SET_INDUSTRIA_COMPETITIVIDADE_FILTER =
    'SET_INDUSTRIA_COMPETITIVIDADE_FILTER';
export const SET_SAVED_COMPETITIVIDADE_FILTER =
    'SET_SAVED_COMPETITIVIDADE_FILTER';
export const SET_SAVED_COMPETITIVIDADE_INDUSTRY_FILTER =
    'SET_SAVED_COMPETITIVIDADE_INDUSTRY_FILTER';

export function setCompetitividadeFilter(name, item) {
    return {
        type: SET_COMPETITIVIDADE_FILTER,
        item,
        name,
    };
}

export function setIndustriaCompetitividadeFilter(name, item) {
    return {
        type: SET_INDUSTRIA_COMPETITIVIDADE_FILTER,
        item,
        name,
    };
}

export function setItemPreferenceFilter(name, item) {
    return {
        type: SET_ITEM_PREFERENCE_FILTER,
        item,
        name,
    };
}

export function setPreferenceFilter(preferences) {
    return {
        type: SET_PREFERENCE_FILTER,
        preferences,
    };
}

export function setSingleReducer(name, value) {
    return {
        type: SET_SINGLE_REDUCER,
        name,
        value,
    };
}

export function setFiltrosDataCompetitividade(name, value) {
    return {
        type: SET_FILTROS_DATA_COMPETITIVIDADE,
        name,
        value,
    };
}

export function resetToInitialState() {
    return {
        type: RESET_TO_INITIAL_STATE,
    };
}

export function setSavedCompetitividadeFilter(item) {
    return {
        type: SET_SAVED_COMPETITIVIDADE_FILTER,
        item,
    };
}

export function setSavedCompetitividadeIndustriaFilter(item) {
    return {
        type: SET_SAVED_COMPETITIVIDADE_INDUSTRY_FILTER,
        item,
    };
}

export function getCompetitiviteData(params) {
    return (dispatch) => {
        dispatch(setSingleReducer('isEmptyResponse', false));
        dispatch(setSingleReducer('loadingPage', true));

        CompetitividadeService.getAverageCompetitivite(params)
            .then((data) => {
                if (data) {
                    dispatch(setSingleReducer('competitividadeResponse', data));
                    dispatch(setSingleReducer('loadingPage', false));
                } else {
                    dispatch(setSingleReducer('isEmptyResponse', true));
                    dispatch(setSingleReducer('loadingPage', false));
                }
            })
            .catch((err) => {
                if (err) {
                    dispatch(setSingleReducer('isEmptyResponse', true));
                    dispatch(setSingleReducer('loadingPage', false));
                }
            });
    };
}

export function getIndustryCompetitiviteData(params) {
    return (dispatch) => {
        dispatch(setSingleReducer('isEmptyResponse', false));
        dispatch(setSingleReducer('loadingPage', true));

        CompetitividadeService.getIndustryAverageCompetitivite(params)
            .then((data) => {
                if (data) {
                    dispatch(setSingleReducer('competitividadeResponse', data));
                    dispatch(setSingleReducer('loadingPage', false));
                } else {
                    dispatch(setSingleReducer('isEmptyResponse', true));
                    dispatch(setSingleReducer('loadingPage', false));
                }
            })
            .catch((err) => {
                if (err) {
                    dispatch(setSingleReducer('isEmptyResponse', true));
                    dispatch(setSingleReducer('loadingPage', false));
                }
            });
    };
}

export function savePreferences(preferences) {
    return () => {
        CompetitividadeService.saveCompetitivitePreferences(preferences);
    };
}

export function getEvolutionData(params, loadType) {
    return (dispatch) => {
        if (loadType === 'chart') {
            dispatch(setSingleReducer('loadingChart', true));
        }

        CompetitividadeService.getEvolutionCompetitive(params).then((data) => {
            if (data) {
                dispatch(setSingleReducer('evolutionResponse', data));
                dispatch(setSingleReducer('loadingChart', false));
            } else {
                dispatch(setSingleReducer('loadingChart', false));
            }
        });
    };
}

export function getDetailsData(params) {
    return (dispatch) => {
        dispatch(setSingleReducer('loadingDetails', true));
        CompetitividadeService.getDetailsCompetitive(params)
            .then((data) => {
                if (data) {
                    dispatch(setSingleReducer('detailsResponse', data));
                    dispatch(setSingleReducer('loadingDetails', false));
                } else {
                    dispatch(setSingleReducer('loadingDetails', false));
                }
            })
            .catch(() => dispatch(setSingleReducer('loadingDetails', false)));
    };
}

export function getDetailsProductsData(params) {
    return (dispatch) => {
        dispatch(setSingleReducer('loadingProducts', true));
        CompetitividadeService.getDetailsProductsCompetitive(params)
            .then((data) => {
                if (data) {
                    dispatch(setSingleReducer('productsResponse', data));
                    dispatch(setSingleReducer('loadingProducts', false));
                } else {
                    dispatch(setSingleReducer('loadingProducts', false));
                }
            })
            .catch(() => dispatch(setSingleReducer('loadingProducts', false)));
    };
}

export function getDownloadRetail(extension, data) {
    return (dispatch) => {
        dispatch(setSingleReducer('loadingPage', true));
        CompetitividadeService.downloadRetail(extension, data)
            .then((response) => {
                const content = response.data;
                const fileName = `solicitacoes_competitividade.${extension}`;
                downloadFile(content, fileName);
                dispatch(setSingleReducer('loadingPage', false));
            })
            .catch(() => dispatch(setSingleReducer('loadingPage', false)));
    };
}

export function getIndustryEvolutionData(params, loadType) {
    return (dispatch) => {
        if (loadType === 'chart') {
            dispatch(setSingleReducer('loadingChart', true));
        }

        CompetitividadeService.getIndustryEvolutionCompetitive(params)
            .then((data) => {
                if (data) {
                    dispatch(setSingleReducer('evolutionResponse', data));
                    dispatch(setSingleReducer('loadingChart', false));
                } else {
                    dispatch(setSingleReducer('loadingChart', false));
                }
            })
            .catch(() => dispatch(setSingleReducer('loadingChart', false)));
    };
}

export function getIndustryDetailsData(params) {
    return (dispatch) => {
        dispatch(setSingleReducer('loadingDetails', true));
        CompetitividadeService.getIndustryDetailsCompetitive(params)
            .then((data) => {
                if (data) {
                    dispatch(setSingleReducer('detailsResponse', data));
                    dispatch(setSingleReducer('loadingDetails', false));
                } else {
                    dispatch(setSingleReducer('loadingDetails', false));
                }
            })
            .catch(() => dispatch(setSingleReducer('loadingDetails', false)));
    };
}

export function getIndustryDetailsNetworksData(params) {
    return (dispatch) => {
        dispatch(setSingleReducer('loadingProducts', true));
        CompetitividadeService.getIndustryDetailsNetworksCompetitive(params)
            .then((data) => {
                if (data) {
                    dispatch(setSingleReducer('productsResponse', data));
                    dispatch(setSingleReducer('loadingProducts', false));
                } else {
                    dispatch(setSingleReducer('loadingProducts', false));
                }
            })
            .catch(() => dispatch(setSingleReducer('loadingProducts', false)));
    };
}

export function getDownloadIndustry(extension, data) {
    return (dispatch) => {
        dispatch(setSingleReducer('loadingPage', true));
        CompetitividadeService.downloadIndustry(extension, data)
            .then((response) => {
                const content = response.data;
                const fileName = `solicitacoes_competitividade.${extension}`;
                downloadFile(content, fileName);
                dispatch(setSingleReducer('loadingPage', false));
            })
            .catch(() => dispatch(setSingleReducer('loadingPage', false)));
    };
}
