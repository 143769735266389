import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import { RootState } from '../../@types/RootState';

export type GerenciadorSelectedDatapoints = {
    selectedIds: string[];
    excludedIds: string[];
    selectedAll: boolean;
};

const initialState: GerenciadorSelectedDatapoints = {
    selectedIds: [],
    excludedIds: [],
    selectedAll: false,
};

export type UpdateGerenciadorSelectedDatapointsList =
    | 'selectedIds'
    | 'excludedIds';

const slice = createSlice({
    name: 'ipa/gerenciador/selectedDatapoints',
    initialState,
    reducers: {
        SET_SELECTED_IDS(
            state,
            action: PayloadAction<GerenciadorSelectedDatapoints['selectedIds']>,
        ) {
            state.selectedIds = action.payload;
        },
        SET_EXCLUDED_IDS(
            state,
            action: PayloadAction<GerenciadorSelectedDatapoints['excludedIds']>,
        ) {
            state.excludedIds = action.payload;
        },
        SET_SELECTED_ALL(
            state,
            action: PayloadAction<GerenciadorSelectedDatapoints['selectedAll']>,
        ) {
            state.selectedAll = action.payload;
        },
        RESET_SELECTED_DATAPOINTS() {
            return initialState;
        },
        ADD_EXPANDED_ROW(
            state,
            {
                payload,
            }: PayloadAction<{
                productsToBePricedId: string;
                updateList: UpdateGerenciadorSelectedDatapointsList;
            }>,
        ) {
            const uniqueList = _.uniq([
                ...state[payload.updateList],
                payload.productsToBePricedId,
            ]);
            return {
                ...state,
                [payload.updateList]: uniqueList,
            };
        },
        REMOVE_SELECTED_DATAPOINT(
            state,
            {
                payload,
            }: PayloadAction<{
                productsToBePricedId: string;
                updateList: UpdateGerenciadorSelectedDatapointsList;
            }>,
        ) {
            const uniqueList = state[payload.updateList].filter(
                (id) => id !== payload.productsToBePricedId,
            );
            return {
                ...state,
                [payload.updateList]: uniqueList,
            };
        },
    },
});

const selectorSelectedDatapoints = (state: RootState) => {
    return state.gerenciadorPrecosReducer.selectedDatapoints;
};

const {
    SET_SELECTED_IDS,
    SET_EXCLUDED_IDS,
    SET_SELECTED_ALL,
    RESET_SELECTED_DATAPOINTS,
    ADD_EXPANDED_ROW,
    REMOVE_SELECTED_DATAPOINT,
} = slice.actions;

export {
    ADD_EXPANDED_ROW,
    initialState,
    REMOVE_SELECTED_DATAPOINT,
    RESET_SELECTED_DATAPOINTS,
    selectorSelectedDatapoints,
    SET_EXCLUDED_IDS,
    SET_SELECTED_ALL,
    SET_SELECTED_IDS,
};

export default slice.reducer;
