import { format, parseISO } from 'date-fns';
import React from 'react';
import { Loader } from 'rsuite';
import { Tag } from '../../Lite';
import { useDataUltimaIntegracao } from '../hooks';

const STATUSES = {
    NOT_UPDATED: 'red',
    UPDATING: 'red',
    UPDATED: 'green',
};

export const DataUltimaIntegracao = () => {
    const { data, isLoading } = useDataUltimaIntegracao();

    if (isLoading) {
        return (
            <Tag className="data-integracao-container">
                <span>Carregando informação...</span>
                <Loader className="loader-integracao" />
            </Tag>
        );
    }

    if (!data?.lastExecutionDate) {
        return (
            <Tag className="data-integracao-container" skin="red">
                Sem informações
            </Tag>
        );
    }

    const parsedLastExecutionDate = format(
        parseISO(data.lastExecutionDate),
        'dd/MM/yyy, HH:mm',
    );

    return (
        <Tag
            className="data-integracao-container"
            skin={STATUSES[data?.engineStatus]}
            size="xsmall"
        >
            atualizado em {parsedLastExecutionDate}
        </Tag>
    );
};
