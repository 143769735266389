import { trackPromise } from 'react-promise-tracker';
import api from '../../../../utils/API';

export const createFreeUser = (data) => trackPromise(
    api({
        url: `${process.env.REACT_APP_CLIENTE_URL}/usuarios/freemium`,
        method: 'POST',
        data: JSON.stringify(data),
    }).then((resp) => resp.data),
);

export const updateFreeUser = (data) => trackPromise(
    api({
        url: `${process.env.REACT_APP_CLIENTE_URL}/usuarios/freemium`,
        method: 'PUT',
        data: JSON.stringify(data),
        expectToken: true,
    }).then((resp) => resp.data),
);

export const getFree = () => trackPromise(
    api({
        url: `${process.env.REACT_APP_CLIENTE_URL}/usuarios/freemium`,
        method: 'GET',
        expectToken: true,
    }).then((resp) => resp.data),
);

export const checkUserEmail = (email) => trackPromise(
    api({
        url: `${process.env.REACT_APP_CLIENTE_URL}/usuarios/email`,
        method: 'POST',
        params: {
            email,
        },
    }).then((resp) => resp.data),
);

export const validateDataQuantity = (data) => trackPromise(
    api({
        url: `${process.env.REACT_APP_CLIENTE_URL}/usuarios/freemium/validate_sufficient_data`,
        method: 'POST',
        data: JSON.stringify(data),
    }).then((resp) => resp.data),
    'hasData',
);

export const addUserToDataUpdate = (data) => trackPromise(
    api({
        url: `${process.env.REACT_APP_CLIENTE_URL}/usuarios/freemium/subscribe`,
        method: 'POST',
        data: JSON.stringify(data),
    }).then((resp) => resp.data),
);

export const getSegmentos = () => api({
    url: `${process.env.REACT_APP_CLIENTE_URL}/usuarios/segmentos`,
    method: 'GET',
}).then((resp) => resp.data);

export const getCargos = () => api({
    url: `${process.env.REACT_APP_CLIENTE_URL}/usuarios/cargos`,
    method: 'GET',
}).then((resp) => resp.data);

export const getCasosDeUso = () => api({
    url: `${process.env.REACT_APP_CLIENTE_URL}/usuarios/casos-de-uso`,
    method: 'GET',
}).then((resp) => resp.data);

export const getCanais = () => api({
    url: `${process.env.REACT_APP_CLIENTE_URL}/contratos/servicos/panel/grupo-tipo-loja`,
    method: 'GET',
}).then((resp) => resp.data);

export const getUfs = () => api({
    url: `${process.env.REACT_APP_CLIENTE_URL}/regioes`,
    method: 'GET',
}).then((resp) => resp.data);

export const getCidades = (query) => api({
    url: `${process.env.REACT_APP_CLIENTE_URL}/cidades`,
    method: 'GET',
    params: {
        query,
    },
}).then((resp) => resp.data);

export const leadFree = (data) => api({
    url: `${process.env.REACT_APP_CLIENTE_URL}/lead`,
    method: 'POST',
    data: JSON.stringify(data),
}).then((resp) => resp.data);
