import { GerenciadorPrecosDatapoint } from '../pages/IPA/RevisaoPrecos/services';

export const isBrokenedPriceList = ({
    price = null,
    maxPriceList = null,
    minPriceList = null,
}: Pick<
    GerenciadorPrecosDatapoint,
    'maxPriceList' | 'minPriceList' | 'price'
>): boolean => {
    if (!price || !maxPriceList || !minPriceList) {
        return false;
    }
    if (price > maxPriceList || price < minPriceList) {
        return true;
    }
    return false;
};

export const isChangedPrice = ({
    price,
    retailPrice,
}: Pick<GerenciadorPrecosDatapoint, 'price' | 'retailPrice'>): boolean => {
    return price !== retailPrice;
};
