import Immutable from 'seamless-immutable';
import {
    SET_EMAIL, UPDATE_STAGE, SET_ACCOUNT_DATA, SET_ROLES,
    SET_COMPANY_SEGMENTS, SET_MOTIVES, SET_ACCOUNT_MOTIVES, SET_STATES, SET_CITIES, SET_ENOUGH_DATA, SET_ACCOUNT_REGION, SET_ENOUGH_DATA_NULL, SET_SECTORS,
} from '../actions/actionsCreateAccountFreemium';

const accountFreemiumState = Immutable({
    account: {
        email: '',
        name: '',
        phone: '',
        company: '',
        role: null,
        companySegment: null,
        activitySector: null,
        channel: '',
        region: '',
        state: '',
        city: '',
        motives: [],
        userType: null,
    },
    stage: 3,
    roles: [],
    companySegments: [],
    sectors: [],
    motives: [],
    states: [],
    cities: [],
    hasEnoughData: null,
});

function accountFreemiumDataReducer(state = accountFreemiumState, action) {
    switch (action.type) {
        case SET_ROLES:
            return state.merge({
                ...state,
                roles: action.roles.map((item) => ({
                    label: item.descricao,
                    value: item.name,
                })),
            });
        case SET_COMPANY_SEGMENTS:
            return state.merge({
                ...state,
                companySegments: action.companySegments.map((item) => ({
                    label: item.descricao,
                    value: item.name,
                })),
            });
        case SET_SECTORS:
            return state.merge({
                ...state,
                sectors: action.sectors.map((item) => ({
                    label: item.descricao,
                    value: item.name,
                })),
            });
        case SET_MOTIVES:
            return state.merge({
                ...state,
                motives: action.motives,
            });
        case SET_STATES:
            return state.merge({
                ...state,
                states: action.states.map((item) => ({
                    label: item.descricao,
                    value: item.descricao,
                    name: item.value,
                })),
            });
        case SET_CITIES:
            return state.merge({
                ...state,
                cities: action.cities.map((item) => ({
                    label: `${item.cidade} - ${item.regiao.nome}`,
                    value: `${item.cidade} - ${item.regiao.nome}`,
                    codigo: item.codigo,
                })),
            });
        case SET_EMAIL:
            return state.merge({
                ...state,
                account: {
                    ...state.account,
                    email: action.email,
                },
            });
        case SET_ENOUGH_DATA:
            return state.merge({
                ...state,
                hasEnoughData: action.data.permiteCadastro,
            });
        case SET_ENOUGH_DATA_NULL:
            return state.merge({
                ...state,
                hasEnoughData: null,
            });
        case SET_ACCOUNT_DATA:
            return state.merge({
                ...state,
                account: {
                    ...state.account,
                    [action.attributeName]: action.value,
                },
            });
        case SET_ACCOUNT_REGION:
            return state.merge({
                ...state,
                account: {
                    ...state.account,
                    region: action.value,
                    state: '',
                    city: '',
                },
            });
        case SET_ACCOUNT_MOTIVES:
            return state.merge({
                ...state,
                account: {
                    ...state.account,
                    motives: action.motives,
                },
            });
        case UPDATE_STAGE:
            return state.merge({
                ...state,
                stage: action.stage,
            });
        default:
            return state;
    }
}

export default accountFreemiumDataReducer;
