import React from 'react';
import styles from './styles.module.scss';

export interface IBoxRegularText{
    children: string | number;
}

export const BoxRegularText = ({ children }: IBoxRegularText) => (
    <p className={styles['regular-text']}>{children}</p>
);
