export const colunasTabelaBlocoOfertas = [
    {
        sortable: false,
        align: 'left',
        fixed: false,
        headerCell: 'Bloco de Oferta',
        dataKey: 'name',
        flexGrow: 3,
    },
    {
        sortable: false,
        align: 'center',
        fixed: false,
        headerCell: 'Início da Vigência',
        dataKey: 'start_date',
        flexGrow: 1,
    },
    {
        sortable: false,
        align: 'center',
        fixed: false,
        headerCell: 'Final da Vigência',
        dataKey: 'end_date',
        flexGrow: 1,
    },
    {
        sortable: false,
        align: 'left',
        fixed: 'right',
        headerCell: '',
        dataKey: '',
        width: 120,
    },
];
