import React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Alert, Button, Checkbox } from 'rsuite';
import { setSingleReducer } from '../../../../../../../actions/actionsAnaliseCompetitividade';
import { LoadingMenu } from '../../../../../../../components/LoadingSpinner';
import SelectPill from '../../../../../../../components/SelectPill';
import { useChangePlaceholder } from '../../../hooks/useChangePlaceholder';

import arrowRightIcon from '../../../../../../../assets/icons/icon_arrow-right-gray.svg';
import styles from './styles.module.scss';

const locale = {
    noResultsText: 'Nenhum item encontrado',
    searchPlaceholder: 'Buscar item',
};

export const RetailProductSelect = ({
    placeholder,
    products,
    onSelect,
    onSearch,
    onClean,
    onOpen,
    competitionType,
    onClickLoadMore,
    onCheckAllProducts,
    isAllProductsChecked,
    productsCheckAllStatus,
}) => {
    const { filterIsEmpty } = useSelector(
        (state) => ({
            filterIsEmpty:
                state.analiseCompetitividadeDataReducer.filterIsEmpty,
        }),
        shallowEqual,
    );

    const dispatch = useDispatch();

    const {
        selectPlaceholder,
        handlePlaceholderChange,
        handleThirdPlaceholderChange,
        handleCleanCheckPlaceholder,
    } = useChangePlaceholder(placeholder, false);

    const handleOnSelect = (value, item) => {
        if (competitionType === 'varejo') {
            handlePlaceholderChange(value);
        } else {
            if (value.length > 10) {
                Alert.warning('Já existem 10 competidores selecionados.', 5000);
                return;
            }
            handleThirdPlaceholderChange(value, item);
        }
        onSelect(value, item);
    };

    const handleOnClean = () => {
        if (competitionType === 'varejo') {
            handleCleanCheckPlaceholder('second');
        } else {
            handleCleanCheckPlaceholder('third');
        }
        onClean();
    };

    const handleOnOpen = () => {
        dispatch(
            setSingleReducer('filterIsEmpty', {
                ...filterIsEmpty,
                gtins: false,
            }),
        );
        onOpen();
    };

    return (
        <div className={styles['select-pill-wrapper']}>
            <div className={styles['div-pill']}>{placeholder}</div>
            <img
                src={arrowRightIcon}
                alt=""
                className={styles['arrow-right-icon']}
            />
            <SelectPill
                type="check"
                id={
                    filterIsEmpty?.gtins
                        ? 'select-pill-is-empty'
                        : 'check-picker-pill'
                }
                placeholder={selectPlaceholder?.placeholder}
                name="second"
                value={products?.value}
                data={products?.list}
                onSearch={onSearch}
                onSelect={handleOnSelect}
                onOpen={handleOnOpen}
                onClean={handleOnClean}
                locale={locale}
                renderMenu={(menu) => (
                    <span>
                        <LoadingMenu area="panel-filter" />
                        {menu}
                    </span>
                )}
                renderExtraFooter={() => (
                    <div className={styles['product-filter-menu__footer']}>
                        <Checkbox
                            inline
                            indeterminate={productsCheckAllStatus}
                            checked={isAllProductsChecked}
                            onChange={onCheckAllProducts}
                        >
                            Selecionar tudo{' '}
                            <span>
                                {products?.list?.length
                                    ? `(${products.list.length})`
                                    : null}
                            </span>
                        </Checkbox>
                        <Button
                            appearance="ghost"
                            onClick={() => onClickLoadMore(competitionType)}
                            disabled={products?.list?.length >= 1000}
                        >
                            Carregar mais
                        </Button>
                    </div>
                )}
            />
        </div>
    );
};
