/* eslint-disable max-len */
import React from 'react';
import {
 Content, FlexboxGrid, Button, Icon,
} from 'rsuite';
import { format, parseISO } from 'date-fns';
import { Chip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import SubjectIcon from '@material-ui/icons/Subject';

const CustomChip = withStyles({
    icon: {
        color: '#fff',
    },
})(Chip);

const Header = ({
    history,
    id,
    name,
    status,
    backToCampaign,
    startingDate,
    expirationDate,
    blocosOfertas,
    lojasParticipantes,
    ofertas,
    // margemCampanha,
}) => {
    const goToBlocos = () => {
        history.push(`/ipa/campanhas/cadastro/blocos-de-oferta/${id}`);
    };

    const goToLojas = () => {
        history.push(`/ipa/campanhas/cadastro/lojas-participantes/${id}`);
    };

    const rota = history.location.pathname;

    return (
        <Content className="cadastro-campanhas-header">
            <FlexboxGrid justify="center">
                <FlexboxGrid.Item colspan={24}>
                    <FlexboxGrid align="middle">
                        <FlexboxGrid.Item>
                            <h3>{name || 'Não encontrado'}</h3>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item>
                            {status ? (
                                <CustomChip
                                    className={status}
                                    size="small"
                                    label={
                                        status === 'CURRENT'
                                            ? 'Vigente'
                                            : status === 'SCHEDULED'
                                            ? 'Programada'
                                            : status === 'DRAFT'
                                            ? 'Rascunho'
                                            : 'Encerrada'
                                    }
                                    icon={
                                        status === 'CURRENT' ? (
                                            <PlayArrowIcon />
                                        ) : status === 'SCHEDULED' ? (
                                            <AccessTimeIcon />
                                        ) : status === 'DRAFT' ? (
                                            <SubjectIcon />
                                        ) : status === 'CLOSED' ? (
                                            <CheckCircleIcon />
                                        ) : (
                                            ''
                                        )
                                    }
                                />
                            ) : null}
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item>
                            <Button appearance="link" onClick={backToCampaign}>
                                Editar dados da campanha
                            </Button>
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </FlexboxGrid.Item>
                {startingDate && expirationDate ? (
                    <FlexboxGrid.Item className="datas-campanha">
                        <span className="datas-vigencia">
                            {format(parseISO(startingDate), 'dd/MM/yyyy')}
                            {' '}
                            a
                            {' '}
                            {format(parseISO(expirationDate), 'dd/MM/yyyy')}
                        </span>
                        <span className="vigencia-info">
                            Vigência Total da Campanha
                        </span>
                    </FlexboxGrid.Item>
                ) : null}
                <FlexboxGrid.Item className="blocos-oferta">
                    <span className="total-blocos">
                        {blocosOfertas || '--'}
                    </span>
                    <span className="blocos-info">
                        Blocos de Ofertas
                        {' '}
                        {!rota.includes('blocos-de-oferta') ? (
                            <Icon icon="external-link" onClick={goToBlocos} />
                        ) : null}
                    </span>
                </FlexboxGrid.Item>
                <FlexboxGrid.Item className="blocos-oferta">
                    <span className="total-blocos">
                        {lojasParticipantes || '--'}
                    </span>
                    <span className="blocos-info">
                        Lojas Participantes
                        {' '}
                        {!rota.includes('lojas-participantes') ? (
                            <Icon icon="external-link" onClick={goToLojas} />
                        ) : null}
                    </span>
                </FlexboxGrid.Item>
                <FlexboxGrid.Item className="blocos-oferta">
                    <span className="total-blocos">{ofertas || '--'}</span>
                    <span className="blocos-info">Itens ofertados </span>
                </FlexboxGrid.Item>
                {/* <FlexboxGrid.Item className="blocos-oferta">
                    <span className="total-blocos">
                        {margemCampanha || '--'}
                    </span>
                    <span className="blocos-info">Margem Campanha </span>
                </FlexboxGrid.Item> */}
            </FlexboxGrid>
        </Content>
    );
};

export default Header;
