import { format } from 'date-fns';
import _ from 'lodash';
import {
    IGerenciadorPrecosDatapoint as RowData,
    UnknownObject,
} from '../../../../@types';
import { GerenciadorPrecos } from '../../../../@types/GerenciadorPrecos';
import { Sort } from '../../../../@types/sort';
import m from '../../../../lib/IPAMaths';
import { ContextMenuState } from '../../../../reducers/gerenciadorPrecos/contextMenu';
import { DatapointsCacheState } from '../../../../reducers/gerenciadorPrecos/datapointsCache';
import { FiltersValueState } from '../../../../reducers/gerenciadorPrecos/filters/filtersValues';
import { GerenciadorPrecosQuickActionFiltersStateProps } from '../../../../reducers/gerenciadorPrecos/quickActionsFilter';
import { ApplyPricesParams, UndoEditedDatapoint } from '../services';
import { getSortedData } from './getSortedData';
import { getValidData } from './getValidData';

export const handleCalculoPreco = (price: number, rowData: RowData) => {
    const { competitorsPrice, salesTaxPercentage, expensesPercent, cost } =
        rowData;
    const novaCompetitividade = m.novaCompetitividade(price, competitorsPrice);
    const novaMargem = m.novaMargem(
        price,
        salesTaxPercentage,
        expensesPercent,
        cost,
    );
    return {
        price,
        novaCompetitividade,
        novaMargem,
    } as const;
};

export const handleCalculoCompetitividade = (
    novaCompetitividade: number,
    rowData: RowData,
) => {
    const { pmz, competitorsPrice } = rowData;
    const price = m.precoWithCpi(novaCompetitividade, competitorsPrice);
    const novaMargem = m.novaMargemWithPmz(price, pmz);
    return {
        novaCompetitividade,
        novaMargem,
        price,
    } as const;
};

export const handleCalculoMargem = (novaMargem: number, rowData: RowData) => {
    const { cost, salesTaxPercentage, expensesPercent, competitorsPrice } =
        rowData;

    const price = m.precoWithMargem(
        novaMargem,
        cost,
        expensesPercent,
        salesTaxPercentage,
    );

    const novaCompetitividade = m.cpiWithMargem(
        novaMargem,
        price,
        competitorsPrice,
    );

    return {
        novaMargem,
        price,
        novaCompetitividade,
    } as const;
};

export const GET_MODEL_GERENCIADOR_FILTROS = (filters: FiltersValueState) => {
    const getSensibility = (type: GerenciadorPrecos.Sensibilities) => {
        return (
            filters.segmentos
                ?.filter((item) => item?.type === type)
                .map((item) => item?.value) || null
        );
    };

    const data = {
        productIds: filters.productIds,
        storeIds: filters.storeIds,
        productFamilyIds: filters.productFamilyIds,
        priceType: filters.priceType,
        supplier: filters.supplier,
        productBrand: filters.productBrand,
        categoryLevel1: filters.categoryLevel1,
        categoryLevel2: filters.categoryLevel2,
        categoryLevel3: filters.categoryLevel3,
        categoryLevel4: filters.categoryLevel4,
        categoryLevel5: filters.categoryLevel5,
        categoryLevel6: filters.categoryLevel6,
        categoryLevel7: filters.categoryLevel7,
        sensibilities: getSensibility('infoPriceSensibility'),
        exclusive: getSensibility('sensibilityType'),
        curveAbcLocal: getSensibility('infoPriceAbcClass'),
        curveAbcGlobal: getSensibility('infoPriceGlobalAbcClass'),
        curve: getSensibility('abcClass'),
    } as const;

    return getValidData<typeof data>(data);
};

export const GET_MODEL_GERENCIADOR_SORT = (
    sort: Sort,
    sortColumnsTypes?: { name: string },
) => {
    return getValidData({
        sort: getSortedData({ sort, sortColumnsTypes }),
    });
};

export const GET_MODEL_GERENCIADOR_PAGINATION = ({
    displayLength = 25,
    activePage = 0,
}: GerenciadorPrecos.Table.Pagination) => {
    return {
        size: displayLength,
        page: activePage < 0 ? 0 : activePage,
    } as const;
};

const getMinDate = (lastDays: unknown) => {
    const today = new Date(Date.now());
    const day = format(
        // @ts-ignore
        new Date(today - (+lastDays - 1) * 24 * 60 * 60 * 1000),
        'yyyy-MM-dd',
    );
    return day;
};

export const GET_MODEL_GERENCIADOR_QUICK_ACTION_FILTERS = ({
    brokenLimit,
    changedPrices,
    competitorPrices,
    minDateCompetitorPrice,
    isProductMaster,
}: GerenciadorPrecosQuickActionFiltersStateProps): Partial<
    Record<string, string[]> & UnknownObject
> => {
    const data = {
        brokenLimit: brokenLimit.isActive ? brokenLimit.value : null,
        changedPrices: changedPrices.isActive || null,
        competitorPrices: competitorPrices.isActive,
        minDateCompetitorPrice: minDateCompetitorPrice.isActive
            ? getMinDate(minDateCompetitorPrice.value)
            : null,
        ...(isProductMaster.isActive && {
            isProductMaster: isProductMaster.isActive,
        }),
        competitorPriceSources: minDateCompetitorPrice.competitorPriceSources,
    } as const;
    return getValidData<typeof data>(data);
};

namespace ModelApplyPrices {
    export type Params = {
        datapointExhibitionType: string;
        cachedDatapoints: DatapointsCacheState;
        selectedAll: boolean;
        applyToFamily: boolean;
        filters: FiltersValueState;
        quickActionsFilter: GerenciadorPrecosQuickActionFiltersStateProps;
    };
    export type Response = ApplyPricesParams;
}
export const CREATE_MODEL_APPLY_PRICES = ({
    datapointExhibitionType,
    cachedDatapoints,
    selectedAll,
    applyToFamily,
    quickActionsFilter,
    filters,
}: ModelApplyPrices.Params): ModelApplyPrices.Response => {
    const productsSelect = cachedDatapoints.selectedIds.map((item) => ({
        productId: item.productId,
        storeId: item.storeId,
        price: item.price,
    }));

    const productsUnSelect = cachedDatapoints.excludedIds.map((item) => ({
        productId: item.productId,
        storeId: item.storeId,
        price: item.price,
    }));

    return {
        productsSelect,
        productsUnSelect,
        selectedAllProducts: selectedAll,
        applyToFamily,
        datapointExhibitionType: datapointExhibitionType as
            | 'PRODUCT'
            | 'FAMILY',
        ...(selectedAll && {
            filters: {
                ...GET_MODEL_GERENCIADOR_FILTROS(filters),
                ...GET_MODEL_GERENCIADOR_QUICK_ACTION_FILTERS(
                    quickActionsFilter,
                ),
            },
        }),
    };
};

export const CREATE_MODEL_UNDO_DATAPOINT_PRICE_CHANGE = (
    contextMenu: ContextMenuState,
    itemsCountFamily: number,
    datapointsExibitionType?: string
): UndoEditedDatapoint.Params | any => {
    const datapointExhibitionType = datapointsExibitionType === "FAMILY" ? "FAMILY" : "PRODUCT";

    return {
        ..._.pick(contextMenu, ['productId', 'storeId', 'productsToBePricedId']),
        itemsCountFamily,
        datapointExhibitionType,
    };
};

export const isEmptyFilters = (filters: FiltersValueState) => {
    let isEmpty = true;
    Object.entries(filters)?.forEach(([k, v]) => {
        if (Array.isArray(v) && v.length !== 0) {
            isEmpty = false;
        } else if (k === 'changedPrices') {
            isEmpty = !v;
        } else if (k === 'brokenLimit') {
            isEmpty = false;
        }
    });
    return isEmpty;
};

export const isSelectedDatapointsEmpty = (
    selectedDatapoints: GerenciadorPrecos.SelectedDatapoints,
) => {
    const { selectedAll, excludedIds, selectedIds } = selectedDatapoints;
    const currentState = selectedAll ? excludedIds : selectedIds;
    const isEmpty = !(!!currentState.length || selectedAll);
    return isEmpty;
};

export const getTotalProducts = (
    // eslint-disable-next-line default-param-last
    totalElements = 0,
    selectedDatapoints: GerenciadorPrecos.SelectedDatapoints,
) => {
    const { selectedAll, excludedIds, selectedIds } = selectedDatapoints;

    let total = totalElements;

    if (selectedAll) {
        total = totalElements - excludedIds.length;
    } else if (!selectedAll && selectedIds.length) {
        total = selectedIds.length;
    }

    return total;
};
