import React from 'react';
import {
    DescArrowForward,
    MdArrowForward,
    MdChangeCircle,
    MdCheckCircle,
    MdDelete,
    MdDescInsights,
    MdFileDownload,
    MdHandshake,
    MdImage,
    MdInfo,
    MdKeyboardArrowDown,
    MdOpenInNew,
    MdOutlineEditNote,
    MdPriceChange,
    MdReport,
    MdSave,
    MdSelectAll,
    MdStore,
    MdUpload,
    Premium,
} from './assets';

export type IconTypes =
    | 'MdPriceChange'
    | 'MdReport'
    | 'MdSelectAll'
    | 'MdKeyboardArrowDown'
    | 'MdOpenInNew'
    | 'MdUpload'
    | 'MdFileDownload'
    | 'MdInfo'
    | 'MdImage'
    | 'MdSave'
    | 'MdDelete'
    | 'MdCheckCircle'
    | 'MdStore'
    | 'MdHandshake'
    | 'MdDescInsights'
    | 'DescArrowForward'
    | 'MdOutlineEditNote'
    | 'MdArrowForward'
    | 'premium';

type SVGProps = React.SVGProps<SVGSVGElement>;

export type IconProps = {
    icon: IconTypes | React.ReactNode;
} & SVGProps;

const ICONS = {
    MdPriceChange: (props: SVGProps) => <MdPriceChange {...props} />,
    MdReport: (props: SVGProps) => <MdReport {...props} />,
    MdSelectAll: (props: SVGProps) => <MdSelectAll {...props} />,
    MdKeyboardArrowDown: (props: SVGProps) => (
        <MdKeyboardArrowDown {...props} />
    ),
    MdOpenInNew: (props: SVGProps) => <MdOpenInNew {...props} />,
    MdUpload: (props: SVGProps) => <MdUpload {...props} />,
    MdFileDownload: (props: SVGProps) => <MdFileDownload {...props} />,
    MdInfo: (props: SVGProps) => <MdInfo {...props} />,
    MdImage: (props: SVGProps) => <MdImage {...props} />,
    premium: (props: SVGProps) => <Premium {...props} />,
    MdSave: (props: SVGProps) => <MdSave {...props} />,
    MdDelete: (props: SVGProps) => <MdDelete {...props} />,
    MdCheckCircle: (props: SVGProps) => <MdCheckCircle {...props} />,
    MdChangeCircle: (props: SVGProps) => <MdChangeCircle {...props} />,
    MdStore: (props: SVGProps) => <MdStore {...props} />,
    MdHandshake: (props: SVGProps) => <MdHandshake {...props} />,
    MdDescInsights: (props: SVGProps) => <MdDescInsights {...props} />,
    DescArrowForward: (props: SVGProps) => <DescArrowForward {...props} />,
    MdOutlineEditNote: (props: SVGProps) => <MdOutlineEditNote {...props} />,
    MdArrowForward: (props: SVGProps) => <MdArrowForward {...props} />,
} as const;

export const Icon = ({ icon = 'MdSelectAll', ...props }: IconProps) => {
    if (typeof icon !== 'string') return icon;
    const Component = ICONS[icon as IconTypes];
    return <Component data-testid={`icon-${icon}`} {...props} />;
};
