import api from '../../utils/API';

export const getCompetitividadeMediaGeral = async (params) => api({
        url: `${process.env.REACT_APP_IRA_URL}/question/1001`,
        method: 'POST',
        expectToken: true,
        data: params?.filters?.find((item) => item.values?.length)
            ? JSON.stringify({
                  filters: params.filters.filter(
                      (item) => item?.values?.length,
                  ),
              })
            : null,
    }).then((resp) => resp?.data?.data[0]);

export const getNumeroDeItensPesquisados = async (params) => api({
        url: `${process.env.REACT_APP_IRA_URL}/question/1004`,
        method: 'POST',
        expectToken: true,
        data: params?.filters?.find((item) => item.values?.length)
            ? JSON.stringify({
                  filters: params.filters.filter(
                      (item) => item?.values?.length,
                  ),
              })
            : null,
    }).then((resp) => resp?.data?.data[0]);

export const getVendaBrutaDosItensPesquisados = async (params) => api({
        url: `${process.env.REACT_APP_IRA_URL}/question/1005`,
        method: 'POST',
        expectToken: true,
        data: params?.filters?.find((item) => item.values?.length)
            ? JSON.stringify({
                  filters: params.filters.filter(
                      (item) => item?.values?.length,
                  ),
              })
            : null,
    }).then((resp) => resp?.data?.data[0]);

export const getCompetitividadePorCidade = async (params) => api({
        url: `${process.env.REACT_APP_IRA_URL}/question/1002`,
        method: 'POST',
        expectToken: true,
        data: params?.filters?.find((item) => item.values?.length)
            ? JSON.stringify({
                  filters: params.filters.filter(
                      (item) => item?.values?.length,
                  ),
              })
            : null,
    }).then((resp) => resp?.data?.data);

export const getCompetitividadePorLoja = async (params) => api({
        url: `${process.env.REACT_APP_IRA_URL}/question/1003`,
        method: 'POST',
        expectToken: true,
        data: params?.filters?.find((item) => item.values?.length)
            ? JSON.stringify({
                  filters: params.filters.filter(
                      (item) => item?.values?.length,
                  ),
              })
            : null,
    }).then((resp) => resp?.data?.data);

export const getCompetitividadeMediaPorItensPesquisados = async (params) => api({
        url: `${process.env.REACT_APP_IRA_URL}/question/1008`,
        method: 'POST',
        expectToken: true,
        data: params?.filters?.find((item) => item.values?.length)
            ? JSON.stringify({
                  filters: params.filters.filter(
                      (item) => item?.values?.length,
                  ),
              })
            : null,
    }).then((resp) => resp?.data?.data);

export const getCompetitividadeCategoria1 = async (params) => api({
        url: `${process.env.REACT_APP_IRA_URL}/question/1006`,
        method: 'POST',
        expectToken: true,
        data: params?.filters?.find((item) => item.values?.length)
            ? JSON.stringify({
                  filters: params.filters.filter(
                      (item) => item?.values?.length,
                  ),
              })
            : null,
    }).then((resp) => resp?.data?.data);

export const getCompetitividadeCategoria2 = async (params) => api({
        url: `${process.env.REACT_APP_IRA_URL}/question/1007`,
        method: 'POST',
        expectToken: true,
        data: params?.filters?.find((item) => item.values?.length)
            ? JSON.stringify({
                  filters: params.filters.filter(
                      (item) => item?.values?.length,
                  ),
              })
            : null,
    }).then((resp) => resp?.data?.data);

export const getListaRenegociacaoProdutosMaisCaros = async (params) => api({
        url: `${process.env.REACT_APP_IRA_URL}/question/1009`,
        method: 'POST',
        expectToken: true,
        data: params?.filters?.find((item) => item.values?.length)
            ? JSON.stringify({
                  filters: params.filters.filter(
                      (item) => item?.values?.length,
                  ),
              })
            : null,
    }).then((resp) => resp?.data?.data);

export const getListaOportunidades = async (params) => api({
        url: `${process.env.REACT_APP_IRA_URL}/question/1010`,
        method: 'POST',
        expectToken: true,
        data: params?.filters?.find((item) => item.values?.length)
            ? JSON.stringify({
                  filters: params.filters.filter(
                      (item) => item?.values?.length,
                  ),
              })
            : null,
    }).then((resp) => resp?.data?.data);

export const getListaProdutosCompetitividadeAcima = async (params) => api({
        url: `${process.env.REACT_APP_IRA_URL}/question/1011`,
        method: 'POST',
        expectToken: true,
        data: params?.filters?.find((item) => item.values?.length)
            ? JSON.stringify({
                  filters: params.filters.filter(
                      (item) => item?.values?.length,
                  ),
              })
            : null,
    }).then((resp) => resp?.data?.data);

export const getListaProdutosCompetitividadeAbaixo = async (params) => api({
        url: `${process.env.REACT_APP_IRA_URL}/question/1012`,
        method: 'POST',
        expectToken: true,
        data: params?.filters?.find((item) => item.values?.length)
            ? JSON.stringify({
                  filters: params.filters.filter(
                      (item) => item?.values?.length,
                  ),
              })
            : null,
    }).then((resp) => resp?.data?.data);

export const getMargemBrutaRealizada = async (params) => api({
        url: `${process.env.REACT_APP_IRA_URL}/question/2001`,
        method: 'POST',
        expectToken: true,
        data: params?.filters?.find((item) => item.values?.length)
            ? JSON.stringify({
                  filters: params.filters.filter(
                      (item) => item?.values?.length,
                  ),
              })
            : null,
    }).then((resp) => resp?.data?.data);

export const getEvolucaoMargemPraticadaPorSemana = async (params) => api({
        url: `${process.env.REACT_APP_IRA_URL}/question/2002`,
        method: 'POST',
        expectToken: true,
        data: params?.filters?.find((item) => item.values?.length)
            ? JSON.stringify({
                  filters: params.filters.filter(
                      (item) => item?.values?.length,
                  ),
              })
            : null,
    }).then((resp) => resp?.data?.data);

export const getMargemPraticadaCategoria1 = async (params) => api({
        url: `${process.env.REACT_APP_IRA_URL}/question/2003`,
        method: 'POST',
        expectToken: true,
        data: params?.filters?.find((item) => item.values?.length)
            ? JSON.stringify({
                  filters: params.filters.filter(
                      (item) => item?.values?.length,
                  ),
              })
            : null,
    }).then((resp) => resp?.data?.data);

export const getGanhoEmMargemTotal = async (params) => api({
        url: `${process.env.REACT_APP_IRA_URL}/question/2004`,
        method: 'POST',
        expectToken: true,
        data: params?.filters?.find((item) => item.values?.length)
            ? JSON.stringify({
                  filters: params.filters.filter(
                      (item) => item?.values?.length,
                  ),
              })
            : null,
    }).then((resp) => resp?.data?.data);

export const getProdutosMenorMargemAplicada = async (params) => api({
        url: `${process.env.REACT_APP_IRA_URL}/question/2005`,
        method: 'POST',
        expectToken: true,
        data: params?.filters?.find((item) => item.values?.length)
            ? JSON.stringify({
                  filters: params.filters.filter(
                      (item) => item?.values?.length,
                  ),
              })
            : null,
    }).then((resp) => resp?.data?.data);

export const getMargemPraticadaPorLoja = async (params) => api({
        url: `${process.env.REACT_APP_IRA_URL}/question/2006`,
        method: 'POST',
        expectToken: true,
        data: params?.filters?.find((item) => item.values?.length)
            ? JSON.stringify({
                  filters: params.filters.filter(
                      (item) => item?.values?.length,
                  ),
              })
            : null,
    }).then((resp) => resp?.data?.data);

export const getRaioXProdutosMaisVendidos = async (params) => api({
        url: `${process.env.REACT_APP_IRA_URL}/question/2007`,
        method: 'POST',
        expectToken: true,
        data: params?.filters?.find((item) => item.values?.length)
            ? JSON.stringify({
                  filters: params.filters.filter(
                      (item) => item?.values?.length,
                  ),
              })
            : null,
    }).then((resp) => resp?.data?.data);

export const getTotalVendasEmReais = async (params) => api({
        url: `${process.env.REACT_APP_IRA_URL}/question/3001`,
        method: 'POST',
        expectToken: true,
        data: params?.filters?.find((item) => item.values?.length)
            ? JSON.stringify({
                  filters: params.filters.filter(
                      (item) => item?.values?.length,
                  ),
              })
            : null,
    }).then((resp) => resp?.data?.data[0]);

export const getTotalVendasPorCidade = async (params) => api({
        url: `${process.env.REACT_APP_IRA_URL}/question/3002`,
        method: 'POST',
        expectToken: true,
        data: params?.filters?.find((item) => item.values?.length)
            ? JSON.stringify({
                  filters: params.filters.filter(
                      (item) => item?.values?.length,
                  ),
              })
            : null,
    }).then((resp) => resp?.data?.data);

export const getTotalVendasPorCategoria1 = async (params) => api({
        url: `${process.env.REACT_APP_IRA_URL}/question/3003`,
        method: 'POST',
        expectToken: true,
        data: params?.filters?.find((item) => item.values?.length)
            ? JSON.stringify({
                  filters: params.filters.filter(
                      (item) => item?.values?.length,
                  ),
              })
            : null,
    }).then((resp) => resp?.data?.data);

export const getUnidadesVendidasFaturamento = async (params) => api({
        url: `${process.env.REACT_APP_IRA_URL}/question/3004`,
        method: 'POST',
        expectToken: true,
        data: params?.filters?.find((item) => item.values?.length)
            ? JSON.stringify({
                  filters: params.filters.filter(
                      (item) => item?.values?.length,
                  ),
              })
            : null,
    }).then((resp) => resp?.data?.data);

export const getTotalVendasPorLoja = async (params) => api({
        url: `${process.env.REACT_APP_IRA_URL}/question/3005`,
        method: 'POST',
        expectToken: true,
        data: params?.filters?.find((item) => item.values?.length)
            ? JSON.stringify({
                  filters: params.filters.filter(
                      (item) => item?.values?.length,
                  ),
              })
            : null,
    }).then((resp) => resp?.data?.data);

export const getProdutosMenosVendidos = async (params) => api({
        url: `${process.env.REACT_APP_IRA_URL}/question/3006`,
        method: 'POST',
        expectToken: true,
        data: params?.filters?.find((item) => item.values?.length)
            ? JSON.stringify({
                  filters: params.filters.filter(
                      (item) => item?.values?.length,
                  ),
              })
            : null,
    }).then((resp) => resp?.data?.data);

export const getProdutosMaisVendidos = async (params) => api({
        url: `${process.env.REACT_APP_IRA_URL}/question/3007`,
        method: 'POST',
        expectToken: true,
        data: params?.filters?.find((item) => item.values?.length)
            ? JSON.stringify({
                  filters: params.filters.filter(
                      (item) => item?.values?.length,
                  ),
              })
            : null,
    }).then((resp) => resp?.data?.data);

export const getListaAllQuestions = async () => api({
        url: `${process.env.REACT_APP_IRA_URL}/question/all`,
        method: 'POST',
        expectToken: true,
        data: JSON.stringify({
            questionIds: [
                1001, 1002, 1003, 1004, 1005, 1006, 1007, 1008, 1009, 1010,
                1011, 1012,
            ],
        }),
    }).then((resp) => resp?.data);

export const getListaProdutos = async (query) => api({
        url: `${process.env.REACT_APP_IRA_URL}/search/products`,
        method: 'POST',
        expectToken: true,
        data: JSON.stringify({
            query,
            clientId: JSON.parse(localStorage.getItem('cliente'))?.codigo,
        }),
    }).then((resp) => resp?.data);

export const getListaLojas = async () => api({
        url: `${process.env.REACT_APP_IRA_URL}/question/4001`,
        method: 'POST',
        expectToken: true,
    }).then((resp) => resp?.data?.data);

export const getListaTipoLojas = async () => api({
        url: `${process.env.REACT_APP_IRA_URL}/question/4004`,
        method: 'POST',
        expectToken: true,
    }).then((resp) => resp?.data?.data);

export const getListaCategoria1 = async () => api({
        url: `${process.env.REACT_APP_IRA_URL}/question/4002`,
        method: 'POST',
        expectToken: true,
    }).then((resp) => resp?.data?.data);

    export const getListaCompetitividade = async () => api({
        url: `${process.env.REACT_APP_IRA_URL}/question/4005`,
        method: 'POST',
        expectToken: true,
    }).then((resp) => resp?.data?.data);    

export const getListaCategoria2 = async () => api({
        url: `${process.env.REACT_APP_IRA_URL}/question/4003`,
        method: 'POST',
        expectToken: true,
    }).then((resp) => resp?.data?.data);
