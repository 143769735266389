import React from 'react';
import { FlexboxGrid } from 'rsuite';

const PanelPlanTags = ({ plan }) => (
    <FlexboxGrid className="panel-plan-tags">
        <div className="title">
            <p>Versão Free limitado a:</p>
        </div>
        <div className="itens">
            <span className="tags">
                Canal:
                {' '}
                {plan?.canal}
            </span>
            <span className="tags">
                Agrupamento:
                {' '}
                {plan?.agrupamentoPais ? 'País' : null}
                {plan?.cidadeId ? `Cidade - ${plan.cidade.cidade} - ${plan.cidade.uf.name}` : null}
                {!plan?.cidadeId && !plan?.agrupamentoPais ? `Estado - ${plan.estado.descricao}` : null}
            </span>
        </div>
    </FlexboxGrid>

);
export default PanelPlanTags;
