import React, { useState, useEffect, createRef } from 'react';
import {
    Container,
    FlexboxGrid,
    Table,
    InputGroup,
    Icon,
    Input,
    Checkbox,
    Whisper,
    Popover,
    Dropdown,
    CheckboxGroup,
    IconButton,
    Tooltip,
    RadioGroup,
    Radio,
} from 'rsuite';
import { Select, MenuItem, FormControl } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CurrencyInput from 'react-currency-input';
import CurrencyFormat from 'react-currency-format';
import Modal from '../../../../../../components/NewModalConfirmacao';
import { LoadingSpiner } from '../../../../../../components/LoadingSpinner';
import {
    getProdutosCampanha,
    getDetalhesProdutoCampanha,
    getOferta,
} from '../services';
import { colunasTableOfertasModal } from './Columns';

const { Column, HeaderCell, Cell } = Table;

const CustomSelect = withStyles(() => ({
    select: {
        minWidth: '200px',
        background: 'white',
        color: '#378EF0',
        borderColor: '#e5e5ea',
        borderStyle: 'solid',
        borderWidth: '1px',
        borderRadius: '5px',
        paddingTop: '11px',
        paddingLeft: '12px',
        paddingBottom: '11px',
        fontSize: '.875rem',
        '&:hover': {
            borderRadius: '4px',
            background: 'white',
            borderColor: '#378EF0',
            outline: 0,
        },
        '&:focus': {
            borderRadius: '4px',
            background: 'white',
            borderColor: '#378EF0',
        },
    },
}))(Select);

const useStyles = makeStyles((theme) => ({
    icon: {
        color: theme.palette.grey[500],
        right: 12,
        position: 'absolute',
        userSelect: 'none',
        pointerEvents: 'none',
        '&:hover': {
            color: '#378EF0',
        },
        '&:focus': {
            color: '#378EF0',
        },
    },
    list: {
        paddingTop: 0,
        paddingBottom: 0,
        maxHeight: 250,
        background: 'white',
        '& li.Mui-selected': {
            fontWeight: 700,
        },
        fontSize: '0.875rem',
    },
    placeholder: {
        color: '#747474',
        fontSize: '0.875rem',
    },
    listItem: {
        fontSize: '0.875rem',
    },
}));

const MenuPopover = ({
    data,
    select,
    handleChange,
    handleSelect,
    onMouseEnter,
    onMouseLeave,
    className,
    placement,
    positionLeft,
    positionTop,
    style,
    shouldUpdatePosition,
}) => (
    <Popover
        className={`listagem-produtos-ofertas ${className}`}
        placement={placement}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        positionLeft={positionLeft}
        positionTop={positionTop}
        full
        style={style}
        shouldUpdatePosition={shouldUpdatePosition}
    >
        <LoadingSpiner size="md" />
        <Dropdown.Menu>
            <div className="listagem-produtos-ofertas">
                {data.length ? (
                    <Dropdown.Item>
                        <CheckboxGroup
                            name="checkboxList"
                            value={select.value}
                            onChange={(value) => handleChange(value)}
                        >
                            {data.map((item) => (
                                <Checkbox
                                    value={item.product_id}
                                    key={item.product_id}
                                    disabled={item.is_product_used}
                                    onChange={(value, checked) => handleSelect(item, checked)}
                                >
                                    {item.product_id}
                                    {' '}
                                    -
                                    {' '}
                                    {item.description}
                                </Checkbox>
                            ))}
                        </CheckboxGroup>
                    </Dropdown.Item>
                ) : (
                    <span>Nenhum produto encontrado</span>
                )}
            </div>
        </Dropdown.Menu>
    </Popover>
);

const OfertasSelecionadas = ({
    idsLojas,
    blocosOfertas,
    setOferta,
    editOferta,
    id,
    ofertaId,
    show,
    confirmOferta,
}) => {
    const classes = useStyles();
    const triggerRef = createRef();
    const [buscaProduto, setBuscaProduto] = useState('');
    const [produtos, setProdutos] = useState([]);
    const [produtosSelecionados, setProdutosSelecionados] = useState({
        value: [],
        cache: [],
    });
    const [tabelaProdutos, setTabelaProdutos] = useState([]);
    const [sortType, setSortType] = useState('desc');
    const [sortColumn, setSortColumn] = useState('');
    const [tipoOfertaName, setTipoOfertaName] = useState('');
    const [tipoOferta, setTipoOferta] = useState({
        simple: {
            type: '',
            value: 0,
        },

        from_to: {
            from: 0,
            to: 0,
        },
    });
    const [blocoDeOferta, setBlocoDeOferta] = useState('');
    const [showPrecosDiferentes, setShowPrecosDiferentes] = useState(false);

    // moves the menu below the select input
    const menuProps = {
        classes: {
            list: classes.list,
        },
        anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
        },
        transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
        },
        getContentAnchorEl: null,
    };

    const iconComponent = (props) => (
        <ExpandMoreIcon className={`${props.className} ${classes.icon}`} />
    );

    const listaProdutos = async () => {
        const resp = await getProdutosCampanha(id, {
            storesId: idsLojas,
            descOrProdId: buscaProduto,
        });
        setProdutos(
            resp
                .map((item) => ({ ...item, checked: false }))
                .concat(
                    produtosSelecionados.cache.filter(
                        (itemCache) => resp.findIndex(
                                (itemLista) => itemLista.product_id
                                    === itemCache.product_id,
                            ) === -1,
                    ),
                )
                .sort((a, b) => Number(b.checked) - Number(a.checked)),
        );
    };

    const listProdutoDetalhado = async (productId, checked) => {
        if (checked) {
            const resp = await getDetalhesProdutoCampanha({
                storesId: idsLojas,
                productId,
            });
            resp.store_ids = resp.stores_id || 0;
            delete resp.stores_id;
            setTabelaProdutos((prevState) => [...prevState, resp]);
        } else {
            const produtos = tabelaProdutos;
            produtos.splice(
                tabelaProdutos.findIndex(
                    (item) => item.product_id === productId,
                ),
                1,
            );
            setTabelaProdutos(produtos);
        }
    };

    const handleSelectProduto = (item, checked) => {
        const cacheProdutos = produtosSelecionados.cache;
        const itemSelecionado = { ...item, checked };
        cacheProdutos.push(itemSelecionado);
        setProdutosSelecionados((prevState) => ({
            ...prevState,
            cache: cacheProdutos,
        }));

        listProdutoDetalhado(item.product_id, checked);
    };

    const handleChangeProduto = (value) => {
        setProdutosSelecionados((prevState) => ({ ...prevState, value }));

        if (value === null) {
            setProdutosSelecionados((prevState) => ({
                ...prevState,
                cache: [],
            }));
            listaProdutos('');
        }
        if (
            value !== null
            && value.length !== produtosSelecionados.cache.length
        ) {
            const newCacheProduto = produtosSelecionados.cache.filter(
                (item) => value.indexOf(item) !== -1,
            );

            setProdutosSelecionados((prevState) => ({
                ...prevState,
                cache: newCacheProduto,
            }));
        }
    };

    const removeProduto = (value) => {
        setTabelaProdutos((prevState) => prevState.filter((item) => item.product_id !== value.product_id));
        setProdutosSelecionados({
            cache: produtosSelecionados.cache.filter(
                (item) => item.product_id !== value.product_id,
            ),
            value: produtosSelecionados.value.filter(
                (item) => item !== value.product_id,
            ),
        });
    };

    const handleSortColumn = (column, type) => {
        setSortColumn(column);
        setSortType(type);
    };

    const getTabelaProdutos = () => {
        if (sortColumn && sortType) {
            return tabelaProdutos.sort((a, b) => {
                let x = a[sortColumn];
                let y = b[sortColumn];
                if (typeof x === 'string') {
                    x = x.charCodeAt();
                }
                if (typeof y === 'string') {
                    y = y.charCodeAt();
                }
                if (sortType === 'asc') {
                    return x - y;
                }
                return y - x;
            });
        }

        return tabelaProdutos;
    };

    const listOferta = async () => {
        const response = await getOferta(id, ofertaId);
        setTabelaProdutos(response.products);
        response.products.forEach((produto) => {
            setProdutosSelecionados((oldState) => ({
                ...oldState,
                cache: [
                    ...oldState.cache,
                    {
                        product_id: produto.product_id,
                        description: produto.description,
                        checked: true,
                    },
                ],
                value: [...oldState.value, produto.product_id],
            }));
        });
        setTipoOferta(response.offer);
        setTipoOfertaName(
            Object.keys(response.offer.from_to).length === 0
                ? 'simple'
                : 'from_to',
        );
        if (response.offer_group) setBlocoDeOferta(response.offer_group.id);
    };

    useEffect(() => {
        if (buscaProduto.length > 3) {
            listaProdutos();
        }
    }, [buscaProduto]);

    useEffect(() => {
        setOferta({
            products: tabelaProdutos,
            ...(blocosOfertas && {
                offer_group: blocosOfertas
                    .map((item) => ({ name: item.label, id: item.value }))
                    .find((item) => item.id === blocoDeOferta),
            }),
            offer: tipoOferta,
            tipoOfertaName,
        });
    }, [tipoOferta, blocoDeOferta, produtosSelecionados]);

    useEffect(() => {
        if (editOferta && ofertaId) listOferta();
    }, [editOferta, ofertaId]);

    useEffect(() => {
        if (!show) {
            setProdutos([]);
            setTabelaProdutos([]);
            setProdutosSelecionados({ value: [], cache: [] });
            setTipoOferta({
                simple: {
                    type: '',
                    value: 0,
                },

                from_to: {
                    from: 0,
                    to: 0,
                },
            });
            setTipoOfertaName('');
            setBlocoDeOferta({});
            setBuscaProduto('');
        }
    }, [show]);

    useEffect(() => {
        if (tabelaProdutos.length) {
            const somaProdutos = tabelaProdutos
                .map((produto) => produto.retail_price)
                .reduce((accum, curr) => accum + curr, 0);

            const multiplicacaoProdutos
                = tabelaProdutos[0].retail_price * tabelaProdutos.length;

            if (somaProdutos.toFixed(2) !== multiplicacaoProdutos.toFixed(2)) {
                setTipoOferta((oldState) => ({
                    ...oldState,
                    from_to: {
                        ...oldState.from_to,
                        from: 0,
                    },
                }));
                setShowPrecosDiferentes(true);
            } else {
                setTipoOferta((oldState) => ({
                    ...oldState,
                    from_to: {
                        ...oldState.from_to,
                        from: tabelaProdutos[0].retail_price,
                    },
                }));
                setShowPrecosDiferentes(false);
            }
        }
    }, [tabelaProdutos]);

    return (
        <Container>
            <FlexboxGrid justify="space-between">
                <FlexboxGrid.Item colspan={24}>
                    <Whisper
                        placement="bottomStart"
                        trigger="click"
                        triggerRef={triggerRef}
                        speaker={(
                            <MenuPopover
                                select={produtosSelecionados}
                                data={produtos}
                                handleChange={handleChangeProduto}
                                handleSelect={handleSelectProduto}
                                onClear={() => setProdutosSelecionados({
                                        value: [],
                                        cache: [],
                                    })}
                            />
                          )}
                    >
                        <InputGroup inside className="produtos-busca">
                            <InputGroup.Addon>
                                <Icon icon="search" />
                            </InputGroup.Addon>
                            <Input
                                placeholder="Busque por um produto"
                                value={buscaProduto}
                                onChange={(value) => setBuscaProduto(value)}
                            />
                        </InputGroup>
                    </Whisper>
                </FlexboxGrid.Item>
                <FlexboxGrid.Item className="produtos-container" colspan={24}>
                    {!tabelaProdutos.length ? (
                        <FlexboxGrid
                            className="no-data"
                            justify="center"
                            align="middle"
                        >
                            <FlexboxGrid.Item>
                                <p>Não há produtos selecionados</p>
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                    ) : (
                        <FlexboxGrid className="oferta-container">
                            <FlexboxGrid.Item colspan={24}>
                                <p>Produtos selecionados</p>
                                <div className="campanhas-table-container">
                                    <Table
                                        className="table"
                                        data={getTabelaProdutos()}
                                        height={230}
                                        sortColumn={sortColumn}
                                        sortType={sortType}
                                        onSortColumn={handleSortColumn}
                                    >
                                        {colunasTableOfertasModal.map(
                                            (coluna) => (!coluna.dataKey ? (
                                                <Column
                                                    width={coluna.width}
                                                    fixed="right"
                                                    key={Math.random()}
                                                >
                                                    <HeaderCell />

                                                    <Cell className="table__edit-button">
                                                        {(rowData) => (
                                                            <span className="mg-5">
                                                                <IconButton
                                                                    title="Deletar"
                                                                    size="md"
                                                                    icon={
                                                                        <Icon icon="trash2" />
                                                                        }
                                                                    onClick={(
                                                                            value,
                                                                            index,
                                                                        ) => removeProduto(
                                                                                rowData,
                                                                                index,
                                                                            )}
                                                                />
                                                            </span>
                                                            )}
                                                    </Cell>
                                                </Column>
                                                ) : (
                                                    <Column
                                                        // flexGrow={coluna.flexGrow}
                                                        sortable={
                                                            coluna.sortable
                                                        }
                                                        align={coluna.align}
                                                        fixed={coluna.fixed}
                                                        width={coluna.width}
                                                        key={Math.random()}
                                                    >
                                                        <HeaderCell>
                                                            <Whisper
                                                                placement="bottom"
                                                                trigger="hover"
                                                                speaker={(
                                                                    <Tooltip>
                                                                        {
                                                                            coluna.headerCell
                                                                        }
                                                                    </Tooltip>
                                                                  )}
                                                            >
                                                                <span>
                                                                    {
                                                                        coluna.headerCell
                                                                    }
                                                                </span>
                                                            </Whisper>
                                                        </HeaderCell>
                                                        {coluna.dataKey
                                                        === 'product_id' ? (
                                                            <Cell
                                                                dataKey={
                                                                    coluna.dataKey
                                                                }
                                                            >
                                                                {(rowData) => (
                                                                    <span>
                                                                        {
                                                                            rowData.product_id
                                                                        }
                                                                        {' - '}
                                                                        {
                                                                            rowData.description
                                                                        }
                                                                    </span>
                                                                )}
                                                            </Cell>
                                                        ) : coluna.dataKey
                                                          === 'pmz' ? (
                                                              <Cell
                                                                  dataKey={
                                                                    coluna.dataKey
                                                                }
                                                              >
                                                                  {(rowData) => {
                                                                    const calcPreco
                                                                        = rowData.pmz
                                                                            ? rowData.pmz
                                                                            : '';
                                                                    return (
                                                                        <span>
                                                                            {calcPreco ? (
                                                                                <CurrencyFormat
                                                                                    fixedDecimalScale
                                                                                    decimalScale={
                                                                                    2
                                                                                }
                                                                                    value={
                                                                                    calcPreco
                                                                                }
                                                                                    displayType="text"
                                                                                    prefix="R$"
                                                                                    decimalSeparator=","
                                                                                    thousandSeparator="."
                                                                                />
                                                                            ) : (
                                                                                '--'
                                                                            )}
                                                                        </span>
                                                                    );
                                                                }}
                                                              </Cell>
                                                        ) : coluna.dataKey
                                                          === 'retail_price' ? (
                                                              <Cell
                                                                  dataKey={
                                                                    coluna.dataKey
                                                                }
                                                              >
                                                                  {(rowData) => {
                                                                    const calcPreco
                                                                        = rowData.retail_price
                                                                            ? rowData.retail_price
                                                                            : '';
                                                                    return (
                                                                        <span>
                                                                            {calcPreco ? (
                                                                                <CurrencyFormat
                                                                                    fixedDecimalScale
                                                                                    decimalScale={
                                                                                    2
                                                                                }
                                                                                    value={
                                                                                    calcPreco
                                                                                }
                                                                                    displayType="text"
                                                                                    prefix="R$"
                                                                                    decimalSeparator=","
                                                                                    thousandSeparator="."
                                                                                />
                                                                            ) : (
                                                                                '--'
                                                                            )}
                                                                        </span>
                                                                    );
                                                                }}
                                                              </Cell>
                                                        ) : coluna.dataKey
                                                          === 'avg_competitiveness_price' ? (
                                                              <Cell
                                                                  dataKey={
                                                                    coluna.dataKey
                                                                }
                                                              >
                                                                  {(rowData) => {
                                                                    const calcPreco
                                                                        = rowData.avg_competitiveness_price
                                                                            ? rowData.avg_competitiveness_price
                                                                            : '';
                                                                    return (
                                                                        <span>
                                                                            {calcPreco ? (
                                                                                <CurrencyFormat
                                                                                    fixedDecimalScale
                                                                                    decimalScale={
                                                                                    2
                                                                                }
                                                                                    value={
                                                                                    calcPreco
                                                                                }
                                                                                    displayType="text"
                                                                                    prefix="R$"
                                                                                    decimalSeparator=","
                                                                                    thousandSeparator="."
                                                                                />
                                                                            ) : (
                                                                                '--'
                                                                            )}
                                                                        </span>

                                                                    );
                                                                }}
                                                              </Cell>
                                                        ) : coluna.dataKey
                                                          === 'competitiveness' ? (
                                                              <Cell
                                                                  dataKey={
                                                                    coluna.dataKey
                                                                }
                                                              >
                                                                  {(rowData) => {
                                                                    const calcPorcentagem
                                                                        = rowData.competitiveness
                                                                            ? rowData.competitiveness
                                                                            : '';
                                                                    return (
                                                                        <span>
                                                                            {rowData.competitiveness ? (
                                                                                <CurrencyFormat
                                                                                    decimalScale={
                                                                                        2
                                                                                    }
                                                                                    value={
                                                                                        calcPorcentagem
                                                                                    }
                                                                                    displayType="text"
                                                                                    suffix="%"
                                                                                    decimalSeparator=","
                                                                                    thousandSeparator="."
                                                                                />
                                                                            ) : (
                                                                                '--'
                                                                            )}
                                                                        </span>
                                                                    );
                                                                }}
                                                              </Cell>
                                                        ) : coluna.dataKey
                                                          === 'margin' ? (
                                                              <Cell
                                                                  dataKey={
                                                                    coluna.dataKey
                                                                }
                                                              >
                                                                  {(rowData) => {
                                                                    const calcPorcentagem
                                                                        = rowData.margin
                                                                            ? rowData.margin
                                                                            : '';
                                                                    return (
                                                                        <span>
                                                                            {rowData.margin ? (
                                                                                <CurrencyFormat
                                                                                    decimalScale={
                                                                                        2
                                                                                    }
                                                                                    value={
                                                                                        calcPorcentagem
                                                                                    }
                                                                                    displayType="text"
                                                                                    suffix="%"
                                                                                    decimalSeparator=","
                                                                                    thousandSeparator="."
                                                                                />
                                                                            ) : (
                                                                                '--'
                                                                            )}
                                                                        </span>
                                                                    );
                                                                }}
                                                              </Cell>
                                                        ) : coluna.dataKey
                                                          === 'stores_id' ? (
                                                              <Cell
                                                                  dataKey={
                                                                    coluna.dataKey
                                                                }
                                                              >
                                                                  {(rowData) => (
                                                                      <span>
                                                                          {rowData.stores_id
                                                                        < idsLojas.length ? (
                                                                            <Whisper
                                                                                placement="bottom"
                                                                                trigger="hover"
                                                                                speaker={(
                                                                                    <Tooltip>
                                                                                        Esse
                                                                                        produto
                                                                                        está
                                                                                        presente
                                                                                        em
                                                                                        somente
                                                                                        {' '}
                                                                                        {
                                                                                            rowData.stores_id
                                                                                        }
                                                                                        {' '}
                                                                                        lojas
                                                                                        participantes
                                                                                    </Tooltip>
                                                                                  )}
                                                                            >
                                                                                <Icon
                                                                                    className="alert-icon"
                                                                                    icon="exclamation-triangle"
                                                                                />
                                                                            </Whisper>
                                                                        ) : null}
                                                                          {
                                                                            rowData
                                                                                .store_ids
                                                                                ?.length
                                                                        }
                                                                      </span>
                                                                )}
                                                              </Cell>
                                                        ) : (
                                                            <Cell
                                                                dataKey={
                                                                    coluna.dataKey
                                                                }
                                                            />
                                                        )}
                                                    </Column>
                                                )),
                                        )}
                                    </Table>
                                </div>
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item colspan={24}>
                                <p>Bloco de Ofertas</p>
                                <FormControl style={{ width: '100%' }}>
                                    <CustomSelect
                                        id="bloco-de-ofertas-select"
                                        disableUnderline
                                        IconComponent={iconComponent}
                                        MenuProps={menuProps}
                                        value={blocoDeOferta}
                                        onChange={(el) => setBlocoDeOferta(el.target.value)}
                                        renderValue={(selected) => {
                                            if (
                                                typeof selected !== 'string'
                                                || selected === 'none'
                                            ) {
                                                return (
                                                    <span
                                                        className={
                                                            classes.placeholder
                                                        }
                                                    >
                                                        Selecionar
                                                    </span>
                                                );
                                            }

                                            return (
                                                <span>
                                                    {
                                                        blocosOfertas.find(
                                                            (bloco) => bloco.value
                                                                === selected,
                                                        ).label
                                                    }
                                                </span>
                                            );
                                        }}
                                    >
                                        <MenuItem
                                            className={classes.listItem}
                                            value="none"
                                        >
                                            Nenhum
                                        </MenuItem>
                                        {blocosOfertas.map((bloco) => (
                                            <MenuItem
                                                className={classes.listItem}
                                                value={bloco.value}
                                            >
                                                {bloco.label}
                                            </MenuItem>
                                        ))}
                                    </CustomSelect>
                                </FormControl>
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item
                                className="tipo-oferta-container"
                                colspan={8}
                            >
                                <p>Tipo de oferta</p>
                                <RadioGroup
                                    className="tipo-oferta"
                                    inline
                                    name="offerType"
                                    value={tipoOfertaName}
                                    onChange={(value) => setTipoOfertaName(value)}
                                >
                                    <Radio value="simple">Simples</Radio>
                                    <Radio value="from_to">De X por Y</Radio>
                                </RadioGroup>
                                {tipoOfertaName === 'from_to' ? (
                                    <FlexboxGrid
                                        align="middle"
                                        className="grupo-precos"
                                    >
                                        {showPrecosDiferentes ? (
                                            <FlexboxGrid.Item>
                                                <Whisper
                                                    placement="bottom"
                                                    trigger="hover"
                                                    speaker={(
                                                        <Tooltip>
                                                            Os valores dos
                                                            produtos
                                                            selecionados são
                                                            diferentes
                                                        </Tooltip>
                                                      )}
                                                >
                                                    <Icon
                                                        className="alert-icon"
                                                        icon="exclamation-triangle"
                                                    />
                                                </Whisper>
                                            </FlexboxGrid.Item>
                                        ) : null}
                                        <FlexboxGrid.Item colspan={18}>
                                            <FlexboxGrid align="middle">
                                                <FlexboxGrid.Item colspan={24}>
                                                    <FlexboxGrid align="middle">
                                                        <FlexboxGrid.Item
                                                            colspan={6}
                                                        >
                                                            <span className="label">
                                                                DE
                                                            </span>
                                                        </FlexboxGrid.Item>
                                                        <FlexboxGrid.Item
                                                            colspan={18}
                                                        >
                                                            <InputGroup>
                                                                <InputGroup.Addon>
                                                                    R$
                                                                </InputGroup.Addon>
                                                                <CurrencyInput
                                                                    className="rs-input"
                                                                    name="from"
                                                                    decimalSeparator=","
                                                                    thousandSeparator="."
                                                                    precision="2"
                                                                    allowEmpty
                                                                    value={
                                                                        tipoOferta
                                                                            .from_to
                                                                            .from
                                                                    }
                                                                    onChangeEvent={(
                                                                        e,
                                                                        value,
                                                                    ) => setTipoOferta(
                                                                            (
                                                                                prevState,
                                                                            ) => ({
                                                                                ...prevState,
                                                                                from_to:
                                                                                    {
                                                                                        ...prevState.from_to,
                                                                                        from: value,
                                                                                    },
                                                                            }),
                                                                        )}
                                                                />
                                                            </InputGroup>
                                                        </FlexboxGrid.Item>
                                                    </FlexboxGrid>
                                                </FlexboxGrid.Item>
                                            </FlexboxGrid>
                                        </FlexboxGrid.Item>
                                        <FlexboxGrid.Item colspan={18}>
                                            <FlexboxGrid align="middle">
                                                <FlexboxGrid.Item colspan={6}>
                                                    <span className="label">
                                                        POR
                                                    </span>
                                                </FlexboxGrid.Item>
                                                <FlexboxGrid.Item colspan={18}>
                                                    <InputGroup>
                                                        <InputGroup.Addon>
                                                            R$
                                                        </InputGroup.Addon>
                                                        <CurrencyInput
                                                            className="rs-input"
                                                            name="to"
                                                            decimalSeparator=","
                                                            thousandSeparator="."
                                                            precision="2"
                                                            allowEmpty
                                                            value={
                                                                tipoOferta
                                                                    .from_to.to
                                                            }
                                                            onKeyDown={(
                                                                event,
                                                            ) => {
                                                                if (
                                                                    event.key
                                                                    === 'Enter'
                                                                ) {
                                                                    confirmOferta();
                                                                }
                                                            }}
                                                            onChangeEvent={(
                                                                e,
                                                                value,
                                                            ) => setTipoOferta(
                                                                    (
                                                                        prevState,
                                                                    ) => ({
                                                                        ...prevState,
                                                                        from_to:
                                                                            {
                                                                                ...prevState.from_to,
                                                                                to: value,
                                                                            },
                                                                    }),
                                                                )}
                                                        />
                                                    </InputGroup>
                                                </FlexboxGrid.Item>
                                            </FlexboxGrid>
                                        </FlexboxGrid.Item>
                                    </FlexboxGrid>
                                ) : null}
                                {tipoOfertaName === 'simple' ? (
                                    <>
                                        <p>Desconto</p>
                                        <RadioGroup
                                            inline
                                            name="discount"
                                            value={tipoOferta.simple.type}
                                            onChange={(value) => setTipoOferta((prevState) => ({
                                                    ...prevState,
                                                    simple: {
                                                        ...prevState.simple,
                                                        type: value,
                                                        value: 0,
                                                    },
                                                }))}
                                        >
                                            <Radio value="PERCENTUAL">
                                                % Desconto
                                            </Radio>
                                            <Radio value="VALUE">
                                                Preço Oferta
                                            </Radio>
                                        </RadioGroup>
                                        <FlexboxGrid className="grupo-precos">
                                            <FlexboxGrid.Item colspan={11}>
                                                {tipoOferta.simple.type
                                                    === 'VALUE'
                                                || !tipoOferta.simple.type ? (
                                                    <InputGroup>
                                                        <Input disabled />
                                                        <InputGroup.Addon>
                                                            %
                                                        </InputGroup.Addon>
                                                    </InputGroup>
                                                ) : (
                                                    <InputGroup>
                                                        <CurrencyInput
                                                            className="rs-input"
                                                            name="from"
                                                            decimalSeparator=","
                                                            thousandSeparator="."
                                                            precision="2"
                                                            allowEmpty
                                                            value={
                                                                tipoOferta
                                                                    .simple
                                                                    .value
                                                            }
                                                            onKeyDown={(
                                                                event,
                                                            ) => {
                                                                if (
                                                                    event.key
                                                                    === 'Enter'
                                                                ) {
                                                                    confirmOferta();
                                                                }
                                                            }}
                                                            onChangeEvent={(
                                                                e,
                                                                value,
                                                            ) => setTipoOferta(
                                                                    (
                                                                        prevState,
                                                                    ) => ({
                                                                        ...prevState,
                                                                        simple: {
                                                                            ...prevState.simple,
                                                                            value,
                                                                        },
                                                                    }),
                                                                )}
                                                        />
                                                        <InputGroup.Addon>
                                                            %
                                                        </InputGroup.Addon>
                                                    </InputGroup>
                                                )}
                                            </FlexboxGrid.Item>
                                            <FlexboxGrid.Item colspan={11}>
                                                {tipoOferta.simple.type
                                                    === 'PERCENTUAL'
                                                || !tipoOferta.simple.type ? (
                                                    <InputGroup>
                                                        <InputGroup.Addon>
                                                            R$
                                                        </InputGroup.Addon>
                                                        <Input disabled />
                                                    </InputGroup>
                                                ) : (
                                                    <InputGroup>
                                                        <InputGroup.Addon>
                                                            R$
                                                        </InputGroup.Addon>
                                                        <CurrencyInput
                                                            className="rs-input"
                                                            name="from"
                                                            decimalSeparator=","
                                                            thousandSeparator="."
                                                            precision="2"
                                                            allowEmpty
                                                            value={
                                                                tipoOferta
                                                                    .simple
                                                                    .value
                                                            }
                                                            onKeyDown={(
                                                                event,
                                                            ) => {
                                                                if (
                                                                    event.key
                                                                    === 'Enter'
                                                                ) {
                                                                    confirmOferta();
                                                                }
                                                            }}
                                                            onChangeEvent={(
                                                                e,
                                                                value,
                                                            ) => setTipoOferta(
                                                                    (
                                                                        prevState,
                                                                    ) => ({
                                                                        ...prevState,
                                                                        simple: {
                                                                            ...prevState.simple,
                                                                            value,
                                                                        },
                                                                    }),
                                                                )}
                                                        />
                                                    </InputGroup>
                                                )}
                                            </FlexboxGrid.Item>
                                        </FlexboxGrid>
                                    </>
                                ) : null}
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                    )}
                </FlexboxGrid.Item>
            </FlexboxGrid>
        </Container>
    );
};

const ModalCadastroOferta = ({
    show,
    confirmOferta,
    closeOferta,
    id,
    idsLojas,
    blocosOfertas,
    setOferta,
    editOferta,
    ofertaId,
}) => (
    <Modal
        show={show}
        onConfirm={confirmOferta}
        onCancel={closeOferta}
        title={!editOferta ? 'Criar oferta' : 'Editar oferta'}
        subtitle="Selecione um ou mais produtos para criar uma oferta"
        message={OfertasSelecionadas({
            id,
            idsLojas,
            blocosOfertas,
            setOferta,
            editOferta,
            ofertaId,
            show,
            confirmOferta,
        })}
        confirmButton={!editOferta ? 'Salvar oferta' : 'Alterar oferta'}
        cancelButton="Cancelar"
        width="900px"
        height="782px"
    />
);

export default ModalCadastroOferta;
