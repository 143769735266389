import React from 'react';
import { Content } from 'rsuite';
import emptyState from '../../../../../../assets/image/empty-state-4.svg';
import styles from './styles.module.scss';

export const EmptyState = () => (
    <Content className={styles['empty-content']}>
        <div className={styles['text-wrapper']}>
            <h6 className={styles.title}>Sem dados para essa seleção</h6>
            <p className={styles.subtitle}>
                Revise os filtros e tente novamente
            </p>
        </div>
        <img src={emptyState} alt="" />
    </Content>
);
