import React from 'react';

export const MdHandshake = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        width="18"
        height="16"
        viewBox="0 0 18 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M12.7333 6.67509C12.4083 7.00009 11.8666 7.00009 11.5416 6.67509L7.81665 2.95842L1.94165 8.82509L1.39165 8.30009C0.41665 7.32509 0.41665 5.74176 1.39165 4.76676L4.92498 1.23342C5.89998 0.258424 7.48332 0.258424 8.45832 1.23342L12.7333 5.50009C13.0583 5.82509 13.0583 6.35009 12.7333 6.67509ZM13.3166 4.90842C13.9666 5.55842 13.9666 6.61676 13.3166 7.26676C12.2583 8.32509 11.1416 7.45009 10.9583 7.26676L7.82498 4.13342L3.18332 8.77509C2.85832 9.10009 2.85832 9.62509 3.18332 9.95009C3.50832 10.2751 4.03332 10.2751 4.36665 9.95009L8.21665 6.10009L8.80832 6.69176L4.95832 10.5418C4.63332 10.8668 4.63332 11.3918 4.95832 11.7168C5.28332 12.0418 5.80832 12.0418 6.14165 11.7168L9.99165 7.86676L10.5833 8.45843L6.73332 12.3084C6.40832 12.6334 6.40832 13.1584 6.73332 13.4834C7.05832 13.8084 7.58332 13.8084 7.90832 13.4834L11.7583 9.63342L12.35 10.2251L8.49998 14.0751C8.17498 14.4001 8.17498 14.9251 8.49998 15.2501C8.82498 15.5751 9.34998 15.5751 9.67498 15.2501L16.6083 8.30009C17.5833 7.32509 17.5833 5.74176 16.6083 4.76676L13.075 1.23342C12.1166 0.275091 10.5666 0.258424 9.59165 1.18342L13.3166 4.90842Z"
            fill="currentColor"
        />
    </svg>
);
