import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    FormGroup,
    HelpBlock,
    Whisper,
    Icon,
    Tooltip,
    FlexboxGrid,
    Col,
    CheckPicker,
    Button,
    Checkbox,
} from 'rsuite';
import moment from 'moment';
import { selectorExtracaoDados } from '../../../../../../reducers/extracaoDados';
import {
    setEcomValues,
} from '../../../../../../reducers/extracaoDados/ecom';

import DataRangePicker from '../../../../../../components/DataRangePicker';
import { useProduto } from '../../hooks/useProduto';
import { useFontes } from '../../hooks/useFontes';
import { useTableList } from '../../hooks/useTableList';
import styles from './EcomContentFilter.module.scss';
import { LoadingSpinerLocal } from '../../../../../../components/LoadingSpinner';
import { useTableColumn } from '../../hooks/useTableColumn';

const footerStyles = {
    padding: '10px 2px',
    borderTop: '1px solid #e5e5e5',
};

const maxDate = moment().toDate();
const minDate = moment().subtract(91, 'days').toDate();

export const EcomContentFilter = () => {
    const [date, setDate] = useState([]);
    const [errors, setErrors] = useState(false);
    const [page, setPage] = useState(0);

    const dispatch = useDispatch();

    const { ecom } = useSelector(selectorExtracaoDados);

    const {
        produtos,
        checkAllProducts,
        loading,
        onSearchProducts,
        handleCheckAllProducts,
        handleSelectEcomProduct,
    } = useProduto();

    const {
        fontes,
        checkAllFontes,
        onSearchFontes,
        handleCleanFontes,
        handleSelectEcomFonte,
        handleCheckAllFontes,
    } = useFontes();

    const { getEcomTableList } = useTableList();
    const { columnData, columnDataSelected } = useTableColumn();

    const handleFilterDate = (value) => {
        setDate(value);
        const obj = {
            name: 'ecomPayload',
            value: {
                ...ecom.ecomPayload,
                data_inicio: value[0],
                data_fim: value[1],
            },
        };
        dispatch(setEcomValues(obj));
        setErrors(false);
    };

    const getEcomPreview = () => {
        if (date.length) {
            getEcomTableList(page);
        } else {
            setErrors(true);
        }
    };

    const setFillColumns = () => {
        const colunasSelectedObj = { name: 'colunasSelectedList', value: columnDataSelected };
        dispatch(setEcomValues(colunasSelectedObj));
        dispatch(setEcomValues({ name: 'colunasHeaderList', value: columnData.colunas }));
    };

    const setCookieColumns = () => {
        const newColumns = columnData.colunas?.filter((item) => ecom.browserCookie.includes(item.id));
        const colunasObj = { name: 'colunasHeaderList', value: newColumns };
        dispatch(setEcomValues(colunasObj));
    };

    const getResponse = () => {
        setPage(0);
        getEcomPreview();

        if (!ecom.browserCookie?.length) {
            setFillColumns();
        } else {
            setCookieColumns();
        }
    };

    const getBrowserCookie = (cname) => {
        const name = `${cname}=`;
        const decodedCookie = decodeURIComponent(document.cookie);
        const ca = decodedCookie.split(';');
        for (let i = 0; i < ca.length; i += 1) {
            let c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                if (c.substring(name.length, c.length)) {
                    const cookieList = c.substring(name.length, c.length).split(',');
                    const cookieObj = { name: 'browserCookie', value: cookieList };
                    const colunasSelectedObj = { name: 'colunasSelectedList', value: cookieList };
                    dispatch(setEcomValues(cookieObj));
                    dispatch(setEcomValues(colunasSelectedObj));
                }
            }
        }
    };

    useEffect(() => {
        onSearchFontes('');
        dispatch(setEcomValues({ name: 'ecomTableData', value: {} }));
        getBrowserCookie('colunasEcom');
    }, []);

    return (
        <div>
            <FlexboxGrid className={styles['date-row']}>
                <FlexboxGrid.Item componentClass={Col} lg={5} md={6} sm={22} xs={22}>
                    <FormGroup>
                        <DataRangePicker
                            id="date-filter"
                            maxDate={maxDate}
                            minDate={minDate}
                            name="data"
                            placeholder="Data*"
                            onChangeValue={handleFilterDate}
                            ranges={[
                            {
                                label: 'Hoje',
                                value: [moment().toDate(), moment().toDate()],
                            },
                            {
                                label: 'Ontem',
                                value: [moment().subtract(1, 'days').toDate(), moment().subtract(1, 'days').toDate()],
                            },
                            {
                                label: 'Últimos 7 dias',
                                value: [moment().subtract(7, 'days').toDate(), moment().toDate()],
                            },
                            {
                                label: 'Últimos 30 dias',
                                value: [moment().subtract(31, 'days').toDate(), moment().toDate()],
                            },
                        ]}
                        />
                        {errors ? <HelpBlock className="invalid-field" name="erroData">Campo obrigatório</HelpBlock> : null}
                    </FormGroup>
                </FlexboxGrid.Item>
                <FlexboxGrid.Item componentClass={Col} lg={1} md={1} sm={2} xs={2}>

                    <Whisper placement="right" trigger="hover" speaker={<Tooltip>Data da realização da coleta</Tooltip>}>
                        <Icon className="tooltip-info" icon="info" />
                    </Whisper>
                </FlexboxGrid.Item>
                <FlexboxGrid.Item componentClass={Col} lg={8} md={8} sm={22} xs={22}>
                    <CheckPicker
                        className="inf-picker"
                        block
                        id="e-product-filter"
                        filter={false}
                        value={produtos.value}
                        data={produtos.list}
                        locale={{
                            noResultsText: 'Nenhum item encontrado',
                            searchPlaceholder: 'Pesquise o produto que deseja filtrar',
                        }}
                        name="produtos"
                        placeholder="Produtos"
                        onSelect={(value, item) => handleSelectEcomProduct(value, item)}
                        onSearch={onSearchProducts}
                        maxHeight={200}
                        onOpen={() => {
                            const input = document.getElementsByClassName('rs-picker-search-bar-input')[0];
                            input.focus();
                        }}
                        renderMenu={(menu) => (
                            <span>
                                <LoadingSpinerLocal loading={loading} />
                                {menu}
                            </span>
                        )}
                        renderExtraFooter={() => (
                            <div style={footerStyles}>
                                <Checkbox
                                    inline
                                    checked={checkAllProducts}
                                    onChange={handleCheckAllProducts}
                                >
                                    Selecionar tudo
                                    {' '}
                                    {produtos.list.length ? `(${produtos.list.length})` : null}
                                </Checkbox>
                            </div>
                        )}
                    />
                </FlexboxGrid.Item>
                <FlexboxGrid.Item componentClass={Col} lg={1} md={1} sm={2} xs={2}>
                    <Whisper
                        placement="top"
                        trigger="hover"
                        speaker={(
                            <Tooltip>
                                <p>Filtro de produto</p>
                                <p>Pesquise o produto por parte de sua descrição ou pelo seu identificador exato</p>
                            </Tooltip>
                        )}
                    >
                        <Icon className="tooltip-info" icon="info" />
                    </Whisper>
                </FlexboxGrid.Item>
                <FlexboxGrid.Item componentClass={Col} lg={3} md={4} sm={24} xs={24} lgPush={1}>
                    <Button
                        id="obter-resposta"
                        className={styles['btn-get-answer']}
                        appearance="primary"
                        justify="end"
                        block
                        onClick={getResponse}
                    >
                        Obter resposta
                    </Button>
                </FlexboxGrid.Item>
            </FlexboxGrid>
            <FlexboxGrid>
                <FlexboxGrid.Item componentClass={Col} lg={14} md={12} sm={22} xs={22} colspan={16}>
                    <CheckPicker
                        className="inf-picker"
                        block
                        id="fontes-filter"
                        filter={false}
                        data={fontes.list}
                        value={fontes.value}
                        locale={{
                            noResultsText: 'Nenhum item encontrado',
                            searchPlaceholder: 'Pesquise a fonte que deseja filtrar',
                        }}
                        name="fontes"
                        placeholder="Fontes"
                        onClean={handleCleanFontes}
                        onSelect={(value, item) => handleSelectEcomFonte(value, item)}
                        onSearch={onSearchFontes}
                        maxHeight={200}
                        renderExtraFooter={() => (
                            <div style={footerStyles}>
                                <Checkbox
                                    inline
                                    checked={checkAllFontes}
                                    onChange={handleCheckAllFontes}
                                >
                                    Selecionar tudo
                                    {' '}
                                    {fontes.list.length ? `(${fontes.list.length})` : null}
                                </Checkbox>
                            </div>
                        )}
                    />
                </FlexboxGrid.Item>
                <FlexboxGrid.Item componentClass={Col} lg={1} md={1} sm={2} xs={2}>
                    <Whisper
                        placement="top"
                        trigger="hover"
                        speaker={(
                            <Tooltip>
                                <p>Filtro de fonte</p>
                                <p>Pesquise os sites que deseja ver o histórico de preços.</p>
                            </Tooltip>
                        )}
                    >
                        <Icon className="tooltip-info" icon="info" />
                    </Whisper>
                </FlexboxGrid.Item>
            </FlexboxGrid>
        </div>
    );
};
