import React from 'react';
// @ts-ignore
import CurrencyFormat from 'react-currency-format';
import { Alert } from 'rsuite';
import { LoadingSpinerArea } from '../../../../../../../components/LoadingSpinner';
import { DATAKEYS, DATAPOINT_PROPS, EDIT_OBJECT } from '../../../../@types';
import { useGerenciadorPrecos } from '../../../../context';
import { getFromLocalStorage } from '../../../../lib';
import { editDatapoint } from '../../../../lib/services';
import { NumericInput } from '../../../atoms';

export type NumericProps = {
    dataKey: DATAKEYS;
    editable?: boolean;
    index: number;
} & NumericInput &
    DATAPOINT_PROPS;

export const Numeric = ({
    value,
    dataKey,
    index,
    editable,
    prefix,
    suffix,
    skin = 'gray',
    allowNegative = false,
    idProductToBePriced,
    className,
    disabled,
    onChange,
    onBlur,
    children,
    ...props
}: NumericProps) => {
    const { data, setData, handleUpdateField, findDatapoint } =
        useGerenciadorPrecos();
    const datapoint = findDatapoint(idProductToBePriced);
    const newValue = value ?? Number(datapoint?.[dataKey]) ?? 0;

    function handleBlur() {
        if (!datapoint || !datapoint.newPrice) return;
        const user = getFromLocalStorage<{ nome: string }>('usuario');
        const client = getFromLocalStorage<{
            codigo: number;
            nome: string;
            organizacao: string;
        }>('cliente');

        if (!user || !client) return;

        const model: EDIT_OBJECT = {
            id: datapoint.idDatapointAction,
            productsToBePricedId: datapoint.idProductToBePriced,
            productId: datapoint.productId,
            storeId: datapoint.storeId,
            retailPrice: datapoint.retailPrice,
            price: datapoint.price,
            author: user.nome,
            clientId: client.codigo,
            client: client.nome,
            competitorPrice: datapoint.competitorPrice,
        };
        editDatapoint(model).then((response) => {
            if (response) {
                Alert.success('Produto editado com sucesso');
                const dataContent = data.content;
                data.content[index] = {
                    ...data.content[index],
                    idDatapointAction: response.id,
                };
                setData((oldValue) => ({
                    ...oldValue,
                    dataContent,
                }));
            }
        });
    }

    if (editable) {
        return (
            <div>
                <LoadingSpinerArea
                    area={`numeric-input-${idProductToBePriced}`}
                    size="sm"
                    height="64px"
                />
                <NumericInput
                    skin={skin}
                    name={dataKey}
                    prefix={prefix}
                    suffix={suffix}
                    decimalSeparator=","
                    thousandSeparator="."
                    precision="2"
                    allowNegative={allowNegative}
                    onChangeEvent={(e: React.ChangeEvent<HTMLInputElement>) => {
                        if (!value) {
                            handleUpdateField(e.target.value, index, dataKey);
                        }
                        onChange?.(e);
                    }}
                    value={newValue}
                    disabled={disabled}
                    onBlur={(e) => {
                        onBlur?.(e);
                        handleBlur();
                    }}
                    {...props}
                />
            </div>
        );
    }

    return (
        <div
            className={[
                'numeric-input',
                'font-size-100-semibold',
                className,
            ].join(' ')}
        >
            {newValue ? (
                <>
                    <CurrencyFormat
                        className="numeric-input__input-text"
                        fixedDecimalScale
                        decimalScale={2}
                        value={newValue}
                        displayType="text"
                        prefix={prefix ?? 'R$ '}
                        suffix={suffix}
                        decimalSeparator=","
                        thousandSeparator="."
                    />
                    {children}
                </>
            ) : (
                <>--</>
            )}
        </div>
    );
};
