import { useQueries } from '@tanstack/react-query';
import React from 'react';
import { DataItem } from '../../../@types/DataItem';
import { SegmentsCurve } from '../../../pages/IPA/Estrategia/pages/RegrasDePreco/lib';
import { checkIfIsLoading } from '../../../utils';
import {
    listAbcClass,
    listInfoPriceAbcClass,
    listInfoPriceGlobalAbcClass,
    listInfoPriceSensibility,
    listSensibilityType,
} from '../../FilterSection/services';
import { CheckPill } from '../CheckPill';
import { FilterDescription } from '../FilterDescription';
import { FilterSeparator } from '../FilterSeparator';
import { SelectPill } from '../SelectPill';

type ChainedSegmentSelectProps = {
    select: {
        data: DataItem[];
        value?: SegmentsCurve;
        onSelect: (value: SegmentsCurve) => void;
        onClean: () => void;
    };
    check: {
        onSelect: (key: SegmentsCurve, value: string[]) => void;
        onClean?: (key: SegmentsCurve) => void;
        value?: string[];
    };
};

export const ChainedSegmentSelect = ({
    select,
    check,
    ...props
}: ChainedSegmentSelectProps) => {
    const [
        infoPriceSensibility,
        infoPriceAbcClass,
        infoPriceGlobalAbcClass,
        sensibilityType,
        abcClass,
    ] = useQueries({
        queries: [
            { key: 'infoPriceSensibility', fn: listInfoPriceSensibility },
            { key: 'infoPriceAbcClass', fn: listInfoPriceAbcClass },
            { key: 'infoPriceGlobalAbcClass', fn: listInfoPriceGlobalAbcClass },
            { key: 'sensibilityType', fn: listSensibilityType },
            { key: 'abcClass', fn: listAbcClass },
        ].map((query) => ({
            queryKey: ['segment', query.key],
            queryFn: query.fn,
            initialData: [],
            retry: false,
            enabled: select.value === query.key,
        })),
    });

    const data = {
        infoPriceSensibility,
        infoPriceAbcClass,
        infoPriceGlobalAbcClass,
        sensibilityType,
        abcClass,
    };

    const loading = {
        infoPriceSensibility: checkIfIsLoading(
            infoPriceSensibility.fetchStatus,
        ),
        infoPriceAbcClass: checkIfIsLoading(infoPriceAbcClass.fetchStatus),
        infoPriceGlobalAbcClass: checkIfIsLoading(
            infoPriceGlobalAbcClass.fetchStatus,
        ),
        sensibilityType: checkIfIsLoading(sensibilityType.fetchStatus),
        abcClass: checkIfIsLoading(abcClass.fetchStatus),
    };

    return (
        <>
            <SelectPill
                data={select.data}
                value={select.value}
                searchable={false}
                onSelect={select.onSelect}
                onClean={select.onClean}
            />
            {!!select.value && (
                <>
                    <FilterSeparator />
                    <CheckPill
                        {...props}
                        data={
                            (select.value
                                ? data[select?.value]?.data
                                : []) as DataItem[]
                        }
                        value={check?.value ?? []}
                        searchable={false}
                        onSelect={(value) => {
                            if (select.value) {
                                check.onSelect(select.value, value);
                            }
                        }}
                        onClean={() => {
                            if (select.value) {
                                check.onClean?.(select.value);
                            }
                        }}
                        isLoading={loading[select.value]}
                    />
                </>
            )}
            {!select.value && <FilterDescription>Opcional</FilterDescription>}
        </>
    );
};
