import React, { useEffect, useState } from 'react';
import {
    Container, Content, Header, Button,
} from 'rsuite';
import { withRouter } from 'react-router-dom';

import { LoadingSpiner } from '../../../../components/LoadingSpinner';
import { getDashboardSLA } from './services';
import ModalLegenda from './Components/ModalLegenda';

const AcompanhamentoColetas = () => {
    const [dashboardUrl, setDashboardUrl] = useState(null);
    const [showModal, setShowModal] = useState(false);

    async function getDashboard() {
        const response = await getDashboardSLA();
        if (response?.url) {
            setDashboardUrl(response.url);
        }
    }

    useEffect(() => {
        getDashboard();
    }, []);

    return (
        <Container className="main-container acompanhamento-coletas">
            <LoadingSpiner size="md" />
            <Header className="description">
                <div>
                    <h1>
                        Acompanhamento de Coletas
                    </h1>
                    <p>
                        Acompanhe o andamento das coletas do dia em tempo real. Para saber mais sobre cada status
                        <Button appearance="link" onClick={() => setShowModal(true)}>clique aqui</Button>
                    </p>
                </div>
            </Header>

            <Content>
                <iframe
                    title="Acompanhamento de coletas"
                    src={dashboardUrl}
                    frameBorder="0"
                />
            </Content>
            {showModal ? <ModalLegenda show={showModal} onCancel={() => setShowModal(false)} /> : null}

        </Container>
    );
};

export default withRouter(AcompanhamentoColetas);
