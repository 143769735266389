import React from 'react';
// @ts-ignore
import { Tabela } from '../../Components/index.tsx';
// @ts-ignore
import { useMargemPraticadaPorLoja } from '../../../hooks/useMargemPraticadaPorLoja.tsx';

export const MargemPraticadaPorLoja: React.FC = () => {
    const { data, isLoading } = useMargemPraticadaPorLoja();

    const columns = [
        {
            align: 'left',
            headerCell: 'Loja',
            dataKey: 'storeName',
            flexGrow: 2,
        },
        {
            align: 'left',
            headerCell: 'Margem',
            dataKey: 'realizedMargin',
            isPercent: true,
        },
        {
            align: 'left',
            headerCell: 'Ganho margem',
            dataKey: 'marginGain',
            flexGrow: 1,
        },
    ];

    return (
        <Tabela
            data={data}
            isLoading={isLoading}
            columns={columns}
            title="Margem praticada por loja"
        />
    );
};
