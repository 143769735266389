import {
    getSegmentos,
    getItensSegmentoProduto,
    searchFiltroProdutos,
    getSegmentosSensibilities,
    getSegmentosClasses,
} from '../services/ProdutoService';
import { getDatapointsStores } from '../services/DatapointService';
import { getFamiliaProdutos } from '../pages/IPA/RevisaoPrecos/services';

export const ADD_FILTRO_REVISAO = 'ADD_FILTRO_REVISAO';
export const DELETE_FILTRO_REVISAO = 'DELETE_FILTRO_REVISAO';
export const SELECT_VALUE_FILTRO_REVISAO = 'SELECT_VALUE_FILTRO_REVISAO';
export const SELECT_VALUE_FILTRO_LOJAS_REVISAO
    = 'SELECT_VALUE_FILTRO_LOJAS_REVISAO';
export const CHANGE_VALUE_FILTRO_REVISAO = 'CHANGE_VALUE_FILTRO_REVISAO';
export const CHANGE_VALUE_FILTRO_LOJA_REVISAO
    = 'CHANGE_VALUE_FILTRO_LOJA_REVISAO';
export const CHANGE_CACHE_FILTRO = 'CHANGE_CACHE_FILTRO';
export const CHANGE_CACHE_FILTRO_LOJA = 'CHANGE_CACHE_FILTRO_LOJA';
export const LIST_ITENS_FILTROS_REVISAO = 'LIST_ITENS_FILTROS_REVISAO';
export const LIST_ITENS_PRODUTO_REVISAO = 'LIST_ITENS_PRODUTO_REVISAO';
export const LIST_ITENS_FAMILIA_PRODUTO_REVISAO = 'LIST_ITENS_FAMILIA_PRODUTO_REVISAO';
export const LIST_ITENS_LOJA_REVISAO = 'LIST_ITENS_LOJA_REVISAO';
export const LIST_RULE_TYPES_REVISAO = 'LIST_RULE_TYPES_REVISAO';
export const LIST_SEGMENTOS = 'LIST_SEGMENTOS';
export const LIST_ITENS_SEGMENTOS_CLASSES_REVISAO
    = 'LIST_ITENS_SEGMENTOS_CLASSES_REVISAO';
export const CLEAR_FILTROS_PRODUTOS = 'CLEAR_FILTROS_PRODUTOS';

export function addFiltro(filtro) {
    return {
        type: ADD_FILTRO_REVISAO,
        filtro,
    };
}

export function deleteFiltro(index, name) {
    return {
        type: DELETE_FILTRO_REVISAO,
        index,
        name,
    };
}

export function changeValueFiltro(value, name) {
    return {
        type: CHANGE_VALUE_FILTRO_REVISAO,
        value,
        name,
    };
}

export function changeValueFiltroLoja(value, name) {
    return {
        type: CHANGE_VALUE_FILTRO_LOJA_REVISAO,
        value,
        name,
    };
}

export function changeCacheFiltro(name, cache) {
    return {
        type: CHANGE_CACHE_FILTRO,
        name,
        cache,
    };
}

export function changeCacheFiltroLoja(name, cache) {
    return {
        type: CHANGE_CACHE_FILTRO_LOJA,
        name,
        cache,
    };
}

export function selectValue(item, name) {
    return {
        type: SELECT_VALUE_FILTRO_REVISAO,
        item,
        name,
    };
}

export function selectValueLojas(item, name) {
    return {
        type: SELECT_VALUE_FILTRO_LOJAS_REVISAO,
        item,
        name,
    };
}

export function clearFiltros() {
    return {
        type: CLEAR_FILTROS_PRODUTOS,
    };
}

export function listSegmentos(itens) {
    const segmentos = {};
    if (itens) {
        itens.forEach((item) => {
            segmentos[item.field] = { lista: [], value: [] };
        });
    }
    return {
        type: LIST_SEGMENTOS,
        itens: itens || [],
        segmentos,
    };
}

export function listItens(itens, name) {
    return {
        type: LIST_ITENS_FILTROS_REVISAO,
        itens,
        name,
    };
}

export function listItensProduto(itens, name) {
    return {
        type: LIST_ITENS_PRODUTO_REVISAO,
        itens,
        name,
    };
}

export function listItensFamiliaProduto(itens, name) {
    return {
        type: LIST_ITENS_FAMILIA_PRODUTO_REVISAO,
        itens,
        name,
    };
}

export function listItensLoja(itens, name) {
    return {
        type: LIST_ITENS_LOJA_REVISAO,
        itens,
        name,
    };
}

export function listRuleTypes(itens, name) {
    return {
        type: LIST_RULE_TYPES_REVISAO,
        itens,
        name,
    };
}

export function listSegmentosClassesSensibility(itens, name) {
    return {
        type: LIST_ITENS_SEGMENTOS_CLASSES_REVISAO,
        itens,
        name,
    };
}

export function searchSegmentos() {
    return (dispatch) => getSegmentos().then((data) => {
            dispatch(listSegmentos(data));
        });
}

export function searchSegmentosSensibilities() {
    return (dispatch) => {
        getSegmentosSensibilities().then((data) => dispatch(listSegmentosClassesSensibility(data, 'sensibilityType')));
    };
}

export function searchSegmentosClasses() {
    return (dispatch) => {
        getSegmentosClasses().then((data) => dispatch(listSegmentosClassesSensibility(data, 'abcClass')));
    };
}

export function search(nivel, query, name) {
    const nivelCategoria
        = nivel === 'productId'
        || nivel === 'storeName'
        || nivel === 'ruleType'
        || nivel === 'infoPriceSensibility'
            ? nivel
            : `categoryLevel${nivel}` || '';
    return (dispatch) => {
        getItensSegmentoProduto(nivelCategoria, { query }).then((data) => dispatch(listItens(data, name)));
    };
}

export function searchProduto(nivel, query, name) {
    return (dispatch) => {
        searchFiltroProdutos({ query }).then((data) => dispatch(listItensProduto(data, name)));
    };
}

export function searchFamiliaProduto(nivel, query, name) {
    return (dispatch) => {
        getFamiliaProdutos(query).then((data) => dispatch(listItensFamiliaProduto(data, name)));
    };
}

export function searchStore(nivel, query, name) {
    return (dispatch) => {
        getDatapointsStores(query).then((resp) => {
            dispatch(listItensLoja(resp, name));
        });
    };
}

export function searchRuleType(nivel, query, name) {
    return (dispatch) => {
        dispatch(
            listRuleTypes(
                [
                    { value: 'CURRENT', label: 'Preço vigente' },
                    { value: 'AUTO_PRICE', label: 'Automático' },
                    { value: 'MANUAL_PRICE', label: 'Manual' },
                ],
                name,
            ),
        );
    };
}
