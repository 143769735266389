import React from 'react';

export const MdDescInsights = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        {...props}
    >
        <path
            d="M17.5 7.08366C16.2917 7.08366 15.6167 8.28366 15.8917 9.17533L12.9334 12.142C12.6834 12.067 12.3167 12.067 12.0667 12.142L9.94171 10.017C10.225 9.12533 9.55004 7.91699 8.33337 7.91699C7.12504 7.91699 6.44171 9.11699 6.72504 10.017L2.92504 13.8087C2.03337 13.5337 0.833374 14.2087 0.833374 15.417C0.833374 16.3337 1.58337 17.0837 2.50004 17.0837C3.70837 17.0837 4.38337 15.8837 4.10837 14.992L7.90004 11.192C8.15004 11.267 8.51671 11.267 8.76671 11.192L10.8917 13.317C10.6084 14.2087 11.2834 15.417 12.5 15.417C13.7084 15.417 14.3917 14.217 14.1084 13.317L17.075 10.3587C17.9667 10.6337 19.1667 9.95866 19.1667 8.75033C19.1667 7.83366 18.4167 7.08366 17.5 7.08366Z"
            fill="currentColor"
        />
        <path
            d="M12.5 7.91699L13.2834 6.19199L15 5.41699L13.2834 4.64199L12.5 2.91699L11.7334 4.64199L10 5.41699L11.7334 6.19199L12.5 7.91699Z"
            fill="currentColor"
        />
        <path
            d="M2.91671 9.58366L3.33337 7.91699L5.00004 7.50033L3.33337 7.08366L2.91671 5.41699L2.50004 7.08366L0.833374 7.50033L2.50004 7.91699L2.91671 9.58366Z"
            fill="currentColor"
        />
    </svg>
);
