import React from 'react';
import { LoadingSpiner } from '../../../../../components';
import { ConfigForm } from './Components';
import styles from './SetupIPAConfiguracoesBasicas.module.scss';
import { useSetupIPAConfig } from './hooks/useSetupIPAConfig';

const SetupIPAConfiguracoesBasicas = () => {
    const { data, createConfig, updateConfig, isLoading } = useSetupIPAConfig();

    return (
        <div className={styles['setup-ipa__main-config-container']}>
            {isLoading && <LoadingSpiner size="md" />}
            <ConfigForm
                data={data}
                createConfig={createConfig}
                updateConfig={updateConfig}
            />
        </div>
    );
};

export default SetupIPAConfiguracoesBasicas;
