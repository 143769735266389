import EmojiEventsIcon from '@material-ui/icons/EmojiEvents';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FlagIcon from '@material-ui/icons/Flag';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import classNames from 'classnames';
import React, { useState } from 'react';
import {
    Button,
    Checkbox,
    Divider,
    InputGroup,
    Table,
    Tooltip,
    Whisper,
} from 'rsuite';
import { TableProps } from 'rsuite/lib/Table';
import { TablePaginationProps } from 'rsuite/lib/Table/TablePagination';
import infoIcon from '../../../../../../../assets/icons/icon_info-gray.svg';
import {
    ContextMenu,
    ContextMenuContent,
    ContextMenuItem,
    ContextMenuTrigger,
} from '../../../../../../../components';
import { LoadingSpinerArea } from '../../../../../../../components/LoadingSpinner';
import { InfoCurrencyInput } from '../../../../components';
import { UseSelectedDatapointsProps } from '../../../../hooks';
import { NegotiationDataItemProps } from '../../NegociacaoFornecedor.types';
import {
    calculateVariationPercentage,
    competitivenessIndicator,
    formatDecimal,
} from '../../utils';
import { BestBuyPriceIndicator } from '../BestBuyPriceIndicator';
import { ExpandedTableContent } from '../ExpandedTableContent';
import { MoneyPercentageIndicator } from '../MoneyPercentageIndicator';
import { VariationBoxIndicator } from '../VariationBoxIndicator';
import styles from './TableFornecedor.module.scss';

const { Column, HeaderCell, Cell, Pagination } = Table;

export type SortTypeProps = 'asc' | 'desc';

type RowDataProps = NegotiationDataItemProps;

export interface ITableFornecedor
    extends Omit<
        TableProps<keyof RowDataProps, RowDataProps>,
        'expandedRowKeys'
    > {
    data?: TableProps['data'];
    expandedRowKeys?: string[];
    pagination?: TablePaginationProps;
    isLoading?: boolean;
    selectedDatapoints: UseSelectedDatapointsProps;
    onExpandRow?: (rowData: RowDataProps) => void;
    onChangeNewCost: (index: number, value: string, rowData: any) => void;
    onChangeNewPrice: (index: number, value: string, rowData: any) => void;
    onChangeObjectiveMargin: (
        index: number,
        value: string,
        rowData: any,
    ) => void;
    onBlur: (
        rowData: RowDataProps,
        index: number,
        details: {
            onBlurValue: number | null;
            onFocusValue: number | null;
            hasChanges: boolean;
        },
    ) => void;
    onContextMenu?: (
        rowData: RowDataProps,
        index: number,
        event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    ) => void;
    disableCheckBoxes?: boolean;
    disableInputs?: boolean;
    contextMenu?: {
        onUndoChanges: (rowData: RowDataProps) => void;
    };
}

export const TableFornecedor = React.memo(
    ({
        data,
        pagination,
        isLoading,
        expandedRowKeys,
        rowKey,
        selectedDatapoints,
        onExpandRow,
        onBlur,
        onChangeObjectiveMargin,
        onChangeNewCost,
        onChangeNewPrice,
        onContextMenu,
        disableCheckBoxes = false,
        disableInputs = false,
        contextMenu,
        ...props
    }: ITableFornecedor) => {
        const hasItemInList = (id: string) => {
            const existInList = expandedRowKeys?.some(
                (item: string) => item === id,
            );
            return existInList;
        };

        const datapointLength = selectedDatapoints.length;

        const isCheckedAll = selectedDatapoints.data.selectedAll
            ? !datapointLength
            : datapointLength === pagination?.displayLength;

        const isIndeterminate = !isCheckedAll && !!datapointLength;

        const isChecked = (id: string) => {
            if (selectedDatapoints.data.selectedAll) {
                return !selectedDatapoints.data.excluded.some(
                    (item) => item === id,
                );
            }
            return selectedDatapoints.data.selected.some((item) => item === id);
        };

        const [contextMenuItem, setContextMenuItem] =
            useState<null | RowDataProps>(null);

        const handleContextMenu = (
            rowData: RowDataProps,
            index: number,
            event: React.MouseEvent<HTMLDivElement, MouseEvent>,
        ) => {
            setContextMenuItem(rowData);
            onContextMenu?.(rowData, index, event);
        };

        return (
            <div className={styles['table-fornecedor-container']}>
                <ContextMenu>
                    <ContextMenuTrigger>
                        <Table
                            data={data}
                            loading={isLoading}
                            autoHeight
                            id="table-negociacoes-fornecedor"
                            className={styles['table-fornecedor']}
                            expandedRowKeys={expandedRowKeys}
                            rowKey={rowKey}
                            rowClassName={(rowData: RowDataProps) => {
                                return classNames(
                                    styles['table-fornecedor-row'],
                                    `row-data-${rowData?.productsToBePricedId}`,
                                );
                            }}
                            rowExpandedHeight={200}
                            rowHeight={64}
                            headerHeight={46}
                            renderRowExpanded={(rowData) => (
                                <ExpandedTableContent rowData={rowData} />
                            )}
                            {...props}
                        >
                            <Column align="center" width={60}>
                                <HeaderCell style={{ padding: '2px' }}>
                                    <Checkbox
                                        checked={isCheckedAll}
                                        indeterminate={isIndeterminate}
                                        onChange={(_v, checked) => {
                                            selectedDatapoints.onCheckAll(
                                                data,
                                                checked,
                                            );
                                        }}
                                        disabled={disableCheckBoxes}
                                    />
                                </HeaderCell>
                                <Cell>
                                    {(rowData: RowDataProps, index: number) => (
                                        <div
                                            onContextMenu={(e) => {
                                                handleContextMenu?.(
                                                    rowData,
                                                    index,
                                                    e,
                                                );
                                            }}
                                        >
                                            <Checkbox
                                                value={
                                                    rowData.productsToBePricedId
                                                }
                                                checked={isChecked(
                                                    rowData.productsToBePricedId,
                                                )}
                                                onChange={(value, checked) => {
                                                    selectedDatapoints.onCheck(
                                                        value,
                                                        checked,
                                                    );
                                                }}
                                                disabled={disableCheckBoxes}
                                            />
                                        </div>
                                    )}
                                </Cell>
                            </Column>
                            <Column sortable flexGrow={1}>
                                <HeaderCell>Produto</HeaderCell>
                                <Cell dataKey="productId" height={60}>
                                    {(rowData: RowDataProps, index: number) => (
                                        <div
                                            onContextMenu={(e) => {
                                                handleContextMenu?.(
                                                    rowData,
                                                    index,
                                                    e,
                                                );
                                            }}
                                        >
                                            <div
                                                className={
                                                    styles[
                                                        'produto-numbers-wrapper'
                                                    ]
                                                }
                                            >
                                                <p
                                                    className={classNames(
                                                        styles[
                                                            'produto-first-value'
                                                        ],
                                                        styles[
                                                            'cell-main-text'
                                                        ],
                                                    )}
                                                >
                                                    {rowData.productId}
                                                </p>
                                                {rowData.productFamilyId ? (
                                                    <div
                                                        className={[
                                                            styles['blue-box'],
                                                            'produto-box',
                                                        ].join(' ')}
                                                    >
                                                        <GroupWorkIcon
                                                            className={
                                                                styles[
                                                                    'produto-second-icon'
                                                                ]
                                                            }
                                                        />
                                                        <span
                                                            className={
                                                                styles[
                                                                    'produto-second-value'
                                                                ]
                                                            }
                                                        >
                                                            {
                                                                rowData.productFamilyId
                                                            }
                                                        </span>
                                                    </div>
                                                ) : null}
                                            </div>
                                            <p
                                                className={
                                                    styles['produto-name']
                                                }
                                            >
                                                {rowData.description}
                                            </p>
                                        </div>
                                    )}
                                </Cell>
                            </Column>
                            <Column sortable width={90}>
                                <HeaderCell>Loja</HeaderCell>
                                <Cell dataKey="storeId">
                                    {(rowData: RowDataProps, index: number) => (
                                        <div
                                            className={
                                                styles['loja-cell-wrapper']
                                            }
                                            onContextMenu={(e) => {
                                                handleContextMenu?.(
                                                    rowData,
                                                    index,
                                                    e,
                                                );
                                            }}
                                        >
                                            <Whisper
                                                placement="auto"
                                                trigger="hover"
                                                speaker={
                                                    <Tooltip>
                                                        {rowData.store}
                                                    </Tooltip>
                                                }
                                            >
                                                <span
                                                    className={
                                                        styles['cell-main-text']
                                                    }
                                                >
                                                    {rowData.storeId}
                                                </span>
                                            </Whisper>
                                            {/* <div className={[styles['blue-box'], 'loja-regional'].join(' ')}>
                                    <span>{rowData.lojaRegional}</span>
                                </div> */}
                                        </div>
                                    )}
                                </Cell>
                            </Column>
                            <Column width={130}>
                                <HeaderCell>
                                    <div>
                                        <p
                                            className={
                                                styles['table-header-text']
                                            }
                                        >
                                            Margem
                                        </p>
                                        <p
                                            className={
                                                styles['table-header-text']
                                            }
                                        >
                                            objetiva
                                        </p>
                                    </div>
                                </HeaderCell>
                                <Cell dataKey="newObjectiveMargin">
                                    {(rowData: RowDataProps, index: number) => (
                                        <div
                                            className={
                                                styles[
                                                    'margem-objetiva-cell-wrapper'
                                                ]
                                            }
                                            onContextMenu={(e) => {
                                                handleContextMenu?.(
                                                    rowData,
                                                    index,
                                                    e,
                                                );
                                            }}
                                        >
                                            <InputGroup>
                                                <InfoCurrencyInput
                                                    name="margemObjetiva"
                                                    decimalSeparator=","
                                                    maxLength={4}
                                                    precision="1"
                                                    allowEmpty
                                                    allowNegative={false}
                                                    value={
                                                        (rowData.newObjectiveMargin ??
                                                            rowData.objectiveMargin) as number
                                                    }
                                                    onChangeEvent={(
                                                        e,
                                                        value,
                                                    ) => {
                                                        onChangeObjectiveMargin(
                                                            index,
                                                            value,
                                                            rowData,
                                                        );
                                                    }}
                                                    onBlur={(
                                                        _e,
                                                        _v,
                                                        details,
                                                    ) => {
                                                        onBlur(
                                                            rowData,
                                                            index,
                                                            details,
                                                        );
                                                    }}
                                                    disabled={disableInputs}
                                                />
                                                <InputGroup.Addon>
                                                    %
                                                </InputGroup.Addon>
                                            </InputGroup>

                                            {/* <div className={styles['margem-objetiva-sub-wrapper']}>
                                    <FlagIcon className={[styles['flag-icon'], 'margin-right'].join(' ')} />
                                    <p className={styles['cell-subtext']}>
                                        {rowData.margemObjetiva}
                                        {' '}
                                        %
                                    </p>
                                </div> */}
                                        </div>
                                    )}
                                </Cell>
                            </Column>
                            <Column sortable minWidth={88}>
                                <HeaderCell>Custo atual</HeaderCell>
                                <Cell dataKey="cost">
                                    {(rowData: RowDataProps, index: number) => (
                                        <div
                                            className={
                                                styles[
                                                    'custo-atual-cell-wrapper'
                                                ]
                                            }
                                            onContextMenu={(e) => {
                                                handleContextMenu?.(
                                                    rowData,
                                                    index,
                                                    e,
                                                );
                                            }}
                                        >
                                            <p
                                                className={
                                                    styles['cell-main-text']
                                                }
                                            >
                                                R$
                                                {formatDecimal(rowData.cost, 2)}
                                            </p>

                                            <p
                                                className={
                                                    styles['cell-subtext']
                                                }
                                            >
                                                PMZ R${' '}
                                                {formatDecimal(rowData.pmz, 2)}
                                            </p>
                                        </div>
                                    )}
                                </Cell>
                            </Column>
                            <Column width={140}>
                                <HeaderCell>Novo custo</HeaderCell>
                                <Cell dataKey="newCost">
                                    {(rowData: RowDataProps, index: number) => (
                                        <div
                                            className={classNames(
                                                styles[
                                                    'novo-custo-cell-wrapper'
                                                ],
                                                styles['custom-cell'],
                                            )}
                                            onContextMenu={(e) => {
                                                handleContextMenu?.(
                                                    rowData,
                                                    index,
                                                    e,
                                                );
                                            }}
                                        >
                                            <InputGroup
                                                className={
                                                    styles[
                                                        rowData.newCost !== null
                                                            ? 'input-group-focus'
                                                            : 'input-group'
                                                    ]
                                                }
                                            >
                                                <InputGroup.Addon>
                                                    R$
                                                </InputGroup.Addon>
                                                <InfoCurrencyInput
                                                    name="novoCusto"
                                                    decimalSeparator=","
                                                    thousandSeparator="."
                                                    precision="2"
                                                    allowEmpty
                                                    allowNegative={false}
                                                    value={rowData.newCost}
                                                    onChangeEvent={(
                                                        e,
                                                        value,
                                                    ) => {
                                                        onChangeNewCost(
                                                            index,
                                                            value,
                                                            rowData,
                                                        );
                                                    }}
                                                    onBlur={(
                                                        _e,
                                                        _v,
                                                        details,
                                                    ) => {
                                                        onBlur(
                                                            rowData,
                                                            index,
                                                            details,
                                                        );
                                                    }}
                                                    disabled={disableInputs}
                                                />
                                            </InputGroup>

                                            <div
                                                className={
                                                    styles[
                                                        'novo-custo-sub-wrapper'
                                                    ]
                                                }
                                            >
                                                {/* PMZ novo custo */}
                                                <p
                                                    className={
                                                        styles['cell-subtext']
                                                    }
                                                >
                                                    {rowData.newCost === null
                                                        ? 'PMZ -- '
                                                        : 'PMZ R$ '}
                                                    {rowData.newCost === null
                                                        ? null
                                                        : formatDecimal(
                                                              rowData.PMZNewCost,
                                                              2,
                                                          )}
                                                </p>
                                                {rowData.newCost ===
                                                null ? null : (
                                                    <VariationBoxIndicator
                                                        positiveValueIsGood={
                                                            false
                                                        }
                                                        className="box-indicator"
                                                        value={calculateVariationPercentage(
                                                            rowData.cost,
                                                            rowData.newCost,
                                                        )}
                                                        suffix="%"
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    )}
                                </Cell>
                            </Column>
                            <Column width={140}>
                                <HeaderCell>
                                    <Whisper
                                        placement="auto"
                                        trigger="hover"
                                        speaker={
                                            <Tooltip>
                                                Calculado baseado na margem
                                                objetiva do produto e no novo
                                                custo
                                            </Tooltip>
                                        }
                                    >
                                        <span>Novo preço</span>
                                    </Whisper>
                                </HeaderCell>
                                <Cell dataKey="newPrice">
                                    {(rowData: RowDataProps, index: number) => (
                                        <div
                                            className={classNames(
                                                styles[
                                                    'novo-preco-cell-wrapper'
                                                ],
                                                styles['custom-cell'],
                                            )}
                                            onContextMenu={(e) => {
                                                handleContextMenu?.(
                                                    rowData,
                                                    index,
                                                    e,
                                                );
                                            }}
                                        >
                                            <InputGroup
                                                className={
                                                    styles[
                                                        rowData.newPrice !==
                                                        null
                                                            ? 'input-group-focus'
                                                            : 'input-group'
                                                    ]
                                                }
                                            >
                                                <InputGroup.Addon>
                                                    R$
                                                </InputGroup.Addon>
                                                <InfoCurrencyInput
                                                    name="novoPreco"
                                                    decimalSeparator=","
                                                    thousandSeparator="."
                                                    precision="2"
                                                    allowEmpty
                                                    allowNegative={false}
                                                    value={rowData.newPrice}
                                                    onChangeEvent={(
                                                        e,
                                                        value,
                                                    ) => {
                                                        onChangeNewPrice(
                                                            index,
                                                            value,
                                                            rowData,
                                                        );
                                                    }}
                                                    onBlur={(
                                                        _e,
                                                        _value,
                                                        details,
                                                    ) => {
                                                        onBlur(
                                                            rowData,
                                                            index,
                                                            details,
                                                        );
                                                    }}
                                                    disabled={disableInputs}
                                                />
                                            </InputGroup>

                                            <div
                                                className={
                                                    styles[
                                                        'novo-preco-sub-wrapper'
                                                    ]
                                                }
                                            >
                                                <Whisper
                                                    placement="auto"
                                                    trigger="hover"
                                                    speaker={
                                                        <Tooltip>
                                                            Margem objetiva
                                                            usada para calcular
                                                            o novo preço
                                                            estimado
                                                        </Tooltip>
                                                    }
                                                >
                                                    <FlagIcon
                                                        className={[
                                                            styles['flag-icon'],
                                                            'margin-right',
                                                        ].join(' ')}
                                                    />
                                                </Whisper>
                                                {rowData.newPriceMargin !==
                                                null ? (
                                                    <p
                                                        className={
                                                            styles[
                                                                'cell-subtext'
                                                            ]
                                                        }
                                                    >
                                                        {formatDecimal(
                                                            rowData.newPriceMargin,
                                                            1,
                                                        )}{' '}
                                                        %
                                                    </p>
                                                ) : (
                                                    '--'
                                                )}
                                                <Divider vertical />

                                                <Whisper
                                                    placement="auto"
                                                    trigger="hover"
                                                    speaker={
                                                        <Tooltip>
                                                            Competitividade do
                                                            novo preço
                                                        </Tooltip>
                                                    }
                                                >
                                                    <EmojiEventsIcon
                                                        className={[
                                                            styles[
                                                                'trophy-icon'
                                                            ],
                                                            'margin-right',
                                                        ].join(' ')}
                                                    />
                                                </Whisper>

                                                {rowData.newPriceCompetitors !==
                                                null ? (
                                                    <p
                                                        className={
                                                            styles[
                                                                'cell-subtext'
                                                            ]
                                                        }
                                                    >
                                                        {formatDecimal(
                                                            rowData.newPriceCompetitors,
                                                            1,
                                                        )}{' '}
                                                        %
                                                    </p>
                                                ) : (
                                                    '--'
                                                )}
                                            </div>
                                        </div>
                                    )}
                                </Cell>
                            </Column>
                            <Column sortable width={130}>
                                <HeaderCell>Preço vigente</HeaderCell>
                                <Cell dataKey="priceRetail">
                                    {(rowData, index) => (
                                        <div
                                            className={
                                                styles[
                                                    'preco-vigente-cell-wrapper'
                                                ]
                                            }
                                            onContextMenu={(e) => {
                                                handleContextMenu?.(
                                                    rowData,
                                                    index,
                                                    e,
                                                );
                                            }}
                                        >
                                            <p
                                                className={
                                                    styles['cell-main-text']
                                                }
                                            >
                                                R${' '}
                                                {rowData.retailPrice === null
                                                    ? '--'
                                                    : formatDecimal(
                                                          rowData.retailPrice,
                                                          2,
                                                      )}
                                            </p>

                                            <div
                                                className={
                                                    styles[
                                                        'preco-vigente-sub-wrapper'
                                                    ]
                                                }
                                            >
                                                <MoneyPercentageIndicator
                                                    value={
                                                        rowData.marginRetailPrice ||
                                                        null
                                                    }
                                                    newObjectiveMargin={
                                                        rowData?.newObjectiveMargin
                                                    }
                                                    tooltip={`Margem do preço vigente baseado no ${
                                                        rowData.newCost === 0 ||
                                                        rowData.newCost == null
                                                            ? 'custo atual'
                                                            : 'novo custo'
                                                    }`}
                                                />
                                                <Divider vertical />
                                                <Whisper
                                                    placement="auto"
                                                    trigger="hover"
                                                    speaker={
                                                        <Tooltip>
                                                            Competitividade do
                                                            preço vigente
                                                        </Tooltip>
                                                    }
                                                >
                                                    <EmojiEventsIcon
                                                        className={[
                                                            styles[
                                                                'trophy-icon'
                                                            ],
                                                            'margin-right',
                                                        ].join(' ')}
                                                    />
                                                </Whisper>
                                                {rowData.competitorsPrice !==
                                                null ? (
                                                    <p
                                                        className={
                                                            styles[
                                                                'cell-subtext'
                                                            ]
                                                        }
                                                    >
                                                        {formatDecimal(
                                                            (rowData.retailPrice /
                                                                rowData.competitorsPrice) *
                                                                100,
                                                            1,
                                                        )}{' '}
                                                        %
                                                    </p>
                                                ) : (
                                                    '--'
                                                )}
                                            </div>
                                        </div>
                                    )}
                                </Cell>
                            </Column>
                            <Column sortable width={130}>
                                <HeaderCell>
                                    <div>
                                        <p
                                            className={
                                                styles['table-header-text']
                                            }
                                        >
                                            Preço
                                        </p>
                                        <p
                                            className={
                                                styles['table-header-text']
                                            }
                                        >
                                            concorrência
                                        </p>
                                    </div>
                                </HeaderCell>
                                <Cell dataKey="competitorsPrice">
                                    {(rowData: RowDataProps, index: number) => (
                                        <div
                                            className={
                                                styles[
                                                    'preco-concorrencia-cell-wrapper'
                                                ]
                                            }
                                            onContextMenu={(e) => {
                                                handleContextMenu?.(
                                                    rowData,
                                                    index,
                                                    e,
                                                );
                                            }}
                                        >
                                            <div className={styles.flex}>
                                                <p
                                                    className={
                                                        styles['cell-main-text']
                                                    }
                                                >
                                                    R${' '}
                                                    {rowData.competitorsPrice !==
                                                    null
                                                        ? formatDecimal(
                                                              rowData.competitorsPrice,
                                                              2,
                                                          )
                                                        : '--'}
                                                </p>
                                                <span
                                                    className={
                                                        styles['left-icon']
                                                    }
                                                >
                                                    {rowData?.competitivenessIndicator ===
                                                    null
                                                        ? ''
                                                        : competitivenessIndicator(
                                                              rowData?.competitivenessIndicator,
                                                          )}
                                                </span>
                                            </div>

                                            <div
                                                className={
                                                    styles[
                                                        'preco-concorrencia-sub-wrapper'
                                                    ]
                                                }
                                            >
                                                <MoneyPercentageIndicator
                                                    value={
                                                        rowData.marginCompetitorsPrice ||
                                                        null
                                                    }
                                                    newObjectiveMargin={
                                                        rowData?.newObjectiveMargin
                                                    }
                                                    tooltip={`Margem do preço concorrente baseado no ${
                                                        rowData.newCost === 0 ||
                                                        rowData.newCost == null
                                                            ? 'custo atual'
                                                            : 'novo custo'
                                                    }`}
                                                />{' '}
                                                {rowData.competitorsPrice !==
                                                null ? (
                                                    <Button
                                                        className={
                                                            styles['btn-expand']
                                                        }
                                                        onClick={() => {
                                                            onExpandRow?.(
                                                                rowData,
                                                            );
                                                        }}
                                                    >
                                                        {hasItemInList(
                                                            rowData.productsToBePricedId,
                                                        ) ? (
                                                            <>
                                                                <ExpandLessIcon
                                                                    className={
                                                                        styles[
                                                                            'expand-icon'
                                                                        ]
                                                                    }
                                                                />
                                                                Fechar
                                                            </>
                                                        ) : (
                                                            <>
                                                                <ExpandMoreIcon
                                                                    className={
                                                                        styles[
                                                                            'expand-icon'
                                                                        ]
                                                                    }
                                                                />
                                                                Abrir
                                                            </>
                                                        )}
                                                    </Button>
                                                ) : null}
                                            </div>
                                        </div>
                                    )}
                                </Cell>
                            </Column>
                            <Column sortable width={116}>
                                <HeaderCell>
                                    <div
                                        className={
                                            styles['preco-ideal-wrapper']
                                        }
                                    >
                                        <p
                                            className={
                                                styles['table-header-text']
                                            }
                                        >
                                            Preço ideal
                                        </p>
                                        <p
                                            className={
                                                styles['table-header-text']
                                            }
                                        >
                                            de compra
                                        </p>

                                        <Whisper
                                            placement="auto"
                                            trigger="hover"
                                            speaker={
                                                <Tooltip>
                                                    Preço ideal de compra
                                                    baseado no preço
                                                    concorrência e margem
                                                    objetiva
                                                </Tooltip>
                                            }
                                        >
                                            <img
                                                src={infoIcon}
                                                className={styles['icon-info']}
                                                alt=""
                                            />
                                        </Whisper>
                                    </div>
                                </HeaderCell>
                                <Cell dataKey="precoIdealCompra">
                                    {(rowData: RowDataProps, index: number) => (
                                        <div
                                            onContextMenu={(e) => {
                                                handleContextMenu?.(
                                                    rowData,
                                                    index,
                                                    e,
                                                );
                                            }}
                                        >
                                            <BestBuyPriceIndicator
                                                data={rowData}
                                            />
                                            <LoadingSpinerArea
                                                area={`negotiation-edit-datapoint-${rowData.productsToBePricedId}`}
                                                size="sm"
                                                height="64px"
                                            />
                                        </div>
                                    )}
                                </Cell>
                            </Column>
                        </Table>
                    </ContextMenuTrigger>
                    {contextMenu && contextMenuItem && (
                        <ContextMenuContent>
                            {contextMenu.onUndoChanges &&
                                contextMenuItem?.isEdited && (
                                    <ContextMenuItem
                                        onClick={() => {
                                            contextMenu.onUndoChanges(
                                                contextMenuItem,
                                            );
                                        }}
                                    >
                                        Desfazer alteração de preço
                                    </ContextMenuItem>
                                )}
                        </ContextMenuContent>
                    )}
                </ContextMenu>

                <Pagination {...pagination} />
            </div>
        );
    },
);
