import React, { useEffect, useState } from 'react';
import { Table } from 'rsuite';

import styles from './styles.module.scss';

const { Column, HeaderCell, Cell } = Table;
const { Pagination } = Table;

const competitividadeColor = (value) => {
    switch (true) {
        case value < 84.99:
            return 'rgba(236, 91, 98, 0.5)';

        case value > 85 && value <= 94.99:
            return 'rgba(242, 148, 35, 0.5)';

        case value > 95 && value <= 104.99:
            return 'rgba(51, 171, 132, 0.5)';

        case value > 105 && value <= 114.99:
            return 'rgba(242, 148, 35, 0.5)';

        case value > 115:
            return 'rgba(236, 91, 98, 0.5)';

        default:
            break;
    }
};

export const DetailingPanelContentTable = ({
    data,
    setPage,
    loading,
    hasOpen,
    setSortList,
    firsColumnHeadTitle,
    firsColumnHeadDataKey,
}) => {
    const [reloadStyle, setReloadStyle] = useState({});
    const [sortColumn, setSortColumn] = useState('');
    const [sortType, setSortType] = useState('desc');
    const [tabelaProdutos, setTabelaProdutos] = useState(data?.content);

    const handleChangePage = (e) => {
        setPage(e - 1);
    };

    const handleSortColumn = (sortColumn, sortType) => {
        if (sortColumn && sortType) {
            setSortColumn(sortColumn);
            setSortType(sortType);
            setSortList(sortColumn, sortType);
        }
    };

    useEffect(() => {
        if (hasOpen) {
            setReloadStyle({ margin: '0 20px' });
            setTimeout(() => {
                setReloadStyle({ margin: '0 19px' });
            }, 100);
        }
    }, [hasOpen]);

    useEffect(() => {
        setTabelaProdutos(data?.content);
    }, [data]);

    return (
        <>
            <Table
                className={styles['detailing-panel-content-table']}
                autoHeight
                data={tabelaProdutos}
                bordered
                loading={loading}
                rowHeight={32}
                headerHeight={32}
                style={reloadStyle}
                sortColumn={sortColumn}
                sortType={sortType}
                onSortColumn={(sortColumn, sortType) =>
                    handleSortColumn(sortColumn, sortType)
                }
            >
                <Column flexGrow={3} width={300}>
                    <HeaderCell>{firsColumnHeadTitle}</HeaderCell>
                    <Cell dataKey={firsColumnHeadDataKey} />
                </Column>
                <Column flexGrow={1} sortable>
                    <HeaderCell>Preço referência</HeaderCell>
                    <Cell dataKey="precoReferencia">
                        {(rowData) => (
                            <span>
                                R$
                                {rowData.precoReferencia
                                    .toFixed(2)
                                    .toString()
                                    .replace('.', ',')}
                            </span>
                        )}
                    </Cell>
                </Column>
                <Column flexGrow={1} sortable>
                    <HeaderCell>Preço competidor</HeaderCell>
                    <Cell dataKey="precoCompetidor">
                        {(rowData) => (
                            <span>
                                R$
                                {rowData.precoCompetidor
                                    .toFixed(2)
                                    .toString()
                                    .replace('.', ',')}
                            </span>
                        )}
                    </Cell>
                </Column>
                <Column flexGrow={1} sortable>
                    <HeaderCell>Competitividade</HeaderCell>
                    <Cell dataKey="competitividade">
                        {(rowData) => (
                            <div
                                className={styles['last-cell']}
                                style={{
                                    background: competitividadeColor(
                                        (rowData.competitividade * 100).toFixed(
                                            2
                                        )
                                    ),
                                }}
                            >
                                {(rowData.competitividade * 100)
                                    .toFixed(1)
                                    .toString()
                                    .replace('.', ',')}
                                %
                            </div>
                        )}
                    </Cell>
                </Column>
            </Table>
            {data ? (
                <Pagination
                    className={styles['products-pagination']}
                    activePage={data.number + 1}
                    displayLength={data?.size}
                    showLengthMenu={false}
                    total={data?.totalElements}
                    onChangePage={handleChangePage}
                    renderTotal={() =>
                        `Exibindo ${data?.content?.length} de ${data?.totalElements} solicitações.`
                    }
                />
            ) : null}
        </>
    );
};
