import qs from 'qs';
import { trackPromise } from 'react-promise-tracker';

import api from '../../../utils/API';

export const getLimites = (params) => trackPromise(
        api({
            url: `${process.env.REACT_APP_IPA_URL}/limits/search/page`,
            method: 'GET',
            expectToken: true,
            params: {
                clientId: JSON.parse(localStorage.getItem('cliente'))?.codigo,
                size: params?.size || 20,
                page: params?.page || 0,
                ...(params.name && { name: params.name }),
                ...(params.limitType && { limitType: params.limitType }),
                ...(params.author && { authors: params.author }),
                ...(params.storeIds && { storeIds: params.storeIds }),
                ...(params.category && { segmentValue: params.category }),
                ...(params.productDescription && {
                    productId: params.productDescription,
                }),
                ...(params.sort && { sort: params.sort }),
            },
            paramsSerializer(params) {
                return qs.stringify(params, { arrayFormat: 'repeat' });
            },
        }).then((resp) => resp.data),
    );

export const saveLimite = (data) => trackPromise(
        api({
            url: `${process.env.REACT_APP_IPA_URL}/limits`,
            method: 'POST',
            expectToken: true,
            data: JSON.stringify(data),
        }).then((resp) => resp),
    );

export const updateLimite = (id, data) => trackPromise(
        api({
            url: `${process.env.REACT_APP_IPA_URL}/limits/${id}`,
            method: 'PUT',
            expectToken: true,
            data: JSON.stringify(data),
        }).then((resp) => resp),
    );

export const deleteLimite = (id) => trackPromise(
        api({
            url: `${process.env.REACT_APP_IPA_URL}/limits/${id}`,
            method: 'DELETE',
            expectToken: true,
        }).then((resp) => resp),
    );

export const getLimite = (id) => trackPromise(
        api({
            url: `${process.env.REACT_APP_IPA_URL}/limits/${id}`,
            method: 'GET',
            expectToken: true,
        }).then((resp) => resp.data),
    );

export const getAuthors = (params) => api({
        url: `${process.env.REACT_APP_IPA_URL}/limits/authors`,
        method: 'GET',
        expectToken: true,
        params: {
            clientId: JSON.parse(localStorage.getItem('cliente'))?.codigo,
            size: params.size ? params.size : 20,
            page: params.page ? params.page : 0,
            origin: 'AUTO_PRICE',
            author: params.author ? params.author : null,
        },
        paramsSerializer(params) {
            return qs.stringify(params, { arrayFormat: 'repeat' });
        },
    }).then((resp) => resp.data);

export const getLimitType = () => api({
        url: `${process.env.REACT_APP_IPA_URL}/limits/types`,
        method: 'GET',
        expectToken: true,
    }).then((resp) => resp.data);
