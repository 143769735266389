import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../@types/RootState';

const initialState = 0;

const slice = createSlice({
    name: 'PrecoAtacado/totalElements',
    initialState,
    reducers: {
        SET_PRECO_ATACADO_TOTAL_ELEMENTS: (
            _state,
            { payload }: PayloadAction<number>,
        ) => payload,
    },
});

export const selectorPrecoAtacadoTotalElements = (state: RootState) => {
    return state.precoAtacadoReducer.totalElements;
};

export const { SET_PRECO_ATACADO_TOTAL_ELEMENTS } = slice.actions;

export default slice.reducer;
