import React from 'react';
import { FlexboxGrid, Col, Button } from 'rsuite';

const WarningProductsOutOfList = ({
    plan, productsOutOfList, onShowModal, productsLength,
}) => (
    (plan === 'LIMIT' && productsOutOfList)
        ? (
            <FlexboxGrid className="warning-detalhes-produtos">
                <FlexboxGrid.Item componentClass={Col} lg={24} md={24} sm={24} xs={24}>
                    <p className="warning-detalhes-produtos__title">Atenção</p>
                </FlexboxGrid.Item>
                <FlexboxGrid.Item componentClass={Col} lg={24} md={24} sm={24} xs={24}>
                    <p>Os seguintes produtos estão fora da lista de produtos com detalhes disponíveis:</p>
                </FlexboxGrid.Item>
                <FlexboxGrid.Item componentClass={Col} lg={2} md={3} sm={4} xs={4}>
                    <Button appearance="link" onClick={onShowModal}>
                        -
                        {' '}
                        {productsLength}
                        {' '}
                        produtos
                    </Button>
                </FlexboxGrid.Item>
            </FlexboxGrid>
        ) : null
);

export default WarningProductsOutOfList;
