import React, { useState, createContext } from 'react';
import { withRouter } from 'react-router-dom';
import { Container, Content, FlexboxGrid } from 'rsuite';
// @ts-ignore
import { Filtros, Periodo } from '../Components/index.tsx';
import {
    MargemPraticadaPorLoja,
    ProdutosMenorMargemPraticada,
    RaioXProdutosMaisVendidos,
    GanhoEmMargemTotal,
    MargemBrutaRealizada,
    MargemPraticadaCategoria1,
    EvolucaoMargemPraticadaPorSemana,
    // @ts-ignore
} from './Components/index.tsx';

type FiltrosContext = {
    lojasSelecionadas: string[];
    produtosSelecionados: string[];
    periodoSelecionado: Date[];
    tipoLojasSelecionadas: string[];
    categoriaNivel1Selecionadas: string[];
    categoriaNivel2Selecionadas: string[];
}

export const FiltrosContextMargem = createContext<FiltrosContext>({
    lojasSelecionadas: [],
    produtosSelecionados: [],
    periodoSelecionado: [],
    tipoLojasSelecionadas: [],
    categoriaNivel1Selecionadas: [],
    categoriaNivel2Selecionadas: [],
});

const MargemPraticadaGeral: React.FC = () => {
    const [filtros, setFiltros] = useState({
        lojasSelecionadas: [],
        produtosSelecionados: [],
        periodoSelecionado: [],
        tipoLojasSelecionadas: [],
        categoriaNivel1Selecionadas: [],
        categoriaNivel2Selecionadas: [],
    });

    const changeFiltros = (value?: any, filtro?: string) => {
        if (value && filtro)
            setFiltros((prevState) => ({
                ...prevState,
                [filtro]: value,
            }));
        else
            setFiltros({
                lojasSelecionadas: [],
                produtosSelecionados: [],
                periodoSelecionado: [],
                tipoLojasSelecionadas: [],
                categoriaNivel1Selecionadas: [],
                categoriaNivel2Selecionadas: [],
            });
    };

    return (
        <Container className="main-container margem-praticada-geral-page">
            <Content className="no-margin-bottom page-header">
                <h2>Margem Praticada Geral</h2>
            </Content>
            <Filtros filtros={filtros} changeFiltros={changeFiltros} />
            <Periodo
                filtros={filtros}
                title="Resultado da margem bruta de venda"
            />
            <FiltrosContextMargem.Provider value={filtros}>
                <Container className="graficos-container">
                    <FlexboxGrid>
                        <FlexboxGrid.Item colspan={6}>
                            <FlexboxGrid>
                                <FlexboxGrid.Item colspan={24}>
                                    <MargemBrutaRealizada />
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item colspan={24}>
                                    <GanhoEmMargemTotal />
                                </FlexboxGrid.Item>
                            </FlexboxGrid>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={10}>
                            <EvolucaoMargemPraticadaPorSemana />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={8}>
                            <MargemPraticadaCategoria1 />
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                    <FlexboxGrid>
                        <FlexboxGrid.Item colspan={16}>
                            <ProdutosMenorMargemPraticada />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={8}>
                            <MargemPraticadaPorLoja />
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                    <FlexboxGrid>
                        <FlexboxGrid.Item colspan={24}>
                            <RaioXProdutosMaisVendidos />
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </Container>
            </FiltrosContextMargem.Provider>
        </Container>
    );
};

export default withRouter(MargemPraticadaGeral);
