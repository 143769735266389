export const ENTER_ADMIN_AREA = 'ENTER_ADMIN_AREA'
export const SET_ADMINISTRADOR = 'SET_ADMINISTRADOR'
export const SET_ADMINISTRADORES = 'SET_ADMINISTRADORES'

export function enterAdminArea(enterAdmin){
    return {
        type: ENTER_ADMIN_AREA,
        enterAdmin
    }
}

export function setAdministrador(administrador){
    return {
        type: SET_ADMINISTRADOR,
        administrador
    }
}

export function setAdministradores(administradores){
    return {
        type: SET_ADMINISTRADORES,
        administradores
    }
}