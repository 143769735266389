import { FILTER_OPTIONS, FilterProps } from '../../../hooks';

export const createFilterObject = (
    filterList: FILTER_OPTIONS[],
): FilterProps => {
    const filterObject: Partial<FilterProps> = {};

    filterList.forEach((filter) => {
        filterObject[filter] = {
            cache: [],
            value: [],
        };
    });

    return filterObject as FilterProps;
};
