import React, { Component } from "react";
import {
    FlexboxGrid,
    FormGroup,
    Icon,
    Col,
    IconButton,
    SelectPicker,
    Popover,
    Nav,
    Row,
    CheckPicker,
} from "rsuite";

export class InputFilter extends Component {

    render() {
        const { filter, index, handleDeleteFiltro, data, selectValue } = this.props;
        return (

            <FlexboxGrid.Item componentClass={Col} lg={filter.child ? 12 : 8} md={9} style={{ marginBottom: '5px' }}>
                <FlexboxGrid.Item componentClass={Col} lg={filter.child ? 10 : 21} md={21} sm={23} xs={23} style={{ marginBottom: '5px' }}>
                    <FormGroup>
                        {filter.multiple ?
                            <CheckPicker
                                className="inf-picker"
                                block
                                id={filter.id}
                                cleanable={filter.cleanable}
                                data={data[filter.name].data}
                                locale={filter.locale}
                                name={filter.name}
                                placeholder={filter.placeholder}
                                searchable={filter.searchable}
                                filter={filter.searchable}
                                value={data[filter.name].value}
                                onSelect={(value) => selectValue(filter.name, value)}
                                onClean={() => selectValue(filter.name, '')}
                            />
                            :
                            <SelectPicker
                                className="inf-picker"
                                block
                                id={filter.id}
                                cleanable={filter.cleanable}
                                data={data[filter.name].data}
                                locale={filter.locale}
                                name={filter.name}
                                placeholder={filter.placeholder}
                                searchable={filter.searchable}
                                value={data[filter.name].value}
                                onSelect={(value) => selectValue(filter.name, value)}
                                onClean={() => selectValue(filter.name, '')}
                            />
                        }
                    </FormGroup>
                </FlexboxGrid.Item>
                {filter.child ?
                    <FlexboxGrid.Item componentClass={Col} lg={11} md={7} sm={23} xs={23} style={{ marginBottom: '5px' }}>
                        <FormGroup>
                            <SelectPicker
                                className="inf-picker"
                                block
                                disabled={!data[filter.name].value}
                                id={filter.child.id}
                                cleanable={filter.child.cleanable}
                                data={data[filter.child.name].data}
                                locale={filter.child.locale}
                                name={filter.child.name}
                                placeholder={filter.child.placeholder}
                                searchable={filter.child.searchable}
                                value={data[filter.child.name].value}
                                onSelect={(value) => selectValue(filter.child.name, value)}
                                onClean={() => selectValue(filter.child.name, '')}
                            />
                        </FormGroup>
                    </FlexboxGrid.Item> : null
                }
                <FlexboxGrid.Item componentClass={Col} lg={3} md={2} style={{ marginBottom: '5px' }}>
                    <IconButton icon={<Icon icon="trash2" />} onClick={() => handleDeleteFiltro(index, filter.name)} />
                </FlexboxGrid.Item>
            </FlexboxGrid.Item>

        )
    }
};


const CustomNav = ({ title, icon, itens, onSelect, pacote, ...props }) => {
    return (
        <Nav {...props} vertical onSelect={onSelect} style={{ width: '100' }}>
            <div style={{ paddingLeft: '15px', color: '#1787e8', display: 'inline-flex' }}><Icon icon={icon}></Icon><p style={{ paddingLeft: '10px' }}>{title}</p></div>
            {itens.map((item) => {
                return (
                    <Nav.Item key={item.name} name={item.name} eventKey={item.name}>{item.title}</Nav.Item>
                )
            })}
        </Nav>
    );
};

export class MoreFilterDropdown extends Component {

    shouldComponentUpdate(nextProps) {
        return nextProps !== this.props;
    }

    handleClick = (eventKey, b) => {
        if (!this.props.selectedFilters.length || (this.props.selectedFilters.length && this.props.selectedFilters.findIndex(item => { return item.name === b.target.name }) === -1)) {
            this.props.addFilter(this.props.filters[this.props.filters.findIndex((item) => { return item.name === b.target.name })]);
        }
    }

    render() {
        return (
            <Popover {...this.props} >
                <Row style={{ width: '310px' }}>
                    {this.props.filtersType ? this.props.filtersType.map(item => (
                        <Col md={12} key={item.title} style={{ marginBottom: '15px' }}>
                            <CustomNav title={item.title} icon={item.icon} itens={item.itens} onSelect={this.handleClick} />
                        </Col>
                    )) : null}
                </Row>
            </Popover>
        )
    }
};
