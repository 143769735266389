import { format, parseISO } from 'date-fns';
import moment from 'moment';

export const filterTitle = ['PRODUTO', 'LOCAL', 'CANAL DE VENDA', 'PRODUTO CONCORRENTE'];

export const requestBodyNameItems = {
    canal: 'channel',
    subCanal: 'sub_channel',
    pagamentoType: 'payment_method',
};

export const ufToName = {
    AC: 'Acre',
    AL: 'Alagoas',
    AM: 'Amazonas',
    AP: 'Amapá',
    BA: 'Bahia',
    CE: 'Ceará',
    DF: 'Distrito Federal',
    ES: 'Espirito Santo',
    GO: 'Goias',
    MA: 'Maranhão',
    MG: 'Minas Gerais',
    MS: 'Mato Grosso Sul',
    MT: 'Mato Grosso',
    PA: 'Pará',
    PB: 'Paraiba',
    PE: 'Pernambuco',
    PI: 'Piaui',
    PR: 'Parana',
    RJ: 'Rio de Janeiro',
    RN: 'Rio Grande do Norte',
    RO: 'Rondônia',
    RR: 'Roraima',
    RS: 'Rio Grande do Sul',
    SC: 'Santa Catarina',
    SE: 'Sergipe',
    SP: 'São Paulo',
    TO: 'Tocantins',
};

export const paymentTypeName = {
    AVISTA: 'À vista',
    PRAZO30: '30 Dias',
    PRAZO45: '45 Dias',
    PRAZO60: '60 Dias',
};

export const columnType = {
    cenario: 'SCENARIO_NAME',
    produto: 'PRODUCT',
    local: 'LOCATION',
    canal: 'CHANNEL',
    preco: 'PRICE',
    margem: 'MARGIN',
    vendas: 'SALES',
    receita: 'REVENUE',
    lucro: 'PROFIT',
    responsavel: 'RESPONSIBLE',
};

export const locale = {
    noResultsText: 'Nenhum item encontrado',
    searchPlaceholder: 'Buscar item',
};

export const datePickerLocale = {
    sunday: 'Dom',
    monday: 'Seg',
    tuesday: 'Ter',
    wednesday: 'Qua',
    thursday: 'Qui',
    friday: 'Sex',
    saturday: 'Sáb',
    ok: 'OK',
};

export const dateShortcut = [
    {
        label: 'Últimos 7 dias',
        value: [moment().subtract(7, 'days').toDate(), moment().toDate()],
    },
    {
        label: 'Últimos 30 dias',
        value: [moment().subtract(30, 'days').toDate(), moment().toDate()],
    },
    {
        label: 'Últimos 90 dias',
        value: [moment().subtract(90, 'days').toDate(), moment().toDate()],
    },
];

export const filtersSelects = [
    {
        name: 'regional',
        placeholder: 'Região',
        selectType: 'check',
        onSelect: 'handleCheckSavedFilter',
    },
    {
        name: 'uf',
        placeholder: 'Estado',
        selectType: 'check',
        onSelect: 'handleCheckSavedFilter',
    },
    {
        name: 'channel',
        placeholder: 'Canal de venda',
        selectType: 'select',
        onSelect: 'handleSelectSavedFilter',
    },
    {
        name: 'responsible',
        placeholder: 'Responsável',
        selectType: 'select',
        onSelect: 'handleSelectSavedFilter',
    },
];

export const setLocalDateISOFormat = (date) => {
    if (date) {
        const tzoffset = date.getTimezoneOffset() * 60000;
        const localISOTime = new Date(date - tzoffset).toISOString().slice(0, -1);

        return localISOTime;
    }
};

export const formatNumberComma = (value: number) => value.toString().replace('.', ',');
export const formatPercent = (value: number) => (value * 100).toFixed(1).toString().replace('.', ',');
export const formatBRNumber = (value: number) => value.toString().replace(/[,.]/g, (match) => (match === ',' ? '.' : ','));
export const formatLocaleNumber = (value: number, digits: number) => value?.toLocaleString('pt-BR', { minimumFractionDigits: digits, maximumFractionDigits: digits });

export const formatNumberToCurrency = (value: number, digits: number) => {
    if (value) {
        return `R$ ${formatLocaleNumber(value, digits)}`;
    }
    return '-';
};

export const formatDate = (value: string) => format(new Date(parseISO(value)), 'dd/MM/yyyy');

export const limitString = (value: string, limit: number) => {
    if (value?.length > limit) {
      return `${value.slice(0, limit - 3)}...`;
    }
      return value;
  };

export const formatIndicatorValue = (value: number) => {
    if (value) {
        return value * 100;
    }
    return 0;
};

export const limitTitleText = (text: string, dataLength: number) => {
    if (dataLength === 3) {
        return text.slice(0, 40) + (text.length <= 40 ? '' : '...');
    }

    if (dataLength === 4) {
        return text.slice(0, 25) + (text.length <= 25 ? '' : '...');
    }

    return text;
};

export const limitCompetitorText = (text: string, dataLength: number) => {
    if (dataLength === 3) {
        return text.slice(0, 35) + (text.length <= 40 ? '' : '...');
    }

    if (dataLength === 4) {
        return text.slice(0, 23) + (text.length <= 25 ? '' : '...');
    }

    return text;
};

export const paymentTypeText = (type: string) => {
    const text = {
        'À Vista': 'À vista (+0,0%)',
        '30 Dias': '30 dias (+ 2%)',
        '45 Dias': '45 dias (+ 3%)',
        '60 Dias': '60 dias (+ 4%)',
    };

    return text[type];
};
