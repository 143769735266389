import React, { Component, LegacyRef, forwardRef } from 'react';
// @ts-ignore
import classNames from 'classnames';
import { capitalize } from 'lodash';
import { Col, Container, Content, Divider, FlexboxGrid, Modal } from 'rsuite';
import { ModalProps } from 'rsuite/lib/Modal';
import { Nullable } from '../../../../../@types/Nullable';
import { ButtonPrimary } from '../../../../../components';

type Mechanism = {
    mechanismType?: string | null;
    factor?: number | null;
    minimumVariation?: number | null;
    priceCompetitorIndicator?: number | null;
};

export type RuleCompositionProps = {
    ruleId?: string | null;
    name?: string | null;
    productFamily?: boolean | null;
    recurrence?: {
        startingDate?: string | null;
        recurrenceType?: string | null;
        expirationDate?: string | null;
        daysOfWeek?: string[] | null;
        lastSuggestionPrice?: string | null;
        nextSuggestionPrice?: string | null;
    } | null;
    author?: string | null;
    mechanism: Partial<Nullable<Mechanism>>;
    additionalMechanism: Partial<Nullable<Mechanism>>;
};

export type CompositionRuleModalProps = ModalProps & {
    ruleComposition: RuleCompositionProps;
};
export const CompositionRuleModal = forwardRef(
    (
        {
            show = true,
            ruleComposition,
            history,
            ...props
        }: CompositionRuleModalProps,
        ref: LegacyRef<Component<ModalProps, unknown, unknown>>,
    ) => {
        return (
            <Modal
                className="modal-composicao-regras info-modal"
                show={show}
                ref={ref}
                {...props}
            >
                <Container className="info-modal__container">
                    <Modal.Header className="info-modal__header">
                        <Modal.Title className="info-modal__header__title">
                            Resumo da regra aplicada
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="info-modal__body">
                        <Content className="info-modal__content">
                            <FlexboxGrid className="flex-container">
                                <FlexboxGrid.Item
                                    componentClass={Col}
                                    lg={24}
                                    md={24}
                                    xs={24}
                                    className="box-rule-name"
                                >
                                    <h4 className="font-size-50-bold">
                                        NOME DA REGRA
                                    </h4>
                                    <p>
                                        {ruleComposition?.name ?? (
                                            <span className="font-size-50-bold">
                                                --
                                            </span>
                                        )}
                                    </p>
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item
                                    componentClass={Col}
                                    lg={12}
                                    md={12}
                                    xs={12}
                                    className="box-rule-type"
                                >
                                    <h4 className="font-size-50-bold">
                                        TIPO DA REGRA
                                    </h4>
                                    <p>
                                        {ruleComposition?.mechanism
                                            ?.mechanismType ?? (
                                            <span className="font-size-50-bold">
                                                --
                                            </span>
                                        )}
                                    </p>
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item
                                    componentClass={Col}
                                    lg={12}
                                    md={12}
                                    xs={12}
                                    className={classNames(
                                        !ruleComposition?.additionalMechanism
                                            ?.factor && 'box-factor',
                                    )}
                                >
                                    <h4 className="font-size-50-bold">
                                        {`% DE ${ruleComposition?.mechanism?.mechanismType}`}
                                    </h4>
                                    <p className="description-area">
                                        {ruleComposition?.mechanism?.factor ? (
                                            `${ruleComposition?.mechanism?.factor}%`
                                        ) : (
                                            <span className="font-size-50-bold">
                                                --
                                            </span>
                                        )}
                                    </p>
                                </FlexboxGrid.Item>
                                {ruleComposition?.additionalMechanism
                                    ?.mechanismType && (
                                    <>
                                        <FlexboxGrid.Item
                                            componentClass={Col}
                                            lg={12}
                                            md={12}
                                            xs={12}
                                            className="box-rule-type"
                                        >
                                            <h4 className="font-size-50-bold">
                                                REGRA ADICIONAL
                                            </h4>
                                            <p>
                                                {capitalize(
                                                    ruleComposition
                                                        ?.additionalMechanism
                                                        .mechanismType,
                                                )}
                                            </p>
                                        </FlexboxGrid.Item>
                                        <FlexboxGrid.Item
                                            componentClass={Col}
                                            lg={12}
                                            md={12}
                                            xs={12}
                                            className={classNames(
                                                ruleComposition
                                                    ?.additionalMechanism
                                                    ?.factor && 'box-factor',
                                            )}
                                        >
                                            <h4 className="font-size-50-bold">
                                                {`% DE ${ruleComposition?.additionalMechanism?.mechanismType}`}
                                            </h4>
                                            <p className="description-area">
                                                {ruleComposition
                                                    ?.additionalMechanism
                                                    ?.factor ? (
                                                    `${ruleComposition?.additionalMechanism?.factor}%`
                                                ) : (
                                                    <span className="font-size-50-bold">
                                                        --
                                                    </span>
                                                )}
                                            </p>
                                        </FlexboxGrid.Item>
                                    </>
                                )}

                                <Divider className="divider-composition-modal" />
                                <FlexboxGrid.Item
                                    componentClass={Col}
                                    lg={24}
                                    md={24}
                                    xs={24}
                                    className="box-competitive-price"
                                >
                                    <h4 className="font-size-50-bold">
                                        PREÇO CONCORRENTE
                                    </h4>
                                    <p>
                                        {ruleComposition?.mechanism
                                            ?.priceCompetitorIndicator ?? (
                                            <span className="font-size-50-bold">
                                                --
                                            </span>
                                        )}
                                    </p>
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item
                                    componentClass={Col}
                                    lg={12}
                                    md={12}
                                    xs={12}
                                    className="box-min-variation"
                                >
                                    <h4 className="font-size-50-bold">
                                        VARIAÇÃO MÍNIMA
                                    </h4>
                                    <p>
                                        {`${ruleComposition?.mechanism?.minimumVariation}
                                            %` ?? (
                                            <span className="font-size-50-bold">
                                                --
                                            </span>
                                        )}
                                    </p>
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item
                                    componentClass={Col}
                                    lg={12}
                                    md={12}
                                    xs={12}
                                    className="box-family"
                                >
                                    <h4 className="font-size-50-bold">
                                        CONSIDERA FAMÍLIA?
                                    </h4>
                                    <p>
                                        {ruleComposition?.productFamily !==
                                        undefined ? (
                                            ruleComposition.productFamily ? (
                                                'Sim'
                                            ) : (
                                                'Não'
                                            )
                                        ) : (
                                            <span className="font-size-50-bold">
                                                --
                                            </span>
                                        )}
                                    </p>
                                </FlexboxGrid.Item>
                                <Divider className="divider-composition-modal" />
                                <FlexboxGrid.Item
                                    componentClass={Col}
                                    lg={12}
                                    md={12}
                                    xs={12}
                                    className="box-frequency"
                                >
                                    <h4 className="font-size-50-bold">
                                        FREQUÊNCIA
                                    </h4>
                                    <p>
                                        {ruleComposition?.recurrence
                                            ?.recurrenceType ?? (
                                            <span className="font-size-50-bold">
                                                --
                                            </span>
                                        )}
                                    </p>
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item
                                    componentClass={Col}
                                    lg={12}
                                    md={12}
                                    xs={12}
                                    className="box-days-of-week"
                                >
                                    <h4 className="font-size-50-bold">
                                        DIA DA SEMANA
                                    </h4>
                                    <p>
                                        {ruleComposition?.recurrence
                                            ?.daysOfWeek ? (
                                            ruleComposition.recurrence.daysOfWeek.join(
                                                ', ',
                                            )
                                        ) : (
                                            <span className="font-size-50-bold">
                                                --
                                            </span>
                                        )}
                                    </p>
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item
                                    componentClass={Col}
                                    lg={12}
                                    md={12}
                                    xs={12}
                                    className="box-last-suggestion-price"
                                >
                                    <h4 className="font-size-50-bold">
                                        ÚLTIMA SUGESTÃO DE PREÇO
                                    </h4>
                                    <p>
                                        {ruleComposition?.recurrence
                                            ?.lastSuggestionPrice ?? (
                                            <span className="font-size-50-bold">
                                                --
                                            </span>
                                        )}
                                    </p>
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item
                                    componentClass={Col}
                                    lg={12}
                                    md={12}
                                    xs={12}
                                    className="box-next-suggestion-price"
                                >
                                    <h4 className="font-size-50-bold">
                                        PROXÍMA SUGESTÃO DE PREÇO
                                    </h4>
                                    <p>
                                        {ruleComposition?.recurrence
                                            ?.nextSuggestionPrice ?? (
                                            <span className="font-size-50-bold">
                                                --
                                            </span>
                                        )}
                                    </p>
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item
                                    componentClass={Col}
                                    lg={12}
                                    md={12}
                                    xs={12}
                                    className="box-starting-date"
                                >
                                    <h4 className="font-size-50-bold">
                                        INÍCIO DA VIGÊNCIA
                                    </h4>
                                    <p>
                                        {ruleComposition?.recurrence
                                            ?.startingDate ?? (
                                            <span className="font-size-50-bold">
                                                --
                                            </span>
                                        )}
                                    </p>
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item
                                    componentClass={Col}
                                    lg={12}
                                    md={12}
                                    xs={12}
                                    className="box-expiration-date"
                                >
                                    <h4 className="font-size-50-bold">
                                        FINAL DA VIGÊNCIA
                                    </h4>
                                    <p>
                                        {ruleComposition?.recurrence
                                            ?.expirationDate ?? (
                                            <span className="font-size-50-bold">
                                                --
                                            </span>
                                        )}
                                    </p>
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item
                                    componentClass={Col}
                                    lg={24}
                                    md={24}
                                    xs={24}
                                    className="box-author"
                                >
                                    <h4 className="font-size-50-bold">
                                        RESPONSÁVEL
                                    </h4>
                                    <p>
                                        {ruleComposition?.author ?? (
                                            <span className="font-size-50-bold">
                                                --
                                            </span>
                                        )}
                                    </p>
                                </FlexboxGrid.Item>
                            </FlexboxGrid>
                        </Content>
                    </Modal.Body>
                    <Modal.Footer className="box-buttons">
                        <ButtonPrimary
                            theme="ghost"
                            skin="blue"
                            onClick={props.onHide}
                            className="button-close"
                        >
                            FECHAR
                        </ButtonPrimary>
                        <ButtonPrimary
                            skin="blue"
                            onClick={() =>
                                window.open(
                                    `${window.location.origin}/ipa/regras-preco/${ruleComposition?.ruleId}`,
                                    '_blank',
                                )
                            }
                            className="button-see-rule"
                        >
                            VER REGRA
                        </ButtonPrimary>
                    </Modal.Footer>
                </Container>
            </Modal>
        );
    },
);
