import DeleteIcon from '@material-ui/icons/Delete';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Button,
    Content,
    Alert,
} from 'rsuite';
import { selectorCalculadoraPrevisaoDemanda } from '../../../../reducers/calculadoraPrevisaoDemanda';
import {
    setSavedScenarioState,
} from '../../../../reducers/calculadoraPrevisaoDemanda/savedScenario';
import { NotificationComponent } from '../NotificationComponent';
import { ModalDeleteScenario } from '../ModalDeleteScenario';
import DownloadIcon from '../../../../assets/icons/icon_download_gray600.svg';
import { useTableActions, useCompareScenarios } from '../../hooks';
import * as service from '../../services';

import styles from './SavedScenarioCompare.module.scss';

export const SavedScenarioCompare = () => {
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const { savedScenario, savedFiltros } = useSelector(selectorCalculadoraPrevisaoDemanda);
    const itemsSelected = savedScenario.selectedScenarioItemList.length;

    const dispatch = useDispatch();
    const { tableHandlers } = useTableActions();
    const { compareHandlers } = useCompareScenarios();

    const handleClearSelection = () => {
        dispatch(setSavedScenarioState({
            name: 'selectedScenarioItemList',
            data: [],
        }));
    };

    const handleShowDeleteModal = async () => {
        if (itemsSelected === 1) {
            setShowDeleteModal(true);
        } else {
            Alert.warning('Para remover um item da tabela, selecione 1 cenário por vez.', 5000);
        }
    };

    const handleDeleteScenario = async () => {
        const params = {
            page: 0,
            size: 10,
        };
        const id = savedScenario.selectedScenarioItemList[0];
        const response = await service.requestScenariosByID('DELETE', id, '');
        if (response) {
            setShowDeleteModal(false);
            NotificationComponent('Cenário removido com sucesso!');
            tableHandlers.getTableList(params, savedFiltros.savedFilterRequestBody);
            handleClearSelection();
        }
    };

    const handleDownloadScenario = () => {
        const params = savedScenario.selectedScenarioItemList;
        service.scenariosDownload('GET', params, '');
    };

    return (
        <Content className={[
            styles['saved-compare-content'],
            itemsSelected ? styles.selected : ''].join(' ')}
        >
            <div className={styles.flex}>
                {savedScenario.selectedScenarioItemList.length ? (
                    <div className={styles.flex}>
                        <span className={styles['bold-text']}>
                            {savedScenario.selectedScenarioItemList.length}
                            {' '}
                            produtos selecionados
                        </span>
                        {' '}
                        <Button
                            className={styles['btn-clear']}
                            onClick={handleClearSelection}
                        >
                            Limpar seleção
                        </Button>
                    </div>
                ) : (
                    <div>
                        <span className={styles['regular-text']}>Foram encontrados</span>
                        {' '}
                        <span className={[styles['bold-text'], styles.margin].join(' ')}>
                            {savedScenario.savedScenarioData?.totalElements}
                            {' '}
                            resultados
                        </span>
                        {' '}
                        <span className={styles['regular-text']}>no total</span>
                    </div>
                )}

                <div className={[styles.flex, styles.margin].join(' ')}>
                    <Button
                        className={styles['btn-gray']}
                        disabled={!itemsSelected}
                        onClick={handleDownloadScenario}
                    >
                        <img src={DownloadIcon} alt="" />
                    </Button>
                    <Button
                        className={styles['btn-gray']}
                        disabled={!itemsSelected}
                        onClick={handleShowDeleteModal}
                    >
                        <DeleteIcon className={styles['icon-delete']} />
                    </Button>
                    <Button
                        appearance="primary"
                        className={styles['btn-compare']}
                        // disabled={disabled}
                        onClick={compareHandlers.compareScenario}
                    >
                        COMPARAR
                    </Button>
                </div>
            </div>
            <ModalDeleteScenario
                show={showDeleteModal}
                onClickDelete={handleDeleteScenario}
                onHide={() => setShowDeleteModal(false)}
            />
        </Content>
  );
};
