import React from 'react';
import arrowRightIcon from '../../../../assets/icons/icon_arrow-right-gray.svg';
import { LoadingMenu } from '../../../../components/LoadingSpinner';
import SelectPill from '../../../../components/SelectPill';
import styles from './ProductFilter.module.scss';

type ProductFilterProps = {
    productHandlers?: any;
    dataSecond?: any;
    valueSecond?: any;
}

export const ProductFilter = ({
    dataSecond,
    valueSecond,
    productHandlers,
}: ProductFilterProps) => (
    <div className={styles['select-pill-wrapper']}>

        {/* <SelectPill
                type="select"
                id="select-picker-pill"
                filter={false}
                placeholder="Produto"
                name="second"
                searchable={false}
                data={productTypeData}
                value={1}
                onSelect={onSelectFirst}
                onOpen={onOpenFirst}
                // onClean={onCleanReference}
            /> */}
        <div className={styles['div-pill']}>Produto</div>
        <img
            src={arrowRightIcon}
            alt=""
            className={styles['arrow-right-icon']}
        />
        <SelectPill
            type="select"
            id="select-picker-pill"
            placeholder="Selecione um produto"
            name="second"
            value={valueSecond || null}
            data={dataSecond}
            onSearch={(value) => productHandlers.onSearchProducts('produtos', value)}
            onSelect={productHandlers.handleSelectProduct}
            onOpen={() => productHandlers.onSearchProducts('produtos', '')}
            onClean={productHandlers.handleClearProducts}
            locale={{
                    noResultsText: 'Nenhum item encontrado',
                    searchPlaceholder: 'Buscar item',
                }}
            renderMenu={(menu) => (
                <span>
                    <LoadingMenu area="scenario-filter" />
                    {menu}
                </span>
                )}
        />
    </div>
    );
