import React from 'react';
import { Notification } from 'rsuite';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import styles from './NotificationMessage.module.scss';

export const NotificationMessage = (type: string, duration: number, message: string) => {
    const style = {
        success: 'notification-success',
        error: 'notification-error',
    };

    const iconType = {
        success: <CheckCircleIcon className={styles['success-icon']} />,
        error: <ErrorIcon className={styles['error-icon']} />,
    };

    Notification.open({
        key: 'notification-message',
        className: styles[style[type]],
        duration,
        description: (
            <>
                {iconType[type]}
                <p>{message}</p>
            </>
        ),
    });
};
