export const colunasTabelaCampanhas = [
    {
        sortable: true,
        align: 'left',
        fixed: false,
        headerCell: 'Nome Campanha',
        dataKey: 'name',
        flexGrow: 3,
    },
    {
        sortable: true,
        align: 'center',
        fixed: false,
        headerCell: 'Data edição',
        dataKey: 'update_date',
        flexGrow: 1,
    },
    {
        sortable: true,
        align: 'center',
        fixed: false,
        headerCell: 'Data início',
        dataKey: 'starting_date',
        flexGrow: 1,
    },
    {
        sortable: true,
        align: 'center',
        fixed: false,
        headerCell: 'Data fim',
        dataKey: 'expiration_date',
        flexGrow: 1,
    },
    {
        sortable: true,
        align: 'left',
        fixed: false,
        headerCell: 'Criador',
        dataKey: 'author',
        flexGrow: 2,
    },
    {
        sortable: true,
        align: 'left',
        fixed: false,
        headerCell: 'Status',
        dataKey: 'status',
        flexGrow: 2,
    },
    {
        sortable: false,
        align: 'left',
        fixed: 'right',
        headerCell: '',
        dataKey: '',
        width: 120,
    },
];
