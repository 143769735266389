export const modalTabelaDadosExtracaoHeader = ['Nome da coluna na extração', 'Descrição do campo', 'Origem da informação'];

export const modalTabelaDadosExtracao = [
    {
        name: 'Loja cliente',
        description: 'Identificador da loja cadastrado para o cliente',
        origin: 'InfoPrice',
    },
    {
        name: 'Descrição loja cliente',
        description: 'Descrição da loja cadastrada para o cliente',
        origin: 'InfoPrice',
    },
    {
        name: 'UF',
        description: 'Informação cadastral da loja',
        origin: 'InfoPrice',
    },
    {
        name: 'Cidade',
        description: 'Informação cadastral da loja',
        origin: 'InfoPrice',
    },
    {
        name: 'CEP',
        description: 'Informação cadastral da loja',
        origin: 'InfoPrice',
    },
    {
        name: 'Logradouro',
        description: 'Informação cadastral da loja',
        origin: 'InfoPrice',
    },
    {
        name: 'Número',
        description: 'Informação cadastral da loja',
        origin: 'InfoPrice',
    },
    {
        name: 'Produto',
        description: 'Identificador do produto cadastrado para o cliente',
        origin: 'InfoPrice',
    },
    {
        name: 'Código do barras',
        description: 'Código EAN do produto (só existe para gtins)',
        origin: 'InfoPrice',
    },
    {
        name: 'Descrição do produto',
        description: 'Informação cadastral do produto',
        origin: 'InfoPrice',
    },
    {
        name: 'Seção',
        description: 'Informação cadastral do produto',
        origin: 'InfoPrice',
    },
    {
        name: 'Categoria',
        description: 'Informação cadastral do produto',
        origin: 'InfoPrice',
    },
    {
        name: 'Escopo',
        description: 'Informação cadastral do produto',
        origin: 'InfoPrice',
    },
    {
        name: 'Tipo de solicitação',
        description: 'Informação cadastral do produto',
        origin: 'InfoPrice',
    },
    {
        name: 'Fonte',
        description: 'Nome do app',
        origin: 'Site',
    },
    {
        name: 'Data',
        description: 'Data de emissão',
        origin: 'Site',
    },
    {
        name: 'Nome da loja',
        description: 'Nome fantasia da loja emissora da nota',
        origin: 'Site',
    },
    {
        name: 'Preço atacado',
        description: 'Valor atacado praticado',
        origin: 'Site',
    },
    {
        name: 'Preço por',
        description: 'Valor praticado',
        origin: 'Site',
    },
    {
        name: 'Preço de',
        description: 'Valor normal do produto sem descontos ou promoções',
        origin: 'Site',
    },
    {
        name: 'Desconto',
        description: 'Valor do desconto aplicado ao produto',
        origin: 'Site',
    },
    {
        name: 'Gatilho',
        description: 'Trigger para o preço atacado',
        origin: 'Site',
    },
    {
        name: 'Unidades por caixa',
        description: 'Unidades por caixa',
        origin: 'Site',
    },
    // {
    //     name: 'URL',
    //     description: 'URL do link da compra',
    //     origin: 'Site',
    // },
    // {
    //     name: 'URL da foto',
    //     description: 'URL da foto do produto',
    //     origin: 'Site',
    // },
    {
        name: 'Escopo',
        description: 'Informação do escopo do cliente',
        origin: 'InfoPrice',
    },
    {
        name: 'Tipo de solicitação',
        description: 'Informação do escopo do cliente',
        origin: 'InfoPrice',
    },

];

export const setWidth = (coluna) => {
    if (coluna.descricao.length <= 12) {
        return 150;
    }

    if (coluna.descricao.length > 12 && coluna.descricao.length < 20) {
        return 170;
    }

    if (coluna.descricao.length > 20) {
        return 230;
    }

    return 250;
};

export const parseBoolean = (item) => {
    if (item === false) {
        return 'FALSO';
    }
    if (item === true) {
        return 'VERDADEIRO';
    }
    if (item === null) {
        return null;
    }
};
