import React, { Children } from 'react';
import {
    Icon,
    Table,
    Tooltip,
    Whisper,
} from 'rsuite';

const { Column, HeaderCell, Cell } = Table;

const CompetitorsTable = ({ data, dataColumn, bodyRef }) => (
    <Table
        bodyRef={bodyRef}
        data={data}
        autoHeight
        bordered
        headerHeight={55}
        className="table-competitors"
    >
        {dataColumn
            ? Children.toArray(
                dataColumn.map((column) => (
                    <Column flexGrow={column.flexGrow} width={column.width}>
                        <HeaderCell>
                            {column.header}
                            {column.tooltip !== null ? (
                                <Whisper
                                    speaker={
                                        <Tooltip>{column.tooltip}</Tooltip>
                                    }
                                >
                                    <span style={{ marginLeft: '6px' }}>
                                        <Icon icon="info" />
                                    </span>
                                </Whisper>
                            ) : null}
                        </HeaderCell>
                        {column.cellContent !== null ? (
                            <Cell dataKey={column.dataKey}>
                                {column.cellContent}
                            </Cell>
                        ) : (
                            <Cell dataKey={column.dataKey} />
                        )}
                    </Column>
                )),
            )
            : null}
    </Table>
);

export default CompetitorsTable;
