import React, { Component } from "react";
import { bindActionCreators } from 'redux';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { Table, Container, Content, FlexboxGrid, IconButton, Icon, Col, Input, Button, Alert, InputGroup } from 'rsuite';
import CurrencyFormat from 'react-currency-format';

import ModalConfirmacao from "../components/ModalConfirmacao";
import { LoadingSpiner } from '../components/LoadingSpinner';
import { deleteRegra } from '../services/RegrasService';


const { Column, HeaderCell, Cell, Pagination } = Table;


class RegrasPrecoAtacadoPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [],

        }


    }

    componentDidMount() {
        this.searchRegras();
    }



    searchRegras(query) {

    }

    handleChangePage = (e) => {
        this.setState({
            page: e - 1
        }, this.searchRegras)
    }

    handleChangeLength = (e) => {
        this.setState({
            size: e
        }, this.searchRegras)
    }



    handleChangeValue(value, name) {
        this.setState({
            [name]: value
        }, this.searchRegras)
    }


    handleEdit(regra) {
        this.props.history.push('/ipa/regras-atacado/' + regra.ruleId)
    }


    goNovaRegra = () => {
        this.props.history.push('/ipa/regras-atacado/cadastro')
    }

    handleDeleteRegra(regra) {
        this.setState({
            deleteMessage: "Você está tentando deletar uma regra de atacado. Você confirma essa ação?",
            showModalConfirmacaoExclusao: true,
            regraToDelete: regra
        })
    }

    cancelExclusao() {
        this.setState({
            showModalConfirmacaoExclusao: false,
            deleteMessage: '',
            regraToDelete: null
        })
    }

    confirmExclusao() {
        deleteRegra(this.state.regraToDelete.ruleId).then(response => {
            Alert.success('Regra de atacado deletada com sucesso');
            this.setState({
                showModalConfirmacaoExclusao: false,
                deleteMessage: '',
                regraToDelete: null
            })

            this.searchRegras();
        })
    }

    handleSearch(query) {
        this.searchRegras(query);
    }

    render() {

        const { showModalConfirmacaoExclusao, deleteMessage, data } = this.state;

        return (
            <Container className="main-container regra-atacado-page">
                <Content>
                    <FlexboxGrid>
                        <FlexboxGrid.Item colspan={24}>
                            <FlexboxGrid>
                                <FlexboxGrid.Item componentClass={Col} lg={21} md={20} sm={24} xs={24}>
                                    <InputGroup>
                                        <InputGroup.Addon>
                                            <Icon icon="search" />
                                        </InputGroup.Addon>
                                        <Input onChange={this.handleSearch} placeholder="Procure por nome, segmento ou criador" />
                                    </InputGroup>
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item componentClass={Col} lg={3} md={4} sm={24} xs={24}>
                                    <Button title="Nova regra atacado" block onClick={this.goNovaRegra} appearance="primary">Nova regra atacado</Button>
                                </FlexboxGrid.Item>
                            </FlexboxGrid>

                            <LoadingSpiner size="md" />

                            <FlexboxGrid>
                                <FlexboxGrid.Item colspan={24}>
                                    <Table
                                        data={data.content}
                                        autoHeight
                                        className="table"
                                    >
                                        <Column flexGrow={2}>
                                            <HeaderCell>Nome</HeaderCell>
                                            <Cell dataKey="name" />
                                        </Column>
                                        <Column flexGrow={3}>
                                            <HeaderCell>Segmentos</HeaderCell>
                                            <Cell>

                                            </Cell>
                                        </Column>
                                        <Column flexGrow={1} >
                                            <HeaderCell>Atacado</HeaderCell>
                                            <Cell>
                                                {rowData => (
                                                    <CurrencyFormat value={rowData.mechanism.factor * 100 || 0} fixedDecimalScale={true} decimalScale={2} displayType={'text'} suffix={'%'} decimalSeparator={','} />
                                                )}
                                            </Cell>
                                        </Column>
                                        <Column flexGrow={2} >
                                            <HeaderCell>Criador</HeaderCell>
                                            <Cell dataKey="author" />
                                        </Column>
                                        <Column width={120} fixed="right">
                                            <HeaderCell></HeaderCell>

                                            <Cell className="table__edit-button">
                                                {rowData => {
                                                    return (
                                                        <span className="mg-5">
                                                            <IconButton title="Editar" onClick={() => this.handleEdit(rowData)} icon={<Icon icon="edit2" />} size="md" />
                                                            <IconButton title="Deletar" onClick={() => this.handleDeleteRegra(rowData)} size="md" icon={<Icon icon="trash2" />} />
                                                        </span>
                                                    );
                                                }}
                                            </Cell>
                                        </Column>
                                    </Table>
                                    <Pagination
                                        lengthMenu={[
                                            {
                                                value: 20,
                                                label: 20
                                            },
                                            {
                                                value: 40,
                                                label: 40
                                            }
                                        ]}
                                        activePage={data.number + 1}
                                        displayLength={data.numberOfElements}
                                        total={data.totalElements}
                                        onChangePage={this.handleChangePage}
                                        onChangeLength={this.handleChangeLength}
                                    />
                                </FlexboxGrid.Item>
                            </FlexboxGrid>
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </Content>
                <ModalConfirmacao show={showModalConfirmacaoExclusao} onConfirm={this.confirmExclusao} onCancel={this.cancelExclusao} title={'Deletar regra'} message={deleteMessage} confirmButton={'Confirmar'} cancelButton={'Cancelar'} />
            </Container>
        )
    }
}


const mapStateToProps = store => ({

})

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {

        }, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RegrasPrecoAtacadoPage));
