import { trackPromise } from 'react-promise-tracker';
import api from '../utils/API';

export const getAnalises = () => api({
    url: `${process.env.REACT_APP_CADASTROS_API_URL}/analises/usuario`,
    method: 'GET',
    expectToken: true,
}).then((resp) => resp.data);

export const getAnalise = (codigo) => api({
    url: `${process.env.REACT_APP_CADASTROS_API_URL}/analises/${codigo}`,
    method: 'GET',
    expectToken: true,
}).then((resp) => resp.data);

export const getAnaliseToken = (workspace, relatorio) => api({
    url: `${process.env.REACT_APP_CADASTROS_API_URL}/analises/token/${workspace}/${relatorio}`,
    method: 'GET',
    expectToken: true,
}).then((resp) => resp.data);

export const getGraficos = () => api({
    url: `${process.env.REACT_APP_CADASTROS_API_URL}/analises/graficos/usuario`,
    method: 'GET',
    expectToken: true,
}).then((resp) => resp.data);

export const getGrafico = (codigo) => trackPromise(
    api({
        url: `${process.env.REACT_APP_CADASTROS_API_URL}/analises/graficos/embed/${codigo}`,
        method: 'GET',
        expectToken: true,
    }).then((resp) => resp.data),
);

export const clienteHasAnalises = () => api({
    url: `${process.env.REACT_APP_CADASTROS_API_URL}/analises/clienteHabilitado`,
    method: 'GET',
    expectToken: true,
}).then((resp) => resp.data);
