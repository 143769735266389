import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    FlexboxGrid,
    FormGroup,
    HelpBlock,
    Icon,
    Whisper,
    Tooltip,
    Col,
    IconButton,
    Tag,
    SelectPicker,
    CheckPicker,
} from 'rsuite';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

import { Redeem } from '@material-ui/icons';
import {
    selectValueNoCache,
    searchEndereco,
    getLatLon,
    setErro,
    getItensFiltro,
    setSingleReducer,
    changeNoCacheValue,
} from '../../../../../actions/actionsPainelGeral';

import { LoadingMenu } from '../../../../../components/LoadingSpinner';
import volumeBarBlueIcon from '../../../../../assets/icons/icon_volume_bar_blue.svg';
import volumeBarOrangeIcon from '../../../../../assets/icons/icon_volume_bar_orange.svg';
import volumeBarGreenIcon from '../../../../../assets/icons/icon_volume_bar_green.svg';

class CustomFilter extends Component {
    constructor(props) {
        super(props);

        this.state = {
            timer: null,
        };

        this.handleSelect = this.handleSelect.bind(this);
        this.changeEndereco = this.changeEndereco.bind(this);
        this.selectEndereco = this.selectEndereco.bind(this);
        this.selectRaio = this.selectRaio.bind(this);
        this.renderTooltip = this.renderTooltip.bind(this);
        this.renderVolumeIcon = this.renderVolumeIcon.bind(this);
        this.handleClean = this.handleClean.bind(this);
    }

    shouldComponentUpdate(nextProps) {
        const { filtro, dataList, endereco, raio } = this.props;

        return (
            nextProps.filtro !== filtro ||
            nextProps.dataList !== dataList ||
            nextProps.endereco !== endereco ||
            nextProps.raio !== raio
        );
    }

    changeEndereco(value) {
        const { searchEndereco, endereco, changeNoCacheValue } = this.props;

        searchEndereco({ query: value, token: endereco.token });
        changeNoCacheValue('endereco', value);
    }

    selectEndereco(item) {
        const { getLatLon, endereco, changeNoCacheValue, setErro } = this.props;

        getLatLon({
            place_id: item.place_id,
            token: endereco.token,
        });
        changeNoCacheValue('endereco', item.value);

        if (endereco?.erro?.length) {
            setErro(null, 'endereco');
            const wrongEndereco = document.getElementsByName('endereco')[0];
            wrongEndereco.classList.remove('invalid-input');
        }
    }

    selectRaio(value) {
        const { changeNoCacheValue } = this.props;
        changeNoCacheValue('raio', value);
    }

    handleSelect(value, i, name) {
        const { setSingleReducer, dataList } = this.props;
        const hasValue = dataList.value.includes(i.value);

        if (hasValue) {
            const removeCache = dataList.cache.filter(
                (item) => i.value !== item.value
            );
            setSingleReducer(name, value, removeCache);
        } else {
            const addCache = [...dataList.cache, i];
            setSingleReducer(name, value, addCache);
        }
    }

    handleClean(name) {
        const { setSingleReducer } = this.props;
        setSingleReducer(name, [], []);
    }

    renderTooltip(message, icon) {
        return (
            <Whisper
                placement="top"
                trigger="hover"
                speaker={
                    <Tooltip>
                        <p>{message}</p>
                    </Tooltip>
                }
            >
                {icon}
            </Whisper>
        );
    }

    renderVolumeIcon(name) {
        const allowRender = name === 'rede';
        if (allowRender) {
            return true;
        }
        return false;
    }

    render() {
        const {
            filtro,
            index,
            handleDeleteFiltro,
            raio,
            endereco,
            dataList,
            onOpenCustomFilter,
            onSearchCustomFilter,
        } = this.props;

        return (
            <>
                {filtro?.tipoCampo === 1 ? (
                    <FlexboxGrid.Item
                        componentClass={Col}
                        lg={11}
                        md={9}
                        style={{ marginBottom: '0' }}
                        xsHidden
                        smHidden
                    >
                        <FlexboxGrid.Item componentClass={Col} lg={4} md={6}>
                            <Tag
                                style={{
                                    padding: '5px 10px',
                                    marginRight: '10px',
                                    width: '100%',
                                    textAlign: 'center',
                                    border: '2px solid #3597fa',
                                    color: '#3597fa',
                                }}
                            >
                                {filtro.title}
                            </Tag>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item
                            componentClass={Col}
                            lg={16}
                            md={13}
                            sm={23}
                            xs={23}
                            style={{ marginLeft: '10px' }}
                        >
                            <FormGroup>
                                <CheckPicker
                                    className="inf-picker"
                                    block
                                    disabledItemValues={[filtro.name].disabled}
                                    cleanable={filtro.cleanable}
                                    searchable={filtro.searchable}
                                    filter={filtro.filter}
                                    data={dataList?.lista}
                                    value={dataList?.value}
                                    name={filtro.name}
                                    placeholder={filtro.placeholder}
                                    locale={filtro.locale}
                                    onClean={() =>
                                        this.handleClean(filtro.name)
                                    }
                                    onSelect={(value, i) =>
                                        filtro.onSelect
                                            ? this[filtro.onSelect](
                                                  value,
                                                  i,
                                                  filtro.name
                                              )
                                            : this.handleSelect(
                                                  value,
                                                  i,
                                                  filtro.name
                                              )
                                    }
                                    onSearch={
                                        filtro.onSearch
                                            ? (search) =>
                                                  onSearchCustomFilter(
                                                      search,
                                                      filtro
                                                  )
                                            : null
                                    }
                                    onOpen={() =>
                                        onOpenCustomFilter('', filtro)
                                    }
                                    renderMenu={(menu) => (
                                        <span>
                                            <LoadingMenu area="panel-filter" />
                                            {menu}
                                        </span>
                                    )}
                                    renderMenuItem={(label, item) => (
                                        <div>
                                            {this.renderVolumeIcon(
                                                filtro.name
                                            ) ? (
                                                <>
                                                    {item.datapoints < 30
                                                        ? this.renderTooltip(
                                                              'Alguns dados',
                                                              <img
                                                                  src={
                                                                      volumeBarOrangeIcon
                                                                  }
                                                                  alt="volumeBarOrangeIcon"
                                                                  className="product-volume-icon"
                                                              />
                                                          )
                                                        : null}

                                                    {item.datapoints >= 30 &&
                                                    item.datapoints < 100
                                                        ? this.renderTooltip(
                                                              'Diversos dados',
                                                              <img
                                                                  src={
                                                                      volumeBarBlueIcon
                                                                  }
                                                                  alt="volumeBarBlueIcon"
                                                                  className="product-volume-icon"
                                                              />
                                                          )
                                                        : null}

                                                    {item.datapoints >= 100
                                                        ? this.renderTooltip(
                                                              'Muitos dados',
                                                              <img
                                                                  src={
                                                                      volumeBarGreenIcon
                                                                  }
                                                                  alt="volumeBarGreenIcon"
                                                                  className="product-volume-icon"
                                                              />
                                                          )
                                                        : null}
                                                </>
                                            ) : null}
                                            <span>{label}</span>
                                        </div>
                                    )}
                                />
                                <HelpBlock
                                    className="error"
                                    name="noDataFilterWarning"
                                >
                                    {filtro.name.erro && [filtro.name].erro}
                                </HelpBlock>
                            </FormGroup>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item componentClass={Col} xs={1}>
                            {filtro?.tooltipMessage ? (
                                <Whisper
                                    placement="top"
                                    trigger="hover"
                                    speaker={
                                        <Tooltip>
                                            <p>{filtro.tooltipMessage}</p>
                                        </Tooltip>
                                    }
                                >
                                    <Icon
                                        className="tooltip-info"
                                        icon="info"
                                    />
                                </Whisper>
                            ) : null}
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item componentClass={Col} lg={2} md={3}>
                            <IconButton
                                style={{ marginLeft: '10px' }}
                                icon={<Icon icon="trash2" />}
                                onClick={() =>
                                    handleDeleteFiltro(index, filtro.name)
                                }
                            />
                        </FlexboxGrid.Item>
                    </FlexboxGrid.Item>
                ) : null}

                {filtro?.tipoCampo === 2 ? (
                    <FlexboxGrid.Item
                        componentClass={Col}
                        lg={20}
                        md={18}
                        style={{ marginBottom: '0' }}
                        xsHidden
                        smHidden
                    >
                        <FlexboxGrid.Item componentClass={Col} lg={3} md={5}>
                            <Tag
                                style={{
                                    padding: '5px 10px',
                                    marginRight: '10px',
                                    width: '100%',
                                    textAlign: 'center',
                                    border: '2px solid #3597fa',
                                    color: '#3597fa',
                                }}
                            >
                                {filtro.title}
                            </Tag>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item
                            componentClass={Col}
                            lg={10}
                            md={9}
                            style={{ marginLeft: '10px' }}
                        >
                            <FormGroup>
                                <Autocomplete
                                    id={filtro.name}
                                    placeholder={filtro.placeholder}
                                    name={filtro.name}
                                    getOptionLabel={(option) =>
                                        typeof option === 'string'
                                            ? option
                                            : option.label
                                    }
                                    filterOptions={(x) => x}
                                    options={endereco?.lista}
                                    freeSolo
                                    noOptionsText="Pesquise o endereço desejado"
                                    disableClearable
                                    value={endereco?.value}
                                    onChange={(event, newValue) => {
                                        this.selectEndereco(newValue);
                                    }}
                                    onInputChange={(event, newInputValue) => {
                                        this.changeEndereco(newInputValue);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            margin="normal"
                                        />
                                    )}
                                />
                                <HelpBlock
                                    className="invalid-field"
                                    name="erro"
                                >
                                    {filtro.name.erro && endereco.erro}
                                </HelpBlock>
                            </FormGroup>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item componentClass={Col} lg={2} md={2}>
                            <Tag className="filtro-dinamico__tag">Raio</Tag>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item
                            componentClass={Col}
                            lg={5}
                            md={5}
                            style={{ marginLeft: '10px' }}
                        >
                            <SelectPicker
                                className="inf-picker"
                                block
                                cleanable
                                searchable={false}
                                data={raio.lista}
                                value={raio.value}
                                name="raio"
                                placeholder="Selecione o alcance"
                                onSelect={(value) => this.selectRaio(value)}
                                onClean={(value) => this.selectRaio(value)}
                            />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item componentClass={Col} lg={2} md={2}>
                            <IconButton
                                className="filtro-dinamico__delete-button"
                                icon={<Icon icon="trash2" />}
                                onClick={() =>
                                    handleDeleteFiltro(index, filtro.name)
                                }
                            />
                        </FlexboxGrid.Item>
                    </FlexboxGrid.Item>
                ) : null}
            </>
        );
    }
}

const mapStateToProps = (store, props) => ({
    endereco: store.painelGeralDataReducer.endereco,
    raio: store.painelGeralDataReducer.raio,
    dataList: store.painelGeralDataReducer[props.filtro.name],
});
const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            selectValueNoCache,
            getItensFiltro,
            searchEndereco,
            getLatLon,
            setErro,
            setSingleReducer,
            changeNoCacheValue,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(CustomFilter);
