import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'rsuite';

import ModalConfirmation from '../../../../../components/ModalConfirmation';
import gifFree from '../../../../../assets/image/banner_free.gif';
import { sendLead } from '../utils';

const ModalBanner = ({ show, onCancel, usuario }) => {
    const [showCTA, setShowCTA] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            onCancel();
        }, 13000);
    }, show);

    const handleShowCTA = () => {
        setShowCTA(!showCTA);
    };

    const confirmCTA = () => {
        setShowCTA(false);
        sendLead(usuario, 'upsell_infopanel');
    };

    return (
        <>
            <Modal backdrop="static" show={show} size="md" className="modal-banner-component">
                <div className="image-area">
                    <img src={gifFree} alt="Gif" />
                </div>
                <div className="message-area">
                    <p>
                        Enquanto você espera, os preços do seu concorrente estão mudando.
                    </p>
                    <Button block appearance="primary" onClick={handleShowCTA}>Assinar agora</Button>
                </div>
                <div className="container">
                    <div className="progress-bar" />
                </div>
            </Modal>

            <ModalConfirmation
                title="Informações para alteração do plano"
                message="Caso deseje saber mais informações para evoluir o seu plano, clique em “Confirmar”"
                show={showCTA}
                btnConfirmClass="upsell"
                buttonConfirm="Confirmar"
                buttonCancel="Cancelar"
                onConfirm={confirmCTA}
                onCancel={handleShowCTA}
            />
        </>
    );
};

export default ModalBanner;
