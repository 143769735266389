import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import React, { useEffect } from 'react';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, Notification } from 'rsuite';
import {
    setStateValue,
} from '../../actions/actionsModuloRelatorio';
import { getNotificationReport } from '../../services/AlertaService';
import iconCheckCircleGreen from '../../assets/icons/icon_check_circle_green.svg';
import styles from './useNotificationReport.module.scss';

export const useNotificationReport = () => {
    const history = useHistory();

    const dispatch = useDispatch();

    const { report, previousReport } = useSelector(
        (state) => ({
            report: state.moduloRelatorioDataReducer?.report,
            previousReport: state.moduloRelatorioDataReducer?.previousReport,
        }),
        shallowEqual,
    );
    const getRequestNotificationReport = async () => {
        const response = await getNotificationReport();
        dispatch(setStateValue('report', response));
        dispatch(setStateValue('previousReport', report));
    };

    useEffect(() => {
        if (previousReport.length > report.length) {
            dispatch(setStateValue('previousReport', report));
            Notification.open({
                className: styles['notification-link-report'],
                duration: 10000,
                description: (
                    <div className={styles['description-flex']}>
                        <img src={iconCheckCircleGreen} alt="" />
                        <div>
                            <p className={styles.text}>Seu relatório solicitado está disponível!</p>
                            <Button
                                className={styles['link-flex']}
                                appearance="link"
                                onClick={() => history.push('/isa/modulo-relatorio')}
                            >
                                <OpenInNewIcon style={{ width: '12px', marginRight: '2px' }} />
                                Acesse o relatório
                            </Button>
                        </div>
                    </div>
                ),
            });
        }
        dispatch(setStateValue('previousReport', report));
    }, [report, previousReport]);

    return {
        getRequestNotificationReport,
    };
};
