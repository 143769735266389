import React from 'react';
import { Button, Content } from 'rsuite';
import Badge from './Badge';

const type = {
    Pro: 'pill-primary',
    Flex: 'pill-secondary',
    Free: 'pill-tertiary',
};

const Header = ({
    productTitle,
    title,
    subtitle,
    badgeLabel,
    icon,
    textButton,
    onClickTextButton,
    bottonModalVideos,
}) => {
    return (
        <div className="header-container">
            {productTitle && badgeLabel ? (
                <div className="product-title-container">
                    <p className="product-title">{productTitle}</p>
                    {badgeLabel && (
                        <Badge
                            label={badgeLabel}
                            className={type[badgeLabel]}
                        />
                    )}
                </div>
            ) : null}
            <Content className="page-header">
                {icon ? <div className="page-header-icon">{icon}</div> : null}
                <div className="page-header-title-container">
                    <p className="page-header-title">{title}</p>
                    <div className="page-header-subtitle-flex">
                        <p className="page-header-subtitle">{subtitle}</p>
                        {textButton ? (
                            <Button
                                appearance="link"
                                onClick={onClickTextButton}
                            >
                                {textButton}
                            </Button>
                        ) : null}
                    </div>
                </div>
                <div className="page-header-icon-modal">
                    {bottonModalVideos}
                </div>
            </Content>
        </div>
    );
};

export default Header;
