import qs from 'qs';
import { trackPromise } from 'react-promise-tracker';

import api from '../../../../../utils/API';

export const getPrevisoesSalvas = (params) => trackPromise(
    api({
        url: `${process.env.REACT_APP_DEMAND_FORECAST}/demand-forecasts`,
        method: 'GET',
        expectToken: true,
        params: {
            ...(params?.storeId ? { storeId: params.storeId } : null),
            ...(params?.productId ? { productId: params.productId } : null),
            ...(params?.startDate ? { startDate: params.startDate } : null),
            ...(params?.endDate ? { endDate: params.endDate } : null),
            ...(params?.page ? { page: params.page } : null),
            ...(params?.size ? { size: params.size } : null),
        },
        paramsSerializer(params) {
            return qs.stringify(params, { arrayFormat: 'repeat' });
        },
    }).then((resp) => resp.data),
);

export const deletePrevisao = (id) => trackPromise(
    api({
        url: `${process.env.REACT_APP_DEMAND_FORECAST}/demand-forecasts/${id}`,
        method: 'DELETE',
        expectToken: true,
    }).then((resp) => resp),
);

export const deletePrevisoes = (params) => trackPromise(
    api({
        url: `${process.env.REACT_APP_DEMAND_FORECAST}/demand-forecasts`,
        method: 'DELETE',
        expectToken: true,
        params: {
            demandForecastsIds: params.demandForecastsIds,
        },
        paramsSerializer(params) {
            return qs.stringify(params, { arrayFormat: 'repeat' });
        },
    }).then((resp) => resp),
);
