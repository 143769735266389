import { useDispatch, useSelector } from 'react-redux';
import {
    setInputCalculosValue,
    setInputCalculosState,
} from '../../../reducers/calculadoraPrevisaoDemanda/inputCalculos';
import {
    selectorCalculadoraPrevisaoDemanda,
} from '../../../reducers/calculadoraPrevisaoDemanda/calculadoraPrevisaoDemandaReducer';

export const useInputPrice = () => {
    const dispatch = useDispatch();

    const { inputCalculos } = useSelector(
        selectorCalculadoraPrevisaoDemanda,
    );

    const handleOnChangePrecoAtual = (value: string) => {
        const intlFormat = value.replace(/[,.]/g, (match) => (match === ',' ? '.' : ','));
        dispatch(setInputCalculosValue({ name: 'base_price', value: parseFloat(intlFormat) }));

        if (inputCalculos?.inputs.new_price !== 0 && inputCalculos?.variacaoPrecoTabela === 0) {
            const result = ((inputCalculos.inputs.new_price / parseFloat(intlFormat)) * 100) - 100;
            dispatch(setInputCalculosState({ name: 'variacaoPrecoTabela', value: result }));
        }

        if (inputCalculos?.variacaoPrecoTabela !== 0) {
            const result = ((inputCalculos?.variacaoPrecoTabela / 100) * parseFloat(intlFormat)) + parseFloat(intlFormat);
            dispatch(setInputCalculosValue({ name: 'new_price', value: result }));
        }
    };

    const handleOnChangeNovoPreco = (value: string) => {
        const intlFormat = value.replace(/[,.]/g, (match) => (match === ',' ? '.' : ','));
        dispatch(setInputCalculosValue({ name: 'new_price', value: parseFloat(intlFormat) }));

        if (inputCalculos.inputs.base_price !== 0) {
            const result = ((parseFloat(intlFormat) / inputCalculos.inputs.base_price) * 100) - 100;
            dispatch(setInputCalculosState({ name: 'variacaoPrecoTabela', value: result }));
        }
    };

    const handleOnChangeVariacao = (value: string) => {
        const intlFormat = value.replace(/[,.]/g, (match) => (match === ',' ? '.' : ','));
        dispatch(setInputCalculosState({ name: 'variacaoPrecoTabela', value: parseFloat(intlFormat) }));

        if (inputCalculos.inputs.base_price !== 0) {
            const result = ((parseFloat(intlFormat) / 100) * inputCalculos.inputs.base_price) + inputCalculos.inputs.base_price;
            dispatch(setInputCalculosValue({ name: 'new_price', value: result }));
        }

        if (inputCalculos.inputs.base_price !== 0 && inputCalculos?.inputs.new_price !== 0) {
            const result = inputCalculos.inputs.base_price + (inputCalculos.inputs.base_price * (parseFloat(intlFormat) / 100));
            dispatch(setInputCalculosValue({ name: 'new_price', value: result }));
        }
    };

    const handleOnChangeCusto = (value: string) => {
        const intlFormat = value.replace(/[,.]/g, (match) => (match === ',' ? '.' : ','));
        dispatch(setInputCalculosValue({ name: 'cost', value: parseFloat(intlFormat) }));
    };

    const handleOnChangeDemandaPeriodo = (value: string) => {
        dispatch(setInputCalculosValue({ name: 'average_monthly_sales', value: parseFloat(value) }));
    };

    return {
        priceHandlers: {
            handleOnChangePrecoAtual,
            handleOnChangeNovoPreco,
            handleOnChangeVariacao,
            handleOnChangeCusto,
            handleOnChangeDemandaPeriodo,
        },
    };
};
