import React from "react";
import { Message } from 'rsuite';


const Warning = (props) => (

    <Message
        className="warning"
        type="warning"
        title={props.title}
        description={
            <p>
                {props.description}
            </p>
        }
    />
)

export default Warning;