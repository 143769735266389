import React, { useState, useEffect } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { moduleReportReverseEnumsType } from '../utils';

export const useDndComponentToEditItems = (initialItems) => {
    const [editItems, setEditItems] = useState([]);

    const { saveReportItemData } = useSelector(
        (state) => ({
            saveReportItemData:
                state.moduloRelatorioDataReducer?.saveReportItemData,
        }),
        shallowEqual,
    );

    const setListToSavedList = () => {
        const newList = initialItems.map((item) => {
            if (
                saveReportItemData?.reportEngineDTO?.dimensoes.some(
                    (saved) =>
                        item.name ===
                        moduleReportReverseEnumsType[saved.dimensao.name],
                )
            ) {
                return { ...item, column: '2' };
            }
            return item;
        });

        setEditItems(newList);
    };

    useEffect(() => {
        if (
            saveReportItemData?.id &&
            saveReportItemData?.reportEngineDTO?.dimensoes?.length
        ) {
            setListToSavedList();
        } else {
            setEditItems(initialItems);
        }
    }, [
        saveReportItemData?.id,
        saveReportItemData?.reportEngineDTO?.dimensoes,
    ]);

    return { editItems };
};
