import React, { useState } from 'react';

import banner from '../../../../../assets/image/infopanel_fixed_banner.png';
import ModalConfirmation from '../../../../../components/ModalConfirmation';
import { trackEvent } from '../../../../../utils/MatomoConfig';
import { sendLead } from '../utils';

const FixedBanner = ({ usuario }) => {
    const [showCTA, setShowCTA] = useState(false);

    const handleShowCTA = () => {
        setShowCTA(!showCTA);
    };

    const confirmCTA = () => {
        setShowCTA(false);
        sendLead(usuario, 'upsell_infopanel');

        trackEvent('Upsell/Crossell', 'upsell-banner-pesquisa');
        window.open('https://wa.me/551137779670?text=Ol%C3%A1%21+Quero+saber+mais+sobre+os+planos+Flex+e+Pro+do+ISA+-+InfoPanel');
    };

    return (
        <>
            <div className="fixed-banner-component">
                <div
                    onClick={handleShowCTA}
                    role="link"
                    className="banner-area"
                >
                    <img src={banner} alt="Banner" />
                </div>
            </div>

            <ModalConfirmation
                title="Informações para alteração do plano"
                message="Caso deseje saber mais informações para evoluir o seu plano, clique em “Confirmar”"
                show={showCTA}
                btnConfirmClass="upsell"
                buttonConfirm="Confirmar"
                buttonCancel="Cancelar"
                onConfirm={confirmCTA}
                onCancel={handleShowCTA}
            />
        </>
    );
};

export default FixedBanner;
