import QueryString from 'qs';
import { trackPromise } from 'react-promise-tracker';
import { api } from '../../../../../lib';
import { getValidData } from '../../../RevisaoPrecos/utils';

export const getMargemObjetivaSegmento = async () => {
    return trackPromise(
        api
            .get<{
                level: number;
                name: string;
                field: `categoryLevel${number}`;
            }>('/setup/objective-margin/category', {
                expectToken: true,
            })
            .then((res) => res.data),
        'ipa/estrategia/margem-objetiva/segmento',
    );
};

export type IGetMargemObjetiva = {
    filters: Partial<Record<string, string[]>>;
    sort: string;
    page: number;
    size: number;
};

export type IGetMargemObjetivaResponseItem = {
    id: string;
    categoryName: string;
    storeId: string;
    objectiveMargin: number | null;
    currentMargin: number | null;
    editedBy: string | null;
    updatedAt: string | null;
};

export type IGetMargemObjetivaResponse = {
    content: IGetMargemObjetivaResponseItem[];
    totalElements: number;
    totalPages: number;
} & Record<string, unknown>;

export const getMargemObjetivaTableData = async ({
    filters,
    ...sort
}: IGetMargemObjetiva): Promise<IGetMargemObjetivaResponse> => {
    const res = await trackPromise(
        api.get<IGetMargemObjetivaResponse>('setup/objective-margin', {
            expectToken: true,
            params: getValidData({
                ...filters,
                ...sort,
                page: sort.page - 1,
            }),
            paramsSerializer(params) {
                return QueryString.stringify(params, { arrayFormat: 'repeat' });
            },
        }),
        'ipa/gerenciador/margem-objetiva/table-data',
    );

    return res.data;
};

export type IPatchMargemObjetivaEditDatapointData =
    IGetMargemObjetivaResponseItem & { categoryLevel: number };

export type IPatchMargemObjetivaEditDatapointResponse = Pick<
    IGetMargemObjetivaResponseItem,
    'updatedAt' | 'editedBy'
>;

export const patchMargemObjetivaEditDatapoint = async (
    data: IPatchMargemObjetivaEditDatapointData,
): Promise<IPatchMargemObjetivaEditDatapointResponse> => {
    const res = await trackPromise(
        api.put<IPatchMargemObjetivaEditDatapointResponse>(
            'setup/objective-margin',
            data,
            {
                expectToken: true,
            },
        ),
        `ipa/gerenciador/margem-objetiva/table-data-${data.id}`,
    );
    return res.data;
};
