import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import React from 'react';
import styles from './styles.module.scss';

export interface IBoxIndicator{
    indicator: number;
    percentPoint: boolean;
}

export const BoxIndicator = ({ indicator, percentPoint }: IBoxIndicator) => {
    if (indicator === 0) {
        return null;
    }
    return (
        <div className={styles['indicator-wrapper']}>
            {indicator > 0
            ? <ArrowUpwardIcon className={styles['positive-icon']} />
            : <ArrowDownwardIcon className={styles['negative-icon']} />}
            <p className={styles[indicator > 0 ? 'positive' : 'negative']}>
                {Number(indicator).toFixed(2).replace('.', ',').replace('-', '')}
                {' '}
                {percentPoint ? 'pp' : '%'}
            </p>
        </div>
);
};
