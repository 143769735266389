import React from 'react';

export type IconProps = React.ButtonHTMLAttributes<HTMLButtonElement>;

export const Icon = ({ children, className = '', ...props }: IconProps) => (
    <button
        className={['currency-input__icon', className].join(' ')}
        {...props}
    >
        {children}
    </button>
);
