import React from 'react';

export const DescArrowForward = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        {...props}
    >
        <path
            d="M3.47264 8.66681H10.9193L7.66597 11.9201C7.40597 12.1801 7.40597 12.6068 7.66597 12.8668C7.92597 13.1268 8.34597 13.1268 8.60597 12.8668L12.9993 8.47348C13.2593 8.21348 13.2593 7.79348 12.9993 7.53348L8.61264 3.13348C8.35264 2.87348 7.93264 2.87348 7.67264 3.13348C7.41264 3.39348 7.41264 3.81348 7.67264 4.07348L10.9193 7.33348H3.47264C3.10597 7.33348 2.80597 7.63348 2.80597 8.00014C2.80597 8.36681 3.10597 8.66681 3.47264 8.66681Z"
            fill="currentColor"
        />
    </svg>
);
