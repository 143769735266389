import React, { forwardRef } from 'react';

export type CardTitleProps = React.HtmlHTMLAttributes<HTMLHeadingElement>;

export const CardTitle = forwardRef(
    (
        { children, className = '', ...props }: CardTitleProps,
        ref: React.LegacyRef<HTMLHeadingElement>,
    ) => (
        <h3
            ref={ref}
            className={['info-card__title', className].join(' ')}
            {...props}
        >
            {children}
        </h3>
    ),
);
