import React, { forwardRef } from 'react';

export const PageTitle = forwardRef(
    (
        {
            children,
            className = '',
            ...props
        }: React.HTMLAttributes<HTMLHeadingElement>,
        ref: React.LegacyRef<HTMLHeadingElement>,
    ) => (
        <h1 className={`page__title ${className}`} ref={ref} {...props}>
            {children}
        </h1>
    ),
);
