import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Input, RadioGroup } from 'rsuite';
import Modal, { ModalProps } from 'rsuite/lib/Modal';
import { ButtonPrimary, Label, Radio } from '../../../../../components';
import { LoadingSpinerArea } from '../../../../../components/LoadingSpinner';
import { selectorSavedFilters } from '../../../../../reducers/gerenciadorPrecos';
import './style.scss';

export type SalvarFiltrosProps = ModalProps & {
    onChange: (value: unknown) => void;
    salvarFiltros?: () => void;
    filterName?: string;
};

const OPTIONS = [
    {
        id: 1,
        value: 'PUT',
        label: 'Substituir filtro salvo',
    },
    {
        id: 2,
        value: 'POST',
        label: 'Salvar como novo filtro',
    },
];

export const SalvarFiltrosModal = ({
    className = '',
    onChange,
    actions,
    onHide,
    ...props
}: SalvarFiltrosProps) => {
    const [selectedOption, setSelectedOption] = useState<null | 'PUT' | 'POST'>(
        null,
    );

    const savedFilter = useSelector(selectorSavedFilters);
    const hasValue = savedFilter.data?.value?.length > 0;
    const isDisabled =
        (savedFilter?.filterName?.length === 0 ||
            (hasValue && !selectedOption)) ??
        false;

    return (
        <Modal
            className={['info-modal', 'modal-salvar-filtros', className].join(
                ' ',
            )}
            onHide={onHide}
            {...props}
        >
            <form onSubmit={(e) => e.preventDefault()}>
                <Modal.Header>
                    <Modal.Title className="font-size-200-semibold">
                        Salvar filtro
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {hasValue && (
                        <RadioGroup
                            onChange={(value) => setSelectedOption(value)}
                        >
                            {OPTIONS.map(({ value, label, ...rest }) => (
                                <Radio value={value} {...rest}>
                                    {label}
                                </Radio>
                            ))}
                        </RadioGroup>
                    )}
                    <div className="modal-salvar-filtros__filter-name-container">
                        <Label
                            htmlFor="filter-name"
                            className="font-size-100-regular"
                        >
                            Nome do filtro
                        </Label>
                        <Input
                            id="filter-name"
                            onChange={onChange}
                            value={savedFilter.filterName}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <ButtonPrimary
                        skin="blue"
                        theme="ghost"
                        type="button"
                        onClick={(e) => {
                            onHide?.(e);
                        }}
                    >
                        FECHAR
                    </ButtonPrimary>
                    <ButtonPrimary
                        skin="blue"
                        theme="filled"
                        onClick={() => {
                            if (savedFilter?.data?.value?.length === 0) {
                                actions.POST?.();
                            } else if (selectedOption) {
                                actions[selectedOption]?.();
                            }
                        }}
                        type="submit"
                        disabled={isDisabled}
                    >
                        SALVAR
                    </ButtonPrimary>
                </Modal.Footer>
            </form>
            <LoadingSpinerArea size="sm" area="saved-filter" />
        </Modal>
    );
};
