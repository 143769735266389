import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export type SavedScenarioNameProps =
    | 'savedScenarioData'
    | 'selectedScenarioItemList'
    | 'compareScenariosList'

export type SavedScenarioStateProps = {
    savedScenarioData: any,
    selectedScenarioItemList: any,
    compareScenariosList: any,
};

const initialState: SavedScenarioStateProps = {
    savedScenarioData: [],
    selectedScenarioItemList: [],
    compareScenariosList: [],
};

const slice = createSlice({
    name: 'result-headers-state',
    initialState,
    reducers: {
        setSavedScenarioData(state, { payload }: PayloadAction<{
            data: SavedScenarioStateProps;
        }>) {
            state.savedScenarioData = payload.data;
        },
        setSavedScenarioState(state, { payload }: PayloadAction<{
            name: SavedScenarioNameProps;
            data: any;
        }>) {
            state[payload.name] = payload.data;
        },
        resetSavedScenario() {
            return initialState;
        },
    },
});

const { setSavedScenarioData, setSavedScenarioState, resetSavedScenario } = slice.actions;

export { setSavedScenarioData, setSavedScenarioState, resetSavedScenario };

export default slice.reducer;
