import { trackPromise } from 'react-promise-tracker';

import api from '../utils/API';


export const getLojas = (clientId, query, pagination) =>
    api({
        url: process.env.REACT_APP_IPA_URL + '/datapoints-audit/stores',
        method: 'GET',
        params: {
            clientId: clientId,
            query: query || "",
            // page: pagination.page || null,
            // size: pagination.size || null
        }
    }).then((resp) => {
        return resp.data;
    })

export const getProdutos = (clientId, query, pagination) =>
    api({
        url: process.env.REACT_APP_IPA_URL + '/datapoints-audit/products',
        method: 'GET',
        params: {
            clientId: clientId,
            query: query || '',
            // page: pagination.page || null,
            // size: pagination.size || null
        }
    }).then((resp) => {
        return resp.data;
    })

export const getDatapoints = (clientId, query) =>
    trackPromise(
        api({
            url: process.env.REACT_APP_IPA_URL + '/datapoints-audit',
            method: 'GET',
            params: {
                from: query.dataInicio,
                to: query.dataFim,
                storeId: query.loja,
                productId: query.gtin,
                clientId: clientId,
                page: query.page,
                size: query.size
            }
        }).then((resp) => {
            return resp.data;
        })
    )