import React from 'react';
import {
    Modal, Button, Form, FlexboxGrid, Col, Icon,
} from 'rsuite';

const ChannelForm = (props) => {
    const { channel, setChannel } = props;

    return (
        <>
            <Modal.Header>
                <div className="header">
                    <h1 className="header__title">Alterar canal</h1>
                    <p className="header__description">A escolha do canal vai definir em quais tipos de loja você poderá monitorar os preços.</p>
                </div>
            </Modal.Header>
            <Modal.Body>

                <Form fluid>
                    <FlexboxGrid align="middle">
                        <Col xs={24}>
                            <div className="channel-selection">
                                <p className="channel-selection__label">Escolha seu canal</p>
                                <Button
                                    appearance={channel === 'ALIMENTAR' ? 'primary' : 'ghost'}
                                    name="alimentar"
                                    id="btn-alimentar"
                                    className="channel-selection__btn-option"
                                    onClick={() => setChannel('ALIMENTAR')}
                                >
                                    <Icon icon="cutlery" />
                                    <p>Alimentar</p>
                                </Button>
                                <Button
                                    appearance={channel === 'FARMA' ? 'primary' : 'ghost'}
                                    name="farma"
                                    id="btn-farma"
                                    className="channel-selection__btn-option"
                                    onClick={() => setChannel('FARMA')}
                                >
                                    <Icon icon="medkit" />
                                    <p>Farma</p>
                                </Button>
                            </div>
                        </Col>
                        {channel?.length
                            ? (
                                <Col xs={24} className="description-area">
                                    {channel === 'ALIMENTAR' ? (
                                        <p className="description-area__message">
                                            Ao selecionar
                                            {' '}
                                            <b>Alimentar</b>
                                            {' '}
                                            você irá visualizar os dados de: atacado, hipermercado, supermercado,
                                            lojas de vizinhança, padarias, pet shops, armarinhos, lojas de brinquedo e departamento.
                                        </p>
                                    )
                                        : (
                                            <p className="description-area__message">
                                                Ao selecionar
                                                {' '}
                                                <b>Farma</b>
                                                {' '}
                                                você irá visualizar os dados de: farmácias e perfumarias.
                                            </p>
                                        )}
                                </Col>
                            )

                            : null}
                        <Col xs={24}>

                            <div className="warning-area">
                                <span className="warning-area__icon-area">
                                    <Icon icon="exclamation-triangle" />
                                </span>
                                <span className="warning-area__description-area">
                                    <p><b>Atenção!</b></p>
                                    <p>
                                        Você poderá realizar
                                        <b> UMA</b>
                                        {' '}
                                        alteração de canal
                                        <b> POR MÊS</b>
                                        .
                                    </p>
                                </span>
                            </div>
                        </Col>
                    </FlexboxGrid>
                </Form>
            </Modal.Body>
        </>
    );
};
export default ChannelForm;
