import Immutable from 'seamless-immutable';
import {
    ADD_SEGMENTO_CADASTRO_REGRA_ATACADO,
    DELETE_SEGMENTO_CADASTRO_REGRA_ATACADO,
    CHANGE_VALUE_SEGMENTO_CADASTRO_REGRA_ATACADO,
    CHANGE_CACHE_SEGMENTO_CADASTRO_REGRA_ATACADO,
    SELECT_VALUE_SEGMENTO_CADASTRO_REGRA_ATACADO,
    LIST_ITENS_SEGMENTOS_CADASTRO_REGRA_ATACADO,
    GET_SEGMENTOS_CADASTRO_REGRA_ATACADO,
    CLEAR_ALL_SEGMENTOS_CADASTRO_REGRA_ATACADO,
    SET_SEGMENTOS_UPDATE_CADASTRO_REGRA_ATACADO
} from '../actions/actionsRegraPrecoAtacadoCadastro'


const regraPrecoInitialState = Immutable({
    tiposSegmento: [],
    segmentosAdicionados: [],
    segmentos: [],
    segmentosData: {},
})


function regraAtacadoCadastroDataReducer(state = regraPrecoInitialState, action) {
    switch (action.type) {
        case ADD_SEGMENTO_CADASTRO_REGRA_ATACADO:
            return state.merge({
                segmentosAdicionados: [...state.segmentosAdicionados, action.filtro]
            })
        case DELETE_SEGMENTO_CADASTRO_REGRA_ATACADO:
            return state.merge({
                segmentosAdicionados: state.segmentosAdicionados.filter((item, index) => index !== action.index),
                segmentosData: {
                    ...state.segmentosData,
                    [`${action.name}`]: {
                        ...state.segmentosData[`${action.name}`],
                        value: []
                    }
                }
            })
        case CHANGE_VALUE_SEGMENTO_CADASTRO_REGRA_ATACADO:
            return state.merge({
                ...state,
                segmentosData: {
                    ...state.segmentosData,
                    [`${action.name}`]: {
                        ...state.segmentosData[`${action.name}`],
                        value: action.value
                    }
                }
            })
        case CHANGE_CACHE_SEGMENTO_CADASTRO_REGRA_ATACADO:
            return state.merge({
                ...state,
                segmentosData: {
                    ...state.segmentosData,
                    [`${action.name}`]: {
                        ...state.segmentosData[`${action.name}`],
                        cache: action.cache
                    }
                }
            })
        case SELECT_VALUE_SEGMENTO_CADASTRO_REGRA_ATACADO:
            return state.merge({
                ...state,
                segmentosData: {
                    ...state.segmentosData,
                    [`${action.name}`]: {
                        ...state.segmentosData[`${action.name}`],
                        cache: [...state.segmentosData[`${action.name}`].cache, action.item]
                    }
                }
            })
        case CLEAR_ALL_SEGMENTOS_CADASTRO_REGRA_ATACADO:
            return state.merge({
                ...state,
                tiposSegmento: regraPrecoInitialState.tiposSegmento,
                segmentosData: regraPrecoInitialState.segmentosData,
                segmentosAdicionados: regraPrecoInitialState.segmentosAdicionados
            })
        case LIST_ITENS_SEGMENTOS_CADASTRO_REGRA_ATACADO:
            return state.merge({
                ...state,
                segmentosData: {
                    ...state.segmentosData,
                    [`${action.name}`]: {

                        ...state.segmentosData[`${action.name}`],

                        lista: action.itens.map(item => {
                            return {
                                label: item,
                                value: item
                            }
                        }).concat(
                            state.segmentosData[`${action.name}`].cache.filter(item => {
                                return action.itens.findIndex(itemLista => { return itemLista === item.label }) === -1
                            })
                        )
                    }
                }
            })

        case GET_SEGMENTOS_CADASTRO_REGRA_ATACADO:
            return state.merge({
                ...state,
                tiposSegmento: [...state.tiposSegmento, {
                    title: 'SEGMENTAÇÃO', itens: action.itens.map(item => {
                        return {
                            name: item.field,
                            title: item.name,
                            eventKey: item.field,
                            nivel: item.level
                        }
                    })
                }],
                segmentos: state.segmentos.concat(action.itens.map(item => {
                    return {
                        name: item.field,
                        nivel: item.level,
                        cleanable: true,
                        filter: false,
                        title: item.name,
                        placeholder: 'Selecione um ou mais itens',
                        locale: { noResultsText: 'Nenhum item encontrado', searchPlaceholder: 'Digite ou busque o item desejado' },
                        onSelect: 'handleSelect',
                        onSearch: 'searchItensSegmento',
                        onChange: 'handleChange'
                    }
                })),
                segmentosData: Object.assign({}, action.segmentos, state.segmentosData)
            })

        case SET_SEGMENTOS_UPDATE_CADASTRO_REGRA_ATACADO:
            return state.merge({
                ...state,
                segmentosData: {
                    ...state.segmentosData,
                    [`${action.name}`]: {
                        ...state.segmentosData[`${action.name}`],
                        lista: state.segmentosData[`${action.name}`].lista.findIndex(item => { return item.value === action.value }) === -1 ? [...state.segmentosData[`${action.name}`].lista, { label: action.value, value: action.value }] : state.segmentosData[`${action.name}`].lista
                    }
                }
            })
        default:
            return state
    }
}

export default regraAtacadoCadastroDataReducer;