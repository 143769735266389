import React, { useState } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { Content, Panel, FlexboxGrid, Whisper, Tooltip } from 'rsuite';
import {
    getDetailsProductsData,
    setSingleReducer,
    getIndustryDetailsNetworksData,
} from '../../../../../../actions/actionsAnaliseCompetitividade';
import { DetailingPanelHeader } from '../DetailingPanelHeader';
import { DetailingPanelContentTable } from '../DetailingPanelContentTable';
import { LoadingSpinerLocal } from '../../../../../../components/LoadingSpinner';
import { sortData, tooltipCalculationText } from '../../utils';

import infoIcon from '../../../../../../assets/icons/icon_info_gray.svg';
import styles from './styles.module.scss';

export const Detailing = ({ dataPanelHeader, dataTable, competitionType }) => {
    const [openPanelName, setOpenPanelName] = useState('');
    const [timer, setTimer] = useState(null);
    const [currentTablePageData, setCurrentTablePageData] = useState({
        page: 0,
        column: 'competitividade',
        type: 'asc',
    });
    const [isSearching, setIsSearching] = useState(false);

    const isRetail = competitionType === 'varejo';

    const dispatch = useDispatch();

    const {
        analiseCompetitividadeData,
        analiseIndustriaCompetitividadeData,
        loadingProducts,
        loadingDetails,
    } = useSelector(
        (state) => ({
            analiseCompetitividadeData:
                state.analiseCompetitividadeDataReducer
                    .analiseCompetitividadeData,
            analiseIndustriaCompetitividadeData:
                state.analiseCompetitividadeDataReducer
                    .analiseIndustriaCompetitividadeData,
            loadingProducts:
                state.analiseCompetitividadeDataReducer.loadingProducts,
            loadingDetails:
                state.analiseCompetitividadeDataReducer.loadingDetails,
        }),
        shallowEqual
    );

    const validateDataTable = (item) => {
        if (isSearching) {
            return dataTable;
        }
        if (!isSearching && dataTable?.content?.length !== undefined) {
            if (dataTable?.content[0]?.redeComp === item.redeComp) {
                return dataTable;
            }
            return item?.detalhamentoProdutos;
        }
        if (!isSearching && dataTable?.content?.length === undefined) {
            return item?.detalhamentoProdutos;
        }
    };

    const validateIndustryDataTable = (item) => {
        if (isSearching) {
            return dataTable;
        }
        if (!isSearching && dataTable?.content?.length !== undefined) {
            if (
                dataTable?.content[0]?.codigoProdutoComp.slice(1) ===
                item.codigoProdutoComp
            ) {
                return dataTable;
            }
            return item?.detalhamentoRedes;
        }
        if (!isSearching && dataTable?.content?.length === undefined) {
            return item?.detalhamentoRedes;
        }
    };

    const handleChangeTableListPage = (page, redeComp, query) => {
        const productsModel = {
            ordemProdutos: [
                {
                    campo: sortData[currentTablePageData.column],
                    direcao: sortData[currentTablePageData.type],
                },
            ],
            page,
            query,
            size: 10,
            request: {
                ...analiseCompetitividadeData,
                redesCompetidoras: [redeComp],
            },
        };

        setCurrentTablePageData({ ...currentTablePageData, page });
        dispatch(getDetailsProductsData(productsModel));
    };

    const handleChangeIndustryTableListPage = (
        page,
        codigoProdutoComp,
        query
    ) => {
        const networksModel = {
            ordemRedes: [
                {
                    campo: sortData[currentTablePageData.column],
                    direcao: sortData[currentTablePageData.type],
                },
            ],
            page,
            query,
            size: 10,
            request: {
                ...analiseIndustriaCompetitividadeData,
                produtosCompetidores: [codigoProdutoComp],
            },
        };

        setCurrentTablePageData({ ...currentTablePageData, page });
        dispatch(getIndustryDetailsNetworksData(networksModel));
    };

    const handleChangeSortTable = (column, type, redeComp) => {
        setIsSearching(false);
        const productsModel = {
            ordemProdutos: [
                {
                    campo: sortData[column],
                    direcao: sortData[type],
                },
            ],
            page: currentTablePageData.page,
            query: '',
            size: 10,
            request: {
                ...analiseCompetitividadeData,
                redesCompetidoras: [redeComp],
            },
        };
        setCurrentTablePageData({ ...currentTablePageData, column, type });
        dispatch(getDetailsProductsData(productsModel));
    };

    const handleChangeIndustrySortTable = (column, type, codigoProdutoComp) => {
        setIsSearching(false);
        const networksModel = {
            ordemRedes: [
                {
                    campo: sortData[column],
                    direcao: sortData[type],
                },
            ],
            page: currentTablePageData.page,
            query: '',
            size: 10,
            request: {
                ...analiseIndustriaCompetitividadeData,
                produtosCompetidores: [codigoProdutoComp],
            },
        };
        setCurrentTablePageData({ ...currentTablePageData, column, type });
        dispatch(getIndustryDetailsNetworksData(networksModel));
    };

    const handleOpenPanel = (name) => {
        setIsSearching(false);
        dispatch(setSingleReducer('productsResponse', {}));
        if (name === openPanelName) {
            return setOpenPanelName('');
        }
        setOpenPanelName(name);
    };

    const handleOnSearchTable = (value, name) => {
        setIsSearching(true);
        clearTimeout(timer);
        const newTimer = setTimeout(() => {
            handleChangeTableListPage(0, name, value);
        }, 500);
        setTimer(newTimer);
    };

    const handleChangeTablePage = (page, name) => {
        setIsSearching(false);
        handleChangeTableListPage(page, name);
    };

    const handleOnSearchIndustryTable = (value, name) => {
        setIsSearching(true);
        clearTimeout(timer);
        const newTimer = setTimeout(() => {
            handleChangeIndustryTableListPage(0, name, value);
        }, 500);
        setTimer(newTimer);
    };

    const handleChangeIndustryTablePage = (page, name) => {
        setIsSearching(false);
        handleChangeIndustryTableListPage(page, name);
    };

    return (
        <Content className={styles['detailing-content']}>
            <LoadingSpinerLocal
                loading={loadingDetails}
                size="md"
                position="absolute"
            />
            <h5 className={styles['detailing-title']}>Detalhamento</h5>
            <div className={styles['table-container']}>
                <FlexboxGrid align="middle" className={styles['table-header']}>
                    <FlexboxGrid.Item
                        colspan={12}
                        className={styles['table-item']}
                    >
                        <div style={{ lineHeight: 1 }}>
                            {isRetail
                                ? 'Rede competidora'
                                : 'Produto competidor'}
                        </div>
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item
                        colspan={6}
                        className={styles['table-item']}
                    >
                        <FlexboxGrid>
                            <div style={{ lineHeight: 1 }}>
                                {isRetail
                                    ? 'Produtos comparados'
                                    : 'Redes comparadas'}
                            </div>
                            <Whisper
                                trigger="hover"
                                placement="right"
                                speaker={
                                    <Tooltip>{tooltipCalculationText}</Tooltip>
                                }
                            >
                                <img
                                    src={infoIcon}
                                    alt=""
                                    className={styles['info-icon']}
                                />
                            </Whisper>
                        </FlexboxGrid>
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item
                        colspan={6}
                        className={styles['table-item']}
                    >
                        <div style={{ lineHeight: 1 }}>Competitividade</div>
                    </FlexboxGrid.Item>
                </FlexboxGrid>
                {dataPanelHeader?.map((item) => (
                    <Panel
                        header={
                            isRetail ? (
                                <DetailingPanelHeader
                                    id={item.redeComp}
                                    title={item.redeComp}
                                    storeQuantity={item.storeQuantity}
                                    productQuantity={item.productQuantity}
                                    cheaperQuantity={item.maisBaratos}
                                    expensiveQuantity={item.maisCaros}
                                    comparedItemQuantity={item.produtos}
                                    hasOpen={openPanelName === item.redeComp}
                                    comparedItemText="produtos comparados"
                                    expensiveText="mais caros"
                                    cheaperText="mais baratos"
                                    referenceText="A referência está"
                                    referenceCheapText="mais cara"
                                    referenceExpensiveText="mais barata"
                                    data={item}
                                    onClick={() =>
                                        handleOpenPanel(item.redeComp)
                                    }
                                    onSearch={(value) =>
                                        handleOnSearchTable(
                                            value,
                                            item.redeComp
                                        )
                                    }
                                />
                            ) : (
                                <DetailingPanelHeader
                                    id={item.produtoComp}
                                    title={item.produtoComp}
                                    subtitleProduct={item.codigoProdutoComp}
                                    storeQuantity={item.storeQuantity}
                                    productQuantity={item.productQuantity}
                                    cheaperQuantity={item.maisBaratas}
                                    expensiveQuantity={item.maisCaras}
                                    comparedItemQuantity={item.redes}
                                    hasOpen={openPanelName === item.produtoComp}
                                    comparedItemText="redes comparadas"
                                    expensiveText="mais caras"
                                    cheaperText="mais baratas"
                                    referenceText="O referência está"
                                    referenceCheapText="mais caro"
                                    referenceExpensiveText="mais barato"
                                    data={item}
                                    onClick={() =>
                                        handleOpenPanel(item.produtoComp)
                                    }
                                    onSearch={(value) =>
                                        handleOnSearchIndustryTable(
                                            value,
                                            item.codigoProdutoComp
                                        )
                                    }
                                />
                            )
                        }
                        key={isRetail ? item.redeComp : item.produtoComp}
                        className={styles['panel-row']}
                        bodyFill
                        collapsible
                        expanded={
                            openPanelName ===
                            (isRetail ? item.redeComp : item.produtoComp)
                        }
                    >
                        {isRetail ? (
                            <DetailingPanelContentTable
                                data={validateDataTable(item)}
                                totalProducts={item.produtos}
                                hasOpen={openPanelName === item.redeComp}
                                loading={loadingProducts}
                                firsColumnHeadTitle="Produto"
                                firsColumnHeadDataKey="produto"
                                setPage={(page) =>
                                    handleChangeTablePage(page, item.redeComp)
                                }
                                setSortList={(column, type) =>
                                    handleChangeSortTable(
                                        column,
                                        type,
                                        item.redeComp
                                    )
                                }
                            />
                        ) : (
                            <DetailingPanelContentTable
                                data={validateIndustryDataTable(item)}
                                totalProducts={item.redes}
                                hasOpen={openPanelName === item.produtoComp}
                                loading={loadingProducts}
                                firsColumnHeadTitle="Rede"
                                firsColumnHeadDataKey="rede"
                                setPage={(page) =>
                                    handleChangeIndustryTablePage(
                                        page,
                                        item.codigoProdutoComp
                                    )
                                }
                                setSortList={(column, type) =>
                                    handleChangeIndustrySortTable(
                                        column,
                                        type,
                                        item.codigoProdutoComp
                                    )
                                }
                            />
                        )}
                    </Panel>
                ))}
            </div>
        </Content>
    );
};
