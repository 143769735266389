import { trackPromise } from 'react-promise-tracker';

import api from '../../../../utils/API';

export const saveLimite = (data) => trackPromise(
        api({
            url: `${process.env.REACT_APP_IPA_URL}/limits`,
            method: 'POST',
            expectToken: true,
            data: JSON.stringify(data),
        }).then((resp) => resp),
    );

export const updateLimite = (id, data) => trackPromise(
        api({
            url: `${process.env.REACT_APP_IPA_URL}/limits/${id}`,
            method: 'PUT',
            expectToken: true,
            data: JSON.stringify(data),
        }).then((resp) => resp),
    );

export const getLimite = (id) => trackPromise(
        api({
            url: `${process.env.REACT_APP_IPA_URL}/limits/${id}`,
            method: 'GET',
            expectToken: true,
        }).then((resp) => resp.data),
    );
