import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import { GerenciadorPrecos } from '../../@types/GerenciadorPrecos';
import { RootState } from '../../@types/RootState';

type RowData = GerenciadorPrecos.RowData;

export type UpdateDatapointsCacheKeys = 'selectedIds' | 'excludedIds';

export type DatapointsCacheState = Record<UpdateDatapointsCacheKeys, RowData[]>;

const initialState: DatapointsCacheState = {
    selectedIds: [],
    excludedIds: [],
};

const slice = createSlice({
    name: 'ipa.gerenciador.datapointsCache',
    initialState,
    reducers: {
        RESET_DATAPOINTS_CACHE: () => initialState,
        ADD_DATAPOINT_CACHE: (
            state,
            {
                payload,
            }: PayloadAction<{
                rowData: RowData;
                updateList: UpdateDatapointsCacheKeys;
            }>,
        ) => {
            return {
                ...state,
                [payload.updateList]: _.uniqBy(
                    [...state[payload.updateList], payload.rowData],
                    'productsToBePricedId',
                ),
            };
        },
        REMOVE_DATAPOINT_CACHE: (
            state,
            {
                payload,
            }: PayloadAction<{
                id: string;
                updateList: UpdateDatapointsCacheKeys;
            }>,
        ) => {
            return {
                ...state,
                [payload.updateList]: state[payload.updateList].filter(
                    (item) => item.productsToBePricedId !== payload.id,
                ),
            };
        },
        UPDATE_DATAPOINT_CACHE_BY_ID: (
            state,
            {
                payload,
            }: PayloadAction<{
                productsToBePricedId: RowData['productsToBePricedId'];
                updateList: UpdateDatapointsCacheKeys;
                rowData: Partial<RowData>;
            }>,
        ) => {
            return {
                ...state,
                [payload.updateList]: state[payload.updateList].map((item) => {
                    if (
                        item.productsToBePricedId ===
                        payload.productsToBePricedId
                    ) {
                        return {
                            ...item,
                            ...payload.rowData,
                        };
                    }

                    return item;
                }),
            };
        },
    },
});

const selectorDatapointsCache = (state: RootState) => {
    return state.gerenciadorPrecosReducer.datapointsCache;
};

const {
    RESET_DATAPOINTS_CACHE,
    ADD_DATAPOINT_CACHE,
    REMOVE_DATAPOINT_CACHE,
    UPDATE_DATAPOINT_CACHE_BY_ID,
} = slice.actions;

export {
    ADD_DATAPOINT_CACHE,
    initialState,
    REMOVE_DATAPOINT_CACHE,
    RESET_DATAPOINTS_CACHE,
    selectorDatapointsCache,
    UPDATE_DATAPOINT_CACHE_BY_ID,
};

export default slice.reducer;
