import React from 'react';
import { Card, CardProps, LinkButtonProps } from '../../atoms';
import './style.scss';

export type CompetitivenessCard = {
    competitiveness: number;
} & CardProps &
    Pick<LinkButtonProps, 'onClick'>;

export const CompetitivenessCard = ({
    competitiveness,
    className = '',
    // onClick,
    ...props
}: CompetitivenessCard) => (
    <Card
        className={['competitiveness-card', className].join(' ')}
        hasBorder
        variant="filled"
        {...props}
    >
        <Card.Header>
            <Card.Title>{competitiveness}%</Card.Title>
        </Card.Header>
        <Card.Content>
            <p>Competitividade desejada</p>
            {/* <LinkButton onClick={onClick}>Alterar</LinkButton> */}
        </Card.Content>
    </Card>
);
