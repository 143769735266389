import React from 'react';
import { useGerenciadorPrecos } from '../../../../context';
import { Flex } from '../../../atoms';
import { Cell } from './Cell';
import { NumericProps } from './Numeric';

type CompetitivenessProps = Omit<NumericProps, 'dataKey'>;

export const Competitiveness = ({ index, ...props }: CompetitivenessProps) => {
    const { data } = useGerenciadorPrecos();
    const hasCompetitorPrice = !!data.content[index].competitorPrice;
    return (
        <div className="gerenciador-table__cell__container">
            <Cell.Numeric
                dataKey="initialCompetitiveness"
                index={index}
                suffix="%"
                editable
                disabled={!hasCompetitorPrice}
                {...props}
            />
            <Flex
                className="font-size-50-semibold subtitle"
                align="center"
                gap="2px"
            >
                <p>era </p>
                {hasCompetitorPrice ? (
                    <Cell.Numeric
                        className="font-size-50-semibold subtitle"
                        dataKey="competitiveness"
                        index={index}
                        suffix="%"
                        prefix=""
                        {...props}
                    />
                ) : (
                    <>--</>
                )}
            </Flex>
        </div>
    );
};
