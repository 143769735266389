import classNames from 'classnames';
import React, { useCallback } from 'react';
import CurrencyInput from 'react-currency-input';
import { CurrencyInputProps } from '../../@types/CurrencyInput';
import styles from './InputCurrency.module.scss';

export type InputCurrencyProps = CurrencyInputProps & {
    skin?: 'gray' | 'blue' | 'red';
    onBlur?: (
        event: React.FocusEvent<HTMLInputElement, Element>,
        value: number | null,
        details: {
            hasChanges: boolean;
            onFocusValue: number | null;
            onBlurValue: number | null;
        },
    ) => void;
};

const InputCurrency = ({
    className,
    skin = 'gray',
    value,
    onFocus,
    onBlur,
    ...props
}: InputCurrencyProps) => {
    const [focusValue, setFocusValue] = React.useState<number | null>(
        () => value,
    );

    const handleFocus = useCallback(
        (e: React.FocusEvent<HTMLInputElement, Element>) => {
            setFocusValue(value);
            e.target.select();
            onFocus?.(e);
        },
        [onFocus],
    );

    const handleBlur = useCallback(
        (e: React.FocusEvent<HTMLInputElement, Element>) => {
            onBlur?.(e, value, {
                onFocusValue: focusValue,
                onBlurValue: value,
                hasChanges: value !== focusValue,
            });
        },
        [onBlur],
    );

    return (
        <CurrencyInput
            className={classNames(styles.input, className)}
            data-skin={skin}
            value={value}
            onFocus={handleFocus}
            onBlur={handleBlur}
            {...props}
        />
    );
};

export default InputCurrency;
