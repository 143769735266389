import React from 'react';

export const ModalCalculationInfoContent = () => (
    <>
        <div className="content">
            <p>
                <b>Correspondência de produtos</b>
            </p>
            <p>
                Para calcular a competitividade, comparamos somente os preços de
                produtos em comum entre a rede referência e cada uma das demais
                redes selecionadas.
            </p>
        </div>
        <br />
        <div className="content">
            <p>
                <b>Período selecionado</b>
            </p>
            <p>
                O cálculo de competitividade leva em consideração a média dos
                preços no período selecionado, consolidados semanalmente com os
                preços coletados entre domingo e sábado de cada semana. O
                período leva em consideração as semanas fechadas somadas aos
                dias da semana corrente ao cálculo.
            </p>
        </div>
        <br />
        <div className="content">
            <p>
                <b>Cálculo de competitividade</b>
            </p>
            <p>
                Para chegar no índice de competitividade, dividimos o preço de
                cada produto na rede referência pelo preço em cada rede
                selecionada, estabelecendo um índice de base 100, sendo {'>'}
                100 mais caro que a concorrência e {'<'}
                100 mais barato que a concorrência. Então calculamos a média da
                competitividade por produto para determinar o índice de
                competitividade da rede. A competitividade geral é a média dos
                índices de competitividade das redes analisadas.
            </p>
        </div>
    </>
);
