import { combineReducers } from '@reduxjs/toolkit';
import { FiltersStateProps } from '../../@types/FiltersTypes';
import { GerenciadorPrecosSavedFilterProps } from '../gerenciadorPrecos';
import filtrosSlice from './filtrosState';
import modalsSlice, { NegociacaoFornecedorModalsStateProps } from './modals';
import savedFilter from './savedFilters';

export type NegociacaoFornecedorStateProps = {
    filtros: FiltersStateProps;
    savedFilter: GerenciadorPrecosSavedFilterProps;
    modals: NegociacaoFornecedorModalsStateProps;
};

const rootReducer = combineReducers({
    filtros: filtrosSlice,
    modals: modalsSlice,
    savedFilter,
});

export const selectorNegociacaoFornecedor = (
    state: Record<
        'negociacaoFornecedorReducer',
        NegociacaoFornecedorStateProps
    >,
) => state.negociacaoFornecedorReducer;

export default rootReducer;
