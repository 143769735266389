import React, { useState, createRef, useEffect } from 'react';
import {
    Container,
    FlexboxGrid,
    Icon,
    Table,
    Input,
    InputGroup,
    Col,
    Checkbox,
} from 'rsuite';
import { LoadingSpiner } from '../../../../../../components/LoadingSpinner';
import { colunasTableLojasParticipantesModal } from './Columns';
import Modal from '../../../../../../components/NewModalConfirmacao';
import BotaoFiltro from './Filtro';

const {
 Column, HeaderCell, Cell, Pagination,
} = Table;

const LojaParticipante = ({
    lojasParticipantesSelecionadas,
    setLojasParticipantesSelecionadas,
    tiposLoja,
    clusters,
    listLojas,
    lojas,
}) => {
    const [buscaLoja, setBuscaLoja] = useState('');
    const [page, setPage] = useState(0);
    const [size, setSize] = useState(7);
    const [tipoLojaSelecionado, setTipoLojaSelecionado] = useState({});
    const [clusterSelecionado, setClusterSelecionado] = useState('');
    const [checked, setChecked] = useState(false);
    const [indeterminate, setIndeterminate] = useState(false);
    const tiposLojaRef = createRef();
    const clusterRef = createRef();

    const handleSelectMenu = (value, type) => {
        switch (type) {
            case 'tipoLoja':
                setTipoLojaSelecionado(value);
                tiposLojaRef.current.hide();
                break;
            case 'cluster':
                setClusterSelecionado(value);
                clusterRef.current.hide();
                break;
            default:
                break;
        }
    };

    const handleCheckAll = (value, checked) => {
        const checkedKeys = checked ? lojas?.content : [];
        setLojasParticipantesSelecionadas(checkedKeys);
    };

    const handleCheck = (value, checked) => {
        const nextCheckedKeys = checked
            ? [...lojasParticipantesSelecionadas, value]
            : lojasParticipantesSelecionadas.filter(
                  (item) => item.store_id !== value.store_id,
              );
        setLojasParticipantesSelecionadas(nextCheckedKeys);
    };

    useEffect(() => {
        listLojas(
            page,
            size,
            tipoLojaSelecionado,
            clusterSelecionado,
            buscaLoja,
        );
    }, [page, size, tipoLojaSelecionado, clusterSelecionado, buscaLoja]);

    useEffect(() => {
        if (lojasParticipantesSelecionadas.length === lojas.content.length) {
            setChecked(true);
        } else if (lojasParticipantesSelecionadas.length === 0) {
            setChecked(false);
            setIndeterminate(false);
        } else if (
            lojasParticipantesSelecionadas.length > 0
            && lojasParticipantesSelecionadas.length !== lojas.content.length
        ) {
            setIndeterminate(true);
        }
    }, [lojasParticipantesSelecionadas, lojas]);

    return (
        <Container>
            <LoadingSpiner size="md" />
            <FlexboxGrid className="lojas-participantes-modal" justify="space-between">
                <FlexboxGrid.Item colspan={24}>
                    <InputGroup inside className="input-outline">
                        <InputGroup.Addon>
                            <Icon className="icon-busca" icon="search" />
                        </InputGroup.Addon>
                        <Input
                            placeholder="Busque por uma loja"
                            value={buscaLoja}
                            onChange={(value) => setBuscaLoja(value)}
                        />
                    </InputGroup>
                </FlexboxGrid.Item>
                <FlexboxGrid.Item colspan={24}>
                    <div className="campanhas-filtros">
                        <FlexboxGrid justify="space-between">
                            <FlexboxGrid.Item componentClass={Col} lg={16}>
                                <FlexboxGrid className="filtros-container">
                                    <FlexboxGrid.Item componentClass={Col}>
                                        <BotaoFiltro
                                            filtroRef={tiposLojaRef}
                                            label="Tipos de loja"
                                            data={tiposLoja}
                                            type="tipoLoja"
                                            itemsSelected={
                                                tipoLojaSelecionado.value
                                            }
                                            onSelect={handleSelectMenu}
                                            showValue
                                        />
                                    </FlexboxGrid.Item>
                                    <FlexboxGrid.Item componentClass={Col}>
                                        <BotaoFiltro
                                            filtroRef={clusterRef}
                                            label="Cluster"
                                            data={clusters}
                                            type="cluster"
                                            itemsSelected={clusterSelecionado}
                                            onSelect={handleSelectMenu}
                                        />
                                    </FlexboxGrid.Item>
                                </FlexboxGrid>
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                    </div>
                </FlexboxGrid.Item>
                <FlexboxGrid.Item colspan={24}>
                    <div className="campanhas-table-container">
                        <Table
                            className="table"
                            data={lojas.content}
                            height={380}
                        >
                            {colunasTableLojasParticipantesModal.map((coluna) => (coluna.dataKey !== 'store_id' ? (
                                <Column
                                    flexGrow={coluna.flexGrow}
                                    sortable={coluna.sortable}
                                    align={coluna.align}
                                    fixed={coluna.fixed}
                                    width={coluna.width}
                                    key={Math.random()}
                                >
                                    <HeaderCell>
                                        {coluna.headerCell}
                                    </HeaderCell>
                                    <Cell dataKey={coluna.dataKey} />
                                </Column>
                                ) : (
                                    <Column
                                        className="check-lojas"
                                        flexGrow={coluna.flexGrow}
                                        sortable={coluna.sortable}
                                        align={coluna.align}
                                        fixed={coluna.fixed}
                                        key={Math.random()}
                                    >
                                        <HeaderCell>
                                            <div style={{ lineHeight: '40px' }}>
                                                <Checkbox
                                                    checked={checked}
                                                    indeterminate={
                                                        indeterminate
                                                    }
                                                    onChange={handleCheckAll}
                                                />
                                            </div>
                                        </HeaderCell>
                                        <Cell dataKey={coluna.dataKey}>
                                            {(rowData) => (
                                                <Checkbox
                                                    value={rowData}
                                                    onChange={handleCheck}
                                                    checked={lojasParticipantesSelecionadas.some(
                                                        (item) => item.store_id
                                                            === rowData.store_id,
                                                    )}
                                                />
                                            )}
                                        </Cell>
                                    </Column>
                                )))}
                        </Table>
                        <Pagination
                            lengthMenu={[
                                {
                                    value: 7,
                                    label: 7,
                                },
                                {
                                    value: 20,
                                    label: 20,
                                },
                                {
                                    value: 40,
                                    label: 40,
                                },
                            ]}
                            activePage={lojas.number + 1}
                            displayLength={lojas.size}
                            total={lojas.totalElements}
                            onChangePage={(value) => setPage(value - 1)}
                            onChangeLength={(value) => setSize(value)}
                        />
                    </div>
                </FlexboxGrid.Item>
            </FlexboxGrid>
        </Container>
    );
};

const ModalLojasParticipantes = ({
    show,
    confirmLoja,
    closeLoja,
    id,
    lojasParticipantesSelecionadas,
    setLojasParticipantesSelecionadas,
    tiposLoja,
    clusters,
    listLojas,
    lojas,
}) => (
    <Modal
        show={show}
        onConfirm={confirmLoja}
        onCancel={closeLoja}
        title="Adicionar Lojas"
        subtitle="Selecione as lojas que você quer adicionar na campanha"
        message={LojaParticipante({
            id,
            lojasParticipantesSelecionadas,
            setLojasParticipantesSelecionadas,
            tiposLoja,
            clusters,
            listLojas,
            lojas,
        })}
        confirmButton="Adicionar Lojas"
        cancelButton="Cancelar"
        width="774px"
        height="864px"
    />
);

export default ModalLojasParticipantes;
