import React from 'react';
import { Card } from '../../../../../components/atoms/Card';
import { useOnboarding } from '../../../../../context';
import { Layout } from '../layout';
import { ConcorrenteForm } from './ConcorrenteForm';

export const Concorrente = () => {
    const { increment, decrement } = useOnboarding();
    return (
        <>
            <Layout.Title>
                Nos ajude a encontrar o <br /> preço concorrente
            </Layout.Title>
            <Layout.Content>
                <Layout.Section>
                    <p>
                        Usamos os dados do <b>InfoPanel</b> para incrementar os
                        preços concorrentes.
                        <b> Defina como você prefere usar esses dados</b>
                    </p>
                </Layout.Section>
                <Layout.Section className="text-start">
                    <ConcorrenteForm />
                    <Card className="time-period-card" isPrimary disableHover>
                        <Card.Header>
                            <Card.Title>Período de tempo</Card.Title>
                        </Card.Header>
                        <Card.Content className=" text-start">
                            <p className="font-size-100-regular">
                                Para garantir a qualidade dos preços, usamos os
                                dados de concorrentes dos
                                <b> últimos 14 dias.</b>
                            </p>
                        </Card.Content>
                    </Card>
                </Layout.Section>
                <Layout.ButtonGroup>
                    <Layout.Button theme="ghost" onClick={decrement}>
                        VOLTAR
                    </Layout.Button>
                    <Layout.Button onClick={increment}>AVANÇAR</Layout.Button>
                </Layout.ButtonGroup>
            </Layout.Content>
        </>
    );
};
