import React from 'react';
import { useStringArrayReducer, useToggle } from '../../../../hooks';
import { NegotiationDataItemProps } from '../pages';

export type SelectedDatapointsState = {
    selected: string[];
    excluded: string[];
    selectedAll: boolean;
};

type Action = {
    key: 'selected' | 'excluded';
    type: 'SET' | 'ADD' | 'REMOVE' | 'RESET';
    payload: any;
};

export type UseSelectedDatapointsProps = {
    data: {
        selectedAll: boolean;
        selected: string[];
        excluded: string[];
    };
    isEmpty: boolean;
    length: number;
    currentState: string[];
    set: (key: Action['key'], payload: Action['payload']) => void;
    add: (key: Action['key'], payload: Action['payload']) => void;
    remove: (key: Action['key'], payload: Action['payload']) => void;
    reset: () => void;
    toggleSelectedAll: (payload?: boolean) => void;
    onCheckAll: (content: any[], isChecked: boolean) => void;
    onSelectAll: () => void;
    onCheck: (value: string, isChecked: boolean) => void;
};

export const useSelectedDatapoints = (): UseSelectedDatapointsProps => {
    const [
        selected,
        {
            SET: setSelected,
            ADD: addSelected,
            REMOVE: removeSelected,
            RESET: resetSelected,
        },
    ] = useStringArrayReducer();

    const [
        excluded,
        {
            SET: setExcluded,
            ADD: addExcluded,
            REMOVE: removeExcluded,
            RESET: resetExcluded,
        },
    ] = useStringArrayReducer();

    const handlers = {
        selected: {
            SET: setSelected,
            ADD: addSelected,
            REMOVE: removeSelected,
            RESET: resetSelected,
        },
        excluded: {
            SET: setExcluded,
            ADD: addExcluded,
            REMOVE: removeExcluded,
            RESET: resetExcluded,
        },
    } as const;

    const [
        selectedAll,
        { toggle: toggleSelectedAll, set: setSelectedAll, toggleOff },
    ] = useToggle();

    const handleSetData = (key: Action['key'], payload: Action['payload']) => {
        handlers[key].SET(payload);
    };

    const handleAddData = (key: Action['key'], payload: Action['payload']) => {
        handlers[key].ADD(payload);
    };

    const handleRemoveData = (
        key: Action['key'],
        payload: Action['payload'],
    ) => {
        handlers[key].REMOVE(payload);
    };

    const handleResetData = () => {
        resetSelected();
        resetExcluded();
    };

    const handleToggleData = (payload?: boolean) => {
        if (!payload) {
            return toggleSelectedAll();
        }
        return setSelectedAll(payload);
    };

    const currentState = selectedAll ? excluded : selected;

    const isEmpty = !(!!currentState.length || selectedAll);

    const { length } = currentState;

    const handleCheckAll = (
        content: NegotiationDataItemProps[],
        isChecked: boolean,
    ) => {
        if (selectedAll) {
            toggleOff();
        }

        if (isChecked) {
            const allDataPoints = content.map(
                (datapoint) => datapoint.productsToBePricedId,
            );
            setSelected(allDataPoints);
            return;
        }

        setSelected([]);
    };

    const handleSelectAll = () => {
        toggleSelectedAll();
        if (selectedAll) {
            setExcluded([]);
            setSelected([]);
        }
    };

    const handleCheckDatapoint = (value: string, isChecked: boolean) => {
        if (selectedAll) {
            if (isChecked) {
                removeExcluded(value);
                return;
            }
            removeExcluded(value);
            return;
        }

        if (isChecked) {
            addSelected(value);
            return;
        }

        removeSelected(value);
    };

    const state = React.useMemo(
        () => ({
            selected,
            excluded,
            selectedAll,
        }),
        [selected, excluded, selectedAll],
    );

    return {
        data: state,
        set: handleSetData,
        add: handleAddData,
        remove: handleRemoveData,
        reset: handleResetData,
        toggleSelectedAll: handleToggleData,
        onCheckAll: handleCheckAll,
        onSelectAll: handleSelectAll,
        onCheck: handleCheckDatapoint,
        isEmpty,
        length,
        currentState,
    };
};
