import downloadFileIcon from '../../../../assets/icons/icon_down-arrow-doted-circle.svg';
import iconNofileDownloaded from '../../../../assets/icons/icon_no-file-downloaded.svg';

export const fileDownloadStatus = {
    showModalConfirmDownload: {
        title: 'Precisamos de um tempo para processar o relatório',
        message:
            'Acompanhe o status da solicitação em Histórico de Relatórios ou aguarde o e-mail que enviaremos' +
            ' quando o arquivo estiver disponível para download',
        confirmButton: 'ver histórico de relatórios',
        cancelButton: 'criar novo relatório',
        icon: downloadFileIcon,
    },
    413: {
        title: 'A solicitação é muito grande para ser processada',
        message:
            'Não foi possível processar o relatório, pois ele excede a capacidade de linhas do Excel (1 milhão de linhas). Revise os filtros e tente novamente.',
        confirmButton: 'REVISAR FILTROS',
        cancelButton: 'FECHAR',
        icon: iconNofileDownloaded,
        closeButton: false,
    },
    410: {
        title: 'Nenhum resultado encontrado',
        message:
            'Não foram encontrados dados para download. Revise os filtros e tente novamente.',
        confirmButton: null,
        cancelButton: 'FECHAR',
        icon: iconNofileDownloaded,
        closeButton: false,
    },
    500: {
        title: 'Tente novamente',
        message:
            'Não conseguimos processar sua solicitação, por favor, tente novamente em alguns segundos',
        confirmButton: null,
        cancelButton: 'FECHAR',
        icon: iconNofileDownloaded,
        closeButton: false,
    },
    503: {
        title: 'Tente novamente',
        message:
            'Não conseguimos processar sua solicitação, por favor, tente novamente em alguns segundos',
        confirmButton: null,
        cancelButton: 'FECHAR',
        icon: iconNofileDownloaded,
        closeButton: false,
    },
};
