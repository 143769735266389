import Immutable from 'seamless-immutable';
import {
    DELETE_FILTRO_REVISAO,
    ADD_FILTRO_REVISAO,
    CHANGE_CACHE_FILTRO,
    CHANGE_CACHE_FILTRO_LOJA,
    SELECT_VALUE_FILTRO_REVISAO,
    SELECT_VALUE_FILTRO_LOJAS_REVISAO,
    CHANGE_VALUE_FILTRO_REVISAO,
    CHANGE_VALUE_FILTRO_LOJA_REVISAO,
    LIST_ITENS_FILTROS_REVISAO,
    LIST_SEGMENTOS,
    LIST_ITENS_SEGMENTOS_CLASSES_REVISAO,
    LIST_ITENS_PRODUTO_REVISAO,
    LIST_ITENS_FAMILIA_PRODUTO_REVISAO,
    LIST_ITENS_LOJA_REVISAO,
    LIST_RULE_TYPES_REVISAO,
    CLEAR_FILTROS_PRODUTOS,
} from '../actions/actionsRevisao';

const revisaoInitialState = Immutable({
    tiposFiltro: [
        {
            title: 'PRODUTO',
            itens: [
                {
                    name: 'productId',
                    title: 'Produto',
                    eventKey: 'productId',
                    nivel: 'productId',
                },
                {
                    name: 'productFamilyId',
                    title: 'Família',
                    eventKey: 'productFamilyId',
                    nivel: 'productFamilyId',
                },
            ],
        },
        {
            title: 'LOJAS',
            itens: [
                {
                    name: 'storeName',
                    title: 'Lojas',
                    eventKey: 'storeName',
                    nivel: 9,
                },
            ],
        },
        {
            title: 'REGRAS',
            itens: [
                {
                    name: 'ruleType',
                    title: 'Tipo de regras',
                    eventKey: 'ruleType',
                    nivel: 9,
                },
            ],
        },
        {
            title: 'SEGMENTAÇÃO',
            itens: [
                {
                    name: 'sensibilityType',
                    title: 'Exclusiva',
                    eventKey: 'sensibilityType',
                    nivel: 8,
                },
                {
                    name: 'infoPriceSensibility',
                    title: 'Sensibilidade',
                    eventKey: 'infoPriceSensibility',
                    nivel: 'infoPriceSensibility',
                },
                {
                    name: 'abcClass',
                    title: 'Curva',
                    eventKey: 'abcClass',
                    nivel: 9,
                },
                {
                    name: 'infoPriceAbcClass',
                    title: 'Curva ABC Local',
                    eventKey: 'infoPriceAbcClass',
                    nivel: 'infoPriceAbcClass',
                },
                {
                    name: 'infoPriceGlobalAbcClass',
                    title: 'Curva ABC Global',
                    eventKey: 'infoPriceGlobalAbcClass',
                    nivel: 'infoPriceGlobalAbcClass',
                },
            ],
        },
    ],
    filtros: [
        {
            name: 'productId',
            nivel: 'productId',
            cleanable: true,
            filter: false,
            title: 'Produto',
            placeholder: 'Selecione um ou mais itens',
            locale: {
                noResultsText: 'Nenhum item encontrado',
                searchPlaceholder: 'Digite ou busque o item desejado',
            },
            onSelect: 'handleSelect',
            onSearch: 'searchProduto',
        },
        {
            name: 'productFamilyId',
            nivel: 'productFamilyId',
            cleanable: true,
            filter: false,
            title: 'Família',
            placeholder: 'Selecione um ou mais itens',
            locale: {
                noResultsText: 'Nenhum item encontrado',
                searchPlaceholder: 'Digite ou busque o item desejado',
            },
            onSelect: 'handleSelect',
            onSearch: 'searchFamiliaProduto',
        },
        {
            name: 'storeName',
            nivel: 'storeName',
            cleanable: true,
            filter: false,
            title: 'Lojas',
            placeholder: 'Selecione uma loja',
            locale: {
                noResultsText: 'Nenhum item encontrado',
                searchPlaceholder: 'Digite ou busque o item desejado',
            },
            onSelect: 'handleSelect',
            onSearch: 'searchStore',
        },
        {
            name: 'ruleType',
            nivel: 'ruleType',
            cleanable: true,
            filter: true,
            title: 'Tipo de regra',
            placeholder: 'Selecione um tipo de regra',
            locale: { noResultsText: 'Nenhum item encontrado' },
            onSelect: 'handleSelect',
            onSearch: 'searchRuleType',
        },
        {
            name: 'sensibilityType',
            nivel: 8,
            cleanable: true,
            filter: false,
            title: 'Exclusiva',
            placeholder: 'Selecione um ou mais itens',
            locale: {
                noResultsText: 'Nenhum item encontrado',
                searchPlaceholder: 'Digite ou busque o item desejado',
            },
            onSelect: 'handleSelect',
            onSearch: 'searchSegmentosSensibilities',
            onChange: 'handleChange',
        },
        {
            name: 'infoPriceSensibility',
            nivel: 'infoPriceSensibility',
            cleanable: true,
            filter: false,
            title: 'Sensibilidade',
            placeholder: 'Selecione um ou mais itens',
            locale: {
                noResultsText: 'Nenhum item encontrado',
                searchPlaceholder: 'Digite ou busque o item desejado',
            },
            onSelect: 'handleSelect',
            onChange: 'handleChange',
        },
        {
            name: 'abcClass',
            nivel: 9,
            cleanable: true,
            filter: false,
            title: 'Curva',
            placeholder: 'Selecione um ou mais itens',
            locale: {
                noResultsText: 'Nenhum item encontrado',
                searchPlaceholder: 'Digite ou busque o item desejado',
            },
            onSelect: 'handleSelect',
            onSearch: 'searchSegmentosClasses',
            onChange: 'handleChange',
        },
        {
            name: 'infoPriceAbcClass',
            nivel: 'infoPriceAbcClass',
            cleanable: true,
            filter: false,
            title: 'Curva ABC Local',
            placeholder: 'Selecione um ou mais itens',
            locale: {
                noResultsText: 'Nenhum item encontrado',
                searchPlaceholder: 'Digite ou busque o item desejado',
            },
            onSelect: 'handleSelect',
            onChange: 'handleChange',
        },
        {
            name: 'infoPriceGlobalAbcClass',
            nivel: 'infoPriceGlobalAbcClass',
            cleanable: true,
            filter: false,
            title: 'Curva ABC Global',
            placeholder: 'Selecione um ou mais itens',
            locale: {
                noResultsText: 'Nenhum item encontrado',
                searchPlaceholder: 'Digite ou busque o item desejado',
            },
            onSelect: 'handleSelect',
            onChange: 'handleChange',
        },
    ],
    filtrosAdicionados: [],
    filtrosData: {
        storeName: {
            lista: [],
            value: [],
            cache: [],
        },
        productId: {
            lista: [],
            value: [],
            cache: [],
        },
        productFamilyId: {
            lista: [],
            value: [],
            cache: [],
        },
        ruleType: {
            lista: [],
            value: [],
            cache: [],
        },
        sensibilityType: {
            lista: [],
            value: [],
            cache: [],
        },
        abcClass: {
            lista: [],
            value: [],
            cache: [],
        },
        infoPriceAbcClass: {
            lista: [
                { label: 'Local A', value: 'A' },
                { label: 'Local B', value: 'B' },
                { label: 'Local C', value: 'C' },
                { label: 'Local D', value: 'D' },
            ],
            value: [],
            cache: [],
        },
        infoPriceGlobalAbcClass: {
            lista: [
                { label: 'Global A', value: 'A' },
                { label: 'Global B', value: 'B' },
                { label: 'Global C', value: 'C' },
                { label: 'Global D', value: 'D' },
            ],
            value: [],
            cache: [],
        },
        infoPriceSensibility: {
            lista: [
                { label: 'Sensível', value: 'SENSITIVE' },
                { label: 'Não Sensível', value: 'NOT_SENSITIVE' },
                { label: 'Super Sensível', value: 'SUPER_SENSITIVE' },
            ],
            value: [],
            cache: [],
        },
    },
    loja: null,
    lojas: [],
    simularMultiplasLojas: false,
});

// eslint-disable-next-line default-param-last
function revisaoDataReducer(state = revisaoInitialState, action) {
    switch (action.type) {
        case ADD_FILTRO_REVISAO:
            return state.merge({
                filtrosAdicionados: [
                    ...state.filtrosAdicionados,
                    action.filtro,
                ],
            });
        case DELETE_FILTRO_REVISAO:
            return state.merge({
                filtrosAdicionados: state.filtrosAdicionados.filter(
                    (item, index) => index !== action.index,
                ),
                filtrosData: {
                    ...state.filtrosData,
                    [`${action.name}`]: {
                        ...state.filtrosData[`${action.name}`],
                        value: [],
                    },
                },
            });
        case SELECT_VALUE_FILTRO_REVISAO:
            return state.merge({
                ...state,
                filtrosData: {
                    ...state.filtrosData,
                    [`${action.name}`]: {
                        ...state.filtrosData[`${action.name}`],
                        value: action.item,
                    },
                },
            });
        case SELECT_VALUE_FILTRO_LOJAS_REVISAO:
            return state.merge({
                ...state,
                filtrosData: {
                    ...state.filtrosData,
                    [`${action.name}`]: {
                        ...state.filtrosData[`${action.name}`],
                        cache: [
                            ...state.filtrosData[`${action.name}`].cache,
                            action.item,
                        ],
                    },
                },
            });
        case CHANGE_VALUE_FILTRO_REVISAO:
            return state.merge({
                ...state,
                filtrosData: {
                    ...state.filtrosData,
                    [`${action.name}`]: {
                        ...state.filtrosData[`${action.name}`],
                        value: action.value,
                    },
                },
            });
        case CHANGE_VALUE_FILTRO_LOJA_REVISAO:
            return state.merge({
                ...state,
                filtrosData: {
                    ...state.filtrosData,
                    [`${action.name}`]: {
                        ...state.filtrosData[`${action.name}`],
                        value: action.value,
                    },
                },
            });
        case CHANGE_CACHE_FILTRO:
            return state.merge({
                ...state,
                filtrosData: {
                    ...state.filtrosData,
                    [`${action.name}`]: {
                        ...state.filtrosData[`${action.name}`],
                        cache: action.cache,
                    },
                },
            });
        case CHANGE_CACHE_FILTRO_LOJA:
            return state.merge({
                ...state,
                filtrosData: {
                    ...state.filtrosData,
                    [`${action.name}`]: {
                        ...state.filtrosData[`${action.name}`],
                        cache: action.cache,
                    },
                },
            });
        case LIST_ITENS_FILTROS_REVISAO:
            return state.merge({
                ...state,
                filtrosData: {
                    ...state.filtrosData,
                    [`${action.name}`]: {
                        ...state.filtrosData[`${action.name}`],
                        lista: action.itens?.map((item) => ({
                            label: item,
                            value: item,
                        })),
                    },
                },
            });
        case LIST_SEGMENTOS:
            return state.merge({
                ...state,
                tiposFiltro: state.tiposFiltro.some(
                    (item) => item.title === 'CATEGORIZAÇÃO',
                )
                    ? state.tiposFiltro
                    : [
                          ...state.tiposFiltro,
                          {
                              title: 'CATEGORIZAÇÃO',
                              itens: action.itens.map((item) => ({
                                  name: item.field,
                                  title: item.name,
                                  eventKey: item.field,
                                  nivel: item.level,
                              })),
                          },
                      ],
                filtros: state.tiposFiltro.some(
                    (item) => item.title === 'CATEGORIZAÇÃO',
                )
                    ? state.filtros
                    : state.filtros.concat(
                          action.itens.map((item) => ({
                              name: item.field,
                              nivel: item.level,
                              cleanable: true,
                              filter: false,
                              title: item.name,
                              placeholder: `Selecione uma ${item.name.toLowerCase()}`,
                              locale: {
                                  noResultsText: 'Nenhum item encontrado',
                              },
                              onSelect: 'handleSelect',
                              onSearch: 'search',
                          })),
                      ),
                filtrosData: { ...action.segmentos, ...state.filtrosData },
            });
        case LIST_ITENS_SEGMENTOS_CLASSES_REVISAO:
            return state.merge({
                ...state,
                filtrosData: {
                    ...state.filtrosData,
                    [`${action.name}`]: {
                        ...state.filtrosData[`${action.name}`],
                        lista: action.itens?.map((item) => ({
                            label: item.abc_class || item.sensibility_type,
                            value: item.abc_class || item.sensibility_type,
                        })),
                    },
                },
            });
        case LIST_ITENS_PRODUTO_REVISAO:
            return state.merge({
                ...state,
                filtrosData: {
                    ...state.filtrosData,
                    [`${action.name}`]: {
                        ...state.filtrosData[`${action.name}`],
                        lista: action.itens.data
                            .map((item) => ({
                                label: `${item.product_id} - ${item.description}`,
                                value: item.product_id,
                                gtin: item.gtin,
                                product_id: item.product_id,
                            }))
                            .concat(
                                state.filtrosData[
                                    `${action.name}`
                                ].cache.filter(
                                    (item) => action.itens.data.findIndex(
                                            (itemLista) => itemLista.product_id
                                                === item.product_id,
                                        ) === -1,
                                ),
                            ),
                    },
                },
            });
        case LIST_ITENS_FAMILIA_PRODUTO_REVISAO:
            return state.merge({
                ...state,
                filtrosData: {
                    ...state.filtrosData,
                    [`${action.name}`]: {
                        ...state.filtrosData[`${action.name}`],
                        lista: action?.itens?.product_family_ids
                            ?.map((item) => ({
                                label: item,
                                value: item,
                            }))
                            .concat(
                                state.filtrosData[
                                    `${action.name}`
                                ]?.cache?.filter(
                                    (item) => action.itens?.product_family_ids?.findIndex(
                                            (itemLista) => itemLista === item?.value,
                                        ) === -1,
                                ),
                            ),
                    },
                },
            });
        case LIST_ITENS_LOJA_REVISAO:
            return state.merge({
                ...state,
                filtrosData: {
                    ...state.filtrosData,
                    [`${action.name}`]: {
                        ...state.filtrosData[`${action.name}`],
                        lista: action.itens
                            .map((item) => ({
                                label: `${item.storeId} - ${item.store}`,
                                value: item.store,
                            }))
                            .concat(
                                state.filtrosData[
                                    `${action.name}`
                                ].cache.filter(
                                    (item) => action.itens.findIndex(
                                            (itemLista) => itemLista === item,
                                        ) === -1,
                                ),
                            ),
                    },
                },
            });
        case LIST_RULE_TYPES_REVISAO:
            return state.merge({
                ...state,
                filtrosData: {
                    ...state.filtrosData,
                    [`${action.name}`]: {
                        ...state.filtrosData[`${action.name}`],
                        lista: action.itens
                            .map((item) => ({
                                label: item.label || item,
                                value: item.value || item,
                            }))
                            .concat(
                                state.filtrosData[
                                    `${action.name}`
                                ].cache.filter(
                                    (item) => action.itens.findIndex(
                                            (itemLista) => itemLista === item,
                                        ) === -1,
                                ),
                            ),
                    },
                },
            });
        case CLEAR_FILTROS_PRODUTOS:
            return state.merge({
                ...state,
                filtrosData: revisaoInitialState.filtrosData,
                filtrosAdicionados: revisaoInitialState.filtrosAdicionados,
            });
        default:
            return state;
    }
}

export default revisaoDataReducer;
