export const handleMoveFocusToNextColumnInput = (
    e: KeyboardEvent,
    targetClasses: string[] = [],
) => {
    const targetClass = (e.target as HTMLElement).className.replace(' ', '.');

    if (
        e.key === 'Enter' &&
        targetClasses.some((cls) => targetClass.match(cls))
    ) {
        const nodeList = (e.currentTarget as HTMLElement).querySelectorAll(
            `.${targetClass}`,
        );
        const currentIndex = Array.from(nodeList).indexOf(
            e.target as HTMLInputElement,
        );
        const nextIndex = currentIndex + 1;
        if (nextIndex < nodeList.length) {
            const nextInput = nodeList[nextIndex] as HTMLInputElement;
            nextInput.focus();
        }
    }
};
