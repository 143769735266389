import React from 'react';
import { Tag } from '../atoms';
import { Card } from '../atoms/Card';

export const UploadCard = () => (
    <Card hasBorder style={{ pointerEvents: 'none' }}>
        <Card.Header>
            <Card.Icon icon="MdUpload" />
            <Card.Title>
                Envie seus dados
                <Tag skin="blue">EM BREVE</Tag>
            </Card.Title>
        </Card.Header>
        <Card.Content>
            <p>
                Tenha mais confiança nos seus
                <br />
                preços fazendo o upload dos seus dados
            </p>
        </Card.Content>
    </Card>
);
