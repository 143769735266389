import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectorExtracaoDados } from '../../../../../reducers/extracaoDados';
import { setEcomValues } from '../../../../../reducers/extracaoDados/ecom';
import * as ProdutoService from '../../../../../services/ProdutoService';

export const useProduto = () => {
    const [produtos, setProdutos] = useState({
        list: [],
        cache: [],
        value: [],
    });
    const [checkAllProducts, setCheckAllProducts] = useState(false);
    const [timer, setTimer] = useState(null);
    const [loading, setLoading] = useState(false);

    const dispatch = useDispatch();

    const { ecom } = useSelector(selectorExtracaoDados);

    const setList = (list) => {
        const newList = list.map((item) => ({
            id: `${item.codigoCliente}-${item.identificadorCliente}`,
            value: item.identificadorCliente,
            label: `${item.identificadorCliente} - ${item.descricaoCliente}`,
            codigo: item.codigoCliente,
            identificadorCliente: item.identificadorCliente,
        }));

        const cacheList = produtos.cache;
        const removeDuplicate = newList.filter(
            (i) => !produtos.value.includes(i.value),
        );
        const concatList = produtos.value.length
            ? cacheList.concat(removeDuplicate)
            : newList;

        setProdutos((prevState) => ({
            ...prevState,
            list: concatList,
        }));
    };

    const handleAddProduct = (item) => {
        const hasValue = produtos.value.includes(item.value);
        if (!hasValue) {
            const newList = produtos.value;
            const { cache } = produtos;
            const newValue = newList.concat(item.value);
            const newCache = cache.concat(item);
            setProdutos((prevState) => ({
                ...prevState,
                value: newValue,
                cache: newCache,
            }));
        }
    };

    const addAllProducts = () => {
        const newList = produtos.list;
        const newValue = newList.map((item) => item.value);
        setProdutos((prevState) => ({
            ...prevState,
            value: newValue,
            cache: produtos.list,
        }));
    };

    const handleRemoveProduct = (item) => {
        const hasValue = produtos.value.includes(item.value);
        if (hasValue) {
            const removedCache = produtos.cache.filter(
                (i) => i.value !== item.value,
            );
            const newValue = produtos.value.filter((i) => i !== item.value);
            setProdutos((prevState) => ({
                ...prevState,
                value: newValue,
                cache: removedCache,
            }));
        }
    };

    const removeAllProducts = () => {
        setProdutos((prevState) => ({
            ...prevState,
            value: [],
            cache: [],
        }));
    };

    const handleCleanProduct = () => {
        setProdutos((prevState) => ({ ...prevState, value: [], cache: [] }));
    };

    const searchProdutos = (data) => {
        setLoading(true);
        ProdutoService.getProdutoCliente(data).then((response) => {
            if (response) {
                setList(response);
                setLoading(false);
            }
        });
    };

    const onSearchProducts = (inputValue) => {
        let filtros;

        if (
            inputValue &&
            typeof inputValue === 'string' &&
            inputValue.match(/^\d+ \d+[\d+ ]*$/)
        ) {
            const concatValue = produtos.value.concat(inputValue.split(' '));
            const newValue = [...new Set(concatValue)];
            filtros = { query: '', identificadores: newValue };

            setProdutos((prevState) => ({
                ...prevState,
                value: newValue,
            }));
        } else {
            filtros = {
                query: typeof inputValue === 'string' ? inputValue : '',
            };
        }

        clearTimeout(timer);

        const newTimer = setTimeout(() => {
            searchProdutos({ query: filtros.query });
        }, 500);

        setTimer(newTimer);
    };

    const handleSelectProduct = (item) => {
        const hasValue = produtos?.value?.includes(item.value);
        if (hasValue) {
            handleRemoveProduct(item, 'competidor');
        } else {
            handleAddProduct(item, 'competidor');
        }
    };

    const handleCheckAllProducts = (item, value) => {
        setCheckAllProducts(value);

        if (value) {
            addAllProducts();
        } else {
            removeAllProducts();
        }
    };

    const handleSelectEcomProduct = (value, item) => {
        const hasValue = produtos?.value?.includes(item.value);

        const obj = {
            name: 'ecomPayload',
            value: {
                ...ecom.ecomPayload,
                produtos: value,
            },
        };

        if (hasValue) {
            handleRemoveProduct(item);
            dispatch(setEcomValues(obj));
        } else {
            handleAddProduct(item);
            dispatch(setEcomValues(obj));
        }
    };

    return {
        produtos,
        checkAllProducts,
        loading,
        onSearchProducts,
        handleAddProduct,
        handleRemoveProduct,
        handleCleanProduct,
        handleSelectProduct,
        handleCheckAllProducts,
        handleSelectEcomProduct,
    };
};
