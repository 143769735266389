import { trackPromise } from 'react-promise-tracker';
import qs from 'qs';
import api from '../../../utils/API';

export const getTokenByEmbedType = (embedType) => trackPromise(
    api({
        url: `${process.env.REACT_APP_UPLOAD_FILE}/jwt/${embedType}`,
        method: 'GET',
        expectToken: true,
        token: localStorage.getItem('autenticacao.token'),
    }).then((resp) => resp.data),
);

export const saveUpload = (data) => trackPromise(
    api({
        url: `${process.env.REACT_APP_UPLOAD_FILE}/file`,
        method: 'POST',
        expectToken: true,
        token: localStorage.getItem('autenticacao.token'),
        data: JSON.stringify(data),
    }).then((resp) => resp),
);

export const listUploads = (params, pagination) => trackPromise(
    api({
        url: `${process.env.REACT_APP_UPLOAD_FILE}/file`,
        method: 'GET',
        expectToken: true,
        token: localStorage.getItem('autenticacao.token'),
        params: {
            ...params,
            ...pagination,
        },
        paramsSerializer(params) {
            return qs.stringify(params, { arrayFormat: 'repeat' });
        },
    }).then((resp) => resp.data),
);

export const downloadItemOriginal = (batchId, fileTitle) => trackPromise(
    api({
        url: `${process.env.REACT_APP_UPLOAD_FILE}/file/download/${batchId}/original`,
        method: 'GET',
        expectToken: true,
        token: localStorage.getItem('autenticacao.token'),
    }).then((resp) => {
        const blob = new Blob([resp.data]);
        const hiddenElement = document.createElement('a');
        hiddenElement.href = window.URL.createObjectURL(blob);
        hiddenElement.target = '_blank';
        hiddenElement.download = fileTitle;
        hiddenElement.click();
    }),
);

export const downloadItemFinal = (batchId, fileTitle) => trackPromise(
    api({
        url: `${process.env.REACT_APP_UPLOAD_FILE}/file/download/${batchId}/final`,
        method: 'GET',
        expectToken: true,
        token: localStorage.getItem('autenticacao.token'),
    }).then((resp) => {
        const blob = new Blob([resp.data]);
        const hiddenElement = document.createElement('a');
        hiddenElement.href = window.URL.createObjectURL(blob);
        hiddenElement.target = '_blank';
        hiddenElement.download = `final-${fileTitle}`;
        hiddenElement.click();
    }),
);
