import {
    FiltersDataState,
    FilterValueState,
} from '../../../../../../../../components';
import { getSortModel } from '../../../../../../../../utils';
import { GetRegra } from '../../../../../../RegrasPreco/Cadastro/services';
import { EditRuleCompetitorsApi } from '../EditCompetitors.types';

type Sort<SortColumn extends string = string> = {
    sortType: 'asc' | 'desc';
    sortColumn: SortColumn;
};

export const GET_MODEL_SORT = (sort: Sort) => ({
    sort: getSortModel(sort.sortColumn, sort.sortType),
});

type Pagination = {
    displayLength: number;
    activePage: number;
};

export const GET_MODEL_PAGINATION = (pagination: Pagination) => {
    return {
        size: pagination.displayLength,
        page: pagination.activePage - 1,
    };
};

export const GET_DATA_MODEL = ({
    sort,
    pagination,
}: {
    sort: Sort;
    pagination: Pagination;
    selectedStores?: string[];
}): EditRuleCompetitorsApi['params'] => {
    return {
        ...GET_MODEL_SORT(sort),
        ...GET_MODEL_PAGINATION(pagination),
    };
};

export const PARSE_RAW_RESPONSE = (
    rawResponse: EditRuleCompetitorsApi['rawResponse'],
): EditRuleCompetitorsApi['response'] => {
    return {
        content: rawResponse.content.map((row) => ({
            storeId: row.storeId,
            storeName: row.storeName,
            competitors: row.competitors.map((competitor) => ({
                label: competitor,
                value: competitor,
            })),
        })),
        totalElements: rawResponse.totalElements,
    };
};

export const GET_SAVE_RULE_EDIT_COMPETITORS_MODEL = (
    values: FilterValueState,
): { storeId: string; unselectedCompetitors: string[] }[] => {
    return Object.entries(values).map(
        ([storeId, unselectedCompetitors = []]) => {
            return {
                storeId,
                unselectedCompetitors,
            };
        },
    );
};

export const PARSE_EDIT_RULE_COMPETITORS = (
    competitors: GetRegra['competitors'],
): { values: FilterValueState; cache: FiltersDataState } => {
    const values: FilterValueState = {};
    const cache: FiltersDataState = {};
    competitors?.forEach(({ storeId, unselectedCompetitors }) => {
        values[storeId] = unselectedCompetitors;
        cache[storeId] = unselectedCompetitors?.map((value) => ({
            label: value,
            value,
        }));
    });
    return { values, cache };
};
