import React from 'react';
import {
    Button,
    ControlLabel,
    Form,
    FormControl,
    FormGroup,
    Modal,
    Radio,
    RadioGroup,
} from 'rsuite';

import { LoadingSpinerArea } from '../../../../../../components/LoadingSpinner';
import * as T from './ModalSaveFilters.types';
import styles from './styles.module.scss';

export type ModalSaveFiltersProps = {
    onChangeName: (value: unknown) => void;
    onChangeRadio: (value: unknown) => void;
    onClickConfirm: () => void;
    onClickCancel: () => void;
    onHide: () => void;
    salvarFiltros?: () => void;
    filterName?: string;
    show?: boolean;
    filtroSalvoSelecionado?: T.SavedFilter;
    analiseCompetitividadeDataReducer?: any;
    deleteSavedFilters: (data: any) => unknown;
    getfiltrosSalvos: () => void;
    nameFilter?: string;
    radioList?: string;
    setNameFilter?: any;
};

export const ModalSaveFilters = ({
    show,
    filtroSalvoSelecionado,
    nameFilter,
    radioList,
    onHide,
    onChangeName,
    onClickConfirm,
    onChangeRadio,
    ...props
}: ModalSaveFiltersProps) => (
    <Modal
        className={styles['modal-save-filters']}
        show={show}
        {...props}
        onHide={onHide}
    >
        <Modal.Header>
            <Modal.Title>Salvar filtro</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Form fluid>
                {filtroSalvoSelecionado ? (
                    <FormGroup>
                        <RadioGroup
                            name="radioList"
                            value={radioList}
                            onChange={onChangeRadio}
                        >
                            <Radio value="SUB">
                                Substituir o filtro
                                {' '}
                                <b>{filtroSalvoSelecionado?.nomeFiltro}</b>
                                ?
                            </Radio>
                            <Radio value="CRI">Criar novo filtro</Radio>
                        </RadioGroup>
                    </FormGroup>
                    ) : null}
                {!filtroSalvoSelecionado
                    || (filtroSalvoSelecionado && radioList) ? (
                        <FormGroup>
                            <ControlLabel>Nome do filtro</ControlLabel>
                            <FormControl
                                name="nome"
                                value={nameFilter}
                                onChange={onChangeName}
                            />
                        </FormGroup>
                    ) : null}
            </Form>
        </Modal.Body>
        <Modal.Footer>
            {!filtroSalvoSelecionado
                || (filtroSalvoSelecionado && radioList) ? (
                    <Button
                        appearance="primary"
                        onClick={onClickConfirm}
                    >
                        {radioList === 'SUB'
                            ? 'Substituir filtro'
                            : 'Salvar filtro'}
                    </Button>
                ) : null}
            <Button onClick={onHide} appearance="default">
                Descartar
            </Button>
        </Modal.Footer>
        <LoadingSpinerArea size="sm" area="saved-filter" height={null} />
    </Modal>
    );
