import React, { forwardRef } from 'react';

export const PageSection = forwardRef(
    (
        {
            children,
            className = '',
            ...props
        }: React.HtmlHTMLAttributes<HTMLElement>,
        ref: React.LegacyRef<HTMLElement>,
    ) => (
        <section ref={ref} className={`page__section ${className}`} {...props}>
            {children}
        </section>
    ),
);
