import classNames from 'classnames';
import React, { forwardRef } from 'react';
import styles from '../SetupIPA.module.scss';
import * as T from '../SetupIPA.types';

export const SetupIPAHeader = forwardRef<HTMLDivElement, T.SetupIPAHeaderProps>(
    ({ className, children, title, subtitle, ...props }, ref) => (
        <div
            className={classNames(styles['setup-ipa__header'], className)}
            ref={ref}
            {...props}
        >
            <h3 className={styles['setup-ipa__header__title']}>{title}</h3>
            <p className={styles['setup-ipa__header__subtitle']}>{subtitle}</p>
            {children}
        </div>
    ),
);
