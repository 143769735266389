import React from 'react';
import {
    FlexboxGrid,
    CheckPicker,
    Col,
    InputPicker,
    Icon,
    IconButton,
    Tag,
} from 'rsuite';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
    addFiltro,
    deleteFiltro,
    selectValue,
    changeValueFiltro,
    changeCacheFiltro,
    search,
    searchSegmentos,
    searchProduto,
    searchFamiliaProduto,
    searchStore,
    searchRuleType,
    clearFiltros,
    searchSegmentosClasses,
    searchSegmentosSensibilities,
} from '../../../../actions/actionsRevisao';
import { selectSearch } from '../../../../utils/Helpers';

const FiltroCustomizado = ({
    selectValue,
    resetPageNumber,
    changeValueFiltro,
    changeCacheFiltro,
    filtrosData,
    searchDataTable,
    search,
    searchProduto,
    searchFamiliaProduto,
    searchStore,
    searchRuleType,
    searchSegmentos,
    filtro,
    index,
    handleDeleteFiltro,
}) => {
    const handleSelect = (value, item, name) => {
        selectValue(value, name);
        setTimeout(() => {
            resetPageNumber();
        }, 300);
    };

    const handleChange = (value, event, item) => {
        changeValueFiltro(value, item.name);
        let cache;
        if (value === null) {
            cache = [];
            changeCacheFiltro(item.name, cache);
        }
        const cacheData = filtrosData[`${item.name}`].lista;
        if (value !== null && value.length !== cacheData.length) {
            cache = cacheData.filter(
                (item) => value.indexOf(item.value) !== -1,
            );
            changeCacheFiltro(item.name, cache);
        }

        searchDataTable();
    };

    const handleClean = (value, e, item) => {
        selectValue('', item.name);

        setTimeout(() => {
            selectSearch(
                search,
                searchProduto,
                searchStore,
                searchRuleType,
                searchSegmentos,
                '',
                searchFamiliaProduto,
                filtro,
            );
            resetPageNumber();
            searchDataTable();
        }, 300);
    };

    return (
        <FlexboxGrid.Item
            className="filtro-dinamico"
            componentClass={Col}
            lg={8}
            md={12}
        >
            <FlexboxGrid.Item
                componentClass={Col}
                className="filtro-dinamico__tag-wrapper"
            >
                <Tag className="filtro-dinamico__tag">{filtro.title}</Tag>
            </FlexboxGrid.Item>
            <FlexboxGrid.Item
                className="filtro-dinamico__input-section"
                componentClass={Col}
                lg={15}
                md={14}
                sm={24}
                xs={24}
            >
                {filtro.name === 'infoPriceSensibility'
                || filtro.name === 'infoPriceGlobalAbcClass'
                || filtro.name === 'infoPriceAbcClass' ? (
                    <CheckPicker
                        className="inf-picker"
                        sticky
                        block
                        cleanable
                        searchable
                        filter={filtro.filter}
                        placeholder={filtro.placeholder}
                        data={
                            filtrosData
                            && filtrosData[filtro.name]
                            && filtrosData[filtro.name].lista.find(
                                (item) => item?.label,
                            )
                                ? filtrosData[filtro.name].lista
                                : []
                        }
                        value={
                            filtrosData && filtrosData[filtro.name]
                                ? filtrosData[filtro.name].value
                                : []
                        }
                        name={filtro.name}
                        locale={{
                            noResultsText: 'Nenhum item encontrado',
                            searchPlaceholder: 'Busque o item desejado',
                        }}
                        onSelect={
                            filtro.onSelect
                                ? (value, i) => handleSelect(value, i, filtro.name)
                                : null
                        }
                        onChange={
                            filtro.onChange
                                ? (value, e) => setTimeout(() => {
                                          handleChange(value, e, filtro);
                                      }, 100)
                                : null
                        }
                        onClean={
                            filtro.onClean
                                ? () => handleClean('')
                                : (value, e) => handleClean(value, e, filtro)
                        }
                        onSearch={
                            filtro.onSearch
                                ? (searchValue) => selectSearch(
                                          search,
                                          searchProduto,
                                          searchStore,
                                          searchRuleType,
                                          searchSegmentos,
                                          '',
                                          searchFamiliaProduto,
                                          filtro,
                                          searchValue,
                                      )
                                : null
                        }
                    />
                ) : (
                    <InputPicker
                        style={{ marginRight: '0' }}
                        block
                        cleanable={filtro.cleanable}
                        searchable={filtro.searchable}
                        filter={filtro.filter}
                        data={
                            filtrosData && filtrosData[filtro.name]
                                ? filtrosData[filtro.name].lista
                                : []
                        }
                        value={
                            filtrosData && filtrosData[filtro.name]
                                ? filtrosData[filtro.name].value
                                : []
                        }
                        name={filtro.name}
                        placeholder={filtro.placeholder}
                        locale={filtro.locale}
                        onSelect={
                            filtro.onSelect
                                ? (value, i) => handleSelect(value, i, filtro.name)
                                : null
                        }
                        onClean={
                            filtro.onClean && filtro.name !== 'sensibilityType'
                                ? () => handleClean('')
                                : (value, e) => handleClean(value, e, filtro)
                        }
                        onChange={
                            filtro.onChange
                                ? (value, e) => setTimeout(() => {
                                          handleChange(value, e, filtro);
                                      }, 100)
                                : null
                        }
                        onSearch={
                            filtro.onSearch && filtro.name !== 'sensibilityType'
                                ? (searchValue) => selectSearch(
                                          search,
                                          searchProduto,
                                          searchStore,
                                          searchRuleType,
                                          searchSegmentos,
                                          '',
                                          searchFamiliaProduto,
                                          filtro,
                                          searchValue,
                                      )
                                : null
                        }
                    />
                )}
            </FlexboxGrid.Item>
            <FlexboxGrid.Item componentClass={Col} lg={2} md={2}>
                <IconButton
                    className="filtro-dinamico__delete-button"
                    icon={<Icon icon="trash2" />}
                    onClick={() => handleDeleteFiltro(index, filtro.name)}
                />
            </FlexboxGrid.Item>
        </FlexboxGrid.Item>
    );
};

const mapStateToProps = (store) => ({
    tiposFiltro: store.revisaoDataReducer.tiposFiltro,
    filtros: store.revisaoDataReducer.filtros,
    filtrosAdicionados: store.revisaoDataReducer.filtrosAdicionados,
    filtrosData: store.revisaoDataReducer.filtrosData,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
        {
            addFiltro,
            deleteFiltro,
            selectValue,
            changeValueFiltro,
            changeCacheFiltro,
            search,
            searchSegmentos,
            searchProduto,
            searchFamiliaProduto,
            searchStore,
            searchRuleType,
            searchSegmentosClasses,
            searchSegmentosSensibilities,
            clearFiltros,
        },
        dispatch,
    );

export default connect(mapStateToProps, mapDispatchToProps)(FiltroCustomizado);
