import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    Container,
    Content,
} from 'rsuite';
import { selectorCalculadoraPrevisaoDemanda } from '../../../../reducers/calculadoraPrevisaoDemanda/calculadoraPrevisaoDemandaReducer';
import {
    resetSavedFilters,
} from '../../../../reducers/calculadoraPrevisaoDemanda/savedFiltros';
import {
    resetSavedScenario,
} from '../../../../reducers/calculadoraPrevisaoDemanda/savedScenario';
import Header from '../../../../components/Header';
import {
    BestPriceChart,
    BigNumbersArea,
    DemandVersusRevenueChart,
    EmptyState,
    FiltersArea,
    PriceSettingsArea,
    SimulationArea,
    ViewResultsArea,
    EmptyStateSecond,
} from '../../Components';
import styles from './CalculadoraDemanda.module.scss';

export const CalculadoraDemanda = () => {
    const { resultScenario } = useSelector(
        selectorCalculadoraPrevisaoDemanda,
    );

    const { outputs } = resultScenario.resultScenarioData;
    const hasElasticity = outputs?.elasticity !== null;

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(resetSavedFilters());
        dispatch(resetSavedScenario());
    }, []);

    return (
        <Container className="main-container">
            <Header
                title="Simulador de demanda"
                subtitle="Calcule o impacto da alteração de preços nas vendas do seu produto"
            />
            <FiltersArea />
            <PriceSettingsArea />
            <ViewResultsArea />
            {outputs?.base_price_scenario?.price ? (
                <>
                    <SimulationArea />
                    {hasElasticity ? (
                        <Content className={styles['results-section']}>
                            <BigNumbersArea />
                            <div className={styles['charts-flex']}>
                                <DemandVersusRevenueChart />
                                <BestPriceChart />
                            </div>
                        </Content>
                    ) : (
                        <EmptyStateSecond />
                    )}
                </>
        ) : (
            <EmptyState />
        )}
        </Container>
  );
};
