import React from 'react';
import { Tooltip, Whisper } from 'rsuite';

export type ICONS_TYPE = 'RULE_CPI_MAX' | 'RULE_CPI_MIN' | 'RULE_CPI_AVG' | null;

const LOOKUP = {
    RULE_CPI_MAX: (
        <Whisper
            placement="right"
            speaker={<Tooltip>Preço mais alto concorrente</Tooltip>}
        >
            <span className="CompetitorIndicator">{' >'}</span>
        </Whisper>
    ),
    RULE_CPI_MIN: (
        <Whisper
            placement="right"
            speaker={<Tooltip>Preço mais baixo concorrente</Tooltip>}
        >
            <span className="CompetitorIndicator">{' <'}</span>
        </Whisper>
    ),
    RULE_CPI_AVG: (
        <Whisper
            placement="right"
            speaker={<Tooltip>Média do preço concorrente</Tooltip>}
        >
            <span className="CompetitorIndicator">{' x̄'}</span>
        </Whisper>
    ),
};

export const CompetitivenesIcon = ({
    type = 'RULE_CPI_AVG',
}: {
    type?: ICONS_TYPE;
}) => {
    if (type === null) return null;
    return LOOKUP[type];
};
