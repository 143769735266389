import React from 'react';
import {
    Col,
    Modal,
    Row,
} from 'rsuite';
import { handleDateFormat } from '../../../../../../utils/Helpers';
import { ShowStatus } from '../../Components/ShowStatus';
import styles from './styles.module.scss';

interface IRequestDetailsContent {
    data?: any;
}

export const RequestDetailsContent = ({ data }: IRequestDetailsContent) => {
    const validateDataValue = (value: string | number | boolean) => {
        if (value === null || value === '') {
            return '-';
        }
        return value;
    };

    return (
        <Modal.Body className={styles.body}>
            <h4 className={styles['content-title']}>
                Detalhes da solicitação
            </h4>
            <div className={styles['first-section']}>
                <Row className={styles.row}>
                    <Col xs={24} sm={24} md={8}>
                        <p className={styles['item-title']}>Identificador</p>
                        <p className={styles['item-value']}>{validateDataValue(data?.idSolicitacao)}</p>
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                        <p className={styles['item-title']}>Status</p>
                        <ShowStatus status={data?.status} />
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                        <p className={styles['item-title']}>Data criação</p>
                        <p className={styles['item-value']}>{validateDataValue(handleDateFormat(data?.dataCriacao))}</p>
                    </Col>
                    {/* <Col xs={24} sm={24} md={8}>
                        <p className={styles['item-title']}>Tempo de atividade</p>
                        <p className={styles['item-value']}>0125</p>
                    </Col> */}
                </Row>

                <Row className={styles.row}>
                    <Col xs={24} sm={24} md={8}>
                        <p className={styles['item-title']}>Código de barras</p>
                        <p className={styles['item-value']}>{validateDataValue(data?.gtin)}</p>
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                        <p className={styles['item-title']}>Palavra-chave</p>
                        <p className={styles['item-value']}>{validateDataValue(data?.palavraChave)}</p>
                    </Col>

                    <Col xs={24} sm={24} md={8}>
                        <p className={styles['item-title']}>Criador</p>
                        <p className={styles['item-value-lower']}>{validateDataValue(data?.criador)}</p>
                    </Col>
                </Row>
                <Row className={styles['last-row']}>

                    <Col xs={24} sm={24} md={8}>
                        <p className={styles['item-title']}>Última alteração</p>
                        <p className={styles['item-value']}>{validateDataValue(handleDateFormat(data?.dataAlteracao))}</p>
                    </Col>
                </Row>
            </div>
            <div className={styles['first-section']}>
                <Row className={styles.row}>
                    <Col xs={24} sm={24} md={8}>
                        <p className={styles['item-title']}>UF</p>
                        <p className={styles['item-value']}>{validateDataValue(data?.uf)}</p>
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                        <p className={styles['item-title']}>Cidade</p>
                        <p className={styles['item-value']}>{validateDataValue(data?.cidade)}</p>
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                        <p className={styles['item-title']}>Raio (KM)</p>
                        <p className={styles['item-value']}>{validateDataValue(data?.raio)}</p>
                    </Col>
                </Row>

                <Row className={styles.row}>
                    <Col xs={24} sm={24} md={8}>
                        <p className={styles['item-title']}>Logradouro</p>
                        <p className={styles['item-value']}>{validateDataValue(data?.logradouro)}</p>
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                        <p className={styles['item-title']}>Número</p>
                        <p className={styles['item-value']}>{validateDataValue(data?.numeroLogradouro)}</p>
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                        <p className={styles['item-title']}>CEP</p>
                        <p className={styles['item-value']}>{validateDataValue(data?.cep)}</p>
                    </Col>
                </Row>
            </div>
        </Modal.Body>
  );
};
