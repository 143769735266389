import React from 'react';
import { Button } from 'rsuite';
import { Layout } from '../../../../../components';
import { AsyncCheckPill } from '../../../../../components/Filters';
import styles from './PrecoAtacadoFilters.module.scss';
import usePrecoAtacadoFilters from './usePrecoAtacadoFilters';

const PrecoAtacadoFilters = () => {
    const { list: filters } = usePrecoAtacadoFilters();

    return (
        <Layout.Section padded>
            <section className={styles.wrapper}>
                <div className={styles.content}>
                    <div>
                        {filters.map((filter) => {
                            return (
                                !filter.dataKey.match(/categoryLevel/) && (
                                    <AsyncCheckPill {...filter} />
                                )
                            );
                        })}
                    </div>
                    <div>
                        {filters.map((filter) => {
                            return (
                                filter.dataKey.match(/categoryLevel/) && (
                                    <AsyncCheckPill {...filter} />
                                )
                            );
                        })}
                    </div>
                </div>
                <Button
                    className="filter-button"
                    onClick={() => {
                        filters.forEach((filter) => {
                            filter.onClean?.();
                        });
                    }}
                >
                    Limpar filtros
                </Button>
            </section>
        </Layout.Section>
    );
};

export default PrecoAtacadoFilters;
