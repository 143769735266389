import React from 'react';
import {
    Modal,
    Button,
    FlexboxGrid,
    Table,
    Col,
    FormGroup,
    ControlLabel,
    Content,
    Container,
} from 'rsuite';
import CurrencyFormat from 'react-currency-format';
const { Column, HeaderCell, Cell, Pagination } = Table;

const ModalFamiliaPrecificacaoLoja = ({
    isOpen,
    onClose,
    cabecalho,
    tabela,
    loja,
    onChangePage,
}) => (
    <Modal
        style={{ width: '992px', height: '646px' }}
        show={isOpen}
        onHide={onClose}
        className="modal-family-precificacao"
    >
        <Container className="modal-family-precificacao">
            <Modal.Header>
                <Modal.Title>
                    {`Familia ${cabecalho?.product_family_id || ''} na ${
                        loja.storeName
                    }`}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Content>
                    <FlexboxGrid className="cabecalho">
                        <FlexboxGrid.Item
                            componentClass={Col}
                            lg={5}
                            md={5}
                            sm={24}
                            xs={24}
                        >
                            <FormGroup>
                                <ControlLabel className="description__modal-label">
                                    FAMÍLIA DE PRODUTOS
                                </ControlLabel>
                                <p className="list__modal-label">
                                    <strong>
                                        {cabecalho.product_family_id}
                                    </strong>
                                </p>
                            </FormGroup>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item
                            componentClass={Col}
                            lg={4}
                            md={5}
                            sm={24}
                            xs={24}
                        >
                            <FormGroup>
                                <ControlLabel className="description__modal-label">
                                    TOTAL DE PRODUTOS
                                </ControlLabel>
                                <p className="list__modal-label">
                                    {cabecalho.count}
                                </p>
                            </FormGroup>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item
                            componentClass={Col}
                            lg={11}
                            md={3}
                            sm={24}
                            xs={24}
                        >
                            <FormGroup>
                                <ControlLabel className="description__modal-label">
                                    LOJA
                                </ControlLabel>
                                <p className="list__modal-label">
                                    {loja.storeName
                                        ? `${loja.storeId} - ${loja.storeName}`
                                        : '--'}
                                </p>
                            </FormGroup>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item
                            componentClass={Col}
                            lg={5}
                            md={3}
                            sm={24}
                            xs={24}
                        >
                            <FormGroup>
                                <ControlLabel className="description__modal-label-niveis">
                                    PMZ
                                </ControlLabel>
                                <p className="list__modal-label-niveis">
                                    {cabecalho.pmz ? (
                                        <CurrencyFormat
                                            fixedDecimalScale
                                            decimalScale={2}
                                            value={cabecalho.pmz}
                                            displayType="text"
                                            prefix="R$"
                                            decimalSeparator=","
                                            thousandSeparator="."
                                        />
                                    ) : (
                                        '--'
                                    )}
                                </p>
                            </FormGroup>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item
                            componentClass={Col}
                            lg={4}
                            md={3}
                            sm={24}
                            xs={24}
                        >
                            <FormGroup>
                                <ControlLabel className="description__modal-label-niveis">
                                    PREÇO VIGENTE
                                </ControlLabel>
                                <p className="list__modal-label-niveis">
                                    {cabecalho.retail_price ? (
                                        <CurrencyFormat
                                            fixedDecimalScale
                                            decimalScale={2}
                                            value={cabecalho.retail_price}
                                            displayType="text"
                                            prefix="R$"
                                            decimalSeparator=","
                                            thousandSeparator="."
                                        />
                                    ) : (
                                        '--'
                                    )}
                                </p>
                            </FormGroup>
                        </FlexboxGrid.Item>

                        <FlexboxGrid.Item
                            componentClass={Col}
                            lg={5}
                            md={3}
                            sm={24}
                            xs={24}
                        >
                            <FormGroup>
                                <ControlLabel className="description__modal-label-niveis">
                                    MÉDIA CONCORRÊNCIA
                                </ControlLabel>
                                <p className="list__modal-label-niveis">
                                    {cabecalho.competitor_price ? (
                                        <CurrencyFormat
                                            fixedDecimalScale
                                            decimalScale={2}
                                            value={cabecalho.competitor_price}
                                            displayType="text"
                                            prefix="R$"
                                            decimalSeparator=","
                                            thousandSeparator="."
                                        />
                                    ) : (
                                        '--'
                                    )}
                                </p>
                            </FormGroup>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item
                            componentClass={Col}
                            lg={4}
                            md={3}
                            sm={24}
                            xs={24}
                        >
                            <FormGroup>
                                <ControlLabel className="description__modal-label-niveis">
                                    COMPETITIVIDADE
                                </ControlLabel>
                                <p className="list__modal-label-niveis">
                                    {cabecalho.competitiveness ? (
                                        <CurrencyFormat
                                            fixedDecimalScale
                                            decimalScale={2}
                                            value={cabecalho.competitiveness}
                                            displayType="text"
                                            suffix="%"
                                            decimalSeparator=","
                                            thousandSeparator="."
                                        />
                                    ) : (
                                        '--'
                                    )}
                                </p>
                            </FormGroup>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item
                            componentClass={Col}
                            lg={3}
                            md={3}
                            sm={24}
                            xs={24}
                        >
                            <FormGroup>
                                <ControlLabel className="description__modal-label-niveis">
                                    MARGEM
                                </ControlLabel>
                                <p className="list__modal-label-niveis">
                                    {cabecalho.margin ? (
                                        <CurrencyFormat
                                            fixedDecimalScale
                                            decimalScale={2}
                                            value={cabecalho.margin}
                                            displayType="text"
                                            suffix="%"
                                            decimalSeparator=","
                                            thousandSeparator="."
                                        />
                                    ) : (
                                        '--'
                                    )}
                                </p>
                            </FormGroup>
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </Content>
                <Content>
                    <Table data={tabela.content} className="tabela" autoHeight>
                        <Column flexGrow={4}>
                            <HeaderCell>Produto</HeaderCell>
                            <Cell>
                                {(rowData) => {
                                    const codigoproduto = [
                                        ...(rowData.product_id?.length
                                            ? [rowData.product_id]
                                            : []),
                                    ];

                                    const descricaoproduto = [
                                        ...(rowData.description?.length
                                            ? [rowData.description]
                                            : []),
                                    ];
                                    return (
                                        <span>
                                            {codigoproduto?.length ? (
                                                <strong>{codigoproduto}</strong>
                                            ) : null}{' '}
                                            - {descricaoproduto}
                                        </span>
                                    );
                                }}
                            </Cell>
                        </Column>
                        <Column flexGrow={1} >
                            <HeaderCell>PMZ</HeaderCell>
                            <Cell className="grid__tabel-modal">
                                {(rowData) =>
                                    rowData.pmz ? (
                                        <CurrencyFormat
                                            fixedDecimalScale
                                            decimalScale={2}
                                            value={rowData.pmz}
                                            displayType="text"
                                            prefix="R$"
                                            decimalSeparator=","
                                            thousandSeparator="."
                                        />
                                    ) : (
                                        '--'
                                    )
                                }
                            </Cell>
                        </Column>
                        <Column flexGrow={1} >
                            <HeaderCell>Preço vigente</HeaderCell>
                            <Cell className="grid__tabel-modal">
                                {(rowData) =>
                                    rowData.retail_price ? (
                                        <CurrencyFormat
                                            fixedDecimalScale
                                            decimalScale={2}
                                            value={rowData.retail_price}
                                            displayType="text"
                                            prefix="R$"
                                            decimalSeparator=","
                                            thousandSeparator="."
                                        />
                                    ) : (
                                        '--'
                                    )
                                }
                            </Cell>
                        </Column>
                        <Column width={150} >
                            <HeaderCell>Média concorrência</HeaderCell>
                            <Cell className="grid__tabel-modal">
                                {(rowData) =>
                                    rowData.competitor_price ? (
                                        <CurrencyFormat
                                            fixedDecimalScale
                                            decimalScale={2}
                                            value={rowData.competitor_price}
                                            displayType="text"
                                            prefix="R$"
                                            decimalSeparator=","
                                            thousandSeparator="."
                                        />
                                    ) : (
                                        '--'
                                    )
                                }
                            </Cell>
                        </Column>
                        <Column flexGrow={1} >
                            <HeaderCell>Competitividade</HeaderCell>
                            <Cell className="grid__tabel-modal">
                                {(rowData) =>
                                    rowData.competitiveness ? (
                                        <CurrencyFormat
                                            fixedDecimalScale
                                            decimalScale={2}
                                            value={rowData.competitiveness}
                                            displayType="text"
                                            suffix="%"
                                            decimalSeparator=","
                                            thousandSeparator="."
                                        />
                                    ) : (
                                        '--'
                                    )
                                }
                            </Cell>
                        </Column>
                        <Column flexGrow={1} >
                            <HeaderCell>Margem</HeaderCell>
                            <Cell className="grid__tabel-modal">
                                {(rowData) =>
                                    rowData.margin ? (
                                        <CurrencyFormat
                                            fixedDecimalScale
                                            value={rowData.margin}
                                            decimalScale={2}
                                            displayType="text"
                                            suffix="%"
                                            decimalSeparator=","
                                            thousandSeparator="."
                                        />
                                    ) : null
                                }
                            </Cell>
                        </Column>
                    </Table>
                    <Pagination
                        showLengthMenu={false}
                        activePage={tabela.number + 1}
                        displayLength={tabela.size}
                        total={tabela.totalElements}
                        onChangePage={onChangePage}
                    />
                </Content>
            </Modal.Body>
        </Container>
        <Modal.Footer>
            <Button
                onClick={onClose}
                appearance="ghost"
                className="botao-fechar-modal"
            >
                FECHAR
            </Button>
        </Modal.Footer>
    </Modal>
);

export default ModalFamiliaPrecificacaoLoja;
