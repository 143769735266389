import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import {
    Alert,
    Button,
    ButtonToolbar,
    ControlLabel,
    Form,
    FormControl,
    FormGroup,
    HelpBlock,
} from 'rsuite';

import {
    SELECT_CHANNEL_STAGE, setAccountData,
    setEmail, updateStage,
} from '../../../../../actions/actionsCreateAccountFreemium';
import { checkUserEmail } from '../../../../../services/FreemiumService';
import { sendLeadEvent, validEmail } from '../utils';

class CreateAccountEmailForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            error: {},
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.checkEmail = this.checkEmail.bind(this);
    }

    handleSubmit(e) {
        e.preventDefault();

        const { account } = this.props;

        if (this.validForm()) {
            this.checkEmail(account.email);
        }
    }

    checkEmail(email) {
        const {
            history, updateStage, setEmail, setAccountData,
        } = this.props;

        checkUserEmail(email).then((response) => {
            if (response.decisao === 'USUARIO_CADASTRADO') {
                setEmail('');
                history.push('/login');
                Alert.warning('Você já possui cadastro no InfoPanel, realize o login.', 8000);
            }
            if (response.decisao === 'USUARIO_NAO_ASSOCIADO') {
                Alert.warning(
                    `${'Identificamos que seu e-mail está associado em uma organização que já possui InfoPanel. '
                    + 'Entre em contato com o administrador da conta, '}
                    ${response.administradores[0] ? `${response.administradores[0]},` : ''} e solicite o acesso.`,
                    8000,
                );
            }
            if (response.decisao === 'USUARIO_CADASTRADO_INTERNO') {
                Alert.warning('E-mail já cadastrado como usuário interno InfoPrice', 8000);
            }
            if (response.decisao === 'USUARIO_CADASTRADO_CLIENTE_NAO_PANEL') {
                updateStage(SELECT_CHANNEL_STAGE);
                history.push('/free/configuracoes');
            }
            if (response.decisao === 'USUARIO_NAO_CADASTRADO' || response.decisao === 'USUARIO_EXISTE_NAO_CADASTRADO') {
                history.push('/free/usuario');
            }

            setAccountData(response.decisao, 'userType');
            sendLeadEvent('email-cadastrado-free', { email });
        });
    }

    validForm() {
        const { account } = this.props;
        const { error } = this.state;
        let formIsValid = true;

        const inputEmail = document.getElementById('email');
        if (!account.email.length) {
            formIsValid = false;
            error.email = 'Campo obrigatório';
            if (inputEmail) {
                inputEmail.classList.add('invalid-input');
            }
        } else if (!validEmail(account.email)) {
            formIsValid = false;
            error.email = 'Digite um e-mail válido';
            if (inputEmail) {
                inputEmail.classList.add('invalid-input');
            }
        } else {
            error.email = null;
            if (inputEmail) {
                inputEmail.classList.remove('invalid-input');
            }
        }

        this.setState({ error });
        return formIsValid;
    }

    render() {
        const { error } = this.state;
        const { account, setEmail } = this.props;

        return (
            <Form fluid onSubmit={this.handleSubmit}>
                <FormGroup>
                    <ControlLabel>E-mail Corporativo</ControlLabel>
                    <FormControl
                        placeholder="usuario@empresa.com.br"
                        id="email"
                        name="email"
                        value={account.email}
                        onChange={(value) => setEmail(value)}
                        type="email"
                    />
                    {error.email ? <HelpBlock className="input-error" name="erroEmail">{error.email}</HelpBlock> : null}
                </FormGroup>
                <FormGroup>
                    <ButtonToolbar>
                        <Button block type="submit" appearance="primary" id="btn-create-account">Criar conta</Button>
                    </ButtonToolbar>
                </FormGroup>
                <div className="link-login">
                    <Link id="btn-go-to-login" to={{ pathname: '/login' }}>Fazer login </Link>
                </div>
            </Form>
        );
    }
}

const mapStateToProps = (store) => ({
    account: store.accountFreemiumDataReducer.account,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    setEmail,
    updateStage,
    setAccountData,
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CreateAccountEmailForm));
