import cn from 'classnames';
import React from 'react';
import CurrencyFormat from 'react-currency-format';
import { Whisper } from 'rsuite';
import { ButtonsCompetitorPrice, ICONS_TYPE } from '..';

const CompetitorsSection = ({ className = '', ...props }) => (
    <section className={cn(['competitors-section', className])} {...props} />
);

const Title = ({ className = '', ...props }) => (
    <span
        className={cn(['competitor-title-concorrencia', className])}
        {...props}
    />
);

type SubtitleProps = Omit<
    React.HtmlHTMLAttributes<HTMLSpanElement>,
    'children'
> & {
    total?: number;
};

const Subtitle = ({ className = '', total, ...props }: SubtitleProps) => (
    <span
        className={cn([
            'competitor-produto-encontrado-concorrencia',
            className,
        ])}
        {...props}
    >
        Produto encontrado em{' '}
        <span className="competitor-title-concorrencia-amount-store">
            {total} lojas
        </span>
    </span>
);

const Header = ({ className = '', ...props }) => (
    <span
        className={cn(['competitor-title-concorrencia-card', className])}
        {...props}
    />
);

Header.Title = Title;
Header.Subtitle = Subtitle;

export type CompetitorProps = Record<
    | 'competitorAverage'
    | 'competitorHigher'
    | 'competitorLower'
    | 'countCompetitorStore',
    number
>;

type RowDataProps = {
    competitivenessIndicator: ICONS_TYPE;
    competitorsPrice: number;
    priceType?: string;
    competitorsInfo: {
        competitor: CompetitorProps | null;
        competitorInfoPanel: CompetitorProps | null;
    };
};

export type CardResumoConcorrenteProps =
    React.HtmlHTMLAttributes<HTMLDivElement> & {
        rowData: RowDataProps;
        handleCalculoPreco: (
            newPriceCompetitiviness: number,
            rowData: RowDataProps,
            competitivenessIndicator: ICONS_TYPE,
        ) => void | null;
        handleCalculoCompetitiviness: (
            newPriceCompetitiviness: number,
            rowData: RowDataProps,
            competitivenessIndicator: ICONS_TYPE,
        ) => void | null;
    };

const CardResumoConcorrente = ({
    className = '',
    rowData,
    handleCalculoPreco,
    handleCalculoCompetitiviness,
    ...props
}: CardResumoConcorrenteProps) => {
    const {
        competitorsInfo: { competitor, competitorInfoPanel },
    } = rowData;

    const data = competitor ?? competitorInfoPanel;

    return (
        <div
            className={cn(['competitor-card-concorrencia', className])}
            {...props}
        >
            <Header>
                <Header.Title>
                    {competitor
                        ? 'Concorrência direta'
                        : 'Concorrência Infopanel'}
                </Header.Title>
                <Header.Subtitle total={data?.countCompetitorStore} />
            </Header>
            <div className="competitor-info-concorrencia">
                <Whisper
                    trigger="click"
                    placement="bottom"
                    speaker={
                        <ButtonsCompetitorPrice
                            classNamecompetitor="buttons-competitor-price"
                            handleCalculoPreco={() =>
                                handleCalculoPreco(
                                    data?.competitorAverage ?? 0,
                                    rowData,
                                    'RULE_CPI_AVG',
                                )
                            }
                            handleCalculoCompetitiviness={() =>
                                handleCalculoCompetitiviness(
                                    data?.competitorAverage ?? 0,
                                    rowData,
                                    'RULE_CPI_AVG',
                                )
                            }
                        />
                    }
                >
                    <div
                        className={
                            rowData?.competitivenessIndicator ===
                                'RULE_CPI_AVG' ||
                            (rowData?.competitorsPrice &&
                                rowData?.priceType === null) ||
                            rowData?.competitivenessIndicator === null
                                ? 'highlight-price'
                                : ''
                        }
                    >
                        <span>
                            <CurrencyFormat
                                fixedDecimalScale
                                decimalScale={2}
                                value={data?.competitorAverage}
                                displayType="text"
                                prefix=" R$"
                                decimalSeparator=","
                                thousandSeparator="."
                            />
                        </span>
                        <h6>
                            x̄ Preço <br />
                            Médio
                        </h6>
                    </div>
                </Whisper>
                <Whisper
                    trigger="click"
                    placement="bottom"
                    speaker={
                        <ButtonsCompetitorPrice
                            classNamecompetitor="buttons-competitor-price"
                            handleCalculoPreco={() =>
                                handleCalculoPreco(
                                    data?.competitorLower ?? 0,
                                    rowData,
                                    'RULE_CPI_MIN',
                                )
                            }
                            handleCalculoCompetitiviness={() =>
                                handleCalculoCompetitiviness(
                                    data?.competitorLower ?? 0,
                                    rowData,
                                    'RULE_CPI_MIN',
                                )
                            }
                        />
                    }
                >
                    <div
                        className={
                            rowData?.competitivenessIndicator === 'RULE_CPI_MIN'
                                ? 'highlight-price'
                                : ''
                        }
                    >
                        <span>
                            <CurrencyFormat
                                fixedDecimalScale
                                decimalScale={2}
                                value={data?.competitorLower}
                                displayType="text"
                                prefix=" R$"
                                decimalSeparator=","
                                thousandSeparator="."
                            />
                        </span>
                        <h6>
                            {'<'} Preço <br /> Mais Baixo
                        </h6>
                    </div>
                </Whisper>
                <Whisper
                    trigger="click"
                    placement="bottom"
                    speaker={
                        <ButtonsCompetitorPrice
                            classNamecompetitor="buttons-competitor-price"
                            handleCalculoPreco={() =>
                                handleCalculoPreco(
                                    data?.competitorHigher ?? 0,
                                    rowData,
                                    'RULE_CPI_MAX',
                                )
                            }
                            handleCalculoCompetitiviness={() =>
                                handleCalculoCompetitiviness(
                                    data?.competitorHigher ?? 0,
                                    rowData,
                                    'RULE_CPI_MAX',
                                )
                            }
                        />
                    }
                >
                    <div
                        className={
                            rowData?.competitivenessIndicator === 'RULE_CPI_MAX'
                                ? 'highlight-price'
                                : ''
                        }
                    >
                        <span>
                            <CurrencyFormat
                                fixedDecimalScale
                                decimalScale={2}
                                value={data?.competitorHigher}
                                displayType="text"
                                prefix=" R$"
                                decimalSeparator=","
                                thousandSeparator="."
                            />
                        </span>
                        <h6>
                            {'>'} Preço <br /> Mais Alto
                        </h6>
                    </div>
                </Whisper>
            </div>
        </div>
    );
};

export { CardResumoConcorrente, CompetitorsSection };
