export const colunasTablePrevisoesSalvas = [
    {
        align: 'left',
        width: 40,
        headerCell: '',
        dataKey: 'check',
        sortable: false,
    },
    {
        align: 'left',
        headerCell: 'Id',
        dataKey: 'product_id',
        sortable: false,
        width: 80,
    },
    {
        sortable: false,
        align: 'left',
        fixed: false,
        headerCell: 'Descrição do produto',
        dataKey: 'product_description',
        flexGrow: 1,
    },
    {
        sortable: false,
        align: 'left',
        fixed: false,
        headerCell: 'Loja',
        dataKey: 'store_name',
        flexGrow: 1,
    },
    {
        sortable: false,
        align: 'left',
        fixed: false,
        headerCell: 'Preço simulado',
        dataKey: 'simulated_price',
        width: 95,
    },
    {
        sortable: false,
        align: 'left',
        fixed: false,
        headerCell: 'Previsão 7d',
        dataKey: 'revenue_forecast_seven_days',
        width: 135,
    },
    {
        sortable: false,
        align: 'left',
        fixed: false,
        headerCell: 'Data simulação',
        dataKey: 'simulation_date',
        width: 121,
    },
    {
        sortable: false,
        align: 'left',
        fixed: false,
        headerCell: 'Usuário',
        dataKey: 'user_mail',
        flexGrow: 1,
    },
    {
        sortable: false,
        align: 'left',
        fixed: 'right',
        headerCell: '',
        dataKey: '',
        width: 60,
    },
];
