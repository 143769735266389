import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    InputCalculosNameProps,
    setInputTaxesValue,
} from '../../../../reducers/calculadoraPrevisaoDemanda/inputCalculos';
import { selectorCalculadoraPrevisaoDemanda } from '../../../../reducers/calculadoraPrevisaoDemanda/calculadoraPrevisaoDemandaReducer';
import { FormBox } from '../FormBox';
import { InputAddon } from '../index';
import * as utils from '../../utils';

export const TaxForm = () => {
    const { inputCalculos } = useSelector(
        selectorCalculadoraPrevisaoDemanda,
    );

    const { taxes } = inputCalculos.inputs;
    const dispatch = useDispatch();

    const handleChangeTaxes = (name:InputCalculosNameProps, value: string) => {
        const stringToNumber = Number(value.replace(',', '.'));
        dispatch(setInputTaxesValue({ name, value: stringToNumber }));
    };

    return (
        <FormBox.Root title="Impostos">
            <FormBox.Row>
                <FormBox.Label width="80px">
                    IPI
                </FormBox.Label>
                <InputAddon
                    position="right"
                    addon="%"
                    width="96px"
                    currency
                    value={utils.formatNumberComma(taxes.ipi)}
                    onChange={(value) => handleChangeTaxes('ipi', value)}
                />
            </FormBox.Row>
            <FormBox.Row>
                <FormBox.Label width="80px">
                    ICMS
                </FormBox.Label>
                <InputAddon
                    position="right"
                    addon="%"
                    width="96px"
                    currency
                    value={utils.formatNumberComma(taxes.icms)}
                    onChange={(value) => handleChangeTaxes('icms', value)}
                />
            </FormBox.Row>
            <FormBox.Row>
                <FormBox.Label width="80px">
                    PIS
                </FormBox.Label>
                <InputAddon
                    position="right"
                    addon="%"
                    width="96px"
                    currency
                    value={utils.formatNumberComma(taxes.pis)}
                    onChange={(value) => handleChangeTaxes('pis', value)}
                />
            </FormBox.Row>
            <FormBox.Row>
                <FormBox.Label width="80px">
                    COFINS
                </FormBox.Label>
                <InputAddon
                    position="right"
                    addon="%"
                    width="96px"
                    currency
                    value={utils.formatNumberComma(taxes.cofins)}
                    onChange={(value) => handleChangeTaxes('cofins', value)}
                />
            </FormBox.Row>
            <FormBox.Row>
                <FormBox.Label width="80px">
                    Outros
                </FormBox.Label>
                <InputAddon
                    position="right"
                    addon="%"
                    width="96px"
                    currency
                    value={utils.formatNumberComma(taxes.others)}
                    onChange={(value) => handleChangeTaxes('others', value)}
                />
            </FormBox.Row>
        </FormBox.Root>
  );
};
