import { useQuery } from '@tanstack/react-query';
import classNames from 'classnames';
import React, {
    forwardRef,
    useDeferredValue,
    useImperativeHandle,
    useState,
} from 'react';
import Modal, { ModalProps } from 'rsuite/lib/Modal';
import { ButtonPrimary, InputBox } from '../../../../../../../../components';
import { LoadingSpinerArea } from '../../../../../../../../components/LoadingSpinner';
import { checkIfIsLoading } from '../../../../../../../../utils';
import { getNegociacoesSalvas } from '../../../NegociacaoFornecedor.services';

export type AdicionarProdutoNegociacaoProps = ModalProps & {
    onSubmit?: (formData: { id: string }) => void;
    onHide?: () => void;
};

export type AdicionarProdutoRefProps = {
    reset: () => void;
};

export const AdicionarProdutoNegociacaoModal = forwardRef<
    AdicionarProdutoRefProps,
    AdicionarProdutoNegociacaoProps
>(({ className = '', onHide, onSubmit, ...props }, ref) => {
    const [selectedNegotiation, setSelectedNegotiation] = useState('');
    const [query, setQuery] = useState('');
    const deferredQuery = useDeferredValue(query);

    useImperativeHandle(ref, () => ({
        reset: () => setSelectedNegotiation(''),
    }));

    const handleSearch = (value: string) => setQuery(value);

    const { data, fetchStatus } = useQuery({
        initialData: [],
        queryKey: ['get-saved-negotiations', deferredQuery],
        queryFn: () => getNegociacoesSalvas(query),
    });

    const isLoading = checkIfIsLoading(fetchStatus);

    return (
        <Modal
            className={classNames('info-modal', className)}
            onHide={onHide}
            {...props}
        >
            <LoadingSpinerArea
                height="100%"
                size="md"
                area="negotiation/add-product-to-negotiation"
            />
            <form onSubmit={(e) => e.preventDefault()}>
                <Modal.Header>
                    <Modal.Title className="font-size-200-semibold">
                        Adicionar à negociação salva
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <InputBox>
                        <InputBox.Label>
                            Selecione uma negociação para adicionar o produto
                        </InputBox.Label>
                        <InputBox.Select
                            data={isLoading ? [] : data}
                            value={selectedNegotiation}
                            onChange={setSelectedNegotiation}
                            onSearch={handleSearch}
                            placeholder="Selecione uma opção"
                            locale={{
                                searchPlaceholder: 'Busque uma negociação',
                                noResultsText: isLoading
                                    ? 'Carregando negociações...'
                                    : 'Nenhuma negociação encontrada',
                            }}
                            onClean={() => handleSearch('')}
                        />
                    </InputBox>
                </Modal.Body>
                <Modal.Footer>
                    <ButtonPrimary
                        skin="blue"
                        theme="ghost"
                        type="button"
                        onClick={onHide}
                    >
                        FECHAR
                    </ButtonPrimary>
                    <ButtonPrimary
                        skin="blue"
                        theme="filled"
                        type="submit"
                        onClick={() =>
                            onSubmit?.({
                                id: selectedNegotiation,
                            })
                        }
                        disabled={!selectedNegotiation?.length}
                    >
                        SALVAR
                    </ButtonPrimary>
                </Modal.Footer>
            </form>
            <LoadingSpinerArea size="sm" area="saved-filter" height={null} />
        </Modal>
    );
});
