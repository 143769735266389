import classNames from 'classnames';
import React from 'react';
import styles from './FilterDescription.module.scss';

export const FilterDescription = ({
    children,
    className,
    ...props
}: React.HTMLAttributes<HTMLParagraphElement>) => {
    return (
        <p className={classNames(styles.description, className)} {...props}>
            {children}
        </p>
    );
};
