import React, { useState, useEffect, useReducer } from 'react';
import { FlexboxGrid, Container } from 'rsuite';
import Highcharts from 'highcharts';
import { withRouter, useParams } from 'react-router-dom';
import { LoadingSpiner } from '../../../../../../components/LoadingSpinner';
import Header from './Components/Header';
import Simulacao from './Components/Simulacao';
import { getPrevisaoSalva } from './services';

const PrevisaoSalva = ({ history }) => {
    const { id } = useParams();
    const [previsaoSalva, updatePrevisaoSalva] = useReducer(
        (prev, next) => ({
            ...prev,
            ...next,
        }),
        {
            header: {
                id: '',
                userEmail: '',
                date: '',
                store: '',
                product: '',
            },
            currentPrice: {
                value: 0,
                forecast: {
                    sales: 0,
                    revenue: 0,
                    profit: 0,
                },
                margin: 0,
                competitiveness: 0,
            },
            newPrice: {
                value: 0,
                forecast: {
                    sales: 0,
                    revenue: 0,
                    profit: 0,
                },
                margin: 0,
                competitiveness: 0,
            },
            variation: {
                sales: 0,
                revenue: 0,
                profit: 0,
            },
            elasticity: 0,
        },
    );
    const [graficoSemanal, setGraficoSemanal] = useState(null);
    const [graficoMensal, setGraficoMensal] = useState(null);
    const [graficoElasticidade, setGraficoElasticidade] = useState(null);

    const listPrevisaoSalva = async () => {
        const response = await getPrevisaoSalva(id);
        if (response) {
            const {
                id,
                current_price: currentPrice,
                new_price: newPrice,
                variation,
                product,
                date,
                user_mail: userEmail,
                weekly_total_sales_forecast: weeklyTotalSalesForecast,
                monthly_seasonalities_factor: monthlySeasonalitiesFactor,
                elasticity,
            } = response;

            const diaDaSemana = {
                TUESDAY: 'Ter',
                WEDNESDAY: 'Qua',
                THURSDAY: 'Qui',
                FRIDAY: 'Sex',
                SATURDAY: 'Sáb',
                SUNDAY: 'Dom',
                MONDAY: 'Seg',
            };

            if (weeklyTotalSalesForecast) {
                setGraficoSemanal({
                    exporting: {
                        enabled: false,
                    },
                    title: {
                        text: '',
                    },
                    chart: {
                        type: 'column',
                    },
                    series: [
                        {
                            name: 'Vigente',
                            data:
                                weeklyTotalSalesForecast?.days
                                    ?.map((item) => item.total_sales)
                                    .map((item) => item.current_price) || [],
                        },
                        {
                            name: 'Previsão',
                            data:
                                weeklyTotalSalesForecast?.days
                                    ?.map((item) => item.total_sales)
                                    .map((item) => item.new_price) || [],
                        },
                    ],
                    yAxis: {
                        title: {
                            text: 'Volume de venda',
                        },
                    },
                    xAxis: {
                        title: {
                            text: 'Dia da semana',
                        },
                        categories: weeklyTotalSalesForecast?.days.map(
                            (item) => diaDaSemana[item.day],
                        ),
                    },
                });
            } else {
                setGraficoSemanal(null);
            }

            if (monthlySeasonalitiesFactor) {
                setGraficoMensal({
                    exporting: {
                        enabled: false,
                    },
                    title: {
                        text: '',
                    },
                    series: [
                        {
                            name: 'Dia do mês',
                            data:
                                monthlySeasonalitiesFactor?.days_of_month?.map(
                                    (item) => item.factor,
                                ) || [],
                        },
                    ],
                    yAxis: {
                        title: {
                            text: 'Fator de sazonalidade (%)',
                        },
                    },
                    xAxis: {
                        title: {
                            text: 'Dia do mês',
                        },
                        tickInterval: 4,
                        categories: [
                            '1',
                            '2',
                            '3',
                            '4',
                            '5',
                            '6',
                            '7',
                            '8',
                            '9',
                            '10',
                            '11',
                            '12',
                            '13',
                            '14',
                            '15',
                            '16',
                            '17',
                            '18',
                            '19',
                            '20',
                            '21',
                            '22',
                            '23',
                            '24',
                            '25',
                            '26',
                            '27',
                            '28',
                            '29',
                            '30',
                            '31',
                        ],
                    },
                });
            } else {
                setGraficoMensal(null);
            }

            if (elasticity) {
                setGraficoElasticidade({
                    exporting: {
                        enabled: false,
                    },
                    chart: {
                        type: 'gauge',
                        backgroundColor: 'transparent',
                        height: 172,
                    },
                    title: {
                        text: '',
                    },
                    pane: {
                        startAngle: -90,
                        endAngle: 89.9,
                        background: null,
                        center: ['50%', '75%'],
                        size: '100%',
                    },
                    yAxis: {
                        min: 0,
                        max: 4,
                        tickPixelInterval: 0,
                        tickPosition: 'inside',
                        tickColor:
                            Highcharts.defaultOptions.chart.backgroundColor
                            || '#FFFFFF',
                        tickLength: 20,
                        tickWidth: 2,
                        minorTickInterval: null,
                        labels: {
                            formatter: () => null,
                        },
                        plotBands: [
                            {
                                from: 0,
                                to: 1,
                                color: '#F5F5F5',
                                thickness: 15,
                            },
                            {
                                from: 1,
                                to: 2,
                                color: '#CCE9FA',
                                thickness: 15,
                            },
                            {
                                from: 2,
                                to: 3,
                                color: '#559FF2',
                                thickness: 15,
                            },
                            {
                                from: 3,
                                to: 4,
                                color: '#0D66D0',
                                thickness: 15,
                            },
                        ],
                    },
                    tooltip: {
                        enabled: false,
                    },
                    series: [
                        {
                            name: 'Elasticidade',
                            data: [
                                elasticity < 0
                                    ? 0
                                    : elasticity > 4
                                    ? 4
                                    : +elasticity.toFixed(2),
                            ],
                            dataLabels: {
                                y: 100,
                                borderWidth: 0,
                                color: '#323232',
                                style: {
                                    fontSize: '24px',
                                    fontWeight: '300',
                                    fontFamily: 'Open Sans',
                                    fontStyle: 'normal',
                                },
                            },
                            dial: {
                                radius: '65%',
                                backgroundColor: '#505050',
                                baseWidth: 12,
                                baseLength: '0%',
                                rearLength: '0%',
                            },
                            pivot: {
                                backgroundColor: '#505050',
                                radius: 6,
                            },
                        },
                    ],
                });
            } else {
                setGraficoElasticidade(null);
            }

            updatePrevisaoSalva({
                header: {
                    id,
                    userEmail,
                    date,
                    store: product.store.description,
                    product: product.description,
                },
                currentPrice,
                newPrice,
                variation,
                elasticity,
            });
        }
    };

    useEffect(() => {
        listPrevisaoSalva();
    }, [id]);

    return (
        <Container className="main-container previsao-salva-page">
            <FlexboxGrid>
                <FlexboxGrid.Item colspan={24}>
                    <LoadingSpiner size="md" />
                    <Header header={previsaoSalva.header} history={history} />
                    <Simulacao
                        previsaoSalva={previsaoSalva}
                        forecast={previsaoSalva.currentPrice.forecast}
                        currentPrice={previsaoSalva.currentPrice}
                        newPrice={previsaoSalva.newPrice}
                        variation={previsaoSalva.variation}
                        elasticity={previsaoSalva.elasticity}
                        graficoSemanal={graficoSemanal}
                        graficoMensal={graficoMensal}
                        graficoElasticidade={graficoElasticidade}
                    />
                </FlexboxGrid.Item>
            </FlexboxGrid>
        </Container>
    );
};

export default withRouter(PrevisaoSalva);
