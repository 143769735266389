// @ts-nocheck
import { useQuery } from '@tanstack/react-query';

import { getVariationPrices } from '../services';

export const useVariationPrice = (params) => {
    const variationPrice = async () => {
        const resp = await getVariationPrices(params);
        if (resp) return resp;

        return [];
    };

    const {
        isFetching,
        data,
        refetch,
    } = useQuery(['variationPrice'], variationPrice, {
        retry: false,
        enabled: false,
    });

    const variationPriceData = data?.data;
    const variationPriceLoading = isFetching;
    const variationPriceRefetch = refetch;

    return { variationPriceData, variationPriceLoading, variationPriceRefetch };
};
