
import { getSegmentos, getItensSegmentoProduto } from '../services/ProdutoService';

export const ADD_SEGMENTO_CADASTRO_REGRA_ATACADO = 'ADD_SEGMENTO_CADASTRO_REGRA_ATACADO'
export const DELETE_SEGMENTO_CADASTRO_REGRA_ATACADO = 'DELETE_SEGMENTO_CADASTRO_REGRA_ATACADO'
export const CHANGE_VALUE_SEGMENTO_CADASTRO_REGRA_ATACADO = 'CHANGE_VALUE_SEGMENTO_CADASTRO_REGRA_ATACADO'
export const CHANGE_CACHE_SEGMENTO_CADASTRO_REGRA_ATACADO = 'CHANGE_CACHE_SEGMENTO_CADASTRO_REGRA_ATACADO'
export const SELECT_VALUE_SEGMENTO_CADASTRO_REGRA_ATACADO = 'SELECT_VALUE_SEGMENTO_CADASTRO_REGRA_ATACADO'
export const LIST_ITENS_SEGMENTOS_CADASTRO_REGRA_ATACADO = 'LIST_ITENS_SEGMENTOS_CADASTRO_REGRA_ATACADO'
export const GET_SEGMENTOS_CADASTRO_REGRA_ATACADO = 'GET_SEGMENTOS_CADASTRO_REGRA_ATACADO'
export const CLEAR_ALL_SEGMENTOS_CADASTRO_REGRA_ATACADO = 'CLEAR_ALL_SEGMENTOS_CADASTRO_REGRA_ATACADO' 
export const SET_SEGMENTOS_UPDATE_CADASTRO_REGRA_ATACADO = 'SET_SEGMENTOS_UPDATE_CADASTRO_REGRA_ATACADO'

export function addSegmento(filtro) {
    return {
        type: ADD_SEGMENTO_CADASTRO_REGRA_ATACADO,
        filtro
    }
}

export function deleteSegmento(index, name) {
    return {
        type: DELETE_SEGMENTO_CADASTRO_REGRA_ATACADO,
        index,
        name
    }
}

export function changeValueSegmento(value, name) {
    return {
        type: CHANGE_VALUE_SEGMENTO_CADASTRO_REGRA_ATACADO,
        value,
        name
    }
}

export function changeCacheSegmento(name, cache){
    return{
        type: CHANGE_CACHE_SEGMENTO_CADASTRO_REGRA_ATACADO,
        name,
        cache
    }
}

export function selectValueSegmento(item, name) {
    return {
        type: SELECT_VALUE_SEGMENTO_CADASTRO_REGRA_ATACADO,
        item,
        name
    }
}

export function listItensSegmentos(itens, name){
    return{ 
        type: LIST_ITENS_SEGMENTOS_CADASTRO_REGRA_ATACADO,
        itens,
        name
    }
}


export function clearSegmentos(){
    return{
        type: CLEAR_ALL_SEGMENTOS_CADASTRO_REGRA_ATACADO
    }
}


export function getAllSegmentos(itens, name){    
    var segmentos = {};
    itens.forEach(item => {
        segmentos[item.field] = { lista: [], value: [], cache: []}
    })
    return{ 
        type: GET_SEGMENTOS_CADASTRO_REGRA_ATACADO,
        itens,
        segmentos
    }
}

export function setSegmentosUpdateRegra(name, value){
    return{
        type: SET_SEGMENTOS_UPDATE_CADASTRO_REGRA_ATACADO,
        name, 
        value   
    }
}
  

export function searchSegmentos(){
    return dispatch => {
        getSegmentos()            
            .then(data => dispatch(getAllSegmentos(data)))
    }
}

export function searchItensSegmento(nivel, query, name){
    return dispatch => {
        getItensSegmentoProduto(nivel, query).then((data) => dispatch(listItensSegmentos(data, name)))
    }
}







