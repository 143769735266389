import { useState } from 'react';
import { getSummary } from '../../services';

export const useOnDemandSummary = () => {
    const [summaryData, setSummaryData] = useState();

    const handleGetSummary = async () => {
        const res = await getSummary();
        if (res) {
            setSummaryData(res);
        }
    };

    return {
        summaryData, handleGetSummary,
    };
};
