import { useCallback, useMemo, useState } from 'react';

type UseSortProps<SortColumn extends string = string> = {
    initialSortType?: 'asc' | 'desc';
    initialColumn?: SortColumn;
};

const INITIAL_SORT_TYPE = 'asc';
const INITIAL_COLUMN = '' as const;

export const useSort = <SortColumn extends string = string>({
    initialSortType = INITIAL_SORT_TYPE,
    initialColumn = INITIAL_COLUMN as SortColumn,
}: UseSortProps<SortColumn> = {}) => {
    const [sortType, setSortType] = useState<'asc' | 'desc'>(
        () => initialSortType,
    );
    const [sortColumn, setSortColumn] = useState<SortColumn>(
        () => initialColumn,
    );

    const onSortColumn = useCallback(
        (sortColumn: SortColumn, sortType: 'asc' | 'desc') => {
            setSortType(sortType);
            setSortColumn(sortColumn);
        },
        [],
    );

    const reset = useCallback(() => {
        setSortType(INITIAL_SORT_TYPE);
        setSortColumn(INITIAL_COLUMN as SortColumn);
    }, []);

    return useMemo(
        () => ({ sortType, sortColumn, onSortColumn, reset }),
        [sortType, sortColumn, onSortColumn, reset],
    );
};
