import React from 'react';
import { Button, Content, Dropdown, FlexboxGrid, IconButton } from 'rsuite';
import chevroDownIcon from '../../../../../../assets/icons/icon_chevron_down-blue.svg';
import iconEllipsis from '../../../../../../assets/icons/icon_ellipsis-v.svg';
import { LoadingSpinerLocal } from '../../../../../../components/LoadingSpinner';
import styles from './styles.module.scss';

const AllProductsSelected = ({
    onClickSeeLess,
    children,
    onClickDownload,
    loading,
    selectedQuantity,
    canDownload,
}) => (
    <Content className={styles.content}>
        <LoadingSpinerLocal loading={loading} size="md" />
        <FlexboxGrid justify="space-between">
            <FlexboxGrid.Item className={styles['see-less']}>
                <div>
                    <span className={styles['bottom-bold-text']}>
                        {selectedQuantity} produtos
                    </span>
                    <span className={styles['bottom-regular-text']}>
                        estão inclusos na seleção.
                    </span>
                </div>
                <Button
                    appearance="link"
                    className={styles['see-less-button']}
                    onClick={onClickSeeLess}
                >
                    Ver menos produtos
                    <img src={chevroDownIcon} alt="" />
                </Button>
            </FlexboxGrid.Item>
            {!canDownload ? (
                <FlexboxGrid.Item>
                    <Dropdown
                        id="all-products-selected-more-options"
                        placement="bottomEnd"
                        renderTitle={() => (
                            <IconButton
                                className={styles['more-options-button']}
                                appearance="subtle"
                                icon={
                                    <img alt="Mais opções" src={iconEllipsis} />
                                }
                            />
                        )}
                    >
                        <Dropdown.Item
                            onClick={() => onClickDownload('csv')}
                            id="btn-big-number-download-csv"
                        >
                            Download em CSV
                        </Dropdown.Item>
                        <Dropdown.Item
                            onClick={() => onClickDownload('xlsx')}
                            id="btn-big-number-download-xls"
                        >
                            Download em XLS
                        </Dropdown.Item>
                    </Dropdown>
                </FlexboxGrid.Item>
            ) : null}
        </FlexboxGrid>
        {children}
    </Content>
);

export default AllProductsSelected;
