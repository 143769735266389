import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    reducerOnDemandSelector,
    setSingleRequestData,
} from '../../../../../../reducers/reducerOnDemand';
import { cepService } from '../../../../../../services/CepService';

export const useOnDemandGetAddressFromCep = () => {
    const [cep, setCep] = useState('');
    const [notSameCity, setNotSameCity] = useState(false);

    const reduceDispatch = useDispatch();
    const { singleRequestData } = useSelector(reducerOnDemandSelector);

    const {
        isLoading, isError, data, error, isSuccess, isFetching,
    } = useQuery(
        ['getAddressFromCep', cep],
        async () => {
            const res = await cepService(cep);
            return res.data;
        },
        {
            enabled: cep !== '',
        },
    );

    const handleGetCepRequest = (query: string) => {
        const formatQuery = query.replace('-', '').replace(' ', '');
        setNotSameCity(false);
        if (formatQuery.length === 8) {
            setCep(formatQuery);
        }
    };

    useEffect(() => {
        if (isSuccess) {
            const newObj = {
                ...singleRequestData,
                cep: data.cep,
                logradouro: data.logradouro,
            };

            const hasSameCity
                = singleRequestData?.cidade?.toUpperCase() === data.localidade?.toUpperCase();

            reduceDispatch(
                setSingleRequestData({
                    name: 'singleRequestData',
                    data: newObj,
                }),
            );

            if (!hasSameCity) {
                setNotSameCity(true);
            } else {
                setNotSameCity(false);
            }
        }
    }, [data, singleRequestData?.cidade, isSuccess]);

    return {
        isLoading,
        isError: isError || notSameCity,
        isSuccess,
        isFetching,
        data,
        error,
        setCep,
        handleGetCepRequest,
    };
};
