import Immutable from 'seamless-immutable';
import moment from 'moment';
import {
    SET_FREE_PRODUCT, UPDATE_FREE_DATA, SET_ENOUGH_DATA_EDIT, SET_CITIES_UPDATE_FREE,
    SET_STATES_UPDATE_FREE, SET_ENOUGH_DATA_EDIT_NULL,
} from '../actions/actionsProductConfiguration';

const productConfigurationState = Immutable({
    infopanel: {
        channel: '',
        region: '',
        registerDate: '',
        updateDate: '',
        city: '',
        state: '',
    },
    hasEnoughData: null,
    states: [],
    cities: [],
});

function productConfigurationDataReducer(state = productConfigurationState, action) {
    switch (action.type) {
        case SET_FREE_PRODUCT:
            return state.merge({
                ...state,
                infopanel: {
                    ...state.infopanel,
                    channel: action.data.canal,
                    region: action.data.agrupamentoPais ? 'pais' : action.data.cidade ? 'cidade' : 'estado',
                    city: action.data?.cidade?.cidade,
                    state: action.data?.estado?.descricao || '',
                    registerDate: moment(action.data.dataCadastro).format('DD/MM/YYYY hh:mm:ss'),
                    updateDate: moment(action.data.dataAlteracao).format('DD/MM/YYYY hh:mm:ss'),
                    daysDiff: moment().diff(moment(action.data.dataAlteracao).toDate(), 'days'),
                    firstUpdateDiff: moment().diff(moment(action.data.dataCadastro).toDate(), 'days'),
                },
            });
        case UPDATE_FREE_DATA:
            return state.merge({
                ...state,
                infopanel: {
                    ...state.infopanel,
                    [action.key]: action.value,
                },
            });
        case SET_ENOUGH_DATA_EDIT:
            return state.merge({
                ...state,
                hasEnoughData: action.data.permiteCadastro,
            });
        case SET_ENOUGH_DATA_EDIT_NULL:
            return state.merge({
                ...state,
                hasEnoughData: null,
            });
        case SET_STATES_UPDATE_FREE:
            return state.merge({
                ...state,
                states: action.states.map((item) => ({
                    label: item.descricao,
                    value: item.descricao,
                    descricao: item.descricao,
                    name: item.value,
                })),
            });
        case SET_CITIES_UPDATE_FREE:
            return state.merge({
                ...state,
                cities: action.cities.map((item) => ({
                    label: `${item.cidade} - ${item.regiao.nome}`,
                    value: `${item.cidade} - ${item.regiao.nome}`,
                    codigo: item.codigo,
                })),
            });
        default:
            return state;
    }
}

export default productConfigurationDataReducer;
