import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import moment from 'moment';
import 'powerbi-client/dist/powerbi.js';
import React, { useEffect } from 'react';
import { Router } from 'react-router-dom';
import 'rsuite/dist/styles/rsuite-default.css';
import RouteChange from './components/RouteChange';
import PrimaryLayout from './layouts/PrimaryLayout';
import history from './utils/History';
import { startLogRocket } from './utils/LogRocketConfig';
import { startTagManager } from './utils/TagManagerConfig';

require('moment/locale/pt-br');

moment.locale('pt-br');

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            retry: false,
        },
    },
});

const App = () => {
    useEffect(() => {
        if (process.env.NODE_ENV === 'production') {
            startLogRocket();
            startTagManager();
        }
    }, []);

    return (
        <QueryClientProvider client={queryClient}>
            <Router history={history}>
                <RouteChange />
                <PrimaryLayout />
            </Router>
            <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
    );
};

export default App;
