import { trackPromise } from 'react-promise-tracker';
import api from '../../../../../utils/API';

export const saveBloco = ({ data, id }) => trackPromise(
        api({
            url: `${process.env.REACT_APP_IPA_URL}/campaign/campaigns/${id}/offer-groups`,
            method: 'POST',
            expectToken: true,
            params: {
                clientId: JSON.parse(localStorage.getItem('cliente'))?.codigo,
            },
            data: JSON.stringify(data),
        }).then((resp) => resp),
    );

export const listBlocos = (id, params) => trackPromise(
    api({
        url: `${process.env.REACT_APP_IPA_URL}/campaign/campaigns/${id}/offer-groups`,
        method: 'GET',
        expectToken: true,
        params: {
            clientId: JSON.parse(localStorage.getItem('cliente'))?.codigo,
            size: params?.size || 20,
            page: params?.page || 0,
        },
    }).then((resp) => resp.data),
);

export const deleteBloco = (id, offerGroupId) => trackPromise(
        api({
            url: `${process.env.REACT_APP_IPA_URL}/campaign/campaigns/${id}/offer-groups/${offerGroupId}`,
            method: 'DELETE',
            expectToken: true,
            params: {
                clientId: JSON.parse(localStorage.getItem('cliente'))?.codigo,
            },
        }).then((resp) => resp),
    );

export const listBlocoSelecionado = (id, offerGroupId) => trackPromise(
    api({
        url: `${process.env.REACT_APP_IPA_URL}/campaign/campaigns/${id}/offer-groups/${offerGroupId}`,
        method: 'GET',
        expectToken: true,
        params: {
            clientId: JSON.parse(localStorage.getItem('cliente'))?.codigo,
        },
    }).then((resp) => resp.data),
);

export const updateBloco = ({ data, id }) => trackPromise(
    api({
        url: `${process.env.REACT_APP_IPA_URL}/campaign/campaigns/${id}/offer-groups/${data.id}`,
        method: 'PUT',
        expectToken: true,
        params: {
            clientId: JSON.parse(localStorage.getItem('cliente'))?.codigo,
        },
        data: JSON.stringify(data),
    }).then((resp) => resp),
);
