import React, { useEffect, useRef, useState } from 'react';
import {
    Whisper, Icon, Tooltip, Row, Col, CheckPicker, Grid, Toggle, Button,
} from 'rsuite';
import TagsInput from 'react-tagsinput';
import 'react-tagsinput/react-tagsinput.css';

import { LoadingMenu, LoadingSpiner } from '../../../../../components/LoadingSpinner';
import ModalGtinIvalido from './ModalGtinInvalido';
import ModalConfirmacaoFiltro from './ModalConfirmacaoFiltro';
import { isGtinValid, setDTO, setFiltersModelNovaLista } from '../utils';
import { getFiltroItens, getLojas, getPreviewNovaLista } from '../services';
import ResultadoValidacao from './ResultadoValidacao';

const NovaLista = () => {
    const resultRef = useRef(null);

    const size = 20;
    const [gtinList, setGtinList] = useState([]);
    const [invalidGtins, setInvalidGtins] = useState([]);
    const [result, setResult] = useState([]);
    const [page, setPage] = useState(0);
    const [totalItens, setTotalItens] = useState(0);
    const [showModalConfirmacao, setModalConfirmacao] = useState(false);
    const [showModalLongList, setShowModalLongList] = useState(false);
    const [proceedSearch, setProceedSearch] = useState(false);
    const [filtersData, setFilterData] = useState({
        uf: {
            value: [],
            data: [],
            cache: [],
        },
        cidade: {
            value: [],
            data: [],
            cache: [],
        },
        rede: {
            value: [],
            data: [],
            cache: [],
        },
        loja: {
            value: [],
            data: [],
            cache: [],
        },
        todasLojas: false,
    });

    const filterInvalidGtin = (data) => {
        setInvalidGtins(data.filter((item) => !isGtinValid(item)));
    };

    const changeGtinList = (tags) => {
        filterInvalidGtin(tags);
        setGtinList(tags);
    };

    const pasteSplit = (data) => {
        const separators = [',', ';', '\\(', '\\)', '\\*', '/', ':', '\\?', '\n', '\r'];
        const splitedData = data.split(new RegExp(separators.join('|'))).map((d) => d.trim()).filter((item) => item.length);

        filterInvalidGtin(splitedData);
        return splitedData;
    };

    const clearList = () => {
        setGtinList([]);
        setInvalidGtins([]);
        setFilterData({
            uf: {
                value: [],
                data: [],
                cache: [],
            },
            cidade: {
                value: [],
                data: [],
                cache: [],
            },
            rede: {
                value: [],
                data: [],
                cache: [],
            },
            loja: {
                value: [],
                data: [],
                cache: [],
            },
            todasLojas: false,
        });
    };

    const removeInvalidGtinFromList = () => {
        setGtinList(gtinList.filter((item) => isGtinValid(item)));
        setInvalidGtins([]);
    };

    const searchLojas = async (query) => {
       getLojas(setFiltersModelNovaLista({ name: 'loja', query, filters: filtersData })).then((response) => {
           setFilterData((oldState) => (
               {
                   ...oldState,
                   loja: {
                       ...oldState.loja,
                       data: response?.map((
                           item,
                       ) => ({ label: item.descricao, value: item.identificador })).concat(
                           ...oldState.loja.cache.filter(
                               (item) => response.findIndex(
                                   (itemLista) => itemLista.identificador === item.value,
                               ) === -1,
                           ),
                       ),
                   },
               }));
       });
    };

    const searchFilterItens = async (name, query) => {
        await getFiltroItens(setFiltersModelNovaLista({ name, query, filters: filtersData })).then((response) => {
            setFilterData((oldState) => (
                {
                    ...oldState,
                    [name]: {
                        ...oldState[name],
                        data: response?.map((
                            item,
                        ) => ({ label: item, value: item })).concat(
                            ...oldState[name].cache.filter(
                                (item) => response.findIndex(
                                    (itemLista) => itemLista === item.label,
                                ) === -1,
                            ),
                        ) || [],
                    },
                }));
        });
    };

    const clearFilter = (name) => {
        setFilterData((oldState) => ({ ...oldState, [name]: { ...oldState[name], cache: [], value: [] } }));
    };

    const selectFilter = (value, item, name) => {
        const cache = [...filtersData[name].cache, item];
        let updatedCache = cache;

        if (!value.length) {
            if (name === 'loja') {
                searchLojas('');
            } else {
                searchFilterItens(name, '');
            }
            updatedCache = [];
        }

        if (value !== null && value.length !== cache.length) {
            updatedCache = cache.filter(
                (item) => value.indexOf(item.label) !== -1,
            );
        }

        setFilterData((oldState) => (
            {
                ...oldState,
                [name]: {
                    ...oldState[name],
                    cache: updatedCache,
                    value,
                },
            }));
    };
    const setTodasLojas = (checked) => {
        if (checked) {
            setFilterData({
                uf: {
                    value: [],
                    data: [],
                    cache: [],
                },
                cidade: {
                    value: [],
                    data: [],
                    cache: [],
                },
                rede: {
                    value: [],
                    data: [],
                    cache: [],
                },
                loja: {
                    value: [],
                    data: [],
                    cache: [],
                },
                todasLojas: true,
            });
        } else {
            setFilterData((oldState) => ({
                ...oldState,
                todasLojas: false,
            }));
        }
    };

    const executeScroll = () => {
        if (resultRef?.current) {
            resultRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const getNovaLista = (dto) => {
        if (gtinList.length) {
            getPreviewNovaLista(dto).then((response) => {
                setResult(response.data.datapoints.content);
                setTotalItens(response.data.totalElements);
                setPage(response.data.datapoints.number);
                executeScroll();
            });
        }
    };

    const getNewPage = () => {
        const dto = setDTO(gtinList, filtersData, { size, page });
        dto.codigoValidacaoLista = (page > 0 && result[0]?.clienteValidacaoLista?.id) || null;

        getNovaLista(dto);
    };

    const manterBusca = () => {
        setShowModalLongList(false);

        const dto = setDTO(gtinList, filtersData, { size, page: 0 });
        dto.quantityAcepted = true;

        getNovaLista(dto);
    };

    const getResult = () => {
        if (gtinList.length) {
            getPreviewNovaLista(setDTO(gtinList, filtersData, { size, page: 0 })).then((response) => {
                if (response?.status === 200) {
                    setResult(response.data.datapoints.content);
                    setTotalItens(response.data.datapoints.totalElements);
                    setPage(response.data.datapoints.number);
                    executeScroll();
                }
                if (response?.status === 202) {
                    setShowModalLongList(true);
                    setTotalItens(response.data.totalElements);
                }
            });
        }
    };

    const hasLojaFilter = () => {
        if (!filtersData.loja.value.length && !filtersData.rede.value.length && !filtersData.uf.value.length
            && !filtersData.cidade.value.length && !filtersData.todasLojas) {
            setModalConfirmacao(true);
        } else {
            getResult();
        }
    };

    const confirmTodasLojas = () => {
        setModalConfirmacao(false);
        setFilterData((oldState) => ({ ...oldState, todasLojas: true }));
        setProceedSearch(true);
    };

    const changePage = (page) => {
        setPage(page - 1);
    };

    useEffect(() => {
        if (proceedSearch) {
            getResult();
            setProceedSearch(false);
        }
    }, [filtersData.todasLojas, proceedSearch]);

    useEffect(() => {
        if (page > 0) {
            getNewPage();
        }
    }, [page]);

    return (
        <>
            <div className="nova-lista-component">
                <Grid fluid>
                    <Row>
                        <Col xs={24} md={24}>
                            <div className="input-area">
                                <label htmlFor="products-input">Produtos</label>

                                <TagsInput
                                    addOnPaste
                                    value={gtinList}
                                    onChange={(tags) => changeGtinList(tags)}
                                    pasteSplit={pasteSplit}
                                    onlyUnique
                                    inputProps={{ placeholder: 'Digite ou cole aqui a lista de código dos produtos (GTIN) para revisão' }}
                                />
                            </div>
                            <div>
                                <span className="help-text">
                                    Os códigos devem ser copiados de uma planilha ou separados por qualquer um dos seguintes caracteres especiais: , ; / : *
                                </span>
                            </div>
                            {gtinList.length ? (
                                <div>
                                    <span className="help-text">
                                        {gtinList.length}
                                        {' '}
                                        códigos de produto serão revisados.
                                    </span>
                                </div>
                            ) : null}
                        </Col>

                    </Row>
                </Grid>
                <Grid fluid className="filter-area">
                    <Row>
                        <Col xs={24}>
                            <div className="filter-area__label">
                                <p>lojas </p>
                                <Whisper placement="right" trigger="hover" speaker={<Tooltip>Lojas concorrentes já cadastradas para o cliente</Tooltip>}>
                                    <Icon className="tooltip-info" icon="info" />
                                </Whisper>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12} xs={24}>
                            <CheckPicker
                                className="inf-picker"
                                maxHeight={200}
                                block
                                disabled={filtersData.todasLojas}
                                id="columns-select-picker"
                                filter={false}
                                cleanable
                                data={filtersData.uf.data}
                                value={filtersData.uf.value}
                                placeholder="Seleciona UF"
                                locale={{
                                    searchPlaceholder: 'Pesquisar',
                                    noResultsText: 'Nenhum item encontrado',
                                }}
                                onOpen={() => searchFilterItens('uf', '')}
                                onSearch={(query) => searchFilterItens('uf', query)}
                                onSelect={(value, item) => selectFilter(value, item, 'uf')}
                                onClean={() => clearFilter('uf')}
                                renderMenu={(menu) => (
                                    <span>
                                        <LoadingMenu area="revisao-filter" />
                                        {menu}
                                    </span>
                                )}
                            />
                        </Col>
                        <Col md={12} xs={24}>
                            <CheckPicker
                                className="inf-picker"
                                maxHeight={200}
                                block
                                disabled={filtersData.todasLojas}
                                id="columns-select-picker"
                                filter={false}
                                cleanable
                                data={filtersData.cidade.data}
                                value={filtersData.cidade.value}
                                placeholder="Seleciona Cidade"
                                locale={{
                                    searchPlaceholder: 'Pesquisar',
                                    noResultsText: 'Nenhum item encontrado',
                                }}
                                onOpen={() => searchFilterItens('cidade', '')}
                                onSearch={(query) => searchFilterItens('cidade', query)}
                                onSelect={(value, item) => selectFilter(value, item, 'cidade')}
                                onClean={() => clearFilter('cidade')}
                                renderMenu={(menu) => (
                                    <span>
                                        <LoadingMenu area="revisao-filter" />
                                        {menu}
                                    </span>
                                )}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12} xs={24}>
                            <CheckPicker
                                className="inf-picker"
                                maxHeight={200}
                                block
                                disabled={filtersData.todasLojas}
                                id="columns-select-picker"
                                filter={false}
                                cleanable
                                data={filtersData.rede.data}
                                value={filtersData.rede.value}
                                placeholder="Seleciona Rede"
                                locale={{
                                    searchPlaceholder: 'Pesquisar',
                                    noResultsText: 'Nenhum item encontrado',
                                }}
                                onOpen={() => searchFilterItens('rede', '')}
                                onSearch={(query) => searchFilterItens('rede', query)}
                                onSelect={(value, item) => selectFilter(value, item, 'rede')}
                                onClean={() => clearFilter('rede')}
                                renderMenu={(menu) => (
                                    <span>
                                        <LoadingMenu area="revisao-filter" />
                                        {menu}
                                    </span>
                                )}
                            />
                        </Col>
                        <Col md={12} xs={24}>
                            <CheckPicker
                                className="inf-picker"
                                maxHeight={200}
                                block
                                disabled={filtersData.todasLojas}
                                id="columns-select-picker"
                                filter={false}
                                cleanable
                                data={filtersData.loja.data}
                                value={filtersData.loja.value}
                                placeholder="Seleciona Loja"
                                locale={{
                                    searchPlaceholder: 'Pesquisar',
                                    noResultsText: 'Nenhum item encontrado',
                                }}
                                onSearch={(query) => searchLojas(query)}
                                onOpen={() => searchLojas('')}
                                onSelect={(value, item) => selectFilter(value, item, 'loja')}
                                onClean={() => clearFilter('loja')}
                                renderMenu={(menu) => (
                                    <span>
                                        <LoadingMenu area="revisao-filter" />
                                        {menu}
                                    </span>
                                )}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={8} xs={24} className="lojas-cadastradas">
                            <Toggle checked={filtersData.todasLojas} onChange={(value) => setTodasLojas(value)} />
                            <span>Considerar todas lojas cadastradas</span>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={24} md={6} lg={5} lgPush={14} mdPush={12}>
                            <Button
                                id="btn-clear-input"
                                className="btn-clear-input"
                                block
                                appearance="ghost"
                                onClick={() => clearList()}
                            >
                                limpar campos

                            </Button>
                        </Col>
                        <Col xs={24} md={6} lg={5} lgPush={14} mdPush={12}>
                            <Button
                                id="btn-result"
                                className="btn-result"
                                block
                                appearance="primary"
                                onClick={() => hasLojaFilter()}
                                disabled={!gtinList.length}
                            >
                                obter resultado

                            </Button>
                        </Col>
                    </Row>
                    <LoadingSpiner size="md" />
                </Grid>
            </div>
            {result?.length
                ? (
                    <ResultadoValidacao
                        ref={resultRef}
                        resultado={result}
                        page={page}
                        size={size}
                        totalItens={totalItens}
                        onChangePage={changePage}
                        tipo="NOVA_LISTA"
                    />
                )
                : null}
            {invalidGtins?.length ? (
                <ModalGtinIvalido
                    gtins={invalidGtins}
                    show
                    totalGtins={gtinList.length}
                    onConfirm={() => removeInvalidGtinFromList()}
                    onCancel={() => clearList()}
                />
            ) : null}
            {showModalConfirmacao
                ? (
                    <ModalConfirmacaoFiltro
                        show
                        title="Considerar todas lojas cadastradas?"
                        description="Para obter resultados mais direcionados, preencha um dos campos de filtro de lojas."
                        btnCancel="Atualizar filtros"
                        btnConfirm="sim, PROSSEGUIR"
                        onConfirm={() => confirmTodasLojas()}
                        onCancel={() => setModalConfirmacao(false)}
                    />
                )
                : null}
            {showModalLongList ? (
                <ModalConfirmacaoFiltro
                    show={showModalLongList}
                    title="O processamento dos dados deve demorar mais que o normal. "
                    description="Precisaremos de mais tempo para realizar essa revisão. Você pode otimizar sua consulta utilizando os filtros ou aguardar."
                    btnCancel="Atualizar filtros"
                    btnConfirm="OK, AGUARDAR"
                    onConfirm={() => manterBusca()}
                    onCancel={() => setShowModalLongList(false)}
                />
            ) : null}
        </>

    );
};

export default NovaLista;
