import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import {
    Button,
    Col,
    FlexboxGrid,
    Grid,
    Header,
    Row,
} from 'rsuite';
import {
    reducerOnDemandSelector,
} from '../../../../../../reducers/reducerOnDemand';
import ModalHowItWorks from '../ModalHowItWorks';
import ModalUpload from '../ModalUpload';
import { PendingRequestBox } from '../PendingRequestBox';
import useRequestPending from '../../Hooks/useRequestPending';
import AdsClickIcon from '../../../../../../assets/icons/icon_target_click_blue.svg';
import uploadIcon from '../../../../../../assets/icons/icon_upload.svg';

import styles from './styles.module.scss';

export interface IPageHeader {
    showResultList?: () => void;
    client?: boolean;
}

const PageHeader = ({
 showResultList, client,
}: IPageHeader) => {
    const [showModalUpload, setShowModalUpload] = useState(false);
    const [showModalHowItWorks, setShowModalHowItWorks] = useState(false);

    const { hasPendingRequest } = useSelector(reducerOnDemandSelector);

    const history = useHistory();
    const location = useLocation();
    const isSystemRequest = location.pathname.includes('sistema');

    const { pendingData, refetch } = useRequestPending();

    const handleSendSystemRequest = () => {
        history.push('/isa/solicitacao-ondemand/sistema');
    };

    const handleShowModal = () => {
        setShowModalUpload(true);
    };

    useEffect(() => {
        if (!hasPendingRequest) {
            return;
        }

        const interval = setInterval(() => {
            if (hasPendingRequest) {
                refetch();
            }
        }, 60000);

        return () => clearInterval(interval);
    }, [hasPendingRequest, pendingData]);

    return (
        <Header className={styles.header}>
            <Grid fluid>
                <Row className={styles['title-wrapper']}>
                    <div className={styles['icon-wrapper']}>
                        <img src={AdsClickIcon} alt="" />
                    </div>
                    <Col lg={24} md={24} sm={24} xs={24}>
                        <h1>On Demand</h1>
                        <div className={styles['subtitle-wrapper']}>
                            <span className={styles.subtitle}>
                                Consulte, acompanhe e envie novas solicitações de
                                dados de produtos e localidade para enriquecer os
                                seus resultados.
                            </span>
                            <Button
                                appearance="link"
                                className={styles['link-text']}
                                onClick={() => setShowModalHowItWorks(true)}
                            >
                                Entenda como funciona
                            </Button>
                        </div>
                    </Col>
                </Row>
                <FlexboxGrid className={styles['competitivity-type-wrapper']}>
                    <FlexboxGrid.Item>
                        <Button
                            className={
                                styles[
                                    isSystemRequest
                                        ? 'ondemand-nav-type-active'
                                        : 'ondemand-nav-type'
                                ]
                            }
                            onClick={handleSendSystemRequest}
                        >
                            <p className={styles['nav-box-title']}>Enviar solicitações por sistema</p>
                            <p className={styles['nav-box-subtitle']}>Faça suas solicitações sem arquivos externos</p>
                        </Button>
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item>
                        <Button
                            className={
                                styles['ondemand-nav-type']
                            }
                            onClick={handleShowModal}
                        >
                            <p className={styles['nav-box-title']}>
                                Enviar solicitações por arquivo
                                <span className={styles['upload-icon']}>
                                    <img src={uploadIcon} alt="" />
                                </span>
                            </p>
                            <p className={styles['nav-box-subtitle']}>Faça upload de suas solicitações em massa</p>
                        </Button>
                    </FlexboxGrid.Item>
                    {pendingData?.length ? (
                        <FlexboxGrid.Item>
                            <PendingRequestBox />
                        </FlexboxGrid.Item>
                    ) : null}
                </FlexboxGrid>
            </Grid>
            <ModalUpload
                show={showModalUpload}
                client={client}
                onCancel={() => setShowModalUpload(false)}
                onSuccess={() => showResultList()}
            />
            <ModalHowItWorks
                show={showModalHowItWorks}
                onCancel={() => setShowModalHowItWorks(false)}
            />
        </Header>
    );
};

export default PageHeader;
