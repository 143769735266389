import React, { useEffect, useState } from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import {
    Button,
    Col,
    Container, Content,
    Grid,
    Header,
    Icon, Row,
    Tooltip,
    Whisper,
} from 'rsuite';

import { LoadingSpiner } from '../../../../components/LoadingSpinner';
import { getDashboardRetorno } from './services';

const GestaoRetorno = () => {
    const history = useHistory();
    const [dashboardUrl, setDashboardUrl] = useState(null);

    async function getDashboard() {
        const response = await getDashboardRetorno();
        if (response?.url) {
            setDashboardUrl(response.url);
        }
    }

    useEffect(() => {
        getDashboard();
    }, []);

    return (
        <Container className="main-container gestao-retorno">
            <LoadingSpiner size="md" />
            <Header className="description">
                <div>
                    <h1>
                        Gestão do Retorno
                        <Whisper
                            placement="right"
                            trigger="hover"
                            speaker={(
                                <Tooltip>
                                    <p>
                                        {' '}
                                        <b>O Retorno é o percentual de preços coletados (encontrados e validados) sobre o total de preços pesquisados.</b>
                                    </p>
                                    <p>
                                        Não são considerados na métrica de Retorno casos em que uma coleta inteira não é realizada,
                                        por exemplo quando uma loja está bloqueada, quando uma coleta não é feita por ausência do pesquisador.
                                    </p>
                                </Tooltip>
                            )}
                        >
                            <Icon className="tooltip-info" icon="info" />
                        </Whisper>
                    </h1>
                    <p>
                        Analise o Retorno da sua pesquisa e identifique as principais oportunidades
                    </p>
                    <Grid fluid>
                        <Row className="row-btn">
                            <Col xs={24} md={18}>
                                <div className="info-area">
                                    <span><Icon className="tooltip-info" icon="info" /></span>
                                    <span><p>Quer melhorar seu Retorno? Revise sua lista e receba recomendações</p></span>
                                </div>
                            </Col>
                            <Col xs={24} md={6}>
                                <Button
                                    className="btn-go-to-review"
                                    appearance="ghost"
                                    onClick={() => history.push('/isa/revisao-lista')}
                                >
                                    Ir para revisão de lista

                                </Button>
                            </Col>
                        </Row>
                    </Grid>
                </div>
            </Header>

            <Content className="iframe-area">
                <iframe
                    title="Gestão de Retorno"
                    src={dashboardUrl}
                    frameBorder="0"
                />
            </Content>

        </Container>
    );
};

export default withRouter(GestaoRetorno);
