import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IGerenciadorPrecosDatapoint } from '../../@types';
import { RootState } from '../../@types/RootState';

export type RowData = Partial<IGerenciadorPrecosDatapoint>;

export type GetFamilyModalDatapointsState = RowData[];

const initialState: GetFamilyModalDatapointsState = [];

const slice = createSlice({
    name: 'getFamilyModal/datapoints',
    initialState,
    reducers: {
        SET_GET_FAMILY_MODAL_DATAPOINTS: (
            _state,
            { payload }: PayloadAction<GetFamilyModalDatapointsState>,
        ) => payload,

        UPDATE_GET_FAMILY_MODAL_DATAPOINT: (
            state,
            {
                payload,
            }: {
                payload: {
                    index: number;
                    updatedData: Partial<RowData>;
                };
            },
        ) => {

            state[payload.index] = {
                ...state[payload.index],
                ...payload.updatedData,
            };
        },
    },
});

export const {
    SET_GET_FAMILY_MODAL_DATAPOINTS,
    UPDATE_GET_FAMILY_MODAL_DATAPOINT,
} = slice.actions;

export const selectorGetFamilyModalDatapoints = (state: RootState) =>
    state.getFamilyModalReducer.datapoints;

export default slice.reducer;
