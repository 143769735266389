import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import styles from './styles.module.scss';

export const GeneralAverageBox = ({ className, data, isRetail }) => {
    const graficoData = {
        exporting: {
            enabled: false,
        },
        chart: {
            type: 'gauge',
            backgroundColor: 'transparent',
            height: 220,
        },
        title: {
            text: '',
        },
        pane: {
            startAngle: -90,
            endAngle: 89.9,
            background: null,
            center: ['50%', '75%'],
            size: '135%',
        },
        plotOptions: {
            gauge: {
                wrap: false,
            },
        },
        yAxis: {
            min: 75,
            max: 125,
            tickInterval: 5,
            tickLength: 125,
            tickWidth: 0,
            minorTickInterval: null,
            labels: {
                distance: 16,
                style: {
                    fontSize: '12px',
                    color: '#959595',
                },
                formatter() {
                    const label = this.axis.defaultLabelFormatter.call(this);
                    if (label % 2 === 1) {
                        return `${label}%`;
                    }
                },
            },
            plotBands: [
                {
                    from: 75,
                    to: 85,
                    color: '#EC5B62', // red
                    thickness: 27,
                },
                {
                    from: 85,
                    to: 95,
                    color: '#F29423', // yellow
                    thickness: 27,
                },
                {
                    from: 95,
                    to: 105,
                    color: '#33AB84', // green
                    thickness: 27,
                },
                {
                    from: 105,
                    to: 115,
                    color: '#F29423', // yellow
                    thickness: 27,
                },
                {
                    from: 115,
                    to: 125,
                    color: '#EC5B62', // red
                    thickness: 27,
                },
            ],
        },
        tooltip: {
            enabled: false,
        },
        series: [
            {
                data: [data],
                dataLabels: {
                    y: 100,
                    formatter() {
                        const label = this.y / 100;
                        return label.toLocaleString('pt-BR', {
                            style: 'percent',
                            minimumFractionDigits: 1,
                            maximumFractionDigits: 1,
                        });
                    },
                    borderWidth: 0,
                    color: '#323232',
                    style: {
                        fontSize: '32px',
                        fontWeight: '300',
                        fontFamily: 'Open Sans',
                        fontStyle: 'normal',
                    },
                },
                dial: {
                    radius: '65%',
                    backgroundColor: '#505050',
                    baseWidth: 12,
                    baseLength: '0%',
                    rearLength: '0%',
                },
                pivot: {
                    backgroundColor: '#505050',
                    radius: 6,
                },
            },
        ],
    };

    return (
        <div className={`${styles['generate-average-box']} ${className}`}>
            <p className={styles['generate-average-title']}>
                Competitividade média geral
            </p>
            <div className={styles['gauge-chart-container']}>
                <HighchartsReact
                    highcharts={Highcharts}
                    options={graficoData}
                />
            </div>
            <div className={styles['average-wrapper']}>
                <div className={styles['result-text-wrapper']}>
                    <p className={styles['regular-text']}>Está em média</p>
                    {data >= 100 ? (
                        <p className={styles['bold-text']}>
                            {(data - 100)
                                .toFixed(1)
                                .toString()
                                .replace('.', ',')}
                            % mais caro
                        </p>
                    ) : (
                        <p className={styles['bold-text']}>
                            {(100 - data)
                                .toFixed(1)
                                .toString()
                                .replace('.', ',')}
                            % mais barato
                        </p>
                    )}
                </div>
                <p className={styles['regular-text']}>
                    no período analisado consolidado
                </p>
                <p className={styles['item-selected-text']}>
                    {isRetail
                        ? 'Competitividade média versus as redes selecionadas'
                        : 'Competitividade média versus os produtos selecionados'}
                </p>
            </div>
        </div>
    );
};
