import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
    Button,
    Container,
} from 'rsuite';
import {
    setResultFromEditScenario,
    setResultScenarioData,
} from '../../../../reducers/calculadoraPrevisaoDemanda/resultScenario';
import {
    setFiltersFromEditScenario,
} from '../../../../reducers/calculadoraPrevisaoDemanda/filtros';
import {
    setInputFromEditScenario,
} from '../../../../reducers/calculadoraPrevisaoDemanda/inputCalculos';
import {
    selectorCalculadoraPrevisaoDemanda,
} from '../../../../reducers/calculadoraPrevisaoDemanda/calculadoraPrevisaoDemandaReducer';
import arrowLeftIcon from '../../../../assets/icons/icon_arrow-left2.svg';
import {
    ComparisonScenarioColumn,
} from '../../Components';
import { useGetScenarioResults } from '../../hooks';
import * as service from '../../services';
import styles from './ComparacaoCenarios.module.scss';
import { LoadingSpinerArea } from '../../../../components/LoadingSpinner';

export const ComparacaoCenarios = () => {
    const { savedScenario } = useSelector(
        selectorCalculadoraPrevisaoDemanda,
    );
    const history = useHistory();

    const dispatch = useDispatch();

    const { resultHandlers } = useGetScenarioResults();

    const handleGoBackToSavedScenarios = () => {
        history.push('/ipa/cenarios-salvos');
    };

    const handleEditItem = async (id: string) => {
        dispatch(setResultScenarioData({ name: 'isEditing', data: true }));
        dispatch(setResultScenarioData({ name: 'editId', data: id }));
        const response = await service.requestScenariosByID('GET', id, null);
        if (response) {
            dispatch(setFiltersFromEditScenario({ data: response }));
            dispatch(setResultFromEditScenario({ data: response }));
            resultHandlers.handleGetFirstChartsData(response);
            resultHandlers.handleGetSecondChartsData(response);
            resultHandlers.handleGetCPIChartsData(response);
            resultHandlers.handleChartBigNumbers(response);
            setTimeout(() => {
                dispatch(setInputFromEditScenario({ data: response }));
            }, 1500);
        }
        history.push('/ipa/calculadora-demanda');
    };

    return (
        <Container className="main-container">
            <LoadingSpinerArea area="compare-scenario" />
            <Button className={styles['btn-back']} onClick={handleGoBackToSavedScenarios}>
                <img src={arrowLeftIcon} alt="" />
                Voltar para Cenários salvos
            </Button>
            <div className={styles.header}>
                <h5 className={styles.title}>Comparação de cenários</h5>
            </div>
            <div className={styles['flex-container']}>
                {savedScenario.compareScenariosList.map((data) => (
                    <div className={styles['columns-container']}>
                        <ComparisonScenarioColumn
                            data={data}
                            dataLength={savedScenario.compareScenariosList.length}
                            onClickOpenScenario={handleEditItem}
                        />
                    </div>
                    ))}
            </div>
        </Container>
    );
};
