import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Button,
    Content,
} from 'rsuite';
import { selectorCalculadoraPrevisaoDemanda } from '../../../../reducers/calculadoraPrevisaoDemanda';
import {
    setSavedFilterRequestBody,
    clearSavedFilters,
} from '../../../../reducers/calculadoraPrevisaoDemanda/savedFiltros';
import { useSavedSelectFilters, useSavedSelectProduct } from '../../hooks';
import { LoadingMenu } from '../../../../components/LoadingSpinner';
import SelectPill from '../../../../components/SelectPill';
import DateRangePickerPill from '../../../../components/DateRangePickerPill';
import calendarIcon from '../../../../assets/icons/icon_calendar.svg';
import { useTableActions } from '../../hooks/useTableActions';
import * as utils from '../../utils';
import styles from './SavedScenarioFilter.module.scss';

export const SavedScenarioFilter = () => {
    const [dateRangeValue, setDateRangeValue] = useState([]);

    const { savedFiltros } = useSelector(selectorCalculadoraPrevisaoDemanda);
    const { savedProductHandlers } = useSavedSelectProduct();
    const { savedFilterHandlers } = useSavedSelectFilters();

    const dispatch = useDispatch();

    const { tableHandlers } = useTableActions();

    const params = { page: 0 };

    const menu = (menu) => (
        <span>
            <LoadingMenu area="scenario-filter" />
            {menu}
        </span>
        );

    const handleApplyFilters = () => {
        tableHandlers.getTableList(params, savedFiltros.savedFilterRequestBody);
    };

    const handleDateRangePicker = (value) => {
        setDateRangeValue(value);
        const body = {
            ...savedFiltros.savedFilterRequestBody,
            query: {
                ...savedFiltros.savedFilterRequestBody?.query,
                from_date: utils?.setLocalDateISOFormat(value[0]),
                to_date: utils?.setLocalDateISOFormat(value[1]),
            },
        };
        tableHandlers.getTableList(params, body);
    };

    const handleClearDatePicker = () => {
        setDateRangeValue([]);
        dispatch(setSavedFilterRequestBody({
            name: 'query',
            data: {
                ...savedFiltros.savedFilterRequestBody?.query,
                from_date: '',
                to_date: '',
            },
        }));
    };

    const handleClearAllFilters = () => {
        dispatch(clearSavedFilters());
        tableHandlers.getTableList(params, 'initial');
    };

    return (
        <Content className={styles['saved-filter-content']}>
            <div className={styles['in-flex']}>
                <div className={styles.flex}>
                    <SelectPill
                        id="check-picker-pill"
                        type="check"
                        placeholder="Produto"
                        value={savedFiltros.produtos.value || null}
                        data={savedFiltros.produtos.list}
                        onSearch={savedProductHandlers.onSearchProducts}
                        onSelect={savedProductHandlers.handleSelectProduct}
                        onOpen={() => savedProductHandlers.onSearchProducts('')}
                        onClean={savedProductHandlers.handleClearProducts}
                        onClose={handleApplyFilters}
                        locale={utils.locale}
                        renderMenu={menu}
                    />
                    {utils.filtersSelects.map((s) => (
                        <SelectPill
                            id={`${s.selectType}-picker-pill`}
                            type={s.selectType}
                            placeholder={s.placeholder}
                            value={
                                s.selectType === 'check'
                                ? savedFiltros[s.name].value
                                : savedFiltros[s.name].value[0]
                                || null
                            }
                            data={savedFiltros[s.name].list}
                            onSearch={(value) => savedFilterHandlers.handleOnSearchSavedFilter(s.name, value)}
                            onSelect={(value, item) => savedFilterHandlers[s.onSelect](s.name, value, item)}
                            onOpen={() => savedFilterHandlers.handleOnSearchSavedFilter(s.name, '')}
                            onClean={() => savedFilterHandlers.handleClearSavedFilter(s.name)}
                            onClose={handleApplyFilters}
                            locale={utils.locale}
                            renderMenu={menu}
                        />
                    ))}
                    <DateRangePickerPill
                        block
                        name="date-range"
                        placement="bottomEnd"
                        cleanable
                        classNameValue={
                            dateRangeValue.length
                                ? 'date-range-has-value'
                                : ''
                        }
                        value={dateRangeValue}
                        onChange={handleDateRangePicker}
                        onClean={handleClearDatePicker}
                        appearance="default"
                        placeholder="Período"
                        format="DD/MM/YYYY"
                        locale={utils.datePickerLocale}
                        ranges={utils.dateShortcut}
                        leftIcon={(
                            <img
                                src={calendarIcon}
                                alt=""
                                style={{ height: '12px' }}
                            />
                        )}
                    />
                </div>
                <Button
                    className={styles['btn-clear-filters']}
                    onClick={handleClearAllFilters}
                >
                    Limpar filtros
                </Button>
            </div>
        </Content>
  );
};
