import * as PainelGeralService from '../pages/ISA/InfoPanel/InfoPanel/services';

export const SET_DATA_C1 = 'SET_DATA_C1';
export const SET_DATA_C2 = 'SET_DATA_C2';
export const SET_DATA_C3 = 'SET_DATA_C3';
export const SET_DATA_C4 = 'SET_DATA_C4';
export const SET_SHARED_STATE_DATA = 'SET_SHARED_STATE_DATA';
export const SET_DATA_MAP_PINPOINTS = 'SET_DATA_MAP_PINPOINTS';

export function setDataC1(data) {
    return {
        type: SET_DATA_C1,
        data,
    };
}

export function setDataC2(data) {
    return {
        type: SET_DATA_C2,
        data,
    };
}

export function setDataC3(data) {
    return {
        type: SET_DATA_C3,
        data,
    };
}

export function setDataC4(data) {
    return {
        type: SET_DATA_C4,
        data,
    };
}

export function setDataMapPinpoints(data) {
    return {
        type: SET_DATA_MAP_PINPOINTS,
        data,
    };
}

export function setSharedStateData(data) {
    return {
        type: SET_SHARED_STATE_DATA,
        data,
    };
}

export function getPublicData(filter, payload, type) {
    const requestType = {
        c2: {
            set: setDataC2,
            size: 10,
        },
        c3: {
            set: setDataC3,
            size: 30,
        },
    };

    return (dispatch) => {
        PainelGeralService.getAgregadoPublic(
            { filter, payload },
            requestType[type].size,
        ).then((response) => {
            dispatch(requestType[type].set(response));
        });
    };
}

export function getAgregadoWithCount(filter, payload) {
    return (dispatch) => {
        PainelGeralService.getAgregadoWithCountPublic({ filter, payload }).then(
            (response) => {
                dispatch(setDataC1(response));
            },
        );
    };
}

export function getAgregadoTopFivePublicData(filter, payload) {
    return (dispatch) => {
        PainelGeralService.getAgregadoTopFivePublic({ filter, payload }).then(
            (response) => {
                dispatch(setDataC4(response));
            },
        );
    };
}

export function getMapPinpointsPublicData(filter, payload) {
    return (dispatch) => {
        PainelGeralService.getMapPinpointsPublic({ filter, payload }).then(
            (response) => {
                dispatch(setDataMapPinpoints(response));
            },
        );
    };
}

export function getSharedStatePublicData(payload) {
    return (dispatch) => {
        PainelGeralService.getSharedStatePublic(payload).then((response) => {
            dispatch(setSharedStateData(response));
        });
    };
}
