/* eslint-disable no-class-assign */
/* eslint-disable class-methods-use-this */
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
    Container,
    Content,
    FlexboxGrid,
    Col,
    Input,
    Button,
    InputGroup,
    ControlLabel,
    Alert,
} from 'rsuite';
import CurrencyInput from 'react-currency-input';
import iconDown from '../../../assets/icons/icon_arrow_circle_down.svg';
import iconEqual from '../../../assets/icons/icon_equal_circle.svg';
import iconUp from '../../../assets/icons/icon_arrow_circle_up.svg';
import iconCaret from '../../../assets/icons/icon_caret.svg';
import iconDelete from '../../../assets/icons/icon_trash.svg';
import { getRanges, saveRanges } from './services';
import { LoadingSpiner } from '../../../components/LoadingSpinner';

import {
    setRanges,
    clearRanges,
    addRange,
    addDecimalRange,
    setValueRange,
    setValueDecimalRange,
    deleteRange,
    deleteDecimalRange,
} from '../../../actions/actionsPrecoPsicologico';

class PrecoPsicologicoPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            rangesErrors: [],
        };

        this.onClickAddRange = this.onClickAddRange.bind(this);
        this.onClickSaveRanges = this.onClickSaveRanges.bind(this);
        this.onClickCancel = this.onClickCancel.bind(this);
        this.convertFloat = this.convertFloat.bind(this);
    }

    componentDidMount() {
        this.getDataRanges();
    }

    onClickAddRange() {
        const { addRange } = this.props;
        addRange();
    }

    async onClickSaveRanges() {
        const { formIsValid, rangesBody } = this.validRanges();

        if (formIsValid) {
            try {
                let responseErrors = [];
                const resp = await saveRanges(rangesBody);
                if (resp.length > 0) {
                    responseErrors = resp.filter(
                        (item) => (item.statusCode !== 'CREATED'
                                && item.statusCode !== 'OK')
                            || (item.statusCodeValue !== 201
                                && item.statusCodeValue !== 200),
                    );
                } else {
                    Alert.error(
                        'Ocorreu um erro, tente novamente mais tarde.',
                        10000,
                    );
                }

                if (responseErrors.length > 0) {
                    const errorMsg
                        = responseErrors[0]?.body[0]?.message
                        || 'Ocorreu um erro, tente novamente mais tarde.';
                    Alert.error(errorMsg, 10000);
                } else if (!responseErrors.length && resp.length > 0) {
                    Alert.success('Faixas salvas com sucesso!', 10000);
                    this.getDataRanges();
                }
            } catch (error) {
                Alert.error(
                    'Ocorreu um erro, tente novamente mais tarde.',
                    10000,
                );
                console.log(error);
            }
        }
    }

    onClickCancel() {
        this.getDataRanges();
    }

    getDataRanges() {
        const { clearRanges, setRanges, addRange } = this.props;
        getRanges().then((response) => {
            clearRanges();
            const rangesArray = [];
            const decimalRangesArray = [];
            if (response?.length) {
                response.forEach((item) => {
                    rangesArray.push({
                        id: item.range_id,
                        min: item.initial_range,
                        max: item.final_range,
                        status: item.status,
                    });
                    item.decimal_ranges.forEach((decimalItem) => {
                        decimalRangesArray.push({
                            rangeId: item.range_id,
                            id: decimalItem.decimal_range_id,
                            min: decimalItem.initial_range,
                            max: decimalItem.final_range,
                            roundingTo: decimalItem.rounding_to,
                            badge: decimalItem.rounding_type,
                            status: decimalItem.status,
                        });
                    });
                });
                setRanges(rangesArray, decimalRangesArray);
                this.validRanges();
            } else {
                addRange();
            }
        });
    }

    validRanges() {
        const cliendId = JSON.parse(localStorage.getItem('cliente'))?.codigo;
        const errors = {};
        let formIsValid = true;
        const rangesBody = [];
        const { ranges, decimalRanges } = this.props;

        ranges.forEach((rangeItem) => {
            const finalDecimalRanges = [];
            const validRange = { max: null, min: null, decimalRanges: {} };
            const rangeMin = this.convertFloat(rangeItem.min);
            const rangeMax = this.convertFloat(rangeItem.max);

            ranges.forEach((secondRangeItem) => {
                const secondRangeMin = this.convertFloat(secondRangeItem.min);
                const secondRangeMax = this.convertFloat(secondRangeItem.max);
                if (
                    rangeItem.id === secondRangeItem.id
                    || rangeMin === null
                    || rangeMax === null
                    || secondRangeMin === null
                    || secondRangeMax === null
                ) {
                    return null;
                }
                if (rangeMin <= secondRangeMax && rangeMax >= secondRangeMin) {
                    validRange.min = 'Esta faixa está sobrescrevendo outra';
                    formIsValid = false;
                }

                return true;
            });

            if (rangeMin === rangeMax) {
                validRange.min = 'Os valores devem ser diferentes';
                validRange.max = 'Os valores devem ser diferentes';
                formIsValid = false;
            }

            if (rangeMin >= rangeMax) {
                validRange.min
                    = 'Valor inicial deve ser menor que o valor final';
                formIsValid = false;
            }

            if (
                (rangeMin !== 0 && !rangeMin)
                || (rangeMax !== 0 && !rangeMax)
            ) {
                validRange.max = 'Preencha todos os campos';
                validRange.min = 'Preencha todos os campos';
                formIsValid = false;
            }

            errors[`${rangeItem.id}`] = validRange;

            // const decimalReturn = decimalRanges.map((decimalRangeItem, decimalRangeIndex) => {
            decimalRanges.forEach((decimalRangeItem) => {
                const validDecimalRange = {
                    max: null,
                    min: null,
                    roundingTo: null,
                    badge: null,
                };
                const decimalRangeMin = parseInt(decimalRangeItem.min, 10);
                const decimalRangeMax = parseInt(decimalRangeItem.max, 10);
                const decimalRangeItemRoundingTo = parseInt(
                    decimalRangeItem.roundingTo,
                    10,
                );

                if (rangeItem.id !== decimalRangeItem.rangeId) return null;

                // const secondDecimalRangeReturn = decimalRanges.map((secondDecimalRangeItem, secondDecimalRangeIndex) => {
                decimalRanges.forEach((secondDecimalRangeItem) => {
                    const secondDecimalRangeMin = parseInt(
                        secondDecimalRangeItem.min,
                        10,
                    );
                    const secondDecimalRangeMax = parseInt(
                        secondDecimalRangeItem.max,
                        10,
                    );
                    if (
                        secondDecimalRangeItem.rangeId !== rangeItem.id
                        || decimalRangeItem.id === secondDecimalRangeItem.id
                        || decimalRangeMin === null
                        || decimalRangeMax === null
                        || secondDecimalRangeMin === null
                        || secondDecimalRangeMax === null
                    ) {
                        return null;
                    }
                    if (
                        decimalRangeMin <= secondDecimalRangeMax
                        && decimalRangeMax >= secondDecimalRangeMin
                    ) {
                        validDecimalRange.min
                            = 'Esta faixa está sobrescrevendo outra';
                        formIsValid = false;
                    }

                    return true;
                });

                if (decimalRangeMin === 0 && rangeMin === 0) {
                    validDecimalRange.min
                        = 'Valor inicial deve ser mais que zero';
                    formIsValid = false;
                }
                if (decimalRangeMin === decimalRangeMax) {
                    validDecimalRange.min = 'Os valores devem ser diferentes';
                    validDecimalRange.max = 'Os valores devem ser diferentes';
                    formIsValid = false;
                }

                if (decimalRangeMin >= decimalRangeMax) {
                    validDecimalRange.min
                        = 'Valor inicial deve ser menor que o valor final';
                    formIsValid = false;
                }
                if (rangeMin === 0 && decimalRangeMin === 0) {
                    validDecimalRange.min
                        = 'Valor inicial deve ser maior que zero';
                    formIsValid = false;
                }

                if (decimalRangeItem.badge === null) {
                    validDecimalRange.badge = 'Selecione uma faixa';
                    formIsValid = false;
                }
                if (
                    (decimalRangeMin !== 0 && !decimalRangeMin)
                    || (decimalRangeMax !== 0 && !decimalRangeMax)
                    || (decimalRangeItemRoundingTo !== 0
                        && !decimalRangeItemRoundingTo)
                ) {
                    validDecimalRange.roundingTo = 'Preencha todos os campos';
                    validDecimalRange.max = 'Preencha todos os campos';
                    validDecimalRange.min = 'Preencha todos os campos';
                    formIsValid = false;
                }

                errors[`${decimalRangeItem.rangeId}`].decimalRanges[
                    `${decimalRangeItem.id}`
                ] = validDecimalRange;

                finalDecimalRanges.push({
                    status: decimalRangeItem.status || 'CREATE',
                    decimal_range_id: decimalRangeItem.id,
                    client_id: cliendId,
                    range_id: decimalRangeItem.rangeId,
                    initial_range: decimalRangeMin,
                    final_range: decimalRangeMax,
                    rounding_to: decimalRangeItemRoundingTo,
                    rounding_type: decimalRangeItem.badge,
                });

                if (decimalRangeItem.status === 'DELETE') formIsValid = true;

                return finalDecimalRanges;
            });

            rangesBody.push({
                status: rangeItem.status || 'CREATE',
                range_id: rangeItem.id,
                client_id: cliendId,
                initial_range: rangeMin,
                final_range: rangeMax,
                decimal_ranges: finalDecimalRanges,
            });

            if (rangeItem.status === 'DELETE') formIsValid = true;

            return validRange;
        });

        this.setState({
            rangesErrors: errors,
        });
        return { formIsValid, rangesBody };
    }

    convertFloat(value) {
        return value?.replace
            ? parseFloat(value.replace('.', '').replace(',', '.'))
            : value;
    }

    render() {
        const { ranges } = this.props;
        const { rangesErrors } = this.state;
        return (
            <Container className="main-container preco-psicologico-page">
                <LoadingSpiner size="md" />
                <Content>
                    <FlexboxGrid>
                        <FlexboxGrid.Item
                            componentClass={Col}
                            lg={16}
                            md={16}
                            sm={24}
                            xs={24}
                        >
                            <ControlLabel className="description__label">
                                <p>FAIXAS</p>
                            </ControlLabel>
                            {ranges?.length > 0
                                && ranges.map((item, index) => {
                                    if (item?.status === 'DELETE') return null;
                                    return (
                                        <Range
                                            range={item}
                                            key={index.toString()}
                                            rangesErrors={rangesErrors}
                                            validRanges={this.validRanges}
                                        />
                                    );
                                })}
                            <div className="rounding__plus-range">
                                <p onClick={this.onClickAddRange} aria-hidden>
                                    + Adicionar faixa
                                </p>
                            </div>
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                    <FlexboxGrid className="buttons-wrapper">
                        <FlexboxGrid.Item componentClass={Col}>
                            <Button
                                onClick={this.onClickSaveRanges}
                                appearance="primary"
                            >
                                Confirmar
                            </Button>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item componentClass={Col}>
                            <Button onClick={this.onClickCancel}>
                                Cancelar
                            </Button>
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </Content>
            </Container>
        );
    }
}

class Range extends Component {
    constructor(props) {
        super(props);

        const { range } = this.props;

        this.state = {
            minValue: range?.min,
            maxValue: range?.max,
            closedRange: false,
        };

        this.onClickDeleteRange = this.onClickDeleteRange.bind(this);
    }

    // eslint-disable-next-line react/sort-comp
    changeMinValue(newValue) {
        const { status, maxValue } = this.state;
        const { range, setValueRange } = this.props;
        const newStatus
            = status === 'NONE' && range.min !== newValue ? 'UPDATE' : status;
        this.setState({ minValue: newValue });
        setValueRange(range.id, newValue, maxValue, newStatus);
    }

    changeMaxValue(newValue) {
        const { status, minValue } = this.state;
        const { range, setValueRange } = this.props;
        const newStatus
            = status === 'NONE' && range.max !== newValue ? 'UPDATE' : status;
        this.setState({ maxValue: newValue });
        setValueRange(range.id, minValue, newValue, newStatus);
    }

    onClickDeleteRange() {
        const { range, deleteRange } = this.props;
        deleteRange(range.id, range.status);
    }

    clickAddDecimalRange() {
        const { range, addDecimalRange } = this.props;
        addDecimalRange(range.id);
    }

    render() {
        const { closedRange, minValue, maxValue } = this.state;
        const { range, decimalRanges, rangesErrors } = this.props;
        // const undeletedRanges = ranges.filter((item) => { return item.status !== "DELETE" })
        return (
            <div className={`rounding${closedRange ? ' closed' : ''}`}>
                <div className="rounding__range">
                    <div
                        className="switcher"
                        onClick={() => this.setState({ closedRange: !closedRange })}
                        aria-hidden
                    >
                        <img alt="" src={iconCaret || ''} />
                    </div>
                    <div className="rounding__range-min">
                        <InputGroup>
                            <InputGroup.Addon>R$</InputGroup.Addon>
                            <CurrencyInput
                                className="rs-input"
                                value={minValue}
                                decimalSeparator=","
                                thousandSeparator="."
                                precision="2"
                                allowEmpty={false}
                                allowNegative={false}
                                onChange={(value) => this.changeMinValue(value)}
                            />
                        </InputGroup>
                    </div>
                    <div className="rounding__range-text">
                        <span>a</span>
                    </div>
                    <div className="rounding__range-max">
                        <InputGroup>
                            <InputGroup.Addon>R$</InputGroup.Addon>
                            <CurrencyInput
                                className="rs-input"
                                value={maxValue}
                                decimalSeparator=","
                                thousandSeparator="."
                                precision="2"
                                allowEmpty={false}
                                allowNegative={false}
                                onChange={(value) => this.changeMaxValue(value)}
                            />
                        </InputGroup>
                    </div>
                    <DeleteButton onClick={this.onClickDeleteRange} />
                </div>
                <p className="rounding__range-error">
                    {rangesErrors[range.id]?.min || rangesErrors[range.id]?.max}
                </p>

                <div className="rounding__decimal-ranges">
                    <div className="rounding__decimal-range-labels">
                        <span>FAIXAS DECIMAIS</span>
                        <span>AJUSTE PARA</span>
                    </div>
                    {decimalRanges.length
                        && decimalRanges.map((item, index) => {
                            if (
                                item?.rangeId !== range.id
                                || item?.status === 'DELETE'
                            ) {
                                return null;
                            }
                            return (
                                <DecimalRange
                                    decimalRange={{ ...item, index }}
                                    rangeId={range.id}
                                    key={index.toString()}
                                    rangesErrors={rangesErrors}
                                />
                            );
                        })}
                    <div
                        onClick={(e) => this.clickAddDecimalRange(e)}
                        className="rounding__plus-decimal-range"
                        aria-hidden
                    >
                        <p>+ Adicionar faixa decimal</p>
                    </div>
                </div>
                {/* <button onClick={() => onClickJSONRange()}>rangesReducer</button> */}
            </div>
        );
    }
}

class DecimalRange extends Component {
    constructor(props) {
        super(props);

        const { decimalRange } = this.props;

        this.state = {
            decimalMinValue: decimalRange?.min,
            decimalMaxValue: decimalRange?.max,
            decimalRoundingToValue: decimalRange?.roundingTo,
            activeBadge: decimalRange?.badge,
            disableBadgeDown: false,
            disableBadgeEqual: false,
            disableBadgeUp: false,
        };

        this.onClickBadge = this.onClickBadge.bind(this);
        this.onClickDeleteDecimalRange
            = this.onClickDeleteDecimalRange.bind(this);
        this.formatDecimalValue = this.formatDecimalValue.bind(this);
    }

    componentDidMount() {
        const { decimalRange } = this.props;
        this.updateCalc(
            decimalRange?.id,
            this.formatDecimalValue(decimalRange?.min?.toString()),
            this.formatDecimalValue(decimalRange?.max?.toString()),
            this.formatDecimalValue(decimalRange?.roundingTo?.toString()),
            decimalRange?.badge,
            decimalRange?.status,
        );
    }

    onClickBadge(badge, disabled) {
        const { decimalRange } = this.props;
        const { decimalMinValue, decimalMaxValue, decimalRoundingToValue }
            = this.state;
        if (disabled) return false;
        return this.updateCalc(
            decimalRange.id,
            decimalMinValue,
            decimalMaxValue,
            decimalRoundingToValue,
            badge,
            decimalRange?.status,
        );
    }

    // eslint-disable-next-line class-methods-use-this
    formatDecimalValue(value) {
        let newValue = value;
        if (value === 0) newValue = '00';
        else if (value > 0 && value.startsWith('00')) {
            newValue = value.replace('0', '');
        } else if (value < 10 && !value.startsWith('0')) newValue = `0${value}`;
        else if (value >= 10 && value.startsWith('0')) {
            newValue = value.replace('0', '');
        }
        return newValue;
    }

    changeMinValue(newValue) {
        const { decimalRange } = this.props;
        const { activeBadge, decimalMaxValue, decimalRoundingToValue }
            = this.state;
        let value = newValue;
        if (parseInt(value, 10) > 99) return false;
        value = this.formatDecimalValue(value);
        return this.updateCalc(
            decimalRange.id,
            value,
            decimalMaxValue,
            decimalRoundingToValue,
            activeBadge,
            decimalRange?.status,
        );
    }

    changeMaxValue(newValue) {
        const { decimalRange } = this.props;
        const { activeBadge, decimalMinValue, decimalRoundingToValue }
            = this.state;
        let value = newValue;
        if (parseInt(value, 10) > 99) return false;
        value = this.formatDecimalValue(value);
        return this.updateCalc(
            decimalRange.id,
            decimalMinValue,
            value,
            decimalRoundingToValue,
            activeBadge,
            decimalRange?.status,
        );
    }

    changeRoundingToValue(newValue) {
        const { decimalRange } = this.props;
        const { activeBadge, decimalMinValue, decimalMaxValue } = this.state;
        let value = newValue;
        if (parseInt(value, 10) > 99) return false;
        value = this.formatDecimalValue(value);
        return this.updateCalc(
            decimalRange.id,
            decimalMinValue,
            decimalMaxValue,
            value,
            activeBadge,
            decimalRange?.status,
        );
    }

    getStatus(minValue, maxValue, roundingTo, badge, status) {
        const { decimalRange } = this.props;
        let decimalStatus = status;

        if (status === 'CREATE') {
            decimalStatus = 'CREATE';
        } else {
            switch (true) {
                case decimalRange.roundingTo !== Number(roundingTo):
                    decimalStatus = 'UPDATE';
                    break;
                case decimalRange.max !== Number(maxValue):
                    decimalStatus = 'UPDATE';
                    break;
                case decimalRange.min !== Number(minValue):
                    decimalStatus = 'UPDATE';
                    break;
                case decimalRange.badge !== badge:
                    decimalStatus = 'UPDATE';
                    break;

                default:
                    decimalStatus = 'NONE';
                    break;
            }
        }

        return decimalStatus;
    }

    updateCalc(id, minValue, maxValue, roundingTo, badge, status) {
        const { setValueDecimalRange } = this.props;
        let disableBadgeDown = false;
        let disableBadgeUp = false;
        let disableBadgeEqual = false;
        let newBadge = badge;
        if (
            (minValue || minValue === 0)
            && (maxValue || maxValue === 0)
            && (roundingTo || roundingTo === 0)
        ) {
            if (roundingTo <= maxValue && roundingTo >= minValue) {
                newBadge = 'ROUNDING_IN_INTERVAL';
                disableBadgeDown = true;
                disableBadgeUp = true;
            } else {
                newBadge
                    = newBadge === 'ROUNDING_IN_INTERVAL' ? null : newBadge;
                disableBadgeEqual = true;
            }
        }
        this.setState({
            decimalMinValue: minValue,
            decimalMaxValue: maxValue,
            decimalRoundingToValue: roundingTo,
            disableBadgeDown,
            disableBadgeEqual,
            disableBadgeUp,
            activeBadge: newBadge || null,
        });

        setValueDecimalRange(
            id,
            minValue,
            maxValue,
            roundingTo,
            newBadge,
            this.getStatus(minValue, maxValue, roundingTo, newBadge, status),
        );
    }

    onClickDeleteDecimalRange() {
        const { deleteDecimalRange, decimalRange } = this.props;

        deleteDecimalRange(
            decimalRange.id,
            decimalRange.rangeId,
            decimalRange.status,
        );
    }

    render() {
        const { decimalRange, decimalRanges, rangesErrors } = this.props;
        const undeletedDecimalRanges = decimalRanges.filter(
            (item) => item.rangeId === decimalRange.rangeId
                && item.status !== 'DELETE',
        );
        const {
            decimalMinValue,
            decimalMaxValue,
            decimalRoundingToValue,
            disableBadgeDown,
            activeBadge,
            disableBadgeEqual,
            disableBadgeUp,
        } = this.state;
        return (
            <>
                <div className="rounding__decimal-range">
                    <div className="rounding__decimal-range-text">
                        <span>de</span>
                    </div>
                    <div className="rounding__decimal-range-min">
                        <InputGroup>
                            <InputGroup.Addon>R$</InputGroup.Addon>
                            <InputGroup.Addon>X,</InputGroup.Addon>
                            <Input
                                type="number"
                                min={0}
                                max={99}
                                onChange={(value) => this.changeMinValue(value)}
                                value={decimalMinValue}
                            />
                        </InputGroup>
                    </div>
                    <div className="rounding__decimal-range-text">
                        <span>a</span>
                    </div>
                    <div className="rounding__decimal-range-max">
                        <InputGroup>
                            <InputGroup.Addon>R$</InputGroup.Addon>
                            <InputGroup.Addon>X,</InputGroup.Addon>
                            <Input
                                type="number"
                                min={0}
                                max={99}
                                onChange={(value) => this.changeMaxValue(value)}
                                value={decimalMaxValue}
                            />
                        </InputGroup>
                    </div>
                    <div className="rounding__decimal-range-rounding-to">
                        <InputGroup>
                            <InputGroup.Addon>R$</InputGroup.Addon>
                            <InputGroup.Addon>X,</InputGroup.Addon>
                            <Input
                                type="number"
                                min={0}
                                max={99}
                                onChange={(value) => this.changeRoundingToValue(value)}
                                value={decimalRoundingToValue}
                            />
                        </InputGroup>
                    </div>
                    <div className="rounding__decimal-range-badges">
                        <RangeBadge
                            disabled={disableBadgeDown}
                            badge="ROUNDING_DOWN"
                            activeBadge={activeBadge}
                            onClick={() => this.onClickBadge(
                                    'ROUNDING_DOWN',
                                    disableBadgeDown,
                                )}
                        />
                        <RangeBadge
                            disabled={disableBadgeEqual}
                            badge="ROUNDING_IN_INTERVAL"
                            activeBadge={activeBadge}
                            onClick={() => this.onClickBadge(
                                    'ROUNDING_IN_INTERVAL',
                                    disableBadgeEqual,
                                )}
                        />
                        <RangeBadge
                            disabled={disableBadgeUp}
                            badge="ROUNDING_UP"
                            activeBadge={activeBadge}
                            onClick={() => this.onClickBadge('ROUNDING_UP', disableBadgeUp)}
                        />
                    </div>
                    {undeletedDecimalRanges.length > 1 && (
                        <DeleteButton
                            range={decimalRange}
                            onClick={() => this.onClickDeleteDecimalRange()}
                        />
                    )}
                </div>
                <div>
                    <p className="rounding__decimal-range-error">
                        {rangesErrors[decimalRange?.rangeId]?.decimalRanges[
                            decimalRange?.id
                        ]?.min
                            || rangesErrors[decimalRange?.rangeId]?.decimalRanges[
                                decimalRange?.id
                            ]?.max
                            || rangesErrors[decimalRange?.rangeId]?.decimalRanges[
                                decimalRange?.id
                            ]?.roundingTo
                            || rangesErrors[decimalRange?.rangeId]?.decimalRanges[
                                decimalRange?.id
                            ]?.badge}
                    </p>
                </div>
            </>
        );
    }
}

const RangeBadge = ({
 badge, activeBadge, onClick, disabled,
}) => {
    const badges = {
        ROUNDING_DOWN: {
            icon: iconDown,
            text: 'FAIXA ABAIXO',
            class: 'down',
        },
        ROUNDING_IN_INTERVAL: {
            icon: iconEqual,
            text: 'MESMA FAIXA',
            class: 'equal',
        },
        ROUNDING_UP: {
            icon: iconUp,
            text: 'FAIXA ACIMA',
            class: 'up',
        },
    };

    return (
        <div className="badge-container">
            <div
                className={`badge badge--${badges[badge]?.class}${
                    activeBadge === badge && !disabled ? ' active' : ''
                }${disabled ? ' disabled' : ''}`}
                onClick={() => onClick()}
                disabled={disabled}
                aria-hidden
            >
                <div className="badge__icon">
                    <img alt="" src={badges[badge]?.icon || ''} />
                </div>
                <div className="badge__text">
                    <span>{badges[badge]?.text || '--'}</span>
                </div>
            </div>
        </div>
    );
};

const DeleteButton = ({ onClick }) => (
    <div className="delete-button" onClick={() => onClick()} aria-hidden>
        <img alt="" src={iconDelete} />
    </div>
);

const mapStateToProps = (store) => ({
    decimalRanges: store.precoPsicologicoDataReducer.decimalRanges,
    ranges: store.precoPsicologicoDataReducer.ranges,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
        {
            setRanges,
            clearRanges,
            addRange,
            addDecimalRange,
            setValueRange,
            setValueDecimalRange,
            deleteRange,
            deleteDecimalRange,
        },
        dispatch,
    );

Range = connect(mapStateToProps, mapDispatchToProps)(Range);
DecimalRange = connect(mapStateToProps, mapDispatchToProps)(DecimalRange);
export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(PrecoPsicologicoPage),
);
