/* eslint-disable no-unsafe-optional-chaining */
import { camelToSnakeCase, getSortedData } from '../../../RevisaoPrecos/utils';
import {
    NegotitationEditDatapointProps,
    NegotitationRowDataProps,
    SuppliersResponseContentProps,
} from './NegociacaoFornecedor.types';

export const formatDecimal = (value: number, number: number) =>
    value?.toFixed(number).toString().replace('.', ',');

export const newPriceCalc = (
    data: SuppliersResponseContentProps,
    value: number,
) =>
    (value /
        (100 -
            (data?.newObjectiveMargin +
                data.salesTaxPercentage +
                data.expensesPercent))) *
    100;

export const newCostCalc = (
    data: SuppliersResponseContentProps,
    margin: number,
    newPrice: number,
) =>
    (newPrice *
        (100 - (margin + data.salesTaxPercentage + data.expensesPercent))) /
    100;

export const PMZNewCostCalc = (
    data: SuppliersResponseContentProps,
    value: number,
) => (value / (100 - (data.salesTaxPercentage + data.expensesPercent))) * 100;

export const priceMarginCalc = (
    data: SuppliersResponseContentProps,
    price: number,
    cost: number,
) => {
    let tax = (price * (data.salesTaxPercentage + data.expensesPercent)) / 100;

    // Calcula o preço líquido
    let liquidPrice = price - tax;

    // Retorna a margem de lucro como uma porcentagem
    return ((liquidPrice - cost) / price) * 100;
};

export const newPriceCompetitorsCalc = (
    data: SuppliersResponseContentProps,
    newPrice: number,
) => (newPrice / data.competitorsPrice) * 100;

export const bestIdealPriceCalc = (
    data: SuppliersResponseContentProps,
    newObjectiveMargin: number,
) =>
    ((data.competitorsPrice !== null
        ? data.competitorsPrice
        : data.retailPrice) *
        (100 -
            newObjectiveMargin -
            (data.expensesPercent + data.salesTaxPercentage))) /
    100;

export const bestIdealPricePMZCalc = (
    data: SuppliersResponseContentProps,
    price: number,
) => (price / (100 - (data.expensesPercent + data.salesTaxPercentage))) * 100;

export const calculateVariationPercentage = (cost: number, newCost: number) =>
    ((newCost - cost) / cost) * 100;

export const calculateVariation = (newNumber: number, originalNumber: number) =>
    newNumber - originalNumber;

export const competitivenessIndicator = (value: string) => {
    const indicator = {
        RULE_CPI_MAX: '>',
        RULE_CPI_MIN: '<',
        RULE_CPI_AVG: 'x̅',
    };

    return indicator[value];
};

type SortMode = 'snake' | 'camel';

export const getFiltrosModel = (
    { size = 20, sort, page = 0, ...filter }: Record<string, any>,
    sortMode: SortMode = 'snake',
) => {
    const CURRENT_SORT = {
        snake: camelToSnakeCase(getSortedData({ sort }) ?? ''),
        camel: getSortedData({ sort }) ?? '',
    } as const;

    return {
        page: page <= 0 ? 0 : page - 1,
        sort: CURRENT_SORT[sortMode],
        size,
        ...filter,
    };
};

export const getEditedDatapointModel = (
    rowData: NegotitationRowDataProps,
): NegotitationEditDatapointProps => ({
    id: rowData.productsToBePricedId,
    newObjectiveMargin: rowData.newObjectiveMargin,
    newCost: rowData.newCost,
    newPrice: rowData.newPrice,
    newPriceMargin: rowData.newPriceMargin,
    newPriceCompetitors: rowData.newPriceCompetitors,
    bestIdealPrice: rowData.bestIdealPrice,
    bestIdealPricePMZ: rowData.bestIdealPricePMZ,
    PMZNewCost: rowData.PMZNewCost,
});
