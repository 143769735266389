import React from 'react';
import { Content, Loader } from 'rsuite';
// @ts-ignore
import { useVendaBruta } from '../../../hooks/useVendaBruta.tsx';

export const VendaBruta: React.FC = () => {
    const { data, isLoading } = useVendaBruta();

    if (isLoading)
        return (
            <Content className="itens-pesquisados-loading">
                <Loader center />
            </Content>
        );

    if (!data?.sumSalesGross)
        return (
            <Content className="itens-pesquisados-sem-dados">
                <h3>Sem dados para essa análise</h3>
                <p>Revise os filtros e tente novamente</p>
            </Content>
        );

    return (
        <Content className="itens-pesquisados">
            <div>
                <p>{data?.sumSalesGross}</p>
                <span>Venda bruta dos itens pesquisados</span>
            </div>
        </Content>
    );
};
