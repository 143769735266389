import { trackPromise } from 'react-promise-tracker';
import qs from 'qs';
import api from '../../../../../utils/API';

export const getProdutosCampanha = (id, params) => trackPromise(
        api({
            url: `${process.env.REACT_APP_IPA_URL}/campaign/campaigns/${id}/products`,
            method: 'GET',
            expectToken: true,
            params: {
                client: JSON.parse(localStorage.getItem('cliente'))?.nome,
                storesId: params.storesId,
                descOrProdId: params.descOrProdId,
            },
            paramsSerializer(params) {
                return qs.stringify(params, { arrayFormat: 'repeat' });
            },
        }).then((resp) => resp.data),
    );
export const getDetalhesProdutoCampanha = (params) => trackPromise(
        api({
            url: `${process.env.REACT_APP_IPA_URL}/campaign/campaigns/product`,
            method: 'GET',
            expectToken: true,
            params: {
                client: JSON.parse(localStorage.getItem('cliente'))?.nome,
                storesId: params.storesId,
                productId: params.productId,
            },
            paramsSerializer(params) {
                return qs.stringify(params, { arrayFormat: 'repeat' });
            },
        }).then((resp) => resp.data),
    );
export const saveOferta = (id, data) => trackPromise(
        api({
            url: `${process.env.REACT_APP_IPA_URL}/campaign/campaigns/${id}/offers`,
            method: 'POST',
            expectToken: true,
            params: {
                client: JSON.parse(localStorage.getItem('cliente'))?.nome,
                clientId: JSON.parse(localStorage.getItem('cliente'))?.codigo,
            },
            data: JSON.stringify(data),
        }).then((resp) => resp),
    );
export const updateOferta = (id, data, offerId) => trackPromise(
        api({
            url: `${process.env.REACT_APP_IPA_URL}/campaign/campaigns/${id}/offers/${offerId}`,
            method: 'PUT',
            expectToken: true,
            params: {
                client: JSON.parse(localStorage.getItem('cliente'))?.nome,
                clientId: JSON.parse(localStorage.getItem('cliente'))?.codigo,
            },
            data: JSON.stringify(data),
        }).then((resp) => resp),
    );
export const getOfertas = (params) => trackPromise(
        api({
            url: `${process.env.REACT_APP_IPA_URL}/campaign/campaigns/${params.id}/offers`,
            method: 'GET',
            expectToken: true,
            params: {
                client: JSON.parse(localStorage.getItem('cliente'))?.nome,
                size: params?.size || 20,
                page: params?.page || 0,
            },
        }).then((resp) => resp.data),
    );
export const getOferta = (id, offerId) => trackPromise(
        api({
            url: `${process.env.REACT_APP_IPA_URL}/campaign/campaigns/${id}/offers/${offerId}`,
            method: 'GET',
            expectToken: true,
            params: {
                client: JSON.parse(localStorage.getItem('cliente'))?.nome,
            },
        }).then((resp) => resp.data),
    );
export const deleteProdutoOferta = (id, productId) => trackPromise(
        api({
            url: `${process.env.REACT_APP_IPA_URL}/campaign/campaigns/${id}/offers/product/${productId}`,
            method: 'DELETE',
            expectToken: true,
            params: {
                client: JSON.parse(localStorage.getItem('cliente'))?.nome,
                clientId: JSON.parse(localStorage.getItem('cliente'))?.codigo,
            },
        }).then((resp) => resp.data),
    );
export const programarOferta = (id, status) => trackPromise(
        api({
            url: `${process.env.REACT_APP_IPA_URL}/campaign/campaigns/${id}/status/${status}`,
            method: 'PATCH',
            expectToken: true,
            params: {
                clientId: JSON.parse(localStorage.getItem('cliente'))?.codigo,
            },
        }).then((resp) => resp.data),
    );
