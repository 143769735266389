/* eslint-disable default-param-last */
import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IGerenciadorPrecosDatapoint as RowData } from '../../../../@types';
import { selectorDatapoints } from '../../../../reducers/gerenciadorPrecos/datapoints';
import {
    ADD_DATAPOINT_CACHE,
    REMOVE_DATAPOINT_CACHE,
    RESET_DATAPOINTS_CACHE,
} from '../../../../reducers/gerenciadorPrecos/datapointsCache';
import { FiltersValueState } from '../../../../reducers/gerenciadorPrecos/filters/filtersValues';
import {
    ADD_EXPANDED_ROW,
    REMOVE_SELECTED_DATAPOINT,
    RESET_SELECTED_DATAPOINTS,
    selectorSelectedDatapoints,
    SET_SELECTED_ALL,
    UpdateGerenciadorSelectedDatapointsList,
} from '../../../../reducers/gerenciadorPrecos/selectedDatapoints';
import { isSelectedDatapointsEmpty } from '../utils';

type DataKey = RowData['productsToBePricedId'];

type UpdateList = UpdateGerenciadorSelectedDatapointsList;

export const useSelectedDatapoints = () => {
    const dispatch = useDispatch();

    const data = useSelector(selectorSelectedDatapoints);

    const { excludedIds, selectedAll, selectedIds } = data;

    const updateList: UpdateList = useMemo(() => {
        return selectedAll ? 'excludedIds' : 'selectedIds';
    }, [selectedAll]);

    const datapoints = useSelector(selectorDatapoints);

    const contentLength = useMemo(() => datapoints.length, [datapoints.length]);

    const toggleSelectedAllProductsOn = useCallback(() => {
        dispatch(SET_SELECTED_ALL(true));
    }, [dispatch]);

    const handleResetState = useCallback(() => {
        dispatch(RESET_SELECTED_DATAPOINTS());
        dispatch(RESET_DATAPOINTS_CACHE());
    }, [dispatch]);

    const findDatapoint = useCallback(
        (id: DataKey, listToCheck: UpdateList = 'selectedIds') => {
            return data[listToCheck].find((item) => item === id);
        },
        [data],
    );

    const handleAddDatapoint = useCallback(
        (id: DataKey) => {
            dispatch(
                ADD_EXPANDED_ROW({ productsToBePricedId: id, updateList }),
            );

            const rowData = datapoints.find(
                (item) => item.productsToBePricedId === id,
            );

            if (rowData) dispatch(ADD_DATAPOINT_CACHE({ rowData, updateList }));
        },
        [dispatch, datapoints, updateList],
    );

    const handleAddManyDatapoints = useCallback(
        (ids: DataKey[]) => ids.forEach((id) => handleAddDatapoint(id)),
        [handleAddDatapoint],
    );

    const handleRemoveDatapoint = useCallback(
        (id: DataKey, updateList: UpdateList = 'selectedIds') => {
            dispatch(
                REMOVE_SELECTED_DATAPOINT({
                    productsToBePricedId: id,
                    updateList,
                }),
            );
            dispatch(REMOVE_DATAPOINT_CACHE({ id, updateList }));
        },
        [dispatch],
    );

    const handleCheck = useCallback(
        (id = '', checked: boolean) => {
            if (selectedAll) {
                if (!checked) {
                    if (findDatapoint(id)) {
                        handleRemoveDatapoint(id);
                    } else handleAddDatapoint(id);
                } else handleRemoveDatapoint(id);
            } else if (checked) {
                handleAddDatapoint(id);
            } else {
                handleRemoveDatapoint(id);
            }
        },
        [selectedAll, findDatapoint, handleAddDatapoint, handleRemoveDatapoint],
    );

    const handleCheckAll = useCallback(
        (checked: boolean, datapoints: RowData[]) => {
            if (checked) {
                const ids = datapoints.map(
                    ({ productsToBePricedId }) => productsToBePricedId,
                );
                handleAddManyDatapoints(ids);
            } else handleResetState();
        },
        [handleResetState, handleAddManyDatapoints],
    );

    const handleUpdateSelectedDatapointValue = useCallback(() => {
        toggleSelectedAllProductsOn();
    }, [toggleSelectedAllProductsOn]);

    const isToggleChecked = useCallback(
        (productsToBePricedId: DataKey) => {
            const isChecked = !!findDatapoint(productsToBePricedId);

            if (selectedAll) {
                return !findDatapoint(productsToBePricedId, 'excludedIds');
            }

            return isChecked;
        },
        [findDatapoint, selectedAll],
    );

    const isEmptySelectedDatapoints = useMemo(
        () => isSelectedDatapointsEmpty(data),
        [data],
    );

    const getSelectedDatapointModel = useCallback(
        (filters: Partial<FiltersValueState> = {}) => {
            return { ...data, filters };
        },
        [data],
    );

    const isAllChecked = useMemo(() => {
        return (
            !!(
                selectedIds.length === contentLength && selectedIds.length !== 0
            ) || selectedAll
        );
    }, [selectedIds.length, selectedAll, contentLength]);

    const isIndeterminate = useMemo(() => {
        return (
            (selectedIds.length > 0 && selectedIds.length < contentLength) ||
            excludedIds.length > 0
        );
    }, [selectedIds.length, excludedIds.length, contentLength]);

    return {
        data,
        isSelectedDatapointsEmpty: isEmptySelectedDatapoints,
        isAllChecked,
        isIndeterminate,
        updateList,
        handleCheck,
        handleCheckAll,
        getSelectedDatapointModel,
        isToggleChecked,
        handleUpdateSelectedDatapointValue,
        handleAddDatapoint,
        findDatapoint,
        toggleSelectedAllProductsOn,
        handleResetState,
    } as const;
};
