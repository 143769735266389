import React from 'react';
import {
    FlexboxGrid,
    Icon,
    Col,
    Modal,
    Grid,
    Row,
    Whisper,
    Tooltip,
    Button,
} from 'rsuite';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import ValueCard from '../../SimulacoesAnalises/PrevisaoDemanda/Components/ValueCard';

const ModalPrevisaoDemanda = ({
    handleSalvarSimulacao,
    goToPrevisao,
    show,
    closeModal,
    previsao,
}) => (
    <Modal
        className="modal-previsao-demanda"
        show={show}
        onHide={closeModal}
        size="md"
    >
        <Modal.Header>
            <Modal.Title>
                <FlexboxGrid>
                    <FlexboxGrid.Item colspan={24}>
                        <h1>
                            Previsão de Demanda
                            {' '}
                            <span>BETA</span>
                        </h1>
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item colspan={24}>
                        <h2>Previsão realizada para os próximos 7 dias</h2>
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {previsao?.current_price?.forecast
                && 'sales' in previsao.current_price.forecast
                && previsao?.new_price?.forecast
                && 'sales' in previsao.new_price.forecast ? (
                    <FlexboxGrid justify="center">
                        <FlexboxGrid.Item colspan={24}>
                            <div className="confiabilidade-info">
                                <p>
                                    Essa previsão tem margem de erro de
                                    aproximadamente
                                    {' '}
                                    <strong>40%</strong>
                                    <Whisper
                                        trigger="hover"
                                        placement="top"
                                        speaker={(
                                            <Tooltip>
                                                Estimativas de demanda estão
                                                sujeitas a erros, visto que o
                                                preço não é o único fator de
                                                influência do consumo
                                            </Tooltip>
                                          )}
                                    >
                                        <Icon icon="info" />
                                    </Whisper>
                                </p>
                            </div>
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                ) : null}
            <Grid fluid>
                {previsao?.current_price?.forecast
                    && 'sales' in previsao.current_price.forecast
                    && previsao?.new_price?.forecast
                    && 'sales' in previsao.new_price.forecast ? (
                        <>
                            <Row>
                                <Col xs={8}>
                                    <ValueCard
                                        title="Previsão Venda"
                                        currentValue={
                                            previsao?.current_price?.forecast
                                                ?.sales
                                        }
                                        value={
                                            previsao?.new_price?.forecast?.sales
                                        }
                                    />
                                </Col>
                                <Col xs={8}>
                                    <ValueCard
                                        title="Previsão Receita"
                                        currentValue={
                                            previsao?.current_price?.forecast
                                                ?.revenue
                                        }
                                        value={
                                            previsao?.new_price?.forecast
                                                ?.revenue
                                        }
                                        type="value"
                                    />
                                </Col>
                                <Col xs={8}>
                                    <ValueCard
                                        title="Previsão Lucro"
                                        currentValue={
                                            previsao?.current_price?.forecast
                                                ?.profit
                                        }
                                        value={
                                            previsao?.new_price?.forecast
                                                ?.profit
                                        }
                                        type="value"
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={8}>
                                    <ValueCard
                                        title="Variação venda"
                                        value={previsao?.variation?.sales}
                                        type="variation"
                                        color={
                                            previsao?.variation?.sales > 0
                                                ? 'positive'
                                                : previsao?.variation?.sales < 0
                                                ? 'negative'
                                                : ''
                                        }
                                    />
                                </Col>
                                <Col xs={8}>
                                    <ValueCard
                                        title="Variação receita"
                                        value={previsao?.variation?.revenue}
                                        type="variation"
                                        color={
                                            previsao?.variation?.revenue > 0
                                                ? 'positive'
                                                : previsao?.variation?.revenue
                                                  < 0
                                                ? 'negative'
                                                : ''
                                        }
                                    />
                                </Col>
                                <Col xs={8}>
                                    <ValueCard
                                        title="Variação lucro"
                                        value={previsao?.variation?.profit}
                                        type="variation"
                                        color={
                                            previsao?.variation?.profit > 0
                                                ? 'positive'
                                                : previsao?.variation?.profit
                                                  < 0
                                                ? 'negative'
                                                : ''
                                        }
                                    />
                                </Col>
                            </Row>
                        </>
                    ) : (
                        <Row>
                            <Col xs={24}>
                                <div className="grafico-indisponivel">
                                    <WarningRoundedIcon
                                        style={{
                                            fontSize: '24px',
                                        }}
                                    />
                                    <p>
                                        Não temos dados suficientes para
                                        calcular
                                        <br />
                                        {' '}
                                        a previsão de demanda desse
                                        produto.
                                    </p>
                                </div>
                            </Col>
                        </Row>
                    )}
            </Grid>
        </Modal.Body>
        {previsao?.current_price?.forecast
            && 'sales' in previsao.current_price.forecast
            && previsao?.new_price?.forecast
            && 'sales' in previsao.new_price.forecast ? (
                <Modal.Footer>
                    <Button title="Ver resultados completos" appearance="link" onClick={goToPrevisao}>
                        Ver resultados completos
                    </Button>
                    <Button
                        title="Salvar simulação"
                        appearance="link"
                        onClick={handleSalvarSimulacao}
                    >
                        Salvar simulação
                    </Button>
                </Modal.Footer>
            ) : null}
    </Modal>
    );

export default ModalPrevisaoDemanda;
