export const colunasTableLojasParticipantesModal = [
    {
        align: 'center',
        width: 50,
        dataKey: 'store_id',
    },
    {
        sortable: false,
        align: 'left',
        fixed: false,
        headerCell: 'Nome da loja',
        dataKey: 'store',
        flexGrow: 2,
    },
    {
        sortable: false,
        align: 'left',
        fixed: false,
        headerCell: 'Tipo de loja',
        dataKey: 'store_type',
        flexGrow: 1,
    },
    {
        sortable: false,
        align: 'left',
        fixed: false,
        headerCell: 'Cluster',
        dataKey: 'store_cluster',
        flexGrow: 1,
    },
];

export const colunasTableLojasParticipantes = [
    {
        sortable: false,
        align: 'left',
        fixed: false,
        headerCell: 'Nome da loja',
        dataKey: 'store_name',
        flexGrow: 2,
    },
    {
        sortable: false,
        align: 'left',
        fixed: false,
        headerCell: 'Tipo de loja',
        dataKey: 'store_type',
        flexGrow: 1,
    },
    {
        sortable: false,
        align: 'left',
        fixed: false,
        headerCell: 'Cluster',
        dataKey: 'store_cluster',
        flexGrow: 1,
    },
    {
        sortable: false,
        align: 'left',
        fixed: 'right',
        headerCell: '',
        dataKey: '',
        width: 120,
    },
];
