import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import InputMask from 'react-input-mask';
import {
    Button,
    ButtonToolbar,
    ControlLabel,
    Form,
    FormControl,
    FormGroup,
    HelpBlock,
    Input,
    SelectPicker,
} from 'rsuite';

import {
    getCompanySegmentsList,
    getRoleList,
    getSectorsList,
    nextStage,
    setAccountData,
    setEmail,
} from '../../../../../actions/actionsCreateAccountFreemium';
import {
    sendLeadEvent, validEmail, validPhone, validateName,
} from '../utils';

class CreateAccountUserDataForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            error: {},
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.validNameInput = this.validNameInput.bind(this);
    }

    componentDidMount() {
        this.verifyAccountInfo();
        const {
            getCompanySegmentsList, getRoleList, getSectorsList,
        } = this.props;

        getCompanySegmentsList();
        getRoleList();
        getSectorsList();
    }

    handleSubmit(e) {
        e.preventDefault();
        const { history, account } = this.props;
        const { error } = this.state;

        if (
            this.validNameInput(account, error)
            && this.validPhoneInput(account, error)
            && this.validCompanyInput(account, error)
            && this.validForm()
        ) {
            history.push('/free/configuracoes');
            sendLeadEvent('infos-cadastradas-free', account);
        }
    }

    verifyAccountInfo() {
        const { account, history } = this.props;
        if (!account.email) {
            history.push('/free/email');
        }
    }

    validNameInput(account, error) {
        const inputName = document.getElementById('name');
        if (!account.name.length) {
            error.name = 'Campo obrigatório';
            if (inputName) {
                inputName.classList.add('invalid-input');
            }

            this.setState({ error });
            return false;
        }
        if (!validateName(account.name)) {
            error.name = 'Digite nome e sobrenome';
            if (inputName) {
                inputName.classList.add('invalid-input');
            }

            this.setState({ error });
            return false;
        }
        error.name = null;
        if (inputName) {
            inputName.classList.remove('invalid-input');
        }
        this.setState({ error });
        return true;
    }

    validPhoneInput(account, error) {
        const inputPhone = document.getElementById('phone');
        if (!account.phone.length) {
            error.phone = 'Campo obrigatório';
            if (inputPhone) {
                inputPhone.classList.add('invalid-input');
            }
            this.setState({ error });
            return false;
        }
        if (!validPhone(account.phone)) {
            error.phone = 'Digite um telefone válido';
            if (inputPhone) {
                inputPhone.classList.add('invalid-input');
            }
            this.setState({ error });
            return false;
        }
        error.phone = null;
        if (inputPhone) {
            inputPhone.classList.remove('invalid-input');
        }
        this.setState({ error });
        return true;
    }

    validCompanyInput(account, error) {
        const inputCompany = document.getElementById('company');
        if (!account.company.length) {
            error.company = 'Campo obrigatório';
            if (inputCompany) {
                inputCompany.classList.add('invalid-input');
            }
            this.setState({ error });
            return false;
        }
        error.company = null;
        if (inputCompany) {
            inputCompany.classList.remove('invalid-input');
        }
        this.setState({ error });
        return true;
    }

    validForm() {
        const { account } = this.props;
        const { error } = this.state;
        let formIsValid = true;

        const inputEmail = document.getElementById('email');
        if (!account.email.length) {
            formIsValid = false;
            error.email = 'Campo obrigatório';
            if (inputEmail) {
                inputEmail.classList.add('invalid-input');
            }
        } else if (!validEmail(account.email)) {
            formIsValid = false;
            error.email = 'Digite um e-mail válido';
            if (inputEmail) {
                inputEmail.classList.add('invalid-input');
            }
        } else {
            error.email = null;
            if (inputEmail) {
                inputEmail.classList.remove('invalid-input');
            }
        }

        const inputRole = document.getElementById('role');
        if (!account?.role?.length) {
            formIsValid = false;
            error.role = 'Campo obrigatório';
            if (inputRole) {
                inputRole.classList.add('invalid-input');
            }
        } else {
            error.role = null;
            if (inputRole) {
                inputRole.classList.remove('invalid-input');
            }
        }

        const inputSegment = document.getElementById('companySegment');
        if (!account?.companySegment?.length) {
            formIsValid = false;
            error.companySegment = 'Campo obrigatório';
            if (inputSegment) {
                inputSegment.classList.add('invalid-input');
            }
        } else {
            error.companySegment = null;
            if (inputSegment) {
                inputSegment.classList.remove('invalid-input');
            }
        }

        const inputActivitySector = document.getElementById('activitySector');
        if (!account?.activitySector?.length) {
            formIsValid = false;
            error.activitySector = 'Campo obrigatório';
            if (inputActivitySector) {
                inputActivitySector.classList.add('invalid-input');
            }
        } else {
            error.activitySector = null;
            if (inputActivitySector) {
                inputActivitySector.classList.remove('invalid-input');
            }
        }

        this.setState({ error });
        return formIsValid;
    }

    render() {
        const {
            account, companySegments, roles, setAccountData, sectors,
        } = this.props;
        const { error } = this.state;

        return (
            <Form fluid onSubmit={this.handleSubmit}>
                <FormGroup>
                    <ControlLabel>Nome</ControlLabel>
                    <FormControl
                        accepter={Input}
                        placeholder="Digite seu nome completo"
                        name="name"
                        id="name"
                        value={account.name}
                        onChange={(value, e) => setAccountData(value, e.target.name)}
                        onBlur={() => this.validNameInput(account, error)}
                        type="text"
                    />
                    {error.name ? (
                        <HelpBlock className="input-error" name="erroName">
                            {error.name}
                        </HelpBlock>
                    ) : null}
                </FormGroup>
                <FormGroup>
                    <ControlLabel>E-mail Corporativo</ControlLabel>
                    <FormControl
                        disabled
                        placeholder="usuario@empresa.com.br"
                        name="email"
                        id="email"
                        value={account.email}
                        onChange={(value, e) => setAccountData(value, e.target.name)}
                        type="text"
                    />
                    {error.email ? (
                        <HelpBlock className="input-error" name="erroEmail">
                            {error.email}
                        </HelpBlock>
                    ) : null}
                </FormGroup>
                <FormGroup>
                    <ControlLabel>Telefone</ControlLabel>
                    <InputMask
                        className="rs-input"
                        mask={
                            account.phone.length > 14
                                ? '(99) 99999-9999'
                                : '(99) 9999-99999'
                        }
                        maskChar={null}
                        placeholder="(XX) XXXXX-XXXX"
                        name="phone"
                        id="phone"
                        value={account.phone}
                        onChange={(event) => setAccountData(
                            event.target.value,
                            event.target.name,
                        )}
                        onBlur={() => this.validPhoneInput(account, error)}
                        type="text"
                    />
                    {error.phone ? (
                        <HelpBlock className="input-error" name="erroPhone">
                            {error.phone}
                        </HelpBlock>
                    ) : null}
                </FormGroup>
                <FormGroup>
                    <ControlLabel>Empresa</ControlLabel>
                    <FormControl
                        accepter={Input}
                        placeholder="Digite o nome da sua empresa"
                        name="company"
                        id="company"
                        value={account.company}
                        onChange={(value) => setAccountData(value, 'company')}
                        onBlur={() => this.validCompanyInput(account, error)}
                        type="text"
                    />
                    {error.company ? (
                        <HelpBlock className="input-error" name="erroCompany">
                            {error.company}
                        </HelpBlock>
                    ) : null}
                </FormGroup>
                <FormGroup>
                    <ControlLabel>Segmento da empresa</ControlLabel>
                    <FormControl
                        block
                        accepter={SelectPicker}
                        maxHeight={200}
                        data={companySegments}
                        searchable={false}
                        placeholder="Selecione o segmento da sua empresa"
                        name="companySegment"
                        id="companySegment"
                        value={account.companySegment}
                        onChange={(value) => setAccountData(value, 'companySegment')}
                        locale={{
                            noResultsText: 'Nenhum segmento encontrado',
                            searchPlaceholder: 'Buscar',
                        }}
                    />
                    {error.companySegment ? (
                        <HelpBlock
                            className="input-error"
                            name="erroCompanySegment"
                        >
                            {error.companySegment}
                        </HelpBlock>
                    ) : null}
                </FormGroup>
                <FormGroup>
                    <ControlLabel>Setor de atuação</ControlLabel>
                    <FormControl
                        block
                        accepter={SelectPicker}
                        maxHeight={200}
                        data={sectors}
                        searchable={false}
                        placeholder="Selecione o seu setor de atuação"
                        name="activitySector"
                        id="activitySector"
                        value={account.activitySector}
                        onChange={(value) => setAccountData(value, 'activitySector')}
                        locale={{
                            noResultsText: 'Nenhum setor encontrado',
                            searchPlaceholder: 'Buscar',
                        }}
                    />
                    {error.activitySector ? (
                        <HelpBlock
                            className="input-error"
                            name="erroActivitySector"
                        >
                            {error.activitySector}
                        </HelpBlock>
                    ) : null}
                </FormGroup>
                <FormGroup>
                    <ControlLabel>Cargo</ControlLabel>
                    <FormControl
                        block
                        maxHeight={200}
                        accepter={SelectPicker}
                        data={roles}
                        searchable={false}
                        placeholder="Selecione seu cargo"
                        name="role"
                        id="role"
                        value={account.role}
                        onChange={(value) => setAccountData(value, 'role')}
                        locale={{
                            noResultsText: 'Nenhum cargo encontrado',
                            searchPlaceholder: 'Buscar',
                        }}
                    />
                    {error.role ? (
                        <HelpBlock className="input-error" name="erroRole">
                            {error.role}
                        </HelpBlock>
                    ) : null}
                </FormGroup>

                <FormGroup>
                    <ButtonToolbar>
                        <Button block type="submit" appearance="primary">
                            Criar conta
                        </Button>
                    </ButtonToolbar>
                </FormGroup>
                <div className="link-login">
                    <Link to={{ pathname: '/login' }}>Voltar para login </Link>
                </div>
            </Form>
        );
    }
}

const mapStateToProps = (store) => ({
    account: store.accountFreemiumDataReducer.account,
    roles: store.accountFreemiumDataReducer.roles,
    companySegments: store.accountFreemiumDataReducer.companySegments,
    sectors: store.accountFreemiumDataReducer.sectors,
    stage: store.accountFreemiumDataReducer.stage,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
    {
        setEmail,
        nextStage,
        setAccountData,
        getCompanySegmentsList,
        getRoleList,
        getSectorsList,
    },
    dispatch,
);

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(CreateAccountUserDataForm),
);
