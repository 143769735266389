import cn from 'classnames';
import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GerenciadorPrecos } from '../../../../../../@types/GerenciadorPrecos';
import {
    ButtonSecondary,
    SecondaryButtonProps,
} from '../../../../../../components';
import {
    ADD_GERENCIADOR_EXPANDED_ROW_OPTION,
    REMOVE_GERENCIADOR_EXPANDED_ROW_OPTION,
    RESET_GERENCIADOR_EXPANDED_ROW,
    SET_GERENCIADOR_EXPANDED_ROW_KEY,
    selectorExpandedRowKey,
} from '../../../../../../reducers/gerenciadorPrecos/expandedRowKey';
import { BaseCell } from '../BaseCell';
import { BaseCellProps } from '../BaseCell/BaseCell';
import styles from './ExpandWholesaleCell.module.scss';

type ExpandWholesaleCellProps = BaseCellProps & {
    rowData: GerenciadorPrecos.RowData;
    theme?: SecondaryButtonProps['skin'];
};

const ExpandWholesaleCell = ({
    rowData,
    handleContextMenu,
    theme,
    className,
}: ExpandWholesaleCellProps) => {
    const { productsToBePricedId, wholesale } = rowData;

    if (!wholesale) return null;

    const dispatch = useDispatch();

    const { key, options } = useSelector(selectorExpandedRowKey);

    const isExpanded = useMemo(() => {
        return key === productsToBePricedId && options.includes('WHOLESALE');
    }, [key, productsToBePricedId, options]);

    const handleExpandWholesaleRow = useCallback(() => {
        if (key !== productsToBePricedId) {
            dispatch(RESET_GERENCIADOR_EXPANDED_ROW());
        }

        if (isExpanded) {
            dispatch(REMOVE_GERENCIADOR_EXPANDED_ROW_OPTION('WHOLESALE'));
            return;
        }

        dispatch(SET_GERENCIADOR_EXPANDED_ROW_KEY(productsToBePricedId));
        dispatch(ADD_GERENCIADOR_EXPANDED_ROW_OPTION('WHOLESALE'));
    }, [dispatch, isExpanded, key]);

    const newTheme = useMemo(
        () => theme || (isExpanded ? 'blue' : 'gray'),
        [isExpanded],
    );

    return (
        <BaseCell
            className={cn(className, styles.wrapper)}
            rowData={rowData}
            handleContextMenu={handleContextMenu}
        >
            <ButtonSecondary
                onClick={handleExpandWholesaleRow}
                theme={newTheme}
            >
                2
            </ButtonSecondary>
        </BaseCell>
    );
};

export default ExpandWholesaleCell;
