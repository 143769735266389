import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import { FilterItemProps, FiltersStateProps } from '../../@types/FiltersTypes';

export const initialState: FiltersStateProps = {
    productIds: {
        cache: [],
        value: [],
    },
    productFamilyIds: {
        cache: [],
        value: [],
    },
    storeIds: {
        cache: [],
        value: [],
    },
    productBrand: {
        cache: [],
        value: [],
    },
    supplier: {
        cache: [],
        value: [],
    },
    categoryLevel1: {
        cache: [],
        value: [],
    },
    categoryLevel2: {
        cache: [],
        value: [],
    },
    categoryLevel3: {
        cache: [],
        value: [],
    },
    categoryLevel4: {
        cache: [],
        value: [],
    },
    categoryLevel5: {
        cache: [],
        value: [],
    },
    categoryLevel6: {
        cache: [],
        value: [],
    },
    categoryLevel7: {
        cache: [],
        value: [],
    },
};

const slice = createSlice({
    name: 'filtros-state',
    initialState,
    reducers: {
        NEGOTIATION_SET_FITER_VALUE_LIST(
            state,
            {
                payload,
            }: PayloadAction<{
                name: keyof FiltersStateProps;
                value: string[];
            }>,
        ) {
            if (state[payload.name]) {
                state[payload.name]!.value = payload.value;
            }
        },
        NEGOTIATION_ADD_FILTER_STATE(
            state,
            {
                payload,
            }: PayloadAction<{
                name: keyof FiltersStateProps;
                data: Partial<FilterItemProps>;
            }>,
        ) {
            if (payload.data.value) {
                if (!state[payload.name]) return;
                state[payload.name]!.value = payload.data.value;
            }
            if (payload.data.cache) {
                if (!state[payload.name]) return;
                state[payload.name]!.cache = _.uniqBy(
                    [...state[payload.name].cache, ...payload.data.cache],
                    'value',
                );
            }
        },
        NEGOTIATION_CLEAR_FILTERS() {
            return initialState;
        },
    },
});

const {
    NEGOTIATION_SET_FITER_VALUE_LIST,
    NEGOTIATION_CLEAR_FILTERS,
    NEGOTIATION_ADD_FILTER_STATE,
} = slice.actions;

export {
    NEGOTIATION_ADD_FILTER_STATE,
    NEGOTIATION_CLEAR_FILTERS,
    NEGOTIATION_SET_FITER_VALUE_LIST,
};

export default slice.reducer;
