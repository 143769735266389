import { zodResolver } from '@hookform/resolvers/zod';
import React, { useEffect } from 'react';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { z } from 'zod';
import { ButtonPrimary, Callout } from '../../../../../../components';
import { SetupIPAHeader } from '../../SetupIPA/elements';
import styles from '../SetupIPAConfiguracoesBasicas.module.scss';
import { SetupIPAConfiguracoesBasicas } from '../SetupIPAConfiguracoesBasicas.types';
import CompetitorDaysInput from './CompetitorDaysInput';
import SuppliersCompetitorDaysInput from './SuppliersCompetitorDaysInput';

const schema = z.object({
    id: z.string().nullable().optional(),
    competitor_days: z.coerce
        .number()
        .min(1, 'Deve ser maior que 0')
        .max(180, 'Deve ser menor que 180'),
    suppliers_competitor_days: z.coerce
        .number()
        .min(1, 'Deve ser maior que 0')
        .max(180, 'Deve ser menor que 180'),
});

type FormValues = z.infer<typeof schema>;

interface ConfigFormProps {
    data?: SetupIPAConfiguracoesBasicas;
    createConfig: (data: FormValues) => void;
    updateConfig: (data: FormValues) => void;
}

const ConfigForm = ({ data, createConfig, updateConfig }: ConfigFormProps) => {
    const { control, handleSubmit, setValue } = useForm({
        resolver: zodResolver(schema),
        mode: 'onSubmit',
    });

    useEffect(() => {
        if (!data) return;
        setValue('competitor_days', data.competitor_days);
        setValue('suppliers_competitor_days', data.suppliers_competitor_days);
        setValue('id', data.id ? data.id : null);
    }, [data]);

    const onSubmit = (formData: FormValues) => {
        if (formData.id) {
            updateConfig(formData);
        } else {
            createConfig(formData);
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmit as SubmitHandler<FieldValues>)}>
            <div className={styles['setup-ipa__main-config']}>
                <h3>Configurações básicas</h3>
                <p
                    className={
                        styles['setup-ipa__main-config__header__subtitle']
                    }
                >
                    Defina as configurações básicas para
                </p>
            </div>
            <div className={styles['setup-ipa__price-preference-competitor']}>
                <SetupIPAHeader
                    title="Preferências do preço concorrente"
                    subtitle="Defina o período de tempo que deve ser considerado no uso do preço concorrente"
                />
                <div
                    className={
                        styles[
                            'setup-ipa__price-preference-competitor__content'
                        ]
                    }
                >
                    <h4>
                        Total de dias do preço concorrente para precificação
                    </h4>
                    <p>
                        Somente preços concorrentes que estiverem dentro do
                        total de dias definido serão considerados no processo de
                        precificação.
                    </p>
                    <CompetitorDaysInput control={control} />
                </div>
                <div
                    className={
                        styles[
                            'setup-ipa__price-preference-competitor__content'
                        ]
                    }
                >
                    <h4>
                        Total de dias do preço concorrente para negociação com
                        fornecedores
                    </h4>
                    <p>
                        Somente preços concorrentes que estiverem dentro do
                        total de dias definido serão considerados no módulo de
                        Negociação com fornecedores.
                    </p>
                    <SuppliersCompetitorDaysInput control={control} />
                </div>
            </div>
            <div className={styles['setup-ipa__main-config__footer']}>
                <Callout variant="warning" size="sm">
                    As modificações serão aplicadas na próxima integração de
                    dados.
                </Callout>
            </div>
            <div className={styles.footer}>
                <ButtonPrimary type="reset" theme="ghost">
                    cancelar
                </ButtonPrimary>
                <ButtonPrimary type="submit">salvar</ButtonPrimary>
            </div>
        </form>
    );
};

export default ConfigForm;
