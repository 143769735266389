import React from 'react';
import {
    Modal, Button, Icon,
} from 'rsuite';

const ModalConfirmacaoFiltro = ({
    show, onCancel, onConfirm, title, description, btnConfirm, btnCancel,
}) => (
    <Modal backdrop show={show} className="modal-confirmacao-filtro">
        <Modal.Body>
            <div className="header">
                <div className="header__icon">
                    <Icon icon="exclamation-triangle" />
                </div>
                <div className="header__content">
                    <h1>
                        {title}
                    </h1>
                    <p>{description}</p>
                </div>
            </div>
        </Modal.Body>
        <Modal.Footer>
            <Button appearance="ghost" onClick={() => onCancel()}>{btnCancel}</Button>
            <Button appearance="primary" onClick={() => onConfirm()}>{btnConfirm}</Button>
        </Modal.Footer>
    </Modal>
);

export default ModalConfirmacaoFiltro;
