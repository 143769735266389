import React, { HTMLAttributes } from 'react';
import styles from './styles.module.scss';

export interface IBoxRegularText{
    children: string | number;
    className?: HTMLAttributes<HTMLParagraphElement>;
}

export const BoxRegularText = ({ children, className }: IBoxRegularText) => (
    <p className={`${styles['regular-text']} ${className}`}>{children}</p>
);
