import React, { ReactNode } from 'react';
import styles from './styles.module.scss';

export interface IBoxRoot {
    children: ReactNode;
    marginRight?: string;
    type?: string;
}

export const BoxRoot = ({ children, type = 'container', marginRight = '8px' }: IBoxRoot) => (
    <div
        className={styles[type]}
        style={{ marginRight }}
    >
        {children}
    </div>
);
