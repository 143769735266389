import React from 'react';
import {
    Navbar, Nav, Button, Col,
} from 'rsuite';
import logoInfoprice from '../../../assets/logo/logo-infoprice-branco.svg';

const NavBar = ({ onClickLogin, onClickRegister }) => (
    <Navbar
        componentClass={Col}
        sm={24}
        appearance="inverse"
        className="share-analyze-navbar"
    >
        <Navbar.Header style={{ marginRight: '25px' }}>
            <div className="navbar-brand logo">
                <img alt="Logo Infoprice" src={logoInfoprice} />
            </div>
            <p className="nav-title">ISA | InfoPanel</p>
        </Navbar.Header>
        <Navbar.Body>
            <Nav pullRight>
                <Nav.Item
                    componentClass={Col}
                    sm={24}
                    eventKey="1"
                    title="ISA | InfoPanel"
                >
                    <Button appearance="primary" onClick={onClickLogin}>
                        Fazer login
                    </Button>
                    <Button appearance="ghost" onClick={onClickRegister}>
                        Criar conta grátis
                    </Button>
                </Nav.Item>
            </Nav>
        </Navbar.Body>
    </Navbar>
);

export default NavBar;
