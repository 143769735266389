import React from 'react';
import { Content, Loader } from 'rsuite';

type CardProps = {
    data: any;
    isLoading: boolean;
    title: string;
    description?: any;
    fullHeight?: boolean;
};

export const Card: React.FC<CardProps> = (props) => {
    const { data, isLoading, title, description, fullHeight } = props;

    if (isLoading)
        return (
            <Content
                className={`ira-card-loading ${
                    fullHeight ? 'full-height' : ''
                }`}
            >
                <Loader center />
            </Content>
        );

    console.log(data);

    if (!data || !data.length)
        return (
            <Content
                className={`ira-card-sem-dados ${
                    fullHeight ? 'full-height' : ''
                }`}
            >
                <h3>Sem dados para essa análise</h3>
                <p>Revise os filtros e tente novamente</p>
            </Content>
        );

    return (
        <Content className={`ira-card ${fullHeight ? 'full-height' : ''}`}>
            <div>
                <h3>{data}</h3>
                <p>{title}</p>
                <span>{description}</span>
            </div>
        </Content>
    );
};
