import { useSelector } from 'react-redux';
import {
    reducerOnDemandSelector,
} from '../../../../../../reducers/reducerOnDemand';
import { download, downloadInfopanel } from '../../services';
import { downloadFile } from '../../utils';

export const useOnDemandDownload = () => {
    const { tableRequestBody, downloadType } = useSelector(reducerOnDemandSelector);

    const downloadRequest = (body, extension) => {
        if (downloadType === 'list') {
            download(body).then((response) => {
                const content = response.data;
                const fileName = `solicitacoes_ondemand.${extension}`;
                downloadFile(content, fileName);
            });
        } else {
            downloadInfopanel(body).then((response) => {
                const content = response.data;
                const fileName = `solicitacoes_ondemand_price.${extension}`;
                downloadFile(content, fileName);
            });
        }
    };

    const handleDownload = (extension, tableData, checkedItems) => {
        const idsToExclude = tableData?.content
            .filter((i) => !checkedItems.includes(i.idSolicitacao))
            .map((i) => i.idSolicitacao);

        const validateSelection = (checkedItems.length === tableData?.content.length) || checkedItems.length === 0;

        const downloadBody = {
            ...tableRequestBody,
            fileExtension: extension,
            idsToExclude: validateSelection ? [] : idsToExclude,
        };

        downloadRequest(downloadBody, extension);
    };

    const tableIndividualDownload = (extension, id) => {
        const downloadBody = {
            ...tableRequestBody,
            fileExtension: extension,
            id: [id],
        };

        downloadRequest(downloadBody, extension);
    };

    return {
        handleDownload,
        tableIndividualDownload,
    };
};
