import React, { Component } from 'react';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import {
    Button, Col, FlexboxGrid, IconButton, Icon,
} from 'rsuite';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

class MobileSelectOptionComponent extends Component {
    shouldComponentUpdate(nextProps) {
        return nextProps !== this.props;
    }

    render() {
        const {
            open, handleClose, handleSelect, optionSelected, options,
        } = this.props;

        return (
            <Dialog className="dialog-select-option" fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
                <IconButton className="btn-close" appearance="subtle" onClick={handleClose} icon={<Icon icon="close" />} circle size="lg" />
                <FlexboxGrid className="content">
                    {options.map((item) => (
                        <FlexboxGrid.Item key={item.value} componentClass={Col} xs={24}>
                            <Button
                                block
                                onClick={() => handleSelect(item.value)}
                                appearance={item.value === optionSelected ? 'primary' : 'subtle'}
                            >
                                {item.label}

                            </Button>
                        </FlexboxGrid.Item>
                    ))}
                </FlexboxGrid>
            </Dialog>
        );
    }
}

export default MobileSelectOptionComponent;
