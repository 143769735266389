import React from 'react';
// @ts-ignore
import { Tabela } from '../../Components/index.tsx';
// @ts-ignore
import { useMargemPraticadaCategoria1 } from '../../../hooks/useMargemPraticadaCategoria1.tsx';

export const MargemPraticadaCategoria1: React.FC = () => {
    const { data, isLoading } = useMargemPraticadaCategoria1();

    const columns = [
        {
            align: 'left',
            headerCell: 'Categoria Nível 1',
            dataKey: 'categoryLevel1',
            flexGrow: 2,
        },
        {
            align: 'left',
            headerCell: 'Margem',
            dataKey: 'realizedMargin',
            isPercent: true,
        },
        {
            align: 'left',
            headerCell: 'Ganho margem',
            dataKey: 'marginGain',
            flexGrow: 1,
        },
    ];

    return (
        <Tabela
            data={data}
            isLoading={isLoading}
            columns={columns}
            title="Margem praticada por categoria nível 1"
        />
    );
};
