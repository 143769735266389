/* eslint-disable import/extensions */
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
    Table,
    Container,
    Content,
    FlexboxGrid,
    Col,
    ButtonToolbar,
    IconButton,
    Icon,
    ControlLabel,
    Input,
    Button,
    Tag,
    FormGroup,
    Grid,
    Row,
    Loader,
    CheckPicker,
} from 'rsuite';
import CurrencyFormat from 'react-currency-format';
import ModalProdutoPrecificacao from './Components/ModalFamiliaLojas';
import { format, parseISO } from 'date-fns';
import { LoadingSpiner } from '../../../../components/LoadingSpinner';
import {
    getInformacoesProduto,
    getDatapointProdutoFamilia,
    getGridModalFamilia,
} from './services.js';
import { getLastUpdate } from '../../../../services/ProdutoService';
import { getDatapoints } from '../../../../services/DatapointService';
import {
    simularPrecoLoja,
    simularPrecoMultiplasLojas,
    searchSegmentos,
} from '../../../../actions/actionsPrecificacaoManual';

const { Column, HeaderCell, Cell } = Table;

class ProdutoPrecificacaoManual extends Component {
    constructor(props) {
        super(props);

        this.state = {
            produto: null,
            lojas: [],
            pageNumber: 0,
            paginationModal: {},
            pageSize: 500,
            dataUltimaIntegracao: null,
            showModalProduto: false,
            modalFamiliaTabelaData: {},
            modalFamiliaInfoData: {},
        };

        this.getLojas = this.getLojas.bind(this);
        this.getInfoProduto = this.getInfoProduto.bind(this);
        this.back = this.back.bind(this);
        this.getInfoProdutoFamilia = this.getInfoProdutoFamilia.bind(this);
        this.setShowModal = this.setShowModal.bind(this);
        this.getTabelaModal = this.getTabelaModal.bind(this);
        this.setCloseModal = this.setCloseModal.bind(this);
        this.handleChangeLength = this.handleChangeLength.bind(this);
        this.handleChangePage = this.handleChangePage.bind(this);
    }

    componentDidMount() {
        const { searchSegmentos } = this.props;
        this.getLojas();
        this.getInfoProduto();
        this.getLastIntegracao();

        searchSegmentos();
    }

    handleChangeFilterInput(value, name) {
        this.setState(
            {
                [name]: value,
            },
            this.searchLojas
        );
    }

    getInfoProdutoFamilia(productFamilyId) {
        getDatapointProdutoFamilia(productFamilyId).then((response) => {
            if (response.product_family_id) {
                this.setState({
                    modalFamiliaInfoData: response,
                });
            }
        });
    }

    getTabelaModal() {
        const { produto, pageNumber } = this.state;
        getGridModalFamilia(produto.productFamilyId, pageNumber).then(
            (response) => {
                if (response.content.length) {
                    this.setState({
                        modalFamiliaTabelaData: response.content,
                        paginationModal: {
                            number: response.number,
                            size: response.size,
                            totalElements: response.totalElements,
                        },
                    });
                }
            }
        );
    }

    setCloseModal() {
        this.setState({
            showModalProduto: false,
        });
    }

    setShowModal(productFamilyId) {
        this.getInfoProdutoFamilia(productFamilyId);
        this.getTabelaModal();
        this.setState({
            showModalProduto: true,
        });
    }

    handleChangePage(e) {
        this.setState(
            {
                pageNumber: e - 1,
            },
            this.getTabelaModal
        );
    }

    handleChangeLength(e) {
        this.setState({
            pageSize: e,
        });
    }

    getLastIntegracao() {
        getLastUpdate().then((response) => {
            const lastUpdate = response?.last_update
                ? format(parseISO(response.last_update), 'dd/MM/yyyy')
                : '--';
            this.setState({ dataUltimaIntegracao: lastUpdate });
        });
    }

    getInfoProduto() {
        const { match } = this.props;
        getInformacoesProduto(match.params.identificador).then((response) => {
            if (response) {
                this.setState({
                    produto: response,
                });
            }
        });
    }

    getLojas() {
        const { match } = this.props;
        const { pageNumber, pageSize, lojas } = this.state;

        const params = {
            clientId: JSON.parse(localStorage.getItem('cliente'))?.codigo,
            page: pageNumber || 0,
            size: pageSize || 20,
        };

        getDatapoints(match.params.identificador, params).then((response) => {
            if (response) {
                this.setState({
                    lojas: response.content
                        .map((item) => ({
                            abcClass: item.abcClass,
                            categories: item.categories,
                            clientId: item.clientId,
                            competitorsPrice: item.competitorsPrice,
                            datapointId: item.datapointId,
                            description: item.description,
                            gtin: item.gtin,
                            limits: item.limits,
                            name: item.name,
                            pmz: item.pmz,
                            retailPrice: item.retailPrice,
                            margin:
                                item.retailPrice && item.pmz
                                    ? (item.retailPrice - item.pmz) /
                                      item.retailPrice
                                    : null,
                            cpi: item.competitorsPrice
                                ? item.retailPrice / item.competitorsPrice
                                : null,
                            price: item.price,
                            novaMargem: item.newMargin,
                            novaCpi:
                                item.competitorsPrice && item.price
                                    ? item.price / item.competitorsPrice
                                    : null,
                            productId: item.productId,
                            ruleActive: item.ruleActive,
                            rules: item.rules,
                            sensibilityType: item.sensibilityType,
                            status: item.status,
                            storeId: item.storeId,
                            storeName: item.storeName,
                            storeType: item.storeType,
                        }))
                        .concat(lojas),
                    data: response,
                });
            }
        });
    }

    searchLojas() {
        const { match } = this.props;
        const { pageNumber, pageSize, loja } = this.state;

        const data = {
            clientId: JSON.parse(localStorage.getItem('cliente'))?.codigo,
            storeName: loja || null,
            page: pageNumber || 0,
            size: pageSize || 20,
        };
        getDatapoints(match.params.identificador, data).then((response) => {
            if (response) {
                this.setState({
                    lojas: response.content.map((item) => ({
                        abcClass: item.abcClass,
                        categories: item.categories,
                        clientId: item.clientId,
                        competitorsPrice: item.competitorsPrice,
                        datapointId: item.datapointId,
                        description: item.description,
                        gtin: item.gtin,
                        limits: item.limits,
                        name: item.name,
                        pmz: item.pmz,
                        retailPrice: item.retailPrice,
                        margin:
                            item.retailPrice && item.pmz
                                ? (item.retailPrice - item.pmz) /
                                  item.retailPrice
                                : null,
                        cpi: item.competitorsPrice
                            ? item.retailPrice / item.competitorsPrice
                            : null,
                        price: item.price,
                        novaMargem: item.newMargin,
                        novaCpi:
                            item.competitorsPrice && item.price
                                ? item.price / item.competitorsPrice
                                : null,
                        productId: item.productId,
                        ruleActive: item.ruleActive,
                        rules: item.rules,
                        sensibilityType: item.sensibilityType,
                        status: item.status,
                        storeId: item.storeId,
                        storeName: item.storeName,
                        storeType: item.storeType,
                    })),
                    data: response,
                });
            }
        });
    }

    back() {
        const { history } = this.props;
        history.push('/ipa/precificacao-produto');
    }

    precificacaoLoja(loja) {
        const { history, simularPrecoLoja, match } = this.props;
        simularPrecoLoja(loja);
        history.push(
            `/ipa/precificacao-produto/${match.params.identificador}/precificar?storeId=${loja.storeId}`
        );
    }

    fetchMoreData(scrollX, scrollY) {
        const { data, lojas } = this.state;
        if (scrollY <= -350) {
            if (lojas.length < data.totalElements) {
                this.setState(
                    {
                        // eslint-disable-next-line react/no-unused-state
                        page: data.number + 1,
                    },
                    this.getLojas
                );
            }
        }
    }

    render() {
        const {
            produto,
            dataUltimaIntegracao,
            lojas,
            valueTipoLoja,
            tiposLoja,
            valueCluster,
            clusters,
            data,
            pageNumber,
            pageSize,
            showModalProduto,
            modalFamiliaTabelaData,
            paginationModal,
            modalFamiliaInfoData,
        } = this.state;
        const { segmentos } = this.props;
        return (
            <Grid>
                <Row className="show-grid">
                    <Col lg={24} xs={24}>
                        <Container className="main-container produto-precificacao-manual-page">
                            <Content>
                                <FlexboxGrid align="middle">
                                    <FlexboxGrid.Item
                                        componentClass={Col}
                                        lg={24}
                                        md={24}
                                        sm={24}
                                        xs={24}
                                        className="mg-bottom-0"
                                        style={{ padding: '0' }}
                                    >
                                        <FlexboxGrid align="middle">
                                            <FlexboxGrid.Item
                                                style={{ padding: '0' }}
                                                componentClass={Col}
                                                lg={3}
                                                md={3}
                                                sm={24}
                                                xs={24}
                                                className="mg-bottom-0"
                                            >
                                                <ButtonToolbar
                                                    onClick={this.back}
                                                >
                                                    <IconButton
                                                        icon={
                                                            <Icon icon="long-arrow-left" />
                                                        }
                                                    >
                                                        Voltar
                                                    </IconButton>
                                                </ButtonToolbar>
                                            </FlexboxGrid.Item>

                                            <FlexboxGrid.Item
                                                style={{
                                                    padding: '0',
                                                    textAlign: 'end',
                                                }}
                                                componentClass={Col}
                                                lg={6}
                                                md={8}
                                                sm={24}
                                                xs={24}
                                                mdPush={13}
                                                lgPush={15}
                                                className="mg-bottom-0"
                                            >
                                                <Tag
                                                    style={{
                                                        fontWeight: '600',
                                                    }}
                                                    color="green"
                                                >
                                                    Data da última integração:{' '}
                                                    {dataUltimaIntegracao ? (
                                                        <span>
                                                            {
                                                                dataUltimaIntegracao
                                                            }
                                                        </span>
                                                    ) : (
                                                        <Loader className="loader-integracao" />
                                                    )}
                                                </Tag>
                                            </FlexboxGrid.Item>
                                        </FlexboxGrid>
                                    </FlexboxGrid.Item>
                                </FlexboxGrid>

                                {produto ? (
                                    <FlexboxGrid
                                        align="middle"
                                        className="produto"
                                    >
                                        {produto.imagemUrl && (
                                            <FlexboxGrid.Item
                                                componentClass={Col}
                                                lg={3}
                                                md={3}
                                                sm={24}
                                                xs={24}
                                                className="image"
                                            >
                                                <img
                                                    alt="produto"
                                                    className="image__img"
                                                    src={produto.imagemUrl}
                                                />
                                            </FlexboxGrid.Item>
                                        )}
                                        <FlexboxGrid.Item
                                            componentClass={Col}
                                            lg={21}
                                            md={21}
                                            sm={24}
                                            xs={24}
                                            className="description"
                                        >
                                            <FlexboxGrid className="description__info-geral">
                                                <FlexboxGrid.Item
                                                    componentClass={Col}
                                                    lg={3}
                                                    md={5}
                                                    sm={24}
                                                    xs={24}
                                                >
                                                    <FormGroup>
                                                        <ControlLabel className="description__label">
                                                            IDENTIFICADOR
                                                        </ControlLabel>
                                                        <p>
                                                            {produto.productId}
                                                        </p>
                                                    </FormGroup>
                                                </FlexboxGrid.Item>
                                                <FlexboxGrid.Item
                                                    componentClass={Col}
                                                    lg={6}
                                                    md={5}
                                                    sm={24}
                                                    xs={24}
                                                >
                                                    <FormGroup>
                                                        <ControlLabel className="description__label">
                                                            DESCRIÇÃO
                                                        </ControlLabel>
                                                        <p>
                                                            {
                                                                produto.description
                                                            }
                                                        </p>
                                                    </FormGroup>
                                                </FlexboxGrid.Item>
                                                <FlexboxGrid.Item
                                                    componentClass={Col}
                                                    lg={3}
                                                    md={4}
                                                    sm={24}
                                                    xs={24}
                                                >
                                                    <FormGroup>
                                                        <ControlLabel className="description__familyId-label">
                                                            FAMILÍA DE PRODUTOS
                                                        </ControlLabel>
                                                        <p>
                                                            {produto.productFamilyId ? (
                                                                <Button
                                                                    style={{
                                                                        padding:
                                                                            '0px 0px 20px',
                                                                    }}
                                                                    appearance="link"
                                                                    onClick={() =>
                                                                        this.setShowModal(
                                                                            produto.productFamilyId
                                                                        )
                                                                    }
                                                                >
                                                                    {
                                                                        produto.productFamilyId
                                                                    }
                                                                </Button>
                                                            ) : (
                                                                '--'
                                                            )}
                                                        </p>
                                                    </FormGroup>
                                                </FlexboxGrid.Item>

                                                <ModalProdutoPrecificacao
                                                    isOpen={showModalProduto}
                                                    tabelaData={
                                                        modalFamiliaTabelaData
                                                    }
                                                    headerData={
                                                        modalFamiliaInfoData
                                                    }
                                                    onClose={this.setCloseModal}
                                                    pageModal={pageNumber}
                                                    sizeModal={pageSize}
                                                    onChangePage={
                                                        this.handleChangePage
                                                    }
                                                    onChangeLength={
                                                        this.handleChangeLength
                                                    }
                                                    paginationData={
                                                        paginationModal
                                                    }
                                                />
                                            </FlexboxGrid>
                                            {segmentos.length ? (
                                                <FlexboxGrid className="description__info-niveis">
                                                    <FlexboxGrid.Item
                                                        componentClass={Col}
                                                        lg={3}
                                                        md={4}
                                                        sm={24}
                                                        xs={24}
                                                    >
                                                        {produto.categoryLevel1 ? (
                                                            <FormGroup>
                                                                <ControlLabel className="description__label">
                                                                    {
                                                                        segmentos[
                                                                            segmentos?.findIndex(
                                                                                (
                                                                                    item
                                                                                ) =>
                                                                                    item?.nivel ===
                                                                                    1
                                                                            )
                                                                        ]?.title
                                                                    }
                                                                </ControlLabel>
                                                                <p>
                                                                    {
                                                                        produto.categoryLevel1
                                                                    }
                                                                </p>
                                                            </FormGroup>
                                                        ) : null}
                                                    </FlexboxGrid.Item>
                                                    <FlexboxGrid.Item
                                                        componentClass={Col}
                                                        lg={3}
                                                        md={4}
                                                        sm={24}
                                                        xs={24}
                                                    >
                                                        {produto.categoryLevel2 ? (
                                                            <FormGroup>
                                                                <ControlLabel className="description__label">
                                                                    {
                                                                        segmentos[
                                                                            segmentos?.findIndex(
                                                                                (
                                                                                    item
                                                                                ) =>
                                                                                    item?.nivel ===
                                                                                    2
                                                                            )
                                                                        ]?.title
                                                                    }
                                                                </ControlLabel>
                                                                <p>
                                                                    {
                                                                        produto.categoryLevel2
                                                                    }
                                                                </p>
                                                            </FormGroup>
                                                        ) : null}
                                                    </FlexboxGrid.Item>
                                                    <FlexboxGrid.Item
                                                        componentClass={Col}
                                                        lg={3}
                                                        md={4}
                                                        sm={24}
                                                        xs={24}
                                                    >
                                                        {produto.categoryLevel3 ? (
                                                            <FormGroup>
                                                                <ControlLabel className="description__label">
                                                                    {
                                                                        segmentos[
                                                                            segmentos?.findIndex(
                                                                                (
                                                                                    item
                                                                                ) =>
                                                                                    item?.nivel ===
                                                                                    3
                                                                            )
                                                                        ]?.title
                                                                    }
                                                                </ControlLabel>
                                                                <p>
                                                                    {
                                                                        produto.categoryLevel3
                                                                    }
                                                                </p>
                                                            </FormGroup>
                                                        ) : null}
                                                    </FlexboxGrid.Item>
                                                    <FlexboxGrid.Item
                                                        componentClass={Col}
                                                        lg={3}
                                                        md={4}
                                                        sm={24}
                                                        xs={24}
                                                    >
                                                        {produto.categoryLevel4 ? (
                                                            <FormGroup>
                                                                <ControlLabel className="description__label">
                                                                    {
                                                                        segmentos[
                                                                            segmentos?.findIndex(
                                                                                (
                                                                                    item
                                                                                ) =>
                                                                                    item?.nivel ===
                                                                                    4
                                                                            )
                                                                        ]?.title
                                                                    }
                                                                </ControlLabel>
                                                                <p>
                                                                    {
                                                                        produto.categoryLevel4
                                                                    }
                                                                </p>
                                                            </FormGroup>
                                                        ) : null}
                                                    </FlexboxGrid.Item>
                                                    <FlexboxGrid.Item
                                                        componentClass={Col}
                                                        lg={3}
                                                        md={4}
                                                        sm={24}
                                                        xs={24}
                                                    >
                                                        {produto.categoryLevel5 ? (
                                                            <FormGroup>
                                                                <ControlLabel className="description__label">
                                                                    {
                                                                        segmentos[
                                                                            segmentos?.findIndex(
                                                                                (
                                                                                    item
                                                                                ) =>
                                                                                    item?.nivel ===
                                                                                    5
                                                                            )
                                                                        ]?.title
                                                                    }
                                                                </ControlLabel>
                                                                <p>
                                                                    {
                                                                        produto.categoryLevel5
                                                                    }
                                                                </p>
                                                            </FormGroup>
                                                        ) : null}
                                                    </FlexboxGrid.Item>
                                                    <FlexboxGrid.Item
                                                        componentClass={Col}
                                                        lg={3}
                                                        md={4}
                                                        sm={24}
                                                        xs={24}
                                                    >
                                                        {produto.categoryLevel6 ? (
                                                            <FormGroup>
                                                                <ControlLabel className="description__label">
                                                                    {
                                                                        segmentos[
                                                                            segmentos?.findIndex(
                                                                                (
                                                                                    item
                                                                                ) =>
                                                                                    item?.nivel ===
                                                                                    6
                                                                            )
                                                                        ]?.title
                                                                    }
                                                                </ControlLabel>
                                                                <p>
                                                                    {
                                                                        produto.categoryLevel6
                                                                    }
                                                                </p>
                                                            </FormGroup>
                                                        ) : null}
                                                    </FlexboxGrid.Item>
                                                    <FlexboxGrid.Item
                                                        componentClass={Col}
                                                        lg={3}
                                                        md={4}
                                                        sm={24}
                                                        xs={24}
                                                    >
                                                        {produto.categoryLevel7 ? (
                                                            <FormGroup>
                                                                <ControlLabel className="description__label">
                                                                    {
                                                                        segmentos[
                                                                            segmentos?.findIndex(
                                                                                (
                                                                                    item
                                                                                ) =>
                                                                                    item?.nivel ===
                                                                                    7
                                                                            )
                                                                        ]?.title
                                                                    }
                                                                </ControlLabel>
                                                                <p>
                                                                    {
                                                                        produto.categoryLevel7
                                                                    }
                                                                </p>
                                                            </FormGroup>
                                                        ) : null}
                                                    </FlexboxGrid.Item>
                                                </FlexboxGrid>
                                            ) : (
                                                ''
                                            )}
                                        </FlexboxGrid.Item>
                                    </FlexboxGrid>
                                ) : null}
                            </Content>
                            <Content>
                                <FlexboxGrid>
                                    <FlexboxGrid.Item
                                        componentClass={Col}
                                        lg={24}
                                        md={24}
                                        sm={24}
                                        xs={24}
                                    >
                                        <FlexboxGrid align="middle">
                                            <FlexboxGrid.Item
                                                componentClass={Col}
                                                lg={4}
                                                md={4}
                                                sm={24}
                                                xs={24}
                                                className="mg-bottom-0"
                                            >
                                                <Input
                                                    placeholder="Nome da loja"
                                                    onChange={(value) =>
                                                        this.handleChangeFilterInput(
                                                            value,
                                                            'loja'
                                                        )
                                                    }
                                                />
                                            </FlexboxGrid.Item>

                                            <FlexboxGrid.Item
                                                componentClass={Col}
                                                colspan={4}
                                                lg={4}
                                                md={4}
                                                sm={24}
                                                xs={24}
                                                className="mg-bottom-0"
                                            >
                                                <CheckPicker
                                                    className="inf-picker"
                                                    value={valueTipoLoja}
                                                    cleanable
                                                    filter={false}
                                                    data={tiposLoja}
                                                    name="tipoLoja"
                                                    placeholder="Tipo de Loja"
                                                    locale={{
                                                        noResultsText:
                                                            'Nenhum item encontrado',
                                                        searchPlaceholder:
                                                            'Busque o tipo de loja desejada',
                                                    }}
                                                    onSelect={
                                                        this
                                                            .handleSelectTipoLoja
                                                    }
                                                    onSearch={(search) =>
                                                        this.searchTipoLoja({
                                                            query: search,
                                                        })
                                                    }
                                                    onChange={
                                                        this
                                                            .handleChangeTipoLoja
                                                    }
                                                />
                                            </FlexboxGrid.Item>

                                            <FlexboxGrid.Item
                                                componentClass={Col}
                                                colspan={4}
                                                lg={4}
                                                md={4}
                                                sm={24}
                                                xs={24}
                                                className="mg-bottom-0"
                                            >
                                                <CheckPicker
                                                    className="inf-picker"
                                                    value={valueCluster}
                                                    cleanable
                                                    filter={false}
                                                    data={clusters}
                                                    name="cluster"
                                                    placeholder="Cluster"
                                                    locale={{
                                                        noResultsText:
                                                            'Nenhum item encontrado',
                                                        searchPlaceholder:
                                                            'Busque o cluster desejado',
                                                    }}
                                                    onSelect={
                                                        this.handleSelectCluster
                                                    }
                                                    onSearch={(search) =>
                                                        this.searchCluster({
                                                            query: search,
                                                        })
                                                    }
                                                    onChange={
                                                        this.handleChangeCluster
                                                    }
                                                />
                                            </FlexboxGrid.Item>
                                        </FlexboxGrid>
                                    </FlexboxGrid.Item>
                                </FlexboxGrid>

                                <FlexboxGrid>
                                    <FlexboxGrid.Item
                                        componentClass={Col}
                                        lg={24}
                                        md={24}
                                        sm={24}
                                        xs={24}
                                    >
                                        <LoadingSpiner size="md" />
                                        <Table
                                            height={400}
                                            data={lojas}
                                            rowKey="storeId"
                                            onScroll={(scrollX, scrollY) =>
                                                this.fetchMoreData(
                                                    scrollX,
                                                    scrollY,
                                                    data.pageable
                                                )
                                            }
                                            shouldUpdateScroll={false}
                                            className="table"
                                        >
                                            <Column flexGrow={2} fixed>
                                                <HeaderCell>
                                                    Nome da loja
                                                </HeaderCell>
                                                <Cell dataKey="storeName" />
                                            </Column>

                                            <Column flexGrow={2} fixed>
                                                <HeaderCell>
                                                    Tipo da Loja
                                                </HeaderCell>
                                                <Cell dataKey="storeType" />
                                            </Column>
                                            <Column flexGrow={1}>
                                                <HeaderCell>Cluster</HeaderCell>
                                                <Cell dataKey="cluster" />
                                            </Column>
                                            <Column flexGrow={1}>
                                                <HeaderCell>PMZ</HeaderCell>
                                                <Cell>
                                                    {(rowData) =>
                                                        rowData.pmz ? (
                                                            <CurrencyFormat
                                                                fixedDecimalScale
                                                                decimalScale={2}
                                                                value={
                                                                    rowData.pmz
                                                                }
                                                                displayType="text"
                                                                prefix="R$"
                                                                decimalSeparator=","
                                                                thousandSeparator="."
                                                            />
                                                        ) : (
                                                            ''
                                                        )
                                                    }
                                                </Cell>
                                            </Column>

                                            <Column flexGrow={1}>
                                                <HeaderCell>
                                                    Preço Vigente
                                                </HeaderCell>
                                                <Cell>
                                                    {(rowData) =>
                                                        rowData.retailPrice ? (
                                                            <CurrencyFormat
                                                                fixedDecimalScale
                                                                decimalScale={2}
                                                                value={
                                                                    rowData.retailPrice
                                                                }
                                                                displayType="text"
                                                                prefix="R$"
                                                                decimalSeparator=","
                                                                thousandSeparator="."
                                                            />
                                                        ) : (
                                                            ''
                                                        )
                                                    }
                                                </Cell>
                                            </Column>
                                            <Column flexGrow={2}>
                                                <HeaderCell>
                                                    Novo Preço
                                                </HeaderCell>
                                                <Cell>
                                                    {(rowData) =>
                                                        rowData.price ||
                                                        rowData.price === 0 ? (
                                                            <span>
                                                                <CurrencyFormat
                                                                    className="table__novo-preco"
                                                                    fixedDecimalScale
                                                                    decimalScale={
                                                                        2
                                                                    }
                                                                    value={
                                                                        rowData.price
                                                                    }
                                                                    displayType="text"
                                                                    prefix="R$"
                                                                    decimalSeparator=","
                                                                    thousandSeparator="."
                                                                />
                                                                {rowData.ruleActive ? (
                                                                    <Tag
                                                                        className="table__tag-tipo-preco"
                                                                        color="blue"
                                                                    >
                                                                        {rowData
                                                                            .ruleActive
                                                                            .type ===
                                                                        'manualPrice'
                                                                            ? 'MANUAL'
                                                                            : 'REGRA'}
                                                                    </Tag>
                                                                ) : (
                                                                    ''
                                                                )}
                                                            </span>
                                                        ) : (
                                                            ''
                                                        )
                                                    }
                                                </Cell>
                                            </Column>
                                            <Column flexGrow={1}>
                                                <HeaderCell>
                                                    Nova Margem
                                                </HeaderCell>
                                                <Cell>
                                                    {(rowData) =>
                                                        rowData.novaMargem ? (
                                                            <CurrencyFormat
                                                                fixedDecimalScale
                                                                decimalScale={2}
                                                                value={
                                                                    rowData.novaMargem
                                                                }
                                                                displayType="text"
                                                                suffix="%"
                                                                decimalSeparator=","
                                                                thousandSeparator="."
                                                            />
                                                        ) : (
                                                            '-'
                                                        )
                                                    }
                                                </Cell>
                                            </Column>
                                            <Column flexGrow={1}>
                                                <HeaderCell>
                                                    Nova Competitividade
                                                </HeaderCell>
                                                <Cell>
                                                    {(rowData) =>
                                                        rowData.novaCpi ? (
                                                            <CurrencyFormat
                                                                fixedDecimalScale
                                                                decimalScale={2}
                                                                value={
                                                                    rowData.novaCpi *
                                                                    100
                                                                }
                                                                displayType="text"
                                                                suffix="%"
                                                                decimalSeparator=","
                                                                thousandSeparator="."
                                                            />
                                                        ) : (
                                                            '-'
                                                        )
                                                    }
                                                </Cell>
                                            </Column>

                                            <Column flexGrow={1} align="center">
                                                <HeaderCell>Limite</HeaderCell>
                                                <Cell>
                                                    {(rowData) =>
                                                        rowData.status ===
                                                        'REVISAO' ? (
                                                            <Icon
                                                                icon="remind"
                                                                className="font-size-25"
                                                            />
                                                        ) : null
                                                    }
                                                </Cell>
                                            </Column>
                                            <Column width={100} fixed="right">
                                                <HeaderCell />

                                                <Cell>
                                                    {(rowData) => (
                                                        <span
                                                            className="cursor-pointer"
                                                            onClick={() =>
                                                                this.precificacaoLoja(
                                                                    rowData
                                                                )
                                                            }
                                                            aria-hidden="true"
                                                        >
                                                            {' '}
                                                            Precificar{' '}
                                                        </span>
                                                    )}
                                                </Cell>
                                            </Column>
                                        </Table>
                                    </FlexboxGrid.Item>
                                </FlexboxGrid>
                            </Content>
                        </Container>
                    </Col>
                </Row>
            </Grid>
        );
    }
}

const mapStateToProps = (store) => ({
    segmentos: store.precificacaoManualDataReducer.filtros,
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            simularPrecoLoja,
            simularPrecoMultiplasLojas,
            searchSegmentos,
        },
        dispatch
    );

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(ProdutoPrecificacaoManual)
);
