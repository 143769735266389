import React, { useEffect } from 'react';
import { usePromiseTracker } from 'react-promise-tracker';
import { Alert } from 'rsuite';
import { ButtonPrimary } from '../../../../../components';
import { LoadingSpinerLocal } from '../../../../../components/LoadingSpinner';
import {
    CompetitivenessCard,
    FilterGroup,
    GerenciadorTable,
    Layout,
    NoDatapointResults,
    PremiumChip,
    ToolsAndActions,
    UploadCard,
} from '../../components';
import { PAGE_PROPS } from '../../components/template/GerenciadorTable/types';
import { useGerenciadorPrecos } from '../../context';
import { useFilters } from '../../hooks/useFilters';
import { getFromLocalStorage } from '../../lib';
import prices from '../../lib/logicalBusines';
import {
    GetDatapointProps,
    downloadDatapoints,
    getDatapoints,
} from '../../lib/services';
import './style.scss';

export type GerenciadorPrecosProps = React.HtmlHTMLAttributes<HTMLElement> &
    PAGE_PROPS;

export const GerenciadorPrecos = ({ history }: GerenciadorPrecosProps) => {
    const { data, setData, pagination, sort, setPagination } =
        useGerenciadorPrecos();
    if (!data.competitiveness) return history.push('/ipa/lite/onboarding');

    const {
        filters,
        model: filtersModel,
        data: { gtin },
        changeQuery,
        selectFiltro,
        changeFiltros,
        cleanFilters,
        changeFiltroCheckAll,
    } = useFilters(['gtin']);

    const { promiseInProgress: isDatapointLoading } = usePromiseTracker({
        area: 'datapoints-table',
    });
    const { promiseInProgress: isDownloadLoading } = usePromiseTracker({
        area: 'download-datapoints',
    });

    const getDatapointsBulk = async (data?: GetDatapointProps) => {
        getDatapoints(data).then((response) => {
            const { content } = response;
            const updatedContent = content.map((content) => {
                const competitiveness = prices.competitiveness(
                    content.retailPrice,
                    content.competitorPrice,
                );
                const initialCompetitiveness = prices.competitiveness(
                    content.price,
                    content.competitorPrice,
                );

                return {
                    ...content,
                    competitiveness,
                    initialCompetitiveness,
                    initialPrice: content.price,
                };
            });

            setData((oldValue) => ({
                ...oldValue,
                ...response,
                content: updatedContent,
            }));
        });
    };

    const handleDownloadDatapoints = () => {
        const client = getFromLocalStorage<{
            codigo: number;
            nome: string;
            organizacao: string;
        }>('cliente');
        if (!client) return;
        downloadDatapoints().then((response) => {
            if (response.status === 200) {
                Alert.success('Download realizado com sucesso');
            }
        });
    };

    useEffect(() => {
        const lastPage = data.totalElements / pagination.displayLength;

        getDatapointsBulk({
            page: Math.round(
                pagination.activePage > lastPage
                    ? lastPage
                    : pagination.activePage,
            ),
            size: pagination.displayLength,
            sort: `${sort.type},${sort.orderBy}`,
            ...filtersModel,
        });
    }, [pagination.displayLength, pagination.activePage, sort]);

    useEffect(() => {
        getDatapointsBulk({
            size: pagination.displayLength,
            sort: `${sort.type},${sort.orderBy}`,
            ...filtersModel,
        }).then(() => {
            setPagination((oldValue) => ({
                ...oldValue,
                activePage: 0,
            }));
        });
    }, [filters]);

    return (
        <Layout className="gerenciador-precos">
            <Layout.Header className="gerenciador-precos__header">
                <div>
                    <div className="flex-align-center">
                        <Layout.Title>Gerenciador de preços</Layout.Title>
                    </div>
                </div>
                <PremiumChip />
            </Layout.Header>
            <Layout.Section className="big-numbers">
                <UploadCard />
                <CompetitivenessCard competitiveness={data.competitiveness} />
            </Layout.Section>
            <Layout.Section className="filters-section">
                <FilterGroup>
                    <FilterGroup.Item
                        id="check-picker-pill"
                        type="check"
                        placeholder="Produto"
                        data={gtin?.content ?? []}
                        cache={filters.gtin?.cache ?? []}
                        value={filters.gtin?.value ?? []}
                        onSelect={(value) => selectFiltro(value, 'gtin')}
                        onChange={(value) => changeFiltros(value, 'gtin')}
                        onSearch={(value) => changeQuery(value)}
                        onClean={() => selectFiltro([], 'gtin')}
                        onClose={() => changeQuery('')}
                        locale={{
                            noResultsText: gtin?.isLoading
                                ? 'Carregando famílias...'
                                : 'Nenhum item encontrado',
                            searchPlaceholder: 'Pesquisar',
                        }}
                        sticky
                        renderExtraFooter={() => (
                            <FilterGroup.Footer
                                checked={filters.gtin?.checkAll}
                                onChange={(
                                    value: unknown,
                                    checked: boolean,
                                ) => {
                                    changeFiltroCheckAll('gtin', checked);
                                }}
                            >
                                Selecionar todos os produtos
                            </FilterGroup.Footer>
                        )}
                    />
                    <FilterGroup.Item
                        id="check-picker-pill"
                        placeholder="Família"
                        type="check"
                        data={[]}
                        locked
                    />
                    <FilterGroup.Item
                        id="check-picker-pill"
                        placeholder="Segmentação"
                        type="check"
                        data={[]}
                        locked
                    />
                    <FilterGroup.CleanFilters
                        className="clear-filtros-btn"
                        onClick={cleanFilters}
                    >
                        Limpar Filtros
                    </FilterGroup.CleanFilters>
                </FilterGroup>
            </Layout.Section>
            <Layout.Section>
                <ToolsAndActions total={data.totalElements}>
                    <ButtonPrimary
                        size="medium"
                        skin="blue"
                        onClick={handleDownloadDatapoints}
                        isLoading={isDatapointLoading || isDownloadLoading}
                        disabled={data.content.length < 1}
                    >
                        PREÇOS ATUALIZADOS
                        <ButtonPrimary.Icon icon="MdFileDownload" />
                    </ButtonPrimary>
                </ToolsAndActions>
            </Layout.Section>

            {data.content.length === 0 ? (
                <Layout.Section className="gerenciador-precos__no-data">
                    {isDatapointLoading ? (
                        <LoadingSpinerLocal
                            loading={isDatapointLoading}
                            size="md"
                            height="100%"
                            position="absolute"
                        />
                    ) : (
                        <NoDatapointResults />
                    )}
                </Layout.Section>
            ) : (
                <GerenciadorTable
                    loading={isDatapointLoading || isDownloadLoading}
                />
            )}
        </Layout>
    );
};
