import { faTh } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsMore from 'highcharts/highcharts-more';
import HighchartsExport from 'highcharts/modules/export-data';
import HighchartsExporting from 'highcharts/modules/exporting';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    Alert,
    Button,
    Col,
    Content,
    Dropdown,
    FlexboxGrid,
    Icon,
    IconButton,
    Table,
} from 'rsuite';

import iconLowQuartile from '../../../../../assets/icons/icon_boxplot_low-quartile.svg';
import iconMax from '../../../../../assets/icons/icon_boxplot_max.svg';
import iconMedian from '../../../../../assets/icons/icon_boxplot_median.svg';
import iconMin from '../../../../../assets/icons/icon_boxplot_min.svg';
import iconUpperQuartile from '../../../../../assets/icons/icon_boxplot_upper-quartile.svg';
import iconChain from '../../../../../assets/icons/icon_chain.svg';
import iconEllipsis from '../../../../../assets/icons/icon_ellipsis-v.svg';
import lockIcon from '../../../../../assets/icons/icon_lock.svg';
import iconMaximize from '../../../../../assets/icons/icon_maximize.svg';
import iconMinimize from '../../../../../assets/icons/icon_minimize.svg';
import iconFrequence from '../../../../../assets/icons/icon_price-2.svg';
import iconAverage from '../../../../../assets/icons/icon_price.svg';
import iconStore from '../../../../../assets/icons/icon_store.svg';
import noDataImage from '../../../../../assets/image/empty-state-2.svg';

import {
    getBoxPlotData,
    setSortColumnBoxplot,
    setVisualizationTypeBoxplot,
} from '../../../../../actions/actionsPainelGeral';
import { LoadingSpinerLocal } from '../../../../../components/LoadingSpinner';
import MobileSelectOptionComponent from '../../../../../components/MobileSelectOptionComponent';
import ModalConfirmation from '../../../../../components/ModalConfirmation';
import ModalNotification from '../../../../../components/ModalNotification';
import { setClickToLocalStorage } from '../../../../../components/NavBar';
import { lead } from '../../../../../services/EventLeadService';
import { HighchartsOptions } from '../../../../../utils/HighchartsConfig';
import { formatNumberWithComma } from '../utils';

import { trackEvent } from '../../../../../utils/MatomoConfig';
import EmptyState from './EmptyState';

const {
    Column, HeaderCell, Cell, Pagination,
} = Table;

HighchartsMore(Highcharts);
HighchartsExporting(Highcharts);
HighchartsExport(Highcharts);

Highcharts.setOptions(HighchartsOptions);

const seriesColors = [
    '#76D680',
    '#A371E2',
    '#DFA25B',
    '#F25E5E',
    '#609BCE',
    '#B30089',
    '#FFB46E',
    '#FFD76E',
    '#5FC8FF',
    '#FF73C3',
];

const optionsGroupBy = [
    { label: 'Produto', value: 'produto' },
    { label: 'Rede', value: 'rede' },
    { label: 'Produto e rede', value: 'produtoRede' },
    { label: 'UF', value: 'uf' },
    { label: 'Produto e UF', value: 'produtoUf' },
    { label: 'Região', value: 'regiao' },
    { label: 'Produto e Região', value: 'produtoRegiao' },
    { label: 'Fabricante', value: 'fabricante' },
];

const optionsVisualize = [
    { label: 'Gráfico', value: 'chart' },
    { label: 'Tabela', value: 'table' },
];

const visualizeTypeLabel = {
    produto: 'PRODUTO',
    rede: 'REDE',
    produtoRede: 'PRODUTO REDE',
    uf: 'UF',
    produtoUf: 'PRODUTO UF',
    regiao: 'REGIÃO',
    produtoRegiao: 'PRODUTO REGIÃO',
    fabricante: 'FABRICANTE',
};

const groupByOptions = [
    { label: 'Produto', key: 'produto' },
    { label: 'Rede', key: 'rede' },
    { label: 'Produto e rede', key: 'produtoRede' },
    { label: 'UF', key: 'uf' },
    { label: 'Produto e UF', key: 'produtoUf' },
    { label: 'Fabricante e UF', key: 'fabricanteUf' },
    { label: 'Região', key: 'regiao' },
    { label: 'Produto e Região', key: 'produtoRegiao' },
    { label: 'Fabricante e Região', key: 'fabricanteRegiao' },
    { label: 'Fabricante', key: 'fabricante' },
    { label: 'Consolidado', key: 'semAgrupamento' },
];

class BoxPlotChart extends Component {
    constructor(props) {
        super(props);

        this.state = {
            typeVisualization: 'produto',
            visualize: 'chart',
            hasPermition: true,
            showCTA: false,
            expand: true,
            showModalDownload: false,
            sortType: 'asc',
            openDialogGroupBy: false,
            openDialogVisualize: false,
            visualizationNameType: 'Produto',
        };

        this.handleDownload = this.handleDownload.bind(this);
        this.exportPNG = this.exportPNG.bind(this);
        this.print = this.print.bind(this);
        this.viewFullScreen = this.viewFullScreen.bind(this);
        this.sendCTA = this.sendCTA.bind(this);
        this.handleSortColumn = this.handleSortColumn.bind(this);
        this.handleOpenDialogGroupBy = this.handleOpenDialogGroupBy.bind(this);
        this.handleOpenDialogVisualize
            = this.handleOpenDialogVisualize.bind(this);
        this.changeVisualization = this.changeVisualization.bind(this);
        this.changeVisualize = this.changeVisualize.bind(this);
        this.handleChangePage = this.handleChangePage.bind(this);
        this.setShowModalConfirm = this.setShowModalConfirm.bind(this);
        this.handleNameOptions = this.handleNameOptions.bind(this);
    }

    shouldComponentUpdate(nextProps, nextState) {
        const {
            data, sortColumn, loadingBoxplot, produto,
        } = this.props;
        const {
            typeVisualization,
            visualize,
            showCTA,
            expand,
            showModalDownload,
            sortType,
            openDialogVisualize,
            openDialogGroupBy,
            hasPermition,
        } = this.state;

        const permit = nextProps.produto?.cache.some((item) => item.limited === true);

        if (produto !== nextProps?.produto) {
            this.setState({
                hasPermition: !permit,
            });
        }

        return (
            nextProps.data !== data
            || nextProps.sortColumn !== sortColumn
            || nextState.typeVisualization !== typeVisualization
            || nextState.visualize !== visualize
            || nextState.showCTA !== showCTA
            || nextState.expand !== expand
            || nextState.showModalDownload !== showModalDownload
            || nextState.sortType !== sortType
            || nextState.openDialogVisualize !== openDialogVisualize
            || nextState.openDialogGroupBy !== openDialogGroupBy
            || nextState.hasPermition !== hasPermition
            || nextProps.produto !== produto
            || nextState.loadingBoxplot !== loadingBoxplot
        );
    }

    handleSortColumn(sortColumn, sortType) {
        const { setSortColumnBoxplot } = this.props;

        setSortColumnBoxplot(sortColumn);
        this.setState(
            {
                sortType,
            },
            this.getNewData,
        );
    }

    handleOpenDialogGroupBy() {
        this.setState({
            openDialogGroupBy: true,
        });
    }

    handleOpenDialogVisualize() {
        this.setState({
            openDialogVisualize: true,
        });
    }

    handleChangePage(e) {
        this.setState(
            {
                page: e - 1,
            },
            this.getNewData,
        );
    }

    getNewData() {
        const { page, sortType, typeVisualization } = this.state;
        const { sortColumn, onChangeVisualizationBoxplot } = this.props;
        const pagination = { page: page || 0, size: 10 };
        const order = sortColumn
            ? [{ by: sortColumn, ascending: sortType === 'asc' }]
            : null;

        onChangeVisualizationBoxplot(typeVisualization, pagination, order);
    }

    mapDataCategories(data, type) {
        const mapData = [...data];
        let categories;
        switch (type) {
            case 'produto':
                categories = mapData.map((item) => item.descricao);
                return categories;
            case 'rede':
                categories = mapData.map((item) => item.rede);
                return categories;
            case 'produtoRede':
                categories = mapData.map(
                    (item) => `${item.descricao}<br>${item.rede}`,
                );
                return categories;
            case 'uf':
                categories = mapData.map((item) => item.uf);
                return categories;
            case 'produtoUf':
                categories = mapData.map(
                    (item) => `${item.descricao}<br>${item.uf}`,
                );
                return categories;
            case 'regiao':
                categories = mapData.map((item) => item.regiao);
                return categories;
            case 'produtoRegiao':
                categories = mapData.map(
                    (item) => `${item.descricao}<br>${item.regiao || '-'}`,
                );
                return categories;
            case 'fabricanteRegiao':
                categories = mapData.map(
                    (item) => `${item.fabricante || 'SEM INFORMAÇÃO DE FABRICANTE'
                        }<br>${item.regiao || '-'}`,
                );
                return categories;
            case 'fabricanteUf':
                categories = mapData.map(
                    (item) => `${item.fabricante || 'SEM INFORMAÇÃO DE FABRICANTE'
                        }<br>${item.uf || '-'}`,
                );
                return categories;
            case 'fabricante':
                categories = mapData.map(
                    (item) => item.fabricante || 'SEM INFORMAÇÃO DE FABRICANTE',
                );
                return categories;
            case 'semAgrupamento':
                return ['Consolidado'];
            default:
                categories = mapData.map((item) => item.descricao);
                return categories;
        }
    }

    mapDataSeries(data) {
        const mapData = [...data];
        const serie = mapData.map((item, index) => ({
            data: [
                {
                    x: index,
                    name: item.descricao,
                    low: item.preco_minimo,
                    minPrice: item.preco_minimo,
                    q1: item.preco_quartil_inferior,
                    median: item.preco_mediano,
                    q3: item.preco_quartil_superior,
                    high: item.preco_maximo,
                    maxPrice: item.preco_maximo,
                    numberStores: item.cnpjs_distintos,
                    numberRedes: item.redes_distintas,
                    frequentPrice: item.preco_moda,
                    averagePrice: item.preco_medio,
                    rede: item.rede,
                    fillColor: seriesColors[index],
                },
            ],
        }));
        return serie;
    }

    renderChart() {
        const { data } = this.props;
        const { typeVisualization } = this.state;

        return {
            chart: {
                type: 'boxplot',
                inverted: true,
                height:
                    data.content.length <= 5
                        ? 400
                        : data.content.length >= 6 && data.content.length <= 8
                            ? 600
                            : 800,
            },
            title: {
                text: '',
            },
            legend: {
                enabled: false,
            },
            xAxis: {
                categories: this.mapDataCategories(
                    data.content,
                    typeVisualization,
                ),
                title: {
                    text: visualizeTypeLabel[typeVisualization],
                },
                labels: {
                    style: {},
                },
            },
            yAxis: [
                {
                    title: {
                        text: 'PREÇO',
                    },
                    labels: {
                        formatter() {
                            let price
                                = this.axis.defaultLabelFormatter.call(this);
                            if (price.indexOf(',') !== -1) {
                                price = price.replace(',', '.');
                            }
                            return `R$ ${formatNumberWithComma(Number(price))}`;
                        },
                    },
                },
                {
                    title: {
                        text: '',
                    },
                    linkedTo: 0,
                    labels: {
                        formatter() {
                            let price
                                = this.axis.defaultLabelFormatter.call(this);
                            if (price.includes('.')) {
                                price = price.replace('.', '');
                            }
                            if (price.indexOf(',') !== -1) {
                                price = price.replace(',', '.');
                            }
                            return `R$ ${formatNumberWithComma(Number(price))}`;
                        },
                    },
                    opposite: true,
                },
            ],
            tooltip: {
                useHTML: true,
                formatter() {
                    return (
                        `<p>${this.x}</p>`
                        + `<p><img src='${iconMax}'/>Máximo: <b>${formatNumberWithComma(this.point.maxPrice)}</b></p>`
                        + `<p><img src='${iconUpperQuartile}'/>Quartil superior: <b>${formatNumberWithComma(this.point.q3)}</b></p>`
                        + `<p><img src='${iconMedian}'/>Mediana: <b>${formatNumberWithComma(this.point.median)}</b></p>`
                        + `<p><img src='${iconLowQuartile}'/>Quartil inferior: <b>${formatNumberWithComma(this.point.q1)}</b></p>`
                        + `<p><img src='${iconMin}'/>Mínimo: <b>${formatNumberWithComma(this.point.minPrice)}</b></p>`
                        + `<p><img src='${iconAverage}'/>Preço médio: <b>${(this.point.averagePrice)}</b></p>`
                        + `<p><img src='${iconFrequence}'/>Preço mais frequente: <b>${formatNumberWithComma(this.point.frequentPrice)}</b></p>`
                        + `<p><img src='${iconStore}'/># de lojas: <b>${this.point.numberStores}</b></p>`
                        + `<p><img src='${iconChain}'/># de redes: <b>${this.point.numberRedes}</b></p>`
                    );
                },
                padding: 15,
            },
            exporting: {
                enabled: false,
            },
            plotOptions: {
                series: {
                    color: '#979797',
                    pointWidth: 30,
                    stickyTracking: true,
                },
                boxplot: {
                    medianColor: '#4F4F4F',
                    medianWidth: 3,
                },
            },
            series: this.mapDataSeries(data.content),
        };
    }

    handleNameOptions(label) {
        if (label) {
            this.setState({ visualizationNameType: label });
        }
    }

    changeVisualization(type, label) {
        this.handleNameOptions(label);
        const {
            setVisualizationTypeBoxplot,
            setSortColumnBoxplot,
            usuario,
            produto,
        } = this.props;

        setVisualizationTypeBoxplot(type);
        if (
            type !== 'produto'
            && (usuario?.servicoPanel?.pacotePainel?.name === 'FREE'
                || usuario?.servicoPanel?.pacotePainel?.name === 'LITE'
                || (usuario?.servicoPanel?.pacotePainel?.name === 'LIMIT'
                    && produto?.cache.some((item) => item.limited === true)))
        ) {
            this.setState({
                page: 0,
                typeVisualization: type,
                hasPermition: false,
            });
            setSortColumnBoxplot(null);
            if (usuario?.servicoPanel?.pacotePainel?.name === 'FREE') {
                setClickToLocalStorage(
                    'addBlockFree',
                    'clique-adblock',
                    usuario,
                );
            }
        } else {
            setSortColumnBoxplot(null);
            this.setState(
                {
                    page: 0,
                    typeVisualization: type,
                    hasPermition: true,
                },
                this.getNewData,
            );
        }
    }

    changeVisualize(type) {
        this.setState({
            visualize: type,
        });
    }

    sendCTA() {
        const { usuario } = this.props;
        const identificador = 'upsell_infopanel';
        lead(identificador, usuario).then(() => {
            this.setState({
                showCTA: false,
            });
            Alert.success('Em breve entraremos em contato!');
        });

        trackEvent('Upsell/Crossell', 'upsell-block-amplitude');
        window.open(
            'https://wa.me/551137779670?text=Ol%C3%A1%21+Quero+saber+mais+sobre+os+planos+Flex+e+Pro+do+ISA+-+InfoPanel',
        );
    }

    setShowModalConfirm() {
        this.setState({ showCTA: true });
        trackEvent('Upsell/Crossell', 'click-funcao-bloqueada');
    }

    handleDownload(type) {
        const { sortColumn, onClickDownload } = this.props;
        const { sortType, typeVisualization } = this.state;
        onClickDownload(type, sortColumn, sortType, typeVisualization);
    }

    exportPNG() {
        if (this.chart?.chart) {
            this.chart.chart.exportChart({ type: 'image/png' });
        }
    }

    print() {
        if (this.chart?.chart) {
            this.chart.chart.print();
        }
    }

    viewFullScreen() {
        if (this.chart?.chart) {
            this.chart.chart.fullscreen.toggle();
        }
    }

    render() {
        const {
            data, sortColumn, usuario, loadingBoxplot, produto,
        }
            = this.props;

        const {
            typeVisualization,
            visualize,
            hasPermition,
            showCTA,
            expand,
            sortType,
            showModalDownload,
            openDialogGroupBy,
            openDialogVisualize,
            visualizationNameType,
        } = this.state;

        return (
            <Content className="box-plot-chart">
                <LoadingSpinerLocal loading={loadingBoxplot} size="md" />
                <FlexboxGrid className="header-area">
                    <FlexboxGrid.Item
                        componentClass={Col}
                        lg={22}
                        md={22}
                        sm={17}
                        xs={17}
                        className={!expand ? 'minimized' : null}
                    >
                        <h4>AMPLITUDE DE PREÇO</h4>
                    </FlexboxGrid.Item>

                    <FlexboxGrid.Item
                        componentClass={Col}
                        lg={1}
                        md={1}
                        sm={3}
                        xs={3}
                        className={
                            !expand
                                ? 'expand-section minimized'
                                : 'expand-section'
                        }
                    >
                        <Button
                            id="boxplot-expand-btn"
                            appearance="subtle"
                            onClick={() => {
                                this.setState({
                                    expand: !expand,
                                });
                            }}
                        >
                            {expand ? (
                                <img alt="Maximizar" src={iconMinimize} />
                            ) : (
                                <img alt="Minimizar" src={iconMaximize} />
                            )}
                        </Button>
                    </FlexboxGrid.Item>
                    {typeVisualization !== 'produto'
                        && (usuario?.servicoPanel?.pacotePainel?.name === 'LITE'
                            || (usuario?.servicoPanel?.pacotePainel?.name
                                === 'LIMIT'
                                && produto?.cache.some(
                                    (item) => item.limited === true,
                                ))) ? null : (
                        <FlexboxGrid.Item
                            componentClass={Col}
                            lg={1}
                            md={1}
                            sm={3}
                            xs={3}
                            className={
                                !expand
                                    ? 'more-options-section minimized'
                                    : 'more-options-section'
                            }
                        >
                            <Dropdown
                                id="boxplot-more-options"
                                placement="bottomEnd"
                                renderTitle={() => (
                                    <IconButton
                                        className="more-options-section__btn"
                                        appearance="subtle"
                                        icon={(
                                            <img
                                                alt="Mais opções"
                                                src={iconEllipsis}
                                            />
                                        )}
                                    />
                                )}
                            >
                                {visualize === 'chart' ? (
                                    <>
                                        <Dropdown.Item
                                            onClick={this.viewFullScreen}
                                            id="btn-boxplot-full-screen"
                                        >
                                            Ver em tela cheia
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                            disabled={
                                                usuario?.servicoPanel
                                                    ?.pacotePainel?.name
                                                === 'FREE'
                                            }
                                            onClick={this.print}
                                            id="btn-boxplot-print"
                                        >
                                            Imprimir
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                            disabled={
                                                usuario?.servicoPanel
                                                    ?.pacotePainel?.name
                                                === 'FREE'
                                            }
                                            divider
                                        />
                                        <Dropdown.Item
                                            disabled={
                                                usuario?.servicoPanel
                                                    ?.pacotePainel?.name
                                                === 'FREE'
                                            }
                                            onClick={this.exportPNG}
                                            id="btn-boxplot-download-png"
                                        >
                                            Download em PNG
                                        </Dropdown.Item>
                                    </>
                                ) : null}
                                <Dropdown.Item
                                    disabled={
                                        usuario?.servicoPanel?.pacotePainel
                                            ?.name === 'FREE'
                                    }
                                    onClick={() => this.handleDownload('csv')}
                                    id="btn-boxplot-download-csv"
                                >
                                    Download em CSV
                                </Dropdown.Item>
                                <Dropdown.Item
                                    disabled={
                                        usuario?.servicoPanel?.pacotePainel
                                            ?.name === 'FREE'
                                    }
                                    onClick={() => this.handleDownload('xlsx')}
                                    id="btn-boxplot-download-xls"
                                >
                                    Download em XLSX
                                </Dropdown.Item>
                            </Dropdown>
                        </FlexboxGrid.Item>
                    )}
                </FlexboxGrid>
                {expand ? (
                    <>
                        <FlexboxGrid>
                            <FlexboxGrid.Item
                                componentClass={Col}
                                xsHidden
                                smHidden
                                className="select-visualization-section mg-right-28"
                            >
                                <span>Visualizar por: </span>
                                <Button
                                    className="select-visualize-section__button"
                                    appearance={
                                        visualize === 'chart'
                                            ? 'primary'
                                            : 'subtle'
                                    }
                                    onClick={() => this.changeVisualize('chart')}
                                >
                                    <svg
                                        width="18"
                                        height="6"
                                        viewBox="0 0 18 6"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <rect
                                            x="4.76471"
                                            width="8.47059"
                                            height="6"
                                        />
                                        <path d="M13.2353 3L17 3" />
                                        <path d="M4.76471 3L1 3" />
                                        <path d="M17 1L17 5" />
                                        <path d="M1 5L1 1" />
                                    </svg>
                                </Button>
                                <Button
                                    className="select-visualize-section__button"
                                    appearance={
                                        visualize === 'table'
                                            ? 'primary'
                                            : 'subtle'
                                    }
                                    onClick={() => this.changeVisualize('table')}
                                >
                                    <FontAwesomeIcon icon={faTh} />
                                </Button>
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item
                                componentClass={Col}
                                xs={24}
                                sm={24}
                                lgHidden
                                mdHidden
                                className="select-visualization-section"
                            >
                                <IconButton
                                    className="select-visualization-section__dropdown"
                                    icon={<Icon icon="angle-down" />}
                                    onClick={this.handleOpenDialogGroupBy}
                                    placement="right"
                                >
                                    Agrupar por:
                                    {' '}
                                    <span>
                                        {typeVisualization === 'produto'
                                            ? 'Produto'
                                            : typeVisualization === 'rede'
                                                ? 'Rede'
                                                : typeVisualization
                                                    === 'produtoRede'
                                                    ? 'Produto e rede'
                                                    : null}
                                    </span>
                                </IconButton>
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item
                                componentClass={Col}
                                lg={5}
                                md={5}
                                xsHidden
                                smHidden
                                className="select-visualization-section"
                            >
                                <span>Agrupar por: </span>
                                <Dropdown title={visualizationNameType}>
                                    {groupByOptions.map((groupBy) => (
                                        <Dropdown.Item
                                            className="select-visualization-section__button"
                                            onClick={() => this.changeVisualization(
                                                groupBy.key,
                                                groupBy.label,
                                            )}
                                        >
                                            {groupBy.label}
                                        </Dropdown.Item>
                                    ))}
                                </Dropdown>
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item
                                componentClass={Col}
                                xs={24}
                                sm={24}
                                lgHidden
                                mdHidden
                                className="select-visualization-section"
                            >
                                <IconButton
                                    className="select-visualization-section__dropdown"
                                    icon={<Icon icon="angle-down" />}
                                    onClick={this.handleOpenDialogVisualize}
                                    placement="right"
                                >
                                    Visualizar por:
                                    {' '}
                                    <span>
                                        {visualize === 'chart'
                                            ? 'Gráfico'
                                            : visualize === 'table'
                                                ? 'Tabela'
                                                : null}
                                    </span>
                                </IconButton>
                            </FlexboxGrid.Item>
                        </FlexboxGrid>

                        <FlexboxGrid>
                            {
                                hasPermition
                                    ? (
                                        <FlexboxGrid.Item
                                            componentClass={Col}
                                            lg={24}
                                            md={24}
                                            sm={24}
                                            xs={24}
                                            id="chart-area"
                                            className="chart-area"
                                        >
                                            {data?.content?.length ? (
                                                visualize === 'chart' ? (
                                                    <HighchartsReact
                                                        ref={(ref) => {
                                                            this.chart = ref;
                                                        }}
                                                        highcharts={Highcharts}
                                                        options={this.renderChart()}
                                                    />
                                                ) : (
                                                    <Table
                                                        className="second-table"
                                                        height={
                                                            data.content.length <= 3
                                                                ? 200
                                                                : data.content.length
                                                                    > 3
                                                                    && data.content.length
                                                                    <= 5
                                                                    ? 300
                                                                    : data.content.length
                                                                        >= 6
                                                                        && data.content.length
                                                                        <= 7
                                                                        ? 400
                                                                        : 520
                                                        }
                                                        data={data.content}
                                                        sortColumn={sortColumn}
                                                        sortType={sortType}
                                                        onSortColumn={
                                                            this.handleSortColumn
                                                        }
                                                    >
                                                        {typeVisualization
                                                            === 'semAgrupamento' ? (
                                                            <Column
                                                                fixed={
                                                                    !(
                                                                        window.innerWidth
                                                                        <= 992
                                                                    )
                                                                }
                                                                width={140}
                                                            >
                                                                <HeaderCell />
                                                                <Cell>Consolidado</Cell>
                                                            </Column>
                                                        ) : null}
                                                        {typeVisualization
                                                            !== 'semAgrupamento'
                                                            && typeVisualization !== 'rede'
                                                            && typeVisualization
                                                            !== 'fabricante'
                                                            && typeVisualization !== 'uf'
                                                            && typeVisualization
                                                            !== 'regiao'
                                                            && typeVisualization
                                                            !== 'fabricanteUf'
                                                            && typeVisualization
                                                            !== 'fabricanteRegiao' ? (
                                                            <Column
                                                                fixed={
                                                                    !(
                                                                        window.innerWidth
                                                                        <= 992
                                                                    )
                                                                }
                                                                sortable
                                                                width={140}
                                                            >
                                                                <HeaderCell title="Identificador">
                                                                    Identificador
                                                                </HeaderCell>
                                                                <Cell dataKey="GTIN">
                                                                    {(rowData) => (
                                                                        <span
                                                                            title={
                                                                                rowData.gtin
                                                                            }
                                                                        >
                                                                            {
                                                                                rowData.gtin
                                                                            }
                                                                        </span>
                                                                    )}
                                                                </Cell>
                                                            </Column>
                                                        ) : null}
                                                        {typeVisualization
                                                            !== 'semAgrupamento'
                                                            && typeVisualization !== 'rede'
                                                            && typeVisualization
                                                            !== 'fabricante'
                                                            && typeVisualization !== 'uf'
                                                            && typeVisualization
                                                            !== 'regiao'
                                                            && typeVisualization
                                                            !== 'fabricanteUf'
                                                            && typeVisualization
                                                            !== 'fabricanteRegiao' ? (
                                                            <Column
                                                                fixed={
                                                                    !(
                                                                        window.innerWidth
                                                                        <= 992
                                                                    )
                                                                }
                                                                sortable
                                                                width={230}
                                                            >
                                                                <HeaderCell>
                                                                    Descrição
                                                                </HeaderCell>
                                                                <Cell dataKey="DESCRICAO">
                                                                    {(rowData) => (
                                                                        <span
                                                                            title={
                                                                                rowData.descricao
                                                                            }
                                                                        >
                                                                            {
                                                                                rowData.descricao
                                                                            }
                                                                        </span>
                                                                    )}
                                                                </Cell>
                                                            </Column>
                                                        ) : null}
                                                        {typeVisualization === 'rede'
                                                            || typeVisualization
                                                            === 'produtoRede' ? (
                                                            <Column
                                                                fixed={
                                                                    !(
                                                                        window.innerWidth
                                                                        <= 992
                                                                    )
                                                                }
                                                                sortable
                                                                width={200}
                                                            >
                                                                <HeaderCell>
                                                                    Rede
                                                                </HeaderCell>
                                                                <Cell dataKey="REDE">
                                                                    {(rowData) => (
                                                                        <span
                                                                            title={
                                                                                rowData.rede
                                                                            }
                                                                        >
                                                                            {
                                                                                rowData.rede
                                                                            }
                                                                        </span>
                                                                    )}
                                                                </Cell>
                                                            </Column>
                                                        ) : null}
                                                        {typeVisualization === 'uf'
                                                            || typeVisualization
                                                            === 'produtoUf'
                                                            || typeVisualization
                                                            === 'fabricanteUf' ? (
                                                            <Column
                                                                fixed={
                                                                    !(
                                                                        window.innerWidth
                                                                        <= 992
                                                                    )
                                                                }
                                                                sortable
                                                                width={120}
                                                            >
                                                                <HeaderCell>
                                                                    UF
                                                                </HeaderCell>
                                                                <Cell dataKey="UF">
                                                                    {(rowData) => (
                                                                        <span
                                                                            title={
                                                                                rowData.uf
                                                                            }
                                                                        >
                                                                            {rowData.uf}
                                                                        </span>
                                                                    )}
                                                                </Cell>
                                                            </Column>
                                                        ) : null}
                                                        {typeVisualization
                                                            === 'regiao'
                                                            || typeVisualization
                                                            === 'produtoRegiao'
                                                            || typeVisualization
                                                            === 'fabricanteRegiao' ? (
                                                            <Column
                                                                fixed={
                                                                    !(
                                                                        window.innerWidth
                                                                        <= 992
                                                                    )
                                                                }
                                                                sortable
                                                                width={120}
                                                            >
                                                                <HeaderCell>
                                                                    Região
                                                                </HeaderCell>
                                                                <Cell dataKey="regiao">
                                                                    {(rowData) => (
                                                                        <span
                                                                            title={
                                                                                rowData.regiao
                                                                            }
                                                                        >
                                                                            {
                                                                                rowData.regiao
                                                                            }
                                                                        </span>
                                                                    )}
                                                                </Cell>
                                                            </Column>
                                                        ) : null}
                                                        {typeVisualization
                                                            === 'fabricante'
                                                            || typeVisualization
                                                            === 'fabricanteUf'
                                                            || typeVisualization
                                                            === 'fabricanteRegiao' ? (
                                                            <Column
                                                                fixed={
                                                                    !(
                                                                        window.innerWidth
                                                                        <= 992
                                                                    )
                                                                }
                                                                sortable
                                                                width={230}
                                                            >
                                                                <HeaderCell>
                                                                    Fabricante
                                                                </HeaderCell>
                                                                <Cell dataKey="FABRICANTE">
                                                                    {(rowData) => (
                                                                        <span
                                                                            title={
                                                                                rowData.fabricante
                                                                            }
                                                                        >
                                                                            {rowData.fabricante
                                                                                || 'SEM INFORMAÇÃO DE FABRICANTE'}
                                                                        </span>
                                                                    )}
                                                                </Cell>
                                                            </Column>
                                                        ) : null}
                                                        <Column sortable width={150}>
                                                            <HeaderCell>
                                                                Preço mínimo
                                                            </HeaderCell>
                                                            <Cell dataKey="PRECO_MINIMO">
                                                                {(rowData) => (
                                                                    <span
                                                                        title={formatNumberWithComma(rowData.preco_minimo)}
                                                                    >
                                                                        {formatNumberWithComma(rowData.preco_minimo)}
                                                                    </span>
                                                                )}
                                                            </Cell>
                                                        </Column>
                                                        <Column sortable width={120}>
                                                            <HeaderCell>
                                                                Preço médio
                                                            </HeaderCell>
                                                            <Cell dataKey="PRECO_MEDIO">
                                                                {(rowData) => (
                                                                    <span
                                                                        title={formatNumberWithComma(rowData.preco_medio)}
                                                                    >
                                                                        {formatNumberWithComma(rowData.preco_medio)}
                                                                    </span>
                                                                )}
                                                            </Cell>
                                                        </Column>
                                                        <Column sortable width={120}>
                                                            <HeaderCell>
                                                                Mediana
                                                            </HeaderCell>
                                                            <Cell dataKey="MEDIANA">
                                                                {(rowData) => (
                                                                    <span
                                                                        title={formatNumberWithComma(rowData.preco_mediano)}
                                                                    >
                                                                        {formatNumberWithComma(rowData.preco_mediano)}
                                                                    </span>
                                                                )}
                                                            </Cell>
                                                        </Column>
                                                        <Column sortable width={155}>
                                                            <HeaderCell>
                                                                Preço mais frequente
                                                            </HeaderCell>
                                                            <Cell dataKey="PRECO_MODA">
                                                                {(rowData) => (
                                                                    <span
                                                                        title={formatNumberWithComma(rowData.preco_moda)}
                                                                    >
                                                                        {formatNumberWithComma(rowData.preco_moda)}
                                                                    </span>
                                                                )}
                                                            </Cell>
                                                        </Column>
                                                        <Column sortable width={120}>
                                                            <HeaderCell>
                                                                Preço máximo
                                                            </HeaderCell>
                                                            <Cell dataKey="PRECO_MAXIMO">
                                                                {(rowData) => (
                                                                    <span
                                                                        title={formatNumberWithComma(rowData.preco_maximo)}
                                                                    >
                                                                        {formatNumberWithComma(rowData.preco_maximo)}
                                                                    </span>
                                                                )}
                                                            </Cell>
                                                        </Column>
                                                        <Column sortable width={130}>
                                                            <HeaderCell>
                                                                Número de lojas
                                                            </HeaderCell>
                                                            <Cell dataKey="CNPJS_DISTINTOS">
                                                                {(rowData) => (
                                                                    <span
                                                                        title={
                                                                            rowData.cnpjs_distintos
                                                                        }
                                                                    >
                                                                        {
                                                                            rowData.cnpjs_distintos
                                                                        }
                                                                    </span>
                                                                )}
                                                            </Cell>
                                                        </Column>
                                                        <Column sortable width={130}>
                                                            <HeaderCell>
                                                                Número de redes
                                                            </HeaderCell>
                                                            <Cell dataKey="REDES_DISTINTAS">
                                                                {(rowData) => (
                                                                    <span
                                                                        title={
                                                                            rowData.redes_distintas
                                                                        }
                                                                    >
                                                                        {
                                                                            rowData.redes_distintas
                                                                        }
                                                                    </span>
                                                                )}
                                                            </Cell>
                                                        </Column>
                                                    </Table>
                                                )
                                            ) : (
                                                <EmptyState
                                                    title="Ops! Nenhum resultado encontrado"
                                                    message="Sua busca não retornou nenhuma informação. Altere os filtros e tente novamente."
                                                    image={noDataImage}
                                                />
                                            )}
                                            {data?.content?.length ? (
                                                <Pagination
                                                    showInfo={false}
                                                    showLengthMenu={false}
                                                    activePage={
                                                        data ? data.number + 1 : null
                                                    }
                                                    displayLength={10}
                                                    total={
                                                        data ? data.totalElements : null
                                                    }
                                                    onChangePage={this.handleChangePage}
                                                />
                                            ) : null}
                                        </FlexboxGrid.Item>
                                    ) : (
                                        <FlexboxGrid.Item
                                            className="no-access"
                                            componentClass={Col}
                                            lg={24}
                                            md={24}
                                            sm={24}
                                            xs={24}
                                        >
                                            <div className="no-access__main">
                                                <div>
                                                    <img
                                                        alt="Cadeado"
                                                        className="no-access__icon"
                                                        src={lockIcon}
                                                    />
                                                </div>
                                                <div className="no-access__message">
                                                    <p>
                                                        {(usuario?.servicoPanel
                                                            ?.pacotePainel?.name
                                                            === 'FREE') ? (
                                                            <span>
                                                                Este conteúdo pode ser
                                                                acessado somente por
                                                                assinantes.
                                                            </span>
                                                        ) : (
                                                            <span>
                                                                Este conteúdo só pode
                                                                ser acessado por
                                                                assinantes do plano
                                                                {' '}
                                                                <b>Flex</b>
                                                                {' '}
                                                                (para
                                                                produtos escolhidos) ou
                                                                {' '}
                                                                <b>Pro</b>
                                                            </span>
                                                        )}
                                                    </p>
                                                </div>
                                                <div>
                                                    <Button
                                                        appearance="primary"
                                                        className="upsell"
                                                        onClick={() => this.setShowModalConfirm()}
                                                    >
                                                        {usuario?.servicoPanel
                                                            ?.pacotePainel?.name
                                                            === 'FREE' ? (
                                                            <span>
                                                                Quero ser assinante
                                                            </span>
                                                        ) : (
                                                            <span>
                                                                Entrar em contato
                                                            </span>
                                                        )}
                                                    </Button>
                                                </div>
                                            </div>
                                        </FlexboxGrid.Item>
                                    )
                            }
                        </FlexboxGrid>
                    </>
                ) : null}
                <ModalConfirmation
                    title="Informações para alteração do plano"
                    message="Caso deseje saber mais informações para evoluir o seu plano, clique em “Confirmar”"
                    show={showCTA}
                    buttonConfirm="Confirmar"
                    buttonCancel="Cancelar"
                    btnConfirmClass="upsell"
                    onConfirm={this.sendCTA}
                    onCancel={() => {
                        this.setState({
                            showCTA: false,
                        });
                    }}
                />
                <ModalNotification
                    show={showModalDownload}
                    onHide={() => this.setState({
                        showModalDownload: false,
                    })}
                    title="Download"
                    message="O arquivo que você deseja baixar contém mais de cinquenta mil linhas e
                    pode demorar um pouco para ficar pronto. Te enviaremos por e-mail assim que estiver tudo certo!"
                    close={() => {
                        this.setState({
                            showModalDownload: false,
                        });
                    }}
                />
                <MobileSelectOptionComponent
                    open={openDialogGroupBy}
                    handleClose={() => this.setState({ openDialogGroupBy: false })}
                    options={optionsGroupBy}
                    optionSelected={typeVisualization}
                    handleSelect={this.changeVisualization}
                />
                <MobileSelectOptionComponent
                    open={openDialogVisualize}
                    handleClose={() => this.setState({ openDialogVisualize: false })}
                    options={optionsVisualize}
                    optionSelected={visualize}
                    handleSelect={this.changeVisualize}
                />
            </Content>
        );
    }
}

const mapStateToProps = (store) => ({
    data: store.painelGeralDataReducer.boxplot.data,
    sortColumn: store.painelGeralDataReducer.boxplot.sortColumn,
    produto: store.painelGeralDataReducer.produto,
    servicoPainel: store.usuarioDataReducer.servicoPainel,
    loadingBoxplot: store.painelGeralDataReducer.loadingBoxplot,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
    {
        getBoxPlotData,
        setVisualizationTypeBoxplot,
        setSortColumnBoxplot,
    },
    dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(BoxPlotChart);
