import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsExport from 'highcharts/modules/export-data';
import HighchartsExporting from 'highcharts/modules/exporting';
import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { selectorCalculadoraPrevisaoDemanda } from '../../../../reducers/calculadoraPrevisaoDemanda';

import * as utils from '../../utils';
import { RevenueDemandChartResponse, InputCalculos } from '../../types';

HighchartsExporting(Highcharts);
HighchartsExport(Highcharts);

export const HighchartsOptions = {
    lang: {
        decimalPoint: ',',
        thousandsSep: '.',
        exitFullscreen: 'Sair da tela cheia',
    },
};

Highcharts.setOptions(HighchartsOptions);

const yAxis = (name: string, item: RevenueDemandChartResponse) => {
    const y = {
        demanda: item.demand,
        receita: parseInt(item.revenue.toFixed(0)),
        preco: item.price,
    };

    return y[name];
};

const setDataChart = (name: string, data: any) => {
    const inputPrices = data.revenueDemandChartData?.filter((item) => item.input_price === true);

    const basePrice = data.resultScenarioData?.outputs.base_price_scenario.price;
    const newPrice = data.resultScenarioData?.outputs.new_price_scenario.price;
    const pAtual = inputPrices?.filter((item) => item.price === basePrice);
    const pNovo = inputPrices?.filter((item) => item.price === newPrice);

    const newData = data.revenueDemandChartData?.map((item) => ({
        name,
        x: item.price,
        y: yAxis(name, item),
        demand: item.demand,
        revenue: item.revenue,
        novoPreco: newPrice,
        precoAtual: basePrice,
        novoPrecoReceita: pNovo[0]?.revenue,
        novoPrecoDemanda: pNovo[0]?.demand,
        precoAtualReceita: pAtual[0]?.revenue,
        precoAtualDemanda: pAtual[0]?.demand,
        marker: {
            enabled: item.input_price,
            states: {
                hover: {
                    enabled: item.input_price,
                },
            },
        },
    }));

    return newData;
};

const setPlotLines = (data: RevenueDemandChartResponse[]) => {
    const filterData = data.filter((item) => item.input_price === true).map((item) => item.price);
    const minor = Math.min(...filterData);
    const major = Math.max(...filterData);

    return { minor, major };
};

export const ElasticSimpleChart = () => {
    const elasticSimpleChartRef = useRef(null);

    const { resultScenario } = useSelector(selectorCalculadoraPrevisaoDemanda);

    const defaultChartOption = {
        chart: {
            type: 'spline',
        },
        title: {
            text: '',
        },
        exporting: {
            enabled: false,
        },
        tooltip: {
            useHTML: true,
            pointFormatter() {
                const name = this.name === 'receita' ? 'Novo preço:' : 'Preço Atual:';
                const preco = this.name === 'receita' ? this.novoPreco : this.precoAtual;
                const precoAtualDemanda = Math.floor(this.precoAtualDemanda);
                const novoPrecoDemanda = Math.floor(this.novoPrecoDemanda);

                return `
                    <p>
                        Preço Atual: <b>R$ ${utils.formatLocaleNumber(this.precoAtual, 2)}</b>
                    </p>
                    <br/>
                    <div>
                        <span style="color:#E663C9">
                            <b>●</b>
                        </span>
                        <span>
                            Receita: <b>
                                R$ ${this.precoAtualReceita
                                    ? utils.formatLocaleNumber(this.precoAtualReceita, 2)
                                    : 0}
                            </b>
                        </span>
                    </div>
                    <div>
                        <span style="color:#0D66D0">
                            <b>■</b>
                        </span>
                        <span>
                            Demanda: <b>${precoAtualDemanda ? utils.formatLocaleNumber(precoAtualDemanda, 0) : 0} un</b>
                        </span>
                    </div>
                    <br/>
                    <p>
                        Novo preço: <b>R$ ${utils.formatLocaleNumber(this.novoPreco, 2)}</b>
                    </p>
                    <br/>
                    <div>
                        <span style="color:#E663C9">
                            <b>●</b>
                        </span>
                        <span>
                            Receita: <b>R$ ${this.novoPrecoReceita ? utils.formatLocaleNumber(this.novoPrecoReceita, 2) : 0}</b>
                        </span>
                    </div>
                    <div>
                        <span style="color:#0D66D0">
                            <b>■</b>
                        </span>
                        <span>
                            Demanda: <b>${novoPrecoDemanda ? utils.formatLocaleNumber(novoPrecoDemanda, 0) : 0} un</b>
                        </span>
                    </div>
                `;
            },
            headerFormat: '',
        },
        legend: {
            symbolWidth: 50,
        },
        yAxis: [
            {
                title: {
                    text: 'RECEITA',
                },
                accessibility: {
                    description: 'RECEITA',
                },
                opposite: true,
                labels: {
                    formatter() {
                        const price = this.axis.defaultLabelFormatter.call(this);
                        return `R$ ${price.replace(',', '.')}`;
                    },
                },
            },
            {
                title: {
                    text: 'IMPACTO DEMANDA',
                },
                accessibility: {
                    description: 'IMPACTO DEMANDA',
                },
            },

        ],

        xAxis: {
            plotBands: [{
                from: setPlotLines(resultScenario?.revenueDemandChartData).minor,
                to: setPlotLines(resultScenario?.revenueDemandChartData).major,
                color: '#F5F9FE',
                // label: {
                //     text: 'Summer',
                //     align: 'left',
                //     x: 10,
                // },
            }],
            plotLines: [{
                value: setPlotLines(resultScenario?.revenueDemandChartData).minor,
                color: '#BCBCBC',
                width: 1,
                zIndex: 1.5,
                dashStyle: 'ShortDashDot',
            }, {
                value: setPlotLines(resultScenario?.revenueDemandChartData).major,
                color: '#BCBCBC',
                width: 1,
                zIndex: 1.5,
                dashStyle: 'ShortDashDot',
            }],
            title: {
                text: 'PREÇO',
            },
            accessibility: {
                description: 'PREÇO',
            },
            labels: {
                formatter() {
                    let price = this.axis.defaultLabelFormatter.call(this);
                    if (price.includes('.')) {
                        price = price.replace('.', '');
                    }

                    if (price.includes(',')) {
                        price = price.replace(',', '.');
                    }
                    return `R$ ${Number(price).toFixed(2).replace('.', ',')}`;
                },
            },
        },
        plotOptions: {},
        series: [
            {
                name: 'Demanda',
                data: setDataChart('demanda', resultScenario),
                color: '#0D66D0',
                marker: {
                    symbol: 'square',
                },
                yAxis: 1,
            },
            {
                name: 'Receita',
                data: setDataChart('receita', resultScenario),
                color: '#E663C9',
            },
        ],
    };

    return (
        <HighchartsReact
            ref={elasticSimpleChartRef}
            highcharts={Highcharts}
            options={defaultChartOption}
        />
    );
};
