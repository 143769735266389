import qs from 'qs';
import { trackPromise } from 'react-promise-tracker';

import api from '../../../utils/API';

export const getCampanhas = (params) => trackPromise(
        api({
            url: `${process.env.REACT_APP_IPA_URL}/campaign/campaigns`,
            method: 'GET',
            expectToken: true,
            params: {
                clientId: JSON.parse(localStorage.getItem('cliente'))?.codigo,
                size: params?.size || 20,
                page: params?.page || 0,
                ...(params?.name && { name: params.name }),
                ...(params?.status && { status: params.status }),
                ...(params?.productIds && { productIds: params.productIds }),
                ...(params?.storeIds && { storeIds: params.storeIds }),
                ...(params?.authors && { authors: params.authors }),
                ...(params?.clusters && {
                    clusters: params.clusters,
                }),
                ...(params.sort && { sort: params.sort }),
            },
            paramsSerializer(params) {
                return qs.stringify(params, { arrayFormat: 'repeat' });
            },
        }).then((resp) => resp.data),
    );

export const deleteCampanha = (id) => trackPromise(
        api({
            url: `${process.env.REACT_APP_IPA_URL}/campaign/campaigns/${id}`,
            method: 'DELETE',
            expectToken: true,
            params: {
                clientId: JSON.parse(localStorage.getItem('cliente'))?.codigo,
            },
        }).then((resp) => resp),
    );

export const getLojas = (params) => trackPromise(
        api({
            url: `${process.env.REACT_APP_IPA_URL}/campaign/campaigns/stores/all`,
            method: 'GET',
            expectToken: true,
            params: {
                clientId: JSON.parse(localStorage.getItem('cliente'))?.codigo,
                size: 200,
                page: 0,
                ...(params?.storeName && { storeName: params.storeName }),
            },
            paramsSerializer(params) {
                return qs.stringify(params, { arrayFormat: 'repeat' });
            },
        }).then((resp) => resp.data),
    );

export const getClusters = () => api({
        url: `${process.env.REACT_APP_IPA_URL}/setup/stores/clusters`,
        method: 'GET',
        expectToken: true,
        params: {
            clientId: JSON.parse(localStorage.getItem('cliente'))?.codigo,
        },
        paramsSerializer(params) {
            return qs.stringify(params, { arrayFormat: 'repeat' });
        },
    }).then((resp) => resp.data);

export const getAuthors = (params) => api({
        url: `${process.env.REACT_APP_IPA_URL}/campaign/campaigns/authors`,
        method: 'GET',
        expectToken: true,
        params: {
            clientId: JSON.parse(localStorage.getItem('cliente'))?.codigo,
            size: params.size || 20,
            page: params.page || 0,
        },
        paramsSerializer(params) {
            return qs.stringify(params, { arrayFormat: 'repeat' });
        },
    }).then((resp) => resp.data);

export const getStatus = (params) => api({
        url: `${process.env.REACT_APP_IPA_URL}/campaign/campaigns/status`,
        method: 'GET',
        expectToken: true,
        params: {
            size: params?.size || 20,
            page: params?.page || 0,
        },
    }).then((resp) => resp.data);
