import React, { createContext, useContext, useMemo, useState } from 'react';
import {
    DATAKEYS,
    DATAPOINTS_RESPONSE_PROPS,
    DATAPOINT_PROPS,
} from '../@types';
import { PAGE_PROPS } from '../components/template/GerenciadorTable/types';
import { usePagination } from '../hooks';
import { parseValue } from '../lib';

export type DataProps = DATAPOINTS_RESPONSE_PROPS & {
    competitiveness: number | null;
};

export type PaginationProps = Record<
    'totalElements' | 'activePage' | 'displayLength',
    number
>;

export type GerenciadorPrecosContextProps = {
    data: DataProps;
    setData: React.Dispatch<React.SetStateAction<DataProps>>;
    pagination: PaginationProps;
    setPagination: React.Dispatch<React.SetStateAction<PaginationProps>>;
    handleUpdateField: (
        value: string,
        index: number,
        dataKey: DATAKEYS,
    ) => void;
    findDatapoint: (id: string) => DATAPOINT_PROPS | undefined;
    handleChangePage: (page: number) => void;
    handleChangeLength: (size: number) => void;
    sort: Record<string, string>;
    setSort: React.Dispatch<React.SetStateAction<Record<string, string>>>;
    handleSortColumn: (sortColumn: string, sortType: string) => void;
};

export const GerenciadorPrecosContext =
    createContext<GerenciadorPrecosContextProps>(
        {} as GerenciadorPrecosContextProps,
    );

export const GerenciadorPrecosProvider = ({
    children,
}: React.HtmlHTMLAttributes<HTMLElement> & Pick<PAGE_PROPS, 'history'>) => {
    const [data, setData] = useState<DataProps>({
        content: [],
        totalElements: 0,
        number: 0,
        size: 20,
        competitiveness: null,
    });
    const [sort, setSort] = useState<Record<string, string>>({
        type: 'precoMedio',
        orderBy: 'desc',
    });
    const handleSortColumn = (sortColumn: string, sortType: string) => {
        setSort({
            type: sortColumn,
            orderBy: sortType,
        });
    };

    const findDatapoint = (id: string) =>
        data.content.find((datapoint) => datapoint.idProductToBePriced === id);

    function handleUpdateField(
        value: string,
        index: number,
        dataKey: DATAKEYS,
    ) {
        const parsedValue = parseValue(value);
        if (parsedValue === 0) return;
        const dataContent = data?.content;

        // sets the newPrice
        // && newCompetitivenessPrice
        // as true
        dataContent[index] = {
            ...dataContent[index],
            newCompetitivenessPrice: true,
            newPrice: true,
        };
        setData((oldValue) => ({
            ...oldValue,
            dataContent,
        }));

        // handles the math to calculate
        // each column field
        switch (dataKey) {
            case 'price':
                dataContent[index] = {
                    ...dataContent[index],
                    price: parsedValue,
                    initialCompetitiveness:
                        (parsedValue / dataContent[index].competitorPrice) *
                        100,
                };
                setData((oldState) => ({ ...oldState, content: dataContent }));
                break;
            case 'initialCompetitiveness':
                dataContent[index] = {
                    ...dataContent[index],
                    price:
                        (dataContent[index].competitorPrice * parsedValue) /
                        100,
                    initialCompetitiveness: parsedValue,
                };
                setData((oldState) => ({ ...oldState, content: dataContent }));
                break;
            default:
                dataContent[index] = {
                    ...dataContent[index],
                    [dataKey]: value,
                };
                setData((oldState) => ({ ...oldState, content: dataContent }));
                break;
        }
    }

    const { pagination, setPagination, handleChangePage, handleChangeLength } =
        usePagination(data);

    const value = useMemo(
        () => ({
            data,
            setData,
            handleUpdateField,
            findDatapoint,
            pagination,
            setPagination,
            handleChangePage,
            handleChangeLength,
            sort,
            setSort,
            handleSortColumn,
        }),
        [data, setData, pagination, setPagination, sort, setSort],
    );

    return (
        <GerenciadorPrecosContext.Provider value={value}>
            {children}
        </GerenciadorPrecosContext.Provider>
    );
};

export const useGerenciadorPrecos = () => useContext(GerenciadorPrecosContext);
