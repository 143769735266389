import React from 'react';
import { Modal, Button, FlexboxGrid } from 'rsuite';

const ModalNoAccessInfoPanel = ({ show, close, confirmSetup }) => (
    <Modal className="modal-no-access-infopanel" size="sm" show={show} onHide={close}>
        <Modal.Header>
            <Modal.Title>Acesso restrito</Modal.Title>
        </Modal.Header>
        <Modal.Body className="description">
            <FlexboxGrid>
                <p>
                    Você ainda não tem acesso ao InfoPanel. Gostaria de experimentar a versão
                    {' '}
                    <b>Free</b>
                    ?
                </p>
            </FlexboxGrid>
        </Modal.Body>
        <Modal.Footer>
            <Button block onClick={confirmSetup} appearance="primary">
                Cadastrar
            </Button>
        </Modal.Footer>
    </Modal>
);

export default ModalNoAccessInfoPanel;
