import classNames from 'classnames';
import React from 'react';
import { Icon as RSIcon } from 'rsuite';
import { FilterSection, TableHeading } from '../../../../../components';
import { parseStringToPlural } from '../../../../../utils';
import History from '../../../../../utils/History';
import { Layout, LinkButton } from '../../../Lite';
import { DataUltimaIntegracao, Icon } from '../../../RevisaoPrecos/Components';
import { SalvarNegociacaoModal } from '../../components';
import { useNegociacaoFornecedor } from './NegociacaoFornecedor.hooks';
import styles from './NegociacaoFornecedor.module.scss';
import * as T from './NegociacaoFornecedor.types';
import { AdicionarProdutoNegociacaoModal, TableFornecedor } from './components';

export const NegociacaoFornecedor = ({
    className,
    usuario,
    ...props
}: T.NegociacaoFornecedorProps) => {
    if (!usuario) return null;

    if (!usuario.tipoAdicionalContratos.IPA?.includes('MODULO_NEGOCIACAO')) {
        History.push('/ipa/gerenciador-de-precos');
        return null;
    }

    const {
        table: {
            data: { totalElements, content },
            selectedDatapoints,
            ...table
        },
        modals,
        handlers: { openModal },
        filters,
        handlers: { download },
    } = useNegociacaoFornecedor();

    return (
        <>
            <Layout
                className={classNames(
                    styles['negociacao-fornecedor'],
                    className,
                )}
                {...props}
            >
                <Layout.Header
                    className={styles['negociacao-fornecedor__header']}
                >
                    <div>
                        <Layout.Title>Negociação fornecedor</Layout.Title>
                        <DataUltimaIntegracao />
                    </div>
                    <div>
                        <Layout.Subtitle>
                            Negocie o melhor custo para o seu produto
                        </Layout.Subtitle>
                        <LinkButton
                            to="/ipa/negociacoes/salvas"
                            className="link-button"
                        >
                            <Icon width="9px" height="9px" icon="MdSave" />
                            Abrir negociações salvas
                        </LinkButton>
                    </div>
                </Layout.Header>
                <Layout.Section>
                    <FilterSection {...filters} />
                </Layout.Section>
                <TableHeading isSelected={!selectedDatapoints.isEmpty}>
                    <TableHeading.Container>
                        <p>
                            {!selectedDatapoints.isEmpty ? (
                                <strong>
                                    {`${selectedDatapoints.length} produtos
                                    ${parseStringToPlural(
                                        'selecionado',
                                        selectedDatapoints.length,
                                    )}`}
                                </strong>
                            ) : (
                                <>
                                    Foram encontrados
                                    <strong>
                                        {` ${totalElements} ${parseStringToPlural(
                                            'resultado',
                                            totalElements,
                                        )} `}
                                    </strong>
                                    no total
                                </>
                            )}
                        </p>
                        {!filters.isEmpty && selectedDatapoints.length && (
                            <LinkButton
                                onClick={() => selectedDatapoints.onSelectAll()}
                            >
                                {selectedDatapoints.data.selectedAll
                                    ? 'Limpar seleção'
                                    : 'Selecionar tudo'}
                            </LinkButton>
                        )}
                    </TableHeading.Container>
                    <TableHeading.Container>
                        <TableHeading.SecondaryButton
                            icon={<Icon icon="MdFileDownload" />}
                            onClick={download}
                            placeholder="Baixar produtos selecionados"
                            disabled={selectedDatapoints.isEmpty}
                        />
                        {!selectedDatapoints.isEmpty && (
                            <TableHeading.SecondaryButton
                                icon={<RSIcon icon="plus" />}
                                placeholder="Adicionar à negociação salva"
                                onClick={() => {
                                    openModal('adicionarProdutoNegociacao');
                                }}
                            />
                        )}
                        <TableHeading.PrimaryButton
                            onClick={() => openModal('salvarNegociacao')}
                            disabled={!!selectedDatapoints.isEmpty}
                        >
                            Salvar
                        </TableHeading.PrimaryButton>
                    </TableHeading.Container>
                </TableHeading>
                <TableFornecedor
                    {...table}
                    selectedDatapoints={selectedDatapoints}
                    data={content}
                />
            </Layout>
            <AdicionarProdutoNegociacaoModal
                {...modals.adicionarProdutoNegociacao}
            />

            <SalvarNegociacaoModal {...modals.salvarNegociacao} />
        </>
    );
};
