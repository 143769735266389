import { useCallback, useEffect, useMemo, useState } from 'react';

const INITIAL_ACTIVE_PAGE = 1;

const INITIAL_LENGTH_MENU = [
    { label: '25', value: 25 },
    { label: '50', value: 50 },
    { label: '100', value: 100 },
];

const INITIAL_DISPLAY_LENGTH = INITIAL_LENGTH_MENU[0].value;

type PaginationProps = {
    total: number;
    lengthMenu?: typeof INITIAL_LENGTH_MENU;
};

export const usePagination = ({
    total,
    lengthMenu = INITIAL_LENGTH_MENU,
}: PaginationProps) => {
    const [activePage, setActivePage] = useState(INITIAL_ACTIVE_PAGE);

    const [displayLength, setDisplayLength] = useState(INITIAL_DISPLAY_LENGTH);

    const onChangePage = useCallback((page: number) => setActivePage(page), []);

    const onChangeLength = useCallback((size: number) => {
        setDisplayLength(size);
    }, []);

    useEffect(() => {
        const lastValidIndex = Math.ceil(total / displayLength);

        const isOnInvalidLastIndex = activePage > lastValidIndex;

        if (total && isOnInvalidLastIndex) onChangePage(lastValidIndex);
    }, [total, onChangePage, activePage, displayLength]);

    const reset = useCallback(() => {
        setActivePage(INITIAL_ACTIVE_PAGE);
        setDisplayLength(INITIAL_DISPLAY_LENGTH);
    }, []);

    return useMemo(
        () => ({
            total,
            activePage,
            displayLength,
            lengthMenu,
            onChangePage,
            onChangeLength,
            reset,
        }),
        [
            total,
            lengthMenu,
            activePage,
            displayLength,
            onChangePage,
            onChangeLength,
            reset,
        ],
    );
};
