import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React, { Component, LegacyRef, forwardRef } from 'react';
// @ts-ignore
import CurrencyFormat from 'react-currency-format';
import { usePromiseTracker } from 'react-promise-tracker';
import { Badge, Container, Content, Modal, Table } from 'rsuite';
import { ModalProps } from 'rsuite/lib/Modal';
import { LoadingSpinerLocal } from '../../../../../components/LoadingSpinner';

type DataProps = {
    priceType: string;
    price: number;
} & Record<
    'liquid_cost' | 'profit' | 'expenses' | 'tax',
    {
        value: number;
        percentage: number;
    }
>;

export type ComposicaoPrecoModalProps = ModalProps & {
    priceComposition: DataProps[];
};

const { Column, HeaderCell, Cell } = Table;

export const ComposicaoPrecoModal = forwardRef(
    (
        { priceComposition, show = true, ...props }: ComposicaoPrecoModalProps,
        ref: LegacyRef<Component<ModalProps, unknown, unknown>>,
    ) => {
        const { promiseInProgress } = usePromiseTracker({
            area: 'price-composition',
        });

        const everagePriceCompositionData = (
            dataPriceComposition: DataProps[],
        ) => {
            const everagePriceComposition = dataPriceComposition?.find(
                (item) => item.priceType === 'Preço vigente',
            );
            return [
                {
                    name: 'Custo Líquido',
                    y: everagePriceComposition?.liquid_cost.percentage,
                },
                {
                    name: 'Lucro',
                    y: everagePriceComposition?.profit.percentage,
                },
                {
                    name: 'Imposto de venda',
                    y: everagePriceComposition?.tax.percentage,
                },
                {
                    name: 'Despesas',
                    y: everagePriceComposition?.expenses.percentage,
                },
            ];
        };

        const everagePriceCompositionNewPriceData = (
            dataPriceComposition: DataProps[],
        ) => {
            const everagePriceCompositionNewPrice = dataPriceComposition?.find(
                (item) => item.priceType === 'Novo preço',
            );
            return [
                {
                    name: 'Custo Líquido',
                    y: everagePriceCompositionNewPrice?.liquid_cost.percentage,
                },
                {
                    name: 'Lucro',
                    y: everagePriceCompositionNewPrice?.profit.percentage,
                },
                {
                    name: 'Imposto de venda',
                    y: everagePriceCompositionNewPrice?.tax.percentage,
                },
                {
                    name: 'Despesas',
                    y: everagePriceCompositionNewPrice?.expenses.percentage,
                },
            ];
        };

        if (promiseInProgress) {
            return (
                <LoadingSpinerLocal
                    loading={promiseInProgress}
                    size="md"
                    height="100%"
                    position="absolute"
                />
            );
        }

        return (
            <Modal
                className="modal-composicao-precos"
                show={show}
                ref={ref}
                {...props}
            >
                <Container className="info-modal__container">
                    <Modal.Header className="info-modal__header">
                        <Modal.Title className="info-modal__header__title">
                            Composição do preço
                        </Modal.Title>
                        <p className="info-modal__header__subtitle">
                            Demonstração da composição do preço vigente e novo
                            preço.
                        </p>
                    </Modal.Header>
                    <Modal.Body className="info-modal__body">
                        <Content className="info-modal__content">
                            <div className="info-modal__content__charts__container">
                                <HighchartsReact
                                    highcharts={Highcharts}
                                    options={{
                                        chart: {
                                            plotBackgroundColor: '#F5F9FE',
                                            plotBorderWidth: null,
                                            plotShadow: false,
                                            type: 'pie',
                                        },
                                        title: {
                                            text: 'Preço Vigente',
                                            align: 'left',
                                            verticalAlign: 'middle',
                                            y: -154,
                                            x: 16,
                                            style: {
                                                fontFamily: 'Open Sans',
                                                fontSize: '14px',
                                                fontStyle: 'normal',
                                                fontWeight: '600',
                                            },
                                        },
                                        tooltip: {
                                            pointFormat:
                                                '{series.priceType} <b>{point.percentage:.2f}%</b>',
                                        },
                                        accessibility: {
                                            point: {
                                                valueSuffix: '%',
                                            },
                                        },
                                        exporting: {
                                            enabled: false,
                                        },
                                        plotOptions: {
                                            pie: {
                                                allowPointSelect: true,
                                                cursor: 'pointer',
                                                dataLabels: {
                                                    enabled: true,
                                                    format: '<b>{point.priceType}</b> {point.percentage:.2f} %',
                                                },
                                                showInLegend: true,
                                            },
                                        },
                                        series: [
                                            {
                                                priceType: 'Brands',
                                                colorByPoint: true,
                                                data: everagePriceCompositionData(
                                                    priceComposition,
                                                ),
                                                colors: [
                                                    '#0D66D0',
                                                    '#5AE47A',
                                                    '#E663C9',
                                                    '#FFA164',
                                                ],
                                            },
                                        ],
                                    }}
                                />
                                <HighchartsReact
                                    highcharts={Highcharts}
                                    options={{
                                        chart: {
                                            plotBackgroundColor: '#F5F9FE',
                                            plotBorderWidth: null,
                                            plotShadow: false,
                                            type: 'pie',
                                        },
                                        title: {
                                            text: 'Novo Preço',
                                            align: 'left',
                                            verticalAlign: 'middle',
                                            y: -154,
                                            x: 16,
                                            style: {
                                                fontFamily: 'Open Sans',
                                                fontSize: '14px',
                                                fontStyle: 'normal',
                                                fontWeight: '600',
                                            },
                                        },
                                        tooltip: {
                                            pointFormat:
                                                '{series.priceType} <b>{point.percentage:.2f}%</b>',
                                        },
                                        accessibility: {
                                            point: {
                                                valueSuffix: '%',
                                            },
                                        },
                                        exporting: {
                                            enabled: false,
                                        },
                                        plotOptions: {
                                            pie: {
                                                allowPointSelect: true,
                                                cursor: 'pointer',
                                                dataLabels: {
                                                    enabled: true,
                                                    format: '<b>{point.priceType}</b> {point.percentage:.2f} %',
                                                },
                                                showInLegend: true,
                                            },
                                        },
                                        series: [
                                            {
                                                priceType: 'Brands',
                                                colorByPoint: true,
                                                data: everagePriceCompositionNewPriceData(
                                                    priceComposition,
                                                ),
                                                colors: [
                                                    '#0D66D0',
                                                    '#5AE47A',
                                                    '#E663C9',
                                                    '#FFA164',
                                                ],
                                            },
                                        ],
                                    }}
                                />
                            </div>
                            <div className="info-modal__content__table__container">
                                <Table
                                    id="table-composition-price"
                                    data={priceComposition}
                                >
                                    <Column flexGrow={2}>
                                        <HeaderCell className="info-modal__content__table__title-columns">
                                            Tipo de preço
                                        </HeaderCell>
                                        <Cell className="info-modal__content__table__type">
                                            {(rowData) => rowData?.priceType}
                                        </Cell>
                                    </Column>

                                    <Column flexGrow={2}>
                                        <HeaderCell className="info-modal__content__table__title-columns">
                                            Preço
                                        </HeaderCell>
                                        <Cell>
                                            {(rowData) => (
                                                <span className="info-modal__content__table__value">
                                                    <CurrencyFormat
                                                        fixedDecimalScale
                                                        decimalScale={2}
                                                        value={rowData?.price}
                                                        displayType="text"
                                                        prefix="R$ "
                                                        decimalSeparator=","
                                                        thousandSeparator="."
                                                    />
                                                </span>
                                            )}
                                        </Cell>
                                    </Column>
                                    <Column flexGrow={2}>
                                        <HeaderCell className="info-modal__content__table__title-columns">
                                            Custo líquido
                                        </HeaderCell>
                                        <Cell>
                                            {(rowData) => (
                                                <div className="info-modal__content__table__value">
                                                    <span>
                                                        <CurrencyFormat
                                                            fixedDecimalScale
                                                            decimalScale={2}
                                                            value={
                                                                rowData
                                                                    ?.liquid_cost
                                                                    ?.value
                                                            }
                                                            displayType="text"
                                                            prefix="R$ "
                                                            decimalSeparator=","
                                                            thousandSeparator="."
                                                        />
                                                    </span>{' '}
                                                    <Badge
                                                        className="info-modal__content__table__percentage"
                                                        content={
                                                            <CurrencyFormat
                                                                fixedDecimalScale
                                                                decimalScale={2}
                                                                value={
                                                                    rowData
                                                                        ?.liquid_cost
                                                                        ?.percentage
                                                                }
                                                                displayType="text"
                                                                suffix="%"
                                                                decimalSeparator=","
                                                            />
                                                        }
                                                    />
                                                </div>
                                            )}
                                        </Cell>
                                    </Column>
                                    <Column flexGrow={2}>
                                        <HeaderCell className="info-modal__content__table__title-columns">
                                            Lucro
                                        </HeaderCell>
                                        <Cell>
                                            {(rowData) => (
                                                <div>
                                                    <span className="info-modal__content__table__value">
                                                        <CurrencyFormat
                                                            fixedDecimalScale
                                                            decimalScale={2}
                                                            value={
                                                                rowData?.profit
                                                                    ?.value
                                                            }
                                                            displayType="text"
                                                            prefix="R$ "
                                                            decimalSeparator=","
                                                            thousandSeparator="."
                                                        />
                                                    </span>{' '}
                                                    <Badge
                                                        className="info-modal__content__table__percentage"
                                                        content={
                                                            <CurrencyFormat
                                                                fixedDecimalScale
                                                                decimalScale={2}
                                                                value={
                                                                    rowData
                                                                        ?.profit
                                                                        ?.percentage
                                                                }
                                                                displayType="text"
                                                                suffix="%"
                                                                decimalSeparator=","
                                                            />
                                                        }
                                                    />
                                                </div>
                                            )}
                                        </Cell>
                                    </Column>
                                    <Column flexGrow={2}>
                                        <HeaderCell className="info-modal__content__table__title-columns">
                                            Despesas
                                        </HeaderCell>
                                        <Cell>
                                            {(rowData) => (
                                                <div>
                                                    <span className="info-modal__content__table__value">
                                                        <CurrencyFormat
                                                            fixedDecimalScale
                                                            decimalScale={2}
                                                            value={
                                                                rowData
                                                                    ?.expenses
                                                                    ?.value
                                                            }
                                                            displayType="text"
                                                            prefix="R$ "
                                                            decimalSeparator=","
                                                            thousandSeparator="."
                                                        />
                                                    </span>{' '}
                                                    <Badge
                                                        className="info-modal__content__table__percentage"
                                                        content={
                                                            <CurrencyFormat
                                                                fixedDecimalScale
                                                                decimalScale={2}
                                                                value={
                                                                    rowData
                                                                        ?.expenses
                                                                        ?.percentage
                                                                }
                                                                displayType="text"
                                                                suffix="%"
                                                                decimalSeparator=","
                                                            />
                                                        }
                                                    />
                                                </div>
                                            )}
                                        </Cell>
                                    </Column>
                                    <Column flexGrow={2}>
                                        <HeaderCell className="info-modal__content__table__title-columns">
                                            Impostos de venda
                                        </HeaderCell>
                                        <Cell>
                                            {(rowData) => (
                                                <div>
                                                    <span className="info-modal__content__table__value">
                                                        <CurrencyFormat
                                                            fixedDecimalScale
                                                            decimalScale={2}
                                                            value={
                                                                rowData?.tax
                                                                    ?.value
                                                            }
                                                            displayType="text"
                                                            prefix="R$ "
                                                            decimalSeparator=","
                                                            thousandSeparator="."
                                                        />
                                                    </span>{' '}
                                                    <Badge
                                                        className="info-modal__content__table__percentage"
                                                        content={
                                                            <CurrencyFormat
                                                                fixedDecimalScale
                                                                decimalScale={2}
                                                                value={
                                                                    rowData?.tax
                                                                        ?.percentage
                                                                }
                                                                displayType="text"
                                                                suffix="%"
                                                                decimalSeparator=","
                                                            />
                                                        }
                                                    />
                                                </div>
                                            )}
                                        </Cell>
                                    </Column>
                                </Table>
                            </div>
                        </Content>
                    </Modal.Body>
                </Container>
            </Modal>
        );
    },
);
