import React from 'react'
import Downshift from 'downshift'
import { Row, Grid, Col } from "rsuite";


function AutoComplete2(props) {
    const { classes, items, placeholder, gridSize, name, ...rest } = props;

    return (
        <Downshift {...rest}>
            {({
                getInputProps,
                getItemProps,
                getMenuProps,
                inputValue,
                selectedItem,
                highlightedIndex,
                toggleMenu,
                isOpen
            }) => (

                <div>
                    <Grid fluid>
                        <Row className="show-grid">
                            <Col xs={gridSize}>
                                <div className='rs-auto-complete'>
                                    <div className="rs-picker-search-input">
                                        <input className='rs-input' placeholder={placeholder} name={name} {...getInputProps({
                                            onClick: () => toggleMenu(),
                                        })} />
                                    </div>
                                </div>
                                <div className="rs-picker-menu fade in placement-bottom-start rs-auto-complete-menu">
                                    <ul
                                        {...getMenuProps({})}
                                    >
                                        {isOpen
                                            ? items.map((item, index) => (
                                                <li
                                                    {...getItemProps({
                                                        item,
                                                        key: item.id,
                                                    })}
                                                >
                                                    <label>
                                                        <p className='rs-auto-complete-item'>{item.value}</p>
                                                    </label>
                                                </li>
                                            ))
                                            : null}
                                    </ul>
                                </div>

                            </Col>
                        </Row>
                    </Grid>

                </div>
            )}
        </Downshift>
    )
}

export default AutoComplete2;
