import React, { memo } from 'react';
import { QuickActionFilter } from '../QuickActionFilter';
import useGerenciadorQuickActionFilters from './useGerenciadorQuickActionFilters';

const { Select, SelectInterval, Button } = QuickActionFilter;

const GerenciadorQuickActionFilters = () => {
    const filters = useGerenciadorQuickActionFilters();

    return (
        <QuickActionFilter>
            <SelectInterval {...filters.lastCpiDays} />
            <Button {...filters.changedPrices} forceDisabled={false} />
            <Select {...filters.selectLimits} />
            <Button {...filters.derrivedProducts} forceDisabled />
        </QuickActionFilter>
    );
};

export default memo(GerenciadorQuickActionFilters);
