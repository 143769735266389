import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import React, { ReactNode } from 'react';
import { Tooltip, Whisper } from 'rsuite';
import { formatDecimal } from '../../utils';
import styles from './MoneyPercentageIndicator.module.scss';

export interface IMoneyPercentageIndicator {
    value?: number | null;
    newObjectiveMargin?: number | null;
    className?: string;
    tooltip?: string;
}

type IconTooltip = {
    icon: ReactNode;
    tooltip?: string;
};

const TooltipIndicator = ({
    icon,
    tooltip = 'Filtrar por fator margem',
}: IconTooltip) => (
    <Whisper
        placement="auto"
        trigger="hover"
        speaker={<Tooltip>{tooltip}</Tooltip>}
    >
        {icon}
    </Whisper>
);

export const MoneyPercentageIndicator = ({
    value,
    newObjectiveMargin,
    className,
    tooltip,
}: IMoneyPercentageIndicator) => {
    const iconStatus =
        newObjectiveMargin > value ? 'icon-negative' : 'icon-default';
    const textStatus =
        newObjectiveMargin > value ? 'text-negative' : 'text-default';

    return (
        <div className={[styles['indicator-wrapper'], className].join(' ')}>
            <TooltipIndicator
                icon={
                    <AttachMoneyIcon
                        className={
                            styles[value !== null ? iconStatus : 'icon-default']
                        }
                    />
                }
                tooltip={tooltip}
            />
            {value !== null ? (
                <p className={styles[textStatus]}>
                    {formatDecimal(value, 1)} %
                </p>
            ) : (
                <p className={styles['text-default']}>--</p>
            )}
        </div>
    );
};
