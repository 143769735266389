import React from 'react';
import { Content, FlexboxGrid, Col } from 'rsuite';
import ProductsCard from '../../ISA/InfoPanel/InfoPanel/Components/ProductsCard';
import SBoxPlotChart from '../../ISA/InfoPanel/InfoPanel/Components/SBoxPlotChart';
import STopPricesChart from '../../ISA/InfoPanel/InfoPanel/Components/STopPricesChart';
import STimeEvolutionChart from '../../ISA/InfoPanel/InfoPanel/Components/STimeEvolutionChart';
import SMapChart from '../../ISA/InfoPanel/InfoPanel/Components/SMapChart';
import computerImage from '../../../assets/image/computer.svg';

const timeEvolutionGroup = {
    GTIN: 'produto',
    REDE: 'rede',
    TIPO_LOJA: 'tipoLoja',
    FABRICANTE: 'fabricante',
};

const boxplotGroup = (data) => {
    if (data?.length === 2) {
        switch (true) {
            case data?.some((item) => item === 'REDE'):
                return 'produtoRede';
            case data?.some((item) => item === 'UF'):
                return 'produtoUf';
            default:
                break;
        }
    } else {
        switch (true) {
            case data[0] === 'GTIN':
                return 'produto';
            case data[0] === 'REDE':
                return 'rede';
            case data[0] === 'UF':
                return 'uf';
            case data[0] === 'FABRICANTE':
                return 'fabricante';
            default:
                break;
        }
    }
};

const ChartViews = ({
    topPricesData,
    boxPlotData,
    mapData,
    dataTimeEvolution,
    sharedState,
    expired,
}) => (
    <Content className="share-analyze-chart-views share-content">
        <h4 className="chart-views-title">Resultados</h4>
        <p className="chart-views-subtitle">
            Essa é uma visualização dos resultados das análises compartilhadas.
        </p>
        {expired ? (
            <div className="expired-analysis">
                <h4>
                    O período da análise compartilhada não está mais disponível
                </h4>
                <p>
                    Para visualizar as análises é necessário que período
                    selecionado seja entre os últimos 90 dias
                </p>
                <img src={computerImage} alt="" />
            </div>
        ) : (
            <>
                <div className="product-card-wrapper">
                    <ProductsCard products={sharedState?.products} />
                    <FlexboxGrid style={{ marginTop: '20px' }}>
                        <FlexboxGrid.Item
                            componentClass={Col}
                            lg={24}
                            md={24}
                            sm={24}
                            xs={24}
                        >
                            <STimeEvolutionChart
                                chains={[]}
                                groupBy={
                                    timeEvolutionGroup[
                                    sharedState?.c1?.group[0]
                                    ]
                                }
                                period={sharedState?.c1?.group[1]}
                                chartData={dataTimeEvolution || null}
                                showExportOptions={false}
                                userPlan={sharedState?.plan}
                                products={sharedState?.products}
                                disabledTypeOptions
                                disabledPeriodSelect
                            />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item
                            componentClass={Col}
                            lg={24}
                            md={24}
                            sm={24}
                            xs={24}
                        >
                            <SBoxPlotChart
                                data={boxPlotData || []}
                                groupBy={boxplotGroup(sharedState?.c2?.group)}
                                userPlan={sharedState?.plan}
                                products={sharedState?.products}
                                shared
                            />
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </div>
                <FlexboxGrid>
                    <FlexboxGrid.Item
                        componentClass={Col}
                        lg={12}
                        md={12}
                        sm={24}
                        xs={24}
                    >
                        <SMapChart
                            chartData={mapData || []}
                            groupBy={
                                sharedState?.c3?.group === null
                                    ? 'loja'
                                    : 'estado'
                            }
                            disabledGroupByOptions
                            showExportOptions={false}
                            userPlan={sharedState?.plan}
                        />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item
                        componentClass={Col}
                        lg={12}
                        md={12}
                        sm={24}
                        xs={24}
                    >
                        <STopPricesChart data={topPricesData || []} shared />
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </>
        )}
    </Content>
);

export default ChartViews;
