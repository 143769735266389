export const colunasTableOfertasModal = [
    {
        sortable: true,
        align: 'left',
        fixed: false,
        headerCell: 'Produto',
        dataKey: 'product_id',
        // flexGrow: 4,
        width: 248,
    },
    {
        sortable: true,
        align: 'right',
        fixed: false,
        headerCell: 'Lojas',
        dataKey: 'stores_id',
        // flexGrow: 1,
        width: 72,
    },
    {
        sortable: true,
        align: 'center',
        fixed: false,
        headerCell: 'PMZ',
        dataKey: 'pmz',
        // flexGrow: 2,
        width: 80,
    },
    {
        sortable: true,
        align: 'center',
        fixed: false,
        headerCell: 'Preço Vigente',
        dataKey: 'retail_price',
        // flexGrow: 2,
        width: 96,
    },
    {
        sortable: true,
        align: 'center',
        fixed: false,
        headerCell: 'Preço Médio Concorrente',
        dataKey: 'avg_competitiveness_price',
        // flexGrow: 2,
        width: 96,
    },
    {
        sortable: true,
        align: 'right',
        fixed: false,
        headerCell: 'Competitividade',
        dataKey: 'competitiveness',
        // flexGrow: 1,
        width: 96,
    },
    {
        sortable: true,
        align: 'right',
        fixed: false,
        headerCell: 'Margem',
        dataKey: 'margin',
        // flexGrow: 1,
        width: 80,
    },
    {
        sortable: false,
        align: 'right',
        fixed: 'right',
        headerCell: '',
        dataKey: '',
        width: 50,
    },
];

export const colunasTableOfertas = [
    {
        sortable: false,
        align: 'left',
        fixed: false,
        headerCell: 'id',
        dataKey: 'product_id',
        flexGrow: 0,
    },
    {
        sortable: false,
        align: 'left',
        fixed: false,
        headerCell: 'Produto',
        dataKey: 'description',
        flexGrow: 3,
    },
    {
        sortable: false,
        align: 'left',
        fixed: false,
        headerCell: 'Tipo de oferta',
        dataKey: 'offer_type',
        flexGrow: 1,
    },
    {
        sortable: false,
        align: 'left',
        fixed: false,
        headerCell: 'Bloco de oferta',
        dataKey: 'offer_group',
        flexGrow: 2,
    },
    {
        sortable: false,
        align: 'left',
        fixed: false,
        headerCell: 'PMZ',
        dataKey: 'pmz',
        flexGrow: 1,
    },
    {
        sortable: false,
        align: 'left',
        fixed: false,
        headerCell: 'Preço vigente',
        dataKey: 'retail_price',
        flexGrow: 1,
    },
    {
        sortable: false,
        align: 'left',
        fixed: false,
        headerCell: 'Preço oferta',
        dataKey: 'offer_final_price',
        flexGrow: 1,
    },
    {
        sortable: false,
        align: 'left',
        fixed: false,
        headerCell: '% Desconto',
        dataKey: 'offer_discount_percentage',
        flexGrow: 1,
    },
    {
        sortable: false,
        align: 'left',
        fixed: false,
        headerCell: 'Competitividade Oferta',
        dataKey: 'competitiveness',
        flexGrow: 1,
    },
    {
        sortable: false,
        align: 'left',
        fixed: false,
        headerCell: 'Margem Oferta',
        dataKey: 'margin',
        flexGrow: 1,
    },
    {
        sortable: false,
        align: 'left',
        fixed: 'right',
        headerCell: '',
        dataKey: '',
        width: 120,
    },
];
