import React from 'react';
import {
    Modal, Button, Form, FlexboxGrid, Col, Icon, AutoComplete, HelpBlock,
} from 'rsuite';

import countryIconWhite from '../../../../../assets/icons/icon_country-white.svg';
import countryIconBlue from '../../../../../assets/icons/icon_country-blue.svg';
import stateIconWhite from '../../../../../assets/icons/icon_state-white.svg';
import stateIconBlue from '../../../../../assets/icons/icon_state-blue.svg';
import cityIconWhite from '../../../../../assets/icons/icon_city-white.svg';
import cityIconBlue from '../../../../../assets/icons/icon_city-blue.svg';

const RegionForm = (props) => {
    const {
        region, setRegion, changeAutocompleteValue, hasEnoughData, states, cities, searchCity, autocompleteValue,
        setDataAlarm,
    } = props;
    return (
        <>
            <Modal.Header>
                <div className="header">
                    <h1 className="header__title">Alterar agrupamento de dados</h1>
                    <p className="header__description">O agrupamento de dados é a forma como vamos sintetizar e exibir os dados da sua pesquisa.</p>
                </div>
            </Modal.Header>
            <Modal.Body>

                <Form fluid>
                    <FlexboxGrid align="middle">
                        <Col xs={24}>
                            <div className="region-selection">
                                <p className="region-selection__label">Escolha sua forma de agrupamento de dados</p>
                                <div>
                                    <Button
                                        appearance={region === 'pais' ? 'primary' : 'ghost'}
                                        className="region-selection__btn-option"
                                        onClick={() => setRegion('pais')}
                                    >
                                        <img alt="País" src={region === 'pais' ? countryIconWhite : countryIconBlue} />
                                        <p>País</p>
                                    </Button>
                                    <Button
                                        appearance={region === 'estado' ? 'primary' : 'ghost'}
                                        className="region-selection__btn-option"
                                        onClick={() => setRegion('estado')}
                                    >
                                        <img alt="Estado" src={region === 'estado' ? stateIconWhite : stateIconBlue} />
                                        <p>Estado</p>
                                    </Button>
                                    <Button
                                        appearance={region === 'cidade' ? 'primary' : 'ghost'}
                                        className="region-selection__btn-option"
                                        onClick={() => setRegion('cidade')}
                                    >
                                        <img alt="Cidade" src={region === 'cidade' ? cityIconWhite : cityIconBlue} />
                                        <p>Cidade</p>
                                    </Button>
                                </div>
                            </div>
                        </Col>
                        {region.length && region !== 'pais' ? (
                            <Col xs={24} className="subregion-area">
                                <div>
                                    {region === 'estado'
                                        ? (
                                            <label
                                                htmlFor="autocomplete"
                                                className="subregion-area__label"
                                            >
                                                Digite um estado:
                                            </label>
                                        )
                                        : (
                                            <label
                                                htmlFor="autocomplete"
                                                className="subregion-area__label"
                                            >
                                                Digite uma cidade:
                                            </label>
                                        )}
                                    <AutoComplete
                                        menuClassName="subregion-area__menu"
                                        className={hasEnoughData === false ? 'subregion-area__input invalid' : 'subregion-area__input'}
                                        data={region === 'estado' ? states : cities}
                                        name={region === 'estado' ? 'state' : 'city'}
                                        value={autocompleteValue}
                                        id="autocomplete"
                                        autoComplete="off"
                                        onSelect={(item) => changeAutocompleteValue(item, region === 'estado' ? 'state' : 'city')}
                                        onChange={(value, event) => searchCity(value, event)}
                                    />
                                    {hasEnoughData === false ? (
                                        <HelpBlock className="help-block-warning">
                                            {region === 'estado'
                                                ? 'O estado selecionado possui poucos dados. Por favor selecione outro estado ou outra forma de agrupamento.'
                                                : 'A cidade selecionada possui poucos dados. Por favor selecione outra cidade ou outra forma de agrupamento.'}

                                        </HelpBlock>
                                    ) : null}
                                </div>
                            </Col>
                        ) : null}
                        {region.length && (hasEnoughData == null || hasEnoughData === true)
                            ? (
                                <Col xs={24} className="description-area">
                                    {region === 'pais' ? (
                                        <p className="description-area__message">
                                            Ao selecionar o agrupamento de dados por
                                            {' '}
                                            <b>País</b>
                                            {' '}
                                            você vai visualizar uma
                                            {' '}
                                            <b>única média</b>
                                            {' '}
                                            de preços do Brasil.
                                        </p>
                                    )
                                        : region === 'estado' ? (
                                            <p className="description-area__message">
                                                Ao selecionar o agrupamento de dados por
                                                {' '}
                                                <b>Estado</b>
                                                {' '}
                                                você vai visualizar uma
                                                {' '}
                                                <b>única média</b>
                                                {' '}
                                                de preços do estado selecionado.
                                            </p>
                                        )
                                            : (
                                                <p className="description-area__message">
                                                    Ao selecionar o agrupamento de dados por
                                                    {' '}
                                                    <b>Cidade</b>
                                                    {' '}
                                                    você vai visualizar uma
                                                    {' '}
                                                    <b>única média</b>
                                                    {' '}
                                                    de preços da cidade selecionada.
                                                </p>
                                            )}
                                </Col>
                            )
                            : null}

                        {hasEnoughData === false ? (
                            <Col xs={24} className="no-data-warning">
                                <button type="button" onClick={setDataAlarm} className="no-data-warning__link">
                                    <span className="no-data-warning__icon-area">
                                        <Icon icon="exclamation-circle2" />
                                    </span>
                                    Clique aqui para ser informado quando houver mais dados disponíveis
                                </button>
                            </Col>
                        ) : null}
                        <Col xs={24}>
                            <div className="warning-area">
                                <span className="warning-area__icon-area">
                                    <Icon icon="exclamation-triangle" />
                                </span>
                                <span className="warning-area__description-area">
                                    <p><b>Atenção!</b></p>
                                    <p>
                                        Você poderá realizar
                                        <b> UMA</b>
                                        {' '}
                                        alteração de distribuição geográfica
                                        <b> POR MÊS</b>
                                        .
                                    </p>
                                </span>
                            </div>
                        </Col>
                    </FlexboxGrid>
                </Form>

            </Modal.Body>
        </>
    );
};
export default RegionForm;
