import { RequestInSystemReducerActionType, RequestInSystemReducerStateType } from '../types';

export const initialState: RequestInSystemReducerStateType = {
    addressComponentList: [{
        id: 1,
        uf: '',
        cidade: '',
        cep: '',
        endereco: '',
        numero: '',
        raio: '',
        codeOrText: [],
    }],
    showModal: false,
    invalidGtins: [],
    cepLoading: false,
    modalType: 'sendRequest',
    removeAddressId: null,
    gtinList: [],
    cepError: false,
    activeRequests: {
        show: false,
        message: '',
    },
};

export const requestInSystemReducer = (
    state: RequestInSystemReducerStateType,
    action: RequestInSystemReducerActionType,
): RequestInSystemReducerStateType => {
    switch (action.type) {
        case 'SET_ADDRESS_COMPONENT_LIST':
            return {
                ...state,
                addressComponentList: action.addressComponentList,
            };
        case 'SET_SHOW_MODAL':
            return {
                ...state,
                showModal: action.showModal,
            };
        case 'SET_INVALID_GTINS':
            return {
                ...state,
                invalidGtins: action.invalidGtins,
            };
        case 'SET_CEP_LOADING':
            return {
                ...state,
                cepLoading: action.cepLoading,
            };
        case 'SET_MODAL_TYPE':
            return {
                ...state,
                modalType: action.modalType,
            };
        case 'SET_REMOVE_ADDRESS_ID':
            return {
                ...state,
                removeAddressId: action.removeAddressId,
            };
        case 'SET_GTIN_LIST':
            return {
                ...state,
                gtinList: action.gtinList,
            };
        case 'SET_CEP_ERROR':
            return {
                ...state,
                cepError: action.cepError,
            };
        case 'SET_STATE':
            return {
                ...state,
                [action.name]: action.data,
            };

        default:
            return state;
    }
};
