import React, { memo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../@types/RootState';
import { LinkButton } from '../LinkButton';

type SelectAllButtonProps = {
    handleSelectAllFromAllPages: () => void;
    handleUnselectAll: () => void;
    isEmpty: boolean;
};

const SelectAllButton = ({
    handleSelectAllFromAllPages,
    handleUnselectAll,
    isEmpty,
}: SelectAllButtonProps) => {
    const selectedAll = useSelector((state: RootState) => {
        return state.gerenciadorPrecosReducer.selectedDatapoints.selectedAll;
    });

    const onClick = useCallback(() => {
        if (!selectedAll) handleSelectAllFromAllPages();
        else handleUnselectAll();
    }, [selectedAll, handleSelectAllFromAllPages, handleUnselectAll]);

    return (
        !isEmpty && (
            <LinkButton
                className="aplicar-precos-container__link-button"
                onClick={onClick}
            >
                {selectedAll ? 'Limpar seleção' : 'Selecionar tudo'}
            </LinkButton>
        )
    );
};

export default memo(SelectAllButton);
