export const handleMoveFocusToNextRowInput = (e: KeyboardEvent) => {
    const targetClass = (e.target as HTMLElement).className.replace(' ', '.');
    let nextInput: HTMLInputElement;
    if (
        e.key === 'Enter' &&
        (targetClass.match(/novo-preco/) ||
            targetClass.match(/nova-competitividade/) ||
            targetClass.match(/nova-margem/))
    ) {
        const nodeList = (e.currentTarget as HTMLElement).querySelectorAll(
            `.${targetClass}`,
        );
        const currentIndex = Array.from(nodeList).indexOf(
            e.target as HTMLInputElement,
        );
        const nextIndex = currentIndex + 1;

        if (nextIndex < nodeList.length) {
            nextInput = nodeList[nextIndex] as HTMLInputElement;
            nextInput.focus();
        }
    }
};
