import React from 'react';

export const MdKeyboardArrowDown = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M9.06693 13.0903L5.24364 9.26706C4.91879 8.9422 4.91879 8.41744 5.24364 8.09258C5.5685 7.76773 6.09326 7.76773 6.41812 8.09258L9.65833 11.3245L12.8902 8.09258C13.2151 7.76773 13.7398 7.76773 14.0647 8.09258C14.3895 8.41744 14.3895 8.9422 14.0647 9.26706L10.2414 13.0903C9.92488 13.4152 9.39178 13.4152 9.06693 13.0903Z"
            fill="currentColor"
        />
    </svg>
);
