import Immutable from 'seamless-immutable';
import {
    SET_RANGES,
    ADD_RANGE,
    ADD_DECIMAL_RANGE,
    DELETE_DECIMAL_RANGE,
    DELETE_RANGE,
    SET_VALUE_RANGE,
    SET_VALUE_DECIMAL_RANGE,
} from '../actions/actionsPrecoPsicologico';

export const precoPsicologicoInitialState = Immutable({
    // ranges: [{
    //     id: timestamp,
    //     min: null,
    //     max: null,
    // }],
    // decimalRanges: [{
    //     rangeId: timestamp,
    //     id: timestamp,
    //     min: null,
    //     max: null,
    //     roundingTo: null,
    //     badge: null,
    // }]
    ranges: [],
    decimalRanges: [],
});
function precoPsicologicoDataReducer(
    state = precoPsicologicoInitialState,
    action,
) {
    switch (action.type) {
        case SET_RANGES:
            return {
                ranges: action.ranges,
                decimalRanges: action.decimalRanges,
            };
        case ADD_RANGE:
            return {
                ...state,
                ranges: [...state.ranges, action.range],
                decimalRanges: [...state.decimalRanges, action.decimalRange],
            };
        case ADD_DECIMAL_RANGE:
            return {
                ...state,
                decimalRanges: [...state.decimalRanges, action.decimalRange],
            };
        case SET_VALUE_RANGE:
            return {
                ...state,
                ranges: state.ranges.map((range, i) => (range.id === action.rangeId
                        ? {
                              ...range,
                              min: action.minValue,
                              max: action.maxValue,
                              status: action.status,
                          }
                        : range)),
            };
        case SET_VALUE_DECIMAL_RANGE:
            return {
                ...state,
                decimalRanges: state.decimalRanges.map((decimalRange, i) => (decimalRange.id === action.decimalRangeId
                        ? {
                              ...decimalRange,
                              min: action.decimalMinValue,
                              max: action.decimalMaxValue,
                              roundingTo: action.decimalRoundingToValue,
                              badge: action.activeBadge,
                              status: action.status,
                          }
                        : decimalRange)),
            };
        case DELETE_RANGE:
            if (action.status === 'CREATE') {
                return {
                    ...state,
                    ranges: state.ranges.filter(
                        (range, index) => range.id !== action.rangeId,
                    ),
                    decimalRanges: state.decimalRanges.filter(
                        (decimalRange, index) => decimalRange.rangeId !== action.rangeId,
                    ),
                };
            }
            return {
                ...state,
                ranges: state.ranges.map((range, i) => (range.id === action.rangeId
                        ? { ...range, status: 'DELETE' }
                        : range)),
                decimalRanges: state.decimalRanges.map((decimalRange, i) => (decimalRange.rangeId === action.rangeId
                        ? { ...decimalRange, status: 'DELETE' }
                        : decimalRange)),
            };

        case DELETE_DECIMAL_RANGE:
            if (action.status === 'CREATE') {
                return {
                    ...state,
                    decimalRanges: state.decimalRanges.filter(
                        (decimalRange, index) => decimalRange.id !== action.decimalRangeId,
                    ),
                };
            }
            return {
                ...state,
                decimalRanges: state.decimalRanges.map((decimalRange, i) => (decimalRange.id === action.decimalRangeId
                        ? { ...decimalRange, status: 'DELETE' }
                        : decimalRange)),
            };

        default:
            return state;
    }
}

export default precoPsicologicoDataReducer;
