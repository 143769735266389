import React from 'react';
import { useDrop } from 'react-dnd';

const DndColumn = ({ children, className, title, name, element }) => {
    const [{ isOver, canDrop }, drop] = useDrop({
        accept: 'block',
        drop: () => ({ name }),
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
    });

    const dndCanDropStyle = () => {
        if (isOver) {
            if (canDrop) {
                return {
                    border: '2px dashed #c7e0ff',
                    borderRadius: '6px',
                    minHeight: '45px',
                };
            }
        } else {
            return {};
        }
    };

    return (
        <div ref={drop} className={className}>
            <p className="dnd-column-title">{title}</p>
            {element}
            <div className="dnd-column-content" style={dndCanDropStyle()}>
                {children}
            </div>
        </div>
    );
};

export default DndColumn;
