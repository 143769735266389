import React from 'react';
import { Link } from 'react-router-dom';

const Steps = ({ rota, campanhaId, status, lojas }) => (
    <ul className="cadastro-campanha-steps">
        <li className={`${rota.includes('dados-campanha') ? 'active' : ''}`}>
            <Link to={`/ipa/campanhas/cadastro/dados-campanha/${campanhaId}`}>
                Dados da Campanha
            </Link>
        </li>
        <li
            className={`${
                campanhaId
                    ? rota.includes('blocos-de-oferta')
                        ? 'active'
                        : ''
                    : 'disabled'
            }`}
        >
            {campanhaId ? (
                <Link
                    to={`/ipa/campanhas/cadastro/blocos-de-oferta/${campanhaId}`}
                >
                    Blocos de ofertas
                </Link>
            ) : (
                'Blocos de ofertas'
            )}
        </li>
        <li
            className={`${
                campanhaId
                    ? rota.includes('lojas-participantes')
                        ? 'active'
                        : ''
                    : 'disabled'
            }`}
        >
            {campanhaId ? (
                <Link
                    to={`/ipa/campanhas/cadastro/lojas-participantes/${campanhaId}`}
                >
                    Lojas Participantes
                </Link>
            ) : (
                'Lojas Participantes'
            )}
        </li>
        <li
            className={`${
                campanhaId && lojas
                    ? rota.includes('ofertas')
                        ? 'active'
                        : ''
                    : 'disabled'
            }`}
        >
            {campanhaId && lojas ? (
                <Link to={`/ipa/campanhas/cadastro/ofertas/${campanhaId}`}>
                    Ofertas
                </Link>
            ) : (
                'Ofertas'
            )}
        </li>
        <li
            className={`${
                campanhaId && (status === 'CURRENT' || status === 'SCHEDULED' || status === 'CLOSED')
                    ? rota.includes('extracao')
                        ? 'active'
                        : ''
                    : 'disabled'
            }`}
        >
            {campanhaId && (status === 'CURRENT' || status === 'SCHEDULED' || status === 'CLOSED') ? (
                <Link
                    to={`/ipa/campanhas/cadastro/extracao-precos/${campanhaId}`}
                >
                    Extração dos Preços
                </Link>
            ) : (
                'Extração dos Preços'
            )}
        </li>
    </ul>
);
export default Steps;
