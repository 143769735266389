import { PayloadAction, createSlice } from '@reduxjs/toolkit';

type FilterDataProps = Record<string, unknown> & {
    label: string;
    value: string;
};

type FilterItemProps = {
    list: FilterDataProps[];
    cache: FilterDataProps[];
    value: string[];
};

type SavedOrderingProps = {
    orderField: string;
    ascending: boolean;
};

type SavedQueryItemProps = {
    query: string,
    from_date: string,
    to_date: string,
    product_id: string[],
    regional: string[],
    uf: string[],
    channel: string,
    sub_channel: string,
    responsible: string,
};

type FilterRequestBodyProps = {
    query: SavedQueryItemProps,
    ordering: SavedOrderingProps[],
};

export type SavedFiltersNameProps =
    | 'produtos'
    | 'uf'
    | 'channel'
    | 'responsible'
    | 'regional';

export type SavedFiltersRequestBodyNameProps =
    | 'product_id'
    | 'product_description'
    | 'location_type'
    | 'location'
    | 'channel'
    | 'sub_channel'
    | 'competitor_product_id'
    | 'competitor_product_description'
    | 'payment_method'
    | 'query'
    | 'ordering'

export type ScenarioSavedFiltrosStateProps = {
    produtos: FilterItemProps;
    uf: FilterItemProps;
    channel: FilterItemProps;
    savedFilterRequestBody: FilterRequestBodyProps;
    regional: FilterItemProps;
    responsible: FilterItemProps;
};

const initialState: ScenarioSavedFiltrosStateProps = {
    produtos: {
        list: [],
        cache: [],
        value: [],
    },
    uf: {
        list: [],
        cache: [],
        value: [],
    },
    channel: {
        list: [],
        cache: [],
        value: [],
    },
    regional: {
        list: [],
        cache: [],
        value: [],
    },
    responsible: {
        list: [],
        cache: [],
        value: [],
    },
    savedFilterRequestBody: {
        query: {
            query: '',
            from_date: '',
            to_date: '',
            product_id: [],
            regional: [],
            uf: [],
            channel: '',
            sub_channel: '',
            responsible: '',
        },
        ordering: [],
    },
};

const slice = createSlice({
    name: 'saved-filtros-state',
    initialState,
    reducers: {
        setSavedFilterList(state, { payload }: PayloadAction<{
                name: SavedFiltersNameProps;
                list: FilterDataProps[];
            }>) {
            state[payload.name].list = payload.list;
        },
        setSavedFilterCacheValue(state, { payload }: PayloadAction<{
            name: SavedFiltersNameProps;
            data: {cache: FilterDataProps[], value: string[]};
        }>) {
            state[payload.name].cache = payload.data.cache;
            state[payload.name].value = payload.data.value;
        },
        addSavedFilterState(
            state,
            {
                payload,
            }: PayloadAction<{
                name: SavedFiltersNameProps;
                data: Partial<FilterItemProps>;
            }>,
        ) {
            if (payload.data.value) {
                if (!state[payload.name]) return;
                state[payload.name].value = payload.data.value;
            }
            if (payload.data.cache) {
                if (!state[payload.name]) return;
                state[payload.name].cache = payload.data.cache;
            }
        },
        clearSavedFilters() {
            return initialState;
        },
        resetSavedFilters() {
            return initialState;
        },
        setSavedFilterRequestBody(state, { payload }: PayloadAction<{
            name: SavedFiltersRequestBodyNameProps;
            data: any;
        }>) {
            state.savedFilterRequestBody[payload.name] = payload.data;
        },
    },
});

const {
    setSavedFilterList,
    setSavedFilterCacheValue,
    clearSavedFilters,
    addSavedFilterState,
    resetSavedFilters,
    setSavedFilterRequestBody,
} = slice.actions;

export {
    setSavedFilterList,
    setSavedFilterCacheValue,
    clearSavedFilters,
    addSavedFilterState,
    resetSavedFilters,
    setSavedFilterRequestBody,
};

export default slice.reducer;
