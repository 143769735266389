import React, { forwardRef } from 'react';

export const PageHeader = forwardRef(
    (
        {
            children,
            className = '',
            ...props
        }: React.HTMLAttributes<HTMLHeadingElement>,
        ref: React.LegacyRef<HTMLElement>,
    ) => (
        <header ref={ref} className={`page__header ${className}`} {...props}>
            {children}
        </header>
    ),
);
