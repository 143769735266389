import classNames from 'classnames';
import React from 'react';
import { MdTune } from 'react-icons/md';
import {
    LinkButton,
    LinkButtonProps,
} from '../../../../../../RevisaoPrecos/Components';
import styles from './EditCompetitorsButton.module.scss';

export const EditCompetitorsButton = ({
    className,
    ...props
}: Omit<LinkButtonProps, 'children'>) => {
    return (
        <LinkButton
            type="button"
            className={classNames(styles.wrapper, className)}
            {...props}
        >
            <MdTune /> Editar concorrência
        </LinkButton>
    );
};
