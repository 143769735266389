import Immutable from 'seamless-immutable';
import {
    DELETE_FILTRO_PRECIFICACAO_MANUAL,
    ADD_FILTRO_PRECIFICACAO_MANUAL,
    SELECT_VALUE_FILTRO_PRECIFICACAO_MANUAL,
    LIST_ITENS_FILTROS_PRECIFICACAO_MANUAL,
    LIST_SEGMENTOS,
    LIST_ITENS_FAMILIA_PRODUTO_PRECIFICACAO,
    SIMULAR_PRECO_LOJA,
    SIMULAR_PRECO_MULTIPLAS_LOJAS,
    CLEAR_FILTROS_PRODUTOS,
} from '../actions/actionsPrecificacaoManual';

const precificacaoManualInitialState = Immutable({
    tiposFiltro: [
        {
            title: 'PRODUTO',
            itens: [
                {
                    name: 'productFamilyId',
                    title: 'Família',
                    eventKey: 'productFamilyId',
                    nivel: 'productFamilyId',
                },
            ],
        },
        {
            title: 'SEGMENTAÇÃO',
            itens: [
                {
                    name: 'abcClass',
                    title: 'Curva',
                    eventKey: 'abcClass',
                    nivel: 9,
                },
            ],
        },
    ],
    filtros: [
        {
            name: 'productFamilyId',
            nivel: 'productFamilyId',
            cleanable: true,
            filter: false,
            title: 'Família',
            placeholder: 'Selecione um ou mais itens',
            locale: {
                noResultsText: 'Nenhum item encontrado',
                searchPlaceholder: 'Digite ou busque o item desejado',
            },
            onSelect: 'handleSelect',
            onSearch: 'searchFamiliaProduto',
        },
        {
            name: 'abcClass',
            nivel: 9,
            cleanable: true,
            filter: true,
            title: 'Curva',
            placeholder: 'Selecione uma curva',
            locale: { noResultsText: 'Nenhum item encontrado' },
            onSelect: 'handleSelect',
            onSearch: 'search',
        },
    ],
    filtrosAdicionados: [],
    filtrosData: {
        productFamilyId: {
            lista: [],
            value: [],
            cache: [],
        },
        abcClass: {
            lista: [],
            value: [],
        },
        description: {
            value: '',
        },
        productId: {
            value: '',
        },
    },
    loja: null,
    lojas: [],
    simularMultiplasLojas: false,
});

function precificacaoManualDataReducer(
    state = precificacaoManualInitialState,
    action,
) {
    switch (action.type) {
        case ADD_FILTRO_PRECIFICACAO_MANUAL:
            return state.merge({
                filtrosAdicionados: [
                    ...state.filtrosAdicionados,
                    action.filtro,
                ],
            });
        case DELETE_FILTRO_PRECIFICACAO_MANUAL:
            return state.merge({
                filtrosAdicionados: state.filtrosAdicionados.filter(
                    (item, index) => index !== action.index,
                ),
                filtrosData: {
                    ...state.filtrosData,
                    [`${action.name}`]: {
                        ...state.filtrosData[`${action.name}`],
                        value: [],
                    },
                },
            });
        case SELECT_VALUE_FILTRO_PRECIFICACAO_MANUAL:
            return state.merge({
                ...state,
                filtrosData: {
                    ...state.filtrosData,
                    [`${action.name}`]: {
                        ...state.filtrosData[`${action.name}`],
                        value: action.item,
                    },
                },
            });
        case LIST_ITENS_FILTROS_PRECIFICACAO_MANUAL:
            return state.merge({
                ...state,
                filtrosData: {
                    ...state.filtrosData,
                    [`${action.name}`]: {
                        ...state.filtrosData[`${action.name}`],
                        lista: action.itens?.map((item) => ({
                                label: item,
                                value: item,
                            })),
                    },
                },
            });
        case LIST_ITENS_FAMILIA_PRODUTO_PRECIFICACAO:
        return state.merge({
            ...state,
            filtrosData: {
                ...state.filtrosData,
                [`${action.name}`]: {
                    ...state.filtrosData[`${action.name}`],
                    lista: action?.item?.product_family_ids
                        ?.map((item) => ({
                            label: item,
                            value: item,
                        }))
                        .concat(
                            state.filtrosData[
                                `${action.name}`
                            ]?.cache?.filter(
                                (item) => action.item?.product_family_ids?.findIndex(
                                        (itemLista) => itemLista === item?.value,
                                    ) === -1,
                            ),
                        ),
                },
            },
        });
        case LIST_SEGMENTOS:
            return state.merge({
                ...state,
                tiposFiltro: state.tiposFiltro.some((item) => item.title === 'CATEGORIZAÇÃO')
                    ? state.tiposFiltro
                    : [
                          ...state.tiposFiltro,
                          {
                              title: 'CATEGORIZAÇÃO',
                              itens: action.itens.map((item) => ({
                                      name: item.field,
                                      title: item.name,
                                      eventKey: item.field,
                                      nivel: item.level,
                                  })),
                          },
                      ],
                filtros: state.tiposFiltro.some((item) => item.title === 'CATEGORIZAÇÃO')
                    ? state.filtros
                    : state.filtros.concat(
                          action.itens.map((item) => ({
                                  name: item.field,
                                  nivel: item.level,
                                  cleanable: true,
                                  filter: false,
                                  title: item.name,
                                  placeholder:
                                      `Selecione uma ${
                                      item.name.toLowerCase()}`,
                                  locale: {
                                      noResultsText: 'Nenhum item encontrado',
                                  },
                                  onSelect: 'handleSelect',
                                  onSearch: 'search',
                              })),
                      ),
                filtrosData: {

                    ...action.segmentos,
                    ...state.filtrosData,
                },
            });
        case SIMULAR_PRECO_LOJA:
            return state.merge({
                ...state,
                loja: action.loja,
                lojas: [],
                simularMultiplasLojas: false,
            });
        case SIMULAR_PRECO_MULTIPLAS_LOJAS:
            return state.merge({
                ...state,
                lojas: action.lojas,
                simularMultiplasLojas: true,
                loja: '',
            });
        case CLEAR_FILTROS_PRODUTOS:
            return state.merge({
                ...state,
                filtrosData: precificacaoManualInitialState.filtrosData,
                filtrosAdicionados:
                    precificacaoManualInitialState.filtrosAdicionados,
            });
        default:
            return state;
    }
}

export default precificacaoManualDataReducer;
