import api from '../utils/API';
import { trackPromise } from 'react-promise-tracker';

export const getContratoServicoCliente = (usuario) =>
    api({
        url: process.env.REACT_APP_CADASTROS_API_URL + '/contratos/servicos/cliente',
        method: 'GET',
        params: {
            username: usuario
        }
    }).then((resp) => {
        return resp.data;
    })

export const sendNotification = () =>
    api({
        url: process.env.REACT_APP_CADASTROS_API_URL + '/contratos/notificacao/panel',
        method: 'POST',
        expectToken: true
    })

export const getUsuariosContrato = (data) =>
    trackPromise(
        api({
            url: process.env.REACT_APP_CADASTROS_API_URL + '/contratos/usuarioServicos',
            method: 'GET',
            params: {
                cliente: data.cliente,
                query: data.query
            },
            expectToken: true
        }).then((resp) => {
            return resp.data;
        })
    )

export const addUsuariosContrato = (cliente, data) =>
    trackPromise(
        api({
            url: process.env.REACT_APP_CADASTROS_API_URL + '/contratos/usuarioServicos',
            method: 'POST',
            params: {
                cliente: cliente
            },
            expectToken: true,
            data: JSON.stringify(data),
        }).then((resp) => {
            return resp.data;
        })
    )

export const editUsuariosContrato = (cliente, data) =>
    trackPromise(
        api({
            url: process.env.REACT_APP_CADASTROS_API_URL + '/contratos/usuarioServicos',
            method: 'PUT',
            params: {
                cliente: cliente
            },
            expectToken: true,
            data: JSON.stringify(data),
        }).then((resp) => {
            return resp.data;
        })
    )

export const deleteUsuariosContrato = (cliente, data) =>
    api({
        url: process.env.REACT_APP_CADASTROS_API_URL + '/contratos/usuarioServicos',
        method: 'DELETE',
        params: {
            cliente: cliente
        },
        data: JSON.stringify(data),
        expectToken: true
    }).then((resp) => {
        return resp.data;
    })


export const getAcessos = (cliente) =>
    api({
        url: process.env.REACT_APP_CADASTROS_API_URL + '/contratos/acessos',
        method: 'GET',
        params: {
            cliente: cliente
        },
        expectToken: true
    }).then((resp) => {
        return resp.data;
    })

export const getDominiosContrato = (cliente) =>
    api({
        url: process.env.REACT_APP_CADASTROS_API_URL + '/contratos/dominios',
        method: 'GET',
        params: {
            cliente: cliente
        },
        expectToken: true
    }).then((resp) => {
        return resp.data;
    })