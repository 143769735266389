import { roundValue } from './utils';

export function calcCompetitiveness(
    retailPrice: number,
    competitorPrice: number,
) {
    const result = (retailPrice / competitorPrice) * 100;
    return roundValue(result);
}

export function calcSuggested(
    competitiveness: number,
    competitorPrice: number,
) {
    return roundValue(competitiveness / 100) * competitorPrice;
}

const prices = {
    competitiveness: calcCompetitiveness,
    price: calcSuggested,
} as const;

export default prices;
