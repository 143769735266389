import { camelToSnakeCase } from './camelToSnakeCase';

export type GetSortedDataProps = {
    sort?: Partial<Record<string, string>>;
    sortColumnsTypes?: { name: string };
};

export function getSortedData({
    sort = {},
    sortColumnsTypes,
}: GetSortedDataProps): string | null {
    if (!sort) return null;
    if (sort?.type && sort?.orderBy) {
        return camelToSnakeCase(
            `${
                (sortColumnsTypes && sortColumnsTypes[sort.type]) || sort.type
            },${sort.orderBy}`,
        );
    }
    return null;
}
