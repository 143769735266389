import React from 'react';
import { CheckPicker, SelectPicker } from 'rsuite';
import { CheckPickerProps } from 'rsuite/lib/CheckPicker';

// id = select-picker-pill (forma de pílula, para select normal sem checkbox)
// id = check-picker-pill (forma de pílula, para select com checkbox na lista e placeholder visível no check do item)
// id = check-picker-pill-default (forma de pílula, para select com checkbox na lista e placeholder escondido)
// type = check (para select com checkbox na lista, se essa props estiver vazia o select será uma lista normal)

type SelectPillProps = CheckPickerProps & {
    id: string;
    type?: string;
    className?: string;
    style?: React.CSSProperties;
    searchable?: boolean;
    onClose?: () => void;
    onOpen?: () => void;
    placeholder?: React.ReactNode;
    isLoading?: boolean;
};

export const SelectPill: React.FC<SelectPillProps> = (props) => {
    const {
        id,
        searchable,
        type,
        className,
        style,
        placeholder,
        renderValue,
        name,
        renderExtraFooter,
        onClose,
        onOpen,
        data,
        cache,
        isLoading = false,
        ...restProps
    } = props;

    const renderPlaceholder =
        id === 'check-picker-pill'
            ? (value: string | any[]) => (
                  <>
                      <span>{value && placeholder}</span>
                      <span className="counter">{value && value?.length}</span>
                  </>
              )
            : renderValue;

    return type === 'check' ? (
        <div className="picker-pill-wrapper">
            <CheckPicker
                block
                id={id}
                className={className}
                name={name}
                placeholder={placeholder}
                searchable={searchable}
                renderValue={renderPlaceholder}
                style={style}
                renderExtraFooter={renderExtraFooter}
                onOpen={onOpen}
                onClose={onClose}
                data={isLoading ? [] : data}
                cache={isLoading ? [] : cache}
                {...restProps}
            />
        </div>
    ) : (
        <SelectPicker
            block
            id={id}
            className={className}
            name={name}
            placeholder={placeholder}
            searchable={searchable}
            style={style}
            onOpen={onOpen}
            onClose={onClose}
            data={isLoading ? [] : data}
            cache={isLoading ? [] : cache}
            {...restProps}
        />
    );
};
