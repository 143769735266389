import React, { memo, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GerenciadorPrecos } from '../../../../../@types/GerenciadorPrecos';
import { RootState } from '../../../../../@types/RootState';
import { RESET_GERENCIADOR_EXPANDED_ROW } from '../../../../../reducers/gerenciadorPrecos/expandedRowKey';
import { ExpandedRowCompetitors } from '../ExpandedRowCompetitors';
import { ExpandedRowWholesale } from '../ExpandedRowWholesale';
import styles from './ExpandedRowWrapper.module.scss';

const MemoCompetitors = memo(ExpandedRowCompetitors);
const MemoWholesale = memo(ExpandedRowWholesale);

type RowData = GerenciadorPrecos.RowData;

type ExpandedRowWrapperProps = {
    rowData?: RowData;
};

const ExpandedRowWrapper = ({ rowData }: ExpandedRowWrapperProps) => {
    if (!rowData) return null;

    const dispatch = useDispatch();

    const options = useSelector((state: RootState) => {
        return state.gerenciadorPrecosReducer.expandedRowKey.options;
    });

    const isExpanded = useMemo(() => options.length, [options]);

    useEffect(() => {
        if (!isExpanded) dispatch(RESET_GERENCIADOR_EXPANDED_ROW());
    }, [options]);

    return (
        <div className={styles.wrapper} data-table-row-is-expanded={isExpanded}>
            {options.includes('WHOLESALE') && (
                <MemoWholesale rowData={rowData} />
            )}
            {options.includes('COMPETITORS') && (
                <MemoCompetitors rowData={rowData} />
            )}
        </div>
    );
};

export default ExpandedRowWrapper;
