import { trackPromise } from 'react-promise-tracker';
import qs from 'qs';
import api from '../utils/API';

export const getDatapoints = (id, params) => trackPromise(
        api({
            url: `${process.env.REACT_APP_IPA_URL}/datapoints/product/${id}`,
            method: 'GET',
            expectToken: true,
            params,
            paramsSerializer(params) {
                return qs.stringify(params, { arrayFormat: 'repeat' });
            },
        }).then((resp) => resp.data),
    );

export const getDatapointsStores = (search, pageNumber, pageSize) => trackPromise(
        api({
            url: `${process.env.REACT_APP_IPA_URL}/datapoints/store`,
            method: 'GET',
            expectToken: true,
            params: {
                clientId: JSON.parse(localStorage.getItem('cliente'))?.codigo,
                status: 'REVISAO',
                search: search || '',
                page: pageNumber || 0,
                size: pageSize || 20,
            },
        }).then((resp) => resp.data),
    );

export const getDatapointsRuleTypes = () => [
    'CURRENT',
    'AUTO_PRICE',
    'MANUAL_PRICE',
];
