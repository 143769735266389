import { trackPromise } from 'react-promise-tracker';
import api from '../../../../utils/API';

const competitivityURL = `${process.env.REACT_APP_COMPETITIVITE_URL}/competitivity/retail`;
const industryCompetitivityURL = `${process.env.REACT_APP_INDUSTRY_COMPETITIVITE_URL}/competitivity/retail`;

export const getfiltrosSalvos = (tipoFiltro, query) => trackPromise(
    api({
        url: `${process.env.REACT_APP_ISA_URL}/competitivity/filters`,
        method: 'GET',
        expectToken: true,
        params: {
            tipoFiltro,
            query,
        },
    }).then((resp) => resp.data),
    'saved-competitivity-filter',
);

export const saveFiltersCompetitivePreference = (data) => trackPromise(
    api({
        url: `${process.env.REACT_APP_ISA_URL}/competitivity/filters`,
        method: 'POST',
        expectToken: true,
        data: JSON.stringify(data),
    }).then((resp) => resp.data),
    'saving-competitivity-filter',
);

export const updateSavedFilters = (data, id) => trackPromise(
    api({
        url: `${process.env.REACT_APP_ISA_URL}/competitivity/filters`,
        method: 'PUT',
        expectToken: true,
        data: JSON.stringify(data),
        params: {
            idFilter: id,
        },
    }).then((resp) => resp.data),
    'saving-competitivity-filter',
);

export const deleteSavedFilters = (id) => trackPromise(
    api({
        url: `${process.env.REACT_APP_ISA_URL}/competitivity/filters/inactive`,
        method: 'PUT',
        expectToken: true,
        params: {
            idFilter: id,
        },
    }).then((resp) => resp.data),
    'saving-competitivity-filter',
);

export const getAverageCompetitivite = (data) => trackPromise(
    api({
        url: `${competitivityURL}/average`,
        method: 'POST',
        expectToken: true,
        data: JSON.stringify(data),
    }).then((resp) => resp.data),
);

export const saveCompetitivitePreferences = (data) => trackPromise(
    api({
        url: `${process.env.REACT_APP_COMPETITIVITE_PREFERENCES_URL}/preferences/save`,
        method: 'PUT',
        expectToken: true,
        data: JSON.stringify(data),
    }).then((resp) => resp.data),
);

export const getEvolutionCompetitive = (data) => trackPromise(
    api({
        url: `${competitivityURL}/evolution`,
        method: 'POST',
        expectToken: true,
        data: JSON.stringify(data),
    }).then((resp) => resp.data),
);

export const getCompetitivePreferences = () => trackPromise(
    api({
        url: `${process.env.REACT_APP_COMPETITIVITE_PREFERENCES_URL}/preferences/get-by-current-user`,
        method: 'GET',
        expectToken: true,
    }).then((resp) => resp.data),
);

export const getDetailsCompetitive = (data) => trackPromise(
    api({
        url: `${competitivityURL}/details`,
        method: 'POST',
        expectToken: true,
        data: JSON.stringify(data),
    }).then((resp) => resp.data),
);

export const getDetailsProductsCompetitive = (data) => trackPromise(
    api({
        url: `${competitivityURL}/details/products`,
        method: 'POST',
        expectToken: true,
        data: JSON.stringify(data),
    }).then((resp) => resp.data),
);

export const downloadRetail = (extension, data) => trackPromise(
    api({
        url: `${competitivityURL}/download`,
        method: 'POST',
        params: {
            extensao: extension,
        },
        data: JSON.stringify(data),
        expectToken: true,
        responseType: 'arraybuffer',
    }).then((res) => res),
);

export const getIndustryAverageCompetitivite = (data) => trackPromise(
    api({
        url: `${industryCompetitivityURL}/average`,
        method: 'POST',
        expectToken: true,
        data: JSON.stringify(data),
    }).then((resp) => resp.data),
);

export const getIndustryEvolutionCompetitive = (data) => trackPromise(
    api({
        url: `${industryCompetitivityURL}/evolution`,
        method: 'POST',
        expectToken: true,
        data: JSON.stringify(data),
    }).then((resp) => resp.data),
);

export const getIndustryDetailsCompetitive = (data) => trackPromise(
    api({
        url: `${industryCompetitivityURL}/details`,
        method: 'POST',
        expectToken: true,
        data: JSON.stringify(data),
    }).then((resp) => resp.data),
);

export const getIndustryDetailsNetworksCompetitive = (data) => trackPromise(
    api({
        url: `${industryCompetitivityURL}/details/networks`,
        method: 'POST',
        expectToken: true,
        data: JSON.stringify(data),
    }).then((resp) => resp.data),
);

export const downloadIndustry = (extension, data) => trackPromise(
    api({
        url: `${industryCompetitivityURL}/download`,
        method: 'POST',
        params: {
            extensao: extension,
        },
        data: JSON.stringify(data),
        expectToken: true,
        responseType: 'arraybuffer',
    }).then((res) => res),
);
