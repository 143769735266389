import { AxiosResponse } from 'axios';
import qs from 'qs';
import { trackPromise } from 'react-promise-tracker';
import api from '../../../../../utils/API';
import { getValidData } from '../../../RevisaoPrecos/utils';

export type GetNegociacoesItemProps = Partial<{
    id: string;
    name: string;
    totalProducts: number;
    supplierId: string;
    supplierName: string;
    startDate: string;
    endDate: string | null;
    lastEditedDate: string;
    owner: string;
    status: 'ONGOING' | 'CLOSED';
    enableFinalDate?: boolean | null;
}>;

export type GetNegociacoesDataProps = {
    content: GetNegociacoesItemProps[];
    totalElements: number;
};

// negotiations/saved
export async function GETNegociacoesSalvas(params?: {
    sort: string;
    size: number;
    page: number;
}): Promise<GetNegociacoesDataProps> {
    return trackPromise<AxiosResponse<GetNegociacoesDataProps>>(
        // @ts-expect-error 'expectToken' does not exist in type 'AxiosRequestConfig'
        api({
            url: `${process.env.REACT_APP_IPA_URL}/negotiations`,
            method: 'GET',
            expectToken: true,
            params: getValidData(params),
            paramsSerializer(params: GetNegociacoesDataProps) {
                return qs.stringify(params, { arrayFormat: 'repeat' });
            },
        }),
        'negociacoes/salvas/get-negociacoes',
    ).then((res) => res.data);
}
