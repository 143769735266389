import React from 'react'
import { Button, Modal } from 'rsuite';
import iconUnlock from '../../../../../../assets/icons/icon_unlock_white.svg'

import styles from './ModalAnalisysPlan.module.scss'
import { analisysPlanBoxData } from '../../utils';

type ModalAnalisysPlanProps = {
    show?: boolean,
    onCancel?: () => void,
    onConfirm?: () => void,
}

export const ModalAnalisysPlan = ({show, onCancel, onConfirm}: ModalAnalisysPlanProps) => {
    return (
        <Modal
            show={show}
            size="md"
            className={styles["modal-analisys-plan"]}
            onHide={onCancel}
        >
            <Modal.Header className={styles.header}>
                <div className={styles['lock-icon-wrapper']}>
                    <img src={iconUnlock} alt="" />
                </div>
                <p className={styles.title}>Aproveite análises ilimitadas durante o mês inteiro.</p>
                <p className={styles.subtitle}>Suas análises são renovadas mensalmente. Para ter acesso ilimitado, basta escolher um dos nossos planos Flex ou Pro.</p>
            </Modal.Header>
            <Modal.Body className={styles.body}>
                <div className={styles.label}>
                    <span>Os planos</span>
                    {' '}
                    <span className={styles.bold}>Flex</span>
                    {' '}
                    <span>e</span>
                    {' '}
                    <span className={styles.bold}>Pro</span>
                    {' '}
                    <span>também incluem:</span>
                </div>
                <div className={styles['box-container']}>
                    {analisysPlanBoxData.map((item) => (
                        <div className={styles.box}>
                            <div className={styles['box-icon']}>{item.icon}</div>
                            <p className={styles['box-title']}>{item.title}</p>
                            <p className={styles['box-subtitle']}>{item.subtitle}</p>
                        </div>
                    ))}
                </div>
            </Modal.Body>
            <Modal.Footer className={styles.footer}>
                <Button appearance="ghost" onClick={onCancel}>Cancelar</Button>
                <Button appearance="primary" onClick={onConfirm}>Contratar plano</Button>
            </Modal.Footer>
        </Modal>
    )
}
