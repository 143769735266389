import React, { createRef } from 'react';
import {
    Whisper,
    IconButton,
    RadioGroup,
    Radio,
    Divider,
    Checkbox,
    Button,
} from 'rsuite';
import iconPreference from '../../../../../../assets/icons/icon_preference.svg';
import styles from './styles.module.scss';

export const FilterPreference = ({
    className,
    onChangeTipoPreco,
    onCancel,
    onConfirm,
    tipoPrecoValue,
    onChangePontoVenda,
    onChangeApp,
    valueCanalFisico,
    valueCanalDigital,
    onChangeCalculo,
    valueCalculo,
}) => {
    const popoverRef = createRef();

    const handleClosePopover = () => {
        onCancel();
        popoverRef.current.hide();
    };

    const handleConfirm = () => {
        handleClosePopover();
        onConfirm();
    };

    return (
        <Whisper
            placement="bottomEnd"
            trigger="click"
            triggerRef={popoverRef}
            speaker={
                <div className={styles['preference-popover']}>
                    <div className={styles['form-wrapper']}>
                        {/* <p className={styles['popover-title']}>CÁLCULO</p>
                        <RadioGroup
                            name="calculo"
                            onChange={onChangeCalculo}
                            value={valueCalculo}
                        >
                            <Radio value="PRECO_MEDIO">
                                <p className={styles['input-title']}>
                                    Preço médio
                                </p>
                                <p className={styles['input-subtitle']}>
                                    Usar a média dos preços coletados para o cálculo.
                                </p>
                            </Radio>
                            <Radio value="PRECO_MAIS_FREQUENTE">
                                <p className={styles['input-title']}>
                                    Preço mais frequente
                                </p>
                                <p className={styles['input-subtitle']}>
                                    Usar os preços mais frequentes coletados para o cálculo.
                                </p>
                            </Radio>
                        </RadioGroup>
                        <Divider className={styles.divider} /> */}
                        <p className={styles['popover-title']}>TIPO DE PREÇO</p>
                        <RadioGroup
                            name="tipoPreco"
                            onChange={onChangeTipoPreco}
                            value={tipoPrecoValue}
                        >
                            <Radio value="PRECO_PAGO">
                                <p className={styles['input-title']}>
                                    Preço pago
                                </p>
                                <p className={styles['input-subtitle']}>
                                    Preço efetivamente pago pelo usuário no
                                    varejo, podendo ser promoção.
                                </p>
                            </Radio>
                            <Radio value="PRECO_REGULAR">
                                <p className={styles['input-title']}>
                                    Preço regular
                                </p>
                                <p className={styles['input-subtitle']}>
                                    Preço original do produto, sem promoção e
                                    outros descontos.
                                </p>
                            </Radio>
                        </RadioGroup>
                        <Divider className={styles.divider} />
                        <div className={styles['canal-title-wrapper']}>
                            <p className={styles['popover-title']}>CANAL</p>
                            <p className={styles['popover-subtitle']}>
                                Selecione ao menos uma opção
                            </p>
                        </div>
                        <Checkbox
                            value="canalFisico"
                            checked={valueCanalFisico}
                            onChange={onChangePontoVenda}
                        >
                            <p className={styles['input-title']}>
                                Canal físico
                            </p>
                            <p className={styles['input-subtitle']}>
                                Preços praticados na loja física. Coletados
                                diretamente do ponto de venda, de notas fiscais
                                ou de apps quando correspondem aos preços da
                                loja.
                            </p>
                        </Checkbox>
                        <Checkbox
                            value="canalDigital"
                            checked={valueCanalDigital}
                            onChange={onChangeApp}
                        >
                            <p className={styles['input-title']}>Canal digital</p>
                            <p className={styles['input-subtitle']}>
                                Preços praticados em apps do varejo físico
                                (iFood, Rappi, etc.).
                            </p>
                        </Checkbox>
                    </div>
                </div>
            }
        >
            <IconButton
                className={`${styles['btn-preference']} ${className}`}
                appearance="subtle"
                placement="right"
                icon={<img alt="preferencias" src={iconPreference} />}
            >
                Preferências
            </IconButton>
        </Whisper>
    );
};
