import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../@types/RootState';
import { RowData } from './datapoints';

export type EditedDatapointsState = Record<string, Partial<RowData>>;

const initialState: EditedDatapointsState = {};

const slice = createSlice({
    name: 'getFamilyModal/editedDatapoints',
    initialState,
    reducers: {
        SET_EDITED_DATAPOINTS: (
            _state,
            { payload }: PayloadAction<EditedDatapointsState>
        ) => payload,

        UPDATE_EDITED_DATAPOINT: (
            state,
            {
                payload,
            }: {
                payload: {
                    key: string;
                    updatedData: Partial<RowData>;
                };
            },
        ) => {
            state[payload.key] = {
                ...state[payload.key],
                ...payload.updatedData,
            };
        },
        CLEAR_EDITED_DATAPOINTS: () => initialState,
    },
});

export const {
    SET_EDITED_DATAPOINTS,
    UPDATE_EDITED_DATAPOINT,
    CLEAR_EDITED_DATAPOINTS,
} = slice.actions;

export const selectorGetEditedDatapoints = (state: RootState) =>
    state.getFamilyModalReducer.editedDatapoints;

export default slice.reducer;
