import { getFiltroItensListaCadatrada } from '../pages/ISA/MPDV/RevisaoLista/services';

export const ADD_FILTRO_REVISAO_LISTA = 'ADD_FILTRO_REVISAO_LISTA';
export const DELETE_FILTRO_REVISAO_LISTA = 'DELETE_FILTRO_REVISAO_LISTA';
export const LIST_ITENS_FILTROS_REVISAO_LISTA = 'LIST_ITENS_FILTROS_REVISAO_LISTA';
export const CHANGE_RECOMMENDATION = 'CHANGE_RECOMMENDATION';
export const SELECT_FILTER_REVISAO_LISTA = 'SELECT_FILTER_REVISAO_LISTA';
export const CLEAR_FILTER_REVISAO_LISTA = 'CLEAR_FILTER_REVISAO_LISTA';

export function addFiltro(filtro) {
    return {
        type: ADD_FILTRO_REVISAO_LISTA,
        filtro,
    };
}

export function deleteFiltro(index, name) {
    return {
        type: DELETE_FILTRO_REVISAO_LISTA,
        index,
        name,
    };
}

export function changeRecommendation(data) {
    return {
        type: CHANGE_RECOMMENDATION,
        data,
    };
}

export function selectFilter(name, cache, value) {
    return {
        type: SELECT_FILTER_REVISAO_LISTA,
        name,
        cache,
        value,
    };
}

export function clearFilter(name) {
    return {
        type: CLEAR_FILTER_REVISAO_LISTA,
        name,
    };
}

export function listItens(itens, name) {
    return {
        type: LIST_ITENS_FILTROS_REVISAO_LISTA,
        itens,
        name,
    };
}

export function getItensFiltro(params, name) {
    return (dispatch) => {
        getFiltroItensListaCadatrada(params).then((data) => {
            if (data) { dispatch(listItens(data, name)); }
        });
    };
}
