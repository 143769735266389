import React, { ButtonHTMLAttributes, ReactNode } from 'react';
import classNames from 'classnames';
import styles from './SecondaryButton.module.scss';

export type SecondaryButtonProps = {
    icon?: ReactNode;
    skin?: 'blue' | 'red' | 'gray';
} & ButtonHTMLAttributes<HTMLButtonElement>;

const SecondaryButton = ({
    className, children, icon, skin = 'gray', ...props
}: SecondaryButtonProps) => (
    <button
        className={
            classNames(
                styles['info-secondary-button'],
                styles[`info-secondary-button-${skin}`],
                className,
            )
        }
        {...props}
    >
        {icon && (
            <span className={styles['info-secondary-button__icon__container']}>
                {icon}
            </span>
        )}
        {children && (
            <span className={styles['info-secondary-button__content']}>
                {children}
            </span>
        )}
    </button>
);

export { SecondaryButton };
