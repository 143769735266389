/* eslint-disable react/jsx-one-expression-per-line */
import plural from 'pluralize-ptbr';
import React, { useCallback, useMemo } from 'react';
import CurrencyFormat from 'react-currency-format';
import { useDispatch } from 'react-redux';
import { Whisper } from 'rsuite';
import { Competitor } from '../../../../../@types';
import { GerenciadorPrecos } from '../../../../../@types/GerenciadorPrecos';
import { Competitors } from '../../../../../components';
import { IPAMaths } from '../../../../../lib';
import { UPDATE_GERENCIADOR_DATAPOINT_BY_ID } from '../../../../../reducers/gerenciadorPrecos/datapoints';
import { handleCalculoPreco } from '../../utils';
import { ButtonsCompetitorPrice } from '../ButtonsCompetitorPrice';

type RowData = GerenciadorPrecos.RowData;

type ExpandedRowResumeCardProps = {
    rowData: RowData;
    resumeCardData?: Competitor | null;
    competitor?: Competitor | null;
};

const ExpandedRowResumeCard = ({
    rowData,
    resumeCardData,
    competitor,
}: ExpandedRowResumeCardProps) => {
    if (!resumeCardData) return null;

    const dispatch = useDispatch();

    const resumeTitle = useMemo(() => {
        return competitor ? 'Concorrência direta' : 'Concorrência Infopanel';
    }, [competitor]);

    const competitorsDays = useMemo(() => {
        return `${rowData.competitorDays ?? 7} DIAS`;
    }, [rowData]);

    const total = useMemo(
        () => resumeCardData?.countCompetitorStore ?? 0,
        [resumeCardData],
    );

    const totalText = useMemo(
        () => (total > 1 ? `${total} ${plural('loja')}` : `${total} loja`),
        [total],
    );

    const handleApplyPrice = useCallback(
        (
            competitorsPrice: number,
            rowData: RowData,
            competitivenessIndicator: RowData['competitivenessIndicator'],
        ) => {
            const { productsToBePricedId } = rowData;

            const newData = handleCalculoPreco(competitorsPrice, rowData);

            dispatch(
                UPDATE_GERENCIADOR_DATAPOINT_BY_ID({
                    id: productsToBePricedId,
                    data: {
                        ...newData,
                        competitivenessIndicator,
                        competitorsPrice,
                        novoCalculo: true,
                    },
                }),
            );
        },
        [dispatch],
    );

    const handleCalculoCompetitiviness = useCallback(
        (
            competitorsPrice: number,
            rowData: RowData,
            competitivenessIndicator: RowData['competitivenessIndicator'],
        ) => {
            const { price, productsToBePricedId } = rowData;

            const novaCompetitividade = IPAMaths.novaCompetitividade(
                price,
                competitorsPrice,
            );

            dispatch(
                UPDATE_GERENCIADOR_DATAPOINT_BY_ID({
                    id: productsToBePricedId,
                    data: {
                        novaCompetitividade,
                        competitivenessIndicator,
                        competitorsPrice,
                        novoCalculo: true,
                    },
                }),
            );
        },
        [dispatch],
    );

    if (!resumeCardData) {
        return (
            <p className="competitor-empty">
                Informações de concorrência indisponíveis
            </p>
        );
    }

    return (
        <Competitors.Section>
            <Competitors.Header>
                <Competitors.Icon icon="MdDescInsights" />
                <Competitors.Title>Concorrentes</Competitors.Title>
            </Competitors.Header>
            <Competitors.Card>
                <Competitors.CardHeader>
                    <Competitors.Title>{resumeTitle}</Competitors.Title>
                    <Competitors.Tag>{competitorsDays}</Competitors.Tag>
                    <Competitors.Subtitle>
                        Produto encontrado em <strong>{totalText}</strong>
                    </Competitors.Subtitle>
                </Competitors.CardHeader>
                <Competitors.CardContent>
                    <Whisper
                        trigger="click"
                        placement="bottom"
                        speaker={
                            <ButtonsCompetitorPrice
                                classNamecompetitor="buttons-competitor-price"
                                handleCalculoPreco={() => {
                                    handleApplyPrice(
                                        resumeCardData.competitorAverage ?? 0,
                                        rowData,
                                        'RULE_CPI_AVG',
                                    );
                                }}
                                handleCalculoCompetitiviness={() => {
                                    handleCalculoCompetitiviness(
                                        resumeCardData?.competitorAverage ?? 0,
                                        rowData,
                                        'RULE_CPI_AVG',
                                    );
                                }}
                            />
                        }
                    >
                        <Competitors.InfoBox
                            isActive={
                                rowData.competitivenessIndicator ===
                                'RULE_CPI_AVG'
                            }
                        >
                            <Competitors.Value>
                                <CurrencyFormat
                                    fixedDecimalScale
                                    decimalScale={2}
                                    value={resumeCardData.competitorAverage}
                                    displayType="text"
                                    prefix=" R$"
                                    decimalSeparator=","
                                    thousandSeparator="."
                                />
                            </Competitors.Value>
                            <Competitors.Label>
                                x̄ Preço
                                <br />
                                Médio
                            </Competitors.Label>
                        </Competitors.InfoBox>
                    </Whisper>

                    <Whisper
                        trigger="click"
                        placement="bottom"
                        speaker={
                            <ButtonsCompetitorPrice
                                classNamecompetitor="buttons-competitor-price"
                                handleCalculoPreco={() => {
                                    handleApplyPrice(
                                        resumeCardData.competitorLower ?? 0,
                                        rowData,
                                        'RULE_CPI_MIN',
                                    );
                                }}
                                handleCalculoCompetitiviness={() => {
                                    handleCalculoCompetitiviness(
                                        resumeCardData?.competitorLower ?? 0,
                                        rowData,
                                        'RULE_CPI_MIN',
                                    );
                                }}
                            />
                        }
                    >
                        <Competitors.InfoBox
                            isActive={
                                rowData.competitivenessIndicator ===
                                'RULE_CPI_MIN'
                            }
                        >
                            <Competitors.Value>
                                <CurrencyFormat
                                    fixedDecimalScale
                                    decimalScale={2}
                                    value={resumeCardData.competitorLower}
                                    displayType="text"
                                    prefix=" R$"
                                    decimalSeparator=","
                                    thousandSeparator="."
                                />
                            </Competitors.Value>
                            <Competitors.Label>
                                {'<'} Preço
                                <br />
                                Mais Baixo
                            </Competitors.Label>
                        </Competitors.InfoBox>
                    </Whisper>

                    <Whisper
                        trigger="click"
                        placement="bottom"
                        speaker={
                            <ButtonsCompetitorPrice
                                classNamecompetitor="buttons-competitor-price"
                                handleCalculoPreco={() => {
                                    handleApplyPrice(
                                        resumeCardData.competitorHigher ?? 0,
                                        rowData,
                                        'RULE_CPI_MAX',
                                    );
                                }}
                                handleCalculoCompetitiviness={() => {
                                    handleCalculoCompetitiviness(
                                        resumeCardData?.competitorHigher ?? 0,
                                        rowData,
                                        'RULE_CPI_MAX',
                                    );
                                }}
                            />
                        }
                    >
                        <Competitors.InfoBox
                            isActive={
                                rowData.competitivenessIndicator ===
                                'RULE_CPI_MAX'
                            }
                        >
                            <Competitors.Value>
                                <CurrencyFormat
                                    fixedDecimalScale
                                    decimalScale={2}
                                    value={resumeCardData.competitorHigher}
                                    displayType="text"
                                    prefix=" R$"
                                    decimalSeparator=","
                                    thousandSeparator="."
                                />
                            </Competitors.Value>
                            <Competitors.Label>
                                {'>'} Preço
                                <br />
                                Mais Alto
                            </Competitors.Label>
                        </Competitors.InfoBox>
                    </Whisper>
                </Competitors.CardContent>
            </Competitors.Card>
        </Competitors.Section>
    );
};

export default ExpandedRowResumeCard;
