import React, { HtmlHTMLAttributes } from 'react';
import { TableHeading } from '../../../../../../../components';
import { parseStringToPlural } from '../../../../../../../utils';
import { QuickActionFilter } from '../../../../../RevisaoPrecos/Components';

export type DetalhesDaNegociacaoTableHeadingProps =
    HtmlHTMLAttributes<HTMLDivElement> & {
        hasSelectedDatapoints: boolean;
        selectedDatapointsLength: number;
        totalElements: number;
        openModal: (name: string) => void;
        isSelected: boolean;
        status?: 'ONGOING' | 'CLOSED';
        onDownload: () => void;
        onDeleteDatapoints: () => void;
    };

export const DetalhesDaNegociacaoTableHeading = ({
    hasSelectedDatapoints,
    selectedDatapointsLength,
    totalElements,
    isSelected,
    status,
    openModal,
    onDownload,
    onDeleteDatapoints,
}: DetalhesDaNegociacaoTableHeadingProps) => (
    <TableHeading isSelected={isSelected}>
        <TableHeading.Container>
            <p>
                {hasSelectedDatapoints ? (
                    <strong>
                        {`${selectedDatapointsLength} produtos
                                    ${parseStringToPlural(
                                        'selecionado',
                                        selectedDatapointsLength,
                                    )}`}
                    </strong>
                ) : (
                    <>
                        Foram encontrados
                        <strong>
                            {` ${totalElements} ${parseStringToPlural(
                                'resultado',
                                totalElements,
                            )} `}
                        </strong>
                        no total
                    </>
                )}
            </p>
        </TableHeading.Container>
        <TableHeading.Container>
            {isSelected ? (
                <TableHeading.PrimaryButton
                    theme="ghost"
                    onClick={onDeleteDatapoints}
                >
                    REMOVER PRODUTOS
                </TableHeading.PrimaryButton>
            ) : (
                <>
                    <QuickActionFilter.Button
                        icon="MdFileDownload"
                        placeholder="Baixar todos os produtos"
                        onClick={onDownload}
                    />
                    <TableHeading.PrimaryButton
                        onClick={() => {
                            openModal(
                                status === 'ONGOING'
                                    ? 'fecharNegocio'
                                    : 'reabrirNegocio',
                            );
                        }}
                    >
                        {status === 'ONGOING'
                            ? 'Fechar negócio'
                            : 'reabrir NEGOCIAÇÃO'}
                    </TableHeading.PrimaryButton>
                </>
            )}
        </TableHeading.Container>
    </TableHeading>
);
