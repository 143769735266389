import AppBar from '@material-ui/core/AppBar';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Slide from '@material-ui/core/Slide';
import Toolbar from '@material-ui/core/Toolbar';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    Button,
    IconButton as ButtonPlus,
    ButtonToolbar,
    FlexboxGrid,
    Icon,
} from 'rsuite';

import {
    addFiltro,
    changeNoCacheValue,
    clearFiltrosDinamicos,
    deleteFiltro,
    getItensFiltro,
    getLatLon,
    searchEndereco,
    selectValueNoCache,
    setErro,
    setSingleReducer,
} from '../../../../../actions/actionsPainelGeral';
import { LoadingMenu } from '../../../../../components/LoadingSpinner';
import { getModelPanel } from '../utils';
import { shouldBlockMenuItem } from './SpeakerCustomFilter';

const Transition = React.forwardRef((props, ref) => (
    <Slide direction="up" ref={ref} {...props} />
));

function customFilterList(name, props) {
    const typeList = {
        rede: props.rede,
        tipoLoja: props.tipoLoja,
        loja: props.loja,
        fabricante: props.fabricante,
        marca: props.marca,
        conteudo: props.conteudo,
        tipoProduto: props.tipoProduto,
        secao: props.secao,
        categoria: props.categoria,
        mecanica: props.mecanica,
        grupoMidia: props.grupoMidia,
        midia: props.midia,
        uf: props.uf,
        cidade: props.cidade,
        endereco: props.endereco,
        tipoPreco: props.tipoPreco,
        origemPreco: props.origemPreco,
        raio: props.raio,
    };
    return typeList[name] || [];
}
class MobileMoreFiltersComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            open: false,
            openList: false,
        };

        this.openModalSelect = this.openModalSelect.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleSelectFilter = this.handleSelectFilter.bind(this);
        this.clearFilters = this.clearFilters.bind(this);
        this.closeList = this.closeList.bind(this);
        this.handleClean = this.handleClean.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.openListFilter = this.openListFilter.bind(this);
        this.changeEndereco = this.changeEndereco.bind(this);
        this.selectEndereco = this.selectEndereco.bind(this);
        this.selectRaio = this.selectRaio.bind(this);
    }

    shouldComponentUpdate(nextProps, nextState) {
        const {
            filtro,
            rede,
            tipoLoja,
            loja,
            fabricante,
            marca,
            conteudo,
            tipoProduto,
            secao,
            categoria,
            mecanica,
            grupoMidia,
            midia,
            uf,
            cidade,
            endereco,
            tipoPreco,
            origemPreco,
            raio,
        } = this.props;

        return (
            nextProps.filtro !== filtro ||
            nextProps.filtro?.name === 'endereco' ||
            nextProps.rede !== rede ||
            nextProps.tipoLoja !== tipoLoja ||
            nextProps.loja !== loja ||
            nextProps.fabricante !== fabricante ||
            nextProps.marca !== marca ||
            nextProps.conteudo !== conteudo ||
            nextProps.tipoProduto !== tipoProduto ||
            nextProps.secao !== secao ||
            nextProps.categoria !== categoria ||
            nextProps.mecanica !== mecanica ||
            nextProps.grupoMidia !== grupoMidia ||
            nextProps.midia !== midia ||
            nextProps.uf !== uf ||
            nextProps.cidade !== cidade ||
            nextProps.endereco !== endereco ||
            nextProps.tipoPreco !== tipoPreco ||
            nextProps.raio !== raio ||
            nextProps.origemPreco !== origemPreco ||
            nextProps !== this.props ||
            nextState !== this.state
        );
    }

    handleClose() {
        this.setState({
            open: false,
        });
    }

    handleSelectFilter(eventKey) {
        const {
            filtrosAdicionados,
            searchEndereco,
            getItensFiltro,
            addFiltro,
            filtros,
        } = this.props;
        if (
            !filtrosAdicionados.length ||
            (filtrosAdicionados.length &&
                filtrosAdicionados.findIndex(
                    (item) => item.name === eventKey,
                ) === -1)
        ) {
            if (eventKey === 'endereco') {
                searchEndereco('');
            } else {
                const data = getModelPanel(this.props, '', eventKey);
                getItensFiltro(data, eventKey, 'painel');
            }

            addFiltro(
                filtros[filtros.findIndex((item) => item.name === eventKey)],
            );

            this.setState({
                filterSelected: eventKey,
                openList: true,
            });
        }
    }

    handleClean(value, filterName) {
        const { setSingleReducer } = this.props;

        if (filterName === 'endereco') {
            this.changeEndereco(value);
        } else {
            setSingleReducer(filterName, [], []);
        }
    }

    handleSelect(item, filterName) {
        const { setSingleReducer } = this.props;

        if (filterName === 'endereco') {
            this.selectEndereco(item);
        } else if (
            customFilterList(filterName, this.props).value.indexOf(
                item.value,
            ) !== -1
        ) {
            const removeCache = customFilterList(
                filterName,
                this.props,
            ).cache.filter((remove) => item.value !== remove.value);

            const removeValue = customFilterList(
                filterName,
                this.props,
            ).value.filter((remove) => item.value !== remove);
            setSingleReducer(filterName, removeValue, removeCache);
        } else {
            const newCache = [
                ...customFilterList(filterName, this.props).cache,
                item,
            ];
            const newValue = [
                ...customFilterList(filterName, this.props).value,
                item.value,
            ];

            setSingleReducer(filterName, newValue, newCache);
        }
    }

    handleSearch(searchValue, filterName) {
        const { getItensFiltro } = this.props;

        if (filterName === 'endereco') {
            this.changeEndereco(searchValue);
        } else {
            getItensFiltro(
                getModelPanel(this.props, searchValue, filterName),
                filterName,
                'painel',
            );
        }
    }

    closeList(filter) {
        const { filtrosAdicionados, deleteFiltro } = this.props;

        if (!customFilterList(filter, this.props)?.value?.length) {
            const index = filtrosAdicionados.findIndex(
                (item) => item.name === filter,
            );
            deleteFiltro(index, filter);
        }

        this.setState({
            openList: false,
        });
    }

    clearFilters() {
        const { clearFiltrosDinamicos } = this.props;
        clearFiltrosDinamicos();
    }

    openListFilter(filterName) {
        const { getItensFiltro } = this.props;
        const data = getModelPanel(this.props, '', filterName);

        getItensFiltro(data, filterName, 'painel');
        this.setState({
            filterSelected: filterName,
            openList: true,
        });
    }

    openModalSelect() {
        this.setState({
            open: true,
        });
    }

    changeEndereco(value) {
        const { searchEndereco, endereco, changeNoCacheValue } = this.props;

        changeNoCacheValue('endereco', value);
        if (value.length) {
            searchEndereco({ query: value, token: endereco.token });
        }
    }

    selectEndereco(item) {
        const { getLatLon, endereco, changeNoCacheValue, setErro } = this.props;

        getLatLon({ place_id: item.place_id, token: endereco.token });
        changeNoCacheValue('endereco', item.value);
        this.selectRaio(5);

        if (endereco?.erro?.length) {
            setErro(null, 'endereco');
            const endereco = document.getElementsByName('endereco')[0];
            endereco.classList.remove('invalid-input');
        }
    }

    selectRaio(value) {
        const { changeNoCacheValue } = this.props;
        changeNoCacheValue('raio', value);
    }

    render() {
        const { tiposFiltro, filtrosAdicionados, usuario } = this.props;
        const { open, openList, filterSelected } = this.state;

        return (
            <>
                <ButtonToolbar>
                    <ButtonPlus
                        block
                        onClick={this.openModalSelect}
                        id="add-filter-mobile"
                        icon={<Icon icon="plus" />}
                        placement="left"
                    >
                        Adicionar filtros
                    </ButtonPlus>
                </ButtonToolbar>
                {filtrosAdicionados.length ? (
                    <FlexboxGrid>
                        <FlexboxGrid.Item
                            colspan={24}
                            className="selected-filters-mobile"
                        >
                            {filtrosAdicionados?.map((filtro) => (
                                <div
                                    key={filtro.name}
                                    className="selected-filters-mobile__wrapper"
                                    onClick={() =>
                                        this.openListFilter(filtro.name)
                                    }
                                >
                                    <span className="title">
                                        {filtro.title}{' '}
                                        {filtro.name === 'endereco'
                                            ? ' - Raio 5km'
                                            : null}
                                    </span>
                                    {filtro.name !== 'endereco' ? (
                                        <span className="quantity">
                                            {
                                                customFilterList(
                                                    filtro.name,
                                                    this.props,
                                                )?.value?.length
                                            }
                                        </span>
                                    ) : null}
                                </div>
                            ))}
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                ) : null}
                <Dialog
                    className="dialog-more-filters"
                    fullScreen
                    open={open}
                    onClose={this.handleClose}
                    TransitionComponent={Transition}
                >
                    <AppBar className="dialog-more-filters__header">
                        <Toolbar className="header">
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={this.handleClose}
                                aria-label="close"
                            >
                                <CloseIcon />
                            </IconButton>
                        </Toolbar>
                    </AppBar>

                    <DialogContent className="dialog-more-filters__content">
                        {tiposFiltro?.map((tipo) => (
                            <FlexboxGrid
                                key={tipo.title}
                                className="filter-type"
                            >
                                <FlexboxGrid.Item
                                    colspan={24}
                                    className="filter-type__category"
                                >
                                    <p>
                                        <Icon icon={tipo.icon} />
                                        {tipo.title}
                                    </p>
                                </FlexboxGrid.Item>

                                <FlexboxGrid.Item
                                    colspan={24}
                                    className="filter-type__itens"
                                >
                                    {tipo.itens.map((item) => (
                                        <Button
                                            key={item.name}
                                            name={item.name}
                                            disabled={
                                                usuario?.servicoPanel
                                                    ?.pacotePainel.name ===
                                                    'FREE' &&
                                                shouldBlockMenuItem(item.name)
                                            }
                                            className={
                                                filtrosAdicionados.findIndex(
                                                    (filter) =>
                                                        filter.name ===
                                                        item.name,
                                                ) !== -1
                                                    ? 'selected item'
                                                    : 'item'
                                            }
                                            appearance="subtle"
                                            onClick={
                                                filtrosAdicionados.findIndex(
                                                    (filter) =>
                                                        filter.name ===
                                                        item.name,
                                                ) !== -1
                                                    ? () =>
                                                          this.openListFilter(
                                                              item.name,
                                                          )
                                                    : () =>
                                                          this.handleSelectFilter(
                                                              item.name,
                                                          )
                                            }
                                        >
                                            <span>{item.title}</span>
                                            {usuario?.servicoPanel?.pacotePainel
                                                .name === 'FREE' &&
                                            shouldBlockMenuItem(item.name) ? (
                                                <span className="item__block-icon">
                                                    <Icon icon="lock" />
                                                </span>
                                            ) : null}
                                        </Button>
                                    ))}
                                </FlexboxGrid.Item>
                            </FlexboxGrid>
                        ))}
                    </DialogContent>
                    <AppBar
                        position="fixed"
                        className="dialog-more-filters__footer"
                    >
                        <Button
                            appearance="subtle"
                            disabled={!filtrosAdicionados?.length}
                            onClick={this.clearFilters}
                        >
                            Limpar filtros
                        </Button>
                    </AppBar>
                </Dialog>
                <DialogListItensFiltro
                    filterName={filterSelected}
                    dataList={customFilterList(filterSelected, this.props)}
                    openList={openList}
                    handleClose={this.closeList}
                    handleSearch={this.handleSearch}
                    handleSelect={this.handleSelect}
                    handleClean={this.handleClean}
                />
            </>
        );
    }
}

const DialogListItensFiltro = (props) => {
    const {
        dataList,
        filterName,
        openList,
        handleClose,
        handleClean,
        handleSelect,
        handleSearch,
    } = props;

    return (
        <Dialog
            className="dialog-list-itens"
            fullScreen
            open={openList}
            onClose={() => handleClose(filterName)}
            TransitionComponent={Transition}
        >
            <AppBar className="dialog-select__header">
                <Toolbar className="header">
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={() => handleClose(filterName)}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>

                    <div className="header__search-input">
                        <div className="search-input__icon">
                            <SearchIcon />
                        </div>
                        <InputBase
                            inputProps={{ 'aria-label': 'search' }}
                            onChange={(event) =>
                                handleSearch(event.target.value, filterName)
                            }
                            placeholder={
                                filterName === 'endereco'
                                    ? 'Digite o endereço que deseja'
                                    : null
                            }
                        />
                    </div>
                </Toolbar>
            </AppBar>
            {filterName ? (
                <List className="dialog-select__list">
                    <LoadingMenu area="panel-filter" />
                    {dataList?.lista?.map((item) => {
                        const labelId = `checkbox-list-label-${item.value}`;

                        return (
                            <ListItem
                                key={item.value}
                                onClick={() => handleSelect(item, filterName)}
                            >
                                <ListItemIcon>
                                    <Checkbox
                                        edge="start"
                                        checked={
                                            dataList?.value?.indexOf(
                                                item.value,
                                            ) !== -1 || false
                                        }
                                        tabIndex={-1}
                                        disableRipple
                                        inputProps={{
                                            'aria-labelledby': labelId,
                                        }}
                                    />
                                </ListItemIcon>
                                <ListItemText
                                    id={item.value}
                                    primary={item.label}
                                />
                            </ListItem>
                        );
                    })}
                </List>
            ) : null}
            <AppBar position="fixed" className="dialog-list-itens__footer">
                <Button
                    appearance="subtle"
                    disabled={!dataList?.value?.length}
                    onClick={() => handleClean([], filterName)}
                >
                    Limpar
                </Button>
                <Button
                    appearance="primary"
                    onClick={() => handleClose(filterName)}
                >
                    Aplicar
                </Button>
            </AppBar>
        </Dialog>
    );
};

const mapStateToProps = (store) => ({
    tiposFiltro: store.painelGeralDataReducer.tiposFiltro,
    filtrosAdicionados: store.painelGeralDataReducer.filtrosAdicionados,
    filtros: store.painelGeralDataReducer.filtros,
    rede: store.painelGeralDataReducer.rede,
    tipoLoja: store.painelGeralDataReducer.tipoLoja,
    loja: store.painelGeralDataReducer.loja,
    fabricante: store.painelGeralDataReducer.fabricante,
    marca: store.painelGeralDataReducer.marca,
    conteudo: store.painelGeralDataReducer.conteudo,
    tipoProduto: store.painelGeralDataReducer.tipoProduto,
    secao: store.painelGeralDataReducer.secao,
    categoria: store.painelGeralDataReducer.categoria,
    mecanica: store.painelGeralDataReducer.mecanica,
    grupoMidia: store.painelGeralDataReducer.grupoMidia,
    midia: store.painelGeralDataReducer.midia,
    uf: store.painelGeralDataReducer.uf,
    cidade: store.painelGeralDataReducer.cidade,
    endereco: store.painelGeralDataReducer.endereco,
    tipoPreco: store.painelGeralDataReducer.tipoPreco,
    origemPreco: store.painelGeralDataReducer.origemPreco,
    raio: store.painelGeralDataReducer.raio,
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            addFiltro,
            getItensFiltro,
            deleteFiltro,
            clearFiltrosDinamicos,
            searchEndereco,
            selectValueNoCache,
            getLatLon,
            setErro,
            changeNoCacheValue,
            setSingleReducer,
        },
        dispatch,
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(MobileMoreFiltersComponent);
