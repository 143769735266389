import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import * as utils from '../../pages/CalculadoraPrevisaoDemanda/utils';

export type FilterDataProps = Record<string, unknown> & {
    label: string;
    value: string;
    description: string;
};

type FilterItemProps = {
    list: FilterDataProps[];
    cache: FilterDataProps[];
    value: string[];
};

export type FilterRequestBodyProps = {
    product_id: number | null,
    product_description: string,
    location_type: 'UF' | 'REGIONAL' | null,
    location: string,
    channel: string,
    sub_channel: string,
    competitor_product_id: number | null,
    competitor_product_description: string,
    payment_method: 'À Vista' | '30 Dias' | '45 Dias' | '60 Dias' | '90 Dias',
};

export type FiltersNameProps =
    | 'produtos'
    | 'produtoType'
    | 'produtoConcorrente'
    | 'uf'
    | 'cidade'
    | 'localType'
    | 'canal'
    | 'subCanal'
    | 'pagamentoType'
    | 'regional';

export type FiltersRequestBodyNameProps =
    | 'product_id'
    | 'product_description'
    | 'location_type'
    | 'location'
    | 'channel'
    | 'sub_channel'
    | 'competitor_product_id'
    | 'competitor_product_description'
    | 'payment_method';

export type CalculadoraPrevisaoDemandaFiltrosStateProps = {
    produtos: FilterItemProps;
    produtoType: FilterItemProps;
    produtoConcorrente: FilterItemProps;
    uf: FilterItemProps;
    cidade: FilterItemProps;
    localType: FilterItemProps;
    canal: FilterItemProps;
    subCanal: FilterItemProps;
    pagamentoType: FilterItemProps;
    filterRequestBody: FilterRequestBodyProps;
    mainRequestBody: any;
    regional: FilterItemProps;
};

const initialState: CalculadoraPrevisaoDemandaFiltrosStateProps = {
    produtos: {
        list: [],
        cache: [],
        value: [],
    },
    produtoType: {
        list: [],
        cache: [],
        value: [],
    },
    produtoConcorrente: {
        list: [],
        cache: [],
        value: [],
    },
    uf: {
        list: [],
        cache: [],
        value: [],
    },
    cidade: {
        list: [],
        cache: [],
        value: [],
    },
    localType: {
        list: [],
        cache: [],
        value: [],
    },
    canal: {
        list: [],
        cache: [],
        value: [],
    },
    subCanal: {
        list: [],
        cache: [],
        value: [],
    },
    pagamentoType: {
        list: [],
        cache: [],
        value: [],
    },
    regional: {
        list: [],
        cache: [],
        value: [],
    },
    filterRequestBody: {
        product_id: null,
        product_description: '',
        location_type: null,
        location: '',
        channel: '',
        sub_channel: '',
        competitor_product_id: null,
        competitor_product_description: '',
        payment_method: 'À Vista',
    },
    mainRequestBody: {
        scenario: {
          scenario_id: '',
          scenario_name: '',
          simulation_date: '',
          responsible: '',
        },
        outputs: {
          elasticity: 0,
          elasticity_reference_price: 0,
          base_price_scenario: {
            price: 0,
            margin: 0,
            demand_forecast: 0,
            revenue_forecast: 0,
            profit_forecast: 0,
          },
          new_price_scenario: {
            price: 0,
            margin: 0,
            demand_forecast: 0,
            revenue_forecast: 0,
            profit_forecast: 0,
          },
          base_to_new_variations: {
            price: 0,
            margin: 0,
            demand_forecast: 0,
            revenue_forecast: 0,
            profit_forecast: 0,
          },
        },
    },
};

const setSelectObject = (id: string | number, description: string) => {
    const obj = {
        label: description,
        value: id,
    };

    return obj;
};

const setSelectProductObject = (id: string | number, description: string) => {
    const obj = {
        label: `${id} - ${description}`,
        value: id,
    };

    return obj;
};

const slice = createSlice({
    name: 'filtros-state',
    initialState,
    reducers: {
        setFilterList(state, { payload }: PayloadAction<{
                name: FiltersNameProps;
                list: FilterDataProps[];
            }>) {
            state[payload.name].list = payload.list;
        },
        setFilterCacheValue(state, { payload }: PayloadAction<{
            name: FiltersNameProps;
            data: {cache: FilterDataProps[], value: string[]};
        }>) {
            state[payload.name].cache = payload.data.cache;
            state[payload.name].value = payload.data.value;
        },
        addFilterState(
            state,
            {
                payload,
            }: PayloadAction<{
                name: FiltersNameProps;
                data: Partial<FilterItemProps>;
            }>,
        ) {
            if (payload.data.value) {
                if (!state[payload.name]) return;
                state[payload.name].value = payload.data.value;
            }
            if (payload.data.cache) {
                if (!state[payload.name]) return;
                state[payload.name].cache = payload.data.cache;
            }
        },
        clearFilters() {
            return initialState;
        },
        resetFilters() {
            return initialState;
        },
        selectFilterValue(state, { payload }: PayloadAction<{
            name: FiltersRequestBodyNameProps;
            data: any;
        }>) {
            state.filterRequestBody[payload.name] = payload.data;
        },
        setFiltersFromEditScenario(state, { payload }: PayloadAction<{
            data: any;
        }>) {
            const {
                product_id,
                product_description,
                channel,
                sub_channel,
                payment_method,
                competitor_product_id,
                competitor_product_description,
                location,
                location_type,
            } = payload.data.query;

            state.filterRequestBody = payload.data.query;
            state.produtos = {
                list: [setSelectProductObject(product_id, product_description)],
                cache: [setSelectProductObject(product_id, product_description)],
                value: [product_id],
            };
            state.canal = {
                list: [setSelectObject(channel, channel)],
                cache: [setSelectObject(channel, channel)],
                value: [channel],
            };
            state.subCanal = {
                list: [setSelectObject(sub_channel, sub_channel)],
                cache: [setSelectObject(sub_channel, sub_channel)],
                value: [sub_channel],
            };
            state.pagamentoType = {
                list: [setSelectObject(payment_method, payment_method)],
                cache: [setSelectObject(payment_method, payment_method)],
                value: [payment_method],
            };
            state.produtoConcorrente = {
                list: [setSelectProductObject(competitor_product_id, competitor_product_description)],
                cache: [setSelectProductObject(competitor_product_id, competitor_product_description)],
                value: [competitor_product_id],
            };
            state.uf = location_type === 'UF' ? {
                list: [setSelectObject(location, utils.ufToName[location])],
                cache: [setSelectObject(location, utils.ufToName[location])],
                value: [location],
            } : state.uf;
            state.regional = location_type !== 'UF' ? {
                list: [setSelectObject(location, location)],
                cache: [setSelectObject(location, location)],
                value: [location],
            } : state.regional;
        },
    },
});

const {
    setFilterList,
    setFilterCacheValue,
    clearFilters,
    addFilterState,
    resetFilters,
    selectFilterValue,
    setFiltersFromEditScenario,
} = slice.actions;

export {
    addFilterState, clearFilters, setFiltersFromEditScenario,
    resetFilters, selectFilterValue, setFilterCacheValue, setFilterList,
};

export default slice.reducer;
