import React from 'react';
import { Modal, Button } from 'rsuite';

const ModalConfirmacao = ({
    show, title, message, onCancel, onConfirm, confirmButton, cancelButton,
}) => (

    <Modal backdrop show={show}>
        <Modal.Header closeButton={false}>
            <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <p>{message}</p>
        </Modal.Body>
        <Modal.Footer>
            <Button onClick={onCancel} appearance="ghost">
                <strong>{cancelButton}</strong>
            </Button>
            <Button onClick={onConfirm} appearance="primary">
                <strong>{confirmButton}</strong>
            </Button>
        </Modal.Footer>
    </Modal>
);

export default ModalConfirmacao;
