import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../@types/RootState';

export type CategoryLevel =
    | 'categoryLevel1'
    | 'categoryLevel2'
    | 'categoryLevel3'
    | 'categoryLevel4'
    | 'categoryLevel5'
    | 'categoryLevel6'
    | 'categoryLevel7'
    | 'productIds';

export type Segment = {
    level: number;
    name: string;
    field: CategoryLevel;
    label: string;
    value: CategoryLevel;
};

const initialState: Segment[] = [];

const slice = createSlice({
    name: 'gerenciadorPrecos/segmentos',
    initialState,
    reducers: {
        SET_SEGMENTOS: (_, action: PayloadAction<Segment[]>) => {
            return action.payload;
        },
    },
});

const selectorSegmentos = (state: RootState) => {
    return state.gerenciadorPrecosReducer.segmentos;
};

const { SET_SEGMENTOS } = slice.actions;

export { SET_SEGMENTOS, selectorSegmentos };

export default slice.reducer;
