import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../@types/RootState';

type LastIntegrationDate = {
    lastExecutionDate: string | null;
    engineStatus: 'NOT_UPDATED' | 'UPDATED' | 'UPDATING' | null;
    parsedDate: string | null;
};

const initialState: LastIntegrationDate = {
    lastExecutionDate: null,
    engineStatus: null,
    parsedDate: null,
};

const slice = createSlice({
    name: 'ipa/setup/lastIntegrationDate',
    initialState,
    reducers: {
        SET_LAST_INTEGRATION_DATE: (
            _state,
            { payload }: PayloadAction<LastIntegrationDate>,
        ) => {
            return payload;
        },
    },
});

export const { SET_LAST_INTEGRATION_DATE } = slice.actions;

export const selectorLastIntegrationDate = (state: RootState) => {
    return state.reducerIPA.lastIntegrationDate;
};

export default slice.reducer;
