import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import {
    Button, FlexboxGrid, HelpBlock, Col, Content, DateRangePicker,
} from 'rsuite';
import '@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css';
import { Calendar } from '@hassanmojab/react-modern-calendar-datepicker';
import { FormGroup } from '@material-ui/core';
import moment from 'moment';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const myCustomLocale = {
    months: [
        'Janeiro',
        'Fevereiro',
        'Março',
        'Abril',
        'Maio',
        'Junho',
        'Julho',
        'Agosto',
        'Setembro',
        'Outubro',
        'Novembro',
        'Dezembro',
    ],
    weekDays: [
        {
            name: 'Domingo', // used for accessibility
            short: 'D', // displayed at the top of days' rows
            isWeekend: true, // is it a formal weekend or not?
        },
        {
            name: 'Segunda',
            short: 'S',
        },
        {
            name: 'Terça',
            short: 'T',
        },
        {
            name: 'Quarta',
            short: 'Q',
        },
        {
            name: 'Quinta',
            short: 'Q',
        },
        {
            name: 'Sexta',
            short: 'S',
        },
        {
            name: 'Sábado',
            short: 'S',
            isWeekend: true,
        },
    ],
    weekStartingIndex: 0,
    getToday(gregorainTodayObject) {
        return gregorainTodayObject;
    },

    // return a native JavaScript date here
    toNativeDate(date) {
        return new Date(date.year, date.month - 1, date.day);
    },

    // return a number for date's month length
    getMonthLength(date) {
        return new Date(date.year, date.month, 0).getDate();
    },

    // return a transformed digit to your locale
    transformDigit(digit) {
        return digit;
    },

    // texts in the date picker
    nextMonth: 'Next Month',
    previousMonth: 'Previous Month',
    openMonthSelector: 'Open Month Selector',
    openYearSelector: 'Open Year Selector',
    closeMonthSelector: 'Close Month Selector',
    closeYearSelector: 'Close Year Selector',
    defaultPlaceholder: 'Select...',

    // for input range value
    from: 'from',
    to: 'to',

    digitSeparator: ',',

    // if your provide -2 for example, year will be 2 digited
    yearLetterSkip: 0,

    // is your language rtl or ltr?
    isRtl: false,
};

class MobileDatePickerComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            date: { from: null, to: null },
            open: false,
        };

        this.openModalSelect = this.openModalSelect.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.apply = this.apply.bind(this);
        this.clearSelection = this.clearSelection.bind(this);
        this.handleDatePickerChange = this.handleDatePickerChange.bind(this);
        this.setShortCutDate = this.setShortCutDate.bind(this);
    }

    openModalSelect() {
        this.setState({
            open: true,
        });
    }

    handleClose() {
        this.setState({
            open: false,
        });
    }

    apply() {
        const { date } = this.state;
        const { onChange } = this.props;

        if (date?.from && date?.to) {
            const from = moment({ year: date.from.year, month: date.from.month - 1, date: date.from.day }).toDate();
            const to = moment({ year: date.to.year, month: date.to.month - 1, date: date.to.day }).toDate();

            const newDate = [from, to];
            onChange(newDate);
        } else {
            onChange([]);
        }

        this.handleClose();
    }

    clearSelection() {
        this.setState({
            date: { from: null, to: null },
        });
    }

    handleDatePickerChange(newDate) {
        this.setState({
            date: newDate,
        });
    }

    setShortCutDate(shortCut) {
        const today = moment();
        const from = moment().subtract(shortCut, 'days');

        this.setState({
            date: {
                from: {
                    day: from.date(),
                    month: from.month() + 1,
                    year: from.year(),
                },
                to: {
                    day: today.date(),
                    month: today.month() + 1,
                    year: today.year(),
                },
            },
        });
    }

    render() {
        const {
            maxDate,
            minDate,
            errors,
            dataInicio,
            dataFim,
        } = this.props;
        const { date, open } = this.state;

        const minimumDate = {
            year: moment(minDate).year(),
            month: moment(minDate).month() + 1,
            day: moment(minDate).date(),
        };

        const maximumDate = {
            year: moment(maxDate).year(),
            month: moment(maxDate).month() + 1,
            day: moment(maxDate).date(),
        };

        return (
            <>
                <FormGroup>
                    <DateRangePicker
                        open={false}
                        cleanable={false}
                        onClick={() => this.openModalSelect()}
                        id="date-filter"
                        name="data"
                        placeholder="Data*"
                        value={dataInicio && dataFim ? [dataInicio, dataFim] : []}
                    />
                    <HelpBlock className="filtros__message filtros__message_invalid" name="erroData">{errors.data}</HelpBlock>
                </FormGroup>
                <Dialog className="dialog-date" fullScreen open={open} onClose={this.handleClose} TransitionComponent={Transition}>
                    <AppBar className="dialog-date__header">
                        <Toolbar className="header">
                            <IconButton edge="start" color="inherit" onClick={this.handleClose} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                    <DialogContent className="dialog-date__content">
                        <Calendar
                            value={date}
                            onChange={this.handleDatePickerChange}
                            calendarClassName="content__calendar"
                            colorPrimary="#3597fa" // added this
                            colorPrimaryLight="rgba(52, 152, 255, 0.33)"
                            minimumDate={minimumDate}
                            maximumDate={maximumDate}
                            locale={myCustomLocale}
                        />
                        <Content className="short-cut">
                            <FlexboxGrid>
                                <FlexboxGrid.Item componentClass={Col} xs={8}>
                                    <Button block appearance="ghost" onClick={() => this.setShortCutDate(30)}>
                                        30 dias
                                    </Button>
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item componentClass={Col} xs={8}>
                                    <Button block appearance="ghost" onClick={() => this.setShortCutDate(60)}>
                                        60 dias
                                    </Button>
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item componentClass={Col} xs={8}>
                                    <Button block appearance="ghost" onClick={() => this.setShortCutDate(90)}>
                                        90 dias
                                    </Button>
                                </FlexboxGrid.Item>
                            </FlexboxGrid>
                        </Content>
                    </DialogContent>

                    <AppBar position="fixed" className="dialog-date__footer">
                        <Button appearance="subtle" disabled={date?.length < 2} onClick={this.clearSelection}>
                            Limpar
                        </Button>
                        <Button appearance="primary" onClick={this.apply}>
                            Aplicar
                        </Button>

                    </AppBar>
                </Dialog>
            </>
        );
    }
}

const mapStateToProps = (store) => ({
    dataInicio: store.painelGeralDataReducer.dataInicio,
    dataFim: store.painelGeralDataReducer.dataFim,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({

}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MobileDatePickerComponent);
