import { trackPromise } from 'react-promise-tracker';

import api from '../../../../utils/API';

export const getUltimaAtualizacao = (usuario) =>
    api({
        url: `${process.env.REACT_APP_CLIENTE_URL}/extracao/ultima_atualizacao`,
        method: 'GET',
        params: {
            login: usuario,
        },
        expectToken: true,
    }).then((resp) => resp.data);

export const getDimensoes = (data) =>
    api({
        url: `${process.env.REACT_APP_CLIENTE_URL}/extracao/dimensoes`,
        method: 'GET',
        data: JSON.stringify(data),
        expectToken: true,
    }).then((resp) => resp.data);

export const getPreview = (data, pagination) =>
    trackPromise(
        api({
            url: `${process.env.REACT_APP_CLIENTE_URL}/extracao/find`,
            method: 'POST',
            params: {
                pageSize: pagination.size,
                page: pagination.page,
            },
            data: JSON.stringify(data),
            expectToken: true,
        }).then((resp) => resp.data),
    );

export const getEcomPreview = (data, pagination) =>
    trackPromise(
        api({
            url: `${process.env.REACT_APP_INTEGRACAO_ECOM_URL}/extracao/find`,
            method: 'POST',
            params: {
                pageSize: pagination.size,
                page: pagination.page,
            },
            data: JSON.stringify(data),
            expectToken: true,
        }).then((resp) => resp.data),
    );

export const getDownload = (data) =>
    trackPromise(
        api({
            url: `${process.env.REACT_APP_CLIENTE_URL}/extracao/download`,
            method: 'POST',
            data: JSON.stringify(data),
            expectToken: true,
            responseType: 'arraybuffer',
        })
            .then((res) => {
                if (res.status === 200) {
                    return res.data;
                }
                return res;
            })
            .then((download) => {
                if (!download.status) {
                    const blob = new Blob([download]);
                    const hiddenElement = document.createElement('a');
                    hiddenElement.href = window.URL.createObjectURL(blob);
                    hiddenElement.target = '_blank';
                    hiddenElement.download = `extrator_dados.${data.extensao}`;
                    hiddenElement.click();
                } else {
                    return download;
                }
            }),
    );

export const getDownloadEcom = (data, extension) =>
    trackPromise(
        api({
            url: `${process.env.REACT_APP_INTEGRACAO_ECOM_URL}/extracao/download`,
            method: 'POST',
            params: {
                extension,
            },
            data: JSON.stringify(data),
            expectToken: true,
            responseType: 'arraybuffer',
        })
            .then((res) => {
                if (res.status === 200) {
                    return res.data;
                }
                return res;
            })
            .then((download) => {
                if (!download.status) {
                    const blob = new Blob([download]);
                    const hiddenElement = document.createElement('a');
                    hiddenElement.href = window.URL.createObjectURL(blob);
                    hiddenElement.target = '_blank';
                    hiddenElement.download = `extrator_dados.${extension}`;
                    hiddenElement.click();
                } else {
                    return download;
                }
            }),
    );

export const getFontesEcom = (data) =>
    trackPromise(
        api({
            url: `${process.env.REACT_APP_INTEGRACAO_ECOM_URL}/extracao/fonte`,
            method: 'GET',
            params: {
                fonte: data.fonte,
            },
            expectToken: true,
        }).then((resp) => resp.data),
        'ecom-mpdv',
    );

export const getDimensoesEcom = (data) =>
    api({
        url: `${process.env.REACT_APP_INTEGRACAO_ECOM_URL}/extracao/dimensoes`,
        method: 'GET',
        data: JSON.stringify(data),
        expectToken: true,
    }).then((resp) => resp.data);
