import React, { Component } from 'react';
import {
    Table, Container, Content, FlexboxGrid, IconButton, Icon, Notification,
} from 'rsuite';
import moment from 'moment';
import { LoadingSpiner } from '../../../../components/LoadingSpinner';

import { getRelatoriosCliente } from './services';
import * as UsuarioService from '../../../../services/UsuarioService';

const { Column, HeaderCell, Cell } = Table;

class DownloadRelatorioPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [],
        };
    }

    componentDidMount() {
        UsuarioService.getCliente(JSON.parse(localStorage.getItem('usuario'))?.email).then((cliente) => {
            if (cliente) {
                getRelatoriosCliente(cliente).then(
                    (data) => {
                        if (data.length) {
                            data.sort((a, b) => new Date(b.dataEnvio) - new Date(a.dataEnvio));
                            data.forEach((item) => {
                                item.dataEnvio = moment(item.dataEnvio).format('DD/MM/YYYY');
                            });
                            this.setState({ data });
                        }
                    },
                );
            }
        });
    }

    render() {
        const { match } = this.props;
        const { data } = this.state;
        return (
            <Container className="main-container download-relatorio-page">
                <Content>
                    <FlexboxGrid>
                        <FlexboxGrid.Item colspan={24}>
                            <LoadingSpiner size="md" />

                            {data && data.length ? (
                                <Table
                                    height={400}
                                    data={data}
                                    rowClassName={(data) => {
                                        let status;
                                        if (data && data.codigoRelatorioEnvio && data.codigoRelatorioEnvio.toString() === match.params.id) {
                                            status = 'is-active';
                                        }
                                        return status;
                                    }}
                                    autoHeight
                                >
                                    <Column flexGrow={2}>
                                        <HeaderCell>Nome do relatório</HeaderCell>
                                        <Cell dataKey="nomeRelatorio" />
                                    </Column>

                                    <Column flexGrow={2}>
                                        <HeaderCell>Data de envio</HeaderCell>
                                        <Cell dataKey="dataEnvio" />
                                    </Column>

                                    <Column width={120} fixed="right">
                                        <HeaderCell />

                                        <Cell style={{
                                            padding: 0, textAlign: 'center', display: 'table-cell', verticalAlign: 'middle',
                                        }}
                                        >
                                            {(rowData) => {
                                                function handleAction() {
                                                    const hiddenElement = document.createElement('a');
                                                    hiddenElement.href = `${rowData.linkRelatorio}`;
                                                    hiddenElement.target = '_blank';
                                                    hiddenElement.download = `${`${rowData.nomeRelatorio}`.replace(' ', '_')}.csv`;
                                                    hiddenElement.click();
                                                }
                                                function openNotification() {
                                                    Notification.success({
                                                        title: 'Link copiado com sucesso!',
                                                    });
                                                }
                                                function handleCopy() {
                                                    const copy = document.createElement('input');
                                                    document.body.appendChild(copy);
                                                    copy.setAttribute('value', rowData.linkRelatorio);
                                                    copy.select();
                                                    document.execCommand('copy');
                                                    document.body.removeChild(copy);
                                                    openNotification();
                                                }
                                                return (
                                                    <div>
                                                        <span title="Download" className="mg-5">
                                                            <IconButton onClick={handleAction} icon={<Icon icon="cloud-download" />} circle size="md" />
                                                        </span>
                                                        <span title="Copiar link de download" className="mg-5">
                                                            <IconButton onClick={handleCopy} icon={<Icon icon="share-alt" />} circle size="md" />
                                                        </span>
                                                    </div>
                                                );
                                            }}
                                        </Cell>
                                    </Column>
                                </Table>
                            ) : null}
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </Content>
            </Container>
        );
    }
}
export default DownloadRelatorioPage;
