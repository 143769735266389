import React from 'react';
import noImage from '../../../../../../assets/icons/no-image.svg';
import styles from './styles.module.scss';

const ProductInfoBox = ({
    image,
    productTitle,
    gtinValue,
    secaoValue,
    categoryValue,
}) => (
    <div className={styles.container}>
        <div className={styles['product-image']}>
            <img
                alt="imagem produto"
                width="80"
                height="80"
                loading="lazy"
                className="card-products__img"
                src={image || noImage}
                onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = noImage;
                }}
            />
        </div>
        <div className={styles['product-info']}>
            <h6 className={styles['product-info-title']}>{productTitle}</h6>
            <div className={styles['product-detail']}>
                <div className={styles['detail-wrapper']}>
                    <div className={styles['detail-title']}>Gtin</div>
                    <div className={styles['detail-value']}>{gtinValue}</div>
                </div>
                <div className={styles['detail-wrapper']}>
                    <div className={styles['detail-title']}>Seção</div>
                    <div className={styles['detail-value']}>{secaoValue}</div>
                </div>
                <div>
                    <div className={styles['detail-title']}>Categoria</div>
                    <div className={styles['detail-value']}>
                        {categoryValue}
                    </div>
                </div>
            </div>
        </div>
    </div>
);

export default ProductInfoBox;
