import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    FiltersNameProps,
    addFilterState,
    clearFilters,
    setFilterList,
    selectFilterValue,
} from '../../../reducers/calculadoraPrevisaoDemanda/filtros';
import { selectorCalculadoraPrevisaoDemanda } from '../../../reducers/calculadoraPrevisaoDemanda/calculadoraPrevisaoDemandaReducer';
import * as service from '../services';
import * as utils from '../utils';

export const useSelectChannel = () => {
    const [timer, setTimer] = useState(null);

    const dispatch = useDispatch();

    const { filtros } = useSelector(
        selectorCalculadoraPrevisaoDemanda,
    );

    const setList = (name, list) => {
        const newList = list.map((item) => ({
            value: item.key,
            label: item.value,
        }));

        const cacheList = filtros[name].cache;
        const removeDuplicate = newList.filter(
            (i) => !filtros[name].value.includes(i.value),
        );
        const concatList = filtros[name].value.length
            ? cacheList.concat(removeDuplicate)
            : newList;

        dispatch(setFilterList({ name, list: concatList }));
    };

    const handleSelectCanal = (name: FiltersNameProps, value: string, item: any) => {
        dispatch(selectFilterValue({ name: utils.requestBodyNameItems[name], data: value }));
        dispatch(addFilterState({
            name,
            data: {
                cache: [item],
                value: [value],
            },
        }));
    };

    const searchCanal = async (name: string, query: string) => {
        const subCanalData = { channel: filtros.canal.value[0], query };
        const params = name === 'subCanal' ? subCanalData : query;
        const requestType = {
            canal: service.getChannel,
            subCanal: service.getSubChannel,
            pagamentoType: service.getPaymentMethod,
        };

        const response = await requestType[name](params);

        if (response) {
            setList(name, response);
        }
    };

    const onSearchCanal = (name: string, inputValue: string) => {
        clearTimeout(timer);

        const newTimer = setTimeout(() => {
            searchCanal(name, inputValue);
        }, 500);

        setTimer(newTimer);
    };

    const handleClearCanal = (name: FiltersNameProps) => {
        dispatch(selectFilterValue({ name: utils.requestBodyNameItems[name], data: '' }));
        dispatch(addFilterState({
            name,
            data: {
                cache: [],
                value: [],
            },
        }));
    };

    const handleClearAllCanal = () => {
        const filterArray = ['canal', 'subCanal', 'pagamentoType'];

        filterArray.map((item) => handleClearCanal(item));
    };

    return {
        canalHandlers: {
            onSearchCanal,
            handleSelectCanal,
            handleClearCanal,
            handleClearAllCanal,
        },
    };
};
