import React from 'react';
import arrowRightIcon from '../../../../assets/icons/icon_arrow-right-gray.svg';
import { LoadingMenu } from '../../../../components/LoadingSpinner';
import SelectPill from '../../../../components/SelectPill';
import styles from './SalesChannel.module.scss';

type SalesChannelProps = {
    channelHandlers: any;
    filterData?: any;
}

export const SalesChannel = ({
    channelHandlers,
    filterData,
}: SalesChannelProps) => {
    return (
        <div className={styles['select-pill-wrapper']}>

            <SelectPill
                type="select"
                id="select-picker-pill"
                filter={false}
                placeholder="Selecione um canal"
                name="first"
                searchable={false}
                value={filterData.canal.value[0] || null}
                data={filterData.canal.list}
                onSelect={(value, item) => channelHandlers.handleSelectCanal('canal', value, item)}
                onOpen={() => channelHandlers.onSearchCanal('canal', '')}
                onClean={() => channelHandlers.handleClearAllCanal()}
                locale={{
                    noResultsText: 'Nenhum item encontrado',
                    searchPlaceholder: 'Buscar item',
                }}
                renderMenu={(menu) => (
                    <span>
                        <LoadingMenu area="scenario-filter" />
                        {menu}
                    </span>
                )}

            />

            { filterData.canal.value[0]
                ? (
                    <>
                        <img
                            src={arrowRightIcon}
                            alt=""
                            className={styles['arrow-right-icon']}
                        />
                        <SelectPill
                            type="select"
                            id="select-picker-pill"
                            placeholder="Selecione um sub-canal"
                            name="second"
                            searchable={false}
                            value={filterData.subCanal.value[0] || null}
                            data={filterData.subCanal.list}
                            onSelect={(value, item) => channelHandlers.handleSelectCanal('subCanal', value, item)}
                            onOpen={() => channelHandlers.onSearchCanal('subCanal', '')}
                            onClean={() => channelHandlers.handleClearCanal('subCanal')}
                            locale={{
                                noResultsText: 'Nenhum item encontrado',
                                searchPlaceholder: 'Buscar item',
                            }}
                            renderMenu={(menu) => (
                                <span>
                                    <LoadingMenu area="scenario-filter" />
                                    {menu}
                                </span>
                            )}
                        />
                        <img
                            src={arrowRightIcon}
                            alt=""
                            className={styles['arrow-right-icon']}
                        />
                        <SelectPill
                            type="select"
                            id="select-picker-pill"
                            placeholder="Selecione o tipo de pagamento"
                            name="third"
                            searchable={false}
                            value={filterData.pagamentoType.value[0] || null}
                            data={filterData.pagamentoType.list}
                            onSelect={(value, item) => channelHandlers.handleSelectCanal('pagamentoType', value, item)}
                            onOpen={() => channelHandlers.onSearchCanal('pagamentoType', '')}
                            onClean={() => channelHandlers.handleClearCanal('pagamentoType')}
                            locale={{
                                noResultsText: 'Nenhum item encontrado',
                                searchPlaceholder: 'Buscar item',
                            }}
                            renderMenu={(menu) => (
                                <span>
                                    <LoadingMenu area="scenario-filter" />
                                    {menu}
                                </span>
                            )}
                        />
                    </>
                ) : null}
        </div>
    );
};
