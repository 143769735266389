import { faTh } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsMore from 'highcharts/highcharts-more';
import HighchartsExport from 'highcharts/modules/export-data';
import HighchartsExporting from 'highcharts/modules/exporting';
import React, { useState } from 'react';
import {
    Alert,
    Button,
    Col,
    Content,
    Dropdown,
    FlexboxGrid,
    Icon,
    IconButton,
    Table,
} from 'rsuite';

import iconEllipsis from '../../../../../assets/icons/icon_ellipsis-v.svg';
import lockIcon from '../../../../../assets/icons/icon_lock.svg';
import iconMaximize from '../../../../../assets/icons/icon_maximize.svg';
import iconMinimize from '../../../../../assets/icons/icon_minimize.svg';
import noDataImage from '../../../../../assets/image/empty-state-2.svg';

import MobileSelectOptionComponent from '../../../../../components/MobileSelectOptionComponent';
import ModalConfirmation from '../../../../../components/ModalConfirmation';
import ModalNotification from '../../../../../components/ModalNotification';
import { setClickToLocalStorage } from '../../../../../components/NavBar';
import { lead } from '../../../../../services/EventLeadService';
import { HighchartsOptions } from '../../../../../utils/HighchartsConfig';
import { downloadBoxplot } from '../services';
import {
    getGroupBy,
    getModelDashboards,
    getOrder,
    optionsGroupBy,
    optionsVisualize,
    renderBoxPlotChart,
    seriesColors,
} from '../utils';

import EmptyState from './EmptyState';

const {
    Column, HeaderCell, Cell, Pagination,
} = Table;

HighchartsMore(Highcharts);
HighchartsExporting(Highcharts);
HighchartsExport(Highcharts);

Highcharts.setOptions(HighchartsOptions);

const SBoxPlotChart = ({
    setSortColumnBoxplot,
    data,
    setVisualizationTypeBoxplot,
    filtrosData,
    usuario,
    sortColumn,
    shared,
    groupBy,
    userPlan,
    products,
}) => {
    const [typeVisualization, setTypeVisualization] = useState(
        groupBy || 'produto',
    );
    const [visualize, setVisualize] = useState('chart');
    const [hasPermition, setHasPermition] = useState(true);
    const [showCTA, setShowCTA] = useState(false);
    const [expand, setExpand] = useState(true);
    const [showModalDownload, setShowModalDownload] = useState(false);
    const [sortType, setSortType] = useState('asc');
    const [openDialogGroupBy, setOpenDialogGroupBy] = useState(false);
    const [openDialogVisualize, setOpenDialogVisualize] = useState(false);
    const [page, setPage] = useState(0);
    const [boxPlotChartRef, setBoxPlotChartRef] = useState(null);

    const handleSortColumn = (sortColumn, sortType) => {
        setSortColumnBoxplot(sortColumn);
        setSortType(sortType);
    };

    const handleOpenDialogGroupBy = () => {
        setOpenDialogGroupBy(true);
    };

    const handleOpenDialogVisualize = () => {
        setOpenDialogVisualize(true);
    };

    const handleChangePage = (e) => {
        setPage(e - 1);
    };

    const mapDataCategories = (data, type) => {
        const mapData = [...data];
        let categories;
        switch (type) {
            case 'produto':
                categories = mapData.map((item) => item.descricao);
                return categories;
            case 'rede':
                categories = mapData.map((item) => item.rede);
                return categories;
            case 'produtoRede':
                categories = mapData.map(
                    (item) => `${item.descricao}<br>${item.rede}`,
                );
                return categories;
            case 'uf':
                categories = mapData.map((item) => item.uf);
                return categories;
            case 'produtoUf':
                categories = mapData.map((item) => `${item.descricao}<br>${item.uf}`);
                return categories;
            case 'regiao':
                categories = mapData.map((item) => item.regiao);
                return categories;
            case 'produtoRegiao':
                categories = mapData.map((item) => `${item.descricao}<br>${item.regiao}`);
                return categories;
            case 'fabricante':
                categories = mapData.map(
                    (item) => item.fabricante || 'SEM INFORMAÇÃO DE FABRICANTE',
                );
                return categories;
            default:
                categories = mapData.map((item) => item.descricao);
                return categories;
        }
    };

    const mapDataSeries = (data) => {
        const mapData = [...data];
        const serie = mapData.map((item, index) => ({
            data: [
                {
                    x: index,
                    name: item.descricao,
                    low: item.preco_minimo,
                    minPrice: item.preco_minimo,
                    q1: item.preco_quartil_inferior,
                    median: item.preco_mediano,
                    q3: item.preco_quartil_superior,
                    high: item.preco_maximo,
                    maxPrice: item.preco_maximo,
                    numberStores: item.cnpjs_distintos,
                    numberRedes: item.redes_distintas,
                    frequentPrice: item.preco_moda,
                    averagePrice: item.preco_medio,
                    rede: item.rede,
                    fillColor: seriesColors[index],
                },
            ],
        }));
        return serie;
    };

    const changeVisualization = (type) => {
        setVisualizationTypeBoxplot(type);

        if (shared) {
            setSortColumnBoxplot(null);
            setPage(0);
            setTypeVisualization(type);
            setHasPermition(true);
        } else if (
            (type === 'rede'
                || type === 'produtoRede'
                || type === 'fabricante')
            && (userPlan === 'FREE'
                || userPlan === 'LITE'
                || (userPlan === 'LIMIT'
                    && products.some((item) => item.limited === true)))
        ) {
            setPage(0);
            setTypeVisualization(type);
            setHasPermition(false);
            setSortColumnBoxplot(null);
            if (userPlan === 'FREE') {
                setClickToLocalStorage(
                    'addBlockFree',
                    'clique-adblock',
                    usuario,
                );
            }
        } else {
            setSortColumnBoxplot(null);
            setPage(0);
            setTypeVisualization(type);
            setHasPermition(true);
        }
    };

    const changeVisualize = (type) => {
        setVisualize(type);
    };

    const sendCTA = () => {
        const identificador = 'upsell_infopanel';
        lead(identificador, usuario).then(() => {
            setShowCTA(false);
            Alert.success('Em breve entraremos em contato!');
        });
    };

    const exportCSV = () => {
        // const { sortColumn } = this.props;

        const data = {
            filter: getModelDashboards(filtrosData),
            group: getGroupBy(typeVisualization),
            order: sortColumn
                ? [{ by: sortColumn, ascending: sortType === 'asc' }]
                : getOrder(typeVisualization),
            options: {
                download: {
                    extension: 'csv',
                },
            },
        };

        downloadBoxplot(data).then((data) => {
            if (data && data.status === 202) {
                setShowModalDownload(true);
            }
        });
    };

    const exportXLS = () => {
        const data = {
            filter: getModelDashboards(filtrosData),
            group: getGroupBy(typeVisualization),
            order: sortColumn
                ? [{ by: sortColumn, ascending: sortType === 'asc' }]
                : getOrder(typeVisualization),
            options: {
                download: {
                    extension: 'xlsx',
                },
            },
        };

        downloadBoxplot(data).then((data) => {
            if (data && data.status === 202) {
                setShowModalDownload(true);
            }
        });
    };

    const exportPNG = () => {
        if (boxPlotChartRef?.current?.chart) {
            boxPlotChartRef?.current.chart.exportChart({ type: 'image/png' });
        }
    };

    const print = () => {
        if (boxPlotChartRef?.current?.chart) {
            boxPlotChartRef?.current.chart.print();
        }
    };

    const viewFullScreen = () => {
        if (boxPlotChartRef?.current?.chart) {
            boxPlotChartRef?.current.chart.fullscreen.toggle();
        }
    };

    return (
        <Content className="box-plot-chart">
            <FlexboxGrid className="header-area">
                <FlexboxGrid.Item
                    componentClass={Col}
                    lg={22}
                    md={22}
                    sm={17}
                    xs={17}
                    className={!expand ? 'minimized' : null}
                >
                    <h4>AMPLITUDE DE PREÇOS</h4>
                </FlexboxGrid.Item>

                {shared ? null : (
                    <>
                        <FlexboxGrid.Item
                            componentClass={Col}
                            lg={1}
                            md={1}
                            sm={3}
                            xs={3}
                            className={
                                !expand
                                    ? 'expand-section minimized'
                                    : 'expand-section'
                            }
                        >
                            <Button
                                id="boxplot-expand-btn"
                                appearance="subtle"
                                onClick={() => setExpand(!expand)}
                            >
                                {expand ? (
                                    <img alt="Maximizar" src={iconMinimize} />
                                ) : (
                                    <img alt="Minimizar" src={iconMaximize} />
                                )}
                            </Button>
                        </FlexboxGrid.Item>
                        {(typeVisualization === 'rede'
                            || typeVisualization === 'produtoRede'
                            || typeVisualization === 'fabricante')
                            && (userPlan === 'LITE'
                                || (userPlan === 'LIMIT'
                                    && products.some(
                                        (item) => item.limited === true,
                                    ))) ? null : (
                            <FlexboxGrid.Item
                                componentClass={Col}
                                lg={1}
                                md={1}
                                sm={3}
                                xs={3}
                                className={
                                    !expand
                                        ? 'more-options-section minimized'
                                        : 'more-options-section'
                                }
                            >
                                <Dropdown
                                    id="boxplot-more-options"
                                    placement="bottomEnd"
                                    renderTitle={() => (
                                        <IconButton
                                            className="more-options-section__btn"
                                            appearance="subtle"
                                            icon={(
                                                <img
                                                    alt="Mais opções"
                                                    src={iconEllipsis}
                                                />
                                            )}
                                        />
                                    )}
                                >
                                    {visualize === 'chart' ? (
                                        <>
                                            <Dropdown.Item
                                                onClick={viewFullScreen}
                                                id="btn-boxplot-full-screen"
                                            >
                                                Ver em tela cheia
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                                disabled={userPlan === 'FREE'}
                                                onClick={print}
                                                id="btn-boxplot-print"
                                            >
                                                Imprimir
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                                disabled={userPlan === 'FREE'}
                                                divider
                                            />
                                            <Dropdown.Item
                                                disabled={userPlan === 'FREE'}
                                                onClick={exportPNG}
                                                id="btn-boxplot-download-png"
                                            >
                                                Download em PNG
                                            </Dropdown.Item>
                                        </>
                                    ) : null}
                                    <Dropdown.Item
                                        disabled={userPlan === 'FREE'}
                                        onClick={exportCSV}
                                        id="btn-boxplot-download-csv"
                                    >
                                        Download em CSV
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                        disabled={userPlan === 'FREE'}
                                        onClick={exportXLS}
                                        id="btn-boxplot-download-xls"
                                    >
                                        Download em XLSX
                                    </Dropdown.Item>
                                </Dropdown>
                            </FlexboxGrid.Item>
                        )}
                    </>
                )}
            </FlexboxGrid>
            {expand ? (
                <>
                    <FlexboxGrid>
                        <FlexboxGrid.Item
                            componentClass={Col}
                            lg={20}
                            md={20}
                            xsHidden
                            smHidden
                            className="select-visualization-section"
                        >
                            <span>AGRUPAR POR: </span>
                            <Button
                                className="select-visualization-section__button"
                                appearance={
                                    typeVisualization === 'produto'
                                        ? 'primary'
                                        : 'subtle'
                                }
                                onClick={() => changeVisualization('produto')}
                                disabled={shared}
                            >
                                Produto
                            </Button>
                            <Button
                                className="select-visualization-section__button"
                                appearance={
                                    typeVisualization === 'rede'
                                        ? 'primary'
                                        : 'subtle'
                                }
                                onClick={() => changeVisualization('rede')}
                                disabled={shared}
                            >
                                Rede
                            </Button>
                            <Button
                                className="select-visualization-section__button"
                                appearance={
                                    typeVisualization === 'produtoRede'
                                        ? 'primary'
                                        : 'subtle'
                                }
                                onClick={() => changeVisualization('produtoRede')}
                                disabled={shared}
                            >
                                Produto e rede
                            </Button>
                            <Button
                                className="select-visualization-section__button"
                                appearance={
                                    typeVisualization === 'uf'
                                        ? 'primary'
                                        : 'subtle'
                                }
                                onClick={() => changeVisualization('uf')}
                                disabled={shared}
                            >
                                UF
                            </Button>
                            <Button
                                className="select-visualization-section__button"
                                appearance={
                                    typeVisualization === 'produtoUf'
                                        ? 'primary'
                                        : 'subtle'
                                }
                                onClick={() => changeVisualization('produtoUf')}
                                disabled={shared}
                            >
                                Produto e UF
                            </Button>
                            <Button
                                className="select-visualization-section__button"
                                appearance={
                                    typeVisualization === 'regiao'
                                        ? 'primary'
                                        : 'subtle'
                                }
                                onClick={() => changeVisualization('regiao')}
                                disabled={shared}
                            >
                                Região
                            </Button>
                            <Button
                                className="select-visualization-section__button"
                                appearance={
                                    typeVisualization === 'produtoRegiao'
                                        ? 'primary'
                                        : 'subtle'
                                }
                                onClick={() => changeVisualization('produtoRegiao')}
                                disabled={shared}
                            >
                                Produto e Região
                            </Button>
                            <Button
                                className="select-visualization-section__button"
                                appearance={
                                    typeVisualization === 'fabricante'
                                        ? 'primary'
                                        : 'subtle'
                                }
                                onClick={() => changeVisualization('fabricante')}
                                disabled={shared}
                            >
                                Fabricante
                            </Button>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item
                            componentClass={Col}
                            xs={24}
                            sm={24}
                            lgHidden
                            mdHidden
                            className="select-visualization-section"
                        >
                            <IconButton
                                className="select-visualization-section__dropdown"
                                icon={<Icon icon="angle-down" />}
                                onClick={handleOpenDialogGroupBy}
                                placement="right"
                            >
                                Agrupar por:
                                {' '}
                                <span>
                                    {typeVisualization === 'produto'
                                        ? 'Produto'
                                        : typeVisualization === 'rede'
                                            ? 'Rede'
                                            : typeVisualization === 'produtoRede'
                                                ? 'Produto e rede'
                                                : null}
                                </span>
                            </IconButton>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item
                            componentClass={Col}
                            lg={4}
                            md={4}
                            xsHidden
                            smHidden
                            className="select-visualization-section"
                        >
                            <span>VISUALIZAR: </span>
                            <Button
                                className="select-visualize-section__button"
                                appearance={
                                    visualize === 'chart' ? 'primary' : 'subtle'
                                }
                                onClick={() => changeVisualize('chart')}
                            >
                                <svg
                                    width="18"
                                    height="6"
                                    viewBox="0 0 18 6"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <rect
                                        x="4.76471"
                                        width="8.47059"
                                        height="6"
                                    />
                                    <path d="M13.2353 3L17 3" />
                                    <path d="M4.76471 3L1 3" />
                                    <path d="M17 1L17 5" />
                                    <path d="M1 5L1 1" />
                                </svg>
                            </Button>
                            <Button
                                className="select-visualize-section__button"
                                appearance={
                                    visualize === 'table' ? 'primary' : 'subtle'
                                }
                                onClick={() => changeVisualize('table')}
                            >
                                <FontAwesomeIcon icon={faTh} />
                            </Button>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item
                            componentClass={Col}
                            xs={24}
                            sm={24}
                            lgHidden
                            mdHidden
                            className="select-visualization-section"
                        >
                            <IconButton
                                className="select-visualization-section__dropdown"
                                icon={<Icon icon="angle-down" />}
                                onClick={handleOpenDialogVisualize}
                                placement="right"
                            >
                                Visualizar por:
                                {' '}
                                <span>
                                    {visualize === 'chart'
                                        ? 'Gráfico'
                                        : visualize === 'table'
                                            ? 'Tabela'
                                            : null}
                                </span>
                            </IconButton>
                        </FlexboxGrid.Item>
                    </FlexboxGrid>

                    <FlexboxGrid>
                        {hasPermition ? (
                            <FlexboxGrid.Item
                                componentClass={Col}
                                lg={24}
                                md={24}
                                sm={24}
                                xs={24}
                                id="chart-area"
                                className="chart-area"
                            >
                                {data?.content?.length ? (
                                    visualize === 'chart' ? (
                                        <HighchartsReact
                                            ref={boxPlotChartRef}
                                            highcharts={Highcharts}
                                            options={renderBoxPlotChart(
                                                data,
                                                typeVisualization,
                                                mapDataCategories,
                                                mapDataSeries,
                                            )}
                                        />
                                    ) : (
                                        <Table
                                            className="second-table"
                                            height={
                                                data.content.length <= 3
                                                    ? 200
                                                    : data.content.length > 3
                                                        && data.content.length <= 5
                                                        ? 300
                                                        : data.content.length
                                                            >= 6
                                                            && data.content.length <= 7
                                                            ? 400
                                                            : 520
                                            }
                                            data={data.content}
                                            rowKey="gtin"
                                            sortColumn={sortColumn}
                                            sortType={sortType}
                                            onSortColumn={handleSortColumn}
                                        >
                                            {typeVisualization !== 'rede' && typeVisualization !== 'fabricante'
                                                && typeVisualization !== 'uf' && typeVisualization !== 'regiao'
                                                ? (
                                                    <Column
                                                        fixed={
                                                            !(
                                                                window.innerWidth
                                                                <= 992
                                                            )
                                                        }
                                                        sortable
                                                        width={140}
                                                    >
                                                        <HeaderCell title="Identificador">
                                                            Identificador
                                                        </HeaderCell>
                                                        <Cell dataKey="GTIN">
                                                            {(rowData) => (
                                                                <span
                                                                    title={
                                                                        rowData.gtin
                                                                    }
                                                                >
                                                                    {rowData.gtin}
                                                                </span>
                                                            )}
                                                        </Cell>
                                                    </Column>
                                                ) : null}
                                            {typeVisualization !== 'rede' && typeVisualization !== 'fabricante'
                                                && typeVisualization !== 'uf' && typeVisualization !== 'regiao' ? (
                                                <Column
                                                    fixed={
                                                        !(
                                                            window.innerWidth
                                                            <= 992
                                                        )
                                                    }
                                                    sortable
                                                    width={230}
                                                >
                                                    <HeaderCell>
                                                        Descrição
                                                    </HeaderCell>
                                                    <Cell dataKey="DESCRICAO">
                                                        {(rowData) => (
                                                            <span
                                                                title={
                                                                    rowData.descricao
                                                                }
                                                            >
                                                                {
                                                                    rowData.descricao
                                                                }
                                                            </span>
                                                        )}
                                                    </Cell>
                                                </Column>
                                            ) : null}
                                            {typeVisualization === 'rede' || typeVisualization === 'produtoRede' ? (
                                                <Column
                                                    fixed={
                                                        !(
                                                            window.innerWidth
                                                            <= 992
                                                        )
                                                    }
                                                    sortable
                                                    width={200}
                                                >
                                                    <HeaderCell>
                                                        Rede
                                                    </HeaderCell>
                                                    <Cell dataKey="REDE">
                                                        {(rowData) => (
                                                            <span
                                                                title={
                                                                    rowData.rede
                                                                }
                                                            >
                                                                {rowData.rede}
                                                            </span>
                                                        )}
                                                    </Cell>
                                                </Column>
                                            ) : null}
                                            {typeVisualization === 'uf' || typeVisualization === 'produtoUf'
                                                ? (
                                                    <Column fixed={!(window.innerWidth <= 992)} sortable width={120}>
                                                        <HeaderCell>UF</HeaderCell>
                                                        <Cell dataKey="UF">
                                                            {(rowData) => (
                                                                <span title={rowData.uf}>{rowData.uf}</span>
                                                            )}
                                                        </Cell>
                                                    </Column>
                                                ) : null}
                                            {typeVisualization === 'regiao' || typeVisualization === 'produtoRegiao'
                                                ? (
                                                    <Column fixed={!(window.innerWidth <= 992)} sortable width={120}>
                                                        <HeaderCell>Região</HeaderCell>
                                                        <Cell dataKey="Regiao">
                                                            {(rowData) => (
                                                                <span title={rowData.regiao}>{rowData.regiao}</span>
                                                            )}
                                                        </Cell>
                                                    </Column>
                                                ) : null}
                                            {typeVisualization === 'fabricante' ? (
                                                <Column
                                                    fixed={
                                                        !(
                                                            window.innerWidth
                                                            <= 992
                                                        )
                                                    }
                                                    sortable
                                                    width={230}
                                                >
                                                    <HeaderCell>
                                                        Fabricante
                                                    </HeaderCell>
                                                    <Cell dataKey="FABRICANTE">
                                                        {(rowData) => (
                                                            <span
                                                                title={
                                                                    rowData.fabricante
                                                                }
                                                            >
                                                                {rowData.fabricante
                                                                    || 'SEM INFORMAÇÃO DE FABRICANTE'}
                                                            </span>
                                                        )}
                                                    </Cell>
                                                </Column>
                                            ) : null}
                                            <Column sortable width={150}>
                                                <HeaderCell>
                                                    Preço mínimo
                                                </HeaderCell>
                                                <Cell dataKey="PRECO_MINIMO">
                                                    {(rowData) => (
                                                        <span
                                                            title={rowData.preco_minimo
                                                                .toFixed(2)
                                                                .replace(
                                                                    '.',
                                                                    ',',
                                                                )}
                                                        >
                                                            {rowData.preco_minimo
                                                                .toFixed(2)
                                                                .replace(
                                                                    '.',
                                                                    ',',
                                                                )}
                                                        </span>
                                                    )}
                                                </Cell>
                                            </Column>
                                            <Column sortable width={120}>
                                                <HeaderCell>
                                                    Preço médio
                                                </HeaderCell>
                                                <Cell dataKey="PRECO_MEDIO">
                                                    {(rowData) => (
                                                        <span
                                                            title={rowData.preco_medio
                                                                .toFixed(2)
                                                                .replace(
                                                                    '.',
                                                                    ',',
                                                                )}
                                                        >
                                                            {rowData.preco_medio
                                                                .toFixed(2)
                                                                .replace(
                                                                    '.',
                                                                    ',',
                                                                )}
                                                        </span>
                                                    )}
                                                </Cell>
                                            </Column>
                                            <Column sortable width={120}>
                                                <HeaderCell>Mediana</HeaderCell>
                                                <Cell dataKey="MEDIANA">
                                                    {(rowData) => (
                                                        <span
                                                            title={rowData.preco_mediano
                                                                .toFixed(2)
                                                                .replace(
                                                                    '.',
                                                                    ',',
                                                                )}
                                                        >
                                                            {rowData.preco_mediano
                                                                .toFixed(2)
                                                                .replace(
                                                                    '.',
                                                                    ',',
                                                                )}
                                                        </span>
                                                    )}
                                                </Cell>
                                            </Column>
                                            <Column sortable width={155}>
                                                <HeaderCell>
                                                    Preço mais frequente
                                                </HeaderCell>
                                                <Cell dataKey="PRECO_MODA">
                                                    {(rowData) => (
                                                        <span
                                                            title={rowData.preco_moda
                                                                .toFixed(2)
                                                                .replace(
                                                                    '.',
                                                                    ',',
                                                                )}
                                                        >
                                                            {rowData.preco_moda
                                                                .toFixed(2)
                                                                .replace(
                                                                    '.',
                                                                    ',',
                                                                )}
                                                        </span>
                                                    )}
                                                </Cell>
                                            </Column>
                                            <Column sortable width={120}>
                                                <HeaderCell>
                                                    Preço máximo
                                                </HeaderCell>
                                                <Cell dataKey="PRECO_MAXIMO">
                                                    {(rowData) => (
                                                        <span
                                                            title={rowData.preco_maximo
                                                                .toFixed(2)
                                                                .replace(
                                                                    '.',
                                                                    ',',
                                                                )}
                                                        >
                                                            {rowData.preco_maximo
                                                                .toFixed(2)
                                                                .replace(
                                                                    '.',
                                                                    ',',
                                                                )}
                                                        </span>
                                                    )}
                                                </Cell>
                                            </Column>
                                            <Column sortable width={130}>
                                                <HeaderCell>
                                                    Número de lojas
                                                </HeaderCell>
                                                <Cell dataKey="CNPJS_DISTINTOS">
                                                    {(rowData) => (
                                                        <span
                                                            title={
                                                                rowData.cnpjs_distintos
                                                            }
                                                        >
                                                            {
                                                                rowData.cnpjs_distintos
                                                            }
                                                        </span>
                                                    )}
                                                </Cell>
                                            </Column>
                                            <Column sortable width={130}>
                                                <HeaderCell>
                                                    Número de redes
                                                </HeaderCell>
                                                <Cell dataKey="REDES_DISTINTAS">
                                                    {(rowData) => (
                                                        <span
                                                            title={
                                                                rowData.redes_distintas
                                                            }
                                                        >
                                                            {
                                                                rowData.redes_distintas
                                                            }
                                                        </span>
                                                    )}
                                                </Cell>
                                            </Column>
                                        </Table>
                                    )
                                ) : (
                                    <EmptyState
                                        title="Ops! Nenhum resultado encontrado"
                                        message="Sua busca não retornou nenhuma informação. Altere os filtros e tente novamente."
                                        image={noDataImage}
                                    />
                                )}
                                {data?.content?.length ? (
                                    <Pagination
                                        showInfo={false}
                                        showLengthMenu={false}
                                        activePage={
                                            data ? data.number + 1 : null
                                        }
                                        displayLength={10}
                                        total={data ? data.totalElements : null}
                                        onChangePage={handleChangePage}
                                    />
                                ) : null}
                            </FlexboxGrid.Item>
                        ) : (
                            <FlexboxGrid.Item
                                className="no-access"
                                componentClass={Col}
                                lg={24}
                                md={24}
                                sm={24}
                                xs={24}
                            >
                                <div className="no-access__main">
                                    <div>
                                        <img
                                            alt="Cadeado"
                                            className="no-access__icon"
                                            src={lockIcon}
                                        />
                                    </div>
                                    <div className="no-access__message">
                                        <p>
                                            {userPlan === 'FREE' ? (
                                                <span>
                                                    Este conteúdo pode ser
                                                    acessado somente por
                                                    assinantes.
                                                </span>
                                            ) : (
                                                <span>
                                                    Este conteúdo só pode ser
                                                    acessado por assinantes do
                                                    plano
                                                    {' '}
                                                    <b>Flex</b>
                                                    {' '}
                                                    (para
                                                    produtos escolhidos) ou
                                                    {' '}
                                                    <b>Pro</b>
                                                </span>
                                            )}
                                        </p>
                                    </div>
                                    <div>
                                        <Button
                                            appearance="primary"
                                            className="upsell"
                                            onClick={() => setShowCTA(true)}
                                        >
                                            {userPlan === 'FREE' ? (
                                                <span>Quero ser assinante</span>
                                            ) : (
                                                <span>Entrar em contato</span>
                                            )}
                                        </Button>
                                    </div>
                                </div>
                            </FlexboxGrid.Item>
                        )}
                    </FlexboxGrid>
                </>
            ) : null}
            <ModalConfirmation
                title="Informações para alteração do plano"
                message="Caso deseje saber mais informações para evoluir o seu plano, clique em “Confirmar”"
                show={showCTA}
                buttonConfirm="Confirmar"
                buttonCancel="Cancelar"
                btnConfirmClass="upsell"
                onConfirm={sendCTA}
                onCancel={() => setShowCTA(false)}
            />
            <ModalNotification
                show={showModalDownload}
                onHide={() => setShowModalDownload(false)}
                title="Download"
                message="O arquivo que você deseja baixar contém mais de cinquenta mil linhas e
                    pode demorar um pouco para ficar pronto. Te enviaremos por e-mail assim que estiver tudo certo!"
                close={() => setShowModalDownload(false)}
            />
            <MobileSelectOptionComponent
                open={openDialogGroupBy}
                handleClose={() => setOpenDialogGroupBy(false)}
                options={optionsGroupBy}
                optionSelected={typeVisualization}
                handleSelect={changeVisualization}
            />
            <MobileSelectOptionComponent
                open={openDialogVisualize}
                handleClose={() => setOpenDialogVisualize(false)}
                options={optionsVisualize}
                optionSelected={visualize}
                handleSelect={changeVisualize}
            />
        </Content>
    );
};

export default SBoxPlotChart;
