import React from 'react';
import {
    Button,
    CheckPicker,
    Checkbox,
    Content,
    Dropdown,
    Icon,
    Tooltip,
    Whisper,
} from 'rsuite';
import downloadIcon from '../../../../../../assets/icons/icon_download_blue.svg';
import downloadGrayIcon from '../../../../../../assets/icons/icon_download_gray.svg';
import Totalizer from '../Totalizer';
import styles from './styles.module.scss';

export const ResultsButtonArea = ({
    showPainel,
    columns,
    disabledItemValues,
    columnsValue,
    columnRef,
    indeterminateColumn,
    checkAllColumn,
    onClickViewResults,
    onChangeColumn,
    onClickDownload,
    isShoppingBrasilColumn,
    onChangeCheckAll,
    onClickColumnConfirm,
    onClickShare,
    totalDatapoints,
    userHasPermission,
    userIsLimit,
}) => (
    <Content className={styles['result-button-content']}>
        <Button
            appearance="primary"
            className={styles['result-button']}
            onClick={onClickViewResults}
            id="visualize-dashboard-btn"
        >
            {showPainel ? 'ATUALIZAR RESULTADOS' : 'VISUALIZAR RESULTADOS'}
        </Button>
        <Totalizer datapoints={totalDatapoints} />
        <div>
            {showPainel ? (
                <CheckPicker
                    appearance="subtle"
                    className={styles['column-picker']}
                    cleanable={false}
                    data={columns || []}
                    disabledItemValues={disabledItemValues}
                    filter
                    id="columns-select-picker"
                    locale={{
                        searchPlaceholder: 'Pesquisar',
                        noResultsText: 'Nenhum item encontrado',
                    }}
                    menuClassName="infopanel-column-picker"
                    onChange={onChangeColumn}
                    placeholder="Colunas"
                    placement="bottomEnd"
                    value={columnsValue}
                    ref={columnRef}
                    renderExtraFooter={() => (
                        <div className={styles.footer}>
                            <Checkbox
                                className={styles['check-all-checkbox']}
                                inline
                                indeterminate={indeterminateColumn}
                                checked={checkAllColumn}
                                onChange={onChangeCheckAll}
                            >
                                Selecionar tudo
                            </Checkbox>
                            <Button
                                className={styles['footer-btn']}
                                appearance="primary"
                                size="sm"
                                onClick={onClickColumnConfirm}
                            >
                                Confirmar
                            </Button>
                        </div>
                    )}
                    renderValue={(value, items, selectedElement) => (
                        <span>
                            <span
                                style={{
                                    color: '#8e8e93',
                                }}
                            >
                                Colunas:
                            </span>
                            {' '}
                            <span
                                style={{
                                    margin: '0 4px',
                                    background: '#3498ff',
                                    color: '#fff',
                                    borderRadius: '10px',
                                    padding: '0 10px',
                                    lineHeight: '20px',
                                }}
                            >
                                {selectedElement?.props?.selectedItems.length}
                            </span>
                        </span>
                    )}
                    renderMenuItem={(label, item) => (isShoppingBrasilColumn(item.name) ? (
                        <Whisper
                            placement="top"
                            trigger="hover"
                            speaker={(
                                <Tooltip>
                                    Coluna disponível mediante a contratação
                                    adicional
                                </Tooltip>
                            )}
                        >
                            <span>{label}</span>
                        </Whisper>
                    ) : (
                        <span>{label}</span>
                    ))}
                />
            ) : null}
            {!userHasPermission ? (
                <Dropdown
                    id="download-btn"
                    placement="bottomStart"
                    menuStyle={{ zIndex: 9 }}
                    title="Download"
                    renderTitle={() => (
                        <Button
                            appearance="ghost"
                            className={styles['download-button']}
                            disabled={!showPainel}
                        >
                            <img
                                src={downloadIcon}
                                alt=""
                                className={styles['download-button-icon']}
                            />
                            DOWNLOAD
                        </Button>
                    )}
                >
                    <Dropdown.Item
                        id="download-csv-file"
                        eventKey="csv"
                        onSelect={onClickDownload}
                        icon={<Icon icon="file-text-o" />}
                    >
                        .csv
                    </Dropdown.Item>
                    <Dropdown.Item
                        id="download-xlsx-file"
                        eventKey="xlsx"
                        onSelect={onClickDownload}
                        icon={<Icon icon="file-excel-o" />}
                    >
                        .xlsx
                    </Dropdown.Item>
                </Dropdown>
            ) : (
                <Whisper
                    trigger="hover"
                    placement="topStart"
                    speaker={(
                        <Tooltip>
                            {userIsLimit
                                ? 'O download só pode ser realizado quando forem selecionados apenas produtos dentro do seu pacote'
                                : 'O download é uma funcionalidade disponível apenas nos produtos InfoPanel Flex e InfoPanel Pro'}
                        </Tooltip>
                    )}
                >
                    <Button disabled className={styles['download-button']}>
                        <img
                            src={downloadGrayIcon}
                            alt=""
                            className={styles['download-button-icon']}
                        />
                        {' '}
                        DOWNLOAD
                    </Button>
                </Whisper>
            )}
            <Whisper
                placement="bottom"
                trigger="hover"
                speaker={<Tooltip>Compartilhar análise</Tooltip>}
            >
                <Button
                    className={styles['share-analyzed-button']}
                    appearance="primary"
                    disabled={!showPainel}
                    onClick={onClickShare}
                >
                    <Icon icon="share-alt" />
                </Button>
            </Whisper>
        </div>
    </Content>
);
