import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Typography, Grid, Card } from '@material-ui/core';
import {
    Toggle,
    Radio,
    RadioGroup,
    Checkbox,
    CheckboxGroup,
    Button,
} from 'rsuite';
import { useForm, Controller } from 'react-hook-form';
import iconInfo from '../../../../assets/icons/icon_info.svg';
import iconAlertTriangle from '../../../../assets/icons/icon_alert_yellow_triangle.svg';
import ModalConfirm from '../../../../components/ModalConfirm';
import { LoadingSpiner } from '../../../../components/LoadingSpinner';

const HeaderText = ({ title, subtitle }) => (
    <>
        <Typography variant="body1" className="title">
            {title}
        </Typography>
        <Typography variant="body1" className="subtitle">
            {subtitle}
        </Typography>
    </>
);

const InputSideLabel = ({ label, subtitle }) => (
    <div>
        <Typography variant="body1" className="input-label">
            {label}
        </Typography>
        <Typography variant="body1" className="input-subtitle">
            {subtitle}
        </Typography>
    </div>
);

const storeReference = {
    TIPOLOJA_REDE: ['REDE', 'TIPOLOJA'],
    REDE: ['REDE'],
    LOJA: ['LOJA'],
    TIPOLOJA: ['TIPOLOJA'],
};

const referenciaLojaValidation = (reference) => {
    if (reference?.includes('LOJA')) {
        return 'LOJA';
    }

    if (reference?.length === 2 && !reference?.includes('LOJA')) {
        return 'TIPOLOJA_REDE';
    }

    return reference[0];
};

const MpdvConfig = ({ data, save }) => {
    const [adicionarColunaOrigemPreco, setAdicionarColunaOrigemPreco] =
        useState(
            !!(
                data?.adicionarColunaOrigemPreco ||
                data?.adicionarColunaOrigemPreco === null ||
                data?.adicionarColunaOrigemPreco === undefined
            )
        );
    const [showOriginPriceAlertModal, setShowOriginPriceAlertModal] =
        useState(false);
    const [showConfigContent, setShowConfigContent] = useState(false);
    const {
        handleSubmit,
        control,
        watch,
        setError,
        reset,
        formState: { errors },
    } = useForm({
        defaultValues: {
            integrarPrecoVarejo: 'true',
            tipoPreco: 'tipoPreco',
            periodoPreco: '14',
            referenciaLoja: [],
            referenciaLocalizacao: 'CIDADE',
            raio: '10',
            adicionarPrecoPanelApi: false,
        },
    });

    const isRadius = watch('referenciaLocalizacao');
    const addColumn = watch('integrarPrecoVarejo');
    const storeReferenceType = watch('referenciaLoja');

    const history = useHistory();

    const submit = (formData) => {
        const obj = {
            integrarPrecoVarejo: addColumn === 'true',
            adicionarColunaPrecoInfopanel: addColumn === 'false',
            referenciaLoja: referenciaLojaValidation(storeReferenceType),
            referenciaLocalizacao: storeReferenceType.includes('LOJA')
                ? null
                : formData.referenciaLocalizacao,
            adicionarColunaOrigemPreco: !!(
                adicionarColunaOrigemPreco && addColumn !== 'false'
            ),
            periodoPreco: +formData.periodoPreco,
            raio: storeReferenceType.includes('LOJA') ? null : +formData.raio,
            adicionarPrecoPanelApi: formData.adicionarPrecoPanelApi,
        };

        if (formData.referenciaLoja.length === 0) {
            setError('referenciaLoja', {
                type: 'custom',
                message:
                    '- É necessário selecionar uma referência de loja abaixo para salvar as configurações',
            });
        } else {
            save(data?.createdAt ? 'PUT' : 'POST', obj);
        }
    };

    const handleAddOriginPrice = (value) => {
        if (value) {
            setShowOriginPriceAlertModal(true);
        } else {
            setAdicionarColunaOrigemPreco(!adicionarColunaOrigemPreco);
        }
    };

    const handleIntegrarPrecoVarejo = (e) => {
        if (!adicionarColunaOrigemPreco && e === 'true') {
            setAdicionarColunaOrigemPreco(true);
        }
    };

    const handleSaveToNotUseInfopanelPrice = () => {
        if (data?.createdAt) {
            save('DELETE', {});
        }
    };

    useEffect(() => {
        if (data?.createdAt) {
            setShowConfigContent(true);
            reset({
                integrarPrecoVarejo: data?.integrarPrecoVarejo?.toString(),
                tipoPreco: 'tipoPreco',
                periodoPreco: data?.periodoPreco?.toString(),
                referenciaLoja: storeReference[data?.referenciaLoja],
                referenciaLocalizacao:
                    data?.referenciaLocalizacao === null
                        ? 'CIDADE'
                        : data?.referenciaLocalizacao,
                raio: data?.raio === null ? '10' : data?.raio?.toString(),
                adicionarPrecoPanelApi: data?.adicionarPrecoPanelApi,
            });

            if (data?.adicionarColunaOrigemPreco === false) {
                setAdicionarColunaOrigemPreco(false);
            }
        } else {
            reset({
                integrarPrecoVarejo: 'true',
                tipoPreco: 'tipoPreco',
                periodoPreco: '14',
                referenciaLoja: [],
                referenciaLocalizacao: 'CIDADE',
                raio: '10',
                adicionarPrecoPanelApi: false,
            });
        }
    }, [data]);

    return (
        <Grid item xs={12} className="products-config-mpdv">
            <LoadingSpiner size="md" />
            <Card elevation={0} className="use-infopanel-price">
                <div className="use-infopanel-price-header">
                    <HeaderText
                        title="Complemento de Retorno"
                        subtitle="Dê mais robustez ao resultado da sua pesquisa,
                        adicionando preço InfoPanel."
                    />
                </div>
                <div className="input-wrapper">
                    <Toggle
                        size="sm"
                        onChange={() =>
                            setShowConfigContent(!showConfigContent)
                        }
                        checked={showConfigContent}
                    />
                    <InputSideLabel
                        label="Utilizar preço InfoPanel"
                        subtitle="Preços InfoPanel serão adicionados aos resultados da sua pesquisa."
                    />
                </div>
            </Card>
            {!showConfigContent ? (
                <div className="footer" style={{ marginTop: '20px' }}>
                    <Button
                        className="btn-cancel"
                        appearance="ghost"
                        color="blue"
                        onClick={() => history.goBack()}
                    >
                        CANCELAR
                    </Button>
                    <Button
                        type="submit"
                        className="btn-save"
                        appearance="primary"
                        onClick={handleSaveToNotUseInfopanelPrice}
                    >
                        SALVAR
                    </Button>
                </div>
            ) : null}
            {showConfigContent && (
                <>
                    <Card elevation={0} className="use-infopanel-description">
                        <img src={iconInfo} alt="" />
                        <Typography variant="body1" className="subtitle">
                            O InfoPanel é um painel de preços que consolida
                            dados de diversas fontes, recomendado para
                            acompanhamento de preços de mercado, porém{' '}
                            <strong>
                                não garante disponibilidade de dados
                                específicos.
                            </strong>{' '}
                            Produtos a granel (sem códigos de barra) apresentam
                            baixa cobertura no InfoPanel.
                        </Typography>
                    </Card>
                    <Card elevation={0} className="configuration">
                        <div className="configuration-title">
                            <Typography variant="h6">Configurações</Typography>
                        </div>
                        <div className="configuration-description">
                            Considere que quanto mais específicas as seleções de
                            período, loja e localização, menor o potencial de
                            preços serem encontrados no painel.
                        </div>
                        <form onSubmit={handleSubmit(submit)}>
                            <div className="data-integration">
                                <div className="data-integration-header">
                                    <HeaderText
                                        title="INTEGRAÇÃO DE DADOS"
                                        subtitle="Como será disponibilizado o preço InfoPanel na
                                    Extração de Dados/API."
                                    />
                                </div>
                                <Controller
                                    name="integrarPrecoVarejo"
                                    control={control}
                                    render={({ field }) => (
                                        <RadioGroup
                                            {...field}
                                            onChange={(e) => {
                                                field.onChange(e);
                                                handleIntegrarPrecoVarejo(e);
                                            }}
                                        >
                                            <Radio value="true">
                                                <InputSideLabel
                                                    label="Integrar preço InfoPanel na coluna “Preço Varejo”"
                                                    subtitle="Nos casos de ausência de preço pesquisado, será considerado o preço InfoPanel na coluna
                                                    “Preço Varejo”."
                                                />
                                            </Radio>
                                            <Checkbox
                                                className="retail-origin-price"
                                                value={
                                                    adicionarColunaOrigemPreco
                                                }
                                                checked={
                                                    !!(
                                                        adicionarColunaOrigemPreco &&
                                                        addColumn !== 'false'
                                                    )
                                                }
                                                onChange={handleAddOriginPrice}
                                                disabled={addColumn === 'false'}
                                            >
                                                <InputSideLabel
                                                    label="Adicionar coluna “Origem do preço”"
                                                    subtitle="Será adicionada uma coluna na Extração de Dados com as informações
                                                    “Pesquisa em loja” ou “InfoPanel”."
                                                />
                                            </Checkbox>
                                            <Radio value="false">
                                                <InputSideLabel
                                                    label="Adicionar coluna “Preço InfoPanel”"
                                                    subtitle="Será adicionada uma coluna na Extração de Dados com o preço InfoPanel dos itens pesquisados."
                                                />
                                            </Radio>
                                        </RadioGroup>
                                    )}
                                />
                                <div className="wrapper-add-price-infopanel-title">
                                    <div className="add-price-infopanel-title">
                                        <Typography
                                            variant="body1"
                                            className="input-label"
                                        >
                                            Integração via API
                                        </Typography>
                                        <span>
                                            (apenas para clientes que utilizam
                                            API)
                                        </span>
                                    </div>

                                    <Typography
                                        className="subtitle"
                                        variant="subtitle2"
                                    >
                                        Para ter acesso a essas informações é
                                        necessário estar consumindo a versão 3.0
                                        da API (
                                        <a href="https://api.infopriceti.com.br/integracao/v3/relatorio">
                                            https://api.infopriceti.com.br/integracao/v3/relatorio
                                        </a>
                                        )
                                    </Typography>
                                </div>

                                <Controller
                                    name="adicionarPrecoPanelApi"
                                    control={control}
                                    render={({
                                        field: { onChange, value },
                                    }) => (
                                        <div className="input-wrapper">
                                            <Toggle
                                                size="sm"
                                                onChange={onChange}
                                                checked={value}
                                            />
                                            <InputSideLabel label="Disponibilizar o preço InfoPanel na API" />
                                        </div>
                                    )}
                                />
                            </div>
                            <div className="parameterization">
                                <div className="parameterization-header">
                                    <HeaderText
                                        title="PARAMETRIZAÇÃO"
                                        subtitle="Como será definido o preço InfoPanel para cada
                                    item pesquisado."
                                    />
                                </div>
                                <Typography
                                    variant="body1"
                                    className="input-label"
                                >
                                    Tipo de preço
                                </Typography>
                                <Controller
                                    name="tipoPreco"
                                    control={control}
                                    render={({ field }) => (
                                        <Radio
                                            value="tipoPreco"
                                            {...field}
                                            className="average-price"
                                            checked
                                            disabled
                                        >
                                            <Typography
                                                variant="body1"
                                                className="input-label"
                                            >
                                                Preço Médio
                                            </Typography>
                                        </Radio>
                                    )}
                                />
                                <Typography
                                    variant="body1"
                                    className="input-label"
                                >
                                    Período
                                </Typography>
                                <Controller
                                    name="periodoPreco"
                                    control={control}
                                    render={({ field }) => (
                                        <RadioGroup {...field} inline>
                                            <Radio value="7">
                                                <Typography
                                                    variant="body1"
                                                    className="input-label"
                                                >
                                                    Últimos 7 dias
                                                </Typography>
                                            </Radio>
                                            <Radio value="14">
                                                <Typography
                                                    variant="body1"
                                                    className="input-label"
                                                >
                                                    Últimos 14 dias
                                                </Typography>
                                            </Radio>
                                        </RadioGroup>
                                    )}
                                />
                            </div>
                            <div className="reference">
                                <div className="reference-header">
                                    <HeaderText
                                        title="REFERÊNCIA"
                                        subtitle="Como será considerado o preço InfoPanel em
                                    relação a loja pesquisada"
                                    />
                                </div>
                                <Typography
                                    variant="body1"
                                    className={`input-label ${
                                        errors.referenciaLoja && 'alert'
                                    }`}
                                >
                                    *Loja{' '}
                                    {errors.referenciaLoja &&
                                        errors.referenciaLoja.message}
                                </Typography>
                                <Controller
                                    name="referenciaLoja"
                                    control={control}
                                    render={({ field }) => (
                                        <CheckboxGroup
                                            {...field}
                                            inline
                                            className="store-reference"
                                        >
                                            <Checkbox value="LOJA">
                                                <Typography
                                                    variant="body1"
                                                    className="input-label"
                                                >
                                                    Somente da mesma loja
                                                </Typography>
                                            </Checkbox>
                                            <Checkbox
                                                value={
                                                    storeReferenceType?.includes(
                                                        'LOJA'
                                                    )
                                                        ? null
                                                        : 'REDE'
                                                }
                                                disabled={storeReferenceType?.includes(
                                                    'LOJA'
                                                )}
                                            >
                                                <Typography
                                                    variant="body1"
                                                    className="input-label"
                                                >
                                                    Somente da mesma rede
                                                </Typography>
                                            </Checkbox>
                                            <Checkbox
                                                value={
                                                    storeReferenceType?.includes(
                                                        'LOJA'
                                                    )
                                                        ? null
                                                        : 'TIPOLOJA'
                                                }
                                                disabled={storeReferenceType?.includes(
                                                    'LOJA'
                                                )}
                                            >
                                                <Typography
                                                    variant="body1"
                                                    className="input-label"
                                                >
                                                    Somente do mesmo tipo de
                                                    loja
                                                </Typography>
                                            </Checkbox>
                                        </CheckboxGroup>
                                    )}
                                />
                                <Typography
                                    variant="body1"
                                    className="input-label"
                                >
                                    Localização
                                </Typography>
                                <Controller
                                    name="referenciaLocalizacao"
                                    control={control}
                                    render={({ field }) => (
                                        <RadioGroup {...field} inline>
                                            <Radio
                                                value="CIDADE"
                                                disabled={storeReferenceType?.includes(
                                                    'LOJA'
                                                )}
                                            >
                                                <Typography
                                                    variant="body1"
                                                    className="input-label"
                                                >
                                                    Cidade da loja pesquisada
                                                </Typography>
                                            </Radio>
                                            <Radio
                                                value="RAIO"
                                                disabled={storeReferenceType?.includes(
                                                    'LOJA'
                                                )}
                                            >
                                                <Typography
                                                    variant="body1"
                                                    className="input-label"
                                                >
                                                    Distância da loja pesquisada
                                                </Typography>
                                            </Radio>
                                        </RadioGroup>
                                    )}
                                />
                                {isRadius === 'RAIO' &&
                                    !storeReferenceType?.includes('LOJA') && (
                                        <div className="radius-wrapper">
                                            <Controller
                                                name="raio"
                                                control={control}
                                                render={({ field }) => (
                                                    <RadioGroup
                                                        {...field}
                                                        inline
                                                    >
                                                        <Radio value="2">
                                                            <Typography
                                                                variant="body1"
                                                                className="input-label"
                                                            >
                                                                2 km
                                                            </Typography>
                                                        </Radio>
                                                        <Radio value="5">
                                                            <Typography
                                                                variant="body1"
                                                                className="input-label"
                                                            >
                                                                5 km
                                                            </Typography>
                                                        </Radio>
                                                        <Radio value="10">
                                                            <Typography
                                                                variant="body1"
                                                                className="input-label"
                                                            >
                                                                10 km
                                                            </Typography>
                                                        </Radio>
                                                    </RadioGroup>
                                                )}
                                            />
                                        </div>
                                    )}
                            </div>
                            <div className="footer">
                                <Button
                                    className="btn-cancel"
                                    onClick={() => history.goBack()}
                                    appearance="ghost"
                                    color="blue"
                                >
                                    CANCELAR
                                </Button>
                                <Button
                                    type="submit"
                                    className="btn-save"
                                    appearance="primary"
                                >
                                    SALVAR
                                </Button>
                            </div>
                        </form>
                    </Card>
                </>
            )}
            <ModalConfirm
                show={showOriginPriceAlertModal}
                className="modal-origin-price-change-alert"
                confirmButton="PROSSEGUIR"
                cancelButton="CANCELAR"
                footerAlign="end"
                confirmButtonWidth="140px"
                size="xs"
                modalWidth="512px"
                onHideHeaderButton={() => setShowOriginPriceAlertModal(false)}
                onCancel={() => setShowOriginPriceAlertModal(false)}
                onConfirm={() => {
                    setAdicionarColunaOrigemPreco(!adicionarColunaOrigemPreco);
                    setShowOriginPriceAlertModal(false);
                }}
            >
                <img src={iconAlertTriangle} alt="" />
                <Typography variant="body1" className="input-label">
                    Sem a coluna “Origem do preço” não será possível diferenciar
                    preços pesquisados e preços InfoPanel na Extração de Dados.
                    Tem certeza de que deseja prosseguir?
                </Typography>
            </ModalConfirm>
        </Grid>
    );
};

export default MpdvConfig;
