import React, { Component } from 'react';
import {
    Container, Content, FlexboxGrid, Button, Form, FormGroup, ControlLabel, ButtonToolbar, Alert, Col, HelpBlock, Input, InputGroup, Icon,
} from 'rsuite';
import { withRouter } from 'react-router-dom';

import { resetSenha, getUsuarioByTokenEsqueciSenha } from '../../services/UsuarioService';
import { HomeCarouselComponent } from '../../components/HomeCarouselComponent';

class NovaSenha extends Component {
    constructor(props) {
        super(props);

        this.state = {
            confirmacaoNovaSenha: '',
            novaSenha: '',
            errors: {},
            token: null,
            usuario: null,
        };

        this.showNovaSenha = this.showNovaSenha.bind(this);
        this.showNovaSenhaConfirmacao = this.showNovaSenhaConfirmacao.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        const { location } = this.props;
        const query = new URLSearchParams(location.search);
        const token = query.get('token');
        this.setState(
            {
                token,
            },
            this.getUsuario,
        );
    }

    handleSubmit(e) {
        e.preventDefault();
        const { token, novaSenha } = this.state;
        const { history } = this.props;

        if (this.validInput()) {
            resetSenha(token, novaSenha).then((data) => {
                if (data) {
                    Alert.success('Senha alterada com sucesso', 5000);
                    history.push('/login');
                }
            });
        }
    }

    handleChange(value, e) {
        this.setState(
            {
                [e.target.name]: value,
            },
            this.validInput,
        );
    }

    getUsuario() {
        const { token } = this.state;
        getUsuarioByTokenEsqueciSenha(token).then((data) => {
            if (data) {
                this.setState({
                    usuario: data,
                });
            }
        });
    }

    validInput() {
        const { novaSenha, usuario, confirmacaoNovaSenha } = this.state;

        const errors = {};
        let formIsValid = true;

        if (!novaSenha.length) {
            formIsValid = false;
            errors.novaSenha = 'Campo obrigatório';
            const novaSenhaInput = document.getElementsByName('novaSenha')[0];
            if (novaSenhaInput) {
                novaSenhaInput.classList.add('invalid-input');
            }
        } else if (novaSenha.length < 6) {
            formIsValid = false;
            errors.novaSenha = 'A senha deve possuir pelo menos 6 caracteres';
            const novaSenhaInput = document.getElementsByName('novaSenha')[0];
            if (novaSenhaInput) {
                novaSenhaInput.classList.add('invalid-input');
            }
        } else if (usuario.login.length && novaSenha.indexOf(usuario.login) !== -1) {
            formIsValid = false;
            errors.novaSenha = 'A senha não pode conter seu e-mail na integra';
            const novaSenhaInput = document.getElementsByName('novaSenha')[0];
            if (novaSenhaInput) {
                novaSenhaInput.classList.add('invalid-input');
            }
        } else {
            const novaSenhaInput = document.getElementsByName('novaSenha')[0];
            if (novaSenhaInput) {
                novaSenhaInput.classList.remove('invalid-input');
            }
        }

        if (confirmacaoNovaSenha.length && confirmacaoNovaSenha !== novaSenha) {
            formIsValid = false;
            errors.confirmacaoNovaSenha = 'A confirmação não bate com a senha';
            const confirmacaoNovaSenhaInput = document.getElementsByName('confirmacaoNovaSenha')[0];
            if (confirmacaoNovaSenhaInput) {
                confirmacaoNovaSenhaInput.classList.add('invalid-input');
            }
        } else {
            const confirmacaoNovaSenhaInput = document.getElementsByName('confirmacaoNovaSenha')[0];
            if (confirmacaoNovaSenhaInput) {
                confirmacaoNovaSenhaInput.classList.remove('invalid-input');
            }
        }

        this.setState({ errors });
        return formIsValid;
    }

    showNovaSenha() {
        const passwordInput = document.getElementById('nova-senha');

        if (passwordInput?.type === 'password') {
            passwordInput.type = 'text';
        } else {
            passwordInput.type = 'password';
        }
    }

    showNovaSenhaConfirmacao() {
        const passwordInput = document.getElementById('confirmacao-nova-senha');

        if (passwordInput?.type === 'password') {
            passwordInput.type = 'text';
        } else {
            passwordInput.type = 'password';
        }
    }

    render() {
        const { novaSenha, confirmacaoNovaSenha, errors } = this.state;

        return (
            <div className="nova-senha-page">
                <Container>
                    <FlexboxGrid>
                        <Col md={10} lg={10} xsHidden smHidden>
                            <Content>
                                <HomeCarouselComponent />
                            </Content>
                        </Col>
                        <Col md={14} lg={14} xs={24} className="form-area">
                            <Content className="new-password-form">
                                <h4>Alterar senha</h4>

                                <Form fluid onSubmit={this.handleSubmit}>
                                    <FormGroup>
                                        <ControlLabel>Nova senha</ControlLabel>
                                        <InputGroup>
                                            <Input
                                                id="nova-senha"
                                                name="novaSenha"
                                                value={novaSenha}
                                                onChange={this.handleChange}
                                                type="password"
                                            />
                                            <InputGroup.Button
                                                onClick={this.showNovaSenha}
                                                name="showNovaSenha"
                                                id="btn-show-nova-senha"
                                            >
                                                <Icon icon="eye" />
                                            </InputGroup.Button>
                                        </InputGroup>
                                        <HelpBlock className="invalid-field" name="errorNovaSenha">{errors.novaSenha}</HelpBlock>
                                    </FormGroup>
                                    <FormGroup>
                                        <ControlLabel>Confirmar nova senha</ControlLabel>
                                        <InputGroup>
                                            <Input
                                                id="confirmacao-nova-senha"
                                                name="confirmacaoNovaSenha"
                                                value={confirmacaoNovaSenha}
                                                onChange={this.handleChange}
                                                type="password"
                                            />
                                            <InputGroup.Button onClick={this.showNovaSenhaConfirmacao} name="confirmacaoNovaSenha">
                                                <Icon icon="eye" />
                                            </InputGroup.Button>
                                        </InputGroup>
                                        <HelpBlock
                                            className="invalid-field"
                                            name="errorConfirmacaoNovaSenha"
                                        >
                                            {errors.confirmacaoNovaSenha}

                                        </HelpBlock>
                                    </FormGroup>
                                    <div className="instructions">
                                        <p>Para proteger sua conta, certifique-se que sua senha:</p>
                                        <ul>
                                            <li>Possui mais que 6 caracteres</li>
                                            <li>Não contenha o e-mail da conta na íntegra</li>
                                        </ul>
                                    </div>
                                    <FormGroup>
                                        <ButtonToolbar>
                                            <Button block type="submit" appearance="primary">Criar nova senha</Button>
                                        </ButtonToolbar>
                                    </FormGroup>
                                </Form>

                            </Content>
                        </Col>
                    </FlexboxGrid>
                </Container>
            </div>
        );
    }
}

export default withRouter(NovaSenha);
