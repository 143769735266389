import React from 'react';

export const MdChangeCircle = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 24 24"
        fill="none"
        {...props}
    >
        <path
            d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12.06 19V16.99C12.04 16.99 12.02 16.99 12 16.99C10.72 16.99 9.44 16.5 8.46 15.53C6.75 13.82 6.54 11.18 7.82 9.24L8.92 10.34C8.21 11.67 8.39 13.35 9.51 14.47C10.21 15.17 11.13 15.5 12.05 15.48V13.34L14.88 16.17L12.06 19ZM16.17 14.76L15.07 13.66C15.78 12.33 15.6 10.65 14.48 9.53C13.79 8.84 12.9 8.5 12 8.5C11.98 8.5 11.96 8.5 11.94 8.5V10.65L9.11 7.83L11.94 5V7.02C13.24 7 14.55 7.47 15.54 8.47C17.24 10.17 17.45 12.82 16.17 14.76Z"
            fill="currentColor"
        />
    </svg>
);
