import React, { useMemo, useState } from 'react';
import { useDebounce } from '../../../hooks';

type Search<K extends string = string> = Partial<Record<K, string>>;

const initialState: Search = {};

export const useFilterSearch = <K extends string = string>(
    initialSearch = initialState,
) => {
    const [search, setSearch] = useState<Search<K>>(() => initialSearch);

    const debounce = useDebounce();

    const onSearch = (key: K, query: string) => {
        debounce(() => {
            setSearch((oldValue) => ({ ...oldValue, [key]: query }));
        });
    };

    const reset = React.useCallback(() => {
        setSearch(initialState);
    }, []);

    return [useMemo(() => search, [search]), { onSearch, reset }] as const;
};
