import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../@types/RootState';

export type PrecoAtacadoPaginationState = {
    activePage: number;
    displayLength: number;
};

const initialState: PrecoAtacadoPaginationState = {
    activePage: 0,
    displayLength: 25,
};

const slice = createSlice({
    name: 'ipa/precoAtacado/pagination',
    initialState,
    reducers: {
        SET_PRECO_ATACADO_PAGINATION: (
            _state,
            {
                payload,
            }: PayloadAction<{ activePage: number; displayLength: number }>,
        ) => payload,
        UPDATE_PRECO_ATACADO_PAGINATION: (
            state,
            { payload }: PayloadAction<Partial<PrecoAtacadoPaginationState>>,
        ) => {
            return {
                ...state,
                ...payload,
            };
        },
        SET_PRECO_ATACADO_DISPLAY_LENGTH: (
            state,
            { payload }: PayloadAction<number>,
        ) => {
            return {
                ...state,
                displayLength: payload,
            };
        },
        SET_PRECO_ATACADO_ACTIVE_PAGE: (
            state,
            { payload }: PayloadAction<number>,
        ) => {
            return {
                ...state,
                activePage: payload,
            };
        },
    },
});

export const selectorPrecoAtacadoPagination = (state: RootState) => {
    return state.precoAtacadoReducer.pagination;
};

export const {
    SET_PRECO_ATACADO_PAGINATION,
    UPDATE_PRECO_ATACADO_PAGINATION,
    SET_PRECO_ATACADO_DISPLAY_LENGTH,
    SET_PRECO_ATACADO_ACTIVE_PAGE,
} = slice.actions;

export default slice.reducer;
