import { trackPromise } from 'react-promise-tracker';
import { Alert, Notification } from 'rsuite';
import { api } from '../../../../../lib';
import { PrecoAtacadoSchema } from '../../../../../reducers/reducerIPA/wholesale';

export async function GET_SETUP_IPA_PRECO_ATACADO() {
    const res = await trackPromise(
        api.get<PrecoAtacadoSchema>('/setup/wholesale-price/config', {
            expectToken: true,
        }),
        'setup-ipa',
    );

    return res.data;
}

export async function PUT_SETUP_IPA_PRECO_ATACADO(
    formData: PrecoAtacadoSchema,
): Promise<void> {
    const ERROR_MESSAGE = 'Erro ao salvar configurações';
    const SUCCESS_MESSAGE = 'Configurações salvas';
    try {
        const { status, data } = await trackPromise(
            api.put('/setup/wholesale-price', formData, {
                expectToken: true,
            }),
            'setup-ipa',
        );

        if (status !== 200) throw new Error(ERROR_MESSAGE);

        Notification.success({ description: SUCCESS_MESSAGE });

        return data;
    } catch {
        return Alert.error(ERROR_MESSAGE);
    }
}
