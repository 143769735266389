export const SET_RANGES = 'SET_RANGES';
export const ADD_RANGE = 'ADD_RANGE';
export const ADD_DECIMAL_RANGE = 'ADD_DECIMAL_RANGE';
export const SET_VALUE_RANGE = 'SET_VALUE_RANGE';
export const SET_VALUE_DECIMAL_RANGE = 'SET_VALUE_DECIMAL_RANGE';
export const DELETE_RANGE = 'DELETE_RANGE';
export const DELETE_DECIMAL_RANGE = 'DELETE_DECIMAL_RANGE';

export function setRanges(ranges, decimalRanges) {
    const timestamp = new Date().getTime();
    const newRange = {
        id: timestamp,
        min: null,
        max: null,
        status: 'CREATE',
    };
    const newDecimalRange = {
        rangeId: timestamp,
        id: timestamp,
        min: null,
        max: null,
        roundingTo: null,
        badge: null,
        status: 'CREATE',
    };
    return {
        type: SET_RANGES,
        ranges: ranges || [newRange],
        decimalRanges: decimalRanges || [newDecimalRange],
    };
}

export function clearRanges() {
    return {
        type: SET_RANGES,
        ranges: [],
        decimalRanges: [],
    };
}

export function addRange(range, decimalRange) {
    const timestamp = new Date().getTime();
    const newRange = {
        id: timestamp,
        min: null,
        max: null,
        status: 'CREATE',
    };
    const newDecimalRange = {
        rangeId: timestamp,
        id: timestamp,
        min: null,
        max: null,
        roundingTo: null,
        badge: null,
        status: 'CREATE',
    };
    return {
        type: ADD_RANGE,
        range: range || newRange,
        decimalRange: decimalRange || newDecimalRange,
    };
}

export function addDecimalRange(rangeId) {
    const newDecimalRange = {
        rangeId,
        id: new Date().getTime(),
        min: null,
        max: null,
        roundingTo: null,
        badge: null,
        status: 'CREATE',
    };
    return {
        type: ADD_DECIMAL_RANGE,
        decimalRange: newDecimalRange,
    };
}

export function setValueRange(rangeId, minValue, maxValue, status) {
    return {
        type: SET_VALUE_RANGE,
        rangeId,
        minValue,
        maxValue,
        status,
    };
}

export function setValueDecimalRange(
    decimalRangeId,
    decimalMinValue,
    decimalMaxValue,
    decimalRoundingToValue,
    activeBadge,
    status
) {
    return {
        type: SET_VALUE_DECIMAL_RANGE,
        decimalRangeId,
        decimalMinValue,
        decimalMaxValue,
        decimalRoundingToValue,
        activeBadge,
        status,
    };
}

export function deleteRange(rangeId, status) {
    return {
        type: DELETE_RANGE,
        rangeId,
        status,
    };
}

export function deleteDecimalRange(decimalRangeId, rangeId, status) {
    return {
        type: DELETE_DECIMAL_RANGE,
        decimalRangeId,
        rangeId,
        status,
    };
}
