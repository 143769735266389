import { useContext, useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { format } from 'date-fns';
import { getCompetitividadeMediaGeral } from '../services';
// @ts-ignore
import { FiltrosContext } from '../Pages/CompetitividadeGeral/index.tsx';

export const useMediaCompetitividadeGeral = () => {
    const filtros = useContext(FiltrosContext);
    const [
        graficoCompetitividadeMediaGeral,
        setGraficoCompetitividadeMediaGeral,
    ] = useState<any>({});

    const listCompetitividadeMediaGeral = async () => {
        const params = {
            filters: [
                {
                    filter: 'STOREID',
                    values: filtros?.lojasSelecionadas || null,
                },
                {
                    filter: 'PRODUCTID',
                    values: filtros?.produtosSelecionados || null,
                },
                {
                    filter: 'STARTDATE',
                    values: filtros?.periodoSelecionado?.length
                        ? [format(filtros.periodoSelecionado[0], 'yyyy-MM-dd')]
                        : null,
                },
                {
                    filter: 'ENDDATE',
                    values: filtros?.periodoSelecionado?.length
                        ? [format(filtros.periodoSelecionado[1], 'yyyy-MM-dd')]
                        : null,
                },
                {
                    filter: 'STORETYPE',
                    values: filtros?.tipoLojasSelecionadas || null,
                },
                {
                    filter: 'CATEGORYLEVEL1',
                    values: filtros?.categoriaNivel1Selecionadas || null,
                },
                {
                    filter: 'CATEGORYLEVEL2',
                    values: filtros?.categoriaNivel2Selecionadas || null,
                },
                {
                    filter: 'COMPETITORSTOREID',
                    values: filtros?.competitividadeSelecionada || null,
                },
            ],
        };
        const resp = await getCompetitividadeMediaGeral(params);
        const key = Object.keys(resp)[0]?.split('.');
        return resp[Object.keys(resp)[0]]
            ? {
                  [key[1]]:
                      parseFloat(
                          parseFloat(
                              // @ts-ignore
                              resp[Object.keys(resp)[0]] * 100
                          ).toFixed(2)
                      ),
              }
            : null;
    };

    const { isLoading, isError, data, error } = useQuery(
        ['competitividadeMediaGeral', filtros],
        listCompetitividadeMediaGeral,
        {
            retry: false,
            cacheTime: 6 * 180 * 1000,
            staleTime: 6 * 180 * 1000,
        }
    );

    useEffect(() => {
        const avg_average_competitivity_retail =
            data?.avg_average_competitivity_retail;

        setGraficoCompetitividadeMediaGeral({
            exporting: {
                enabled: false,
            },
            chart: {
                type: 'gauge',
                backgroundColor: 'transparent',
                height: 220,
            },
            title: {
                text: '',
            },
            pane: {
                startAngle: -90,
                endAngle: 89.9,
                background: null,
                center: ['50%', '75%'],
                size: '135%',
            },
            yAxis: {
                min: 75,
                max: 125,
                tickInterval: 5,
                tickLength: 0,
                tickWidth: 0,
                minorTickInterval: null,
                labels: {
                    distance: 16,
                    style: {
                        fontSize: '12px',
                        color: '#959595',
                    },
                    formatter: function () {
                        const label =
                            this.axis.defaultLabelFormatter.call(this);
                        if (label % 2 === 1) {
                            return `${label}%`;
                        }
                    },
                },
                plotBands: [
                    {
                        from: 75,
                        to: 85,
                        color: '#EC5B62', // red
                        thickness: 27,
                    },
                    {
                        from: 85,
                        to: 95,
                        color: '#F29423', // yellow
                        thickness: 27,
                    },
                    {
                        from: 95,
                        to: 105,
                        color: '#33AB84', // green
                        thickness: 27,
                    },
                    {
                        from: 105,
                        to: 115,
                        color: '#F29423', // yellow
                        thickness: 27,
                    },
                    {
                        from: 115,
                        to: 125,
                        color: '#EC5B62', // red
                        thickness: 27,
                    },
                ],
            },
            tooltip: {
                enabled: false,
            },
            series: [
                {
                    data: [avg_average_competitivity_retail],
                    dataLabels: {
                        y: 100,
                        formatter: function () {
                            const label = this.y / 100;
                            return label.toLocaleString('pt-BR', {
                                style: 'percent',
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            });
                        },
                        borderWidth: 0,
                        color: '#323232',
                        style: {
                            fontSize: '32px',
                            fontWeight: '300',
                            fontFamily: 'Open Sans',
                            fontStyle: 'normal',
                        },
                    },
                    dial: {
                        radius: '65%',
                        backgroundColor: '#505050',
                        baseWidth: 12,
                        baseLength: '0%',
                        rearLength: '0%',
                    },
                    pivot: {
                        backgroundColor: '#505050',
                        radius: 6,
                    },
                },
            ],
        });
    }, [data]);

    return {
        isLoading,
        isError,
        graficoCompetitividadeMediaGeral,
        data,
        error,
    };
};
