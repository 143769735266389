import React, { HtmlHTMLAttributes } from 'react';
import { Radio } from 'rsuite';
import { RadioProps } from 'rsuite/lib/Radio';
import { Card } from '../../../../../components/atoms/Card';
import './style.scss';

export type ConcorrenteCardProps = {} & HtmlHTMLAttributes<HTMLDivElement>;

const ConcorrenteCard = ({
    className = '',
    ...props
}: ConcorrenteCardProps) => (
    <Card
        hasBorder={false}
        disableHover
        className={['concorrente-card', className].join(' ')}
        {...props}
    />
);

const Header = ({
    className = '',
    ...props
}: HtmlHTMLAttributes<HTMLDivElement>) => (
    <div
        className={['concorrente-card__header', className].join(' ')}
        {...props}
    />
);

const Title = ({
    className = '',
    children,
    ...props
}: HtmlHTMLAttributes<HTMLHeadingElement>) => (
    <h3
        className={[
            'concorrente-card__title',
            'font-size-75-bold',
            className,
        ].join(' ')}
        {...props}
    >
        {children}
    </h3>
);

const Subtitle = ({
    className = '',
    children,
    ...props
}: HtmlHTMLAttributes<HTMLHeadingElement>) => (
    <p
        className={[
            'concorrente-card__subtitle',
            'font-size-50-regular',
            className,
        ].join(' ')}
        {...props}
    >
        {children}
    </p>
);

const Content = ({
    className = '',
    ...props
}: HtmlHTMLAttributes<HTMLDivElement>) => (
    <div
        className={['concorrente-card__content', className].join(' ')}
        {...props}
    />
);

export type OptionProps = RadioProps;

const Option = ({
    className = '',
    children,
    checked,
    ...props
}: OptionProps) => (
    <div
        className={[
            'concorrente-card__option',
            checked ? 'concorrente-card__option-is-active' : '',
            className,
        ].join(' ')}
    >
        <Radio {...props}>{children}</Radio>
    </div>
);

ConcorrenteCard.Header = Header;
ConcorrenteCard.Title = Title;
ConcorrenteCard.Subtitle = Subtitle;
ConcorrenteCard.Content = Content;
ConcorrenteCard.Option = Option;

export { ConcorrenteCard };
