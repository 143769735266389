import React, { useEffect, useState } from 'react';
import {
    Container,
    Content,
    FlexboxGrid,
    DatePicker,
    Button,
    HelpBlock,
    InputGroup,
    Input,
} from 'rsuite';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { withRouter, useParams } from 'react-router-dom';
import { parseISO, isBefore } from 'date-fns';
import { LoadingSpiner } from '../../../../../components/LoadingSpinner';
import Modal from '../../../../../components/NewModalConfirmacao';
import { saveCampanha, updateCampanha } from './services';
import Steps from '../Components/Steps';
import { getCampaign } from '../services';
import { getLojasParticipantes } from '../LojasParticipantes/services';

const DeleteMessage = () => (
    <>
        <p>
            Ao voltar, você perderá os dados preenchidos nessa etapa. Para
            salvar os dados prossiga para Escolher Lojas.
        </p>
        <br />
        <p>Você confirma que deseja voltar?</p>
    </>
);

const DadosCampanha = ({ history }) => {
    const { id } = useParams();
    const [showModalDescartarCampanha, setShowModalDescartarCampanha]
        = useState(false);
    const [campanha, setCampanha] = useState({
        name: '',
        starting_date: null,
        expiration_date: null,
        author: JSON.parse(localStorage.getItem('usuario'))?.email,
        client_id: JSON.parse(localStorage.getItem('cliente'))?.codigo,
        status: 'DRAFT',
    });
    const [errors, setErrors] = useState({});
    const [lojasParticipantes, setLojasParticipantes] = useState({
        content: [],
    });

    const back = () => {
        history.push('/ipa/campanhas');
    };

    const closeDescartar = () => {
        setShowModalDescartarCampanha(false);
    };

    const validForm = () => {
        const errors = {};
        let formIsValid = true;

        if (!campanha.name) {
            formIsValid = false;
            errors.name = 'Campo obrigatório';
        }

        if (!campanha.starting_date) {
            formIsValid = false;
            errors.starting_date = 'Campo obrigatório';
        }

        if (!campanha.expiration_date) {
            formIsValid = false;
            errors.expiration_date = 'Campo obrigatório';
        }

        setErrors(errors);
        return formIsValid;
    };

    const createCampanha = async () => {
        let response = null;
        const dadosCampanha = {
            ...campanha,
            offers: [],
            stores: [],
        };
        if (campanha.status === 'CURRENT' || campanha.status === 'CLOSED') {
            history.push(`/ipa/campanhas/cadastro/blocos-de-oferta/${id}`);
        } else if (validForm()) {
            if (id) {
                response = await updateCampanha(dadosCampanha);
            } else {
                response = await saveCampanha(dadosCampanha);
            }
            if (response?.campaign_id) {
                history.push(
                    `/ipa/campanhas/cadastro/blocos-de-oferta/${response.campaign_id}`,
                );
            }
        }
    };

    const listCampaign = async () => {
        const response = await getCampaign(id);
        response.data.starting_date = parseISO(response.data.starting_date);
        response.data.expiration_date = parseISO(response.data.expiration_date);
        setCampanha((oldState) => ({ ...oldState, ...response.data }));
    };

    const listLojasParticipantes = async () => {
        const response = await getLojasParticipantes({
            id,
            page: 0,
            size: 20,
        });
        if (response) setLojasParticipantes(response);
    };

    useEffect(() => {
        if (id) {
            listCampaign();
            listLojasParticipantes();
        }
    }, []);

    return (
        <Container className="main-container cadastro-dados-campanha-page">
            <Steps
                rota={history.location.pathname}
                campanhaId={id}
                status={campanha?.status}
                lojas={lojasParticipantes?.content?.length}
            />
            <Content className="dados-campanha-header">
                <LoadingSpiner size="md" />
                <FlexboxGrid>
                    <FlexboxGrid.Item colspan={24}>
                        <FlexboxGrid>
                            <FlexboxGrid.Item colspan={24}>
                                <h2>Dados da campanha</h2>
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item colspan={24}>
                                <FlexboxGrid className="dados-campanha-form-container">
                                    <FlexboxGrid.Item colspan={8}>
                                        <div className="dados-campanha-input-container">
                                            <span className="label">
                                                Nome da Campanha
                                            </span>
                                            <InputGroup
                                                inside
                                                className="input-outline-no-icon"
                                            >
                                                <Input
                                                    id="dados-campanha-input"
                                                    value={campanha.name}
                                                    onChange={(value) => setCampanha(
                                                            (oldState) => ({
                                                                ...oldState,
                                                                name: value,
                                                            }),
                                                        )}
                                                />
                                                {errors.name ? (
                                                    <HelpBlock
                                                        style={{ color: 'red' }}
                                                    >
                                                        {errors.name}
                                                    </HelpBlock>
                                                ) : null}
                                            </InputGroup>
                                        </div>
                                    </FlexboxGrid.Item>
                                    <FlexboxGrid.Item>
                                        <div className="dados-campanha-input-container">
                                            <span className="label">
                                                Início de Circulação
                                            </span>
                                            <DatePicker
                                                id="dados-campanha-data-inicio"
                                                oneTap
                                                className="data-campanha"
                                                format="DD/MM/YYYY"
                                                disabledDate={(date) => isBefore(date, new Date())}
                                                value={campanha.starting_date}
                                                onChange={(value) => setCampanha((oldState) => ({
                                                        ...oldState,
                                                        starting_date: value,
                                                    }))}
                                            />
                                            {errors.starting_date ? (
                                                <HelpBlock
                                                    style={{ color: 'red' }}
                                                >
                                                    {errors.starting_date}
                                                </HelpBlock>
                                            ) : null}
                                        </div>
                                    </FlexboxGrid.Item>
                                    <FlexboxGrid.Item>
                                        <div className="dados-campanha-input-container">
                                            <span className="label">
                                                Final de Circulação
                                            </span>
                                            <DatePicker
                                                id="dados-campanha-data-final"
                                                oneTap
                                                format="DD/MM/YYYY"
                                                disabledDate={(date) => (campanha.starting_date
                                                        ? isBefore(
                                                              date,
                                                              campanha.starting_date,
                                                          )
                                                        : isBefore(
                                                              date,
                                                              new Date(),
                                                          ))}
                                                className="data-campanha"
                                                value={campanha.expiration_date}
                                                onChange={(value) => setCampanha((oldState) => ({
                                                        ...oldState,
                                                        expiration_date: value,
                                                    }))}
                                            />
                                            {errors.expiration_date ? (
                                                <HelpBlock
                                                    style={{ color: 'red' }}
                                                >
                                                    {errors.expiration_date}
                                                </HelpBlock>
                                            ) : null}
                                        </div>
                                    </FlexboxGrid.Item>
                                </FlexboxGrid>
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </Content>
            <Content className="navegacao-dados-campanha">
                <FlexboxGrid justify="space-between">
                    <FlexboxGrid.Item>
                        <Button
                            appearance="link"
                            onClick={() => (id
                                    ? back()
                                    : setShowModalDescartarCampanha(true))}
                        >
                            <ArrowBackIcon />
                            {' '}
                            Voltar para campanhas
                        </Button>
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item>
                        <Button id="dados-campanha-next" appearance="primary" onClick={createCampanha}>
                            <ArrowForwardIcon />
                            {' '}
                            {campanha.status === 'CURRENT' || campanha.status === 'CLOSED'
                                ? 'BLOCOS DE OFERTAS'
                                : id
                                ? 'ATUALIZAR CAMPANHA'
                                : 'CRIAR CAMPANHAS'}
                        </Button>
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </Content>
            <Modal
                show={showModalDescartarCampanha}
                onConfirm={back}
                onCancel={closeDescartar}
                title="Descartar campanha"
                message={DeleteMessage()}
                confirmButton="Confirmar"
                cancelButton="Cancelar"
            />
        </Container>
    );
};

export default withRouter(DadosCampanha);
