import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import { Alert } from 'rsuite';
import {
    setResultScenarioData,
} from '../../../reducers/calculadoraPrevisaoDemanda/resultScenario';
import { selectorCalculadoraPrevisaoDemanda } from '../../../reducers/calculadoraPrevisaoDemanda/calculadoraPrevisaoDemandaReducer';
import { NotificationComponent } from '../Components';
import * as service from '../services';
import { AllScenario } from '../types';

export const useSaveScenarios = () => {
    const dispatch = useDispatch();

    const { resultScenario, inputCalculos } = useSelector(
        selectorCalculadoraPrevisaoDemanda,
    );

    const hasInputValues
        = inputCalculos.inputs.base_price !== 0
        && inputCalculos.inputs.new_price !== 0
        && inputCalculos.inputs.average_monthly_sales !== 0;

    const handleSaveScenario = async (data: AllScenario) => {
        const date = new Date();
        const savingDate = format(date, 'yyyy-MM-dd');
        const saveData = {
            ...data,
            scenario: {
                ...data.scenario,
                simulation_date: savingDate,
            },
        };

        if (!hasInputValues) {
            Alert.warning('Por favor, preencha os campos de preços antes de salvar', 5000);
            return;
        }

        const response = await service.getSaveScenarios(saveData);

        if (response) {
            NotificationComponent('Cenário salvo com sucesso!');
            dispatch(setResultScenarioData({ name: 'showModalSave', data: false }));
            // dispatch(setResultScenarioData({ name: 'isEditing', data: true }));
        }
    };

    const handleUpdateScenario = async (data: AllScenario) => {
        if (!hasInputValues) {
            Alert.warning('Por favor, preencha os campos de preços antes de salvar', 5000);
            return;
        }
        const id = resultScenario.editId;
        const response = await service.requestScenariosByID('PUT', id, data);
        if (response) {
            NotificationComponent('Cenário salvo com sucesso!');
            dispatch(setResultScenarioData({ name: 'showModalSave', data: false }));
            dispatch(setResultScenarioData({ name: 'showModalUpdate', data: false }));
        }
    };

    const handleSavingTypeScenario = async (data: AllScenario) => {
        if (resultScenario.isEditing) {
            handleUpdateScenario(data);
        } else {
            handleSaveScenario(data);
        }
    };

    const handleShowModal = () => {
        if (resultScenario.isEditing) {
            dispatch(setResultScenarioData({ name: 'showModalUpdate', data: true }));
        } else {
            dispatch(setResultScenarioData({ name: 'showModalSave', data: true }));
        }
    };

    const handleShowSaveModal = () => {
        dispatch(setResultScenarioData({ name: 'isEditing', data: false }));
        dispatch(setResultScenarioData({ name: 'showModalSave', data: true }));
        dispatch(setResultScenarioData({ name: 'showModalUpdate', data: false }));
    };

    return {
        saveHandlers: {
            handleSaveScenario,
            handleShowModal,
            handleUpdateScenario,
            handleShowSaveModal,
            handleSavingTypeScenario,
        },
        saveState: {
        },
    };
};
