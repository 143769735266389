import { useState } from 'react';
import { getRequestBySystemAddress } from '../../services';

const selectDataObj = {
    id: 1,
    uf: [],
    cidade: [],
    ufValue: [],
    cidadeValue: [],
};

const selectBodyObj = {
    cidade: [],
    uf: [],
    regiao: null,
    field: null,
    query: null,
};

export const useSystemRequestSelectRegion = () => {
    const [selectData, setSelectData] = useState([selectDataObj]);
    const [ufData, setUfData] = useState([]);
    const [cidadeData, setCidadeData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [timer, setTimer] = useState(null);

    const setList = (name, position, data) => {
        const newObj = selectData.map((item) => (item.id === position ? ({ ...item, [name]: data }) : item));
        return newObj;
    };

    const handleGetRegion = async (name, position, query) => {
        const idExist = selectData.find((item) => (item.id === position));

        clearTimeout(timer);
        const cityBody = {
            ...selectBodyObj,
            uf: typeof idExist?.ufValue === 'string' ? [idExist?.ufValue] : [],
            field: name,
            query,
        };

        const ufBody = {
            cidade: [],
            uf: [],
            regiao: null,
            field: 'uf',
            query,
        };

        if (idExist?.ufValue !== '' && name === 'uf') {
            const selectedData = [{ label: idExist?.ufValue, value: idExist?.ufValue }];
            const newObj = setList(name, position, selectedData);
            setSelectData(newObj);
        }

        const isCity = name === 'cidade';
        setIsLoading(true);

        const newTimer = setTimeout(async () => {
            const res = await getRequestBySystemAddress(isCity ? cityBody : ufBody);

            if (res.length) {
                const data = res.map((item) => ({ label: item.key, value: item.key }));
                const newObj = setList(name, position, data);
                const findID = selectData.some((item) => item.id === position);

                if (findID) {
                    setSelectData(newObj);
                } else {
                    setSelectData((prevState) => ([
                        ...prevState,
                        {
                            ...selectDataObj,
                            id: position,
                            [name]: data,
                        },
                    ]));
                }
                setIsLoading(false);
            } else {
                setIsLoading(false);
            }
        }, 500);

        setTimer(newTimer);
    };

    const handleSelectRegion = (index, name, value) => {
        const objType = {
            uf: 'ufValue',
            cidade: 'cidadeValue',
        };
        const newObj = selectData.map((item) => (item.id === index ? ({ ...item, [objType[name]]: value }) : item));
        const findID = selectData.some((item) => item.id === index);

        if (findID) {
            setSelectData(newObj);
        }
    };

    const handleGetUF = async (city: string) => {
        const ufBody = {
            cidade: [city],
            uf: [],
            regiao: null,
            field: 'uf',
            query: '',
        };

        const res = await getRequestBySystemAddress(ufBody);

        if (res.length) {
            const data = res.map((item) => ({ label: item.key, value: item.key }));
            setUfData(data);
        }
    };

    const handleGetCidade = async (uf: string, query: string) => {
        const body = {
            cidade: [],
            uf: [uf],
            regiao: null,
            field: 'cidade',
            query,
        };

        const res = await getRequestBySystemAddress(body);

        if (res.length) {
            const data = res.map((item) => ({ label: item.key, value: item.key }));
            setCidadeData(data);
        }
    };

    return {
        selectData,
        isLoading,
        handleGetRegion,
        handleSelectRegion,
        handleGetUF,
        handleGetCidade,
        ufData,
        cidadeData,
    };
};
