import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Alert } from 'rsuite';
import {
    setSavedScenarioState,
} from '../../../reducers/calculadoraPrevisaoDemanda/savedScenario';
import {
    selectorCalculadoraPrevisaoDemanda,
} from '../../../reducers/calculadoraPrevisaoDemanda/calculadoraPrevisaoDemandaReducer';
import * as service from '../services';

export const useCompareScenarios = () => {
    const dispatch = useDispatch();

    const history = useHistory();

    const { savedScenario } = useSelector(
        selectorCalculadoraPrevisaoDemanda,
    );

    const setNewCompareList = (data) => {
        const margin = Math.max(...data.map((obj) => obj.outputs.new_price_scenario.margin));
        const demand = Math.max(...data.map((obj) => obj.outputs.new_price_scenario.demand_forecast));
        const revenue = Math.max(...data.map((obj) => obj.outputs.new_price_scenario.revenue_forecast));
        const profit = Math.max(...data.map((obj) => obj.outputs.new_price_scenario.profit_forecast));

        const newList = data.map((obj) => ({
            ...obj,
            highlight: {
                margem: obj.outputs.new_price_scenario.margin === margin,
                demanda: obj.outputs.new_price_scenario.demand_forecast === demand,
                receita: obj.outputs.new_price_scenario.revenue_forecast === revenue,
                lucro: obj.outputs.new_price_scenario.profit_forecast === profit,
            },
        }));

        return newList;
    };

    const compareScenarioRequest = async () => {
        const data = savedScenario.selectedScenarioItemList;
        const response = await service.getCompareScenarios(data);
        if (response.length) {
            dispatch(setSavedScenarioState({
                name: 'compareScenariosList',
                data: setNewCompareList(response),
            }));
        }
    };

    const compareScenario = () => {
        const items = savedScenario.selectedScenarioItemList.length;
        if (items < 2 || items > 4) {
            Alert.warning(
                'Para comparar cenários, defina no mínimo 2 ou no máximo 4 cenários',
                5000,
            );
        } else {
            history.push('/ipa/comparacao-cenarios');
            dispatch(setSavedScenarioState({
                name: 'selectedScenarioItemList',
                data: [],
            }));
            compareScenarioRequest();
        }
    };

    return {
        compareHandlers: {
            compareScenario,
        },
    };
};
