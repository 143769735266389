export const dataVideoModuleReportModal = [
    {
        link: (
            <iframe
                width="100%"
                height="524"
                src="https://www.youtube.com/embed/_f1Ap0BpOb8?si=dYJS3eIKd7dY-Lau?&autoplay=1"
                title="Conheça o Módulo de Relatórios"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
            />
        ),
    },
];

export const videosData = [
    {
        title: 'Competitividade entre varejos',
        links: (
            <iframe
                src="https://www.youtube.com/embed/_f1Ap0BpOb8"
                width={501}
                Height={286}
                allowFullScreen
            />
        ),
    },
    {
        title: 'Competitividade entre produtos',
        links: (
            <iframe
                src="https://www.youtube.com/embed/4iBRCIUa76U"
                width={501}
                Height={286}
                allowFullScreen
            />
        ),
    },
];

export const filterTitle = ['COMPARAR', 'ENTRE', 'ONDE', 'PERÍODO'];

export const firstLocalData = [
    { label: 'Cidade', value: 'Cidade' },
    { label: 'UF', value: 'UF' },
    { label: 'Todo o território', value: 'Todo o território' },
];

export const periodData = [
    { label: 'Últimas 4 semanas', value: 4 },
    { label: 'Últimas 8 semanas', value: 8 },
    { label: 'Últimas 12 semanas', value: 12 },
];

export const setPlaceholderChooseSentence = {
    Produto: 'Escolha os produtos',
    Rede: 'Rede referência',
    Cidade: 'Escolha as cidades',
    UF: 'Escolha as UFs',
};

export const setPlaceholderSelectedSentence = {
    Produto: 'Produtos selecionados',
    Rede: 'Redes selecionadas',
    Cidade: 'Cidades selecionadas',
    UF: 'UFs selecionadas',
};

export const setVersusPlaceholderChooseSentence = {
    Rede: 'Escolha até 10 redes',
    Produto: 'Escolha até 10 produtos',
};

export const setVersusPlaceholderSelectedSentence = {
    Rede: 'Redes competidoras',
    Produto: 'Produtos competidores',
};

export const filtrosDataType = {
    Categoria: 'categoria',
    Fabricante: 'fabricante',
    Marca: 'marca',
    Seção: 'secao',
    Produto: 'produto',
    'Tipo de produto': 'tipoProduto',
    'Tipo de Loja': 'tipoLoja',
    'Tipo promoção': 'tipoPromocao',
    'Flag promoção': 'flagPromocao',
    Cidade: 'cidade',
    'CNPJ da Loja': 'cnpjLoja',
    'Origem do preço': 'origemPreco',
    UF: 'uf',
    Loja: 'loja',
    Rede: 'rede',
    Região: 'regiao',
};

export const localDataType = {
    Cidade: 'CIDADE',
    UF: 'UF',
    'Todo o território': 'TODO_TERRITORIO',
};

export const reverseLocalDataType = {
    CIDADE: 'Cidade',
    UF: 'UF',
    TODO_TERRITORIO: 'Todo o território',
};

export const seriesColors = [
    '#76D680',
    '#A371E2',
    '#DFA25B',
    '#F25E5E',
    '#609BCE',
    '#B30089',
    '#FFB46E',
    '#FFD76E',
    '#5FC8FF',
    '#FF73C3',
];

export const defaultChartOption = {
    chart: {
        type: 'column',
    },
    title: {
        text: '',
    },

    exporting: {
        enabled: false,
    },
    tooltip: {
        useHTML: true,
        shared: true,
        pointFormatter() {
            let symbol = '';

            if (this.graphic && this.graphic.symbolName) {
                switch (this.graphic.symbolName) {
                    case 'circle':
                        symbol = '●';
                        break;
                    case 'diamond':
                        symbol = '♦';
                        break;
                    case 'square':
                        symbol = '■';
                        break;
                    case 'triangle':
                        symbol = '▲';
                        break;
                    case 'triangle-down':
                        symbol = '▼';
                        break;
                    default:
                        return null;
                }
            } else if (this.marker && this.marker.symbol) {
                const url = this.marker.symbol.replace(/^url\(|\)$/g, '');
                symbol = `<img src="${url}" alt="Marker" />`;
            }

            return this.products
                ? `<span style="color:${this.series.color}">${symbol}</span> ${
                      this.series.name === 'competitividade_geral'
                          ? 'Competitividade geral'
                          : this.series.name
                  }: <b>${this.y
                      .toFixed(1)
                      .toString()
                      .replace('.', ',')}%</b> <span> / ${
                      this.products
                  } produtos</span><br/>`
                : `<span style="color:${this.series.color}">${symbol}</span> ${
                      this.series.name === 'competitividade_geral'
                          ? 'Competitividade geral'
                          : this.series.name
                  }: <b>${this.y
                      .toFixed(1)
                      .toString()
                      .replace('.', ',')}%</b><br/>`;
        },
        headerFormat: '<b>{point.key}</b><br/>',
        xDateFormat: '%d/%m/%Y',
    },
    legend: {
        symbolWidth: 30,
    },
    yAxis: [],
    xAxis: {
        type: 'datetime',
        title: {
            text: 'TEMPO',
        },
        startOfWeek: 0,
        labels: {
            allowOverlap: true,
            padding: 0,
            rotation: 0,
            format: '{value: %d/%m/%Y}',
        },
    },
    plotOptions: {
        series: {
            lineWidth: 1,
            marker: {
                radius: 3,
            },

            states: {
                hover: {
                    brightness: -0.3, // darken
                },
            },
        },
    },
    series: [],
};

export const sortData = {
    asc: 'ASC',
    desc: 'DESC',
    competitividade: 'COMPETITIVIDADE',
    precoReferencia: 'PRECO_REFERENCIA',
    precoCompetidor: 'PRECO_COMPETIDOR',
};

export const tooltipCalculationText =
    'Para calcular a competitividade usamos somente produtos encontrados entre as redes comparadas.';

export const competitivityTypeObj = [
    {
        id: 'varejo',
        title: 'Competitividade de varejo',
        subtitle: 'Calcule a competitividade entre redes',
    },
    {
        id: 'produtos',
        title: 'Competitividade de produto',
        subtitle: 'Calcule a competitividade entre produtos',
    },
];

export const stringLimited = (string) => `${string.slice(0, 20)}...`;

export function getModel(props, query, name, identificadores) {
    return {
        query: query || '',
        field: name || null,
        login: JSON.parse(localStorage.getItem('usuario'))?.email,
        cliente: JSON.parse(localStorage.getItem('cliente'))?.codigo,
        addNotFound: true,
        canal: props.filtrosData?.canal || ['Canal físico', 'Canal digital'],
        identificadores: identificadores || null,
        preco: props.filtrosData?.preco?.name
            ? { name: props.filtrosData.preco.name }
            : { name: 'PRECO_PAGO' },
        gtin:
            name && name === 'produto'
                ? null
                : props.produto?.length
                ? props.produto
                : null,
        rede:
            name && name === 'rede'
                ? null
                : props.rede?.length
                ? props.rede
                : null,
        uf: name && name === 'uf' ? null : props?.uf?.length ? props.uf : null,
        cidade:
            name && name === 'cidade'
                ? null
                : props?.cidade?.length
                ? props.cidade
                : null,
    };
}
