// @ts-nocheck
import { useQuery } from '@tanstack/react-query';

import { getTotalPrices } from '../services';

export const useTotalPrice = (params) => {
    const totalPrice = async () => {
        const resp = await getTotalPrices(params);
        if (resp) return resp;

        return [];
    };

    const {
        isFetching,
        data,
        refetch,
    } = useQuery(['totalPrice'], totalPrice, {
        retry: false,
        enabled: false,
    });

    const totalPriceData = data?.data;
    const totalPriceLoading = isFetching;
    const totalPriceRefetch = refetch;

    return {
        totalPriceData,
        totalPriceLoading,
        totalPriceRefetch,
    };
};
