import { useQuery } from '@tanstack/react-query';
import _ from 'lodash';
import { useRef, useState } from 'react';
import { Alert, Notification } from 'rsuite';
import { TableProps } from 'rsuite/lib/Table';
import {
    IUseFilterOptions,
    useFilterSection,
    useModal,
} from '../../../../../components';
import * as services from '../../../../../components/FilterSection/services';
import { usePagination } from '../../../../../hooks';
import { getCompetitorsInfo } from '../../../RevisaoPrecos/services_ts';
import { SalvarNegociacaoSchemaProps } from '../../components';
import { useSelectedDatapoints } from '../../hooks';
import {
    AddProductToNegotiationProps,
    SalvarNegociacaoData,
    addProductToNegotiation,
    desfazerAlteracaoDePrecos,
    downloadDatapointsNegotiation,
    getDatapointsNegotiation,
    patchNegotiationSaveEditedDatapoint,
    salvarNegociacao,
} from './NegociacaoFornecedor.services';
import * as T from './NegociacaoFornecedor.types';
import {
    AdicionarProdutoRefProps,
    SalvarNegociacaoRefProps,
} from './components';
import {
    PMZNewCostCalc,
    bestIdealPriceCalc,
    bestIdealPricePMZCalc,
    getFiltrosModel,
    newCostCalc,
    newPriceCalc,
    newPriceCompetitorsCalc,
    priceMarginCalc,
} from './utils';

const DEFAULT_ORDER: T.SortStateProps = {
    type: 'productId',
    orderBy: 'asc',
};

const DEFAULT_PAGINATION_OPTIONS = [
    {
        value: 20,
        label: 20,
    },
    {
        value: 40,
        label: 40,
    },
];

const INITIAL_DATA: T.NegotiationDataProps = {
    content: [],
    totalElements: 0,
    totalPages: 0,
};

const ROW_KEY = 'productsToBePricedId';

const options: IUseFilterOptions = {
    page: 'NEGOTIATION_SUPPLIER',
    filters: [
        {
            key: 'productIds',
            placeholder: 'Produto',
            queryFn: services.listProducts,
            textLoading: 'Carregando produtos...',
        },
        {
            key: 'productFamilyIds',
            placeholder: 'Família',
            queryFn: services.listFamilies,
            textLoading: 'Carregando famílias...',
        },
        {
            key: 'storeIds',
            placeholder: 'Loja',
            queryFn: services.listStores,
            selectAll: true,
            textLoading: 'Carregando lojas...',
        },
        {
            key: 'productBrand',
            placeholder: 'Marca',
            queryFn: services.listProductBrands,
            textLoading: 'Carregando marcas...',
        },
        {
            key: 'supplier',
            placeholder: 'Fornecedor',
            queryFn: services.listSuppliers,
            textLoading: 'Carregando fornecedores...',
        },
        {
            key: 'savedFilters',
            placeholder: 'Filtros salvos',
            queryFn: (query) => {
                return services.listSavedFilters(query, {
                    screen: 'NEGOTIATION_SUPPLIER',
                });
            },
            type: 'select',
            textLoading: 'Carregando filtros...',
        },
    ],
};

export const useNegociacaoFornecedor = () => {
    const [data, setData] = useState<T.NegotiationDataProps>(INITIAL_DATA);

    const filters = useFilterSection(options);

    const [modalsState, { onHide: handleCloseModal, onOpen: handleOpenModal }] =
        useModal();

    const selectedDatapoints = useSelectedDatapoints();

    const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);

    const [sort, setSort] = useState<T.SortStateProps>(DEFAULT_ORDER);

    const pagination = usePagination({
        totalElements: data?.totalElements,
    });

    const negociacaoModalRef = useRef<SalvarNegociacaoRefProps>(null);
    const adicionarProdutoRef = useRef<AdicionarProdutoRefProps>(null);

    const filtrosModel = getFiltrosModel(
        {
            ...filters.values,
            sort,
            size: pagination.pageSize,
            page: pagination.isOnInvalidLastIndex
                ? pagination.lastValidPage
                : pagination.activePage,
        },
        'camel',
    );

    const filtrosModelWithoutSortAndPagination = _.omit(filtrosModel, [
        'sort',
        'page',
        'size',
    ]);

    const downloadNegotiationModel = {
        filters: filtrosModelWithoutSortAndPagination,
        products: {
            selectedIds: selectedDatapoints.data.selected,
            excludedIds: selectedDatapoints.data.excluded,
        },
        selectedAll: selectedDatapoints.data.selectedAll,
        sort: filtrosModel.sort,
    };

    const handleDownloadNegotitation = async () => {
        downloadDatapointsNegotiation(downloadNegotiationModel).then(
            (resp) => resp,
        );
    };

    const handleGetDatapoints = (data: T.SuppliersResponseProps) => {
        setData((oldValue) => ({ ...oldValue, ...data }));
    };

    const handleSortColumn = (
        sortColumn: TableProps['sortColumn'],
        sortType: TableProps['sortType'],
    ) => {
        setSort({
            orderBy: sortType,
            type: sortColumn,
        });
    };

    const updateCompetitorData = (
        productsToBePricedId: string,
        response: T.NegotiationDataItemProps['competitorsInfo'],
    ) => {
        setData((oldState) => ({
            ...oldState,
            content: data.content.map((item) => {
                if (item.productsToBePricedId === productsToBePricedId) {
                    return {
                        ...item,
                        competitorsInfo: response,
                    };
                }
                return item;
            }),
        }));
    };

    const getInfoCompetitors = ({
        storeId,
        productId,
        productFamilyId,
        competitorsPrice,
        productsToBePricedId,
        productFamily,
    }: T.NegotitationRowDataProps) => {
        if (competitorsPrice !== 0 || productFamilyId) {
            const hasPreviousData = data.content.find(
                (item) => item.productsToBePricedId === productsToBePricedId,
            )?.competitorsInfo;

            if (hasPreviousData) return;

            getCompetitorsInfo(
                {
                    productId,
                    storeId,
                    productFamilyId: productFamily ? productFamilyId : null,
                },
                `competitor-row-${storeId}-${productId}`,
            ).then((data) => {
                if (data) updateCompetitorData(productsToBePricedId, data);
            });
        }
    };

    const handleExpandedRow = (rowData: T.NegotitationRowDataProps) => {
        let isOpen = false;
        const nextExpandedRowKeys = [];

        expandedRowKeys?.forEach((key) => {
            if (key === rowData[ROW_KEY]) {
                isOpen = true;
            } else {
                nextExpandedRowKeys.push(key);
            }
        });
        if (!isOpen) {
            nextExpandedRowKeys.push(rowData[ROW_KEY]);
            getInfoCompetitors(rowData);
        }

        setExpandedRowKeys(nextExpandedRowKeys);
    };

    const handleChangeObjectiveMargin = (
        index: number,
        value: string,
        rowData: T.SuppliersResponseContentProps,
    ) => {
        const marginValue = Number(value.replace(/\./g, '').replace(',', '.'));
        const newCost = newCostCalc(rowData, marginValue, rowData.newPrice);
        const PMZNewCost = PMZNewCostCalc(rowData, newCost);
        const bestIdealPrice = Number(
            bestIdealPriceCalc(rowData, marginValue)
                .toFixed(2)
                .replace(',', '.'),
        );
        const bestIdealPricePMZ = Number(
            bestIdealPricePMZCalc(rowData, bestIdealPrice)
                .toFixed(2)
                .replace(',', '.'),
        );
        const newPriceMargin = priceMarginCalc(
            rowData,
            rowData?.newPrice,
            rowData.newCost,
        );
        const hasRetailPrice =
            rowData.retailPrice !== null && rowData.retailPrice > 0;
        const newRetailPriceMargin = hasRetailPrice
            ? priceMarginCalc(
                  rowData,
                  rowData.retailPrice,
                  newCost === 0 || newCost == null ? rowData.cost : newCost,
              )
            : null;
        const isValueNull = value === '0,0' ? null : marginValue;
        const dataContent = data?.content;

        const hasCompetitorsPrice =
            rowData.competitorsPrice !== null && rowData.competitorsPrice > 0;

        const newCompetitorPriceMargin = hasCompetitorsPrice
            ? priceMarginCalc(
                  rowData,
                  rowData.competitorsPrice,
                  newCost === 0 || newCost == null ? rowData.cost : newCost,
              )
            : null;

        if (rowData?.newCost !== null || rowData?.newPrice !== null) {
            dataContent[index] = {
                ...dataContent[index],
                newObjectiveMargin: isValueNull,
                newCost,
                PMZNewCost,
                newPriceMargin,
                bestIdealPrice,
                bestIdealPricePMZ,
                marginRetailPrice: newRetailPriceMargin,
                marginCompetitorsPrice: newCompetitorPriceMargin,
            };
        } else {
            dataContent[index] = {
                ...dataContent[index],
                newObjectiveMargin: isValueNull,
                bestIdealPrice,
                marginRetailPrice: newRetailPriceMargin,
                bestIdealPricePMZ,
                marginCompetitorsPrice: newCompetitorPriceMargin,
            };
        }
        setData((oldState) => ({ ...oldState, content: dataContent }));
    };

    const handleChangeNewCost = (
        index: number,
        value: string,
        rowData: T.SuppliersResponseContentProps,
    ) => {
        const cost = Number(value.replace(/\./g, '').replace(',', '.'));

        const PMZNewCost = PMZNewCostCalc(rowData, cost);
        const newPriceDecimals = Number(newPriceCalc(rowData, cost).toFixed(2));
        const newPrice = newPriceCalc(rowData, cost);
        const newPriceMargin = priceMarginCalc(rowData, newPriceDecimals, cost);
        const hasRetailPrice =
            rowData.retailPrice !== null && rowData.retailPrice > 0;
        const newRetailPriceMargin = hasRetailPrice
            ? priceMarginCalc(
                  rowData,
                  rowData.retailPrice,
                  cost === 0 || cost == null ? rowData.cost : cost,
              )
            : null;
        const newPriceCompetitors = newPriceCompetitorsCalc(
            rowData,
            newPriceDecimals,
        );
        const hasCompetitorsPrice =
            rowData.competitorsPrice !== null && rowData.competitorsPrice > 0;

        const newCompetitorPriceMargin = hasCompetitorsPrice
            ? priceMarginCalc(
                  rowData,
                  rowData.competitorsPrice,
                  cost === 0 || cost == null ? rowData.cost : cost,
              )
            : null;
        const dataContent = data?.content;

        if (value === '0,00') {
            dataContent[index] = {
                ...dataContent[index],
                newCost: null,
                newPrice: null,
                newPriceMargin: null,
                newPriceCompetitors: null,
                marginRetailPrice: newRetailPriceMargin,
                marginCompetitorsPrice: newCompetitorPriceMargin,
            };
        } else {
            dataContent[index] = {
                ...dataContent[index],
                newCost: cost,
                newPrice,
                PMZNewCost,
                newPriceMargin,
                marginRetailPrice: newRetailPriceMargin,
                newPriceCompetitors: hasCompetitorsPrice
                    ? newPriceCompetitors
                    : null,
                marginCompetitorsPrice: newCompetitorPriceMargin,
            };
        }
        setData((oldState) => ({ ...oldState, content: dataContent }));
    };

    const handleChangeNewPrice = (
        index: number,
        value: string,
        rowData: T.SuppliersResponseContentProps,
    ) => {
        const newPrice = Number(value.replace(/\./g, '').replace(',', '.'));
        const newCost = newCostCalc(
            rowData,
            rowData?.newObjectiveMargin,
            newPrice,
        );
        const PMZNewCost = PMZNewCostCalc(rowData, newCost);
        const newPriceMargin = priceMarginCalc(rowData, newPrice, newCost);
        const hasRetailPrice =
            rowData.retailPrice !== null && rowData.retailPrice > 0;
        const newRetailPriceMargin = hasRetailPrice
            ? priceMarginCalc(
                  rowData,
                  rowData.retailPrice,
                  newCost === 0 || newCost == null ? rowData.cost : newCost,
              )
            : null;
        const newPriceCompetitors = newPriceCompetitorsCalc(rowData, newPrice);
        const hasCompetitorsPrice =
            rowData.competitorsPrice !== null && rowData.competitorsPrice > 0;

        const newCompetitorPriceMargin = hasCompetitorsPrice
            ? priceMarginCalc(
                  rowData,
                  rowData.competitorsPrice,
                  newCost === 0 || newCost == null ? rowData.cost : newCost,
              )
            : null;

        const dataContent = data?.content;

        if (value === '0,00') {
            dataContent[index] = {
                ...dataContent[index],
                newCost: null,
                newPrice: null,
                newPriceMargin: null,
                newPriceCompetitors: null,
                marginRetailPrice: newRetailPriceMargin,
                marginCompetitorsPrice: newCompetitorPriceMargin,
            };
            setData((oldState) => ({ ...oldState, content: dataContent }));
        } else {
            dataContent[index] = {
                ...dataContent[index],
                newPrice,
                newCost,
                PMZNewCost,
                newPriceMargin,
                marginRetailPrice: newRetailPriceMargin,
                newPriceCompetitors: hasCompetitorsPrice
                    ? newPriceCompetitors
                    : null,
                marginCompetitorsPrice: newCompetitorPriceMargin,
            };
            setData((oldState) => ({ ...oldState, content: dataContent }));
        }
    };

    const getEditedDatapointModel = (
        rowData: T.NegotitationRowDataProps,
    ): T.NegotitationEditDatapointProps => ({
        id: rowData.productsToBePricedId,
        newObjectiveMargin: rowData.newObjectiveMargin,
        newCost: rowData.newCost,
        newPrice: rowData.newPrice,
        newPriceMargin: rowData.newPriceMargin,
        newPriceCompetitors: rowData.newPriceCompetitors,
        bestIdealPrice: rowData.bestIdealPrice,
        bestIdealPricePMZ: rowData.bestIdealPricePMZ,
        PMZNewCost: rowData.PMZNewCost,
    });

    const findDataAndUpdate = async (
        id: string,
        payload: Partial<T.NegotiationDataItemProps>,
    ) => {
        return new Promise<T.NegotiationDataItemProps>((resolve, reject) => {
            const index = data.content.findIndex(
                ({ productsToBePricedId }) => productsToBePricedId === id,
            );

            if (index === -1) {
                reject(new Error('Index not found'));
                return;
            }

            const currentData = data.content;

            currentData[index] = {
                ...currentData[index],
                ...payload,
            };

            setData((oldState) => ({ ...oldState, content: currentData }));

            resolve(data.content[index]);
        });
    };

    const handleInputBlur = async (
        rowData: T.NegotitationRowDataProps,
        _index: number,
        details: {
            onBlurValue: number | null;
            onFocusValue: number | null;
            hasChanges: boolean;
        },
    ) => {
        if (!details.hasChanges) return;
        const model = getEditedDatapointModel(rowData);
        const res = await patchNegotiationSaveEditedDatapoint(model);
        await findDataAndUpdate(rowData.productsToBePricedId, {
            isEdited: res.isEdited,
        });
    };

    const handleResetPageState = () => {
        filters.onClearAll();
        selectedDatapoints.reset();
    };

    const handleSaveNegotiation = async (
        formData: SalvarNegociacaoSchemaProps,
    ) => {
        const model: SalvarNegociacaoData = {
            name: formData.name,
            supplier: formData.supplier,
            startDate: formData.startDate,
            endDate: formData.endDate,
            datapoints: {
                excludedIds: selectedDatapoints.data.excluded,
                selectedIds: selectedDatapoints.data.selected,
            },
            selectedAll: selectedDatapoints.data.selectedAll,
            filters: filters.values,
        } as const;

        salvarNegociacao(model)
            .then((response) => {
                if (response.status !== 201) return;
                Notification.success({
                    description: 'Negociação salva com sucesso!',
                });
                handleCloseModal('salvarNegociacao');
                negociacaoModalRef.current?.handleReset();
                handleResetPageState();
            })
            .catch((err) => new Error(err.message));
    };

    const handleAddProductToNegotiation = async (formData: { id: string }) => {
        const model: AddProductToNegotiationProps = {
            negotiationId: formData.id,
            filters: filtrosModelWithoutSortAndPagination,
            datapoints: {
                selectedIds: selectedDatapoints.data.selected,
                excludedIds: selectedDatapoints.data.excluded,
            },
            selectedAll: selectedDatapoints.data.selectedAll,
        };

        addProductToNegotiation(model)
            .then((response) => {
                if (response.status !== 200) return;
                handleCloseModal('adicionarProdutoNegociacao');
                adicionarProdutoRef.current?.reset();
                Notification.success({
                    description: 'Produto adicionado à negociação',
                });
            })
            .catch((err) => new Error(err.message));
    };

    const { fetchStatus } = useQuery({
        queryKey: [
            'get-negociacao',
            filters.values,
            sort,
            pagination.activePage,
            pagination.pageSize,
        ],
        queryFn: async () => getDatapointsNegotiation(filtrosModel),
        retry: false,
        onSuccess: handleGetDatapoints,
    });

    const isTableDataLoading = !(fetchStatus === 'idle');

    const handleDesfazerAlteracaoDePrecos = async (
        rowData: T.NegotitationRowDataProps,
    ) => {
        try {
            const res = await desfazerAlteracaoDePrecos(rowData);
            if (!res) return;
            await findDataAndUpdate(rowData.productsToBePricedId, res);
            Alert.success('Alteração de preço desfeita com sucesso');
        } catch {
            Alert.error('Erro ao desfazer alteração de preço');
        }
    };

    return {
        filters,
        table: {
            data,
            isLoading: isTableDataLoading,
            rowKey: ROW_KEY,
            expandedRowKeys,
            sortColumn: sort.type,
            selectedDatapoints,
            pagination: {
                lengthMenu: DEFAULT_PAGINATION_OPTIONS,
                activePage: pagination.activePage,
                displayLength: pagination.pageSize,
                total: data?.totalElements ?? 0,
                onChangePage: pagination.handleChangePage,
                onChangeLength: pagination.handleChangeLength,
            },
            onSortColumn: handleSortColumn,
            onExpandRow: handleExpandedRow,
            onChangeNewCost: handleChangeNewCost,
            onChangeNewPrice: handleChangeNewPrice,
            onChangeObjectiveMargin: handleChangeObjectiveMargin,
            onBlur: handleInputBlur,
            contextMenu: {
                onUndoChanges: handleDesfazerAlteracaoDePrecos,
            },
        },
        handlers: {
            download: handleDownloadNegotitation,
            save: handleSaveNegotiation,
            openModal: handleOpenModal,
            closeModal: handleCloseModal,
        },
        modals: {
            salvarNegociacao: {
                show: modalsState?.salvarNegociacao,
                handleOpen: () => handleOpenModal('salvarNegociacao'),
                onHide: () => handleCloseModal('salvarNegociacao'),
                onSubmit: handleSaveNegotiation,
            },
            adicionarProdutoNegociacao: {
                ref: adicionarProdutoRef,
                show: modalsState.adicionarProdutoNegociacao,
                onHide: () => handleCloseModal('adicionarProdutoNegociacao'),
                onSubmit: handleAddProductToNegotiation,
            },
        },
    };
};
