import React, { HtmlHTMLAttributes } from 'react';
import logo from '../../../../../../assets/logo/logo-infoprice-preto-azul.svg';
import './style.scss';

export type LogoProps = HtmlHTMLAttributes<HTMLDivElement>;

const Logo = ({ children, ...props }: LogoProps) => (
    <div className="infoprice-logo" {...props}>
        <img src={logo} alt="InfoPrice" />
        {children}
    </div>
);

const Label = ({
    children,
    ...props
}: React.HtmlHTMLAttributes<HTMLHeadingElement>) => (
    <h2 {...props}>{children}</h2>
);

const Container = ({
    children,
    ...props
}: HtmlHTMLAttributes<HTMLDivElement>) => (
    <div className="infoprice-logo__container" {...props}>
        {children}
    </div>
);

Logo.Container = Container;
Logo.Label = Label;

export { Logo };
