import React from 'react';
import { Modal, Button } from 'rsuite';

const ModalLegenda = ({ onCancel, show }) => (

    <Modal size="md" backdrop show={show} className="modal-legenda-component">
        <Modal.Body>
            <h1>Detalhamento dos status de coletas</h1>
            <li>
                <b>Entregue no horário:</b>
                {' '}
                Coleta realizada e finalizada no horário previsto
            </li>
            <li>
                <b>Entregue atrasado:</b>
                {' '}
                Coleta realizada e finalizada após o horário previsto
            </li>
            <li>
                <b>Pendente: </b>
                {' '}
                Coleta ainda não finalizada, até 23h59 do dia de entrega
            </li>
            <li>
                <b>Não Entregue: </b>
                {' '}
                Coleta finalizada e não realizada. Exemplo: loja bloqueada
            </li>
        </Modal.Body>
        <Modal.Footer>
            <Button onClick={onCancel} appearance="primary">
                Fechar
            </Button>
        </Modal.Footer>
    </Modal>
);

export default ModalLegenda;
