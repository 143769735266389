import React from 'react';
// @ts-ignore
import { Tabela } from '../../Components/index.tsx';
// @ts-ignore
import { useProdutosMenosVendidos } from '../../../hooks/useProdutosMenosVendidos.tsx';

export const ProdutosMenosVendidos: React.FC = () => {
    const { data, isLoading } = useProdutosMenosVendidos();

    const columns = [
        {
            align: 'left',
            headerCell: 'Produto',
            dataKey: 'productId',
            flexGrow: 1,
        },
        {
            align: 'left',
            headerCell: 'Descrição do produto',
            dataKey: 'description',
            flexGrow: 2,
        },
        {
            align: 'left',
            headerCell: 'Margem',
            dataKey: 'realizedMargin',
            flexGrow: 1,
        },
        {
            align: 'left',
            headerCell: 'Unidades vendidas',
            dataKey: 'sumSalesAmount',
            flexGrow: 1,
        },
    ];

    return (
        <Tabela
            data={data}
            isLoading={isLoading}
            columns={columns}
            title="Lista de produtos menos vendidos"
        />
    );
};
