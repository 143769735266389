import React from 'react';
import {
    Button,
} from 'rsuite';
import styles from './styles.module.scss';

export type SendRequestButtonAreaTypes = {
    onClickSendRequest?: () => void;
}

export const SendRequestButtonArea = ({ onClickSendRequest }: SendRequestButtonAreaTypes) => (
    <div className={styles['send-request-button-area-container']}>
        <Button
            appearance="ghost"
            className={styles['more-request-button']}
            // onClick={() => setShowModalHowItWorks(true)}
        >
            Quero mais solicitações on demand
        </Button>
        <Button
            appearance="primary"
            className={styles['request-button']}
            onClick={onClickSendRequest}
        >
            Enviar solicitações
        </Button>
    </div>
);
