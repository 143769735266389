import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { z } from 'zod';
import { RootState } from '../../@types/RootState';

const schema = z.object({
    info_panel_data_able: z.boolean(),
    same_location: z.boolean(),
    radius: z.number().nullable(),
    period: z.number(),
    store_type: z.enum([
        'SAME_STORE_TYPE',
        'SAME_NETWORK_DIRECT_COMPETITOR',
        'ALL_STORE_TYPE',
    ]),
});

export type DadosInfopanelSchema = z.infer<typeof schema>;

const initialState = schema.parse({
    info_panel_data_able: false,
    store_type: 'SAME_STORE_TYPE',
    same_location: true,
    radius: 2,
    period: 7,
});

const slice = createSlice({
    name: 'dadosInfopanel',
    initialState,
    reducers: {
        SET_DADOS_INFOPANEL: (
            state,
            action: PayloadAction<DadosInfopanelSchema>,
        ) => ({ ...state, ...action.payload }),
    },
});

const { SET_DADOS_INFOPANEL } = slice.actions;

const selector = (state: RootState) => state.reducerIPA.dadosInfopanel;

export { SET_DADOS_INFOPANEL, initialState, schema, selector };

export default slice.reducer;
