import React, { useState } from 'react';
import { SelectPicker } from 'rsuite';
import styles from './DemandVersusRevenueChart.module.scss';
import { ElasticCrossChart } from '../ElasticCrossChart';
import { ElasticSimpleChart } from '../ElasticSimpleChart';

const elasticidadeSelectData = [
    {
        label: 'Elasticidade simples',
        value: 'simples',
    },
    {
        label: 'Elasticidade cruzada',
        value: 'cruzada',
    },
];

export const DemandVersusRevenueChart = () => {
    const [chartType, setchartType] = useState('simples');

    const handleSelectChartType = (value: string) => {
        if (value === 'simples') {
            setchartType('simples');
        } else {
            setchartType('cruzada');
        }
    };

    return (
        <div className={styles.container}>
            <div className={styles['header-wrapper']}>
                <div className={styles['title-wrapper']}>
                    <p className={styles.title}>Curva de demanda vs receita</p>
                    <p className={styles.subtitle}>Visualize o impacto das diferentes faixas de preço na demanda e receita</p>
                </div>
                <SelectPicker
                    className={styles.select}
                    cleanable={false}
                    searchable={false}
                    data={elasticidadeSelectData}
                    value={chartType}
                    onSelect={handleSelectChartType}
                />
            </div>
            {chartType === 'simples' ? (
                <ElasticSimpleChart />
            ) : (
                <ElasticCrossChart />
            )}
        </div>
    );
};
