import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import {
    FilterModelProps,
    GetDatapointProps,
    getFiltersGtin,
} from '../lib/services';
import { createFilterObject } from '../pages/GerenciadorPrecos/lib';

export type FILTER_OPTIONS =
    | 'products'
    | 'gtin'
    | 'segmentacaoSelecionada'
    | 'familiaSelecionada'
    | 'categoryLevel1'
    | 'categoryLevel2'
    | 'categoryLevel3'
    | 'categoryLevel4'
    | 'categoryLevel5'
    | 'categoryLevel6'
    | 'categoryLevel7'
    | 'lojasSelecionadas'
    | 'clustersSelecionados';

export type FilterItemProps = {
    cache: {
        label: string;
        value: string;
    }[];
    value: string[];
    checkAll?: boolean;
    indeterminate?: boolean;
};
export type FilterProps = Partial<Record<FILTER_OPTIONS, FilterItemProps>>;

export const useFilters = (filterList: FILTER_OPTIONS[]) => {
    const FILTROS = createFilterObject(filterList);
    const [filters, setFiltros] = useState<FilterProps>(FILTROS);
    const [searchProduct, setSearchProduct] = useState('');

    const setProductsList = (
        list: Record<
            | 'client'
            | 'store'
            | 'gtin'
            | 'description'
            | 'store_id'
            | 'store_brand'
            | 'product_id',
            string
        >[],
    ) => {
        const newList = list.map((item) => ({
            label: `${item.gtin} - ${item.description}`,
            value: item.gtin,
            gtin: item.gtin,
            product_id: item.product_id,
            description: item.description,
        }));

        const cacheList = filters?.gtin?.cache;

        const removeDuplicate = newList.filter(
            (i) => !filters?.gtin?.value.includes(i.value),
        );

        const concatList = filters?.gtin?.value.length
            ? cacheList?.concat(removeDuplicate)
            : newList;
        return concatList;
    };

    const searchProducts = async (query: string) => {
        const response = await getFiltersGtin({ query });
        if (response?.data) {
            return setProductsList(response.data);
        }
        return [];
    };
    const { isLoading: loadingProducts, data: gtin } = useQuery(
        ['filtroProductsRevisao', searchProduct],
        () => searchProducts(searchProduct),
        {
            retry: false,
        },
    );

    const data: Partial<
        Record<
            FILTER_OPTIONS,
            {
                content: typeof gtin;
                isLoading: boolean;
            }
        >
    > = {
        gtin: {
            content: gtin,
            isLoading: loadingProducts,
        },
    };

    const changeQuery = (value: string) => {
        setSearchProduct(value);
    };

    const cleanFilters = () => {
        setFiltros(FILTROS);
    };

    const selectFiltro = (item: unknown, filtro: FILTER_OPTIONS) => {
        setFiltros((oldValue) => ({
            ...oldValue,
            [filtro]: {
                ...oldValue[filtro],
                // @ts-ignore
                cache: [...oldValue[filtro].cache, item],
            },
        }));
    };

    const changeFiltros = (value: unknown[], filtro: FILTER_OPTIONS) => {
        let cache: Partial<Record<'label' | 'value', string>>[] | undefined;

        if (value === null) {
            cache = [];
            setFiltros((oldState) => ({
                ...oldState,
                [filtro]: {
                    ...oldState[filtro],
                    cache,
                },
            }));
        }

        const cacheData = filters[filtro];

        if (value !== null && value.length !== cacheData?.cache?.length) {
            cache = gtin?.filter((item) => value.indexOf(item.value) !== -1);

            setFiltros((oldValue) => ({
                ...oldValue,
                [filtro]: {
                    ...filters[filtro],
                    cache,
                    value,
                },
            }));
        }
    };

    const changeFiltroCheckAll = (filtro: FILTER_OPTIONS, checked: boolean) => {
        const content = data[filtro]?.content;

        let value: string[] | undefined = [];
        let cache: { label: string; value: string }[] | undefined = [];

        if (checked && content) {
            value = content.map((item) => item.value);
            cache = [...content];
        }

        setFiltros((oldValue) => ({
            ...oldValue,
            [filtro]: {
                ...oldValue[filtro],
                cache,
                value,
            },
        }));
    };

    function getFiltersModel(): GetDatapointProps {
        let data: FilterModelProps[] = [];

        Object.keys(filters).forEach((key) => {
            if (filters[key as keyof typeof filters]?.value.length === 0) {
                return;
            }

            data = [
                ...data,
                {
                    field: key,
                    operator: 'in',
                    values: filters[key as keyof typeof filters]?.value ?? [],
                },
            ];
        });

        return { filters: data };
    }

    const model = getFiltersModel();

    return {
        filters,
        data,
        model,
        changeQuery,
        selectFiltro,
        changeFiltros,
        cleanFilters,
        changeFiltroCheckAll,
    };
};
