import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { useSelector, shallowEqual } from 'react-redux';
import {
    Button,
    FormGroup,
    FlexboxGrid,
    Col,
    Icon,
    Whisper,
    Tooltip,
    InputGroup,
    Panel,
    PanelGroup,
    Table,
    ControlLabel,
    DatePicker,
    Toggle,
    Badge,
    Container,
    Content,
    Radio,
    RadioGroup,
    HelpBlock,
    Alert,
} from 'rsuite';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import { isBefore, addDays, format, toDate, parseISO } from 'date-fns';
import CurrencyFormat from 'react-currency-format';
import CurrencyInput from 'react-currency-input';
import { LoadingSpiner } from '../../../components/LoadingSpinner';
import ModalConfirmacaoFamilia from './Components/ModalConfirmacaoFamilia';
import ModalFamiliaPrecificacaoLoja from './Components/ModalFamiliaPrecificacaoLoja';
import {
    savePrecificacao,
    getDatapointProdutoLoja,
    getModalConfimacao,
    getGridModalFamilia,
    getDatapointProdutoFamilia,
} from './services';
import { getCompetitorsPrices } from '../../../services/ProdutoService';
import { getDatapoints } from '../../../services/DatapointService';

const { Column, HeaderCell, Cell } = Table;

const PrecificacaoManualLoja = ({ history, location, match }) => {
    const { lojas, loja, simularMultiplasLojas } = useSelector(
        (state) => ({
            lojas: state.precificacaoManualDataReducer.lojas,
            loja: state.precificacaoManualDataReducer.loja,
            simularMultiplasLojas:
                state.precificacaoManualDataReducer.simularMultiplasLojas,
        }),
        shallowEqual
    );
    const [novaCompetitividade, setNovaCompetitividade] = useState(0);
    const [novaMargem, setNovaMargem] = useState(0);
    const [novoPreco, setNovoPreco] = useState(0);
    const [novoMixMargem, setNovoMixMargem] = useState(0);
    const [showSectionValidade, setShowSectionValidade] = useState(true);
    const [radioValue, setRadioValue] = useState('');
    const [competitors, setCompetitors] = useState(null);
    const [dataValidade, setDataValidade] = useState(addDays(new Date(), 1));
    const [dataLojas, setDataLojas] = useState({ limites: [] });
    const [errors, setErrors] = useState({});
    const [datapoint, setDatapoint] = useState({});
    const [margin, setMargin] = useState(0);
    const [avgPrice, setAvgPrice] = useState(null);
    const [applyFamilyPrice, setApplyFamilyPrice] = useState(false);
    const [produtoEmCampanha, setProdutoEmCampanha] = useState(false);
    const [showModalFamilia, setshowModalFamilia] = useState(false);
    const [dataFamilia, setDataFamilia] = useState({});
    const [tabelFamilia, setTabelFamilia] = useState({});
    const [modalConfirmacaoFamiliaData, setModalConfirmacaoFamiliaData] =
        useState({});
    const [showModalConfirmacaoFamilia, setShowModalConfirmacaoFamilia] =
        useState(false);
    const [produtoFamilia, setprodutoFamilia] = useState(null);
    const [saveItem, setSaveItem] = useState(false);

    const getStoreId = () => {
        const query = new URLSearchParams(location.search);
        const storeId = query.get('storeId');
        return storeId;
    };

    const getModal = () => {
        setshowModalFamilia(!showModalFamilia);
    };

    const getCabecalhoModal = async (productFamilyId) => {
        const storeId = getStoreId();
        const response = await getDatapointProdutoFamilia(
            productFamilyId,
            storeId
        );
        setDataFamilia(response);
    };

    const getTabelaModal = async (productFamilyId, page) => {
        const storeId = getStoreId();
        const response = await getGridModalFamilia(productFamilyId, storeId, page);
        setTabelFamilia(response);
    };

    const getShowModalFamilia = () => {
        getModal();
        getTabelaModal(produtoFamilia);
        getCabecalhoModal(produtoFamilia);
    };

    const getShowModalConfimacao = async (productFamilyId, page) => {
        const storeId = getStoreId();
        const response = await getModalConfimacao(
            productFamilyId,
            storeId,
            novoPreco,
            page,
        );
        setModalConfirmacaoFamiliaData(response);
    };

    const handleChangePage = (value) =>{
        const page = value - 1
        getShowModalConfimacao(produtoFamilia, page);
    }

    const handleChangePageGrid = (value) =>{
        const page = value - 1
        getTabelaModal(produtoFamilia, page);
    }

    const getInfoProdutoLoja = async (storeId) => {
        const response = await getDatapointProdutoLoja(
            match.params.identificador,
            storeId || loja?.storeId
        );
        if (response) {
            setProdutoEmCampanha(response?.isInCampaign);
            const datapoint = response;
            datapoint.storeId = storeId;
            datapoint.cpi =
                datapoint?.competitorsPrice && datapoint?.retailPrice
                    ? datapoint.retailPrice / datapoint.competitorsPrice
                    : 0;
            setMargin(
                datapoint?.retailPrice && datapoint?.pmz
                    ? (datapoint.retailPrice - datapoint.pmz) /
                          datapoint.retailPrice
                    : 0
            );
            setNovoPreco(datapoint?.price || 0);
            setNovaMargem(datapoint?.newMargin || 0);
            setprodutoFamilia(
                datapoint?.ruleActive?.productFamily ||
                    datapoint?.productFamilyId
            );
            setNovaCompetitividade(
                datapoint?.competitorsPrice && datapoint?.price
                    ? (datapoint.price / datapoint.competitorsPrice) * 100
                    : 0
            );
            setDatapoint(datapoint);
        }
    };

    const updateMixMargem = () => {
        const amount =
            datapoint?.salessetDatapointGross && datapoint?.retailPrice
                ? datapoint.salesGross / datapoint.retailPrice
                : 0;
        const margemAtual =
            datapoint?.retailPrice && datapoint?.pmz
                ? (datapoint.retailPrice - datapoint.pmz) /
                  datapoint.retailPrice
                : 0;
        const margemBrutaRestante =
            datapoint?.mixMarginCategory &&
            datapoint?.totalSalesGross &&
            datapoint?.salesGross
                ? datapoint.mixMarginCategory * datapoint.totalSalesGross -
                  margemAtual * datapoint.salesGross
                : 0;
        const novoSalesGross = novoPreco * amount;
        const novoMixMargem =
            novoPreco && datapoint?.totalSalesGross && datapoint?.salesGross
                ? (((novaMargem / 100) * novoSalesGross + margemBrutaRestante) /
                      (datapoint.totalSalesGross -
                          datapoint.salesGross +
                          novoSalesGross)) *
                  100
                : 0;
        setNovoMixMargem(novoMixMargem);
    };

    const getInfoCompetitors = async (storeId) => {
        const response = await getCompetitorsPrices(
            match.params.identificador,
            null,
            storeId
        );
        const avgPrice = response[0]?.avgPrice;
        setCompetitors(response);
        setAvgPrice(avgPrice);
    };

    const getLojas = async () => {
        const data = {
            produtos: [match.params.identificador],
            lojas,
        };

        const response = await getDatapoints({ loja: '' }, data);
        setDataLojas({
            itens: response.datapoints,
            limites: response.datapoints[0]?.limites,
            pmz: response.datapoints?.map((item) => item.pmz),
            cpiVigente: response.datapoints?.map((item) => item.cpiVigente),
            precoVigente: response.datapoints?.map((item) => item.precoVigente),
            precoMedio:
                response.datapoints?.length > 0
                    ? response.datapoints.reduce(
                          (total, item) => total + item.precoVigente,
                          0
                      ) / response.datapoints.length
                    : 0,
            margemMedia:
                response.datapoints?.length > 0
                    ? response.datapoints.reduce(
                          (total, item) => total + item.margemVigente,
                          0
                      ) / response.datapoints.length
                    : 0,
            cpiMedio:
                response.datapoints?.length > 0
                    ? response.datapoints.reduce(
                          (total, item) => total + item.cpiVigente,
                          0
                      ) / response.datapoints.length
                    : 0,
            mixMargemMedio: 0,
        });
    };

    const validValues = () => {
        const errors = {};
        let formIsValid = true;

        if (novaMargem && novaMargem > 100) {
            formIsValid = false;
            errors.novaMargem = 'A margem não pode ser maior que 100';
            const novaMargem = document.getElementsByName('novaMargem')[0];
            if (novaMargem) {
                novaMargem.classList.add('info-preco__input_invalid');
            }
        } else {
            const novaMargem = document.getElementsByName('novaMargem')[0];
            if (novaMargem) {
                novaMargem.classList.remove('info-preco__input_invalid');
            }
        }

        if (
            novaCompetitividade === 0 &&
            datapoint?.ruleActive?.mechanism?.mechanismType ===
                'COMPETITIVIDADE'
        ) {
            formIsValid = false;
            errors.novaCompetitividade =
                'A competitividade deve ser maior que 0';
            const novaCompetitividade = document.getElementsByName(
                'novaCompetitividade'
            )[0];
            if (novaCompetitividade) {
                novaCompetitividade.classList.add('info-preco__input_invalid');
            }
        } else {
            const novaCompetitividade = document.getElementsByName(
                'novaCompetitividade'
            )[0];
            if (novaCompetitividade) {
                novaCompetitividade.classList.remove(
                    'info-preco__input_invalid'
                );
            }
        }

        setErrors(errors);
        return formIsValid;
    };

    const handleNovoPrecoMultiplo = (e, value) => {
        if (value || parseFloat(value.replace(',', '.')) === 0) {
            const preco = parseFloat(value.replace('.', '').replace(',', '.'));

            const vendaTotal = dataLojas?.itens.reduce(
                (total, item) => total + item.venda,
                0
            );

            const margemMedia =
                dataLojas?.itens?.length > 0
                    ? dataLojas.itens.reduce(
                          (total, item) =>
                              total + ((preco - item.pmz) / preco) * item.venda,
                          0
                      ) / vendaTotal
                    : 0;

            const cpiMedio =
                dataLojas?.itens?.length > 0
                    ? dataLojas.itens.reduce((total, item) => {
                          const precoConcorrente =
                              item.precoVigente / item.cpiVigente;
                          return (
                              total + (preco / precoConcorrente) * item.venda
                          );
                      }, 0) / vendaTotal
                    : 0;

            setNovoPreco(preco);
            setNovaMargem(margemMedia * 100);
            setNovaCompetitividade(cpiMedio * 100);
        } else {
            setNovoPreco(null);
            setNovaMargem(null);
            setNovaCompetitividade(null);
        }
    };

    const handleNovaCompetitividadeMultiplo = (e, value) => {
        if (value || parseFloat(value.replace(',', '.')) === 0) {
            const competitividade = parseFloat(
                value.replace('.', '').replace(',', '.')
            );

            const vendaTotal = dataLojas?.itens?.reduce(
                (total, item) => total + item.venda,
                0
            );

            const precoMedio =
                dataLojas?.itens?.length > 0
                    ? dataLojas.itens.reduce((total, item) => {
                          const precoConcorrente =
                              item.precoVigente / item.cpiVigente;
                          return (
                              total +
                              precoConcorrente * competitividade * item.venda
                          );
                      }, 0) /
                      vendaTotal /
                      100
                    : 0;

            const margemMedia =
                dataLojas?.itens?.length > 0
                    ? dataLojas.itens.reduce(
                          (total, item) =>
                              total +
                              ((precoMedio - item.pmz) / precoMedio) *
                                  item.venda,
                          0
                      ) / vendaTotal
                    : 0;

            setNovoPreco(precoMedio);
            setNovaMargem(margemMedia * 100);
            setNovaCompetitividade(competitividade);
        } else {
            setNovoPreco(0);
            setNovaMargem(0);
            setNovaCompetitividade(0);
        }
    };

    const handleNovaMargemMultiplo = (e, value) => {
        if (value || value === 0) {
            const margem = value;

            const vendaTotal = dataLojas?.itens?.reduce(
                (total, item) => total + item.venda,
                0
            );

            const precoMedio =
                dataLojas?.itens?.length > 0
                    ? dataLojas.itens.reduce(
                          (total, item) =>
                              total +
                              (item.pmz / (1 - margem / 100)) * item.venda,
                          0
                      ) / vendaTotal
                    : 0;

            const cpiMedio =
                dataLojas?.itens?.length > 0
                    ? dataLojas.itens.reduce((total, item) => {
                          const precoConcorrente =
                              item.precoVigente / item.cpiVigente;
                          return (
                              total +
                              (precoMedio / precoConcorrente) * item.venda
                          );
                      }, 0) / vendaTotal
                    : 0;

            setNovoPreco(precoMedio);
            setNovaMargem(margem);
            setNovaCompetitividade(cpiMedio * 100);
        } else {
            setNovoPreco(0);
            setNovaMargem(0);
            setNovaCompetitividade(0);
        }
    };

    const handleUpdateCampo = (campo) => (e, value) => {
        const item = datapoint;

        if (value !== 0) {
            if (campo === 'novoPreco') {
                const newValue = parseFloat(
                    value.replace('.', '').replace(',', '.')
                );
                setNovoPreco(newValue);
                setNovaMargem(
                    ((newValue -
                        newValue *
                            ((item.salesTaxPercentage + item.expensesPercent) /
                                100) -
                        item.cost) /
                        newValue) *
                        100
                );
                setNovaCompetitividade(
                    (newValue / item.competitorsPrice) * 100
                );
            }

            if (campo === 'novaMargem') {
                const price =
                    item.cost > item.lastUnitNetCost
                        ? item.cost
                        : item.lastUnitNetCost;
                const newPrice =
                    (price /
                        (100 -
                            value -
                            (item.expensesPercent + item.salesTaxPercentage))) *
                    100;

                setNovoPreco(newPrice < 0 ? null : newPrice);
                setNovaMargem(value);
                setNovaCompetitividade(
                    (newPrice / datapoint.competitorsPrice) * 100
                );
            }

            if (campo === 'novaCompetitividade') {
                const newPrice = (value / 100) * item.competitorsPrice;
                setNovaCompetitividade(value);
                setNovoPreco(newPrice);
                setNovaMargem(((newPrice - item.pmz) / newPrice) * 100);
            }
        } else {
            setNovoPreco(0);
            setNovaMargem(0);
            setNovaCompetitividade(0);
        }
    };

    const back = () => {
        history.push(`/ipa/precificacao-produto/${match.params.identificador}`);
    };

    const save = async () => {
        const data = {
            clientId: localStorage.getItem('autenticacao.clientId'),
            name: `${datapoint?.storeId}-${match.params.identificador}-MANUAL`,
            productId: match.params.identificador,
            productFamily: applyFamilyPrice,
            productFamilyId: datapoint?.productFamilyId,
            storeId: datapoint?.storeId,
            type: 'manualPrice',
            recurrence: {
                startingDate: format(new Date(), 'yyyy-MM-dd'),
                recurrenceType: 'DAILY',
                expirationDate: dataValidade
                    ? format(new Date(dataValidade), 'yyyy-MM-dd')
                    : null,
            },
            price: novoPreco,
            author: localStorage.getItem('autenticacao.usuarioLogin'),
        };

        const response = await savePrecificacao(data);
        if (response.status >= 200 && response.status <= 300) {
            setNovoPreco(0);
            setNovaMargem(0);
            setNovaCompetitividade(0);
            Alert.success('Precificação salva com sucesso!');
            back();
        }
    };

    const getValidacaoSave = () => {
        if (applyFamilyPrice) {
            setShowModalConfirmacaoFamilia(true);
            getShowModalConfimacao(produtoFamilia, 0);
        } else {
            save();
        }
    };

    const getSaveProdutoUnicoModal = () => {
        setApplyFamilyPrice(false);
        setSaveItem(true);
    };

    const getSaveProdutoFamiliaModal = () => {
        setApplyFamilyPrice(true);
        setSaveItem(true);
    };

    useEffect(() => {
        const storeId = getStoreId();
        if (!storeId) history.goBack();
        getInfoProdutoLoja(storeId);
        getInfoCompetitors(storeId);
        if (lojas.length) {
            getLojas();
        }
    }, []);

    useEffect(() => {
        if (saveItem) {
            save();
        }
    }, [applyFamilyPrice, saveItem]);

    useEffect(() => {
        if (!showModalConfirmacaoFamilia) setSaveItem(false);
    }, [showModalConfirmacaoFamilia]);

    useEffect(() => {
        updateMixMargem();
    }, [margin, novoPreco, novaMargem, novaCompetitividade, datapoint]);

    return (
        <Container className="main-container precificacao-manual-loja-page">
            <LoadingSpiner size="md" />
            <Content className="container content" style={{ flex: 'none' }}>
                <FlexboxGrid align="middle">
                    <FlexboxGrid.Item
                        componentClass={Col}
                        lg={24}
                        md={24}
                        sm={24}
                        xs={24}
                    >
                        {!simularMultiplasLojas && (
                            <p style={{ fontSize: '18px' }}>
                                Precificar{' '}
                                <span style={{ fontWeight: '600' }}>
                                    {datapoint?.description}
                                </span>{' '}
                                na{' '}
                                <span style={{ fontWeight: '600' }}>
                                    {datapoint?.storeName}
                                </span>
                            </p>
                        )}
                    </FlexboxGrid.Item>
                    {produtoEmCampanha ? (
                        <FlexboxGrid.Item
                            componentClass={Col}
                            lg={24}
                            md={24}
                            sm={24}
                            xs={24}
                        >
                            <div className="produto-em-campanhas-info">
                                <LocalOfferIcon />
                                <span>
                                    Esse produto está com uma Campanha
                                    Promocional ativa. Por isso você não pode
                                    alterar o preço.
                                </span>
                            </div>
                        </FlexboxGrid.Item>
                    ) : null}
                </FlexboxGrid>

                {!simularMultiplasLojas ? (
                    <FlexboxGrid className="info-loja">
                        <FlexboxGrid.Item
                            componentClass={Col}
                            lg={3}
                            md={3}
                            sm={24}
                            xs={24}
                        >
                            <FormGroup>
                                <ControlLabel className="info-loja__label">
                                    TIPO DE LOJA
                                </ControlLabel>
                                <p>{datapoint?.storeType}</p>
                            </FormGroup>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item
                            componentClass={Col}
                            lg={3}
                            md={3}
                            sm={24}
                            xs={24}
                        >
                            <FormGroup>
                                <ControlLabel className="info-loja__label">
                                    PMZ - PREÇO MARGEM ZERO
                                </ControlLabel>
                                <p>
                                    <CurrencyFormat
                                        fixedDecimalScale
                                        decimalScale={2}
                                        value={datapoint?.pmz || 0}
                                        displayType="text"
                                        prefix="R$"
                                        decimalSeparator=","
                                        thousandSeparator="."
                                    />
                                </p>
                            </FormGroup>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item
                            componentClass={Col}
                            lg={3}
                            md={3}
                            sm={24}
                            xs={24}
                        >
                            <FormGroup>
                                <ControlLabel className="info-loja__label">
                                    SENSIBILIDADE
                                </ControlLabel>
                                <p>{datapoint?.sensibilityType || null}</p>
                            </FormGroup>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item
                            componentClass={Col}
                            lg={3}
                            md={3}
                            sm={24}
                            xs={24}
                        >
                            <FormGroup>
                                <ControlLabel className="info-loja__label">
                                    CURVA
                                </ControlLabel>
                                <p>{datapoint?.abcClass || null}</p>
                            </FormGroup>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item
                            componentClass={Col}
                            lg={3}
                            md={4}
                            sm={24}
                            xs={24}
                        >
                            <FormGroup>
                                <ControlLabel className="info-loja__label">
                                    FAMILÍA DE PRODUTOS
                                </ControlLabel>
                                <p>
                                    {datapoint.productFamilyId ? (
                                        <Button
                                            style={{ padding: '0px 0px 20px' }}
                                            onClick={getShowModalFamilia}
                                            appearance="link"
                                        >
                                            {datapoint.productFamilyId}
                                        </Button>
                                    ) : (
                                        '--'
                                    )}
                                </p>
                            </FormGroup>
                        </FlexboxGrid.Item>
                        <ModalFamiliaPrecificacaoLoja
                            loja={datapoint}
                            cabecalho={dataFamilia}
                            tabela={tabelFamilia}
                            isOpen={showModalFamilia}
                            onClose={() => setshowModalFamilia(false)}
                            onChangePage={(value) => handleChangePageGrid(value)}
                        />
                    </FlexboxGrid>
                ) : null}
                <FlexboxGrid className="instruction">
                    <FlexboxGrid.Item
                        componentClass={Col}
                        lg={12}
                        md={12}
                        sm={24}
                        xs={24}
                    >
                        {!simularMultiplasLojas ? (
                            <p className="instruction__title">
                                Defina o novo preço do produto
                            </p>
                        ) : null}
                        {simularMultiplasLojas ? (
                            <p className="instruction__title">
                                Defina o novo preço do produto em diversas lojas
                                por:
                            </p>
                        ) : null}
                        {simularMultiplasLojas ? (
                            <RadioGroup
                                onChange={(value) => setRadioValue(value)}
                                className="instruction__options"
                                name="options"
                                inline
                                disabled={produtoEmCampanha}
                            >
                                <Radio value="preco">Preço</Radio>
                                <Radio value="competitividade">
                                    Competitividade
                                </Radio>
                                <Radio value="margem">Margem</Radio>
                            </RadioGroup>
                        ) : null}
                        <p className="instruction__subtitle">
                            Alterar o{' '}
                            <span className="instruction__subtitle_bold">
                                Preço
                            </span>
                            ,{' '}
                            <span className="instruction__subtitle_bold">
                                Competitividade
                            </span>{' '}
                            ou{' '}
                            <span className="instruction__subtitle_bold">
                                Margem
                            </span>{' '}
                            de um produto alterará os outros dois fatores de
                            forma correspondente.
                        </p>
                    </FlexboxGrid.Item>
                </FlexboxGrid>
                {!simularMultiplasLojas ||
                (simularMultiplasLojas && radioValue) ? (
                    <FlexboxGrid>
                        <FlexboxGrid.Item
                            componentClass={Col}
                            lg={16}
                            md={16}
                            sm={24}
                            xs={24}
                        >
                            <FlexboxGrid
                                className="info-preco"
                                style={{ marginBottom: '25px' }}
                                align="bottom"
                            >
                                <FlexboxGrid.Item
                                    componentClass={Col}
                                    lg={4}
                                    md={4}
                                    sm={24}
                                    xs={24}
                                >
                                    <p className="info-preco__tipo">VIGENTE</p>
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item
                                    componentClass={Col}
                                    lg={5}
                                    md={5}
                                    sm={24}
                                    xs={24}
                                >
                                    <FormGroup>
                                        <div className="info-preco__group-label">
                                            <ControlLabel className="info-preco__label">
                                                PREÇO
                                            </ControlLabel>
                                            {simularMultiplasLojas &&
                                            radioValue !== 'preco' ? (
                                                <HelpBlock className="info-preco__sub-label">
                                                    Média
                                                </HelpBlock>
                                            ) : null}
                                        </div>
                                        <p>
                                            <CurrencyFormat
                                                fixedDecimalScale
                                                decimalScale={2}
                                                value={
                                                    datapoint?.retailPrice ||
                                                    dataLojas.precoMedio
                                                }
                                                displayType="text"
                                                prefix="R$"
                                                decimalSeparator=","
                                                thousandSeparator="."
                                            />
                                        </p>
                                    </FormGroup>
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item
                                    componentClass={Col}
                                    lg={5}
                                    md={5}
                                    sm={24}
                                    xs={24}
                                >
                                    <FormGroup>
                                        <div className="info-preco__group-label">
                                            <ControlLabel className="info-preco__label">
                                                COMPETITIVIDADE
                                            </ControlLabel>
                                            {simularMultiplasLojas &&
                                            radioValue !== 'competitividade' ? (
                                                <HelpBlock className="info-preco__sub-label">
                                                    Média
                                                </HelpBlock>
                                            ) : null}
                                        </div>
                                        {datapoint?.cpi ? (
                                            <p>
                                                <CurrencyFormat
                                                    fixedDecimalScale
                                                    decimalScale={2}
                                                    value={
                                                        datapoint.cpi * 100 ||
                                                        dataLojas.cpiMedio * 100
                                                    }
                                                    displayType="text"
                                                    suffix="%"
                                                    decimalSeparator=","
                                                    thousandSeparator="."
                                                />
                                            </p>
                                        ) : (
                                            '-'
                                        )}
                                    </FormGroup>
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item
                                    componentClass={Col}
                                    lg={5}
                                    md={5}
                                    sm={24}
                                    xs={24}
                                >
                                    <FormGroup>
                                        <div className="info-preco__group-label">
                                            <ControlLabel className="info-preco__label">
                                                MARGEM
                                            </ControlLabel>
                                            {simularMultiplasLojas &&
                                            radioValue !== 'margem' ? (
                                                <HelpBlock className="info-preco__sub-label">
                                                    Média
                                                </HelpBlock>
                                            ) : null}
                                        </div>
                                        {datapoint?.margin ||
                                        margin ||
                                        dataLojas?.margemMedia ? (
                                            <p>
                                                <CurrencyFormat
                                                    fixedDecimalScale
                                                    decimalScale={2}
                                                    value={datapoint.newMargin}
                                                    displayType="text"
                                                    suffix="%"
                                                    decimalSeparator=","
                                                    thousandSeparator="."
                                                />
                                            </p>
                                        ) : (
                                            '-'
                                        )}
                                    </FormGroup>
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item
                                    componentClass={Col}
                                    lg={5}
                                    md={5}
                                    sm={24}
                                    xs={24}
                                >
                                    <FormGroup>
                                        <div className="info-preco__group-label">
                                            <ControlLabel className="info-preco__label">
                                                MIX DE MARGEM
                                            </ControlLabel>
                                            <HelpBlock className="info-preco__sub-label">
                                                Média
                                            </HelpBlock>
                                        </div>
                                        {datapoint?.mixMarginCategory ? (
                                            <p>
                                                <CurrencyFormat
                                                    value={
                                                        datapoint.mixMarginCategory *
                                                            100 ||
                                                        dataLojas.mixMargemMedio *
                                                            100
                                                    }
                                                    decimalScale={2}
                                                    displayType="text"
                                                    suffix="%"
                                                    decimalSeparator=","
                                                    thousandSeparator="."
                                                />
                                            </p>
                                        ) : (
                                            '-'
                                        )}
                                    </FormGroup>
                                </FlexboxGrid.Item>
                            </FlexboxGrid>
                            <FlexboxGrid>
                                <FlexboxGrid.Item
                                    componentClass={Col}
                                    lg={4}
                                    md={4}
                                    sm={24}
                                    xs={24}
                                >
                                    <p className="info-preco__tipo">NOVO</p>
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item
                                    componentClass={Col}
                                    lg={5}
                                    md={5}
                                    sm={24}
                                    xs={24}
                                >
                                    <FormGroup>
                                        {!simularMultiplasLojas ||
                                        (simularMultiplasLojas &&
                                            radioValue) === 'preco' ? (
                                            <InputGroup className="info-preco__input-group">
                                                <InputGroup.Addon>
                                                    R$
                                                </InputGroup.Addon>
                                                <CurrencyInput
                                                    name="novoPreco"
                                                    className="rs-input"
                                                    decimalSeparator=","
                                                    thousandSeparator="."
                                                    precision="2"
                                                    allowEmpty
                                                    allowNegative={false}
                                                    onChangeEvent={
                                                        simularMultiplasLojas
                                                            ? handleNovoPrecoMultiplo
                                                            : handleUpdateCampo(
                                                                  'novoPreco'
                                                              )
                                                    }
                                                    onBlur={validValues}
                                                    value={novoPreco}
                                                    disabled={produtoEmCampanha}
                                                />
                                            </InputGroup>
                                        ) : (
                                            <p>
                                                <CurrencyFormat
                                                    fixedDecimalScale
                                                    decimalScale={2}
                                                    value={novoPreco || 0}
                                                    displayType="text"
                                                    prefix="R$"
                                                    decimalSeparator=","
                                                    thousandSeparator="."
                                                />
                                            </p>
                                        )}
                                        <HelpBlock
                                            className="info-preco__message_invalid"
                                            name="erroPreco"
                                        >
                                            {errors.novoPreco}
                                        </HelpBlock>
                                    </FormGroup>
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item
                                    componentClass={Col}
                                    lg={5}
                                    md={5}
                                    sm={24}
                                    xs={24}
                                >
                                    <FormGroup>
                                        {!simularMultiplasLojas ||
                                        (simularMultiplasLojas &&
                                            radioValue) ===
                                            'competitividade' ? (
                                            <InputGroup className="info-preco__input-group">
                                                <CurrencyInput
                                                    disabled={
                                                        !(
                                                            datapoint?.competitorsPrice ||
                                                            avgPrice
                                                        ) || produtoEmCampanha
                                                    }
                                                    name="novaCompetitividade"
                                                    className="rs-input"
                                                    decimalSeparator=","
                                                    thousandSeparator="."
                                                    precision="2"
                                                    allowEmpty
                                                    allowNegative={false}
                                                    onChangeEvent={
                                                        simularMultiplasLojas
                                                            ? handleNovaCompetitividadeMultiplo
                                                            : handleUpdateCampo(
                                                                  'novaCompetitividade'
                                                              )
                                                    }
                                                    onBlur={validValues}
                                                    value={novaCompetitividade}
                                                />
                                                <InputGroup.Addon>
                                                    %
                                                </InputGroup.Addon>
                                            </InputGroup>
                                        ) : (
                                            <p>
                                                <CurrencyFormat
                                                    fixedDecimalScale
                                                    decimalScale={2}
                                                    value={
                                                        novaCompetitividade || 0
                                                    }
                                                    displayType="text"
                                                    suffix="%"
                                                    decimalSeparator=","
                                                    thousandSeparator="."
                                                />
                                            </p>
                                        )}
                                        <HelpBlock
                                            className="info-preco__message_invalid"
                                            name="erroCompetitividade"
                                        >
                                            {errors.novaCompetitividade}
                                        </HelpBlock>
                                    </FormGroup>
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item
                                    componentClass={Col}
                                    lg={5}
                                    md={5}
                                    sm={24}
                                    xs={24}
                                >
                                    <FormGroup>
                                        {!simularMultiplasLojas ||
                                        (simularMultiplasLojas &&
                                            radioValue) === 'margem' ? (
                                            <InputGroup className="info-preco__input-group">
                                                <CurrencyInput
                                                    name="novaMargem"
                                                    className="rs-input"
                                                    decimalSeparator=","
                                                    thousandSeparator="."
                                                    precision="2"
                                                    allowEmpty
                                                    allowNegative
                                                    onChange={
                                                        simularMultiplasLojas
                                                            ? handleNovaMargemMultiplo
                                                            : handleUpdateCampo(
                                                                  'novaMargem'
                                                              )
                                                    }
                                                    onBlur={validValues}
                                                    value={novaMargem}
                                                    disabled={
                                                        produtoEmCampanha ||
                                                        !datapoint?.newMargin
                                                    }
                                                />
                                                <InputGroup.Addon>
                                                    %
                                                </InputGroup.Addon>
                                            </InputGroup>
                                        ) : (
                                            <p>
                                                <CurrencyFormat
                                                    fixedDecimalScale
                                                    decimalScale={2}
                                                    value={novaMargem || 0}
                                                    displayType="text"
                                                    suffix="%"
                                                    decimalSeparator=","
                                                    thousandSeparator="."
                                                />
                                            </p>
                                        )}
                                        <HelpBlock
                                            className="info-preco__message_invalid"
                                            name="erroMargem"
                                        >
                                            {errors.novaMargem}
                                        </HelpBlock>
                                    </FormGroup>
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item
                                    componentClass={Col}
                                    lg={5}
                                    md={5}
                                    sm={24}
                                    xs={24}
                                >
                                    <FormGroup>
                                        {novoMixMargem ? (
                                            <p>
                                                <CurrencyFormat
                                                    value={novoMixMargem || 0}
                                                    displayType="text"
                                                    decimalScale={2}
                                                    suffix="%"
                                                    decimalSeparator=","
                                                    thousandSeparator="."
                                                />
                                            </p>
                                        ) : (
                                            '-'
                                        )}
                                    </FormGroup>
                                </FlexboxGrid.Item>
                            </FlexboxGrid>
                        </FlexboxGrid.Item>
                        {radioValue !== '' ? (
                            <FlexboxGrid.Item
                                className="instruction__subtitle"
                                componentClass={Col}
                                lg={5}
                                md={5}
                                sm={24}
                                xs={24}
                            >
                                <p>
                                    Para preficicação em diversas lojas, os
                                    fatores
                                    {radioValue !== 'preco' ? (
                                        <span className="instruction__subtitle_bold">
                                            {' '}
                                            Preço{' '}
                                        </span>
                                    ) : null}
                                    {radioValue !== 'preco' ? (
                                        <span>e</span>
                                    ) : null}
                                    {radioValue !== 'competitividade' ? (
                                        <span className="instruction__subtitle_bold">
                                            {' '}
                                            Competitividade{' '}
                                        </span>
                                    ) : null}
                                    {radioValue !== 'competitividade' &&
                                    radioValue === 'preco' ? (
                                        <span>e</span>
                                    ) : null}
                                    {radioValue !== 'margem' ? (
                                        <span className="instruction__subtitle_bold">
                                            {' '}
                                            Margem{' '}
                                        </span>
                                    ) : null}
                                    são calculados como médias{' '}
                                    {radioValue === 'preco' ? (
                                        <span>
                                            {' '}
                                            em relação aos concorrentes
                                            cadastrados{' '}
                                        </span>
                                    ) : null}{' '}
                                    e não representam os valores absolutos a
                                    serem aplicados.
                                </p>
                            </FlexboxGrid.Item>
                        ) : null}
                    </FlexboxGrid>
                ) : null}

                {!simularMultiplasLojas ||
                (simularMultiplasLojas && radioValue) ? (
                    <FlexboxGrid align="middle" className="date">
                        <FlexboxGrid.Item
                            componentClass={Col}
                            lg={7}
                            md={7}
                            sm={24}
                            xs={24}
                        >
                            <Toggle
                                className="date__toggle"
                                defaultChecked
                                onChange={(checked) =>
                                    setShowSectionValidade(checked)
                                }
                                disabled={produtoEmCampanha}
                            />
                            <span>
                                Definir data de validade do preço definido
                            </span>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item>
                            <Toggle
                                className="date__toggle-familia"
                                checked={applyFamilyPrice}
                                onChange={(value) => setApplyFamilyPrice(value)}
                            />
                            <span>Aplicar o preço na família de produtos</span>{' '}
                            <Whisper
                                placement="top"
                                trigger="hover"
                                speaker={
                                    <Tooltip>
                                        <p>
                                            O preço será aplicado em todos os
                                            produtos que fazem parte de uma
                                            mesma família.
                                        </p>
                                    </Tooltip>
                                }
                            >
                                <Icon className="tooltip__icon" icon="info" />
                            </Whisper>
                        </FlexboxGrid.Item>

                        {showSectionValidade ? (
                            <FlexboxGrid.Item
                                componentClass={Col}
                                lg={24}
                                md={24}
                                sm={24}
                                xs={24}
                            >
                                <DatePicker
                                    oneTap
                                    value={dataValidade}
                                    format="DD/MM/YYYY"
                                    placeholder="DD/MM/AAAA"
                                    onChange={(value) => setDataValidade(value)}
                                    disabledDate={(date) =>
                                        isBefore(date, new Date())
                                    }
                                    disabled={produtoEmCampanha}
                                    locale={{
                                        sunday: 'Dom',
                                        monday: 'Seg',
                                        tuesday: 'Ter',
                                        wednesday: 'Qua',
                                        thursday: 'Qui',
                                        friday: 'Sex',
                                        saturday: 'Sáb',
                                        ok: 'OK',
                                        today: 'Hoje',
                                    }}
                                    ranges={[
                                        {
                                            label: '15 dias',
                                            value: toDate(
                                                addDays(new Date(), 15)
                                            ),
                                        },
                                        {
                                            label: '30 dias',
                                            value: toDate(
                                                addDays(new Date(), 30)
                                            ),
                                        },
                                    ]}
                                />
                            </FlexboxGrid.Item>
                        ) : (
                            ''
                        )}
                    </FlexboxGrid>
                ) : null}
            </Content>
            <Content>
                <FlexboxGrid className="info-adicionais">
                    <FlexboxGrid.Item
                        componentClass={Col}
                        lg={24}
                        md={24}
                        sm={24}
                        xs={24}
                    >
                        <p className="info-adicionais__title">
                            Informações adicionais
                        </p>
                    </FlexboxGrid.Item>
                </FlexboxGrid>
                <FlexboxGrid>
                    <FlexboxGrid.Item
                        componentClass={Col}
                        lg={24}
                        md={24}
                        sm={24}
                        xs={24}
                    >
                        {!simularMultiplasLojas ? (
                            <PanelGroup accordion bordered>
                                <Panel
                                    className="panel"
                                    header={
                                        <Badge
                                            content={competitors?.length || 0}
                                        >
                                            <p>Concorrentes - Preço médio</p>
                                        </Badge>
                                    }
                                >
                                    <Table data={competitors || []}>
                                        <Column width={200}>
                                            <HeaderCell>Concorrente</HeaderCell>
                                            <Cell dataKey="competitorStore">
                                                {(rowData) => (
                                                    <span
                                                        title={
                                                            rowData.competitorStore
                                                        }
                                                    >
                                                        {
                                                            rowData.competitorStore
                                                        }
                                                    </span>
                                                )}
                                            </Cell>
                                        </Column>
                                        <Column width={100}>
                                            <HeaderCell>Preço</HeaderCell>
                                            <Cell>
                                                {(rowData) =>
                                                    rowData.retailPrice ||
                                                    rowData.avgPrice ? (
                                                        <CurrencyFormat
                                                            fixedDecimalScale
                                                            decimalScale={2}
                                                            value={
                                                                rowData.retailPrice ||
                                                                rowData.avgPrice
                                                            }
                                                            displayType="text"
                                                            prefix="R$"
                                                            decimalSeparator=","
                                                            thousandSeparator="."
                                                        />
                                                    ) : (
                                                        ''
                                                    )
                                                }
                                            </Cell>
                                        </Column>
                                        <Column width={200}>
                                            <HeaderCell>
                                                Tipo do Preço
                                            </HeaderCell>
                                            <Cell>Regular</Cell>
                                        </Column>
                                        <Column width={200}>
                                            <HeaderCell>
                                                Tipo concorrente
                                            </HeaderCell>
                                            <Cell dataKey="competitorStoreType" />
                                        </Column>
                                        <Column width={130}>
                                            <HeaderCell>
                                                Competitividade
                                            </HeaderCell>
                                            <Cell>
                                                {(rowData) =>
                                                    rowData.avgPrice ? (
                                                        <CurrencyFormat
                                                            fixedDecimalScale
                                                            decimalScale={2}
                                                            value={
                                                                ((datapoint?.retailPrice ||
                                                                    dataLojas.precoMedio) /
                                                                    rowData.retailPrice) *
                                                                100
                                                            }
                                                            displayType="text"
                                                            suffix="%"
                                                            decimalSeparator=","
                                                            thousandSeparator="."
                                                        />
                                                    ) : (
                                                        ''
                                                    )
                                                }
                                            </Cell>
                                        </Column>
                                        <Column width={150}>
                                            <HeaderCell>
                                                Data da pesquisa
                                            </HeaderCell>
                                            <Cell>
                                                {(rowData) =>
                                                    rowData.date
                                                        ? format(
                                                              parseISO(
                                                                  rowData.date
                                                              ),
                                                              'dd/MM/yyyy'
                                                          )
                                                        : ''
                                                }
                                            </Cell>
                                        </Column>
                                    </Table>
                                </Panel>
                                <Panel
                                    className="panel"
                                    header={
                                        <Badge
                                            content={
                                                datapoint?.limits
                                                    ? datapoint?.limits.length
                                                    : 0
                                            }
                                        >
                                            <p>Limites associados</p>
                                        </Badge>
                                    }
                                >
                                    <Table
                                        data={
                                            datapoint?.limits
                                                ? datapoint?.limits
                                                : []
                                        }
                                        className="table-limite"
                                    >
                                        <Column width={300}>
                                            <HeaderCell>
                                                Nome do limite
                                            </HeaderCell>
                                            <Cell dataKey="name" />
                                        </Column>
                                        <Column width={300}>
                                            <HeaderCell>Margem</HeaderCell>
                                            <Cell>
                                                {(rowData) => {
                                                    const maxMargem =
                                                        rowData.maxMargin
                                                            ? rowData.maxMargin *
                                                              100
                                                            : '';
                                                    const minMargem =
                                                        rowData.minMargin
                                                            ? rowData.minMargin *
                                                              100
                                                            : '';
                                                    return (
                                                        <p>
                                                            {!minMargem &&
                                                            !maxMargem
                                                                ? '--'
                                                                : null}
                                                            {minMargem ? (
                                                                <span>
                                                                    Mín:
                                                                    <CurrencyFormat
                                                                        fixedDecimalScale
                                                                        decimalScale={
                                                                            2
                                                                        }
                                                                        value={
                                                                            minMargem
                                                                        }
                                                                        displayType="text"
                                                                        suffix="%"
                                                                        decimalSeparator=","
                                                                        thousandSeparator="."
                                                                    />
                                                                </span>
                                                            ) : (
                                                                ''
                                                            )}
                                                            {minMargem &&
                                                            maxMargem ? (
                                                                <span> | </span>
                                                            ) : (
                                                                ''
                                                            )}
                                                            {maxMargem ? (
                                                                <span>
                                                                    Max:
                                                                    <CurrencyFormat
                                                                        fixedDecimalScale
                                                                        decimalScale={
                                                                            2
                                                                        }
                                                                        value={
                                                                            maxMargem
                                                                        }
                                                                        displayType="text"
                                                                        suffix="%"
                                                                        decimalSeparator=","
                                                                        thousandSeparator="."
                                                                    />
                                                                </span>
                                                            ) : (
                                                                ''
                                                            )}
                                                        </p>
                                                    );
                                                }}
                                            </Cell>
                                        </Column>
                                        <Column width={300}>
                                            <HeaderCell>
                                                Competitividade
                                            </HeaderCell>
                                            <Cell>
                                                {(rowData) => {
                                                    const maxCpi =
                                                        rowData.maxCpi
                                                            ? rowData.maxCpi *
                                                              100
                                                            : '';
                                                    const minCpi =
                                                        rowData.minCpi
                                                            ? rowData.minCpi *
                                                              100
                                                            : '';
                                                    return (
                                                        <p>
                                                            {!minCpi && !maxCpi
                                                                ? '--'
                                                                : null}
                                                            {minCpi ? (
                                                                <span>
                                                                    Mín:
                                                                    <CurrencyFormat
                                                                        fixedDecimalScale
                                                                        decimalScale={
                                                                            2
                                                                        }
                                                                        value={
                                                                            minCpi
                                                                        }
                                                                        displayType="text"
                                                                        suffix="%"
                                                                        decimalSeparator=","
                                                                        thousandSeparator="."
                                                                    />
                                                                </span>
                                                            ) : (
                                                                ''
                                                            )}
                                                            {minCpi &&
                                                            maxCpi ? (
                                                                <span> | </span>
                                                            ) : (
                                                                ''
                                                            )}
                                                            {maxCpi ? (
                                                                <span>
                                                                    Max:
                                                                    <CurrencyFormat
                                                                        fixedDecimalScale
                                                                        decimalScale={
                                                                            2
                                                                        }
                                                                        value={
                                                                            maxCpi
                                                                        }
                                                                        displayType="text"
                                                                        suffix="%"
                                                                        decimalSeparator=","
                                                                        thousandSeparator="."
                                                                    />
                                                                </span>
                                                            ) : (
                                                                ''
                                                            )}
                                                        </p>
                                                    );
                                                }}
                                            </Cell>
                                        </Column>
                                        <Column width={200}>
                                            <HeaderCell>Responsável</HeaderCell>
                                            <Cell dataKey="author" />
                                        </Column>
                                    </Table>
                                </Panel>
                                {/* <Panel
                                    className="panel"
                                    header={(
                                        <Badge content={0}>
                                            <p>Campanhas ativas</p>
                                        </Badge>
                                      )}
                                >
                                    <Table
                                        data={
                                            datapoint?.campanhas
                                                ? datapoint?.campanhas
                                                : []
                                        }
                                    >
                                        <Column>
                                            <HeaderCell>
                                                Nome da campanha
                                            </HeaderCell>
                                            <Cell dataKey="nome" />
                                        </Column>
                                        <Column>
                                            <HeaderCell>Tipo</HeaderCell>
                                            <Cell dataKey="tipoCampanha" />
                                        </Column>
                                        <Column>
                                            <HeaderCell>Vigência</HeaderCell>
                                            <Cell dataKey="vigencia" />
                                        </Column>
                                        <Column>
                                            <HeaderCell>Status</HeaderCell>
                                            <Cell dataKey="status" />
                                        </Column>
                                        <Column>
                                            <HeaderCell>Responsável</HeaderCell>
                                            <Cell dataKey="responsavel" />
                                        </Column>
                                    </Table>
                                </Panel> */}
                                <Panel
                                    className="panel"
                                    header={
                                        <Badge
                                            content={
                                                datapoint?.rules?.length || 0
                                            }
                                        >
                                            <p>Regras de preço</p>
                                        </Badge>
                                    }
                                >
                                    <Table data={datapoint?.rules || []}>
                                        <Column width={300}>
                                            <HeaderCell>
                                                Nome da regra
                                            </HeaderCell>
                                            <Cell dataKey="name" />
                                        </Column>
                                        <Column width={200}>
                                            <HeaderCell>Regra</HeaderCell>
                                            <Cell>
                                                {(rowData) => (
                                                    <span>
                                                        {rowData.mechanism
                                                            ?.mechanismType ||
                                                            'MANUAL'}
                                                    </span>
                                                )}
                                            </Cell>
                                        </Column>
                                        <Column width={200}>
                                            <HeaderCell>Responsável</HeaderCell>
                                            <Cell dataKey="author" />
                                        </Column>
                                    </Table>
                                </Panel>
                            </PanelGroup>
                        ) : (
                            <PanelGroup accordion bordered>
                                <Panel
                                    className="panel"
                                    header={
                                        <Badge
                                            content={
                                                dataLojas.limites.length || 0
                                            }
                                        >
                                            <p>Limites associasos</p>
                                        </Badge>
                                    }
                                >
                                    <Table data={dataLojas.limites}>
                                        <Column flexGrow={1}>
                                            <HeaderCell>Concorrente</HeaderCell>
                                            <Cell dataKey="loja" />
                                        </Column>
                                        <Column flexGrow={1}>
                                            <HeaderCell>Preço</HeaderCell>
                                            <Cell>
                                                {(rowData) =>
                                                    rowData.preco ? (
                                                        <CurrencyFormat
                                                            fixedDecimalScale
                                                            decimalScale={2}
                                                            value={
                                                                rowData.preco
                                                            }
                                                            displayType="text"
                                                            prefix="R$"
                                                            decimalSeparator=","
                                                            thousandSeparator="."
                                                        />
                                                    ) : (
                                                        ''
                                                    )
                                                }
                                            </Cell>
                                        </Column>
                                        <Column flexGrow={1}>
                                            <HeaderCell>
                                                Tipo do Preço
                                            </HeaderCell>
                                            <Cell dataKey="tipoPreco" />
                                        </Column>
                                        <Column flexGrow={1}>
                                            <HeaderCell>
                                                Tipo concorrente
                                            </HeaderCell>
                                            <Cell dataKey="tipoConcorrente" />
                                        </Column>
                                        <Column flexGrow={1}>
                                            <HeaderCell>
                                                Competitividade
                                            </HeaderCell>
                                            <Cell dataKey="competitividade" />
                                        </Column>
                                        <Column flexGrow={1}>
                                            <HeaderCell>
                                                Data da pesquisa
                                            </HeaderCell>
                                            <Cell dataKey="dataPesquisa" />
                                        </Column>
                                    </Table>
                                </Panel>
                                <Panel
                                    className="panel"
                                    header={<p>Lojas selecionadas</p>}
                                >
                                    <Table data={dataLojas.itens}>
                                        <Column flexGrow={2}>
                                            <HeaderCell>Loja</HeaderCell>
                                            <Cell dataKey="loja" />
                                        </Column>
                                        <Column flexGrow={1}>
                                            <HeaderCell>PMZ</HeaderCell>
                                            <Cell>
                                                {(rowData) =>
                                                    rowData.pmz ? (
                                                        <CurrencyFormat
                                                            fixedDecimalScale
                                                            decimalScale={2}
                                                            value={rowData.pmz}
                                                            displayType="text"
                                                            prefix="R$"
                                                            decimalSeparator=","
                                                            thousandSeparator="."
                                                        />
                                                    ) : (
                                                        ''
                                                    )
                                                }
                                            </Cell>
                                        </Column>
                                        <Column flexGrow={1}>
                                            <HeaderCell>
                                                Preço vigente
                                            </HeaderCell>
                                            <Cell>
                                                {(rowData) =>
                                                    rowData.pmz ? (
                                                        <CurrencyFormat
                                                            fixedDecimalScale
                                                            decimalScale={2}
                                                            value={
                                                                rowData.precoVigente
                                                            }
                                                            displayType="text"
                                                            prefix="R$"
                                                            decimalSeparator=","
                                                            thousandSeparator="."
                                                        />
                                                    ) : (
                                                        ''
                                                    )
                                                }
                                            </Cell>
                                        </Column>
                                        <Column flexGrow={2}>
                                            <HeaderCell>
                                                Preço concorrente
                                            </HeaderCell>
                                            <Cell>
                                                {(rowData) => (
                                                    <CurrencyFormat
                                                        fixedDecimalScale
                                                        decimalScale={2}
                                                        value={
                                                            rowData.precoVigente *
                                                            rowData.cpiVigente
                                                        }
                                                        displayType="text"
                                                        prefix="R$"
                                                        decimalSeparator=","
                                                        thousandSeparator="."
                                                    />
                                                )}
                                            </Cell>
                                        </Column>
                                        <Column flexGrow={1}>
                                            <HeaderCell>Margem</HeaderCell>
                                            <Cell>
                                                {(rowData) =>
                                                    rowData.margemVigente ? (
                                                        <CurrencyFormat
                                                            fixedDecimalScale
                                                            decimalScale={2}
                                                            value={
                                                                rowData.margemVigente
                                                            }
                                                            displayType="text"
                                                            prefix="R$"
                                                            decimalSeparator=","
                                                            thousandSeparator="."
                                                        />
                                                    ) : (
                                                        ''
                                                    )
                                                }
                                            </Cell>
                                        </Column>
                                        <Column flexGrow={1}>
                                            <HeaderCell>Campanha</HeaderCell>
                                            <Cell>
                                                {(rowData) => (
                                                    <span>
                                                        {rowData.campanhas &&
                                                        rowData.campanhas.length
                                                            ? rowData
                                                                  .campanhas[0]
                                                                  .nome
                                                            : ''}
                                                    </span>
                                                )}
                                            </Cell>
                                        </Column>
                                        <Column flexGrow={1}>
                                            <HeaderCell>
                                                Fim campanha
                                            </HeaderCell>
                                            <Cell>
                                                {(rowData) => (
                                                    <span>
                                                        {rowData.campanhas &&
                                                        rowData.campanhas.length
                                                            ? rowData
                                                                  .campanhas[0]
                                                                  .fimCampanha
                                                            : ''}
                                                    </span>
                                                )}
                                            </Cell>
                                        </Column>
                                    </Table>
                                </Panel>
                            </PanelGroup>
                        )}
                    </FlexboxGrid.Item>
                </FlexboxGrid>
                <FlexboxGrid justify="end">
                    <FlexboxGrid.Item
                        componentClass={Col}
                        lg={2}
                        md={2}
                        sm={24}
                        xs={24}
                    >
                        <Button block appearance="ghost" onClick={back}>
                            Cancelar
                        </Button>
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item
                        componentClass={Col}
                        lg={2}
                        md={2}
                        sm={24}
                        xs={24}
                    >
                        <Button
                            block
                            appearance="primary"
                            onClick={getValidacaoSave}
                        >
                            Enviar{' '}
                        </Button>
                    </FlexboxGrid.Item>
                    <ModalConfirmacaoFamilia
                        saveProduto={getSaveProdutoUnicoModal}
                        saveFamilia={getSaveProdutoFamiliaModal}
                        tabela={modalConfirmacaoFamiliaData}
                        isOpen={showModalConfirmacaoFamilia}
                        onClose={() => setShowModalConfirmacaoFamilia(false)}
                        loja={datapoint}
                        onChangePage={(value) => handleChangePage(value)}
                    />
                </FlexboxGrid>
            </Content>
        </Container>
    );
};

export default withRouter(PrecificacaoManualLoja);
