import { useState } from 'react';
import {
    setPlaceholderChooseSentence,
    setPlaceholderSelectedSentence,
    setVersusPlaceholderChooseSentence,
    setVersusPlaceholderSelectedSentence,
} from '../utils';

export const useChangePlaceholder = (placeholder, addSelect) => {
    const [selectPlaceholder, setSelectPlaceholder] = useState({
        label: placeholder || '',
        placeholder: placeholder
            ? setPlaceholderChooseSentence[placeholder]
            : '',
        isFirstSelect: false,
    });
    const [thirdSelectPlaceholder, setThirdSelectPlaceholder] = useState({
        label: placeholder || '',
        placeholder: placeholder
            ? setVersusPlaceholderChooseSentence[placeholder]
            : '',
    });

    const handlePlaceholderChange = (v) => {
        if (typeof v === 'string') {
            setSelectPlaceholder((prevState) => ({
                ...prevState,
                placeholder: setPlaceholderChooseSentence[prevState.label],
            }));
        }

        if (typeof v === 'object' && v.length > 0) {
            setSelectPlaceholder((prevState) => ({
                ...prevState,
                placeholder: setPlaceholderSelectedSentence[prevState.label],
            }));
        } else {
            setSelectPlaceholder((prevState) => ({
                ...prevState,
                placeholder: setPlaceholderChooseSentence[prevState?.label],
            }));
        }
    };

    const handleFirstPlaceholderChange = (name) => {
        setSelectPlaceholder((prevState) => ({
            ...prevState,
            label: name,
            placeholder: setPlaceholderChooseSentence[name],
            isFirstSelect: addSelect,
        }));
    };

    const handleThirdPlaceholderChange = (value) => {
        if (typeof value === 'object' && value.length > 0) {
            setThirdSelectPlaceholder((prevState) => ({
                ...prevState,
                placeholder:
                    setVersusPlaceholderSelectedSentence[prevState.label],
            }));
        } else {
            setThirdSelectPlaceholder((prevState) => ({
                ...prevState,
                placeholder:
                    setVersusPlaceholderChooseSentence[prevState?.label],
            }));
        }
    };

    const handleCleanCheckPlaceholder = (type) => {
        if (type === 'third') {
            setThirdSelectPlaceholder((prevState) => ({
                ...prevState,
                placeholder:
                    setVersusPlaceholderChooseSentence[prevState?.label],
            }));
        } else {
            setSelectPlaceholder((prevState) => ({
                ...prevState,
                placeholder: setPlaceholderChooseSentence[prevState?.label],
            }));
        }
    };

    return {
        selectPlaceholder,
        setSelectPlaceholder,
        thirdSelectPlaceholder,
        setThirdSelectPlaceholder,
        handlePlaceholderChange,
        handleFirstPlaceholderChange,
        handleThirdPlaceholderChange,
        handleCleanCheckPlaceholder,
    };
};
