import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export type ResultScenarioStateProps = {
    resultScenarioData: any,
    simulatorHeaderData: any,
    revenueDemandChartData: any,
    profitRevenueChartData: any,
    cpiChartData: any,
    isEditing: boolean,
    showModalSave: boolean,
    showModalUpdate: boolean,
    modalSaveTitle: string,
    editId: string,
};

export type ResultScenarioNameProps =
    | 'resultScenarioData'
    | 'simulatorHeaderData'
    | 'revenueDemandChartData'
    | 'profitRevenueChartData'
    | 'isEditing'
    | 'showModalSave'
    | 'showModalUpdate'
    | 'modalSaveTitle'
    | 'editId'
    | 'cpiChartData'

const initialState: ResultScenarioStateProps = {
    resultScenarioData: {
        query: {},
        scenario: {},
        inputs: {},
        outputs: {},
    },
    simulatorHeaderData: {
        isOpen: false,
    },
    revenueDemandChartData: [],
    profitRevenueChartData: [],
    cpiChartData: [],
    isEditing: false,
    showModalSave: false,
    showModalUpdate: false,
    modalSaveTitle: 'Salvar',
    editId: '',
};

const slice = createSlice({
    name: 'result-headers-state',
    initialState,
    reducers: {
        setResultScenarioData(state, { payload }: PayloadAction<{
            data: any;
            name: ResultScenarioNameProps;
        }>) {
            state[payload.name] = payload.data;
        },
        setResultFromEditScenario(state, { payload }: PayloadAction<{
            data: ResultScenarioStateProps;
        }>) {
            state.resultScenarioData = payload.data;
        },
        resetResultScenario() {
            return initialState;
        },
    },
});

const { setResultScenarioData, setResultFromEditScenario, resetResultScenario } = slice.actions;

export { setResultScenarioData, setResultFromEditScenario, resetResultScenario };

export default slice.reducer;
