import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export type ExtracaoDadosEcomNameProps =
    | 'browserCookie'
    | 'openModalDadosTabelaExtracao'
    | 'ecomTableData'
    | 'ecomPayload'
    | 'colunasHeaderList'
    | 'colunasSelectedList'

export type ExtracaoDadosEcomProps = {
    browserCookie: string[];
    openModalDadosTabelaExtracao: boolean;
    ecomTableData: any;
    ecomPayload: any;
    colunasHeaderList: any;
    colunasSelectedList: any;
};

const initialState: ExtracaoDadosEcomProps = {
    browserCookie: [],
    openModalDadosTabelaExtracao: false,
    ecomTableData: {},
    colunasHeaderList: [],
    colunasSelectedList: [],
    ecomPayload: {
        login: JSON.parse(localStorage.getItem('usuario'))?.email,
        data_inicio: null,
        data_fim: null,
        fonte: [],
        produtos: [],
    },
};

const slice = createSlice({
    name: '/isa/extracao-dados/ecom',
    initialState,
    reducers: {
        setEcomValues(state, { payload }: PayloadAction<{
            name: ExtracaoDadosEcomNameProps;
            value: any;
        }>) {
            state[payload.name] = payload.value;
        },
        resetExtracaoDadosEcom() {
            return initialState;
        },
    },
});

const {
    setEcomValues,
    resetExtracaoDadosEcom,
} = slice.actions;

export {
    setEcomValues,
    resetExtracaoDadosEcom,
};

export default slice.reducer;
