import { trackPromise } from 'react-promise-tracker';

import api from '../utils/API';

export const getRegrasProduto = (params) => api({
        url: `${process.env.REACT_APP_IPA_URL}/rules`,
        method: 'GET',
        expectToken: true,
        params: {
            clientId: JSON.parse(localStorage.getItem('cliente'))?.codigo,
            clientName: JSON.parse(localStorage.getItem('cliente'))?.nome,
            type: 'GENERIC_RULE',
            size: params.size ? params.size : 20,
            productId: params.productId ? params.productId : null,
        },
    }).then((resp) => resp.data);

export const deleteRegra = (id) => trackPromise(
        api({
            url: `${process.env.REACT_APP_IPA_URL}/rules/${id}`,
            method: 'DELETE',
            expectToken: true,
        }).then((resp) => resp),
    );
