import React from 'react';
import { CheckPicker, SelectPicker } from 'rsuite';

// id = select-picker-pill (forma de pílula, para select normal sem checkbox)
// id = check-picker-pill (forma de pílula, para select com checkbox na lista e placeholder visível no check do item)
// id = check-picker-pill-default (forma de pílula, para select com checkbox na lista e placeholder escondido)
// type = check (para select com checkbox na lista, se essa props estiver vazia o select será uma lista normal)

const Select = ({
    id,
    data,
    size,
    placeholder,
    onChange,
    onSearch,
    onClose,
    onOpen,
    maxHeight,
    name,
    onSelect,
    searchable,
    defaultValue,
    onClean,
    cleanable,
    disabled,
    value,
    locale,
    type,
    className,
    disabledItemValues,
    filter,
    menuClassName,
    renderMenuItem,
    renderExtraFooter,
    renderMenu,
    style,
    renderValue,
    placement,
    container,
}) => {
    const renderPlaceholder =
        id === 'check-picker-pill'
            ? (value) => (
                <>
                    <span>{value && placeholder}</span>
                    <span className="counter">{value && value?.length}</span>
                </>
            )
            : renderValue;

    return type === 'check' ? (
        <div className="picker-pill-wrapper">
            <CheckPicker
                block
                id={id}
                className={className}
                value={value}
                disabled={disabled}
                data={data}
                size={size}
                name={name}
                defaultValue={defaultValue}
                placeholder={placeholder}
                cleanable={cleanable}
                onChange={onChange}
                onSelect={onSelect}
                onClean={onClean}
                onSearch={onSearch}
                onClose={onClose}
                onOpen={onOpen}
                searchable={searchable}
                locale={locale}
                disabledItemValues={disabledItemValues}
                filter={filter}
                menuClassName={menuClassName}
                renderMenuItem={renderMenuItem}
                renderExtraFooter={renderExtraFooter}
                renderMenu={renderMenu}
                renderValue={renderPlaceholder}
                maxHeight={maxHeight}
                style={style}
                placement={placement}
            />
        </div>
    ) : (
        <SelectPicker
            block
            id={id}
            className={`${className || ''} ${cleanable === false ? 'no-clean' : ''
                }`}
            filter={filter}
            value={value}
            disabled={disabled}
            data={data}
            size={size}
            name={name}
            defaultValue={defaultValue}
            placeholder={placeholder}
            cleanable={cleanable}
            onChange={onChange}
            onSelect={onSelect}
            onSearch={onSearch}
            onClean={onClean}
            onClose={onClose}
            onOpen={onOpen}
            searchable={searchable}
            locale={locale}
            style={style}
            placement={placement}
            renderExtraFooter={renderExtraFooter}
            menuClassName={menuClassName}
            container={container}
            renderMenu={renderMenu}
            renderValue={renderPlaceholder}
        />
    );
};

export default Select;
