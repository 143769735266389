import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import * as UsuarioService from '../services/UsuarioService';
import PrivateRoute from './PrivateRoute';

import { setAdministrador, setAdministradores } from '../actions/actionsAdmin';
import {
    setCliente,
    setClienteData,
    setServicosCliente,
} from '../actions/actionsCliente';
import { setPermissoesUsuario } from '../actions/actionsUsuario';

import 'rsuite/dist/styles/rsuite-default.css';
import {
    GerenciadorPrecosProvider,
    OnboardingProvider,
} from '../pages/IPA/Lite/context';
import IPAProvider from '../store/IPAProvider';
import '../style/app.scss';
import { RoutesConfig } from '../utils/Routes';

class PrimaryLayout extends Component {
    constructor(props) {
        super(props);

        this.state = {
            alertas: [],
            usuario: null,
        };

        this.getUser = this.getUser.bind(this);
    }

    componentDidMount() {
        this.getUser();
    }

    shouldComponentUpdate(nextProps, nextState) {
        return nextProps !== this.props || nextState !== this.state;
    }

    componentDidUpdate() {
        const { history } = this.props;
        const { usuario } = this.state;
        const usuarioLogin = JSON.parse(localStorage.getItem('usuario'))?.email;

        if (
            usuario?.login !== usuarioLogin &&
            history.location.pathname !== '/login'
        ) {
            this.getUser();
        }
    }

    getUser() {
        if (JSON.parse(localStorage.getItem('usuario'))?.email) {
            const {
                setCliente,
                setAdministrador,
                setPermissoesUsuario,
                setServicosCliente,
                setAdministradores,
                setClienteData,
            } = this.props;

            UsuarioService.getClienteCompleto(
                JSON.parse(localStorage.getItem('usuario'))?.email,
            ).then(async (data) => {
                if (data) {
                    this.setState({
                        usuario: data,
                    });
                    setCliente(data.codigoCliente);
                    setAdministrador(data.usuarioAdministrador);
                    setPermissoesUsuario(
                        data.servicos,
                        data.servicoBi,
                        data.servicoPanel,
                        data.servicoIra,
                    );
                    setServicosCliente(data.servicosCliente);
                    setAdministradores(data.administradores);
                    setClienteData(data);
                }
            });
        }
    }

    render() {
        const { alertas, usuario } = this.state;
        const { history } = this.props;

        const routesMap = RoutesConfig.map(
            ({ path, component, privateRoute, adminArea }, key) =>
                privateRoute ? (
                    <PrivateRoute
                        key={key}
                        exact
                        path={path}
                        component={component}
                        adminArea={adminArea}
                        alertas={alertas}
                        history={history}
                        usuario={usuario}
                    />
                ) : (
                    <Route exact path={path} component={component} key={key} />
                ),
        );

        return (
            <IPAProvider>
                <GerenciadorPrecosProvider usuario={usuario} history={history}>
                    <OnboardingProvider usuario={usuario} history={history}>
                        <Switch>
                            {routesMap}
                            <Redirect exact from="/" to="/login" />
                        </Switch>
                    </OnboardingProvider>
                </GerenciadorPrecosProvider>
            </IPAProvider>
        );
    }
}

const mapStateToProps = (store) => ({
    enterAdmin: store.adminDataReducer.enterAdmin,
    permissoes: store.usuarioDataReducer.permissoes,
    codigoCliente: store.clienteDataReducer.codigoCliente,
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            setPermissoesUsuario,
            setCliente,
            setAdministrador,
            setServicosCliente,
            setAdministradores,
            setClienteData,
        },
        dispatch,
    );

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(PrimaryLayout),
);
