import React from 'react';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import styles from './PendingRequestBox.module.scss';

export const PendingRequestBox = () => {
  return (
      <div className={styles.box}>
          <RadioButtonCheckedIcon />
          <span className={styles.text}>
              Algumas solicitações
              {' '}
              <br />
              {' '}
              estão em processamento
          </span>
      </div>
  );
};
