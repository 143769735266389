import classNames from 'classnames';
import React from 'react';
import styles from './ChipIcon.module.scss';

type ChipIconProps = {
    format?: 'compact' | 'default';
    state?: 'default' | 'active' | 'disabled' | 'error';
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

export const ChipIcon = ({
    format = 'default',
    state = 'default',
    className,
    ...props
}: ChipIconProps) => {
    return (
        <button
            className={classNames(
                styles['chip-icon'],
                styles[`chip-icon-format-${format}`],
                className,
            )}
            {...props}
        />
    );
};
