import React from 'react';
import { Tooltip, Whisper } from 'rsuite';
import { BaseCell } from '../BaseCell';
import { BaseCellProps } from '../BaseCell/BaseCell';

type StoreCellProps = BaseCellProps & {
    rowData: {
        storeId: string;
        storeName: string;
    };
};

const StoreCell = ({ rowData, ...props }: StoreCellProps) => {
    const { storeName, storeId } = rowData;

    return (
        <BaseCell {...props}>
            {storeName ? (
                <Whisper
                    delayShow={0.2}
                    trigger="hover"
                    placement="right"
                    speaker={<Tooltip>{storeName}</Tooltip>}
                >
                    <span className="font-size-100-semibold">{storeId}</span>
                </Whisper>
            ) : null}
        </BaseCell>
    );
};

export default StoreCell;
