export function getValidData<T = Record<string, unknown>>(data: T) {
    let newData = {};

    function updateData(key, value) {
        newData = {
            ...newData,
            [key]: value,
        };
    }

    Object.entries(data).forEach(([key, value]) => {
        // @ts-expect-error Property 'length' does not exist on type 'number'
        if (value === undefined || null || value?.length <= 0) return;
        if (typeof value === 'string' && value.length > 0) {
            updateData(key, value);
        }
        if (Array.isArray(value) && value.length > 0) {
            updateData(key, value);
        }
        if (value !== (undefined || null) && !Array.isArray(value)) {
            updateData(key, value);
        }
    });

    return newData as T;
}
