import React from 'react';
import { useSelector } from 'react-redux';
import {
    Button,
    Dropdown,
    Icon,
} from 'rsuite';

import { selectorExtracaoDados } from '../../../../../../reducers/extracaoDados';
import * as service from '../../services';

export const DownloadDropdownEcom = ({ handleShowModalDownload }) => {
    const { ecom } = useSelector(selectorExtracaoDados);

    const handleDownload = (extension) => {
        const data = { ...ecom.ecomPayload, dimensoes: ecom.colunasSelectedList }
        service.getDownloadEcom(data, extension).then(response => {
            if (response && response.status === 202) {
                handleShowModalDownload();
            }
        });
    };

    return (
        <Dropdown
            id="download"
            placement="bottomEnd"
            title="Download"
            icon={<Icon icon="cloud-download" />}
            renderTitle={() => (
                <Button block appearance="primary">
                    <Icon icon="cloud-download" />
                    {' '}
                    Download
                    {' '}
                </Button>
            )}
        >
            <Dropdown.Item
                eventKey="csv"
                id="download-csv"
                onSelect={handleDownload}
                icon={<Icon icon="file-text-o" />}
            >
                .csv
            </Dropdown.Item>
            <Dropdown.Item
                eventKey="xlsx"
                id="download-xlsx"
                onSelect={handleDownload}
                icon={<Icon icon="file-excel-o" />}
            >
                .xlsx
            </Dropdown.Item>
        </Dropdown>
    );
};
