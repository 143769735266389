import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../@types/RootState';

export type PrecoAtacadoFiltersValueState = Record<string, string[]>;

const initialState: PrecoAtacadoFiltersValueState = {};

const slice = createSlice({
    name: 'ipa/precoAtacado/filtersValue',
    initialState,
    reducers: {
        SET_PRECO_ATACADO_FILTERS_VALUE: (
            _state,
            { payload }: PayloadAction<PrecoAtacadoFiltersValueState>,
        ) => payload,
        UPDATE_PRECO_ATACADO_FILTERS_VALUE: (
            state,
            {
                payload,
            }: PayloadAction<{
                key: string;
                value: string[];
            }>,
        ) => {
            const { key, value } = payload;
            state[key] = value;
        },
    },
});

const { SET_PRECO_ATACADO_FILTERS_VALUE, UPDATE_PRECO_ATACADO_FILTERS_VALUE } =
    slice.actions;

const selectorPrecoAtacadoFiltersValue = (state: RootState) => {
    return state.precoAtacadoReducer.filters.values;
};

export {
    initialState,
    selectorPrecoAtacadoFiltersValue,
    SET_PRECO_ATACADO_FILTERS_VALUE,
    UPDATE_PRECO_ATACADO_FILTERS_VALUE,
};

export default slice.reducer;
