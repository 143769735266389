import qs from 'qs';
import { UnknownObject } from '../../../../@types';
import { GetCompetitorsInfo } from '../services';
import { getValidData } from './getValidData';

export function GET_ALL_PRODUCTS(params: UnknownObject) {
    return {
        url: '/all-products',
        config: {
            expectToken: true,
            params: getValidData({
                field: params.field,
                ...params,
            }),
            paramsSerializer(params: UnknownObject) {
                return qs.stringify(params, { arrayFormat: 'repeat' });
            },
        },
    } as const;
}

export function GET_COMPETITORS_INFO(rowData: GetCompetitorsInfo.Params) {
    return {
        url: `/competitor/prices/${rowData.productId}`,
        config: {
            method: 'GET',
            expectToken: true,
            params: getValidData(rowData),
            paramsSerializer(params: UnknownObject) {
                return qs.stringify(params, { arrayFormat: 'repeat' });
            },
        },
    } as const;
}
