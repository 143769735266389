import HelpIcon from '@material-ui/icons/Help';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Button,
    Col,
    Container,
    Content,
    FlexboxGrid,
    Icon,
} from 'rsuite';
import { selectorExtracaoDados } from '../../../../../../reducers/extracaoDados';
import {
    setEcomValues,
} from '../../../../../../reducers/extracaoDados/ecom';
import { CheckPickerColunas } from '../CheckPickerColunas';
import { DownloadDropdownEcom } from '../DownloadDropdownEcom';
import { ModalDadosTabelaExtracao } from '../ModalDadosTabelaExtracao';
import { TableEcom } from '../TableEcom';
import styles from './TableEcomContainer.module.scss';

type TableEcomContainerProsp = {
    tab: string,
    handleShowModalDownload: any
}

export const TableEcomContainer = ({ tab, handleShowModalDownload }: TableEcomContainerProsp) => {
    const dispatch = useDispatch();

    const { ecom } = useSelector(selectorExtracaoDados);

    const handleOpenModal = () => {
        const obj = { name: 'openModalDadosTabelaExtracao', value: true };
        dispatch(setEcomValues(obj));
    };

    if (tab === 'ECOMM' && Object.keys(ecom.ecomTableData).length === 0) {
        return null;
    }

    if (tab === 'ECOMM' && ecom.ecomTableData.empty) {
        return (
            <Container>
                <Content className={styles['content-empty-state']}>
                    <div className="empty-state">
                        <Icon icon="collasped-o" className="empty-state-icon" />
                        <h3>Não encontramos resultados que corresponda a sua busca.</h3>
                        <h4>Tente novamente.</h4>
                    </div>
                </Content>
            </Container>
        );
    }

    return (
        <Content className={styles['ecom-table-container']}>
            <FlexboxGrid>
                <FlexboxGrid.Item className="pd-top-10" componentClass={Col} colspan={10} lg={10} md={10} sm={24} xs={24}>
                    <div className="total-itens">
                        Exibindo
                        {' '}
                        {ecom.ecomTableData?.totalElements > 20 ? 20 : ecom.ecomTableData?.totalElements}
                        {' '}
                        de
                        {' '}
                        {ecom.ecomTableData?.totalElements}
                        {' '}
                        {ecom.ecomTableData?.totalElements > 1 ? 'itens' : 'item'}
                        .
                        {' '}
                        {ecom.ecomTableData?.totalElements > 20 ? 'Para visualizar todos os itens faça o download' : null}
                    </div>
                </FlexboxGrid.Item>
                <FlexboxGrid.Item componentClass={Col} colspan={14} lg={14} md={14} sm={24} xs={24} style={{ textAlign: 'end' }}>
                    <CheckPickerColunas />
                    <DownloadDropdownEcom handleShowModalDownload={handleShowModalDownload} />
                    <Button
                        className={styles['btn-column-help']}
                        appearance="default"
                        onClick={handleOpenModal}
                    >
                        <HelpIcon />
                    </Button>
                </FlexboxGrid.Item>
                <FlexboxGrid.Item componentClass={Col} colspan={24} lg={24} md={24} sm={24} xs={24}>
                    <TableEcom />
                </FlexboxGrid.Item>
            </FlexboxGrid>
            <ModalDadosTabelaExtracao />
        </Content>
    );
};
