import React from 'react';
import { Notification } from 'rsuite';
import iconCheckCircleGreen from '../../../../assets/icons/icon_check_circle_green.svg';
import styles from './NotificationComponent.module.scss';

export const NotificationComponent = (text: string) => (
    Notification.open({
        className: styles.notification,
        duration: 5000,
        description: (
            <div className={styles['description-flex']}>
                <img src={iconCheckCircleGreen} alt="" />
                <div>
                    <p className={styles.text}>{text}</p>
                </div>
            </div>
        ),
    })
);
