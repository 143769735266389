import * as StoreCompetitorsService from '../pages/ISA/InfoPanel/StoreCompetitors/services';

export const SET_FILTER_VALUE_LOJAS_CONCORRENTES
    = 'SET_FILTER_VALUE_LOJAS_CONCORRENTES';
export const SET_FILTROS_DATA_LOJAS_CONCORRENTES
    = 'SET_FILTROS_DATA_LOJAS_CONCORRENTES';
export const SET_FILTERS_LIST = 'SET_FILTERS_LIST';
export const SELECT_FILTER_VALUE = 'SELECT_FILTER_VALUE';
export const SET_ADDRESS_LIST = 'SET_ADDRESS_LIST';
export const SET_FILTER_OBJECT = 'SET_FILTER_OBJECT';
export const RESET_ALL_FILTERS = 'RESET_ALL_FILTERS';
export const SET_SINGLE_REDUCER = 'SET_SINGLE_REDUCER';

export function setLojasConcorrentesFilterValue(name, value) {
    return {
        type: SET_FILTER_VALUE_LOJAS_CONCORRENTES,
        value,
        name,
    };
}

export function setFiltrosDataLojasConcorrentes(name, data) {
    const newList = data?.map((item) => ({
        label: item.key,
        value: item.key,
        datapoints: item.datapoints,
    }));

    return {
        type: SET_FILTROS_DATA_LOJAS_CONCORRENTES,
        name,
        list: newList,
    };
}

export function selectFilterValue(name, value) {
    return {
        type: SELECT_FILTER_VALUE,
        value,
        name,
    };
}

export function setAddressList(list) {
    const newList = list?.predictions?.map((item) => ({
        label: item.description,
        value: item.description,
        place_id: item.place_id,
        token: list.session_token,
    }));

    return {
        type: SET_ADDRESS_LIST,
        list: newList,
    };
}

export function setFilterObject(name, value) {
    return {
        type: SET_FILTER_OBJECT,
        value,
        name,
    };
}

export function resetAllFilters() {
    return {
        type: RESET_ALL_FILTERS,
    };
}

export function setSingleReducer(name, value) {
    return {
        type: SET_SINGLE_REDUCER,
        name,
        value,
    };
}

export function getFiltersList(name, params) {
    return (dispatch) => {
        StoreCompetitorsService.getItensFiltros(params).then((data) => {
            if (data) {
                dispatch(setFiltrosDataLojasConcorrentes(name, data));
            }
        });
    };
}

export function getAddress(params) {
    return (dispatch) => {
        StoreCompetitorsService.getEndereco(params).then((data) => {
            dispatch(setAddressList(data));
        });
    };
}
