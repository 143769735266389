import React, { useState } from 'react';
import { Col, Grid, Row } from 'rsuite';
import Slider from 'react-slick';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import noImage from '../../../../../assets/icons/no-image.svg';

const ProductsCards = ({ products }) => {
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: true,
    };
    const [viewFull, setViewFull] = useState(false);

    const showAllProducts = () => {
        if (products?.length > 1) {
            setViewFull(true);
        }
    };

    const resumeAllProducts = () => {
        setViewFull(false);
    };

    const imageError = (e) => {
        e.target.onerror = null;
        e.target.src = noImage;
    };

    return (
        !viewFull
            ? (
                <Grid fluid style={{ width: '100%' }}>
                    <Row>
                        <Col lg={12} md={12} sm={24} xs={24} className="content-carousel">
                            <Row className="products-cards">
                                <Col lg={24} md={24} sm={24} xs={24} className="pd-0">
                                    <Slider {...settings} className="carosel">
                                        {products?.length
                                            ? products.map((item) => (
                                                <div key={item.identificador} className="wrapper-slick card-products">
                                                    <div className="row">
                                                        <div className="column left card-products__product-img">
                                                            <img
                                                                alt="imagem produto"
                                                                width="80"
                                                                height="80"
                                                                loading="lazy"
                                                                className="card-products__img"
                                                                src={item.foto || noImage}
                                                                onError={(e) => imageError(e)}
                                                            />
                                                        </div>
                                                        <div className="column right card-products__info-section">
                                                            <div className="row">
                                                                <span>Descrição</span>
                                                                <p>{item.descricao}</p>
                                                            </div>
                                                            <div className="row">
                                                                <div className="column more-info">
                                                                    <span>GTIN</span>
                                                                    <p>{item.identificador}</p>
                                                                </div>
                                                                <div className="column more-info">
                                                                    <span>Seção</span>
                                                                    <p>{item.secao}</p>
                                                                </div>
                                                                <div className="column more-info">
                                                                    <span>Categoria</span>
                                                                    <p>{item.categoria}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )) : null}
                                    </Slider>
                                </Col>
                            </Row>
                        </Col>
                        <Col lg={12} md={12} className="resume-area mg-bottom-0" smHidden xsHidden>
                            <div className="resume-area__wrapper">
                                {products?.length > 1
                                    ? (
                                        <p className="resume-area__products-quantity">
                                            E outros
                                            {' '}
                                            <b>
                                                {products?.length ? products.length - 1 : null}
                                                {' '}
                                                produto(s)
                                            </b>
                                            {' '}
                                            estão incluídos na seleção
                                        </p>
                                    )
                                    : (
                                        <p className="resume-area__products-quantity">
                                            Este é o único
                                            {' '}
                                            <b>produto</b>
                                            {' '}
                                            na sua busca.
                                        </p>
                                    )}
                                <button
                                    type="button"
                                    className={
                                        products?.length > 1
                                            ? 'resume-area__more-products'
                                            : 'resume-area__more-products resume-area__more-products_disabled'
                                    }
                                    onClick={() => showAllProducts()}
                                >
                                    <FontAwesomeIcon icon={faChevronDown} />
                                    {' '}
                                    VER TODOS OS PRODUTOS
                                </button>
                            </div>
                        </Col>
                    </Row>
                </Grid>
            )
            : (
                <Grid fluid style={{ width: '100%' }}>
                    <Row>
                        <Col lg={24} md={24} className="products-cards margin-20" smHidden xsHidden>
                            <Row className="scroll-area">
                                {products && products.length
                                    ? products.map((item) => (
                                        <Col key={item.identificador} lg={12} md={12}>
                                            <div className="wrapper card-products">
                                                <div className="row">
                                                    <div className="column left card-products__product-img">
                                                        <img
                                                            alt="imagem produto"
                                                            width="80"
                                                            height="80"
                                                            loading="lazy"
                                                            className="card-products__img"
                                                            src={item.foto || noImage}
                                                            onError={(e) => imageError(e)}
                                                        />
                                                    </div>
                                                    <div className="column right card-products__info-section">
                                                        <div className="row">
                                                            <span>Descrição</span>
                                                            <p>{item.descricao}</p>
                                                        </div>
                                                        <div className="row">
                                                            <div className="column more-info">
                                                                <span>GTIN</span>
                                                                <p>{item.identificador}</p>
                                                            </div>
                                                            <div className="column more-info">
                                                                <span>Seção</span>
                                                                <p>{item.secao}</p>
                                                            </div>
                                                            <div className="column more-info">
                                                                <span>Categoria</span>
                                                                <p>{item.categoria}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    )) : null}
                            </Row>
                            <Row>
                                <Col lg={4}>
                                    <button
                                        type="button"
                                        className="resume-area__less-products"
                                        onClick={() => resumeAllProducts()}
                                    >
                                        <FontAwesomeIcon icon={faChevronUp} />
                                        {' '}
                                        FECHAR DETALHES DE PRODUTOS
                                    </button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Grid>
            )
    );
};

export default React.memo(ProductsCards);
