import React, { forwardRef } from 'react';
import { Icon } from 'rsuite';
import { Icon as CustomIcon } from '../../pages/IPA/RevisaoPrecos/Components/QuickActionFilter';
import styles from './style.module.scss';

export type EngineWarningProps = {
    variant?: 'NOT_UPDATED' | 'UPDATED' | 'UPDATING';
} & React.HtmlHTMLAttributes<HTMLDivElement>;

const ICONS = {
    UPDATING: ({ className }: React.HtmlHTMLAttributes<HTMLElement>) => (
        <>
            <CustomIcon className={className} icon="MdChangeCircle" />
            <p>
                O motor está sendo executado neste momento, em breve os preços
                estarão atualizados.
            </p>
        </>
    ),
    NOT_UPDATED: (props: React.HtmlHTMLAttributes<HTMLElement>) => (
        <>
            <Icon icon="exclamation-triangle" {...props} />
            <p>
                O motor ainda não foi executado hoje e por isso os preços
                encontram-se desatualizados.
            </p>
        </>
    ),
} as const;

enum VARIANTS {
    UPDATING = 'blue',
    NOT_UPDATED = 'orange',
}

export const EngineWarning = forwardRef<HTMLDivElement, EngineWarningProps>(
    ({ className = '', variant = 'UPDATED', ...props }, ref) => {
        if (!variant || variant === 'UPDATED' || variant === 'UPDATING')
            return null;
        const RenderIcon = ICONS[variant];
        return (
            <div
                className={[
                    styles['engine-warning'],
                    styles[`engine-warning-skin-${VARIANTS[variant]}`],
                    className,
                ].join(' ')}
                ref={ref}
                {...props}
            >
                <div className={styles['message-container']}>
                    <RenderIcon className="engine-warning__icon" />
                </div>
            </div>
        );
    },
);
