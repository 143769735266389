import classNames from 'classnames';
import React from 'react';
import CurrencyFormat from 'react-currency-format';
import { MdEmojiEvents } from 'react-icons/md';
import { Tooltip, Whisper } from 'rsuite';
import styles from './CompetitivenessPriceIndicator.module.scss';

export type CompetitivenessPriceIndicatorProps = {
    value?: number | null;
} & React.HtmlHTMLAttributes<HTMLDivElement>;

export const CompetitivenessPriceIndicator = ({
    value,
    className,
    ...props
}: CompetitivenessPriceIndicatorProps) => {
    return (
        <div className={classNames(styles.container, className)} {...props}>
            <Whisper
                placement="auto"
                trigger="hover"
                speaker={<Tooltip>Competitividade do preço vigente</Tooltip>}
            >
                <MdEmojiEvents size={11} />
            </Whisper>
            {typeof value === 'number' ? (
                <CurrencyFormat
                    fixedDecimalScale
                    decimalScale={1}
                    value={value}
                    displayType="text"
                    decimalSeparator=","
                    thousandSeparator="."
                    suffix="%"
                />
            ) : (
                '--'
            )}
        </div>
    );
};
