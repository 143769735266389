export const CHANGE_MENU = 'CHANGE_MENU'
export const CHANGE_ACTIVE_KEY_MENU = 'CHANGE_ACTIVE_KEY_MENU'

export function changeSideMenu(sideMenu){
    return {
        type: CHANGE_MENU,
        sideMenu
    }
}

export function changeMenuActiveKey(activeKeyMenu){
    return {
        type: CHANGE_ACTIVE_KEY_MENU,
        activeKeyMenu
    }
}