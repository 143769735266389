import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsExport from 'highcharts/modules/export-data';
import HighchartsExporting from 'highcharts/modules/exporting';
import React, {
    useEffect,
    useRef,
    useState,
} from 'react';
import {
    Button,
    Col,
    Content,
    Dropdown,
    FlexboxGrid,
    Icon,
    IconButton,
    SelectPicker,
    Tooltip, Whisper,
} from 'rsuite';

import iconEllipsis from '../../../../../assets/icons/icon_ellipsis-v.svg';
import lockIcon from '../../../../../assets/icons/icon_lock.svg';
import iconMaximize from '../../../../../assets/icons/icon_maximize.svg';
import iconMinimize from '../../../../../assets/icons/icon_minimize.svg';
import noDataImage from '../../../../../assets/image/empty-state-2.svg';

import { HighchartsOptions } from '../../../../../utils/HighchartsConfig';

import MobileSelectOptionComponent from '../../../../../components/MobileSelectOptionComponent';
import ModalConfirmation from '../../../../../components/ModalConfirmation';
import { setClickToLocalStorage } from '../../../../../components/NavBar';

import EmptyState from './EmptyState';

HighchartsExporting(Highcharts);
HighchartsExport(Highcharts);

Highcharts.setOptions(HighchartsOptions);

const optionsGroupBy = [
    { label: 'Produto', value: 'produto' },
    { label: 'Rede', value: 'rede' },
    { label: 'Consolidado', value: 'semAgrupamento' },
    { label: 'Fabricante', value: 'fabricante' },
    { label: 'Tipo de loja', value: 'tipoLoja' },
];

const periodoOptions = [
    { label: 'Dia', value: 'DATA' },
    { label: 'Semana', value: 'SEMANA' },
    { label: 'Mês', value: 'MES' },
];

const seriesColors = ['#76D680', '#A371E2', '#DFA25B', '#F25E5E', '#609BCE', '#B30089', '#FFB46E', '#FFD76E', '#5FC8FF', '#FF73C3'];

const defaultChartOption = {
    chart: {
        type: 'column',
    },
    title: {
        text: '',
    },

    exporting: {
        enabled: false,
    },
    tooltip: {
        useHTML: true,
        shared: true,
        pointFormatter() {
            let symbol = '';

            if (this.graphic && this.graphic.symbolName) {
                switch (this.graphic.symbolName) {
                    case 'circle':
                        symbol = '●';
                        break;
                    case 'diamond':
                        symbol = '♦';
                        break;
                    case 'square':
                        symbol = '■';
                        break;
                    case 'triangle':
                        symbol = '▲';
                        break;
                    case 'triangle-down':
                        symbol = '▼';
                        break;
                    default:
                        return null;
                }
            } else if (this.marker && this.marker.symbol) {
                const url = this.marker.symbol.replace(/^url\(|\)$/g, '');
                symbol = `<img src="${url}" alt="Marker" />`;
            }

            return `<span style="color:${this.series.color}">${symbol}</span> ${this.series.name}: <b>${this.series.name !== 'Lojas'
                && this.series.name !== 'Redes' ? (this.y).toFixed(2).replace('.', ',') : this.y}</b><br/>`;
        },
        headerFormat: '<b>{point.key}</b><br/>',
        xDateFormat: '%m/%Y',
    },
    legend: {
        symbolWidth: 30,
    },
    yAxis: [{ // Primary yAxis
        title: {
            // text: visualizationType === 'rede' ? 'QUANTIDADE DE LOJAS' : 'QUANTIDADE DE REDES E LOJAS',
            text: 'QUANTIDADE DE REDES E LOJAS',
        },
        breakSize: 1,
        alignTicks: false,
        opposite: true,
    },
    { // Secondary yAxis
        title: {
            text: 'PREÇOS',
        },
        labels: {
            formatter() {
                let price = this.axis.defaultLabelFormatter.call(this);
                if (price.includes('.')) {
                    price = price.replace('.', '');
                }

                if (price.includes(',')) {
                    price = price.replace(',', '.');
                }
                return `R$ ${Number(price).toFixed(2).replace('.', ',')}`;
            },
        },
        alignTicks: false,
    }],
    xAxis: {
        type: 'datetime',
        title: {
            text: 'TEMPO',
        },
        startOfWeek: 0,
        labels: {
            allowOverlap: true,
            padding: 0,
            rotation: -45,
            format: '{value: %B}',
        },
    },
    plotOptions: {
        series: {
            lineWidth: 1,
            marker: {
                radius: 3,
            },

            states: {
                hover: {
                    brightness: -0.3, // darken
                },
            },

        },
    },
    series: [],
};

const dateKey = {
    SEMANA: 'dia_semana',
    DATA: 'data_preco',
    MES: 'dia_mes',
};

const STimeEvolutionChart = ({
    user,
    userPlan,
    products,
    chains,
    groupBy,
    period,
    changePeriod,
    getData,
    sendCTA,
    chartData,
    disableExport,
    showExportOptions,
    disabledTypeOptions,
    disabledPeriodSelect,
}) => {
    const timeEvolutionChartRef = useRef(null);
    const [hasPermition, setHasPermition] = useState(true);
    const [expandTimeEvolution, setExpandTimeEvolution] = useState(true);
    const [openDialog, setOpenDialog] = useState(false);
    const [showCTA, setShowCTA] = useState(false);
    const [chartOptions, setChartOptions] = useState(defaultChartOption);
    const [visualizationType, setVisualizationType] = useState(groupBy || 'produto');
    const [priceType, setPriceType] = useState('average');

    const mapDataStore = (data) => {
        let returnArray = [];

        data.forEach((item) => {
            returnArray = [...returnArray, {
                x: new Date(item[dateKey[period]]).getTime(),
                y: item.cnpjs_distintos,
            }];
        });

        return returnArray.sort((a, b) => new Date(a.x) - new Date(b.x));
    };

    const mapDataChain = (data) => {
        let returnArray = [];

        data.forEach((item) => {
            returnArray = [...returnArray, {
                x: new Date(item[dateKey[period]]).getTime(),
                y: item.redes_distintas,
            }];
        });

        return returnArray.sort((a, b) => new Date(a.x) - new Date(b.x));
    };

    const setStoreColumn = (data) => {
        const dataToMap = [...data];
        return {
            type: 'column',
            name: 'Lojas',
            color: '#D6EEE6',
            yAxis: 0,
            zIndex: 0,
            states: {
                hover: {
                    color: '#33AB84',
                    borderColor: '#33AB84',
                },
            },
            data: mapDataStore(dataToMap),
        };
    };

    const setChainColumn = (data) => {
        const dataToMap = [...data];
        return {
            type: 'column',
            name: 'Redes',
            color: '#E9DDF7',
            yAxis: 0,
            zIndex: 0,
            states: {
                hover: {
                    color: '#9256D9',
                    borderColor: '#9256D9',
                },
            },
            data: mapDataChain(dataToMap),
        };
    };

    const setNoGroup = (data) => {
        const dataToMap = [...data.content];

        const priceObject = {
            type: 'spline',
            name: 'Preço',
            yAxis: 1,
            zIndex: 1,
            data: period === 'SEMANA' ? dataToMap.map((item) => ({
                x: new Date(item.dia_semana).getTime(),
                y: item[priceType === 'average' ? 'preco_medio' : 'preco_moda'],
            }))
                : period === 'DATA' ? dataToMap.map((item) => ({
                    x: new Date(item.data_preco).getTime(),
                    y: item[priceType === 'average' ? 'preco_medio' : 'preco_moda'],
                }))
                    : dataToMap.map((item) => ({
                        x: new Date(item.dia_mes).getTime(),
                        y: item[priceType === 'average' ? 'preco_medio' : 'preco_moda']
                    })),
        };

        const series = period === 'DATA' ? [priceObject] : [priceObject, setStoreColumn(data.totalsResult), setChainColumn(data.totalsResult)];
        return series;
    };

    const setProductColumn = (data) => {
        if (visualizationType === 'produto' && data?.content?.some((item) => item.descricao)) {
            const productsData = [...data.content];
            const productsObject = {};

            productsData.forEach((item, index) => {
                if (productsObject[item.gtin]) {
                    productsObject[productsData[index].gtin].data = [...productsObject[productsData[index].gtin].data, {
                        x: new Date(item[dateKey[period]]).getTime(),
                        y: productsData[index][priceType === 'average' ? 'preco_medio' : 'preco_moda'],
                    }];
                } else {
                    productsObject[item.gtin] = {
                        type: 'spline',
                        name: item.descricao,
                        data: [],
                        yAxis: 1,
                        zIndex: 1,
                        color: seriesColors[index + 2],
                    };

                    productsObject[item.gtin].data = [...productsObject[item.gtin].data, {
                        x: new Date(item[dateKey[period]]).getTime(),
                        y: productsData[index][priceType === 'average' ? 'preco_medio' : 'preco_moda'],
                    }];
                }
            });

            let series = Object.values(productsObject);
            series.forEach((item) => {
                item.data.sort((a, b) => new Date(a.x) - new Date(b.x));
            });
            series = period === 'DATA' ? [...series] : [...series, setStoreColumn(data.totalsResult), setChainColumn(data.totalsResult)];

            return series;
        }
        return [];
    };

    const setMakerColumn = (data) => {
        if (visualizationType === 'fabricante' && data?.content?.some((item) => item.fabricante)) {
            const fabricantes = [...data.content];
            const fabricantesObject = {};

            fabricantes.forEach((item, index) => {
                if (fabricantesObject[item.fabricante] || (!item.fabricante && fabricantesObject['Sem informação de fabricante'])) {
                    if (!item.fabricante) {
                        fabricantesObject['Sem informação de fabricante'].data = [...fabricantesObject['Sem informação de fabricante'].data, {
                            x: new Date(item[dateKey[period]]).getTime(),
                            y: fabricantes[index][priceType === 'average' ? 'preco_medio' : 'preco_moda'],
                        }];
                    } else {
                        fabricantesObject[fabricantes[index].fabricante].data = [...fabricantesObject[fabricantes[index].fabricante].data, {
                            x: new Date(item[dateKey[period]]).getTime(),
                            y: fabricantes[index][priceType === 'average' ? 'preco_medio' : 'preco_moda'],
                        }];
                    }
                } else if (item.fabricante) {
                    fabricantesObject[item.fabricante] = {
                        type: 'spline',
                        name: item.fabricante,
                        data: [],
                        yAxis: 1,
                        zIndex: 1,
                        color: seriesColors[index + 2],
                    };

                    fabricantesObject[item.fabricante].data = [...fabricantesObject[item.fabricante].data, {
                        x: new Date(item[dateKey]).getTime(),
                        y: fabricantes[index][priceType === 'average' ? 'preco_medio' : 'preco_moda'],
                    }];
                } else {
                    fabricantesObject['Sem informação de fabricante'] = {
                        type: 'spline',
                        name: 'Sem informação de fabricante',
                        data: [],
                        yAxis: 1,
                        zIndex: 1,
                        color: seriesColors[index + 2],
                    };

                    fabricantesObject['Sem informação de fabricante'].data = [...fabricantesObject['Sem informação de fabricante'].data, {
                        x: new Date(item[dateKey]).getTime(),
                        y: fabricantes[index][priceType === 'average' ? 'preco_medio' : 'preco_moda'],
                    }];
                }
            });

            let series = Object.values(fabricantesObject);
            series.forEach((item) => {
                item.data.sort((a, b) => new Date(a.x) - new Date(b.x));
            });
            series = period === 'DATA' ? [...series] : [...series, setStoreColumn(data.totalsResult), setChainColumn(data.totalsResult)];

            return series;
        }
        return [];
    };

    const setChainNameColumn = (data) => {
        if (visualizationType === 'rede' && data?.content?.some((item) => item.rede)) {
            const chains = [...data.content];
            const chainsObject = {};

            chains.forEach((item, index) => {
                if (chainsObject[item.rede]) {
                    chainsObject[chains[index].rede].data = [...chainsObject[chains[index].rede].data, {
                        x: new Date(item[dateKey[period]]).getTime(),
                        y: chains[index][priceType === 'average' ? 'preco_medio' : 'preco_moda'],
                    }];
                } else {
                    chainsObject[item.rede] = {
                        type: 'spline',
                        name: item.rede,
                        data: [],
                        yAxis: 1,
                        zIndex: 1,
                        color: seriesColors[index + 2],
                    };

                    chainsObject[item.rede].data = [...chainsObject[item.rede].data, {
                        x: new Date(item[dateKey[period]]).getTime(),
                        y: chains[index][priceType === 'average' ? 'preco_medio' : 'preco_moda'],
                    }];
                }
            });

            let series = Object.values(chainsObject);
            series.forEach((item) => {
                item.data.sort((a, b) => new Date(a.x) - new Date(b.x));
            });
            series = period === 'DATA' ? [...series] : [...series, setStoreColumn(data.totalsResult)];

            return series;
        }

        return [];
    };

    const setStoreTypeColumn = (data) => {
        if (visualizationType === 'tipoLoja' && data?.content?.some((item) => item.tipo_loja)) {
            const tiposLoja = [...data.content];
            const tiposLojaObject = {};

            tiposLoja.forEach((item, index) => {
                if (tiposLojaObject[item.tipo_loja]) {
                    tiposLojaObject[tiposLoja[index].tipo_loja].data = [...tiposLojaObject[tiposLoja[index].tipo_loja].data, {
                        x: new Date(item[dateKey[period]]).getTime(),
                        y: tiposLoja[index][priceType === 'average' ? 'preco_medio' : 'preco_moda'],
                    }];
                } else {
                    tiposLojaObject[item.tipo_loja] = {
                        type: 'spline',
                        name: item.tipo_loja,
                        data: [],
                        yAxis: 1,
                        zIndex: 1,
                        color: seriesColors[index + 2],
                    };
                    tiposLojaObject[item.tipo_loja].data = [...tiposLojaObject[item.tipo_loja].data, {
                        x: new Date(item[dateKey[period]]).getTime(),
                        y: tiposLoja[index][priceType === 'average' ? 'preco_medio' : 'preco_moda'],
                    }];
                }
            });

            let series = Object.values(tiposLojaObject);
            series.forEach((item) => {
                item.data.sort((a, b) => new Date(a.x) - new Date(b.x));
            });
            series = period === 'DATA' ? [...series] : [...series, setStoreColumn(data.totalsResult), setChainColumn(data.totalsResult)];

            return series;
        }

        return [];
    };

    const mapDataSeries = (data, type) => {
        switch (type) {
            case 'produto':
                return setProductColumn(data);
            case 'rede':
                return setChainNameColumn(data);
            case 'tipoLoja':
                return setStoreTypeColumn(data);
            case 'fabricante':
                return setMakerColumn(data);
            case 'semAgrupamento':
                return setNoGroup(data);
            default:
                return [];
        }
    };

    const changeVisualization = (type) => {
        const visualType = type === 'rede' || type === 'fabricante';
        const planType = userPlan === 'FREE' || userPlan === 'LITE';
        const limitType = userPlan === 'LIMIT' && products?.some((item) => item.limited === true);

        if ((visualType && planType) || limitType) {
            setVisualizationType(type);
            setHasPermition(false);

            if (userPlan === 'FREE') {
                setClickToLocalStorage('addBlockFree', 'clique-adblock', user);
            }
        } else {
            setVisualizationType(type);
            setHasPermition(true);
        }
    };

    const exportCSV = () => {
        if (timeEvolutionChartRef?.current?.chart) {
            timeEvolutionChartRef.current.chart.downloadCSV();
        }
    };

    const exportXLS = () => {
        if (timeEvolutionChartRef?.current?.chart) {
            timeEvolutionChartRef.current.chart.downloadXLS();
        }
    };

    const exportPNG = () => {
        if (timeEvolutionChartRef?.current?.chart) {
            timeEvolutionChartRef.current.chart.exportChart({ type: 'image/png' });
        }
    };

    const print = () => {
        if (timeEvolutionChartRef?.current?.chart) {
            timeEvolutionChartRef.current.chart.print();
        }
    };

    const viewFullScreen = () => {
        if (timeEvolutionChartRef?.current?.chart) {
            timeEvolutionChartRef.current.chart.fullscreen.toggle();
        }
    };

    useEffect(() => {
        if (chartData?.content?.length) {
            const newSeries = mapDataSeries(chartData, visualizationType);
            setChartOptions((prevState) => ({
                ...prevState,
                series: newSeries,
            }));
        }
    }, [chartData, priceType]);

    useEffect(() => {
        // getData(visualizationType, period);
        setChartOptions((prevState) => ({
            ...prevState,
            tooltip: {
                ...prevState.tooltip,
                xDateFormat: period === 'MES' ? '%m/%Y' : '%d/%m/%Y',
            },
            xAxis: {
                ...prevState.xAxis,
                labels: {
                    ...prevState.xAxis.labels,
                    rotation: period === 'SEMANA' ? -45 : period === 'DATA' ? -45 : 0,
                    format: period === 'MES' ? '{value: %B}' : '{value: %d/%m/%Y}',
                },
            },
        }));
    }, [period]);

    useEffect(() => {
        // getData(visualizationType, period);
        setChartOptions((prevState) => ({
            ...prevState,
            yAxis: [
                {
                    ...prevState.yAxis[0],
                    title: {
                        ...prevState.yAxis[0].title,
                        text: visualizationType === 'rede' ? 'QUANTIDADE DE LOJAS' : 'QUANTIDADE DE REDES E LOJAS',
                    },
                },
                { ...prevState.yAxis[1] },
            ],
        }));
    }, [visualizationType]);

    return (
        <Content className="time-evolution-chart">
            <FlexboxGrid className="header-area">
                <FlexboxGrid.Item componentClass={Col} lg={22} md={22} sm={17} xs={17} className={!expandTimeEvolution ? 'minimized' : null}>
                    <h4>EVOLUÇÃO TEMPORAL</h4>
                    <span className="header-area__info">
                        <Whisper
                            placement={window.innerWidth > 992 ? 'right' : 'bottom'}
                            trigger="hover"
                            speaker={(
                                <Tooltip>
                                    <p>
                                        O gráfico de evolução temporal exibe até 10 séries de preço por visualização,
                                        oferecendo uma melhor experiência para entendimento das informações.
                                        Caso não selecione um filtro de rede, serão exibidas as 10 redes com maior quantidade de dados.

                                    </p>
                                </Tooltip>
                            )}
                        >
                            <Icon className="tooltip-info" icon="info" />
                        </Whisper>
                    </span>
                </FlexboxGrid.Item>
                <FlexboxGrid.Item
                    componentClass={Col}
                    lg={1}
                    md={1}
                    sm={3}
                    xs={3}
                    className={!expandTimeEvolution ? 'expand-section minimized' : 'expand-section'}
                >
                    <Button
                        id="time-evolution-expand-btn"
                        appearance="subtle"
                        onClick={() => setExpandTimeEvolution(!expandTimeEvolution)}
                    >
                        {expandTimeEvolution ? <img alt="Minimizar" src={iconMinimize} /> : <img alt="Maximizar" src={iconMaximize} />}
                    </Button>
                </FlexboxGrid.Item>
                {(visualizationType === 'rede' || visualizationType === 'fabricante')
                    && (userPlan === 'LITE'
                        || (userPlan === 'LIMIT'
                            && products?.some((item) => item.limited === true)))
                    ? null

                    : (
                        <FlexboxGrid.Item
                            componentClass={Col}
                            lg={1}
                            md={1}
                            sm={3}
                            xs={3}
                            className={!expandTimeEvolution ? 'more-options-section minimized' : 'more-options-section'}
                        >
                            <Dropdown
                                id="time-evolution-more-options"
                                placement="bottomEnd"
                                renderTitle={
                                    () => (
                                        <IconButton
                                            className="more-options-section__btn"
                                            appearance="subtle"
                                            icon={<img alt="Mais opções" src={iconEllipsis} />}
                                        />
                                    )
                                }
                            >
                                <Dropdown.Item
                                    onClick={viewFullScreen}
                                    id="btn-time-evolution-full-screen"
                                >
                                    Ver em tela cheia

                                </Dropdown.Item>
                                {showExportOptions
                                    ? (
                                        <>
                                            <Dropdown.Item
                                                disabled={disableExport}
                                                onClick={print}
                                                id="btn-time-evolution-print"
                                            >
                                                Imprimir

                                            </Dropdown.Item>
                                            <Dropdown.Item divider />
                                            <Dropdown.Item
                                                disabled={disableExport}
                                                onClick={exportPNG}
                                                id="btn-time-evolution-download-png"
                                            >
                                                Download em PNG

                                            </Dropdown.Item>
                                            <Dropdown.Item
                                                disabled={disableExport}
                                                onClick={exportCSV}
                                                id="btn-time-evolution-download-csv"
                                            >
                                                Download em CSV

                                            </Dropdown.Item>
                                            <Dropdown.Item
                                                disabled={disableExport}
                                                onClick={exportXLS}
                                                id="btn-time-evolution-download-xls"
                                            >
                                                Download em XLS

                                            </Dropdown.Item>
                                        </>
                                    ) : null}
                            </Dropdown>
                        </FlexboxGrid.Item>
                    )}
            </FlexboxGrid>
            {expandTimeEvolution
                ? (
                    <>
                        <FlexboxGrid>
                            <FlexboxGrid.Item componentClass={Col} lg={13} md={13} xsHidden smHidden className="select-visualization-section">
                                <span>AGRUPAR POR: </span>
                                <Button
                                    className="select-visualization-section__button"
                                    appearance={visualizationType === 'produto' ? 'primary' : 'subtle'}
                                    onClick={() => changeVisualization('produto')}
                                    disabled={disabledTypeOptions}
                                >
                                    Produto
                                </Button>
                                <Button
                                    className="select-visualization-section__button"
                                    appearance={visualizationType === 'tipoLoja' ? 'primary' : 'subtle'}
                                    onClick={() => changeVisualization('tipoLoja')}
                                    disabled={disabledTypeOptions}
                                >
                                    Tipo de loja
                                </Button>
                                <Button
                                    className="select-visualization-section__button"
                                    appearance={visualizationType === 'rede' ? 'primary' : 'subtle'}
                                    onClick={() => changeVisualization('rede')}
                                    disabled={disabledTypeOptions}
                                >
                                    Rede
                                </Button>
                                <Button
                                    className="select-visualization-section__button"
                                    appearance={visualizationType === 'fabricante' ? 'primary' : 'subtle'}
                                    onClick={() => changeVisualization('fabricante')}
                                    disabled={disabledTypeOptions}
                                >
                                    Fabricante
                                </Button>
                                <Button
                                    className="select-visualization-section__button"
                                    appearance={visualizationType === 'semAgrupamento' ? 'primary' : 'subtle'}
                                    onClick={() => changeVisualization('semAgrupamento')}
                                    disabled={disabledTypeOptions}
                                >
                                    Consolidado
                                </Button>
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item componentClass={Col} lg={7} md={7} xsHidden smHidden className="select-price-section">
                                <span>EXIBIR PREÇO: </span>
                                <Button
                                    className="select-price-section__button"
                                    appearance={priceType === 'average' ? 'primary' : 'subtle'}
                                    onClick={() => setPriceType('average')}
                                >
                                    Médio
                                </Button>
                                <Button
                                    className="select-price-section__button"
                                    appearance={priceType === 'frequent' ? 'primary' : 'subtle'}
                                    onClick={() => setPriceType('frequent')}
                                >
                                    Mais frequente
                                </Button>
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item componentClass={Col} lg={4} md={4} xsHidden smHidden className="select-periodo-section">
                                <span>PERÍODO: </span>
                                <SelectPicker
                                    appearance="subtle"
                                    onChange={changePeriod}
                                    searchable={false}
                                    data={periodoOptions}
                                    value={period}
                                    cleanable={false}
                                    disabled={disabledPeriodSelect}
                                />
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item componentClass={Col} xs={24} sm={24} lgHidden mdHidden className="select-visualization-section">
                                <IconButton
                                    className="select-visualization-section__dropdown"
                                    icon={<Icon icon="angle-down" />}
                                    onClick={() => setOpenDialog(true)}
                                    placement="right"
                                >
                                    Agrupar por:
                                    {' '}
                                    <span>
                                        {visualizationType === 'produto' ? 'Produto'
                                            : visualizationType === 'tipoLoja' ? 'Tipo de Loja'
                                                : visualizationType === 'rede' ? 'Rede'
                                                    : visualizationType === 'fabricante' ? 'Fabricante'
                                                        : visualizationType === 'semAgrupamento' ? 'Sem agrupamento' : null}

                                    </span>
                                </IconButton>
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                        {visualizationType === 'produto' && products?.length > 10 ? (
                            <FlexboxGrid className="warning-visualization">
                                <FlexboxGrid.Item componentClass={Col} lg={24} md={24} sm={24} xs={24}>
                                    <p>Este gráfico pode mostrar no máximo 10 produtos simultaneamente para melhor visualização</p>
                                </FlexboxGrid.Item>
                            </FlexboxGrid>
                        ) : null}
                        {visualizationType === 'rede' && chains.length > 10 ? (
                            <FlexboxGrid>
                                <FlexboxGrid.Item componentClass={Col} lg={24} md={24} sm={24} xs={24} className="warning-visualization">
                                    <p>Este gráfico pode mostrar no máximo 10 redes simultaneamente para melhor visualização</p>
                                </FlexboxGrid.Item>
                            </FlexboxGrid>
                        ) : null}
                        <FlexboxGrid>
                            {hasPermition
                                ? (
                                    <FlexboxGrid.Item componentClass={Col} lg={24} md={24} sm={24} xs={24} id="chart-area" className="chart-area">
                                        {chartData?.content?.length
                                            ? (
                                                <HighchartsReact
                                                    ref={timeEvolutionChartRef}
                                                    highcharts={Highcharts}
                                                    options={chartOptions}
                                                />
                                            )
                                            : (
                                                <EmptyState
                                                    title="Ops! Nenhum resultado encontrado"
                                                    message="Sua busca não retornou nenhuma informação. Altere os filtros e tente novamente."
                                                    image={noDataImage}
                                                />
                                            )}
                                    </FlexboxGrid.Item>
                                )
                                : (
                                    <FlexboxGrid.Item className="no-access" componentClass={Col} lg={24} md={24} sm={24} xs={24}>
                                        <div className="no-access__main">
                                            <div>
                                                <img alt="Cadeado" className="no-access__icon" src={lockIcon} />
                                            </div>
                                            <div className="no-access__message">
                                                <p>
                                                    {userPlan === 'FREE'
                                                        ? (
                                                            <span>
                                                                Este conteúdo pode ser acessado somente por assinantes.
                                                            </span>
                                                        )
                                                        : (
                                                            <span>
                                                                Este conteúdo só pode ser acessado por assinantes do plano
                                                                {' '}
                                                                <b>Flex</b>
                                                                {' '}
                                                                (para produtos escolhidos) ou
                                                                {' '}
                                                                <b>Pro</b>
                                                            </span>
                                                        )}
                                                </p>
                                            </div>
                                            {showCTA ? (
                                                <div>
                                                    <Button
                                                        appearance="primary"
                                                        onClick={() => setShowCTA(true)}
                                                    >
                                                        {userPlan === 'FREE'
                                                            ? <span>Quero ser assinante</span>
                                                            : <span>Entrar em contato</span>}
                                                    </Button>
                                                </div>
                                            ) : null}
                                        </div>
                                    </FlexboxGrid.Item>
                                )}
                        </FlexboxGrid>
                    </>
                ) : null}
            <ModalConfirmation
                title="Informações para alteração do plano"
                message="Caso deseje saber mais informações para evoluir o seu plano, clique em “Confirmar”"
                show={showCTA}
                btnConfirmClass="upsell"
                buttonConfirm="Confirmar"
                buttonCancel="Cancelar"
                onConfirm={sendCTA}
                onCancel={() => setShowCTA(false)}
            />
            <MobileSelectOptionComponent
                open={openDialog}
                handleClose={() => setOpenDialog(false)}
                options={optionsGroupBy}
                optionSelected={visualizationType}
                handleSelect={changeVisualization}
            />
        </Content>
    );
};

export default STimeEvolutionChart;
