import { trackPromise } from 'react-promise-tracker';

import api from '../../../../utils/API';

export const getRelatoriosCliente = (codigoCliente) => trackPromise(
    api({
        url: `${process.env.REACT_APP_RELATORIO_URL}/relatorio/envio`,
        method: 'GET',
        params: {
            codigoCliente,
        },
        expectToken: true,
    }).then((resp) => resp.data),
);
