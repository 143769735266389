import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export type GerenciadorPrecosModals = 'salvarFiltros' | 'excluirFiltro';

export type ModalProps = Record<string, unknown> & {
    isOpen: boolean;
    data?: Record<string, unknown>;
};

export type GerenciadorPrecosModalsStateProps = Record<
    GerenciadorPrecosModals,
    ModalProps
>;

const initialState: GerenciadorPrecosModalsStateProps = {
    salvarFiltros: {
        isOpen: false,
    },
    excluirFiltro: {
        isOpen: false,
    },
};

const slice = createSlice({
    name: 'filtros-state',
    initialState,
    reducers: {
        openModal(
            state,
            {
                payload: { name },
            }: PayloadAction<{ name: GerenciadorPrecosModals }>,
        ) {
            state[name].isOpen = true;
        },
        closeModal(
            state,
            { payload }: PayloadAction<{ name: GerenciadorPrecosModals }>,
        ) {
            state[payload.name].isOpen = false;
        },
    },
});

const { openModal, closeModal } = slice.actions;

export { closeModal, openModal };

export default slice.reducer;
