import { format, parseISO } from 'date-fns';
import React from 'react';
import BaseCell, { BaseCellProps } from '../BaseCell/BaseCell';
import styles from './LastUpdateCell.module.scss';

const { MainContainer, SubContainer } = BaseCell;

type LastUpdateCellProps = BaseCellProps & {
    rowData: {
        lastEditedBy: string | null;
        lastEditedDate: string | null;
    };
};

const LastUpdateCell = ({ rowData, ...props }: LastUpdateCellProps) => {
    const { lastEditedBy, lastEditedDate } = rowData;

    return (
        <BaseCell {...props}>
            <MainContainer className={styles.last_edited}>
                {lastEditedBy ? (lastEditedBy as string) : '--'}
            </MainContainer>
            <SubContainer>
                {lastEditedDate
                    ? format(parseISO(lastEditedDate as string), 'dd/MM/yyyy')
                    : '--'}
            </SubContainer>
        </BaseCell>
    );
};

export default LastUpdateCell;
