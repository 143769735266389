import React, { forwardRef } from 'react';
import { Radio as RSuiteRadio } from 'rsuite';
import { RadioProps as RSuiteRadioProps } from 'rsuite/lib/Radio';
import './style.scss';

export type RadioProps = RSuiteRadioProps & {
    isSelected?: boolean;
};

export const Radio = forwardRef<HTMLDivElement, RadioProps>(
    ({ className = '', isSelected = false, ...props }, ref) => (
        <RSuiteRadio
            ref={ref}
            className={[
                'info-radio',
                isSelected ? 'info-radio-is-selected' : '',
                className,
            ].join(' ')}
            {...props}
        />
    ),
);
