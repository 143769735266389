import { useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import * as PainelGeralService from '../../InfoPanel/services';
import { getModel } from '../utils';

export const filtrosDataType = {
    Categoria: 'categoria',
    Fabricante: 'fabricante',
    Marca: 'marca',
    Seção: 'secao',
    Produto: 'produto',
    'Tipo de produto': 'tipoProduto',
    'Tipo de Loja': 'tipoLoja',
    'Tipo promoção': 'tipoPromocao',
    'Flag promoção': 'flagPromocao',
    Cidade: 'cidade',
    'CNPJ da Loja': 'cnpjLoja',
    'Origem do preço': 'origemPreco',
    UF: 'uf',
    Loja: 'loja',
    Rede: 'rede',
    Região: 'regiao',
};

const data = {
    rede: {
        referencia: {
            lista: [],
            value: [],
            cache: [],
            erro: null,
        },
        competidoras: {
            lista: [],
            value: [],
            cache: [],
            erro: null,
        },
    },
};

export const useDoubleRequestList = (name) => {
    const [filtrosDoubleData, setFiltrosDoubleData] = useState(data);
    const [timer, setTimer] = useState(null);

    const { filtrosDataCompetitividade } = useSelector(
        (state) => ({
            filtrosDataCompetitividade:
                state.analiseCompetitividadeDataReducer
                    .filtrosDataCompetitividade,
        }),
        shallowEqual,
    );

    const setList = (list, name, listType) => {
        const newList = list.map((item) => ({
            label: item?.key,
            value: item?.key,
            datapoints: item?.datapoints,
        }));

        const cacheList = filtrosDoubleData[name][listType].cache;
        const removeDuplicate = newList.filter(
            (i) => !filtrosDoubleData[name][listType].value.includes(i.value),
        );
        const concatList = filtrosDoubleData[name][listType].value.length
            ? cacheList.concat(removeDuplicate)
            : newList;

        setFiltrosDoubleData((prevState) => ({
            ...prevState,
            [name]: {
                ...prevState[name],
                [listType]: {
                    ...prevState[name][listType],
                    lista: concatList,
                },
            },
        }));
    };

    const getDoubleListFilter = (params, name, listType) => {
        PainelGeralService.getItensFiltros(params).then((data) => {
            if (data) {
                setList(data, name, listType);
            }
        });
    };

    const doubleListData = filtrosDoubleData[filtrosDataType[name]];

    const handleGetDoubleList = (name, type) => {
        getDoubleListFilter(
            getModel(filtrosDataCompetitividade, '', filtrosDataType[name]),
            filtrosDataType[name],
            type,
        );
    };

    const handleSearchDoubleList = (inputValue, name, type) => {
        clearTimeout(timer);

        const newTimer = setTimeout(() => {
            getDoubleListFilter(
                getModel(
                    filtrosDataCompetitividade,
                    inputValue,
                    filtrosDataType[name],
                ),
                filtrosDataType[name],
                type,
            );
        }, 500);

        setTimer(newTimer);
    };

    const handleSelectDoubleItem = (name, item, listType) => {
        setFiltrosDoubleData((prevState) => ({
            ...prevState,
            [name]: {
                ...prevState[name],
                [listType]: {
                    ...prevState[name][listType],
                    value: [...prevState[name][listType].value, item.value],
                    cache: [...prevState[name][listType].cache, item],
                },
            },
        }));
    };

    const handleSelectReferenceDoubleItem = (name, item) => {
        setFiltrosDoubleData((prevState) => ({
            ...prevState,
            [name]: {
                ...prevState[name],
                referencia: {
                    ...prevState[name].referencia,
                    value: [item.value],
                    cache: [item],
                },
            },
        }));
    };

    const handleRemoveDoubleItem = (name, item) => {
        const hasValue = doubleListData.competidoras.value.includes(item.value);
        const removeItemValue = doubleListData.competidoras.value.filter(
            (i) => i !== item.value,
        );
        const removeItemCache = doubleListData.competidoras.cache.filter(
            (i) => i.label !== item.value,
        );

        if (hasValue) {
            setFiltrosDoubleData((prevState) => ({
                ...prevState,
                [name]: {
                    ...prevState[name],
                    competidoras: {
                        ...prevState[name].competidoras,
                        value: removeItemValue,
                        cache: removeItemCache,
                    },
                },
            }));
        }
    };

    const handleCleanDoubleItem = (type) => {
        setFiltrosDoubleData((prevState) => ({
            ...prevState,
            rede: {
                ...prevState.rede,
                [type]: {
                    ...prevState.rede[type],
                    value: [],
                    cache: [],
                },
            },
        }));
    };

    const handleCleanAllDoubleSelects = () => {
        setFiltrosDoubleData({
            rede: {
                referencia: {
                    value: [],
                    cache: [],
                },
                competidoras: {
                    value: [],
                    cache: [],
                },
            },
        });
    };

    const handleSetSavedDoubleFilters = (object) => {
        const listReference = [
            {
                label: object.redeReferencia,
                value: object.redeReferencia,
            },
        ];

        const listCompetitors = object.redesCompetidoras?.map((item) => ({
            label: item,
            value: item,
        }));

        const redeReferenciaValue =
            object.redeReferencia === null || object.redeReferencia === ''
                ? []
                : [object.redeReferencia];

        setFiltrosDoubleData((prevState) => ({
            ...prevState,
            rede: {
                ...prevState.rede,
                referencia: {
                    ...prevState.rede.referencia,
                    lista: listReference,
                    cache: listReference,
                    value: redeReferenciaValue,
                },
                competidoras: {
                    ...prevState.rede.competidoras,
                    lista: listCompetitors,
                    cache: listCompetitors,
                    value: object.redesCompetidoras,
                },
            },
        }));
    };

    return {
        stateDoubleList: {
            doubleListData,
        },
        handlersDoubleList: {
            handleGetDoubleList,
            handleSearchDoubleList,
            getDoubleListFilter,
            handleRemoveDoubleItem,
            handleSelectDoubleItem,
            handleCleanDoubleItem,
            handleSetSavedDoubleFilters,
            setFiltrosDoubleData,
            handleCleanAllDoubleSelects,
            handleSelectReferenceDoubleItem,
        },
    };
};
