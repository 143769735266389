import { shallowEqual, useSelector } from 'react-redux';
import { useQuery } from '@tanstack/react-query';
import { getSaveReportLineCounter } from '../services';

export const useSaveReportCounter = () => {
    const {
        moduloRelatorioData,
    } = useSelector(
        (state) => ({
            moduloRelatorioData:
                state.moduloRelatorioDataReducer?.moduloRelatorioData,
        }),
        shallowEqual,
    );

    const generateDataBody = {
        ...moduloRelatorioData,
        recorrencia: false,
        relatorioSalvo: false,
    };

    const counter = async () => {
        const resp = await getSaveReportLineCounter(generateDataBody);
        return resp;
    };

    const {
        isLoading, isError, data, error, isFetching, refetch,
    } = useQuery(
        ['counter'],
        counter,
        {
            enabled: false,
            retry: false,
        },
    );

    return {
        isLoading,
        isError,
        dataCounter: data,
        error,
        isFetching,
        refetchCounter: refetch,
    };
};
