import React, { ReactNode } from 'react';
import styles from './styles.module.scss';

export interface IBoxRoot {
    children: ReactNode;
    marginRight?: string;
    type?: 'container' | 'up' | 'down';
    height?: string;
}

export const BoxRoot = ({ children, type = 'container', marginRight = '8px', height }: IBoxRoot) => (
    <div
        className={styles[type]}
        style={{ marginRight, height }}
    >
        {children}
    </div>
);
