import Immutable from 'seamless-immutable';
import { SET_PERMISSOES } from '../actions/actionsUsuario'

const usuarioInitialState = Immutable({
    permissoes: [],
    servicoBi: null,
    servicoPainel: null,
    servicoIra: null
})


function usuarioDataReducer(state = usuarioInitialState, action) {
    switch (action.type) {
        case SET_PERMISSOES:
            return state.merge({
                permissoes: action.permissoes,
                servicoBi: action.servicoBi,
                servicoIra: action.servicoIra,
                servicoPainel: action.servicoPainel,
                servicoColeta: action.servicoColeta
            })
        default:
            return state
    }
}

export default usuarioDataReducer;