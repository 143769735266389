import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { Content, Loader } from 'rsuite';
// @ts-ignore
import { useMediaCompetitividadeGeral } from '../../../hooks/useMediaCompetitividadeGeral.tsx';

export const CompetitividadeMediaGeral: React.FC = () => {
    const { graficoCompetitividadeMediaGeral, data, isLoading } =
        useMediaCompetitividadeGeral();

    if (isLoading)
        return (
            <Content className="grafico-competitividade-media-geral-loading">
                <Loader center />
            </Content>
        );

    if (!data)
        return (
            <Content className="grafico-competitividade-media-geral">
                <h2>Competitividade média geral</h2>
                <div className="sem-dados">
                    <h3>Sem dados para essa análise</h3>
                    <p>Revise os filtros e tente novamente</p>
                </div>
            </Content>
        );

    return (
        <Content>
            <div className="grafico-competitividade-media-geral">
                <h2>Competitividade média geral</h2>
                <HighchartsReact
                    highcharts={Highcharts}
                    options={graficoCompetitividadeMediaGeral}
                />
            </div>
        </Content>
    );
};
