import { useContext, useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { format, parseISO } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import { getCompetitividadeMediaPorItensPesquisados } from '../services';
// @ts-ignore
import { FiltrosContext } from '../Pages/CompetitividadeGeral/index.tsx';

export const useCompetitividadeMediaItensPesquisados = () => {
    const filtros = useContext(FiltrosContext);

    const [
        graficoCompetitividadeMediaGeral,
        setGraficoCompetitividadeMediaGeral,
    ] = useState({});

    const listCompetitividadeMediaItensPesquisados = async () => {
        const params = {
            filters: [
                {
                    filter: 'STOREID',
                    values: filtros?.lojasSelecionadas || null,
                },
                {
                    filter: 'PRODUCTID',
                    values: filtros?.produtosSelecionados || null,
                },
                {
                    filter: 'STARTDATE',
                    values: filtros?.periodoSelecionado?.length
                        ? [format(filtros.periodoSelecionado[0], 'yyyy-MM-dd')]
                        : null,
                },
                {
                    filter: 'ENDDATE',
                    values: filtros?.periodoSelecionado?.length
                        ? [format(filtros.periodoSelecionado[1], 'yyyy-MM-dd')]
                        : null,
                },
                {
                    filter: 'STORETYPE',
                    values: filtros?.tipoLojasSelecionadas || null,
                },
                {
                    filter: 'CATEGORYLEVEL1',
                    values: filtros?.categoriaNivel1Selecionadas || null,
                },
                {
                    filter: 'CATEGORYLEVEL2',
                    values: filtros?.categoriaNivel2Selecionadas || null,
                },
                {
                    filter: 'COMPETITORSTOREID',
                    values: filtros?.competitividadeSelecionada || null,
                },
            ],
        };
        const resp = await getCompetitividadeMediaPorItensPesquisados(params);
        if (resp.length) {
            const dateWeek = Object.keys(resp[0])[0]?.split('.');
            const avg_average_competitivity_retail = Object.keys(
                resp[0]
            )[1]?.split('.');
            const count = Object.keys(resp[0])[2]?.split('.');
            return {
                [dateWeek[1]]: resp.map((item: { [x: string]: any }) =>
                    format(parseISO(item[Object.keys(item)[0]]), 'dd MMM. yyyy', {
                        locale: ptBR,
                    })
                ),
                [avg_average_competitivity_retail[1]]: resp.map(
                    (item: { [x: string]: any }) =>
                        parseFloat(
                            // @ts-ignore
                            Number(
                                parseFloat(item[Object.keys(item)[1]]) * 100
                            ).toFixed(2)
                        )
                ),
                [count[1]]: resp.map(
                    (item: { [x: string]: any }) => +item[Object.keys(item)[2]]
                ),
            };
        }
    };

    const { isLoading, isError, data, error } = useQuery(
        ['competitividadeMediaItensPesquisados', filtros],
        listCompetitividadeMediaItensPesquisados,
        {
            retry: false,
            cacheTime: 6 * 180 * 1000,
            staleTime: 6 * 180 * 1000,
        }
    );

    useEffect(() => {
        const avg_average_competitivity_retail =
            data?.avg_average_competitivity_retail;

        const count = data?.count;

        const dateWeek = data?.dateWeek;

        setGraficoCompetitividadeMediaGeral({
            exporting: {
                enabled: false,
            },
            chart: {
                backgroundColor: 'transparent',
                height: 369,
            },
            title: {
                text: '',
            },
            xAxis: [
                {
                    categories: dateWeek,
                    crosshair: true,
                },
            ],
            yAxis: [
                {
                    // Primary yAxis
                    labels: {
                        format: '{value}%',
                    },
                    title: {
                        text: 'Competitividade',
                    },
                },
                {
                    // Secondary yAxis
                    title: {
                        text: 'Itens pesquisados',
                    },
                    labels: {
                        formatter: function () {
                            return String(this.value)?.replace(
                                /\B(?=(\d{3})+(?!\d))/g,
                                '.'
                            );
                        },
                    },
                    opposite: true,
                },
            ],
            tooltip: {
                shared: true,
            },
            series: [
                {
                    name: 'Itens pesquisados',
                    type: 'column',
                    yAxis: 1,
                    data: count,
                    color: '#959595',
                },
                {
                    name: 'Competitividade',
                    type: 'spline',
                    data: avg_average_competitivity_retail,
                    tooltip: {
                        valueSuffix: '%',
                    },
                    color: '#0D66D0',
                },
            ],
        });
    }, [data]);

    return {
        isLoading,
        isError,
        data,
        graficoCompetitividadeMediaGeral,
        error,
    };
};
