import { combineReducers } from 'redux';
import datapoints from './datapoints';
import filters from './filters/root';
import modals from './modals';
import pagination from './pagination';
import selectedDatapoints from './selectedDatapoints';
import sort from './sort';
import totalElements from './totalElements';

const rootReducer = combineReducers({
    datapoints,
    totalElements,
    selectedDatapoints,
    sort,
    pagination,
    filters,
    modals,
});

export type PrecoAtacadoStateProps = ReturnType<typeof rootReducer>;

export default rootReducer;
