import React from 'react';
import { Radio } from 'rsuite';
import { RadioProps } from 'rsuite/lib/Radio';
import './style.scss';

export type InfoRadioProps = Exclude<RadioProps, 'classPrefix'>;

export const InfoRadio = ({
    className = '',
    children,
    ...props
}: InfoRadioProps) => (
    <Radio
        className={['info-radio', className].join(' ')}
        classPrefix="info-radio"
        {...props}
    >
        {children}
    </Radio>
);
