import { api } from '../../../../../../../lib';
import { EditRuleCompetitorsApi } from './EditCompetitors.types';
import { PARSE_RAW_RESPONSE } from './EditRuleCompetitorsModalTable';

export const GET_EDIT_COMPETITORS_MODAL_DATA = async (
    params: {
        sort: string;
        size: number;
        page: number;
    },
    data: { storeId: string[] },
): Promise<EditRuleCompetitorsApi['response']> => {
    const response = await api.post<EditRuleCompetitorsApi['rawResponse']>(
        '/competitors/rule/stores',
        data,
        {
            expectToken: true,
            params,
        },
    );
    return PARSE_RAW_RESPONSE(response.data);
};
