import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { GerenciadorPrecos } from '../../../@types/GerenciadorPrecos';
import { RootState } from '../../../@types/RootState';

type Keys = GerenciadorPrecos.FilterKeys;

type SegmentoValue = {
    type: string;
    value: string;
};

export type FiltersValueState = Partial<
    Record<Exclude<Keys, 'segmentos'>, string[]> &
        Record<'segmentos', SegmentoValue[]>
>;

const initialState: FiltersValueState = {};

const slice = createSlice({
    name: 'ipa.filters.value',
    initialState,
    reducers: {
        SET_FILTERS_VALUE: (_, action: PayloadAction<FiltersValueState>) => {
            return action.payload;
        },
        SET_FILTERS_VALUE_BY_KEY: (
            state,
            {
                payload,
            }: PayloadAction<{
                key: Keys;
                value: string[];
            }>,
        ) => {
            return {
                ...state,
                [payload.key]: payload.value,
            };
        },
        UPDATE_FILTERS_VALUE_BY_KEY: (
            state,
            {
                payload,
            }: PayloadAction<{
                key: Keys;
                value: string[];
            }>,
        ) => {
            return {
                ...state,
                [payload.key]: [
                    ...(state[payload.key] ?? []),
                    ...payload.value,
                ],
            };
        },
        RESET_FILTERS_VALUE: () => {
            return initialState;
        },
        RESET_FILTERS_VALUE_BY_KEY: (
            state,
            {
                payload,
            }: PayloadAction<{
                key: Keys;
            }>,
        ) => {
            return {
                ...state,
                [payload.key]: [],
            };
        },
        ADD_SEGMENT_VALUE: (
            state,
            { payload }: PayloadAction<SegmentoValue[]>,
        ) => {
            return {
                ...state,
                segmentos: [...(state.segmentos ?? []), ...payload],
            };
        },
    },
});

const selectorFiltersValues = (state: RootState) => {
    return state.gerenciadorPrecosReducer.filters.values;
};

const {
    SET_FILTERS_VALUE,
    SET_FILTERS_VALUE_BY_KEY,
    UPDATE_FILTERS_VALUE_BY_KEY,
    RESET_FILTERS_VALUE,
    RESET_FILTERS_VALUE_BY_KEY,
    ADD_SEGMENT_VALUE,
} = slice.actions;

export {
    ADD_SEGMENT_VALUE,
    RESET_FILTERS_VALUE,
    RESET_FILTERS_VALUE_BY_KEY,
    SET_FILTERS_VALUE,
    SET_FILTERS_VALUE_BY_KEY,
    UPDATE_FILTERS_VALUE_BY_KEY,
    initialState,
    selectorFiltersValues,
};

export default slice.reducer;
