import React, { ReactNode } from 'react';
import { Button, Modal, Whisper, Tooltip } from 'rsuite';
import styles from './styles.module.scss';

export interface IModalOnDemandPlan {
    onCancel():void;
    onConfirm():void;
    show: boolean;
    contentType: boolean;
    content1: ReactNode;
    content2: ReactNode;
    canSave: boolean;
    canEdit: boolean;
}

export const ModalEditRequest = ({
    show,
    onCancel,
    onConfirm,
    content1,
    content2,
    contentType,
    canSave,
    canEdit,
}: IModalOnDemandPlan) => (
    <Modal
        overflow={false}
        backdrop="static"
        show={show}
        className={styles['modal-container']}
        onHide={onCancel}
    >
        <Modal.Header closeButton />
        {contentType ? content1 : content2}

        <Modal.Footer>
            <Button onClick={onCancel} appearance="ghost">
                Fechar
            </Button>
            <Whisper
                delayShow={0.2}
                trigger={[canEdit ? 'hover' : 'none']}
                placement="top"
                speaker={
                    <Tooltip>
                        Edição permitida a partir de 7 dias de solicitação
                    </Tooltip>
                }
            >
                <Button
                    appearance="primary"
                    disabled={contentType ? !canSave : canEdit}
                    onClick={onConfirm}
                >
                    {contentType ? 'Salvar' : 'Editar solicitação'}
                </Button>
            </Whisper>
        </Modal.Footer>
    </Modal>
);
