import classNames from 'classnames';
import _ from 'lodash';
import React, { memo, useMemo } from 'react';
import { Whisper } from 'rsuite';
import { GerenciadorPrecos } from '../../../../../../@types/GerenciadorPrecos';
import {
    InputAddon,
    InputCurrency,
    InputGroup,
} from '../../../../../../components';
import { InputCurrencyProps } from '../../../../../../components/InputCurrency/InputCurrency';
import {
    isBrokenedPriceList,
    isChangedPrice,
    IPAMaths as m,
} from '../../../../../../lib';
import { Tag } from '../../../../Lite';
import { VariationBoxIndicator } from '../../../../Negociacoes/pages/NegociacaoFornecedor/components';
import { QuebraLimitesInfo } from '../../QuebraLimitesInfo';
import BaseCell, { BaseCellWithUpdateCampoProps } from '../BaseCell/BaseCell';
import styles from './PrecoSugeridoCell.module.scss';

type RowData = GerenciadorPrecos.RowData;

export type PrecoSugeridoCellProps = BaseCellWithUpdateCampoProps;

const getSkin = (rowData: RowData): InputCurrencyProps['skin'] => {
    if (isBrokenedPriceList(rowData)) return 'red';
    if (isChangedPrice(rowData)) return 'blue';
    return 'gray';
};

const PrecoSugeridoCell = ({
    className,
    dataKey,
    rowData,
    index,
    onBlur,
    handleBlur,
    handleContextMenu,
    handleUpdateCampo,
    ...props
}: PrecoSugeridoCellProps) => {
    const { priceType, price, retailPrice } = rowData;

    const priceVariation = useMemo(() => {
        return m.variation.price(price, retailPrice);
    }, [price, retailPrice]);

    return (
        <BaseCell
            handleContextMenu={handleContextMenu}
            rowData={rowData}
            className={classNames(className)}
        >
            <BaseCell.MainContainer>
                <InputGroup>
                    <Whisper
                        trigger="click"
                        placement="bottomEnd"
                        // @ts-ignore
                        speaker={<QuebraLimitesInfo content={rowData} />}
                    >
                        <InputAddon>R$</InputAddon>
                    </Whisper>
                    <InputCurrency
                        name={dataKey}
                        className="novo-preco"
                        decimalSeparator=","
                        thousandSeparator="."
                        precision="2"
                        allowEmpty
                        allowNegative={false}
                        value={price}
                        onChangeEvent={(_e, _m, v) => {
                            handleUpdateCampo(dataKey, v, rowData, index);
                        }}
                        skin={getSkin(rowData)}
                        onBlur={(e) => {
                            onBlur?.(e);
                            handleBlur(rowData, index);
                        }}
                        {..._.omit(props, 'value')}
                    />
                </InputGroup>
            </BaseCell.MainContainer>
            <BaseCell.SubContainer>
                {!!price && !!retailPrice ? (
                    <VariationBoxIndicator value={priceVariation} suffix="%" />
                ) : (
                    <>--</>
                )}
                <Tag className={styles.tag} skin="blue" size="xsmall">
                    {priceType}
                </Tag>
            </BaseCell.SubContainer>
        </BaseCell>
    );
};

export default memo(PrecoSugeridoCell);
