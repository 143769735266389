import classNames from 'classnames';
import React, { HtmlHTMLAttributes } from 'react';
import { Icon, IconButton } from 'rsuite';
import { Layout } from '../../../Lite';
import {
    ExcluirNegociacaoModal,
    FecharNegocioModalModal,
    ReabrirNegociacaoModalModal,
} from '../../components';
import { SalvarNegociacaoModal as EditNegociacaoModal } from '../../components/Modals';
import { TableFornecedor } from '../NegociacaoFornecedor/components';
import {
    DetalhesDaNegociacaoHeading,
    DetalhesDaNegociacaoTableHeading,
} from '../NegociacoesSalvas/components';
import { useDetalhesDaNegociacao } from './DetalhesDaNegociacao.hooks';
import styles from './DetalhesDaNegociacao.module.scss';

export type DetalhesDaNegociacao = HtmlHTMLAttributes<HTMLDivElement> & {
    match: {
        params?: Record<string, unknown>;
    };
};

export const DetalhesDaNegociacao = ({ className }: DetalhesDaNegociacao) => {
    const { handleNavBack, table, heading, tableHeading, modals } =
        useDetalhesDaNegociacao();

    return (
        <>
            <Layout
                className={classNames(
                    styles['detalhes-da-negociacao'],
                    className,
                )}
            >
                <Layout.Header
                    className={styles['detalhes-da-negociacao__header']}
                >
                    <div>
                        <IconButton
                            onClick={handleNavBack}
                            icon={<Icon icon="back-arrow" />}
                        />
                        Voltar para negociações salvas
                    </div>
                    <p>As alterações são salvas automaticamente </p>
                </Layout.Header>
                <DetalhesDaNegociacaoHeading {...heading} />
                <DetalhesDaNegociacaoTableHeading {...tableHeading} />
                <TableFornecedor {...table} />
            </Layout>
            <>
                <ExcluirNegociacaoModal {...modals.excluirNegociacao} />
                <FecharNegocioModalModal {...modals.fecharNegocio} />
                <ReabrirNegociacaoModalModal {...modals.reabrirNegocio} />
                <EditNegociacaoModal {...modals.editarNegociacao} />
            </>
        </>
    );
};
