import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    SavedFiltersNameProps,
    addSavedFilterState,
    setSavedFilterList,
    setSavedFilterRequestBody,
} from '../../../reducers/calculadoraPrevisaoDemanda/savedFiltros';
import {
    selectorCalculadoraPrevisaoDemanda,
} from '../../../reducers/calculadoraPrevisaoDemanda/calculadoraPrevisaoDemandaReducer';
import { useTableActions } from './useTableActions';
import * as service from '../services';

export const useSavedSelectFilters = () => {
    const [timer, setTimer] = useState(null);

    const dispatch = useDispatch();

    const { savedFiltros } = useSelector(
        selectorCalculadoraPrevisaoDemanda,
    );

    const { tableHandlers } = useTableActions();

    const params = { page: 0 };

    const setList = (name: SavedFiltersNameProps, list) => {
        const newList = list.map((item) => ({
            value: item.key,
            label: item.value,
        }));

        const cacheList = savedFiltros[name].cache;
        const removeDuplicate = newList.filter(
            (i) => !savedFiltros[name].value.includes(i.value),
        );
        const concatList = savedFiltros[name].value.length
            ? cacheList.concat(removeDuplicate)
            : newList;

        dispatch(setSavedFilterList({ name, list: concatList }));
    };

    const handleSelectSavedFilter = (name: SavedFiltersNameProps, value: string, item: any) => {
        dispatch(setSavedFilterRequestBody({
            name: 'query',
            data: {
                ...savedFiltros.savedFilterRequestBody?.query,
                [name]: value,
            },
        }));
        dispatch(addSavedFilterState({
            name,
            data: {
                cache: [item],
                value: [value],
            },
        }));
    };

    const searchSavedFilters = async (name: SavedFiltersNameProps, query: string) => {
        const requestType = {
            regional: service.getRegional,
            uf: service.getLocationUF,
            channel: service.getChannel,
            responsible: service.getResponsibles,
        };

        const response = await requestType[name](query);

        if (response) {
            setList(name, response);
        }
    };

    const handleOnSearchSavedFilter = (name: SavedFiltersNameProps, inputValue: string) => {
        clearTimeout(timer);

        const newTimer = setTimeout(() => {
            searchSavedFilters(name, inputValue);
        }, 500);

        setTimer(newTimer);
    };

    const handleClearSavedFilter = (name: SavedFiltersNameProps) => {
        const checkTypeFilters = name === 'regional' || name === 'uf';
        const query = {
            ...savedFiltros.savedFilterRequestBody?.query,
            [name]: checkTypeFilters ? [] : '',
        };

        dispatch(setSavedFilterRequestBody({
            name: 'query',
            data: query,
        }));
        dispatch(addSavedFilterState({
            name,
            data: {
                cache: [],
                value: [],
            },
        }));

        const body = {
            ...savedFiltros.savedFilterRequestBody,
            query,
        };

        tableHandlers.getTableList(params, body);
    };

    const addCheckFilter = (name: SavedFiltersNameProps, value: string[], item: any) => {
        dispatch(addSavedFilterState({
            name,
            data: {
                cache: [...savedFiltros[name].cache, item],
                value,
            },
        }));
    };

    const removeCheckFilter = (name: SavedFiltersNameProps, item: any) => {
        const removedCache = savedFiltros[name].cache.filter(
            (i) => i.value !== item.value,
        );
        const newValue = savedFiltros[name].value.filter(
            (i) => i !== item.value,
        );
        dispatch(addSavedFilterState({
            name,
            data: {
                cache: removedCache,
                value: newValue,
            },
        }));
    };

    const handleCheckSavedFilter = (name: SavedFiltersNameProps, value: string[], item: any) => {
        const hasValue = savedFiltros[name].value.includes(item.value);
        dispatch(setSavedFilterRequestBody({
            name: 'query',
            data: {
                ...savedFiltros.savedFilterRequestBody?.query,
                [name]: value,
            },
        }));

        if (hasValue) {
            removeCheckFilter(name, item);
        } else {
            addCheckFilter(name, value, item);
        }
    };

    return {
        savedFilterHandlers: {
            handleOnSearchSavedFilter,
            handleSelectSavedFilter,
            handleClearSavedFilter,
            handleCheckSavedFilter,
        },
    };
};
