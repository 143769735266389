import React from 'react';
import styles from './styles.module.scss';

export const ReferenceBox = ({ name, badgeText, product, className }) => (
    <div
        className={`${
            styles[product ? 'content-box' : 'content-box-flex']
        } ${className}`}
    >
        <div className={styles['item-name-wrapper']}>
            {product ? name : <p className={styles['item-name']}>{name}</p>}
            <div className={styles['reference-badge']}>{badgeText}</div>
        </div>
        {product ? <p className={styles['item-subtitle']}>{product}</p> : null}
    </div>
);
