import React, { useState, createContext } from 'react';
import { withRouter } from 'react-router-dom';
import { Container, Content, FlexboxGrid } from 'rsuite';
// @ts-ignore
import { Filtros, Periodo } from '../Components/index.tsx';
import {
    TotalVendaEmReais,
    TotalVendasPorCidade,
    TotalVendasPorCategoria1,
    UnidadesVendidasFaturamento,
    TotalVendasPorLoja,
    ProdutosMenosVendidos,
    ProdutosMaisVendidos,
    // @ts-ignore
} from './Components/index.tsx';

type FiltrosContext = {
    lojasSelecionadas: string[];
    produtosSelecionados: string[];
    periodoSelecionado: Date[];
    tipoLojasSelecionadas: string[];
    categoriaNivel1Selecionadas: string[];
    categoriaNivel2Selecionadas: string[];
}

export const FiltrosContextVendas = createContext<FiltrosContext>({
    lojasSelecionadas: [],
    produtosSelecionados: [],
    periodoSelecionado: [],
    tipoLojasSelecionadas: [],
    categoriaNivel1Selecionadas: [],
    categoriaNivel2Selecionadas: [],
});

const VendasGerais: React.FC = () => {
    const [filtros, setFiltros] = useState({
        lojasSelecionadas: [],
        produtosSelecionados: [],
        periodoSelecionado: [],
        tipoLojasSelecionadas: [],
        categoriaNivel1Selecionadas: [],
        categoriaNivel2Selecionadas: [],
    });

    const changeFiltros = (value?: any, filtro?: string) => {
        if (value && filtro)
            setFiltros((prevState) => ({
                ...prevState,
                [filtro]: value,
            }));
        else
            setFiltros({
                lojasSelecionadas: [],
                produtosSelecionados: [],
                periodoSelecionado: [],
                tipoLojasSelecionadas: [],
                categoriaNivel1Selecionadas: [],
                categoriaNivel2Selecionadas: [],
            });
    };

    return (
        <Container className="main-container vendas-gerais-page">
            <Content className="no-margin-bottom page-header">
                <h2>Vendas Geral</h2>
            </Content>
            <Filtros filtros={filtros} changeFiltros={changeFiltros} />
            <Periodo
                filtros={filtros}
                title="Resultado do faturamento de vendas"
            />
            <FiltrosContextVendas.Provider value={filtros}>
                <Container className="graficos-container">
                    <FlexboxGrid>
                        <FlexboxGrid.Item colspan={6}>
                            <TotalVendaEmReais />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={9}>
                            <TotalVendasPorCidade />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={9}>
                            <TotalVendasPorCategoria1 />
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                    <FlexboxGrid>
                        <FlexboxGrid.Item colspan={12}>
                            <UnidadesVendidasFaturamento />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={12}>
                            <TotalVendasPorLoja />
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                    <FlexboxGrid>
                        <FlexboxGrid.Item colspan={12}>
                            <ProdutosMenosVendidos />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={12}>
                            <ProdutosMaisVendidos />
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </Container>
            </FiltrosContextVendas.Provider>
        </Container>
    );
};

export default withRouter(VendasGerais);
