import React from 'react';
import { Nav, Sidenav } from 'rsuite';

const SidebarMenuMobile = ({ children, appearance, activeKey, open }) => (
    <div className={`sidebar-mobile-menu ${open ? 'open' : ''}`}>
        <Sidenav appearance={appearance} activeKey={activeKey}>
            <Sidenav.Body>
                <Nav>{children}</Nav>
            </Sidenav.Body>
        </Sidenav>
    </div>
);

export default SidebarMenuMobile;
