import React from 'react';
import CurrencyFormat from 'react-currency-format';
import { Col, FlexboxGrid, Popover, Row } from 'rsuite';

export const QuebraLimitesInfo = ({
    content,
    className,
    onMouseEnter,
    onMouseLeave,
    style,
}) => (
    <Popover
        title={
            content.maxPriceList && content.minPriceList ? (
                <div className="popover-quebra-limite__title">
                    Informações de tabela de preços
                </div>
            ) : (
                <div className="popover-quebra-limite__title">
                    Informações de tabela de preços
                    <p>
                        Não há preços cadastrados na tabela de preços para este
                        produto
                    </p>
                </div>
            )
        }
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        className={className}
        style={style}
    >
        <Row className="popover-quebra-limite__row">
            {content.maxPriceList &&
                content.maxPriceList !== content.minPriceList && (
                    <FlexboxGrid>
                        <FlexboxGrid.Item
                            componentClass={Col}
                            lg={10}
                            md={10}
                            sm={10}
                            xs={10}
                        >
                            <span className="popover-quebra-limite__description">
                                PREÇO MÁXIMO
                            </span>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item
                            componentClass={Col}
                            lg={14}
                            md={14}
                            sm={14}
                            xs={14}
                            className="popover-quebra-limite__section-value"
                        >
                            <span>
                                <CurrencyFormat
                                    fixedDecimalScale
                                    decimalScale={2}
                                    value={content.maxPriceList}
                                    displayType="text"
                                    prefix="R$ "
                                    decimalSeparator=","
                                    thousandSeparator="."
                                />
                            </span>
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                )}
            {content.minPriceList &&
                content.maxPriceList !== content.minPriceList && (
                    <FlexboxGrid>
                        <FlexboxGrid.Item
                            componentClass={Col}
                            lg={10}
                            md={10}
                            sm={10}
                            xs={10}
                        >
                            <span className="popover-quebra-limite__description">
                                PREÇO MÍNIMO
                            </span>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item
                            componentClass={Col}
                            lg={14}
                            md={14}
                            sm={14}
                            xs={14}
                            className="popover-quebra-limite__section-value"
                        >
                            <span>
                                <CurrencyFormat
                                    fixedDecimalScale
                                    decimalScale={2}
                                    value={content.minPriceList}
                                    displayType="text"
                                    prefix="R$ "
                                    decimalSeparator=","
                                    thousandSeparator="."
                                />
                            </span>
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                )}
            {content.minPriceList === content.maxPriceList &&
                content.minPriceList !== null && (
                    <FlexboxGrid>
                        <FlexboxGrid.Item
                            componentClass={Col}
                            lg={10}
                            md={10}
                            sm={10}
                            xs={10}
                        >
                            <span className="popover-quebra-limite__description">
                                PREÇO SUGERIDO
                            </span>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item
                            componentClass={Col}
                            lg={14}
                            md={14}
                            sm={14}
                            xs={14}
                            className="popover-quebra-limite__section-value"
                        >
                            <span>
                                <CurrencyFormat
                                    fixedDecimalScale
                                    decimalScale={2}
                                    value={content.maxPriceList}
                                    displayType="text"
                                    prefix="R$ "
                                    decimalSeparator=","
                                    thousandSeparator="."
                                />
                            </span>
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                )}
        </Row>
    </Popover>
);
