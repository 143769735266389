import classNames from 'classnames';
import React from 'react';
import { Modal } from 'rsuite';
import { EditCompetitorsModalTable } from '..';
import { ButtonPrimary } from '../../../../../../../../components';
import { SortTypeProps } from '../../../../../../Negociacoes/pages/NegociacaoFornecedor/components';
import { useEditRuleCompetitors } from '../../../hooks';
import styles from './EditCompetitorsModal.module.scss';

const { Header, Title, Body, Footer } = Modal;

export type EditCompetitorsModalProps = {
    modal: ReturnType<typeof useEditRuleCompetitors>['modal'];
    table: ReturnType<typeof useEditRuleCompetitors>['table'];
};

export const EditRuleCompetitorsModal = ({
    table,
    modal,
}: EditCompetitorsModalProps) => {
    return (
        <Modal
            className={classNames('info-modal', styles.modal)}
            show={modal.show}
            onHide={modal.onHide}
            onShow={modal.onShow}
        >
            <Header>
                <Title>Customização de concorrência</Title>
            </Header>
            <Body>
                <EditCompetitorsModalTable
                    {...table}
                    onSortColumn={(dataKey: string, sortType: SortTypeProps) =>
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        table.onSortColumn(dataKey as any, sortType)
                    }
                />
            </Body>
            <Footer className={styles.footer}>
                <ButtonPrimary
                    type="reset"
                    onClick={modal.onHide}
                    theme="ghost"
                >
                    fechar
                </ButtonPrimary>
                <ButtonPrimary type="button" onClick={modal.onSubmit}>
                    salvar alterações
                </ButtonPrimary>
            </Footer>
        </Modal>
    );
};
