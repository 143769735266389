import React, { useEffect, useState } from 'react';
import { FlexboxGrid } from 'rsuite';

import DropdownDownloadOptions from '../../../../../components/DropdownDownloadOptions';
import ModalConfirm from '../../../../../components/ModalConfirm';
import { getCompetitors, getDownloadCompetitorsStorage } from '../services';
import CompetitorsTable from './CompetitorsTable';
import TopTenHeader from './TopTenHeader';

import { topTenStoreCompetitorsTableColumn } from '../utils';

const TopTenStoreCompetitors = ({ referenceStore }) => {
    const [showModal, setShowModal] = useState(false);
    const [topTenStores, setTopTenStores] = useState([]);

    const setCompetitorsTable = async (cnpj) => {
        const response = await getCompetitors(cnpj);
        if (response) {
            setTopTenStores(response);
        }
    };

    const getDownloadCompetitors = async (extension) => {
        getDownloadCompetitorsStorage(referenceStore.cnpj, extension);
    };

    const handleViewFullScreen = () => {
        const element = document.getElementById('top-ten-stores-view');
        element.requestFullscreen();
    };

    useEffect(() => {
        if (referenceStore?.cnpj) {
            setCompetitorsTable(referenceStore.cnpj);
        }
    }, [referenceStore]);

    return (
        <div className="top-ten-competitors-content" id="top-ten-stores-view">
            <FlexboxGrid justify="space-between">
                <TopTenHeader
                    title="TOP 10 - LOJAS CONCORRENTES"
                    subtitle="Considerando distância, similaridade de sortimento e
                        posicionamento de preços."
                    onClick={() => setShowModal(true)}
                />
                <FlexboxGrid.Item>
                    <DropdownDownloadOptions
                        showExportOptions
                        idName="top-ten-store-competitors-dropdown"
                        hidePrintOption
                        onClickFullScreen={handleViewFullScreen}
                        onClickExport={getDownloadCompetitors}
                        disableExport={!topTenStores?.length}
                    />
                </FlexboxGrid.Item>
            </FlexboxGrid>
            <CompetitorsTable
                data={topTenStores}
                dataColumn={topTenStoreCompetitorsTableColumn}
            />
            <ModalConfirm
                title="Entendendo como calculamos o Top 10 - Lojas Concorrentes"
                confirmButton="FECHAR"
                confirmButtonWidth="140px"
                footerAlign="end"
                show={showModal}
                closeButton={false}
                onConfirm={() => setShowModal(false)}
            >
                <p>Para gerar essa análise, por padrão, é considerado o raio de até 10 km e todos os tipos de lojas similares a loja selecionada.</p>
                <p>
                    O modelo calcula e ordena os concorrentes para cada loja, onde são considerados a distância entre as lojas,
                    a compatibilidade entre os tipos de lojas, a fração de sortimento entre a loja referência
                    e as concorrentes e a similaridade de preços praticados.
                </p>
            </ModalConfirm>
        </div>
    );
};

export default TopTenStoreCompetitors;
