import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export type NegociacaoFornecedorModals = 'salvarFiltros' | 'excluirFiltro' | 'salvarNegociacao' | 'adicionarProdutoNegociacao';
export type ModalProps = Record<string, unknown> & {
    isOpen: boolean;
    data?: Record<string, unknown>;
};

export type NegociacaoFornecedorModalsStateProps = Record<
    NegociacaoFornecedorModals,
    ModalProps
>;

const initialState: NegociacaoFornecedorModalsStateProps = {
    salvarFiltros: {
        isOpen: false,
    },
    excluirFiltro: {
        isOpen: false,
    },
    salvarNegociacao: {
        isOpen: false,
    },
    adicionarProdutoNegociacao: {
        isOpen: false,
    },
};

const slice = createSlice({
    name: 'filtros-state',
    initialState,
    reducers: {
        NEGOTIATION_OPEN_MODAL(
            state,
            {
                payload: { name },
            }: PayloadAction<{ name: NegociacaoFornecedorModals }>,
        ) {
            state[name].isOpen = true;
        },
        NEGOTIATION_CLOSE_MODAL(
            state,
            { payload }: PayloadAction<{ name: NegociacaoFornecedorModals }>,
        ) {
            state[payload.name].isOpen = false;
        },
    },
});

const { NEGOTIATION_OPEN_MODAL, NEGOTIATION_CLOSE_MODAL } = slice.actions;

export { NEGOTIATION_CLOSE_MODAL, NEGOTIATION_OPEN_MODAL };

export default slice.reducer;
