import React from 'react';
import styles from './styles.module.scss';

export interface IBoxBoldText{
    children: string | number;
    style?: React.CSSProperties;
}

export const BoxBoldText = ({ children, style }: IBoxBoldText) => (
    <p className={styles['bold-text']} style={style}>{children}</p>
);
