import SaveIcon from '@material-ui/icons/Save';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Alert, Button, Content, FlexboxGrid, Icon } from 'rsuite';
import {
    resetToInitialState,
    setCompetitividadeFilter,
    setFiltrosDataCompetitividade,
    setIndustriaCompetitividadeFilter,
    setSavedCompetitividadeFilter,
    setSavedCompetitividadeIndustriaFilter,
    setSingleReducer,
} from '../../../../../../actions/actionsAnaliseCompetitividade';
import {
    LoadingMenu,
    LoadingSpinerArea,
    LoadingSpinerLocal,
} from '../../../../../../components/LoadingSpinner';
import ModalConfirmation from '../../../../../../components/ModalConfirmation';
import SelectPill from '../../../../../../components/SelectPill';
import { useDoubleRequestList } from '../../hooks/useDoubleRequestList';
import { useLocalSelectRequestList } from '../../hooks/useLocalSelectRequestList';
import { usePreferenceFilter } from '../../hooks/usePreferenceFilter';
import { useProductDoubleRequestList } from '../../hooks/useProductDoubleRequestList';
import { useReferenciaCompetidor } from '../../hooks/useReferenciaCompetidor';
import { useSaveFilter } from '../../hooks/useSaveFilter';
import { deleteSavedFilters } from '../../services';
import {
    competitivityTypeObj,
    filterTitle,
    firstLocalData,
    localDataType,
    periodData,
} from '../../utils';
import { FilterPreference } from '../FilterPreference';
import {
    BetweenVersusSelect,
    CompareSelect,
    LocalSelect,
} from '../FilterSelects';
import { ModalSaveFilters } from '../ModalSaveFilters';

import { RetailProductSelect } from '../FilterSelects/RetailProductSelect';
import { RetailRedeSelect } from '../FilterSelects/RetailRedeSelect';
import styles from './styles.module.scss';

const selectName = {
    produto: 'produto',
    rede: 'rede',
    Cidade: 'cidade',
    UF: 'uf',
};

export const FiltersArea = () => {
    const [productTimer, setProductTimer] = useState(null);
    const [isPasted, setIsPasted] = useState(false);
    const [locationFilterType, setLocationFilterType] = useState(null);
    const [filtroSalvoSelecionado, setFiltroSalvoSelecionado] = useState(null);
    const [selectFilterPeriodo, setSelectFilterPeriodo] = useState(12);
    const [showModalConfirm, setShowModalConfirm] = useState(false);

    const history = useHistory();
    const location = useLocation();
    const competitionType = location.pathname.split('/').pop();
    const isRetail = competitionType === 'varejo';
    const bigListBlockMessage =
        'São permitidos somente 1000 produtos por vez, revise sua lista e tente novamente';

    const { preferences, filtrosDataCompetitividade, filterIsEmpty } =
        useSelector(
            (state) => ({
                preferences:
                    state.analiseCompetitividadeDataReducer.preferences,
                filtrosDataCompetitividade:
                    state.analiseCompetitividadeDataReducer
                        .filtrosDataCompetitividade,
                filterIsEmpty:
                    state.analiseCompetitividadeDataReducer.filterIsEmpty,
            }),
            shallowEqual,
        );

    const dispatch = useDispatch();

    const {
        productsCompetitor,
        productsReference,
        handleSearchProductsCompetitor,
        handleAddProduct,
        handleRemoveProduct,
        handleCleanProduct,
        handleLoadMore,
        indeterminateProductCompetitor,
        allProductsCompetitorChecked,
        setIndeterminateProductCompetitor,
        setAllProductsCompetitorChecked,
        changeProductValue,
        setProductsReference,
        handleSearchSavedProductsCompetitor,
        savedFiltersDoubleRequest,
        savedFilterLoading,
        handleCleanAllSelectProduct,
    } = useProductDoubleRequestList();

    const { stateDoubleList, handlersDoubleList } =
        useDoubleRequestList('Rede');

    const { stateLocation, handlersLocation } = useLocalSelectRequestList();

    const {
        referenciaCompetidor,
        setReferencia,
        setCompetidor,
        setCleanReferenciaCompetidor,
    } = useReferenciaCompetidor();

    const {
        nameFilter,
        openModalSaveFilters,
        saveFiltroResponse,
        dataSaveFiltro,
        radioList,
        setOpenModalSaveFilters,
        setNameFilter,
        salvarFiltro,
        searchFiltersSave,
        handleHideModal,
        handleChangeRadio,
    } = useSaveFilter(competitionType, filtroSalvoSelecionado);

    const { handlersPreference } = usePreferenceFilter();

    const handleCheckAllProducts = (value, checked) => {
        let newCacheProduct;
        let newValueProduct;

        if (checked) {
            newCacheProduct = productsCompetitor?.cache?.concat(
                productsCompetitor?.list?.filter(
                    (cache) =>
                        productsCompetitor.cache.findIndex(
                            (item) => item.value === cache.value,
                        ) === -1,
                ),
            );
            newValueProduct = newCacheProduct.map((item) => item.value);
        } else {
            newCacheProduct = productsCompetitor?.cache?.filter(
                (cacheItem) =>
                    productsCompetitor?.list?.findIndex(
                        (item) => cacheItem.label === item.label,
                    ) === -1,
            );
            newValueProduct = [];
        }

        if (newValueProduct.length < 1000) {
            changeProductValue(newCacheProduct, newValueProduct);
            setIndeterminateProductCompetitor(false);
            setAllProductsCompetitorChecked(checked);

            dispatch(setFiltrosDataCompetitividade('produto', newValueProduct));
            dispatch(setCompetitividadeFilter('gtins', newValueProduct));
        } else {
            Alert.warning(bigListBlockMessage, 5000);
        }
    };

    const handleCompareProducts = (value, item) => {
        const hasValue = productsCompetitor?.value?.includes(
            item.identificador,
        );
        const concatList = referenciaCompetidor.referencia.concat(value);
        setIsPasted(false);

        if (hasValue) {
            handleRemoveProduct(item, 'competidor');
        } else {
            handleAddProduct(item, 'competidor');
        }

        if (isRetail) {
            if (value.length < 1000) {
                dispatch(setFiltrosDataCompetitividade('produto', value));
                dispatch(setCompetitividadeFilter('gtins', value));
            } else {
                Alert.warning(bigListBlockMessage, 5000);
            }
        } else {
            dispatch(setFiltrosDataCompetitividade('produto', concatList));
            dispatch(
                setIndustriaCompetitividadeFilter(
                    'produtosCompetidores',
                    value,
                ),
            );
            setCompetidor(value);
        }
    };

    const clearAllFilterSelects = () => {
        dispatch(resetToInitialState());
        dispatch(setFiltrosDataCompetitividade('initialState'));
        handleCleanAllSelectProduct();
        handlersDoubleList.handleCleanAllDoubleSelects();
        setLocationFilterType(null);
        handlersLocation.handleCleanLocation();
        setSelectFilterPeriodo(null);
        setFiltroSalvoSelecionado(null);
    };

    const handleDeleteSavedFilters = async () => {
        const response = await deleteSavedFilters(filtroSalvoSelecionado.id);

        if (response) {
            setShowModalConfirm(false);
            Alert.success('Filtro excluido com sucesso');
            searchFiltersSave();
            clearAllFilterSelects();
        }
    };

    const handleSelectReferenceProducts = (value, item) => {
        const produto = filtrosDataCompetitividade?.produto;
        const hasValue = productsReference?.value?.includes(item.identificador);
        setIsPasted(false);
        dispatch(setSingleReducer('productLabel', item.descricao));
        dispatch(setIndustriaCompetitividadeFilter('produtoReferencia', value));

        setReferencia(value);

        if (referenciaCompetidor.referencia.length) {
            const removedItem = produto?.filter(
                (i) => i !== referenciaCompetidor.referencia[0],
            );
            const updatedList = removedItem.concat([value]);
            dispatch(setFiltrosDataCompetitividade('produto', updatedList));
        } else {
            const newList = produto?.concat([value]);
            dispatch(setFiltrosDataCompetitividade('produto', newList));
        }

        if (hasValue) {
            handleRemoveProduct(item, 'referencia');
        } else {
            handleAddProduct(item, 'referencia');
        }
    };

    const handleRetailProductSearch = (inputValue, type) => {
        const blockProductBigList = inputValue.split(' ').length > 1000;

        if (inputValue.includes(' ') && blockProductBigList) {
            setIsPasted(false);
            Alert.warning(bigListBlockMessage, 5000);
            return;
        }

        if (inputValue.includes(' ')) {
            setIsPasted(true);
        } else {
            setIsPasted(false);
        }
        clearTimeout(productTimer);
        const newTimer = setTimeout(() => {
            handleSearchProductsCompetitor(inputValue, type);
        }, 500);
        setProductTimer(newTimer);
    };

    const handleCompareProductSearch = (inputValue, type) => {
        const blockProductBigList = inputValue.split(' ').length > 10;

        if (inputValue.includes(' ') && blockProductBigList) {
            setIsPasted(false);
            Alert.warning(
                'São permitidos somente 10 produtos por vez, revise sua lista e tente novamente',
                5000,
            );
            return;
        }

        if (inputValue.includes(' ') && !blockProductBigList) {
            setIsPasted(true);
        } else {
            setIsPasted(false);
        }
        clearTimeout(productTimer);
        const newTimer = setTimeout(() => {
            handleSearchProductsCompetitor(inputValue, type);
        }, 500);
        setProductTimer(newTimer);
    };

    const handleOnCleanProductReference = () => {
        const produto = filtrosDataCompetitividade?.produto;
        const removedItem = produto?.filter(
            (i) => i !== referenciaCompetidor.referencia[0],
        );
        setIsPasted(false);
        setCleanReferenciaCompetidor('referencia');
        handleCleanProduct('referencia');
        dispatch(setFiltrosDataCompetitividade('produto', removedItem));
        dispatch(setIndustriaCompetitividadeFilter('produtoReferencia', null));
    };

    const handleOnCleanProductCompetitor = () => {
        setIsPasted(false);
        handleCleanProduct('competidor');
        setCleanReferenciaCompetidor('competidor');
        setAllProductsCompetitorChecked(false);

        dispatch(
            setFiltrosDataCompetitividade(
                'produto',
                referenciaCompetidor.referencia,
            ),
        );

        if (isRetail) {
            dispatch(setCompetitividadeFilter('gtins', []));
        } else {
            dispatch(
                setIndustriaCompetitividadeFilter('produtosCompetidores', []),
            );
        }
    };

    const handleOnSelectRedeCompetidora = (value) => {
        const concatList = referenciaCompetidor.referencia.concat(value);
        dispatch(setCompetitividadeFilter('redesCompetidoras', value));
        setCompetidor(value);
        dispatch(setFiltrosDataCompetitividade('rede', concatList));
    };

    const handleOnSelectRedeReferencia = (value) => {
        const rede = filtrosDataCompetitividade?.rede;
        dispatch(setCompetitividadeFilter('redeReferencia', value));
        setReferencia(value);

        if (referenciaCompetidor.referencia.length) {
            const removedList = rede
                ?.filter((i) => i !== referenciaCompetidor.referencia)
                .concat([value]);
            dispatch(setFiltrosDataCompetitividade('rede', removedList));
        } else {
            const newList = rede?.concat([value]);
            dispatch(setFiltrosDataCompetitividade('rede', newList));
        }
    };

    const handleOnCleanRedeReferencia = () => {
        const rede = filtrosDataCompetitividade?.rede;
        const removedItem = rede?.filter(
            (i) => i !== referenciaCompetidor.referencia[0],
        );
        dispatch(setFiltrosDataCompetitividade('rede', removedItem));
        dispatch(setCompetitividadeFilter('redeReferencia', null));
        setCleanReferenciaCompetidor('referencia');
    };

    const handleOnSelectPeriodo = (value) => {
        setSelectFilterPeriodo(value);
        if (isRetail) {
            dispatch(setCompetitividadeFilter('periodoSemanas', value));
        } else {
            dispatch(
                setIndustriaCompetitividadeFilter('periodoSemanas', value),
            );
        }
    };

    const handleOnSelectIndustryFilter = (value, type, name) => {
        dispatch(setIndustriaCompetitividadeFilter(type, value));
        if (name !== undefined) {
            dispatch(setFiltrosDataCompetitividade(selectName[name], value));
        }
    };

    const handleOnCleanRedeCompetidoras = () => {
        dispatch(setIndustriaCompetitividadeFilter('redes', []));
        dispatch(
            setFiltrosDataCompetitividade(
                'rede',
                referenciaCompetidor.referencia,
            ),
        );
        dispatch(setCompetitividadeFilter('redesCompetidoras', []));
        setCleanReferenciaCompetidor('competidor');
    };

    const handleOnSelectLocal = (value, locationType) => {
        dispatch(setCompetitividadeFilter('listaLocalizacao', value));
        dispatch(
            setFiltrosDataCompetitividade(selectName[locationType], value),
        );
    };

    const handleLocationType = (value) => {
        setLocationFilterType(value);
        dispatch(setFiltrosDataCompetitividade('cidade', []));
        dispatch(setFiltrosDataCompetitividade('uf', []));
        if (isRetail) {
            dispatch(
                setCompetitividadeFilter(
                    'tipoLocalizacao',
                    localDataType[value],
                ),
            );
            dispatch(setCompetitividadeFilter('listaLocalizacao', []));
        } else {
            dispatch(
                setIndustriaCompetitividadeFilter(
                    'tipoLocalizacao',
                    localDataType[value],
                ),
            );
            dispatch(setIndustriaCompetitividadeFilter('listaLocalizacao', []));
        }
    };

    const handleCompetitivityType = (id) => {
        history.push(
            id === 'varejo'
                ? '/isa/analise-competitividade/varejo'
                : '/isa/analise-competitividade/produtos',
        );
        handleOnCleanProductReference();
        handleOnCleanProductCompetitor();
        dispatch(setFiltrosDataCompetitividade('initialState'));
        dispatch(resetToInitialState());
    };

    const handleOpenModalSaveFilters = () => {
        setOpenModalSaveFilters(true);
        setNameFilter(filtroSalvoSelecionado?.nomeFiltro);
    };

    const handleSetFiltersSaveSelect = (filters) => {
        const requestData = filters.competitivityFilterDTO.request;

        if (requestData.gtins) {
            setSelectFilterPeriodo(requestData.periodoSemanas);
            if (!isRetail) {
                const productReference = requestData.redeReferencia;
                const productReferenceValue =
                    productReference === null ? [] : [productReference];

                savedFiltersDoubleRequest(
                    productReferenceValue,
                    requestData.gtins,
                );

                setProductsReference((prevState) => ({
                    ...prevState,
                    value: productReferenceValue,
                }));
                dispatch(setSavedCompetitividadeIndustriaFilter(requestData));
            } else {
                handleSearchSavedProductsCompetitor(requestData.gtins);

                dispatch(setSavedCompetitividadeFilter(requestData));
            }
        }
    };

    const handleSelectFiltersSave = async (filtroSalvo) => {
        dispatch(resetToInitialState());
        if (filtroSalvo) {
            const filters = saveFiltroResponse.find(
                (item) => item.id === filtroSalvo,
            );
            setFiltroSalvoSelecionado(filters);
            handleSetFiltersSaveSelect(filters);
        }
    };

    const handleOnOpenPeriodo = () => {
        dispatch(
            setSingleReducer('filterIsEmpty', {
                ...filterIsEmpty,
                periodoSemanas: false,
            }),
        );
    };

    useEffect(() => {
        searchFiltersSave();
        handleSearchProductsCompetitor();
        handlersPreference.getPreferences();
    }, []);

    useEffect(() => {
        handlersPreference.save();
    }, [preferences]);

    useEffect(() => {
        if (isPasted && productsCompetitor?.cache?.length) {
            if (isRetail) {
                dispatch(
                    setCompetitividadeFilter('gtins', productsCompetitor.value),
                );
            } else {
                dispatch(
                    setIndustriaCompetitividadeFilter(
                        'produtosCompetidores',
                        productsCompetitor.value,
                    ),
                );
            }
        }
    }, [isPasted, productsCompetitor?.cache]);

    return (
        <Content className={styles['filters-area-content']}>
            <LoadingSpinerLocal loading={savedFilterLoading} />
            <LoadingSpinerArea area="saving-competitivity-filter" size="md" />
            <FlexboxGrid className={styles['competitivity-type-wrapper']}>
                {competitivityTypeObj.map((item) => (
                    <FlexboxGrid.Item key={item.id}>
                        <Button
                            className={
                                styles[
                                    competitionType === item.id
                                        ? 'competitivity-type-active'
                                        : 'competitivity-type'
                                ]
                            }
                            onClick={() => handleCompetitivityType(item.id)}
                        >
                            <p className={styles['competitivity-type-title']}>
                                {item.title}
                            </p>
                            <p
                                className={
                                    styles['competitivity-type-subtitle']
                                }
                            >
                                {item.subtitle}
                            </p>
                        </Button>
                    </FlexboxGrid.Item>
                ))}
            </FlexboxGrid>
            <div className={styles['inner-div']}>
                <div className={styles['title-container']}>
                    {filterTitle.map((title) => (
                        <div className={styles.title}>
                            <p>{title}</p>
                        </div>
                    ))}
                </div>
                <div>
                    <div className={styles['filter-selection-wrapper']}>
                        {competitionType === 'varejo' ? (
                            <RetailProductSelect
                                placeholder="Produto"
                                products={productsCompetitor}
                                onSelect={(value, item) =>
                                    handleCompareProducts(value, item)
                                }
                                onSearch={(value) =>
                                    handleRetailProductSearch(
                                        value,
                                        'competitor',
                                    )
                                }
                                onClean={handleOnCleanProductCompetitor}
                                onOpen={() =>
                                    handleSearchProductsCompetitor(
                                        null,
                                        'competitor',
                                    )
                                }
                                competitionType={competitionType}
                                onClickLoadMore={handleLoadMore}
                                onCheckAllProducts={handleCheckAllProducts}
                                productsCheckAllStatus={
                                    indeterminateProductCompetitor
                                }
                                isAllProductsChecked={
                                    allProductsCompetitorChecked
                                }
                            />
                        ) : (
                            <CompareSelect
                                placeholder="Produto"
                                productsCompetitor={productsCompetitor}
                                productsReference={productsReference}
                                onSelectCompetitor={(value, item) =>
                                    handleCompareProducts(value, item)
                                }
                                onSelectReference={(value, item) =>
                                    handleSelectReferenceProducts(value, item)
                                }
                                onSearchCompetitor={(value) =>
                                    handleCompareProductSearch(
                                        value,
                                        'competitor',
                                    )
                                }
                                onSearchReference={(value) =>
                                    handleCompareProductSearch(
                                        value,
                                        'referencia',
                                    )
                                }
                                onCleanCompetitor={
                                    handleOnCleanProductCompetitor
                                }
                                onCleanReference={handleOnCleanProductReference}
                                onOpenReference={() =>
                                    handleSearchProductsCompetitor(
                                        null,
                                        'referencia',
                                    )
                                }
                                onOpenCompetitor={() =>
                                    handleSearchProductsCompetitor(
                                        null,
                                        'competitor',
                                    )
                                }
                                competitionType={competitionType}
                                onClickLoadMore={handleLoadMore}
                                onCheckAllProducts={handleCheckAllProducts}
                                productsCompetitorCheckAllStatus={
                                    indeterminateProductCompetitor
                                }
                                isAllProductsCompetitorChecked={
                                    allProductsCompetitorChecked
                                }
                            />
                        )}
                    </div>
                    <div className={styles['filter-selection-wrapper']}>
                        {competitionType === 'varejo' ? (
                            <RetailRedeSelect
                                placeholder="Rede"
                                redeState={stateDoubleList}
                                redeHandlers={handlersDoubleList}
                                savedRedeSelectedObject={
                                    filtroSalvoSelecionado
                                        ?.competitivityFilterDTO?.request
                                }
                                onSelectReference={(value) =>
                                    handleOnSelectRedeReferencia(value)
                                }
                                onSelectCompetitors={(value) =>
                                    handleOnSelectRedeCompetidora(value)
                                }
                                onCleanReference={handleOnCleanRedeReferencia}
                                onCleanCompetitors={() =>
                                    handleOnCleanRedeCompetidoras(
                                        'redesCompetidoras',
                                    )
                                }
                            />
                        ) : (
                            <BetweenVersusSelect
                                placeholder="Rede"
                                redeState={stateDoubleList}
                                redeHandlers={handlersDoubleList}
                                savedRedeSelectedObject={
                                    filtroSalvoSelecionado
                                        ?.competitivityFilterDTO?.request
                                }
                                onSelectCompetitors={(value) =>
                                    handleOnSelectIndustryFilter(
                                        value,
                                        'redes',
                                        'rede',
                                    )
                                }
                                onCleanCompetitors={() =>
                                    handleOnCleanRedeCompetidoras(
                                        'redesCompetidoras',
                                    )
                                }
                            />
                        )}
                    </div>
                    <div className={styles['filter-selection-wrapper']}>
                        <LocalSelect
                            valueLocationType={locationFilterType}
                            addSelect
                            locationState={stateLocation}
                            locationHandlers={handlersLocation}
                            savedFilters={
                                filtroSalvoSelecionado?.competitivityFilterDTO
                                    ?.request
                            }
                            firstSelectData={firstLocalData}
                            secondSelectType="check"
                            onSelectLocationType={(value) =>
                                handleLocationType(value)
                            }
                            onSelectLocations={(value) =>
                                isRetail
                                    ? handleOnSelectLocal(
                                          value,
                                          locationFilterType,
                                      )
                                    : handleOnSelectIndustryFilter(
                                          value,
                                          'listaLocalizacao',
                                          locationFilterType,
                                      )
                            }
                        />
                    </div>
                    <div className={styles['filter-selection-wrapper']}>
                        {/* PERIODO */}
                        <SelectPill
                            id={
                                filterIsEmpty?.periodoSemanas
                                    ? 'select-pill-is-empty'
                                    : 'select-picker-pill'
                            }
                            placeholder="Escolha uma opção"
                            name="filter"
                            cleanable={false}
                            searchable={false}
                            value={selectFilterPeriodo}
                            data={periodData}
                            onSelect={(value) =>
                                handleOnSelectPeriodo(value, 'periodoSemanas')
                            }
                            onOpen={handleOnOpenPeriodo}
                        />
                    </div>
                </div>
                <div className={styles['preference-wrapper']}>
                    <div className={styles['save-filter-wrapper']}>
                        <SelectPill
                            id="select-picker-pill"
                            className={styles['save-filter']}
                            placeholder="Filtros Salvos"
                            name="save-filter"
                            filter
                            data={dataSaveFiltro}
                            locale={{
                                noResultsText: 'Nenhum item encontrado',
                                searchPlaceholder: 'Buscar item',
                            }}
                            onSelect={(filtroSalvo) =>
                                handleSelectFiltersSave(filtroSalvo)
                            }
                            onClean={clearAllFilterSelects}
                            renderMenu={(menu) => (
                                <span>
                                    <LoadingMenu area="saved-competitivity-filter" />
                                    {menu}
                                </span>
                            )}
                        />
                        {filtroSalvoSelecionado ? (
                            <Button
                                id="delete-saved-filter-competitividade"
                                className={styles['btn-delete-saved-filter']}
                                color="red"
                                onClick={() => setShowModalConfirm(true)}
                            >
                                <Icon icon="trash2" />
                            </Button>
                        ) : null}
                        <Button
                            className={styles['btn-save-filter']}
                            onClick={handleOpenModalSaveFilters}
                        >
                            <SaveIcon className={styles['save-icon']} />
                            Salvar filtros
                        </Button>
                    </div>
                    <FilterPreference
                        className={styles['preference-dropdown']}
                        onChangeTipoPreco={(value) =>
                            handlersPreference.radio(value, 'tipoPreco')
                        }
                        onChangeCalculo={(value) =>
                            handlersPreference.radio(value, 'calculo')
                        }
                        onChangeApp={(v, c) => handlersPreference.app(c)}
                        onChangePontoVenda={(v, c) =>
                            handlersPreference.pontoVenda(c)
                        }
                        tipoPrecoValue={preferences.tipoPreco}
                        valueCanalFisico={preferences.canalFisico}
                        valueCanalDigital={preferences.canalDigital}
                        valueCalculo={preferences.calculo}
                        onConfirm={handlersPreference.save}
                        onCancel={handlersPreference.onCancel}
                    />
                </div>
            </div>
            <ModalConfirmation
                title="Excluir filtro"
                message={
                    filtroSalvoSelecionado?.nomeFiltro
                        ? `Deseja deletar o filtro ${filtroSalvoSelecionado.nomeFiltro}?
                    Esta ação não poderá ser desfeita`
                        : null
                }
                show={showModalConfirm && filtroSalvoSelecionado}
                buttonConfirm="Deletar filtro"
                buttonCancel="Cancelar"
                onConfirm={handleDeleteSavedFilters}
                onCancel={() => setShowModalConfirm(false)}
            />
            <ModalSaveFilters
                show={openModalSaveFilters}
                nameFilter={nameFilter}
                radioList={radioList}
                setNameFilter={setNameFilter}
                onHide={handleHideModal}
                onChangeName={(name) => setNameFilter(name)}
                onClickConfirm={() =>
                    salvarFiltro(radioList, filtroSalvoSelecionado?.id)
                }
                onChangeRadio={(value) =>
                    handleChangeRadio(value, filtroSalvoSelecionado?.nomeFiltro)
                }
                competitivityTypeObj={competitivityTypeObj}
                filtroSalvoSelecionado={filtroSalvoSelecionado}
                deleteSavedFilters={deleteSavedFilters}
            />
        </Content>
    );
};
