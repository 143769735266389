import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Content } from 'rsuite';
import HowToUseModuleReportModal from './HowToUseModuleReportModal';

const HowToUseModuleReportBanner = ({ dataVideo, title, onClickModal }) => {
    const history = useHistory();
    const [showModal, setShowModal] = useState(false);
    return (
        <>
            <Content className="how-to-use-module-report-banner">
                <div className="diagonal-banner-container">
                    <div className="diagonal-banner">Novidade</div>
                </div>
                <div className="banner-title">
                    {title}
                </div>
                <div className="banner-subtitle">
                    Crie e exporte relatórios de preço{' '}
                    <strong>personalizados</strong> para a sua análise
                </div>
                <Button color="orange" onClick={history.location.pathname === '/isa/analise-competitividade/varejo' ? onClickModal : () => setShowModal(true)}>
                    Veja como usar
                </Button>
            </Content>
            <HowToUseModuleReportModal
                dataVideo={dataVideo}
                show={showModal}
                onHide={() => setShowModal(false)}
            />
        </>
    );
};

export default HowToUseModuleReportBanner;
