import React from 'react';
import { Container, Content } from 'rsuite';

const EmptyState = ({ title, message, image }) => (
    <Container>
        <Content className="empty-state-content">
            <div className="empty-state">
                <h4>{title}</h4>
                <p>{message}</p>
                <img alt="Empty state" src={image} />
            </div>
        </Content>
    </Container>
);

export default EmptyState;
