import React from 'react';
// @ts-ignore
import { Tabela } from '../../Components/Tabela.tsx';
// @ts-ignore
import { useProdutosMenorMargemPraticada } from '../../../hooks/useProdutosMenorMargemPraticada.tsx';

export const ProdutosMenorMargemPraticada: React.FC = () => {
    const { data, isLoading } = useProdutosMenorMargemPraticada();

    const columns = [
        {
            align: 'left',
            headerCell: 'Produto',
            dataKey: 'productId',
        },
        {
            align: 'left',
            headerCell: 'Descrição',
            dataKey: 'description',
            flexGrow: 2,
        },
        {
            align: 'left',
            headerCell: 'Itens',
            dataKey: 'sumSalesAmount',
        },
        {
            align: 'left',
            headerCell: 'Margem',
            dataKey: 'realizedMargin',
            isPercent: true,
        },
        {
            align: 'left',
            headerCell: 'Ganho margem',
            dataKey: 'marginGain',
        },
    ];

    return (
        <Tabela
            data={data}
            isLoading={isLoading}
            columns={columns}
            title="Produtos com menor margem praticada"
        />
    );
};
