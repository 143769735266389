import classNames from 'classnames';
import React from 'react';
import styles from './InputAddon.module.scss';

type InputAddonProps = React.HtmlHTMLAttributes<HTMLSpanElement>;

const InputAddon = ({ className, ...props }: InputAddonProps) => {
    return (
        <span
            className={classNames(styles.addon, className)}
            data-input-addon
            {...props}
        />
    );
};

export default InputAddon;
