import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { z } from 'zod';
import { RootState } from '../../@types/RootState';

export const schemaSetupWholesale = z.object({
    allowPriceRound: z.boolean(),
    allowPriceEdit: z.boolean(),
    allowTriggerEdit: z.boolean(),
});

export type PrecoAtacadoSchema = z.infer<typeof schemaSetupWholesale>;

export type SetupWholesaleState = PrecoAtacadoSchema;

export const initialState: SetupWholesaleState = {
    allowPriceRound: true,
    allowPriceEdit: true,
    allowTriggerEdit: false,
};

const slice = createSlice({
    name: 'ipa/setup-form/wholesale',
    initialState,
    reducers: {
        SET_SETUP_WHOLESALE(
            _state,
            action: PayloadAction<SetupWholesaleState>,
        ) {
            return action.payload;
        },
    },
});

export const { SET_SETUP_WHOLESALE } = slice.actions;

export const selectorSetupWholesale = (state: RootState) => {
    return state.reducerIPA.wholesale;
};

export default slice.reducer;
