import React, { useState, useEffect, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import {
    Container,
    Content,
    FlexboxGrid,
    Button,
    Col,
    Table,
    Checkbox,
    IconButton,
    Icon,
    Whisper,
    Tooltip,
    Notification,
    ButtonToolbar,
    DateRangePicker,
} from 'rsuite';
import CurrencyFormat from 'react-currency-format';
import { format, parseISO, toDate } from 'date-fns';
import SelectPill from '../../../../../components/SelectPill';
import { searchFiltroProdutos } from '../../../../../services/ProdutoService';
import { getLojas, downloadPrevisao } from '../services';
import {
    getPrevisoesSalvas,
    deletePrevisao,
    deletePrevisoes,
} from './services';
import { colunasTablePrevisoesSalvas } from './Components/Columns';
import { LoadingSpiner } from '../../../../../components/LoadingSpinner';
import ModalConfirmacao from '../../../../../components/ModalConfirmacao';

const {
 Column, HeaderCell, Cell, Pagination,
} = Table;

const PrevisoesSalvas = ({ history }) => {
    const datePickerRef = useRef();
    const [produtos, setProdutos] = useState([]);
    const [loadingProdutos, setLoadingProdutos] = useState(false);
    const [produtoSelecionado, setProdutoSelecionado] = useState(null);
    const [lojas, setLojas] = useState([]);
    const [loadingLojas, setLoadingLojas] = useState(false);
    const [lojaSelecionada, setLojaSelecionada] = useState(null);
    const [checked, setChecked] = useState(false);
    const [indeterminate, setIndeterminate] = useState(false);
    const [previsoesSelecionadas, setPrevisoesSelecionadas] = useState([]);
    const [previsoesSalvas, setPrevisoesSalvas] = useState({ content: [] });
    const [page, setPage] = useState(0);
    const [size, setSize] = useState(20);
    const [showModalConfirmacaoExclusao, setShowModalConfirmacaoExclusao]
        = useState(false);
    const [deleteMessage, setDeleteMessage] = useState('');
    const [previsaoToDelete, setPrevisaoToDelete] = useState(null);
    const [isMultiplasPrevisoes, setIsMultiplasPrevisoes] = useState(false);
    const [datePickerIsOpen, setDatePickerIsOpen] = useState(false);
    const [selectedDate, setSelectedDate] = useState([]);
    const [formattedDates, setFormattedDates] = useState('');

    const listPrevisoesSalvas = async () => {
        const resp = await getPrevisoesSalvas({
            productId: produtoSelecionado,
            storeId: lojaSelecionada,
            page,
            size,
            startDate: selectedDate.length
                ? format(toDate(selectedDate[0]), 'yyyy-MM-dd')
                : null,
            endDate:
                selectedDate.length > 0
                    ? format(toDate(selectedDate[1]), 'yyyy-MM-dd')
                    : null,
        });
        setPrevisoesSalvas(resp);
    };

    const listProdutos = async (query) => {
        setLoadingProdutos(true);
        const response = await searchFiltroProdutos({ query });
        if (response) {
            setProdutos(
                response.data.map((item) => ({
                    label: `${item.product_id} - ${item.description}`,
                    value: item.product_id,
                    gtin: item.gtin,
                })),
            );
        }
        setLoadingProdutos(false);
    };

    const listLojas = async (query) => {
        setLoadingLojas(true);
        const response = await getLojas({ query });
        if (response) {
            setLojas(
                response?.map((item) => ({
                    value: item.store_id,
                    label: item.store,
                    store_id: item.store_id,
                })),
            );
        }
        setLoadingLojas(false);
    };

    const handleCheckAll = (value, checked) => {
        const checkedKeys = checked ? previsoesSalvas?.content : [];
        setPrevisoesSelecionadas(checkedKeys);
    };

    const handleCheck = (value, checked) => {
        const nextCheckedKeys = checked
            ? [...previsoesSelecionadas, value]
            : previsoesSelecionadas.filter(
                  (item) => item.demand_forecast_id !== value.demand_forecast_id,
              );
        setPrevisoesSelecionadas(nextCheckedKeys);
    };

    const removePrevisao = (id) => {
        setDeleteMessage(
            'Você está tentando excluir uma previsão. Você confirma essa ação?',
        );
        setShowModalConfirmacaoExclusao(true);
        setPrevisaoToDelete(id);
        setIsMultiplasPrevisoes(false);
    };

    const removePrevisoes = () => {
        setDeleteMessage(
            'Você está tentando excluir várias previsões. Você confirma essa ação?',
        );
        setShowModalConfirmacaoExclusao(true);
        setIsMultiplasPrevisoes(true);
    };

    const closeExclusao = () => {
        setDeleteMessage('');
        setShowModalConfirmacaoExclusao(false);
        setPrevisaoToDelete(null);
    };

    const confirmExclusao = () => {
        deletePrevisao(previsaoToDelete).then(() => {
            Notification.success({
                title: 'Notificação',
                duration: 6000,
                description: (
                    <div>
                        <p>Previsão excluída com sucesso.</p>
                    </div>
                ),
            });
            listPrevisoesSalvas();
            closeExclusao();
        });
    };

    const confirmExclusaoMultipla = () => {
        deletePrevisoes({
            demandForecastsIds: previsoesSelecionadas.map(
                (item) => item.demand_forecast_id,
            ),
        }).then(() => {
            Notification.success({
                title: 'Notificação',
                duration: 6000,
                description: (
                    <div>
                        <p>Previsões excluídas com sucesso.</p>
                    </div>
                ),
            });
            listPrevisoesSalvas();
            closeExclusao();
            setPrevisoesSelecionadas([]);
        });
    };

    const openDatePicker = () => {
        datePickerRef?.current?.open();
        setDatePickerIsOpen(true);
    };

    const baixarPrevisao = () => {
        downloadPrevisao({
            ids: previsoesSelecionadas.map((item) => item.demand_forecast_id),
        }).then(() => {
            console.log('ok');
            setPrevisoesSelecionadas([]);
        });
    };

    useEffect(() => {
        listLojas();
    }, []);

    useEffect(() => {
        listPrevisoesSalvas();
    }, [page, size, lojaSelecionada, produtoSelecionado, selectedDate]);

    useEffect(() => {
        if (previsoesSelecionadas.length === previsoesSalvas?.content?.length) {
            setChecked(true);
            setIndeterminate(false);
        } else if (previsoesSelecionadas.length === 0) {
            setChecked(false);
            setIndeterminate(false);
        } else if (
            previsoesSelecionadas.length > 0
            && previsoesSelecionadas.length !== previsoesSalvas?.content?.length
        ) {
            setIndeterminate(true);
        }
    }, [previsoesSelecionadas, previsoesSalvas]);

    useEffect(() => {
        if (selectedDate.length > 0) {
            setFormattedDates(
                `${format(toDate(selectedDate[0]), 'dd/MM/yyyy')} - ${format(
                    toDate(selectedDate[selectedDate.length - 1]),
                    'dd/MM/yyyy',
                )}`,
            );
        }
    }, [selectedDate]);

    return (
        <Container className="main-container previsoes-salvas-page">
            <FlexboxGrid>
                <FlexboxGrid.Item colspan={24}>
                    <LoadingSpiner size="md" />
                    <Content className="no-margin-bottom page-header">
                        <h2>Simulações salvas de Previsão de demanda</h2>
                        <Button
                            appearance="link"
                            onClick={() => history.push(
                                    '/ipa/simulacoes-analises/previsao-demanda',
                                )}
                        >
                            Fazer nova simulação
                        </Button>
                    </Content>
                    <Content className="page-filtros">
                        <h3>Filtros</h3>
                        <FlexboxGrid>
                            <FlexboxGrid.Item colspan={24}>
                                <div className="campanhas-filtros">
                                    <FlexboxGrid justify="space-between">
                                        <FlexboxGrid.Item
                                            componentClass={Col}
                                            lg={16}
                                        >
                                            <FlexboxGrid className="filtros-container">
                                                <FlexboxGrid.Item
                                                    componentClass={Col}
                                                >
                                                    <SelectPill
                                                        id="select-picker-pill"
                                                        placeholder="Produto"
                                                        name="produto"
                                                        filter={false}
                                                        value={
                                                            produtoSelecionado
                                                        }
                                                        data={produtos}
                                                        onSelect={(value) => setProdutoSelecionado(
                                                                value,
                                                            )}
                                                        onSearch={(e) => e.length > 3
                                                            && listProdutos(e)}
                                                        onClean={() => setProdutoSelecionado(
                                                                '',
                                                            )}
                                                        locale={{
                                                            noResultsText:
                                                                loadingProdutos
                                                                    ? 'Carregando produtos...'
                                                                    : 'Nenhum item encontrado',
                                                            searchPlaceholder:
                                                                'Pesquisar',
                                                        }}
                                                    />
                                                </FlexboxGrid.Item>
                                                <FlexboxGrid.Item
                                                    componentClass={Col}
                                                >
                                                    <SelectPill
                                                        id="select-picker-pill"
                                                        placeholder="Loja"
                                                        name="loja"
                                                        filter={false}
                                                        value={lojaSelecionada}
                                                        data={lojas}
                                                        onSelect={(value) => setLojaSelecionada(
                                                                value,
                                                            )}
                                                        onClean={() => setLojaSelecionada(
                                                                '',
                                                            )}
                                                        locale={{
                                                            noResultsText:
                                                                loadingLojas
                                                                    ? 'Carregando lojas...'
                                                                    : 'Nenhum item encontrado',
                                                            searchPlaceholder:
                                                                'Pesquisar',
                                                        }}
                                                    />
                                                </FlexboxGrid.Item>
                                                <FlexboxGrid.Item>
                                                    <DateRangePicker
                                                        id="seletor-range-data"
                                                        ref={datePickerRef}
                                                        value={selectedDate}
                                                        onChange={(value) => setSelectedDate(
                                                                value,
                                                            )}
                                                        onClose={() => setDatePickerIsOpen(
                                                                false,
                                                            )}
                                                        format="DD/MM/YYYY"
                                                        appearance="subtle"
                                                        style={{
                                                            width: 0,
                                                            height: 0,
                                                            background: '#fff',
                                                            color: '#fff',
                                                            position:
                                                                'absolute',
                                                        }}
                                                        locale={{
                                                            sunday: 'Dom',
                                                            monday: 'Seg',
                                                            tuesday: 'Ter',
                                                            wednesday: 'Qua',
                                                            thursday: 'Qui',
                                                            friday: 'Sex',
                                                            saturday: 'Sáb',
                                                            ok: 'OK',
                                                            today: 'Hoje',
                                                            yesterday: 'Ontem',
                                                            last7Days:
                                                                'Últimos 7 dias',
                                                        }}
                                                    />
                                                    <button
                                                        type="button"
                                                        className={`filtro-data ${
                                                            datePickerIsOpen
                                                                ? 'active'
                                                                : ''
                                                        } ${
                                                            selectedDate.length
                                                                ? 'selected'
                                                                : ''
                                                        }`}
                                                        onClick={openDatePicker}
                                                    >
                                                        {formattedDates
                                                            || 'Período'}
                                                        <Icon
                                                            className="button-icon"
                                                            icon="chevron-down"
                                                        />
                                                        {selectedDate.length ? (
                                                            <Icon
                                                                className="remove-icon"
                                                                icon="close"
                                                                onClick={() => {
                                                                    setSelectedDate(
                                                                        [],
                                                                    );
                                                                    setFormattedDates(
                                                                        '',
                                                                    );
                                                                }}
                                                            />
                                                        ) : null}
                                                    </button>
                                                </FlexboxGrid.Item>
                                            </FlexboxGrid>
                                        </FlexboxGrid.Item>
                                    </FlexboxGrid>
                                </div>
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                    </Content>
                    <Content>
                        <FlexboxGrid>
                            <FlexboxGrid.Item colspan={24}>
                                <FlexboxGrid
                                    justify="space-between"
                                    align="top"
                                    className={`header-tabela ${
                                        previsoesSelecionadas.length
                                            ? 'itens-selecionados'
                                            : ''
                                    }`}
                                >
                                    <FlexboxGrid.Item>
                                        <FlexboxGrid align="middle">
                                            <FlexboxGrid.Item>
                                                {!previsoesSelecionadas.length ? (
                                                    <h3>
                                                        Foram encontrados
                                                        {' '}
                                                        <strong>
                                                            {
                                                                previsoesSalvas.totalElements
                                                            }
                                                            {' '}
                                                            resultados
                                                        </strong>
                                                        {' '}
                                                        no total
                                                    </h3>
                                                ) : (
                                                    <h3>
                                                        <strong>
                                                            {
                                                                previsoesSelecionadas.length
                                                            }
                                                            {' '}
                                                            {previsoesSelecionadas.length
                                                            === 1
                                                                ? 'resultado'
                                                                : 'resultados'}
                                                        </strong>
                                                        {' '}
                                                        selecionados
                                                    </h3>
                                                )}
                                            </FlexboxGrid.Item>
                                            <FlexboxGrid.Item>
                                                {!previsoesSelecionadas.length ? (
                                                    <Button
                                                        appearance="link"
                                                        onClick={() => handleCheckAll(
                                                                '',
                                                                true,
                                                            )}
                                                    >
                                                        Selecionar tudo
                                                    </Button>
                                                ) : (
                                                    <Button
                                                        appearance="link"
                                                        onClick={() => setPrevisoesSelecionadas(
                                                                [],
                                                            )}
                                                    >
                                                        Limpar seleção
                                                    </Button>
                                                )}
                                            </FlexboxGrid.Item>
                                        </FlexboxGrid>
                                    </FlexboxGrid.Item>
                                    <FlexboxGrid.Item>
                                        <ButtonToolbar>
                                            <Button
                                                appearance="ghost"
                                                disabled={
                                                    !previsoesSelecionadas.length
                                                }
                                                onClick={removePrevisoes}
                                            >
                                                <Icon icon="trash" />
                                                {' '}
                                                <strong>EXCLUIR</strong>
                                            </Button>
                                            <Button
                                                appearance="primary"
                                                disabled={
                                                    !previsoesSelecionadas.length
                                                }
                                                onClick={baixarPrevisao}
                                            >
                                                <Icon icon="download" />
                                                {' '}
                                                <strong>DOWNLOAD CSV</strong>
                                            </Button>
                                        </ButtonToolbar>
                                    </FlexboxGrid.Item>
                                </FlexboxGrid>

                                <div className="campanhas-table-container">
                                    <Table
                                        className="table"
                                        data={previsoesSalvas.content}
                                        autoHeight
                                    >
                                        {colunasTablePrevisoesSalvas.map(
                                            (coluna) => (!coluna.dataKey ? (
                                                <Column
                                                    width={120}
                                                    fixed="right"
                                                    key={Math.random()}
                                                >
                                                    <HeaderCell />

                                                    <Cell className="table__edit-button">
                                                        {(rowData) => (
                                                            <span className="mg-5">
                                                                <IconButton
                                                                    title="Visualizar previsão salva"
                                                                    size="md"
                                                                    icon={
                                                                        <Icon icon="external-link" />
                                                                        }
                                                                    onClick={() => history.push(
                                                                                `/ipa/simulacoes-analises/previsao-demanda/${rowData.demand_forecast_id}`,
                                                                            )}
                                                                />
                                                                <IconButton
                                                                    title="Deletar"
                                                                    size="md"
                                                                    icon={
                                                                        <Icon icon="trash2" />
                                                                        }
                                                                    onClick={() => removePrevisao(
                                                                                rowData.demand_forecast_id,
                                                                            )}
                                                                />
                                                            </span>
                                                            )}
                                                    </Cell>
                                                </Column>
                                                ) : coluna.dataKey
                                                  !== 'check' ? (
                                                      <Column
                                                          flexGrow={
                                                            coluna.flexGrow
                                                        }
                                                          sortable={
                                                            coluna.sortable
                                                        }
                                                          align={coluna.align}
                                                          fixed={coluna.fixed}
                                                          width={coluna.width}
                                                          key={Math.random()}
                                                      >
                                                          {coluna.dataKey
                                                        === 'simulated_price' ? (
                                                            <HeaderCell>
                                                                <Whisper
                                                                    placement="top"
                                                                    speaker={(
                                                                        <Tooltip>
                                                                            {
                                                                                coluna.headerCell
                                                                            }
                                                                        </Tooltip>
                                                                      )}
                                                                >
                                                                    <span>
                                                                        {
                                                                            coluna.headerCell
                                                                        }
                                                                    </span>
                                                                </Whisper>
                                                                {
                                                                    coluna.headerCell
                                                                }
                                                            </HeaderCell>
                                                        ) : coluna.dataKey
                                                          === 'revenue_forecast_seven_days' ? (
                                                              <HeaderCell>
                                                                  {
                                                                    coluna.headerCell
                                                                }
                                                                  <Whisper
                                                                      placement="top"
                                                                      speaker={(
                                                                          <Tooltip>
                                                                              Previsão
                                                                              de
                                                                              receita
                                                                              para
                                                                              7
                                                                              dias
                                                                          </Tooltip>
                                                                      )}
                                                                  >
                                                                      <span>
                                                                          <Icon
                                                                              icon="info"
                                                                              style={{
                                                                                fontSize:
                                                                                    '12px',
                                                                                marginLeft:
                                                                                    '2px',
                                                                            }}
                                                                          />
                                                                      </span>
                                                                  </Whisper>
                                                              </HeaderCell>
                                                        ) : (
                                                            <HeaderCell>
                                                                {
                                                                    coluna.headerCell
                                                                }
                                                            </HeaderCell>
                                                        )}
                                                          {coluna.dataKey
                                                        === 'simulated_price' ? (
                                                            <Cell
                                                                dataKey={
                                                                    coluna.dataKey
                                                                }
                                                            >
                                                                {(rowData) => (rowData.simulated_price ? (
                                                                    <CurrencyFormat
                                                                        className="currency"
                                                                        fixedDecimalScale
                                                                        decimalScale={
                                                                                2
                                                                            }
                                                                        value={
                                                                                rowData.simulated_price
                                                                            }
                                                                        displayType="text"
                                                                        prefix="R$"
                                                                        decimalSeparator=","
                                                                        thousandSeparator="."
                                                                    />
                                                                    ) : (
                                                                        '--'
                                                                    ))}
                                                            </Cell>
                                                        ) : coluna.dataKey
                                                          === 'revenue_forecast_seven_days' ? (
                                                              <Cell
                                                                  dataKey={
                                                                    coluna.dataKey
                                                                }
                                                              >
                                                                  {(rowData) => (rowData.revenue_forecast_seven_days ? (
                                                                      <CurrencyFormat
                                                                          className="currency"
                                                                          fixedDecimalScale
                                                                          decimalScale={
                                                                                2
                                                                            }
                                                                          value={
                                                                                rowData.revenue_forecast_seven_days
                                                                            }
                                                                          displayType="text"
                                                                          prefix="R$"
                                                                          decimalSeparator=","
                                                                          thousandSeparator="."
                                                                      />
                                                                    ) : (
                                                                        '--'
                                                                    ))}
                                                              </Cell>
                                                        ) : coluna.dataKey
                                                          === 'simulation_date' ? (
                                                              <Cell
                                                                  dataKey={
                                                                    coluna.dataKey
                                                                }
                                                              >
                                                                  {(rowData) => (rowData.simulation_date ? (
                                                                      <p>
                                                                          {format(
                                                                                parseISO(
                                                                                    rowData.simulation_date,
                                                                                ),
                                                                                'dd/MM/yyyy',
                                                                            )}
                                                                      </p>
                                                                    ) : (
                                                                        '--'
                                                                    ))}
                                                              </Cell>
                                                        ) : (
                                                            <Cell
                                                                dataKey={
                                                                    coluna.dataKey
                                                                }
                                                            />
                                                        )}
                                                      </Column>
                                                ) : (
                                                    <Column
                                                        className="check-lojas"
                                                        flexGrow={
                                                            coluna.flexGrow
                                                        }
                                                        sortable={
                                                            coluna.sortable
                                                        }
                                                        align={coluna.align}
                                                        fixed={coluna.fixed}
                                                        key={Math.random()}
                                                        width={coluna.width}
                                                    >
                                                        <HeaderCell>
                                                            <div
                                                                style={{
                                                                    lineHeight:
                                                                        '40px',
                                                                }}
                                                            >
                                                                <Checkbox
                                                                    checked={
                                                                        checked
                                                                    }
                                                                    indeterminate={
                                                                        indeterminate
                                                                    }
                                                                    onChange={
                                                                        handleCheckAll
                                                                    }
                                                                />
                                                            </div>
                                                        </HeaderCell>
                                                        <Cell
                                                            dataKey={
                                                                coluna.dataKey
                                                            }
                                                        >
                                                            {(rowData) => (
                                                                <Checkbox
                                                                    value={
                                                                        rowData
                                                                    }
                                                                    onChange={
                                                                        handleCheck
                                                                    }
                                                                    checked={previsoesSelecionadas?.some(
                                                                        (
                                                                            item,
                                                                        ) => item.demand_forecast_id
                                                                            === rowData.demand_forecast_id,
                                                                    )}
                                                                />
                                                            )}
                                                        </Cell>
                                                    </Column>
                                                )),
                                        )}
                                    </Table>
                                    <Pagination
                                        lengthMenu={[
                                            {
                                                value: 10,
                                                label: 10,
                                            },
                                            {
                                                value: 20,
                                                label: 20,
                                            },
                                            {
                                                value: 40,
                                                label: 40,
                                            },
                                        ]}
                                        activePage={previsoesSalvas.number + 1}
                                        displayLength={previsoesSalvas.size}
                                        total={previsoesSalvas.totalElements}
                                        onChangePage={(value) => setPage(value - 1)}
                                        onChangeLength={(value) => setSize(value)}
                                    />
                                </div>
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                    </Content>
                </FlexboxGrid.Item>
            </FlexboxGrid>
            <ModalConfirmacao
                show={showModalConfirmacaoExclusao}
                onConfirm={
                    !isMultiplasPrevisoes
                        ? confirmExclusao
                        : confirmExclusaoMultipla
                }
                onCancel={closeExclusao}
                title="Excluir previsão"
                message={deleteMessage}
                confirmButton="CONFIRMAR"
                cancelButton="CANCELAR"
            />
        </Container>
    );
};

export default withRouter(PrevisoesSalvas);
