import axios, { AxiosRequestConfig } from 'axios';

 const api = axios.create({
    baseURL: process.env.REACT_APP_IPA_URL,
    responseType: 'json',
});

api.interceptors.request.use((config: AxiosRequestConfig) => {
    config.headers = {
        ...config.headers,
        Authorization: config.token
            ? `${config.uploadToken ? '' : 'Bearer '}${config.token}`
            : config.expectToken
            ? `Bearer ${localStorage.getItem('autenticacao.token')}`
            : config.uploadToken
            ? localStorage.getItem('autenticacao.token')
            : 'Basic bXktdHJ1c3RlZC1jbGllbnQ6c2VjcmV0',
        'Content-Type': config.headers.contentType
            ? config.headers.contentType
            : 'application/json; charset=utf-8',
    };
    return config;
});

export default api
