import React from 'react';
import { usePromiseTracker } from 'react-promise-tracker';
import { NumericInput } from '../../../../../components';
import { useOnboarding } from '../../../../../context';
import { Layout } from '../layout';
import './style.scss';

export const Estrategia = () => {
    const { dataForm, setDataForm, decrement } = useOnboarding();
    const { promiseInProgress } = usePromiseTracker({ area: 'onboard-config' });

    return (
        <Layout.Content>
            <Layout.Section>
                <Layout.Title>
                    Defina a sua competitividade em
                    <br />
                    comparação ao seu mercado concorrente
                </Layout.Title>
                <div className="estrategia__input__container">
                    <NumericInput
                        value={dataForm.competitiveness}
                        name="novaMargem"
                        decimalSeparator=","
                        thousandSeparator="."
                        precision="0"
                        onChangeEvent={(e) =>
                            setDataForm((oldValue) => ({
                                ...oldValue,
                                competitiveness: Number(e.target.value),
                            }))
                        }
                        suffix="%"
                        allowNegative
                    />
                </div>
            </Layout.Section>
            <Layout.Section>
                <p>
                    <b>
                        O CPI é um índice que reflete seu posicionamento no
                        mercado.
                    </b>{' '}
                    Um valor de 100% indica preços equivalentes aos da
                    concorrência. Acima desse valor, você será percebido como
                    mais caro, e abaixo, como mais barato.{' '}
                    <b>A escolha do posicionamento cabe a você.</b>
                </p>
            </Layout.Section>
            <Layout.ButtonGroup>
                <Layout.Button
                    theme="ghost"
                    onClick={decrement}
                    disabled={promiseInProgress}
                >
                    VOLTAR
                </Layout.Button>
                <Layout.Button isLoading={promiseInProgress} type="submit">
                    CONFIGURAR
                </Layout.Button>
            </Layout.ButtonGroup>
        </Layout.Content>
    );
};
