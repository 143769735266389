import React from 'react';
import { MdGroupWork, MdStars } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { Tooltip, Whisper } from 'rsuite';
import { selectorGetFamilyModalIsOpen } from '../../../reducers/gerenciadorPrecos/datapointExhibitionType';
import { TableBadge } from '../../TableBadge';
import { BaseCell } from '../BaseCell';
import { BaseCellProps } from '../BaseCell/BaseCell';
import styles from './ProductCell.module.scss';

const { MainContainer, SubContainer } = BaseCell;

type ProductCellProps = BaseCellProps & {
    rowData: {
        productId: string;
        description: string;
        productFamilyId?: string;
        isProductMaster?: boolean;
    };
};

const ProductCell = ({ rowData, ...props }: ProductCellProps) => {

    const getFamilyModalIsOpen = useSelector(
        selectorGetFamilyModalIsOpen,
    );

    const { productId, productFamilyId, isProductMaster, description } =
        rowData;
    return (
        <BaseCell {...props}>
            <MainContainer>
                <span className={styles.product_id}>{productId}</span>
                {productFamilyId && !getFamilyModalIsOpen ? (
                    <Whisper
                        delayShow={0.2}
                        trigger="hover"
                        placement="right"
                        speaker={<Tooltip>{productFamilyId}</Tooltip>}
                    >

                        <TableBadge skin="light-blue">
                            <MdGroupWork />
                            {productFamilyId}
                        </TableBadge>

                    </Whisper>
                ) : null}
                {isProductMaster && (
                    <TableBadge skin="blue">
                        <MdStars />
                        PAI
                    </TableBadge>
                )}
            </MainContainer>
            <SubContainer>
                <Whisper
                    delayShow={0.2}
                    trigger="hover"
                    placement="right"
                    speaker={<Tooltip>{description}</Tooltip>}
                >
                    <p className={styles.description}>{description}</p>
                </Whisper>
            </SubContainer>
        </BaseCell>
    );
};

export default ProductCell;
