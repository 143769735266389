import React from 'react';
import { Modal, Button } from 'rsuite';
import styles from './ModalDeleteScenario.module.scss';

const {
    Header, Title, Body, Footer,
   } = Modal;

type ModalUpdateScenarioProps = {
    show?: boolean,
    onHide?: () => void,
    onClickDelete?: () => void,
}

export const ModalDeleteScenario = ({
    show,
    onHide,
    onClickDelete,
}: ModalUpdateScenarioProps) => (
    <Modal
        show={show}
        className={styles['modal-update-scenario']}
        onHide={onHide}
    >
        <Header className={styles.header} closeButton={false}>
            <Title>Excluir cenário salvo?</Title>
        </Header>
        <Body>
            <p className={styles.description1}>Tem certeza que deseja excluir esse cenário?</p>
            <p className={styles.description2}>Ele será excluído permanentemente</p>
        </Body>
        <Footer>
            <Button
                id="btn-delete-cancel"
                className={styles['btn-cancel']}
                appearance="ghost"
                onClick={onHide}
            >
                Cancelar
            </Button>
            <Button
                id="btn-delete-scenario"
                className={styles['btn-delete']}
                appearance="primary"
                onClick={onClickDelete}
            >
                Excluir
            </Button>
        </Footer>
    </Modal>
);
