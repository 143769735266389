import classNames from 'classnames';
import React from 'react';
import ReactImageMagnify from 'react-image-magnify';
import Modal, { ModalProps } from 'rsuite/lib/Modal';
import styles from './PhotoModal.module.scss';

export type PhotoModalProps = {
    photo?: null | string;
} & ModalProps;

export const PhotoModal = ({ className, photo, ...props }: PhotoModalProps) => {
    return (
        photo && (
            <Modal
                {...props}
                className={classNames(styles['photo-modal'], className)}
            >
                <Modal.Header />
                <Modal.Body className={styles['photo-modal__body']}>
                    <ReactImageMagnify
                        smallImage={{
                            alt: 'Foto do produto',
                            isFluidWidth: true,
                            src: photo,
                            height: 600,
                        }}
                        largeImage={{
                            src: photo,
                            width: 1200,
                            height: 1080,
                        }}
                        enlargedImagePosition="over"
                        hintTextMouse="Deslize o cursor para dar zoom"
                        isHintEnabled
                    />
                </Modal.Body>
                <button
                    className="rs-modal-header-close"
                    onClick={props.onHide}
                >
                    <span>x</span>
                </button>
            </Modal>
        )
    );
};
