import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    setSavedScenarioData,
    setSavedScenarioState,
} from '../../../reducers/calculadoraPrevisaoDemanda/savedScenario';
import { selectorCalculadoraPrevisaoDemanda } from '../../../reducers/calculadoraPrevisaoDemanda/calculadoraPrevisaoDemandaReducer';

import * as service from '../services';
import * as utils from '../utils';

const listRequestBody = {
    query: {
        query: '',
        from_date: '',
        to_date: '',
        product_id: [],
        regional: [],
        uf: [],
        channel: '',
        sub_channel: '',
        responsible: '',
    },
    ordering: [],
};

export const useTableActions = () => {
    const [sortColumn, setSortColumn] = useState('');
    const [sortType, setSortType] = useState('asc');
    const [page, setPage] = useState(0);
    const { savedFiltros, savedScenario } = useSelector(
        selectorCalculadoraPrevisaoDemanda,
    );
    const selectedScenario = savedScenario.selectedScenarioItemList;
    const tableData = savedScenario.savedScenarioData;

    const dispatch = useDispatch();

    const getTableList = async (params: any, body: any) => {
        const requestBody = body === 'initial' ? listRequestBody : body;
        const response = await service.getTableScenarios(params, requestBody);
        if (response) {
            dispatch(setSavedScenarioData({ data: response }));
        }
    };

    const setRequestBody = (item) => {
        const order = [{ orderField: utils.columnType[item.column], ascending: item.type === 'asc' }];
        const body = {
            ...savedFiltros.savedFilterRequestBody,
            ordering: order,
        };

        return body;
    };

    const handleSortColumns = async (column, type) => {
        const params = { page: 0, sort: [] };

        setSortColumn(column);
        setSortType(type);
        getTableList(params, setRequestBody({ column, type }));
    };

    const handleChangeCheckbox = (id) => {
        const hasValue = selectedScenario.includes(id);

        if (hasValue) {
            const removeItem = selectedScenario.filter((item) => item !== id);
            dispatch(setSavedScenarioState({
                name: 'selectedScenarioItemList',
                data: removeItem,
            }));
        } else {
            const array = [...selectedScenario, id];
            dispatch(setSavedScenarioState({
                name: 'selectedScenarioItemList',
                data: array,
            }));
        }
    };

    const handleChangeAllCheckbox = () => {
        const all = tableData?.content
            .map((item) => item.scenario.scenario_id);

        if (selectedScenario.length === tableData.totalElements) {
            dispatch(setSavedScenarioState({
                name: 'selectedScenarioItemList',
                data: [],
            }));
        } else {
            dispatch(setSavedScenarioState({
                name: 'selectedScenarioItemList',
                data: all,
            }));
        }
    };

    const handleChangePage = (value) => {
        setPage(value - 1);
        const params = { page: value - 1 };
        getTableList(params, savedFiltros.savedFilterRequestBody);
    };

    const handleChangeSize = (value) => {
        const params = { page: 0, size: value };
        getTableList(params, setRequestBody({ column: 'SCENARIO_NAME', type: 'asc' }));
    };

    return {
        tableHandlers: {
            handleSortColumns,
            handleChangeCheckbox,
            handleChangeAllCheckbox,
            handleChangePage,
            handleChangeSize,
            setSortColumn,
            setSortType,
            getTableList,
        },
        tableState: {
            sortColumn,
            sortType,
            page,
        },
    };
};
