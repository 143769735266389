import React from 'react';
import styles from './styles.module.scss';

const PriceTypeBox = ({ headerText, priceValue, bottomValue, className }) => (
    <div className={className}>
        <div className={styles.header}>{headerText}</div>
        <div className={styles.content}>
            <div>
                <span className={styles['price-symbol']}>R$</span>
                <span className={styles['price-number']}>{priceValue}</span>
            </div>
            <div>
                <span className={styles['bottom-text']}>
                    {headerText === 'Último preço' ? 'Coletado' : null}
                    {headerText === 'Preço mais frequente' ? 'Praticado' : null}
                </span>
                <span
                    className={styles['bottom-value']}
                    style={
                        headerText === 'Preço médio' ? { marginLeft: 0 } : null
                    }
                >
                    {bottomValue}
                </span>
                <span className={styles['bottom-text']}>
                    {headerText === 'Preço médio' ? 'calculados' : null}
                    {headerText === 'Preço mais frequente' ? 'das vezes' : null}
                </span>
            </div>
        </div>
    </div>
);

export default PriceTypeBox;
