import React from 'react';
import { Nav, Icon, IconButton } from 'rsuite';
import Dialog from '@material-ui/core/Dialog';
import logo from '../assets/logo/logo-infoprice-branco.svg';

const NavFullscreenMobile = ({ open, onClose, children, activeKey }) => (
    <Dialog
        className="dialog-mobile-menu"
        fullScreen
        open={open}
        onClose={onClose}
    >
        <IconButton
            className="btn-close"
            appearance="subtle"
            onClick={onClose}
            icon={<Icon icon="close" />}
            circle
            size="lg"
        />
        <img src={logo} alt="" />
        <Nav className="content" vertical activeKey={activeKey}>
            {children}
        </Nav>
    </Dialog>
);

export default NavFullscreenMobile;
