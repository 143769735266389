import { useQuery } from '@tanstack/react-query';
import { format, parseISO } from 'date-fns';
import React from 'react';
import { useDispatch } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { getSetupIPAOptions } from '../pages/Administrador/Produtos/Components/SetupIPA/SetupIPA.services';
import { GET_SETUP_IPA_PRECO_ATACADO } from '../pages/Administrador/Produtos/Components/SetupIPAPrecoAtacado/services';
import { SET_SEGMENTOS } from '../reducers/gerenciadorPrecos/segmentos';
import { SET_DADOS_INFOPANEL } from '../reducers/reducerIPA/dadosInfopanel';
import { SET_LAST_INTEGRATION_DATE } from '../reducers/reducerIPA/lastIntegrationDate';
import { SET_SETUP_WHOLESALE } from '../reducers/reducerIPA/wholesale';
import { getCattegories, getLastUpdate } from '../services/ProdutoService_ts';

const ENABLED_URL = ['/administrador/produtos/IPA/*', '/ipa/*'] as const;

type IPAProviderProps = React.HTMLAttributes<HTMLDivElement> &
    RouteComponentProps;

const STALE_TIME = 1000 * 60 * 60; // 60 minutes

const IPAProvider = ({ children, location }: IPAProviderProps) => {
    const { pathname } = location;

    const IS_ENABLED_QUERY = ENABLED_URL.some((url) => {
        return new RegExp(url).test(pathname);
    });

    if (!IS_ENABLED_QUERY) return children;

    const dispatch = useDispatch();

    useQuery({
        queryKey: ['setup-ipa', 'dados-infopanel'],
        queryFn: getSetupIPAOptions,
        enabled: IS_ENABLED_QUERY,
        staleTime: STALE_TIME,
        onSuccess: (data) => dispatch(SET_DADOS_INFOPANEL(data)),
    });

    useQuery({
        queryKey: ['ipa.filters.cattegories'],
        queryFn: getCattegories,
        enabled: IS_ENABLED_QUERY,
        staleTime: STALE_TIME,
        onSuccess: (data) => dispatch(SET_SEGMENTOS(data)),
    });

    useQuery({
        queryKey: ['ipa.lastIntegrationDate'],
        queryFn: getLastUpdate,
        staleTime: 1000 * 60 * 60, // 60 minutes,
        enabled: IS_ENABLED_QUERY,
        onSuccess: (data) => {
            dispatch(
                SET_LAST_INTEGRATION_DATE({
                    ...data,
                    parsedDate: format(
                        parseISO(data.lastExecutionDate),
                        'dd/MM/yyy, HH:mm',
                    ),
                }),
            );
        },
    });

    useQuery({
        queryKey: ['ipa/setup-form/wholesale'],
        queryFn: GET_SETUP_IPA_PRECO_ATACADO,
        staleTime: STALE_TIME,
        enabled: IS_ENABLED_QUERY,
        onSuccess: (data) => dispatch(SET_SETUP_WHOLESALE(data)),
    });

    return children;
};

export default withRouter(IPAProvider);
