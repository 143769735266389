import React from 'react';
import { Button, Col, FlexboxGrid, Tooltip, Whisper } from 'rsuite';
import iconModalVideos from '../../assets/icons/icon_modal_videos.svg';
import './style.scss';

export const ButtonModalVideos = ({ onClickModal }) => {
    return (
        <FlexboxGrid>
            <FlexboxGrid.Item
                componentClass={Col}
                lg={24}
                md={24}
                sm={24}
                xs={24}
            >
                <Whisper
                    trigger="hover"
                    placement="left"
                    speaker={<Tooltip>Assista e veja como funciona</Tooltip>}
                >
                    <Button className="button-video" onClick={onClickModal}>
                        <img src={iconModalVideos} />
                    </Button>
                </Whisper>
            </FlexboxGrid.Item>
        </FlexboxGrid>
    );
};
