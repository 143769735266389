import { DataItem } from '../../../../../../@types/DataItem';
import { MechanismType } from './utils';

const mechanismType: { label: string; value: MechanismType }[] = [
    { label: 'Competitividade', value: 'COMPETITIVIDADE' },
    { label: 'Margem', value: 'MARGEM' },
    { label: 'Margem objetiva', value: 'MARGEM_OBJETIVA' },
];

export const aditionalMechanismTypeValue = [
    'MARGEM',
    'MARGEM_OBJETIVA',
] as const;

export type AditionalMechanismTypeValue =
    typeof aditionalMechanismTypeValue[number];

export const aditionalMechanismType: {
    label: string;
    value: AditionalMechanismTypeValue;
}[] = [
    { label: 'Margem', value: 'MARGEM' },
    { label: 'Margem objetiva', value: 'MARGEM_OBJETIVA' },
];

const recurrenceTypes = [
    { label: 'Diária', value: 'DAILY' },
    { label: 'Semanal', value: 'WEEKLY' },
    { label: 'Mensal', value: 'MONTHLY' },
];

const datePickerLocale = {
    sunday: 'Dom',
    monday: 'Seg',
    tuesday: 'Ter',
    wednesday: 'Qua',
    thursday: 'Qui',
    friday: 'Sex',
    saturday: 'Sáb',
    ok: 'OK',
    today: 'Hoje',
};

const monthlyRecurrenceType = [
    {
        label: 'Primeira',
        value: 'FIRST',
    },
    {
        label: 'Segunda',
        value: 'SECOND',
    },
    {
        label: 'Terceira',
        value: 'THIRD',
    },
    {
        label: 'Quarta',
        value: 'FOURTH',
    },
    {
        label: 'Última',
        value: 'LAST',
    },
];

const monthlyRecurrenceDayOfWeek = [
    {
        label: 'Segunda-feira',
        value: 'MONDAY',
    },
    {
        label: 'Terça-feira',
        value: 'TUESDAY',
    },
    {
        label: 'Quarta-feira',
        value: 'WEDNESDAY',
    },
    {
        label: 'Quinta-feira',
        value: 'THURSDAY',
    },
    {
        label: 'Sexta-feira',
        value: 'FRIDAY',
    },
    {
        label: 'Sábado',
        value: 'SATURDAY',
    },
    {
        label: 'Domingo',
        value: 'SUNDAY',
    },
];

const priceCompetitorIndicator = [
    { label: 'Média do preço concorrente', value: 'AVERAGE' },
    { label: 'Preço mais alto', value: 'HIGHER' },
    { label: 'Preço mais baixo', value: 'LOWER' },
];

const abcClass = async () => {
    const data = [
        { label: 'A', value: 'A' },
        { label: 'B', value: 'B' },
        { label: 'C', value: 'C' },
        { label: 'D', value: 'D' },
    ];
    return new Promise<typeof data>((resolve) => {
        resolve(data);
    });
};

export type StoreType = 'individual-store' | 'all-stores';

const storeType: DataItem[] = [
    { value: 'individual-store', label: 'Loja' },
    { value: 'all-stores', label: 'Todas as lojas' },
];

export const segmentsType: DataItem[] = [
    { value: 'infoPriceSensibility', label: 'Sensibilidade InfoPrice' },
    { value: 'infoPriceAbcClass', label: 'Curva ABC Local' },
    { value: 'infoPriceGlobalAbcClass', label: 'Curva ABC Global' },
    { value: 'sensibilityType', label: 'Exclusiva' },
    { value: 'abcClass', label: 'Curva' },
];

export default {
    mechanismType,
    aditionalMechanismType,
    recurrenceTypes,
    datePickerLocale,
    monthlyRecurrenceType,
    monthlyRecurrenceDayOfWeek,
    priceCompetitorIndicator,
    abcClass,
    storeType,
    segmentsType,
};
