import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsExport from 'highcharts/modules/export-data';
import HighchartsExporting from 'highcharts/modules/exporting';
import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { selectorCalculadoraPrevisaoDemanda } from '../../../../reducers/calculadoraPrevisaoDemanda';
import * as utils from '../../utils';
import { BestPriceChartResponse } from '../../types';
import styles from './BestPriceChart.module.scss';

HighchartsExporting(Highcharts);
HighchartsExport(Highcharts);

const HighchartsOptions = {
    lang: {
        decimalPoint: ',',
        thousandsSep: '.',
        exitFullscreen: 'Sair da tela cheia',
    },
};

Highcharts.setOptions(HighchartsOptions);

const setDataChart = (name: string, data: BestPriceChartResponse[]) => {
    const balanceada = data.filter((item) => item.optimum_balanced_price === true);
    const profit = data.filter((item) => item.optimum_profit_price === true);
    const revenue = data.filter((item) => item.optimum_revenue_price === true);
    const precoBalanceada = (balanceada[0]?.price + balanceada[0]?.price) / 2;

    const newData = data.map((item) => ({
        x: item.price,
        y: name === 'receita' ? item.revenue : item.profit,
        profit: profit[0]?.price,
        revenue: revenue[0]?.price,
        optimumRevenue: item.optimum_revenue_price,
        optimumProfit: item.optimum_profit_price,
        optimumBalanced: item.optimum_balanced_price,
        precoBalanceada,
        marker: {
            enabled: name === 'receita' ? item.optimum_revenue_price : item.optimum_profit_price,
            states: {
                hover: {
                    enabled: name === 'receita' ? item.optimum_revenue_price : item.optimum_profit_price,
                },
            },
        },
    }));

    return newData;
};

const setPlotLines = (name: string, data: BestPriceChartResponse[]) => {
    const type = {
        receita: 'optimum_revenue_price',
        lucro: 'optimum_profit_price',
        media: 'optimum_balanced_price',
    };

    const filterData = data
        .filter((item) => item[type[name]] === true)
        .map((item) => item.price);

    const minor = Math.min(...filterData);
    const major = Math.max(...filterData);

    const returnType = {
        receita: { minor, major },
        lucro: { minor, major },
        media: filterData[0],
    };

    return returnType[name];
};

export const BestPriceChart = () => {
    const bestPriceChartRef = useRef(null);

    const { resultScenario } = useSelector(selectorCalculadoraPrevisaoDemanda);

    const defaultChartOption = {
        chart: {
            type: 'spline',
        },
        title: {
            text: '',
        },

        exporting: {
            enabled: false,
        },
        tooltip: {
            useHTML: true,
            pointFormatter() {
                return `
                    <p>
                        Preço ótimo:
                    </p>
                    <br/>
                    <div>
                        <span style="color:#E663C9">
                            <b>●</b>
                        </span>
                        <span>
                            Para receita: <b>R$ ${utils.formatLocaleNumber(this.revenue, 2)}</b>
                        </span>
                    </div>
                    <br/>
                    <div>
                        <span style="color:#32CD32">
                            <b>■</b>
                        </span>
                        <span>
                            Para lucro: <b>R$ ${utils.formatLocaleNumber(this.profit, 2)}</b>
                        </span>
                    </div>
                    <br/>
                    <div>
                        <span>
                            Balanceada: <b>R$ ${utils.formatLocaleNumber(this.precoBalanceada, 2)}</b>
                        </span>
                    </div>
                `;
            },
            headerFormat: '',
        },
        legend: {
            symbolWidth: 50,
        },
        yAxis: {
            title: {
                text: 'IMPACTO RECEITA E LUCRO',
            },
            accessibility: {
                description: 'IMPACTO RECEITA E LUCRO',
            },

        },

        xAxis: {
            plotBands: [{
                from: setPlotLines('receita', resultScenario?.profitRevenueChartData).minor,
                to: setPlotLines('lucro', resultScenario?.profitRevenueChartData).major,
                color: '#e663c94d',
            }],
            plotLines: [{
                value: setPlotLines('receita', resultScenario?.profitRevenueChartData).minor,
                color: '#F25C96',
                width: 1,
                zIndex: 1.5,
                dashStyle: 'ShortDashDot',
            }, {
                value: setPlotLines('lucro', resultScenario?.profitRevenueChartData).major,
                color: '#32CD32',
                width: 1,
                zIndex: 1.5,
                dashStyle: 'ShortDashDot',
            }, {
                value: setPlotLines('media', resultScenario?.profitRevenueChartData),
                color: '#FF756B',
                width: 1,
                zIndex: 1.5,
                dashStyle: 'ShortDashDot',
                marker: {
                    symbol: 'triangle',
                },
            }],
            title: {
                text: 'PREÇO',
            },
            accessibility: {
                description: 'PREÇO',
            },
            labels: {
                formatter() {
                    let price = this.axis.defaultLabelFormatter.call(this);
                    if (price.includes('.')) {
                        price = price.replace('.', '');
                    }

                    if (price.includes(',')) {
                        price = price.replace(',', '.');
                    }
                    return `R$ ${Number(price).toFixed(2).replace('.', ',')}`;
                },
            },
        },
        plotOptions: {},
        series: [
            {
                name: 'Receita',
                data: setDataChart('receita', resultScenario?.profitRevenueChartData),
                color: '#E663C9',

            },
            {
                name: 'Lucro',
                data: setDataChart('lucro', resultScenario?.profitRevenueChartData),
                color: '#32CD32',
                marker: {
                    symbol: 'square',
                },
            },
        ],
    };

    return (
        <div className={styles.container}>
            <div className={styles['title-wrapper']}>
                <p className={styles.title}>Curva do preço ótimo</p>
                <p className={styles.subtitle}>
                    O preço ótimo é aquele que alcança o ponto mais alto de lucro ou de receita
                </p>
            </div>
            <HighchartsReact
                ref={bestPriceChartRef}
                highcharts={Highcharts}
                options={defaultChartOption}
            />
        </div>
    );
};
