import React, { memo } from 'react';
import { MdAccountTree } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown, Tooltip, Whisper } from 'rsuite';
import { RootState } from '../../../../../@types/RootState';
import { Layout } from '../../../../../components';
import { SET_DATA_POINTS_EXHIBITION_TYPE } from '../../../../../reducers/gerenciadorPrecos/datapointExhibitionType';
import { RESET_QUICK_ACTION_FILTER } from '../../../../../reducers/gerenciadorPrecos/quickActionsFilter';
import { DataUltimaIntegracao } from '../DataUltimaIntegracao';
import styles from './Gerenciador.module.scss';

const GerenciadorHeader = () => {
    const dispatch = useDispatch();
    const datapointExhibitionType = useSelector((state: RootState) => {
        return state.datapointExhibitionType.datapointExhibitionType;
    });

    const handleSelect = (value: 'PRODUCT' | 'FAMILY') => {
        if (value === 'FAMILY') {
            dispatch(RESET_QUICK_ACTION_FILTER());
        }
        dispatch(SET_DATA_POINTS_EXHIBITION_TYPE(value));
    };

    const title = datapointExhibitionType === 'PRODUCT' ? 'Produto' : 'Família';

    return (
        <div className={styles['gerenciador-header']}>
            <div className={styles['gerenciador-header__content']}>
                <div
                    className={styles['gerenciador-header__title-integration']}
                >
                    <Layout.Title>Gerenciador de preços</Layout.Title>
                    <DataUltimaIntegracao />
                </div>

                <Whisper
                    placement="bottom"
                    speaker={<Tooltip>Precificar por</Tooltip>}
                >
                    <Dropdown
                        title={title}
                        icon={
                            <MdAccountTree
                                className={styles['gerenciador-header__icon']}
                            />
                        }
                    >
                        <Dropdown.Item
                            className={styles['gerenciador-header__item']}
                            onSelect={() => handleSelect('PRODUCT')}
                        >
                            Produto
                        </Dropdown.Item>
                        <Dropdown.Item
                            className={styles['gerenciador-header__item']}
                            onSelect={() => handleSelect('FAMILY')}>
                            Família
                        </Dropdown.Item>
                    </Dropdown>
                </Whisper>
            </div>
            <Layout.Subtitle className={styles['gerenciador-header__subtitle']}>
                Revisar todos os produtos
            </Layout.Subtitle>
        </div>
    );
};

export default memo(GerenciadorHeader);
