import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
    setGenerateReportData,
    setEnderecoLatLon,
    setStateValue,
    setEnderecoRaio,
} from '../../../../../actions/actionsModuloRelatorio';
import * as ModuloRelatorioService from '../services';

export const useSelectEndereco = () => {
    const dispatch = useDispatch();

    const { endereco, moduloRelatorioData } = useSelector(
        (state) => ({
            endereco: state.moduloRelatorioDataReducer?.endereco,
            moduloRelatorioData:
                state.moduloRelatorioDataReducer?.moduloRelatorioData,
        }),
        shallowEqual,
    );

    const setList = (list) => {
        const newList = list.predictions.map((item) => ({
            label: item.description,
            value: item.description,
            place_id: item.place_id,
        }));

        const cacheList = endereco.cache;
        const removeDuplicate = newList.filter(
            (i) => !endereco.value.includes(i.value),
        );
        const concatList = endereco.value.length
            ? cacheList.concat(removeDuplicate)
            : newList;

        dispatch(setStateValue('endereco', {
            ...endereco,
            lista: concatList,
            token: list.session_token,
        }));
    };

    const handleGetLatLon = async (item) => {
        const placeResponse = await ModuloRelatorioService.getPlace({
            place_id: item.place_id,
            token: endereco.token,
        });

        if (placeResponse) {
            const { lat, lng } = placeResponse.result.geometry.location;
            dispatch(setEnderecoLatLon({
                latitude: lat,
                longitude: lng,
                endereco: item.value,
            }));
        }
    };

    const handleSelectEndereco = (item) => {
        dispatch(setGenerateReportData('endereco', item.value));
        handleGetLatLon(item);
        dispatch(setStateValue('endereco', {
            ...endereco,
            cache: [item],
            value: [item.value],
        }));

        if (moduloRelatorioData.enderecoDTO?.raio === null) {
            dispatch(setEnderecoRaio(2));
        }
    };

    const handleSetValuesForEditEndereco = (item) => {
        dispatch(setStateValue('endereco', {
            ...endereco,
            lista: [item],
            cache: [item],
            value: [item.value],
        }));
    };

    const handleCleanEndereco = (item, name) => {
        dispatch(setEnderecoLatLon({ latitude: null, longitude: null, endereco: null }));
    };

    const searchEndereco = (data) => {
        if (data.query) {
            ModuloRelatorioService.getEndereco(data).then((list) => setList(list));
        }
    };

    const handleSearchEndereco = (inputValue) => {
        searchEndereco({
            query: inputValue,
            token: endereco.token,
        });
    };

    return {
        enderecoHandlers: {
            handleGetLatLon,
            handleSelectEndereco,
            handleCleanEndereco,
            handleSearchEndereco,
            handleSetValuesForEditEndereco,
        },
    };
};
