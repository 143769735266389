import classNames from 'classnames';
import React from 'react';
import CurrencyFormat from 'react-currency-format';
import { Divider } from 'rsuite';
import { GerenciadorPrecos } from '../../../../../../@types/GerenciadorPrecos';
import {
    CompetitivenessPriceIndicator,
    MoneyPercentageIndicator,
} from '../../../../Negociacoes/pages/NegociacaoFornecedor/components';
import BaseCell, { BaseCellProps } from '../BaseCell/BaseCell';

export type PrecoVigenteCellProps = BaseCellProps & {
    rowData: GerenciadorPrecos.RowData;
};

const PrecoVigenteCell = ({
    className,
    rowData,
    ...props
}: PrecoVigenteCellProps) => {
    const { retailPrice, originalMargin, retailCpi } = rowData;

    return (
        <BaseCell className={classNames(className)} {...props}>
            <BaseCell.MainContainer>
                {retailPrice ? (
                    <CurrencyFormat
                        fixedDecimalScale
                        decimalScale={2}
                        value={retailPrice}
                        displayType="text"
                        prefix="R$ "
                        decimalSeparator=","
                        thousandSeparator="."
                    />
                ) : (
                    '--'
                )}
            </BaseCell.MainContainer>
            <BaseCell.SubContainer style={{ gap: '0px' }}>
                <MoneyPercentageIndicator
                    value={originalMargin}
                    tooltip="Margem do preço vigente"
                />
                <Divider vertical />
                <CompetitivenessPriceIndicator value={retailCpi} />
            </BaseCell.SubContainer>
        </BaseCell>
    );
};

export default PrecoVigenteCell;
