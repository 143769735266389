import Immutable from 'seamless-immutable';
import { CHANGE_ACTIVE_KEY_ADMIN } from '../actions/actionsNavbarAdmin';

const navbarAdminInitialState = Immutable({
    activeKey: null,
});

function navbarAdminDataReducer(state = navbarAdminInitialState, action) {
    switch (action.type) {
        case CHANGE_ACTIVE_KEY_ADMIN:
            return state.merge({
                activeKey: action.activeKey,
            });
        default:
            return state;
    }
}

export default navbarAdminDataReducer;
