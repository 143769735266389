import api from '../utils/API';

export const getAlertas = () =>
    api({
        url: `${process.env.REACT_APP_CADASTROS_API_URL}/alertas/portal`,
        method: 'GET',
        expectToken: true,
    }).then((resp) => resp.data);

export const getNotificationReport = () =>
    api({
        url: `${process.env.REACT_APP_MODULO_RELATORIO_URL}/pending`,
        method: 'GET',
        expectToken: true,
    }).then((resp) => resp.data);
