import React, { useEffect, useState } from 'react';
import { withRouter, useParams } from 'react-router-dom';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {
    Container,
    Content,
    FlexboxGrid,
    Button,
    Table,
    IconButton,
    Icon,
    Notification,
    Tooltip,
    Whisper,
} from 'rsuite';
import CurrencyFormat from 'react-currency-format';
import { LoadingSpiner } from '../../../../../components/LoadingSpinner';
import Header from '../Components/Header';
import Steps from '../Components/Steps';
import { getCampaign } from '../services';
import { getLojasParticipantes } from '../LojasParticipantes/services';
import { listBlocos } from '../BlocoDeOferta/services';
import ModalCadastroOferta from './Components/ModalCadastroOferta';
import ModalProgramarCampanha from './Components/ModalProgramarCampanha';
import ModalConfirmacao from '../../../../../components/ModalConfirmacao';
import {
    saveOferta,
    getOfertas,
    updateOferta,
    deleteProdutoOferta,
    programarOferta,
} from './services';
import { colunasTableOfertas } from './Components/Columns';

const {
 Column, HeaderCell, Cell, Pagination,
} = Table;

const Ofertas = ({ history }) => {
    const { id } = useParams();
    const [campanha, setCampanha] = useState({});
    const [lojasParticipantes, setLojasParticipantes] = useState({
        content: [],
    });
    const [blocosOfertas, setBlocosOfertas] = useState({});
    const [ofertas, setOfertas] = useState({ content: [] });
    const [page, setPage] = useState(0);
    const [size, setSize] = useState(20);
    const [showModalCadastroOfertas, setShowModalCadastroOfertas]
        = useState(false);
    const [oferta, setOferta] = useState({
        products: [],
        offer_groups: {},
        offer: {},
    });
    const [showEditOferta, setShowEditOferta] = useState(false);
    const [ofertaSelecionada, setOfertaSelecionada] = useState(null);
    const [deleteMessage, setDeleteMessage] = useState('');
    const [showModalConfirmacaoExclusao, setShowModalConfirmacaoExclusao]
        = useState(false);
    const [showModalProgramarCampanha, setShowModalProgramarCampanha]
        = useState(false);
    const [ofertaToDelete, setOfertaToDelete] = useState(null);

    const goToLojas = () => {
        history.push(`/ipa/campanhas/cadastro/lojas-participantes/${id}`);
    };

    const listCampaign = async () => {
        const response = await getCampaign(id);
        if (response) {
            setCampanha(response.data);
        }
    };

    const listOfertas = async () => {
        const response = await getOfertas({
            id,
            page,
            size,
        });
        if (response) {
            setOfertas(response);
        }
    };

    const listLojasParticipantes = async () => {
        const response = await getLojasParticipantes({
            id,
        });
        if (response) setLojasParticipantes(response);
    };

    const listBlocosOfertas = async () => {
        const response = await listBlocos(id, { size: 200 });
        if (response) setBlocosOfertas(response);
    };

    const back = () => {
        history.push(`/ipa/campanhas/cadastro/dados-campanha/${id}`);
    };

    const draft = () => {
        history.push('/ipa/campanhas');
    };

    const closeOferta = () => {
        setShowModalCadastroOfertas(false);
        setShowEditOferta(false);
    };

    const confirmOferta = async () => {
        const ofertaData = oferta;

        ofertaData.offer = {
            ...ofertaData.offer,
            simple: {
                ...ofertaData.offer.simple,
                value: ofertaData.offer.simple.value
                    ? typeof ofertaData.offer.simple.value !== 'number'
                        ? parseFloat(
                              ofertaData.offer.simple.value.replace(',', '.'),
                          )
                        : ofertaData.offer.simple.value
                    : 0,
            },
            from_to: {
                ...ofertaData.offer.from_to,
                from: ofertaData.offer.from_to.from
                    ? typeof ofertaData.offer.from_to.from !== 'number'
                        ? parseFloat(
                              ofertaData.offer.from_to.from.replace(',', '.'),
                          )
                        : ofertaData.offer.from_to.from
                    : 0,
                to: ofertaData.offer.from_to.to
                    ? typeof ofertaData.offer.from_to.to !== 'number'
                        ? parseFloat(
                              ofertaData.offer.from_to.to.replace(',', '.'),
                          )
                        : ofertaData.offer.from_to.to
                    : 0,
            },
        };

        if (
            !ofertaData.offer.from_to.from
            && !ofertaData.offer.from_to.to
            && !ofertaData.offer.simple.value
        ) {
            Notification.error({
                title: 'Notificação',
                duration: 6000,
                description: (
                    <div>
                        <p>
                            Tipo de oferta obrigatório para o cadastro de oferta
                        </p>
                    </div>
                ),
            });
            return;
        }

        if (
            ofertaData.tipoOfertaName === 'simple'
            || ofertaData.offer.simple.value
        ) {
            delete ofertaData.offer.from_to;
        } else delete ofertaData.offer.simple;

        delete ofertaData.tipoOfertaName;

        if (
            ofertaData.offer?.from_to?.from === 0
            || ofertaData.offer?.from_to?.to === 0
            || (ofertaData.offer?.simple?.type === 'VALUE'
                && ofertaData.offer?.simple?.value === 0)
        ) {
            Notification.error({
                title: 'Error',
                duration: 6000,
                description: (
                    <div>
                        <p>Valor da oferta precisa ser maior do que 0</p>
                    </div>
                ),
            });
        } else if (
            ofertaData.offer?.simple?.type === 'PERCENTUAL'
            && ofertaData.offer?.simple?.value >= 100
        ) {
            Notification.error({
                title: 'Error',
                duration: 6000,
                description: (
                    <div>
                        <p>Valor do desconto deve ser menor do que 100%</p>
                    </div>
                ),
            });
        } else {
            const resp = showEditOferta
                ? await updateOferta(id, ofertaData, ofertaSelecionada)
                : await saveOferta(id, ofertaData);
            if (resp.status >= 200 && resp.status < 300) {
                listOfertas();
                closeOferta();
            }
        }
    };

    const idsLojasParticipantes = lojasParticipantes?.content?.map(
        (item) => item.store_id_external,
    );

    const openEditOferta = async (oferta) => {
        setShowEditOferta(true);
        setOfertaSelecionada(oferta.offer_id);
        setShowModalCadastroOfertas(true);
    };

    const removeOferta = (id) => {
        setDeleteMessage(
            'Você está tentando deletar uma oferta. Você confirma essa ação?',
        );
        setShowModalConfirmacaoExclusao(true);
        setOfertaToDelete(id);
    };

    const closeExclusao = () => {
        setDeleteMessage('');
        setShowModalConfirmacaoExclusao(false);
        setOfertaToDelete(null);
    };

    const confirmExclusao = () => {
        deleteProdutoOferta(id, ofertaToDelete).then(() => {
            Notification.success({
                title: 'Notificação',
                duration: 6000,
                description: (
                    <div>
                        <p>Oferta excluída com sucesso.</p>
                    </div>
                ),
            });
            listOfertas();
            closeExclusao();
        });
    };

    const next = async () => {
        if (campanha.status === 'CURRENT' || campanha.status === 'CLOSED') {
            history.push(`/ipa/campanhas/cadastro/extracao-precos/${id}`);
        } else {
            const resp = await programarOferta(id, 'SCHEDULED');
            if (resp) {
                history.push(`/ipa/campanhas/cadastro/extracao-precos/${id}`);
            }
        }
    };

    useEffect(() => {
        listCampaign();
        listLojasParticipantes();
        listBlocosOfertas();
    }, []);

    useEffect(() => {
        listOfertas();
    }, [page, size]);

    return (
        <Container className="main-container cadastro-ofertas-page">
            <Steps
                rota={history.location.pathname}
                campanhaId={id}
                status={campanha?.status}
                lojas={lojasParticipantes?.content?.length}
            />
            <LoadingSpiner size="md" />
            <Header
                history={history}
                id={id}
                name={campanha?.name}
                status={campanha?.status}
                backToCampaign={back}
                startingDate={campanha?.starting_date}
                expirationDate={campanha?.expiration_date}
                blocosOfertas={blocosOfertas?.totalElements}
                lojasParticipantes={lojasParticipantes?.totalElements}
                ofertas={ofertas?.totalElements}
            />
            <Content className="ofertas">
                <FlexboxGrid justify="space-between" align="middle">
                    <FlexboxGrid.Item>
                        <h3>Ofertas</h3>
                    </FlexboxGrid.Item>
                    {!ofertas?.content?.length ? null : (
                        <FlexboxGrid.Item>
                            <Button
                                title="Criar oferta"
                                block
                                appearance="primary"
                                onClick={() => setShowModalCadastroOfertas(true)}
                                disabled={
                                    campanha.status === 'CURRENT'
                                    || campanha.status === 'CLOSED'
                                }
                            >
                                CRIAR OFERTA
                            </Button>
                        </FlexboxGrid.Item>
                    )}

                    <FlexboxGrid.Item
                        className="ofertas-container"
                        colspan={24}
                    >
                        {!ofertas?.content?.length ? (
                            <FlexboxGrid
                                className="no-data"
                                justify="center"
                                align="middle"
                            >
                                <FlexboxGrid.Item>
                                    <p>Você ainda não criou nenhuma oferta</p>
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item>
                                    <Button
                                        title="Criar bloco de oferta"
                                        block
                                        appearance="primary"
                                        onClick={() => setShowModalCadastroOfertas(true)}
                                        disabled={
                                            campanha.status === 'CURRENT'
                                            || campanha.status === 'CLOSED'
                                        }
                                    >
                                        CRIAR OFERTA
                                    </Button>
                                </FlexboxGrid.Item>
                            </FlexboxGrid>
                        ) : (
                            <div className="campanhas-table-container">
                                <Table
                                    className="table"
                                    data={ofertas.content}
                                    autoHeight
                                >
                                    {colunasTableOfertas.map((coluna) => (!coluna.dataKey ? (
                                        <Column
                                            width={120}
                                            fixed="right"
                                            key={Math.random()}
                                        >
                                            <HeaderCell />

                                            <Cell className="table__edit-button">
                                                {(rowData) => (
                                                    <span className="mg-5">
                                                        <IconButton
                                                            title="Editar"
                                                            icon={
                                                                <Icon icon="edit2" />
                                                                }
                                                            size="md"
                                                            onClick={() => openEditOferta(
                                                                        rowData,
                                                                    )}
                                                            disabled={
                                                                    campanha.status
                                                                        === 'CURRENT'
                                                                    || campanha.status
                                                                        === 'CLOSED'
                                                                }
                                                        />
                                                        <IconButton
                                                            title="Deletar"
                                                            size="md"
                                                            icon={
                                                                <Icon icon="trash2" />
                                                                }
                                                            onClick={() => removeOferta(
                                                                        rowData.product_uuid,
                                                                    )}
                                                            disabled={
                                                                    campanha.status
                                                                        === 'CURRENT'
                                                                    || campanha.status
                                                                        === 'CLOSED'
                                                                }
                                                        />
                                                    </span>
                                                    )}
                                            </Cell>
                                        </Column>
                                        ) : (
                                            <Column
                                                flexGrow={coluna.flexGrow}
                                                sortable={coluna.sortable}
                                                align={coluna.align}
                                                fixed={coluna.fixed}
                                                width={coluna.width}
                                                key={Math.random()}
                                            >
                                                <HeaderCell>
                                                    <Whisper
                                                        placement="bottom"
                                                        trigger="hover"
                                                        speaker={(
                                                            <Tooltip>
                                                                {
                                                                    coluna.headerCell
                                                                }
                                                            </Tooltip>
                                                          )}
                                                    >
                                                        <span>
                                                            {coluna.headerCell}
                                                        </span>
                                                    </Whisper>
                                                </HeaderCell>
                                                {coluna.dataKey
                                                === 'offer_type' ? (
                                                    <Cell
                                                        dataKey={coluna.dataKey}
                                                    >
                                                        {(rowData) => (rowData.offer_type
                                                            === 'SIMPLE'
                                                                ? 'Simples'
                                                                : 'De X por Y')}
                                                    </Cell>
                                                ) : coluna.dataKey === 'pmz' ? (
                                                    <Cell
                                                        dataKey={coluna.dataKey}
                                                    >
                                                        {(rowData) => (rowData.pmz ? (
                                                            <CurrencyFormat
                                                                className="currency"
                                                                fixedDecimalScale
                                                                decimalScale={
                                                                        2
                                                                    }
                                                                value={
                                                                        rowData.pmz
                                                                    }
                                                                displayType="text"
                                                                prefix="R$"
                                                                decimalSeparator=","
                                                                thousandSeparator="."
                                                            />
                                                            ) : (
                                                                '--'
                                                            ))}
                                                    </Cell>
                                                ) : coluna.dataKey
                                                  === 'retail_price' ? (
                                                      <Cell
                                                          dataKey={coluna.dataKey}
                                                      >
                                                          {(rowData) => (rowData.retail_price ? (
                                                              <CurrencyFormat
                                                                  className="currency"
                                                                  fixedDecimalScale
                                                                  decimalScale={
                                                                        2
                                                                    }
                                                                  value={
                                                                        rowData.retail_price
                                                                    }
                                                                  displayType="text"
                                                                  prefix="R$"
                                                                  decimalSeparator=","
                                                                  thousandSeparator="."
                                                              />
                                                            ) : (
                                                                '--'
                                                            ))}
                                                      </Cell>
                                                ) : coluna.dataKey
                                                  === 'offer_final_price' ? (
                                                      <Cell
                                                          dataKey={coluna.dataKey}
                                                      >
                                                          {(rowData) => (rowData.offer_final_price ? (
                                                              <CurrencyFormat
                                                                  className="currency"
                                                                  fixedDecimalScale
                                                                  decimalScale={
                                                                        2
                                                                    }
                                                                  value={
                                                                        rowData.offer_final_price
                                                                    }
                                                                  displayType="text"
                                                                  prefix="R$"
                                                                  decimalSeparator=","
                                                                  thousandSeparator="."
                                                              />
                                                            ) : (
                                                                '--'
                                                            ))}
                                                      </Cell>
                                                ) : coluna.dataKey
                                                  === 'offer_discount_percentage' ? (
                                                      <Cell
                                                          dataKey={coluna.dataKey}
                                                      >
                                                          {(rowData) => (rowData.offer_discount_percentage ? (
                                                              <CurrencyFormat
                                                                  className="currency"
                                                                  fixedDecimalScale
                                                                  decimalScale={
                                                                        2
                                                                    }
                                                                  value={
                                                                        rowData.offer_discount_percentage
                                                                    }
                                                                  displayType="text"
                                                                  suffix="%"
                                                                  decimalSeparator=","
                                                                  thousandSeparator="."
                                                              />
                                                            ) : (
                                                                '--'
                                                            ))}
                                                      </Cell>
                                                ) : coluna.dataKey
                                                  === 'competitiveness' ? (
                                                      <Cell
                                                          dataKey={coluna.dataKey}
                                                      >
                                                          {(rowData) => (rowData.competitiveness ? (
                                                              <CurrencyFormat
                                                                  className="currency"
                                                                  fixedDecimalScale
                                                                  decimalScale={
                                                                        2
                                                                    }
                                                                  value={
                                                                        rowData.competitiveness
                                                                    }
                                                                  displayType="text"
                                                                  suffix="%"
                                                                  decimalSeparator=","
                                                                  thousandSeparator="."
                                                              />
                                                            ) : (
                                                                '--'
                                                            ))}
                                                      </Cell>
                                                ) : coluna.dataKey
                                                  === 'margin' ? (
                                                      <Cell
                                                          dataKey={coluna.dataKey}
                                                      >
                                                          {(rowData) => (rowData.margin ? (
                                                              <CurrencyFormat
                                                                  className="currency"
                                                                  fixedDecimalScale
                                                                  decimalScale={
                                                                        2
                                                                    }
                                                                  value={
                                                                        rowData.margin
                                                                    }
                                                                  displayType="text"
                                                                  suffix="%"
                                                                  decimalSeparator=","
                                                                  thousandSeparator="."
                                                              />
                                                            ) : (
                                                                '--'
                                                            ))}
                                                      </Cell>
                                                ) : (
                                                    <Cell
                                                        dataKey={coluna.dataKey}
                                                    />
                                                )}
                                            </Column>
                                        )))}
                                </Table>
                                <Pagination
                                    lengthMenu={[
                                        {
                                            value: 20,
                                            label: 20,
                                        },
                                        {
                                            value: 40,
                                            label: 40,
                                        },
                                    ]}
                                    activePage={ofertas.number + 1}
                                    displayLength={ofertas.size}
                                    total={ofertas.totalElements}
                                    onChangePage={(value) => setPage(value - 1)}
                                    onChangeLength={(value) => setSize(value)}
                                />
                            </div>
                        )}
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </Content>
            <Content className="navegacao-ofertas">
                <FlexboxGrid justify="space-between" colspan={24}>
                    <FlexboxGrid.Item>
                        <Button appearance="link" onClick={goToLojas}>
                            <ArrowBackIcon />
                            {' '}
                            Voltar para lojas participantes
                        </Button>
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item>
                        <FlexboxGrid>
                            {campanha.status === 'DRAFT' ? (
                                <FlexboxGrid.Item
                                    style={{ marginRight: '16px' }}
                                >
                                    <Button appearance="ghost" onClick={draft}>
                                        SALVAR RASCUNHO
                                    </Button>
                                </FlexboxGrid.Item>
                            ) : null}
                            <FlexboxGrid.Item>
                                <Button
                                    appearance="primary"
                                    disabled={!ofertas.content.length}
                                    onClick={() => (campanha?.status === 'DRAFT'
                                            ? setShowModalProgramarCampanha(!showModalProgramarCampanha)
                                            : next())}
                                >
                                    <ArrowForwardIcon />
                                    {' '}
                                    {campanha.status === 'CURRENT'
                                    || campanha.status === 'CLOSED'
                                        ? 'EXTRAÇÃO DE PREÇOS'
                                        : campanha.status === 'SCHEDULED'
                                        ? 'PROGRAMAR ALTERAÇÕES'
                                        : 'PROGRAMAR CAMPANHA'}
                                </Button>
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </Content>
            <ModalCadastroOferta
                show={showModalCadastroOfertas}
                confirmOferta={confirmOferta}
                closeOferta={closeOferta}
                id={id}
                idsLojas={idsLojasParticipantes}
                blocosOfertas={blocosOfertas?.content?.map((item) => ({
                    label: item.name,
                    value: item.id,
                }))}
                setOferta={setOferta}
                editOferta={showEditOferta}
                ofertaId={ofertaSelecionada}
            />
            <ModalConfirmacao
                show={showModalConfirmacaoExclusao}
                onConfirm={confirmExclusao}
                onCancel={closeExclusao}
                title="Deletar Oferta"
                message={deleteMessage}
                confirmButton="Confirmar"
                cancelButton="Cancelar"
            />
            <ModalProgramarCampanha
                show={showModalProgramarCampanha}
                campanha={campanha}
                onCancel={() => setShowModalProgramarCampanha((current) => !current)}
                onConfirm={next}
            />
        </Container>
    );
};

export default withRouter(Ofertas);
