import React, { HtmlHTMLAttributes, Ref, forwardRef } from 'react';
import { Button, Tooltip, Whisper } from 'rsuite';
import { Icon } from '..';
import { FilterButtonProps } from '../..';

export const IconButton = forwardRef(
    (
        {
            icon = 'MdSelectAll',
            skin,
            placeholder,
            onClick,
            className = '',
            ...props
        }: FilterButtonProps,
        ref: Ref<HtmlHTMLAttributes<HTMLButtonElement>>,
    ) => {
        const Component = (
            <Button
                ref={ref}
                onClick={onClick}
                className={[
                    'quick-action-filter__button__container',
                    'quick-action-filter__button',
                    `quick-action-filter__button-${skin}`,
                    className,
                ].join(' ')}
                {...props}
            >
                <Icon icon={icon} />
            </Button>
        );
        if (placeholder) {
            return (
                <Whisper
                    delayShow={0.2}
                    trigger="hover"
                    placement="bottom"
                    speaker={<Tooltip>{placeholder}</Tooltip>}
                >
                    {Component}
                </Whisper>
            );
        }

        return Component;
    },
);
