import { useContext } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getGanhoEmMargemTotal } from '../services';
// @ts-ignore
import { FiltrosContextMargem } from '../Pages/MargemPraticadaGeral/index.tsx';
import { format } from 'date-fns';

export const useGanhoEmMargemTotal = () => {
    const filtros = useContext(FiltrosContextMargem);

    const listaGanhoEmMargemTotal = async () => {
        const params = {
            filters: [
                {
                    filter: 'STOREID',
                    values: filtros?.lojasSelecionadas || null,
                },
                {
                    filter: 'PRODUCTID',
                    values: filtros?.produtosSelecionados || null,
                },
                {
                    filter: 'STARTDATE',
                    values: filtros?.periodoSelecionado?.length
                        ? [format(filtros.periodoSelecionado[0], 'yyyy-MM-dd')]
                        : null,
                },
                {
                    filter: 'ENDDATE',
                    values: filtros?.periodoSelecionado?.length
                        ? [format(filtros.periodoSelecionado[1], 'yyyy-MM-dd')]
                        : null,
                },
                {
                    filter: 'STORETYPE',
                    values: filtros?.tipoLojasSelecionadas || null,
                },
                {
                    filter: 'CATEGORYLEVEL1',
                    values: filtros?.categoriaNivel1Selecionadas || null,
                },
                {
                    filter: 'CATEGORYLEVEL2',
                    values: filtros?.categoriaNivel2Selecionadas || null,
                },
            ],
        };
        const resp = await getGanhoEmMargemTotal(params);
        const marginGain = Object.keys(resp[0])[2]?.split('.');
        return {
            marginGain: parseFloat(
                resp[resp.length - 1][`${marginGain[0]}.${marginGain[1]}`]
            )?.toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL',
            }),
            ...(resp.length > 1 && {
                diff:
                    100 *
                    Math.abs(
                        (parseFloat(
                            resp[resp.length - 2][
                                `${marginGain[0]}.${marginGain[1]}`
                            ]
                        ) -
                            parseFloat(
                                resp[resp.length - 1][
                                    `${marginGain[0]}.${marginGain[1]}`
                                ]
                            )) /
                            ((parseFloat(
                                resp[resp.length - 2][
                                    `${marginGain[0]}.${marginGain[1]}`
                                ]
                            ) +
                                parseFloat(
                                    resp[resp.length - 1][
                                        `${marginGain[0]}.${marginGain[1]}`
                                    ]
                                )) /
                                2)
                    ),
            }),
            ...(resp.length > 1 && {
                lastMonth: parseFloat(
                    resp[resp.length - 2][`${marginGain[0]}.${marginGain[1]}`]
                )?.toLocaleString('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                }),
            }),
        };
    };

    const { isLoading, isError, data, error } = useQuery(
        ['listaGanhoEmMargemTotal', filtros],
        listaGanhoEmMargemTotal,
        {
            retry: false,
            cacheTime: 6 * 180 * 1000,
            staleTime: 6 * 180 * 1000,
        }
    );

    return {
        isLoading,
        isError,
        data,
        error,
    };
};
