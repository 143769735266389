/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable import/extensions */
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import React, { Component, useEffect, useState } from 'react';
import CurrencyFormat from 'react-currency-format';
import { connect, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import {
    Button,
    ButtonToolbar,
    CheckPicker,
    Col,
    Container,
    Content,
    FlexboxGrid,
    Icon,
    IconButton,
    InputPicker,
    Nav,
    Popover,
    Row,
    Table,
    Tag,
    Tooltip,
    Whisper,
} from 'rsuite';
import {
    addFiltro,
    clearFiltros,
    deleteFiltro,
    search,
    searchFamiliaProduto,
    searchSegmentos,
    selectValue,
} from '../../../actions/actionsPrecificacaoManual';
import { LoadingSpiner } from '../../../components/LoadingSpinner';
import { getProdutos, searchFiltroProdutos } from './services.js';

const {
    Column, HeaderCell, Cell, Pagination,
} = Table;

class PrecificacaoManual extends Component {
    constructor(props) {
        super(props);

        this.state = {
            page: 0,
            size: 20,
            data: [],
            // listaProdutos: null,
        };

        this.listProdutos = this.listProdutos.bind(this);
        this.getModelFiltros = this.getModelFiltros.bind(this);
        this.getItensFiltroProdutoId = this.getItensFiltroProdutoId.bind(this);
        this.handleDeleteFiltro = this.handleDeleteFiltro.bind(this);
        this.handleChangeLength = this.handleChangeLength.bind(this);
        this.handleChangePage = this.handleChangePage.bind(this);
        this.handleEdit = this.handleEdit.bind(this);
        this.handleChangeFiltro = this.handleChangeFiltro.bind(this);
        this.handleFiltroFamilia = this.handleFiltroFamilia.bind(this);
    }

    componentDidMount() {
        const { clearFiltros, searchSegmentos } = this.props;

        clearFiltros();

        searchSegmentos().then(() => {
            this.listProdutos();
            this.getItensFiltroProdutoId('');
        });
    }

    handleChangePage(e) {
        this.setState(
            {
                page: e - 1,
            },
            this.listProdutos,
        );
    }

    handleChangeLength(e) {
        this.setState(
            {
                size: e,
            },
            this.listProdutos,
        );
    }

    handleEdit(item) {
        const { history } = this.props;
        history.push(`/ipa/precificacao-produto/${item.productId}`);
    }

    handleChangeFiltro(value, name) {
        const { selectValue, filtrosAdicionados, search } = this.props;

        selectValue(value, name);

        setTimeout(() => {
            this.listProdutos();
            filtrosAdicionados.forEach((item) => {
                search(item.name, this.getModelFiltros(), item.name);
            });
        }, 100);
    }

    handleDeleteFiltro(index, name) {
        const { deleteFiltro } = this.props;

        deleteFiltro(index, name);

        setTimeout(() => {
            this.listProdutos();
        }, 100);
    }

    handleFiltroFamilia(index, name) {
        const { searchFamiliaProduto } = this.props;

        searchFamiliaProduto(index, name);

        setTimeout(() => {
            this.listProdutos();
        }, 100);
    }

    getModelFiltros(params) {
        const { filtrosData, filtros } = this.props;
        const { page, size } = this.state;
        return {
            abcClass: filtrosData.abcClass.value
                ? filtrosData.abcClass.value
                : null,
            description: filtrosData.description.value
                ? filtrosData.description.value
                : null,
            productId: filtrosData.productId.value
                ? filtrosData.productId.value
                : null,
            productFamilyIds: filtrosData.productFamilyId.value
                ? filtrosData.productFamilyId.value
                : null,
            page: params?.resetPage ? 0 : page || 0,
            size: size || 20,
            categoryLevel1:
                filtros.findIndex((item) => item.nivel === 1) !== -1
                    ? filtrosData[
                        filtros[filtros.findIndex((item) => item.nivel === 1)]
                            .name
                    ].value
                    : null,
            categoryLevel2:
                filtros.findIndex((item) => item.nivel === 2) !== -1
                    ? filtrosData[
                        filtros[filtros.findIndex((item) => item.nivel === 2)]
                            .name
                    ].value
                    : null,
            categoryLevel3:
                filtros.findIndex((item) => item.nivel === 3) !== -1
                    ? filtrosData[
                        filtros[filtros.findIndex((item) => item.nivel === 3)]
                            .name
                    ].value
                    : null,
            categoryLevel4:
                filtros.findIndex((item) => item.nivel === 4) !== -1
                    ? filtrosData[
                        filtros[filtros.findIndex((item) => item.nivel === 4)]
                            .name
                    ].value
                    : null,
            categoryLevel5:
                filtros.findIndex((item) => item.nivel === 5) !== -1
                    ? filtrosData[
                        filtros[filtros.findIndex((item) => item.nivel === 5)]
                            .name
                    ].value
                    : null,
            categoryLevel6:
                filtros.findIndex((item) => item.nivel === 6) !== -1
                    ? filtrosData[
                        filtros[filtros.findIndex((item) => item.nivel === 6)]
                            .name
                    ].value
                    : null,
            categoryLevel7:
                filtros.findIndex((item) => item.nivel === 7) !== -1
                    ? filtrosData[
                        filtros[filtros.findIndex((item) => item.nivel === 7)]
                            .name
                    ].value
                    : null,
        };
    }

    getItensFiltroProdutoId(query) {
        searchFiltroProdutos({ query }).then((response) => {
            if (response) {
                this.setState({
                    produtos: response.data.map((item) => ({
                        label: `${item.product_id} - ${item.description}`,
                        product_id: item.product_id,
                        gtin: item.gtin,
                        description: item.description,
                        value: item.product_id,
                    })),
                });
            }
        });
    }

    listProdutos(params) {
        const data = this.getModelFiltros(params);

        getProdutos(data).then((response) => {
            this.setState({
                data: response,
            });
            return true;
        });
    }

    render() {
        const { filtrosAdicionados } = this.props;
        const { produtos, data } = this.state;
        return (
            <Container className="main-container precificacao-manual-page">
                <Content className="container" style={{ flex: 'none' }}>
                    <FlexboxGrid>
                        <FlexboxGrid.Item
                            style={{
                                marginRight: '10px',
                                marginBottom: '15px',
                            }}
                        >
                            <AddFiltroComponent />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item
                            style={{ marginBottom: '15px' }}
                            componentClass={Col}
                            lg={6}
                            md={6}
                            sm={24}
                            xs={24}
                        >
                            <InputPicker
                                block
                                valueKey="product_id"
                                className="filtros__input"
                                filter={false}
                                cleanable
                                searchable
                                data={produtos}
                                name="gtin"
                                placeholder="Código do produto"
                                locale={{
                                    noResultsText: 'Nenhum item encontrado',
                                }}
                                onSearch={this.getItensFiltroProdutoId}
                                onSelect={(value) => this.handleChangeFiltro(value, 'productId')}
                                onClean={() => this.handleChangeFiltro('', 'productId')}
                            />
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                    <FlexboxGrid>
                        {filtrosAdicionados.length
                            ? filtrosAdicionados.map((filtro, index) => (
                                <FiltroCustomizado
                                    key={filtro.name}
                                    filtro={filtro}
                                    handleDeleteFiltro={
                                        this.handleDeleteFiltro
                                    }
                                    index={index}
                                    searchDataTable={this.listProdutos}
                                />
                            ))
                            : ''}
                    </FlexboxGrid>
                </Content>
                <Content>
                    <FlexboxGrid>
                        <FlexboxGrid.Item colspan={24}>
                            <LoadingSpiner size="md" />

                            {data && (
                                <FlexboxGrid>
                                    <FlexboxGrid.Item colspan={24}>
                                        <span className="title-table">
                                            Comparação com concorrentes
                                        </span>
                                        <Table
                                            className="table-row-principal-header"
                                            data={data?.content}
                                            height={1000}
                                        >
                                            <Column width={650}>
                                                <HeaderCell>Produto</HeaderCell>
                                                <Cell>
                                                    {(rowData) => {
                                                        const codigoProduto = [
                                                            ...(rowData
                                                                .productId
                                                                ?.length
                                                                ? [
                                                                    rowData.productId,
                                                                ]
                                                                : []),
                                                        ];
                                                        const familiaProduto = [
                                                            ...(rowData
                                                                .productFamilyId
                                                                ?.length
                                                                ? [
                                                                    rowData.productFamilyId,
                                                                ]
                                                                : []),
                                                        ];
                                                        const descricaoProduto
                                                            = [
                                                                ...(rowData
                                                                    .description
                                                                    ?.length
                                                                    ? [
                                                                        rowData.description,
                                                                    ]
                                                                    : []),
                                                            ];
                                                        return (
                                                            <span>
                                                                {familiaProduto?.length ? (
                                                                    <Whisper
                                                                        trigger="hover"
                                                                        placement="top"
                                                                        speaker={(
                                                                            <Tooltip>
                                                                                Família
                                                                                {' '}
                                                                                {
                                                                                    familiaProduto
                                                                                }
                                                                            </Tooltip>
                                                                        )}
                                                                    >
                                                                        <GroupWorkIcon
                                                                            style={{
                                                                                fontSize: 12,
                                                                            }}
                                                                        />
                                                                    </Whisper>
                                                                ) : null}
                                                                {' '}
                                                                <strong>
                                                                    {
                                                                        codigoProduto
                                                                    }
                                                                </strong>
                                                                {' '}
                                                                -
                                                                {' '}
                                                                {
                                                                    descricaoProduto
                                                                }
                                                            </span>
                                                        );
                                                    }}
                                                </Cell>
                                            </Column>
                                            <Column align="left" width={150}>
                                                <HeaderCell>PMZ</HeaderCell>
                                                <Cell>
                                                    {(rowData) => (rowData.pmz ? (
                                                        <CurrencyFormat
                                                            fixedDecimalScale
                                                            decimalScale={2}
                                                            value={
                                                                rowData.pmz
                                                            }
                                                            displayType="text"
                                                            prefix="R$"
                                                            decimalSeparator=","
                                                            thousandSeparator="."
                                                        />
                                                    ) : null)}
                                                </Cell>
                                            </Column>
                                            <Column align="left" width={150}>
                                                <HeaderCell>
                                                    Preço Vigente
                                                </HeaderCell>
                                                <Cell dataKey="preco-vigente">
                                                    {(rowData) => (rowData.avgPrice ? (
                                                        <CurrencyFormat
                                                            fixedDecimalScale
                                                            decimalScale={2}
                                                            value={
                                                                rowData.avgPrice
                                                            }
                                                            displayType="text"
                                                            prefix="R$"
                                                            decimalSeparator=","
                                                            thousandSeparator="."
                                                        />
                                                    ) : (
                                                        ''
                                                    ))}
                                                </Cell>
                                            </Column>
                                            <Column>
                                                <HeaderCell>
                                                    Preço igual ou menor
                                                </HeaderCell>
                                                <Cell>
                                                    {(rowData) => (rowData.storePositioning
                                                        ?.priceGT ? (
                                                        <CurrencyFormat
                                                            fixedDecimalScale
                                                            decimalScale={2}
                                                            value={
                                                                rowData
                                                                    .storePositioning
                                                                    ?.priceGT
                                                            }
                                                            displayType="text"
                                                            prefix="R$"
                                                            decimalSeparator=","
                                                            thousandSeparator="."
                                                        />
                                                    ) : (
                                                        ''
                                                    ))}
                                                </Cell>
                                            </Column>
                                            <Column align="center">
                                                <HeaderCell>
                                                    Preço maior
                                                </HeaderCell>
                                                <Cell>
                                                    {(rowData) => (rowData.storePositioning
                                                        ?.priceLTE ? (
                                                        <CurrencyFormat
                                                            fixedDecimalScale
                                                            decimalScale={2}
                                                            value={
                                                                rowData
                                                                    .storePositioning
                                                                    ?.priceLTE
                                                            }
                                                            displayType="text"
                                                            prefix="R$"
                                                            decimalSeparator=","
                                                            thousandSeparator="."
                                                        />
                                                    ) : (
                                                        ''
                                                    ))}
                                                </Cell>
                                            </Column>
                                            <Column align="center" width={150}>
                                                <HeaderCell>
                                                    Sem informação
                                                </HeaderCell>
                                                <Cell>
                                                    {(rowData) => rowData.storePositioning
                                                        ?.withoutPriceInformation}
                                                </Cell>
                                            </Column>
                                            <Column align="center" width={180}>
                                                <HeaderCell>
                                                    Margem média
                                                </HeaderCell>
                                                <Cell>
                                                    {(rowData) => (rowData.margin ? (
                                                        <CurrencyFormat
                                                            fixedDecimalScale
                                                            value={
                                                                rowData.margin
                                                            }
                                                            decimalScale={2}
                                                            displayType="text"
                                                            suffix="%"
                                                            decimalSeparator=","
                                                            thousandSeparator="."
                                                        />
                                                    ) : null)}
                                                </Cell>
                                            </Column>
                                            <Column verticalAlign="middle">
                                                <HeaderCell />
                                                <Cell>
                                                    {(rowData) => (
                                                        <span className="cell-options">
                                                            <Button
                                                                appearance="primary"
                                                                onClick={() => this.handleEdit(
                                                                    rowData,
                                                                )}
                                                            >
                                                                {' '}
                                                                Precificar
                                                                {' '}
                                                            </Button>
                                                        </span>
                                                    )}
                                                </Cell>
                                            </Column>
                                        </Table>
                                        <Pagination
                                            lengthMenu={[
                                                {
                                                    value: 20,
                                                    label: 20,
                                                },
                                                {
                                                    value: 40,
                                                    label: 40,
                                                },
                                            ]}
                                            activePage={data.number + 1}
                                            displayLength={data.size}
                                            total={data.totalElements}
                                            onChangePage={this.handleChangePage}
                                            onChangeLength={
                                                this.handleChangeLength
                                            }
                                        />
                                    </FlexboxGrid.Item>
                                </FlexboxGrid>
                            )}
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </Content>
            </Container>
        );
    }
}
const AddFiltroComponent = () => (
    <Whisper
        trigger="click"
        placement="bottomStart"
        speaker={<Speaker className="popover-filtro-dinamico" />}
    >
        <ButtonToolbar>
            <IconButton icon={<Icon icon="plus" />}>
                Adicionar Filtros
            </IconButton>
        </ButtonToolbar>
    </Whisper>
);

let Speaker = ({
    tiposFiltro,
    onMouseEnter,
    onMouseLeave,
    className,
    style,
    filtrosData,
    filtros,
    filtrosAdicionados,
    search,
    addFiltro,
}) => {
    const handleClick = (eventKey, b) => {
        const data
            = b.target.name === 'productFamilyId'
                ? ''
                : {
                    query: '',
                    abcClass: filtrosData.abcClass.value
                        ? filtrosData.abcClass.value
                        : null,
                    description: filtrosData.description.value
                        ? filtrosData.description.value
                        : null,
                    productId: filtrosData.productId.value
                        ? filtrosData.productId.value
                        : null,
                    productFamilyIds: filtrosData.productFamilyId.value
                        ? filtrosData.productFamilyId
                        : null,
                    categoryLevel1:
                        filtros.findIndex((item) => item.nivel === 1) !== -1
                            ? filtrosData[
                                filtros[
                                    filtros.findIndex(
                                        (item) => item.nivel === 1,
                                    )
                                ].name
                            ].value
                            : null,
                    categoryLevel2:
                        filtros.findIndex((item) => item.nivel === 2) !== -1
                            ? filtrosData[
                                filtros[
                                    filtros.findIndex(
                                        (item) => item.nivel === 2,
                                    )
                                ].name
                            ].value
                            : null,
                    categoryLevel3:
                        filtros.findIndex((item) => item.nivel === 3) !== -1
                            ? filtrosData[
                                filtros[
                                    filtros.findIndex(
                                        (item) => item.nivel === 3,
                                    )
                                ].name
                            ].value
                            : null,
                    categoryLevel4:
                        filtros.findIndex((item) => item.nivel === 4) !== -1
                            ? filtrosData[
                                filtros[
                                    filtros.findIndex(
                                        (item) => item.nivel === 4,
                                    )
                                ].name
                            ].value
                            : null,
                    categoryLevel5:
                        filtros.findIndex((item) => item.nivel === 5) !== -1
                            ? filtrosData[
                                filtros[
                                    filtros.findIndex(
                                        (item) => item.nivel === 5,
                                    )
                                ].name
                            ].value
                            : null,
                    categoryLevel6:
                        filtros.findIndex((item) => item.nivel === 6) !== -1
                            ? filtrosData[
                                filtros[
                                    filtros.findIndex(
                                        (item) => item.nivel === 6,
                                    )
                                ].name
                            ].value
                            : null,
                    categoryLevel7:
                        filtros.findIndex((item) => item.nivel === 7) !== -1
                            ? filtrosData[
                                filtros[
                                    filtros.findIndex(
                                        (item) => item.nivel === 7,
                                    )
                                ].name
                            ].value
                            : null,
                };

        if (
            filtrosAdicionados.findIndex(
                (item) => item.name === b.target.name,
            ) === -1
        ) {
            search(eventKey, data, b.target.name);
            addFiltro(
                filtros[
                filtros.findIndex((item) => item.name === b.target.name)
                ],
            );
        }
    };

    return (
        <Popover
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            className={className}
            style={style}
        >
            <Row className="popover-filtro-dinamico__row">
                {tiposFiltro.map((item) => (
                    <Col
                        md={12}
                        key={item.title}
                        className="popover-filtro-dinamico__col"
                    >
                        <CustomNav
                            title={item.title}
                            icon={item.icon}
                            itens={item.itens}
                            onSelect={handleClick}
                        />
                    </Col>
                ))}
            </Row>
        </Popover>
    );
};

const CustomNav = ({
    title, icon, itens, onSelect, activeKey,
}) => (
    <Nav
        activeKey={activeKey}
        vertical
        onSelect={onSelect}
        className="nav-filtro-dinamico"
    >
        <div className="nav-filtro-dinamico__title-div">
            <Icon icon={icon} />
            <p className="nav-filtro-dinamico__title">{title}</p>
        </div>
        {itens.map((item) => (
            <Nav.Item key={item.name} name={item.name} eventKey={item.name}>
                {item.title}
            </Nav.Item>
        ))}
    </Nav>
);

let FiltroCustomizado = ({
    filtro,
    index,
    handleDeleteFiltro,
    filtrosData,
    selectValue,
    filtrosAdicionados,
    searchDataTable,
    filtros,
}) => {
    const dispatch = useDispatch();

    const [data, setData] = useState({
        query: '',
        abcClass: filtrosData?.abcClass?.value
            ? filtrosData?.abcClass?.value
            : null,
        description: filtrosData?.description?.value
            ? filtrosData?.description?.value
            : null,
        productId: filtrosData?.productId?.value
            ? filtrosData?.productId?.value
            : null,
        productFamilyIds: filtrosData?.productFamilyId?.value
            ? filtrosData.productFamilyId
            : null,
        categoryLevel1:
            filtros?.findIndex((item) => item.nivel === 1) !== -1
                ? filtrosData[
                    filtros[filtros?.findIndex((item) => item.nivel === 1)]
                        ?.name
                ]?.value
                : null,
        categoryLevel2:
            filtros?.findIndex((item) => item.nivel === 2) !== -1
                ? filtrosData[
                    filtros[filtros?.findIndex((item) => item.nivel === 2)]
                        ?.name
                ]?.value
                : null,
        categoryLevel3:
            filtros?.findIndex((item) => item.nivel === 3) !== -1
                ? filtrosData[
                    filtros[filtros?.findIndex((item) => item.nivel === 3)]
                        ?.name
                ]?.value
                : null,
        categoryLevel4:
            filtros?.findIndex((item) => item.nivel === 4) !== -1
                ? filtrosData[
                    filtros[filtros?.findIndex((item) => item.nivel === 4)]
                        ?.name
                ]?.value
                : null,
        categoryLevel5:
            filtros?.findIndex((item) => item.nivel === 5) !== -1
                ? filtrosData[
                    filtros[filtros?.findIndex((item) => item.nivel === 5)]
                        ?.name
                ]?.value
                : null,
        categoryLevel6:
            filtros?.findIndex((item) => item.nivel === 6) !== -1
                ? filtrosData[
                    filtros[filtros?.findIndex((item) => item.nivel === 6)]
                        ?.name
                ]?.value
                : null,
        categoryLevel7:
            filtros?.findIndex((item) => item.nivel === 7) !== -1
                ? filtrosData[
                    filtros[filtros?.findIndex((item) => item.nivel === 7)]
                        ?.name
                ]?.value
                : null,
    });

    const handleSearch = () => {
        dispatch(search(filtro.name, data, filtro.name));
    };

    const handleSelect = (value, item, name) => {
        dispatch(selectValue(value, name));
    };

    const handleChange = () => {
        filtrosAdicionados.forEach((item) => {
            dispatch(search(item.name, data, item.name));
        });

        searchDataTable({ resetPage: true });
    };

    const handleClean = (value, e, item) => {
        setData((oldState) => ({ ...oldState, query: '' }));

        dispatch(selectValue('', item.name));
        dispatch(search(item.name, data, item.name));

        searchDataTable({ resetPage: true });
    };

    useEffect(() => {
        console.log(data.query);
        if (data.query) handleSearch();
    }, [data]);

    return (
        <FlexboxGrid.Item
            className="filtro-dinamico"
            componentClass={Col}
            lg={8}
            md={12}
        >
            <FlexboxGrid.Item
                componentClass={Col}
                className="filtro-dinamico__tag-wrapper"
            >
                <Tag className="filtro-dinamico__tag">{filtro.title}</Tag>
            </FlexboxGrid.Item>
            <FlexboxGrid.Item
                className="filtro-dinamico__input-section"
                componentClass={Col}
                lg={15}
                md={14}
                sm={24}
                xs={24}
            >
                {filtro.name === 'productFamilyId' ? (
                    <CheckPicker
                        style={{ marginRight: '0' }}
                        block
                        cleanable={filtro.cleanable}
                        searchable={filtro.searchable}
                        filter={filtro.filter}
                        data={
                            filtrosData && filtrosData[filtro.name]
                                ? filtrosData[filtro.name].lista
                                : []
                        }
                        value={
                            filtrosData && filtrosData[filtro.name]
                                ? filtrosData[filtro.name].value
                                : []
                        }
                        name={filtro.name}
                        placeholder={filtro.placeholder}
                        locale={filtro.locale}
                        onSelect={
                            filtro.onSelect
                                ? (value, i) => handleSelect(value, i, filtro.name)
                                : null
                        }
                        onSearch={
                            filtro.onSearch
                                ? (search) => setData((oldState) => ({
                                    ...oldState.data,
                                    query: search || '',
                                }))
                                : null
                        }
                        onChange={(value, e) => setTimeout(() => {
                            handleChange(value, e);
                        }, 100)}
                        onClean={(value, e) => handleClean(value, e, filtro)}
                    />
                ) : (
                    <InputPicker
                        style={{ marginRight: '0' }}
                        block
                        cleanable={filtro.cleanable}
                        searchable={filtro.searchable}
                        filter={filtro.filter}
                        data={
                            filtrosData && filtrosData[filtro.name]
                                ? filtrosData[filtro.name].lista
                                : []
                        }
                        value={
                            filtrosData && filtrosData[filtro.name]
                                ? filtrosData[filtro.name].value
                                : []
                        }
                        name={filtro.name}
                        placeholder={filtro.placeholder}
                        locale={filtro.locale}
                        onSelect={
                            filtro.onSelect
                                ? (value, i) => handleSelect(value, i, filtro.name)
                                : null
                        }
                        onSearch={
                            filtro.onSearch
                                ? (search) => setData((oldState) => ({
                                    ...oldState.data,
                                    query: search || '',
                                }))
                                : null
                        }
                        onChange={(value, e) => setTimeout(() => {
                            handleChange(value, e);
                        }, 100)}
                        onClean={(value, e) => handleClean(value, e, filtro)}
                    />
                )}
            </FlexboxGrid.Item>
            <FlexboxGrid.Item componentClass={Col} lg={2} md={2}>
                <IconButton
                    className="filtro-dinamico__delete-button"
                    icon={<Icon icon="trash2" />}
                    onClick={() => handleDeleteFiltro(index, filtro.name)}
                />
            </FlexboxGrid.Item>
        </FlexboxGrid.Item>
    );
};

const mapStateToProps = (store) => ({
    tiposFiltro: store.precificacaoManualDataReducer.tiposFiltro,
    filtros: store.precificacaoManualDataReducer.filtros,
    filtrosAdicionados: store.precificacaoManualDataReducer.filtrosAdicionados,
    filtrosData: store.precificacaoManualDataReducer.filtrosData,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
    {
        addFiltro,
        deleteFiltro,
        selectValue,
        search,
        searchSegmentos,
        searchFamiliaProduto,
        clearFiltros,
    },
    dispatch,
);

Speaker = connect(mapStateToProps, mapDispatchToProps)(Speaker);
FiltroCustomizado = connect(
    mapStateToProps,
    mapDispatchToProps,
)(FiltroCustomizado);
export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(PrecificacaoManual),
);
