import api from '../utils/API';

export const getEscopoTypes = (clienteCodigo) => api({
    url: `${process.env.REACT_APP_CADASTROS_API_URL}/escopos/cliente/fixed-range`,
    method: 'GET',
    expectToken: true,
    params: {
        clienteCodigo,
    },
}).then((resp) => resp.data);
