import React from 'react';
import { Button, Whisper, Tooltip, Icon } from 'rsuite';

const TopTenHeader = ({ title, subtitle, onClick, subHeader, rede, uf }) => (
    <div className="top-ten-header">
        <div className="main-title-header">
            <p className="top-ten-header-title">{title}</p>
            <p className="top-ten-header-subtitle">{subtitle}</p>
            <Button appearance="link" onClick={onClick}>
                Entenda como calculamos
            </Button>
        </div>
        {subHeader ? (
            <div className="sub-header">
                <div className="flex-wrapper">
                    <p>Referência dos resultados</p>
                    <Whisper
                        speaker={
                            <Tooltip>
                                Para alterar essas informações selecione outra
                                loja no mapa
                            </Tooltip>
                        }
                    >
                        <span>
                            <Icon icon="info" />
                        </span>
                    </Whisper>
                </div>
                <div className="flex-wrapper">
                    <div className="flex-wrapper-pill first-wrapper">
                        <p className="reference-title">REDE</p>
                        <div className="pill-reference">{rede}</div>
                    </div>
                    <div className="flex-wrapper-pill">
                        <p className="reference-title">UF</p>
                        <div className="pill-reference">{uf}</div>
                    </div>
                </div>
            </div>
        ) : null}
    </div>
);

export default TopTenHeader;
