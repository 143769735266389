import React from 'react';
import { Loader } from 'rsuite';
import { CardLojas } from '../../../../../components';
import { Card } from '../../../../../components/atoms/Card';
import { useOnboarding } from '../../../../../context';
import { Layout } from '../layout';
import './style.scss';

export const Loja = () => {
    const { data, increment } = useOnboarding();

    const handleClick = () => {
        increment();
    };

    return (
        <>
            <Layout.Title>Bem vindo ao IPA Lite</Layout.Title>
            <Layout.Content>
                <Layout.Section className="store__intro">
                    <div>
                        <p>
                            Experimente <b>gratuitamente</b> o IPA Lite e
                            descubra como você pode se destacar no mercado e
                            maximizar seus lucros. Você terá acesso a{' '}
                            <b>dados de concorrência atualizados</b>, permitindo
                            que você aprimore sua imagem de preço em relação aos
                            seus principais produtos.
                        </p>
                        <p className="font-size-75-regular">
                            Avalie gratuitamente no périodo combinado e após
                            este prazo, o valor investido será de R$ 99,90 por
                            mês. Mas não se preocupe, cancele quando quiser, sem
                            multa ou taxas.
                        </p>
                    </div>
                </Layout.Section>
                <Layout.Section className="store__select">
                    <Layout.Title>
                        Para iniciar, confirme a sua loja abaixo
                    </Layout.Title>
                    <div className="store__stores__section">
                        {data.stores ? (
                            data.stores?.map((store) => (
                                <CardLojas
                                    key={store.alias}
                                    onClick={(e) => e.preventDefault()}
                                    {...store}
                                />
                            ))
                        ) : (
                            <Card
                                className="store__stores__loading__container"
                                isPrimary
                            >
                                <Loader />
                            </Card>
                        )}

                        <span>
                            Não é a sua loja?{' '}
                            <a
                                href="https://infoprice-support.freshdesk.com/support/home"
                                target="_blank"
                                rel="noopener noreferrer"
                                className={[
                                    'link-button',
                                    'link-button-$medium',
                                ].join(' ')}
                            >
                                Fale conosco
                            </a>
                        </span>
                    </div>
                </Layout.Section>
                <Layout.Button disabled={!data.stores} onClick={handleClick}>
                    AVANÇAR
                </Layout.Button>
            </Layout.Content>
        </>
    );
};
