import React, { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Content, Icon, IconButton, SelectPicker } from 'rsuite';
import { setGenerateReportData } from '../../../../../actions/actionsModuloRelatorio';
import {
    moduleReportEnumsType,
    moduleReportReverseEnumsType,
    noConsolidateList,
} from '../utils';

const ConsolidateItems = ({
    editId,
    consolidateSelectedList,
    setConsolidateSelectedList,
}) => {
    const {
        moduloRelatorioData,
        saveReportItemData,
        reportSaveSuccess,
        showModalConfirmDownload,
    } = useSelector(
        (state) => ({
            moduloRelatorioData:
                state.moduloRelatorioDataReducer?.moduloRelatorioData,
            showModalConfirmDownload:
                state.moduloRelatorioDataReducer?.showModalConfirmDownload,
            saveReportItemData:
                state.moduloRelatorioDataReducer?.saveReportItemData,
            reportSaveSuccess:
                state.moduloRelatorioDataReducer?.reportSaveSuccess,
        }),
        shallowEqual,
    );

    const dispatch = useDispatch();

    const setConsolidateSelectList = () => {
        const selectList = moduloRelatorioData.dimensoes
            .filter((item) => !noConsolidateList.includes(item.dimensao.name))
            .map((item) => ({
                label: moduleReportReverseEnumsType[item.dimensao.name],
                value: moduleReportReverseEnumsType[item.dimensao.name],
            }));
        return selectList;
    };

    const updateDimensionsList = (value, consolidado) => {
        const dimensions = moduloRelatorioData.dimensoes;
        const dimensionsUpdated = dimensions.map((item) =>
            item.dimensao.name === moduleReportEnumsType[value]
                ? { ...item, consolidado }
                : item,
        );
        dispatch(setGenerateReportData('dimensoes', dimensionsUpdated));
    };

    const handleSelectConsolidateItem = (value) => {
        if (!consolidateSelectedList.includes(value)) {
            setConsolidateSelectedList((prevState) => [...prevState, value]);
            updateDimensionsList(value, true);
        }
    };

    const handleRemoveConsolidateItem = (value) => {
        if (consolidateSelectedList.includes(value)) {
            setConsolidateSelectedList((prevState) =>
                prevState.filter((item) => item !== value),
            );

            updateDimensionsList(value, false);
        }
    };

    const setConsolidateSavedReportArray = (savedReport) => {
        const savedConsolidates = savedReport?.reportEngineDTO?.dimensoes
            ?.filter((item) => item?.consolidado)
            .map((obj) => moduleReportReverseEnumsType[obj?.dimensao?.name]);
        setConsolidateSelectedList(savedConsolidates);
    };

    useEffect(() => {
        if (showModalConfirmDownload || reportSaveSuccess) {
            setConsolidateSelectedList([]);
        }
    }, [showModalConfirmDownload, reportSaveSuccess]);

    useEffect(() => {
        if (typeof editId === 'string' && editId === saveReportItemData?.id) {
            setConsolidateSavedReportArray(saveReportItemData);
        }
    }, [editId, saveReportItemData]);

    return (
        <Content className="consolidate-items">
            <div className="consolidate-items-title-wrapper">
                <p className="title">AGRUPAR DADOS</p>
                <p className="subtitle">
                    Para agrupar os itens de uma coluna, selecione-a abaixo. Os
                    dados dos itens serão totalizados levando em consideração a
                    quebra de período escolhido e a coluna virá preenchida com
                    “agrupado”.
                </p>
            </div>
            <SelectPicker
                className="consolidate-picker"
                placeholder="Escolher elementos"
                searchable={false}
                data={setConsolidateSelectList()}
                onSelect={(value) => handleSelectConsolidateItem(value)}
                renderValue={() => <span>Escolher elementos</span>}
            />
            {consolidateSelectedList.length ? (
                <div className="consolidate-item-pill-wrapper">
                    {React.Children.toArray(
                        consolidateSelectedList.map((item) => (
                            <div className="consolidate-item-pill">
                                {item}
                                <IconButton
                                    size="xs"
                                    icon={<Icon icon="close" />}
                                    onClick={() =>
                                        handleRemoveConsolidateItem(item)
                                    }
                                />
                            </div>
                        )),
                    )}
                </div>
            ) : null}
        </Content>
    );
};

export default ConsolidateItems;
