import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsExport from 'highcharts/modules/export-data';
import HighchartsExporting from 'highcharts/modules/exporting';
import React, { useEffect, useRef, useState } from 'react';
import {
    Button,
    Col,
    Content,
    Dropdown,
    FlexboxGrid,
    IconButton,
} from 'rsuite';

import iconEllipsis from '../../../../../../assets/icons/icon_ellipsis-v.svg';
import { HighchartsOptions } from '../../../../../../utils/HighchartsConfig';

import { LoadingSpinerLocal } from '../../../../../../components/LoadingSpinner';
import { defaultChartOption, seriesColors } from '../../utils';
import styles from './styles.module.scss';

HighchartsExporting(Highcharts);
HighchartsExport(Highcharts);

Highcharts.setOptions(HighchartsOptions);

export const TimeEvolutionChart = ({
    chartData,
    loadingChart,
    disabledTypeOptions,
    onClickViewChange,
    competitionType,
    redes,
    usuario,
}) => {
    const timeEvolutionChartRef = useRef(null);
    const [chartOptions, setChartOptions] = useState(defaultChartOption);
    const [visualizationType, setVisualizationType] = useState('rede');

    const chartObjItemsName = (item) => {
        if (item.rede) {
            if (item.rede === 'competitividade_geral') {
                return 'Competitividade geral';
            }
            return item.rede;
        }
        if (item.produto === 'competitividade_geral') {
            return 'Competitividade geral';
        }
        return item.produto;
    };

    const exportCSV = () => {
        if (timeEvolutionChartRef?.current?.chart) {
            timeEvolutionChartRef?.current?.chart.downloadCSV();
        }
    };

    const exportPNG = () => {
        if (timeEvolutionChartRef?.current?.chart) {
            timeEvolutionChartRef?.current?.chart.exportChart();
        }
    };

    const exportXLS = () => {
        if (timeEvolutionChartRef?.current?.chart) {
            timeEvolutionChartRef?.current?.chart.downloadXLS();
        }
    };

    const setChainNameColumn = (data) => {
        const chains = [...data];
        const chainsObject = {};

        chains.forEach((item, index) => {
            chainsObject[
                competitionType === 'varejo' ? item.rede : item.produto
            ] = {
                type: 'spline',
                name: chartObjItemsName(item),
                data: [],
                yAxis: 1,
                zIndex: 1,
                color: seriesColors[index + 2],
            };

            chainsObject[
                competitionType === 'varejo'
                    ? chains[index].rede
                    : chains[index].produto
            ].data = item.semanas?.map((i) => ({
                x: new Date(i.semana).getTime(),
                y: Number((i.competitividadeMedia * 100).toFixed(1)),
                products: i.produtos,
            }));
        });

        const series = Object.values(chainsObject);
        series.forEach((item) => {
            Array.from(item.data)?.sort(
                (a, b) => new Date(a.x) - new Date(b.x),
            );
        });

        return series;
    };

    const changeVisualization = (type) => {
        setVisualizationType(type);
        setChartOptions((prevState) => ({
            ...prevState,
            series: [],
        }));
        onClickViewChange(type);
    };

    useEffect(() => {
        if (chartData?.length) {
            const yAxisLabelValues = chartData
                .map((item) => item.semanas)
                .flat()
                .map((item) => (item.competitividadeMedia * 100).toFixed(0));

            const minNumber = Math.min(...yAxisLabelValues);
            const maxNumber = Math.max(...yAxisLabelValues);

            setChartOptions((prevState) => ({
                ...prevState,
                series: setChainNameColumn(chartData),
                tooltip: {
                    ...prevState.tooltip,
                    footerFormat:
                        competitionType === 'varejo' ||
                        visualizationType === 'general'
                            ? ''
                            : `<br>REDE: ${redes}`,
                },
                yAxis: [
                    {
                        // Primary yAxis
                        title: {
                            text: ' ',
                        },
                        breakSize: 1,
                        alignTicks: false,
                        opposite: true,
                    },
                    {
                        // Secondary yAxis
                        max: minNumber <= 100 && maxNumber <= 100 ? 110 : null,
                        min: minNumber >= 100 && maxNumber >= 100 ? 80 : null,
                        title: {
                            text: 'COMPETITIVIDADE',
                        },
                        labels: {
                            formatter() {
                                let percentage =
                                    this.axis.defaultLabelFormatter.call(this);
                                if (percentage.includes('.')) {
                                    percentage = percentage.replace('.', '');
                                }

                                if (percentage.includes(',')) {
                                    percentage = percentage.replace(',', '.');
                                }
                                return `${Number(percentage)
                                    .toFixed(0)
                                    .replace('.', ',')}%`;
                            },
                        },
                        alignTicks: false,
                        plotLines: [
                            {
                                value: 100,
                                color: '#959595',
                                dashStyle: 'shortDash',
                                width: 2,
                            },
                        ],
                    },
                ],
            }));
        }
    }, [chartData]);

    return (
        <Content className={styles['time-evolution-chart']}>
            <LoadingSpinerLocal
                loading={loadingChart}
                size="md"
                position="absolute"
            />
            <div className={styles.header}>
                <h6 className={styles.title}>
                    Evolução temporal de competitividade
                </h6>
                <p className={styles.subtitle}>
                    {competitionType === 'varejo'
                        ? 'Competitividade da rede referência comparada com as demais redes selecionadas'
                        : 'Competitividade do produto referência comparado com os demais produtos selecionados'}
                </p>
                {usuario?.servicoPanel?.pacotePainel?.name === 'LITE' ||
                usuario?.servicoPanel?.pacotePainel?.name === 'LIMIT' ? null : (
                    <div className={styles['more-options-section']}>
                        <Dropdown
                            id="time-evolution-more-options"
                            placement="bottomEnd"
                            renderTitle={() => (
                                <IconButton
                                    className={
                                        styles['more-options-section__btn']
                                    }
                                    appearance="subtle"
                                    icon={
                                        <img
                                            alt="Mais opções"
                                            src={iconEllipsis}
                                        />
                                    }
                                />
                            )}
                        >
                            <Dropdown.Item
                                onClick={exportPNG}
                                id="btn-competitivines-time-evolution-download-png"
                            >
                                Download em PNG
                            </Dropdown.Item>
                            <Dropdown.Item
                                onClick={exportCSV}
                                id="btn-competitivines-time-evolution-download-csv"
                            >
                                Download em CSV
                            </Dropdown.Item>
                            <Dropdown.Item
                                onClick={exportXLS}
                                id="btn-competitivines-time-evolution-download-xls"
                            >
                                Download em XLS
                            </Dropdown.Item>
                        </Dropdown>
                    </div>
                )}
            </div>
            <FlexboxGrid>
                <FlexboxGrid.Item
                    componentClass={Col}
                    lg={6}
                    md={6}
                    xsHidden
                    smHidden
                >
                    <span className={styles['group-by']}>AGRUPAR POR:</span>
                    <Button
                        className={styles['group-by-buttons']}
                        appearance={
                            visualizationType === 'general'
                                ? 'primary'
                                : 'subtle'
                        }
                        onClick={() => changeVisualization('general')}
                        disabled={disabledTypeOptions}
                    >
                        Geral
                    </Button>
                    <Button
                        className={styles['group-by-buttons']}
                        appearance={
                            visualizationType === 'rede' ||
                            visualizationType === 'produto'
                                ? 'primary'
                                : 'subtle'
                        }
                        onClick={() =>
                            changeVisualization(
                                competitionType === 'varejo'
                                    ? 'rede'
                                    : 'produto',
                            )
                        }
                        disabled={disabledTypeOptions}
                    >
                        {competitionType === 'varejo' ? 'Rede' : 'Produto'}
                    </Button>
                </FlexboxGrid.Item>
                <FlexboxGrid.Item
                    componentClass={Col}
                    lg={6}
                    md={6}
                    xsHidden
                    smHidden
                >
                    <span className={styles['group-by']}>
                        QUEBRA DE PERÍODO:
                    </span>
                    <Button
                        className={styles['period-button']}
                        appearance="default"
                    >
                        Semanal
                    </Button>
                </FlexboxGrid.Item>
            </FlexboxGrid>
            <div style={{ width: '99%' }}>
                <HighchartsReact
                    ref={timeEvolutionChartRef}
                    highcharts={Highcharts}
                    options={chartOptions}
                />
            </div>
        </Content>
    );
};
