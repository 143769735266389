import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import {
    Button,
    Col,
    Container,
    Content,
    FlexboxGrid, Header, Icon,
    Nav, Navbar, Steps,
} from 'rsuite';

import {
    getMotivesList,
    getRegionAutocompleteOptions,
    nextStage,
    setAccountData,
    setAccountMotives,
    updateStage,
} from '../../../../../actions/actionsCreateAccountFreemium';
import logoInfoprice from '../../../../../assets/logo/logo-infoprice-branco.svg';
import { LoadingSpiner, LoadingSpinerWithMessage } from '../../../../../components/LoadingSpinner';
import DataGroupFormFreemium from '../Components/DataGroupFormFreemium';
import { createFreeUser } from '../services';
import { sendLeadEvent, setUserAccoundModel } from '../utils';

const ChannelForm = ({ channel, setChannel }) => (
    <>
        <h4 className="title">Olá, vamos dar início ao InfoPanel!</h4>
        <div className="subtitle">
            <p>A escolha do canal vai definir em quais tipos de loja você poderá monitorar os preços.</p>
        </div>
        <FlexboxGrid align="middle">
            <Col xs={24} md={16}>
                <div className="channel-selection">
                    <p className="channel-selection__label">Escolha seu canal</p>
                    <Button
                        appearance={channel === 'ALIMENTAR' ? 'primary' : 'ghost'}
                        className="channel-selection__btn-option"
                        onClick={() => setChannel('ALIMENTAR', 'channel')}
                    >
                        <Icon icon="cutlery" />
                        <p>Alimentar</p>
                    </Button>
                    <Button
                        appearance={channel === 'FARMA' ? 'primary' : 'ghost'}
                        className="channel-selection__btn-option"
                        onClick={() => setChannel('FARMA', 'channel')}
                    >
                        <Icon icon="medkit" />
                        <p>Farma</p>
                    </Button>
                </div>
            </Col>
            <Col sm={8} xsHidden>
                <div className="update-warning-box">
                    <span className="update-warning-box__icon-area">
                        <Icon icon="exclamation-triangle" />
                    </span>
                    <span className="update-warning-box__description-area">
                        <p><b>Atenção!</b></p>
                        <p>
                            Você poderá realizar
                            {' '}
                            <b>UMA</b>
                            {' '}
                            alteração de canal
                            {' '}
                            <b>POR MÊS</b>
                            .
                        </p>
                    </span>
                </div>
            </Col>
            {channel.length
                ? (
                    <Col xs={24} md={14} className="description-area">
                        {channel === 'ALIMENTAR'
                            ? (
                                <p className="description-area__message">
                                    Ao selecionar
                                    {' '}
                                    <b>Alimentar</b>
                                    {' '}
                                    você irá visualizar os dados de:
                                    {' '}
                                    atacado, hipermercado, supermercado, lojas de vizinhança, padarias, pet shops, armarinhos,
                                    {' '}
                                    lojas de brinquedo e departamento.
                                </p>
                            )
                            : (
                                <p className="description-area__message">
                                    Ao selecionar
                                    {' '}
                                    <b>Farma</b>
                                    {' '}
                                    você irá visualizar os dados de: farmácias e perfumarias.
                                </p>
                            )}
                    </Col>
                )

                : null}
        </FlexboxGrid>
    </>
);

const MotiveForm = ({ motiveSelectedList, changeMotiveSelectedList, motives }) => (
    <div>
        <h4 id="stage3" className="title">Último passo!</h4>
        <div className="subtitle">
            <p>Para entender melhor seus objetivos com o InfoPanel, nos conte mais sobre suas motivações.</p>
        </div>

        <div className="motive-list">
            <div className="motive-list__label">
                <p>Selecione até 3 categorias que mais se encaixam às suas necessidades atuais</p>
            </div>
            {motives.map((item) => (
                <Button
                    className={motiveSelectedList?.find((motive) => motive === item.name) ? 'motive-option selected' : 'motive-option'}
                    key={item.name}
                    onClick={() => changeMotiveSelectedList(item)}
                >
                    {item.descricao}

                </Button>
            ))}
        </div>
    </div>
);

const NavBarFreemium = () => (
    <Navbar componentClass={Col} sm={24} appearance="inverse">
        <Navbar.Header>
            <div className="navbar-brand logo">
                <img alt="Logo Infoprice" src={logoInfoprice} />
            </div>
        </Navbar.Header>
        <Navbar.Body>
            <Nav activeKey="1">
                <Nav.Item componentClass={Col} sm={24} eventKey="1" title="ISA | Free">
                    <span>ISA | Free</span>
                </Nav.Item>
            </Nav>
        </Navbar.Body>
    </Navbar>
);

const SidenavSteps = ({ stage }) => (
    <Steps>
        <Steps.Item status={stage === 3 ? 'process' : 'finish'} title="Escolha de canal" />
        <Steps.Item status={stage === 4 ? 'process' : stage > 4 ? 'finish' : 'wait'} title="Agrupamento de dados" />
        <Steps.Item status={stage === 5 ? 'process' : stage > 5 ? 'finish' : 'wait'} title="Motivação de uso" />
    </Steps>
);

class FreemiumWizard extends Component {
    constructor(props) {
        super(props);

        this.changeMotiveSelectedList = this.changeMotiveSelectedList.bind(this);
        this.finishSetting = this.finishSetting.bind(this);
        this.stepSetting = this.stepSetting.bind(this);
    }

    componentDidMount() {
        const { getMotivesList } = this.props;

        this.verifyAccountInfo();
        getMotivesList();
    }

    verifyAccountInfo() {
        const { account, history } = this.props;
        if (!account.email) {
            history.push('/free/email');
        }
    }

    changeMotiveSelectedList(motive) {
        const { setAccountMotives, account } = this.props;

        let newList = [...account.motives];

        if (newList.length === 3) {
            if (newList.some((item) => item === motive.name)) {
                const index = newList.findIndex((item) => item === motive.name);

                newList.splice(index, 1);
            }
        } else if (newList.some((item) => item === motive.name)) {
            const index = newList.findIndex((item) => item === motive.name);

            newList.splice(index, 1);
        } else {
            newList = [...newList, motive.name];
        }

        setAccountMotives(newList);
    }

    finishSetting(account) {
        const { history } = this.props;

        createFreeUser(setUserAccoundModel(account)).then((response) => {
            if (response) {
                if (account.userType === 'USUARIO_CADASTRADO_CLIENTE_NAO_PANEL') {
                    window.location.href = '/isa/painel-geral';
                } else {
                    history.push('/free/sucesso');
                }

                sendLeadEvent('infopanel-free-configurado', account);
            }
        });
    }

    disabledBtn() {
        const { stage, account, hasEnoughData } = this.props;

        if (stage === 3 && !account.channel.length) {
            return true;
        }
        if (stage === 4 && !account.region) {
            return true;
        }
        if (stage === 4 && (account.region === 'state' || account.region === 'city') && (!account.city && !account.state)) {
            return true;
        }
        if (stage === 4 && account.region && hasEnoughData === false) {
            return true;
        }
        if (stage === 5 && !account.motives.length) {
            return true;
        }
        return false;
    }

    stepSetting(stage, account) {
        const { nextStage } = this.props;

        if (stage === 5) {
            this.finishSetting(account);
        } else {
            nextStage(stage);

            if (stage === 3) {
                setTimeout(() => {
                    const element = document.querySelector('#stage2');
                    element.scrollIntoView({ behavior: 'smooth' });
                }, 100);
            } else {
                setTimeout(() => {
                    const element = document.querySelector('#stage3');
                    element.scrollIntoView({ behavior: 'smooth' });
                }, 100);
            }
        }
    }

    render() {
        const {
            stage, account, motives, setAccountData, states, cities,
        } = this.props;

        return (

            <Container className="freemium-setting-page">
                <Header>
                    <NavBarFreemium />
                </Header>
                <LoadingSpiner size="md" />
                <LoadingSpinerWithMessage size="md" area="hasData" message="Aguarde... Verificando dados" />
                <Content>
                    <SidenavSteps stage={stage} />
                    <FlexboxGrid className="content-section" style={{ marginBottom: '60px' }}>
                        <Col xs={24} className="first-col">

                            <div>
                                <ChannelForm
                                    channel={account.channel}
                                    setChannel={setAccountData}
                                />
                                {stage !== 3 && (
                                    <DataGroupFormFreemium
                                        region={account.region}
                                        state={account.state}
                                        city={account.city}
                                        states={states}
                                        cities={cities}
                                        changeAutocompleteValue={setAccountData}
                                        setRegion={this.setRegion}
                                        setDataAlarm={this.setDataAlarm}
                                        hasNoEnoughData={this.checkData}
                                    />
                                )}
                                {stage !== 3 && stage !== 4 && (
                                    <FlexboxGrid>
                                        <Col md={16} xs={24}>
                                            <MotiveForm
                                                motiveSelectedList={account.motives}
                                                changeMotiveSelectedList={this.changeMotiveSelectedList}
                                                motives={motives}
                                            />
                                        </Col>
                                    </FlexboxGrid>
                                )}
                            </div>
                            <div className="btn-area">
                                <Col xs={24} md={4} mdPush={20}>
                                    <Button
                                        block
                                        className="footer__button"
                                        onClick={() => this.stepSetting(stage, account)}
                                        appearance="primary"
                                        disabled={this.disabledBtn()}
                                    >
                                        {stage === 3 || stage === 4 ? 'Próximo' : null}
                                        {stage === 5 ? 'Concluir' : null}
                                    </Button>
                                </Col>
                            </div>
                        </Col>
                    </FlexboxGrid>
                </Content>
            </Container>
        );
    }
}

const mapStateToProps = (store) => ({
    account: store.accountFreemiumDataReducer.account,
    stage: store.accountFreemiumDataReducer.stage,
    motives: store.accountFreemiumDataReducer.motives,
    states: store.accountFreemiumDataReducer.states,
    cities: store.accountFreemiumDataReducer.cities,
    hasEnoughData: store.accountFreemiumDataReducer.hasEnoughData,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    updateStage,
    setAccountData,
    getMotivesList,
    nextStage,
    setAccountMotives,
    getRegionAutocompleteOptions,
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FreemiumWizard));
