import React, { useEffect, useState, useRef } from 'react';
import {
    Content,
    FlexboxGrid,
    Col,
    Icon,
    Button,
    Dropdown,
    IconButton,
} from 'rsuite';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsExporting from 'highcharts/modules/exporting';
import HighchartsExport from 'highcharts/modules/export-data';
import HighchartsMap from 'highcharts/modules/map';
import mapDataBR from '@highcharts/map-collection/countries/br/br-all.geo.json';
import MarkerClusterer from '@googlemaps/markerclustererplus';

import { trackPromise } from 'react-promise-tracker';
import { MAPS_LOADER } from '../../../../../utils/GoogleChartsConfig';
import clusterPoint2 from '../../../../../assets/icons/cluster_point-2.svg';
import singlePoint from '../../../../../assets/icons/single_point.svg';

import iconEllipsis from '../../../../../assets/icons/icon_ellipsis-v.svg';
import noDataImage from '../../../../../assets/image/empty-state-2.svg';
import iconMinimize from '../../../../../assets/icons/icon_minimize.svg';
import iconMaximize from '../../../../../assets/icons/icon_maximize.svg';
import lockIcon from '../../../../../assets/icons/icon_lock.svg';

import ModalConfirmation from '../../../../../components/ModalConfirmation';
import MobileSelectOptionComponent from '../../../../../components/MobileSelectOptionComponent';
import EmptyState from './EmptyState';

HighchartsExporting(Highcharts);
HighchartsExport(Highcharts);
HighchartsMap(Highcharts);

Highcharts.setOptions({
    lang: {
        decimalPoint: ',',
        thousandsSep: '.',
        exitFullscreen: 'Sair da tela cheia',
    },
});

const optionsGroupBy = [
    { label: 'Estado', value: 'estado' },
    { label: 'Loja', value: 'loja' },
];

const defaultChartOptions = {
    chart: {
        map: mapDataBR,
        height: 435,
    },
    title: {
        text: '',
    },
    exporting: {
        enabled: false,
    },
    mapNavigation: {
        enabled: true,
        buttonOptions: {
            verticalAlign: 'bottom',
        },
    },
    colorAxis: {
        // min: this.getMin(data.content),
        // max: this.getMax(data.content),
    },
    tooltip: {
        useHTML: true,
        shared: true,
        pointFormatter() {
            return `<span><b>${this.name}</b> <br> Preço médio: <b>${this.value
                .toFixed(2)
                .replace('.', ',')}</b></span>`;
        },
        headerFormat: '',
    },
    series: [],
};

const createHTMLMapMarker = ({
    OverlayView = window.google.maps.OverlayView,
    ...args
}) => {
    class HTMLMapMarker extends OverlayView {
        constructor() {
            super();
            this.lat = args.lat;
            this.lng = args.lng;
            this.latlng = new window.google.maps.LatLng(args.lat, args.lng);
            this.price = args.price;
            this.html = args.html;
            this.setMap(args.map);
        }

        createDiv() {
            this.div = document.createElement('div');
            this.div.style.position = 'absolute';
            if (this.html) {
                this.div.innerHTML = this.html;
            }
            this.div.addEventListener('click', () => {
                window.google.maps.event.trigger(this, 'click');
            });
        }

        appendDivToOverlay() {
            const panes = this.getPanes();
            panes.overlayImage.appendChild(this.div);
        }

        positionDiv() {
            const point = this.getProjection().fromLatLngToDivPixel(
                this.latlng,
            );
            if (point) {
                this.div.style.left = `${point.x}px`;
                this.div.style.top = `${point.y}px`;
            }
        }

        draw() {
            if (!this.div) {
                this.createDiv();
                this.appendDivToOverlay();
            }
            this.positionDiv();
        }

        remove() {
            if (this.div) {
                this.div.parentNode.removeChild(this.div);
                this.div = null;
            }
        }

        getPosition() {
            return this.latlng;
        }

        getDraggable() {
            return false;
        }

        getVisible() {
            return this.getVisible();
        }
    }

    return new HTMLMapMarker();
};

function createMarkerLayout(item) {
    return (
        `${"<div class='marker'>" + "<img src='"}${singlePoint}'>`
        + '<div>'
        + `<span>${item.price.toFixed(2).replace('.', ',')}</span></div>`
        + '</div>'
    );
}

// eslint-disable-next-line no-unused-vars
MarkerClusterer.CALCULATOR = function (markers, _numStyles) {
    const index = 0;

    const valueToSum = markers.reduce((total, item) => {
        let reducer = total;
        reducer += item.price;
        return reducer;
    }, 0);

    const media = (valueToSum / markers.length).toFixed(2).replace('.', ',');

    return {
        text: `<span class='cluster__stores'>${markers.length}</span><span class='cluster__price-average'>${media}</span>`,
        index,
        title: `Preço médio: ${media}`,
    };
};

function buildCluster(map, markers, icon) {
    return new MarkerClusterer(map, markers, {
        gridSize: 90,
        styles: [
            {
                url: icon,
                height: 40,
                width: 185,
                textColor: '#2169a8',
                fontWeight: 500,
                marginTop: 22,
            },
        ],
    });
}

const SMapChart = ({
    user,
    userPlan,
    groupBy,
    changeGroupBy,
    products,
    chartData,
    sendCTA,
    getData,
    showExportOptions,
    disableExport,
    disabledGroupByOptions,
}) => {
    const mapChartRef = useRef(null);
    const [expand, setExpand] = useState(true);
    const [openDialog, setOpenDialog] = useState(false);
    const [hasPermition, setHasPermition] = useState(false);
    const [showCTA, setShowCTA] = useState(false);
    const [chartOptions, setChartOptions] = useState(defaultChartOptions);

    useEffect(() => {
        setHasPermition(!userPlan === 'LITE' || userPlan === 'FREE');
    }, []);

    const getMax = (data) => {
        if (data?.length) {
            const copyData = [...data];

            const sortData = copyData.sort((a, b) => b.preco_medio - a.preco_medio);

            return sortData[0].preco_medio;
        }
        return null;
    };

    const getMin = (data) => {
        if (data?.length) {
            const copyData = [...data];

            const sortData = copyData.sort((a, b) => a.preco_medio - b.preco_medio);

            return sortData[0].preco_medio;
        }
        return null;
    };

    const mapDataSeries = (data) => {
        const receivedData = [...data];

        const mapData = receivedData
            .filter((item) => item.uf.indexOf('SP') === -1)
            .map((item) => [`br-${item.uf.toLowerCase()}`, item.preco_medio]);

        let media = 0;
        receivedData
            .filter((item) => item.uf.indexOf('SP') !== -1)
            .forEach((item) => {
                media += item.preco_medio;
            });

        if (media) {
            if (
                receivedData.filter((item) => item.uf.indexOf('SP') !== -1)
                    .length === 2
            ) {
                mapData.push(['br-sp', Number((media / 2).toFixed(2))]);
            } else {
                mapData.push(['br-sp', Number(media.toFixed(2))]);
            }
        }

        return mapData;
    };

    const initMap = () => {
        if (chartData?.length) {
            const newMakers = [...chartData];

            trackPromise(
                MAPS_LOADER.load().then(() => {
                    const map = new window.google.maps.Map(
                        document.getElementById('map'),
                        {
                            zoom: 3,
                            streetViewControl: false,
                            mapTypeControl: false,
                            center: new window.google.maps.LatLng(
                                newMakers[0].lat,
                                newMakers[0].lng,
                            ),
                        },
                    );

                    // eslint-disable-next-line no-unused-vars
                    const markers = newMakers.map((location) => {
                        const marker = createHTMLMapMarker({
                            lat: location.lat,
                            lng: location.lng,
                            price: location.price,
                            map,
                            html: createMarkerLayout(location),
                        });

                        const infowindow = new window.google.maps.InfoWindow({
                            content: `Rede: <b>${location.rede
                                }</b><br>Média de preços: <b>${location.price
                                    .toFixed(2)
                                    .replace('.', ',')}</b>`,
                        });

                        marker.addListener('click', () => {
                            infowindow.open(map, marker);
                        });

                        return marker;
                    });

                    new MarkerClusterer(map, markers, {
                        gridSize: 90,
                        styles: [
                            {
                                url: clusterPoint2,
                                height: 40,
                                width: 185,
                                textColor: '#2169a8',
                                fontWeight: 500,
                                marginTop: 22,
                            },
                        ],
                    });
                }),
            );
        }
    };

    useEffect(() => {
        if (
            groupBy === 'loja'
            && userPlan === 'LIMIT'
            && products?.some((item) => item.limited === true)
        ) {
            setHasPermition(false);
        } else if (
            groupBy === 'loja'
            && (userPlan === 'FREE' || userPlan === 'LITE')
        ) {
            setHasPermition(false);
        } else if (
            groupBy === 'estado'
            && (userPlan === 'FREE' || userPlan === 'LITE')
        ) {
            setHasPermition(false);
        } else {
            setHasPermition(true);
        }

        if (groupBy === 'loja' && hasPermition) {
            initMap();
        }
    }, [groupBy]);

    useEffect(() => {
        if (groupBy === 'loja' && hasPermition) {
            initMap();
        }
        // if (hasPermition) {
        //     getData();
        // }
    }, [hasPermition]);

    const exportCSV = () => {
        if (mapChartRef?.current?.chart) {
            mapChartRef.current.chart.downloadCSV();
        }
    };

    const exportXLS = () => {
        if (mapChartRef?.current?.chart) {
            mapChartRef.current.chart.downloadXLS();
        }
    };

    const exportPNG = () => {
        if (mapChartRef?.current?.chart) {
            mapChartRef.current.chart.exportChart({ type: 'image/png' });
        }
    };

    const print = () => {
        if (mapChartRef?.current?.chart) {
            mapChartRef.current.chart.print();
        }
    };

    const viewFullScreen = () => {
        if (mapChartRef?.current?.chart) {
            mapChartRef.current.chart.fullscreen.toggle();
        }
    };

    useEffect(() => {
        if (Array.isArray(chartData)) {
            if (groupBy === 'estado') {
                setChartOptions((prevState) => ({
                    ...prevState,
                    series: [
                        {
                            data: mapDataSeries(chartData, groupBy),
                            name: '',
                            dataLabels: {
                                enabled: false,
                                format: '{point.name}',
                            },
                        },
                    ],
                    colorAxis: {
                        min: getMin(chartData),
                        max: getMax(chartData),
                    },
                }));
            } else {
                initMap();
            }
        }
    }, [chartData]);

    return (
        <Content className="map-chart">
            <FlexboxGrid className="header-area">
                <FlexboxGrid.Item
                    componentClass={Col}
                    lg={20}
                    md={20}
                    sm={17}
                    xs={17}
                    className={!expand ? 'minimized' : null}
                >
                    <h4>MAPA DE PREÇOS</h4>
                </FlexboxGrid.Item>
                <FlexboxGrid.Item
                    componentClass={Col}
                    lg={2}
                    md={2}
                    sm={3}
                    xs={3}
                    className={
                        !expand ? 'expand-section minimized' : 'expand-section'
                    }
                >
                    <Button
                        id="boxplot-expand-btn"
                        appearance="subtle"
                        onClick={() => setExpand(!expand)}
                    >
                        {expand ? (
                            <img alt="Maximizar" src={iconMinimize} />
                        ) : (
                            <img alt="Minimizar" src={iconMaximize} />
                        )}
                    </Button>
                </FlexboxGrid.Item>
                {groupBy === 'estado' ? (
                    <FlexboxGrid.Item
                        componentClass={Col}
                        lg={2}
                        md={2}
                        sm={3}
                        xs={3}
                        className={
                            !expand
                                ? 'more-options-section minimized'
                                : 'more-options-section'
                        }
                    >
                        <Dropdown
                            id="map-more-options"
                            placement="bottomEnd"
                            renderTitle={() => (
                                <IconButton
                                    className="more-options-section__btn"
                                    appearance="subtle"
                                    icon={(
                                        <img
                                            alt="Mais opções"
                                            src={iconEllipsis}
                                        />
                                    )}
                                />
                            )}
                        >
                            <Dropdown.Item
                                onClick={viewFullScreen}
                                id="btn-map-full-screen"
                            >
                                Ver em tela cheia
                            </Dropdown.Item>
                            {showExportOptions ? (
                                <>
                                    <Dropdown.Item
                                        disabled={
                                            userPlan === 'FREE' || disableExport
                                        }
                                        onClick={print}
                                        id="btn-map-print"
                                    >
                                        Imprimir
                                    </Dropdown.Item>
                                    <Dropdown.Item divider />
                                    <Dropdown.Item
                                        disabled={
                                            userPlan === 'FREE' || disableExport
                                        }
                                        onClick={exportPNG}
                                        id="btn-map-download-png"
                                    >
                                        Download em PNG
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                        disabled={
                                            userPlan === 'FREE' || disableExport
                                        }
                                        onClick={exportCSV}
                                        id="btn-map-download-csv"
                                    >
                                        Download em CSV
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                        disabled={
                                            userPlan === 'FREE' || disableExport
                                        }
                                        onClick={exportXLS}
                                        id="btn-map-download-xls"
                                    >
                                        Download em XLS
                                    </Dropdown.Item>
                                </>
                            ) : null}
                        </Dropdown>
                    </FlexboxGrid.Item>
                ) : null}
            </FlexboxGrid>
            {expand ? (
                <>
                    <FlexboxGrid>
                        <FlexboxGrid.Item
                            componentClass={Col}
                            lg={24}
                            md={24}
                            xsHidden
                            smHidden
                            className="select-visualization-section"
                        >
                            <span>AGRUPAR POR: </span>
                            <Button
                                className="select-visualization-section__button"
                                appearance={
                                    groupBy === 'estado' ? 'primary' : 'subtle'
                                }
                                onClick={() => changeGroupBy('estado')}
                                id="btn-map-group-by-state"
                                disabled={disabledGroupByOptions}
                            >
                                Estado
                            </Button>
                            <Button
                                className="select-visualization-section__button"
                                appearance={
                                    groupBy === 'loja' ? 'primary' : 'subtle'
                                }
                                onClick={() => changeGroupBy('loja')}
                                id="btn-map-group-by-store"
                                disabled={disabledGroupByOptions}
                            >
                                Loja
                            </Button>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item
                            componentClass={Col}
                            xs={24}
                            sm={24}
                            lgHidden
                            mdHidden
                            className="select-visualization-section"
                        >
                            <IconButton
                                className="select-visualization-section__dropdown"
                                icon={<Icon icon="angle-down" />}
                                onClick={() => setOpenDialog(true)}
                                placement="right"
                            >
                                Agrupar por:
                                {' '}
                                <span>
                                    {groupBy === 'estado'
                                        ? 'Estado'
                                        : groupBy === 'loja'
                                            ? 'Loja'
                                            : null}
                                </span>
                            </IconButton>
                        </FlexboxGrid.Item>
                    </FlexboxGrid>

                    <FlexboxGrid>
                        {!hasPermition ? (
                            <FlexboxGrid.Item
                                className="no-access"
                                componentClass={Col}
                                lg={24}
                                md={24}
                                sm={24}
                                xs={24}
                            >
                                <div className="no-access__main">
                                    <div>
                                        <img
                                            alt="Cadeado"
                                            className="no-access__icon"
                                            src={lockIcon}
                                        />
                                    </div>
                                    <div className="no-access__message">
                                        <p>
                                            {userPlan === 'FREE' ? (
                                                <span>
                                                    Este conteúdo pode ser
                                                    acessado somente por
                                                    assinantes.
                                                </span>
                                            ) : (
                                                <span>
                                                    Este conteúdo só pode ser
                                                    acessado por assinantes do
                                                    plano
                                                    {' '}
                                                    <b>Flex</b>
                                                    {' '}
                                                    (para
                                                    produtos escolhidos) ou
                                                    {' '}
                                                    <b>Pro</b>
                                                </span>
                                            )}
                                        </p>
                                    </div>
                                    <div>
                                        <Button
                                            appearance="primary"
                                            onClick={() => setShowCTA(true)}
                                        >
                                            Quero ser assinante
                                        </Button>
                                    </div>
                                </div>
                            </FlexboxGrid.Item>
                        ) : groupBy === 'estado' ? (
                            <FlexboxGrid.Item
                                componentClass={Col}
                                lg={24}
                                md={24}
                                sm={24}
                                xs={24}
                                id="chart-area"
                                className="chart-area"
                            >
                                {chartData?.length ? (
                                    <HighchartsReact
                                        ref={mapChartRef}
                                        constructorType="mapChart"
                                        highcharts={Highcharts}
                                        options={chartOptions}
                                    />
                                ) : (
                                    <EmptyState
                                        title="Ops! Nenhum resultado encontrado"
                                        message="Sua busca não retornou nenhuma informação. Altere os filtros e tente novamente."
                                        image={noDataImage}
                                    />
                                )}
                            </FlexboxGrid.Item>
                        ) : (
                            <>
                                {chartData?.length === 3000 ? (
                                    <FlexboxGrid className="warning-map">
                                        <FlexboxGrid.Item
                                            componentClass={Col}
                                            lg={24}
                                            md={24}
                                            sm={24}
                                            xs={24}
                                            className="warning-visualization"
                                        >
                                            <p>
                                                Você pode visualizar até 3.000
                                                resultados por busca. Para
                                                visualizar outros resultados
                                                altere seus filtros.
                                            </p>
                                        </FlexboxGrid.Item>
                                    </FlexboxGrid>
                                ) : null}
                                <FlexboxGrid.Item
                                    componentClass={Col}
                                    lg={24}
                                    md={24}
                                    sm={24}
                                    xs={24}
                                    id="chart-area-map"
                                    className="markers-map"
                                >
                                    <div style={{ height: '435px' }} id="map" />
                                </FlexboxGrid.Item>
                            </>
                        )}
                    </FlexboxGrid>
                </>
            ) : null}
            <ModalConfirmation
                title="Informações para alteração do plano"
                message="Caso deseje saber mais informações para evoluir o seu plano, clique em “Confirmar”"
                show={showCTA}
                btnConfirmClass="upsell"
                buttonConfirm="Confirmar"
                buttonCancel="Cancelar"
                onConfirm={sendCTA}
                onCancel={() => setShowCTA(false)}
            />
            <MobileSelectOptionComponent
                open={openDialog}
                handleClose={() => setOpenDialog(false)}
                options={optionsGroupBy}
                optionSelected={groupBy}
                handleSelect={changeGroupBy}
            />
        </Content>
    );
};

export default SMapChart;
