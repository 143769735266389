import classNames from 'classnames';
import { format } from 'date-fns';
import React from 'react';
import { Icon, Tag } from 'rsuite';
import { IconNames } from 'rsuite/lib/Icon';
import { GetRegra } from '../../../RegrasPreco/Cadastro/services';
import { SchemaNewRuleProps } from '../../pages/RegrasDePreco/lib';
import styles from './RegraDePrecoTag.module.scss';

type RegraDePrecoTagProps = {
    status?: GetRegra['ruleStatus'];
    expirationDate?: SchemaNewRuleProps['expirationDate'];
} & React.HtmlHTMLAttributes<HTMLElement>;

export const RegraDePrecoTag = ({
    status = 'ACTIVE',
    expirationDate,
    className,
    ...props
}: RegraDePrecoTagProps) => {
    return (
        <Tag
            className={classNames(
                styles['rule-status'],
                styles[status],
                className,
            )}
            {...props}
        >
            <Icon
                icon={
                    status === 'ACTIVE'
                        ? 'check-circle'
                        : status === 'EXPIRED'
                        ? 'exclamation-circle'
                        : ('' as IconNames)
                }
            />
            {status === 'ACTIVE'
                ? expirationDate
                    ? 'REGRA ATIVA ATÉ O DIA'
                    : 'REGRA ATIVA POR TEMPO INDETERMINADO'
                : 'REGRA EXPIRADA NO DIA'}{' '}
            {expirationDate ? (
                <span>{format(expirationDate, 'dd/MM/yyyy')}</span>
            ) : null}
        </Tag>
    );
};
