import React from 'react';
import styles from './styles.module.scss';

export interface IBoxMainValue {
    children: string | number;
}

export const BoxMainValue = ({ children }: IBoxMainValue) => (
    <p className={styles['main-value']}>{children}</p>
);
