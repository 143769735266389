import React from 'react';
import { QuickActionFilterProps } from './QuickActionFilter.types';
import { FilterButton, FilterSelect, FilterSelectInterval } from './elements';
import './styles/index.scss';

const QuickActionFilter = ({
    children,
    className = '',
    ...props
}: QuickActionFilterProps) => (
    <div className={`quick-action-filter ${className}`} {...props}>
        {children}
    </div>
);

QuickActionFilter.Button = FilterButton;
QuickActionFilter.Select = FilterSelect;
QuickActionFilter.SelectInterval = FilterSelectInterval;

export { QuickActionFilter };
