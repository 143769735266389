import { GerenciadorPrecos } from '../../../../../@types/GerenciadorPrecos';
import { IPAMaths as calc } from '../../../../../lib';

type RowData = GerenciadorPrecos.RowData;

export const handleCalcWholesalePrice = (
    price: number | null,
    rowData: RowData,
) => {
    const { competitorsPrice, salesTaxPercentage, expensesPercent, cost } =
        rowData;

    const newCompetitivenessPrice = calc.novaCompetitividade(
        price,
        competitorsPrice,
    );

    const newMargin = calc.novaMargem(
        price,
        salesTaxPercentage,
        expensesPercent,
        cost,
    );

    return {
        price,
        newCompetitivenessPrice,
        newMargin,
    };
};

export const handleCalcWholesaleCPI = (
    newCompetitivenessPrice: number | null,
    rowData: RowData,
) => {
    const { pmz, competitorsPrice } = rowData;

    const price = calc.precoWithCpi(newCompetitivenessPrice, competitorsPrice);

    const newMargin = calc.novaMargemWithPmz(price, pmz);

    return {
        price,
        newCompetitivenessPrice,
        newMargin,
    };
};
export const handleCalcWholesaleMargin = (
    newMargin: number | null,
    rowData: RowData,
) => {
    const { cost, expensesPercent, salesTaxPercentage, competitorsPrice } =
        rowData;

    const price = calc.precoWithMargem(
        newMargin,
        cost,
        expensesPercent,
        salesTaxPercentage,
    );

    const newCompetitivenessPrice = calc.cpiWithMargem(
        newMargin,
        price,
        competitorsPrice,
    );

    return {
        price,
        newCompetitivenessPrice,
        newMargin,
    };
};
