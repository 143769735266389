import React, { useState, useEffect } from 'react';
import { withRouter, useParams } from 'react-router-dom';
import {
    Container,
    Content,
    Tooltip,
    FlexboxGrid,
    Button,
    Whisper,
    Col,
    InputGroup,
    Grid,
    Row,
    Input,
    IconButton,
    Icon,
    Notification,
} from 'rsuite';
import CurrencyInput from 'react-currency-input';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import SelectPill from '../../../../components/SelectPill';
import SimulacaoData from '../../../../assets/image/simulacao-data.svg';
import { searchFiltroProdutos } from '../../../../services/ProdutoService';
import {
    getLojas,
    getProdutoValue,
    getSimulacaoPrevisao,
    getGraficoMensal,
    getGraficoSemanal,
    getGraficoElasticidade,
    savePrevisao,
    downloadPrevisao,
} from './services';
import ValueCard from './Components/ValueCard';
import IconQuery from '../../../../assets/icons/icon_query.svg';
import { LoadingSpiner } from '../../../../components/LoadingSpinner';
import { useQuery } from '../../../../utils/Helpers';

const PrevisaoDemanda = ({ history }) => {
    const query = useQuery();
    const [produtos, setProdutos] = useState([]);
    const [loadingProdutos, setLoadingProdutos] = useState(false);
    const [produto, setProduto] = useState({
        value: null,
        margin: null,
        competitiveness: null,
    });
    const [novoPrecoProduto, setNovoPrecoProduto] = useState(0);
    const [produtoSelecionado, setProdutoSelecionado] = useState(null);
    const [lojas, setLojas] = useState([]);
    const [loadingLojas, setLoadingLojas] = useState(false);
    const [lojaSelecionada, setLojaSelecionada] = useState(null);
    const [simulacao, setSimulacao] = useState(null);
    const [erroPreco, setErroPreco] = useState(false);
    const [graficoSemanal, setGraficoSemanal] = useState(null);
    const [graficoMensal, setGraficoMensal] = useState(null);
    const [graficoElasticidade, setGraficoElasticidade] = useState(null);
    const [previsaoDemanda, setPrevisaoDemanda] = useState({});
    const [isSaveEnabled, setIsSaveEnabled] = useState(false);
    const [previsaoSalva, setPrevisaoSalva] = useState(null);
    const [isRedirect, setIsRedirect] = useState(false);

    const listProdutos = async (query) => {
        setLoadingProdutos(true);
        const response = await searchFiltroProdutos({ query });
        if (response) {
            setProdutos(
                response.data.map((item) => ({
                    label: `${item.product_id} - ${item.description}`,
                    value: item.product_id,
                    gtin: item.gtin,
                    description: item.description,
                }))
            );
        }
        setLoadingProdutos(false);
    };

    const listLojas = async (query) => {
        setLoadingLojas(true);
        const response = await getLojas({ query });
        if (response) {
            setLojas(
                response?.map((item) => ({
                    value: item.store_id,
                    label: item.store,
                    store_id: item.store_id,
                }))
            );
        }
        setLoadingLojas(false);
    };

    const listProdutoValue = async () => {
        const response = await getProdutoValue({
            productId: produtoSelecionado,
            storeId: lojaSelecionada,
        });
        if (response) {
            setProduto(response);
            setNovoPrecoProduto(
                parseFloat(query.get('price')) || response.value || 0
            );
        }
    };

    const listaGraficoSemanal = async () => {
        const response = await getGraficoSemanal({
            productId: produtoSelecionado,
            storeId: lojaSelecionada,
            currentPrice: produto.value,
            newPrice: String(novoPrecoProduto).includes(',')
                ? String(novoPrecoProduto).replace(',', '.')
                : novoPrecoProduto,
        });

        const diaDaSemana = {
            TUESDAY: 'Ter',
            WEDNESDAY: 'Qua',
            THURSDAY: 'Qui',
            FRIDAY: 'Sex',
            SATURDAY: 'Sáb',
            SUNDAY: 'Dom',
            MONDAY: 'Seg',
        };

        if (response?.days_of_week) {
            setPrevisaoDemanda((prevState) => ({
                ...prevState,
                weekly_total_sales_forecast: {
                    days: response.days_of_week,
                },
            }));

            setGraficoSemanal({
                exporting: {
                    enabled: false,
                },
                title: {
                    text: '',
                },
                chart: {
                    type: 'column',
                },
                series: [
                    {
                        name: 'Vigente',
                        data:
                            response.days_of_week
                                ?.map((item) => item.total_sales)
                                .map((item) => item.current_price) || [],
                    },
                    {
                        name: 'Previsão',
                        data:
                            response.days_of_week
                                ?.map((item) => item.total_sales)
                                .map((item) => item.new_price) || [],
                    },
                ],
                yAxis: {
                    title: {
                        text: 'Volume de venda',
                    },
                },
                xAxis: {
                    title: {
                        text: 'Dia da semana',
                    },
                    categories: response.days_of_week.map(
                        (item) => diaDaSemana[item.day]
                    ),
                },
            });
        } else {
            setPrevisaoDemanda((prevState) => ({
                ...prevState,
                weekly_total_sales_forecast: {
                    days: null,
                },
            }));

            setGraficoSemanal(null);
        }
    };

    const listGraficoMensal = async () => {
        const response = await getGraficoMensal({
            productId: produtoSelecionado,
            storeId: lojaSelecionada,
            currentPrice: produto.value,
        });

        if (response && Object.keys(response).length !== 0) {
            setPrevisaoDemanda((prevState) => ({
                ...prevState,
                monthly_seasonalities_factor: response,
            }));

            setGraficoMensal({
                exporting: {
                    enabled: false,
                },
                title: {
                    text: '',
                },
                series: [
                    {
                        name: 'Dia do mês',
                        data:
                            response.days_of_month?.map(
                                (item) => item.factor
                            ) || [],
                    },
                ],
                yAxis: {
                    title: {
                        text: 'Fator de sazonalidade (%)',
                    },
                },
                xAxis: {
                    title: {
                        text: 'Dia do mês',
                    },
                    tickInterval: 4,
                    categories: [
                        '1',
                        '2',
                        '3',
                        '4',
                        '5',
                        '6',
                        '7',
                        '8',
                        '9',
                        '10',
                        '11',
                        '12',
                        '13',
                        '14',
                        '15',
                        '16',
                        '17',
                        '18',
                        '19',
                        '20',
                        '21',
                        '22',
                        '23',
                        '24',
                        '25',
                        '26',
                        '27',
                        '28',
                        '29',
                        '30',
                        '31',
                    ],
                },
            });
        } else {
            setPrevisaoDemanda((prevState) => ({
                ...prevState,
                monthly_seasonalities_factor: null,
            }));

            setGraficoMensal(null);
        }
    };

    const listGraficoElasticidade = async () => {
        const response = await getGraficoElasticidade({
            productId: produtoSelecionado,
            storeId: lojaSelecionada,
        });

        if (response && Object.keys(response).length !== 0) {
            setPrevisaoDemanda((prevState) => ({
                ...prevState,
                elasticity: response.value,
            }));

            setGraficoElasticidade({
                exporting: {
                    enabled: false,
                },
                chart: {
                    type: 'gauge',
                    backgroundColor: 'transparent',
                    height: 172,
                },
                title: {
                    text: '',
                },
                pane: {
                    startAngle: -90,
                    endAngle: 89.9,
                    background: null,
                    center: ['50%', '75%'],
                    size: '100%',
                },

                yAxis: {
                    min: 0,
                    max: 4,
                    tickPixelInterval: 0,
                    tickPosition: 'inside',
                    tickColor:
                        Highcharts.defaultOptions.chart.backgroundColor ||
                        '#FFFFFF',
                    tickLength: 20,
                    tickWidth: 2,
                    minorTickInterval: null,
                    labels: {
                        formatter: () => null,
                    },
                    plotBands: [
                        {
                            from: 0,
                            to: 1,
                            color: '#F5F5F5',
                            thickness: 15,
                        },
                        {
                            from: 1,
                            to: 2,
                            color: '#CCE9FA',
                            thickness: 15,
                        },
                        {
                            from: 2,
                            to: 3,
                            color: '#559FF2',
                            thickness: 15,
                        },
                        {
                            from: 3,
                            to: 4,
                            color: '#0D66D0',
                            thickness: 15,
                        },
                    ],
                },
                tooltip: {
                    enabled: false,
                },
                series: [
                    {
                        name: 'Elasticidade',
                        data: [
                            response.value < 0
                                ? 0
                                : response.value > 4
                                ? 4
                                : +response.value.toFixed(2),
                        ],
                        dataLabels: {
                            y: 100,
                            borderWidth: 0,
                            color: '#323232',
                            style: {
                                fontSize: '24px',
                                fontWeight: '300',
                                fontFamily: 'Open Sans',
                                fontStyle: 'normal',
                            },
                        },
                        dial: {
                            radius: '65%',
                            backgroundColor: '#505050',
                            baseWidth: 12,
                            baseLength: '0%',
                            rearLength: '0%',
                        },
                        pivot: {
                            backgroundColor: '#505050',
                            radius: 6,
                        },
                    },
                ],
            });
        } else {
            setPrevisaoDemanda((prevState) => ({
                ...prevState,
                elasticity: null,
            }));

            setGraficoElasticidade(null);
        }
    };

    const simularPrevisao = async () => {
        if (!novoPrecoProduto) {
            setErroPreco(true);
            setIsSaveEnabled(false);
            setPrevisaoSalva(null);
        } else {
            const response = await getSimulacaoPrevisao({
                productId: produtoSelecionado,
                storeId: lojaSelecionada,
                currentPrice: produto.value,
                newPrice: String(novoPrecoProduto).includes(',')
                    ? String(novoPrecoProduto).replace(',', '.')
                    : novoPrecoProduto,
            });
            if (response) {
                const produto = produtos.find(
                    (item) => item.value === produtoSelecionado
                );
                const loja = lojas.find(
                    (item) => item.store_id === lojaSelecionada
                );
                setPrevisaoDemanda((prevState) => ({
                    ...prevState,
                    ...response,
                    product: {
                        id: produto?.value,
                        description: produto?.description,
                        store: {
                            id: loja?.value,
                            description: loja?.label,
                        },
                    },
                }));
                setSimulacao(response);
                listGraficoMensal();
                listaGraficoSemanal();
                listGraficoElasticidade();
                setIsSaveEnabled(true);
            }
        }

        if (isRedirect) setIsRedirect(false);
    };

    const newPrevisao = async () => {
        const response = await savePrevisao(previsaoDemanda);

        if (response.status >= 200 && response.status < 300) {
            Notification.success({
                title: 'Notificação',
                duration: 6000,
                description: (
                    <div>
                        <p>Previsão salva com sucesso!</p>
                    </div>
                ),
            });
            setPrevisaoSalva({
                ids: [response?.data?.id],
                produto: response?.data?.product?.description,
                loja: response?.data?.product?.store?.description,
            });
            setIsSaveEnabled(false);
        } else {
            Notification.error({
                title: 'Notificação',
                duration: 6000,
                description: (
                    <div>
                        <p>Erro ao salvar previsão. Favor entrar em contato</p>
                    </div>
                ),
            });
        }
    };

    const baixarPrevisao = () => {
        downloadPrevisao(previsaoSalva).then(() => {
            console.log('ok');
        });
    };

    useEffect(() => {
        if (produtoSelecionado && lojaSelecionada) {
            listProdutoValue();
            setSimulacao(null);
            setIsSaveEnabled(false);
            setPrevisaoSalva(null);
        }

        if (produtoSelecionado && erroPreco) setErroPreco(false);
    }, [lojaSelecionada, produtoSelecionado]);

    useEffect(() => {
        listLojas();
        if (query.get('storeId')) setLojaSelecionada(query.get('storeId'));
        if (query.get('productId')) {
            listProdutos(query.get('productId'));
            setProdutoSelecionado(query.get('productId'));
            setIsRedirect(true);
        }
    }, []);

    useEffect(() => {
        if (
            query.get('productId') &&
            query.get('storeId') &&
            produtoSelecionado &&
            lojaSelecionada &&
            produto.value &&
            novoPrecoProduto &&
            isRedirect
        ) {
            simularPrevisao();
        }
    }, [lojaSelecionada, produtoSelecionado, novoPrecoProduto, produto]);

    return (
        <Container className="main-container previsao-demanda-page">
            <FlexboxGrid>
                <FlexboxGrid.Item colspan={24}>
                    <LoadingSpiner size="md" />
                    <Content className="no-margin-bottom page-header">
                        <div className="grupo-titulo">
                            <h2>Previsão de Demanda</h2>
                            <Button
                                appearance="link"
                                onClick={() =>
                                    history.push(
                                        '/ipa/simulacoes-analises/previsao-demanda/lista'
                                    )
                                }
                            >
                                Ver simulações salvas
                            </Button>
                        </div>
                        <div>
                            <IconButton
                                disabled={!previsaoSalva}
                                icon={<Icon icon="download" />}
                                onClick={baixarPrevisao}
                            />
                            <IconButton
                                disabled={!isSaveEnabled}
                                icon={<Icon icon="save" />}
                                onClick={newPrevisao}
                                style={{ marginLeft: '16px' }}
                            />
                        </div>
                    </Content>
                    <Content className="page-filtros">
                        <h3>Selecione um produto e uma loja</h3>
                        <FlexboxGrid>
                            <FlexboxGrid.Item colspan={24}>
                                <div className="campanhas-filtros">
                                    <FlexboxGrid justify="space-between">
                                        <FlexboxGrid.Item
                                            componentClass={Col}
                                            lg={16}
                                        >
                                            <FlexboxGrid className="filtros-container">
                                                <FlexboxGrid.Item
                                                    componentClass={Col}
                                                >
                                                    <SelectPill
                                                        id="select-picker-pill"
                                                        placeholder="Produto"
                                                        name="produto"
                                                        filter={false}
                                                        value={
                                                            produtoSelecionado
                                                        }
                                                        data={produtos}
                                                        onSelect={(value) =>
                                                            setProdutoSelecionado(
                                                                value
                                                            )
                                                        }
                                                        onSearch={(e) =>
                                                            e.length > 3 &&
                                                            listProdutos(e)
                                                        }
                                                        onClean={() =>
                                                            setProdutoSelecionado(
                                                                ''
                                                            )
                                                        }
                                                        locale={{
                                                            noResultsText:
                                                                loadingProdutos
                                                                    ? 'Carregando produtos...'
                                                                    : 'Nenhum item encontrado',
                                                            searchPlaceholder:
                                                                'Pesquisar',
                                                        }}
                                                    />
                                                </FlexboxGrid.Item>
                                                <FlexboxGrid.Item
                                                    componentClass={Col}
                                                >
                                                    <SelectPill
                                                        id="select-picker-pill"
                                                        placeholder="Loja"
                                                        name="loja"
                                                        filter={false}
                                                        value={lojaSelecionada}
                                                        data={lojas}
                                                        onSelect={(value) =>
                                                            setLojaSelecionada(
                                                                value
                                                            )
                                                        }
                                                        onClean={() =>
                                                            setLojaSelecionada(
                                                                ''
                                                            )
                                                        }
                                                        locale={{
                                                            noResultsText:
                                                                loadingLojas
                                                                    ? 'Carregando lojas...'
                                                                    : 'Nenhum item encontrado',
                                                            searchPlaceholder:
                                                                'Pesquisar',
                                                        }}
                                                    />
                                                </FlexboxGrid.Item>
                                            </FlexboxGrid>
                                        </FlexboxGrid.Item>
                                    </FlexboxGrid>
                                </div>
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                    </Content>
                    <Content className="page-campos-simulacao">
                        <h3>Simulador</h3>
                        <div className="simulacao-info">
                            <p>
                                Defina um preço para simular a previsão de
                                demanda
                            </p>
                        </div>
                        <FlexboxGrid>
                            <FlexboxGrid.Item
                                componentClass={Col}
                                lg={3}
                                md={5}
                                sm={24}
                                xs={24}
                            >
                                <FlexboxGrid justify="center">
                                    <FlexboxGrid.Item colspan={24}>
                                        <InputGroup
                                            className={`${
                                                erroPreco ? 'error' : ''
                                            }`}
                                        >
                                            <InputGroup.Addon>
                                                R$
                                            </InputGroup.Addon>
                                            <CurrencyInput
                                                className="rs-input"
                                                decimalSeparator=","
                                                thousandSeparator="."
                                                precision="2"
                                                allowEmpty
                                                value={novoPrecoProduto}
                                                onChangeEvent={(e, value) =>
                                                    setNovoPrecoProduto(value)
                                                }
                                            />
                                        </InputGroup>
                                    </FlexboxGrid.Item>
                                    <FlexboxGrid.Item>
                                        <p className="simulacao-input-label">
                                            Preço
                                        </p>
                                        {erroPreco ? (
                                            <p className="simulacao-input-error">
                                                Campo obrigatório
                                            </p>
                                        ) : null}
                                    </FlexboxGrid.Item>
                                </FlexboxGrid>
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item componentClass={Col} xsHidden>
                                <div className="divider" />
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item
                                componentClass={Col}
                                lg={3}
                                md={5}
                                sm={12}
                                xs={24}
                                style={{ marginRight: '16px' }}
                            >
                                <FlexboxGrid justify="center">
                                    <FlexboxGrid.Item colspan={24}>
                                        {produto.competitiveness ? (
                                            <InputGroup>
                                                <CurrencyInput
                                                    className="rs-input"
                                                    decimalSeparator=","
                                                    thousandSeparator="."
                                                    precision="2"
                                                    allowEmpty
                                                    value={
                                                        produto.competitiveness
                                                    }
                                                    disabled
                                                />
                                                <InputGroup.Addon>
                                                    %
                                                </InputGroup.Addon>
                                            </InputGroup>
                                        ) : (
                                            <InputGroup>
                                                <Input disabled value="--" />
                                                <InputGroup.Addon>
                                                    %
                                                </InputGroup.Addon>
                                            </InputGroup>
                                        )}
                                    </FlexboxGrid.Item>
                                    <FlexboxGrid.Item>
                                        <p className="simulacao-input-label">
                                            Competitividade
                                        </p>
                                    </FlexboxGrid.Item>
                                </FlexboxGrid>
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item
                                componentClass={Col}
                                lg={3}
                                md={5}
                                sm={12}
                                xs={24}
                            >
                                <FlexboxGrid justify="center">
                                    <FlexboxGrid.Item colspan={24}>
                                        {produto.margin ? (
                                            <InputGroup>
                                                <CurrencyInput
                                                    className="rs-input"
                                                    name="from"
                                                    decimalSeparator=","
                                                    thousandSeparator="."
                                                    precision="2"
                                                    allowEmpty
                                                    value={produto.margin}
                                                    disabled
                                                />
                                                <InputGroup.Addon>
                                                    %
                                                </InputGroup.Addon>
                                            </InputGroup>
                                        ) : (
                                            <InputGroup>
                                                <Input disabled value="--" />
                                                <InputGroup.Addon>
                                                    %
                                                </InputGroup.Addon>
                                            </InputGroup>
                                        )}
                                    </FlexboxGrid.Item>
                                    <FlexboxGrid.Item>
                                        <p className="simulacao-input-label">
                                            Margem
                                        </p>
                                    </FlexboxGrid.Item>
                                </FlexboxGrid>
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item componentClass={Col} xsHidden>
                                <div className="divider" />
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item>
                                <Button
                                    appearance="primary"
                                    onClick={simularPrevisao}
                                    disabled={
                                        !produtoSelecionado || !lojaSelecionada
                                    }
                                >
                                    SIMULAR PREVISÃO
                                </Button>
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                    </Content>
                    <Content>
                        {query.get('productId') &&
                        query.get('storeId') &&
                        !produto.value ? (
                            <div className="previsao-volume-sem-dados">
                                <WarningRoundedIcon
                                    style={{ fontSize: '24px' }}
                                />
                                <p>
                                    Não foi possível calcular a previsão de
                                    demanda deste produto para esta loja.
                                </p>
                                <span>Busque outro produto ou loja.</span>
                            </div>
                        ) : !simulacao ? (
                            <div className="simulacao-data-info">
                                <img
                                    src={SimulacaoData}
                                    alt="Simulação de dados"
                                />
                                <p>
                                    Para ver os resultados da simulação,
                                    <br /> preencha os dados acima
                                </p>
                            </div>
                        ) : (
                            <>
                                <div className="previsao-volume-info">
                                    <img
                                        alt="ícone de previsão"
                                        src={IconQuery}
                                    />
                                    <h3>
                                        Previsão de Demanda de Volume e Receita
                                    </h3>
                                    <p>
                                        Previsão realizada para os próximos 7
                                        dias
                                    </p>
                                    {simulacao?.current_price?.forecast &&
                                    Object.keys(
                                        simulacao.current_price.forecast
                                    ).length !== 0 ? (
                                        <div className="confiabilidade-info">
                                            <p>
                                                Essa previsão tem margem de erro
                                                de aproximadamente{' '}
                                                <strong>40% </strong>
                                                <Whisper
                                                    trigger="hover"
                                                    placement="top"
                                                    speaker={
                                                        <Tooltip>
                                                            Estimativas de
                                                            demanda estão
                                                            sujeitas a erros,
                                                            visto que o preço
                                                            não é o único fator
                                                            de influência do
                                                            consumo
                                                        </Tooltip>
                                                    }
                                                >
                                                    <Icon icon="info" />
                                                </Whisper>
                                            </p>
                                        </div>
                                    ) : null}
                                </div>
                                {simulacao?.current_price?.forecast &&
                                Object.keys(simulacao.current_price.forecast)
                                    .length === 0 ? (
                                    <div className="previsao-volume-sem-dados">
                                        <WarningRoundedIcon
                                            style={{ fontSize: '24px' }}
                                        />
                                        <p>
                                            Não temos dados suficientes para
                                            calcular a previsão de demanda desse
                                            produto.
                                        </p>
                                        <span>
                                            Busque outro produto ou loja.
                                        </span>
                                    </div>
                                ) : (
                                    <Grid fluid>
                                        <Row gutter={16}>
                                            <Col xs={8} lg={3}>
                                                <ValueCard
                                                    title="Preço"
                                                    currentValue={
                                                        simulacao?.current_price
                                                            ?.value
                                                    }
                                                    value={
                                                        simulacao?.new_price
                                                            ?.value
                                                    }
                                                    type="value"
                                                />
                                            </Col>
                                            <Col xs={8} lg={3}>
                                                <ValueCard
                                                    title="Competitividade"
                                                    currentValue={
                                                        simulacao?.current_price
                                                            ?.competitiveness
                                                    }
                                                    value={
                                                        simulacao?.new_price
                                                            ?.competitiveness
                                                    }
                                                    type="percent"
                                                />
                                            </Col>
                                            <Col xs={8} lg={3}>
                                                <ValueCard
                                                    title="Margem"
                                                    currentValue={
                                                        simulacao?.current_price
                                                            ?.margin
                                                    }
                                                    value={
                                                        simulacao?.new_price
                                                            ?.margin
                                                    }
                                                    type="percent"
                                                />
                                            </Col>
                                            <Col xs={8} lg={4}>
                                                <ValueCard
                                                    title="Previsão de vendas 7 dias"
                                                    currentValue={
                                                        simulacao?.current_price
                                                            ?.forecast?.sales
                                                    }
                                                    value={
                                                        simulacao?.new_price
                                                            ?.forecast?.sales
                                                    }
                                                />
                                            </Col>
                                            <Col xs={8} lg={6}>
                                                <ValueCard
                                                    title="Previsão de receita 7 dias"
                                                    currentValue={
                                                        simulacao?.current_price
                                                            ?.forecast?.revenue
                                                    }
                                                    value={
                                                        simulacao?.new_price
                                                            ?.forecast?.revenue
                                                    }
                                                    type="value"
                                                />
                                            </Col>
                                            <Col xs={8} lg={5}>
                                                <ValueCard
                                                    title="Previsão de lucro 7 dias"
                                                    currentValue={
                                                        simulacao?.current_price
                                                            ?.forecast?.profit
                                                    }
                                                    value={
                                                        simulacao?.new_price
                                                            ?.forecast?.profit
                                                    }
                                                    type="value"
                                                />
                                            </Col>
                                        </Row>
                                        <Row gutter={16}>
                                            <Col xs={8}>
                                                <ValueCard
                                                    title="Variação venda"
                                                    value={
                                                        simulacao?.variation
                                                            ?.sales
                                                    }
                                                    type="variation"
                                                    color={
                                                        simulacao?.variation
                                                            ?.sales > 0
                                                            ? 'positive'
                                                            : simulacao
                                                                  ?.variation
                                                                  ?.sales < 0
                                                            ? 'negative'
                                                            : ''
                                                    }
                                                />
                                            </Col>
                                            <Col xs={8}>
                                                <ValueCard
                                                    title="Variação receita"
                                                    value={
                                                        simulacao?.variation
                                                            ?.revenue
                                                    }
                                                    type="variation"
                                                    color={
                                                        simulacao?.variation
                                                            ?.revenue > 0
                                                            ? 'positive'
                                                            : simulacao
                                                                  ?.variation
                                                                  ?.revenue < 0
                                                            ? 'negative'
                                                            : ''
                                                    }
                                                />
                                            </Col>
                                            <Col xs={8}>
                                                <ValueCard
                                                    title="Variação lucro"
                                                    value={
                                                        simulacao?.variation
                                                            ?.profit
                                                    }
                                                    type="variation"
                                                    color={
                                                        simulacao?.variation
                                                            ?.profit > 0
                                                            ? 'positive'
                                                            : simulacao
                                                                  ?.variation
                                                                  ?.profit < 0
                                                            ? 'negative'
                                                            : ''
                                                    }
                                                />
                                            </Col>
                                        </Row>
                                        <Row
                                            className="container-graficos"
                                            gutter={16}
                                        >
                                            <Col xs={24} md={12} lg={8}>
                                                <div className="grafico-card">
                                                    <h2>
                                                        Previsão de venda
                                                        semanal
                                                    </h2>
                                                    <p>
                                                        Gráfico comparativo do
                                                        preço vigente e simulado
                                                        na venda semanal
                                                    </p>
                                                    {graficoSemanal ? (
                                                        <HighchartsReact
                                                            highcharts={
                                                                Highcharts
                                                            }
                                                            options={
                                                                graficoSemanal
                                                            }
                                                        />
                                                    ) : (
                                                        <div className="grafico-indisponivel">
                                                            <WarningRoundedIcon
                                                                style={{
                                                                    fontSize:
                                                                        '24px',
                                                                }}
                                                            />
                                                            <h3>
                                                                Gráfico
                                                                indisponível
                                                            </h3>
                                                            <p>
                                                                Não temos dados
                                                                suficientes para
                                                                gerar o gráfico
                                                                de sazonalidade
                                                                mensal deste
                                                                produto
                                                            </p>
                                                        </div>
                                                    )}
                                                </div>
                                            </Col>
                                            <Col xs={24} md={12} lg={16}>
                                                <div className="grafico-card">
                                                    <h2>
                                                        Gráfico de sazonalidade
                                                        mensal
                                                    </h2>
                                                    <p>
                                                        Comportamento de venda
                                                        mensal baseado em
                                                        histórico
                                                    </p>
                                                    {graficoMensal ? (
                                                        <HighchartsReact
                                                            highcharts={
                                                                Highcharts
                                                            }
                                                            options={
                                                                graficoMensal
                                                            }
                                                        />
                                                    ) : (
                                                        <div className="grafico-indisponivel">
                                                            <WarningRoundedIcon
                                                                style={{
                                                                    fontSize:
                                                                        '24px',
                                                                }}
                                                            />
                                                            <h3>
                                                                Gráfico
                                                                indisponível
                                                            </h3>
                                                            <p>
                                                                Não temos dados
                                                                suficientes para
                                                                gerar o gráfico
                                                                de sazonalidade
                                                                mensal deste
                                                                produto
                                                            </p>
                                                        </div>
                                                    )}
                                                </div>
                                            </Col>
                                            <Col xs={24} md={24} lg={24}>
                                                {graficoElasticidade ? (
                                                    <div className="grafico-card-elasticidade">
                                                        <h2>
                                                            Elasticidade do
                                                            produto
                                                        </h2>
                                                        <HighchartsReact
                                                            highcharts={
                                                                Highcharts
                                                            }
                                                            options={
                                                                graficoElasticidade
                                                            }
                                                        />
                                                        <h3>
                                                            Esse produto é{' '}
                                                            {previsaoDemanda.elasticity >=
                                                                0 &&
                                                            previsaoDemanda.elasticity <=
                                                                1
                                                                ? 'Inelástico'
                                                                : previsaoDemanda.elasticity >=
                                                                      1 &&
                                                                  previsaoDemanda.elasticity <=
                                                                      2
                                                                ? 'Pouco elástico'
                                                                : previsaoDemanda.elasticity >=
                                                                      2 &&
                                                                  previsaoDemanda.elasticity <=
                                                                      3
                                                                ? 'Elástico'
                                                                : previsaoDemanda.elasticity >=
                                                                  3
                                                                ? 'Muito elástico'
                                                                : ''}
                                                        </h3>
                                                        <p>
                                                            {previsaoDemanda.elasticity >=
                                                                0 &&
                                                            previsaoDemanda.elasticity <=
                                                                1
                                                                ? 'Variações de preços não impactam nas vendas do produto'
                                                                : previsaoDemanda.elasticity >=
                                                                      1 &&
                                                                  previsaoDemanda.elasticity <=
                                                                      2
                                                                ? 'Variações de preços podem impactar nas vendas do produto'
                                                                : previsaoDemanda.elasticity >=
                                                                      2 &&
                                                                  previsaoDemanda.elasticity <=
                                                                      3
                                                                ? 'Pequenas variações de preços impactam nas vendas do produto'
                                                                : previsaoDemanda.elasticity >=
                                                                  3
                                                                ? 'Pequenas variações de preços impactam fortemente nas vendas do produto'
                                                                : ''}
                                                        </p>
                                                    </div>
                                                ) : (
                                                    <div className="grafico-indisponivel-elasticidade">
                                                        <h2>
                                                            Elasticidade do
                                                            produto
                                                        </h2>
                                                        <WarningRoundedIcon
                                                            style={{
                                                                fontSize:
                                                                    '24px',
                                                            }}
                                                        />
                                                        <h3>
                                                            Gráfico indisponível
                                                        </h3>
                                                        <p>
                                                            Não temos dados
                                                            suficientes para
                                                            gerar o gráfico de
                                                            elasticidade deste
                                                            produto
                                                        </p>
                                                    </div>
                                                )}
                                            </Col>
                                        </Row>
                                    </Grid>
                                )}
                            </>
                        )}
                    </Content>
                </FlexboxGrid.Item>
            </FlexboxGrid>
        </Container>
    );
};

export default withRouter(PrevisaoDemanda);
