import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TableProps } from 'rsuite/lib/Table';
import { RootState } from '../../@types/RootState';

export type GetFamilyModalPaginationStateSortState<
    K extends TableProps['sortColumn'] = string,
> = {
    type: K;
    orderBy: TableProps['sortType'];
};

const initialState: GetFamilyModalPaginationStateSortState = {
    type: 'productIds',
    orderBy: 'asc',
};

const slice = createSlice({
    name: 'getFamilyModal/sort',
    initialState,
    reducers: {
        SET_GET_FAMILY_MODAL_SORT: (
            _state,
            { payload }: PayloadAction<GetFamilyModalPaginationStateSortState>,
        ) => payload,
    },
});

export const { SET_GET_FAMILY_MODAL_SORT } = slice.actions;

export const selectorGetFamilyModalSort = (state: RootState) => {
    return state.getFamilyModalReducer.sort;
};

export default slice.reducer;
