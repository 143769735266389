import React from 'react';
import { withRouter } from 'react-router-dom';
import {
    Button,
    Col,
    Container,
    Content,
    FlexboxGrid,
    Grid,
    Message,
    Row,
} from 'rsuite';

import { LoadingSpiner } from '../../../components/LoadingSpinner';

const TabelaPrecos = () => {
    const downloadEcxel = () => {
        const csvBlob = new Blob(['Nome Cliente;Cod Produto;Cod Loja;Preco Minimo;Preco Maximo;Preco Sugerido'], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(csvBlob);
        link.download = 'Modelo_de_envio_de_tabela_de_preço.csv';
        document.body.appendChild(link);
        link.click();
        URL.revokeObjectURL(link.href);
        document.body.removeChild(link);
    };

    const setOpenFormsUpload = () => {
        window.open('https://forms.clickup.com/12917775/f/ca70f-69943/PHAL1FEDELXHFJR0Y8/');
    };

    return (
        <Container
            className="main-container tabela-precos-page"
        >
            <LoadingSpiner size="md" />
            <FlexboxGrid>
                <FlexboxGrid.Item
                    className="container-title"
                    componentClass={Col}
                    lg={24}
                    md={24}
                    sm={24}
                    xs={24}
                >
                    <Content className="container-preco-title">
                        <strong className="tabela-preco-title">
                            Tabela de Preço
                        </strong>
                        <br />
                        <span>
                            A tabela de preços ajuda a garantir transparência e
                            confiança para os clientes, além de automatizar e reduzir
                            erros na precificação dos produtos com preços tabelados ou
                            acordados com a indústria.
                        </span>
                        <br />
                    </Content>
                </FlexboxGrid.Item>
            </FlexboxGrid>
            <Grid fluid>
                <Row>
                    <Col
                        className="tabela-preco-texto-explicativo"
                        xs={12}
                    >
                        <Content className="container-preco-texto-explicativo">
                            <span className="content-title">
                                Como funciona a tabela de preços
                            </span>
                            <br />
                            <br />
                            <span>
                                A
                                {' '}
                                <strong>tabela de preços</strong>
                                {' '}
                                opera como uma referência para os valores dos produtos, estabelecendo limites para esses valores. Esses limites podem ser
                                definidos como uma faixa de preços,
                                com um preço mínimo e máximo, ou como um valor fixo recomendado (preço sugerido).
                                <br />
                                <br />
                                Quando um produto está configurado com os valores da tabela,
                                o IPA verifica se o preço atual do produto está dentro da faixa definida ou se corresponde ao preço sugerido.
                                <br />
                                Se o preço estiver fora desses parâmetros, será necessária uma revisão manual. Dessa forma,
                                a tabela de preços serve como um guia para garantir que os preços dos produtos estejam alinhados com as estratégias de precificação,
                                permitindo uma revisão manual quando necessário para ajustar os valores.
                                <br />
                                <br />
                                Para utilizar, basta enviar o arquivo para que possamos adicionar ao sistema.
                                <br />
                                <br />
                                Se tiver dúvidas entre em contato com a gente.
                            </span>
                            <br />
                        </Content>
                    </Col>
                    <Col
                        className="container-upload"
                        xs={12}

                    >
                        <Content
                            className="container-preco-upload"
                        >
                            <span className="content-title">
                                Envie sua tabela de preços atualizada
                            </span>
                            <br />
                            <br />
                            <span>
                                Você pode enviar qualquer arquivo
                                {' '}
                                <strong> .txt, .xls, e .xlsx </strong>
                                com as colunas de Produto, Loja,
                                <br />
                                {' '}
                                Preço mínimo, Preço máximo ou Preço sugerido. Para excluir um produto da tabela,
                                {' '}
                                <br />
                                preencha 0 nos campos de Preço Mínimo e Preço Máximo.
                                <br />
                                <br />
                                <span
                                    className="download-modelo"
                                    onClick={downloadEcxel}
                                >
                                    {' '}
                                    Baixe aqui
                                    {' '}
                                </span>
                                o modelo de planilha.
                            </span>
                            <br />
                            <br />
                            <Button
                                className="botao-arquivo"
                                appearance="primary"
                            >
                                <span
                                    onClick={setOpenFormsUpload}
                                >
                                    ENVIAR ARQUIVO
                                </span>
                            </Button>
                        </Content>
                    </Col>
                </Row>
            </Grid>
            <FlexboxGrid justify="start">
                <FlexboxGrid.Item
                    className="container-message"
                    componentClass={Col}
                    lg={24}
                    md={24}
                    sm={24}
                    xs={24}
                >
                    <Content className="container-preco-message">
                        <Message
                            className="message"
                            showIcon
                            type="warning"
                            description={(
                                <span>
                                    Assim que o arquivo for enviado, nossa
                                    equipe fará a atualização no sistema,
                                    e a tabela de preços estará em funcionamento em
                                    {' '}
                                    <strong>até 48 horas.</strong>
                                </span>
                            )}
                        />
                    </Content>
                </FlexboxGrid.Item>
            </FlexboxGrid>
        </Container>
    );
};
export default withRouter(TabelaPrecos);
