import React, { useState } from 'react';
import {
    Container, Header, Nav, Content,
} from 'rsuite';
import ListasCadastradas from './Components/ListasCadastradas';

import NovaLista from './Components/NovaLista';

const RevisaoLista = () => {
    const [tab, setTab] = useState('LISTA_CADASTRADA');

    return (
        <Container className="main-container revisao-lista-page">
            <Header>
                <div className="header">
                    <h1>Revisão de Lista</h1>
                    <h2>Quer melhorar o seu retorno? Revise aqui se sua lista de pesquisa está aderente.</h2>
                </div>
                <div>
                    <Nav activeKey={tab} appearance="subtle" onSelect={(eventKey) => setTab(eventKey)}>
                        <Nav.Item eventKey="LISTA_CADASTRADA">Listas cadastradas</Nav.Item>

                        <Nav.Item eventKey="NOVA_LISTA">Nova lista</Nav.Item>

                    </Nav>
                </div>
            </Header>
            <Content>
                <div>
                    {tab === 'LISTA_CADASTRADA' ? <ListasCadastradas /> : <NovaLista />}
                </div>
            </Content>
        </Container>
    );
};

export default RevisaoLista;
