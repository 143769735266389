import React from 'react';
import {
    Table,
} from 'rsuite';
import useAvailableUFs from '../../Hooks/useAvailableUFs';
import styles from './ModalHowItWorks.module.scss';

const { Column, HeaderCell, Cell } = Table;

const AvailableUfTable = () => {
    const { ufData } = useAvailableUFs();
  return (
      <Table
          className={styles['available-ufs-table']}
          height={500}
          data={ufData}
          bordered
      >
          <Column flexGrow={1}>
              <HeaderCell>
                  UF
              </HeaderCell>
              <Cell dataKey="id" />
          </Column>
          <Column flexGrow={1}>
              <HeaderCell>
                  Disponibilidade
              </HeaderCell>
              <Cell dataKey="available">
                  {(rowData) => (rowData.available ? 'Disponível' : 'Indisponível')}
              </Cell>
          </Column>
          <Column flexGrow={1}>
              <HeaderCell>
                  Máx. de retorno
              </HeaderCell>
              <Cell dataKey="retorno" />
          </Column>
      </Table>
  );
};

export default AvailableUfTable;
