import { leadFree } from '../../../../services/EventLeadService';
import { trackEvent } from '../../../../utils/MatomoConfig';

export function validEmail(email) {
    const regexEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regexEmail.test(email.toLowerCase());
}

export function validPhone(phone) {
    const phoneWithSpecialCaracter = phone.replace(/^|\D/g, '');
    const regexPhone = /^\(?(?:[14689][1-9]|2[12478]|3[1234578]|5[1345]|7[134579])\)? ?(?:[2-8]|9[1-9])[0-9]{3}-?[0-9]{4}$/;
    return regexPhone.test(phoneWithSpecialCaracter.toLowerCase());
}

export function validateName(name) {
    const regexName = /\b[A-Za-zÀ-ú][A-Za-zÀ-ú]+,?\s[A-Za-zÀ-ú][A-Za-zÀ-ú]{2,19}\b/;
    return regexName.test(name);
}

export function setUserAccoundModel(account) {
    return {
        nome: account?.name || null,
        email: account?.email,
        telefone: account?.phone || null,
        empresa: account?.company || null,
        cargo: account?.role || null,
        segmento: account?.companySegment || null,
        canal: account?.channel || null,
        agrupamentoPais: account?.region === 'country',
        estado: account?.region === 'state' ? { value: account.state } : null,
        cidadeId: account?.city || null,
        casosDeUso: account?.motives?.length ? account.motives : null,
        setorAtuacao: account?.activitySector || null,
    };
}

function mapSegmento(segmento) {
    switch (segmento) {
        case 'ATACADO':
            return 'Atacado';
        case 'CONSULTORIA':
            return 'Consultoria';
        case 'ECOMMERCE':
            return 'E-commerce';
        case 'ERP':
            return 'ERP';
        case 'FARMA':
            return 'Varejo Farma';
        case 'HOMECENTER':
            return 'Home Center';
        case 'INDUSTRIA':
            return 'Indústria (Alimentar / Higiene e Beleza / Farma)';
        case 'VAREJO':
            return 'Supermercado, hipermercado ou mercearia';
        case 'TRADE_MARKETING':
            return 'Trade Marketing';
        case 'DISTRIBUIDOR':
            return 'Distribuidor';
        case 'OUTROS':
            return 'Outros';
        case 'PESSOAFISICA':
            return 'Pessoa Física';
        case 'INSTITUICAOF':
            return 'Instituição Financeira';
        case 'PETS':
            return 'Pets';
        default:
            return true;
    }
}

function mapJobLevel(jobLevel) {
    switch (jobLevel) {
        case 'ESTAGIO':
            return 'Estágio';
        case 'AUXILIAR':
            return 'Auxiliar';
        case 'ASSISTENTE':
            return 'Assistente';
        case 'ANALISTA':
            return 'Analista';
        case 'SUPERVISAO':
            return 'Supervisão';
        case 'COORDENACAO':
            return 'Coordenação';
        case 'GERENCIA':
            return 'Gerência';
        case 'DIRETORIA':
            return 'Diretoria';
        case 'PRESIDENCIA':
            return 'Presidência';
        case 'AUTONOMO':
            return 'Autônomo(a)';
        case 'CONSULTORIA':
            return 'Consultoria';
        case 'ESPECIALISTA':
            return 'Especialista';
        default:
            return null;
    }
}

function mapSectors(sectors) {
    switch (sectors) {
        case 'PRECIFICACAO':
            return 'Precificação';
        case 'COMERCIAL':
            return 'Comercial';
        case 'COMPRAS':
            return 'Compras';
        case 'TECNOLOGIA_INFORMACAO':
            return 'Tecnologia da Informação';
        case 'INTELIGENCIA_NEGOCIOS':
            return 'Inteligência de Negócios';
        case 'OPERACIONAL':
            return 'Operacional';
        case 'MARKETING':
            return 'Marketing';
        case 'TRADE_MARKETING':
            return 'Trade Marketing';
        case 'FINANCEIRO_CONTABILIDADE':
            return 'Financeiro/Contabilidade';
        case 'OUTROS':
            return 'Outros';
        default:
            return null;
    }
}

function mapEventLeadDTO(identifier, data) {
    return {
        rotulo_evento_do_produto: identifier,
        email: data?.email || null,
        firstname: data?.name?.indexOf(' ') > 0 ? data?.name?.substring(0, data?.name?.indexOf(' ')) : data?.name,
        lastname: data?.name?.indexOf(' ') > 0 ? data?.name?.substring(data?.name?.indexOf(' ') + 1, data?.name.length) : null,
        phone: data?.phone || null,
        company: data?.company || null,
        qual_a_segmentacao_da_sua_empresa_: data.companySegment ? mapSegmento(data.companySegment) : null,
        qual_o_seu_setor_de_atuacao_na_empresa_: data?.activitySector ? mapSectors(data.activitySector) : null,
        nivel_de_cargo: data?.role ? mapJobLevel(data?.role) : null,
        objetivo_utilizacao___infopanel_free: data?.motives?.length
            ? (data?.motives?.every((item) => item === 'USO_PESSOAL') ? 'Uso pessoal' : 'Uso profissional') : null,
    };
}

function sendMatomoEvent(identifier) {
    trackEvent('Free', identifier);
}

export function sendLeadEvent(identifier, data) {
    leadFree(mapEventLeadDTO(identifier, data));
    sendMatomoEvent(identifier, data);
}
