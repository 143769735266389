import { useContext, useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { format } from 'date-fns';
import { getTotalVendasPorLoja } from '../services';
// @ts-ignore
import { FiltrosContextVendas } from '../Pages/VendasGerais/index.tsx';

export const useTotalVendasPorLoja = () => {
    const filtros = useContext(FiltrosContextVendas);

    const [grafico, setGrafico] = useState({});

    const listTotalVendasPorLoja = async () => {
        const params = {
            filters: [
                {
                    filter: 'STOREID',
                    values: filtros?.lojasSelecionadas || null,
                },
                {
                    filter: 'PRODUCTID',
                    values: filtros?.produtosSelecionados || null,
                },
                {
                    filter: 'STARTDATE',
                    values: filtros?.periodoSelecionado?.length
                        ? [format(filtros.periodoSelecionado[0], 'yyyy-MM-dd')]
                        : null,
                },
                {
                    filter: 'ENDDATE',
                    values: filtros?.periodoSelecionado?.length
                        ? [format(filtros.periodoSelecionado[1], 'yyyy-MM-dd')]
                        : null,
                },
                {
                    filter: 'STORETYPE',
                    values: filtros?.tipoLojasSelecionadas || null,
                },
                {
                    filter: 'CATEGORYLEVEL1',
                    values: filtros?.categoriaNivel1Selecionadas || null,
                },
                {
                    filter: 'CATEGORYLEVEL2',
                    values: filtros?.categoriaNivel2Selecionadas || null,
                },
            ],
        };
        const resp = await getTotalVendasPorLoja(params);
        if (resp.length) {
            const storeId = Object.keys(resp[0])[0]?.split('.');
            const sumSalesGross = Object.keys(resp[0])[2]?.split('.');
            return {
                [storeId[1]]: resp.map(
                    (item: { [x: string]: any }) => +item[Object.keys(item)[0]]
                ),
                [sumSalesGross[1]]: resp.map((item: { [x: string]: any }) =>
                    parseFloat(
                        // @ts-ignore
                        Number(parseFloat(item[Object.keys(item)[2]])).toFixed(
                            2
                        )
                    )
                ),
            };
        }
    };

    const { isLoading, isError, data, error } = useQuery(
        ['listTotalVendasPorLoja', filtros],
        listTotalVendasPorLoja,
        {
            retry: false,
            cacheTime: 6 * 180 * 1000,
            staleTime: 6 * 180 * 1000,
        }
    );

    useEffect(() => {
        const sumSalesGross = data?.sumSalesGross;

        const storeId = data?.storeId;

        setGrafico({
            exporting: {
                enabled: false,
            },
            chart: {
                type: 'column',
                backgroundColor: 'transparent',
                height: 369,
            },
            title: {
                text: '',
            },
            xAxis: {
                categories: storeId,
                crosshair: true,
                title: {
                    text: 'Loja',
                }
            },
            yAxis: {
                title: {
                    text: 'Faturado',
                },
                labels: {
                    formatter: function () {
                        return this.value.toLocaleString('pt-BR', {
                            style: 'currency',
                            currency: 'BRL',
                        });
                    },
                },
            },
            series: [
                {
                    name: 'Faturado',
                    data: sumSalesGross,
                    color: '#0D66D0',
                    tooltip: {
                        valuePrefix: 'R$ ',
                    },
                },
            ],
        });
    }, [data]);

    return {
        isLoading,
        isError,
        data,
        grafico,
        error,
    };
};
