import React from 'react';

const useDebounce = (delay = 500) => {
    const timer = React.useRef<NodeJS.Timeout>();

    const debounce = React.useCallback(
        (callback: () => void) => {
            clearTimeout(timer.current);

            const newTimer = setTimeout(() => {
                callback();
            }, delay);

            timer.current = newTimer;
        },
        [timer.current, delay],
    );

    return debounce;
};

export default useDebounce;
