import React, { forwardRef } from 'react';

export type CardHeaderProps = React.HtmlHTMLAttributes<HTMLDivElement>;

export const CardHeader = forwardRef(
    (
        { children, ...props }: CardHeaderProps,
        ref: React.LegacyRef<HTMLDivElement>,
    ) => (
        <div
            className="info-card__header flex-align-center"
            ref={ref}
            {...props}
        >
            {children}
        </div>
    ),
);
