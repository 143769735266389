import { AxiosResponse } from 'axios';
import qs from 'qs';
import { trackPromise } from 'react-promise-tracker';
import { z } from 'zod';
import { UnknownObject } from '../../../../../@types';
import { ModalAlterarPrecoAtacadoPropsSchemaProps } from '../../../../../components/ModalAlterarPrecoAtacado/ModalAlterarPrecoAtacado';
import { api } from '../../../../../lib';
import { downloadTable } from '../../../../../utils/downloadTable';
import { getValidData } from '../../../RevisaoPrecos/utils';

export type DatapointPrecoAtacado = {
    productsToBePricedId: string;
    productId: string;
    productFamilyId: string;
    description: string;
    storeName: string;
    storeId: string;
    triggerCount: number;
    discountPercentage: number;
    lastEditedBy: string;
    lastEditedDate: string;
} & UnknownObject;

export type GET_ITEMS_PRECO_ATACADO_PARAMS = Partial<{
    page: number;
    size: number;
    sort: string;
    filters: Partial<Record<string, string[]>>;
}>;

export type GET_ITEMS_PRECO_ATACADO_RESPONSE = {
    content: DatapointPrecoAtacado[];
    totalElements: number;
} & Record<string, unknown>;

export const GET_ITEMS_PRECO_ATACADO = async (
    params: GET_ITEMS_PRECO_ATACADO_PARAMS = {},
) => {
    const { page, size, sort, filters } = params;

    const { data } = await trackPromise(
        api.get<GET_ITEMS_PRECO_ATACADO_RESPONSE>('/wholesale-price/products', {
            expectToken: true,
            params: {
                page,
                size,
                sort,
                ...getValidData(filters),
            },
            paramsSerializer: (params) => {
                return qs.stringify(params, { arrayFormat: 'repeat' });
            },
        }),
    );

    return data;
};

export const WholeSaleSaveOnBlurSchema = z.object({
    productsToBePricedId: z.string(),
    triggerCount: z.number().positive(),
    discountPercentage: z.number().positive().nullable(),
    storeId: z.string(),
    productId: z.string(),
});

export namespace SaveItemPrecoAtacado {
    export type Data = z.infer<typeof WholeSaleSaveOnBlurSchema>;
    export type Return = Partial<DatapointPrecoAtacado>;
}

export const putSaveWholesaleOnBlur = async (
    data: SaveItemPrecoAtacado.Data,
): Promise<AxiosResponse<SaveItemPrecoAtacado.Return>> => {
    const res = await api.put<DatapointPrecoAtacado>(
        '/wholesale-price/edit',
        data,
        {
            expectToken: true,
        },
    );

    return res;
};

export type POST_CHANGE_MASSIVE_PRICE_DATA = {
    filters: Partial<Record<string, string[]>>;
    products: {
        selectedIds: string[];
        excludedIds: string[];
    };
    selectedAll: boolean;
} & ModalAlterarPrecoAtacadoPropsSchemaProps;

export const POST_CHANGE_MASSIVE_PRICE = async (
    data: POST_CHANGE_MASSIVE_PRICE_DATA,
) => {
    const res = await trackPromise(
        api.post<void>('/wholesale-price/edit/bulk', data, {
            expectToken: true,
        }),
    );

    return res;
};

export const GET_DOWNLOAD_ITEMS_PRECO_ATACADO = async (
    params: GET_ITEMS_PRECO_ATACADO_PARAMS = {},
) => {
    const ERROR_MESSAGE = 'Erro ao baixar arquivo';
    try {
        const { data } = await trackPromise(
            api.get<BlobPart>('/wholesale-price/products/download', {
                expectToken: true,
                params,
                paramsSerializer(params) {
                    return qs.stringify(params, { arrayFormat: 'repeat' });
                },
                responseType: 'arraybuffer',
            }),
        );

        if (!data) throw new Error(ERROR_MESSAGE);

        return downloadTable(data);
    } catch {
        throw new Error(ERROR_MESSAGE);
    }
};
