import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../@types/RootState';

export type GerenciadorPaginationState = {
    activePage: number;
    displayLength: number;
};

const initialState: GerenciadorPaginationState = {
    activePage: 0,
    displayLength: 25,
};

const slice = createSlice({
    name: 'gerenciadorPrecos/pagination',
    initialState,
    reducers: {
        SET_ACTIVE_PAGE(state, action: PayloadAction<number>) {
            state.activePage = action.payload;
        },
        SET_DISPLAY_LENGTH(state, action: PayloadAction<number>) {
            state.displayLength = action.payload;
        },
    },
});

const selectorPagination = (state: RootState) => {
    return state.gerenciadorPrecosReducer.pagination;
};

const { SET_ACTIVE_PAGE, SET_DISPLAY_LENGTH } = slice.actions;

export { selectorPagination, SET_ACTIVE_PAGE, SET_DISPLAY_LENGTH };

export default slice.reducer;
