import React from 'react';
import { withRouter } from 'react-router-dom';
import {
    Container, Content, FlexboxGrid, Col,
} from 'rsuite';

import { HomeCarouselComponent } from '../../../../../components/HomeCarouselComponent';

const CadastroComSucesso = () => (
    <div className="freemium-success-setting-page">
        <Container>
            <FlexboxGrid>
                <Col md={10} lg={10} xsHidden smHidden>
                    <Content>
                        <HomeCarouselComponent />
                    </Content>
                </Col>
                <Col md={14} lg={14} xs={24} className="form-area">
                    <Content className="text-area">
                        <h2>Verifique seu e-mail</h2>
                        <p>Enviamos para o seu e-mail instruções de como confirmar seu cadastro, definir sua senha e realizar o primeiro login.</p>
                    </Content>
                </Col>
            </FlexboxGrid>
        </Container>
    </div>
);
export default withRouter(CadastroComSucesso);
