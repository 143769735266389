import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    Button,
    CheckPicker,
    Checkbox,
} from 'rsuite';
import moment from 'moment';

import { selectorExtracaoDados } from '../../../../../../reducers/extracaoDados';

import {
    setEcomValues,
} from '../../../../../../reducers/extracaoDados/ecom';
import { useTableColumn } from '../../hooks/useTableColumn';
import styles from './CheckPickerColunas.module.scss';
import { useTableList } from '../../hooks/useTableList';

const disabledItems = ['DATA_COLETA', 'FONTES', 'PRODUTO'];
const locale = { searchPlaceholder: 'Pesquisar', noResultsText: 'Nenhum item encontrado' };

export const CheckPickerColunas = () => {
    const [checkAllColumn, setCheckAllColumn] = useState(false);
    const [indeterminate, setIndeterminate] = useState(false);

    const columnPickerRef = useRef(null);

    const { columnData, columnDataSelected } = useTableColumn();

    const dispatch = useDispatch();

    const { ecom } = useSelector(selectorExtracaoDados);
    const { getEcomTableList } = useTableList();

    const handleChangeColuna = (value) => {
        const colunasSelectedObj = { name: 'colunasSelectedList', value };
        dispatch(setEcomValues(colunasSelectedObj));
        setIndeterminate(value.length > 0 && value.length < columnData.colunas.length);
        setCheckAllColumn(value.length === columnData.colunas.length);
    };

    const handleCheckAll = (value, checked) => {
        const nextValue = checked
            ? columnDataSelected
            : columnDataSelected.filter((item) => item === 'DATA_COLETA' || item === 'LOJA' || item === 'PRODUTO');

        const colunasSelectedObj = { name: 'colunasSelectedList', value: nextValue };
        dispatch(setEcomValues(colunasSelectedObj));
        setCheckAllColumn(checked);
    };

    const setCookie = (colunas) => {
        const colunasJoin = colunas.join(',');
        const expires = moment().add(365, 'days').toDate();
        document.cookie = `colunasEcom=${colunasJoin};expires=${expires.toUTCString()};path=/`;
    };

    const confirmColuns = () => {
        const newColumns = columnData.colunas?.filter((item) => ecom.colunasSelectedList.includes(item.id));
        const colunasObj = { name: 'colunasHeaderList', value: newColumns };
        dispatch(setEcomValues(colunasObj));

        setCookie(ecom.colunasSelectedList);
    };

    const handleClickConfirm = () => {
        columnPickerRef.current.close();
        confirmColuns();
        getEcomTableList();
    };

    useEffect(() => {
        if (!ecom.browserCookie?.length) {
            setCheckAllColumn(true);
        }
    }, [ecom.browserCookie]);

    return (

        <CheckPicker
            cleanable={false}
            data={columnData.colunas}
            placeholder="Colunas"
            style={{ width: 155 }}
            ref={columnPickerRef}
            filter
            className={styles['checkpicker-colunas']}
            appearance="subtle"
            placement="bottomEnd"
            value={ecom.colunasSelectedList}
            onChange={handleChangeColuna}
            disabledItemValues={disabledItems}
            locale={locale}
            renderExtraFooter={() => (
                <div className={styles['select-footer']}>
                    <Checkbox
                        inline
                        checked={checkAllColumn}
                        onChange={handleCheckAll}
                        indeterminate={indeterminate}
                    >
                        Selecionar tudo
                    </Checkbox>
                    <Button
                        className={styles['btn-footer']}
                        appearance="primary"
                        size="sm"
                        onClick={handleClickConfirm}
                    >
                        Confirmar
                    </Button>
                </div>
            )}
            renderValue={(value, items, selectedElement) => (
                <span>
                    <span className={styles['render-value-text']}>
                        Colunas:
                    </span>
                    {' '}
                    <span className={styles['render-value-number']}>
                        {selectedElement.props.selectedItems.length}
                    </span>
                </span>
            )}
        />
    );
};
