import React from 'react';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import styles from './LastWeekPriceStatus.module.scss';

type LastWeekPriceStatusProps = {
    lastWeekPrice: number,
    beforeLastWeekPrice: number,
}
export const LastWeekPriceStatus = ({ lastWeekPrice, beforeLastWeekPrice }: LastWeekPriceStatusProps) => {
    if (lastWeekPrice > beforeLastWeekPrice) {
        return (
            <ArrowUpwardIcon
                fontSize="small"
                className={styles['arrow-up']}
            />
        );
    }

    if (lastWeekPrice < beforeLastWeekPrice) {
        return (
            <ArrowDownwardIcon
                fontSize="small"
                className={styles['arrow-down']}
            />
        );
    }

    return null;
};
