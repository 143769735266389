import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    selectorCalculadoraPrevisaoDemanda,
} from '../../../../reducers/calculadoraPrevisaoDemanda/calculadoraPrevisaoDemandaReducer';
import {
    setInputCalculosValue,
} from '../../../../reducers/calculadoraPrevisaoDemanda/inputCalculos';

import { FormBox } from '../FormBox';
import { InputAddon } from '../index';

export const PreferenceForm = () => {
    const { inputCalculos } = useSelector(
        selectorCalculadoraPrevisaoDemanda,
    );

    const { preferences } = inputCalculos.inputs;

    const dispatch = useDispatch();

    const handleChangeCalculusPeriod = (value: string) => {
        const obj = { name: 'preferences', value: { ...preferences, period_in_days: value } };
        dispatch(setInputCalculosValue(obj));
    };

    return (
        <FormBox.Root title="Preferências">
            <FormBox.Row>
                <FormBox.Label width="160px">
                    Período para o cálculo
                </FormBox.Label>
                <InputAddon
                    position="right"
                    addon="dias"
                    width="96px"
                    value={preferences.period_in_days}
                    onChange={handleChangeCalculusPeriod}
                />
            </FormBox.Row>
            {/* <FormBox.Row>
                <FormBox.Label width="160px">
                    Usar sazonalidade
                </FormBox.Label>
                <Toggle
                    className={styles['seasonality-toggle']}
                    size="sm"
                    checked={preferences.enable_seasonality}
                    onChange={onChangeToggle}
                />
            </FormBox.Row> */}
        </FormBox.Root>
  );
};
