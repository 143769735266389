import React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import SelectPill from '../../../../../../../components/SelectPill';

import { setEnderecoRaio } from '../../../../../../../actions/actionsModuloRelatorio';
import { raioList } from '../../../utils';
import iconSixDots from '../../../../../../../assets/icons/icon_six-dots.svg';
import iconArrowRight from '../../../../../../../assets/icons/icon_arrow-right-gray.svg';
import styles from './DndSelectRaio.module.scss';

export const DndSelectRaio = () => {
    const dispatch = useDispatch();
    const { moduloRelatorioData } = useSelector(
        (state) => ({
            moduloRelatorioData:
                state.moduloRelatorioDataReducer?.moduloRelatorioData,
        }),
        shallowEqual,
    );

    const handleOnSelectRaio = (value: number) => {
        dispatch(setEnderecoRaio(value));
    };

    const handleOnCleanRaio = () => {
        dispatch(setEnderecoRaio(null));
    };

    const renderSelectValue = () => <span>Raio</span>;

    return (
        <div className={styles['dnd-raio-wrapper']}>
            {moduloRelatorioData?.enderecoDTO?.endereco
                ? (
                    <>
                        <img
                            src={iconArrowRight}
                            alt=""
                            className={styles['arrow-right-icon']}
                        />

                        <div className={styles['dnd-raio']}>
                            <img src={iconSixDots} alt="" />
                            <SelectPill
                                type="select"
                                id="select-picker-pill"
                                name="modulo-relatorio-select"
                                filter={false}
                                placeholder="Raio"
                                value={moduloRelatorioData?.enderecoDTO?.raio}
                                data={raioList}
                                onSelect={handleOnSelectRaio}
                                onClean={handleOnCleanRaio}
                                renderValue={renderSelectValue}
                            />
                        </div>
                    </>
                ) : null}
        </div>
    );
};
