import React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Alert, Button } from 'rsuite';
import { setSingleReducer } from '../../../../../../../actions/actionsAnaliseCompetitividade';
import { LoadingMenu } from '../../../../../../../components/LoadingSpinner';
import SelectPill from '../../../../../../../components/SelectPill';
import { useChangePlaceholder } from '../../../hooks/useChangePlaceholder';
import { stringLimited } from '../../../utils';

import arrowRightIcon from '../../../../../../../assets/icons/icon_arrow-right-gray.svg';
import styles from './styles.module.scss';

export const CompareSelect = ({
    placeholder,
    productsCompetitor,
    productsReference,
    onSelectCompetitor,
    onSelectReference,
    onSearchCompetitor,
    onSearchReference,
    onCleanCompetitor,
    onCleanReference,
    onOpenCompetitor,
    onOpenReference,
    competitionType,
    onClickLoadMore,
}) => {
    const { filterIsEmpty } = useSelector(
        (state) => ({
            filterIsEmpty:
                state.analiseCompetitividadeDataReducer.filterIsEmpty,
        }),
        shallowEqual,
    );

    const dispatch = useDispatch();

    const {
        thirdSelectPlaceholder,
        handlePlaceholderChange,
        handleThirdPlaceholderChange,
        handleCleanCheckPlaceholder,
    } = useChangePlaceholder(placeholder, false);

    const handleCompareOnSelect = (value, item) => {
        if (value.length > 10) {
            Alert.warning('Já existem 10 competidores selecionados.', 5000);
            return;
        }
        handleThirdPlaceholderChange(value, item);

        onSelectCompetitor(value, item);
    };

    const handleReferenceOnSelect = (value, item) => {
        const placeholder = stringLimited(item.descricao);
        handlePlaceholderChange(placeholder);
        onSelectReference(value, item);
    };

    const handleCompetitorOnClean = () => {
        handleCleanCheckPlaceholder('third');
        onCleanCompetitor();
    };

    const handleOpenCompetitorSelect = () => {
        dispatch(
            setSingleReducer('filterIsEmpty', {
                ...filterIsEmpty,
                produtosCompetidores: false,
            }),
        );
        onOpenCompetitor();
    };

    const handleOpenReferenceSelect = () => {
        dispatch(
            setSingleReducer('filterIsEmpty', {
                ...filterIsEmpty,
                produtoReferencia: false,
            }),
        );
        onOpenReference();
    };

    return (
        <div className={styles['select-pill-wrapper']}>
            <div className={styles['div-pill']}>{placeholder}</div>
            <img
                src={arrowRightIcon}
                alt=""
                className={styles['arrow-right-icon']}
            />
            <SelectPill
                type="select"
                id={
                    filterIsEmpty?.produtoReferencia
                        ? styles['is-empty']
                        : 'select-picker-pill'
                }
                filter={false}
                placeholder="Produto referência"
                name="second"
                data={productsReference?.list}
                value={productsReference?.value[0]}
                onSearch={onSearchReference}
                onSelect={handleReferenceOnSelect}
                onOpen={handleOpenReferenceSelect}
                onClean={onCleanReference}
                locale={{
                    noResultsText: 'Nenhum item encontrado',
                    searchPlaceholder: 'Buscar item',
                }}
                renderMenu={(menu) => (
                    <span>
                        <LoadingMenu area="panel-filter" />
                        {menu}
                    </span>
                )}
                renderValue={(value, item) => (
                    <span>{stringLimited(item.descricao)}</span>
                )}
                renderExtraFooter={() => (
                    <div
                        className={
                            styles['product-filter-menu__footer-sigle-item']
                        }
                    >
                        <Button
                            appearance="ghost"
                            onClick={() => onClickLoadMore(competitionType)}
                            disabled={productsReference?.list?.length >= 1000}
                        >
                            Carregar mais
                        </Button>
                    </div>
                )}
            />
            <div className={styles.versus}>Vs</div>
            <SelectPill
                type="check"
                id={
                    filterIsEmpty?.produtosCompetidores
                        ? styles['is-empty']
                        : 'check-picker-pill'
                }
                placeholder={thirdSelectPlaceholder?.placeholder}
                name="second"
                value={productsCompetitor?.value}
                data={productsCompetitor?.list}
                onSearch={onSearchCompetitor}
                onSelect={handleCompareOnSelect}
                onOpen={handleOpenCompetitorSelect}
                onClean={handleCompetitorOnClean}
                locale={{
                    noResultsText: 'Nenhum item encontrado',
                    searchPlaceholder: 'Buscar item',
                }}
                renderMenu={(menu) => (
                    <span>
                        <LoadingMenu area="panel-filter" />
                        {menu}
                    </span>
                )}
                renderExtraFooter={() => (
                    <div
                        className={
                            styles['product-filter-menu__footer-sigle-item']
                        }
                    >
                        <Button
                            appearance="ghost"
                            onClick={() => onClickLoadMore(competitionType)}
                            disabled={productsCompetitor?.list?.length >= 1000}
                        >
                            Carregar mais
                        </Button>
                    </div>
                )}
            />
        </div>
    );
};
