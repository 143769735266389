/* eslint-disable max-len */
import React, { useEffect, useState /* createRef */ } from 'react';
import { withRouter, useParams } from 'react-router-dom';
import {
    Container,
    Content,
    FlexboxGrid,
    Button,
    Icon,
    Table,
    Whisper,
    Tooltip,
    // Col,
    IconButton,
    Notification,
} from 'rsuite';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { LoadingSpiner } from '../../../../../components/LoadingSpinner';
import Steps from '../Components/Steps';
import { getCampaign } from '../services';
import { listBlocos } from '../BlocoDeOferta/services';
import {
    getLojas,
    getLojasParticipantes,
    getTiposLojas,
    saveLojasParticipantes,
    deleteLojaParticipante,
} from './services';
import { colunasTableLojasParticipantes } from './Components/Columns';
import { getClusters } from '../../services';
import ModalConfirmacao from '../../../../../components/ModalConfirmacao';
// import BotaoFiltro from './Components/Filtro';
import ModalLojasParticipantes from './Components/ModalLojasParticipantes';
import Header from '../Components/Header';
import { getOfertas } from '../Ofertas/services';

const {
 Column, HeaderCell, Cell, Pagination,
} = Table;

const LojasParticipantes = ({ history }) => {
    const { id } = useParams();
    const [lojasParticipantesSelecionadas, setLojasParticipantesSelecionadas]
        = useState([]);
    const [lojasParticipantes, setLojasParticipantes] = useState({
        content: [],
    });
    const [page, setPage] = useState(0);
    const [size, setSize] = useState(20);
    const [showModalLojasParicipantes, setModalLojasParticipantes]
        = useState(false);
    const [campanha, setCampanha] = useState({});
    const [blocosOfertas, setBlocosOfertas] = useState({});
    const [deleteMessage, setDeleteMessage] = useState('');
    const [lojaToDelete, setLojaToDelete] = useState(null);
    const [showModalConfirmacaoExclusao, setShowModalConfirmacaoExclusao]
        = useState(false);
    const [tipoLojaSelecionado, setTipoLojaSelecionado] = useState({});
    const [clusterSelecionado, setClusterSelecionado] = useState('');
    const [tiposLoja, setTiposLoja] = useState({ content: [] });
    const [lojas, setLojas] = useState({ content: [] });
    const [clusters, setClusters] = useState([]);
    const [ofertas, setOfertas] = useState({ content: [] });
    // const tiposLojaListagemRef = createRef();
    // const clusterListagemRef = createRef();

    const back = () => {
        history.push(`/ipa/campanhas/cadastro/dados-campanha/${id}`);
    };

    const goToBlocos = () => {
        history.push(`/ipa/campanhas/cadastro/blocos-de-oferta/${id}`);
    };

    const goToOfertas = () => {
        history.push(`/ipa/campanhas/cadastro/ofertas/${id}`);
    };

    const listLojas = async (
        page,
        size,
        tipoLojaSelecionado,
        clusterSelecionado,
        buscaLoja,
    ) => {
        const resp = await getLojas({
            page,
            size,
            storeType: tipoLojaSelecionado?.value,
            storeCluster: clusterSelecionado,
            storeName: buscaLoja,
            campaignId: id,
        });
        setLojas(resp);
    };

    const listLojasSelecionadas = () => {
        if (lojas.content.length && lojasParticipantes.content.length) {
            let lojasSelecionadas = [
                ...lojasParticipantesSelecionadas,
                ...lojas.content.filter((item) => item.checked),
            ];
            lojasSelecionadas = [
                ...new Map(
                    lojasSelecionadas.map((item) => [item.store_id, item]),
                ).values(),
            ];
            setLojasParticipantesSelecionadas(lojasSelecionadas);
        }
    };

    const listLojasParticipantes = async () => {
        const response = await getLojasParticipantes({
            id,
            page,
            size,
            storeType: tipoLojaSelecionado.value,
            storeCluster: clusterSelecionado,
        });
        if (response) setLojasParticipantes(response);
    };

    const listCampaign = async () => {
        const response = await getCampaign(id);
        if (response) {
            setCampanha(response.data);
        }
    };

    const listBlocosOfertas = async () => {
        const response = await listBlocos(id);
        if (response) setBlocosOfertas(response);
    };

    const confirmLoja = async () => {
        const data = {
            client_id: JSON.parse(localStorage.getItem('cliente'))?.codigo,
            stores: lojasParticipantesSelecionadas.map((item) => ({
                store_name: item.store,
                store_id_external: item.store_id,
                store_type: item.store_type,
                store_cluster: item.store_cluster,
            })),
        };
        const resp = await saveLojasParticipantes({
            data,
            id,
        });
        if (resp) {
            listLojasParticipantes();
            setLojasParticipantesSelecionadas([]);
            setModalLojasParticipantes(false);
            listLojasSelecionadas();
        }
    };

    const closeLoja = () => {
        setLojasParticipantesSelecionadas([]);
        setModalLojasParticipantes(false);
        listLojasSelecionadas();
        listLojas();
    };

    const removeLoja = (id) => {
        setDeleteMessage(
            'Você está tentando remover uma loja participante. Você confirma essa ação?',
        );
        setShowModalConfirmacaoExclusao(true);
        setLojaToDelete(id);
    };

    const closeExclusao = () => {
        setDeleteMessage('');
        setShowModalConfirmacaoExclusao(false);
        setLojaToDelete(null);
    };

    const confirmExclusao = () => {
        deleteLojaParticipante(id, lojaToDelete).then(() => {
            Notification.success({
                title: 'Notificação',
                duration: 6000,
                description: (
                    <div>
                        <p>Loja Participante excluída com sucesso.</p>
                    </div>
                ),
            });
            listLojasParticipantes();
            closeExclusao();
        });
    };

    // const handleSelectMenu = (value, type) => {
    //     switch (type) {
    //         case 'tipoLoja':
    //             setTipoLojaSelecionado(value);
    //             tiposLojaListagemRef.current.hide();
    //             break;
    //         case 'cluster':
    //             setClusterSelecionado(value);
    //             clusterListagemRef.current.hide();
    //             break;
    //         default:
    //             break;
    //     }
    // };

    const listTiposLojas = async () => {
        const response = await getTiposLojas(id);
        setTiposLoja(
            response.map((item) => ({
                value: item,
                label: item,
            })),
        );
    };

    const getClustersCliente = async () => {
        const resp = await getClusters();
        setClusters(
            resp.map((item) => ({
                value: item.store_cluster,
                label: item.store_cluster,
            })),
        );
    };

    const listOfertas = async () => {
        const response = await getOfertas({
            id,
            page: 0,
            size: 20,
        });
        if (response) {
            setOfertas(response);
        }
    };

    useEffect(() => {
        listCampaign();
        listBlocosOfertas();
        listTiposLojas();
        getClustersCliente();
        listOfertas();
    }, []);

    useEffect(() => {
        listLojasParticipantes();
    }, [page, size, tipoLojaSelecionado, clusterSelecionado]);

    useEffect(() => {
        listLojasSelecionadas();
    }, [lojas, lojasParticipantes]);

    return (
        <Container className="main-container cadastro-lojas-participantes-page">
            <Steps
                rota={history.location.pathname}
                campanhaId={id}
                status={campanha?.status}
                lojas={lojasParticipantes?.content?.length}
            />
            <LoadingSpiner size="md" />
            <Header
                history={history}
                id={id}
                name={campanha?.name}
                status={campanha?.status}
                backToCampaign={back}
                startingDate={campanha?.starting_date}
                expirationDate={campanha?.expiration_date}
                blocosOfertas={blocosOfertas?.totalElements}
                lojasParticipantes={lojasParticipantes?.totalElements}
                ofertas={ofertas?.totalElements}
            />
            <Content className="lojas-participantes">
                <FlexboxGrid justify="space-between" align="middle">
                    <FlexboxGrid.Item>
                        <h3>
                            Lojas participantes
                            <Whisper
                                speaker={(
                                    <Tooltip>
                                        Escolha as lojas participantes da
                                        campanha.
                                    </Tooltip>
                                  )}
                            >
                                <span>
                                    <Icon icon="info" />
                                </span>
                            </Whisper>
                        </h3>
                    </FlexboxGrid.Item>
                    {!lojasParticipantes?.content?.length ? null : (
                        <FlexboxGrid.Item>
                            <Button
                                title="Adicionar lojas"
                                block
                                appearance="primary"
                                onClick={() => setModalLojasParticipantes(true)}
                                disabled={
                                    campanha.status === 'CURRENT'
                                    || campanha.status === 'CLOSED'
                                }
                            >
                                ADICIONAR LOJAS
                            </Button>
                        </FlexboxGrid.Item>
                    )}

                    <FlexboxGrid.Item
                        className="lojas-participantes-container"
                        colspan={24}
                    >
                        {!lojasParticipantes?.content?.length ? (
                            <FlexboxGrid
                                className="no-data"
                                justify="center"
                                align="middle"
                            >
                                <FlexboxGrid.Item>
                                    <p>
                                        Não há lojas participantes adicionadas
                                    </p>
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item>
                                    <Button
                                        title="Criar bloco de oferta"
                                        block
                                        appearance="primary"
                                        onClick={() => setModalLojasParticipantes(true)}
                                        disabled={
                                            campanha.status === 'CURRENT'
                                            || campanha.status === 'CLOSED'
                                        }
                                    >
                                        ADICIONAR LOJAS
                                    </Button>
                                </FlexboxGrid.Item>
                            </FlexboxGrid>
                        ) : (
                            <Container>
                                <FlexboxGrid justify="space-between">
                                    <FlexboxGrid.Item colspan={24}>
                                        {/* <div className="campanhas-filtros">
                                            <FlexboxGrid justify="space-between">
                                                <FlexboxGrid.Item
                                                    componentClass={Col}
                                                    lg={16}
                                                >
                                                    <FlexboxGrid className="filtros-container">
                                                        <FlexboxGrid.Item
                                                            componentClass={Col}
                                                        >
                                                            <BotaoFiltro
                                                                filtroRef={
                                                                    tiposLojaListagemRef
                                                                }
                                                                label="Tipos de loja"
                                                                data={tiposLoja}
                                                                type="tipoLoja"
                                                                itemsSelected={
                                                                    tipoLojaSelecionado.value
                                                                }
                                                                onSelect={
                                                                    handleSelectMenu
                                                                }
                                                                showValue
                                                                onClear={() => setTipoLojaSelecionado(
                                                                        {},
                                                                    )}
                                                            />
                                                        </FlexboxGrid.Item>
                                                        <FlexboxGrid.Item
                                                            componentClass={Col}
                                                        >
                                                            <BotaoFiltro
                                                                filtroRef={
                                                                    clusterListagemRef
                                                                }
                                                                label="Cluster"
                                                                data={clusters}
                                                                type="cluster"
                                                                itemsSelected={
                                                                    clusterSelecionado
                                                                }
                                                                onSelect={
                                                                    handleSelectMenu
                                                                }
                                                                onClear={() => setClusterSelecionado(
                                                                        '',
                                                                    )}
                                                            />
                                                        </FlexboxGrid.Item>
                                                    </FlexboxGrid>
                                                </FlexboxGrid.Item>
                                            </FlexboxGrid>
                                        </div> */}
                                    </FlexboxGrid.Item>
                                    <FlexboxGrid.Item colspan={24}>
                                        <div className="campanhas-table-container">
                                            <Table
                                                className="table"
                                                data={
                                                    lojasParticipantes.content
                                                }
                                                autoHeight
                                            >
                                                {colunasTableLojasParticipantes.map(
                                                    (coluna) => (!coluna.dataKey ? (
                                                        <Column
                                                            width={120}
                                                            fixed="right"
                                                            key={Math.random()}
                                                        >
                                                            <HeaderCell />

                                                            <Cell className="table__edit-button">
                                                                {(
                                                                        rowData,
                                                                    ) => (
                                                                        <span className="mg-5">
                                                                            <IconButton
                                                                                title="Deletar"
                                                                                size="md"
                                                                                icon={
                                                                                    <Icon icon="trash2" />
                                                                                }
                                                                                onClick={() => removeLoja(
                                                                                        rowData.id,
                                                                                    )}
                                                                                disabled={
                                                                                    campanha.status
                                                                                        === 'CURRENT'
                                                                                    || campanha.status
                                                                                        === 'CLOSED'
                                                                                }
                                                                            />
                                                                        </span>
                                                                    )}
                                                            </Cell>
                                                        </Column>
                                                        ) : (
                                                            <Column
                                                                flexGrow={
                                                                    coluna.flexGrow
                                                                }
                                                                sortable={
                                                                    coluna.sortable
                                                                }
                                                                align={
                                                                    coluna.align
                                                                }
                                                                fixed={
                                                                    coluna.fixed
                                                                }
                                                                width={
                                                                    coluna.width
                                                                }
                                                                key={Math.random()}
                                                            >
                                                                <HeaderCell>
                                                                    {
                                                                        coluna.headerCell
                                                                    }
                                                                </HeaderCell>
                                                                <Cell
                                                                    dataKey={
                                                                        coluna.dataKey
                                                                    }
                                                                />
                                                            </Column>
                                                        )),
                                                )}
                                            </Table>
                                            <Pagination
                                                lengthMenu={[
                                                    {
                                                        value: 20,
                                                        label: 20,
                                                    },
                                                    {
                                                        value: 40,
                                                        label: 40,
                                                    },
                                                ]}
                                                activePage={
                                                    lojasParticipantes.number
                                                    + 1
                                                }
                                                displayLength={
                                                    lojasParticipantes.size
                                                }
                                                total={
                                                    lojasParticipantes.totalElements
                                                }
                                                onChangePage={(value) => setPage(value - 1)}
                                                onChangeLength={(value) => setSize(value)}
                                            />
                                        </div>
                                    </FlexboxGrid.Item>
                                </FlexboxGrid>
                            </Container>
                        )}
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </Content>
            <Content className="navegacao-lojas-participantes">
                <FlexboxGrid justify="space-between">
                    <FlexboxGrid.Item>
                        <Button appearance="link" onClick={goToBlocos}>
                            <ArrowBackIcon />
                            {' '}
                            Voltar para blocos de ofertas
                        </Button>
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item>
                        <Button
                            appearance="primary"
                            disabled={!lojasParticipantes.content.length}
                            onClick={goToOfertas}
                        >
                            <ArrowForwardIcon />
                            {' '}
                            {campanha.status === 'CURRENT'
                            || campanha.status === 'CLOSED'
                                ? 'ITENS OFERTADOS'
                                : 'OFERTAR ITENS'}
                        </Button>
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </Content>
            <ModalLojasParticipantes
                show={showModalLojasParicipantes}
                confirmLoja={confirmLoja}
                closeLoja={closeLoja}
                id={id}
                lojasParticipantesSelecionadas={lojasParticipantesSelecionadas}
                setLojasParticipantesSelecionadas={
                    setLojasParticipantesSelecionadas
                }
                tiposLoja={tiposLoja}
                clusters={clusters}
                listLojas={listLojas}
                lojas={lojas}
            />
            <ModalConfirmacao
                show={showModalConfirmacaoExclusao}
                onConfirm={confirmExclusao}
                onCancel={closeExclusao}
                title="Remover Loja Participante"
                message={deleteMessage}
                confirmButton="Confirmar"
                cancelButton="Cancelar"
            />
        </Container>
    );
};

export default withRouter(LojasParticipantes);
