import { trackPromise } from 'react-promise-tracker';

import api from '../../../../utils/API';

export const generateReportService = (data) => trackPromise(
    api({
        url: `${process.env.REACT_APP_MODULO_RELATORIO_URL}/generate`,
        method: 'POST',
        data,
        expectToken: true,
    }).then((res) => res),
);

export const generateSavedReport = (id) => trackPromise(
    api({
        url: `${process.env.REACT_APP_MODULO_RELATORIO_URL}/generate/${id}`,
        method: 'POST',
        expectToken: true,
    }).then((res) => res),
    'generate-report-now',
);

export const getTipoPromocaoService = () => trackPromise(
    api({
        url: `${process.env.REACT_APP_MODULO_RELATORIO_URL}/tipo-promocao`,
        method: 'GET',
        expectToken: true,
    }).then((res) => res),
    'panel-filter',
);

export const getSavedReportListService = (query) => trackPromise(
    api({
        url: `${process.env.REACT_APP_MODULO_RELATORIO_URL}/arquivos/user/saved`,
        method: 'GET',
        expectToken: true,
        params: {
            page: query.page,
            size: query.size,
            query: query.query,
        },
    }).then((res) => res),
    'panel-filter',
);

export const getGenerateReportHistoryListService = (params) => trackPromise(
    api({
        url: `${process.env.REACT_APP_MODULO_RELATORIO_URL}/arquivos/cliente/${params.clientCode}`,
        method: 'GET',
        params: {
            size: params.size,
            page: params.page,
            usuario: params.usuarioEmail,
        },
        expectToken: true,
    }).then((res) => res),
);

export const deleteSavedReportItemService = (id) => trackPromise(
    api({
        url: `${process.env.REACT_APP_MODULO_RELATORIO_URL}/deleted/${id}`,
        method: 'DELETE',
        expectToken: true,
    }).then((res) => res),
);

export const getSavedReportItemService = (id) => trackPromise(
    api({
        url: `${process.env.REACT_APP_MODULO_RELATORIO_URL}/arquivos/${id}`,
        method: 'GET',
        expectToken: true,
    }).then((res) => res),
);

export const saveEditedReportService = (id, data) => trackPromise(
    api({
        url: `${process.env.REACT_APP_MODULO_RELATORIO_URL}/update`,
        method: 'PUT',
        expectToken: true,
        data,
        params: {
            idReport: id,
        },
        validateStatus: null,
    }).then((res) => res),
);

export const getEndereco = (data) => api({
    url: `${process.env.REACT_APP_CLIENTE_URL}/location/autocomplete`,
    method: 'GET',
    expectToken: true,
    params: {
        query: data.query ? data.query : null,
        session_token: data.token ? data.token : null,
    },
}).then((resp) => resp.data);

export const getPlace = (data) => api({
    url: `${process.env.REACT_APP_CLIENTE_URL}/location/place`,
    method: 'GET',
    params: {
        place_id: data.place_id ? data.place_id : null,
        session_token: data.token ? data.token : null,
    },
}).then((resp) => resp.data);

export const getSaveReportLineCounter = (data) => trackPromise(
    api({
        url: `${process.env.REACT_APP_MODULO_RELATORIO_URL}/total-elements`,
        method: 'POST',
        data,
        expectToken: true,
        validateStatus: null,
    }).then((res) => res),
);
