import React from 'react';
import { Content, FlexboxGrid, Col, DateRangePicker, Button } from 'rsuite';
// @ts-ignore
import { SelectPill } from '../../Components/SelectPill.tsx';
// @ts-ignore
import { DateRangePickerPill } from '../../Components/DateRangePickerPill.tsx';
// @ts-ignore
import { useFiltros } from '../../hooks/useFiltros.tsx';
import { ButtonProps } from 'rsuite/lib/Button/Button.js';

type FiltrosProps = {
    filtros: any;
    match?: {
        isExact: boolean;
        params: {};
        path: string;
        url: string;
    };
    changeFiltros: (value: any, filtro: string) => void;
}

interface IButtonProps extends ButtonProps {
    onClick: any;
}

export const ButtonLimparFiltros: React.FC<IButtonProps> = (props) => {
    const { onClick } = props;
    return (
        <Button appearance="primary" className="filtro-botao" onClick={onClick}>
            Limpar Filtros
        </Button>
    );
};

export const Filtros: React.FC<FiltrosProps> = (props) => {
    const { afterToday } = DateRangePicker;
    const { filtros, changeFiltros, match } = props;
    const {
        lojas,
        loadingLojas,
        produtos,
        loadingProdutos,
        tipoLojas,
        loadingTipoLojas,
        loadingCategoria1,
        categoria1,
        loadingCategoria2,
        categoria2,
        competitividade,
        loadingCompetitividade,
        changeQuery,
    } = useFiltros();

    return (
        <Content className="filtros-header">
            <div className="campanhas-filtros">
                <FlexboxGrid justify="space-between">
                    <FlexboxGrid.Item componentClass={Col} colspan={16}>
                        <FlexboxGrid className="filtros-container">
                            <FlexboxGrid.Item componentClass={Col}>
                                <DateRangePickerPill
                                    placeholder="Período"
                                    onOk={(value: any) =>
                                        changeFiltros(
                                            value,
                                            'periodoSelecionado'
                                        )
                                    }
                                    onChange={(value: any) =>
                                        changeFiltros(
                                            value,
                                            'periodoSelecionado'
                                        )
                                    }
                                    onClean={() =>
                                        changeFiltros([], 'periodoSelecionado')
                                    }
                                    locale={{
                                        sunday: 'D',
                                        monday: 'S',
                                        tuesday: 'T',
                                        wednesday: 'Q',
                                        thursday: 'Q',
                                        friday: 'S',
                                        saturday: 'S',
                                        ok: 'Ok',
                                        today: 'Hoje',
                                        yesterday: 'Ontem',
                                        last7Days: 'Últimos 7 dias',
                                    }}
                                    renderValue={() => <span>Período</span>}
                                    disabledDate={afterToday()}
                                />
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item componentClass={Col}>
                                <SelectPill
                                    id="check-picker-pill"
                                    type="check"
                                    searchable={false}
                                    placeholder="Loja"
                                    name="lojas"
                                    data={lojas}
                                    value={filtros.lojasSelecionadas}
                                    onSelect={(value) =>
                                        changeFiltros(
                                            value,
                                            'lojasSelecionadas'
                                        )
                                    }
                                    onClean={() =>
                                        changeFiltros([], 'lojasSelecionadas')
                                    }
                                    locale={{
                                        noResultsText: loadingLojas
                                            ? 'Carregando lojas...'
                                            : 'Nenhum item encontrado',
                                        searchPlaceholder: 'Pesquisar',
                                    }}
                                />
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item componentClass={Col}>
                                <SelectPill
                                    id="check-picker-pill"
                                    type="check"
                                    searchable={false}
                                    placeholder="Tipos de loja"
                                    name="tipoLoja"
                                    data={tipoLojas}
                                    value={filtros.tipoLojasSelecionadas}
                                    onSelect={(value) =>
                                        changeFiltros(
                                            value,
                                            'tipoLojasSelecionadas'
                                        )
                                    }
                                    onClean={() =>
                                        changeFiltros(
                                            [],
                                            'tipoLojasSelecionadas'
                                        )
                                    }
                                    locale={{
                                        noResultsText: loadingTipoLojas
                                            ? 'Carregando lojas...'
                                            : 'Nenhum item encontrado',
                                        searchPlaceholder: 'Pesquisar',
                                    }}
                                />
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item componentClass={Col}>
                                <SelectPill
                                    id="check-picker-pill"
                                    type="check"
                                    placeholder="Produto"
                                    name="produto"
                                    value={filtros.produtosSelecionados}
                                    data={produtos}
                                    onSelect={(value) =>
                                        changeFiltros(
                                            value,
                                            'produtosSelecionados'
                                        )
                                    }
                                    onSearch={(value) => changeQuery(value)}
                                    onClean={() =>
                                        changeFiltros(
                                            [],
                                            'produtosSelecionados'
                                        )
                                    }
                                    locale={{
                                        noResultsText: loadingProdutos
                                            ? 'Carregando produtos...'
                                            : 'Nenhum item encontrado',
                                        searchPlaceholder: 'Pesquisar',
                                    }}
                                />
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item componentClass={Col}>
                                <SelectPill
                                    id="check-picker-pill"
                                    type="check"
                                    placeholder="Categoria Nível 1"
                                    name="produto"
                                    value={filtros.categoriaNivel1Selecionadas}
                                    data={categoria1}
                                    onSelect={(value) =>
                                        changeFiltros(
                                            value,
                                            'categoriaNivel1Selecionadas'
                                        )
                                    }
                                    onClean={() =>
                                        changeFiltros(
                                            [],
                                            'categoriaNivel1Selecionadas'
                                        )
                                    }
                                    locale={{
                                        noResultsText: loadingCategoria1
                                            ? 'Carregando produtos...'
                                            : 'Nenhum item encontrado',
                                        searchPlaceholder: 'Pesquisar',
                                    }}
                                />
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item componentClass={Col}>
                                <SelectPill
                                    id="check-picker-pill"
                                    type="check"
                                    placeholder="Categoria Nível 2"
                                    name="produto"
                                    value={filtros.categoriaNivel2Selecionadas}
                                    data={categoria2}
                                    onSelect={(value) =>
                                        changeFiltros(
                                            value,
                                            'categoriaNivel2Selecionadas'
                                        )
                                    }
                                    onClean={() =>
                                        changeFiltros(
                                            [],
                                            'categoriaNivel2Selecionadas'
                                        )
                                    }
                                    locale={{
                                        noResultsText: loadingCategoria2
                                            ? 'Carregando produtos...'
                                            : 'Nenhum item encontrado',
                                        searchPlaceholder: 'Pesquisar',
                                    }}
                                />
                            </FlexboxGrid.Item>
                            {match?.path === "/ira/competitividade-geral" && <FlexboxGrid.Item componentClass={Col}>
                                <SelectPill
                                    id="check-picker-pill"
                                    placeholder="Concorrente"
                                    name="produto"
                                    searchable={true}
                                    value={filtros.competitividadeSelecionada}
                                    data={competitividade}
                                    onSelect={(value) =>
                                        changeFiltros(
                                            [value],
                                            'competitividadeSelecionada'
                                        )
                                    }
                                    onClean={() =>
                                        changeFiltros(
                                            [],
                                            'competitividadeSelecionada'
                                        )
                                    }
                                    locale={{
                                        noResultsText: loadingCompetitividade
                                            ? 'Carregando produtos...'
                                            : 'Nenhum item encontrado',
                                        searchPlaceholder: 'Pesquisar',
                                    }}
                                />
                            </FlexboxGrid.Item>}
                        </FlexboxGrid>
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item>
                        <ButtonLimparFiltros onClick={changeFiltros}>
                            Limpar filtros
                        </ButtonLimparFiltros>
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </div>
        </Content>
    );
};
