import React from 'react';
import { RadioGroup, Toggle } from 'rsuite';
import { Chip } from '../../../../../components';
import { Card } from '../../../../../components/atoms/Card';
import { DataFormProps, useOnboarding } from '../../../../../context';
import { ConcorrenteCard, OptionProps } from './ConcorrenteCard';
import './style.scss';

const { Header, Title, Subtitle, Content, Option } = ConcorrenteCard;

export type CardProps = {
    title: string;
    subtitle?: string;
    datakey: keyof DataFormProps;
    options: OptionProps[];
};

const cards: CardProps[] = [
    {
        datakey: 'storeType',
        title: 'TIPO DE LOJA',
        subtitle: '(Supermercado, Hipermercado, Drogaria, etc.)',
        options: [
            {
                id: 1,
                value: 'SAME_TYPE',
                label: (
                    <p>
                        Usar somente dados de concorrentes do mesmo tipo da sua
                        loja indicado
                    </p>
                ),
            },
            {
                id: 2,
                value: 'INDEPENDENT_TYPE',
                label: (
                    <p>
                        Usar dados de concorrentes independentemente do tipo de
                        loja
                    </p>
                ),
            },
        ],
    },
    {
        datakey: 'location',
        title: 'LOCALIZAÇÃO',
        options: [
            {
                id: 1,
                value: 'CITY',
                label: <p>Usar concorrentes da mesma cidade da sua loja</p>,
            },
            {
                id: 2,
                value: 'UF',
                label: <p>Usar concorrentes da mesma UF da sua loja</p>,
            },
            {
                id: 3,
                value: null,
                label: (
                    <p>
                        Usar concorrentes definidos dentro de um raio de alcance
                        a partir da sua loja <Chip>EM BREVE</Chip>
                    </p>
                ),
                disabled: true,
            },
        ],
    },
];

export const ConcorrenteForm = () => {
    const { dataForm, setDataForm } = useOnboarding();

    const handleClick = (value: unknown, datakey: keyof DataFormProps) => {
        setDataForm((oldValue) => ({
            ...oldValue,
            [datakey]: value,
        }));
    };

    return (
        <Card disableHover isPrimary className="competitor-form">
            <Card.Header className="competitor-form__header">
                <div>
                    <Toggle size="sm" checked disabled />
                    <h2 className="font-size-100-semibold">
                        Concorrência indireta
                    </h2>
                </div>
                <p className="font-size-100-regular">
                    Use dados do mercado de onde sua loja está inserida para
                    incrementar os dados de seus concorrentes
                </p>
            </Card.Header>
            <Card.Content className="competitor-form__content">
                {cards.map(({ title, subtitle, options, datakey }) => (
                    <ConcorrenteCard key={datakey}>
                        <Header>
                            <Title>{title}</Title>
                            {subtitle && <Subtitle>{subtitle}</Subtitle>}
                        </Header>
                        <Content>
                            <RadioGroup
                                name={datakey}
                                value={dataForm[datakey]}
                                onChange={(value) =>
                                    handleClick(value, datakey)
                                }
                            >
                                {options.map((option) => (
                                    <Option
                                        key={option.value}
                                        checked={
                                            dataForm[datakey] === option.value
                                        }
                                        {...option}
                                    >
                                        {option.label}
                                    </Option>
                                ))}
                            </RadioGroup>
                        </Content>
                    </ConcorrenteCard>
                ))}
            </Card.Content>
        </Card>
    );
};
