import React, { useEffect, useRef, useState } from 'react';
import { Dropdown } from 'rsuite';
import iconOptionsColums from '../../../../../assets/icons/icon_options_colums.svg.svg';
import iconOptionsColumsGray from '../../../../../assets/icons/icon_options_colums_gray.svg';

export const DropDownOptions = ({ children, title, styleClass }) => {
    const [over, setOver] = useState(false);
    const [hover, setHover] = useState(true);

    const handleHover = (type) => {
        if (hover && type === 'over') {
            setOver(true);
        }
        if (hover && type === 'out') {
            setOver(false);
        }
    };

    const handleClick = () => {
        setOver(true);
        setHover(false);
    };

    const myRef = useRef();

    const handleClickOutside = (e) => {
        if (!myRef.current.contains(e.target)) {
            setHover(true);
            setOver(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside);
        return () => document.removeEventListener('click', handleClickOutside);
    });

    return (
        <Dropdown
            className={styleClass}
            renderTitle={() => (
                <span
                    ref={myRef}
                    onMouseOver={() => handleHover('over')}
                    onMouseOut={() => handleHover('out')}
                    onClick={handleClick}
                >
                    <img
                        src={over ? iconOptionsColumsGray : iconOptionsColums}
                    />
                </span>
            )}
        >
            {title}
            {children}
        </Dropdown>
    );
};
