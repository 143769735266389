/* eslint-disable default-param-last */
import Immutable from 'seamless-immutable';
import { actionsTypeLimitePrecoCadastro } from '../actions/actionsLimitesPrecoCadastro';

const inicialState = Immutable({
    tiposSegmento: [
        {
            title: 'LOJA',
            itens: [
                {
                    name: 'searchStoreIds',
                    title: 'Lojas',
                    eventKey: 'searchStoreIds',
                    nivel: 'searchStoreIds',
                },
                // {
                //     name: 'searchCluster',
                //     title: 'Clusters',
                //     eventKey: 'searchCluster',
                //     nivel: 'searchCluster',
                // },
            ],
        },
        {
            title: 'PRODUTO',
            itens: [
                {
                    name: 'productId',
                    title: 'Produto',
                    eventKey: 'productId',
                    nivel: 'productId',
                },
            ],
        },
        {
            title: 'SEGMENTAÇÃO',
            itens: [
                {
                    name: 'sensibilityType',
                    title: 'Exclusiva',
                    eventKey: 'sensibilityType',
                    nivel: 8,
                },
                {
                    name: 'abcClass',
                    title: 'Curva',
                    eventKey: 'abcClass',
                    nivel: 'abcClass',
                },
                {
                    name: 'infoPriceAbcClass',
                    title: 'Curva ABC Local',
                    eventKey: 'infoPriceAbcClass',
                    nivel: 'infoPriceAbcClass',
                },
                {
                    name: 'infoPriceGlobalAbcClass',
                    title: 'Curva ABC Global',
                    eventKey: 'infoPriceGlobalAbcClass',
                    nivel: 'infoPriceGlobalAbcClass',
                },
                {
                    name: 'infoPriceSensibility',
                    title: 'Sensibilidade',
                    eventKey: 'infoPriceSensibility',
                    nivel: 'infoPriceSensibility',
                },
            ],
        },
    ],
    segmentosAdicionados: [],
    segmentos: [
        {
            name: 'searchStoreIds',
            nivel: 'searchStoreIds',
            cleanable: true,
            filter: true,
            title: 'Lojas',
            placeholder: 'Selecione uma loja',
            locale: {
                noResultsText: 'Nenhum item encontrado',
                searchPlaceholder: 'Digite ou busque o item desejado',
            },
            onSelect: 'handleSelect',
            onSearch: 'searchStore',
            onChange: 'handleChange',
        },
        {
            name: 'productId',
            nivel: 'productId',
            cleanable: true,
            filter: false,
            title: 'Produto',
            placeholder: 'Selecione um ou mais itens',
            locale: {
                noResultsText: 'Nenhum item encontrado',
                searchPlaceholder: 'Digite ou busque o item desejado',
            },
            onSelect: 'handleSelect',
            onSearch: 'searchProduto',
            onChange: 'handleChange',
        },
        {
            name: 'abcClass',
            nivel: 'abcClass',
            cleanable: true,
            filter: false,
            title: 'Curva',
            placeholder: 'Selecione um ou mais itens',
            locale: {
                noResultsText: 'Nenhum item encontrado',
                searchPlaceholder: 'Digite ou busque o item desejado',
            },
            onSelect: 'handleSelect',
            onChange: 'handleChange',
        },
        {
            name: 'sensibilityType',
            nivel: 8,
            cleanable: true,
            filter: false,
            title: 'Exclusiva',
            placeholder: 'Selecione um ou mais itens',
            locale: {
                noResultsText: 'Nenhum item encontrado',
                searchPlaceholder: 'Digite ou busque o item desejado',
            },
            onSelect: 'handleSelect',
            onSearch: 'searchSegmentosSensibilities',
            onChange: 'handleChange',
        },
        {
            name: 'infoPriceSensibility',
            nivel: 'infoPriceSensibility',
            cleanable: true,
            filter: false,
            title: 'Sensibilidade',
            placeholder: 'Selecione um ou mais itens',
            locale: {
                noResultsText: 'Nenhum item encontrado',
                searchPlaceholder: 'Digite ou busque o item desejado',
            },
            onSelect: 'handleSelect',
            onChange: 'handleChange',
        },
        {
            name: 'infoPriceAbcClass',
            nivel: 'infoPriceAbcClass',
            cleanable: true,
            filter: false,
            title: 'Curva ABC Local',
            placeholder: 'Selecione um ou mais itens',
            locale: {
                noResultsText: 'Nenhum item encontrado',
                searchPlaceholder: 'Digite ou busque o item desejado',
            },
            onSelect: 'handleSelect',
            onChange: 'handleChange',
        },
        {
            name: 'infoPriceGlobalAbcClass',
            nivel: 'infoPriceGlobalAbcClass',
            cleanable: true,
            filter: false,
            title: 'Curva ABC Global',
            placeholder: 'Selecione um ou mais itens',
            locale: {
                noResultsText: 'Nenhum item encontrado',
                searchPlaceholder: 'Digite ou busque o item desejado',
            },
            onSelect: 'handleSelect',
            onChange: 'handleChange',
        },
    ],
    segmentosData: {
        productId: {
            lista: [],
            value: [],
            cache: [],
        },
        searchStoreIds: {
            lista: [],
            value: [],
            cache: [],
        },
        abcClass: {
            lista: [
                { label: 'A', value: 'A' },
                { label: 'B', value: 'B' },
                { label: 'C', value: 'C' },
                { label: 'D', value: 'D' },
            ],
            value: '',
            cache: [],
        },
        sensibilityType: {
            lista: [],
            value: [],
            cache: [],
        },
        infoPriceAbcClass: {
            lista: [
                { label: 'Local A', value: 'A' },
                { label: 'Local B', value: 'B' },
                { label: 'Local C', value: 'C' },
                { label: 'Local D', value: 'D' },
            ],
            value: [],
            cache: [],
        },
        infoPriceGlobalAbcClass: {
            lista: [
                { label: 'Global A', value: 'A' },
                { label: 'Global B', value: 'B' },
                { label: 'Global C', value: 'C' },
                { label: 'Global D', value: 'D' },
            ],
            value: [],
            cache: [],
        },
        infoPriceSensibility: {
            lista: [
                { label: 'Sensível', value: 'SENSITIVE' },
                { label: 'Não Sensível', value: 'NOT_SENSITIVE' },
                { label: 'Super Sensível', value: 'SUPER_SENSITIVE' },
            ],
            value: [],
            cache: [],
        },
    },
});

function limitesPrecoCadastroDataReducer(state = inicialState, action) {
    switch (action.type) {
        case actionsTypeLimitePrecoCadastro.ADD_SEGMENTO_LIMITE_PRECO_CADASTRO:
            return state.merge({
                segmentosAdicionados: [
                    ...state.segmentosAdicionados,
                    action.filtro,
                ],
            });
        case actionsTypeLimitePrecoCadastro.DELETE_SEGMENTO_LIMITE_PRECO_CADASTRO:
            return state.merge({
                segmentosAdicionados: state.segmentosAdicionados.filter(
                    (item, index) => index !== action.index,
                ),
                segmentosData: {
                    ...state.segmentosData,
                    [`${action.name}`]: {
                        ...state.segmentosData[`${action.name}`],
                        value: [],
                    },
                },
            });
        case actionsTypeLimitePrecoCadastro.CHANGE_VALUE_SEGMENTO_LIMITE_PRECO_CADASTRO:
            return state.merge({
                ...state,
                segmentosData: {
                    ...state.segmentosData,
                    [`${action.name}`]: {
                        ...state.segmentosData[`${action.name}`],
                        value: action.value,
                    },
                },
            });
        case actionsTypeLimitePrecoCadastro.CHANGE_CACHE_SEGMENTO_LIMITE_PRECO_CADASTRO:
            return state.merge({
                ...state,
                segmentosData: {
                    ...state.segmentosData,
                    [`${action.name}`]: {
                        ...state.segmentosData[`${action.name}`],
                        cache: action.cache,
                    },
                },
            });
        case actionsTypeLimitePrecoCadastro.SELECT_VALUE_SEGMENTO_LIMITE_PRECO_CADASTRO:
            return state.merge({
                ...state,
                segmentosData: {
                    ...state.segmentosData,
                    [`${action.name}`]: {
                        ...state.segmentosData[`${action.name}`],
                        cache: [
                            ...state.segmentosData[`${action.name}`].cache,
                            action.item,
                        ],
                    },
                },
            });
        case actionsTypeLimitePrecoCadastro.CLEAR_ALL_SEGMENTOS_LIMITE_PRECO_CADASTRO:
            return state.merge({
                ...state,
                tiposSegmento: inicialState.tiposSegmento,
                segmentosData: inicialState.segmentosData,
                segmentosAdicionados: inicialState.segmentosAdicionados,
            });
        case actionsTypeLimitePrecoCadastro.LIST_ITENS_SEGMENTOS_LIMITE_PRECO_CADASTRO:
            return state.merge({
                ...state,
                segmentosData: {
                    ...state.segmentosData,
                    [`${action.name}`]: {
                        ...state.segmentosData[`${action.name}`],

                        lista: action.itens
                            ?.map((item) => ({
                                label: item,
                                value: item,
                            }))
                            .concat(
                                state.segmentosData[
                                    `${action.name}`
                                ].cache.filter(
                                    (item) =>
                                        action.itens?.findIndex(
                                            (itemLista) =>
                                                itemLista === item.label,
                                        ) === -1,
                                ),
                            ),
                    },
                },
            });
        case actionsTypeLimitePrecoCadastro.LIST_ITENS_PRODUTO_CADASTRO_LIMITE:
            return state.merge({
                ...state,
                segmentosData: {
                    ...state.segmentosData,
                    [`${action.name}`]: {
                        ...state.segmentosData[`${action.name}`],

                        lista: action.itens?.data
                            .map((item) => ({
                                label: `${item.product_id} - ${item.description}`,
                                value: `${item.product_id}-${item.description}`,
                                gtin: item.gtin,
                                product_id: item.product_id,
                            }))
                            .concat(
                                state.segmentosData[
                                    `${action.name}`
                                ].cache.filter(
                                    (item) =>
                                        action.itens?.data.findIndex(
                                            (itemLista) =>
                                                itemLista.product_id ===
                                                item.product_id,
                                        ) === -1,
                                ),
                            ),
                    },
                },
            });
        case actionsTypeLimitePrecoCadastro.LIST_ITEMS_LOJA_CADASTRO_LIMITE:
            return state.merge({
                ...state,
                segmentosData: {
                    ...state.segmentosData,
                    [`${action.name}`]: {
                        ...state.segmentosData[`${action.name}`],
                        lista: action.itens
                            .map((item) => ({
                                label: `${item.store_id} - ${item.store}`,
                                value: item.store_id,
                                gtin: item.gtin,
                                store_id: item.store_id,
                            }))
                            .concat(
                                state.segmentosData[
                                    `${action.name}`
                                ].cache.filter(
                                    (item) =>
                                        action.itens?.findIndex(
                                            (itemLista) =>
                                                itemLista.store_id ===
                                                item.store_id,
                                        ) === -1,
                                ),
                            ),
                    },
                },
            });
        case actionsTypeLimitePrecoCadastro.LIST_ITENS_SEGMENTOS_CLASSES_LIMITE_PRECO_CADASTRO:
            return state.merge({
                ...state,
                segmentosData: {
                    ...state.segmentosData,
                    [`${action.name}`]: {
                        ...state.segmentosData[`${action.name}`],
                        lista: action.itens?.map((item) => ({
                            label: item.abc_class || item.sensibility_type,
                            value: item.abc_class || item.sensibility_type,
                        })),
                    },
                },
            });
        case actionsTypeLimitePrecoCadastro.GET_SEGMENTOS_LIMITE_PRECO_CADASTRO:
            return state.merge({
                ...state,
                tiposSegmento: [
                    ...state.tiposSegmento,
                    {
                        title: 'CATEGORIZAÇÃO',
                        itens: action.itens?.map((item) => ({
                            name: item.field,
                            title: item.name,
                            eventKey: item.field,
                            nivel: item.level,
                        })),
                    },
                ],
                segmentos: state.segmentos.concat(
                    action.itens?.map((item) => ({
                        name: item.field,
                        nivel: item.level,
                        cleanable: true,
                        filter: false,
                        title: item.name,
                        placeholder: 'Selecione um ou mais itens',
                        locale: {
                            noResultsText: 'Nenhum item encontrado',
                            searchPlaceholder:
                                'Digite ou busque o item desejado',
                        },
                        onSelect: 'handleSelect',
                        onSearch: 'searchItensSegmento',
                        onChange: 'handleChange',
                    })),
                ),
                segmentosData: { ...action.segmentos, ...state.segmentosData },
            });
        case actionsTypeLimitePrecoCadastro.SET_SEGMENTOS_UPDATE:
            return state.merge({
                ...state,
                segmentosData: {
                    ...state.segmentosData,
                    [`${action.name}`]: {
                        ...state.segmentosData[`${action.name}`],
                        lista:
                            state.segmentosData[
                                `${action.name}`
                            ].lista.findIndex(
                                (item) => item.value === action.value,
                            ) === -1
                                ? [
                                      ...state.segmentosData[`${action.name}`]
                                          .lista,
                                      {
                                          label: action.value,
                                          value: action.value,
                                      },
                                  ]
                                : state.segmentosData[`${action.name}`].lista,
                        cache:
                            state.segmentosData[
                                `${action.name}`
                            ].cache.findIndex(
                                (item) => item.value === action.value,
                            ) === -1
                                ? [
                                      ...state.segmentosData[`${action.name}`]
                                          .cache,
                                      {
                                          label: action.value,
                                          value: action.value,
                                      },
                                  ]
                                : state.segmentosData[`${action.name}`].cache,
                    },
                },
            });
        case actionsTypeLimitePrecoCadastro.SET_SEGMENTOS_PRODUTOS_UPDATE_LIMITE:
            return state.merge({
                ...state,
                segmentosData: {
                    ...state.segmentosData,
                    productId: {
                        ...state.segmentosData.productId,
                        lista:
                            state.segmentosData.productId.lista.findIndex(
                                (item) => item.value === action.item.value,
                            ) === -1
                                ? [
                                      ...state.segmentosData.productId.lista,
                                      {
                                          label: `${action.item.value} - ${action.item.description}`,
                                          value: `${action.item.value}-${action.item.description}`,
                                          gtin: action.item.gtin,
                                          product_id: action.item.value,
                                      },
                                  ].filter(
                                      (v, i, a) =>
                                          a.findIndex(
                                              (t) =>
                                                  t.product_id === v.product_id,
                                          ) === i,
                                  )
                                : state.segmentosData.productId.lista,
                        cache:
                            state.segmentosData.productId.cache.findIndex(
                                (item) => item.value === action.item.value,
                            ) === -1
                                ? [
                                      ...state.segmentosData.productId.cache,
                                      {
                                          label: `${action.item.value} - ${action.item.description}`,
                                          value: `${action.item.value}-${action.item.description}`,
                                          gtin: action.item.gtin,
                                          product_id: action.item.value,
                                      },
                                  ].filter(
                                      (v, i, a) =>
                                          a.findIndex(
                                              (t) =>
                                                  t.product_id === v.product_id,
                                          ) === i,
                                  )
                                : state.segmentosData.productId.cache,
                    },
                },
            });
        case actionsTypeLimitePrecoCadastro.SET_SEGMENTOS_LOJAS_UPDATE_LIMITE:
            return state.merge({
                ...state,
                segmentosData: {
                    ...state.segmentosData,
                    [`${action.name}`]: {
                        ...state.segmentosData[`${action.name}`],
                        lista:
                            state.segmentosData[
                                `${action.name}`
                            ].lista.findIndex(
                                (item) => item.value === action.item,
                            ) === -1
                                ? [
                                      ...state.segmentosData.searchStoreIds
                                          .lista,
                                      {
                                          value: action.item,
                                          label: action.item,
                                      },
                                  ]
                                : state.segmentosData.searchStoreIds.lista,
                    },
                },
            });
        case actionsTypeLimitePrecoCadastro.SET_SEGMENTOS_CLASSES_UPDATE_LIMITE:
            return state.merge({
                ...state,
                segmentosData: {
                    ...state.segmentosData,
                    abcClass: {
                        ...state.segmentosData.abcClass,
                        lista:
                            state.segmentosData.abcClass.lista.findIndex(
                                (item) => item.value === action.item,
                            ) === -1
                                ? [
                                      ...state.segmentosData.abcClass.lista,
                                      {
                                          label: action.item,
                                          value: action.item,
                                      },
                                  ]
                                : state.segmentosData.abcClass.lista,
                    },
                },
            });
        case actionsTypeLimitePrecoCadastro.SET_SEGMENTOS_SENSIBILITY_UPDATE_LIMITE:
            return state.merge({
                ...state,
                segmentosData: {
                    ...state.segmentosData,
                    sensibilityType: {
                        ...state.segmentosData.sensibilityType,
                        lista:
                            state.segmentosData.sensibilityType.lista.findIndex(
                                (item) => item.value === action.item,
                            ) === -1
                                ? [
                                      ...state.segmentosData.sensibilityType
                                          .lista,
                                      {
                                          label: action.item,
                                          value: action.item,
                                      },
                                  ]
                                : state.segmentosData.sensibilityType.lista,
                    },
                },
            });
        default:
            return state;
    }
}

export default limitesPrecoCadastroDataReducer;
