import cn from 'classnames';
import React, { ComponentProps, memo, useCallback, useMemo } from 'react';
import CurrencyFormat from 'react-currency-format';
import { MdOutlineClose, MdSwapVert } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Divider } from 'rsuite';
import { GerenciadorPrecos } from '../../../../../@types/GerenciadorPrecos';
import { RootState } from '../../../../../@types/RootState';
import {
    InputAddon,
    InputCurrency,
    InputGroup,
} from '../../../../../components';
import { BaseCell } from '../../../../../components/TableCells';
import ExceptionSaveDatapoint from '../../../../../data/ExceptionSaveDatapoint';
import { IPAMaths } from '../../../../../lib';
import { SET_GERENCIADOR_CONTEXT_MENU } from '../../../../../reducers/gerenciadorPrecos/contextMenu';
import {
    SET_GERENCIADOR_DATAPOINT_BY_INDEX,
    selectorDatapoints,
} from '../../../../../reducers/gerenciadorPrecos/datapoints';
import {
    REMOVE_GERENCIADOR_EXPANDED_ROW_OPTION,
    selectorExpandedRowKey,
} from '../../../../../reducers/gerenciadorPrecos/expandedRowKey';
import {
    CompetitivenessPriceIndicator,
    MoneyPercentageIndicator,
    VariationBoxIndicator,
} from '../../../Negociacoes/pages/NegociacaoFornecedor/components';
import {
    SaveEditedWholesaleDatapoint,
    saveEditedWholesaleDatapoint,
} from '../../services';
import { WholesaleQtdeCell } from '../TableCells';
import { ExpandWholesaleCell } from '../TableCells/ExpandWholesaleCell';
import styles from './ExpandedRowWholesale.module.scss';
import * as calc from './utils';

const MemoQtdeCell = memo(WholesaleQtdeCell);

const { MainContainer, SubContainer } = BaseCell;

type RowData = GerenciadorPrecos.RowData;

export type ExpandedRowWholesaleProps = {
    rowData: RowData;
} & ComponentProps<'div'>;

const ExpandedRowWholesale = ({
    rowData,
    className,
    ...props
}: ExpandedRowWholesaleProps) => {
    if (!rowData) return null;

    const { productsToBePricedId } = rowData;

    const datapoints = useSelector(selectorDatapoints);

    const { key, options } = useSelector(selectorExpandedRowKey);

    const isDisabledEdit = useSelector((state: RootState) => {
        return !state.reducerIPA.wholesale.allowPriceEdit;
    });

    const index = useMemo(() => {
        return datapoints.findIndex(
            (item) => item.productsToBePricedId === productsToBePricedId,
        );
    }, [datapoints, productsToBePricedId]);

    const dispatch = useDispatch();

    const isWholesaleExpanded = useMemo(
        () => key === productsToBePricedId && options.includes('WHOLESALE'),
        [options, key, productsToBePricedId],
    );

    if (!isWholesaleExpanded || !rowData.wholesale) return null;

    const {
        wholesale: {
            retailPrice,
            originalMargin,
            price,
            discountPercentage,
            newCompetitivenessPrice,
            newMargin,
        },
        competitorsPrice,
    } = rowData;

    const competitivenessValue = useMemo(
        () => IPAMaths.novaCompetitividade(retailPrice, competitorsPrice),
        [retailPrice, competitorsPrice],
    );

    const handleContextMenu = useCallback(() => {
        dispatch(SET_GERENCIADOR_CONTEXT_MENU(rowData));
    }, [rowData, dispatch]);

    const handleClose = useCallback(() => {
        dispatch(REMOVE_GERENCIADOR_EXPANDED_ROW_OPTION('WHOLESALE'));
    }, [dispatch]);

    const handleUpdateCampo = useCallback(
        (name: string, value: number, rowData: RowData, index: number) => {
            const { wholesale } = rowData;

            if (!wholesale) return;

            let updatedWholesale = { ...wholesale };

            if (value <= 0) {
                updatedWholesale.price = value;
                updatedWholesale.newCompetitivenessPrice = 0;
                updatedWholesale.newMargin = 0;
                return;
            }

            switch (name) {
                case 'price':
                    updatedWholesale = {
                        ...updatedWholesale,
                        ...calc.handleCalcWholesalePrice(value, rowData),
                    };
                    break;
                case 'CPI':
                    updatedWholesale = {
                        ...updatedWholesale,
                        ...calc.handleCalcWholesaleCPI(value, rowData),
                    };
                    break;
                case 'margin':
                    updatedWholesale = {
                        ...updatedWholesale,
                        ...calc.handleCalcWholesaleMargin(value, rowData),
                    };
                    break;
                default:
                    break;
            }

            dispatch(
                SET_GERENCIADOR_DATAPOINT_BY_INDEX({
                    index,
                    rowData: {
                        ...rowData,
                        wholesale: updatedWholesale,
                    },
                }),
            );
        },
        [dispatch],
    );

    const handleBlur = useCallback(
        async (rowData: RowData) => {
            try {
                const { wholesale } = rowData;

                if (!wholesale) throw new Error('Wholesale data not found');

                const model: SaveEditedWholesaleDatapoint.Data = {
                    price: wholesale.price,
                    triggerCount: wholesale.triggerCount,
                    productId: rowData.productId,
                    storeId: rowData.storeId,
                };

                const res = await saveEditedWholesaleDatapoint(model);

                return res;
            } catch {
                const error = new ExceptionSaveDatapoint({
                    productId: rowData.productId,
                    storeId: rowData.storeId,
                    storeName: rowData.storeName,
                });
                throw Alert.error(error.message);
            }
        },
        [dispatch],
    );

    return (
        <div
            className={cn(styles.wrapper, className)}
            onContextMenu={handleContextMenu}
            {...props}
        >
            {/* 60px // PREÇO ATACADO */}
            <ExpandWholesaleCell
                className={styles.preco_atacado}
                rowData={rowData}
                theme="blue"
            />
            {/* 1fr // DESCRIÇÃO */}
            <BaseCell>
                <MainContainer>
                    <span>Atacado</span>
                </MainContainer>
            </BaseCell>
            {/* 64px // QTDE */}
            <MemoQtdeCell
                rowData={rowData}
                index={index}
                handleBlur={handleBlur}
            />
            {/* 124px + 127px // PREÇO VIGENTE */}
            <BaseCell>
                <MainContainer>
                    {retailPrice ? (
                        <CurrencyFormat
                            fixedDecimalScale
                            decimalScale={2}
                            value={retailPrice}
                            displayType="text"
                            prefix="R$ "
                            decimalSeparator=","
                            thousandSeparator="."
                        />
                    ) : (
                        '--'
                    )}
                </MainContainer>
                <SubContainer>
                    <MoneyPercentageIndicator
                        value={originalMargin}
                        tooltip="Margem do preço vigente"
                    />
                    <Divider vertical />
                    <CompetitivenessPriceIndicator
                        value={competitivenessValue}
                    />
                </SubContainer>
            </BaseCell>
            {/* 132px // PREÇO SUGERIDO */}
            <BaseCell>
                <MainContainer>
                    <InputGroup>
                        <InputAddon>R$</InputAddon>
                        <InputCurrency
                            value={price}
                            name="price"
                            decimalSeparator=","
                            thousandSeparator="."
                            precision="2"
                            allowEmpty
                            allowNegative={false}
                            onChangeEvent={(_e, _m, value) => {
                                handleUpdateCampo(
                                    'price',
                                    value,
                                    rowData,
                                    index,
                                );
                            }}
                            onBlur={(_e, _v, { hasChanges }) => {
                                if (hasChanges) handleBlur(rowData);
                            }}
                            disabled={isDisabledEdit}
                        />
                    </InputGroup>
                </MainContainer>
                <SubContainer>
                    <VariationBoxIndicator
                        value={IPAMaths.variation.price(price, retailPrice)}
                        suffix="%"
                    />
                    <span className={styles.percentage_indicator}>
                        <MdSwapVert size={10} />
                        <CurrencyFormat
                            fixedDecimalScale
                            decimalScale={1}
                            value={discountPercentage}
                            displayType="text"
                            decimalSeparator=","
                            thousandSeparator="."
                            precision={1}
                            prefix="-"
                            suffix="%"
                        />
                    </span>
                </SubContainer>
            </BaseCell>
            {/* 116px // CPI */}
            <BaseCell>
                <MainContainer>
                    <InputGroup>
                        <InputCurrency
                            name="CPI"
                            decimalSeparator=","
                            thousandSeparator="."
                            precision="1"
                            allowEmpty
                            allowNegative={false}
                            value={newCompetitivenessPrice}
                            onChangeEvent={(_e, _m, value) => {
                                handleUpdateCampo('CPI', value, rowData, index);
                            }}
                            onBlur={(_e, _v, { hasChanges }) => {
                                if (hasChanges) handleBlur(rowData);
                            }}
                            disabled={isDisabledEdit}
                        />
                        <InputAddon>%</InputAddon>
                    </InputGroup>
                </MainContainer>
                <SubContainer>
                    <VariationBoxIndicator
                        value={IPAMaths.variation.cpi(
                            newCompetitivenessPrice,
                            competitorsPrice,
                        )}
                    />
                </SubContainer>
            </BaseCell>
            {/* 116px // MARGEM */}
            <BaseCell>
                <MainContainer>
                    <InputGroup>
                        <InputCurrency
                            name="margin"
                            decimalSeparator=","
                            thousandSeparator="."
                            precision="1"
                            allowEmpty
                            allowNegative
                            value={newMargin}
                            onChangeEvent={(_e, _m, value) => {
                                handleUpdateCampo(
                                    'margin',
                                    value,
                                    rowData,
                                    index,
                                );
                            }}
                            onBlur={(_e, _v, { hasChanges }) => {
                                if (hasChanges) handleBlur(rowData);
                            }}
                            disabled={isDisabledEdit}
                        />
                        <InputAddon>%</InputAddon>
                    </InputGroup>
                </MainContainer>
                <SubContainer>
                    <VariationBoxIndicator
                        value={IPAMaths.variation.margem(
                            newMargin,
                            originalMargin,
                        )}
                    />
                </SubContainer>
            </BaseCell>
            {/* 1fr */}
            <span />
            {/* 26px */}
            <button className={styles.close_button} onClick={handleClose}>
                <MdOutlineClose size={12} />
            </button>
        </div>
    );
};

export default ExpandedRowWholesale;
