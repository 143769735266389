import qs from 'qs';
import api from '../utils/API';

export const getLojasCliente = (data) => api({
        url: `${process.env.REACT_APP_CADASTROS_API_URL}/lojas/clientes/search`,
        method: 'GET',
        params: {
            query: data.query,
            rede: data.rede ? data.rede : null,
            uf: data.uf,
            cidade: data.cidade,
            size: data.size,
        },
        expectToken: true,
    }).then((resp) => resp.data);

export const getLojas = () => api({
        url: `${process.env.REACT_APP_IPA_URL}/setup/stores`,
        method: 'GET',
        expectToken: true,
        params: {
            clientId: JSON.parse(localStorage.getItem('cliente'))?.codigo,
        },
        paramsSerializer(params) {
            return qs.stringify(params, { arrayFormat: 'repeat' });
        },
    }).then((resp) => resp.data);

export const getRedes = (data) => api({
        url: `${process.env.REACT_APP_CADASTROS_API_URL}/lojas/clientes/rede/search`,
        method: 'GET',
        params: {
            query: data.query,
            cidade: data.cidade,
            uf: data.uf,
            size: data.size,
        },
        expectToken: true,
    }).then((resp) => resp.data);

export const getUf = (data) => api({
        url: `${process.env.REACT_APP_CADASTROS_API_URL}/lojas/clientes/uf/search`,
        method: 'GET',
        params: {
            query: data.query,
            rede: data.rede ? data.rede : null,
            size: data.size,
        },
        expectToken: true,
    }).then((resp) => resp.data);

export const getCidade = (data) => api({
        url: `${process.env.REACT_APP_CADASTROS_API_URL}/lojas/clientes/cidade/search`,
        method: 'GET',
        params: {
            query: data.query,
            rede: data.rede ? data.rede : null,
            uf: data.uf,
            size: data.size,
        },
        expectToken: true,
    }).then((resp) => resp.data);
