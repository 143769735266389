import React, { useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { add, differenceInDays } from 'date-fns';
import { Container } from 'rsuite';
import NavBar from './components/NavBar';
import Header from './components/Header';
import ChartViews from './components/ChartViews';
import {
    getPublicData,
    getAgregadoWithCount,
    getAgregadoTopFivePublicData,
    getSharedStatePublicData,
    getMapPinpointsPublicData,
} from '../../actions/actionsShareAnalysis';
import { LoadingSpiner } from '../../components/LoadingSpinner';

const ShareAnalyze = () => {
    const dispatch = useDispatch();
    const { hash } = useLocation();
    const queryString = require('query-string');
    const {
        f, c1, c2, c3, c4,
    } = queryString.parse(hash);

    const {
        timeEvolutionPublicData,
        boxplotPublicData,
        topFivePublicData,
        mapPublicData,
        sharedStateData,
        pinpointsData,
    } = useSelector(
        (state) => ({
            timeEvolutionPublicData:
                state.shareAnalysisDataReducer?.timeEvolutionPublic.data,
            boxplotPublicData:
                state.shareAnalysisDataReducer?.boxplotPublic.data,
            topFivePublicData:
                state.shareAnalysisDataReducer?.topFivePublic.data,
            mapPublicData: state.shareAnalysisDataReducer?.mapPublic.data,
            sharedStateData:
                state.shareAnalysisDataReducer?.sharedStatePublic.data,
            pinpointsData:
                state.shareAnalysisDataReducer?.pinpointsPublic.markers,
        }),
        shallowEqual,
    );

    const analysisExpired = () => {
        const date = new Date();
        const today = new Date(
            date.getUTCFullYear(),
            date.getUTCMonth(),
            date.getUTCDate(),
            0,
            0,
            0,
        );
        const [startYear, startMonth, startDay]
            = sharedStateData?.f?.data_inicio?.split('-');
        const ninetyDaysBeforeFromToday = add(today, { days: -91 });
        const filterInitialDate = new Date(startYear, startMonth - 1, startDay);
        const diffDays = differenceInDays(
            filterInitialDate,
            ninetyDaysBeforeFromToday,
        );

        if (diffDays <= 0) {
            return true;
        }
        return false;
    };

    const redirectToLogin = () => {
        const tab = window.open('/login?utm_source=infoprice&utm_medium=share_analyze&utm_campaign=share_analyze_infopanel_cta_login', '_blank');
        tab.focus();
    };

    const redirectToRegister = () => {
        const tab = window.open('/free/email?utm_source=infoprice&utm_medium=share_analyze&utm_campaign=share_analyze_infopanel_cta_create_account', '_blank');
        tab.focus();
    };

    useEffect(() => {
        dispatch(
            getSharedStatePublicData({
                f,
                c1,
                c2,
                c3,
                c4,
            }),
        );
    }, []);

    useEffect(() => {
        if (sharedStateData?.c1?.group.length) {
            dispatch(getAgregadoWithCount(f, c1, 'c1'));
            dispatch(getPublicData(f, c2, 'c2'));
            dispatch(getAgregadoTopFivePublicData(f, c4));
            if (sharedStateData?.c3?.group === null) {
                dispatch(getMapPinpointsPublicData(f, c3));
            } else {
                dispatch(getPublicData(f, c3, 'c3'));
            }
        }
    }, [sharedStateData?.c1?.group.length]);

    return (
        <div className="share-analyze">
            <NavBar onClickLogin={redirectToLogin} onClickRegister={redirectToRegister} />
            <Container className="share-analyze-container">
                <Header
                    headerData={sharedStateData?.f}
                    expired={sharedStateData.f && analysisExpired()}
                />
                <LoadingSpiner size="md" />
                {sharedStateData?.products ? (
                    <ChartViews
                        boxPlotData={boxplotPublicData}
                        topPricesData={topFivePublicData}
                        dataTimeEvolution={timeEvolutionPublicData}
                        mapData={mapPublicData?.content || pinpointsData}
                        sharedState={sharedStateData}
                        expired={sharedStateData.f && analysisExpired()}
                    />
                ) : null}
            </Container>
        </div>
    );
};

export default ShareAnalyze;
